/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PageInfo,
  SortDirection,
  VehicleMovement,
  VehicleMovementCreate,
  VehicleMovementPeriod,
  VehicleMovementPeriodSortKey,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    VehicleMovementFromJSON,
    VehicleMovementToJSON,
    VehicleMovementCreateFromJSON,
    VehicleMovementCreateToJSON,
    VehicleMovementPeriodFromJSON,
    VehicleMovementPeriodToJSON,
    VehicleMovementPeriodSortKeyFromJSON,
    VehicleMovementPeriodSortKeyToJSON,
} from '../models';

export interface VehicleMovementsCreateVehicleMovementRequest {
    vehicleMovementCreate: VehicleMovementCreate;
}

export interface VehicleMovementsListVehicleMovementPeriodsRequest {
    pageSize: number;
    dateStart?: Date;
    dateEnd?: Date;
    search?: string | null;
    vehicleIds?: Array<string> | null;
    userIds?: Array<string> | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: VehicleMovementPeriodSortKey;
}

export interface VehicleMovementsListVehicleMovementPeriodsMetadataRequest {
    pageSize: number;
    dateStart?: Date;
    dateEnd?: Date;
    search?: string | null;
    vehicleIds?: Array<string> | null;
    userIds?: Array<string> | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: VehicleMovementPeriodSortKey;
}

/**
 * VehicleMovementsApi - interface
 * 
 * @export
 * @interface VehicleMovementsApiInterface
 */
export interface VehicleMovementsApiInterface {
    /**
     * Create a new vehicle movement
     * @summary Create a vehicle movement
     * @param {VehicleMovementCreate} vehicleMovementCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleMovementsApiInterface
     */
    vehicleMovementsCreateVehicleMovementRaw(requestParameters: VehicleMovementsCreateVehicleMovementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleMovement>>;

    /**
     * Create a new vehicle movement
     * Create a vehicle movement
     */
    vehicleMovementsCreateVehicleMovement(requestParameters: VehicleMovementsCreateVehicleMovementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleMovement>;

    /**
     * Lists all the vehicle movement periods.
     * @summary List vehicle movement periods
     * @param {number} pageSize 
     * @param {Date} [dateStart] 
     * @param {Date} [dateEnd] 
     * @param {string} [search] 
     * @param {Array<string>} [vehicleIds] 
     * @param {Array<string>} [userIds] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {VehicleMovementPeriodSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleMovementsApiInterface
     */
    vehicleMovementsListVehicleMovementPeriodsRaw(requestParameters: VehicleMovementsListVehicleMovementPeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleMovementPeriod>>>;

    /**
     * Lists all the vehicle movement periods.
     * List vehicle movement periods
     */
    vehicleMovementsListVehicleMovementPeriods(requestParameters: VehicleMovementsListVehicleMovementPeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleMovementPeriod>>;

    /**
     * Fetch information about the total number of pages available.
     * @summary List vehicle movement periods metadata
     * @param {number} pageSize 
     * @param {Date} [dateStart] 
     * @param {Date} [dateEnd] 
     * @param {string} [search] 
     * @param {Array<string>} [vehicleIds] 
     * @param {Array<string>} [userIds] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {VehicleMovementPeriodSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleMovementsApiInterface
     */
    vehicleMovementsListVehicleMovementPeriodsMetadataRaw(requestParameters: VehicleMovementsListVehicleMovementPeriodsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total number of pages available.
     * List vehicle movement periods metadata
     */
    vehicleMovementsListVehicleMovementPeriodsMetadata(requestParameters: VehicleMovementsListVehicleMovementPeriodsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

}

/**
 * 
 */
export class VehicleMovementsApi extends runtime.BaseAPI implements VehicleMovementsApiInterface {

    /**
     * Create a new vehicle movement
     * Create a vehicle movement
     */
    async vehicleMovementsCreateVehicleMovementRaw(requestParameters: VehicleMovementsCreateVehicleMovementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleMovement>> {
        if (requestParameters.vehicleMovementCreate === null || requestParameters.vehicleMovementCreate === undefined) {
            throw new runtime.RequiredError('vehicleMovementCreate','Required parameter requestParameters.vehicleMovementCreate was null or undefined when calling vehicleMovementsCreateVehicleMovement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_movements/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleMovementCreateToJSON(requestParameters.vehicleMovementCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleMovementFromJSON(jsonValue));
    }

    /**
     * Create a new vehicle movement
     * Create a vehicle movement
     */
    async vehicleMovementsCreateVehicleMovement(requestParameters: VehicleMovementsCreateVehicleMovementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleMovement> {
        const response = await this.vehicleMovementsCreateVehicleMovementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists all the vehicle movement periods.
     * List vehicle movement periods
     */
    async vehicleMovementsListVehicleMovementPeriodsRaw(requestParameters: VehicleMovementsListVehicleMovementPeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleMovementPeriod>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling vehicleMovementsListVehicleMovementPeriods.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateStart !== undefined) {
            queryParameters['date_start'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['date_end'] = (requestParameters.dateEnd as any).toISOString();
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.vehicleIds) {
            queryParameters['vehicle_ids'] = requestParameters.vehicleIds;
        }

        if (requestParameters.userIds) {
            queryParameters['user_ids'] = requestParameters.userIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_movements/periods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleMovementPeriodFromJSON));
    }

    /**
     * Lists all the vehicle movement periods.
     * List vehicle movement periods
     */
    async vehicleMovementsListVehicleMovementPeriods(requestParameters: VehicleMovementsListVehicleMovementPeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleMovementPeriod>> {
        const response = await this.vehicleMovementsListVehicleMovementPeriodsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total number of pages available.
     * List vehicle movement periods metadata
     */
    async vehicleMovementsListVehicleMovementPeriodsMetadataRaw(requestParameters: VehicleMovementsListVehicleMovementPeriodsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling vehicleMovementsListVehicleMovementPeriodsMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateStart !== undefined) {
            queryParameters['date_start'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['date_end'] = (requestParameters.dateEnd as any).toISOString();
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.vehicleIds) {
            queryParameters['vehicle_ids'] = requestParameters.vehicleIds;
        }

        if (requestParameters.userIds) {
            queryParameters['user_ids'] = requestParameters.userIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_movements/periods/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total number of pages available.
     * List vehicle movement periods metadata
     */
    async vehicleMovementsListVehicleMovementPeriodsMetadata(requestParameters: VehicleMovementsListVehicleMovementPeriodsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.vehicleMovementsListVehicleMovementPeriodsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
