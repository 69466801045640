/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  FleetAuthenticateEvent,
  FleetAuthenticateEventCreate,
  FleetAuthenticatePeriod,
  FleetAuthenticatePeriodSortKey,
  PageInfo,
  SortDirection,
  VehicleMovementPeriodSortKey,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FleetAuthenticateEventFromJSON,
    FleetAuthenticateEventToJSON,
    FleetAuthenticateEventCreateFromJSON,
    FleetAuthenticateEventCreateToJSON,
    FleetAuthenticatePeriodFromJSON,
    FleetAuthenticatePeriodToJSON,
    FleetAuthenticatePeriodSortKeyFromJSON,
    FleetAuthenticatePeriodSortKeyToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    VehicleMovementPeriodSortKeyFromJSON,
    VehicleMovementPeriodSortKeyToJSON,
} from '../models';

export interface FleetAuthenticateEventsCreateFleetAuthenticateEventRequest {
    fleetAuthenticateEventCreate: FleetAuthenticateEventCreate;
}

export interface FleetAuthenticateEventsExportFleetAuthenticatePeriodsRequest {
    timezone: string;
    exportType: FleetAuthenticateEventsExportFleetAuthenticatePeriodsExportType;
    pageSize: number;
    dateStart?: Date;
    dateEnd?: Date;
    search?: string | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: FleetAuthenticatePeriodSortKey;
}

export interface FleetAuthenticateEventsListFleetAuthenticatePeriodsRequest {
    pageSize: number;
    dateStart?: Date;
    dateEnd?: Date;
    search?: string | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: FleetAuthenticatePeriodSortKey;
}

export interface FleetAuthenticateEventsListFleetAuthenticatePeriodsMetadataRequest {
    pageSize: number;
    dateStart?: Date;
    dateEnd?: Date;
    search?: string | null;
}

export interface VehicleMovementsExportVehicleMovementPeriodsRequest {
    timezone: string;
    exportType: VehicleMovementsExportVehicleMovementPeriodsExportType;
    pageSize: number;
    dateStart?: Date;
    dateEnd?: Date;
    search?: string | null;
    vehicleIds?: Array<string> | null;
    userIds?: Array<string> | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: VehicleMovementPeriodSortKey;
}

/**
 * FleetAuthenticateEventsApi - interface
 * 
 * @export
 * @interface FleetAuthenticateEventsApiInterface
 */
export interface FleetAuthenticateEventsApiInterface {
    /**
     * Create a new fleet_authenticate_event
     * @summary Create a fleet_authenticate_event
     * @param {FleetAuthenticateEventCreate} fleetAuthenticateEventCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FleetAuthenticateEventsApiInterface
     */
    fleetAuthenticateEventsCreateFleetAuthenticateEventRaw(requestParameters: FleetAuthenticateEventsCreateFleetAuthenticateEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FleetAuthenticateEvent>>;

    /**
     * Create a new fleet_authenticate_event
     * Create a fleet_authenticate_event
     */
    fleetAuthenticateEventsCreateFleetAuthenticateEvent(requestParameters: FleetAuthenticateEventsCreateFleetAuthenticateEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FleetAuthenticateEvent>;

    /**
     * Exports all fleet authenticate periods with respect to given filters.
     * @summary Export fleet authenticate periods as login reports
     * @param {string} timezone 
     * @param {'csv' | 'xlsx'} exportType 
     * @param {number} pageSize 
     * @param {Date} [dateStart] 
     * @param {Date} [dateEnd] 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {FleetAuthenticatePeriodSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FleetAuthenticateEventsApiInterface
     */
    fleetAuthenticateEventsExportFleetAuthenticatePeriodsRaw(requestParameters: FleetAuthenticateEventsExportFleetAuthenticatePeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     * Exports all fleet authenticate periods with respect to given filters.
     * Export fleet authenticate periods as login reports
     */
    fleetAuthenticateEventsExportFleetAuthenticatePeriods(requestParameters: FleetAuthenticateEventsExportFleetAuthenticatePeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

    /**
     * Lists all the fleet authenticate periods with both a login and logout event.
     * @summary List completed fleet authenticate periods
     * @param {number} pageSize 
     * @param {Date} [dateStart] 
     * @param {Date} [dateEnd] 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {FleetAuthenticatePeriodSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FleetAuthenticateEventsApiInterface
     */
    fleetAuthenticateEventsListFleetAuthenticatePeriodsRaw(requestParameters: FleetAuthenticateEventsListFleetAuthenticatePeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FleetAuthenticatePeriod>>>;

    /**
     * Lists all the fleet authenticate periods with both a login and logout event.
     * List completed fleet authenticate periods
     */
    fleetAuthenticateEventsListFleetAuthenticatePeriods(requestParameters: FleetAuthenticateEventsListFleetAuthenticatePeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FleetAuthenticatePeriod>>;

    /**
     * Fetch information about the total amount of pages available
     * @summary List completed fleet authenticate periods metadata
     * @param {number} pageSize 
     * @param {Date} [dateStart] 
     * @param {Date} [dateEnd] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FleetAuthenticateEventsApiInterface
     */
    fleetAuthenticateEventsListFleetAuthenticatePeriodsMetadataRaw(requestParameters: FleetAuthenticateEventsListFleetAuthenticatePeriodsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available
     * List completed fleet authenticate periods metadata
     */
    fleetAuthenticateEventsListFleetAuthenticatePeriodsMetadata(requestParameters: FleetAuthenticateEventsListFleetAuthenticatePeriodsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Exports all fleet authenticate periods with respect to given filters.
     * @summary Export fleet authenticate periods as login reports
     * @param {string} timezone 
     * @param {'csv' | 'xlsx'} exportType 
     * @param {number} pageSize 
     * @param {Date} [dateStart] 
     * @param {Date} [dateEnd] 
     * @param {string} [search] 
     * @param {Array<string>} [vehicleIds] 
     * @param {Array<string>} [userIds] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {VehicleMovementPeriodSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FleetAuthenticateEventsApiInterface
     */
    vehicleMovementsExportVehicleMovementPeriodsRaw(requestParameters: VehicleMovementsExportVehicleMovementPeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     * Exports all fleet authenticate periods with respect to given filters.
     * Export fleet authenticate periods as login reports
     */
    vehicleMovementsExportVehicleMovementPeriods(requestParameters: VehicleMovementsExportVehicleMovementPeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

}

/**
 * 
 */
export class FleetAuthenticateEventsApi extends runtime.BaseAPI implements FleetAuthenticateEventsApiInterface {

    /**
     * Create a new fleet_authenticate_event
     * Create a fleet_authenticate_event
     */
    async fleetAuthenticateEventsCreateFleetAuthenticateEventRaw(requestParameters: FleetAuthenticateEventsCreateFleetAuthenticateEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FleetAuthenticateEvent>> {
        if (requestParameters.fleetAuthenticateEventCreate === null || requestParameters.fleetAuthenticateEventCreate === undefined) {
            throw new runtime.RequiredError('fleetAuthenticateEventCreate','Required parameter requestParameters.fleetAuthenticateEventCreate was null or undefined when calling fleetAuthenticateEventsCreateFleetAuthenticateEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fleet_authenticate_events/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FleetAuthenticateEventCreateToJSON(requestParameters.fleetAuthenticateEventCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FleetAuthenticateEventFromJSON(jsonValue));
    }

    /**
     * Create a new fleet_authenticate_event
     * Create a fleet_authenticate_event
     */
    async fleetAuthenticateEventsCreateFleetAuthenticateEvent(requestParameters: FleetAuthenticateEventsCreateFleetAuthenticateEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FleetAuthenticateEvent> {
        const response = await this.fleetAuthenticateEventsCreateFleetAuthenticateEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Exports all fleet authenticate periods with respect to given filters.
     * Export fleet authenticate periods as login reports
     */
    async fleetAuthenticateEventsExportFleetAuthenticatePeriodsRaw(requestParameters: FleetAuthenticateEventsExportFleetAuthenticatePeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.timezone === null || requestParameters.timezone === undefined) {
            throw new runtime.RequiredError('timezone','Required parameter requestParameters.timezone was null or undefined when calling fleetAuthenticateEventsExportFleetAuthenticatePeriods.');
        }

        if (requestParameters.exportType === null || requestParameters.exportType === undefined) {
            throw new runtime.RequiredError('exportType','Required parameter requestParameters.exportType was null or undefined when calling fleetAuthenticateEventsExportFleetAuthenticatePeriods.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling fleetAuthenticateEventsExportFleetAuthenticatePeriods.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateStart !== undefined) {
            queryParameters['date_start'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['date_end'] = (requestParameters.dateEnd as any).toISOString();
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        if (requestParameters.exportType !== undefined) {
            queryParameters['export_type'] = requestParameters.exportType;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fleet_authenticate_events/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Exports all fleet authenticate periods with respect to given filters.
     * Export fleet authenticate periods as login reports
     */
    async fleetAuthenticateEventsExportFleetAuthenticatePeriods(requestParameters: FleetAuthenticateEventsExportFleetAuthenticatePeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.fleetAuthenticateEventsExportFleetAuthenticatePeriodsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists all the fleet authenticate periods with both a login and logout event.
     * List completed fleet authenticate periods
     */
    async fleetAuthenticateEventsListFleetAuthenticatePeriodsRaw(requestParameters: FleetAuthenticateEventsListFleetAuthenticatePeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FleetAuthenticatePeriod>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling fleetAuthenticateEventsListFleetAuthenticatePeriods.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateStart !== undefined) {
            queryParameters['date_start'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['date_end'] = (requestParameters.dateEnd as any).toISOString();
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fleet_authenticate_events/periods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FleetAuthenticatePeriodFromJSON));
    }

    /**
     * Lists all the fleet authenticate periods with both a login and logout event.
     * List completed fleet authenticate periods
     */
    async fleetAuthenticateEventsListFleetAuthenticatePeriods(requestParameters: FleetAuthenticateEventsListFleetAuthenticatePeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FleetAuthenticatePeriod>> {
        const response = await this.fleetAuthenticateEventsListFleetAuthenticatePeriodsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available
     * List completed fleet authenticate periods metadata
     */
    async fleetAuthenticateEventsListFleetAuthenticatePeriodsMetadataRaw(requestParameters: FleetAuthenticateEventsListFleetAuthenticatePeriodsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling fleetAuthenticateEventsListFleetAuthenticatePeriodsMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.dateStart !== undefined) {
            queryParameters['date_start'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['date_end'] = (requestParameters.dateEnd as any).toISOString();
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fleet_authenticate_events/periods/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available
     * List completed fleet authenticate periods metadata
     */
    async fleetAuthenticateEventsListFleetAuthenticatePeriodsMetadata(requestParameters: FleetAuthenticateEventsListFleetAuthenticatePeriodsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.fleetAuthenticateEventsListFleetAuthenticatePeriodsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Exports all fleet authenticate periods with respect to given filters.
     * Export fleet authenticate periods as login reports
     */
    async vehicleMovementsExportVehicleMovementPeriodsRaw(requestParameters: VehicleMovementsExportVehicleMovementPeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.timezone === null || requestParameters.timezone === undefined) {
            throw new runtime.RequiredError('timezone','Required parameter requestParameters.timezone was null or undefined when calling vehicleMovementsExportVehicleMovementPeriods.');
        }

        if (requestParameters.exportType === null || requestParameters.exportType === undefined) {
            throw new runtime.RequiredError('exportType','Required parameter requestParameters.exportType was null or undefined when calling vehicleMovementsExportVehicleMovementPeriods.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling vehicleMovementsExportVehicleMovementPeriods.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateStart !== undefined) {
            queryParameters['date_start'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['date_end'] = (requestParameters.dateEnd as any).toISOString();
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        if (requestParameters.exportType !== undefined) {
            queryParameters['export_type'] = requestParameters.exportType;
        }

        if (requestParameters.vehicleIds) {
            queryParameters['vehicle_ids'] = requestParameters.vehicleIds;
        }

        if (requestParameters.userIds) {
            queryParameters['user_ids'] = requestParameters.userIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_movements/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Exports all fleet authenticate periods with respect to given filters.
     * Export fleet authenticate periods as login reports
     */
    async vehicleMovementsExportVehicleMovementPeriods(requestParameters: VehicleMovementsExportVehicleMovementPeriodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.vehicleMovementsExportVehicleMovementPeriodsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
  * @export
  * @enum {string}
  */
export enum FleetAuthenticateEventsExportFleetAuthenticatePeriodsExportType {
    CSV = 'csv',
    XLSX = 'xlsx'
}
/**
  * @export
  * @enum {string}
  */
export enum VehicleMovementsExportVehicleMovementPeriodsExportType {
    CSV = 'csv',
    XLSX = 'xlsx'
}
