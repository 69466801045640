/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthenticationInfo,
  ErrorResponse,
  LoginToken,
} from '../models';
import {
    AuthenticationInfoFromJSON,
    AuthenticationInfoToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    LoginTokenFromJSON,
    LoginTokenToJSON,
} from '../models';

export interface LoginTokensCreateLoginTokenRequest {
    authenticationInfo: AuthenticationInfo;
}

export interface LoginTokensDeleteLoginTokenRequest {
    loginTokenId: string;
}

export interface LoginTokensReadLoginTokenRequest {
    loginTokenId: string;
}

/**
 * LoginTokensApi - interface
 * 
 * @export
 * @interface LoginTokensApiInterface
 */
export interface LoginTokensApiInterface {
    /**
     * Create a login token by email and password or an NFC token. This is typically the route that one uses in order to log in.
     * @summary Create a login token
     * @param {AuthenticationInfo} authenticationInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginTokensApiInterface
     */
    loginTokensCreateLoginTokenRaw(requestParameters: LoginTokensCreateLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginToken>>;

    /**
     * Create a login token by email and password or an NFC token. This is typically the route that one uses in order to log in.
     * Create a login token
     */
    loginTokensCreateLoginToken(requestParameters: LoginTokensCreateLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginToken>;

    /**
     * Delete the login token with the given ID.
     * @summary Delete a login token
     * @param {string} loginTokenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginTokensApiInterface
     */
    loginTokensDeleteLoginTokenRaw(requestParameters: LoginTokensDeleteLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the login token with the given ID.
     * Delete a login token
     */
    loginTokensDeleteLoginToken(requestParameters: LoginTokensDeleteLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Lists all the login tokens for the current user.
     * @summary List login tokens for the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginTokensApiInterface
     */
    loginTokensListLoginTokensRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LoginToken>>>;

    /**
     * Lists all the login tokens for the current user.
     * List login tokens for the current user.
     */
    loginTokensListLoginTokens(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LoginToken>>;

    /**
     * Returns a single login token specified by the given ID.
     * @summary Get a single login token
     * @param {string} loginTokenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginTokensApiInterface
     */
    loginTokensReadLoginTokenRaw(requestParameters: LoginTokensReadLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginToken>>;

    /**
     * Returns a single login token specified by the given ID.
     * Get a single login token
     */
    loginTokensReadLoginToken(requestParameters: LoginTokensReadLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginToken>;

}

/**
 * 
 */
export class LoginTokensApi extends runtime.BaseAPI implements LoginTokensApiInterface {

    /**
     * Create a login token by email and password or an NFC token. This is typically the route that one uses in order to log in.
     * Create a login token
     */
    async loginTokensCreateLoginTokenRaw(requestParameters: LoginTokensCreateLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginToken>> {
        if (requestParameters.authenticationInfo === null || requestParameters.authenticationInfo === undefined) {
            throw new runtime.RequiredError('authenticationInfo','Required parameter requestParameters.authenticationInfo was null or undefined when calling loginTokensCreateLoginToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/login_tokens/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticationInfoToJSON(requestParameters.authenticationInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginTokenFromJSON(jsonValue));
    }

    /**
     * Create a login token by email and password or an NFC token. This is typically the route that one uses in order to log in.
     * Create a login token
     */
    async loginTokensCreateLoginToken(requestParameters: LoginTokensCreateLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginToken> {
        const response = await this.loginTokensCreateLoginTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the login token with the given ID.
     * Delete a login token
     */
    async loginTokensDeleteLoginTokenRaw(requestParameters: LoginTokensDeleteLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.loginTokenId === null || requestParameters.loginTokenId === undefined) {
            throw new runtime.RequiredError('loginTokenId','Required parameter requestParameters.loginTokenId was null or undefined when calling loginTokensDeleteLoginToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/login_tokens/{login_token_id}`.replace(`{${"login_token_id"}}`, encodeURIComponent(String(requestParameters.loginTokenId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the login token with the given ID.
     * Delete a login token
     */
    async loginTokensDeleteLoginToken(requestParameters: LoginTokensDeleteLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.loginTokensDeleteLoginTokenRaw(requestParameters, initOverrides);
    }

    /**
     * Lists all the login tokens for the current user.
     * List login tokens for the current user.
     */
    async loginTokensListLoginTokensRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LoginToken>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/login_tokens/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LoginTokenFromJSON));
    }

    /**
     * Lists all the login tokens for the current user.
     * List login tokens for the current user.
     */
    async loginTokensListLoginTokens(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LoginToken>> {
        const response = await this.loginTokensListLoginTokensRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns a single login token specified by the given ID.
     * Get a single login token
     */
    async loginTokensReadLoginTokenRaw(requestParameters: LoginTokensReadLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginToken>> {
        if (requestParameters.loginTokenId === null || requestParameters.loginTokenId === undefined) {
            throw new runtime.RequiredError('loginTokenId','Required parameter requestParameters.loginTokenId was null or undefined when calling loginTokensReadLoginToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/login_tokens/{login_token_id}`.replace(`{${"login_token_id"}}`, encodeURIComponent(String(requestParameters.loginTokenId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginTokenFromJSON(jsonValue));
    }

    /**
     * Returns a single login token specified by the given ID.
     * Get a single login token
     */
    async loginTokensReadLoginToken(requestParameters: LoginTokensReadLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginToken> {
        const response = await this.loginTokensReadLoginTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
