import { DefaultButton, Modal, PrimaryButton, Stack, StackItem } from "@fluentui/react";
import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";

export interface ModalConfirmationProps {
    readonly isOpen: boolean;
    readonly text: string;
    readonly title?: string;
    readonly notBlocking?: boolean;
    readonly confirmButtonText?: string;
    readonly cancelButtonText?: string;
    readonly onConfirm: (event: unknown) => void;
    readonly onCancel: () => void;
}

@external
export class ModalConfirmation extends React.Component<ModalConfirmationProps> {
    @inject private readonly i18n!: I18nProvider;
    public render(): JSX.Element {
        return (
            <Modal
                {...this.props}
                isOpen={this.props.isOpen}
                onDismiss={this.props.onCancel}
                isBlocking={!this.props.notBlocking}
            >
                <Stack
                    tokens={{
                        childrenGap: "1em",
                        maxWidth: "300px",
                    }}
                >
                    <StackItem style={{ padding: "0px 20px" }}>
                        <h2>{this.props.title}</h2>
                    </StackItem>

                    <StackItem style={{ padding: "0px 20px" }}>
                        {/* This whiteSpace property transforms line breaks into <br/> tags. This way
                            we can pass in a single text prop and still support multiline. */}
                        <div style={{ whiteSpace: "pre-line" }}>{this.props.text}</div>
                    </StackItem>

                    <Stack
                        horizontal
                        horizontalAlign="end"
                        tokens={{
                            childrenGap: "1em",
                            padding: "20px",
                        }}
                    >
                        <PrimaryButton
                            text={
                                this.props.confirmButtonText ??
                                this.i18n.t("component.modalConfirmation.confirmButton")
                            }
                            onClick={(event: unknown) => {
                                this.props.onConfirm(event);
                            }}
                        />
                        <DefaultButton
                            text={
                                this.props.cancelButtonText ??
                                this.i18n.t("component.modalConfirmation.cancelButton")
                            }
                            onClick={this.props.onCancel}
                        />
                    </Stack>
                </Stack>
            </Modal>
        );
    }
}
