import { action, makeObservable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { RepositorySites } from "../../domain/repositories/repository-sites";
import { UUID } from "../../utils/uuid";
import { ListCommandButtons, ListCommandButtonsProps } from "./list-command-buttons";
import { FormCreateSite } from "../molecules/form-create-site";
import { FormUpdateSite } from "../molecules/form-update-site";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { I18nProvider } from "../../domain/providers/i18n-provider";

export type ListCommandButtonsSitesProps = Omit<
    ListCommandButtonsProps<RepositorySites>,
    "list" | "createForm" | "listState"
>;

@external
@observer
export class ListCommandButtonsSites extends React.Component<ListCommandButtonsSitesProps> {
    @inject private readonly repositorySites!: RepositorySites;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject protected readonly i18n!: I18nProvider;

    constructor(props: ListCommandButtonsSitesProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private async deleteSites(ids: UUID[]): Promise<void> {
        await this.repositorySites.delete(...ids);
        if (this.props.onDelete) {
            this.props.onDelete(ids);
        }
    }
    public render(): JSX.Element {
        return (
            <ListCommandButtons
                updateForm={FormUpdateSite}
                createForm={FormCreateSite}
                listState={this.serviceListStates.sites}
                createButtonText={this.i18n.t("page.systemWide.site.createButton.text")}
                createDialogTitle={this.i18n.t("page.systemWide.site.createDialog.title")}
                updateDialogTitle={this.i18n.t("page.systemWide.site.updateDialog.title")}
                {...this.props}
                onDelete={this.deleteSites}
            />
        );
    }
}
