import * as React from "react";
import { observer } from "mobx-react";
import { Stack, TextField, DefaultButton, Separator, IColumn } from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { UserGroup, VehicleGroupCreate } from "../../api";

import { ServiceListStates } from "../../domain/services/service-list-states";

import { ElofleetDialog } from "../atoms/elofleet-dialog";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import { ModalConfirmation } from "../atoms/modal-confirmation";
import { MultiSelectWithList } from "../organisms/multi-select-with-list";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";

import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { RepositoryVehicleGroups } from "../../domain/repositories/repository-vehicle-groups";
import { RepositoryVehicles } from "../../domain/repositories/repository-vehicles";
import { RepositoryUserGroups } from "../../domain/repositories/repository-user-groups";

import { doubleBindString } from "../../utils/double-bind";
import { createUuid, UUID } from "../../utils/uuid";
import sizes from "../sizes.scss";
import { defaultPageSize } from "../../utils/constants";

export interface FormCreateVehicleGroupProps {
    readonly onDialogCancel: () => void;
    readonly asDialogContent?: boolean;
    readonly isOpen: boolean;
}

@observer
@external
export class FormCreateVehicleGroup extends React.Component<FormCreateVehicleGroupProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;
    @inject private readonly repositoryVehicleGroups!: RepositoryVehicleGroups;
    @inject private readonly repositoryUserGroups!: RepositoryUserGroups;
    @inject private readonly repositoryDepartments!: RepositoryDepartments;

    @observable private cancelConfirmationModalVisible = false;
    @observable private userGroupListVisible = false;

    @observable public label = "";

    // These lists contain the entities that've been added via the multi-select dropdown.
    // I.e. the Vehicles and UserGroups that have been selected for assignments.
    //
    // Once the user sends this form, these entities will be used to create the
    // actual new assignments.
    @observable private addedVehicles: UUID[] = [];
    @observable private addedUserGroups: UUID[] = [];

    // The list state ids for the respective lists of added groups.
    private vehicleListStateId = createUuid();
    private userGroupListStateId = createUuid();

    private validationId = createUuid();

    constructor(props: FormCreateVehicleGroupProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositoryVehicleGroups.validation.create.updateModel(
            this.validationId,
            this.vehicleGroupCreate,
        );
    }

    @initialize protected initialize(): void {
        this.serviceListStates.vehicles.initializeList(this.vehicleListStateId, {
            query: () => {
                return {
                    pageSize: defaultPageSize,
                };
            },
            ignoreUrl: true,
        });
        this.serviceListStates.userGroups.initializeList(this.userGroupListStateId, {
            query: () => {
                return {
                    pageSize: defaultPageSize,
                };
            },
            ignoreUrl: true,
        });

        this.repositoryVehicleGroups.validation.create.initializeModel(
            this.validationId,
            this.vehicleGroupCreate,
        );
    }

    @computed private get vehicleGroupCreate(): VehicleGroupCreate {
        const create = {
            label: this.label,
            vehicleIds: this.addedVehicles,
            userGroupIds: [],
        } as VehicleGroupCreate;

        // Only send any userGroupIds if the list is actually visible.
        if (this.userGroupListVisible) {
            create.userGroupIds = this.addedUserGroups;
        }

        return create;
    }

    /**
     * This is run if the user clicks the form's save button.
     */
    @action.bound private async createVehicleGroup(
        evt: React.SyntheticEvent<HTMLFormElement>,
    ): Promise<void> {
        evt.preventDefault();
        await this.repositoryVehicleGroups.create(this.vehicleGroupCreate);
        this.reset();
        this.triggerValidation();

        this.closeForm();
    }

    @action.bound private reset(): void {
        this.label = "";
        this.addedVehicles = [];
        this.addedUserGroups = [];
    }

    /**
     * This is run if the user clicks the form's cancel button.
     * A confirmation modal might pop up if there are any changes.
     */
    @action.bound private showConfirmAndCloseForm(): void {
        // If there were any changes, i.e. if the original and mutable copy aren't the same,
        // open the confirmation modal for aborting the update process.
        if (this.addedUserGroups.length > 0 || this.addedVehicles.length > 0) {
            this.cancelConfirmationModalVisible = true;
            return;
        }

        this.closeForm();
    }

    @action.bound private toggleUserGroupListVisible(): void {
        this.userGroupListVisible = !this.userGroupListVisible;
    }

    /**
     * Actually close the component and reset all state.
     */
    @action.bound private closeForm(): void {
        this.reset();
        this.cancelConfirmationModalVisible = false;
        this.props.onDialogCancel();
    }

    @action.bound private closeCancelConfirmationModalVisible(): void {
        this.cancelConfirmationModalVisible = false;
    }

    /**
     * Handle the actual addition of all currently selected Vehicles in the multi-select field.
     */
    @action.bound private addVehicles(vehicles: UUID[]): void {
        this.addedVehicles = [...this.addedVehicles, ...vehicles];
    }

    /**
     * Handle the removal of Vehicles.
     * In this case, removal means that they'll be removed from the list of added Vehicles.
     */
    @action.bound private removeVehicles(idsToRemove: UUID[]): void {
        const remainingVehicles = this.addedVehicles.filter((id) => !idsToRemove.includes(id));
        this.addedVehicles = remainingVehicles;
    }

    /**
     * Handle the actual addition of all currently selected UserGroups in the multi-select field.
     */
    @action.bound private addUserGroups(userGroups: UUID[]): void {
        this.addedUserGroups = [...this.addedUserGroups, ...userGroups];
    }

    /**
     * Handle the removal of UserGroups.
     * In this case, removal means that they'll be removed from the list of added UserGroups.
     */
    @action.bound private removeUserGroups(idsToRemove: UUID[]): void {
        const remainingGroups = this.addedUserGroups.filter((id) => !idsToRemove.includes(id));
        this.addedUserGroups = remainingGroups;
    }

    /**
     * The items that should be shown in the list of added UserGroups.
     */
    @computed private get userGroupItems(): Object[] {
        const groups = [];

        for (const id of this.addedUserGroups) {
            const group = this.repositoryUserGroups.byId(id);
            if (group !== undefined) {
                groups.push({
                    key: group.id,
                    label: group.label,
                    assignedUserCount: group.assignedUserCount,
                    assignedVehicleGroupCount: group.assignedVehicleGroupCount,
                });
            }
        }

        return groups;
    }

    /**
     * The columns that should be shown in the list of selected UserGroups.
     */
    @computed private get userGroupColumns(): IColumn[] {
        return [
            {
                fieldName: "label",
                name: this.i18n.t("listUserGroups.column.label.name"),
                key: "label",
                minWidth: 100,
            },
            {
                fieldName: "assignedUserCount",
                name: this.i18n.t("listUserGroups.column.assignedUserCount.name"),
                key: "assignedUserCount",
                minWidth: 80,
            },
            {
                fieldName: "assignedVehicleGroupCount",
                name: this.i18n.t("listUserGroups.column.assignedVehicleGroupCount.name"),
                key: "assignedVehicleGroupCount",
                minWidth: 150,
            },
        ];
    }

    /**
     * The items that should be shown in the list of added Vehicles.
     */
    @computed private get vehicleItems(): Object[] {
        const vehicles = [];

        for (const id of this.addedVehicles) {
            const vehicle = this.repositoryVehicles.byId(id);
            if (vehicle === undefined) {
                continue;
            }

            const department =
                typeof vehicle.departmentId === "string"
                    ? this.repositoryDepartments.byId(vehicle.departmentId)
                    : undefined;
            vehicles.push({
                key: vehicle.id,
                serialNumber: vehicle.serialNumber,
                manufacturer: vehicle.manufacturer,
                model: vehicle.model,
                department: department?.label ?? "",
                vehicleStatus: this.i18n.formatVehicleStatus(vehicle.status),
            });
        }

        return vehicles;
    }

    /**
     * The columns that should be shown in the list of selected Vehicles.
     */
    @computed private get vehicleColumns(): IColumn[] {
        return [
            {
                fieldName: "serialNumber",
                name: this.i18n.t("listVehicles.column.serialNumber.name"),
                key: "serialNumber",
                minWidth: 200,
                maxWidth: 300,
            },
            {
                fieldName: "manufacturer",
                name: this.i18n.t("listVehicles.column.manufacturer.name"),
                key: "manufacturer",
                minWidth: 80,
                maxWidth: 100,
            },
            {
                fieldName: "model",
                name: this.i18n.t("listVehicles.column.model.name"),
                key: "model",
                minWidth: 100,
                maxWidth: 150,
            },
            {
                fieldName: "department",
                name: this.i18n.t("listVehicles.column.department.name"),
                key: "department",
                minWidth: 100,
                maxWidth: 150,
            },
            {
                fieldName: "vehicleStatus",
                name: this.i18n.t("listVehicles.column.vehicleStatus.name"),
                key: "vehicleStatus",
                minWidth: 100,
                maxWidth: 150,
            },
        ];
    }

    public render(): JSX.Element {
        return (
            <ElofleetDialog
                isOpen={this.props.isOpen}
                title={this.i18n.t("formCreateVehicleGroup.title")}
                onDismiss={this.showConfirmAndCloseForm}
            >
                <form onSubmit={this.createVehicleGroup}>
                    <Stack tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                        <TextField
                            label={this.i18n.t("formCreateVehicleGroup.label.label")}
                            {...doubleBindString<FormCreateVehicleGroup>(this, "label", () =>
                                this.triggerValidation(),
                            )}
                            required
                            errorMessage={this.i18n.formatFieldValidationState(
                                this.repositoryVehicleGroups.validation.create.getFieldValidationState(
                                    this.validationId,
                                    "label",
                                ),
                            )}
                        />
                    </Stack>
                    <Stack
                        horizontal
                        styles={{
                            root: {
                                minWidth: "1200px",
                                minHeight: "400px",
                                padding: sizes.xl,
                            },
                        }}
                    >
                        <Stack.Item tokens={{ padding: `0px 0px 0px ${sizes.m}` }}>
                            <MultiSelectWithList
                                repository={this.repositoryVehicles}
                                onAdd={this.addVehicles}
                                onDelete={this.removeVehicles}
                                listStateId={this.vehicleListStateId}
                                listState={this.serviceListStates.vehicles}
                                addedEntities={this.addedVehicles}
                                columns={this.vehicleColumns}
                                items={this.vehicleItems}
                                formatEntity={this.i18n.formatVehicle}
                                addButtonText={this.i18n.t("component.multiSelect.addButton")}
                                removeButtonText={this.i18n.t("component.multiSelect.removeButton")}
                                dropdownLabel={this.i18n.t("formCreateVehicleGroup.vehicle.label")}
                                multiSelectPlaceholder={this.i18n.t(
                                    "formCreateVehicleGroup.vehicle.placeholder",
                                )}
                            />
                        </Stack.Item>
                        {this.userGroupListVisible && (
                            <Stack.Item tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                                <Separator vertical />
                            </Stack.Item>
                        )}
                        {this.userGroupListVisible && (
                            <Stack.Item tokens={{ padding: `0px ${sizes.m} 0px 0px` }}>
                                <MultiSelectWithList
                                    repository={this.repositoryUserGroups}
                                    onAdd={this.addUserGroups}
                                    onDelete={this.removeUserGroups}
                                    listStateId={this.userGroupListStateId}
                                    listState={this.serviceListStates.userGroups}
                                    addedEntities={this.addedUserGroups}
                                    columns={this.userGroupColumns}
                                    items={this.userGroupItems}
                                    formatEntity={(group: UserGroup) => group.label}
                                    addButtonText={this.i18n.t("component.multiSelect.addButton")}
                                    removeButtonText={this.i18n.t(
                                        "component.multiSelect.removeButton",
                                    )}
                                    dropdownLabel={this.i18n.t(
                                        "formCreateVehicleGroup.userGroup.label",
                                    )}
                                    multiSelectPlaceholder={this.i18n.t(
                                        "formCreateVehicleGroup.userGroup.placeholder",
                                    )}
                                />
                            </Stack.Item>
                        )}
                    </Stack>
                    {this.props.asDialogContent ? (
                        <ElofleetDialogFooter style={{ justifyContent: "space-between" }}>
                            <DefaultButton
                                label={this.i18n.t(
                                    "formCreateVehicleGroup.toggleUserGroupList.label",
                                )}
                                text={this.i18n.t(
                                    "formCreateVehicleGroup.toggleUserGroupList.text",
                                )}
                                onClick={this.toggleUserGroupListVisible}
                            />
                            <Stack.Item>
                                <Stack
                                    horizontal
                                    tokens={{
                                        childrenGap: "1em",
                                    }}
                                >
                                    <DefaultButton
                                        label={this.i18n.t("formCreateVehicleGroup.cancel.label")}
                                        text={this.i18n.t("formCreateVehicleGroup.cancel.text")}
                                        onClick={this.showConfirmAndCloseForm}
                                    />
                                    <PrimaryButtonValidation
                                        text={this.i18n.t("formCreateVehicleGroup.submit.text")}
                                        validationId={this.validationId}
                                        validation={this.repositoryVehicleGroups.validation.create}
                                    />
                                </Stack>
                            </Stack.Item>
                        </ElofleetDialogFooter>
                    ) : (
                        <Stack horizontal horizontalAlign="end">
                            <PrimaryButtonValidation
                                text={this.i18n.t("formCreateVehicleGroup.submit.text")}
                                validationId={this.validationId}
                                validation={this.repositoryVehicleGroups.validation.create}
                            />
                        </Stack>
                    )}
                    <ModalConfirmation
                        isOpen={this.cancelConfirmationModalVisible}
                        title={this.i18n.t("modalAbortUpdate.title")}
                        text={this.i18n.t("modalAbortUpdate.description")}
                        onConfirm={this.closeForm}
                        onCancel={this.closeCancelConfirmationModalVisible}
                    />
                </form>
            </ElofleetDialog>
        );
    }
}
