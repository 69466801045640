import { runInAction } from "mobx";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    NfcTokenCreate,
    NfcTokensApi,
    NfcTokensListNfcTokensRequest,
    NfcTokenSortKey,
    NfcTokenUpdate,
    PageInfo,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { Validation } from "../../utils/validation";
import { NfcToken } from "../../api";
import { defaultPageSize } from "../../utils/constants";

export type NfcTokensQuery = Omit<NfcTokensListNfcTokensRequest, "page">;

export interface NfcTokensApiResource extends ApiResource {
    entity: NfcToken;
    query: NfcTokensQuery;
    update: NfcTokenUpdate;
    create: NfcTokenCreate;
    sortKey: NfcTokenSortKey;
}

@component
export class RepositoryNfcTokens extends ElofleetRepository<NfcTokensApiResource> {
    @inject private readonly nfcTokensApi!: NfcTokensApi;

    public validation = {
        create: new Validation((nfcTokenCreate: NfcTokenCreate) =>
            this.nfcTokensApi.nfcTokensValidateCreateNfcToken({
                nfcTokenCreate: nfcTokenCreate,
            }),
        ),
        update: new Validation((nfcTokenUpdate: NfcTokenUpdate, nfcTokenId: UUID) =>
            this.nfcTokensApi.nfcTokensValidateUpdateNfcToken({ nfcTokenUpdate, nfcTokenId }),
        ),
    };

    protected async fetchByQuery(
        query: NfcTokensQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<NfcToken>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.nfcTokensApi.nfcTokensListNfcTokens({
            ...query,
            page,
        });
        return { entities };
    }

    public fetchMetadata(_: NfcTokensQuery): Promise<PageInfo> {
        return this.nfcTokensApi.nfcTokensListNfcTokensMetadata({ pageSize: defaultPageSize });
    }

    protected async fetchById(nfcTokenId: UUID): Promise<NfcToken | undefined> {
        return await this.nfcTokensApi.nfcTokensReadNfcToken({
            nfcTokenId,
        });
    }

    protected extractId = prop("id");

    public async update(nfcTokenId: UUID, nfcTokenUpdate: NfcTokenUpdate): Promise<NfcToken> {
        const nfcToken = await this.wrapApiCall(
            this.nfcTokensApi.nfcTokensUpdateNfcToken({
                nfcTokenId,
                nfcTokenUpdate,
            }),
        );
        await this.waitForIdle();
        runInAction(() => {
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(nfcToken);
        });
        return nfcToken;
    }

    public async create(nfcTokenCreate: NfcTokenCreate): Promise<NfcToken> {
        const nfcToken = await this.wrapApiCall(
            this.nfcTokensApi.nfcTokensCreateNfcToken({
                nfcTokenCreate,
            }),
        );
        await this.waitForIdle();
        runInAction(() => {
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(nfcToken);
        });
        return nfcToken;
    }

    public async delete(...nfcTokenIds: UUID[]): Promise<void> {
        await this.wrapApiCall(
            Promise.all(
                nfcTokenIds.map((nfcTokenId) =>
                    this.nfcTokensApi.nfcTokensDeleteNfcToken({ nfcTokenId }),
                ),
            ),
        );
        await this.waitForIdle();
        this.reloadQuery({ pageSize: defaultPageSize });
    }
}
