/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PageInfo,
  SortDirection,
  VehicleType,
  VehicleTypeCreate,
  VehicleTypeSortKey,
  VehicleTypeUpdate,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    VehicleTypeFromJSON,
    VehicleTypeToJSON,
    VehicleTypeCreateFromJSON,
    VehicleTypeCreateToJSON,
    VehicleTypeSortKeyFromJSON,
    VehicleTypeSortKeyToJSON,
    VehicleTypeUpdateFromJSON,
    VehicleTypeUpdateToJSON,
} from '../models';

export interface VehicleTypesCreateVehicleTypeRequest {
    vehicleTypeCreate: VehicleTypeCreate;
}

export interface VehicleTypesDeleteVehicleTypeRequest {
    id: string;
}

export interface VehicleTypesListVehicleTypesRequest {
    pageSize: number;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: VehicleTypeSortKey;
}

export interface VehicleTypesListVehicleTypesMetadataRequest {
    pageSize: number;
}

export interface VehicleTypesReadVehicleTypeRequest {
    id: string;
}

export interface VehicleTypesUpdateVehicleTypeRequest {
    id: string;
    vehicleTypeUpdate: VehicleTypeUpdate;
}

export interface VehicleTypesValidateCreateVehicleTypeRequest {
    vehicleTypeCreate: VehicleTypeCreate;
}

export interface VehicleTypesValidateUpdateVehicleTypeRequest {
    id: string;
    vehicleTypeUpdate: VehicleTypeUpdate;
}

/**
 * VehicleTypesApi - interface
 * 
 * @export
 * @interface VehicleTypesApiInterface
 */
export interface VehicleTypesApiInterface {
    /**
     * Create a new vehicle type.
     * @summary Create a vehicle type
     * @param {VehicleTypeCreate} vehicleTypeCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTypesApiInterface
     */
    vehicleTypesCreateVehicleTypeRaw(requestParameters: VehicleTypesCreateVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleType>>;

    /**
     * Create a new vehicle type.
     * Create a vehicle type
     */
    vehicleTypesCreateVehicleType(requestParameters: VehicleTypesCreateVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleType>;

    /**
     * Delete the vehicle type with the given ID.
     * @summary Delete a vehicle type
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTypesApiInterface
     */
    vehicleTypesDeleteVehicleTypeRaw(requestParameters: VehicleTypesDeleteVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the vehicle type with the given ID.
     * Delete a vehicle type
     */
    vehicleTypesDeleteVehicleType(requestParameters: VehicleTypesDeleteVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * List all vehicle types.
     * @summary List vehicle types
     * @param {number} pageSize 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {VehicleTypeSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTypesApiInterface
     */
    vehicleTypesListVehicleTypesRaw(requestParameters: VehicleTypesListVehicleTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleType>>>;

    /**
     * List all vehicle types.
     * List vehicle types
     */
    vehicleTypesListVehicleTypes(requestParameters: VehicleTypesListVehicleTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleType>>;

    /**
     * Fetch the total number of pages available.
     * @summary Vehicle type listing metadata
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTypesApiInterface
     */
    vehicleTypesListVehicleTypesMetadataRaw(requestParameters: VehicleTypesListVehicleTypesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch the total number of pages available.
     * Vehicle type listing metadata
     */
    vehicleTypesListVehicleTypesMetadata(requestParameters: VehicleTypesListVehicleTypesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the vehicle type with the given ID.
     * @summary Get a single vehicle type
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTypesApiInterface
     */
    vehicleTypesReadVehicleTypeRaw(requestParameters: VehicleTypesReadVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleType>>;

    /**
     * Returns the vehicle type with the given ID.
     * Get a single vehicle type
     */
    vehicleTypesReadVehicleType(requestParameters: VehicleTypesReadVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleType>;

    /**
     * Update the vehicle type with the given ID.
     * @summary Update a vehicle type
     * @param {string} id 
     * @param {VehicleTypeUpdate} vehicleTypeUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTypesApiInterface
     */
    vehicleTypesUpdateVehicleTypeRaw(requestParameters: VehicleTypesUpdateVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleType>>;

    /**
     * Update the vehicle type with the given ID.
     * Update a vehicle type
     */
    vehicleTypesUpdateVehicleType(requestParameters: VehicleTypesUpdateVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleType>;

    /**
     * Validate data for a new vehicle type.
     * @summary Validate a vehicle type
     * @param {VehicleTypeCreate} vehicleTypeCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTypesApiInterface
     */
    vehicleTypesValidateCreateVehicleTypeRaw(requestParameters: VehicleTypesValidateCreateVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new vehicle type.
     * Validate a vehicle type
     */
    vehicleTypesValidateCreateVehicleType(requestParameters: VehicleTypesValidateCreateVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate data for a vehicle type update.
     * @summary Validate a vehicle type update
     * @param {string} id 
     * @param {VehicleTypeUpdate} vehicleTypeUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTypesApiInterface
     */
    vehicleTypesValidateUpdateVehicleTypeRaw(requestParameters: VehicleTypesValidateUpdateVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a vehicle type update.
     * Validate a vehicle type update
     */
    vehicleTypesValidateUpdateVehicleType(requestParameters: VehicleTypesValidateUpdateVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class VehicleTypesApi extends runtime.BaseAPI implements VehicleTypesApiInterface {

    /**
     * Create a new vehicle type.
     * Create a vehicle type
     */
    async vehicleTypesCreateVehicleTypeRaw(requestParameters: VehicleTypesCreateVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleType>> {
        if (requestParameters.vehicleTypeCreate === null || requestParameters.vehicleTypeCreate === undefined) {
            throw new runtime.RequiredError('vehicleTypeCreate','Required parameter requestParameters.vehicleTypeCreate was null or undefined when calling vehicleTypesCreateVehicleType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_types/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleTypeCreateToJSON(requestParameters.vehicleTypeCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleTypeFromJSON(jsonValue));
    }

    /**
     * Create a new vehicle type.
     * Create a vehicle type
     */
    async vehicleTypesCreateVehicleType(requestParameters: VehicleTypesCreateVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleType> {
        const response = await this.vehicleTypesCreateVehicleTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the vehicle type with the given ID.
     * Delete a vehicle type
     */
    async vehicleTypesDeleteVehicleTypeRaw(requestParameters: VehicleTypesDeleteVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling vehicleTypesDeleteVehicleType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the vehicle type with the given ID.
     * Delete a vehicle type
     */
    async vehicleTypesDeleteVehicleType(requestParameters: VehicleTypesDeleteVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vehicleTypesDeleteVehicleTypeRaw(requestParameters, initOverrides);
    }

    /**
     * List all vehicle types.
     * List vehicle types
     */
    async vehicleTypesListVehicleTypesRaw(requestParameters: VehicleTypesListVehicleTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleType>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling vehicleTypesListVehicleTypes.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_types/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleTypeFromJSON));
    }

    /**
     * List all vehicle types.
     * List vehicle types
     */
    async vehicleTypesListVehicleTypes(requestParameters: VehicleTypesListVehicleTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleType>> {
        const response = await this.vehicleTypesListVehicleTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the total number of pages available.
     * Vehicle type listing metadata
     */
    async vehicleTypesListVehicleTypesMetadataRaw(requestParameters: VehicleTypesListVehicleTypesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling vehicleTypesListVehicleTypesMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_types/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch the total number of pages available.
     * Vehicle type listing metadata
     */
    async vehicleTypesListVehicleTypesMetadata(requestParameters: VehicleTypesListVehicleTypesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.vehicleTypesListVehicleTypesMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the vehicle type with the given ID.
     * Get a single vehicle type
     */
    async vehicleTypesReadVehicleTypeRaw(requestParameters: VehicleTypesReadVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling vehicleTypesReadVehicleType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleTypeFromJSON(jsonValue));
    }

    /**
     * Returns the vehicle type with the given ID.
     * Get a single vehicle type
     */
    async vehicleTypesReadVehicleType(requestParameters: VehicleTypesReadVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleType> {
        const response = await this.vehicleTypesReadVehicleTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the vehicle type with the given ID.
     * Update a vehicle type
     */
    async vehicleTypesUpdateVehicleTypeRaw(requestParameters: VehicleTypesUpdateVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling vehicleTypesUpdateVehicleType.');
        }

        if (requestParameters.vehicleTypeUpdate === null || requestParameters.vehicleTypeUpdate === undefined) {
            throw new runtime.RequiredError('vehicleTypeUpdate','Required parameter requestParameters.vehicleTypeUpdate was null or undefined when calling vehicleTypesUpdateVehicleType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleTypeUpdateToJSON(requestParameters.vehicleTypeUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleTypeFromJSON(jsonValue));
    }

    /**
     * Update the vehicle type with the given ID.
     * Update a vehicle type
     */
    async vehicleTypesUpdateVehicleType(requestParameters: VehicleTypesUpdateVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleType> {
        const response = await this.vehicleTypesUpdateVehicleTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new vehicle type.
     * Validate a vehicle type
     */
    async vehicleTypesValidateCreateVehicleTypeRaw(requestParameters: VehicleTypesValidateCreateVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.vehicleTypeCreate === null || requestParameters.vehicleTypeCreate === undefined) {
            throw new runtime.RequiredError('vehicleTypeCreate','Required parameter requestParameters.vehicleTypeCreate was null or undefined when calling vehicleTypesValidateCreateVehicleType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_types/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleTypeCreateToJSON(requestParameters.vehicleTypeCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new vehicle type.
     * Validate a vehicle type
     */
    async vehicleTypesValidateCreateVehicleType(requestParameters: VehicleTypesValidateCreateVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vehicleTypesValidateCreateVehicleTypeRaw(requestParameters, initOverrides);
    }

    /**
     * Validate data for a vehicle type update.
     * Validate a vehicle type update
     */
    async vehicleTypesValidateUpdateVehicleTypeRaw(requestParameters: VehicleTypesValidateUpdateVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling vehicleTypesValidateUpdateVehicleType.');
        }

        if (requestParameters.vehicleTypeUpdate === null || requestParameters.vehicleTypeUpdate === undefined) {
            throw new runtime.RequiredError('vehicleTypeUpdate','Required parameter requestParameters.vehicleTypeUpdate was null or undefined when calling vehicleTypesValidateUpdateVehicleType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_types/{id}/validate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleTypeUpdateToJSON(requestParameters.vehicleTypeUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a vehicle type update.
     * Validate a vehicle type update
     */
    async vehicleTypesValidateUpdateVehicleType(requestParameters: VehicleTypesValidateUpdateVehicleTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vehicleTypesValidateUpdateVehicleTypeRaw(requestParameters, initOverrides);
    }

}
