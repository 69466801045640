import * as React from "react";
import { external } from "tsdi";
import { DefaultButton, IContextualMenuProps } from "@fluentui/react";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { inject } from "tsdi";
import { UUID } from "../../utils/uuid";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { ElofleetDialog } from "../atoms/elofleet-dialog";
import { FormCreatePreOpsChecklist } from "../molecules/form-create-pre-ops-checklist";
import { FormUpdateSettingPreOps } from "../molecules/form-update-setting-pre-ops";

export interface ListPreOpsChecklistButtonsProps {
    readonly listStateId?: UUID;
}

/**
 * The Checklist page has some special extra list.
 * - Pre-ops Checklist
 * - Pre-ops Checklist Settings
 *
 * This is implemented via a button for pre-ops checklist with a drop-down.
 */
@external
@observer
export class ListPreOpsChecklistButtons extends React.Component<ListPreOpsChecklistButtonsProps> {
    @inject protected readonly i18n!: I18nProvider;

    @observable private settingPreOpsDialogVisible = false;
    @observable private createPreOpsDialogVisible = false;

    constructor(props: ListPreOpsChecklistButtonsProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private closeSettingPreOpsDialog(): void {
        this.settingPreOpsDialogVisible = false;
    }

    @action.bound private openCreatePreOpsDialog(): void {
        this.createPreOpsDialogVisible = true;
    }

    @action.bound private openSettingPreOpsDialog(): void {
        this.settingPreOpsDialogVisible = true;
    }

    @action.bound private closeCreatePreOpsDialogDismiss(): void {
        this.createPreOpsDialogVisible = false;
    }

    public render(): JSX.Element {
        const menuPreOpsProps: IContextualMenuProps = {
            items: [
                {
                    key: "preOps",
                    text: this.i18n.t("listPreOpsChecklist.button.settings"),
                    iconProps: { iconName: "settings" },
                    onClick: this.openSettingPreOpsDialog,
                },
            ],
        };

        return (
            <>
                <DefaultButton
                    text={this.i18n.t("listPreOpsChecklist.button.add")}
                    split
                    primary
                    aria-roledescription="split button"
                    menuProps={menuPreOpsProps}
                    onClick={this.openCreatePreOpsDialog}
                />

                <ElofleetDialog
                    isOpen={this.createPreOpsDialogVisible}
                    title={this.i18n.t("formCreatePreOpsChecklist.title")}
                >
                    {
                        <FormCreatePreOpsChecklist
                            asDialogContent
                            onCreate={this.closeCreatePreOpsDialogDismiss}
                            onDialogCancel={this.closeCreatePreOpsDialogDismiss}
                        />
                    }
                </ElofleetDialog>
                <ElofleetDialog
                    isOpen={this.settingPreOpsDialogVisible}
                    title={this.i18n.t("formUpdateSettingPreOps.dialog.title")}
                >
                    {
                        <FormUpdateSettingPreOps
                            asDialogContent
                            onUpdate={this.closeSettingPreOpsDialog}
                            onDialogCancel={this.closeSettingPreOpsDialog}
                        />
                    }
                </ElofleetDialog>
            </>
        );
    }
}
