import { runInAction, when } from "mobx";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, initialize, inject } from "tsdi";
import {
    PageInfo,
    VehicleGroupCreate,
    VehicleGroupsApi,
    VehicleGroupsListVehicleGroupsRequest,
    VehicleGroupSortKey,
    VehicleGroupUpdate,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { Validation } from "../../utils/validation";
import { VehicleGroup } from "../../api";
import { RepositoryVehicles } from "./repository-vehicles";
import { RepositoryUserGroups } from "./repository-user-groups";
import { ServiceOwnUser } from "../services/service-own-user";
import { defaultPageSize } from "../../utils/constants";

export type VehicleGroupsQuery = Omit<VehicleGroupsListVehicleGroupsRequest, "page">;

export interface VehicleGroupsApiResource extends ApiResource {
    entity: VehicleGroup;
    query: VehicleGroupsQuery;
    update: VehicleGroupUpdate;
    create: VehicleGroupCreate;
    sortKey: VehicleGroupSortKey;
}

@component
export class RepositoryVehicleGroups extends ElofleetRepository<VehicleGroupsApiResource> {
    @inject private readonly serviceOwnUser!: ServiceOwnUser;
    @inject private readonly vehicleGroupsApi!: VehicleGroupsApi;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;
    @inject private readonly repositoryUserGroups!: RepositoryUserGroups;

    public validation = {
        create: new Validation((vehicleGroupCreate: VehicleGroupCreate) =>
            this.vehicleGroupsApi.vehicleGroupsValidateCreateVehicleGroup({
                vehicleGroupCreate: vehicleGroupCreate,
            }),
        ),
        update: new Validation((vehicleGroupUpdate: VehicleGroupUpdate, vehicleGroupId: UUID) =>
            this.vehicleGroupsApi.vehicleGroupsValidateUpdateVehicleGroup({
                vehicleGroupId,
                vehicleGroupUpdate,
            }),
        ),
    };

    @initialize protected initialize(): void {
        // Preload the first page once the user is logged in to avoid loading them individually later.
        when(
            () => this.serviceOwnUser.user !== undefined,
            () =>
                this.byQuery(
                    {
                        pageSize: defaultPageSize,
                    },
                    { count: this.defaultCount },
                ),
        );
    }

    protected async fetchByQuery(
        query: VehicleGroupsQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<VehicleGroup>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.vehicleGroupsApi.vehicleGroupsListVehicleGroups({
            ...query,
            page,
        });
        return { entities };
    }

    public fetchMetadata(_query: unknown): Promise<PageInfo> {
        return this.vehicleGroupsApi.vehicleGroupsListVehicleGroupsMetadata({
            pageSize: defaultPageSize,
        });
    }

    protected async fetchById(vehicleGroupId: UUID): Promise<VehicleGroup | undefined> {
        return await this.vehicleGroupsApi.vehicleGroupsReadVehicleGroup({
            vehicleGroupId,
        });
    }

    protected extractId = prop("id");

    public async update(
        vehicleGroupId: UUID,
        vehicleGroupUpdate: VehicleGroupUpdate,
    ): Promise<VehicleGroup> {
        const vehicleGroup = await this.wrapApiCall(
            this.vehicleGroupsApi.vehicleGroupsUpdateVehicleGroup({
                vehicleGroupId,
                vehicleGroupUpdate,
            }),
        );
        await this.waitForIdle();
        runInAction(() => {
            this.reloadQuery({
                pageSize: defaultPageSize,
            });

            this.add(vehicleGroup);
        });
        return vehicleGroup;
    }

    public async create(vehicleGroupCreate: VehicleGroupCreate): Promise<VehicleGroup> {
        const vehicleGroup = await this.wrapApiCall(
            this.vehicleGroupsApi.vehicleGroupsCreateVehicleGroup({
                vehicleGroupCreate,
            }),
        );
        await this.waitForIdle();
        runInAction(() => {
            this.reloadQuery({
                pageSize: defaultPageSize,
            });
            this.add(vehicleGroup);
        });
        return vehicleGroup;
    }

    public async delete(...vehicleGroupIds: UUID[]): Promise<void> {
        await this.wrapApiCall(
            Promise.all(
                vehicleGroupIds.map((vehicleGroupId) =>
                    this.vehicleGroupsApi.vehicleGroupsDeleteVehicleGroup({ vehicleGroupId }),
                ),
            ),
        );
        await this.waitForIdle();
        this.reloadQuery({
            pageSize: defaultPageSize,
        });
    }
}
