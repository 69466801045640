import { action, makeObservable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { RoleGroups } from "../../api";
import { RepositoryUsers } from "../../domain/repositories/repository-users";
import { UUID } from "../../utils/uuid";
import { ListCommandButtons, ListCommandButtonsProps } from "./list-command-buttons";
import { FormCreateUser } from "../molecules/form-create-user";
import { FormUpdateUser } from "../molecules/form-update-user";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { defaultPageSize } from "../../utils/constants";

export type ListCommandButtonsUsersProps = Omit<
    ListCommandButtonsProps<RepositoryUsers>,
    "list" | "createForm" | "on" | "listState"
> & {
    readonly roleGroup?: RoleGroups;
};

@external
@observer
export class ListCommandButtonsUsers extends React.Component<ListCommandButtonsUsersProps> {
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject protected readonly i18n!: I18nProvider;

    constructor(props: ListCommandButtonsUsersProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private async deleteUsers(ids: UUID[]): Promise<void> {
        await this.repositoryUsers.delete(...ids);
        if (this.props.onDelete) {
            this.props.onDelete(ids);
        }
    }

    @action.bound private reloadUsers(): void {
        this.repositoryUsers.reloadQuery({
            pageSize: defaultPageSize,
            roleGroup: this.props.roleGroup,
        });
    }

    public render(): JSX.Element {
        return (
            <ListCommandButtons
                updateForm={FormUpdateUser}
                createForm={FormCreateUser}
                listState={this.serviceListStates.users}
                createButtonText={this.i18n.t("listUsers.button.add")}
                createDialogTitle={this.i18n.t("listUsers.createDialog.title")}
                updateDialogTitle={this.i18n.t("listUsers.updateDialog.title")}
                {...this.props}
                onDelete={this.deleteUsers}
                onUpdate={this.reloadUsers}
                onCreate={this.reloadUsers}
                dialogMaxWidth="800px"
            />
        );
    }
}
