import * as React from "react";
import { equals } from "ramda";
import { observer } from "mobx-react";
import {
    Stack,
    DefaultButton,
    TextField,
    Toggle,
    DatePicker,
    Separator,
    Dropdown,
    IDropdownOption,
} from "@fluentui/react";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { ConnectedComboBox } from "../atoms/connected-combo-box";
import { RepositoryVehicles } from "../../domain/repositories/repository-vehicles";
import {
    Department,
    EngineType,
    ManagementRole,
    ProcurementType,
    ShockProfile,
    User,
    Vehicle,
    VehicleDevice,
    VehicleDeviceStatus,
    VehicleType,
} from "../../api";
import { createUuid, UUID } from "../../utils/uuid";
import { VehicleUpdate } from "../../api";
import { FormUpdateProps } from "../../utils/form-update-props";
import {
    doubleBindIntegerToString,
    doubleBindNullableString,
    doubleBindNullableStringEmptyAsNull,
    doubleBindString,
} from "../../utils/double-bind";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { RepositoryShockProfiles } from "../../domain/repositories/repository-shock-profiles";
import { ModalConfirmation } from "../atoms/modal-confirmation";
import { RepositoryVehicleDevices } from "../../domain/repositories/repository-vehicle-devices";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";
import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { ServiceStubData } from "../../domain/services/service-stub-data";
import { RepositoryUsers } from "../../domain/repositories/repository-users";
import { RepositoryPreOpsChecklists } from "../../domain/repositories/repository-pre-ops-checklists";
import { ServiceOwnUser } from "../../domain/services/service-own-user";
import { mapSome } from "../../utils/functions";
import { defaultPageSize } from "../../utils/constants";
import { RepositoryVehicleTypes } from "../../domain/repositories/repository-vehicle-types";
import { ClearableDropdown } from "../atoms/clearable-dropdown";

export interface FormUpdateVehicleProps extends FormUpdateProps<Vehicle> {}

@observer
@external
export class FormUpdateVehicle extends React.Component<FormUpdateVehicleProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;
    @inject private readonly repositoryVehicleDevices!: RepositoryVehicleDevices;
    @inject private readonly repositoryVehicleTypes!: RepositoryVehicleTypes;
    @inject private readonly repositoryDepartments!: RepositoryDepartments;
    @inject private readonly repositoryShockProfiles!: RepositoryShockProfiles;
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly repositoryPreOpsChecklists!: RepositoryPreOpsChecklists;
    @inject private readonly serviceOwnUser!: ServiceOwnUser;
    @inject private readonly serviceStubData!: ServiceStubData;

    @observable private cancelConfirmationModalVisible = false;
    @observable public lastVehicleDeviceId?: UUID | null;
    @observable public newVehicleDeviceId?: UUID | null;
    @observable private lastDriver: User | undefined;

    private validationId = createUuid();

    constructor(props: FormUpdateVehicleProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositoryVehicles.validation.update.updateModel(
            this.validationId,
            this.vehicleUpdate,
        );
    }

    @initialize protected initialize(): void {
        // Don't initialize if we don't have props yet.
        // This will otherwise fail.
        if (!this.props) {
            return;
        }
        this.repositoryVehicles.validation.update.initializeModel(
            this.validationId,
            this.vehicleUpdate,
            this.props.id,
        );

        this.repositoryUsers
            .getLastDriverByVehicle(this.vehicle!.id)
            .then((user) => runInAction(() => (this.lastDriver = user)));

        this.getVehicleDeviceId();
    }

    @action.bound private getVehicleDeviceId(): void {
        const vehicleDevice = this.repositoryVehicleDevices.byVehicleId(this.props.id);
        this.lastVehicleDeviceId = vehicleDevice?.id;
        this.newVehicleDeviceId = vehicleDevice?.id;
    }

    public componentWillUnmount(): void {
        this.repositoryVehicles.discardMutableCopy(FormUpdateVehicle.name, this.props.id);
    }

    @computed private get vehicle(): Vehicle | undefined {
        return this.repositoryVehicles.mutableCopyById(FormUpdateVehicle.name, this.props.id);
    }

    @computed private get vehicleUpdate(): VehicleUpdate {
        return this.vehicle!;
    }

    /**
     * Return the hours since the last vehicle maintenance.
     * TODO: This uses the stub service for now, until we get real data.
     */
    @computed private get getHoursSinceLastMaintenance(): string {
        const hours = this.serviceStubData.hoursSinceLastMaintenance(this.vehicle);

        return hours ? String(hours) : "";
    }

    @computed private get daysUntilLeaseTermEnd(): string {
        const leaseTermEnd = this.vehicle?.leaseTermEnd;
        if (!leaseTermEnd) {
            return "";
        }
        const difference = Math.max(0, leaseTermEnd.getTime() - new Date().getTime());
        const millisecondsPerDay = 1000 * 60 * 60 * 24;
        const days = Math.floor(difference / millisecondsPerDay);
        return String(days);
    }

    @action.bound
    private async updateVehicle(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const vehicle = await this.repositoryVehicles.update(this.props.id, this.vehicleUpdate);
        if (this.lastVehicleDeviceId != this.newVehicleDeviceId) {
            if (this.lastVehicleDeviceId != null)
                await this.repositoryVehicleDevices.update(this.lastVehicleDeviceId, {
                    vehicleId: null,
                });
            if (this.newVehicleDeviceId != null)
                await this.repositoryVehicleDevices.update(this.newVehicleDeviceId, {
                    vehicleId: vehicle.id,
                });
            await this.repositoryVehicleDevices.reloadQuery({
                pageSize: defaultPageSize,
                vehicleId: vehicle.id,
            });
            await this.repositoryVehicleDevices.reloadQuery({
                pageSize: defaultPageSize,
                status: VehicleDeviceStatus.NOT_ASSIGNED,
            });
        }

        this.props.onUpdate(vehicle);
    }

    @action.bound private setShockProfile(shockProfileId?: UUID): void {
        if (!this.vehicle) {
            throw new Error("Can't update shock profile id: Vehicle not loaded.");
        }
        this.vehicle.shockProfileId = shockProfileId ?? null;
        this.triggerValidation();
    }

    @action.bound private setVehicleDevice(vehicleDeviceId?: UUID): void {
        this.newVehicleDeviceId = vehicleDeviceId;
        this.triggerValidation();
    }

    @action.bound private setVehicleType(vehicleTypeId?: UUID): void {
        if (this.vehicle === undefined) {
            throw new Error("Can't update vehicle type: Vehicle not loaded.");
        }
        this.vehicle.vehicleTypeId = vehicleTypeId ?? null;
        this.triggerValidation();
    }

    @action.bound private setDepartment(departmentId?: UUID): void {
        if (this.vehicle === undefined) {
            throw new Error("Can't update department: Vehicle not loaded.");
        }
        this.vehicle.departmentId = departmentId ?? null;
        this.triggerValidation();
    }

    @action.bound private setChecklistOverride(_event: unknown, flag: boolean | undefined): void {
        if (!this.vehicle) {
            throw new Error("Can't update checklistOverride: Vehicle not loaded.");
        }
        this.vehicle.checklistOverride = flag ?? false;
        this.triggerValidation();
    }

    @action.bound private setMaintenanceLockdown(_event: unknown, flag: boolean | undefined): void {
        if (!this.vehicle) {
            throw new Error("Can't update maintenanceLockdown: Vehicle not loaded.");
        }
        this.vehicle.maintenanceLockdown = flag ?? false;
        this.triggerValidation();
    }

    @action.bound private setManufactureYear(
        _event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        _index?: number,
        _value?: string,
    ): void {
        option == undefined
            ? (this.vehicle!.manufactureYear = null)
            : (this.vehicle!.manufactureYear = option?.key as number);
        this.triggerValidation();
    }

    @action.bound private setPreOpsChecklist(preOpsChecklistId?: UUID): void {
        if (this.vehicle === undefined) {
            throw new Error("Can't update pre ops checklist: Vehicle not loaded.");
        }
        this.vehicle.preOpsChecklistId = preOpsChecklistId ?? null;
        this.triggerValidation();
    }
    /**
     * This is run, if the user clicks the form's cancel button.
     * A confirmation modal might pop up, if the user did any changes.
     */
    @action.bound private showConfirmAndCallDialogCancelCallback(): void {
        // If there were any changes, i.e. if the original and mutable copy aren't the same,
        // open the confirmation modal for aborting the update process.
        const original = this.repositoryVehicles.byId(this.props.id);
        if (!equals(original, this.vehicle)) {
            this.cancelConfirmationModalVisible = true;
            return;
        }

        this.props.onDialogCancel();
    }

    @action.bound private setPhoneImei(
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string | undefined,
    ): void {
        if (!this.vehicle) {
            throw new Error("Can't update phoneImei: Vehicle not loaded.");
        }
        this.vehicle.phoneImei = newValue == "" ? null : newValue;
        this.triggerValidation();
    }

    @action.bound private setIdleAutoLogoutAfter(
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string | undefined,
    ): void {
        if (newValue == undefined) return;
        if (!this.vehicle) {
            throw new Error("Can't update idleAutoLogoutAfter: Vehicle not loaded.");
        }
        this.vehicle.idleAutoLogoutAfter = newValue == "" ? null : parseInt(newValue);
        this.triggerValidation();
    }

    @action.bound private setMaintenanceNotificationAt(
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string | undefined,
    ): void {
        if (newValue == undefined) return;
        if (!this.vehicle) {
            throw new Error("Can't update maintenanceNotificationAt: Vehicle not loaded.");
        }
        this.vehicle.maintenanceNotificationAt = newValue == "" ? null : parseInt(newValue);
        this.triggerValidation();
    }

    @action.bound private setProcurementType(key?: string): void {
        if (!this.vehicle) {
            throw new Error("Can't update procurementType: Vehicle not loaded.");
        }
        this.vehicle.procurementType =
            ProcurementType[String(key).toUpperCase() as keyof typeof ProcurementType] || null;
        this.triggerValidation();
    }

    @action.bound private setPurchaseDate(date: Date | null | undefined): void {
        if (!this.vehicle) {
            throw new Error("Can't update purchaseDate: Vehicle not loaded.");
        }
        this.vehicle.purchaseDate = date ?? undefined;
        this.triggerValidation();
    }

    @action.bound private setLeaseTermBegin(date: Date | null | undefined): void {
        if (!this.vehicle) {
            throw new Error("Can't update leaseTermBegin: Vehicle not loaded.");
        }
        this.vehicle.leaseTermBegin = date ?? undefined;
        this.triggerValidation();
    }

    @action.bound private setLeaseTermEnd(date: Date | null | undefined): void {
        if (!this.vehicle) {
            throw new Error("Can't update leaseTermEnd: Vehicle not loaded.");
        }
        this.vehicle.leaseTermEnd = date ?? undefined;
        this.triggerValidation();
    }

    @action.bound private setEngineType(
        _event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        _index?: number,
    ): void {
        if (!this.vehicle) {
            throw new Error("Can't update engineType: Vehicle not loaded.");
        }
        if (!option) {
            this.vehicle.engineType = EngineType.ELECTRIC;
            this.triggerValidation();
            return;
        }

        this.vehicle.engineType =
            EngineType[String(option.key).toUpperCase() as keyof typeof EngineType] ||
            EngineType.ELECTRIC;
        this.triggerValidation();
    }

    @action.bound private closeCancelConfirmationModalVisible(): void {
        this.cancelConfirmationModalVisible = false;
    }

    public get yearOptions(): IDropdownOption[] {
        const options: IDropdownOption[] = [];
        const currentYear = new Date().getFullYear();
        for (let i = currentYear; i > 1924; i--) {
            options.push({ key: i, text: i.toString() });
        }
        return options;
    }

    public onFormatDate = (date?: Date): string => {
        return this.i18n.formatDateOnly(date!);
    };

    public formatHoursUntilNextMaintenance(hour: number | undefined): string {
        if (hour === undefined) {
            return "";
        }

        return `${this.i18n.formatHours(Math.abs(hour))} ${
            hour <= 0 ? this.i18n.t("formUpdateVehicle.overdue") : ""
        }`;
    }

    public renderGeneralSection(): JSX.Element {
        const engineTypeOptions: IDropdownOption[] = [
            {
                key: "electric",
                text: this.i18n.t("formCreateVehicle.engineTypeOptions.electric"),
            },
            {
                key: "combustion",
                text: this.i18n.t("formCreateVehicle.engineTypeOptions.combustion"),
            },
        ];
        return (
            <Stack
                horizontal
                horizontalAlign="space-between"
                tokens={{
                    childrenGap: "2em",
                }}
            >
                <Stack>
                    <TextField
                        label={this.i18n.t("formUpdateVehicle.id.label")}
                        {...doubleBindString(this.vehicle!, "serialNumber", () =>
                            this.triggerValidation(),
                        )}
                        required
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "serialNumber",
                            ),
                        )}
                    />
                    <TextField
                        label={this.i18n.t("formUpdateVehicle.manufacturer.label")}
                        {...doubleBindNullableStringEmptyAsNull(this.vehicle!, "manufacturer", () =>
                            this.triggerValidation(),
                        )}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "manufacturer",
                            ),
                        )}
                    />
                    <TextField
                        label={this.i18n.t("formUpdateVehicle.model.label")}
                        {...doubleBindNullableStringEmptyAsNull(this.vehicle!, "model", () =>
                            this.triggerValidation(),
                        )}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "model",
                            ),
                        )}
                    />
                    <ConnectedComboBox
                        formatEntity={(vehicleType: VehicleType) =>
                            this.i18n.formatVehicleType(vehicleType)
                        }
                        repository={this.repositoryVehicleTypes}
                        query={{ pageSize: defaultPageSize }}
                        label={this.i18n.t("formUpdateUser.vehicleType.label")}
                        onChange={this.setVehicleType}
                        clearable
                        selectedKey={this.vehicle?.vehicleTypeId}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "vehicleTypeId",
                            ),
                        )}
                    />
                    <Dropdown
                        label={this.i18n.t("formUpdateVehicle.yearOfBuild.label")}
                        selectedKey={this.vehicle?.manufactureYear}
                        onChange={this.setManufactureYear}
                        options={this.yearOptions}
                        calloutProps={{ directionalHintFixed: true }}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "manufactureYear",
                            ),
                        )}
                    />
                    <ConnectedComboBox
                        formatEntity={(department: Department) =>
                            this.i18n.formatDepartment(department)
                        }
                        repository={this.repositoryDepartments}
                        query={{ pageSize: defaultPageSize }}
                        label={this.i18n.t("formUpdateUser.department.label")}
                        onChange={this.setDepartment}
                        clearable
                        selectedKey={this.vehicle?.departmentId}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "departmentId",
                            ),
                        )}
                    />
                    <ConnectedComboBox
                        formatEntity={this.i18n.formatPreOpsChecklist}
                        repository={this.repositoryPreOpsChecklists}
                        query={{ pageSize: defaultPageSize }}
                        label={this.i18n.t("formUpdateVehicle.preOpsChecklist.label")}
                        onChange={this.setPreOpsChecklist}
                        clearable
                        selectedKey={this.vehicle?.preOpsChecklistId}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "preOpsChecklistId",
                            ),
                        )}
                    />
                </Stack>
                <Stack>
                    <TextField label={this.i18n.t("formUpdateVehicle.group.label")} />
                    <Toggle
                        label={this.i18n.t("formUpdateVehicle.checklistOverride.label")}
                        checked={this.vehicle?.checklistOverride}
                        onChange={this.setChecklistOverride}
                    />
                    <ConnectedComboBox
                        formatEntity={(shockProfile: ShockProfile) => shockProfile.label}
                        repository={this.repositoryShockProfiles}
                        query={{ pageSize: defaultPageSize }}
                        label={this.i18n.t("formUpdateVehicle.shockProfile.label")}
                        onChange={this.setShockProfile}
                        clearable
                        selectedKey={this.vehicle?.shockProfileId}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "shockProfileId",
                            ),
                        )}
                    />
                    <TextField
                        disabled={
                            this.serviceOwnUser.user?.managementRole != ManagementRole.SUPER_ADMIN
                        }
                        label={this.i18n.t("formUpdateVehicle.magneticFieldThreshold.label")}
                        type="number"
                        {...doubleBindIntegerToString(this.vehicle!, "magneticFieldThreshold", () =>
                            this.triggerValidation(),
                        )}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "magneticFieldThreshold",
                            ),
                        )}
                    />
                    <TextField
                        type="number"
                        label={this.i18n.t("formUpdateVehicle.idleAutoLogoutAfter.label")}
                        {...doubleBindIntegerToString(this.vehicle!, "idleAutoLogoutAfter", () =>
                            this.triggerValidation(),
                        )}
                        onChange={this.setIdleAutoLogoutAfter}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "idleAutoLogoutAfter",
                            ),
                        )}
                    />

                    <Dropdown
                        label={this.i18n.t("formUpdateVehicle.engineTypeOptions.label")}
                        options={engineTypeOptions}
                        selectedKey={this.vehicle === undefined ? null : this.vehicle.engineType}
                        onChange={this.setEngineType}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "engineType",
                            ),
                        )}
                    />
                </Stack>
            </Stack>
        );
    }

    public renderMaintenanceSection(): JSX.Element {
        const procurementOptions: IDropdownOption[] = [
            {
                key: "purchase",
                text: this.i18n.t("formUpdateVehicle.procurementOptions.purchase"),
            },
            {
                key: "leasing",
                text: this.i18n.t("formUpdateVehicle.procurementOptions.leasing"),
            },
        ];

        return (
            <Stack
                horizontal
                horizontalAlign="space-between"
                tokens={{
                    childrenGap: "2em",
                }}
            >
                <Stack>
                    <TextField
                        type="number"
                        label={this.i18n.t("formUpdateVehicle.initialOperatingHours.label")}
                        {...doubleBindIntegerToString(this.vehicle!, "initialOperatingHours", () =>
                            this.triggerValidation(),
                        )}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "initialOperatingHours",
                            ),
                        )}
                    />
                    <DatePicker
                        disabled={this.vehicle?.procurementType !== ProcurementType.LEASING}
                        isRequired={this.vehicle?.procurementType === ProcurementType.LEASING}
                        formatDate={this.onFormatDate}
                        maxDate={
                            this.vehicle && this.vehicle.leaseTermEnd
                                ? this.vehicle.leaseTermEnd
                                : undefined
                        }
                        placeholder={this.i18n.t("formUpdateVehicle.datePicker.placeholder")}
                        label={this.i18n.t("formUpdateVehicle.leaseTermBegin.label")}
                        value={this.vehicle?.leaseTermBegin || undefined}
                        onSelectDate={this.setLeaseTermBegin}
                        strings={this.i18n.datePickerStrings(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "leaseTermBegin",
                            ),
                        )}
                    />
                    <DatePicker
                        disabled
                        formatDate={this.onFormatDate}
                        placeholder={this.i18n.t("formUpdateVehicle.datePicker.placeholder")}
                        label={this.i18n.t("formUpdateVehicle.lastMaintenance.label")}
                        value={this.vehicle?.lastMaintenanceDate || undefined}
                    />
                    <TextField
                        type="number"
                        label={this.i18n.t("formUpdateVehicle.maintenanceNotificationAt.label")}
                        {...doubleBindIntegerToString(
                            this.vehicle!,
                            "maintenanceNotificationAt",
                            () => this.triggerValidation(),
                        )}
                        onChange={this.setMaintenanceNotificationAt}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "maintenanceNotificationAt",
                            ),
                        )}
                    />
                    <TextField
                        type="number"
                        label={this.i18n.t("formUpdateVehicle.weeklyDriveTimeGoal.label")}
                        {...doubleBindIntegerToString(this.vehicle!, "weeklyDriveGoalHours", () =>
                            this.triggerValidation(),
                        )}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "weeklyDriveGoalHours",
                            ),
                        )}
                    />
                </Stack>
                <Stack>
                    <ClearableDropdown
                        label={this.i18n.t("formUpdateVehicle.procurementOptions.label")}
                        onChange={this.setProcurementType}
                        options={procurementOptions}
                        selectedKey={this.vehicle?.procurementType}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "procurementType",
                            ),
                        )}
                    />
                    <DatePicker
                        disabled={this.vehicle?.procurementType !== ProcurementType.LEASING}
                        isRequired={this.vehicle?.procurementType === ProcurementType.LEASING}
                        formatDate={this.onFormatDate}
                        minDate={
                            this.vehicle && this.vehicle.leaseTermBegin
                                ? this.vehicle.leaseTermBegin
                                : undefined
                        }
                        placeholder={this.i18n.t("formUpdateVehicle.datePicker.placeholder")}
                        label={this.i18n.t("formUpdateVehicle.leaseTermEnd.label")}
                        value={this.vehicle?.leaseTermEnd || undefined}
                        onSelectDate={this.setLeaseTermEnd}
                        strings={this.i18n.datePickerStrings(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "leaseTermEnd",
                            ),
                        )}
                    />
                    <TextField
                        label={this.i18n.t("formUpdateVehicle.operatingHours.label")}
                        readOnly
                        value={
                            this.vehicle
                                ? this.i18n.formatFloat(
                                      this.vehicle.totalOperatingSeconds / 3600,
                                      2,
                                  )
                                : ""
                        }
                    />
                    <TextField
                        label={this.i18n.t("formUpdateVehicle.hoursSinceLastMaintenance.label")}
                        readOnly
                        value={this.vehicle?.lastMaintenanceOperatingHours?.toString() || undefined}
                    />
                    <Toggle
                        label={this.i18n.t("formUpdateVehicle.maintenanceLockdown.label")}
                        checked={this.vehicle?.maintenanceLockdown}
                        onChange={this.setMaintenanceLockdown}
                    />
                </Stack>
                <Stack>
                    <DatePicker
                        disabled={this.vehicle?.procurementType !== ProcurementType.PURCHASE}
                        isRequired={this.vehicle?.procurementType === ProcurementType.PURCHASE}
                        formatDate={this.onFormatDate}
                        placeholder={this.i18n.t("formUpdateVehicle.datePicker.placeholder")}
                        label={this.i18n.t("formUpdateVehicle.purchaseDate.label")}
                        value={this.vehicle?.purchaseDate || undefined}
                        onSelectDate={this.setPurchaseDate}
                    />
                    <TextField
                        disabled
                        label={this.i18n.t("formUpdateVehicle.daysUntilLeaseTermEnd.label")}
                        readOnly
                        value={this.daysUntilLeaseTermEnd}
                    />
                    <TextField
                        type="number"
                        label={this.i18n.t("formUpdateVehicle.maintenanceInterval.label")}
                        {...doubleBindIntegerToString(this.vehicle!, "maintenanceInterval", () =>
                            this.triggerValidation(),
                        )}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "maintenanceInterval",
                            ),
                        )}
                    />
                    <TextField
                        disabled
                        label={this.i18n.t("formUpdateVehicle.hoursUntilMaintenance.label")}
                        readOnly
                        value={this.formatHoursUntilNextMaintenance(
                            this.vehicle?.hoursUntilNextMaintenance,
                        )}
                    />
                </Stack>
            </Stack>
        );
    }

    public renderMiscellaneousSection(): JSX.Element {
        return (
            <Stack
                horizontal
                horizontalAlign="space-between"
                tokens={{
                    childrenGap: "2em",
                }}
            >
                <Stack>
                    <TextField
                        disabled
                        label={this.i18n.t("formUpdateVehicle.currentStatus.label")}
                        value={mapSome(
                            (status) => this.i18n.t(`formUpdateVehicle.statusOptions.${status}`),
                            this.vehicle?.status,
                        )}
                    />
                    <TextField
                        disabled
                        label={this.i18n.t("formUpdateVehicle.lastLocation.label")}
                    />
                </Stack>
                <Stack>
                    <TextField
                        disabled
                        label={this.i18n.t("formUpdateVehicle.lastDriver.label")}
                        readOnly
                        value={this.vehicle && this.i18n.formatUserFullName(this.lastDriver)}
                    />
                    <TextField
                        disabled={
                            this.serviceOwnUser.user?.managementRole != ManagementRole.SUPER_ADMIN
                        }
                        label={this.i18n.t("formUpdateVehicle.phoneImei.label")}
                        {...doubleBindNullableString(this.vehicle!, "phoneImei", () =>
                            this.triggerValidation(),
                        )}
                        onChange={this.setPhoneImei}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.update.getFieldValidationState(
                                this.validationId,
                                "phoneImei",
                            ),
                        )}
                    />
                </Stack>
                <Stack>
                    <TextField
                        disabled
                        label={this.i18n.t("formUpdateVehicle.lastSynchronization.label")}
                        readOnly
                        value={
                            (this.vehicle?.lastSynchronizedAt &&
                                this.i18n.formatDateTime(this.vehicle.lastSynchronizedAt)) ??
                            ""
                        }
                    />
                    <ConnectedComboBox
                        disabled={
                            this.serviceOwnUser.user?.managementRole != ManagementRole.SUPER_ADMIN
                        }
                        label={this.i18n.t("formUpdateVehicle.vehicleDevice.label")}
                        formatEntity={(vehicleDevice: VehicleDevice) => vehicleDevice.serialNumber}
                        repository={this.repositoryVehicleDevices}
                        query={{
                            pageSize: defaultPageSize,
                            status: VehicleDeviceStatus.NOT_ASSIGNED,
                        }}
                        onChange={this.setVehicleDevice}
                        clearable
                        selectedKey={this.newVehicleDeviceId}
                    />
                </Stack>
            </Stack>
        );
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formUpdateVehicle.submit.text")}
                validation={this.repositoryVehicles.validation.update}
                validationId={this.validationId}
            />
        );
        return (
            <form onSubmit={this.updateVehicle}>
                <Stack
                    horizontal
                    horizontalAlign="space-between"
                    tokens={{
                        childrenGap: "1em",
                        padding: `0px ${sizes.formPaddingHorizontal}`,
                    }}
                >
                    <Stack>
                        <h2>{this.i18n.t("formUpdateVehicle.title.general")}</h2>
                        {this.renderGeneralSection()}
                    </Stack>
                    <Separator vertical />
                    <Stack>
                        <h2>{this.i18n.t("formUpdateVehicle.title.maintenance")}</h2>
                        {this.renderMaintenanceSection()}
                        <Separator />
                        <h2>{this.i18n.t("formUpdateVehicle.title.miscellaneous")}</h2>
                        {this.renderMiscellaneousSection()}
                    </Stack>
                </Stack>
                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            label={this.i18n.t("formUpdateVehicle.cancel.label")}
                            text={this.i18n.t("formUpdateVehicle.cancel.text")}
                            onClick={this.showConfirmAndCallDialogCancelCallback}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
                <ModalConfirmation
                    isOpen={this.cancelConfirmationModalVisible}
                    title={this.i18n.t("modalAbortUpdate.title")}
                    text={this.i18n.t("modalAbortUpdate.description")}
                    onConfirm={this.props.onDialogCancel}
                    onCancel={this.closeCancelConfirmationModalVisible}
                />
            </form>
        );
    }
}
