import * as React from "react";
import { pick } from "ramda";
import { observer } from "mobx-react";
import { Stack, TextField, DefaultButton, Checkbox, Label } from "@fluentui/react";
import { action, computed, makeObservable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositorySafetyMessages } from "../../domain/repositories/repository-safety-messages";
import { SafetyMessageUpdate } from "../../api";
import { doubleBindString } from "../../utils/double-bind";
import { SafetyMessage } from "../../api";
import { createUuid } from "../../utils/uuid";
import { FormUpdateProps } from "../../utils/form-update-props";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";

export interface FormUpdateSafetyMessageProps extends FormUpdateProps<SafetyMessage> {}

@observer
@external
export class FormUpdateSafetyMessage extends React.Component<FormUpdateSafetyMessageProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositorySafetyMessages!: RepositorySafetyMessages;

    private validationId = createUuid();

    constructor(props: FormUpdateSafetyMessageProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositorySafetyMessages.validation.update.updateModel(
            this.validationId,
            this.safetyMessageUpdate,
        );
    }

    @initialize protected initialize(): void {
        this.repositorySafetyMessages.validation.update.initializeModel(
            this.validationId,
            this.safetyMessageUpdate,
            this.props.id,
        );
    }

    public componentWillUnmount(): void {
        this.repositorySafetyMessages.discardMutableCopy(
            FormUpdateSafetyMessage.name,
            this.props.id,
        );
    }

    @computed private get safetyMessage(): SafetyMessage | undefined {
        return this.repositorySafetyMessages.mutableCopyById(
            FormUpdateSafetyMessage.name,
            this.props.id,
        );
    }

    @computed private get safetyMessageUpdate(): SafetyMessageUpdate {
        return pick(["body", "active"], this.safetyMessage);
    }

    @action.bound
    private async updateSafetyMessage(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const safetyMessage = await this.repositorySafetyMessages.update(
            this.props.id,
            this.safetyMessageUpdate,
        );
        this.props.onUpdate(safetyMessage);
    }

    @action.bound private changeActive(
        _event?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean,
    ): void {
        this.safetyMessage!.active = checked!;
        this.triggerValidation();
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formUpdateSafetyMessage.submit.text")}
                validation={this.repositorySafetyMessages.validation.update}
                validationId={this.validationId}
            />
        );
        return (
            <form onSubmit={this.updateSafetyMessage}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    styles={{
                        root: {
                            minWidth: "600px",
                        },
                    }}
                >
                    <Stack.Item tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                        <TextField
                            label={this.i18n.t("formUpdateSafetyMessage.body.label")}
                            disabled={!this.safetyMessage}
                            {...doubleBindString(this.safetyMessage!, "body", () =>
                                this.triggerValidation(),
                            )}
                            required
                            errorMessage={this.i18n.formatFieldValidationState(
                                this.repositorySafetyMessages.validation.update.getFieldValidationState(
                                    this.validationId,
                                    "body",
                                ),
                            )}
                            styles={{
                                root: {
                                    minWidth: "450px",
                                },
                            }}
                        />
                    </Stack.Item>
                    <Stack horizontalAlign="center">
                        <Label>{this.i18n.t("formUpdateSafetyMessage.active.label")}</Label>
                        <Checkbox
                            defaultChecked={this.safetyMessage?.active}
                            onChange={this.changeActive}
                        />
                    </Stack>
                </Stack>
                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            text={this.i18n.t("formUpdateSafetyMessage.cancel.text")}
                            onClick={this.props.onDialogCancel}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
            </form>
        );
    }
}
