import * as React from "react";
import { DocumentGroup, DocumentType } from "../stores/document";
import ButtonRadioGroup from "./button-radio-group";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export default function DocumentTypeRadioGroup({
    documentGroup,
    value: documentType,
    onChange: setDocumentType,
}: {
    documentGroup: DocumentGroup | null;
    value: DocumentType | null;
    onChange: (type: DocumentType) => void;
}): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    let groups;
    switch (documentGroup) {
        case null:
            return <></>;

        case DocumentGroup.Invoice:
            groups = (
                <>
                    <ButtonRadioGroup.Option
                        value={DocumentType.ServiceInvoice}
                        label={t("dms.component.document_type_radio.service")}
                    />
                    <ButtonRadioGroup.Option
                        value={DocumentType.MaintenanceInvoice}
                        label={t("dms.component.document_type_radio.maintenance")}
                    />
                    <ButtonRadioGroup.Option
                        value={DocumentType.OtherInvoice}
                        label={t("dms.component.document_type_radio.other")}
                    />
                </>
            );
            break;

        case DocumentGroup.Contract:
            groups = (
                <>
                    <ButtonRadioGroup.Option
                        value={DocumentType.LeasingContract}
                        label={t("dms.component.document_type_radio.leasing")}
                    />
                    <ButtonRadioGroup.Option
                        value={DocumentType.RentalContract}
                        label={t("dms.component.document_type_radio.rental")}
                    />
                    <ButtonRadioGroup.Option
                        value={DocumentType.ServiceContract}
                        label={t("dms.component.document_type_radio.service")}
                    />
                    <ButtonRadioGroup.Option
                        value={DocumentType.PurchaseContract}
                        label={t("dms.component.document_type_radio.purchase")}
                    />
                    <ButtonRadioGroup.Option
                        value={DocumentType.MaintenanceContract}
                        label={t("dms.component.document_type_radio.maintenance")}
                    />
                    <ButtonRadioGroup.Option
                        value={DocumentType.ServiceLevelAgreement}
                        label={t("dms.component.document_type_radio.sla")}
                    />
                </>
            );
            break;

        case DocumentGroup.Other:
            groups = (
                <>
                    <ButtonRadioGroup.Option
                        value={DocumentType.OwnersManual}
                        label={t("dms.component.document_type_radio.manual")}
                    />
                    <ButtonRadioGroup.Option
                        value={DocumentType.Certificate}
                        label={t("dms.component.document_type_radio.certificate")}
                    />
                </>
            );
            break;
    }

    return (
        <div className="rounded-md bg-neutral-100 p-2">
            <ButtonRadioGroup value={documentType} onChange={setDocumentType}>
                {groups}
            </ButtonRadioGroup>
        </div>
    );
}
