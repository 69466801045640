import * as React from "react";
import { external, initialize, inject } from "tsdi";
import { declareRoute, routePath, RouteProps } from "../routes";
import { Stack, MessageBar, MessageBarType } from "@fluentui/react";
import { LoginStatus, ServiceAuth } from "../domain/services/service-auth";
import { HistoryProvider } from "../domain/providers/history-provider";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { routeSettings } from "./page-settings";
import ErrorBars from "../ui/molecules/error-bars";
import { LayoutCenter } from "../ui/layouts/layout-centered";

export type PageLoginProps = RouteProps<{}>;

@external
@observer
export class PageEmailLoginTokenLogin extends React.Component<PageLoginProps> {
    @inject private serviceAuth!: ServiceAuth;
    @inject private historyProvider!: HistoryProvider;
    @inject private readonly i18n!: I18nProvider;

    @observable public status?: LoginStatus;

    constructor(props: PageLoginProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected async initialize(): Promise<void> {
        // Don't allow users to visit login page if already logged in.
        if (this.serviceAuth.isLoggedIn) {
            this.historyProvider.history.push(routePath.dashboard());
            return;
        }

        const emailLoginToken = this.historyProvider.getQueryParam("token");
        if (typeof emailLoginToken !== "string") {
            return;
        }

        const result = await this.serviceAuth.loginWithEmailLoginToken(emailLoginToken);
        this.status = result.status;
        if (result.status === LoginStatus.SUCCESS) {
            this.historyProvider.history.replace(routeSettings.path());
        }
    }

    public render(): JSX.Element {
        let messageBar;
        if (this.status === LoginStatus.INVALID) {
            messageBar = (
                <MessageBar messageBarType={MessageBarType.error}>
                    {this.i18n.t("page.emailLoginTokenLogin.invalid")}
                </MessageBar>
            );
        }
        return (
            <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
                <LayoutCenter>
                    <Stack.Item style={{ maxWidth: "30rem" }}>
                        <ErrorBars />
                        {messageBar}
                    </Stack.Item>
                </LayoutCenter>
            </div>
        );
    }
}

export const routeLogin = declareRoute({
    component: PageEmailLoginTokenLogin,
    path: routePath.emailLoginTokenLogin,
    pattern: "/email-token-login",
    public: true,
});
