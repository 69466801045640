import React from "react";
import { Link } from "react-router-dom";
import { Stack } from "@fluentui/react";
import { mergeStyleSets } from "@fluentui/react";
import { NotificationCheckBox } from "../atoms/notification-check-box";
import { routeImpactManagement } from "../../pages/page-impact-management";
import { routeUsers } from "../../pages/page-users";
import { routeVehicles } from "../../pages/page-vehicles";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryUsers } from "../../domain/repositories/repository-users";
import { RepositoryVehicles } from "../../domain/repositories/repository-vehicles";
import { PersistentNotification, PersistentNotificationImpactSeverity } from "../../api";
import { external, inject } from "tsdi";
import { observer } from "mobx-react";
import { action, makeObservable } from "mobx";
import colors from "../colors.scss";
import css from "./notification-item.scss";
import sizes from "../sizes.scss";

export type NotificationItemProps = {
    notification: PersistentNotification;
    markNotificationRead: (id: string) => void;
};

const classNames = mergeStyleSets({
    item: {
        padding: 10,
        selectors: {
            "&:hover": { background: "#eee" },
        },
    },
});

@external
@observer
export class ImpactNotificationItem extends React.Component<NotificationItemProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;

    constructor(props: NotificationItemProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private markRead(): void {
        this.props.markNotificationRead(this.props.notification.id);
    }

    render(): JSX.Element {
        const notification = this.props.notification;

        const vehicle = this.repositoryVehicles.byId(notification.vehicleId);
        if (!vehicle) {
            return <></>;
        }

        let fleetUserName = this.i18n.t("component.notificationItemImpact.noOperator");
        if (notification.fleetUserId) {
            const fleetUser = this.repositoryUsers.byId(notification.fleetUserId);
            if (fleetUser) {
                fleetUserName = `${fleetUser.firstName} ${fleetUser.lastName}`;
            }
        }

        let title;
        switch (notification.impactSeverity) {
            case PersistentNotificationImpactSeverity.SEVERE:
                title = this.i18n.t("component.notificationItemImpact.severe");
                break;
            case PersistentNotificationImpactSeverity.MEDIUM:
                title = this.i18n.t("component.notificationItemImpact.medium");
                break;
        }

        return (
            <Stack horizontal className={classNames.item}>
                <Stack style={{ overflow: "hidden", whiteSpace: "nowrap" }} grow={5}>
                    <Link to={routeImpactManagement.path()} className={css.notificationItem__title}>
                        {title}
                    </Link>
                    <span className={css.notificationItem__info}>
                        {this.i18n.formatDateTime(notification.triggeredAt)}
                    </span>
                    <Stack horizontal>
                        <Stack.Item style={{ overflow: "hidden" }} order={2}>
                            <span className={css.notificationItem__info}>
                                <>{this.i18n.t("component.notificationItemImpact.truck")}:&#32;</>
                            </span>
                            <Link
                                to={routeVehicles.path()}
                                style={{ fontSize: 14, color: colors["secondary08"] }}
                            >
                                {vehicle.serialNumber}
                            </Link>
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal>
                        <Stack.Item style={{ overflow: "hidden" }} order={2}>
                            <span className={css.notificationItem__info}>
                                <>
                                    {this.i18n.t("component.notificationItemImpact.operator")}:&#32;
                                </>
                            </span>
                            <Link
                                to={routeUsers.path()}
                                className={css.notificationItem__info}
                                style={{ fontSize: 14, color: colors["secondary08"] }}
                            >
                                {fleetUserName}
                            </Link>
                        </Stack.Item>
                    </Stack>
                </Stack>
                <Stack
                    horizontalAlign="end"
                    verticalAlign="center"
                    grow={1}
                    style={{ paddingLeft: `${sizes.gutter}`, paddingRight: `${sizes.gutter}` }}
                >
                    {!this.props.notification.read && (
                        <NotificationCheckBox hover onClick={this.markRead} />
                    )}
                </Stack>
            </Stack>
        );
    }
}
