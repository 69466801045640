/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DateFormat } from './DateFormat';
import {
    DateFormatFromJSON,
    DateFormatFromJSONTyped,
    DateFormatToJSON,
} from './DateFormat';
import type { Language } from './Language';
import {
    LanguageFromJSON,
    LanguageFromJSONTyped,
    LanguageToJSON,
} from './Language';
import type { TimeFormat } from './TimeFormat';
import {
    TimeFormatFromJSON,
    TimeFormatFromJSONTyped,
    TimeFormatToJSON,
} from './TimeFormat';
import type { WindowsTimezone } from './WindowsTimezone';
import {
    WindowsTimezoneFromJSON,
    WindowsTimezoneFromJSONTyped,
    WindowsTimezoneToJSON,
} from './WindowsTimezone';

/**
 * This struct represents all publicly accessible settings. This is a "singleton": there's only a single row on each instance. The backend does not create or delete rows, it only updates this one row.
 * @export
 * @interface PublicSetting
 */
export interface PublicSetting {
    /**
     * 
     * @type {string}
     * @memberof PublicSetting
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof PublicSetting
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof PublicSetting
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof PublicSetting
     */
    instanceName: string;
    /**
     * 
     * @type {string}
     * @memberof PublicSetting
     */
    logoId?: string | null;
    /**
     * 
     * @type {Language}
     * @memberof PublicSetting
     */
    defaultLanguage: Language;
    /**
     * 
     * @type {WindowsTimezone}
     * @memberof PublicSetting
     */
    defaultTimezone: WindowsTimezone;
    /**
     * 
     * @type {DateFormat}
     * @memberof PublicSetting
     */
    defaultDateFormat: DateFormat;
    /**
     * 
     * @type {TimeFormat}
     * @memberof PublicSetting
     */
    defaultTimeFormat: TimeFormat;
}

/**
 * Check if a given object implements the PublicSetting interface.
 */
export function instanceOfPublicSetting(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "instanceName" in value;
    isInstance = isInstance && "defaultLanguage" in value;
    isInstance = isInstance && "defaultTimezone" in value;
    isInstance = isInstance && "defaultDateFormat" in value;
    isInstance = isInstance && "defaultTimeFormat" in value;

    return isInstance;
}

export function PublicSettingFromJSON(json: any): PublicSetting {
    return PublicSettingFromJSONTyped(json, false);
}

export function PublicSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'instanceName': json['instanceName'],
        'logoId': !exists(json, 'logoId') ? undefined : json['logoId'],
        'defaultLanguage': LanguageFromJSON(json['defaultLanguage']),
        'defaultTimezone': WindowsTimezoneFromJSON(json['defaultTimezone']),
        'defaultDateFormat': DateFormatFromJSON(json['defaultDateFormat']),
        'defaultTimeFormat': TimeFormatFromJSON(json['defaultTimeFormat']),
    };
}

export function PublicSettingToJSON(value?: PublicSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'instanceName': value.instanceName,
        'logoId': value.logoId,
        'defaultLanguage': LanguageToJSON(value.defaultLanguage),
        'defaultTimezone': WindowsTimezoneToJSON(value.defaultTimezone),
        'defaultDateFormat': DateFormatToJSON(value.defaultDateFormat),
        'defaultTimeFormat': TimeFormatToJSON(value.defaultTimeFormat),
    };
}

