import { IColumn, SelectionMode, IObjectWithKey, DefaultButton } from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { external, initialize, inject } from "tsdi";
import { PreOpsChecklist, PreOpsQuestion, PreOpsQuestionSortKey, SortDirection } from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryPreOpsChecklists } from "../../domain/repositories/repository-pre-ops-checklists";
import {
    PreOpsQuestionsApiResource,
    PreOpsQuestionsQuery,
    RepositoryPreOpsQuestions,
} from "../../domain/repositories/repository-pre-ops-questions";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { PaginationState } from "../../utils/pagination-state";
import { SortState } from "../../utils/sort-state";
import { createUuid, UUID } from "../../utils/uuid";
import { ElofleetDialog } from "../atoms/elofleet-dialog";
import { ElofleetList } from "../atoms/elofleet-list";
import { ListPreOpsChecklistItemCell } from "../atoms/list-pre-ops-checklists-item-cell";
import { ModalConfirmation } from "../atoms/modal-confirmation";
import { Section } from "../atoms/section";
import { LayoutList } from "../layouts/layout-list";
import { FormUpdatePreOpsChecklist } from "./form-update-pre-ops-checklist";
import css from "./section-checklist-settings.scss";
import { defaultPageSize } from "../../utils/constants";

export interface SectionPreOpsChecklistSettingsProps {
    readonly id: UUID;
    readonly className?: string;
    readonly canDelete?: boolean;
    readonly canUpdate?: boolean;
}

interface ListPreOpsChecklistItem extends IObjectWithKey {
    readonly key: UUID;
}

@external
@observer
export class SectionPreOpsChecklistSettings extends React.Component<SectionPreOpsChecklistSettingsProps> {
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject private readonly repositoryPreOpsQuestions!: RepositoryPreOpsQuestions;
    @inject private readonly repositoryPreOpsChecklists!: RepositoryPreOpsChecklists;
    @inject protected readonly i18n!: I18nProvider;

    @observable private updateDialogVisible = false;
    @observable private deletionConfirmationModalVisible = false;

    private listStateId = createUuid();

    constructor(props: SectionPreOpsChecklistSettingsProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.preOpsQuestions.initializeList(this.listStateId, {
            query: () => this.query,
            ignoreUrl: true,
        });
        this.sortState.setState(PreOpsQuestionSortKey.POSITION, SortDirection.ASCENDING);
    }

    @computed private get sortState(): SortState<PreOpsQuestionSortKey> {
        return this.serviceListStates.preOpsQuestions.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<PreOpsQuestionsApiResource> {
        return this.serviceListStates.preOpsQuestions.getPaginationState(this.listStateId);
    }

    @computed private get query(): PreOpsQuestionsQuery {
        return { pageSize: defaultPageSize, ...this.sortState.query, checklistId: this.props.id };
    }

    @computed private get preOpsQuestions(): PreOpsQuestion[] {
        return this.repositoryPreOpsQuestions.byQuery(this.query, this.paginationState.pagination);
    }

    @computed private get preOpsChecklist(): PreOpsChecklist | undefined {
        return this.repositoryPreOpsChecklists.byId(this.props.id);
    }

    /**
     * Fetch questions for all requested checklists while grouping them.
     *
     * The returned data is [all_questions, grouping_info].
     */
    @computed private get items(): ListPreOpsChecklistItem[] {
        return this.preOpsQuestions.map((question) => ({ id: question.id, key: question.id }));
    }

    @computed private get columns(): IColumn[] {
        const columns = [
            {
                name: this.i18n.t("listPreOpsChecklist.column.index.name"),
                key: "position",
                minWidth: 100,
                maxWidth: 150,
            },
            {
                name: this.i18n.t("listPreOpsChecklist.column.body.name"),
                key: "body",
                minWidth: 150,
            },
        ];
        return this.sortState.patchColumns(columns);
    }

    private renderItem(questionId: UUID, column: IColumn): JSX.Element {
        return <ListPreOpsChecklistItemCell preOpsQuestionId={questionId} column={column} />;
    }

    @action.bound private reloadQuestions(): void {
        this.repositoryPreOpsQuestions.reloadQuery(this.query);
    }

    @action.bound private openUpdateDialog(event: React.MouseEvent<HTMLButtonElement>): void {
        this.updateDialogVisible = true;
        event.stopPropagation();
    }

    @action.bound private closeUpdateDialog(): void {
        this.updateDialogVisible = false;
    }

    @action.bound private updatePreOpsChecklists(): void {
        this.updateDialogVisible = false;
        this.reloadQuestions();
    }

    @action.bound private async deletePreOpsChecklist(): Promise<void> {
        await this.repositoryPreOpsChecklists.delete(this.props.id);
        await this.repositoryPreOpsChecklists.reloadQuery({ pageSize: defaultPageSize });
    }

    @action.bound private openDeletionConfirmationModal(
        event: React.MouseEvent<HTMLButtonElement>,
    ): void {
        this.deletionConfirmationModalVisible = true;
        event.stopPropagation();
    }

    @action.bound private closeDeletionConfirmationModal(): void {
        this.deletionConfirmationModalVisible = false;
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.preOpsQuestions.isInitialized(this.listStateId)) {
            return <></>;
        }
        return (
            <>
                <Section
                    className={this.props.className}
                    collapsible
                    defaultCollapsed
                    title={this.preOpsChecklist?.label}
                    actionButtons={
                        <div className={css.sectionChecklistSettings__buttons}>
                            {this.props.canUpdate && (
                                <DefaultButton
                                    text={this.i18n.t(
                                        "component.listCommandButton.updateDialog.title",
                                    )}
                                    onClick={this.openUpdateDialog}
                                />
                            )}

                            {this.props.canDelete && (
                                <DefaultButton
                                    text={this.i18n.t(
                                        "component.listCommandButton.deleteSelection.text",
                                    )}
                                    onClick={this.openDeletionConfirmationModal}
                                />
                            )}
                        </div>
                    }
                >
                    <LayoutList paginationState={this.paginationState}>
                        <ElofleetList
                            items={this.items}
                            selectionMode={SelectionMode.none}
                            onRenderItemColumn={(item: ListPreOpsChecklistItem, _index, column) =>
                                this.renderItem(item.key, column!)
                            }
                            onColumnHeaderClick={this.sortState.toggleColumn}
                            columns={this.sortState.patchColumns(this.columns)}
                        />
                    </LayoutList>
                </Section>
                <ElofleetDialog
                    isOpen={this.updateDialogVisible}
                    maxWidth="1000px"
                    title={this.i18n.t("formUpdatePreOpsChecklist.title")}
                >
                    <FormUpdatePreOpsChecklist
                        asDialogContent
                        id={this.props.id}
                        sortState={this.sortState}
                        onUpdate={this.updatePreOpsChecklists}
                        onDialogCancel={this.closeUpdateDialog}
                    />
                </ElofleetDialog>
                <ModalConfirmation
                    isOpen={this.deletionConfirmationModalVisible}
                    text={this.i18n.t("modalConfirmDeletion.description")}
                    title={this.i18n.t("modalConfirmDeletion.title")}
                    onConfirm={this.deletePreOpsChecklist}
                    onCancel={this.closeDeletionConfirmationModal}
                />
            </>
        );
    }
}
