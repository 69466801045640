/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MotionSensorUpsert
 */
export interface MotionSensorUpsert {
    /**
     * 
     * @type {Date}
     * @memberof MotionSensorUpsert
     */
    firmwareLastUpdatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof MotionSensorUpsert
     */
    currentVersion: string;
}

/**
 * Check if a given object implements the MotionSensorUpsert interface.
 */
export function instanceOfMotionSensorUpsert(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firmwareLastUpdatedAt" in value;
    isInstance = isInstance && "currentVersion" in value;

    return isInstance;
}

export function MotionSensorUpsertFromJSON(json: any): MotionSensorUpsert {
    return MotionSensorUpsertFromJSONTyped(json, false);
}

export function MotionSensorUpsertFromJSONTyped(json: any, ignoreDiscriminator: boolean): MotionSensorUpsert {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firmwareLastUpdatedAt': (new Date(json['firmwareLastUpdatedAt'])),
        'currentVersion': json['currentVersion'],
    };
}

export function MotionSensorUpsertToJSON(value?: MotionSensorUpsert | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firmwareLastUpdatedAt': (value.firmwareLastUpdatedAt.toISOString()),
        'currentVersion': value.currentVersion,
    };
}

