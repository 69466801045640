import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { declareRoute, routePath, RouteProps } from "../routes";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { PageHeader } from "../ui/atoms/page-header";
import { PageContent } from "../ui/atoms/page-content";
import { ListPreOpsAnswers } from "../ui/molecules/list-pre-ops-answers";
import { SelectionMode, Stack } from "@fluentui/react";
import { action, makeObservable, observable } from "mobx";
import { Section } from "../ui/atoms/section";
import { DatePicker } from "../ui/organisms/date-picker";
import { endOfISOWeek, startOfISOWeek } from "date-fns";
import sizes from "../ui/sizes.scss";
import { articles } from "../help";

@external
@observer
export class PagePreOpsChecklists extends React.Component<RouteProps> {
    @inject protected readonly i18n!: I18nProvider;

    // Use the same defaults that're displayed in the DatePicker
    @observable public dateStart?: Date = startOfISOWeek(new Date());
    @observable public dateEnd?: Date = endOfISOWeek(new Date());

    constructor(props: RouteProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound public onDateRangeChanged(dateStart?: Date, dateEnd?: Date): void {
        this.dateStart = dateStart;
        this.dateEnd = dateEnd;
    }

    public render(): JSX.Element {
        return (
            <LayoutDefault
                suggestedHelpArticles={[articles.preOpsChecklistsReport]}
                header={<PageHeader title={this.i18n.t("page.preOpsChecklists.title")} />}
            >
                <PageContent>
                    <Section>
                        <ListPreOpsAnswers
                            commandBar={
                                <Stack
                                    horizontal
                                    style={{ width: "100%" }}
                                    horizontalAlign="end"
                                    verticalAlign="center"
                                    tokens={{ childrenGap: 20 }}
                                >
                                    <DatePicker onDateRangeChanged={this.onDateRangeChanged} />
                                </Stack>
                            }
                            selectionMode={SelectionMode.none}
                            dateStart={this.dateStart}
                            dateEnd={this.dateEnd}
                            maxHeight={`${sizes.listMaxHeight}`}
                        />
                    </Section>
                </PageContent>
            </LayoutDefault>
        );
    }
}

export const routePreOpsChecklists = declareRoute({
    navigation: true,
    component: PagePreOpsChecklists,
    icon: "ClipboardList",
    title: "page.preOpsChecklists.navbarEntry",
    path: routePath.preOpsChecklists,
    pattern: "/pre-ops-checklists",
});
