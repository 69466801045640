/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserGroupDetailsUpdate
 */
export interface UserGroupDetailsUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof UserGroupDetailsUpdate
     */
    userEmployeeId?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserGroupDetailsUpdate
     */
    userLastName?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserGroupDetailsUpdate
     */
    userFirstName?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserGroupDetailsUpdate
     */
    userJobTitle?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserGroupDetailsUpdate
     */
    userFleetRole?: boolean | null;
}

/**
 * Check if a given object implements the UserGroupDetailsUpdate interface.
 */
export function instanceOfUserGroupDetailsUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserGroupDetailsUpdateFromJSON(json: any): UserGroupDetailsUpdate {
    return UserGroupDetailsUpdateFromJSONTyped(json, false);
}

export function UserGroupDetailsUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGroupDetailsUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userEmployeeId': !exists(json, 'userEmployeeId') ? undefined : json['userEmployeeId'],
        'userLastName': !exists(json, 'userLastName') ? undefined : json['userLastName'],
        'userFirstName': !exists(json, 'userFirstName') ? undefined : json['userFirstName'],
        'userJobTitle': !exists(json, 'userJobTitle') ? undefined : json['userJobTitle'],
        'userFleetRole': !exists(json, 'userFleetRole') ? undefined : json['userFleetRole'],
    };
}

export function UserGroupDetailsUpdateToJSON(value?: UserGroupDetailsUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userEmployeeId': value.userEmployeeId,
        'userLastName': value.userLastName,
        'userFirstName': value.userFirstName,
        'userJobTitle': value.userJobTitle,
        'userFleetRole': value.userFleetRole,
    };
}

