import * as React from "react";
import { pick } from "ramda";
import { observer } from "mobx-react";
import { Stack, TextField, DefaultButton, Checkbox, Label } from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositorySafetyMessages } from "../../domain/repositories/repository-safety-messages";
import { SafetyMessageCreate } from "../../api";
import { doubleBindString } from "../../utils/double-bind";
import { FormCreateProps } from "../../utils/form-create-props";
import { SafetyMessage } from "../../api";
import { createUuid } from "../../utils/uuid";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";

export interface FormCreateSafetyMessageProps extends FormCreateProps<SafetyMessage> {}

@observer
@external
export class FormCreateSafetyMessage extends React.Component<FormCreateSafetyMessageProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositorySafetyMessages!: RepositorySafetyMessages;

    @observable public body = "";
    @observable public active = false;

    private validationId = createUuid();

    constructor(props: FormCreateSafetyMessageProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositorySafetyMessages.validation.create.updateModel(
            this.validationId,
            this.safetyMessageCreate,
        );
    }

    @initialize protected initialize(): void {
        this.repositorySafetyMessages.validation.create.initializeModel(
            this.validationId,
            this.safetyMessageCreate,
        );
    }

    @computed private get safetyMessageCreate(): SafetyMessageCreate {
        return pick(["body", "active"], this as FormCreateSafetyMessage);
    }

    @action.bound
    private async createSafetyMessage(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const safetyMessage = await this.repositorySafetyMessages.create(this.safetyMessageCreate);
        this.reset();
        if (this.props.onCreate) {
            this.props.onCreate(safetyMessage);
        }
    }

    @action.bound private reset(): void {
        this.body = "";
        this.active = false;
        this.triggerValidation();
    }

    @action.bound private changeActive(
        _event?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean,
    ): void {
        this.active = checked!;
        this.triggerValidation();
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formCreateSafetyMessage.submit.text")}
                validation={this.repositorySafetyMessages.validation.create}
                validationId={this.validationId}
            />
        );
        return (
            <form onSubmit={this.createSafetyMessage}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    styles={{
                        root: {
                            minWidth: "600px",
                        },
                    }}
                >
                    <Stack.Item tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                        <TextField
                            label={this.i18n.t("formCreateSafetyMessage.body.label")}
                            {...doubleBindString<FormCreateSafetyMessage>(this, "body", () =>
                                this.triggerValidation(),
                            )}
                            required
                            errorMessage={this.i18n.formatFieldValidationState(
                                this.repositorySafetyMessages.validation.create.getFieldValidationState(
                                    this.validationId,
                                    "body",
                                ),
                            )}
                            styles={{
                                root: {
                                    minWidth: "450px",
                                },
                            }}
                        />
                    </Stack.Item>
                    <Stack horizontalAlign="center">
                        <Label>{this.i18n.t("formCreateSafetyMessage.active.label")}</Label>
                        <Checkbox defaultChecked={this.active} onChange={this.changeActive} />
                    </Stack>
                </Stack>
                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            text={this.i18n.t("formCreateSafetyMessage.cancel.text")}
                            onClick={this.props.onDialogCancel}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
            </form>
        );
    }
}
