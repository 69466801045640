/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum VehicleGroupAssignmentSortKey {
    CREATED_AT = 'createdAt'
}


export function VehicleGroupAssignmentSortKeyFromJSON(json: any): VehicleGroupAssignmentSortKey {
    return VehicleGroupAssignmentSortKeyFromJSONTyped(json, false);
}

export function VehicleGroupAssignmentSortKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleGroupAssignmentSortKey {
    return json as VehicleGroupAssignmentSortKey;
}

export function VehicleGroupAssignmentSortKeyToJSON(value?: VehicleGroupAssignmentSortKey | null): any {
    return value as any;
}

