import { runInAction } from "mobx";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    PageInfo,
    ShockProfile,
    ShockProfileCreate,
    ShockProfilesApi,
    ShockProfilesListShockProfilesRequest,
    ShockProfileSortKey,
    ShockProfileUpdate,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { Validation } from "../../utils/validation";
import { defaultPageSize } from "../../utils/constants";

export type ShockProfilesQuery = Omit<ShockProfilesListShockProfilesRequest, "page">;

export interface ShockProfilesApiResource extends ApiResource {
    entity: ShockProfile;
    query: ShockProfilesQuery;
    update: ShockProfileUpdate;
    create: ShockProfileCreate;
    sortKey: ShockProfileSortKey;
}

@component
export class RepositoryShockProfiles extends ElofleetRepository<ShockProfilesApiResource> {
    @inject private readonly shockProfilesApi!: ShockProfilesApi;

    public validation = {
        create: new Validation((shockProfileCreate: ShockProfileCreate) =>
            this.shockProfilesApi.shockProfilesValidateCreateShockProfile({
                shockProfileCreate: shockProfileCreate,
            }),
        ),
        update: new Validation((shockProfileUpdate: ShockProfileUpdate, shockProfileId: UUID) =>
            this.shockProfilesApi.shockProfilesValidateUpdateShockProfile({
                shockProfileUpdate,
                shockProfileId,
            }),
        ),
    };

    protected async fetchById(shockProfileId: UUID): Promise<ShockProfile | undefined> {
        return await this.shockProfilesApi.shockProfilesReadShockProfile({ shockProfileId });
    }

    protected extractId = prop("id");

    protected async fetchByQuery(
        query: ShockProfilesQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<ShockProfile>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.shockProfilesApi.shockProfilesListShockProfiles({
            ...query,
            page,
        });
        return { entities };
    }

    public fetchMetadata(query: ShockProfilesQuery): Promise<PageInfo> {
        return this.shockProfilesApi.shockProfilesListShockProfilesMetadata(query);
    }

    public async update(
        shockProfileId: UUID,
        shockProfileUpdate: ShockProfileUpdate,
    ): Promise<ShockProfile> {
        const shockProfile = await this.wrapApiCall(
            this.shockProfilesApi.shockProfilesUpdateShockProfile({
                shockProfileUpdate,
                shockProfileId,
            }),
        );

        await this.waitForIdle();
        runInAction(() => {
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(shockProfile);
        });

        return shockProfile;
    }

    public async create(shockProfileCreate: ShockProfileCreate): Promise<ShockProfile> {
        const shockProfile = await this.wrapApiCall(
            this.shockProfilesApi.shockProfilesCreateShockProfile({ shockProfileCreate }),
        );

        await this.waitForIdle();
        runInAction(() => {
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(shockProfile);
        });

        return shockProfile;
    }

    public async delete(...shockProfileIds: UUID[]): Promise<void> {
        await this.wrapApiCall(
            Promise.all(
                shockProfileIds.map((shockProfileId) =>
                    this.shockProfilesApi.shockProfilesDeleteShockProfile({ shockProfileId }),
                ),
            ),
        );

        await this.waitForIdle();
        // This reload is necessary to reload all queries that want to display "all" entities.
        this.reloadQuery({ pageSize: defaultPageSize });
    }
}
