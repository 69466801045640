import { ActionButton } from "@fluentui/react";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { external, inject } from "tsdi";
import {
    ShockEventLevel,
    SortDirection,
    User,
    UserSortKey,
    Vehicle,
    VehicleSortKey,
} from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryUsers } from "../../domain/repositories/repository-users";
import { RepositoryVehicles } from "../../domain/repositories/repository-vehicles";
import { MultiSelectComboBox } from "../atoms/multi-select-connected-combo-box";
import { defaultPageSize } from "../../utils/constants";

export interface FilterShockEventListProps {
    /** This callback function allows the filter to rerender
     * the parent component. */
    setShockEventListFilter(filter: FilterShockEventListQuery): void;
}

export type FilterShockEventListQuery = {
    filteredVehicles: string[];
    filteredFleetUsers: string[];
    filteredShockLevels: ShockEventLevel[];
    isFiltered: boolean;
};

@external
@observer
export class FilterShockEventList extends React.Component<FilterShockEventListProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;
    @inject private readonly repositoryUsers!: RepositoryUsers;

    @observable private filteredVehicles: string[] = [];
    @observable private filteredFleetUsers: string[] = [];
    @observable private filteredShockLevels: ShockEventLevel[] = [];
    @observable private isFiltered: boolean = false;

    constructor(props: FilterShockEventListProps) {
        super(props);
        makeObservable(this);
    }

    private setVehicleFilter(filter: string): void {
        // Remove element if it is already clicked.
        if (this.filteredVehicles.includes(filter)) {
            this.filteredVehicles = this.filteredVehicles.filter((id) => id !== filter);
            this.setShockEventListFilter();
            return;
        }
        this.isFiltered = true;
        this.filteredVehicles = [filter, ...this.filteredVehicles];
        this.setShockEventListFilter();
    }

    private setFleetUserFilter(filter: string): void {
        // Remove element if it is already clicked.
        if (this.filteredFleetUsers.includes(filter)) {
            this.filteredFleetUsers = this.filteredFleetUsers.filter((level) => level !== filter);
            this.setShockEventListFilter();
            return;
        }
        this.isFiltered = true;
        this.filteredFleetUsers = [filter, ...this.filteredFleetUsers];
        this.setShockEventListFilter();
    }

    private setShockLevelFilter(filter: ShockEventLevel): void {
        // Remove element if it is already clicked.
        if (this.filteredShockLevels.includes(filter)) {
            this.filteredShockLevels = this.filteredShockLevels.filter((id) => id !== filter);
            this.setShockEventListFilter();
            return;
        }
        this.isFiltered = true;
        this.filteredShockLevels = [filter, ...this.filteredShockLevels];
        this.setShockEventListFilter();
    }

    @action.bound private clearFilter(): void {
        this.filteredVehicles = [];
        this.filteredFleetUsers = [];
        this.isFiltered = false;
        this.setShockEventListFilter();
    }

    private setShockEventListFilter(): void {
        const filterQuery: FilterShockEventListQuery = {
            filteredVehicles: this.filteredVehicles,
            filteredFleetUsers: this.filteredFleetUsers,
            filteredShockLevels: this.filteredShockLevels,
            isFiltered: this.isFiltered,
        };
        this.props.setShockEventListFilter(filterQuery);
    }

    private formatEntry(vehicle: Vehicle): string {
        let out = "";
        if (vehicle.manufacturer) {
            out += `${vehicle.manufacturer} `;
        }
        if (vehicle.model) {
            out += `${vehicle.model} `;
        }

        if (out) {
            out += `(${vehicle.serialNumber})`;
        } else {
            out += `${vehicle.serialNumber}`;
        }

        return out;
    }

    public render(): JSX.Element {
        return (
            <>
                <MultiSelectComboBox
                    query={{
                        pageSize: defaultPageSize,
                        sortKey: VehicleSortKey.SERIAL_NUMBER,
                        sortDirection: SortDirection.ASCENDING,
                    }}
                    allowFreeForm={false}
                    placeholder={this.i18n.t("schema.vehicle.vehicle")}
                    repository={this.repositoryVehicles}
                    formatEntity={this.formatEntry}
                    sortFn={(a, b) => a.text.localeCompare(b.text)}
                    selectedKeys={this.filteredVehicles}
                    onChange={(vehicleId: string | undefined) => {
                        if (vehicleId === undefined) {
                            return;
                        }
                        this.setVehicleFilter(vehicleId);
                    }}
                    allowSelectAll
                />

                <MultiSelectComboBox
                    query={{
                        pageSize: defaultPageSize,
                        sortKey: UserSortKey.EMPLOYEE_ID,
                        sortDirection: SortDirection.ASCENDING,
                    }}
                    allowFreeForm={false}
                    placeholder={this.i18n.t("schema.user.operator")}
                    repository={this.repositoryUsers}
                    formatEntity={(user: User) =>
                        `${user.firstName} ${user.lastName} (${user.employeeId})`
                    }
                    sortFn={(a, b) => a.text.localeCompare(b.text)}
                    selectedKeys={this.filteredFleetUsers}
                    onChange={(userId: string | undefined) => {
                        if (userId === undefined) {
                            return;
                        }
                        this.setFleetUserFilter(userId);
                    }}
                    allowSelectAll
                />

                <MultiSelectComboBox
                    entities={[
                        { id: ShockEventLevel.LIGHT },
                        { id: ShockEventLevel.MEDIUM },
                        { id: ShockEventLevel.SEVERE },
                    ]}
                    allowFreeForm={false}
                    placeholder={this.i18n.t("schema.shock_event.shockLevel")}
                    formatEntity={(level: { id: string }) =>
                        this.i18n.t(`listShockEvents.column.severity.${level.id}`)
                    }
                    selectedKeys={this.filteredShockLevels}
                    onChange={(shockLevel: string | undefined) => {
                        if (
                            shockLevel === undefined ||
                            !Object.values(ShockEventLevel).includes(shockLevel as ShockEventLevel)
                        ) {
                            return;
                        }
                        this.setShockLevelFilter(shockLevel as ShockEventLevel);
                    }}
                    allowSelectAll
                />

                <ActionButton
                    iconProps={{ iconName: "clearFilter" }}
                    allowDisabledFocus
                    disabled={!this.isFiltered}
                    checked={true}
                    onClick={this.clearFilter}
                ></ActionButton>
            </>
        );
    }
}
