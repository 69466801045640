import * as React from "react";
import { ElodmsLayout } from "../../components/layout";
import ButtonRadioGroup from "../../components/button-radio-group";
import TableRadioGroup from "../../components/table-radio-group";
import { ServiceReportStatus, ServiceReportStatuses } from "../../stores/service-report";
import { Card } from "../../components/card";
import { Label } from "../../components/label";
import { UUID } from "../../../utils/uuid";
import { useStore } from "../../stores/store";
import { Equipment, equipmentIsVehicle } from "../../stores/equipment";
import { Transition } from "@headlessui/react";
import { useNavigate } from "react-router";
import routes from "../../routes";
import { TextInput } from "../../components/text-input";
import Fuse from "fuse.js";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export function NewServiceReport(): JSX.Element {
    const navigate = useNavigate();

    const [searchString, setSearchString] = React.useState("");

    const [statuses, setQuestionStatuses] = React.useState<Partial<ServiceReportStatuses>>({});

    const [equipmentId, setEquipmentId] = React.useState<UUID | null>(null);

    const { t }: { t: TFunction } = useTranslation();

    const resetForm = (): void => {
        setQuestionStatuses({});

        setEquipmentId(null);
    };

    const allStatusKeys: Array<keyof ServiceReportStatuses> = [
        "motorFluid",
        "lubricate",
        "safetyContacts",
        "steeringAxle",
    ];

    const formValid =
        equipmentId !== null && allStatusKeys.every((key) => statuses[key] !== undefined);

    const equipment: Array<[UUID, Equipment]> = useStore((state) => {
        let equipment = Object.entries(state.equipment)
            .filter(([_equipmentId, equipment]) => equipmentIsVehicle(equipment.equipmentType))
            .map(([equipmentId, equipment]) => ({
                id: equipmentId,
                equipment: {
                    ...equipment,
                    oem: t(equipment.oem ?? ""),
                    modelName: t(equipment.modelName),
                    department: t(equipment.department ?? ""),
                },
            }));

        if (searchString) {
            const fuse = new Fuse(equipment, {
                keys: [
                    "equipment.oem",
                    "equipment.modelName",
                    "equipment.serialNumber",
                    "equipment.department",
                    "equipment.equipmentType",
                    "equipment.manufactureYear",
                ],
                minMatchCharLength: 2,
                threshold: 0.5,
            });
            equipment = fuse.search(searchString).map(({ item }) => item);
        }

        return equipment.map(({ id, equipment }) => [id, equipment]);
    });

    const insertServiceReport = useStore((state) => state.insertServiceReport);
    const onSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        if (!formValid) {
            return;
        }
        insertServiceReport({
            timestamp: new Date(),
            equipment_id: equipmentId,
            statuses: statuses as ServiceReportStatuses,
        });
        resetForm();
        navigate(routes.listServiceReports.generateUrl());
    };

    return (
        <ElodmsLayout title={t("dms.page.newServiceReport.title")}>
            <form onSubmit={onSubmit} className="flex h-full flex-col">
                <Question
                    key="motorFluid"
                    label={t("dms.page.newServiceReport.question.motorFluid")}
                    onChange={(motorFluid) =>
                        setQuestionStatuses({
                            ...statuses,
                            motorFluid,
                        })
                    }
                    value={statuses.motorFluid}
                />

                <Question
                    key="safetyContacts"
                    label={t("dms.page.newServiceReport.question.safetyContacts")}
                    onChange={(safetyContacts) =>
                        setQuestionStatuses({
                            ...statuses,
                            safetyContacts,
                        })
                    }
                    value={statuses.safetyContacts}
                />

                <Question
                    key="lubricate"
                    label={t("dms.page.newServiceReport.question.lubricate")}
                    onChange={(lubricate) =>
                        setQuestionStatuses({
                            ...statuses,
                            lubricate,
                        })
                    }
                    value={statuses.lubricate}
                />

                <Question
                    key="steeringAxle"
                    label={t("dms.page.newServiceReport.question.steeringAxle")}
                    onChange={(steeringAxle) =>
                        setQuestionStatuses({
                            ...statuses,
                            steeringAxle,
                        })
                    }
                    value={statuses.steeringAxle}
                />

                <Card className="row-1 relative grow pb-8">
                    <Label label={<>{t("dms.page.newServiceReport.equipment.label")}</>} />
                    <TextInput
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        placeholder={t("dms.page.newServiceReport.equipment.search")}
                        className="mb-2"
                    />
                    <TableRadioGroup value={equipmentId} onChange={setEquipmentId}>
                        {equipment.map(([id, equipment]) => (
                            <TableRadioGroup.Option key={id} id={id} {...equipment} />
                        ))}
                    </TableRadioGroup>

                    <div className="flex grow items-end">
                        <div className="z-10 flex w-full justify-end">
                            <button
                                className="mt-8 h-12 rounded border-2 border-transparent bg-yellow-400 px-8 transition-colors duration-150 focus:border-blue-900 disabled:bg-gray-200 disabled:text-gray-500"
                                type="submit"
                                disabled={!formValid}
                            >
                                <>{t("dms.page.newServiceReport.submit")}</>
                            </button>
                        </div>

                        <Transition
                            show={formValid}
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            enter="transition-opacity duration-200"
                            leave="transition-opacity duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="absolute bottom-0 right-0">
                                <svg
                                    width="330"
                                    height="128"
                                    viewBox="0 0 330 128"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_75_1556)">
                                        <path
                                            d="M207.971 20.3573L885.796 -29.8977L341.679 267.961L-336.146 318.216L207.971 20.3573Z"
                                            fill="#FFDC00"
                                            fillOpacity="0.3"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_75_1556">
                                            <rect width="330" height="128" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </Transition>
                    </div>
                </Card>
            </form>
        </ElodmsLayout>
    );
}

function Question({
    label,
    value,
    onChange,
}: {
    label: string;
    value: number | undefined;
    onChange: (value: number) => void;
}): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();

    return (
        <Card>
            <Label label={label} />
            <ButtonRadioGroup value={value ?? null} onChange={onChange}>
                <ButtonRadioGroup.Option
                    value={ServiceReportStatus.Ok}
                    label={t("dms.schema.serviceReport.answer.ok")}
                    colorClasses="border-green-400 ui-checked:border-green-500 ui-checked:bg-green-200"
                />
                <ButtonRadioGroup.Option
                    value={ServiceReportStatus.Warning}
                    label={t("dms.schema.serviceReport.answer.warning")}
                    colorClasses="border-yellow-300 ui-checked:border-yellow-500 ui-checked:bg-yellow-100"
                />
                <ButtonRadioGroup.Option
                    value={ServiceReportStatus.Critical}
                    label={t("dms.schema.serviceReport.answer.critical")}
                    colorClasses="border-red-200 ui-checked:border-red-400 ui-checked:bg-red-100"
                />
            </ButtonRadioGroup>
        </Card>
    );
}
