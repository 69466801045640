import * as React from "react";
import { pick } from "ramda";
import { observer } from "mobx-react";
import { Stack, DefaultButton, TextField } from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { ConnectedComboBox } from "../atoms/connected-combo-box";
import { RepositoryVehicleDevices } from "../../domain/repositories/repository-vehicle-devices";
import { VehicleDevice } from "../../api";
import { RepositoryVehicles } from "../../domain/repositories/repository-vehicles";
import { createUuid, UUID } from "../../utils/uuid";
import { VehicleDeviceUpdate } from "../../api";
import { FormUpdateProps } from "../../utils/form-update-props";
import { doubleBindString } from "../../utils/double-bind";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";
import { defaultPageSize } from "../../utils/constants";

export interface FormUpdateVehicleDeviceProps extends FormUpdateProps<VehicleDevice> {}

@observer
@external
export class FormUpdateVehicleDevice extends React.Component<FormUpdateVehicleDeviceProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryVehicleDevices!: RepositoryVehicleDevices;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;

    @observable public hardwareKey?: string;

    private validationId = createUuid();

    constructor(props: FormUpdateVehicleDeviceProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositoryVehicleDevices.validation.update.updateModel(
            this.validationId,
            this.vehicleDeviceUpdate,
        );
    }

    @initialize protected initialize(): void {
        this.repositoryVehicleDevices.validation.update.initializeModel(
            this.validationId,
            this.vehicleDeviceUpdate,
            this.props.id,
        );
    }

    public componentWillUnmount(): void {
        this.repositoryVehicleDevices.discardMutableCopy(
            FormUpdateVehicleDevice.name,
            this.props.id,
        );
    }

    @computed private get vehicleDevice(): VehicleDevice | undefined {
        return this.repositoryVehicleDevices.mutableCopyById(
            FormUpdateVehicleDevice.name,
            this.props.id,
        );
    }

    @computed private get vehicleDeviceUpdate(): VehicleDeviceUpdate {
        const update: VehicleDeviceUpdate = pick(["vehicleId", "serialNumber"], this.vehicleDevice);

        update.hardwareKey = this.hardwareKey;

        return update;
    }

    @action.bound
    private async updateVehicleDevice(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const vehicleDevice = await this.repositoryVehicleDevices.update(
            this.props.id,
            this.vehicleDeviceUpdate,
        );
        this.props.onUpdate(vehicleDevice);
    }

    @action.bound private setVehicleDeviceType(vehicleId?: UUID): void {
        if (!this.vehicleDevice) {
            throw new Error("Can't update vehicleDevice type id: VehicleDevice not loaded.");
        }
        this.vehicleDevice.vehicleId = vehicleId ?? null;
        this.triggerValidation();
    }

    @action.bound private setHardwareKey(_: unknown, hardwareKey: string | undefined): void {
        // Empty strings fail validation, hence we set the value to null.
        this.hardwareKey = hardwareKey === "" ? undefined : hardwareKey;
        this.triggerValidation();
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formUpdateVehicleDevice.submit.text")}
                validation={this.repositoryVehicleDevices.validation.update}
                validationId={this.validationId}
            />
        );
        return (
            <form onSubmit={this.updateVehicleDevice}>
                <Stack tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                    <ConnectedComboBox
                        formatEntity={this.i18n.formatVehicle}
                        repository={this.repositoryVehicles}
                        query={{ pageSize: defaultPageSize }}
                        label={this.i18n.t("formUpdateVehicleDevice.vehicle.label")}
                        onChange={this.setVehicleDeviceType}
                        disabled={!this.vehicleDevice}
                        selectedKey={this.vehicleDevice?.vehicleId}
                        clearable
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicleDevices.validation.update.getFieldValidationState(
                                this.validationId,
                                "vehicleId",
                            ),
                        )}
                    />
                    <TextField
                        label={this.i18n.t("formUpdateVehicleDevice.serialNumber.label")}
                        {...doubleBindString(this.vehicleDevice!, "serialNumber", () =>
                            this.triggerValidation(),
                        )}
                        required
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicleDevices.validation.update.getFieldValidationState(
                                this.validationId,
                                "serialNumber",
                            ),
                        )}
                    />
                    <TextField
                        label={this.i18n.t("formUpdateVehicleDevice.hardwareKey.label")}
                        type="password"
                        canRevealPassword
                        onChange={this.setHardwareKey}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicleDevices.validation.update.getFieldValidationState(
                                this.validationId,
                                "hardwareKey",
                            ),
                        )}
                    />
                    <TextField
                        label={this.i18n.t("formUpdateVehicleDevice.currentFirmwareVersion.label")}
                        type="text"
                        disabled
                        value={this.vehicleDevice?.currentVersion}
                    />
                </Stack>
                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            label={this.i18n.t("formUpdateVehicleDevice.cancel.label")}
                            text={this.i18n.t("formUpdateVehicleDevice.cancel.text")}
                            onClick={this.props.onDialogCancel}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
            </form>
        );
    }
}
