import * as React from "react";
import css from "./page-content.scss";

export interface PageContentProps {
    readonly children: React.ReactNode;
}

export class PageContent extends React.Component<PageContentProps> {
    public render(): JSX.Element {
        return <main className={css.pageContent}>{this.props.children}</main>;
    }
}
