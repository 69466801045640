/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WindowsTimezone } from './WindowsTimezone';
import {
    WindowsTimezoneFromJSON,
    WindowsTimezoneFromJSONTyped,
    WindowsTimezoneToJSON,
} from './WindowsTimezone';

/**
 * 
 * @export
 * @interface TimezoneListEntry
 */
export interface TimezoneListEntry {
    /**
     * 
     * @type {WindowsTimezone}
     * @memberof TimezoneListEntry
     */
    timezone: WindowsTimezone;
    /**
     * 
     * @type {string}
     * @memberof TimezoneListEntry
     */
    description: string;
}

/**
 * Check if a given object implements the TimezoneListEntry interface.
 */
export function instanceOfTimezoneListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "timezone" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function TimezoneListEntryFromJSON(json: any): TimezoneListEntry {
    return TimezoneListEntryFromJSONTyped(json, false);
}

export function TimezoneListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimezoneListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timezone': WindowsTimezoneFromJSON(json['timezone']),
        'description': json['description'],
    };
}

export function TimezoneListEntryToJSON(value?: TimezoneListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timezone': WindowsTimezoneToJSON(value.timezone),
        'description': value.description,
    };
}

