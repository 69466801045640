import * as React from "react";
import { Selection, IObjectWithKey, IColumn } from "@fluentui/react";
import { omit } from "ramda";
import { action, computed, makeObservable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { createUuid, UUID } from "../../utils/uuid";
import { ShockProfile, ShockProfileSortKey } from "../../api";
import { observer } from "mobx-react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { SortState } from "../../utils/sort-state";
import { PaginationState } from "../../utils/pagination-state";
import { LayoutList } from "../layouts/layout-list";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import {
    RepositoryShockProfiles,
    ShockProfilesApiResource,
    ShockProfilesQuery,
} from "../../domain/repositories/repository-shock-profiles";
import { ListShockProfileItemCell } from "../atoms/list-shock-profile-item-cell";
import { SelectionState } from "../../utils/selection-state";
import { defaultPageSize } from "../../utils/constants";

export interface ListShockProfilesProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly commandBar?: JSX.Element;
    readonly listStateId?: UUID;
}

interface ListShockProfileItem extends IObjectWithKey {
    readonly key: UUID;
}

@external
@observer
export class ListShockProfiles extends React.Component<ListShockProfilesProps> {
    @inject private readonly repositoryShockProfiles!: RepositoryShockProfiles;
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;

    private _listStateId = createUuid();
    private selection = new Selection({
        onSelectionChanged: () => this.updateSelection(),
    });

    constructor(props: ListShockProfilesProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.shockProfiles.initializeList(this.listStateId, {
            query: () => this.query,
        });
    }

    @computed private get listStateId(): UUID {
        return this.props.listStateId ?? this._listStateId;
    }

    @computed private get sortState(): SortState<ShockProfileSortKey> {
        return this.serviceListStates.shockProfiles.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<ShockProfilesApiResource> {
        return this.serviceListStates.shockProfiles.getPaginationState(this.listStateId);
    }

    @computed private get selectionState(): SelectionState {
        return this.serviceListStates.shockProfiles.getSelectionState(this.listStateId);
    }

    @computed private get query(): ShockProfilesQuery {
        return { pageSize: defaultPageSize, ...this.sortState.query };
    }

    @computed private get shockProfiles(): (ShockProfile | undefined)[] {
        if (this.props.ids) {
            return this.props.ids.map((id) => this.repositoryShockProfiles.byId(id));
        }
        return this.repositoryShockProfiles.byQuery(this.query, this.paginationState.pagination);
    }

    @computed private get items(): (ListShockProfileItem | undefined)[] {
        return this.shockProfiles.map((shockProfile) => {
            if (!shockProfile) {
                return;
            }
            return {
                key: shockProfile.id,
            };
        });
    }

    private renderItem(shockProfileId: UUID, column: IColumn): JSX.Element {
        return <ListShockProfileItemCell shockProfileId={shockProfileId} column={column} />;
    }

    @computed private get columns(): IColumn[] {
        const columns = [
            {
                name: this.i18n.t("listShockProfiles.column.label.name"),
                key: "label",
                minWidth: 150,
            },
            {
                name: this.i18n.t("listShockProfiles.column.light.name"),
                key: "light",
                minWidth: 150,
            },
            {
                name: this.i18n.t("listShockProfiles.column.medium.name"),
                key: "medium",
                minWidth: 150,
            },
            {
                name: this.i18n.t("listShockProfiles.column.severe.name"),
                key: "severe",
                minWidth: 150,
            },
            {
                name: this.i18n.t("listShockProfiles.column.duration.name"),
                key: "duration",
                minWidth: 150,
            },
        ];

        return this.sortState.patchColumns(columns);
    }

    @action.bound private updateSelection(): void {
        // Update the listState's selectionState to the new selected keys.
        this.selectionState.updateSelection(
            this.selection.getSelection().map((item) => item.key as UUID),
        );
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.shockProfiles.isInitialized(this.listStateId)) {
            return <></>;
        }
        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(["ids"], this.props);
        return (
            <LayoutList
                paginationState={this.paginationState}
                commandBar={this.props.commandBar ? this.props.commandBar : undefined}
            >
                <ElofleetList
                    {...props}
                    selection={this.selection}
                    onRenderItemColumn={(item: ListShockProfileItem, _index, column) =>
                        this.renderItem(item.key, column!)
                    }
                    onColumnHeaderClick={this.sortState.toggleColumn}
                    columns={this.columns}
                    items={this.items}
                />
            </LayoutList>
        );
    }
}
