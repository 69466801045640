import { Checkbox } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { NodeProps } from "reactflow";
import { MutingData } from "../../../api";
import ActionConfigNode from "./action-config-node";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export default function Muting(
    props: NodeProps<MutingData & { invalid?: boolean; validationErrorMessage?: string }>,
): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    const [modeOne, setModeOne] = useState(props.data.modeOne ?? true);
    const [modeTwo, setModeTwo] = useState(props.data.modeTwo ?? true);
    const [modeThree, setModeThree] = useState(props.data.modeThree ?? true);

    // We have to set the data to prevent undefined values
    useEffect(() => {
        props.data.modeOne = modeOne;
        props.data.modeTwo = modeTwo;
        props.data.modeThree = modeThree;
    }, [props.data, modeOne, modeTwo, modeThree]);

    return (
        <ActionConfigNode nodeKind={props.type} {...props.data}>
            <Checkbox
                label={t("component.actionConfigNodes.muting.digitalInput1")}
                checked={modeOne}
                onChange={(_e, checked) => setModeOne(checked!)}
            />
            <Checkbox
                label={t("component.actionConfigNodes.muting.longButtonPress")}
                checked={modeTwo}
                onChange={(_e, checked) => setModeTwo(checked!)}
            />
            <Checkbox
                label={t("component.actionConfigNodes.muting.shortButtonPress")}
                checked={modeThree}
                onChange={(_e, checked) => setModeThree(checked!)}
            />
        </ActionConfigNode>
    );
}
