import { Icon } from "@fluentui/react";
import * as React from "react";

export interface BooleanIconProps {
    readonly status: boolean;
}

export class BooleanIcon extends React.Component<BooleanIconProps> {
    public render(): JSX.Element {
        return <Icon iconName={this.props.status ? "CheckMark" : "Cancel"} />;
    }
}
