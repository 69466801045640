import { Outlet, useNavigate } from "react-router";
import { routeDashboard } from "../../pages/page-dashboard";
import React, { useEffect } from "react";
import sizes from "../sizes.scss";

/**
 * Redirect to the dashboard if on mobile devices.
 */
export default function RedirectMobileToDashboard(): JSX.Element {
    const navigate = useNavigate();

    useEffect(() => {
        if (window.innerWidth <= parseInt(sizes.maxWidthMobileScreens)) {
            navigate(routeDashboard.path(false), { replace: true });
        }
    }, [navigate]);

    return <Outlet />;
}
