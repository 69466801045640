import * as React from "react";
import classNames from "classnames";
import css from "./severity-pill.scss";
import { external, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";

export enum Severity {
    SEVERE = "severe",
    MEDIUM = "medium",
    LIGHT = "light",
    WARNING = "warning",
    DANGER = "danger",
}

export interface SeverityPillProps {
    readonly severity: Severity;
    /** Sets a specific category of the severity pill translation keys. */
    readonly kind?: string;
}

@external
export class SeverityPill extends React.Component<SeverityPillProps> {
    @inject private readonly i18n!: I18nProvider;

    public render(): JSX.Element {
        return (
            <span
                className={classNames(css.severityPill, {
                    [css["severityPill--severe"]]:
                        this.props.severity === Severity.SEVERE ||
                        this.props.severity === Severity.DANGER,
                    [css["severityPill--medium"]]:
                        this.props.severity === Severity.MEDIUM ||
                        this.props.severity === Severity.WARNING,
                    [css["severityPill--light"]]: this.props.severity === Severity.LIGHT,
                })}
            >
                {this.i18n.t(
                    `component.severityPill.${this.props.kind ? this.props.kind : "default"}.${
                        this.props.severity
                    }`,
                )}
            </span>
        );
    }
}
