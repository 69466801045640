/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhoneCreate
 */
export interface PhoneCreate {
    /**
     * 
     * @type {string}
     * @memberof PhoneCreate
     */
    vehicleDeviceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhoneCreate
     */
    androidId: string;
    /**
     * 
     * @type {Date}
     * @memberof PhoneCreate
     */
    appLastUpdatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof PhoneCreate
     */
    currentAppVersion: string;
}

/**
 * Check if a given object implements the PhoneCreate interface.
 */
export function instanceOfPhoneCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "androidId" in value;
    isInstance = isInstance && "appLastUpdatedAt" in value;
    isInstance = isInstance && "currentAppVersion" in value;

    return isInstance;
}

export function PhoneCreateFromJSON(json: any): PhoneCreate {
    return PhoneCreateFromJSONTyped(json, false);
}

export function PhoneCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhoneCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicleDeviceId': !exists(json, 'vehicleDeviceId') ? undefined : json['vehicleDeviceId'],
        'androidId': json['androidId'],
        'appLastUpdatedAt': (new Date(json['appLastUpdatedAt'])),
        'currentAppVersion': json['currentAppVersion'],
    };
}

export function PhoneCreateToJSON(value?: PhoneCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicleDeviceId': value.vehicleDeviceId,
        'androidId': value.androidId,
        'appLastUpdatedAt': (value.appLastUpdatedAt.toISOString()),
        'currentAppVersion': value.currentAppVersion,
    };
}

