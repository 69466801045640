import * as React from "react";
import { external } from "tsdi";
import { IColumn } from "@fluentui/react";
import { makeObservable, computed } from "mobx";
import { observer } from "mobx-react";
import { inject } from "tsdi";
import { UUID } from "../../utils/uuid";
import { MotionSensor, VehicleDevice } from "../../api";
import { RepositoryVehicleDevices } from "../../domain/repositories/repository-vehicle-devices";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryMotionSensors } from "../../domain/repositories/repository-motion-sensors";

export interface ListMotionSensorItemCellProps {
    readonly motionSensorId: UUID;
    readonly column: IColumn;
}

@external
@observer
export class ListMotionSensorItemCell extends React.Component<ListMotionSensorItemCellProps> {
    @inject private readonly repositoryVehicleDevices!: RepositoryVehicleDevices;
    @inject private readonly repositoryMotionSensors!: RepositoryMotionSensors;
    @inject protected readonly i18n!: I18nProvider;

    constructor(props: ListMotionSensorItemCellProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get motionSensor(): MotionSensor | undefined {
        if (this.props.motionSensorId === undefined) {
            return;
        }
        return this.repositoryMotionSensors.byId(this.props.motionSensorId);
    }

    @computed private get vehicleDevice(): VehicleDevice | undefined {
        if (!this.motionSensor?.vehicleDeviceId) {
            return;
        }
        return this.repositoryVehicleDevices.byId(this.motionSensor.vehicleDeviceId);
    }

    public render(): JSX.Element {
        const { column } = this.props;
        switch (column.key) {
            case "vehicleDeviceSerialNumber":
                return <span>{this.vehicleDevice?.serialNumber}</span>;
            case "firmwareLastUpdatedAt":
                return (
                    <span>
                        {this.motionSensor?.firmwareLastUpdatedAt
                            ? this.i18n.formatDateTime(this.motionSensor.firmwareLastUpdatedAt)
                            : ""}
                    </span>
                );
            case "currentFirmwareVersion":
                return <span>{this.motionSensor?.currentVersion}</span>;
            default:
                return <></>;
        }
    }
}
