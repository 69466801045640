import { Segment, FetchByQueryResult } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    PageInfo,
    Phone,
    PhoneCreate,
    PhonesApi,
    PhonesListPhonesRequest,
    PhoneSortKey,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";

export type PhonesQuery = Omit<PhonesListPhonesRequest, "page">;

export interface PhonesApiResource extends ApiResource {
    entity: Phone;
    query: PhonesQuery;
    create: PhoneCreate;
    sortKey: PhoneSortKey;
}

@component
export class RepositoryPhones extends ElofleetRepository<PhonesApiResource> {
    @inject private readonly phonesApi!: PhonesApi;

    public fetchMetadata(query: PhonesQuery): Promise<PageInfo> {
        return this.phonesApi.phonesListPhonesMetadata(query);
    }

    public update(): Promise<Phone> {
        throw new Error("Cannot update phones: Not implemented.");
    }

    public create(): Promise<Phone> {
        throw new Error("Cannot update phones: Not implemented.");
    }

    public delete(): Promise<void> {
        throw new Error("Cannot delete phones: Not implemented.");
    }

    protected async fetchByQuery(
        query: PhonesQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<Phone>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.phonesApi.phonesListPhones({ ...query, page });
        return { entities };
    }

    protected fetchById(phoneId: string): Promise<Phone | undefined> {
        return this.phonesApi.phonesReadPhone({ phoneId });
    }
    protected extractId = prop("id");
}
