import * as React from "react";
import {
    ContextualMenu,
    ContextualMenuItem,
    ContextualMenuItemType,
    IContextualMenuItem,
    IContextualMenuItemProps,
    IPersonaProps,
    Persona,
    PersonaSize,
    TooltipHost,
} from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { external, inject } from "tsdi";
import { User } from "../../api";
import { ServiceFile } from "../../domain/services/service-file";
import { ServiceOwnUser } from "../../domain/services/service-own-user";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import css from "./user-menu.scss";
import colors from "../colors.scss";
import { HistoryProvider } from "../../domain/providers/history-provider";
import { routePath } from "../../routes";
import { LogoutReason, ServiceAuth } from "../../domain/services/service-auth";

export interface UserMenuProps {}

@external
@observer
export class UserMenu extends React.Component<UserMenuProps> {
    @inject private serviceOwnUser!: ServiceOwnUser;
    @inject private serviceFile!: ServiceFile;
    @inject private i18n!: I18nProvider;
    @inject private historyProvider!: HistoryProvider;
    @inject private serviceAuth!: ServiceAuth;

    @observable private menuOpened = false;

    private personaRef = React.createRef<HTMLDivElement>();

    constructor(props: UserMenuProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get user(): User | undefined {
        return this.serviceOwnUser.user;
    }

    @computed private get imageUrl(): string | undefined {
        if (!this.user || !this.user.imageId) {
            return;
        }
        return this.serviceFile.getFileUri(this.user.imageId);
    }

    @computed private get initials(): string | undefined {
        if (!this.user || this.imageUrl) {
            return;
        }
        return `${this.user.firstName[0]}${this.user.lastName[0]}`.toLocaleUpperCase();
    }

    @computed private get items(): IContextualMenuItem[] {
        return [
            {
                key: "profile",
                className: css.userMenu__personaMenuItem,
                itemType: ContextualMenuItemType.Header,
            },
            { key: "divider1", itemType: ContextualMenuItemType.Divider },
            {
                key: "settings",
                text: this.i18n.t("component.userMenu.settings"),
                onClick: this.navigateToSettings,
            },
            { key: "divider2", itemType: ContextualMenuItemType.Divider },
            {
                key: "logout",
                text: this.i18n.t("component.userMenu.logout"),
                onClick: this.logout,
            },
        ];
    }

    private renderMenuItem(item: IContextualMenuItemProps): JSX.Element {
        if (item.index === 0) {
            return (
                <div className={css.userMenu__menuPersonaWrapper}>
                    <Persona {...this.personaProps} />
                </div>
            );
        }
        return <ContextualMenuItem {...item} />;
    }

    @action.bound private async logout(): Promise<void> {
        await this.serviceAuth.logout(LogoutReason.USER_LOGOUT);
    }

    @action.bound private navigateToSettings(): void {
        this.historyProvider.history.push(routePath.settings());
    }

    @action.bound private toggleMenu(): void {
        this.menuOpened = !this.menuOpened;
    }

    @action.bound private closeMenu(): void {
        this.menuOpened = false;
    }

    @computed private get personaProps(): IPersonaProps {
        return {
            size: PersonaSize.size48,
            imageUrl: this.imageUrl,
            imageInitials: this.initials,
            text: this.i18n.formatUserFullName(this.user),
            secondaryText: this.user!.email ?? undefined,
            initialsColor: colors["secondarysidebar-background"],
        };
    }

    public render(): JSX.Element {
        if (!this.user) {
            return <></>;
        }
        return (
            <div className={css.userMenu}>
                <TooltipHost content={this.i18n.t("component.userMenu.tooltip")}>
                    <div
                        ref={this.personaRef}
                        onClick={this.toggleMenu}
                        className={css.userMenu__personaWrapper}
                    >
                        <Persona
                            {...this.personaProps}
                            hidePersonaDetails
                            size={PersonaSize.size32}
                        />
                    </div>
                </TooltipHost>
                <ContextualMenu
                    contextualMenuItemAs={(item) => this.renderMenuItem(item)}
                    hidden={!this.menuOpened}
                    target={this.personaRef.current}
                    items={this.items}
                    onDismiss={this.closeMenu}
                />
            </div>
        );
    }
}
