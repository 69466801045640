import { component, inject } from "tsdi";
import { FilesApi, ResponseError } from "../../api";
import { FileType } from "../../api/models";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { ServiceErrors } from "../../domain/services/service-errors";
import { ValidationProblem } from "../../utils/validation";

const BasePathFileApi = "/api/v1/files/";

// This custom file service was created because the generated OPENAPI
// codes do not work properly in streaming downloads and uploads.
// You can create only one file for an entity.
@component
export class ServiceFile {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly serviceErrors!: ServiceErrors;
    @inject private readonly filesApi!: FilesApi;

    public async createFile(
        entityId: string,
        fileType: FileType,
        image: Uint8Array,
    ): Promise<Response> {
        // Create or update(if exists) the file.
        const request = new Request(BasePathFileApi + fileType + "/" + entityId, {
            method: "POST",
            credentials: "same-origin",
            body: image,
        });

        const response = await fetch(request);
        if (response.status >= 500) {
            throw new ResponseError(response, "Response returned an error code");
        }

        return response;
    }

    public async createImageFile(
        entityId: string,
        fileType: FileType,
        image: Uint8Array,
    ): Promise<string | null> {
        const response = await this.createFile(entityId, fileType, image);
        const body = await response.json();

        if (typeof body == "object") {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const imageField: Array<ValidationProblem> = body.error?.details?.image?.field ?? [];
            if (imageField[0]?.code == "image_too_large") {
                this.serviceErrors.reportError({
                    title: "component.errorBar.image.fileSize.title",
                    description: "component.errorBar.image.fileSize.description",
                    additionalInformation: {
                        maxSize: this.i18n.formatFloat(
                            (imageField[0].params["max_size"] as number) / 1024 / 1024,
                            2,
                        ),
                        status: response.status,
                        statusText: response.statusText,
                        url: response.url,
                        body,
                    },
                });
            } else if (imageField[0]?.code == "image_dimensions_too_small") {
                this.serviceErrors.reportError({
                    title: "component.errorBar.image.dimensionsTooSmall.title",
                    description: "component.errorBar.image.dimensionsTooSmall.description",
                    additionalInformation: {
                        minWidth: imageField[0].params.min_width,
                        minHeight: imageField[0].params.min_height,
                        status: response.status,
                        statusText: response.statusText,
                        url: response.url,
                        body,
                    },
                });
            } else if (imageField[0]?.code == "image_dimensions_too_large") {
                this.serviceErrors.reportError({
                    title: "component.errorBar.image.dimensionsTooLarge.title",
                    description: "component.errorBar.image.dimensionsTooLarge.description",
                    additionalInformation: {
                        maxWidth: imageField[0].params.max_width,
                        maxHeight: imageField[0].params.max_height,
                        status: response.status,
                        statusText: response.statusText,
                        url: response.url,
                        body,
                    },
                });
            } else if (imageField[0]?.code == "invalid_image_data") {
                this.serviceErrors.reportError({
                    title: "component.errorBar.image.invalidImageData.title",
                    description: "component.errorBar.image.invalidImageData.description",
                    additionalInformation: {
                        status: response.status,
                        statusText: response.statusText,
                        url: response.url,
                        body,
                    },
                });
            } else {
                throw new ResponseError(response, "Response returned an error code");
            }

            return null;
        }

        return body;
    }

    // It gives file as blob.
    public async getFile(fileId: string): Promise<Blob> {
        const request = new Request(BasePathFileApi + fileId, {
            method: "GET",
            credentials: "same-origin",
        });

        const file = await fetch(request).then((response) => response.blob());
        return file;
    }

    // It produces uri of file.
    public getFileUri(fileId: string | null | undefined): string | undefined {
        if (!fileId) {
            return;
        }
        return BasePathFileApi + encodeURIComponent(String(fileId));
    }

    public async deleteFile(fileId: string): Promise<void> {
        return this.filesApi.filesDeleteFile({ fileId });
    }
}
