import {
    ActionButton,
    DatePicker as FluentUiDatePicker,
    DateRangeType,
    DayOfWeek,
    Dropdown,
    ICalendarProps,
    IDatePickerProps,
    IDropdownOption,
    IIconProps,
    Stack,
} from "@fluentui/react";
import {
    addMonths,
    addYears,
    endOfDay,
    endOfISOWeek,
    endOfMonth,
    startOfDay,
    startOfISOWeek,
    startOfMonth,
    startOfYear,
} from "date-fns";
import { addDays, endOfYear } from "date-fns";
import { observable, action, makeObservable, computed } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";

export interface DatePickerProps {
    onDateRangeChanged: (dateStart?: Date, dateEnd?: Date) => void;
}

enum DateType {
    DAY = "Day",
    WEEK = "Week",
    MONTH = "Month",
    YEAR = "Year",
}

enum TimeFrame {
    CURRENT = "Current",
    LAST = "Last",
    // Allows to select an interval of given DateType.
    CUSTOM = "Custom",
}

@external
@observer
export class DatePicker extends React.Component<DatePickerProps> {
    @inject protected readonly i18n!: I18nProvider;

    // The selection defaults to current week.
    @observable public dateType = DateType.WEEK;
    @observable public timeFrameOption = TimeFrame.CURRENT;
    @observable public dateStart: Date = startOfISOWeek(new Date());
    @observable public dateEnd: Date = endOfISOWeek(new Date());
    @observable public isFilteredByDateRange: boolean = true;

    constructor(props: DatePickerProps) {
        super(props);
        makeObservable(this);
    }

    @computed get dateTypeOptions(): IDropdownOption[] {
        const options = Object.values(DateType).map((option: DateType) => {
            const text = this.formatDateType(option);
            return { key: option.toString(), text };
        });
        return options;
    }

    @computed get timeFrameOptions(): IDropdownOption[] {
        const options = Object.values(TimeFrame).map((option: TimeFrame) => {
            const text = this.formatTimeFrame(option);
            return { key: option.toString(), text };
        });
        return options;
    }

    @computed get isCustom(): boolean {
        if (this.timeFrameOption === TimeFrame.CUSTOM) {
            return true;
        }
        return false;
    }

    /** Dynamic properties for the two date pickers. */
    @computed get datePickerProps(): IDatePickerProps | undefined {
        switch (this.dateType) {
            case DateType.DAY:
                return {
                    showWeekNumbers: true,
                };
            case DateType.WEEK:
                return {
                    showWeekNumbers: true,
                };
            case DateType.MONTH:
                return {
                    highlightSelectedMonth: true,
                };
            case DateType.YEAR:
                return {
                    highlightSelectedMonth: true,
                };
        }
    }

    /** Dynamic properties of the input calendars for the date pickers. */
    @computed get calenderProps(): ICalendarProps | undefined {
        switch (this.dateType) {
            case DateType.DAY:
                return {
                    isMonthPickerVisible: false,
                };
            case DateType.WEEK:
                return {
                    isMonthPickerVisible: false,
                    dateRangeType: DateRangeType.Week,
                    showWeekNumbers: true,
                };
            case DateType.MONTH:
                return {
                    isDayPickerVisible: false,
                };
            case DateType.YEAR:
                // Unfortunately there is nothing like a year picker only,
                // therefore use the month picker with the integrated year picker.
                // Right now the user still has the possibility to use the month picker.
                return {
                    isDayPickerVisible: false,
                };
        }
    }

    @action.bound private setDateType(dateType: IDropdownOption<DateType> | undefined): void {
        if (dateType === undefined) {
            return;
        }
        this.isFilteredByDateRange = true;
        this.dateType = dateType.key as DateType;
        this.updateDateSelect();
    }

    @action.bound private setTimeFrame(
        timeFrameOption: IDropdownOption<TimeFrame> | undefined,
    ): void {
        if (timeFrameOption === undefined) {
            return;
        }
        this.isFilteredByDateRange = true;
        this.timeFrameOption = timeFrameOption.key as TimeFrame;
        this.updateDateSelect();
    }

    /** This function updates the date selection. We have to differentiate between
     * the different DateTypes and TimeFrames before setting the date.
     */
    @action.bound private updateDateSelect(): void {
        switch (this.dateType) {
            case DateType.DAY:
                this.setDayTimeFrame();
                break;
            case DateType.WEEK:
                this.setWeekTimeFrame();
                break;
            case DateType.MONTH:
                this.setMonthTimeFrame();
                break;
            case DateType.YEAR:
                this.setYearTimeFrame();
                break;
            default:
                break;
        }
        this.props.onDateRangeChanged(this.dateStart, this.dateEnd);
    }

    @action.bound private setDayTimeFrame(): void {
        switch (this.timeFrameOption) {
            case TimeFrame.LAST:
                this.dateStart = startOfDay(addDays(new Date(), -1));
                this.dateEnd = endOfDay(this.dateStart);
                break;
            // For CURRENT and CUSTOM we set the current day. A custom date
            // is set by the datepickers.
            default:
                this.dateStart = startOfDay(new Date());
                this.dateEnd = endOfDay(this.dateStart);
                break;
        }
    }

    @action.bound private setWeekTimeFrame(): void {
        switch (this.timeFrameOption) {
            case TimeFrame.LAST:
                this.dateStart = startOfISOWeek(addDays(new Date(), -7));
                this.dateEnd = endOfISOWeek(this.dateStart);
                break;
            // For CURRENT and CUSTOM we set the current week. A custom date
            // is set by the datepickers.
            default:
                this.dateStart = startOfISOWeek(new Date());
                this.dateEnd = endOfISOWeek(this.dateStart);
                break;
        }
    }

    @action.bound private setMonthTimeFrame(): void {
        switch (this.timeFrameOption) {
            case TimeFrame.LAST:
                this.dateStart = addMonths(startOfMonth(new Date()), -1);
                this.dateEnd = endOfMonth(this.dateStart);
                break;
            // For CURRENT and CUSTOM we set the current month. A custom date
            // is set by the datepickers.
            default:
                this.dateStart = startOfMonth(new Date());
                this.dateEnd = endOfMonth(this.dateStart);
                break;
        }
    }

    @action.bound private setYearTimeFrame(): void {
        switch (this.timeFrameOption) {
            case TimeFrame.LAST:
                this.dateStart = addYears(startOfYear(new Date()), -1);
                this.dateEnd = endOfYear(this.dateStart);
                break;
            // For CURRENT and CUSTOM we set the current year. A custom date
            // is set by the datepickers.
            default:
                this.dateStart = startOfYear(new Date());
                this.dateEnd = endOfYear(this.dateStart);
                break;
        }
    }

    /** This function is passed into the first date time picker and
     * sets dateStart via user input.
     */
    @action.bound private setCustomDateStart(date: Date | null | undefined): void {
        if (date == null || date == undefined) return;

        // If week selection is active, always set the date to the start of the week.
        if (this.dateType === DateType.WEEK) {
            this.dateStart = startOfISOWeek(date);
        } else {
            this.dateStart = startOfDay(date);
        }
        this.isFilteredByDateRange = true;
        this.props.onDateRangeChanged(this.dateStart, this.dateEnd);
    }

    /** This function is passed into the second date time picker and
     * sets dateEnd via user input.
     */
    @action.bound private setCustomDateEnd(date: Date | null | undefined): void {
        if (date == null || date == undefined) return;

        // If week selection is active, always set the date to the end of the week.
        if (this.dateType === DateType.WEEK) {
            this.dateEnd = endOfISOWeek(date);
        } else {
            this.dateEnd = endOfDay(date);
        }
        this.isFilteredByDateRange = true;
        this.props.onDateRangeChanged(this.dateStart, this.dateEnd);
    }

    @action.bound private clearFilter(): void {
        this.dateType = DateType.WEEK;
        this.timeFrameOption = TimeFrame.CURRENT;
        this.isFilteredByDateRange = false;
        this.dateStart = startOfISOWeek(new Date());
        this.dateEnd = endOfISOWeek(new Date());
        this.props.onDateRangeChanged(this.dateStart, this.dateEnd);
    }

    clearFilterIcon: IIconProps = { iconName: "ClearFilter" };

    public getFormattedDate = (date?: Date): string => {
        return this.i18n.formatDateOnly(date!);
    };

    public formatDateType(dateType: DateType): string {
        let date = "";
        switch (dateType) {
            case DateType.DAY:
                date = this.i18n.t("component.datePicker.day");
                break;
            case DateType.WEEK:
                date = this.i18n.t("component.datePicker.week");
                break;
            case DateType.MONTH:
                date = this.i18n.t("component.datePicker.month");
                break;
            case DateType.YEAR:
                date = this.i18n.t("component.datePicker.year");
                break;
            default:
                break;
        }
        return date;
    }

    public formatTimeFrame(timeFrame: TimeFrame): string {
        let frame = "";
        switch (timeFrame) {
            case TimeFrame.CURRENT:
                frame = this.i18n.t("component.datePicker.current");
                break;
            case TimeFrame.LAST:
                frame = this.i18n.t("component.datePicker.last");
                break;
            case TimeFrame.CUSTOM:
                frame = this.i18n.t("component.datePicker.custom");
                break;
            default:
                break;
        }
        return frame;
    }
    public render(): JSX.Element {
        return (
            <>
                <Stack.Item>
                    <Dropdown
                        styles={{
                            dropdown: { width: "85px" },
                        }}
                        options={this.dateTypeOptions}
                        selectedKey={this.dateType}
                        onChange={(
                            _event: React.FormEvent<HTMLDivElement>,
                            dateType: IDropdownOption<DateType> | undefined,
                        ) => {
                            this.setDateType(dateType);
                        }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        styles={{
                            dropdown: { width: "145px" },
                        }}
                        options={this.timeFrameOptions}
                        selectedKey={this.timeFrameOption}
                        onChange={(
                            _event: React.FormEvent<HTMLDivElement>,
                            timeFrameOption: IDropdownOption<TimeFrame> | undefined,
                        ) => {
                            this.setTimeFrame(timeFrameOption);
                        }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <FluentUiDatePicker
                        {...this.datePickerProps}
                        value={this.dateStart}
                        onSelectDate={this.setCustomDateStart}
                        formatDate={this.getFormattedDate}
                        placeholder={this.i18n.t("component.datepicker.rangeStartDate")}
                        style={{ width: "165px" }}
                        calendarProps={this.calenderProps}
                        firstDayOfWeek={DayOfWeek.Monday}
                        disabled={!this.isCustom}
                    />
                </Stack.Item>
                <Stack.Item>
                    <FluentUiDatePicker
                        {...this.datePickerProps}
                        value={this.dateEnd}
                        onSelectDate={this.setCustomDateEnd}
                        formatDate={this.getFormattedDate}
                        placeholder={this.i18n.t("component.datepicker.rangeEndDate")}
                        style={{ width: "165px" }}
                        calendarProps={this.calenderProps}
                        firstDayOfWeek={DayOfWeek.Monday}
                        disabled={!this.isCustom}
                    />
                </Stack.Item>
                <Stack.Item>
                    <ActionButton
                        iconProps={this.clearFilterIcon}
                        allowDisabledFocus
                        disabled={!this.isFilteredByDateRange}
                        checked={true}
                        onClick={this.clearFilter}
                    ></ActionButton>
                </Stack.Item>
            </>
        );
    }
}
