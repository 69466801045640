/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleListStats
 */
export interface VehicleListStats {
    /**
     * 
     * @type {number}
     * @memberof VehicleListStats
     */
    totalOperatingSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleListStats
     */
    totalLoginSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleListStats
     */
    totalDriveSeconds: number;
}

/**
 * Check if a given object implements the VehicleListStats interface.
 */
export function instanceOfVehicleListStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalOperatingSeconds" in value;
    isInstance = isInstance && "totalLoginSeconds" in value;
    isInstance = isInstance && "totalDriveSeconds" in value;

    return isInstance;
}

export function VehicleListStatsFromJSON(json: any): VehicleListStats {
    return VehicleListStatsFromJSONTyped(json, false);
}

export function VehicleListStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleListStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalOperatingSeconds': json['totalOperatingSeconds'],
        'totalLoginSeconds': json['totalLoginSeconds'],
        'totalDriveSeconds': json['totalDriveSeconds'],
    };
}

export function VehicleListStatsToJSON(value?: VehicleListStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalOperatingSeconds': value.totalOperatingSeconds,
        'totalLoginSeconds': value.totalLoginSeconds,
        'totalDriveSeconds': value.totalDriveSeconds,
    };
}

