import * as React from "react";
import { ElodmsLayout } from "../../components/layout";
import { useStore } from "../../stores/store";
import { useParams } from "react-router";
import { Equipment, EquipmentType } from "../../stores/equipment";
import { UUID } from "../../../utils/uuid";
import { Link } from "react-router-dom";
import routes from "../../routes";
import Icon from "@mdi/react";
import { mdiChevronRight, mdiMagnify } from "@mdi/js";
import { formatCurrency } from "../../../utils/currency";
import Fuse from "fuse.js";
import { TextInput } from "../../components/text-input";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

export enum ListType {
    All = "all",
    Forklifts = "forklifts",
    TowTrucks = "towTrucks",
    Batteries = "batteries",
    AGVs = "agvs",
    AMRs = "amrs",
    HandPalletJacks = "handPalletJacks",
    CleaningMachines = "cleaningMachines",
}

function listTypeHeader(listType: ListType, t: TFunction): string {
    switch (listType) {
        case ListType.All:
            return t("dms.schema.equipment.equipment");
        case ListType.Forklifts:
            return t("dms.schema.equipment.type.trucks");
        case ListType.TowTrucks:
            return t("dms.schema.equipment.type.towTrucks");
        case ListType.Batteries:
            return t("dms.schema.equipment.type.batteries");
        case ListType.AGVs:
            return t("dms.schema.equipment.type.agvs");
        case ListType.AMRs:
            return t("dms.schema.equipment.type.amrs");
        case ListType.HandPalletJacks:
            return t("dms.schema.equipment.type.handPalletJacks");
        case ListType.CleaningMachines:
            return t("dms.schema.equipment.type.cleaningMachines");
    }
}

export function ListEquipment(): JSX.Element {
    const { listType } = useParams();
    const [searchString, setSearchString] = React.useState("");
    const { t }: { t: TFunction } = useTranslation();

    const equipment: Array<[UUID, Equipment]> = useStore((state) => {
        let filteredEquipment = Object.entries(state.equipment)
            .filter(([_equipmentId, equipment]) => {
                switch (listType) {
                    case ListType.All:
                        return true;
                    case ListType.Forklifts:
                        return equipment.equipmentType === EquipmentType.Truck;
                    case ListType.Batteries:
                        return equipment.equipmentType === EquipmentType.Battery;
                    case ListType.AGVs:
                        return equipment.equipmentType === EquipmentType.AGV;
                    case ListType.AMRs:
                        return equipment.equipmentType === EquipmentType.AMR;
                    case ListType.CleaningMachines:
                        return equipment.equipmentType === EquipmentType.CleaningMachine;
                    case ListType.HandPalletJacks:
                        return equipment.equipmentType === EquipmentType.HandPalletJack;
                    case ListType.TowTrucks:
                        return equipment.equipmentType === EquipmentType.TowTruck;
                }
            })
            .map(([equipmentId, equipment]) => ({
                id: equipmentId,
                equipment: {
                    ...equipment,
                    // Translate properties here to enable searching by translated value
                    department: t(equipment.department ?? ""),
                    oem: t(equipment.oem ?? ""),
                    modelName: t(equipment.modelName),
                },
            }));

        if (searchString) {
            const fuse = new Fuse(filteredEquipment, {
                keys: [
                    "equipment.oem",
                    "equipment.modelName",
                    "equipment.serialNumber",
                    "equipment.department",
                    "equipment.equipmentType",
                    "equipment.manufactureYear",
                ],
                minMatchCharLength: 2,
                threshold: 0.5,
            });
            filteredEquipment = fuse.search(searchString).map(({ item }) => item);
        }

        return filteredEquipment.map(({ id, equipment }) => [id, equipment]);
    });

    const equipmentIds = equipment.map(([equipmentId, _equipment]) => equipmentId);
    const documents = useStore((state) =>
        Object.values(state.documents).filter((document) =>
            equipmentIds.includes(document.equipmentId),
        ),
    );

    const equipmentWithTco: Array<[UUID, Equipment, number]> = equipment.map(
        ([equipmentId, equipment]) => [
            equipmentId,
            equipment,
            documents
                .filter((document) => document.equipmentId === equipmentId)
                .flatMap((document) => document.costItems)
                .map((costItem) => costItem.amount)
                .reduce((a, b) => a + b, 0),
        ],
    );

    return (
        <ElodmsLayout
            title={listTypeHeader((listType as ListType | undefined) ?? ListType.All, t)}
            className="pb-16"
        >
            <div className="flex items-center border-b border-slate-300 px-8 py-4">
                <Icon path={mdiMagnify} className="mr-1 h-6 text-neutral-500" />
                <TextInput
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                    className="form-input w-full border-none bg-transparent"
                    placeholder={t("dms.page.listEquipment.search")}
                />
            </div>
            <table className="w-full">
                <thead className="h-16 border-b-2 border-b-blue-900 text-left font-header">
                    <tr>
                        <th className="pl-8 pr-4">
                            {t("dms.schema.equipment.oem") +
                                " / " +
                                t("dms.schema.equipment.model")}
                        </th>
                        <th className="pr-4">
                            <>{t("dms.schema.equipment.serialNumber")}</>
                        </th>
                        <th className="pr-4">
                            <>{t("dms.schema.equipment.department")}</>
                        </th>
                        <th className="pr-4 text-right">
                            <>{t("dms.schema.equipment.tco")}</>
                        </th>
                        <th className="pr-8"></th>
                    </tr>
                </thead>
                <tbody>
                    {equipmentWithTco.map(([equipmentId, equipment, tco]) => (
                        <tr
                            key={equipmentId}
                            className="h-12 border-b border-b-slate-300 hover:bg-gray-100"
                        >
                            <td className="pl-8 pr-4">
                                {equipment.oem} {equipment.modelName}
                            </td>
                            <td className="pr-4">#{equipment.serialNumber}</td>
                            <td className="pr-4">{equipment.department}</td>
                            <td className="pr-4 text-right">{formatCurrency(t, tco)}</td>
                            <td className="w-20 text-neutral-500">
                                <Link
                                    to={routes.viewEquipment.generateUrl(equipmentId)}
                                    // Make the link big so it's easily clickable
                                    className="flex h-12 items-center justify-end pr-8"
                                >
                                    <Icon path={mdiChevronRight} className="h-6 w-6" />
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </ElodmsLayout>
    );
}
