import { makeObservable, observable, runInAction } from "mobx";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    PageInfo,
    ResponseError,
    VehicleDeviceCreate,
    VehicleDevicesApi,
    VehicleDevicesListVehicleDevicesRequest,
    VehicleDeviceSortKey,
    VehicleDeviceUpdate,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { Validation } from "../../utils/validation";
import { VehicleDevice } from "../../api";
import { defaultPageSize } from "../../utils/constants";

export type VehicleDevicesQuery = Omit<VehicleDevicesListVehicleDevicesRequest, "page"> & {
    /**
     * Specify this to load the vehicle device associated with the specified vehicle.
     * This is guaranteed to return a maximum of one vehicle device.
     *
     * @deprecated
     */
    vehicleId?: UUID;
};

export interface VehicleDevicesApiResource extends ApiResource {
    entity: VehicleDevice;
    query: VehicleDevicesQuery;
    update: VehicleDeviceUpdate;
    create: VehicleDeviceCreate;
    sortKey: VehicleDeviceSortKey;
}

@component
export class RepositoryVehicleDevices extends ElofleetRepository<VehicleDevicesApiResource> {
    @inject private readonly vehicleDevicesApi!: VehicleDevicesApi;

    @observable private readonly attemptedVehicleIds = new Set<UUID>();

    constructor() {
        super();
        makeObservable(this);
    }

    protected async fetchById(vehicleDeviceId: UUID): Promise<VehicleDevice | undefined> {
        return await this.vehicleDevicesApi.vehicleDevicesReadVehicleDevice({
            vehicleDeviceId,
        });
    }

    public validation = {
        create: new Validation((vehicleDeviceCreate: VehicleDeviceCreate) =>
            this.vehicleDevicesApi.vehicleDevicesValidateCreateVehicleDevice({
                vehicleDeviceCreate: vehicleDeviceCreate,
            }),
        ),
        update: new Validation((vehicleDeviceUpdate: VehicleDeviceUpdate, vehicleDeviceId: UUID) =>
            this.vehicleDevicesApi.vehicleDevicesValidateUpdateVehicleDevice({
                vehicleDeviceId,
                vehicleDeviceUpdate,
            }),
        ),
    };

    protected extractId = prop("id");

    protected async fetchByQuery(
        query: VehicleDevicesQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<VehicleDevice>> {
        if (query.vehicleId) {
            try {
                const vehicleDevice =
                    await this.vehicleDevicesApi.vehicleDevicesReadVehicleDeviceByVehicle({
                        vehicleId: query.vehicleId,
                    });
                return { entities: [vehicleDevice] };
            } catch (err) {
                if (err instanceof ResponseError && err.response.status === 404) {
                    return { entities: [] };
                }
                this.handleError(err);
                throw err;
            } finally {
                runInAction(() => this.attemptedVehicleIds.add(query.vehicleId!));
            }
        }
        const page = pagination.offset / this.defaultCount;
        const entities = await this.vehicleDevicesApi.vehicleDevicesListVehicleDevices({
            ...query,
            page,
        });
        return { entities };
    }

    public fetchMetadata(query: VehicleDevicesQuery): Promise<PageInfo> {
        if (query.vehicleId) {
            return Promise.resolve({
                totalPages: 1,
                pageSize: 1,
                totalCount: 1,
            });
        }
        return this.vehicleDevicesApi.vehicleDevicesListVehicleDevicesMetadata(query);
    }

    public async update(
        vehicleDeviceId: UUID,
        vehicleDeviceUpdate: VehicleDeviceUpdate,
    ): Promise<VehicleDevice> {
        const vehicleDevice = await this.wrapApiCall(
            this.vehicleDevicesApi.vehicleDevicesUpdateVehicleDevice({
                vehicleDeviceUpdate,
                vehicleDeviceId,
            }),
        );
        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(vehicleDevice);
        });

        return vehicleDevice;
    }

    public async create(vehicleDeviceCreate: VehicleDeviceCreate): Promise<VehicleDevice> {
        const vehicleDevice = await this.wrapApiCall(
            this.vehicleDevicesApi.vehicleDevicesCreateVehicleDevice({
                vehicleDeviceCreate,
            }),
        );
        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(vehicleDevice);
        });

        return vehicleDevice;
    }

    public async delete(...vehicleDeviceIds: UUID[]): Promise<void> {
        await this.wrapApiCall(
            Promise.all(
                vehicleDeviceIds.map((vehicleDeviceId) =>
                    this.vehicleDevicesApi.vehicleDevicesDeleteVehicleDevice({ vehicleDeviceId }),
                ),
            ),
        );

        // This reload is necessary to reload all queries that want to display "all" entities.
        await this.waitForIdle();
        this.reloadQuery({ pageSize: defaultPageSize });
    }

    // TODO this is a temporary workaround.
    // Once https://gitlab.com/elokon/elofleet/elofleet/-/issues/439 is implemented,
    // we can remove this.
    /** @deprecated */
    public byVehicleId(vehicleId: UUID): VehicleDevice | undefined {
        return this.byQuery({ pageSize: defaultPageSize, vehicleId })[0];
    }

    // TODO this is a temporary workaround.
    // Once https://gitlab.com/elokon/elofleet/elofleet/-/issues/439 is implemented,
    // we can remove this.
    /** @deprecated */
    public isVehicleIdKnown(vehicleId: UUID): boolean {
        return this.attemptedVehicleIds.has(vehicleId);
    }
}
