import { observer } from "mobx-react";
import * as React from "react";
import { ServiceSidebar } from "../../domain/services/service-sidebar";
import { tsdi } from "../../tsdi";
import HelpMenu from "./help-menu";
import { Navigation } from "./navigation";

export interface ElodmsLayoutProps {
    readonly title: string;
    readonly children: React.ReactNode;
    readonly className?: string;
}

export const ElodmsLayout = observer(function ElodmsLayout(props: ElodmsLayoutProps): JSX.Element {
    React.useEffect(() => {
        document.title = "ELOdms";
    }, []);

    const navigationWidth = "w-64 xl:w-72";
    const headerHeight = "h-16";

    const serviceSidebar = tsdi.get(ServiceSidebar);
    const logo = serviceSidebar.logo;

    return (
        <div className="preflight flex min-h-screen flex-col text-slate-800">
            <Navigation widthClasses={navigationWidth} topOffsetClasses={headerHeight} />
            <div className={`fixed inset-x-0 top-0 bg-white drop-shadow ${headerHeight}`}>
                <div className="flex h-full items-center">
                    <div className={`flex justify-center py-1 pl-2 ${navigationWidth}`}>
                        <a href="/elodms">
                            <img src={logo} className="h-12" />
                        </a>
                    </div>
                    <h1 className="grow items-center px-8 font-header text-2xl">{props.title}</h1>
                    <HelpMenu className="mx-4" />
                </div>
            </div>
            <div className={headerHeight} />
            <div className="flex grow">
                <div className={`shrink-0 ${navigationWidth}`} />
                <main className={`grow overflow-x-auto bg-gray-50 ${props.className ?? ""}`}>
                    {props.children}
                </main>
            </div>
        </div>
    );
});
