import { Segment, FetchByQueryResult } from "mobx-repository";
import { runInAction } from "mobx";
import { prop } from "ramda";
import { component, inject } from "tsdi";

import {
    PageInfo,
    PersistentNotification,
    PersistentNotificationUpdate,
    PersistentNotificationsApi,
    PersistentNotificationsListPersistentNotificationsRequest,
} from "../../api";

import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { defaultPageSize } from "../../utils/constants";

export type PersistentNotificationsQuery = Omit<
    PersistentNotificationsListPersistentNotificationsRequest,
    "page"
>;

export interface PersistentNotificationsApiResource extends ApiResource {
    entity: PersistentNotification;
    query: PersistentNotificationsQuery;
    update: PersistentNotificationUpdate;
    create: unknown;
    sortKey: string;
}

@component
export class RepositoryPersistentNotifications extends ElofleetRepository<PersistentNotificationsApiResource> {
    @inject private readonly persistentNotificationsApi!: PersistentNotificationsApi;

    public fetchMetadata(_: unknown): Promise<PageInfo> {
        return this.persistentNotificationsApi.persistentNotificationsListPersistentNotificationsMetadata(
            { pageSize: defaultPageSize },
        );
    }

    public async fetchAll(): Promise<void> {
        const metadata = await this.fetchMetadata({});
        for (let page = 0; page < metadata.totalPages; page++) {
            await this.byQueryAsync(
                {
                    pageSize: defaultPageSize,
                },
                { offset: page * metadata.pageSize, count: metadata.pageSize },
            );
        }
    }

    public async update(
        notificationId: UUID,
        persistentNotificationUpdate: PersistentNotificationUpdate,
    ): Promise<PersistentNotification> {
        const notification = await this.wrapApiCall(
            this.persistentNotificationsApi.persistentNotificationsUpdatePersistentNotification({
                persistentNotificationUpdate,
                notificationId,
            }),
        );

        await this.waitForIdle();
        runInAction(() => {
            this.reloadId(notificationId);
            this.add(notification);
        });

        return notification;
    }

    public create(): Promise<PersistentNotification> {
        throw new Error("Cannot create persistent notifications: Not implemented.");
    }

    public delete(): Promise<void> {
        throw new Error("Cannot delete persistent notifications: Not implemented.");
    }

    protected async fetchByQuery(
        query: PersistentNotificationsQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<PersistentNotification>> {
        const page = pagination.offset / this.defaultCount;
        const entities =
            await this.persistentNotificationsApi.persistentNotificationsListPersistentNotifications(
                { ...query, page },
            );
        return { entities };
    }

    protected fetchById(notificationId: string): Promise<PersistentNotification | undefined> {
        return this.persistentNotificationsApi.persistentNotificationsReadPersistentNotification({
            notificationId,
        });
    }

    protected extractId = prop("id");
}
