import { Dropdown, IDropdownOption, IDropdownProps, IconButton, Stack } from "@fluentui/react";
import { omit } from "ramda";
import { action, computed, makeObservable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { createUuid } from "../../utils/uuid";

export type ClearableDropdownProps<TKey extends string = string> = Omit<
    IDropdownProps,
    "onChange" | "selectedKey"
> & {
    readonly onChange?: (value: TKey | undefined) => void;
    readonly selectedKey?: TKey | null | TKey[];
};

@observer
export class ClearableDropdown<TKey extends string = string> extends React.Component<
    ClearableDropdownProps<TKey>
> {
    private static readonly emptyStringKey = createUuid();

    constructor(props: ClearableDropdownProps<TKey>) {
        super(props);
        makeObservable(this);
    }

    @action.bound private callChangeCallback(_evt: unknown, option?: IDropdownOption): void {
        this.props.onChange?.(option?.key as TKey);
    }

    @computed private get options(): IDropdownOption[] {
        return [
            { key: ClearableDropdown.emptyStringKey, text: "", hidden: true },
            ...(this.props.options ?? []),
        ];
    }

    @computed private get selectedKey(): string | number | string[] | number[] | null | undefined {
        if (this.props.selectedKey === undefined) {
            return ClearableDropdown.emptyStringKey;
        }
        return this.props.selectedKey;
    }

    public render(): JSX.Element {
        const props = omit(["onChange", "options", "selectedKey"], this.props);
        return (
            <Stack horizontal>
                <Stack.Item style={{ width: "100%" }}>
                    <Dropdown
                        options={this.options}
                        selectedKey={this.selectedKey}
                        onChange={this.callChangeCallback}
                        {...props}
                    />
                </Stack.Item>
                <Stack.Item align="end">
                    <IconButton
                        iconProps={{ iconName: "Cancel" }}
                        onClick={() => this.callChangeCallback(undefined, undefined)}
                    />
                </Stack.Item>
            </Stack>
        );
    }
}
