/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FleetRole {
    DRIVER = 'driver',
    MAINTENANCE_MANAGER = 'maintenanceManager',
    SUPERVISOR = 'supervisor'
}


export function FleetRoleFromJSON(json: any): FleetRole {
    return FleetRoleFromJSONTyped(json, false);
}

export function FleetRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): FleetRole {
    return json as FleetRole;
}

export function FleetRoleToJSON(value?: FleetRole | null): any {
    return value as any;
}

