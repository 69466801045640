import { Dialog, Transition } from "@headlessui/react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import * as React from "react";
import { FilePickerDocument } from "../stores/document";
import * as pdfjs from "pdfjs-dist/webpack";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export interface FilePickerModalProps {
    readonly onDismiss: () => void;
    readonly onSelect: (file: FilePickerDocument) => void;
    readonly isOpen: boolean;
    readonly files: Array<FilePickerDocument>;
}

export function FilePickerModal(props: FilePickerModalProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <Transition appear show={props.isOpen} as={React.Fragment}>
            {/* We use the preflight class here because the dialog's portal renders it outside of the normal app wrapper which has the .preflight class. */}
            <Dialog as="div" className="preflight relative z-10" onClose={props.onDismiss}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-lg bg-gray-50 p-8 pb-12 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="mb-8 font-header text-lg font-medium leading-6 text-gray-900"
                                >
                                    {t("dms.component.file_picker_modal.title")}
                                </Dialog.Title>
                                <button
                                    onClick={props.onDismiss}
                                    className="absolute top-4 right-4 text-neutral-500"
                                >
                                    <Icon path={mdiClose} className="h-6 w-6" />
                                </button>
                                <ModalContents files={props.files} onSelect={props.onSelect} />
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

function ModalContents({
    files,
    onSelect,
}: {
    files: FilePickerDocument[];
    onSelect: (file: FilePickerDocument) => void;
}): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();

    const renderThumbnail = React.useCallback(
        (file: FilePickerDocument, canvas: HTMLCanvasElement) => {
            pdfjs.getDocument(file.link).promise.then((document) => {
                document.getPage(1).then((page) => {
                    canvas.height = 128;
                    canvas.width = 96;

                    const viewport = page.getViewport({ scale: 1 });
                    const scale = canvas.height / viewport.height;

                    page.render({
                        canvasContext: canvas.getContext("2d") as Object,
                        viewport: page.getViewport({ scale }),
                    });
                });
            });
        },
        [],
    );

    return (
        <div className="flex flex-wrap justify-center gap-8">
            {files.map((document) => (
                <div
                    key={document.fileName}
                    onClick={() => onSelect(document)}
                    className="w-64 cursor-pointer overflow-hidden rounded border border-slate-300"
                >
                    <div className="w-full truncate border-b border-slate-300 bg-gray-100 py-4 text-center leading-6">
                        <>{t(document.name)}</>
                    </div>
                    <div className="flex flex-col items-center bg-white py-8 px-4 hover:bg-gray-100">
                        <canvas
                            ref={(element) => {
                                if (element) {
                                    renderThumbnail(document, element);
                                }
                            }}
                            className="h-32"
                        />
                        <div className="mt-2 text-sm text-neutral-500">{document.fileName}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}
