import React, { useEffect, useState } from "react";
import { NodeProps } from "reactflow";
import ActionConfigNode from "./action-config-node";
import { Checkbox } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { DeactivateAntennasData } from "../../../api";

export default function DeactivateAntennas(
    props: NodeProps<
        DeactivateAntennasData & { invalid?: boolean; validationErrorMessage?: string }
    >,
): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    const [antennaA, setAntennaA] = useState(props.data.antennaA ?? true);
    const [antennaBVehicle, setAntennaBVehicle] = useState(props.data.antennaBVehicle ?? true);
    const [antennaBStationary, setAntennaBStationary] = useState(
        props.data.antennaBStationary ?? true,
    );

    useEffect(() => {
        props.data.antennaA = antennaA;
        props.data.antennaBVehicle = antennaBVehicle;
        props.data.antennaBStationary = antennaBStationary;
    }, [props.data, antennaA, antennaBVehicle, antennaBStationary]);

    return (
        <ActionConfigNode nodeKind={props.type} {...props.data}>
            <Checkbox
                label={t("component.actionConfigNodes.deactivate_antenna.0")}
                checked={antennaA}
                onChange={(_e, checked) => setAntennaA(checked!)}
            />
            <Checkbox
                label={t("component.actionConfigNodes.deactivate_antenna.1")}
                checked={antennaBVehicle}
                onChange={(_e, checked) => setAntennaBVehicle(checked!)}
            />
            <Checkbox
                label={t("component.actionConfigNodes.deactivate_antenna.2")}
                checked={antennaBStationary}
                onChange={(_e, checked) => setAntennaBStationary(checked!)}
            />
        </ActionConfigNode>
    );
}
