/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShockEventChartDataPoint
 */
export interface ShockEventChartDataPoint {
    /**
     * 
     * @type {Date}
     * @memberof ShockEventChartDataPoint
     */
    bucket: Date;
    /**
     * 
     * @type {number}
     * @memberof ShockEventChartDataPoint
     */
    severe: number;
    /**
     * 
     * @type {number}
     * @memberof ShockEventChartDataPoint
     */
    medium: number;
    /**
     * 
     * @type {number}
     * @memberof ShockEventChartDataPoint
     */
    light: number;
}

/**
 * Check if a given object implements the ShockEventChartDataPoint interface.
 */
export function instanceOfShockEventChartDataPoint(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bucket" in value;
    isInstance = isInstance && "severe" in value;
    isInstance = isInstance && "medium" in value;
    isInstance = isInstance && "light" in value;

    return isInstance;
}

export function ShockEventChartDataPointFromJSON(json: any): ShockEventChartDataPoint {
    return ShockEventChartDataPointFromJSONTyped(json, false);
}

export function ShockEventChartDataPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShockEventChartDataPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bucket': (new Date(json['bucket'])),
        'severe': json['severe'],
        'medium': json['medium'],
        'light': json['light'],
    };
}

export function ShockEventChartDataPointToJSON(value?: ShockEventChartDataPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bucket': (value.bucket.toISOString()),
        'severe': value.severe,
        'medium': value.medium,
        'light': value.light,
    };
}

