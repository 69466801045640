/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleLastSynchronizedAtUpdate
 */
export interface VehicleLastSynchronizedAtUpdate {
    /**
     * The last time the vehicle has been synced with the server.
     * @type {Date}
     * @memberof VehicleLastSynchronizedAtUpdate
     */
    lastSynchronizedAt: Date;
}

/**
 * Check if a given object implements the VehicleLastSynchronizedAtUpdate interface.
 */
export function instanceOfVehicleLastSynchronizedAtUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastSynchronizedAt" in value;

    return isInstance;
}

export function VehicleLastSynchronizedAtUpdateFromJSON(json: any): VehicleLastSynchronizedAtUpdate {
    return VehicleLastSynchronizedAtUpdateFromJSONTyped(json, false);
}

export function VehicleLastSynchronizedAtUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleLastSynchronizedAtUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastSynchronizedAt': (new Date(json['lastSynchronizedAt'])),
    };
}

export function VehicleLastSynchronizedAtUpdateToJSON(value?: VehicleLastSynchronizedAtUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastSynchronizedAt': (value.lastSynchronizedAt.toISOString()),
    };
}

