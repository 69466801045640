import { Draft } from "immer";
import { StateCreator } from "zustand";
import { UUID, createUuid } from "../../utils/uuid";
import efgManualLink from "../assets/example-documents/A191118-2.pdf";
import dfgManualLink from "../assets/example-documents/201015-2.pdf";
import serviceInvoiceLink from "../assets/example-documents/ERE Richter 2020.pdf";
import serviceInvoice2Link from "../assets/example-documents/ERE Beutlhauser 2019.pdf";
import leasingContractLink from "../assets/example-documents/leasing_contract.pdf";
import rentalContractLink from "../assets/example-documents/forktruck_rent_contract.pdf";
import { demoEquipment, EquipmentType } from "./equipment";
import { TFunction } from "i18next";

export enum DocumentType {
    // Contract
    LeasingContract,
    RentalContract,
    ServiceContract,
    PurchaseContract,
    MaintenanceContract,
    ServiceLevelAgreement,

    // Invoice
    ServiceInvoice,
    MaintenanceInvoice,
    OtherInvoice,

    // Other
    OwnersManual,
    Certificate,
}

export enum DocumentGroup {
    Contract,
    Invoice,
    Other,
}

export enum CostType {
    Disposal = "disposal",
    Maintenance = "maintenance",
    Purchase = "purchase",
    Rental = "rental",
    Repair = "repair",
    SpareParts = "spareparts",
    Training = "training",
    Upgrades = "upgrades",
}

export function displayCostType(t: TFunction, costType: CostType): string {
    return t(`dms.schema.costType.${costType}`);
}

export interface CostItem {
    name: string;
    // In whole cents.
    amount: number;
    type: CostType;
}

export interface Document {
    name: string;
    documentType: DocumentType;

    costItems: Array<CostItem>;

    link?: string;

    equipmentId: UUID;
}

export interface DocumentsSlice {
    documents: Record<UUID, Document>;

    insertDocument: (equipment: Document) => UUID;
    updateDocument: (id: UUID, callback: (equipment: Draft<Document>) => void) => void;
}

export const createDocumentsSlice: StateCreator<DocumentsSlice, [["zustand/immer", never]], []> = (
    set,
) => ({
    documents: demoDocuments,

    insertDocument: (equipment: Document) => {
        const id = createUuid();
        set((state) => {
            state.documents[id] = equipment;
        });
        return id;
    },
    updateDocument: (id, callback) =>
        set((state) => {
            callback(state.documents[id]);
        }),
});

const demoDocuments: { [key: string]: Document } = {
    "1a7d6378-249e-423d-8301-2c8b83c1237d": {
        name: "dms.demo.file.efgManual",
        documentType: DocumentType.OwnersManual,
        link: efgManualLink,
        costItems: [],

        equipmentId: "d77bbdf3-8f4e-419f-8fd6-d3ab8344d7bf",
    },
    "abf3e82a-2e6d-4b35-be6f-923e496851f0": {
        name: "dms.demo.file.rentalContract",
        documentType: DocumentType.RentalContract,
        link: rentalContractLink,
        costItems: [],

        equipmentId: "fbda7cfd-ec49-45bb-8cb9-af9373120b82",
    },
    "26b5b580-da64-4750-a8f8-d2177938327d": {
        name: "dms.demo.file.dfgManual",
        documentType: DocumentType.OwnersManual,
        link: dfgManualLink,
        costItems: [],

        equipmentId: "fbda7cfd-ec49-45bb-8cb9-af9373120b82",
    },
};

// Attach some documents to multiple equipments
// So we have cost items for all our equipment
const expensiveEquipment = new Set(
    Object.keys(demoEquipment).filter((id) =>
        [
            EquipmentType.Truck,
            EquipmentType.AGV,
            EquipmentType.AMR,
            EquipmentType.TowTruck,
        ].includes(demoEquipment[id].equipmentType),
    ),
);

for (const equipmentId of expensiveEquipment) {
    demoDocuments[createUuid()] = {
        name: "dms.demo.file.leasingContract",
        documentType: DocumentType.LeasingContract,
        link: leasingContractLink,
        costItems: [],

        equipmentId,
    };
    demoDocuments[createUuid()] = {
        name: "dms.demo.file.leasingInvoice",
        documentType: DocumentType.OtherInvoice,
        costItems: [
            {
                name: "Leasing Fee",
                amount: Math.floor(5000_00 + Math.random() * 20000_00),
                type: CostType.Purchase,
            },
        ],

        equipmentId,
    };

    demoDocuments[createUuid()] = {
        name: "dms.demo.file.maintenanceInvoice",
        documentType: DocumentType.MaintenanceInvoice,
        link: serviceInvoiceLink,
        costItems: [
            {
                name: "",
                amount: Math.floor(300_00 + Math.random() * 400_00),
                type: CostType.Maintenance,
            },
        ],

        equipmentId,
    };
    demoDocuments[createUuid()] = {
        name: "dms.demo.file.maintenanceInvoice",
        documentType: DocumentType.MaintenanceInvoice,
        link: serviceInvoiceLink,
        costItems: [
            {
                name: "",
                amount: Math.floor(300_00 + Math.random() * 400_00),
                type: CostType.Maintenance,
            },
        ],

        equipmentId,
    };
    demoDocuments[createUuid()] = {
        name: "dms.demo.file.repairInvoice",
        documentType: DocumentType.ServiceInvoice,
        link: serviceInvoiceLink,
        costItems: [
            {
                name: "",
                amount: Math.floor(1000_00 + Math.random() * 3000_00),
                type: CostType.SpareParts,
            },
            {
                name: "",
                amount: Math.floor(500_00 + Math.random() * 1500_00),
                type: CostType.Repair,
            },
        ],

        equipmentId,
    };
    demoDocuments[createUuid()] = {
        name: "dms.demo.file.repairInvoice",
        documentType: DocumentType.ServiceInvoice,
        link: serviceInvoice2Link,
        costItems: [
            {
                name: "",
                amount: Math.floor(1000_00 + Math.random() * 500_00),
                type: CostType.SpareParts,
            },
            {
                name: "",
                amount: Math.floor(500_00 + Math.random() * 500_00),
                type: CostType.Repair,
            },
        ],

        equipmentId,
    };

    demoDocuments[createUuid()] = {
        name: "dms.demo.file.trainingInvoice",
        documentType: DocumentType.OtherInvoice,
        costItems: [
            {
                name: "Training Fee",
                amount: Math.floor(1000_00 + Math.random() * 400_00),
                type: CostType.Training,
            },
        ],

        equipmentId,
    };
}

const notSoExpensiveEquipment = new Set(
    Object.keys(demoEquipment).filter((id) => !expensiveEquipment.has(id)),
);
for (const equipmentId of notSoExpensiveEquipment) {
    demoDocuments[createUuid()] = {
        name: "dms.demo.file.rentalContract",
        documentType: DocumentType.RentalContract,
        link: rentalContractLink,
        costItems: [],

        equipmentId,
    };

    const rentalFee = Math.floor(50_00 + Math.random() * 200_00);
    for (const _ of Array(3)) {
        demoDocuments[createUuid()] = {
            name: "dms.demo.file.rentalInvoice",
            documentType: DocumentType.OtherInvoice,
            costItems: [
                {
                    name: "Rental Fee",
                    amount: rentalFee,
                    type: CostType.Rental,
                },
            ],

            equipmentId,
        };
    }

    demoDocuments[createUuid()] = {
        name: "dms.demo.file.repairInvoice",
        documentType: DocumentType.ServiceInvoice,
        link: serviceInvoiceLink,
        costItems: [
            {
                name: "",
                amount: Math.floor(50_00 + Math.random() * 100_00),
                type: CostType.SpareParts,
            },
            {
                name: "",
                amount: Math.floor(50_00 + Math.random() * 200_00),
                type: CostType.Repair,
            },
        ],

        equipmentId,
    };
}

export interface FilePickerDocument {
    name: string;
    link: string;
    fileName: string;
}

export const filePickerDocuments: Array<FilePickerDocument> = [
    {
        name: "dms.demo.file.efgManual",
        link: efgManualLink,
        fileName: "A191118-2.pdf",
    },
    {
        name: "dms.demo.file.dfgManual",
        link: dfgManualLink,
        fileName: "201015-2.pdf",
    },
    {
        name: "dms.demo.file.serviceInvoiceA",
        link: serviceInvoiceLink,
        fileName: "ERE Richter 2020.pdf",
    },
    {
        name: "dms.demo.file.serviceInvoiceB",
        link: serviceInvoice2Link,
        fileName: "ERe Beutlhauser 2019.pdf",
    },
    {
        name: "dms.demo.file.leasingContract",
        link: leasingContractLink,
        fileName: "leasing_contract.pdf",
    },
    {
        name: "dms.demo.file.rentalContract",
        link: rentalContractLink,
        fileName: "forktruck_rent_contract.pdf",
    },
];
