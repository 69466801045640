import { MessageBar, MessageBarType } from "@fluentui/react";
import React from "react";
import css from "./elofleet-message.scss";
import classNames from "classnames";
import { useLocalStorage } from "../../utils/hooks/use-local-storage";

interface Props {
    localStorageKey: string;
    title?: string;
    body?: string;
    type: MessageBarType;
    /** If set, only display on mobile screens. */
    isMobile?: boolean;
}

export function ElofleetMessage(props: Props): JSX.Element {
    const [messageVisible, setMessageVisible] = useLocalStorage(props.localStorageKey, true);

    const classes = classNames({
        [css.elofleetMessage__mobile]: props.isMobile,
    });

    return (
        <>
            {messageVisible && (
                <MessageBar
                    title={props.title}
                    className={classes}
                    messageBarType={props.type}
                    onDismiss={() => {
                        setMessageVisible(false);
                    }}
                    dismissButtonAriaLabel="Close"
                >
                    {props.body}
                </MessageBar>
            )}
        </>
    );
}
