import * as React from "react";
import { external } from "tsdi";
import { IColumn } from "@fluentui/react";
import { makeObservable, computed } from "mobx";
import { observer } from "mobx-react";
import { inject } from "tsdi";
import { RepositoryVehicles } from "../../domain/repositories/repository-vehicles";
import { UUID } from "../../utils/uuid";
import { Vehicle, VehicleDevice } from "../../api";
import { RepositoryVehicleDevices } from "../../domain/repositories/repository-vehicle-devices";
import { I18nProvider } from "../../domain/providers/i18n-provider";

export interface ListVehicleDeviceItemCellProps {
    readonly vehicleDeviceId: UUID;
    readonly column: IColumn;
}

@external
@observer
export class ListVehicleDeviceItemCell extends React.Component<ListVehicleDeviceItemCellProps> {
    @inject private readonly repositoryVehicles!: RepositoryVehicles;
    @inject private readonly repositoryVehicleDevices!: RepositoryVehicleDevices;
    @inject protected readonly i18n!: I18nProvider;

    constructor(props: ListVehicleDeviceItemCellProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get vehicle(): Vehicle | undefined {
        if (!this.vehicleDevice?.vehicleId) {
            return;
        }
        return this.repositoryVehicles.byId(this.vehicleDevice.vehicleId);
    }

    @computed private get vehicleDevice(): VehicleDevice | undefined {
        if (this.props.vehicleDeviceId === undefined) {
            return;
        }
        return this.repositoryVehicleDevices.byId(this.props.vehicleDeviceId);
    }

    /**
     * Compute the text for the vehicle link.
     * If any of the components is undefined, an empty string will be returned.
     */
    @computed private get vehicleText(): string {
        if (this.vehicleDevice === undefined || this.vehicle === undefined) {
            return "";
        }

        return this.i18n.formatVehicle(this.vehicle);
    }

    public render(): JSX.Element {
        const { column } = this.props;
        switch (column.key) {
            case "serialNumber":
                return <span>{this.vehicleDevice?.serialNumber}</span>;
            case "vehicleId":
                if (!this.vehicleText || !this.vehicle) {
                    return <span></span>;
                }
                return <span>{this.vehicle.serialNumber}</span>;
            case "createdAt":
                return (
                    <span>
                        {this.vehicleDevice
                            ? this.i18n.formatDateOnly(this.vehicleDevice.createdAt)
                            : ""}
                    </span>
                );
            case "firmwareLastUpdatedAt":
                return (
                    <span>
                        {this.vehicleDevice?.firmwareLastUpdatedAt
                            ? this.i18n.formatDateTime(this.vehicleDevice.firmwareLastUpdatedAt)
                            : ""}
                    </span>
                );
            case "currentFirmwareVersion":
                return <span>{this.vehicleDevice?.currentVersion ?? ""}</span>;
            default:
                return <></>;
        }
    }
}
