import * as React from "react";
import { clone, equals, pick } from "ramda";
import { observer } from "mobx-react";
import {
    Stack,
    TextField,
    DefaultButton,
    Separator,
    getTheme,
    DatePicker,
    Toggle,
    PivotItem,
    Pivot,
} from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import {
    doubleBindNullableString,
    doubleBindNullableStringEmptyAsNull,
    doubleBindString,
} from "../../utils/double-bind";
import { ManagementRole, FleetRole, UserCreate, Department, Shift, Site } from "../../api";
import { RepositoryUsers } from "../../domain/repositories/repository-users";
import { User } from "../../api";
import { FormCreateProps } from "../../utils/form-create-props";
import { createUuid, UUID } from "../../utils/uuid";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { ClearableComboBox } from "../atoms/clearable-combo-box";
import { ModalConfirmation } from "../atoms/modal-confirmation";
import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { ConnectedComboBox } from "../atoms/connected-combo-box";
import { RepositoryShifts } from "../../domain/repositories/repository-shifts";
import { RepositorySites } from "../../domain/repositories/repository-sites";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";
import { ImageUploader } from "../atoms/image-uploader";
import { ServiceOwnUser } from "../../domain/services/service-own-user";
import { defaultPageSize } from "../../utils/constants";

const theme = getTheme();

export interface FormCreateUserProps extends FormCreateProps<User> {}
@observer
@external
export class FormCreateUser extends React.Component<FormCreateUserProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly repositoryDepartments!: RepositoryDepartments;
    @inject private readonly repositoryShifts!: RepositoryShifts;
    @inject private readonly repositorySites!: RepositorySites;
    @inject private serviceOwnUser!: ServiceOwnUser;

    @observable public employeeId = "";
    @observable public firstName = "";
    @observable public lastName = "";
    @observable public password: string | undefined;
    @observable public passwordRepeat: string | undefined;
    @observable public email: string | undefined;
    @observable public jobTitle: string | undefined;
    @observable public departmentId?: UUID | null;
    @observable public shiftId?: UUID | null;
    @observable public siteId?: UUID | null;
    @observable public managementRole: ManagementRole | undefined;
    @observable public fleetRole: FleetRole | undefined;
    @observable public licenseExpiryDate: Date | undefined;
    @observable public expiryLogout: boolean = false;
    @observable public nfcToken?: string | null = null;
    @observable public nfcTokenDescription?: string | null = null;
    @observable private cancelConfirmationModalVisible = false;

    // Properties that aren't used during render and don't need to be observable
    private imageBytes: Uint8Array | undefined;
    private validationId = createUuid();
    private cleanState: UserCreate;

    constructor(props: FormCreateUserProps) {
        super(props);
        makeObservable(this);
        this.cleanState = clone(this.userCreate);
    }

    private triggerValidation(): void {
        this.repositoryUsers.validation.create.updateModel(this.validationId, this.userCreate);
    }

    @initialize protected initialize(): void {
        this.repositoryUsers.validation.create.initializeModel(this.validationId, this.userCreate);
    }

    @computed private get userCreate(): UserCreate {
        return pick(
            [
                "email",
                "employeeId",
                "password",
                "firstName",
                "lastName",
                "jobTitle",
                "departmentId",
                "shiftId",
                "siteId",
                "managementRole",
                "fleetRole",
                "licenseExpiryDate",
                "expiryLogout",
                "nfcToken",
                "nfcTokenDescription",
            ],
            this as FormCreateUser,
        );
    }

    @action.bound
    private async createUser(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();

        const user = await this.repositoryUsers.create(this.userCreate, this.imageBytes);
        this.reset();
        if (this.props.onCreate) {
            this.props.onCreate(user);
        }
    }

    @action.bound private reset(): void {
        this.employeeId = "";
        this.firstName = "";
        this.lastName = "";
        this.password = undefined;
        this.passwordRepeat = "";
        this.email = undefined;
        this.jobTitle = undefined;
        this.departmentId = undefined;
        this.shiftId = undefined;
        this.siteId = undefined;
        this.managementRole = undefined;
        this.fleetRole = undefined;
        this.licenseExpiryDate = undefined;
        this.expiryLogout = false;
        this.nfcToken = undefined;
        this.nfcTokenDescription = undefined;
        this.triggerValidation();
    }

    /**
     * This is run, if the user clicks the form's cancel button.
     * A confirmation modal might pop up, if the user did any changes.
     */
    @action.bound private showConfirmAndCallDialogCancelCallback(): void {
        // If there were any changes, i.e. if the original and mutable copy aren't the same,
        // open the confirmation modal for aborting the update process.
        if (!equals(this.cleanState, this.userCreate)) {
            this.cancelConfirmationModalVisible = true;
            return;
        }

        this.props.onDialogCancel();
    }

    @action.bound private closeCancelConfirmationModalVisible(): void {
        this.cancelConfirmationModalVisible = false;
    }

    @action.bound private setFleetRole(fleetRole?: FleetRole): void {
        this.fleetRole = fleetRole;
        this.triggerValidation();
    }

    @action.bound private setManagementRole(managementRole?: ManagementRole): void {
        this.managementRole = managementRole;
        this.triggerValidation();
    }

    @action.bound private setDepartment(departmentId?: UUID): void {
        this.departmentId = departmentId ?? null;
        this.triggerValidation();
    }

    @action.bound private setShift(shiftId?: UUID): void {
        this.shiftId = shiftId ?? null;
        this.triggerValidation();
    }

    @action.bound private setSite(siteId?: UUID): void {
        this.siteId = siteId ?? null;
        this.triggerValidation();
    }

    @action.bound private setLicenseExpiryDate(date: Date | null | undefined): void {
        this.licenseExpiryDate = date ?? undefined;
        this.triggerValidation();
    }

    @action.bound private setExpiryLogout(
        _event: unknown,
        expiryLogout: boolean | undefined,
    ): void {
        this.expiryLogout = expiryLogout ?? false;
        this.triggerValidation();
    }

    @action.bound private setImage(bytes?: Uint8Array): void {
        this.imageBytes = bytes;
    }

    @computed private get passwordRepeatValid(): boolean {
        return this.password === this.passwordRepeat;
    }

    @computed private get passwordRepeatErrorMessage(): string | undefined {
        if (this.passwordRepeatValid) {
            return;
        }
        return this.i18n.t("formCreateUser.passwordRepeatInvalid");
    }

    public onFormatDate = (date?: Date): string => {
        return this.i18n.formatDateOnly(date!);
    };

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formCreateUser.submit.text")}
                validation={this.repositoryUsers.validation.create}
                validationId={this.validationId}
                additionalCondition={this.passwordRepeatValid}
            />
        );
        return (
            <form onSubmit={this.createUser}>
                <Stack tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                    <Stack
                        horizontal
                        horizontalAlign="space-between"
                        tokens={{
                            childrenGap: "2em",
                        }}
                    >
                        <ImageUploader
                            onImageChanged={this.setImage}
                            label={this.i18n.t("formCreateUser.image.label")}
                            placeholderIcon="ContactCard"
                        />
                        <Stack>
                            <TextField
                                label={this.i18n.t("formCreateUser.employeeId.label")}
                                {...doubleBindString<FormCreateUser>(this, "employeeId", () =>
                                    this.triggerValidation(),
                                )}
                                required
                                errorMessage={this.i18n.formatFieldValidationState(
                                    this.repositoryUsers.validation.create.getFieldValidationState(
                                        this.validationId,
                                        "employeeId",
                                    ),
                                )}
                            />
                            <TextField
                                label={this.i18n.t("formCreateUser.lastName.label")}
                                {...doubleBindString<FormCreateUser>(this, "lastName", () =>
                                    this.triggerValidation(),
                                )}
                                required
                                errorMessage={this.i18n.formatFieldValidationState(
                                    this.repositoryUsers.validation.create.getFieldValidationState(
                                        this.validationId,
                                        "lastName",
                                    ),
                                )}
                            />
                            <TextField
                                label={this.i18n.t("formCreateUser.firstName.label")}
                                {...doubleBindString<FormCreateUser>(this, "firstName", () =>
                                    this.triggerValidation(),
                                )}
                                required
                                errorMessage={this.i18n.formatFieldValidationState(
                                    this.repositoryUsers.validation.create.getFieldValidationState(
                                        this.validationId,
                                        "firstName",
                                    ),
                                )}
                            />
                        </Stack>
                        <Stack>
                            <TextField
                                label={this.i18n.t("formCreateUser.jobTitle.label")}
                                {...doubleBindNullableString<FormCreateUser>(this, "jobTitle", () =>
                                    this.triggerValidation(),
                                )}
                                errorMessage={this.i18n.formatFieldValidationState(
                                    this.repositoryUsers.validation.create.getFieldValidationState(
                                        this.validationId,
                                        "jobTitle",
                                    ),
                                )}
                            />
                            <ConnectedComboBox
                                formatEntity={(department: Department) =>
                                    this.i18n.formatDepartment(department)
                                }
                                repository={this.repositoryDepartments}
                                query={{ pageSize: defaultPageSize }}
                                label={this.i18n.t("formCreateUser.department.label")}
                                onChange={this.setDepartment}
                                clearable
                                selectedKey={this.departmentId}
                                errorMessage={this.i18n.formatFieldValidationState(
                                    this.repositoryUsers.validation.create.getFieldValidationState(
                                        this.validationId,
                                        "departmentId",
                                    ),
                                )}
                            />
                        </Stack>
                    </Stack>
                    <Separator></Separator>
                    <Stack
                        horizontal
                        horizontalAlign="space-between"
                        tokens={{
                            childrenGap: "2em",
                        }}
                        // When the Current Tab is changed, the Height of the Current Form also changes,
                        // so there is a shift of the image. Fixed height was given to prevent this.
                        style={{ minHeight: "260px" }}
                    >
                        <Pivot linkSize="large">
                            <PivotItem
                                headerText={this.i18n.t("formCreateUser.fleetUser.tab.label")}
                                itemIcon="People"
                            >
                                <Stack
                                    horizontal
                                    horizontalAlign="space-between"
                                    tokens={{
                                        childrenGap: "2em",
                                    }}
                                    style={{
                                        paddingTop: theme.spacing.l1,
                                    }}
                                >
                                    <Stack>
                                        <ClearableComboBox
                                            label={this.i18n.t("formCreateUser.fleetRole.label")}
                                            options={Object.values(FleetRole).map((role) => ({
                                                key: role,
                                                text: this.i18n.formatFleetRole(role),
                                            }))}
                                            selectedKey={this.fleetRole}
                                            onChange={this.setFleetRole}
                                            errorMessage={this.i18n.formatFieldValidationState(
                                                this.repositoryUsers.validation.create.getFieldValidationState(
                                                    this.validationId,
                                                    "fleetRole",
                                                ),
                                            )}
                                        />

                                        <TextField
                                            label={this.i18n.t(
                                                "formCreateUser.nfcTokenDescription.label",
                                            )}
                                            {...doubleBindNullableStringEmptyAsNull<FormCreateUser>(
                                                this,
                                                "nfcTokenDescription",
                                                () => this.triggerValidation(),
                                            )}
                                            errorMessage={this.i18n.formatFieldValidationState(
                                                this.repositoryUsers.validation.create.getFieldValidationState(
                                                    this.validationId,
                                                    "nfcTokenDescription",
                                                ),
                                            )}
                                        />
                                        <TextField
                                            label={this.i18n.t("formCreateUser.nfcToken.label")}
                                            {...doubleBindNullableStringEmptyAsNull<FormCreateUser>(
                                                this,
                                                "nfcToken",
                                                () => {
                                                    if (this.nfcToken) {
                                                        this.nfcToken = this.nfcToken
                                                            .replace(/:/g, "")
                                                            .match(/.{1,2}/g)!
                                                            .join(":");
                                                    }
                                                    this.triggerValidation();
                                                },
                                            )}
                                            errorMessage={this.i18n.formatFieldValidationState(
                                                this.repositoryUsers.validation.create.getFieldValidationState(
                                                    this.validationId,
                                                    "nfcToken",
                                                ),
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                        <ConnectedComboBox
                                            formatEntity={(shift: Shift) =>
                                                this.i18n.formatShift(shift)
                                            }
                                            repository={this.repositoryShifts}
                                            query={{ pageSize: defaultPageSize }}
                                            label={this.i18n.t("formCreateUser.shift.label")}
                                            onChange={this.setShift}
                                            clearable
                                            selectedKey={this.shiftId}
                                            errorMessage={this.i18n.formatFieldValidationState(
                                                this.repositoryUsers.validation.create.getFieldValidationState(
                                                    this.validationId,
                                                    "shiftId",
                                                ),
                                            )}
                                        />
                                        <ConnectedComboBox
                                            formatEntity={(site: Site) =>
                                                this.i18n.formatSite(site)
                                            }
                                            repository={this.repositorySites}
                                            query={{ pageSize: defaultPageSize }}
                                            label={this.i18n.t("formCreateUser.site.label")}
                                            onChange={this.setSite}
                                            clearable
                                            selectedKey={this.siteId}
                                            errorMessage={this.i18n.formatFieldValidationState(
                                                this.repositoryUsers.validation.create.getFieldValidationState(
                                                    this.validationId,
                                                    "siteId",
                                                ),
                                            )}
                                        />
                                        <DatePicker
                                            formatDate={this.onFormatDate}
                                            placeholder={this.i18n.t(
                                                "formCreateUser.licenseExpiryDate.placeholder",
                                            )}
                                            label={this.i18n.t(
                                                "formCreateUser.licenseExpiryDate.label",
                                            )}
                                            value={this.licenseExpiryDate}
                                            onSelectDate={this.setLicenseExpiryDate}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Toggle
                                            label={this.i18n.t("formCreateUser.expiryLogout.label")}
                                            checked={this.expiryLogout}
                                            onChange={this.setExpiryLogout}
                                        />
                                    </Stack>
                                </Stack>
                            </PivotItem>
                            <PivotItem
                                headerText={this.i18n.t("formCreateUser.managementUser.tab.label")}
                                itemIcon="ContactCard"
                            >
                                <Stack
                                    horizontal
                                    horizontalAlign="space-between"
                                    tokens={{
                                        childrenGap: "2em",
                                    }}
                                    style={{
                                        paddingTop: theme.spacing.l2,
                                        paddingBottom: theme.spacing.l2,
                                    }}
                                >
                                    <Stack>
                                        <ClearableComboBox
                                            label={this.i18n.t(
                                                "formCreateUser.managementRole.label",
                                            )}
                                            options={Object.values(ManagementRole)
                                                .filter((role) =>
                                                    this.serviceOwnUser.user?.managementRole ==
                                                    ManagementRole.SUPER_ADMIN
                                                        ? true
                                                        : role != ManagementRole.SUPER_ADMIN,
                                                )
                                                .map((role) => ({
                                                    key: role,
                                                    text: this.i18n.formatManagementRole(role),
                                                }))}
                                            selectedKey={this.managementRole}
                                            onChange={this.setManagementRole}
                                            errorMessage={this.i18n.formatFieldValidationState(
                                                this.repositoryUsers.validation.create.getFieldValidationState(
                                                    this.validationId,
                                                    "managementRole",
                                                ),
                                            )}
                                        />
                                        <TextField
                                            label={this.i18n.t("formCreateUser.email.label")}
                                            {...doubleBindNullableString<FormCreateUser>(
                                                this,
                                                "email",
                                                () => this.triggerValidation(),
                                            )}
                                            required={!!this.managementRole}
                                            errorMessage={this.i18n.formatFieldValidationState(
                                                this.repositoryUsers.validation.create.getFieldValidationState(
                                                    this.validationId,
                                                    "email",
                                                ),
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                        <TextField
                                            label={this.i18n.t("formCreateUser.password.label")}
                                            type="password"
                                            canRevealPassword
                                            {...doubleBindNullableString<FormCreateUser>(
                                                this,
                                                "password",
                                                () => this.triggerValidation(),
                                            )}
                                            required={!!this.managementRole}
                                            errorMessage={this.i18n.formatFieldValidationState(
                                                this.repositoryUsers.validation.create.getFieldValidationState(
                                                    this.validationId,
                                                    "password",
                                                ),
                                            )}
                                        />
                                        <TextField
                                            label={this.i18n.t(
                                                "formCreateUser.passwordRepeat.label",
                                            )}
                                            type="password"
                                            canRevealPassword
                                            {...doubleBindNullableString<FormCreateUser>(
                                                this,
                                                "passwordRepeat",
                                            )}
                                            required={!!this.managementRole}
                                            errorMessage={this.passwordRepeatErrorMessage}
                                        />
                                    </Stack>
                                    <Stack></Stack>
                                </Stack>
                            </PivotItem>
                        </Pivot>
                    </Stack>
                </Stack>

                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            label={this.i18n.t("formCreateUser.cancel.label")}
                            text={this.i18n.t("formCreateUser.cancel.text")}
                            onClick={this.showConfirmAndCallDialogCancelCallback}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
                {
                    <ModalConfirmation
                        isOpen={this.cancelConfirmationModalVisible}
                        title={this.i18n.t("modalAbortUpdate.title")}
                        text={this.i18n.t("modalAbortUpdate.description")}
                        onConfirm={this.props.onDialogCancel}
                        onCancel={this.closeCancelConfirmationModalVisible}
                    />
                }
            </form>
        );
    }
}
