import * as React from "react";
import { IColumn, Stack } from "@fluentui/react";
import { omit } from "ramda";
import { action, autorun, computed, makeObservable, observable, runInAction } from "mobx";
import { external, initialize, inject } from "tsdi";
import { VehiclesApiResource, VehiclesQuery } from "../../domain/repositories/repository-vehicles";
import { createUuid, UUID } from "../../utils/uuid";
import {
    ColumnVisibility,
    Vehicle,
    VehicleListStats,
    VehiclesApi,
    VehiclesExportVehicleListListType,
    VehicleSortKey,
} from "../../api";
import { observer } from "mobx-react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { SortState } from "../../utils/sort-state";
import { PaginationState } from "../../utils/pagination-state";
import { LayoutList } from "../layouts/layout-list";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import { ListVehicleProductivityItemCell } from "../atoms/list-vehicle-productivity-item-cell";
import { FilterVehicleList, FilterVehicleListQuery } from "../organisms/filter-vehicle-list";
import { SearchState } from "../../utils/search-state";
import { ListSearchField } from "../atoms/list-search-field";
import { DatePicker } from "../organisms/date-picker";
import { endOfISOWeek, startOfISOWeek } from "date-fns";
import { RepositoryColumnVisibility } from "../../domain/repositories/repository-column-visibility";
import { ExportButton, ExportQuery } from "./export-button";
import { defaultPageSize } from "../../utils/constants";

export interface ListVehicleProductivityProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly commandBar?: JSX.Element;
    readonly listStateId?: UUID;
}

interface VehicleListStatsItem {
    readonly columns: string[];
}

@external
@observer
export class ListVehicleProductivity extends React.Component<ListVehicleProductivityProps> {
    @inject private readonly vehiclesApi!: VehiclesApi;
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject private readonly repositoryColumnVisibility!: RepositoryColumnVisibility;

    private _listStateId = createUuid();

    // This item is passed into the <ElofleetList /> component and displays
    // vehicle stats for defined columns summed over all vehicles inside that list.
    private vehicleListStatsItem: VehicleListStatsItem = {
        columns: ["loginTime", "driveTime", "idleTime"],
    };

    @observable private filterVehicleListQuery: FilterVehicleListQuery = {
        filteredDepartments: [],
        filteredVehicleGroups: [],
        isFiltered: false,
    };
    @observable private vehicles?: Vehicle[];
    @observable private vehicleListStats?: VehicleListStats;
    @observable private dateStart?: Date = startOfISOWeek(new Date());
    @observable private dateEnd?: Date = endOfISOWeek(new Date());

    constructor(props: ListVehicleProductivityProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.vehicleProductivity.initializeList(this.listStateId, {
            query: () => this.query,
        });
    }

    @computed private get listStateId(): UUID {
        return this.props.listStateId ?? this._listStateId;
    }

    @computed private get sortState(): SortState<VehicleSortKey> {
        return this.serviceListStates.vehicleProductivity.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<VehiclesApiResource> {
        return this.serviceListStates.vehicleProductivity.getPaginationState(this.listStateId);
    }

    @computed private get searchState(): SearchState {
        return this.serviceListStates.vehicleProductivity.getSearchState(this.listStateId);
    }

    @computed private get columnVisibility(): ColumnVisibility | undefined {
        return this.repositoryColumnVisibility.byQuery()[0];
    }

    @computed private get query(): VehiclesQuery {
        // We need to set the page number here which was set by the mobx repository originally.
        const page = this.paginationState.pagination.offset / defaultPageSize;
        const query = {
            pageSize: defaultPageSize,
            ...this.sortState.query,
            ...this.searchState.query,
            page,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
        } as VehiclesQuery;
        if (this.filterVehicleListQuery === undefined) {
            // VehicleListStats could be effected by date picker only,
            // therefore fetch stats even if the filter is not set.
            return query;
        }
        if (this.filterVehicleListQuery.filteredDepartments.length > 0) {
            query.departmentIds = this.filterVehicleListQuery.filteredDepartments;
        }
        if (this.filterVehicleListQuery.filteredVehicleGroups.length > 0) {
            query.vehicleGroupIds = this.filterVehicleListQuery.filteredVehicleGroups;
        }
        return query;
    }

    @computed private get exportQuery(): ExportQuery {
        const query = Object.entries({
            list_type: VehiclesExportVehicleListListType.PRODUCTIVITY,
            ...this.query,
        });
        return { query, basePath: "/api/v1/vehicles/export?" };
    }

    private fetchVehicles(): void {
        // Fetch the vehicles every time the query changes.
        autorun(() => {
            this.vehiclesApi.vehiclesListVehicles({ ...this.query }).then((result) => {
                runInAction(() => {
                    this.vehicles = result;
                });
            });
        });
    }

    private fetchVehicleListStats(): void {
        // Fetch the vehicle list stats every time the query changes.
        autorun(() => {
            this.vehiclesApi.vehiclesReadVehicleListStats({ ...this.query }).then((result) => {
                runInAction(() => {
                    this.vehicleListStats = result;
                });
            });
        });
    }

    @computed private get columns(): IColumn[] {
        const columns: IColumn[] = [];

        if (this.columnVisibility?.vehicleProductivityList.vehicleSerialNumber) {
            columns.push({
                name: this.i18n.t("listVehicleProductivity.column.serialNumber.name"),
                key: "serialNumber",
                minWidth: 100,
            });
        }
        if (this.columnVisibility?.vehicleProductivityList.vehicleManufacturer) {
            columns.push({
                name: this.i18n.t("listVehicleProductivity.column.manufacturer.name"),
                key: "manufacturer",
                minWidth: 100,
            });
        }
        if (this.columnVisibility?.vehicleProductivityList.vehicleModel) {
            columns.push({
                name: this.i18n.t("listVehicleProductivity.column.model.name"),
                key: "model",
                minWidth: 100,
            });
        }
        if (this.columnVisibility?.vehicleProductivityList.vehicleTypeLabel) {
            columns.push({
                name: this.i18n.t("listVehicleProductivity.column.vehicleTypeLabel.name"),
                key: "vehicleType",
                minWidth: 100,
            });
        }
        if (this.columnVisibility?.vehicleProductivityList.vehicleEngineType) {
            columns.push({
                name: this.i18n.t("listVehicleProductivity.column.vehicleEngineType.name"),
                key: "engineType",
                minWidth: 100,
            });
        }
        if (this.columnVisibility?.vehicleProductivityList.vehicleManufactureYear) {
            columns.push({
                name: this.i18n.t("listVehicleProductivity.column.manufactureYear.name"),
                key: "manufactureYear",
                minWidth: 150,
            });
        }
        if (this.columnVisibility?.vehicleProductivityList.departmentLabel) {
            columns.push({
                name: this.i18n.t("listVehicleProductivity.column.department.name"),
                key: "department",
                minWidth: 150,
            });
        }
        if (this.columnVisibility?.vehicleProductivityList.vehicleGroupLabel) {
            columns.push({
                name: this.i18n.t("listVehicleProductivity.column.group.name"),
                key: "group",
                minWidth: 150,
            });
        }
        columns.push({
            name: this.i18n.t("listVehicleProductivity.column.loginTime.name"),
            key: "loginTime",
            minWidth: 150,
        });
        columns.push({
            name: this.i18n.t("listVehicleProductivity.column.driveTime.name"),
            key: "driveTime",
            minWidth: 150,
        });
        columns.push({
            name: this.i18n.t("listVehicleProductivity.column.idleTime.name"),
            key: "idleTime",
            minWidth: 150,
        });
        if (this.columnVisibility?.vehicleProductivityList.vehicleLastSynchronizedAt) {
            columns.push({
                name: this.i18n.t("listVehicleProductivity.column.lastSynchronization.name"),
                key: "lastSynchronization",
                minWidth: 150,
            });
        }

        return columns;
    }

    private renderVehicleStatsItem(columnKey: string): JSX.Element {
        let hours;
        switch (columnKey) {
            case "loginTime":
                hours = this.vehicleListStats!.totalLoginSeconds;
                break;
            case "driveTime":
                hours = this.vehicleListStats!.totalDriveSeconds;
                break;
            case "idleTime":
                hours =
                    this.vehicleListStats!.totalLoginSeconds -
                    this.vehicleListStats!.totalDriveSeconds;
                break;
            default:
                break;
        }
        if (hours === undefined) {
            return <></>;
        }
        return (
            <span>
                <strong>{this.i18n.formatDuration(hours)}</strong>
            </span>
        );
    }

    private renderItem(item: Vehicle | VehicleListStatsItem, column: IColumn): JSX.Element {
        // Check for a VehicleListStatsItem as suggested in TS docs:
        // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#the-in-operator-narrowing.
        if ("columns" in item) {
            return this.renderVehicleStatsItem(column.key);
        }
        return (
            <ListVehicleProductivityItemCell
                vehicle={item}
                column={column}
                filteredVehicleGroups={this.filterVehicleListQuery.filteredVehicleGroups}
            />
        );
    }

    @action.bound public onDateRangeChanged(dateStart?: Date, dateEnd?: Date): void {
        this.dateStart = dateStart;
        this.dateEnd = dateEnd;
    }

    @action.bound private setVehicleListFilter(filterQuery: FilterVehicleListQuery): void {
        this.filterVehicleListQuery = {
            filteredDepartments: filterQuery.filteredDepartments,
            filteredVehicleGroups: filterQuery.filteredVehicleGroups,
            isFiltered: filterQuery.isFiltered,
        };
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.vehicleProductivity.isInitialized(this.listStateId)) {
            return <></>;
        }
        if (this.vehicles === undefined) {
            this.fetchVehicles();
            return <></>;
        }
        if (this.vehicleListStats === undefined) {
            this.fetchVehicleListStats();
            return <></>;
        }
        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(["ids"], this.props);
        return (
            <LayoutList
                paginationState={this.paginationState}
                commandBar={
                    <Stack horizontal horizontalAlign="space-between">
                        {this.props.commandBar}
                        <ExportButton query={this.exportQuery} />
                    </Stack>
                }
                searchField={
                    <ListSearchField
                        placeholder={this.i18n.t("listVehicles.search.placeholder")}
                        listStateId={this.listStateId}
                        listState={this.serviceListStates.vehicleProductivity}
                    />
                }
                filter={
                    <Stack
                        horizontal
                        style={{ width: "100%" }}
                        horizontalAlign="end"
                        verticalAlign="center"
                        tokens={{ childrenGap: 20 }}
                    >
                        <FilterVehicleList setVehicleListFilter={this.setVehicleListFilter} />
                        <DatePicker onDateRangeChanged={this.onDateRangeChanged} />
                    </Stack>
                }
            >
                <ElofleetList
                    {...props}
                    onRenderItemColumn={(item, _index, column) => this.renderItem(item, column!)}
                    onColumnHeaderClick={this.sortState.toggleColumn}
                    columns={this.sortState.patchColumns(this.columns)}
                    // Prepend the vehicle list stats so it's rendered as the first item as we want it to
                    // appear as a summary above the other entries.
                    items={[this.vehicleListStatsItem, ...this.vehicles]}
                />
            </LayoutList>
        );
    }
}
