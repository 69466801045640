import { action, computed, makeObservable, observable } from "mobx";
import { component } from "tsdi";
import { ResponseError } from "../../api";
import { createUuid, UUID } from "../../utils/uuid";

/** An error as it is reported from anywhere in the application. */
export interface ErrorReport {
    /** Translation key of the error's title. */
    readonly title: string;
    /** Translation key of the error's description. */
    readonly description: string;
    /** The originating instance of `Error`, if any. */
    readonly error?: Error | Response;
    /** Optional additional information that will be handed to the internationalization function. */
    readonly additionalInformation?: object;
    /** Specify this to mark an error as unrecoverable. Those errors cannot be dismissed. */
    readonly fatal?: true;
    /** HTML element to render below the error. */
    readonly html?: JSX.Element;
}

export interface ReportedError extends ErrorReport {
    readonly id: UUID;
}

/**
 * A service that all other services should report errors to.
 * It will accumulate those errors and can display them to the user.
 */
@component
export class ServiceErrors {
    /** Ordered list of errors in sorted by when they were reported.  */
    @observable private errors: ReportedError[] = [];
    /**
     * When enabled, reported errors will be re-thrown.
     * Otherwise they will suppressed.
     */
    public rethrow = false;

    constructor() {
        makeObservable(this);
    }

    /** Report a new error to this service. */
    @action.bound public reportError(details: ErrorReport): ReportedError {
        const reportedError = {
            ...details,
            id: createUuid(),
        } as ReportedError;
        this.errors.push(reportedError);
        if (this.rethrow) {
            if (reportedError.error) {
                throw reportedError.error;
            } else {
                throw new Error(
                    `ServiceErrors was reported an error: ${JSON.stringify(details, null, 2)}`,
                );
            }
        }
        return reportedError;
    }

    /** Report an API response that is to be treated as an error. */
    public async reportResponse(response: Response): Promise<ReportedError> {
        let body: unknown | undefined;
        try {
            body = await response.json();
        } catch (error) {
            // Return either the error or the actual response if we got an ErrorResponse.
            let inner_error = undefined;
            if (error instanceof Error) {
                inner_error = error;
            } else if (error instanceof ResponseError) {
                inner_error = error.response;
            }

            // An error occurred when trying to parse the response JSON.
            return this.reportError({
                title: "component.errorBar.network.title",
                description: "component.errorBar.network.failedToReadError",
                additionalInformation: { status: response.status, statusText: response.statusText },
                error: inner_error,
            });
        }

        return this.reportError({
            title: "component.errorBar.network.title",
            description: "component.errorBar.network.unknownReason",
            additionalInformation: {
                status: response.status,
                statusText: response.statusText,
                url: response.url,
                body,
            },
        });
    }

    /** Tell the service that the user has acknowledged and error. */
    @action.bound public dismissError(id: UUID): void {
        if (this.errors.length === 0) {
            throw new Error("Can't dismiss error: No errors in queue.");
        }
        const error = this.errors.find((error) => error.id === id);
        if (!error) {
            throw new Error("Can't dismiss error: Error was not reported to service.");
        }
        // Fatal errors cannot be dismissed.
        if (error.fatal) {
            throw new Error("Can't dismiss error: Fatal errors cannot be dismissed.");
        }
        this.errors = this.errors.filter((error) => error.id !== id);
    }

    @action.bound public dismissAllErrors(): void {
        this.errors = [];
    }

    /** See the next error in the queue of errors. Can be `undefined` if no errors occurred. */
    @computed public get nextError(): ReportedError | undefined {
        if (this.errors.length === 0) {
            return;
        }
        return this.errors[0];
    }

    @computed public get count(): number {
        return this.errors.length;
    }
}
