/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NewsItem } from './NewsItem';
import {
    NewsItemFromJSON,
    NewsItemFromJSONTyped,
    NewsItemToJSON,
} from './NewsItem';

/**
 * Stores RSS feed of elokon.com and ignores unnecessary fields.
 * @export
 * @interface News
 */
export interface News {
    /**
     * 
     * @type {Array<NewsItem>}
     * @memberof News
     */
    items: Array<NewsItem>;
}

/**
 * Check if a given object implements the News interface.
 */
export function instanceOfNews(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function NewsFromJSON(json: any): News {
    return NewsFromJSONTyped(json, false);
}

export function NewsFromJSONTyped(json: any, ignoreDiscriminator: boolean): News {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(NewsItemFromJSON)),
    };
}

export function NewsToJSON(value?: News | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(NewsItemToJSON)),
    };
}

