import { IColumn, Stack } from "@fluentui/react";
import { endOfISOWeek, startOfISOWeek } from "date-fns";
import { action, autorun, computed, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import { omit } from "ramda";
import * as React from "react";
import { external, initialize, inject } from "tsdi";
import {
    ColumnVisibility,
    VehicleMovementPeriod,
    VehicleMovementPeriodSortKey,
    VehicleMovementsApi,
} from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryColumnVisibility } from "../../domain/repositories/repository-column-visibility";
import {
    VehicleMovementPeriodApiResource,
    VehicleMovementPeriodQuery,
} from "../../domain/repositories/repository-vehicle-movement-periods";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { defaultPageSize } from "../../utils/constants";
import { PaginationState } from "../../utils/pagination-state";
import { SearchState } from "../../utils/search-state";
import { SortState } from "../../utils/sort-state";
import { createUuid, UUID } from "../../utils/uuid";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import { ListSearchField } from "../atoms/list-search-field";
import { ListVehicleMovementPeriodItemCell } from "../atoms/list-vehicle-movement-period-item-cell";
import { LayoutList } from "../layouts/layout-list";
import { DatePicker } from "../organisms/date-picker";
import {
    FilterVehicleMovementPeriodList,
    FilterVehicleMovementPeriodListQuery,
} from "../organisms/filter-vehicle-movement-period-list";
import { ExportButton, ExportQuery } from "./export-button";

export interface ListVehicleMovementPeriodsProps
    extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly commandBar?: JSX.Element;
    readonly listStateId?: UUID;
}

@external
@observer
export class ListVehicleMovementPeriods extends React.Component<ListVehicleMovementPeriodsProps> {
    @inject private readonly vehicleMovementsApi!: VehicleMovementsApi;
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject private readonly repositoryColumnVisibility!: RepositoryColumnVisibility;

    private _listStateId = createUuid();

    @observable private filterVehicleMovementPeriodListQuery: FilterVehicleMovementPeriodListQuery =
        {
            filteredVehicles: [],
            filteredFleetUsers: [],
            isFiltered: false,
        };
    @observable private periods?: VehicleMovementPeriod[];
    @observable private dateStart?: Date = startOfISOWeek(new Date());
    @observable private dateEnd?: Date = endOfISOWeek(new Date());

    constructor(props: ListVehicleMovementPeriodsProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.vehicleMovementPeriods.initializeList(this.listStateId, {
            query: () => this.query,
        });
    }

    @computed private get listStateId(): UUID {
        return this.props.listStateId ?? this._listStateId;
    }

    @computed private get sortState(): SortState<VehicleMovementPeriodSortKey> {
        return this.serviceListStates.vehicleMovementPeriods.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<VehicleMovementPeriodApiResource> {
        return this.serviceListStates.vehicleMovementPeriods.getPaginationState(this.listStateId);
    }

    @computed private get searchState(): SearchState {
        return this.serviceListStates.vehicleMovementPeriods.getSearchState(this.listStateId);
    }

    @computed private get columnVisibility(): ColumnVisibility | undefined {
        return this.repositoryColumnVisibility.byQuery()[0];
    }

    @computed private get query(): VehicleMovementPeriodQuery {
        // We need to set the page number here which was set by the mobx repository originally.
        const page = this.paginationState.pagination.offset / defaultPageSize;
        const query = {
            pageSize: defaultPageSize,
            ...this.sortState.query,
            ...this.searchState.query,
            page,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
        } as VehicleMovementPeriodQuery;
        if (this.filterVehicleMovementPeriodListQuery === undefined) {
            return query;
        }
        if (this.filterVehicleMovementPeriodListQuery.filteredVehicles.length > 0) {
            query.vehicleIds = this.filterVehicleMovementPeriodListQuery.filteredVehicles;
        }
        if (this.filterVehicleMovementPeriodListQuery.filteredFleetUsers.length > 0) {
            query.userIds = this.filterVehicleMovementPeriodListQuery.filteredFleetUsers;
        }
        return query;
    }

    @computed private get exportQuery(): ExportQuery {
        const query = Object.entries(this.query);
        return { query, basePath: "/api/v1/vehicle_movements/export?" };
    }

    @computed private get columns(): IColumn[] {
        const columns: IColumn[] = [];

        if (this.columnVisibility?.vehicleMovementPeriodList.eventId) {
            columns.push({
                name: this.i18n.t("listVehicleMovementPeriods.column.eventId.name"),
                key: "eventId",
                minWidth: 90,
            });
        }
        if (this.columnVisibility?.vehicleMovementPeriodList.vehicleSerialNumber) {
            columns.push({
                name: this.i18n.t("listVehicleMovementPeriods.column.vehicle.serialNumber.name"),
                key: "vehicleSerialNumber",
                minWidth: 90,
            });
        }
        if (this.columnVisibility?.vehicleMovementPeriodList.userEmployeeId) {
            columns.push({
                name: this.i18n.t("listVehicleMovementPeriods.column.user.employeeId.name"),
                key: "userEmployeeId",
                minWidth: 100,
            });
        }
        columns.push({
            name: this.i18n.t("listVehicleMovementPeriods.column.startTime.name"),
            key: "startTime",
            minWidth: 120,
        });
        columns.push({
            name: this.i18n.t("listVehicleMovementPeriods.column.stopTime.name"),
            key: "stopTime",
            minWidth: 120,
        });
        columns.push({
            name: this.i18n.t("listVehicleMovementPeriods.column.totalTime.name"),
            key: "totalTime",
            minWidth: 120,
        });

        return columns;
    }

    private renderItem(item: VehicleMovementPeriod, column: IColumn): JSX.Element {
        return <ListVehicleMovementPeriodItemCell period={item} column={column} />;
    }

    @action.bound public onDateRangeChanged(dateStart?: Date, dateEnd?: Date): void {
        this.dateStart = dateStart;
        this.dateEnd = dateEnd;
    }

    @action.bound private setVehicleMovementPeriodListFilter(
        filterQuery: FilterVehicleMovementPeriodListQuery,
    ): void {
        this.filterVehicleMovementPeriodListQuery = {
            filteredVehicles: filterQuery.filteredVehicles,
            filteredFleetUsers: filterQuery.filteredFleetUsers,
            isFiltered: filterQuery.isFiltered,
        };
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.vehicleMovementPeriods.isInitialized(this.listStateId)) {
            return <></>;
        }

        if (this.periods === undefined) {
            this.periods = [];
            autorun(() => {
                this.vehicleMovementsApi
                    .vehicleMovementsListVehicleMovementPeriods({ ...this.query })
                    .then((result) => {
                        runInAction(() => {
                            this.periods = result;
                        });
                    });
            });
        }

        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(["ids"], this.props);
        return (
            <LayoutList
                paginationState={this.paginationState}
                commandBar={
                    <Stack horizontal horizontalAlign="space-between">
                        {this.props.commandBar}
                        <ExportButton query={this.exportQuery} />
                    </Stack>
                }
                searchField={
                    <ListSearchField
                        placeholder={this.i18n.t("listVehicleMovementPeriods.search.placeholder")}
                        listStateId={this.listStateId}
                        listState={this.serviceListStates.vehicleMovementPeriods}
                    />
                }
                filter={
                    <Stack
                        horizontal
                        style={{ width: "100%" }}
                        horizontalAlign="end"
                        verticalAlign="center"
                        tokens={{ childrenGap: 20 }}
                    >
                        <FilterVehicleMovementPeriodList
                            setVehicleMovementPeriodListFilter={
                                this.setVehicleMovementPeriodListFilter
                            }
                        />
                        <DatePicker onDateRangeChanged={this.onDateRangeChanged} />
                    </Stack>
                }
            >
                <ElofleetList
                    {...props}
                    onRenderItemColumn={(item, _index, column) => this.renderItem(item, column!)}
                    onColumnHeaderClick={this.sortState.toggleColumn}
                    columns={this.sortState.patchColumns(this.columns)}
                    items={this.periods}
                />
            </LayoutList>
        );
    }
}
