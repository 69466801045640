/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EloshieldModule } from './EloshieldModule';
import {
    EloshieldModuleFromJSON,
    EloshieldModuleFromJSONTyped,
    EloshieldModuleToJSON,
} from './EloshieldModule';

/**
 * 
 * @export
 * @interface ActionConfigCreate
 */
export interface ActionConfigCreate {
    /**
     * 
     * @type {string}
     * @memberof ActionConfigCreate
     */
    label: string;
    /**
     * 
     * @type {any}
     * @memberof ActionConfigCreate
     */
    nodes: any | null;
    /**
     * 
     * @type {any}
     * @memberof ActionConfigCreate
     */
    edges: any | null;
    /**
     * 
     * @type {EloshieldModule}
     * @memberof ActionConfigCreate
     */
    eloshieldModule: EloshieldModule;
}

/**
 * Check if a given object implements the ActionConfigCreate interface.
 */
export function instanceOfActionConfigCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "nodes" in value;
    isInstance = isInstance && "edges" in value;
    isInstance = isInstance && "eloshieldModule" in value;

    return isInstance;
}

export function ActionConfigCreateFromJSON(json: any): ActionConfigCreate {
    return ActionConfigCreateFromJSONTyped(json, false);
}

export function ActionConfigCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionConfigCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'nodes': json['nodes'],
        'edges': json['edges'],
        'eloshieldModule': EloshieldModuleFromJSON(json['eloshieldModule']),
    };
}

export function ActionConfigCreateToJSON(value?: ActionConfigCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'nodes': value.nodes,
        'edges': value.edges,
        'eloshieldModule': EloshieldModuleToJSON(value.eloshieldModule),
    };
}

