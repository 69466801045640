/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleGroupAssignmentUpdate
 */
export interface VehicleGroupAssignmentUpdate {
    /**
     * 
     * @type {string}
     * @memberof VehicleGroupAssignmentUpdate
     */
    vehicleGroupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleGroupAssignmentUpdate
     */
    vehicleId?: string | null;
}

/**
 * Check if a given object implements the VehicleGroupAssignmentUpdate interface.
 */
export function instanceOfVehicleGroupAssignmentUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleGroupAssignmentUpdateFromJSON(json: any): VehicleGroupAssignmentUpdate {
    return VehicleGroupAssignmentUpdateFromJSONTyped(json, false);
}

export function VehicleGroupAssignmentUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleGroupAssignmentUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicleGroupId': !exists(json, 'vehicleGroupId') ? undefined : json['vehicleGroupId'],
        'vehicleId': !exists(json, 'vehicleId') ? undefined : json['vehicleId'],
    };
}

export function VehicleGroupAssignmentUpdateToJSON(value?: VehicleGroupAssignmentUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicleGroupId': value.vehicleGroupId,
        'vehicleId': value.vehicleId,
    };
}

