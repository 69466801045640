/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserGroupVehicleGroupAssignmentCreate
 */
export interface UserGroupVehicleGroupAssignmentCreate {
    /**
     * 
     * @type {string}
     * @memberof UserGroupVehicleGroupAssignmentCreate
     */
    userGroupId: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupVehicleGroupAssignmentCreate
     */
    vehicleGroupId: string;
}

/**
 * Check if a given object implements the UserGroupVehicleGroupAssignmentCreate interface.
 */
export function instanceOfUserGroupVehicleGroupAssignmentCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userGroupId" in value;
    isInstance = isInstance && "vehicleGroupId" in value;

    return isInstance;
}

export function UserGroupVehicleGroupAssignmentCreateFromJSON(json: any): UserGroupVehicleGroupAssignmentCreate {
    return UserGroupVehicleGroupAssignmentCreateFromJSONTyped(json, false);
}

export function UserGroupVehicleGroupAssignmentCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGroupVehicleGroupAssignmentCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userGroupId': json['userGroupId'],
        'vehicleGroupId': json['vehicleGroupId'],
    };
}

export function UserGroupVehicleGroupAssignmentCreateToJSON(value?: UserGroupVehicleGroupAssignmentCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userGroupId': value.userGroupId,
        'vehicleGroupId': value.vehicleGroupId,
    };
}

