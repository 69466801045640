import { NewDocument } from "./pages/add-document/new-document";
import React from "react";
import { Navigate } from "react-router";
import { ListEquipment, ListType } from "./pages/list-equipment/list-equipment";
import { UUID } from "../utils/uuid";
import { ViewEquipment } from "./pages/view-equipment";
import { ComingSoon } from "./pages/coming-soon/coming-soon";
import { ListServiceReports } from "./pages/service-reports/list-service-reports";
import { NewServiceReport } from "./pages/service-reports/new-service-report";
import { Dashboard } from "./pages/dashboard";

const routes = {
    index: {
        pathDefinition: "/elodms",
        generateUrl: () => "/elodms",
        component: <Navigate to={"/elodms/dashboard"} replace />,
    },
    dashboard: {
        pathDefinition: "/elodms/dashboard",
        generateUrl: () => "/elodms/dashboard",
        component: <Dashboard />,
    },
    newDocument: {
        pathDefinition: "/elodms/documents/new",
        generateUrl: () => "/elodms/documents/new",
        component: <NewDocument />,
    },
    listEquipment: {
        pathDefinition: "/elodms/equipment/list/:listType",
        generateUrl: (listType: ListType) => `/elodms/equipment/list/${listType}`,
        component: <ListEquipment />,
    },
    viewEquipment: {
        pathDefinition: "/elodms/equipment/view/:equipmentId",
        generateUrl: (equipmentId: UUID) => `/elodms/equipment/view/${equipmentId}`,
        component: <ViewEquipment />,
    },
    newServiceReport: {
        pathDefinition: "/elodms/service_reports/new",
        generateUrl: () => "/elodms/service_reports/new",
        component: <NewServiceReport />,
    },
    listServiceReports: {
        pathDefinition: "/elodms/service_reports/list",
        generateUrl: () => "/elodms/service_reports/list",
        component: <ListServiceReports />,
    },
    comingSoon: {
        pathDefinition: "/elodms/coming_soon/:section",
        generateUrl: (section: string) => `/elodms/coming_soon/${section}`,
        component: <ComingSoon />,
    },
};

export default routes;
