import {
    Async,
    ComboBox,
    IComboBox,
    IComboBoxOption,
    IComboBoxProps,
    IconButton,
    Stack,
} from "@fluentui/react";
import { clone, omit } from "ramda";
import { action, computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { PaginatedSearchableRepository } from "mobx-repository";
import * as React from "react";
import { UUID } from "../../utils/uuid";

export type ConnectedComboBoxProps<TQuery, TEntity extends { id: string }> = {
    readonly repository: PaginatedSearchableRepository<TQuery, TEntity>;
    readonly query: TQuery;
    readonly formatEntity: (entity: TEntity) => string;
    readonly selectedKey?: UUID | null;
    // Determine, whether this ConnectedCombobox supports searching or not.
    readonly searchable?: boolean;
    readonly disabled?: boolean;
} & Omit<IComboBoxProps, "options" | "onChange" | "ref" | "selectedKey"> &
    (
        | {
              readonly clearable?: true;
              readonly onChange: (value?: UUID | undefined, selected?: boolean) => void;
          }
        | {
              readonly clearable?: false;
              readonly onChange: (value?: UUID, selected?: boolean) => void;
          }
    );

@observer
export class ConnectedComboBox<TQuery, TEntity extends { id: string }> extends React.Component<
    ConnectedComboBoxProps<TQuery, TEntity>
> {
    @observable private currentSearch: string | undefined;

    // This reference is needed to open the combobox dropdown on focus.
    // Sadly, this behavior is disabled when in freeform mode.
    private comboBoxRef: React.RefObject<IComboBox> = React.createRef();

    constructor(props: ConnectedComboBoxProps<TQuery, TEntity>) {
        super(props);
        makeObservable(this);
    }

    @computed private get entities(): TEntity[] {
        // Use either an empty query or the one provided via props.
        const query: TQuery & { search?: string } = this.props.query
            ? clone(this.props.query)
            : ({} as TQuery);
        // Patch the base query with the current search.
        if (this.props.searchable && this.currentSearch !== undefined) {
            query.search = this.currentSearch;
        }

        const entities = this.props.repository.byQuery(query as TQuery);

        // The following case can occur:
        // 1.) This component is mounted with a preselected entity.
        // 2.) A lot of choices exist in the database
        // 3.) The selected entity is not on the first page.
        // Then, the above variable `entities` would not include the selected entity.
        // If that is the case, the selected entity is added to the array here.
        // Otherwise, Fluent UI would clear the combo box.
        if (
            this.selectedEntity &&
            !entities.some((entity) => entity.id === this.selectedEntity!.id)
        ) {
            entities.push(this.selectedEntity);
        }
        return entities;
    }

    @computed private get selectedEntity(): TEntity | undefined {
        if (typeof this.props.selectedKey !== "string") {
            return;
        }
        return this.props.repository.byId(this.props.selectedKey);
    }

    @computed private get options(): IComboBoxOption[] {
        return this.entities.map((entity) => ({
            key: entity.id,
            text: this.props.formatEntity(entity),
        }));
    }

    /**
     * This function is called whenever:
     * - An element in the multi-select is un-/selected.
     * - The user presses `Enter` after entering some input.
     * - The user presses `Enter` after navigating to an item with their arrow keys.
     * - The user presses `Escape` after navigating to an item with their arrow keys.
     *    Weirdly enough, pressing `Escape` triggers an onChange!
     *    This is why we simply ignore any focus releated events.
     */
    @action.bound private updateSelection(
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
        _index?: number,
        value?: string,
    ): void {
        // Don't do anything when focus is lost (E.g. escape is pressed).
        if (event.nativeEvent instanceof FocusEvent) {
            return;
        }

        // Propagate the selection state to the parent component.
        if (option !== undefined) {
            this.props.onChange(option.key as UUID, option.selected);
            return;
        }

        // For some reason, when entering a matching text and pressing enter,
        // Fluent-ui **sometimes** does only send `value` **without** the accompanying option!!
        // That's why we sadly have to do our own value->option matching.
        if (value !== undefined) {
            for (const option of this.options) {
                if (option.text === value.trim()) {
                    const key = option.key as UUID;
                    this.props.onChange(key, option.selected);
                }
            }
        }
    }

    /**
     * Handle input changes in the actual text field.
     * It's triggered via many different events, which we try to filter.
     *
     * The search should only be updated if the user changes the text.
     */
    @action.bound private updateSearch(
        option?: IComboBoxOption,
        _index?: number,
        value?: string,
    ): void {
        // The user either
        // - Moved through the options via their keyboard arrow buttons.
        // - Entered a search that perfectly matches one of the options.
        // - The user hovers mouse-over options.
        //
        // We immediately select this entry and set the searchTerm to `undefined`,
        // **if** there is a `value`, which indicates that it's a term typed by the user.
        //
        // Otherwise, we just ignore this input.
        if (option !== undefined && value !== undefined && value !== "") {
            this.currentSearch = undefined;

            // Debounce the direct set, as this leads to tons of validation requests when a user
            // hovers scrolls through options or hovers through options
            this.props.onChange(option.key.toString(), true);
            return;
        }

        // Don't update the search if the event contains no new data or the term didn't change.
        if (value === undefined || this.currentSearch === value.trim()) {
            return;
        }

        // Update the search field state.
        if (value.trim() === "") {
            // Remove the current search if we have an empty string.
            // Otherwise we would query the backend with an empty string search.
            this.currentSearch = undefined;

            // Also unselect the currently selected element.
            if (this.props.selectedKey) {
                this.props.onChange(this.props.selectedKey.toString(), false);
            }
        } else {
            this.currentSearch = value.trim();

            const matchingOption = this.options.find((option) => option.text == this.currentSearch);
            if (matchingOption !== undefined) {
                // We have a matching option for the current search term.
                // This should have already been set by a previous event, but that event is sometimes
                // not dispatched for unknown reasons. That's why we just double-tap to be sure.
                this.props.onChange(matchingOption.key.toString(), true);
            } else if (this.props.selectedKey) {
                // If there's no matching option and we have a selected option, unselect that option.
                this.props.onChange(undefined);
            }
        }
    }

    /// Handle the click on the `X` button besides combobox, if the box is clearable.
    @action.bound private clearSearch(): void {
        if (this.props.selectedKey) {
            // If there's no matching option and we have a selected option, unselect that option.
            this.props.onChange(undefined);
        }
        this.currentSearch = undefined;
    }

    public render(): JSX.Element {
        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(
            ["repository", "query", "formatEntity", "onChange", "clearable"],
            this.props,
        );

        if (this.props.clearable) {
            return (
                <Stack horizontal>
                    <Stack.Item>
                        <ComboBox
                            options={this.options}
                            allowFreeform={this.props.searchable ?? false}
                            autoComplete={this.props.searchable ? "off" : "on"}
                            componentRef={this.comboBoxRef}
                            onFocus={new Async().debounce(
                                () => this.comboBoxRef.current?.focus(true),
                                100,
                            )}
                            onChange={this.updateSelection}
                            onPendingValueChanged={this.updateSearch}
                            {...props}
                        />
                    </Stack.Item>
                    <Stack.Item align="end">
                        <IconButton
                            iconProps={{ iconName: "Cancel" }}
                            onClick={this.clearSearch}
                            disabled={this.props.disabled}
                        />
                    </Stack.Item>
                </Stack>
            );
        }

        return (
            <ComboBox
                options={this.options}
                allowFreeform={!!this.props.searchable}
                autoComplete={this.props.searchable ? "off" : "on"}
                componentRef={this.comboBoxRef}
                onFocus={new Async().debounce(() => this.comboBoxRef.current?.focus(true), 100)}
                onChange={this.updateSelection}
                onPendingValueChanged={this.updateSearch}
                {...props}
            />
        );
    }
}
