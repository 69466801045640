import * as React from "react";
import { external } from "tsdi";
import { IColumn } from "@fluentui/react";
import { makeObservable, computed } from "mobx";
import { observer } from "mobx-react";
import { inject } from "tsdi";
import { UUID } from "../../utils/uuid";
import { ShockEvent, ShockEventLevel, ShockProfile, User, Vehicle, VehicleGroup } from "../../api";
import { RepositoryVehicles } from "../../domain/repositories/repository-vehicles";
import { RepositoryUsers } from "../../domain/repositories/repository-users";
import { RepositoryShockEvents } from "../../domain/repositories/repository-shock-events";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { Severity, SeverityPill } from "./severity-pill";
import { RepositoryVehicleGroups } from "../../domain/repositories/repository-vehicle-groups";
import { RepositoryShockProfileVersions } from "../../domain/repositories/repository-shock-profile-versions";
import { defaultPageSize } from "../../utils/constants";

export interface ListShockEventItemCellProps {
    readonly shockEventId: UUID;
    readonly column: IColumn;
}

@external
@observer
export class ListShockEventItemCell extends React.Component<ListShockEventItemCellProps> {
    @inject private readonly repositoryShockEvents!: RepositoryShockEvents;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly repositoryDepartments!: RepositoryDepartments;
    @inject private readonly repositoryShockProfileVersions!: RepositoryShockProfileVersions;
    @inject private readonly repositoryVehicleGroups!: RepositoryVehicleGroups;
    @inject private readonly i18n!: I18nProvider;

    constructor(props: ListShockEventItemCellProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get shockEvent(): ShockEvent | undefined {
        return this.repositoryShockEvents.byId(this.props.shockEventId);
    }

    @computed private get vehicle(): Vehicle | undefined {
        if (!this.shockEvent) {
            return;
        }
        return this.repositoryVehicles.byId(this.shockEvent.vehicleId);
    }

    @computed private get shockProfile(): ShockProfile | undefined {
        if (!this.shockEvent?.shockProfileVersionId) {
            return;
        }

        return this.repositoryShockProfileVersions.byId(this.shockEvent.shockProfileVersionId);
    }

    @computed private get severity(): Severity | undefined {
        if (!this.shockEvent) {
            return;
        }

        switch (this.shockEvent.shockLevel) {
            case ShockEventLevel.LIGHT:
                return Severity.LIGHT;
            case ShockEventLevel.MEDIUM:
                return Severity.MEDIUM;
            case ShockEventLevel.SEVERE:
                return Severity.SEVERE;
        }
    }

    @computed private get user(): User | undefined {
        if (!this.shockEvent) {
            return;
        }

        return this.shockEvent.userId == null
            ? undefined
            : this.repositoryUsers.byId(this.shockEvent.userId);
    }

    @computed private get departmentLabel(): string | undefined {
        if (!this.vehicle || !this.vehicle.departmentId) {
            return;
        }
        return this.repositoryDepartments.byId(this.vehicle.departmentId)?.label;
    }

    @computed private get groups(): VehicleGroup[] {
        if (!this.vehicle) {
            return [];
        }
        return this.repositoryVehicleGroups.byQuery({
            pageSize: defaultPageSize,
            vehicleId: this.vehicle.id,
        });
    }

    public render(): JSX.Element {
        const { column } = this.props;
        if (!this.shockEvent) {
            return <></>;
        }
        switch (column.key) {
            case "time":
                return <span>{this.i18n.formatDateTime(this.shockEvent.time)}</span>;
            case "username":
                return this.user ? (
                    <span>{this.i18n.formatUserFullName(this.user)}</span>
                ) : (
                    <span />
                );

            case "department":
                return <span>{this.departmentLabel}</span>;
            case "vehicle":
                return this.vehicle ? <span>{this.vehicle.serialNumber}</span> : <span />;
            case "group":
                // TODO: This is a temporary hack until we know how to display all groups.
                // For now, we simply show the first group, if wone exists.
                return this.groups.length > 0 ? <span>{this.groups[0].label}</span> : <span />;
            case "shockProfile":
                return this.shockProfile ? (
                    <span>{this.i18n.formatShockProfile(this.shockProfile)}</span>
                ) : (
                    <span />
                );
            case "motionStatus":
                return this.shockEvent ? (
                    <span>
                        {this.i18n.formatShockEventMotionStatus(this.shockEvent.motionStatus)}
                    </span>
                ) : (
                    <span />
                );
            case "severity":
                return this.severity ? <SeverityPill severity={this.severity} /> : <span />;
            default:
                return <></>;
        }
    }
}
