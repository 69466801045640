import * as React from "react";
import { Listbox, Transition } from "@headlessui/react";
import Icon from "@mdi/react";
import { mdiTriangleSmallDown, mdiCheck } from "@mdi/js";

export interface SingleSelectOption {
    value: string;
}

export interface SingleSelectProps {
    value: string | null;
    placeholder?: string;
    onChange: (value: string | null) => void;
    children: React.ReactNode;
    displayValue: (value: string) => string;
    className?: string;
}

export default function SingleSelect(props: SingleSelectProps): JSX.Element {
    const className = props.className ? props.className : "";
    return (
        <Listbox value={props.value} onChange={props.onChange}>
            <div className={`relative h-11 ${className}`}>
                <SingleSelect.Button
                    value={props.value}
                    placeholder={props.placeholder}
                    displayValue={props.displayValue}
                />
                <Transition
                    as={React.Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {props.children}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    );
}

export interface ButtonProps {
    value: string | null;
    placeholder?: string;
    displayValue: (value: string) => string;
}

SingleSelect.Button = function Button(props: ButtonProps): JSX.Element {
    let className = "";
    const placeholderText = props.value ? props.displayValue(props.value) : props.placeholder ?? "";
    if (props.value === null) {
        className = "text-neutral-500";
    }
    return (
        <Listbox.Button className="relative h-full w-full rounded border border-slate-300 bg-white py-2 px-4 text-left">
            <span className={`block truncate ${className}`}>{placeholderText}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <Icon path={mdiTriangleSmallDown} className="h-5 w-5 text-gray-400" />
            </span>
        </Listbox.Button>
    );
};

interface SingleSelectOptionProps {
    value: string;
    text?: string;
}

SingleSelect.Option = function SingleSelectOption(props: SingleSelectOptionProps): JSX.Element {
    return (
        <Listbox.Option
            value={props.value}
            className={() => {
                const style = [
                    "relative cursor-default select-none bg-white py-2 pl-10 pr-4 hover:bg-blue-50",
                ];
                return style.join(" ");
            }}
        >
            {({ selected }) => {
                let textStyle = "";
                if (selected) {
                    textStyle = "block truncate font-medium";
                } else {
                    textStyle = "block truncate font-normal";
                }

                return (
                    <>
                        <span className={textStyle}>{props.text || props.value}</span>
                        {selected && (
                            <span className="text-amber-600 absolute inset-y-0 left-0 flex items-center pl-3">
                                <Icon path={mdiCheck} className="h-5 w-5" />
                            </span>
                        )}
                    </>
                );
            }}
        </Listbox.Option>
    );
};
