import { action, makeObservable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { RepositoryUserGroups } from "../../domain/repositories/repository-user-groups";
import { UUID } from "../../utils/uuid";
import { ListCommandButtons, ListCommandButtonsProps } from "./list-command-buttons";
import { FormUpdateUserGroup } from "../molecules/form-update-user-group";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { I18nProvider } from "../../domain/providers/i18n-provider";

export type ListCommandButtonsUserGroupsProps = Omit<
    ListCommandButtonsProps<RepositoryUserGroups>,
    "list" | "createForm" | "listState"
>;

@external
@observer
export class ListCommandButtonsUserGroups extends React.Component<ListCommandButtonsUserGroupsProps> {
    @inject private readonly repositoryUserGroups!: RepositoryUserGroups;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject protected readonly i18n!: I18nProvider;

    constructor(props: ListCommandButtonsUserGroupsProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private async deleteUserGroups(ids: UUID[]): Promise<void> {
        await this.repositoryUserGroups.delete(...ids);
        if (this.props.onDelete) {
            this.props.onDelete(ids);
        }
    }

    public render(): JSX.Element {
        return (
            <ListCommandButtons
                updateForm={FormUpdateUserGroup}
                listState={this.serviceListStates.userGroups}
                {...this.props}
                onDelete={this.deleteUserGroups}
            />
        );
    }
}
