/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserGroupSortKey {
    CREATED_AT = 'createdAt',
    LABEL = 'label'
}


export function UserGroupSortKeyFromJSON(json: any): UserGroupSortKey {
    return UserGroupSortKeyFromJSONTyped(json, false);
}

export function UserGroupSortKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGroupSortKey {
    return json as UserGroupSortKey;
}

export function UserGroupSortKeyToJSON(value?: UserGroupSortKey | null): any {
    return value as any;
}

