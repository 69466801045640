/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersistentNotificationUpdate
 */
export interface PersistentNotificationUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof PersistentNotificationUpdate
     */
    read?: boolean | null;
}

/**
 * Check if a given object implements the PersistentNotificationUpdate interface.
 */
export function instanceOfPersistentNotificationUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PersistentNotificationUpdateFromJSON(json: any): PersistentNotificationUpdate {
    return PersistentNotificationUpdateFromJSONTyped(json, false);
}

export function PersistentNotificationUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersistentNotificationUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'read': !exists(json, 'read') ? undefined : json['read'],
    };
}

export function PersistentNotificationUpdateToJSON(value?: PersistentNotificationUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'read': value.read,
    };
}

