import React, { useEffect, useState } from "react";
import { NodeProps } from "reactflow";
import ActionConfigNode from "./action-config-node";
import { StationaryRelay, SetStationaryRelayData } from "../../../api";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export default function SetStationaryRelay(
    props: NodeProps<
        SetStationaryRelayData & { invalid?: boolean; validationErrorMessage?: string }
    >,
): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    const [selectedStationaryRelay, setSelectedStationaryRelay] = useState(
        props.data.relay ?? StationaryRelay.ONE,
    );
    const relayOptions: IChoiceGroupOption[] = Object.values(StationaryRelay).map((key, index) => {
        return { key, text: t(`component.actionConfigNodes.relay.${index}`) };
    });

    // We have to set the data to prevent undefined values
    useEffect(() => {
        props.data.relay = selectedStationaryRelay;
    }, [props.data, selectedStationaryRelay]);

    function onChange(
        _e?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption,
    ): void {
        if (!option) {
            return;
        }
        setSelectedStationaryRelay(option.key as StationaryRelay);
        props.data.relay = option.key as StationaryRelay;
    }

    return (
        <ActionConfigNode nodeKind={props.type} {...props.data}>
            <ChoiceGroup
                options={relayOptions}
                selectedKey={selectedStationaryRelay}
                onChange={onChange}
            />
        </ActionConfigNode>
    );
}
