import { Icon, MessageBar, Shimmer } from "@fluentui/react";
import { action } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import Markdown from "react-markdown";
import RemarkGfm from "remark-gfm";
import RehypeRaw from "rehype-raw";
import { external, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { ServiceHelp } from "../../domain/services/service-help";
import { HelpArticle, HelpArticleStatus } from "../../help-types";
import css from "./help-article-full.scss";

export interface HelpArticleFullProps {
    readonly article: HelpArticle;
}

@observer
@external
export class HelpArticleFull extends React.Component<HelpArticleFullProps> {
    @inject private i18n!: I18nProvider;
    @inject private serviceHelp!: ServiceHelp;

    private renderLoading(): JSX.Element {
        return (
            <>
                <p>
                    <Shimmer width="50%" />
                </p>
                <p>
                    <Shimmer />
                </p>
                <p>
                    <Shimmer width="70%" />
                </p>
                <p>
                    <Shimmer width="80%" />
                </p>
                <p>
                    <Shimmer width="100%" />
                </p>
                <p>
                    <Shimmer width="70%" />
                </p>
            </>
        );
    }

    private renderFailure(): JSX.Element {
        return (
            <>
                <h3>{this.i18n.t("helpArticle.failure.title")}</h3>
                <p>{this.i18n.t("helpArticle.failure.description")}</p>
                <Icon className={css.helpArticleFull__failureIcon} iconName="Sad" />
            </>
        );
    }

    private renderFallback(): JSX.Element {
        return (
            <>
                <MessageBar delayedRender={false} role="none">
                    {this.i18n.t("helpArticle.translationMissing")}
                </MessageBar>
                {this.renderMarkdown()}
            </>
        );
    }

    private renderMarkdown(): JSX.Element {
        const { content } = this.props.article;
        if (
            content.status !== HelpArticleStatus.SUCCESS &&
            content.status !== HelpArticleStatus.FALLBACK
        ) {
            return <></>;
        }
        return (
            <div className={css.helpArticleFull__markdown}>
                <Markdown
                    remarkPlugins={[RemarkGfm]}
                    rehypePlugins={[RehypeRaw]}
                    components={{
                        a: (props) => this.renderLink(props),
                        img: (props) => this.renderImage(props),
                    }}
                >
                    {content.content}
                </Markdown>
            </div>
        );
    }

    @action.bound
    private enterArticle(evt: React.SyntheticEvent<HTMLAnchorElement>, href: string): void {
        evt.preventDefault();
        const path = this.serviceHelp.resolveMarkdownPath(this.props.article.filename, href);
        this.serviceHelp.enterArticle(path);
    }

    private renderLink(props: JSX.IntrinsicElements["a"]): JSX.Element {
        const { href, ...rest } = props;
        if (!href || href.match("^https?://.*?$")) {
            return <a target="blank" href={href} {...rest} />;
        }
        return <a onClick={(evt) => this.enterArticle(evt, href)} {...rest} />;
    }

    private renderImage(props: JSX.IntrinsicElements["img"]): JSX.Element {
        const { src, ...rest } = props;
        const resolvedUrl = src
            ? this.serviceHelp.resolveAssetUrl(this.props.article.filename, src)
            : undefined;

        return <img src={resolvedUrl} {...rest} />;
    }

    private renderContent(): JSX.Element {
        switch (this.props.article.content.status) {
            case HelpArticleStatus.FALLBACK:
                return this.renderFallback();
            case HelpArticleStatus.LOADING:
                return this.renderLoading();
            case HelpArticleStatus.FAILURE:
                return this.renderFailure();
            case HelpArticleStatus.SUCCESS:
                return this.renderMarkdown();
            case HelpArticleStatus.PENDING:
                throw new Error("Failed to render help article: Content not loaded.");
        }
    }

    public render(): JSX.Element {
        return <div className={css.helpArticleFull}>{this.renderContent()}</div>;
    }
}
