import style from "./dashboard-status-circle.scss";
import React from "react";
import DashboardLink from "./dashboard-link";
import { BigStatNumber } from "./big-stat-number";
import { DirectionalHint, HoverCard, HoverCardType, Icon } from "@fluentui/react";
import { TooltipContents } from "./dashboard-card";
import { Trans } from "react-i18next";

interface Props {
    statistic: string;
    description: string;
    color: "red" | "green";
    link: string;
    loading?: boolean;
    tooltipTranslationKey?: string;
}

export function DashboardStatusCircle(props: Props): JSX.Element {
    let colorClass: string = style[`dashboardStatusCircle--${props.color}`];
    const tooltipTargetElementRef: React.RefObject<HTMLDivElement> =
        React.useRef<HTMLDivElement>(null);
    const tooltipEventListenerTargetElementRef: React.RefObject<HTMLDivElement> =
        React.useRef<HTMLDivElement>(null);

    // Set the loading CSS class if data is not loaded yet.
    if (props.loading) {
        colorClass = style["dashboardStatusCircle--loading"];
    }
    return (
        <div className={`${style.dashboardStatusCircle} ${colorClass}`}>
            <div className={style["dashboardStatusCircle__whiteBackgroundCircle"]}></div>
            <svg
                width="200"
                height="160"
                viewBox="0 13 200 173"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={style["dashboardStatusCircle__colorRing"]}
            >
                <path
                    className={style["dashboardStatusCircle__colorRingBand"]}
                    d="M32.0001 168C18.551 154.551 9.39205 137.415 5.68145 118.761C1.97085 100.107 3.87526 80.7707 11.1539 63.1986C18.4325 45.6264 30.7584 30.6073 46.5729 20.0404C62.3874 9.47348 80.9802 3.83342 100 3.83342C119.02 3.83343 137.613 9.47349 153.427 20.0404C169.242 30.6073 181.568 45.6264 188.846 63.1986C196.125 80.7707 198.029 100.107 194.319 118.761C190.608 137.415 181.449 154.551 168 168L100 99.9999L32.0001 168Z"
                    stroke="white"
                    strokeWidth="3"
                />
            </svg>
            <div className={style["dashboardStatusCircle__innerCircle"]}>
                <div
                    className={style["dashboardStatusCircle__statistic"]}
                    ref={tooltipTargetElementRef}
                    data-is-focusable
                >
                    <BigStatNumber>{props.statistic}</BigStatNumber>
                </div>
                <div className={style["dashboardStatusCircle__description"]}>
                    <DashboardLink link={props.link} centered>
                        {props.description}
                    </DashboardLink>
                </div>
            </div>
            {props.tooltipTranslationKey && (
                <HoverCard
                    plainCardProps={{
                        onRenderPlainCard: TooltipContents,
                        directionalHint: DirectionalHint.topCenter,
                        calloutProps: { isBeakVisible: true },
                        renderData: (
                            <Trans
                                i18nKey={props.tooltipTranslationKey}
                                components={[<strong />]}
                            />
                        ),
                    }}
                    target={tooltipTargetElementRef.current}
                    eventListenerTarget={tooltipEventListenerTargetElementRef.current}
                    cardOpenDelay={200}
                    type={HoverCardType.plain}
                    instantOpenOnClick={true}
                >
                    <div
                        data-is-focusable
                        ref={tooltipEventListenerTargetElementRef}
                        className={style["dashboardStatusCircle__tooltipIcon"]}
                    >
                        <Icon iconName="Info" />
                    </div>
                </HoverCard>
            )}
        </div>
    );
}
