import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    PageInfo,
    ShockProfile,
    ShockProfilesListShockProfilesRequest,
    ShockProfileVersionsApi,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";

export type ShockProfilesQuery = Omit<ShockProfilesListShockProfilesRequest, "page">;

export interface ShockProfileVersionsApiResource extends ApiResource {
    entity: ShockProfile;
    query: never;
    update: never;
    create: never;
    sortKey: never;
}

@component
export class RepositoryShockProfileVersions extends ElofleetRepository<ShockProfileVersionsApiResource> {
    @inject private readonly shockProfileVersionsApi!: ShockProfileVersionsApi;

    protected async fetchById(versionId: UUID): Promise<ShockProfile | undefined> {
        return await this.shockProfileVersionsApi.shockProfileVersionsReadShockProfileVersion({
            versionId,
        });
    }

    protected extractId = prop("id");

    public fetchMetadata(_query: never): Promise<PageInfo> {
        throw new Error("Method not implemented.");
    }

    public update(_id: string, _update: never): Promise<ShockProfile> {
        throw new Error("Method not implemented.");
    }

    public create(_update: never): Promise<ShockProfile> {
        throw new Error("Method not implemented.");
    }

    public delete(..._ids: string[]): Promise<void> {
        throw new Error("Method not implemented.");
    }

    protected fetchByQuery(
        _query: never,
        _pagination: Segment,
    ): Promise<FetchByQueryResult<ShockProfile>> {
        throw new Error("Method not implemented.");
    }
}
