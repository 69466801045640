import React from "react";
import { Label, Stack } from "@fluentui/react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { external, inject } from "tsdi";
import { Link } from "react-router-dom";
import colors from "../colors.scss";

export enum LabelDate {
    TODAY,
    YESTERDAY,
    OLDER,
}

export interface NotificationLabelProps {
    date: LabelDate;
    renderMarkAllRead: boolean;
    onClick: () => void;
}

/// This component is displayed in the notification Panel.
/// It is used to devide the notifications into date-based subsections.
/// I.e "Today", "Yesterday" or "Older".
/// Depending on the `renderMarkAllRead` prop, a "Mark all as read" button will be displayed.
/// Once that button is clicked, the `props.onClick` callback will be called.
@external
export class NotificationLabel extends React.Component<NotificationLabelProps> {
    @inject private i18n!: I18nProvider;

    constructor(props: NotificationLabelProps) {
        super(props);
    }

    render(): JSX.Element {
        let label;
        if (this.props.date === LabelDate.TODAY) {
            label = this.i18n.t("component.notificationLabel.today").toUpperCase();
        } else if (this.props.date === LabelDate.YESTERDAY) {
            label = this.i18n.t("component.notificationLabel.yesterday").toUpperCase();
        } else {
            label = this.i18n.t("component.notificationLabel.older").toUpperCase();
        }

        let markAllRead = <></>;
        if (this.props.renderMarkAllRead) {
            markAllRead = (
                <Stack.Item>
                    <Link
                        style={{ fontSize: 14, color: colors["secondary10"], textAlign: "right" }}
                        to=""
                        onClick={this.props.onClick}
                    >
                        {this.i18n.t("component.notificationLabel.markAllRead")}
                    </Link>
                </Stack.Item>
            );
        }

        return (
            <Stack horizontal style={{ padding: 5, marginTop: 10 }}>
                <Stack.Item grow={5}>
                    <Label style={{ color: colors["secondary10"] }}>{label}</Label>
                </Stack.Item>
                {markAllRead}
            </Stack>
        );
    }
}
