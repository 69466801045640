import * as React from "react";
import { observer } from "mobx-react";
import { Stack, TextField, DefaultButton, IColumn, Separator } from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryUserGroups } from "../../domain/repositories/repository-user-groups";
import { User, UserGroupCreate, VehicleGroup } from "../../api";
import { doubleBindString } from "../../utils/double-bind";
import { createUuid, UUID } from "../../utils/uuid";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";
import { RepositoryVehicleGroups } from "../../domain/repositories/repository-vehicle-groups";
import { RepositoryUsers } from "../../domain/repositories/repository-users";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { MultiSelectWithList } from "../organisms/multi-select-with-list";
import { ModalConfirmation } from "../atoms/modal-confirmation";
import { ElofleetDialog } from "../atoms/elofleet-dialog";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { defaultPageSize } from "../../utils/constants";

export interface FormCreateUserGroupProps {
    readonly onDialogCancel: () => void;
    readonly asDialogContent?: boolean;
    readonly isOpen: boolean;
}

/**
 * This form is part of the forms that're opened when clicking on the dropdown button in the
 * UserGroup list view.
 *
 * It is used to create a new UserGroup, but also allows to create assignments for Users and
 * VehicleGroups at the same time.
 */
@observer
@external
export class FormCreateUserGroup extends React.Component<FormCreateUserGroupProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject private readonly repositoryVehicleGroups!: RepositoryVehicleGroups;
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly repositoryUserGroups!: RepositoryUserGroups;

    @observable private cancelConfirmationModalVisible = false;
    @observable private vehicleGroupListVisible = false;

    @observable public label = "";

    // These lists contain the entities that've been added via the multi-select dropdown.
    // I.e. the Users and VehicleGroups that have been selected for assignments.
    //
    // Once the user sends this form, these entities will be used to create the
    // actual new assignments.
    @observable private addedUsers: UUID[] = [];
    @observable private addedVehicleGroups: UUID[] = [];

    // The list state ids for the respective lists of added groups.
    private userListStateId = createUuid();
    private vehicleGroupListStateId = createUuid();

    private validationId = createUuid();

    constructor(props: FormCreateUserGroupProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositoryUserGroups.validation.create.updateModel(
            this.validationId,
            this.userGroupCreate,
        );
    }

    @initialize protected initialize(): void {
        this.serviceListStates.users.initializeList(this.userListStateId, {
            query: () => {
                return {
                    pageSize: defaultPageSize,
                };
            },
            ignoreUrl: true,
        });
        this.serviceListStates.vehicleGroups.initializeList(this.vehicleGroupListStateId, {
            query: () => {
                return {
                    pageSize: defaultPageSize,
                };
            },
            ignoreUrl: true,
        });

        this.repositoryUserGroups.validation.create.initializeModel(
            this.validationId,
            this.userGroupCreate,
        );
    }

    @computed private get userGroupCreate(): UserGroupCreate {
        const create = {
            label: this.label,
            userIds: this.addedUsers,
            vehicleGroupIds: [],
        } as UserGroupCreate;

        // Only send any vehicleGroupIds, if the list is actually visible.
        if (this.vehicleGroupListVisible) {
            create.vehicleGroupIds = this.addedVehicleGroups;
        }

        return create;
    }

    /**
     * This is run if the user clicks the form's save button.
     */
    @action.bound private async createUserGroup(
        evt: React.SyntheticEvent<HTMLFormElement>,
    ): Promise<void> {
        evt.preventDefault();
        await this.repositoryUserGroups.create(this.userGroupCreate);
        this.reset();
        this.triggerValidation();

        this.closeForm();
    }

    @action.bound private reset(): void {
        this.label = "";
        this.addedUsers = [];
        this.addedVehicleGroups = [];
    }

    /**
     * This is run if the user clicks the form's cancel button.
     * A confirmation modal might pop up if there are any changes.
     */
    @action.bound private showConfirmAndCloseForm(): void {
        // If there were any changes, i.e. if the original and mutable copy aren't the same,
        // open the confirmation modal for aborting the update process.
        if (this.addedVehicleGroups.length > 0 || this.addedUsers.length > 0) {
            this.cancelConfirmationModalVisible = true;
            return;
        }

        this.closeForm();
    }

    @action.bound private toggleVehicleGroupListVisible(): void {
        this.vehicleGroupListVisible = !this.vehicleGroupListVisible;
    }

    /**
     * Actually close the component and reset all state.
     */
    @action.bound private closeForm(): void {
        this.reset();
        this.cancelConfirmationModalVisible = false;
        this.props.onDialogCancel();
    }

    @action.bound private closeCancelConfirmationModalVisible(): void {
        this.cancelConfirmationModalVisible = false;
    }

    /**
     * Handle the actual addition of all currently selected Users in the group.
     */
    @action.bound private addUsers(users: UUID[]): void {
        this.addedUsers = [...this.addedUsers, ...users];
    }

    /**
     * Handle the removal of Users.
     * In this case, removal means that they'll be removed from the list of added Users.
     */
    @action.bound private removeUsers(idsToRemove: UUID[]): void {
        const remainingUsers = this.addedUsers.filter((id) => !idsToRemove.includes(id));
        this.addedUsers = remainingUsers;
    }

    /**
     * Handle the actual addition of all currently selected Vehicles in the group.
     */
    @action.bound private addVehicleGroups(vehicleGroups: UUID[]): void {
        this.addedVehicleGroups = [...this.addedVehicleGroups, ...vehicleGroups];
    }

    /**
     * Handle the removal of VehicleGroups.
     * In this case, removal means that they'll be removed from the list of added VehicleGroups.
     */
    @action.bound private removeVehicleGroups(idsToRemove: UUID[]): void {
        const remainingGroups = this.addedVehicleGroups.filter((id) => !idsToRemove.includes(id));
        this.addedVehicleGroups = remainingGroups;
    }

    /**
     * The items that should be shown in the list of added VehicleGroups.
     */
    @computed private get vehicleGroupItems(): Object[] {
        const groups = [];

        for (const id of this.addedVehicleGroups) {
            const group = this.repositoryVehicleGroups.byId(id);
            if (group !== undefined) {
                groups.push({
                    key: group.id,
                    label: group.label,
                    assignedVehicleCount: group.assignedVehicleCount,
                    assignedUserGroupCount: group.assignedUserGroupCount,
                });
            }
        }

        return groups;
    }

    /**
     * The columns that should be shown in the list of selected VehicleGroups.
     */
    @computed private get vehicleGroupColumns(): IColumn[] {
        return [
            {
                fieldName: "label",
                name: this.i18n.t("listVehicleGroups.column.label.name"),
                key: "label",
                minWidth: 100,
            },
            {
                fieldName: "assignedVehicleCount",
                name: this.i18n.t("listVehicleGroups.column.assignedVehicleCount.name"),
                key: "assignedVehicleCount",
                minWidth: 80,
            },
            {
                fieldName: "assignedUserGroupCount",
                name: this.i18n.t("listVehicleGroups.column.assignedUserGroupCount.name"),
                key: "assignedUserGroupCount",
                minWidth: 150,
            },
        ];
    }

    /**
     * The items that should be shown in the list of added Users.
     */
    @computed private get userItems(): Object[] {
        const users = [];

        for (const id of this.addedUsers) {
            const user = this.repositoryUsers.byId(id);
            if (user !== undefined) {
                users.push({
                    key: user.id,
                    employeeId: user.employeeId,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    jobTitle: user.jobTitle,
                    fleetRole: this.i18n.formatFleetRole(user.fleetRole),
                });
            }
        }

        return users;
    }

    /**
     * The columns that should be shown in the list of selected Users.
     */
    @computed private get userColumns(): IColumn[] {
        return [
            {
                fieldName: "employeeId",
                name: this.i18n.t("listUsers.column.employeeId.name"),
                key: "employeeId",
                minWidth: 200,
                maxWidth: 300,
            },
            {
                fieldName: "firstName",
                name: this.i18n.t("listUsers.column.firstName.name"),
                key: "firstName",
                minWidth: 80,
                maxWidth: 100,
            },
            {
                fieldName: "lastName",
                name: this.i18n.t("listUsers.column.lastName.name"),
                key: "lastName",
                minWidth: 100,
                maxWidth: 150,
            },
            {
                fieldName: "jobtitle",
                name: this.i18n.t("listUsers.column.jobTitle.name"),
                key: "jobTitle",
                minWidth: 100,
                maxWidth: 150,
            },
            {
                fieldName: "fleetRole",
                name: this.i18n.t("listUsers.column.fleetRole.name"),
                key: "fleetRole",
                minWidth: 120,
                maxWidth: 200,
            },
        ];
    }

    public render(): JSX.Element {
        return (
            <ElofleetDialog
                isOpen={this.props.isOpen}
                title={this.i18n.t("formCreateUserGroup.title")}
                onDismiss={this.showConfirmAndCloseForm}
            >
                <form onSubmit={this.createUserGroup}>
                    <Stack tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                        <TextField
                            label={this.i18n.t("formCreateUserGroup.label.label")}
                            {...doubleBindString<FormCreateUserGroup>(this, "label", () =>
                                this.triggerValidation(),
                            )}
                            required
                            errorMessage={this.i18n.formatFieldValidationState(
                                this.repositoryUserGroups.validation.create.getFieldValidationState(
                                    this.validationId,
                                    "label",
                                ),
                            )}
                        />
                    </Stack>
                    <Stack
                        horizontal
                        styles={{
                            root: {
                                minWidth: "1200px",
                                minHeight: "400px",
                                padding: sizes.xl,
                            },
                        }}
                    >
                        <Stack.Item tokens={{ padding: `0px 0px 0px ${sizes.m}` }}>
                            <MultiSelectWithList
                                repository={this.repositoryUsers}
                                onAdd={this.addUsers}
                                onDelete={this.removeUsers}
                                listStateId={this.userListStateId}
                                listState={this.serviceListStates.users}
                                addedEntities={this.addedUsers}
                                columns={this.userColumns}
                                items={this.userItems}
                                formatEntity={(user: User) => this.i18n.formatUserFullName(user)}
                                addButtonText={this.i18n.t("component.multiSelect.addButton")}
                                removeButtonText={this.i18n.t("component.multiSelect.removeButton")}
                                dropdownLabel={this.i18n.t("formCreateUserGroup.user.label")}
                                multiSelectPlaceholder={this.i18n.t(
                                    "formCreateUserGroup.user.placeholder",
                                )}
                            />
                        </Stack.Item>
                        {this.vehicleGroupListVisible && (
                            <Stack.Item tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                                <Separator vertical />
                            </Stack.Item>
                        )}
                        {this.vehicleGroupListVisible && (
                            <Stack.Item tokens={{ padding: `0px ${sizes.m} 0px 0px` }}>
                                <MultiSelectWithList
                                    repository={this.repositoryVehicleGroups}
                                    onAdd={this.addVehicleGroups}
                                    onDelete={this.removeVehicleGroups}
                                    listStateId={this.vehicleGroupListStateId}
                                    listState={this.serviceListStates.vehicleGroups}
                                    addedEntities={this.addedVehicleGroups}
                                    columns={this.vehicleGroupColumns}
                                    items={this.vehicleGroupItems}
                                    formatEntity={(group: VehicleGroup) => group.label}
                                    addButtonText={this.i18n.t("component.multiSelect.addButton")}
                                    removeButtonText={this.i18n.t(
                                        "component.multiSelect.removeButton",
                                    )}
                                    dropdownLabel={this.i18n.t(
                                        "formCreateUserGroup.vehicleGroup.label",
                                    )}
                                    multiSelectPlaceholder={this.i18n.t(
                                        "formCreateUserGroup.vehicleGroup.placeholder",
                                    )}
                                />
                            </Stack.Item>
                        )}
                    </Stack>
                    {this.props.asDialogContent ? (
                        <ElofleetDialogFooter style={{ justifyContent: "space-between" }}>
                            <DefaultButton
                                label={this.i18n.t(
                                    "formCreateUserGroup.toggleVehicleGroupList.label",
                                )}
                                text={this.i18n.t(
                                    "formCreateUserGroup.toggleVehicleGroupList.text",
                                )}
                                onClick={this.toggleVehicleGroupListVisible}
                            />
                            <Stack.Item>
                                <Stack
                                    horizontal
                                    tokens={{
                                        childrenGap: "1em",
                                    }}
                                >
                                    <DefaultButton
                                        label={this.i18n.t("formCreateUserGroup.cancel.label")}
                                        text={this.i18n.t("formCreateUserGroup.cancel.text")}
                                        onClick={this.showConfirmAndCloseForm}
                                    />
                                    <PrimaryButtonValidation
                                        text={this.i18n.t("formCreateUserGroup.submit.text")}
                                        validationId={this.validationId}
                                        validation={this.repositoryUserGroups.validation.create}
                                    />
                                </Stack>
                            </Stack.Item>
                        </ElofleetDialogFooter>
                    ) : (
                        <Stack horizontal horizontalAlign="end">
                            <PrimaryButtonValidation
                                text={this.i18n.t("formCreateUserGroup.submit.text")}
                                validationId={this.validationId}
                                validation={this.repositoryUserGroups.validation.create}
                            />
                        </Stack>
                    )}
                    <ModalConfirmation
                        isOpen={this.cancelConfirmationModalVisible}
                        title={this.i18n.t("modalAbortUpdate.title")}
                        text={this.i18n.t("modalAbortUpdate.description")}
                        onConfirm={this.closeForm}
                        onCancel={this.closeCancelConfirmationModalVisible}
                    />
                </form>
            </ElofleetDialog>
        );
    }
}
