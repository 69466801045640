/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserList
 */
export interface UserList {
    /**
     * 
     * @type {boolean}
     * @memberof UserList
     */
    userImage: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserList
     */
    userEmployeeId: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserList
     */
    userLastName: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserList
     */
    userFirstName: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserList
     */
    userJobTitle: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserList
     */
    userNfcTokenDescription: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserList
     */
    userNfcToken: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserList
     */
    userFleetRole: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserList
     */
    userLicenseExpiryDate: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserList
     */
    userExpiryLogout: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserList
     */
    departmentLabel: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserList
     */
    shiftLabel: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserList
     */
    siteLabel: boolean;
}

/**
 * Check if a given object implements the UserList interface.
 */
export function instanceOfUserList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userImage" in value;
    isInstance = isInstance && "userEmployeeId" in value;
    isInstance = isInstance && "userLastName" in value;
    isInstance = isInstance && "userFirstName" in value;
    isInstance = isInstance && "userJobTitle" in value;
    isInstance = isInstance && "userNfcTokenDescription" in value;
    isInstance = isInstance && "userNfcToken" in value;
    isInstance = isInstance && "userFleetRole" in value;
    isInstance = isInstance && "userLicenseExpiryDate" in value;
    isInstance = isInstance && "userExpiryLogout" in value;
    isInstance = isInstance && "departmentLabel" in value;
    isInstance = isInstance && "shiftLabel" in value;
    isInstance = isInstance && "siteLabel" in value;

    return isInstance;
}

export function UserListFromJSON(json: any): UserList {
    return UserListFromJSONTyped(json, false);
}

export function UserListFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userImage': json['userImage'],
        'userEmployeeId': json['userEmployeeId'],
        'userLastName': json['userLastName'],
        'userFirstName': json['userFirstName'],
        'userJobTitle': json['userJobTitle'],
        'userNfcTokenDescription': json['userNfcTokenDescription'],
        'userNfcToken': json['userNfcToken'],
        'userFleetRole': json['userFleetRole'],
        'userLicenseExpiryDate': json['userLicenseExpiryDate'],
        'userExpiryLogout': json['userExpiryLogout'],
        'departmentLabel': json['departmentLabel'],
        'shiftLabel': json['shiftLabel'],
        'siteLabel': json['siteLabel'],
    };
}

export function UserListToJSON(value?: UserList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userImage': value.userImage,
        'userEmployeeId': value.userEmployeeId,
        'userLastName': value.userLastName,
        'userFirstName': value.userFirstName,
        'userJobTitle': value.userJobTitle,
        'userNfcTokenDescription': value.userNfcTokenDescription,
        'userNfcToken': value.userNfcToken,
        'userFleetRole': value.userFleetRole,
        'userLicenseExpiryDate': value.userLicenseExpiryDate,
        'userExpiryLogout': value.userExpiryLogout,
        'departmentLabel': value.departmentLabel,
        'shiftLabel': value.shiftLabel,
        'siteLabel': value.siteLabel,
    };
}

