import { action, computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { omit } from "ramda";
import { external, initialize, inject } from "tsdi";
import React from "react";
import { ColumnVisibility, PreOpsAnswerSortKey } from "../../api";
import {
    PreOpsAnswersApiResource,
    PreOpsAnswersQuery,
    RepositoryPreOpsAnswers,
} from "../../domain/repositories/repository-pre-ops-answers";
import { createUuid, UUID } from "../../utils/uuid";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import { LayoutList } from "../layouts/layout-list";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { SortState } from "../../utils/sort-state";
import { PaginationState } from "../../utils/pagination-state";
import { IColumn, IObjectWithKey, Stack } from "@fluentui/react";
import { ListPreOpsAnswerItemCell } from "../atoms/list-pre-ops-answer-item-cell";
import { startOfDay } from "date-fns";
import { ExportButton, ExportQuery } from "./export-button";
import { RepositoryColumnVisibility } from "../../domain/repositories/repository-column-visibility";
import {
    FilterPreOpsAnswerList,
    FilterPreOpsAnswerListQuery,
} from "../organisms/filter-pre-ops-answers";
import { defaultPageSize } from "../../utils/constants";

export interface ListPreOpsAnswersProps extends Omit<ElofleetListProps, "items" | "columns"> {
    dateStart?: Date;
    dateEnd?: Date;
    readonly commandBar?: JSX.Element;
}

interface ListPreOpsAnswerItem extends IObjectWithKey {
    readonly key: UUID;
}

@external
@observer
export class ListPreOpsAnswers extends React.Component<ListPreOpsAnswersProps> {
    @inject private readonly repositoryPreOpsAnswers!: RepositoryPreOpsAnswers;
    @inject private readonly repositoryColumnVisibility!: RepositoryColumnVisibility;

    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;

    @observable private filterPreOpsAnswerListQuery: FilterPreOpsAnswerListQuery = {
        filteredChoices: [],
        isFiltered: false,
    };

    private listStateId = createUuid();

    constructor(props: ListPreOpsAnswersProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.preOpsAnswers.initializeList(this.listStateId, {
            query: () => this.query,
        });
    }

    @computed private get sortState(): SortState<PreOpsAnswerSortKey> {
        return this.serviceListStates.preOpsAnswers.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<PreOpsAnswersApiResource> {
        return this.serviceListStates.preOpsAnswers.getPaginationState(this.listStateId);
    }

    @computed private get columnVisibility(): ColumnVisibility | undefined {
        return this.repositoryColumnVisibility.byQuery()[0];
    }

    @computed private get query(): PreOpsAnswersQuery {
        const { dateStart, dateEnd } = this.props;
        // For example if DatePicker for date_start is 01.01.2022 and DatePicker for date_end is 10.01.2022
        // It sends to backend date_start = 01.01.2022 00:00 and date_end = 11.01.2022 00:00
        // Backend returns all shock events from 01.01.2022 00:00 to 11.01.2022 00:00
        const query = {
            pageSize: defaultPageSize,
            dateStart: dateStart,
            dateEnd: this.getDateEndForQuery(dateEnd),
            ...this.sortState.query,
        } as PreOpsAnswersQuery;
        if (this.filterPreOpsAnswerListQuery === undefined) {
            return query;
        }
        if (this.filterPreOpsAnswerListQuery.filteredChoices) {
            query.choices = this.filterPreOpsAnswerListQuery.filteredChoices;
        }
        return query;
    }

    @computed private get exportQuery(): ExportQuery {
        const query = Object.entries(this.query);
        return { query, basePath: "/api/v1/pre_ops_answers/export?" };
    }

    private getDateEndForQuery(date: Date | undefined): Date | undefined {
        if (date === undefined) return date;

        // The Product Owner wants to include also date_end in the filter results.
        // Therefore we send the start of the following day to the backend.
        const endDateForQuery = startOfDay(date.valueOf());
        endDateForQuery.setDate(date.getDate() + 1);
        return endDateForQuery;
    }

    @computed private get items(): (ListPreOpsAnswerItem | undefined)[] {
        return this.repositoryPreOpsAnswers
            .byQuery(this.query, this.paginationState.pagination)
            .map((answer) => {
                if (!answer) {
                    return;
                }
                return {
                    key: answer.id,
                };
            });
    }

    @action.bound private setPreOpsAnswerListFilter(
        filterQuery: FilterPreOpsAnswerListQuery,
    ): void {
        this.filterPreOpsAnswerListQuery = {
            filteredChoices: filterQuery.filteredChoices,
            isFiltered: filterQuery.isFiltered,
        };
    }

    private renderItem(answerId: UUID, column: IColumn): JSX.Element {
        return <ListPreOpsAnswerItemCell answerId={answerId} column={column} />;
    }

    @computed private get columns(): IColumn[] {
        const columns: IColumn[] = [];

        columns.push({
            key: "time",
            fieldName: "time",
            name: this.i18n.t("listPreOpsAnswers.column.time.name"),
            minWidth: 100,
            maxWidth: 150,
        });
        if (this.columnVisibility?.preOpsAnswerList.preOpsAnswerChoice) {
            columns.push({
                key: "choice",
                fieldName: "choice",
                name: this.i18n.t("listPreOpsAnswers.column.choice.name"),
                minWidth: 100,
                maxWidth: 100,
            });
        }
        if (this.columnVisibility?.preOpsAnswerList.userFullName) {
            columns.push({
                key: "user",
                fieldName: "user",
                name: this.i18n.t("listPreOpsAnswers.column.user.name"),
                minWidth: 100,
                maxWidth: 150,
            });
        }
        if (this.columnVisibility?.preOpsAnswerList.vehicleSerialNumber) {
            columns.push({
                key: "vehicle",
                fieldName: "vehicle",
                name: this.i18n.t("listPreOpsAnswers.column.vehicle.name"),
                minWidth: 100,
                maxWidth: 150,
            });
        }
        if (this.columnVisibility?.preOpsAnswerList.departmentLabel) {
            columns.push({
                key: "department",
                fieldName: "department",
                name: this.i18n.t("listPreOpsAnswers.column.department.name"),
                minWidth: 100,
                maxWidth: 150,
            });
        }
        if (this.columnVisibility?.preOpsAnswerList.preOpsChecklistLabel) {
            columns.push({
                key: "checklistLabel",
                fieldName: "checklistLabel",
                name: this.i18n.t("listPreOpsAnswers.column.checklistLabel.name"),
                minWidth: 100,
                maxWidth: 150,
            });
        }
        if (this.columnVisibility?.preOpsAnswerList.preOpsQuestionBody) {
            columns.push({
                key: "question",
                fieldName: "question",
                name: this.i18n.t("listPreOpsAnswers.column.question.name"),
                minWidth: 100,
            });
        }

        return columns;
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.preOpsAnswers.isInitialized(this.listStateId)) {
            return <></>;
        }
        const props = omit(["ids"], this.props);
        return (
            <LayoutList
                paginationState={this.paginationState}
                commandBar={
                    <Stack horizontal horizontalAlign="space-between">
                        {this.props.commandBar}
                        <ExportButton query={this.exportQuery} />
                    </Stack>
                }
                filter={
                    <Stack
                        horizontal
                        style={{ width: "100%" }}
                        horizontalAlign="end"
                        verticalAlign="center"
                        tokens={{ childrenGap: 20 }}
                    >
                        <FilterPreOpsAnswerList
                            setPreOpsAnswerListFilter={this.setPreOpsAnswerListFilter}
                        />
                    </Stack>
                }
            >
                <ElofleetList
                    {...props}
                    onRenderItemColumn={(item: ListPreOpsAnswerItem, _index, column) =>
                        this.renderItem(item.key, column!)
                    }
                    onColumnHeaderClick={this.sortState.toggleColumn}
                    columns={this.sortState.patchColumns(this.columns)}
                    items={this.items}
                ></ElofleetList>
            </LayoutList>
        );
    }
}
