import { IStackProps, IStackTokens, Stack } from "@fluentui/react";
import * as React from "react";
import { external } from "tsdi";
import colors from "../colors.scss";
import sizes from "../sizes.scss";

export interface ElofleetDialogFooterProps extends IStackProps {
    readonly leftItems?: React.ReactNode;
    readonly children: React.ReactNode;
}

const stackToken: IStackTokens = {
    childrenGap: "1em",
    padding: sizes.l,
};

@external
export class ElofleetDialogFooter extends React.Component<ElofleetDialogFooterProps> {
    public render(): JSX.Element {
        return (
            <Stack
                horizontal
                grow
                style={{
                    backgroundColor: colors["secondarydialog-header-background"],
                    borderTop: `1px solid ${colors["secondarydialog-header-border"]}`,
                    marginTop: sizes.xl,
                }}
                {...this.props}
            >
                {this.props.leftItems && (
                    <Stack
                        horizontal
                        grow
                        verticalAlign="end"
                        horizontalAlign="start"
                        tokens={stackToken}
                    >
                        {this.props.leftItems}
                    </Stack>
                )}
                <Stack
                    horizontal
                    grow
                    verticalAlign="end"
                    horizontalAlign="end"
                    tokens={stackToken}
                >
                    {this.props.children}
                </Stack>
            </Stack>
        );
    }
}
