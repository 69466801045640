import { HTMLAttributes } from "react";
import { RadioGroup as BaseRadioGroup } from "@headlessui/react";
import React from "react";
import { EquipmentType, displayEquipmentType } from "../stores/equipment";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export default function Group({
    children,
    value,
    onChange,
    className,
}: {
    children: React.ReactNode;
    value: string | null;
    onChange(value: string): void;
    className?: string;
}): JSX.Element {
    return (
        <BaseRadioGroup
            value={value}
            onChange={onChange}
            className={`space-y-2 ${className ?? ""}`}
        >
            {children}
        </BaseRadioGroup>
    );
}

Group.Option = function RadioGroupOption({
    id,
    oem,
    modelName,
    serialNumber,
    department,
    equipmentType,
}: {
    id: string;
    oem?: string;
    modelName: string;
    serialNumber?: string;
    department?: string;
    equipmentType: EquipmentType;
}): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    return (
        <BaseRadioGroup.Option
            key={id}
            value={id}
            className="900 cursor-pointer rounded border border-slate-300 bg-white hover:border-slate-300 ui-checked:border-blue-900 ui-checked:bg-blue-50"
        >
            <div className="mx-4 flex h-11 place-content-between items-center">
                <div className="flex items-center gap-x-4">
                    <div className="w-32 truncate leading-6" title={oem}>
                        {oem}
                    </div>
                    <div className="w-48 truncate leading-6" title={modelName}>
                        {modelName}
                    </div>
                    <div
                        className="w-48 truncate leading-6"
                        title={displayEquipmentType(t, equipmentType)}
                    >
                        {displayEquipmentType(t, equipmentType)}
                    </div>
                    <div className="w-32 truncate leading-6" title={serialNumber}>
                        #{serialNumber}
                    </div>
                    <div className="w-32 truncate leading-6" title={department}>
                        {department}
                    </div>
                </div>
                <div className="h-6 w-6">
                    <CheckIcon className="h-6 w-6 ui-not-checked:invisible" />
                </div>
            </div>
        </BaseRadioGroup.Option>
    );
};

function CheckIcon({ className = "", ...props }: HTMLAttributes<unknown>): JSX.Element {
    return (
        <div
            className={`flex items-center justify-center rounded-full bg-slate-700 ${className}`}
            {...props}
        >
            <svg viewBox="0 0 12 12" fill="none" width="60%" height="60%">
                <path
                    d="M2.16675 7.13758L4.60933 9.5L10.1667 1.5"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                />
            </svg>
        </div>
    );
}
