import * as React from "react";
import { external, inject } from "tsdi";
import { observer } from "mobx-react";
import { NavigationItem } from "../atoms/navigation-item";
import { computed, makeObservable } from "mobx";
import { ServiceSidebar } from "../../domain/services/service-sidebar";
import { Sidebar, SidebarProps } from "../atoms/sidebar";
import { Navigation } from "../atoms/navigation";
import { omit } from "ramda";

@external
@observer
export class SidebarPrimary extends React.Component<Omit<SidebarProps, "secondary">> {
    @inject private readonly serviceSidebar!: ServiceSidebar;

    constructor(props: {}) {
        super(props);
        makeObservable(this);
    }

    @computed private get collapseIcon(): string {
        if (this.serviceSidebar.primaryCollapsed) {
            return "DoubleChevronRight";
        }
        return "DoubleChevronLeft";
    }

    public render(): JSX.Element {
        return (
            <Sidebar
                {...omit(["secondaryContent", "secondary"], this.props)}
                collapsed={this.serviceSidebar.primaryCollapsed}
                secondaryContent={
                    <div>
                        {this.props.secondaryContent}
                        <Navigation>
                            <NavigationItem
                                huge
                                title={""}
                                icon={this.collapseIcon}
                                onClick={this.serviceSidebar.togglePrimaryCollapsed}
                                separated
                            />
                        </Navigation>
                    </div>
                }
            />
        );
    }
}
