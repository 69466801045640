import {
    Checkbox,
    IconButton,
    MessageBar,
    MessageBarType,
    Separator,
    Stack,
    StackItem,
    TooltipHost,
} from "@fluentui/react";
import { UserDataExportResponse } from "../../api";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { TFunction } from "i18next";

interface UserDataExportCredentialsProps {
    credentials: UserDataExportResponse;
    email: string;
    userAcknowledgedNotice: boolean;
    onChange: (acknowledged: boolean) => void;
}
export default function UserDataExportCredentials(
    props: UserDataExportCredentialsProps,
): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    const [copiedPassword, setCopiedPassword] = useState(false);

    return (
        <>
            <Separator />
            <Stack tokens={{ childrenGap: "2rem" }}>
                <MessageBar
                    messageBarType={MessageBarType.warning}
                    messageBarIconProps={{ iconName: "Warning" }}
                    isMultiline
                >
                    {/** This component allows us to use <strong /> tags inside our translations. */}
                    <Trans
                        i18nKey={"component.exportUserButton.dialog.warning"}
                        values={{ email: props.email, exportId: props.credentials.exportId }}
                        components={[<strong />]}
                    />
                </MessageBar>
                <StackItem
                    style={{
                        textAlign: "center",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <code style={{ margin: "0 2rem" }}>{props.credentials.encryptionPassword}</code>
                    <span
                        style={{
                            position: "absolute",
                            right: 0,
                        }}
                    >
                        <TooltipHost content={t("component.exportUserButton.dialog.clipboard")}>
                            <IconButton
                                style={{ color: "black" }}
                                iconProps={
                                    copiedPassword
                                        ? { iconName: "CheckMark" }
                                        : { iconName: "Copy" }
                                }
                                // Copy passphrase to clipboard.
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        props.credentials.encryptionPassword,
                                    );
                                    setCopiedPassword(true);
                                }}
                            />
                        </TooltipHost>
                    </span>
                </StackItem>
                {/** This checkbox enables/disables the close button in the parent component
                to prevent the user from leaving the export context on accident, as the
                the password for the export data is only available once. */}
                <Checkbox
                    checked={props.userAcknowledgedNotice}
                    label={t("component.exportUserButton.dialog.checkbox")}
                    onChange={() => props.onChange(!props.userAcknowledgedNotice)}
                />
            </Stack>
        </>
    );
}
