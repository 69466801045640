import { routePath } from "../routes";
import * as React from "react";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { PageContent } from "../ui/atoms/page-content";
import { PageHeader } from "../ui/atoms/page-header";
import { useTranslation } from "react-i18next";
import { Dashboard, DashboardApi, ShockEventsPerEntity, UtilizationPerVehicle } from "../api";
import { tsdi } from "../tsdi";
import { TFunction } from "i18next";
import {
    DashboardCard,
    DashboardCardCornerFooter,
    DashboardCardHeader,
} from "../ui/atoms/dashboard-card";
import { DashboardGrid } from "../ui/organisms/dashboard-grid";
import { DashboardCockpit } from "../ui/organisms/dashboard-cockpit";
import { Line, LineChart } from "../ui/atoms/line-chart";
import { addDays, startOfToday, subDays } from "date-fns";
import DashboardLink from "../ui/atoms/dashboard-link";
import {
    CommandBarButton,
    DirectionalHint,
    ICalloutProps,
    Icon,
    IContextualMenuProps,
    ITeachingBubbleProps,
    Shimmer,
    TeachingBubble,
} from "@fluentui/react";
import * as Table from "../ui/molecules/dashboard-table";
import { InlineStackedBarChart } from "../ui/atoms/inline-stacked-bar-chart";
import colors from "./dashboard-colors.scss";
import { _ExpectTypeOf } from "expect-type";
import { useLocalStorage } from "../utils/hooks/use-local-storage";
import { useSearchParams } from "react-router-dom";
import { articles } from "../help";

enum CardRange {
    Today = "today",
    Week = "week",
    Month = "month",
    Quarter = "quarter",
}

function rangeToTimestamps(range: CardRange): { start: Date; end: Date } {
    const now = new Date();
    switch (range) {
        case CardRange.Today:
            return { start: startOfToday(), end: now };
        case CardRange.Week:
            return { start: subDays(now, 7), end: now };
        case CardRange.Month:
            return { start: subDays(now, 30), end: now };
        case CardRange.Quarter:
            return { start: subDays(now, 90), end: now };
    }
}

function maintenanceRangeToTimestamp(range: CardRange): { start: Date; end: Date } {
    const now = new Date();
    switch (range) {
        case CardRange.Today:
            return { start: startOfToday(), end: addDays(startOfToday(), 1) };
        case CardRange.Week:
            return { start: subDays(now, 7), end: addDays(now, 7) };
        case CardRange.Month:
            return { start: subDays(now, 30), end: addDays(now, 30) };
        case CardRange.Quarter:
            return { start: subDays(now, 90), end: addDays(now, 90) };
    }
}

function formatOptionsForDateRange(range: CardRange): Intl.DateTimeFormatOptions {
    switch (range) {
        case CardRange.Today:
            return { hour: "numeric", hour12: false, minute: "2-digit" };
        case CardRange.Week:
            return {
                month: "2-digit",
                day: "2-digit",
                weekday: "short",
            };
        case CardRange.Month:
            return {
                month: "2-digit",
                day: "2-digit",
                weekday: "short",
            };
        case CardRange.Quarter:
            return {
                month: "2-digit",
                day: "2-digit",
                weekday: "short",
            };
    }
}

export function PageDashboard(_props: {}): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    const [dashboardData, setDashboardData] = React.useState<Dashboard | undefined>(undefined);
    const [shockEventChartRange, setShockEventChartRange] = useLocalStorage<CardRange>(
        "dashboardShockEventChartRange",
        CardRange.Today,
    );
    const [vehiclesByShockEventsRange, setVehiclesByShockEventsRange] = useLocalStorage<CardRange>(
        "dashboardShockEventTableRange",
        CardRange.Today,
    );
    const [vehicleUtilizationRange, setVehicleUtilizationRange] = useLocalStorage<CardRange>(
        "dashboardVehicleUtilizationTableRange",
        CardRange.Today,
    );
    const [usersByShockEventsRange, setUsersByShockEventsRange] = useLocalStorage<CardRange>(
        "dashboardUsersByShockEventsTableRange",
        CardRange.Today,
    );
    const [maintenanceChartRange, setMaintenanceChartRange] = useLocalStorage<CardRange>(
        "dashboardMaintenanceChartRange",
        CardRange.Week,
    );
    const loading = dashboardData === undefined;

    const dashboardApi = tsdi.get(DashboardApi);
    React.useEffect(() => {
        const today = startOfToday();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        const shockEventChartTimestamps = rangeToTimestamps(shockEventChartRange);
        const vehiclesByShockEventsTimestamps = rangeToTimestamps(vehiclesByShockEventsRange);
        const vehicleUtilizationTimestamps = rangeToTimestamps(vehicleUtilizationRange);
        const usersByShockEventsTimestamps = rangeToTimestamps(usersByShockEventsRange);
        const maintenanceChartTimestamps = maintenanceRangeToTimestamp(maintenanceChartRange);

        dashboardApi
            .dashboardReadDashboardData({
                todayStart: today,
                todayEnd: tomorrow,
                shockEventChartPeriodStart: shockEventChartTimestamps.start,
                shockEventChartPeriodEnd: shockEventChartTimestamps.end,
                maintenanceChartPeriodStart: maintenanceChartTimestamps.start,
                maintenanceChartPeriodEnd: maintenanceChartTimestamps.end,
                topVehiclesByShockEventsPeriodStart: vehiclesByShockEventsTimestamps.start,
                topVehiclesByShockEventsPeriodEnd: vehiclesByShockEventsTimestamps.end,
                vehicleUtilizationPeriodStart: vehicleUtilizationTimestamps.start,
                vehicleUtilizationPeriodEnd: vehicleUtilizationTimestamps.end,
                topUsersByShockEventsPeriodStart: usersByShockEventsTimestamps.start,
                topUsersByShockEventsPeriodEnd: usersByShockEventsTimestamps.end,
            })
            .then(setDashboardData);
    }, [
        dashboardApi,
        shockEventChartRange,
        maintenanceChartRange,
        vehiclesByShockEventsRange,
        vehicleUtilizationRange,
        usersByShockEventsRange,
    ]);

    const impactData: Line[] = [
        {
            color: colors.impactChartLight,
            points:
                dashboardData?.shockEventChartData.map((backendPoint) => [
                    backendPoint.bucket.getTime(),
                    backendPoint.light,
                ]) ?? [],
            label: t("page.dashboard.impacts.light"),
        },
        {
            color: colors.impactChartMedium,
            points:
                dashboardData?.shockEventChartData.map((backendPoint) => [
                    backendPoint.bucket.getTime(),
                    backendPoint.medium,
                ]) ?? [],
            label: t("page.dashboard.impacts.medium"),
        },
        {
            color: colors.impactChartSevere,
            points:
                dashboardData?.shockEventChartData.map((backendPoint) => [
                    backendPoint.bucket.getTime(),
                    backendPoint.severe,
                ]) ?? [],
            label: t("page.dashboard.impacts.severe"),
        },
    ];

    let maintenanceChartData: Line[] = [];
    const maintenanceChartActualPoints =
        dashboardData?.maintenanceChartData
            .filter((point) => !point.predicted)
            .map((backendPoint): [number, number] => [
                backendPoint.bucket.getTime(),
                backendPoint.count,
            ]) ?? [];

    const maintenanceChartPredictedPoints =
        dashboardData?.maintenanceChartData
            .filter((point) => point.predicted)
            .map((backendPoint): [number, number] => [
                backendPoint.bucket.getTime(),
                backendPoint.count,
            ]) ?? [];

    const lastActualPoint = maintenanceChartActualPoints[maintenanceChartActualPoints.length - 1];

    if (lastActualPoint !== undefined) {
        maintenanceChartPredictedPoints?.unshift(lastActualPoint);
    }

    maintenanceChartData = [
        {
            color: colors.maintenanceChartPast,
            points: maintenanceChartActualPoints,
            label: t("page.dashboard.vehiclesInMaintenance"),
        },
        {
            color: colors.maintenanceChartPredicted,
            points: maintenanceChartPredictedPoints,
            label: t("page.dashboard.vehiclesInMaintenancePredicted"),
        },
    ];

    const formatTimestamp =
        (range: CardRange) =>
        (timestamp: number): string => {
            return t("{{ val, datetime }}", {
                val: new Date(timestamp),
                interpolation: {
                    escapeValue: false,
                },
                formatParams: {
                    val: formatOptionsForDateRange(range),
                },
            });
        };

    const cardDropdown = (setter: (val: CardRange) => void): IContextualMenuProps => {
        return {
            items: Object.values(CardRange).map((key) => {
                return {
                    key,
                    text: t(`page.dashboard.dateRange.${key}`),
                    onClick: () => setter(key),
                };
            }),
        };
    };

    const maintenanceCardDropdown: IContextualMenuProps = {
        items: Object.values(CardRange).map((key) => {
            return {
                key,
                text: t(`page.dashboard.maintenanceDateRange.${key}`),
                onClick: () => setMaintenanceChartRange(key),
            };
        }),
    };

    const [showTutorial, setShowTutorial] = useLocalStorage("dashboardShowTutorial", true);

    const tutorialTargetIds = {
        welcome: "dashboardTutorialTargetWelcome",
        tooltips: "dashboardTutorialTargetTooltips",
        drillDown: "dashboardTutorialTargetDrillDown",
        truckUtilization: "dashboardTutorialTargetTruckUtilization",
        dateRange: "dashboardTutorialTargetDateRange",
        revisit: "dashboardTutorialTargetRevisit",
    };

    const [searchParams, setSearchParams] = useSearchParams();
    React.useEffect(() => {
        if (searchParams.get("showTutorial") === "true") {
            setShowTutorial(true);
            setSearchParams({});
        }
    }, [searchParams, setShowTutorial, setSearchParams]);

    return (
        <LayoutDefault
            suggestedHelpArticles={[
                articles.howToAddUser,
                articles.userDefinition,
                articles.fleetUserRoles,
                articles.managementUserRoles,
            ]}
            header={<PageHeader title={t("page.dashboard.navbarEntry")} />}
        >
            <DashboardCockpit data={dashboardData} tutorialId={tutorialTargetIds.welcome} />
            <PageContent>
                <DashboardGrid>
                    <DashboardCard>
                        <DashboardCardHeader
                            dropdown={
                                <CommandBarButton
                                    text={t(`page.dashboard.dateRange.${shockEventChartRange}`)}
                                    menuProps={cardDropdown(setShockEventChartRange)}
                                ></CommandBarButton>
                            }
                            tooltipTranslationKey={"page.dashboard.impactsOverTime.explanation"}
                        >
                            <span>
                                <>{t("page.dashboard.impactsOverTime")}</>

                                <Icon iconName="Info" id={tutorialTargetIds.tooltips} />
                            </span>
                        </DashboardCardHeader>
                        <LineChart
                            lines={impactData}
                            formatXValue={formatTimestamp(shockEventChartRange)}
                            loading={loading}
                        />
                        <DashboardCardCornerFooter id={tutorialTargetIds.drillDown}>
                            <DashboardLink link={routePath.impactManagement()}>
                                {t("page.dashboard.expandCard")}
                            </DashboardLink>
                        </DashboardCardCornerFooter>
                    </DashboardCard>
                    <DashboardCard>
                        <DashboardCardHeader
                            dropdown={
                                <CommandBarButton
                                    text={t(
                                        `page.dashboard.maintenanceDateRange.${maintenanceChartRange}`,
                                    )}
                                    menuProps={maintenanceCardDropdown}
                                ></CommandBarButton>
                            }
                            tooltipTranslationKey={
                                "page.dashboard.vehiclesInMaintenance.explanation"
                            }
                        >
                            <>{t("page.dashboard.vehiclesInMaintenance")}</>
                            <Icon iconName="Info" />
                        </DashboardCardHeader>
                        <LineChart
                            lines={maintenanceChartData}
                            formatXValue={formatTimestamp(maintenanceChartRange)}
                            loading={loading}
                        />
                        <DashboardCardCornerFooter>
                            <DashboardLink link={routePath.maintenance()}>
                                {t("page.dashboard.expandCard")}
                            </DashboardLink>
                        </DashboardCardCornerFooter>
                    </DashboardCard>
                    <DashboardCard>
                        <DashboardCardHeader
                            dropdown={
                                <CommandBarButton
                                    text={t(
                                        `page.dashboard.dateRange.${vehiclesByShockEventsRange}`,
                                    )}
                                    menuProps={cardDropdown(setVehiclesByShockEventsRange)}
                                ></CommandBarButton>
                            }
                            tooltipTranslationKey={"page.dashboard.impactsPerVehicle.explanation"}
                        >
                            <>{t("page.dashboard.impactsPerVehicle")}</>
                            <Icon iconName="Info" />
                        </DashboardCardHeader>
                        <Table.Table>
                            <Table.Head>
                                <Table.Row>
                                    <Table.HeaderCell border>
                                        <>{t("page.dashboard.listHeader.vehicleId")}</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell border>
                                        <>{t("page.dashboard.listHeader.impactDistribution")}</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{ textAlign: "right" }}>
                                        <>{t("page.dashboard.listHeader.totalImpacts")}</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell compact>
                                        <>{t("page.dashboard.listHeader.chart")}</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <>{t("page.dashboard.listHeader.trend")}</>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Head>
                            <Table.Body>
                                {loading
                                    ? Array.from(Array(5).keys()).map(() => (
                                          <ImpactTableRowShimmer />
                                      ))
                                    : dashboardData.topVehiclesByShockEvents.map((row) => (
                                          <ImpactTableRow row={row} key={row.entityId} />
                                      ))}
                            </Table.Body>
                        </Table.Table>
                        {!loading && dashboardData.topVehiclesByShockEvents.length === 0 && (
                            <p>
                                <>{t("page.dashboard.impacts.noData")}</>
                            </p>
                        )}
                        <DashboardCardCornerFooter>
                            <DashboardLink link={routePath.impactManagement()}>
                                {t("page.dashboard.expandCard")}
                            </DashboardLink>
                        </DashboardCardCornerFooter>
                    </DashboardCard>
                    <DashboardCard id={tutorialTargetIds.truckUtilization}>
                        <DashboardCardHeader
                            dropdown={
                                <CommandBarButton
                                    text={t(`page.dashboard.dateRange.${vehicleUtilizationRange}`)}
                                    menuProps={cardDropdown(setVehicleUtilizationRange)}
                                    id={tutorialTargetIds.dateRange}
                                ></CommandBarButton>
                            }
                            tooltipTranslationKey={"page.dashboard.vehicleUtilization.explanation"}
                        >
                            <>{t("page.dashboard.vehicleUtilization")}</>
                            <Icon iconName="Info" />
                        </DashboardCardHeader>
                        <Table.Table>
                            <Table.Head>
                                <Table.Row>
                                    <Table.HeaderCell border>
                                        <>{t("page.dashboard.listHeader.vehicleId")}</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{ textAlign: "right" }}>
                                        <>{t("page.dashboard.listHeader.driveTime")}</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{ textAlign: "right" }}>
                                        <>{t("page.dashboard.listHeader.driveTimeGoal")}</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{ textAlign: "right" }}>
                                        <>{t("page.dashboard.listHeader.utilization")}</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell compact>
                                        <>{t("page.dashboard.listHeader.chart")}</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <>{t("page.dashboard.listHeader.trend")}</>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Head>
                            <Table.Body>
                                {loading
                                    ? Array.from(Array(5).keys()).map(() => (
                                          <UtilizationRowShimmer />
                                      ))
                                    : dashboardData.vehicleUtilization.map((row) => (
                                          <UtilizationRow row={row} key={row.vehicleId} />
                                      ))}
                            </Table.Body>
                        </Table.Table>
                        {!loading && dashboardData.vehicleUtilization.length === 0 && (
                            <p>
                                <>{t("page.dashboard.vehicleUtilization.noData")}</>
                            </p>
                        )}
                        <DashboardCardCornerFooter>
                            <DashboardLink link={routePath.vehicleProductivity()}>
                                {t("page.dashboard.expandCard")}
                            </DashboardLink>
                        </DashboardCardCornerFooter>
                    </DashboardCard>
                    <DashboardCard>
                        <DashboardCardHeader
                            dropdown={
                                <CommandBarButton
                                    text={t(`page.dashboard.dateRange.${usersByShockEventsRange}`)}
                                    menuProps={cardDropdown(setUsersByShockEventsRange)}
                                ></CommandBarButton>
                            }
                            tooltipTranslationKey={"page.dashboard.impactsPerDriver.explanation"}
                        >
                            <>{t("page.dashboard.impactsPerDriver")}</>
                            <Icon iconName="Info" />
                        </DashboardCardHeader>
                        <Table.Table>
                            <Table.Head>
                                <Table.Row>
                                    <Table.HeaderCell border>
                                        <>{t("page.dashboard.listHeader.driver")}</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell border>
                                        <>{t("page.dashboard.listHeader.impactDistribution")}</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{ textAlign: "right" }}>
                                        <>{t("page.dashboard.listHeader.totalImpacts")}</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell compact>
                                        <>{t("page.dashboard.listHeader.chart")}</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <>{t("page.dashboard.listHeader.trend")}</>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Head>
                            <Table.Body>
                                {loading
                                    ? Array.from(Array(5).keys()).map(() => (
                                          <ImpactTableRowShimmer />
                                      ))
                                    : dashboardData.topUsersByShockEvents.map((row) => (
                                          <ImpactTableRow row={row} key={row.entityId} />
                                      ))}
                            </Table.Body>
                        </Table.Table>
                        {!loading && dashboardData.topUsersByShockEvents.length === 0 && (
                            <p>
                                <>{t("page.dashboard.impacts.noData")}</>
                            </p>
                        )}
                        <DashboardCardCornerFooter>
                            <DashboardLink link={routePath.users()}>
                                {t("page.dashboard.expandCard")}
                            </DashboardLink>
                        </DashboardCardCornerFooter>
                    </DashboardCard>
                </DashboardGrid>
                {showTutorial && (
                    <TutorialBubbles
                        targetIds={tutorialTargetIds}
                        onDismiss={() => setShowTutorial(false)}
                    />
                )}
            </PageContent>
        </LayoutDefault>
    );
}

function UtilizationRow({ row }: { row: UtilizationPerVehicle }): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    const trendValue = (row.chartData[row.chartData.length - 1] - row.chartData[0]) * 100;

    return (
        <Table.Row key={row.vehicleId}>
            <Table.Cell
                border
                style={{
                    maxWidth: "20ch",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                title={row.vehicleSerialNumber}
            >
                {row.vehicleSerialNumber}
            </Table.Cell>
            <Table.Cell style={{ textAlign: "right", width: "15%" }}>
                <>
                    {t("{{ val, number }}", {
                        val: row.driveTimeSeconds / 3600,
                        maximumFractionDigits: 1,
                    })}
                </>
            </Table.Cell>
            <Table.Cell style={{ textAlign: "right", width: "15%" }}>
                <>
                    {t("{{ val, number }}", {
                        val: row.driveTimeGoalSeconds / 3600,
                        maximumFractionDigits: 1,
                    })}
                </>
            </Table.Cell>
            <Table.Cell style={{ textAlign: "right", width: "15%" }}>
                <>
                    {t("{{ val, number }}%", {
                        val: (row.driveTimeSeconds / row.driveTimeGoalSeconds) * 100,
                        maximumFractionDigits: 0,
                    })}
                </>
            </Table.Cell>
            <Table.Cell compact style={{ width: "5rem" }}>
                <LineChart
                    lines={[
                        {
                            points: row.chartData.map((y, x) => [x, y]),
                            color: colors.tableInlineLineChart,
                        },
                    ]}
                    lineThickness={16}
                    showScales={false}
                    showBackgroundGuides={false}
                    aspectRatio={10 / 2}
                />
            </Table.Cell>
            <Table.Cell style={{ width: "10%" }}>
                <TrendArrow value={trendValue} upIsGood />{" "}
                <>
                    {t("{{ val, number }}%", {
                        val: Math.abs(trendValue),
                        maximumFractionDigits: 0,
                    })}
                </>
            </Table.Cell>
        </Table.Row>
    );
}

/** Returns a utilization table row with FluentUI's <Shimmer /> as cell content. */
function UtilizationRowShimmer(): JSX.Element {
    return (
        <Table.Row>
            <Table.Cell
                border
                style={{
                    maxWidth: "20ch",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                <Shimmer />
            </Table.Cell>
            <Table.Cell style={{ textAlign: "right", width: "15%" }}>
                <Shimmer />
            </Table.Cell>
            <Table.Cell style={{ textAlign: "right", width: "15%" }}>
                <Shimmer />
            </Table.Cell>
            <Table.Cell style={{ textAlign: "right", width: "15%" }}>
                <Shimmer />
            </Table.Cell>
            <Table.Cell compact style={{ width: "5rem" }}>
                <Shimmer />
            </Table.Cell>
            <Table.Cell style={{ width: "10%" }}>
                <Shimmer />
            </Table.Cell>
        </Table.Row>
    );
}

function ImpactTableRow({ row }: { row: ShockEventsPerEntity }): JSX.Element {
    const firstValue = row.chartData[0];
    const lastValue = row.chartData[row.chartData.length - 1];
    const trendValue = lastValue - firstValue;
    const chartPoints = row.chartData.map((y, i): [number, number] => [i, y]);
    return (
        <Table.Row>
            <Table.Cell
                border
                title={row.entityName}
                style={{
                    maxWidth: "20ch",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {row.entityName}
            </Table.Cell>
            <Table.Cell border style={{ width: "30%" }}>
                <InlineStackedBarChart
                    bars={[
                        { count: row.light, color: colors.impactChartLight },
                        { count: row.medium, color: colors.impactChartMedium },
                        { count: row.severe, color: colors.impactChartSevere },
                    ]}
                />
            </Table.Cell>
            <Table.Cell style={{ textAlign: "right", width: "15%" }}>
                {row.light + row.medium + row.severe}
            </Table.Cell>
            <Table.Cell compact style={{ width: "5rem" }}>
                <LineChart
                    lines={[
                        {
                            points: chartPoints,
                            color: colors.tableInlineLineChart,
                        },
                    ]}
                    lineThickness={16}
                    showScales={false}
                    showBackgroundGuides={false}
                    aspectRatio={10 / 2}
                />
            </Table.Cell>
            <Table.Cell style={{ width: "15%" }}>
                <TrendArrow value={trendValue} upIsGood={false} /> {Math.abs(trendValue)}
            </Table.Cell>
        </Table.Row>
    );
}

/** Returns an impact table row with FluentUI's <Shimmer /> as cell content. */
function ImpactTableRowShimmer(): JSX.Element {
    return (
        <Table.Row>
            <Table.Cell
                border
                style={{
                    maxWidth: "20ch",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                <Shimmer />
            </Table.Cell>
            <Table.Cell border style={{ width: "30%" }}>
                <Shimmer />
            </Table.Cell>
            <Table.Cell style={{ textAlign: "right", width: "15%" }}>
                <Shimmer />
            </Table.Cell>
            <Table.Cell compact style={{ width: "5rem" }}>
                <Shimmer />
            </Table.Cell>
            <Table.Cell style={{ width: "15%" }}>
                <Shimmer />
            </Table.Cell>
        </Table.Row>
    );
}

function TrendArrow({ value, upIsGood }: { value: number; upIsGood: boolean }): JSX.Element {
    if (value > 0) {
        const color = upIsGood ? colors.trendGreen : colors.trendRed;
        return <Icon iconName="Up" style={{ color }} />;
    } else if (value < 0) {
        const color = upIsGood ? colors.trendRed : colors.trendGreen;
        return <Icon iconName="Down" style={{ color }} />;
    } else {
        return <Icon iconName="CalculatorSubtract" style={{ verticalAlign: "middle" }} />;
    }
}
const calloutStyles: ICalloutProps["styles"] = {
    root: {
        // FluentUI sets the width of a callout to "calc(100% + 1px)"
        // This leads to a cut off of the teaching bubble, therefore
        // unset it.
        width: "unset",
        minWidth: "40ch",
    },
};

const teachingBubbleStyles: ITeachingBubbleProps["styles"] = {
    subComponentStyles: {
        callout: calloutStyles,
    },
};

function TutorialBubbles({
    targetIds,
    ...props
}: {
    targetIds: Record<string, string>;
    onDismiss: () => void;
}): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    const stepSequence = Object.keys(targetIds);
    const [tutorialStep, setTutorialStep] = React.useState<string>(stepSequence[0]);

    const nextStep = (): void => {
        const currentIndex = stepSequence.indexOf(tutorialStep);
        if (currentIndex + 1 < stepSequence.length) {
            const tutorialStep = stepSequence[currentIndex + 1];
            setTutorialStep(tutorialStep);
            // Scroll to the next anchor element of the tutorial.
            const anchorElement = document.getElementById(targetIds[tutorialStep]);
            anchorElement!.scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
            props.onDismiss();
        }
    };

    const previousStep = (): void => {
        const currentIndex = stepSequence.indexOf(tutorialStep);
        if (currentIndex - 1 >= 0) {
            setTutorialStep(stepSequence[currentIndex - 1]);
        }
    };

    const [rerenderCount, updateRerenderCount] = React.useState(0);
    const forceRerender = (): void => updateRerenderCount(rerenderCount + 1);

    const primaryButtonLabelKey =
        tutorialStep === stepSequence[stepSequence.length - 1] ? "finish" : "gotIt";

    const isFirst = stepSequence.indexOf(tutorialStep) == 0;
    return (
        <TeachingBubble
            target={`#${targetIds[tutorialStep]}`}
            headline={t(`page.dashboard.tutorial.${tutorialStep}.header`)}
            primaryButtonProps={{
                children: <>{t(`page.dashboard.tutorial.${primaryButtonLabelKey}`)}</>,
                onClick: nextStep,
            }}
            secondaryButtonProps={{
                children: (
                    <>
                        {isFirst
                            ? t(`page.dashboard.tutorial.skip`)
                            : t(`page.dashboard.tutorial.back`)}
                    </>
                ),
                onClick: isFirst ? props.onDismiss : previousStep,
                styles: { root: { color: "white" } },
            }}
            hasCloseButton
            footerContent={t("page.dashboard.tutorial.footer", {
                currentStep: stepSequence.indexOf(tutorialStep) + 1,
                totalSteps: stepSequence.length,
            })}
            onDismiss={(ev) => {
                if (ev.type === "scroll" || ev.type === "resize") {
                    // The TeachingBubble doesn't update it's position on
                    // scroll, so we need to force a rerender here.
                    forceRerender();
                    return;
                }
                props.onDismiss();
            }}
            calloutProps={{
                directionalHint: DirectionalHint.bottomCenter,
            }}
            styles={teachingBubbleStyles}
        >
            <div style={{ whiteSpace: "pre-line" }}>
                <>{t(`page.dashboard.tutorial.${tutorialStep}.body`)}</>
            </div>
        </TeachingBubble>
    );
}

export const routeDashboard = {
    navigation: true,
    component: PageDashboard,
    icon: "Home",
    title: "page.dashboard.navbarEntry",
    path: routePath.dashboard,
    pattern: "/dashboard",
};
