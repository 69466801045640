/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FleetAuthenticateConnectionStatus {
    ONLINE = 'online',
    OFFLINE = 'offline'
}


export function FleetAuthenticateConnectionStatusFromJSON(json: any): FleetAuthenticateConnectionStatus {
    return FleetAuthenticateConnectionStatusFromJSONTyped(json, false);
}

export function FleetAuthenticateConnectionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): FleetAuthenticateConnectionStatus {
    return json as FleetAuthenticateConnectionStatus;
}

export function FleetAuthenticateConnectionStatusToJSON(value?: FleetAuthenticateConnectionStatus | null): any {
    return value as any;
}

