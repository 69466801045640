/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MaintenanceChartDataPoint } from './MaintenanceChartDataPoint';
import {
    MaintenanceChartDataPointFromJSON,
    MaintenanceChartDataPointFromJSONTyped,
    MaintenanceChartDataPointToJSON,
} from './MaintenanceChartDataPoint';
import type { ShockEventChartDataPoint } from './ShockEventChartDataPoint';
import {
    ShockEventChartDataPointFromJSON,
    ShockEventChartDataPointFromJSONTyped,
    ShockEventChartDataPointToJSON,
} from './ShockEventChartDataPoint';
import type { ShockEventsPerEntity } from './ShockEventsPerEntity';
import {
    ShockEventsPerEntityFromJSON,
    ShockEventsPerEntityFromJSONTyped,
    ShockEventsPerEntityToJSON,
} from './ShockEventsPerEntity';
import type { UtilizationPerVehicle } from './UtilizationPerVehicle';
import {
    UtilizationPerVehicleFromJSON,
    UtilizationPerVehicleFromJSONTyped,
    UtilizationPerVehicleToJSON,
} from './UtilizationPerVehicle';

/**
 * 
 * @export
 * @interface Dashboard
 */
export interface Dashboard {
    /**
     * 
     * @type {number}
     * @memberof Dashboard
     */
    severeImpactsToday: number;
    /**
     * 
     * @type {number}
     * @memberof Dashboard
     */
    licensesExpiringNextMonth: number;
    /**
     * 
     * @type {number}
     * @memberof Dashboard
     */
    vehiclesOverdueForMaintenanceNow: number;
    /**
     * 
     * @type {number}
     * @memberof Dashboard
     */
    vehiclesInMaintenanceNow: number;
    /**
     * 
     * @type {number}
     * @memberof Dashboard
     */
    totalOperatingSecondsToday: number;
    /**
     * 
     * @type {number}
     * @memberof Dashboard
     */
    totalFleetUsersLoggedInToday: number;
    /**
     * 
     * @type {number}
     * @memberof Dashboard
     */
    activeVehiclesToday: number;
    /**
     * List of shock event chart data points within the specified shock event chart period.
     * @type {Array<ShockEventChartDataPoint>}
     * @memberof Dashboard
     */
    shockEventChartData: Array<ShockEventChartDataPoint>;
    /**
     * 
     * @type {Array<MaintenanceChartDataPoint>}
     * @memberof Dashboard
     */
    maintenanceChartData: Array<MaintenanceChartDataPoint>;
    /**
     * The five vehicles with the most shock events within the specified top vehicles by shock events period.
     * @type {Array<ShockEventsPerEntity>}
     * @memberof Dashboard
     */
    topVehiclesByShockEvents: Array<ShockEventsPerEntity>;
    /**
     * The five users with the most shock events within the specified top users by shock events period.
     * @type {Array<ShockEventsPerEntity>}
     * @memberof Dashboard
     */
    topUsersByShockEvents: Array<ShockEventsPerEntity>;
    /**
     * The five vehicles with the lowest utilization within the specified vehicle utilization period.
     * @type {Array<UtilizationPerVehicle>}
     * @memberof Dashboard
     */
    vehicleUtilization: Array<UtilizationPerVehicle>;
    /**
     * Mean utilization of all vehicles in the last 24 hours as a value between 0 and 1.
     * @type {number}
     * @memberof Dashboard
     */
    meanVehicleUtilization: number;
}

/**
 * Check if a given object implements the Dashboard interface.
 */
export function instanceOfDashboard(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "severeImpactsToday" in value;
    isInstance = isInstance && "licensesExpiringNextMonth" in value;
    isInstance = isInstance && "vehiclesOverdueForMaintenanceNow" in value;
    isInstance = isInstance && "vehiclesInMaintenanceNow" in value;
    isInstance = isInstance && "totalOperatingSecondsToday" in value;
    isInstance = isInstance && "totalFleetUsersLoggedInToday" in value;
    isInstance = isInstance && "activeVehiclesToday" in value;
    isInstance = isInstance && "shockEventChartData" in value;
    isInstance = isInstance && "maintenanceChartData" in value;
    isInstance = isInstance && "topVehiclesByShockEvents" in value;
    isInstance = isInstance && "topUsersByShockEvents" in value;
    isInstance = isInstance && "vehicleUtilization" in value;
    isInstance = isInstance && "meanVehicleUtilization" in value;

    return isInstance;
}

export function DashboardFromJSON(json: any): Dashboard {
    return DashboardFromJSONTyped(json, false);
}

export function DashboardFromJSONTyped(json: any, ignoreDiscriminator: boolean): Dashboard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'severeImpactsToday': json['severeImpactsToday'],
        'licensesExpiringNextMonth': json['licensesExpiringNextMonth'],
        'vehiclesOverdueForMaintenanceNow': json['vehiclesOverdueForMaintenanceNow'],
        'vehiclesInMaintenanceNow': json['vehiclesInMaintenanceNow'],
        'totalOperatingSecondsToday': json['totalOperatingSecondsToday'],
        'totalFleetUsersLoggedInToday': json['totalFleetUsersLoggedInToday'],
        'activeVehiclesToday': json['activeVehiclesToday'],
        'shockEventChartData': ((json['shockEventChartData'] as Array<any>).map(ShockEventChartDataPointFromJSON)),
        'maintenanceChartData': ((json['maintenanceChartData'] as Array<any>).map(MaintenanceChartDataPointFromJSON)),
        'topVehiclesByShockEvents': ((json['topVehiclesByShockEvents'] as Array<any>).map(ShockEventsPerEntityFromJSON)),
        'topUsersByShockEvents': ((json['topUsersByShockEvents'] as Array<any>).map(ShockEventsPerEntityFromJSON)),
        'vehicleUtilization': ((json['vehicleUtilization'] as Array<any>).map(UtilizationPerVehicleFromJSON)),
        'meanVehicleUtilization': json['meanVehicleUtilization'],
    };
}

export function DashboardToJSON(value?: Dashboard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'severeImpactsToday': value.severeImpactsToday,
        'licensesExpiringNextMonth': value.licensesExpiringNextMonth,
        'vehiclesOverdueForMaintenanceNow': value.vehiclesOverdueForMaintenanceNow,
        'vehiclesInMaintenanceNow': value.vehiclesInMaintenanceNow,
        'totalOperatingSecondsToday': value.totalOperatingSecondsToday,
        'totalFleetUsersLoggedInToday': value.totalFleetUsersLoggedInToday,
        'activeVehiclesToday': value.activeVehiclesToday,
        'shockEventChartData': ((value.shockEventChartData as Array<any>).map(ShockEventChartDataPointToJSON)),
        'maintenanceChartData': ((value.maintenanceChartData as Array<any>).map(MaintenanceChartDataPointToJSON)),
        'topVehiclesByShockEvents': ((value.topVehiclesByShockEvents as Array<any>).map(ShockEventsPerEntityToJSON)),
        'topUsersByShockEvents': ((value.topUsersByShockEvents as Array<any>).map(ShockEventsPerEntityToJSON)),
        'vehicleUtilization': ((value.vehicleUtilization as Array<any>).map(UtilizationPerVehicleToJSON)),
        'meanVehicleUtilization': value.meanVehicleUtilization,
    };
}

