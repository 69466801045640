import { runInAction } from "mobx";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    PageInfo,
    ShiftCreate,
    ShiftsApi,
    ShiftsListShiftsRequest,
    ShiftSortKey,
    ShiftUpdate,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { Validation } from "../../utils/validation";
import { Shift } from "../../api";
import { defaultPageSize } from "../../utils/constants";

export type ShiftsQuery = Omit<ShiftsListShiftsRequest, "page">;

export interface ShiftsApiResource extends ApiResource {
    entity: Shift;
    query: ShiftsQuery;
    update: ShiftUpdate;
    create: ShiftCreate;
    sortKey: ShiftSortKey;
}

@component
export class RepositoryShifts extends ElofleetRepository<ShiftsApiResource> {
    @inject private readonly shiftsApi!: ShiftsApi;

    public validation = {
        create: new Validation((shiftCreate: ShiftCreate) =>
            this.shiftsApi.shiftsValidateCreateShift({
                shiftCreate: shiftCreate,
            }),
        ),
        update: new Validation((shiftUpdate: ShiftUpdate, shiftId: UUID) =>
            this.shiftsApi.shiftsValidateUpdateShift({
                shiftUpdate,
                shiftId,
            }),
        ),
    };

    protected async fetchById(shiftId: UUID): Promise<Shift | undefined> {
        return await this.shiftsApi.shiftsReadShift({
            shiftId,
        });
    }

    protected extractId = prop("id");

    protected async fetchByQuery(
        query: ShiftsQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<Shift>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.shiftsApi.shiftsListShifts({
            ...query,
            page,
        });
        return { entities };
    }

    public fetchMetadata(_: unknown): Promise<PageInfo> {
        return this.shiftsApi.shiftsListShiftsMetadata({ pageSize: defaultPageSize });
    }

    public async update(shiftId: UUID, shiftUpdate: ShiftUpdate): Promise<Shift> {
        const shift = await this.wrapApiCall(
            this.shiftsApi.shiftsUpdateShift({ shiftUpdate, shiftId }),
        );

        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(shift);
        });

        return shift;
    }

    public async create(shiftCreate: ShiftCreate): Promise<Shift> {
        const shift = await this.wrapApiCall(this.shiftsApi.shiftsCreateShift({ shiftCreate }));

        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(shift);
        });

        return shift;
    }

    public async delete(...shiftIds: UUID[]): Promise<void> {
        await this.wrapApiCall(
            Promise.all(shiftIds.map((shiftId) => this.shiftsApi.shiftsDeleteShift({ shiftId }))),
        );

        await this.waitForIdle();
        // This reload is necessary to reload all queries that want to display "all" entities.
        this.reloadQuery({ pageSize: defaultPageSize });
    }
}
