/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FleetRole } from './FleetRole';
import {
    FleetRoleFromJSON,
    FleetRoleFromJSONTyped,
    FleetRoleToJSON,
} from './FleetRole';
import type { ManagementRole } from './ManagementRole';
import {
    ManagementRoleFromJSON,
    ManagementRoleFromJSONTyped,
    ManagementRoleToJSON,
} from './ManagementRole';

/**
 * Remember to update [`ExportUser`](crate::schemas::background_jobs_export_user::ExportUser) when adding new fields to this struct.  This is required to ensure GDPR compliance!
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    employeeId: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string | null;
    /**
     * 
     * @type {ManagementRole}
     * @memberof User
     */
    managementRole?: ManagementRole | null;
    /**
     * 
     * @type {FleetRole}
     * @memberof User
     */
    fleetRole?: FleetRole | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    departmentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    shiftId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    siteId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    licenseExpiryDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    expiryLogout: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    imageId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    nfcToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    nfcTokenDescription?: string | null;
    /**
     * This is calculated from the fleet authentication events table
     * @type {number}
     * @memberof User
     */
    totalLoginSeconds: number;
    /**
     * This is calculated from the vehicle movements table
     * @type {number}
     * @memberof User
     */
    totalDriveSeconds: number;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "employeeId" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "expiryLogout" in value;
    isInstance = isInstance && "totalLoginSeconds" in value;
    isInstance = isInstance && "totalDriveSeconds" in value;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'employeeId': json['employeeId'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'managementRole': !exists(json, 'managementRole') ? undefined : ManagementRoleFromJSON(json['managementRole']),
        'fleetRole': !exists(json, 'fleetRole') ? undefined : FleetRoleFromJSON(json['fleetRole']),
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'shiftId': !exists(json, 'shiftId') ? undefined : json['shiftId'],
        'siteId': !exists(json, 'siteId') ? undefined : json['siteId'],
        'licenseExpiryDate': !exists(json, 'licenseExpiryDate') ? undefined : (json['licenseExpiryDate'] === null ? null : new Date(json['licenseExpiryDate'])),
        'expiryLogout': json['expiryLogout'],
        'imageId': !exists(json, 'imageId') ? undefined : json['imageId'],
        'nfcToken': !exists(json, 'nfcToken') ? undefined : json['nfcToken'],
        'nfcTokenDescription': !exists(json, 'nfcTokenDescription') ? undefined : json['nfcTokenDescription'],
        'totalLoginSeconds': json['totalLoginSeconds'],
        'totalDriveSeconds': json['totalDriveSeconds'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'employeeId': value.employeeId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'managementRole': ManagementRoleToJSON(value.managementRole),
        'fleetRole': FleetRoleToJSON(value.fleetRole),
        'jobTitle': value.jobTitle,
        'departmentId': value.departmentId,
        'shiftId': value.shiftId,
        'siteId': value.siteId,
        'licenseExpiryDate': value.licenseExpiryDate === undefined ? undefined : (value.licenseExpiryDate === null ? null : value.licenseExpiryDate.toISOString()),
        'expiryLogout': value.expiryLogout,
        'imageId': value.imageId,
        'nfcToken': value.nfcToken,
        'nfcTokenDescription': value.nfcTokenDescription,
        'totalLoginSeconds': value.totalLoginSeconds,
        'totalDriveSeconds': value.totalDriveSeconds,
    };
}

