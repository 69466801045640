/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MultiUserGroupVehicleGroupAssignmentCreate
 */
export interface MultiUserGroupVehicleGroupAssignmentCreate {
    /**
     * 
     * @type {Array<string>}
     * @memberof MultiUserGroupVehicleGroupAssignmentCreate
     */
    userGroupIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MultiUserGroupVehicleGroupAssignmentCreate
     */
    vehicleGroupIds: Array<string>;
}

/**
 * Check if a given object implements the MultiUserGroupVehicleGroupAssignmentCreate interface.
 */
export function instanceOfMultiUserGroupVehicleGroupAssignmentCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userGroupIds" in value;
    isInstance = isInstance && "vehicleGroupIds" in value;

    return isInstance;
}

export function MultiUserGroupVehicleGroupAssignmentCreateFromJSON(json: any): MultiUserGroupVehicleGroupAssignmentCreate {
    return MultiUserGroupVehicleGroupAssignmentCreateFromJSONTyped(json, false);
}

export function MultiUserGroupVehicleGroupAssignmentCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultiUserGroupVehicleGroupAssignmentCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userGroupIds': json['userGroupIds'],
        'vehicleGroupIds': json['vehicleGroupIds'],
    };
}

export function MultiUserGroupVehicleGroupAssignmentCreateToJSON(value?: MultiUserGroupVehicleGroupAssignmentCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userGroupIds': value.userGroupIds,
        'vehicleGroupIds': value.vehicleGroupIds,
    };
}

