import * as React from "react";
import { Checkbox, IColumn } from "@fluentui/react";
import { ColumnVisibilityMap } from "../pages/page-column-visibility";
import { ColumnVisibilityRow } from "./molecules/list-column-visibility";

type ColumnVisibilityCheckbox = {
    columnVisibilityMap: ColumnVisibilityMap;
    columnVisibilityUpdate: ColumnVisibilityMap;
    onVisibilityChanged: (page: string, column: string, visibility: boolean) => void;
    item: ColumnVisibilityRow;
    column?: IColumn;
    allColumn?: boolean;
};

export function ColumnVisibilityCheckbox(props: ColumnVisibilityCheckbox): JSX.Element {
    if (!props.column) {
        return <></>;
    }

    // Determine whether the checkbox should be checked and/or disabled.
    let checked = true;
    let disabled = false;
    let indeterminate = false;

    if (props.allColumn) {
        let someColumnsChecked = false;
        for (const page of Object.keys(props.columnVisibilityMap)) {
            // If the column doesn't exist on this page, ignore it.
            if (!(props.item.column in props.columnVisibilityMap[page])) {
                continue;
            }

            // The update map takes precedence over the current visibility state.
            if (
                page in props.columnVisibilityUpdate &&
                props.item.column in props.columnVisibilityUpdate[page]
            ) {
                if (props.columnVisibilityUpdate[page][props.item.column]) {
                    someColumnsChecked = true;
                } else {
                    checked = false;
                }
            } else {
                if (props.columnVisibilityMap[page][props.item.column]) {
                    someColumnsChecked = true;
                } else {
                    checked = false;
                }
            }
        }
        indeterminate = !checked && someColumnsChecked;
    } else {
        if (
            props.column.key in props.columnVisibilityUpdate &&
            props.item.column in props.columnVisibilityUpdate[props.column.key]
        ) {
            if (!props.columnVisibilityUpdate[props.column.key][props.item.column]) {
                checked = false;
            }
        } else {
            if (!props.columnVisibilityMap[props.column.key][props.item.column]) {
                checked = false;
            }
        }

        disabled = !(props.item.column in props.columnVisibilityMap[props.column.key]);
    }

    return (
        <Checkbox
            checked={checked}
            disabled={disabled}
            indeterminate={indeterminate}
            onChange={(_ev, checked?: boolean) => {
                if (checked === undefined) {
                    return;
                }
                props.onVisibilityChanged(props.column!.key, props.item.column, checked);
            }}
        />
    );
}
