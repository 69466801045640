/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ShockProfileSortKey {
    LABEL = 'label',
    CREATED_AT = 'createdAt',
    LIGHT = 'light',
    MEDIUM = 'medium',
    SEVERE = 'severe',
    DURATION = 'duration'
}


export function ShockProfileSortKeyFromJSON(json: any): ShockProfileSortKey {
    return ShockProfileSortKeyFromJSONTyped(json, false);
}

export function ShockProfileSortKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShockProfileSortKey {
    return json as ShockProfileSortKey;
}

export function ShockProfileSortKeyToJSON(value?: ShockProfileSortKey | null): any {
    return value as any;
}

