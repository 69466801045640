import * as React from "react";
import { Stack, DefaultButton, IStackTokens, PrimaryButton } from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { external, inject } from "tsdi";
import { UUID } from "../../utils/uuid";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { FormUpdateProps } from "../../utils/form-update-props";
import { FormCreateProps } from "../../utils/form-create-props";
import { ListStates } from "../../utils/list-states";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { ModalConfirmation } from "../atoms/modal-confirmation";
import { ElofleetListProps } from "../atoms/elofleet-list";
import { ElofleetDialog } from "../atoms/elofleet-dialog";

export interface ListCommandButtonsProps<TRepository extends ElofleetRepository<ApiResource>> {
    readonly onDelete?: (ids: UUID[]) => void | Promise<void>;
    readonly onUpdate?: (entity: TRepository["apiResource"]["entity"]) => void | Promise<void>;
    readonly onCreate?: (entity: TRepository["apiResource"]["entity"]) => void | Promise<void>;
    readonly createForm?: React.ComponentType<
        FormCreateProps<TRepository["apiResource"]["entity"]>
    >;
    readonly updateForm?: React.ComponentType<
        FormUpdateProps<TRepository["apiResource"]["entity"]>
    >;
    readonly canDelete?: boolean;
    readonly createButtonText?: string;
    readonly createDialogTitle?: string;
    readonly updateDialogTitle?: string;
    readonly updateDialogIcon?: string;
    readonly createDialogIcon?: string;
    readonly listProps?: Omit<ElofleetListProps, "items" | "columns">;
    readonly listStateId?: UUID;
    // TODO: Check how to prevent this `any`.
    //       The error message is:
    //       ```
    //      Types of parameters 'sortKey' and 'sortKey' are incompatible.
    //      Type 'string' is not assignable to type 'UserSortKey'.
    //      ```
    //      I'm not sure how to solve this with generics, as typescript doesn't seem to like `string`
    //      even though `ListStates` enforces `TSortKey extends string`.
    //      An alternative would be to add `TSortKey extends string` to the traitbounds of this interface,
    //      but this would force all usages of EditableLIstProps to specify this bound, which is dirty.
    readonly listState?: ListStates<TRepository>;
    //
    readonly dialogMaxWidth?: string;

    // When the list command buttons are rendered in the context of a panel
    // view we're only dealing with a single list item, and so we short-circuit
    // all logic that would normally deal with the list state and just act
    // based on the passed in `itemId`.
    readonly singleItemMode?: boolean;
    readonly itemId?: UUID;
}

@external
@observer
export class ListCommandButtons<TRepository extends ElofleetRepository> extends React.Component<
    ListCommandButtonsProps<TRepository>
> {
    @inject protected readonly i18n!: I18nProvider;

    @observable private createDialogVisible = false;
    @observable private updateDialogVisible = false;
    @observable private deletionConfirmationModalVisible = false;

    constructor(props: ListCommandButtonsProps<TRepository>) {
        super(props);
        makeObservable(this);
    }

    /** Checks whether there're currently selected elements in the list */
    @computed private get areItemsSelected(): boolean {
        if (this.props.singleItemMode) {
            return this.props.itemId !== undefined;
        }

        if (this.props.listState === undefined || this.props.listStateId === undefined) {
            return false;
        }

        if (this.props.listState.isInitialized(this.props.listStateId)) {
            return this.props.listState.getSelectionState(this.props.listStateId).keys.length !== 0;
        }

        return false;
    }

    /** Checks whether there is currently only a single selected element */
    @computed private get isSingleItemSelected(): boolean {
        if (this.props.singleItemMode) {
            return this.props.itemId !== undefined;
        }

        if (this.props.listState === undefined || this.props.listStateId === undefined) {
            return false;
        }

        if (this.props.listState.isInitialized(this.props.listStateId)) {
            return this.props.listState.getSelectionState(this.props.listStateId).keys.length === 1;
        }

        return false;
    }

    /** Return the first selected element if it exists */
    @computed private get selectedItem(): UUID | undefined {
        if (this.props.singleItemMode) {
            return this.props.itemId;
        }

        if (this.props.listState === undefined || this.props.listStateId === undefined) {
            return;
        }

        if (this.props.listState.isInitialized(this.props.listStateId)) {
            const keys = this.props.listState.getSelectionState(this.props.listStateId).keys;
            if (keys.length > 0) {
                return keys[0];
            }
        }
    }

    /** Get the id's of all currently selected elements from the store and pass them to the callback */
    @action.bound private passKeysToDeleteCallback(): void {
        if (this.props.singleItemMode) {
            if (this.props.onDelete && this.props.itemId) {
                this.props.onDelete([this.props.itemId]);
            }
            this.closeDeletionConfirmationModal();
            return;
        }

        if (this.props.listState === undefined || this.props.listStateId === undefined) {
            return;
        }

        if (this.props.onDelete && this.props.listState.isInitialized(this.props.listStateId)) {
            const selectionState = this.props.listState.getSelectionState(this.props.listStateId);
            this.props.onDelete(selectionState.keys);
        }
        this.closeDeletionConfirmationModal();
    }

    @action.bound private openCreateDialog(): void {
        this.createDialogVisible = true;
    }

    @action.bound private closeCreateDialog(): void {
        this.createDialogVisible = false;
    }

    @action.bound private openUpdateDialog(): void {
        this.updateDialogVisible = true;
    }

    @action.bound private closeUpdateDialog(): void {
        this.updateDialogVisible = false;
    }

    @action.bound private closeUpdateDialogAndCallUpdateCallback(
        entity: TRepository["apiResource"]["entity"],
    ): void {
        this.updateDialogVisible = false;
        this.props.onUpdate?.(entity);
    }

    @action.bound private closeCreateDialogAndCallCreateCallback(
        entity: TRepository["apiResource"]["entity"],
    ): void {
        this.createDialogVisible = false;
        if (this.props.onCreate) {
            this.props.onCreate(entity);
        }
    }

    @action.bound private openDeletionConfirmationModal(): void {
        this.deletionConfirmationModalVisible = true;
    }

    @action.bound private closeDeletionConfirmationModal(): void {
        this.deletionConfirmationModalVisible = false;
    }

    @computed private get commandBarItems(): JSX.Element[] {
        const items: JSX.Element[] = [];
        if (this.props.createForm) {
            items.push(
                <PrimaryButton
                    text={
                        this.props.createButtonText ??
                        this.i18n.t("component.listCommandButton.create.text")
                    }
                    onClick={this.openCreateDialog}
                    key="createButton"
                />,
            );
        }
        if (this.props.updateForm) {
            items.push(
                <DefaultButton
                    text={this.i18n.t("component.listCommandButton.updateDialog.title")}
                    onClick={this.openUpdateDialog}
                    disabled={!this.isSingleItemSelected}
                    key="updateDialogButton"
                />,
            );
        }
        if (this.props.canDelete) {
            items.push(
                <DefaultButton
                    text={this.i18n.t("component.listCommandButton.deleteSelection.text")}
                    onClick={this.openDeletionConfirmationModal}
                    disabled={!this.areItemsSelected}
                    key="deleteButton"
                />,
            );
        }

        return items;
    }

    public render(): JSX.Element {
        const CreateForm = this.props.createForm;
        const UpdateForm = this.props.updateForm;

        const buttonStyling: IStackTokens = { childrenGap: 20 };
        return (
            <>
                <Stack
                    // Don't give the children any margin/gaps.
                    // Buttons are stacks anyway and take care of proper gaps by themselves.
                    //
                    // Work with childrenGap in here will break the styling, if one works with forms/
                    // modals, as they'll create a wrapper element that'll shift the buttons once they
                    // become visible!
                    tokens={{
                        childrenGap: "0px",
                    }}
                    style={{
                        marginLeft: "0px",
                    }}
                >
                    <Stack horizontal tokens={buttonStyling}>
                        {this.commandBarItems}
                    </Stack>
                </Stack>
                {/* Include dialogs outside of the stack to prevent the buttons from moving
                around when modals open */}
                {CreateForm && (
                    <ElofleetDialog
                        isOpen={this.createDialogVisible}
                        maxWidth={this.props.dialogMaxWidth}
                        icon={this.props.updateDialogIcon}
                        title={
                            this.props.createDialogTitle ??
                            this.i18n.t("component.listCommandButton.createDialog.title")
                        }
                    >
                        {
                            <CreateForm
                                asDialogContent
                                onCreate={this.closeCreateDialogAndCallCreateCallback}
                                onDialogCancel={this.closeCreateDialog}
                            />
                        }
                    </ElofleetDialog>
                )}
                {UpdateForm && this.selectedItem && (
                    <ElofleetDialog
                        isOpen={this.updateDialogVisible}
                        maxWidth={this.props.dialogMaxWidth}
                        icon={this.props.updateDialogIcon}
                        title={
                            this.props.updateDialogTitle ??
                            this.i18n.t("component.listCommandButton.updateDialog.title")
                        }
                    >
                        {
                            <UpdateForm
                                asDialogContent
                                id={this.selectedItem}
                                onUpdate={this.closeUpdateDialogAndCallUpdateCallback}
                                onDialogCancel={this.closeUpdateDialog}
                            />
                        }
                    </ElofleetDialog>
                )}
                {this.props.canDelete && (
                    <ModalConfirmation
                        isOpen={this.deletionConfirmationModalVisible}
                        title={this.i18n.t("modalConfirmDeletion.title")}
                        text={this.i18n.t("modalConfirmDeletion.description")}
                        onConfirm={this.passKeysToDeleteCallback}
                        onCancel={this.closeDeletionConfirmationModal}
                    />
                )}
            </>
        );
    }
}
