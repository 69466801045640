/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PageInfo,
  PreOpsQuestion,
  PreOpsQuestionCreate,
  PreOpsQuestionSortKey,
  PreOpsQuestionUpdate,
  SortDirection,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    PreOpsQuestionFromJSON,
    PreOpsQuestionToJSON,
    PreOpsQuestionCreateFromJSON,
    PreOpsQuestionCreateToJSON,
    PreOpsQuestionSortKeyFromJSON,
    PreOpsQuestionSortKeyToJSON,
    PreOpsQuestionUpdateFromJSON,
    PreOpsQuestionUpdateToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface PreOpsQuestionsCreatePreOpsQuestionRequest {
    preOpsQuestionCreate: PreOpsQuestionCreate;
}

export interface PreOpsQuestionsDeletePreOpsQuestionRequest {
    questionId: string;
}

export interface PreOpsQuestionsListPreOpsQuestionsRequest {
    pageSize: number;
    search?: string | null;
    checklistId?: string | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: PreOpsQuestionSortKey;
}

export interface PreOpsQuestionsListPreOpsQuestionsMetadataRequest {
    pageSize: number;
    search?: string | null;
    checklistId?: string | null;
}

export interface PreOpsQuestionsReadPreOpsQuestionRequest {
    questionId: string;
}

export interface PreOpsQuestionsUpdatePreOpsQuestionRequest {
    questionId: string;
    preOpsQuestionUpdate: PreOpsQuestionUpdate;
}

export interface PreOpsQuestionsValidateCreatePreOpsQuestionRequest {
    preOpsQuestionCreate: PreOpsQuestionCreate;
}

export interface PreOpsQuestionsValidateUpdatePreOpsQuestionRequest {
    questionId: string;
    preOpsQuestionUpdate: PreOpsQuestionUpdate;
}

/**
 * PreOpsQuestionsApi - interface
 * 
 * @export
 * @interface PreOpsQuestionsApiInterface
 */
export interface PreOpsQuestionsApiInterface {
    /**
     * Create a new pre-ops checklist.
     * @summary Create a pre-ops checklist
     * @param {PreOpsQuestionCreate} preOpsQuestionCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsQuestionsApiInterface
     */
    preOpsQuestionsCreatePreOpsQuestionRaw(requestParameters: PreOpsQuestionsCreatePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreOpsQuestion>>;

    /**
     * Create a new pre-ops checklist.
     * Create a pre-ops checklist
     */
    preOpsQuestionsCreatePreOpsQuestion(requestParameters: PreOpsQuestionsCreatePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreOpsQuestion>;

    /**
     * Delete the pre-ops checklists with the given ID.
     * @summary Delete a pre-ops checklists
     * @param {string} questionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsQuestionsApiInterface
     */
    preOpsQuestionsDeletePreOpsQuestionRaw(requestParameters: PreOpsQuestionsDeletePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the pre-ops checklists with the given ID.
     * Delete a pre-ops checklists
     */
    preOpsQuestionsDeletePreOpsQuestion(requestParameters: PreOpsQuestionsDeletePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Lists all the checklists present.  If `search` is specified, return values similar to the `question` field. In that case, `sortKey` will not have any effect. I.e. results will be sorted by similarity to the search term instead.
     * @summary List pre-ops checklists
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {string} [checklistId] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {PreOpsQuestionSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsQuestionsApiInterface
     */
    preOpsQuestionsListPreOpsQuestionsRaw(requestParameters: PreOpsQuestionsListPreOpsQuestionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PreOpsQuestion>>>;

    /**
     * Lists all the checklists present.  If `search` is specified, return values similar to the `question` field. In that case, `sortKey` will not have any effect. I.e. results will be sorted by similarity to the search term instead.
     * List pre-ops checklists
     */
    preOpsQuestionsListPreOpsQuestions(requestParameters: PreOpsQuestionsListPreOpsQuestionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PreOpsQuestion>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary Pre-ops checklist listing metadata
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {string} [checklistId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsQuestionsApiInterface
     */
    preOpsQuestionsListPreOpsQuestionsMetadataRaw(requestParameters: PreOpsQuestionsListPreOpsQuestionsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * Pre-ops checklist listing metadata
     */
    preOpsQuestionsListPreOpsQuestionsMetadata(requestParameters: PreOpsQuestionsListPreOpsQuestionsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the question specified by the given ID.
     * @summary Get a single question
     * @param {string} questionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsQuestionsApiInterface
     */
    preOpsQuestionsReadPreOpsQuestionRaw(requestParameters: PreOpsQuestionsReadPreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreOpsQuestion>>;

    /**
     * Returns the question specified by the given ID.
     * Get a single question
     */
    preOpsQuestionsReadPreOpsQuestion(requestParameters: PreOpsQuestionsReadPreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreOpsQuestion>;

    /**
     * Update the checklist specified by the given ID.
     * @summary Update a pre-ops checklist
     * @param {string} questionId 
     * @param {PreOpsQuestionUpdate} preOpsQuestionUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsQuestionsApiInterface
     */
    preOpsQuestionsUpdatePreOpsQuestionRaw(requestParameters: PreOpsQuestionsUpdatePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreOpsQuestion>>;

    /**
     * Update the checklist specified by the given ID.
     * Update a pre-ops checklist
     */
    preOpsQuestionsUpdatePreOpsQuestion(requestParameters: PreOpsQuestionsUpdatePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreOpsQuestion>;

    /**
     * Validate data for a new checklist without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a checklist
     * @param {PreOpsQuestionCreate} preOpsQuestionCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsQuestionsApiInterface
     */
    preOpsQuestionsValidateCreatePreOpsQuestionRaw(requestParameters: PreOpsQuestionsValidateCreatePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new checklist without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a checklist
     */
    preOpsQuestionsValidateCreatePreOpsQuestion(requestParameters: PreOpsQuestionsValidateCreatePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate data for updating the given checklist, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a pre-ops checklist update
     * @param {string} questionId 
     * @param {PreOpsQuestionUpdate} preOpsQuestionUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsQuestionsApiInterface
     */
    preOpsQuestionsValidateUpdatePreOpsQuestionRaw(requestParameters: PreOpsQuestionsValidateUpdatePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for updating the given checklist, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a pre-ops checklist update
     */
    preOpsQuestionsValidateUpdatePreOpsQuestion(requestParameters: PreOpsQuestionsValidateUpdatePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class PreOpsQuestionsApi extends runtime.BaseAPI implements PreOpsQuestionsApiInterface {

    /**
     * Create a new pre-ops checklist.
     * Create a pre-ops checklist
     */
    async preOpsQuestionsCreatePreOpsQuestionRaw(requestParameters: PreOpsQuestionsCreatePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreOpsQuestion>> {
        if (requestParameters.preOpsQuestionCreate === null || requestParameters.preOpsQuestionCreate === undefined) {
            throw new runtime.RequiredError('preOpsQuestionCreate','Required parameter requestParameters.preOpsQuestionCreate was null or undefined when calling preOpsQuestionsCreatePreOpsQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_questions/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PreOpsQuestionCreateToJSON(requestParameters.preOpsQuestionCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreOpsQuestionFromJSON(jsonValue));
    }

    /**
     * Create a new pre-ops checklist.
     * Create a pre-ops checklist
     */
    async preOpsQuestionsCreatePreOpsQuestion(requestParameters: PreOpsQuestionsCreatePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreOpsQuestion> {
        const response = await this.preOpsQuestionsCreatePreOpsQuestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the pre-ops checklists with the given ID.
     * Delete a pre-ops checklists
     */
    async preOpsQuestionsDeletePreOpsQuestionRaw(requestParameters: PreOpsQuestionsDeletePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.questionId === null || requestParameters.questionId === undefined) {
            throw new runtime.RequiredError('questionId','Required parameter requestParameters.questionId was null or undefined when calling preOpsQuestionsDeletePreOpsQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_questions/{question_id}`.replace(`{${"question_id"}}`, encodeURIComponent(String(requestParameters.questionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the pre-ops checklists with the given ID.
     * Delete a pre-ops checklists
     */
    async preOpsQuestionsDeletePreOpsQuestion(requestParameters: PreOpsQuestionsDeletePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.preOpsQuestionsDeletePreOpsQuestionRaw(requestParameters, initOverrides);
    }

    /**
     * Lists all the checklists present.  If `search` is specified, return values similar to the `question` field. In that case, `sortKey` will not have any effect. I.e. results will be sorted by similarity to the search term instead.
     * List pre-ops checklists
     */
    async preOpsQuestionsListPreOpsQuestionsRaw(requestParameters: PreOpsQuestionsListPreOpsQuestionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PreOpsQuestion>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling preOpsQuestionsListPreOpsQuestions.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.checklistId !== undefined) {
            queryParameters['checklist_id'] = requestParameters.checklistId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_questions/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PreOpsQuestionFromJSON));
    }

    /**
     * Lists all the checklists present.  If `search` is specified, return values similar to the `question` field. In that case, `sortKey` will not have any effect. I.e. results will be sorted by similarity to the search term instead.
     * List pre-ops checklists
     */
    async preOpsQuestionsListPreOpsQuestions(requestParameters: PreOpsQuestionsListPreOpsQuestionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PreOpsQuestion>> {
        const response = await this.preOpsQuestionsListPreOpsQuestionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * Pre-ops checklist listing metadata
     */
    async preOpsQuestionsListPreOpsQuestionsMetadataRaw(requestParameters: PreOpsQuestionsListPreOpsQuestionsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling preOpsQuestionsListPreOpsQuestionsMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.checklistId !== undefined) {
            queryParameters['checklist_id'] = requestParameters.checklistId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_questions/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * Pre-ops checklist listing metadata
     */
    async preOpsQuestionsListPreOpsQuestionsMetadata(requestParameters: PreOpsQuestionsListPreOpsQuestionsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.preOpsQuestionsListPreOpsQuestionsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the question specified by the given ID.
     * Get a single question
     */
    async preOpsQuestionsReadPreOpsQuestionRaw(requestParameters: PreOpsQuestionsReadPreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreOpsQuestion>> {
        if (requestParameters.questionId === null || requestParameters.questionId === undefined) {
            throw new runtime.RequiredError('questionId','Required parameter requestParameters.questionId was null or undefined when calling preOpsQuestionsReadPreOpsQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_questions/{question_id}`.replace(`{${"question_id"}}`, encodeURIComponent(String(requestParameters.questionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreOpsQuestionFromJSON(jsonValue));
    }

    /**
     * Returns the question specified by the given ID.
     * Get a single question
     */
    async preOpsQuestionsReadPreOpsQuestion(requestParameters: PreOpsQuestionsReadPreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreOpsQuestion> {
        const response = await this.preOpsQuestionsReadPreOpsQuestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the checklist specified by the given ID.
     * Update a pre-ops checklist
     */
    async preOpsQuestionsUpdatePreOpsQuestionRaw(requestParameters: PreOpsQuestionsUpdatePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreOpsQuestion>> {
        if (requestParameters.questionId === null || requestParameters.questionId === undefined) {
            throw new runtime.RequiredError('questionId','Required parameter requestParameters.questionId was null or undefined when calling preOpsQuestionsUpdatePreOpsQuestion.');
        }

        if (requestParameters.preOpsQuestionUpdate === null || requestParameters.preOpsQuestionUpdate === undefined) {
            throw new runtime.RequiredError('preOpsQuestionUpdate','Required parameter requestParameters.preOpsQuestionUpdate was null or undefined when calling preOpsQuestionsUpdatePreOpsQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_questions/{question_id}`.replace(`{${"question_id"}}`, encodeURIComponent(String(requestParameters.questionId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PreOpsQuestionUpdateToJSON(requestParameters.preOpsQuestionUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreOpsQuestionFromJSON(jsonValue));
    }

    /**
     * Update the checklist specified by the given ID.
     * Update a pre-ops checklist
     */
    async preOpsQuestionsUpdatePreOpsQuestion(requestParameters: PreOpsQuestionsUpdatePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreOpsQuestion> {
        const response = await this.preOpsQuestionsUpdatePreOpsQuestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new checklist without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a checklist
     */
    async preOpsQuestionsValidateCreatePreOpsQuestionRaw(requestParameters: PreOpsQuestionsValidateCreatePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.preOpsQuestionCreate === null || requestParameters.preOpsQuestionCreate === undefined) {
            throw new runtime.RequiredError('preOpsQuestionCreate','Required parameter requestParameters.preOpsQuestionCreate was null or undefined when calling preOpsQuestionsValidateCreatePreOpsQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_questions/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PreOpsQuestionCreateToJSON(requestParameters.preOpsQuestionCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new checklist without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a checklist
     */
    async preOpsQuestionsValidateCreatePreOpsQuestion(requestParameters: PreOpsQuestionsValidateCreatePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.preOpsQuestionsValidateCreatePreOpsQuestionRaw(requestParameters, initOverrides);
    }

    /**
     * Validate data for updating the given checklist, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a pre-ops checklist update
     */
    async preOpsQuestionsValidateUpdatePreOpsQuestionRaw(requestParameters: PreOpsQuestionsValidateUpdatePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.questionId === null || requestParameters.questionId === undefined) {
            throw new runtime.RequiredError('questionId','Required parameter requestParameters.questionId was null or undefined when calling preOpsQuestionsValidateUpdatePreOpsQuestion.');
        }

        if (requestParameters.preOpsQuestionUpdate === null || requestParameters.preOpsQuestionUpdate === undefined) {
            throw new runtime.RequiredError('preOpsQuestionUpdate','Required parameter requestParameters.preOpsQuestionUpdate was null or undefined when calling preOpsQuestionsValidateUpdatePreOpsQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_questions/{question_id}/validate`.replace(`{${"question_id"}}`, encodeURIComponent(String(requestParameters.questionId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PreOpsQuestionUpdateToJSON(requestParameters.preOpsQuestionUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for updating the given checklist, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a pre-ops checklist update
     */
    async preOpsQuestionsValidateUpdatePreOpsQuestion(requestParameters: PreOpsQuestionsValidateUpdatePreOpsQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.preOpsQuestionsValidateUpdatePreOpsQuestionRaw(requestParameters, initOverrides);
    }

}
