/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RoleGroups {
    FLEET_USERS = 'fleetUsers',
    MANAGEMENT_USERS = 'managementUsers'
}


export function RoleGroupsFromJSON(json: any): RoleGroups {
    return RoleGroupsFromJSONTyped(json, false);
}

export function RoleGroupsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleGroups {
    return json as RoleGroups;
}

export function RoleGroupsToJSON(value?: RoleGroups | null): any {
    return value as any;
}

