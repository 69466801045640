/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * This is a single version for one of our artifacts. Both number or hash field should be unique for any given version, but we include them both for descriptive reasons.
 * @export
 * @interface LegacyVersion
 */
export interface LegacyVersion {
    /**
     * Number of commits that have changed this artifact
     * @type {number}
     * @memberof LegacyVersion
     */
    number: number;
    /**
     * Commit hash that this artifact was built from
     * @type {string}
     * @memberof LegacyVersion
     */
    hash: string;
}

/**
 * Check if a given object implements the LegacyVersion interface.
 */
export function instanceOfLegacyVersion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "hash" in value;

    return isInstance;
}

export function LegacyVersionFromJSON(json: any): LegacyVersion {
    return LegacyVersionFromJSONTyped(json, false);
}

export function LegacyVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegacyVersion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'hash': json['hash'],
    };
}

export function LegacyVersionToJSON(value?: LegacyVersion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'hash': value.hash,
    };
}

