import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositorySettings } from "../../domain/repositories/repository-settings";
import { NotificationEmailCreate, Setting, SettingUpdate } from "../../api";
import { FormUpdateProps } from "../../utils/form-update-props";
import { createUuid, UUID } from "../../utils/uuid";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { DefaultButton, Stack } from "@fluentui/react";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";
import { NotificationEmails } from "../atoms/notification-emails";

export interface FormUpdateSettingVehicleProps extends Omit<FormUpdateProps<Setting>, "id"> {}

@observer
@external
export class FormUpdateSettingVehicle extends React.Component<FormUpdateSettingVehicleProps> {
    @inject private readonly repositorySetting!: RepositorySettings;
    @inject private readonly i18n!: I18nProvider;

    private validationId = createUuid();

    @observable private settingId?: UUID;
    @observable private emailsCreate: NotificationEmailCreate[] = [];

    constructor(props: FormUpdateSettingVehicleProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected async initialize(): Promise<void> {
        // We can always assume that the API will return exactly one setting object
        const [setting] = await this.repositorySetting.byQueryAsync();
        runInAction(() => {
            this.settingId = setting.id;
        });
        this.repositorySetting.validation.update.initializeModel(
            this.validationId,
            this.settingUpdate,
            setting.id,
        );
    }

    @action.bound private onChangeEmails(): void {
        this.triggerValidation();
    }

    private triggerValidation(): void {
        this.repositorySetting.validation.update.updateModel(this.validationId, this.settingUpdate);
    }

    public componentWillUnmount(): void {
        if (this.setting === undefined) {
            return;
        }
        this.repositorySetting.discardMutableCopy(FormUpdateSettingVehicle.name, this.setting.id);
    }

    @computed private get setting(): Setting | undefined {
        if (this.settingId === undefined) {
            return undefined;
        }
        return this.repositorySetting.mutableCopyById(
            FormUpdateSettingVehicle.name,
            this.settingId,
        );
    }

    @computed private get settingUpdate(): SettingUpdate {
        return {
            maintenanceNotificationEmails: this.setting?.maintenanceNotificationEmails,
            maintenanceNotificationEmailsCreate:
                this.emailsCreate.length == 0 ? null : this.emailsCreate,
        };
    }

    @action.bound
    private async updateSetting(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        if (this.setting === undefined) {
            throw new Error("submit button should be disabled until setting is loaded");
        }
        evt.preventDefault();
        const setting = await this.repositorySetting.update(this.setting.id, this.settingUpdate);
        this.props.onUpdate(setting);
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formUpdateSettingVehicle.submit.text")}
                validation={this.repositorySetting.validation.update}
                validationId={this.validationId}
            />
        );

        if (this.setting != undefined) {
            return (
                <form onSubmit={this.updateSetting}>
                    <Stack
                        tokens={{
                            childrenGap: `${sizes.gutter}`,
                            padding: `0px ${sizes.xl}`,
                        }}
                        styles={{
                            root: {
                                maxHeight: sizes.halfHeight,
                                overflow: "auto",
                            },
                        }}
                    >
                        <NotificationEmails
                            emailsCreate={this.emailsCreate}
                            emailsUpdate={this.setting.maintenanceNotificationEmails}
                            onChange={this.onChangeEmails}
                            translationPrefixKey={"formUpdateSettingVehicle"}
                            validationId={this.validationId}
                            validationKey={"maintenanceNotificationEmails"}
                            validationKeyCreate={"maintenanceNotificationEmailsCreate"}
                        ></NotificationEmails>
                    </Stack>
                    {this.props.asDialogContent ? (
                        <ElofleetDialogFooter>
                            <DefaultButton
                                label={this.i18n.t("formUpdateSettingVehicle.cancel.label")}
                                text={this.i18n.t("formUpdateSettingVehicle.cancel.text")}
                                onClick={this.props.onDialogCancel}
                            />
                            {primaryButton}
                        </ElofleetDialogFooter>
                    ) : (
                        <Stack horizontal horizontalAlign="end">
                            {primaryButton}
                        </Stack>
                    )}
                </form>
            );
        } else return <></>;
    }
}
