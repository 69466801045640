/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VehicleMovementType } from './VehicleMovementType';
import {
    VehicleMovementTypeFromJSON,
    VehicleMovementTypeFromJSONTyped,
    VehicleMovementTypeToJSON,
} from './VehicleMovementType';

/**
 * See [VehicleMovement] for information on the individual fields.
 * @export
 * @interface VehicleMovementCreate
 */
export interface VehicleMovementCreate {
    /**
     * 
     * @type {string}
     * @memberof VehicleMovementCreate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleMovementCreate
     */
    vehicleId: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleMovementCreate
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleMovementCreate
     */
    periodId: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleMovementCreate
     */
    time: Date;
    /**
     * 
     * @type {VehicleMovementType}
     * @memberof VehicleMovementCreate
     */
    movementType: VehicleMovementType;
}

/**
 * Check if a given object implements the VehicleMovementCreate interface.
 */
export function instanceOfVehicleMovementCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "vehicleId" in value;
    isInstance = isInstance && "periodId" in value;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "movementType" in value;

    return isInstance;
}

export function VehicleMovementCreateFromJSON(json: any): VehicleMovementCreate {
    return VehicleMovementCreateFromJSONTyped(json, false);
}

export function VehicleMovementCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleMovementCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'vehicleId': json['vehicleId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'periodId': json['periodId'],
        'time': (new Date(json['time'])),
        'movementType': VehicleMovementTypeFromJSON(json['movementType']),
    };
}

export function VehicleMovementCreateToJSON(value?: VehicleMovementCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vehicleId': value.vehicleId,
        'userId': value.userId,
        'periodId': value.periodId,
        'time': (value.time.toISOString()),
        'movementType': VehicleMovementTypeToJSON(value.movementType),
    };
}

