/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WindowsTimezone {
    DATELINE_STANDARD_TIME = 'datelineStandardTime',
    UTC_MINUS11 = 'utcMinus11',
    ALEUTIAN_STANDARD_TIME = 'aleutianStandardTime',
    HAWAIIAN_STANDARD_TIME = 'hawaiianStandardTime',
    MARQUESAS_STANDARD_TIME = 'marquesasStandardTime',
    ALASKAN_STANDARD_TIME = 'alaskanStandardTime',
    UTC_MINUS09 = 'utcMinus09',
    PACIFIC_STANDARD_TIME_MEXICO = 'pacificStandardTimeMexico',
    UTC_MINUS08 = 'utcMinus08',
    PACIFIC_STANDARD_TIME = 'pacificStandardTime',
    US_MOUNTAIN_STANDARD_TIME = 'usMountainStandardTime',
    MOUNTAIN_STANDARD_TIME_MEXICO = 'mountainStandardTimeMexico',
    MOUNTAIN_STANDARD_TIME = 'mountainStandardTime',
    YUKON_STANDARD_TIME = 'yukonStandardTime',
    CENTRAL_AMERICA_STANDARD_TIME = 'centralAmericaStandardTime',
    CENTRAL_STANDARD_TIME = 'centralStandardTime',
    EASTER_ISLAND_STANDARD_TIME = 'easterIslandStandardTime',
    CENTRAL_STANDARD_TIME_MEXICO = 'centralStandardTimeMexico',
    CANADA_CENTRAL_STANDARD_TIME = 'canadaCentralStandardTime',
    SA_PACIFIC_STANDARD_TIME = 'saPacificStandardTime',
    EASTERN_STANDARD_TIME_MEXICO = 'easternStandardTimeMexico',
    EASTERN_STANDARD_TIME = 'easternStandardTime',
    HAITI_STANDARD_TIME = 'haitiStandardTime',
    CUBA_STANDARD_TIME = 'cubaStandardTime',
    US_EASTERN_STANDARD_TIME = 'usEasternStandardTime',
    TURKS_AND_CAICOS_STANDARD_TIME = 'turksAndCaicosStandardTime',
    PARAGUAY_STANDARD_TIME = 'paraguayStandardTime',
    ATLANTIC_STANDARD_TIME = 'atlanticStandardTime',
    VENEZUELA_STANDARD_TIME = 'venezuelaStandardTime',
    CENTRAL_BRAZILIAN_STANDARD_TIME = 'centralBrazilianStandardTime',
    SA_WESTERN_STANDARD_TIME = 'saWesternStandardTime',
    PACIFIC_SA_STANDARD_TIME = 'pacificSaStandardTime',
    NEWFOUNDLAND_STANDARD_TIME = 'newfoundlandStandardTime',
    TOCANTINS_STANDARD_TIME = 'tocantinsStandardTime',
    E_SOUTH_AMERICA_STANDARD_TIME = 'eSouthAmericaStandardTime',
    SA_EASTERN_STANDARD_TIME = 'saEasternStandardTime',
    ARGENTINA_STANDARD_TIME = 'argentinaStandardTime',
    GREENLAND_STANDARD_TIME = 'greenlandStandardTime',
    MONTEVIDEO_STANDARD_TIME = 'montevideoStandardTime',
    MAGALLANES_STANDARD_TIME = 'magallanesStandardTime',
    SAINT_PIERRE_STANDARD_TIME = 'saintPierreStandardTime',
    BAHIA_STANDARD_TIME = 'bahiaStandardTime',
    UTC_MINUS02 = 'utcMinus02',
    AZORES_STANDARD_TIME = 'azoresStandardTime',
    CAPE_VERDE_STANDARD_TIME = 'capeVerdeStandardTime',
    UTC = 'utc',
    GMT_STANDARD_TIME = 'gmtStandardTime',
    GREENWICH_STANDARD_TIME = 'greenwichStandardTime',
    SAO_TOME_STANDARD_TIME = 'saoTomeStandardTime',
    MOROCCO_STANDARD_TIME = 'moroccoStandardTime',
    W_EUROPE_STANDARD_TIME = 'wEuropeStandardTime',
    CENTRAL_EUROPE_STANDARD_TIME = 'centralEuropeStandardTime',
    ROMANCE_STANDARD_TIME = 'romanceStandardTime',
    CENTRAL_EUROPEAN_STANDARD_TIME = 'centralEuropeanStandardTime',
    W_CENTRAL_AFRICA_STANDARD_TIME = 'wCentralAfricaStandardTime',
    JORDAN_STANDARD_TIME = 'jordanStandardTime',
    GTB_STANDARD_TIME = 'gtbStandardTime',
    MIDDLE_EAST_STANDARD_TIME = 'middleEastStandardTime',
    EGYPT_STANDARD_TIME = 'egyptStandardTime',
    E_EUROPE_STANDARD_TIME = 'eEuropeStandardTime',
    SYRIA_STANDARD_TIME = 'syriaStandardTime',
    WEST_BANK_STANDARD_TIME = 'westBankStandardTime',
    SOUTH_AFRICA_STANDARD_TIME = 'southAfricaStandardTime',
    FLE_STANDARD_TIME = 'fleStandardTime',
    ISRAEL_STANDARD_TIME = 'israelStandardTime',
    SOUTH_SUDAN_STANDARD_TIME = 'southSudanStandardTime',
    KALININGRAD_STANDARD_TIME = 'kaliningradStandardTime',
    SUDAN_STANDARD_TIME = 'sudanStandardTime',
    LIBYA_STANDARD_TIME = 'libyaStandardTime',
    NAMIBIA_STANDARD_TIME = 'namibiaStandardTime',
    ARABIC_STANDARD_TIME = 'arabicStandardTime',
    TURKEY_STANDARD_TIME = 'turkeyStandardTime',
    ARAB_STANDARD_TIME = 'arabStandardTime',
    BELARUS_STANDARD_TIME = 'belarusStandardTime',
    RUSSIAN_STANDARD_TIME = 'russianStandardTime',
    E_AFRICA_STANDARD_TIME = 'eAfricaStandardTime',
    IRAN_STANDARD_TIME = 'iranStandardTime',
    ARABIAN_STANDARD_TIME = 'arabianStandardTime',
    ASTRAKHAN_STANDARD_TIME = 'astrakhanStandardTime',
    AZERBAIJAN_STANDARD_TIME = 'azerbaijanStandardTime',
    RUSSIA_TIME_ZONE3 = 'russiaTimeZone3',
    MAURITIUS_STANDARD_TIME = 'mauritiusStandardTime',
    SARATOV_STANDARD_TIME = 'saratovStandardTime',
    GEORGIAN_STANDARD_TIME = 'georgianStandardTime',
    VOLGOGRAD_STANDARD_TIME = 'volgogradStandardTime',
    CAUCASUS_STANDARD_TIME = 'caucasusStandardTime',
    AFGHANISTAN_STANDARD_TIME = 'afghanistanStandardTime',
    WEST_ASIA_STANDARD_TIME = 'westAsiaStandardTime',
    EKATERINBURG_STANDARD_TIME = 'ekaterinburgStandardTime',
    PAKISTAN_STANDARD_TIME = 'pakistanStandardTime',
    QYZYLORDA_STANDARD_TIME = 'qyzylordaStandardTime',
    INDIA_STANDARD_TIME = 'indiaStandardTime',
    SRI_LANKA_STANDARD_TIME = 'sriLankaStandardTime',
    NEPAL_STANDARD_TIME = 'nepalStandardTime',
    CENTRAL_ASIA_STANDARD_TIME = 'centralAsiaStandardTime',
    BANGLADESH_STANDARD_TIME = 'bangladeshStandardTime',
    OMSK_STANDARD_TIME = 'omskStandardTime',
    MYANMAR_STANDARD_TIME = 'myanmarStandardTime',
    SE_ASIA_STANDARD_TIME = 'seAsiaStandardTime',
    ALTAI_STANDARD_TIME = 'altaiStandardTime',
    W_MONGOLIA_STANDARD_TIME = 'wMongoliaStandardTime',
    NORTH_ASIA_STANDARD_TIME = 'northAsiaStandardTime',
    N_CENTRAL_ASIA_STANDARD_TIME = 'nCentralAsiaStandardTime',
    TOMSK_STANDARD_TIME = 'tomskStandardTime',
    CHINA_STANDARD_TIME = 'chinaStandardTime',
    NORTH_ASIA_EAST_STANDARD_TIME = 'northAsiaEastStandardTime',
    SINGAPORE_STANDARD_TIME = 'singaporeStandardTime',
    W_AUSTRALIA_STANDARD_TIME = 'wAustraliaStandardTime',
    TAIPEI_STANDARD_TIME = 'taipeiStandardTime',
    ULAANBAATAR_STANDARD_TIME = 'ulaanbaatarStandardTime',
    AUS_CENTRAL_W_STANDARD_TIME = 'ausCentralWStandardTime',
    TRANSBAIKAL_STANDARD_TIME = 'transbaikalStandardTime',
    TOKYO_STANDARD_TIME = 'tokyoStandardTime',
    NORTH_KOREA_STANDARD_TIME = 'northKoreaStandardTime',
    KOREA_STANDARD_TIME = 'koreaStandardTime',
    YAKUTSK_STANDARD_TIME = 'yakutskStandardTime',
    CEN_AUSTRALIA_STANDARD_TIME = 'cenAustraliaStandardTime',
    AUS_CENTRAL_STANDARD_TIME = 'ausCentralStandardTime',
    E_AUSTRALIA_STANDARD_TIME = 'eAustraliaStandardTime',
    AUS_EASTERN_STANDARD_TIME = 'ausEasternStandardTime',
    WEST_PACIFIC_STANDARD_TIME = 'westPacificStandardTime',
    TASMANIA_STANDARD_TIME = 'tasmaniaStandardTime',
    VLADIVOSTOK_STANDARD_TIME = 'vladivostokStandardTime',
    LORD_HOWE_STANDARD_TIME = 'lordHoweStandardTime',
    BOUGAINVILLE_STANDARD_TIME = 'bougainvilleStandardTime',
    RUSSIA_TIME_ZONE10 = 'russiaTimeZone10',
    MAGADAN_STANDARD_TIME = 'magadanStandardTime',
    NORFOLK_STANDARD_TIME = 'norfolkStandardTime',
    SAKHALIN_STANDARD_TIME = 'sakhalinStandardTime',
    CENTRAL_PACIFIC_STANDARD_TIME = 'centralPacificStandardTime',
    RUSSIA_TIME_ZONE11 = 'russiaTimeZone11',
    NEW_ZEALAND_STANDARD_TIME = 'newZealandStandardTime',
    UTC_PLUS12 = 'utcPlus12',
    FIJI_STANDARD_TIME = 'fijiStandardTime',
    CHATHAM_ISLANDS_STANDARD_TIME = 'chathamIslandsStandardTime',
    UTC_PLUS13 = 'utcPlus13',
    TONGA_STANDARD_TIME = 'tongaStandardTime',
    SAMOA_STANDARD_TIME = 'samoaStandardTime',
    LINE_ISLANDS_STANDARD_TIME = 'lineIslandsStandardTime'
}


export function WindowsTimezoneFromJSON(json: any): WindowsTimezone {
    return WindowsTimezoneFromJSONTyped(json, false);
}

export function WindowsTimezoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): WindowsTimezone {
    return json as WindowsTimezone;
}

export function WindowsTimezoneToJSON(value?: WindowsTimezone | null): any {
    return value as any;
}

