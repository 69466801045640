/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FleetAuthenticatePeriodListUpdate } from './FleetAuthenticatePeriodListUpdate';
import {
    FleetAuthenticatePeriodListUpdateFromJSON,
    FleetAuthenticatePeriodListUpdateFromJSONTyped,
    FleetAuthenticatePeriodListUpdateToJSON,
} from './FleetAuthenticatePeriodListUpdate';
import type { MaintenanceListUpdate } from './MaintenanceListUpdate';
import {
    MaintenanceListUpdateFromJSON,
    MaintenanceListUpdateFromJSONTyped,
    MaintenanceListUpdateToJSON,
} from './MaintenanceListUpdate';
import type { PreOpsAnswerListUpdate } from './PreOpsAnswerListUpdate';
import {
    PreOpsAnswerListUpdateFromJSON,
    PreOpsAnswerListUpdateFromJSONTyped,
    PreOpsAnswerListUpdateToJSON,
} from './PreOpsAnswerListUpdate';
import type { ShockEventListUpdate } from './ShockEventListUpdate';
import {
    ShockEventListUpdateFromJSON,
    ShockEventListUpdateFromJSONTyped,
    ShockEventListUpdateToJSON,
} from './ShockEventListUpdate';
import type { UserGroupDetailsUpdate } from './UserGroupDetailsUpdate';
import {
    UserGroupDetailsUpdateFromJSON,
    UserGroupDetailsUpdateFromJSONTyped,
    UserGroupDetailsUpdateToJSON,
} from './UserGroupDetailsUpdate';
import type { UserListUpdate } from './UserListUpdate';
import {
    UserListUpdateFromJSON,
    UserListUpdateFromJSONTyped,
    UserListUpdateToJSON,
} from './UserListUpdate';
import type { UserProductivityListUpdate } from './UserProductivityListUpdate';
import {
    UserProductivityListUpdateFromJSON,
    UserProductivityListUpdateFromJSONTyped,
    UserProductivityListUpdateToJSON,
} from './UserProductivityListUpdate';
import type { VehicleListUpdate } from './VehicleListUpdate';
import {
    VehicleListUpdateFromJSON,
    VehicleListUpdateFromJSONTyped,
    VehicleListUpdateToJSON,
} from './VehicleListUpdate';
import type { VehicleMovementPeriodListUpdate } from './VehicleMovementPeriodListUpdate';
import {
    VehicleMovementPeriodListUpdateFromJSON,
    VehicleMovementPeriodListUpdateFromJSONTyped,
    VehicleMovementPeriodListUpdateToJSON,
} from './VehicleMovementPeriodListUpdate';
import type { VehicleProductivityListUpdate } from './VehicleProductivityListUpdate';
import {
    VehicleProductivityListUpdateFromJSON,
    VehicleProductivityListUpdateFromJSONTyped,
    VehicleProductivityListUpdateToJSON,
} from './VehicleProductivityListUpdate';

/**
 * 
 * @export
 * @interface ColumnVisibilityUpdate
 */
export interface ColumnVisibilityUpdate {
    /**
     * 
     * @type {UserListUpdate}
     * @memberof ColumnVisibilityUpdate
     */
    userList?: UserListUpdate | null;
    /**
     * 
     * @type {UserGroupDetailsUpdate}
     * @memberof ColumnVisibilityUpdate
     */
    userGroupDetails?: UserGroupDetailsUpdate | null;
    /**
     * 
     * @type {UserProductivityListUpdate}
     * @memberof ColumnVisibilityUpdate
     */
    userProductivityList?: UserProductivityListUpdate | null;
    /**
     * 
     * @type {VehicleListUpdate}
     * @memberof ColumnVisibilityUpdate
     */
    vehicleList?: VehicleListUpdate | null;
    /**
     * 
     * @type {VehicleProductivityListUpdate}
     * @memberof ColumnVisibilityUpdate
     */
    vehicleProductivityList?: VehicleProductivityListUpdate | null;
    /**
     * 
     * @type {VehicleMovementPeriodListUpdate}
     * @memberof ColumnVisibilityUpdate
     */
    vehicleMovementPeriodList?: VehicleMovementPeriodListUpdate | null;
    /**
     * 
     * @type {MaintenanceListUpdate}
     * @memberof ColumnVisibilityUpdate
     */
    maintenanceList?: MaintenanceListUpdate | null;
    /**
     * 
     * @type {FleetAuthenticatePeriodListUpdate}
     * @memberof ColumnVisibilityUpdate
     */
    fleetAuthenticatePeriodList?: FleetAuthenticatePeriodListUpdate | null;
    /**
     * 
     * @type {ShockEventListUpdate}
     * @memberof ColumnVisibilityUpdate
     */
    shockEventList?: ShockEventListUpdate | null;
    /**
     * 
     * @type {PreOpsAnswerListUpdate}
     * @memberof ColumnVisibilityUpdate
     */
    preOpsAnswerList?: PreOpsAnswerListUpdate | null;
}

/**
 * Check if a given object implements the ColumnVisibilityUpdate interface.
 */
export function instanceOfColumnVisibilityUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ColumnVisibilityUpdateFromJSON(json: any): ColumnVisibilityUpdate {
    return ColumnVisibilityUpdateFromJSONTyped(json, false);
}

export function ColumnVisibilityUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnVisibilityUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userList': !exists(json, 'userList') ? undefined : UserListUpdateFromJSON(json['userList']),
        'userGroupDetails': !exists(json, 'userGroupDetails') ? undefined : UserGroupDetailsUpdateFromJSON(json['userGroupDetails']),
        'userProductivityList': !exists(json, 'userProductivityList') ? undefined : UserProductivityListUpdateFromJSON(json['userProductivityList']),
        'vehicleList': !exists(json, 'vehicleList') ? undefined : VehicleListUpdateFromJSON(json['vehicleList']),
        'vehicleProductivityList': !exists(json, 'vehicleProductivityList') ? undefined : VehicleProductivityListUpdateFromJSON(json['vehicleProductivityList']),
        'vehicleMovementPeriodList': !exists(json, 'vehicleMovementPeriodList') ? undefined : VehicleMovementPeriodListUpdateFromJSON(json['vehicleMovementPeriodList']),
        'maintenanceList': !exists(json, 'maintenanceList') ? undefined : MaintenanceListUpdateFromJSON(json['maintenanceList']),
        'fleetAuthenticatePeriodList': !exists(json, 'fleetAuthenticatePeriodList') ? undefined : FleetAuthenticatePeriodListUpdateFromJSON(json['fleetAuthenticatePeriodList']),
        'shockEventList': !exists(json, 'shockEventList') ? undefined : ShockEventListUpdateFromJSON(json['shockEventList']),
        'preOpsAnswerList': !exists(json, 'preOpsAnswerList') ? undefined : PreOpsAnswerListUpdateFromJSON(json['preOpsAnswerList']),
    };
}

export function ColumnVisibilityUpdateToJSON(value?: ColumnVisibilityUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userList': UserListUpdateToJSON(value.userList),
        'userGroupDetails': UserGroupDetailsUpdateToJSON(value.userGroupDetails),
        'userProductivityList': UserProductivityListUpdateToJSON(value.userProductivityList),
        'vehicleList': VehicleListUpdateToJSON(value.vehicleList),
        'vehicleProductivityList': VehicleProductivityListUpdateToJSON(value.vehicleProductivityList),
        'vehicleMovementPeriodList': VehicleMovementPeriodListUpdateToJSON(value.vehicleMovementPeriodList),
        'maintenanceList': MaintenanceListUpdateToJSON(value.maintenanceList),
        'fleetAuthenticatePeriodList': FleetAuthenticatePeriodListUpdateToJSON(value.fleetAuthenticatePeriodList),
        'shockEventList': ShockEventListUpdateToJSON(value.shockEventList),
        'preOpsAnswerList': PreOpsAnswerListUpdateToJSON(value.preOpsAnswerList),
    };
}

