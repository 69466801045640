import * as React from "react";
import { ElodmsLayout } from "../../components/layout";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

export function ComingSoon(): JSX.Element {
    const { section } = useParams();
    const { t } = useTranslation();

    return (
        <ElodmsLayout title={section ?? "Coming Soon"}>
            <div className="flex h-full items-center justify-center text-center font-bold">
                {t("dms.generic.coming_soon")}
            </div>
        </ElodmsLayout>
    );
}
