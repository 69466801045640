/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  MotionSensor,
  MotionSensorSortKey,
  MotionSensorUpsert,
  PageInfo,
  SortDirection,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    MotionSensorFromJSON,
    MotionSensorToJSON,
    MotionSensorSortKeyFromJSON,
    MotionSensorSortKeyToJSON,
    MotionSensorUpsertFromJSON,
    MotionSensorUpsertToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface MotionSensorsListMotionSensorsRequest {
    pageSize: number;
    search?: string | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: MotionSensorSortKey;
}

export interface MotionSensorsListMotionSensorsMetadataRequest {
    pageSize: number;
    search?: string | null;
}

export interface MotionSensorsUpsertMotionSensorRequest {
    motionSensorUpsert: MotionSensorUpsert;
}

export interface MotionSensorsValidateUpsertMotionSensorRequest {
    motionSensorUpsert: MotionSensorUpsert;
}

/**
 * MotionSensorsApi - interface
 * 
 * @export
 * @interface MotionSensorsApiInterface
 */
export interface MotionSensorsApiInterface {
    /**
     * Lists all motion sensors.
     * @summary List motion sensors
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {MotionSensorSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MotionSensorsApiInterface
     */
    motionSensorsListMotionSensorsRaw(requestParameters: MotionSensorsListMotionSensorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MotionSensor>>>;

    /**
     * Lists all motion sensors.
     * List motion sensors
     */
    motionSensorsListMotionSensors(requestParameters: MotionSensorsListMotionSensorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MotionSensor>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary Motion sensor listing metadata
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MotionSensorsApiInterface
     */
    motionSensorsListMotionSensorsMetadataRaw(requestParameters: MotionSensorsListMotionSensorsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * Motion sensor listing metadata
     */
    motionSensorsListMotionSensorsMetadata(requestParameters: MotionSensorsListMotionSensorsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Either creates or updates a motion sensor.
     * @summary Upsert a motion sensor
     * @param {MotionSensorUpsert} motionSensorUpsert 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MotionSensorsApiInterface
     */
    motionSensorsUpsertMotionSensorRaw(requestParameters: MotionSensorsUpsertMotionSensorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MotionSensor>>;

    /**
     * Either creates or updates a motion sensor.
     * Upsert a motion sensor
     */
    motionSensorsUpsertMotionSensor(requestParameters: MotionSensorsUpsertMotionSensorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MotionSensor>;

    /**
     * Validate data for upserting a motion sensor without persisting it.  Returns an empty body if the data is valid. Returns validation errors if it is invalid.
     * @summary Validate a motion sensor
     * @param {MotionSensorUpsert} motionSensorUpsert 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MotionSensorsApiInterface
     */
    motionSensorsValidateUpsertMotionSensorRaw(requestParameters: MotionSensorsValidateUpsertMotionSensorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for upserting a motion sensor without persisting it.  Returns an empty body if the data is valid. Returns validation errors if it is invalid.
     * Validate a motion sensor
     */
    motionSensorsValidateUpsertMotionSensor(requestParameters: MotionSensorsValidateUpsertMotionSensorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class MotionSensorsApi extends runtime.BaseAPI implements MotionSensorsApiInterface {

    /**
     * Lists all motion sensors.
     * List motion sensors
     */
    async motionSensorsListMotionSensorsRaw(requestParameters: MotionSensorsListMotionSensorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MotionSensor>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling motionSensorsListMotionSensors.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/motion_sensors/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MotionSensorFromJSON));
    }

    /**
     * Lists all motion sensors.
     * List motion sensors
     */
    async motionSensorsListMotionSensors(requestParameters: MotionSensorsListMotionSensorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MotionSensor>> {
        const response = await this.motionSensorsListMotionSensorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * Motion sensor listing metadata
     */
    async motionSensorsListMotionSensorsMetadataRaw(requestParameters: MotionSensorsListMotionSensorsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling motionSensorsListMotionSensorsMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/motion_sensors/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * Motion sensor listing metadata
     */
    async motionSensorsListMotionSensorsMetadata(requestParameters: MotionSensorsListMotionSensorsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.motionSensorsListMotionSensorsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Either creates or updates a motion sensor.
     * Upsert a motion sensor
     */
    async motionSensorsUpsertMotionSensorRaw(requestParameters: MotionSensorsUpsertMotionSensorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MotionSensor>> {
        if (requestParameters.motionSensorUpsert === null || requestParameters.motionSensorUpsert === undefined) {
            throw new runtime.RequiredError('motionSensorUpsert','Required parameter requestParameters.motionSensorUpsert was null or undefined when calling motionSensorsUpsertMotionSensor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/motion_sensors/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MotionSensorUpsertToJSON(requestParameters.motionSensorUpsert),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MotionSensorFromJSON(jsonValue));
    }

    /**
     * Either creates or updates a motion sensor.
     * Upsert a motion sensor
     */
    async motionSensorsUpsertMotionSensor(requestParameters: MotionSensorsUpsertMotionSensorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MotionSensor> {
        const response = await this.motionSensorsUpsertMotionSensorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for upserting a motion sensor without persisting it.  Returns an empty body if the data is valid. Returns validation errors if it is invalid.
     * Validate a motion sensor
     */
    async motionSensorsValidateUpsertMotionSensorRaw(requestParameters: MotionSensorsValidateUpsertMotionSensorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.motionSensorUpsert === null || requestParameters.motionSensorUpsert === undefined) {
            throw new runtime.RequiredError('motionSensorUpsert','Required parameter requestParameters.motionSensorUpsert was null or undefined when calling motionSensorsValidateUpsertMotionSensor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/motion_sensors/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MotionSensorUpsertToJSON(requestParameters.motionSensorUpsert),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for upserting a motion sensor without persisting it.  Returns an empty body if the data is valid. Returns validation errors if it is invalid.
     * Validate a motion sensor
     */
    async motionSensorsValidateUpsertMotionSensor(requestParameters: MotionSensorsValidateUpsertMotionSensorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.motionSensorsValidateUpsertMotionSensorRaw(requestParameters, initOverrides);
    }

}
