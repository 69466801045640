/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserGroupCreate
 */
export interface UserGroupCreate {
    /**
     * 
     * @type {string}
     * @memberof UserGroupCreate
     */
    label: string;
    /**
     * Create UserGroupAssignments for these users.
     * @type {Array<string>}
     * @memberof UserGroupCreate
     */
    userIds?: Array<string> | null;
    /**
     * Create UserGroupVehicleGroupAssignments for these vehicle groups.
     * @type {Array<string>}
     * @memberof UserGroupCreate
     */
    vehicleGroupIds?: Array<string> | null;
}

/**
 * Check if a given object implements the UserGroupCreate interface.
 */
export function instanceOfUserGroupCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "label" in value;

    return isInstance;
}

export function UserGroupCreateFromJSON(json: any): UserGroupCreate {
    return UserGroupCreateFromJSONTyped(json, false);
}

export function UserGroupCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGroupCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'userIds': !exists(json, 'userIds') ? undefined : json['userIds'],
        'vehicleGroupIds': !exists(json, 'vehicleGroupIds') ? undefined : json['vehicleGroupIds'],
    };
}

export function UserGroupCreateToJSON(value?: UserGroupCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'userIds': value.userIds,
        'vehicleGroupIds': value.vehicleGroupIds,
    };
}

