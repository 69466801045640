/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Bulk import error tree.
 * 
 * This is only ever populated for bulk import request errors.
 * @export
 * @interface ErrorPropertiesBulkImportError
 */
export interface ErrorPropertiesBulkImportError {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ErrorPropertiesBulkImportError
     */
    parse: { [key: string]: Array<string>; };
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ErrorPropertiesBulkImportError
     */
    validation: { [key: string]: Array<string>; };
    /**
     * 
     * @type {boolean}
     * @memberof ErrorPropertiesBulkImportError
     */
    database: boolean;
}

/**
 * Check if a given object implements the ErrorPropertiesBulkImportError interface.
 */
export function instanceOfErrorPropertiesBulkImportError(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "parse" in value;
    isInstance = isInstance && "validation" in value;
    isInstance = isInstance && "database" in value;

    return isInstance;
}

export function ErrorPropertiesBulkImportErrorFromJSON(json: any): ErrorPropertiesBulkImportError {
    return ErrorPropertiesBulkImportErrorFromJSONTyped(json, false);
}

export function ErrorPropertiesBulkImportErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorPropertiesBulkImportError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parse': json['parse'],
        'validation': json['validation'],
        'database': json['database'],
    };
}

export function ErrorPropertiesBulkImportErrorToJSON(value?: ErrorPropertiesBulkImportError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parse': value.parse,
        'validation': value.validation,
        'database': value.database,
    };
}

