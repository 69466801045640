import * as React from "react";
import { external } from "tsdi";
import { IColumn } from "@fluentui/react";
import { makeObservable, computed } from "mobx";
import { observer } from "mobx-react";
import { inject } from "tsdi";
import { UUID } from "../../utils/uuid";
import { PreOpsQuestion } from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryPreOpsQuestions } from "../../domain/repositories/repository-pre-ops-questions";

export interface ListPreOpsChecklistItemCellProps {
    readonly preOpsQuestionId: UUID;
    readonly column: IColumn;
}

@external
@observer
export class ListPreOpsChecklistItemCell extends React.Component<ListPreOpsChecklistItemCellProps> {
    @inject private readonly repositoryPreOpsQuestions!: RepositoryPreOpsQuestions;
    @inject protected readonly i18n!: I18nProvider;

    constructor(props: ListPreOpsChecklistItemCellProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get preOpsQuestion(): PreOpsQuestion | undefined {
        return this.repositoryPreOpsQuestions.byId(this.props.preOpsQuestionId);
    }

    public render(): JSX.Element {
        const { column } = this.props;
        const { position, body } = this.preOpsQuestion!;
        switch (column.key) {
            case "position":
                return (
                    <span>
                        {this.i18n.t("listPreOpsChecklist.questionPosition.name", {
                            position,
                        })}
                    </span>
                );
            case "body":
                return <span>{body}</span>;
            default:
                return <></>;
        }
    }
}
