import * as React from "react";
import { external } from "tsdi";
import { IColumn } from "@fluentui/react";
import { makeObservable, computed } from "mobx";
import { observer } from "mobx-react";
import { inject } from "tsdi";
import { RepositoryUsers } from "../../domain/repositories/repository-users";
import { UUID } from "../../utils/uuid";
import { User } from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { RepositoryShifts } from "../../domain/repositories/repository-shifts";

export interface ListUserProductivityItemCellProps {
    readonly userId: UUID;
    readonly column: IColumn;
}

@external
@observer
export class ListUserProductivityItemCell extends React.Component<ListUserProductivityItemCellProps> {
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly repositoryDepartments!: RepositoryDepartments;
    @inject private readonly repositoryShift!: RepositoryShifts;
    @inject protected readonly i18n!: I18nProvider;

    constructor(props: ListUserProductivityItemCellProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get user(): User | undefined {
        return this.repositoryUsers.byId(this.props.userId);
    }

    @computed private get departmentLabel(): string | undefined {
        if (!this.user || !this.user.departmentId) {
            return;
        }
        return this.repositoryDepartments.byId(this.user.departmentId)?.label;
    }

    @computed private get userShift(): string | undefined {
        if (!this.user || !this.user.shiftId) {
            return;
        }
        return this.repositoryShift.byId(this.user.shiftId)?.label;
    }

    public render(): JSX.Element {
        const { column } = this.props;
        if (!this.user) {
            return <></>;
        }
        switch (column.key) {
            case "firstName":
                return <span>{this.user.firstName}</span>;
            case "lastName":
                return <span>{this.user.lastName}</span>;
            case "department":
                return <span>{this.departmentLabel}</span>;
            case "employeeId":
                return <span>{this.user.employeeId}</span>;
            case "shift":
                return <span>{this.userShift}</span>;
            case "loginHours":
                return <span>{this.i18n.formatDuration(this.user.totalLoginSeconds)}</span>;
            case "drivingHours":
                return <span>{this.i18n.formatDuration(this.user.totalDriveSeconds)}</span>;
            case "productivityKpi":
                if (!this.user.totalLoginSeconds) {
                    return <></>;
                }
                return (
                    <span>
                        {Math.trunc(
                            Math.round(
                                (this.user.totalDriveSeconds / this.user.totalLoginSeconds) * 100,
                            ),
                        )}
                        %
                    </span>
                );
            default:
                return <></>;
        }
    }
}
