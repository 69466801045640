/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PreOpsAnswerSortKey {
    TIME = 'time',
    VEHICLE = 'vehicle',
    VEHICLE_DEPARTMENT = 'vehicleDepartment',
    USERNAME = 'username',
    CHECKLIST = 'checklist',
    QUESTION = 'question'
}


export function PreOpsAnswerSortKeyFromJSON(json: any): PreOpsAnswerSortKey {
    return PreOpsAnswerSortKeyFromJSONTyped(json, false);
}

export function PreOpsAnswerSortKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreOpsAnswerSortKey {
    return json as PreOpsAnswerSortKey;
}

export function PreOpsAnswerSortKeyToJSON(value?: PreOpsAnswerSortKey | null): any {
    return value as any;
}

