/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PageInfo,
  Shift,
  ShiftCreate,
  ShiftSortKey,
  ShiftUpdate,
  SortDirection,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    ShiftFromJSON,
    ShiftToJSON,
    ShiftCreateFromJSON,
    ShiftCreateToJSON,
    ShiftSortKeyFromJSON,
    ShiftSortKeyToJSON,
    ShiftUpdateFromJSON,
    ShiftUpdateToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface ShiftsCreateShiftRequest {
    shiftCreate: ShiftCreate;
}

export interface ShiftsDeleteShiftRequest {
    shiftId: string;
}

export interface ShiftsListShiftsRequest {
    pageSize: number;
    search?: string | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: ShiftSortKey;
}

export interface ShiftsListShiftsMetadataRequest {
    pageSize: number;
    search?: string | null;
}

export interface ShiftsReadShiftRequest {
    shiftId: string;
}

export interface ShiftsUpdateShiftRequest {
    shiftId: string;
    shiftUpdate: ShiftUpdate;
}

export interface ShiftsValidateCreateShiftRequest {
    shiftCreate: ShiftCreate;
}

export interface ShiftsValidateUpdateShiftRequest {
    shiftId: string;
    shiftUpdate: ShiftUpdate;
}

/**
 * ShiftsApi - interface
 * 
 * @export
 * @interface ShiftsApiInterface
 */
export interface ShiftsApiInterface {
    /**
     * Create a new shift.
     * @summary Create a shift
     * @param {ShiftCreate} shiftCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftsApiInterface
     */
    shiftsCreateShiftRaw(requestParameters: ShiftsCreateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Shift>>;

    /**
     * Create a new shift.
     * Create a shift
     */
    shiftsCreateShift(requestParameters: ShiftsCreateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Shift>;

    /**
     * Delete the shift with the given ID.
     * @summary Delete a shift
     * @param {string} shiftId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftsApiInterface
     */
    shiftsDeleteShiftRaw(requestParameters: ShiftsDeleteShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the shift with the given ID.
     * Delete a shift
     */
    shiftsDeleteShift(requestParameters: ShiftsDeleteShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Lists all the shifts present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * @summary List shifts
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {ShiftSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftsApiInterface
     */
    shiftsListShiftsRaw(requestParameters: ShiftsListShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Shift>>>;

    /**
     * Lists all the shifts present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List shifts
     */
    shiftsListShifts(requestParameters: ShiftsListShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Shift>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary Shift listing metadata
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftsApiInterface
     */
    shiftsListShiftsMetadataRaw(requestParameters: ShiftsListShiftsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * Shift listing metadata
     */
    shiftsListShiftsMetadata(requestParameters: ShiftsListShiftsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the shift specified by the given ID.
     * @summary Get a single shift
     * @param {string} shiftId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftsApiInterface
     */
    shiftsReadShiftRaw(requestParameters: ShiftsReadShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Shift>>;

    /**
     * Returns the shift specified by the given ID.
     * Get a single shift
     */
    shiftsReadShift(requestParameters: ShiftsReadShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Shift>;

    /**
     * Update the shift specified by the given ID.
     * @summary Update shift
     * @param {string} shiftId 
     * @param {ShiftUpdate} shiftUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftsApiInterface
     */
    shiftsUpdateShiftRaw(requestParameters: ShiftsUpdateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Shift>>;

    /**
     * Update the shift specified by the given ID.
     * Update shift
     */
    shiftsUpdateShift(requestParameters: ShiftsUpdateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Shift>;

    /**
     * Validate data for a new shift without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a shift
     * @param {ShiftCreate} shiftCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftsApiInterface
     */
    shiftsValidateCreateShiftRaw(requestParameters: ShiftsValidateCreateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new shift without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a shift
     */
    shiftsValidateCreateShift(requestParameters: ShiftsValidateCreateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate data for updating the given shift, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate shift update
     * @param {string} shiftId 
     * @param {ShiftUpdate} shiftUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftsApiInterface
     */
    shiftsValidateUpdateShiftRaw(requestParameters: ShiftsValidateUpdateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for updating the given shift, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate shift update
     */
    shiftsValidateUpdateShift(requestParameters: ShiftsValidateUpdateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class ShiftsApi extends runtime.BaseAPI implements ShiftsApiInterface {

    /**
     * Create a new shift.
     * Create a shift
     */
    async shiftsCreateShiftRaw(requestParameters: ShiftsCreateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Shift>> {
        if (requestParameters.shiftCreate === null || requestParameters.shiftCreate === undefined) {
            throw new runtime.RequiredError('shiftCreate','Required parameter requestParameters.shiftCreate was null or undefined when calling shiftsCreateShift.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShiftCreateToJSON(requestParameters.shiftCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShiftFromJSON(jsonValue));
    }

    /**
     * Create a new shift.
     * Create a shift
     */
    async shiftsCreateShift(requestParameters: ShiftsCreateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Shift> {
        const response = await this.shiftsCreateShiftRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the shift with the given ID.
     * Delete a shift
     */
    async shiftsDeleteShiftRaw(requestParameters: ShiftsDeleteShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shiftId === null || requestParameters.shiftId === undefined) {
            throw new runtime.RequiredError('shiftId','Required parameter requestParameters.shiftId was null or undefined when calling shiftsDeleteShift.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts/{shift_id}`.replace(`{${"shift_id"}}`, encodeURIComponent(String(requestParameters.shiftId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the shift with the given ID.
     * Delete a shift
     */
    async shiftsDeleteShift(requestParameters: ShiftsDeleteShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.shiftsDeleteShiftRaw(requestParameters, initOverrides);
    }

    /**
     * Lists all the shifts present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List shifts
     */
    async shiftsListShiftsRaw(requestParameters: ShiftsListShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Shift>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling shiftsListShifts.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShiftFromJSON));
    }

    /**
     * Lists all the shifts present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List shifts
     */
    async shiftsListShifts(requestParameters: ShiftsListShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Shift>> {
        const response = await this.shiftsListShiftsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * Shift listing metadata
     */
    async shiftsListShiftsMetadataRaw(requestParameters: ShiftsListShiftsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling shiftsListShiftsMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * Shift listing metadata
     */
    async shiftsListShiftsMetadata(requestParameters: ShiftsListShiftsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.shiftsListShiftsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the shift specified by the given ID.
     * Get a single shift
     */
    async shiftsReadShiftRaw(requestParameters: ShiftsReadShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Shift>> {
        if (requestParameters.shiftId === null || requestParameters.shiftId === undefined) {
            throw new runtime.RequiredError('shiftId','Required parameter requestParameters.shiftId was null or undefined when calling shiftsReadShift.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts/{shift_id}`.replace(`{${"shift_id"}}`, encodeURIComponent(String(requestParameters.shiftId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShiftFromJSON(jsonValue));
    }

    /**
     * Returns the shift specified by the given ID.
     * Get a single shift
     */
    async shiftsReadShift(requestParameters: ShiftsReadShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Shift> {
        const response = await this.shiftsReadShiftRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the shift specified by the given ID.
     * Update shift
     */
    async shiftsUpdateShiftRaw(requestParameters: ShiftsUpdateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Shift>> {
        if (requestParameters.shiftId === null || requestParameters.shiftId === undefined) {
            throw new runtime.RequiredError('shiftId','Required parameter requestParameters.shiftId was null or undefined when calling shiftsUpdateShift.');
        }

        if (requestParameters.shiftUpdate === null || requestParameters.shiftUpdate === undefined) {
            throw new runtime.RequiredError('shiftUpdate','Required parameter requestParameters.shiftUpdate was null or undefined when calling shiftsUpdateShift.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts/{shift_id}`.replace(`{${"shift_id"}}`, encodeURIComponent(String(requestParameters.shiftId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ShiftUpdateToJSON(requestParameters.shiftUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShiftFromJSON(jsonValue));
    }

    /**
     * Update the shift specified by the given ID.
     * Update shift
     */
    async shiftsUpdateShift(requestParameters: ShiftsUpdateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Shift> {
        const response = await this.shiftsUpdateShiftRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new shift without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a shift
     */
    async shiftsValidateCreateShiftRaw(requestParameters: ShiftsValidateCreateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shiftCreate === null || requestParameters.shiftCreate === undefined) {
            throw new runtime.RequiredError('shiftCreate','Required parameter requestParameters.shiftCreate was null or undefined when calling shiftsValidateCreateShift.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShiftCreateToJSON(requestParameters.shiftCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new shift without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a shift
     */
    async shiftsValidateCreateShift(requestParameters: ShiftsValidateCreateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.shiftsValidateCreateShiftRaw(requestParameters, initOverrides);
    }

    /**
     * Validate data for updating the given shift, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate shift update
     */
    async shiftsValidateUpdateShiftRaw(requestParameters: ShiftsValidateUpdateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shiftId === null || requestParameters.shiftId === undefined) {
            throw new runtime.RequiredError('shiftId','Required parameter requestParameters.shiftId was null or undefined when calling shiftsValidateUpdateShift.');
        }

        if (requestParameters.shiftUpdate === null || requestParameters.shiftUpdate === undefined) {
            throw new runtime.RequiredError('shiftUpdate','Required parameter requestParameters.shiftUpdate was null or undefined when calling shiftsValidateUpdateShift.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts/{shift_id}/validate`.replace(`{${"shift_id"}}`, encodeURIComponent(String(requestParameters.shiftId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ShiftUpdateToJSON(requestParameters.shiftUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for updating the given shift, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate shift update
     */
    async shiftsValidateUpdateShift(requestParameters: ShiftsValidateUpdateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.shiftsValidateUpdateShiftRaw(requestParameters, initOverrides);
    }

}
