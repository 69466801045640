import * as React from "react";
import { external } from "tsdi";
import { IColumn, Icon, ImageFit, Image } from "@fluentui/react";
import { makeObservable, computed } from "mobx";
import { observer } from "mobx-react";
import { inject } from "tsdi";
import { RepositoryUsers } from "../../domain/repositories/repository-users";
import { UUID } from "../../utils/uuid";
import { User } from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryUserGroupAssignments } from "../../domain/repositories/repository-user-group-assignments";
import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { RepositoryShifts } from "../../domain/repositories/repository-shifts";
import { RepositorySites } from "../../domain/repositories/repository-sites";
import { ServiceFile } from "../../domain/services/service-file";
import { defaultPageSize } from "../../utils/constants";

export interface ListUserItemCellProps {
    readonly userId: UUID;
    readonly column: IColumn;
}

@external
@observer
export class ListUserItemCell extends React.Component<ListUserItemCellProps> {
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly repositoryDepartments!: RepositoryDepartments;
    @inject private readonly repositoryShift!: RepositoryShifts;
    @inject private readonly repositorySite!: RepositorySites;
    @inject private readonly serviceFile!: ServiceFile;
    @inject private readonly repositoryUserGroupAssignments!: RepositoryUserGroupAssignments;

    @inject protected readonly i18n!: I18nProvider;

    constructor(props: ListUserItemCellProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get user(): User | undefined {
        return this.repositoryUsers.byId(this.props.userId);
    }

    @computed private get userDepartment(): string | undefined {
        if (!this.user || !this.user.departmentId) {
            return;
        }
        return this.repositoryDepartments.byId(this.user.departmentId)?.label;
    }

    @computed private get userShift(): string | undefined {
        if (!this.user || !this.user.shiftId) {
            return;
        }
        return this.repositoryShift.byId(this.user.shiftId)?.label;
    }

    @computed private get userSite(): string | undefined {
        if (!this.user || !this.user.siteId) {
            return;
        }
        return this.repositorySite.byId(this.user.siteId)?.label;
    }

    @computed private get userImage(): string | undefined {
        if (!this.user || !this.user.imageId) {
            return;
        }
        return this.serviceFile.getFileUri(this.user.imageId) ?? undefined;
    }

    public render(): JSX.Element {
        const { column } = this.props;
        if (!this.user) {
            return <></>;
        }
        const groups = this.repositoryUserGroupAssignments.byQuery({
            pageSize: defaultPageSize,
            userId: this.props.userId,
        });
        switch (column.key) {
            case "imageUri":
                return (
                    <Image src={this.userImage} width={25} height={25} imageFit={ImageFit.cover} />
                );
            case "employeeId":
                return <span>{this.user.employeeId}</span>;
            case "firstName":
                return <span>{this.user.firstName}</span>;
            case "lastName":
                return <span>{this.user.lastName}</span>;
            case "jobTitle":
                return <span>{this.user.jobTitle ?? ""}</span>;
            case "nfcTokenDescription":
                return <span>{this.user.nfcTokenDescription}</span>;
            case "nfcToken":
                return <span>{this.user.nfcToken}</span>;
            case "department":
                return <span>{this.userDepartment}</span>;
            case "managementRole":
                return <span>{this.i18n.formatManagementRole(this.user.managementRole)}</span>;
            case "fleetRole":
                return <span>{this.i18n.formatFleetRole(this.user.fleetRole)}</span>;
            case "shift":
                return <span>{this.userShift}</span>;
            case "site":
                return <span>{this.userSite}</span>;
            case "licenseExpiryDate":
                return (
                    <span>
                        {this.user.licenseExpiryDate
                            ? this.i18n.formatDateOnly(this.user.licenseExpiryDate)
                            : ""}
                    </span>
                );
            case "expiryLogout":
                return (
                    <span style={{ display: "block", textAlign: "center", width: "50 px" }}>
                        <Icon iconName={this.user.expiryLogout ? "CheckMark" : "Cancel"} />
                    </span>
                );
            default:
                if (groups.find((group) => group.userGroupId === column.key)) {
                    return (
                        <span style={{ display: "block", textAlign: "center" }}>
                            <Icon iconName="Accept" />
                        </span>
                    );
                }
                return <></>;
        }
    }
}
