import * as React from "react";
import routes from "../routes";
import { NavLink } from "react-router-dom";
import Icon from "@mdi/react";
import {
    mdiChartArc,
    mdiCog,
    mdiFileUpload,
    mdiForklift,
    mdiGauge,
    mdiScriptText,
    mdiTools,
} from "@mdi/js";
import { ListType } from "../pages/list-equipment/list-equipment";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export interface NavigationProps {
    widthClasses: string;
    topOffsetClasses: string;
}

/**
 * The Sidebar navigation of the Elodms view.
 */
export const Navigation = function Navigation(props: NavigationProps): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    return (
        <div className={`fixed inset-y-0 ${props.widthClasses}`}>
            <div className="flex h-full flex-col overflow-y-auto bg-gray-700 p-2 text-slate-100 xl:p-4">
                <div className={`${props.topOffsetClasses}`}></div>
                <ul className="flex h-full grow flex-col">
                    <NavigationItem
                        key="dashboard"
                        text={t("dms.component.navigation.dashboard")}
                        url={routes.dashboard.generateUrl()}
                        iconPath={mdiGauge}
                    />
                    <NavigationItem
                        key="newDocument"
                        url={routes.newDocument.generateUrl()}
                        text={t("dms.component.navigation.newDocument")}
                        iconPath={mdiFileUpload}
                    />
                    <NavigationItem
                        key="equipment"
                        url={routes.listEquipment.generateUrl(ListType.All)}
                        text={t("dms.component.navigation.equipment")}
                        iconPath={mdiForklift}
                    >
                        <NavigationItemChild
                            key="trucks"
                            url={routes.listEquipment.generateUrl(ListType.Forklifts)}
                            text={t("dms.schema.equipment.type.trucks")}
                        />
                        <NavigationItemChild
                            key="towTrucks"
                            url={routes.listEquipment.generateUrl(ListType.TowTrucks)}
                            text={t("dms.schema.equipment.type.towTrucks")}
                        />
                        <NavigationItemChild
                            key="agv"
                            url={routes.listEquipment.generateUrl(ListType.AGVs)}
                            text={t("dms.schema.equipment.type.agvs")}
                        />
                        <NavigationItemChild
                            key="amr"
                            url={routes.listEquipment.generateUrl(ListType.AMRs)}
                            text={t("dms.schema.equipment.type.amrs")}
                        />
                        <NavigationItemChild
                            key="batteries"
                            url={routes.listEquipment.generateUrl(ListType.Batteries)}
                            text={t("dms.schema.equipment.type.batteries")}
                        />
                        <NavigationItemChild
                            key="handPalletJacks"
                            url={routes.listEquipment.generateUrl(ListType.HandPalletJacks)}
                            text={t("dms.schema.equipment.type.handPalletJacks")}
                        />
                        <NavigationItemChild
                            key="cleaningMachines"
                            url={routes.listEquipment.generateUrl(ListType.CleaningMachines)}
                            text={t("dms.schema.equipment.type.cleaningMachines")}
                        />
                    </NavigationItem>
                    <NavigationItem
                        key="serviceOperations"
                        url={routes.listServiceReports.generateUrl()}
                        text="Service"
                        iconPath={mdiTools}
                        neverActive
                    >
                        <NavigationItemChild
                            key="listServiceReports"
                            url={routes.listServiceReports.generateUrl()}
                            text={t("dms.component.navigation.listServiceReports")}
                        />
                        <NavigationItemChild
                            key="newServiceReport"
                            url={routes.newServiceReport.generateUrl()}
                            text={t("dms.component.navigation.newServiceReport")}
                        />
                    </NavigationItem>
                    <NavigationItem
                        key="reporting"
                        text={t("dms.component.navigation.reporting")}
                        url={routes.comingSoon.generateUrl(t("dms.component.navigation.reporting"))}
                        iconPath={mdiChartArc}
                    />
                    <NavigationItem
                        key="templates"
                        text={t("dms.component.navigation.templates")}
                        url={routes.comingSoon.generateUrl(t("dms.component.navigation.templates"))}
                        iconPath={mdiScriptText}
                    />
                    <div className="grow basis-1"></div>
                    <NavigationItem
                        key="setup"
                        text={t("dms.component.navigation.setup")}
                        url={routes.comingSoon.generateUrl(t("dms.component.navigation.setup"))}
                        iconPath={mdiCog}
                    />
                </ul>
            </div>
        </div>
    );
};

interface NavigationItemProps {
    readonly key: string;
    readonly url: string;
    readonly text: string;
    readonly iconPath: string;
    readonly children?: React.ReactNode;
    readonly neverActive?: boolean;
}

/**
 * A top-level navigation item in our sidebar navigation.
 */
export function NavigationItem({ ...props }: NavigationItemProps): JSX.Element {
    return (
        <div className="flex flex-col">
            <NavLink
                to={props.url}
                className={({ isActive }) => {
                    let style = [`mt-2 flex flex-row items-center p-2 pl-3`];
                    // Set styles when navigation entry is active.
                    if (isActive && !props.neverActive) {
                        style = style.concat([
                            "relative rounded-sm bg-slate-600",
                            // Add before styles, which add the dark-blue colored bar on the left on selection.
                            "before:absolute before:top-0 before:left-0 before:bg-yellow-400",
                            "before:h-full before:w-0.5 before:rounded-tl-sm before:rounded-bl-sm",
                        ]);
                    }

                    return style.join(" ");
                }}
            >
                <Icon path={props.iconPath} className="h-5 w-5 text-slate-400" />
                <span className="pl-2 font-medium leading-tight">{props.text}</span>
            </NavLink>
            {props.children && <ul className="flex flex-col">{props.children}</ul>}
        </div>
    );
}

interface NavigationItemChildProps {
    readonly key: string;
    readonly url: string;
    readonly text: string;
}

/**
 * A child of a main navigation item.
 * The second layer of the navigation bar.
 */
export function NavigationItemChild(props: NavigationItemChildProps): JSX.Element {
    return (
        <NavLink
            to={props.url}
            className={({ isActive }) => {
                const style = [`flex items-center`];
                // Set styles when navigation entry is active.
                if (isActive) {
                    style.push("relative rounded-sm bg-slate-600 font-medium text-slate-100 ");
                    // Add before styles, which add the dark-blue colored bar on the left on selection.
                    style.push(`before:absolute before:top-0 before:left-0 before:h-full
                    before:w-0.5 before:rounded-tl-sm before:rounded-bl-sm before:bg-yellow-400`);
                }

                return style.join(" ");
            }}
        >
            <div className="ml-5" />
            <div className="flex h-full items-center border-l-2 border-slate-500 py-1 pl-5 leading-tight">
                {props.text}
            </div>
        </NavLink>
    );
}
