import { HTMLAttributes } from "react";
import { RadioGroup as BaseRadioGroup } from "@headlessui/react";
import React from "react";

export default function Group({
    children,
    value,
    onChange,
    className,
}: {
    children: React.ReactNode;
    value: number | null;
    onChange(value: number): void;
    className?: string;
}): JSX.Element {
    return (
        <BaseRadioGroup value={value} onChange={onChange}>
            <div className={`flex flex-row flex-wrap gap-2 ${className ? className : ""}`}>
                {children}
            </div>
        </BaseRadioGroup>
    );
}

Group.Option = function RadioGroupOption({
    value,
    label,
    className,
    colorClasses = "border-slate-300 bg-white ui-checked:border-blue-900 ui-checked:bg-blue-50",
}: {
    value: number;
    label?: string;
    className?: string;
    colorClasses?: string;
}): JSX.Element {
    return (
        <BaseRadioGroup.Option
            value={value}
            className={`flex grow basis-4 cursor-pointer items-center justify-between gap-2 rounded border py-4 px-2 ${
                colorClasses ?? ""
            } ${className ?? ""}`}
        >
            <span className="w-6">
                {/* This element is used to center the label by using the same width as the checkmark */}
            </span>
            <BaseRadioGroup.Label as="span" className="text-center">
                {label}
            </BaseRadioGroup.Label>
            <CheckIcon className="h-6 w-6 ui-not-checked:invisible" />
        </BaseRadioGroup.Option>
    );
};

function CheckIcon({ className = "", ...props }: HTMLAttributes<unknown>): JSX.Element {
    return (
        <div
            className={`flex items-center justify-center rounded-full bg-slate-700 ${className}`}
            {...props}
        >
            <svg viewBox="0 0 12 12" fill="none" width="60%" height="60%">
                <path
                    d="M2.16675 7.13758L4.60933 9.5L10.1667 1.5"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                />
            </svg>
        </div>
    );
}
