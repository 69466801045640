import {
    IChoiceGroupOption,
    IStackStyles,
    IStackTokens,
    Stack,
    TooltipHost,
    getTheme,
} from "@fluentui/react";
import colors from "../../../ui/colors.scss";
import React from "react";
import css from "./action-config-node.scss";
import { Antenna, DigitalInput, NodeKind, Zone } from "../../../api";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { NodeHandles } from "./restricted-handle";
import { useNodeId, useReactFlow } from "reactflow";
import classNames from "classnames";

const theme = getTheme();

export const digitalInputOptions = (t: (key: string) => string): IChoiceGroupOption[] => {
    return Object.values(DigitalInput).map((key, index) => {
        return { key, text: t(`component.actionConfigNodes.digitalInput.${index}`) };
    });
};

export const zoneOptions = (t: (key: string) => string): IChoiceGroupOption[] => {
    return Object.values(Zone).map((key, index) => {
        return { key, text: t(`component.actionConfigNodes.zone.${index}`) };
    });
};

export const antennaOptions = (t: (key: string) => string): IChoiceGroupOption[] => {
    return Object.values(Antenna).map((key, index) => {
        return { key, text: t(`component.actionConfigNodes.antenna.${index}`) };
    });
};

const stackStyle: IStackStyles = {
    root: {
        background: colors.primarywhite,
        borderRadius: theme.effects.roundedCorner4,
        boxShadow: theme.effects.elevation4,
    },
};

interface ActionConfigNodeProps {
    nodeKind: string;
    invalid?: boolean;
    validationErrorMessage?: string;
    children?: React.ReactNode;
    sidebar?: boolean;
    disabled?: boolean;
}

const childrenToken: IStackTokens = { childrenGap: "1.5em" };

export default function ActionConfigNode(props: ActionConfigNodeProps): JSX.Element {
    const id = useNodeId();
    const { setNodes, setEdges } = useReactFlow();

    const deleteNode = (): void => {
        setNodes((nodes) => nodes.filter((node) => node.id !== id));
        // Also delete edges that are connected to this node
        setEdges((edges) => edges.filter((edge) => edge.source !== id && edge.target !== id));
    };

    const { t }: { t: TFunction } = useTranslation();
    const tooltipTargetElementRef: React.RefObject<HTMLButtonElement> =
        React.useRef<HTMLButtonElement>(null);

    return (
        <Stack
            className={classNames({
                [css.actionConfigNode__disabled]: props.disabled,
                [css.actionConfigNode__invalid]: props.invalid,
                "react-flow__node": true,
                "react-flow__node-input": true,
            })}
            styles={stackStyle}
            style={{
                height: "100%",
                position: "relative",
                borderStyle: "unset",
                padding: "unset",
                width: props.sidebar ? "" : "unset",
            }}
        >
            <TooltipHost
                styles={{ root: { height: "100%" } }}
                content={t(
                    `validation.invalid.actionConfig.${
                        props.validationErrorMessage ?? "undefined"
                    }`,
                )}
                hidden={!props.invalid}
            >
                <Stack style={{ height: "100%" }}>
                    <div className={css.actionConfigNode__heading}>
                        <>{t(`component.actionConfigNodes.${props.nodeKind}.label`)}</>
                        {!props.sidebar && (
                            <TooltipHost
                                content={t("component.actionConfigNode.delete")}
                                calloutProps={{ target: tooltipTargetElementRef.current }}
                            >
                                <button
                                    ref={tooltipTargetElementRef}
                                    className={css.actionConfigNode__heading__button}
                                    onClick={deleteNode}
                                >
                                    ×
                                </button>
                            </TooltipHost>
                        )}
                    </div>
                    {props.children && (
                        <Stack className={css.actionConfigNode__body} tokens={childrenToken}>
                            {props.children}
                        </Stack>
                    )}
                </Stack>
            </TooltipHost>
            {!props.disabled && <NodeHandles nodeKind={props.nodeKind as NodeKind} />}
        </Stack>
    );
}
