/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  News,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    NewsFromJSON,
    NewsToJSON,
} from '../models';

/**
 * NewsApi - interface
 * 
 * @export
 * @interface NewsApiInterface
 */
export interface NewsApiInterface {
    /**
     * Get formatted ELOfleet development news feeds.
     * @summary Get ELOfleet development news.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsReadNewsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<News>>;

    /**
     * Get formatted ELOfleet development news feeds.
     * Get ELOfleet development news.
     */
    newsReadNews(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<News>;

}

/**
 * 
 */
export class NewsApi extends runtime.BaseAPI implements NewsApiInterface {

    /**
     * Get formatted ELOfleet development news feeds.
     * Get ELOfleet development news.
     */
    async newsReadNewsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<News>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/news/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsFromJSON(jsonValue));
    }

    /**
     * Get formatted ELOfleet development news feeds.
     * Get ELOfleet development news.
     */
    async newsReadNews(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<News> {
        const response = await this.newsReadNewsRaw(initOverrides);
        return await response.value();
    }

}
