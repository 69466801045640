/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DepartmentCreate
 */
export interface DepartmentCreate {
    /**
     * 
     * @type {string}
     * @memberof DepartmentCreate
     */
    label: string;
}

/**
 * Check if a given object implements the DepartmentCreate interface.
 */
export function instanceOfDepartmentCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "label" in value;

    return isInstance;
}

export function DepartmentCreateFromJSON(json: any): DepartmentCreate {
    return DepartmentCreateFromJSONTyped(json, false);
}

export function DepartmentCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): DepartmentCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
    };
}

export function DepartmentCreateToJSON(value?: DepartmentCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
    };
}

