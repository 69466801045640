/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PersistentNotificationImpactSeverity {
    SEVERE = 'severe',
    MEDIUM = 'medium'
}


export function PersistentNotificationImpactSeverityFromJSON(json: any): PersistentNotificationImpactSeverity {
    return PersistentNotificationImpactSeverityFromJSONTyped(json, false);
}

export function PersistentNotificationImpactSeverityFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersistentNotificationImpactSeverity {
    return json as PersistentNotificationImpactSeverity;
}

export function PersistentNotificationImpactSeverityToJSON(value?: PersistentNotificationImpactSeverity | null): any {
    return value as any;
}

