/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Engine type Motion detection for electric engines is achieved using a hall sensor. Combustion engines utilize analog input for motion detection.
 * @export
 * @enum {string}
 */
export enum EngineType {
    ELECTRIC = 'electric',
    COMBUSTION = 'combustion'
}


export function EngineTypeFromJSON(json: any): EngineType {
    return EngineTypeFromJSONTyped(json, false);
}

export function EngineTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineType {
    return json as EngineType;
}

export function EngineTypeToJSON(value?: EngineType | null): any {
    return value as any;
}

