import * as React from "react";
import { omit } from "ramda";
import { IColumn, Image, ImageFit, Stack } from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { createUuid, UUID } from "../../utils/uuid";
import { ColumnVisibility, User, UsersExportUserListListType, UserSortKey } from "../../api";
import { observer } from "mobx-react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import {
    RepositoryUsers,
    UsersApiResource,
    UsersQuery,
} from "../../domain/repositories/repository-users";
import { SortState } from "../../utils/sort-state";
import { PaginationState } from "../../utils/pagination-state";
import { LayoutList } from "../layouts/layout-list";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import { ListUserProductivityItemCell } from "../atoms/list-user-productivity-item-cell";
import { FilterUserList, FilterUserListQuery } from "../organisms/filter-user-list";
import { ListSearchField } from "../atoms/list-search-field";
import { SearchState } from "../../utils/search-state";
import { RepositoryColumnVisibility } from "../../domain/repositories/repository-column-visibility";
import { ExportButton, ExportQuery } from "./export-button";
import { defaultPageSize } from "../../utils/constants";

export interface ListUserProductivitysProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly commandBar?: JSX.Element;
    readonly listStateId?: UUID;
}

interface ListUserProductivityItem {
    readonly key: UUID;
}

@external
@observer
export class ListUserProductivity extends React.Component<ListUserProductivitysProps> {
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject private readonly repositoryColumnVisibility!: RepositoryColumnVisibility;

    private _listStateId = createUuid();

    @observable private filterUserListQuery: FilterUserListQuery = {
        filteredDepartments: [],
        filteredFleetRoles: [],
        filteredUserGroups: [],
        filteredShifts: [],
        isFiltered: false,
    };

    constructor(props: ListUserProductivitysProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.userProductivity.initializeList(this.listStateId, {
            query: () => this.query,
        });
    }

    @computed private get listStateId(): UUID {
        return this.props.listStateId ?? this._listStateId;
    }

    @computed private get sortState(): SortState<UserSortKey> {
        return this.serviceListStates.userProductivity.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<UsersApiResource> {
        return this.serviceListStates.userProductivity.getPaginationState(this.listStateId);
    }

    @computed private get searchState(): SearchState {
        return this.serviceListStates.userProductivity.getSearchState(this.listStateId);
    }

    @computed private get columnVisibility(): ColumnVisibility | undefined {
        return this.repositoryColumnVisibility.byQuery()[0];
    }

    @computed private get query(): UsersQuery {
        const query = {
            pageSize: defaultPageSize,
            ...this.sortState.query,
            ...this.searchState.query,
        } as UsersQuery;
        if (this.filterUserListQuery === undefined) {
            return query;
        }
        if (this.filterUserListQuery.filteredDepartments.length > 0) {
            query.departmentIds = this.filterUserListQuery.filteredDepartments;
        }
        if (this.filterUserListQuery.filteredFleetRoles.length > 0) {
            query.fleetRoles = this.filterUserListQuery.filteredFleetRoles;
        }
        if (this.filterUserListQuery.filteredUserGroups.length > 0) {
            query.userGroupIds = this.filterUserListQuery.filteredUserGroups;
        }
        if (this.filterUserListQuery.filteredShifts.length > 0) {
            query.shiftIds = this.filterUserListQuery.filteredShifts;
        }
        return query;
    }

    @computed private get exportQuery(): ExportQuery {
        const query = Object.entries({
            list_type: UsersExportUserListListType.PRODUCTIVITY,
            ...this.query,
        });
        return { query, basePath: "/api/v1/users/export?" };
    }

    @computed private get users(): (User | undefined)[] {
        if (this.props.ids) {
            return this.props.ids.map((id) => this.repositoryUsers.byId(id));
        }
        return this.repositoryUsers.byQuery(this.query, this.paginationState.pagination);
    }

    @computed private get items(): (ListUserProductivityItem | undefined)[] {
        return this.users.map((user) => user && { key: user.id });
    }

    @computed private get columns(): IColumn[] {
        const columns: IColumn[] = [];

        if (this.columnVisibility?.userProductivityList.userEmployeeId) {
            columns.push({
                name: this.i18n.t("listUserProductivity.column.employeeId.name"),
                key: "employeeId",
                minWidth: 200,
            });
        }
        if (this.columnVisibility?.userProductivityList.userLastName) {
            columns.push({
                name: this.i18n.t("listUserProductivity.column.lastName.name"),
                key: "lastName",
                minWidth: 200,
            });
        }
        if (this.columnVisibility?.userProductivityList.userFirstName) {
            columns.push({
                name: this.i18n.t("listUserProductivity.column.firstName.name"),
                key: "firstName",
                minWidth: 200,
            });
        }
        if (this.columnVisibility?.userProductivityList.departmentLabel) {
            columns.push({
                name: this.i18n.t("listUserProductivity.column.department.name"),
                key: "department",
                minWidth: 200,
            });
        }
        if (this.columnVisibility?.userProductivityList.shiftLabel) {
            columns.push({
                name: this.i18n.t("listUserProductivity.column.shift.name"),
                key: "shift",
                minWidth: 200,
            });
        }
        columns.push({
            name: this.i18n.t("listUserProductivity.column.loginHours.name"),
            key: "loginHours",
            minWidth: 200,
        });
        columns.push({
            name: this.i18n.t("listUserProductivity.column.drivingHours.name"),
            key: "drivingHours",
            minWidth: 200,
        });
        columns.push({
            name: this.i18n.t("listUserProductivity.column.productivityKpi.name"),
            key: "productivityKpi",
            minWidth: 190,
            maxWidth: 190,
        });

        return columns;
    }

    private renderItem(item: ListUserProductivityItem, userId: UUID, column: IColumn): JSX.Element {
        const fieldContent = item[column?.fieldName as keyof ListUserProductivityItem];

        switch (column?.key) {
            case "imageUri":
                return (
                    <Image src={fieldContent} width={50} height={50} imageFit={ImageFit.cover} />
                );

            default:
                return <ListUserProductivityItemCell userId={userId} column={column} />;
        }
    }

    @action.bound private setUserListFilter(filterQuery: FilterUserListQuery): void {
        this.filterUserListQuery = {
            filteredDepartments: filterQuery.filteredDepartments,
            filteredFleetRoles: filterQuery.filteredFleetRoles,
            filteredUserGroups: filterQuery.filteredUserGroups,
            filteredShifts: filterQuery.filteredShifts,
            isFiltered: filterQuery.isFiltered,
        };
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.userProductivity.isInitialized(this.listStateId)) {
            return <></>;
        }
        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(["ids"], this.props);
        return (
            <LayoutList
                paginationState={this.paginationState}
                commandBar={
                    <Stack horizontal horizontalAlign="space-between">
                        {this.props.commandBar}
                        <ExportButton query={this.exportQuery} />
                    </Stack>
                }
                searchField={
                    <ListSearchField
                        placeholder={this.i18n.t("listUsers.search.placeholder")}
                        listStateId={this.listStateId}
                        listState={this.serviceListStates.userProductivity}
                    />
                }
                filter={<FilterUserList setUserListFilter={this.setUserListFilter} />}
            >
                <ElofleetList
                    {...props}
                    onRenderItemColumn={(item: ListUserProductivityItem, _index, column) =>
                        this.renderItem(item, item.key, column!)
                    }
                    onColumnHeaderClick={this.sortState.toggleColumn}
                    columns={this.sortState.patchColumns(this.columns)}
                    items={this.items}
                />
            </LayoutList>
        );
    }
}
