import * as React from "react";
import { Selection, IColumn } from "@fluentui/react";
import { external, initialize, inject } from "tsdi";
import { VehicleGroupAssignment, VehicleGroupAssignmentSortKey } from "../../api";
import { createUuid, UUID } from "../../utils/uuid";
import { observer } from "mobx-react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { action, computed, makeObservable } from "mobx";
import { omit } from "ramda";
import {
    RepositoryVehicleGroupAssignments,
    VehicleGroupAssignmentsApiResource,
    VehicleGroupAssignmentsQuery,
} from "../../domain/repositories/repository-vehicle-group-assignments";
import { RepositoryVehicles } from "../../domain/repositories/repository-vehicles";
import { RepositoryVehicleGroups } from "../../domain/repositories/repository-vehicle-groups";
import { SortState } from "../../utils/sort-state";
import { PaginationState } from "../../utils/pagination-state";
import { LayoutList } from "../layouts/layout-list";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import { SelectionState } from "../../utils/selection-state";
import { defaultPageSize } from "../../utils/constants";

export interface ListVehicleGroupAssignmentProps
    extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly vehicleId?: UUID;
    readonly vehicleGroupId?: UUID;
    readonly showVehicle?: boolean;
    readonly showGroup?: boolean;
    readonly commandBar?: JSX.Element;
    readonly listStateId?: UUID;
}

interface ListVehicleGroupAssignmentItem {
    readonly key: UUID;
    readonly vehicle: string;
    readonly groupLabel: string;
    readonly createdAt: string;
}

@external
@observer
export class ListVehicleGroupAssignments extends React.Component<ListVehicleGroupAssignmentProps> {
    @inject private readonly repositoryVehicleGroupAssignments!: RepositoryVehicleGroupAssignments;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;
    @inject private readonly repositoryVehicleGroups!: RepositoryVehicleGroups;
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;

    private _listStateId = createUuid();
    private selection = new Selection({
        onSelectionChanged: () => this.updateSelection(),
    });

    constructor(props: ListVehicleGroupAssignmentProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.vehicleGroupAssignments.initializeList(this.listStateId, {
            query: () => this.query,
        });
    }

    @computed private get listStateId(): UUID {
        return this.props.listStateId ?? this._listStateId;
    }

    @computed private get sortState(): SortState<VehicleGroupAssignmentSortKey> {
        return this.serviceListStates.vehicleGroupAssignments.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<VehicleGroupAssignmentsApiResource> {
        return this.serviceListStates.vehicleGroupAssignments.getPaginationState(this.listStateId);
    }

    @computed private get selectionState(): SelectionState {
        return this.serviceListStates.vehicleGroupAssignments.getSelectionState(this.listStateId);
    }

    @computed private get query(): VehicleGroupAssignmentsQuery {
        const { vehicleId } = this.props;
        return { vehicleId, pageSize: defaultPageSize, ...this.sortState.query };
    }

    @computed private get vehicleGroupAssignments(): (VehicleGroupAssignment | undefined)[] {
        if (this.props.ids) {
            return this.props.ids.map((id) => this.repositoryVehicleGroupAssignments.byId(id));
        }
        return this.repositoryVehicleGroupAssignments.byQuery(
            this.query,
            this.paginationState.pagination,
        );
    }

    @computed private get items(): (ListVehicleGroupAssignmentItem | undefined)[] {
        return this.vehicleGroupAssignments.map((vehicleGroupAssignment) => {
            if (!vehicleGroupAssignment) {
                return;
            }
            return {
                key: vehicleGroupAssignment.id,
                vehicle: this.repositoryVehicles.byId(vehicleGroupAssignment.vehicleId)?.id ?? "",
                groupLabel:
                    this.repositoryVehicleGroups.byId(vehicleGroupAssignment.vehicleGroupId)
                        ?.label ?? "",
                createdAt: this.i18n.formatDateOnly(vehicleGroupAssignment.createdAt),
            };
        });
    }

    @action.bound private updateSelection(): void {
        // Update the listState's selectionState to the new selected keys.
        this.selectionState.updateSelection(
            this.selection.getSelection().map((item) => item.key as UUID),
        );
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.vehicleGroupAssignments.isInitialized(this.listStateId)) {
            return <></>;
        }
        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(
            ["ids", "vehicleId", "vehicleGroupId", "showVehicle", "showGroup"],
            this.props,
        );
        const columns: IColumn[] = [];
        if (this.props.showVehicle ?? true) {
            columns.push({
                fieldName: "vehicle",
                name: this.i18n.t("listVehicleGroupAssignments.column.vehicle.name"),
                key: "username",
                minWidth: 100,
            });
        }
        if (this.props.showGroup ?? true) {
            columns.push({
                fieldName: "groupLabel",
                name: this.i18n.t("listVehicleGroupAssignments.column.vehicleGroup.name"),
                key: "groupLabel",
                minWidth: 100,
            });
        }
        columns.push({
            fieldName: "createdAt",
            name: this.i18n.t("listVehicleGroupAssignments.column.createdAt.name"),
            key: "createdAt",
            minWidth: 100,
        });
        return (
            <LayoutList paginationState={this.paginationState} commandBar={this.props.commandBar}>
                <ElofleetList
                    {...props}
                    selection={this.selection}
                    onColumnHeaderClick={this.sortState.toggleColumn}
                    columns={this.sortState.patchColumns(columns)}
                    items={this.items}
                />
            </LayoutList>
        );
    }
}
