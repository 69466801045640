import { component, inject } from "tsdi";
import {
    DepartmentSortKey,
    PreOpsAnswerSortKey,
    PreOpsChecklistSortKey,
    PreOpsQuestionSortKey,
    SafetyMessageSortKey,
    ShiftSortKey,
    ShockEventSortKey,
    ShockProfileSortKey,
    SiteSortKey,
    UserSortKey,
    UserGroupAssignmentSortKey,
    UserGroupSortKey,
    VehicleDeviceSortKey,
    VehicleSortKey,
    VehicleGroupAssignmentSortKey,
    VehicleGroupSortKey,
    FleetAuthenticatePeriodSortKey,
    VehicleMovementPeriodSortKey,
    PhoneSortKey,
    VehicleTypeSortKey,
    MotionSensorSortKey,
} from "../../api";
import { ListStates as ListStates } from "../../utils/list-states";
import { RepositoryDepartments } from "../repositories/repository-departments";
import { RepositoryFleetAuthenticatePeriods } from "../repositories/repository-fleet-authenticate-periods";
import { RepositoryPreOpsAnswers } from "../repositories/repository-pre-ops-answers";
import { RepositoryPreOpsChecklists } from "../repositories/repository-pre-ops-checklists";
import { RepositoryPreOpsQuestions } from "../repositories/repository-pre-ops-questions";
import { RepositorySafetyMessages } from "../repositories/repository-safety-messages";
import { RepositoryShifts } from "../repositories/repository-shifts";
import { RepositoryShockEvents } from "../repositories/repository-shock-events";
import { RepositoryShockProfiles } from "../repositories/repository-shock-profiles";
import { RepositorySites } from "../repositories/repository-sites";
import { RepositoryUserGroupAssignments } from "../repositories/repository-user-group-assignments";
import { RepositoryUserGroups } from "../repositories/repository-user-groups";
import { RepositoryUsers } from "../repositories/repository-users";
import { RepositoryVehicleDevices } from "../repositories/repository-vehicle-devices";
import { RepositoryVehicleGroupAssignments } from "../repositories/repository-vehicle-group-assignments";
import { RepositoryVehicleGroups } from "../repositories/repository-vehicle-groups";
import { RepositoryVehicleMovementPeriods } from "../repositories/repository-vehicle-movement-periods";
import { RepositoryVehicles } from "../repositories/repository-vehicles";
import { RepositoryPhones } from "../repositories/repository-phones";
import { RepositoryVehicleTypes } from "../repositories/repository-vehicle-types";
import { RepositoryMotionSensors } from "../repositories/repository-motion-sensors";

/**
 * Hosts all lists states for all lists in the application.
 * This service is initialized when the application starts up.
 * List components can inject this service and register new states
 * on their corresponding list states member.
 */
@component
export class ServiceListStates {
    @inject
    private readonly RepositoryFleetAuthenticatePeriods!: RepositoryFleetAuthenticatePeriods;
    @inject private readonly repositoryMotionSensors!: RepositoryMotionSensors;
    @inject private readonly repositorySafetyMessages!: RepositorySafetyMessages;
    @inject private readonly repositoryShockEvents!: RepositoryShockEvents;
    @inject private readonly repositoryShockProfiles!: RepositoryShockProfiles;
    @inject private readonly repositorySites!: RepositorySites;
    @inject private readonly repositoryDepartments!: RepositoryDepartments;
    @inject private readonly repositoryPhones!: RepositoryPhones;
    @inject private readonly repositoryPreOpsChecklists!: RepositoryPreOpsChecklists;
    @inject private readonly repositoryPreOpsQuestions!: RepositoryPreOpsQuestions;
    @inject private readonly repositoryPreOpsAnswers!: RepositoryPreOpsAnswers;
    @inject private readonly repositoryShifts!: RepositoryShifts;
    @inject private readonly repositoryUserGroupAssignments!: RepositoryUserGroupAssignments;
    @inject private readonly repositoryUserGroups!: RepositoryUserGroups;
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly repositoryVehicleDevices!: RepositoryVehicleDevices;
    @inject private readonly repositoryVehicleGroupAssignments!: RepositoryVehicleGroupAssignments;
    @inject private readonly repositoryVehicleGroups!: RepositoryVehicleGroups;
    @inject private readonly repositoryVehicleMovementPeriods!: RepositoryVehicleMovementPeriods;
    @inject private readonly repositoryVehicleTypes!: RepositoryVehicleTypes;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;

    /** States for all {@link ListDepartments}. */
    public departments = new ListStates<RepositoryDepartments>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "label":
                    return DepartmentSortKey.LABEL;
                case "createdAt":
                    return DepartmentSortKey.CREATED_AT;
            }
        },
        repository: () => this.repositoryDepartments,
    });

    /** States for all {@link ListMotionSensors}. */
    public motionSensors = new ListStates<RepositoryMotionSensors>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "vehicleDeviceSerialNumber":
                    return MotionSensorSortKey.VEHICLE_DEVICE_SERIAL_NUMBER;
                case "firmwareLastUpdatedAt":
                    return MotionSensorSortKey.FIRMWARE_LAST_UPDATED_AT;
                case "currentFirmwareVersion":
                    return MotionSensorSortKey.CURRENT_VERSION;
            }
        },
        repository: () => this.repositoryMotionSensors,
    });

    /** States for all {@link ListPreOpsChecklists}. */
    public preOpsChecklists = new ListStates<RepositoryPreOpsChecklists>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "createdAt":
                    return PreOpsChecklistSortKey.CREATED_AT;
                case "label":
                    return PreOpsChecklistSortKey.LABEL;
            }
        },
        repository: () => this.repositoryPreOpsChecklists,
    });

    /** States for all {@link ListPreOpsQuestions}. */
    public preOpsQuestions = new ListStates<RepositoryPreOpsQuestions>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "position":
                    return PreOpsQuestionSortKey.POSITION;
                case "body":
                    return PreOpsQuestionSortKey.BODY;
            }
        },
        repository: () => this.repositoryPreOpsQuestions,
    });

    /** States for all {@link ListShifts}. */
    public shifts = new ListStates<RepositoryShifts>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "label":
                    return ShiftSortKey.LABEL;
                case "createdAt":
                    return ShiftSortKey.CREATED_AT;
            }
        },
        repository: () => this.repositoryShifts,
    });

    /** States for all {@link ListShockEvents}. */
    public shockEvents = new ListStates<RepositoryShockEvents>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "time":
                    return ShockEventSortKey.TIME;
                case "username":
                    return ShockEventSortKey.USERNAME;
                case "severity":
                    return ShockEventSortKey.G_FORCE;
                case "vehicle":
                    return ShockEventSortKey.VEHICLE;
                case "department":
                    return ShockEventSortKey.VEHICLE_DEPARTMENT;
            }
        },

        repository: () => this.repositoryShockEvents,
    });

    /** States for all {@link ListShockProfiles}. */
    public shockProfiles = new ListStates<RepositoryShockProfiles>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "createdAt":
                    return ShockProfileSortKey.CREATED_AT;
                case "label":
                    return ShockProfileSortKey.LABEL;
                case "light":
                    return ShockProfileSortKey.LIGHT;
                case "medium":
                    return ShockProfileSortKey.MEDIUM;
                case "severe":
                    return ShockProfileSortKey.SEVERE;
                case "duration":
                    return ShockProfileSortKey.DURATION;
            }
        },
        repository: () => this.repositoryShockProfiles,
    });

    /** States for all {@link ListSafetyMessages}. */
    public safetyMessages = new ListStates<RepositorySafetyMessages>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "body":
                    return SafetyMessageSortKey.BODY;
                case "createdAt":
                    return SafetyMessageSortKey.CREATED_AT;
            }
        },
        repository: () => this.repositorySafetyMessages,
    });

    /** States for all {@link ListSites}. */
    public sites = new ListStates<RepositorySites>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "label":
                    return SiteSortKey.LABEL;
                case "createdAt":
                    return SiteSortKey.CREATED_AT;
            }
        },
        repository: () => this.repositorySites,
    });

    /** States for all {@link ListUserGroupAssignments}. */
    public userGroupAssignments = new ListStates<RepositoryUserGroupAssignments>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "createdAt":
                    return UserGroupAssignmentSortKey.CREATED_AT;
            }
        },
        repository: () => this.repositoryUserGroupAssignments,
    });

    /** States for all {@link ListUserGroups}. */
    public userGroups = new ListStates<RepositoryUserGroups>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "label":
                    return UserGroupSortKey.LABEL;
                case "createdAt":
                    return UserGroupSortKey.CREATED_AT;
            }
        },
        repository: () => this.repositoryUserGroups,
    });

    /** States for all {@link ListUsers}. */
    public users = new ListStates<RepositoryUsers>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "createdAt":
                    return UserSortKey.CREATED_AT;
                case "firstName":
                    return UserSortKey.FIRST_NAME;
                case "lastName":
                    return UserSortKey.LAST_NAME;
                case "jobTitle":
                    return UserSortKey.JOB_TITLE;
                case "email":
                    return UserSortKey.EMAIL;
                case "managementRole":
                    return UserSortKey.MANAGEMENT_ROLE;
                case "fleetRole":
                    return UserSortKey.FLEET_ROLE;
                case "department":
                    return UserSortKey.DEPARTMENT;
                case "shift":
                    return UserSortKey.SHIFT;
                case "site":
                    return UserSortKey.SITE;
                case "employeeId":
                    return UserSortKey.EMPLOYEE_ID;
                case "licenseExpiryDate":
                    return UserSortKey.LICENSE_EXPIRY_DATE;
                case "expiryLogout":
                    return UserSortKey.EXPIRY_LOGOUT;
            }
        },
        repository: () => this.repositoryUsers,
    });

    /** States for all {@link ListUserProductivity}. */
    public userProductivity = new ListStates<RepositoryUsers>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "createdAt":
                    return UserSortKey.CREATED_AT;
                case "firstName":
                    return UserSortKey.FIRST_NAME;
                case "lastName":
                    return UserSortKey.LAST_NAME;
                case "jobTitle":
                    return UserSortKey.JOB_TITLE;
                case "email":
                    return UserSortKey.EMAIL;
                case "managementRole":
                    return UserSortKey.MANAGEMENT_ROLE;
                case "fleetRole":
                    return UserSortKey.FLEET_ROLE;
                case "department":
                    return UserSortKey.DEPARTMENT;
                case "shift":
                    return UserSortKey.SHIFT;
                case "site":
                    return UserSortKey.SITE;
                case "employeeId":
                    return UserSortKey.EMPLOYEE_ID;
                case "licenseExpiryDate":
                    return UserSortKey.LICENSE_EXPIRY_DATE;
                case "expiryLogout":
                    return UserSortKey.EXPIRY_LOGOUT;
            }
        },
        repository: () => this.repositoryUsers,
    });

    /** States for all {@link ListVehicleDevices}. */
    public vehicleDevices = new ListStates<RepositoryVehicleDevices>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "serialNumber":
                    return VehicleDeviceSortKey.SERIAL_NUMBER;
                case "createdAt":
                    return VehicleDeviceSortKey.CREATED_AT;
            }
        },
        repository: () => this.repositoryVehicleDevices,
    });

    /** States for all {@link ListVehicleGroupAssignments}. */
    public vehicleGroupAssignments = new ListStates<RepositoryVehicleGroupAssignments>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "createdAt":
                    return VehicleGroupAssignmentSortKey.CREATED_AT;
            }
        },
        repository: () => this.repositoryVehicleGroupAssignments,
    });

    /** States for all {@link ListVehicleGroups}. */
    public vehicleGroups = new ListStates<RepositoryVehicleGroups>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "label":
                    return VehicleGroupSortKey.LABEL;
                case "createdAt":
                    return VehicleGroupSortKey.CREATED_AT;
            }
        },
        repository: () => this.repositoryVehicleGroups,
    });

    /** States for all {@link ListVehicleMovementPeriods}. */
    public vehicleMovementPeriods = new ListStates<RepositoryVehicleMovementPeriods>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "eventId":
                    return VehicleMovementPeriodSortKey.EVENT_ID;
                case "vehicleSerialNumber":
                    return VehicleMovementPeriodSortKey.VEHICLE_SERIAL_NUMBER;
                case "userEmployeeId":
                    return VehicleMovementPeriodSortKey.USER_EMPLOYEE_ID;
                case "startTime":
                    return VehicleMovementPeriodSortKey.START_TIME;
                case "stopTime":
                    return VehicleMovementPeriodSortKey.STOP_TIME;
                case "totalTime":
                    return VehicleMovementPeriodSortKey.TOTAL_TIME;
            }
        },
        repository: () => this.repositoryVehicleMovementPeriods,
    });

    /** States for all {@link ListVehicleTypes}. */
    public vehicleTypes = new ListStates<RepositoryVehicleTypes>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "label":
                    return VehicleTypeSortKey.LABEL;
            }
        },
        repository: () => this.repositoryVehicleTypes,
    });

    /** States for all {@link ListVehicles}. */
    public vehicles = new ListStates<RepositoryVehicles>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "serialNumber":
                    return VehicleSortKey.SERIAL_NUMBER;
                case "createdAt":
                    return VehicleSortKey.CREATED_AT;
                case "manufactureYear":
                    return VehicleSortKey.MANUFACTURE_YEAR;
                case "manufacturer":
                    return VehicleSortKey.MANUFACTURER;
                case "model":
                    return VehicleSortKey.MODEL;
                case "department":
                    return VehicleSortKey.DEPARTMENT;
                case "shockProfile":
                    return VehicleSortKey.SHOCK_PROFILE;
                case "phoneImei":
                    return VehicleSortKey.PHONE_IMEI;
            }
        },
        repository: () => this.repositoryVehicles,
    });

    /** States for all {@link Phones} */
    public phones = new ListStates<RepositoryPhones>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "createdAt":
                    return PhoneSortKey.CREATED_AT;
                case "appLastUpdatedAt":
                    return PhoneSortKey.APP_LAST_UPDATED_AT;
            }
        },
        repository: () => this.repositoryPhones,
    });

    /** States for all {@link ListPreOpsAnswers} */
    public preOpsAnswers = new ListStates<RepositoryPreOpsAnswers>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "time":
                    return PreOpsAnswerSortKey.TIME;
                case "checklistLabel":
                    return PreOpsAnswerSortKey.CHECKLIST;
                case "question":
                    return PreOpsAnswerSortKey.QUESTION;
                case "user":
                    return PreOpsAnswerSortKey.USERNAME;
                case "vehicle":
                    return PreOpsAnswerSortKey.VEHICLE;
                case "department":
                    return PreOpsAnswerSortKey.VEHICLE_DEPARTMENT;
            }
        },
        repository: () => this.repositoryPreOpsAnswers,
    });

    /** States for all {@link ListLoginReport}. */
    public fleetAuthenticatePeriod = new ListStates<RepositoryFleetAuthenticatePeriods>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "eventId":
                    return FleetAuthenticatePeriodSortKey.EVENT_ID;
                case "userEmployeeId":
                    return FleetAuthenticatePeriodSortKey.USER_EMPLOYEE_ID;
                case "vehicleSerialNumber":
                    return FleetAuthenticatePeriodSortKey.VEHICLE_SERIAL_NUMBER;
                case "loggedInTime":
                    return FleetAuthenticatePeriodSortKey.LOGGED_IN_TIME;
                case "loggedOutTime":
                    return FleetAuthenticatePeriodSortKey.LOGGED_OUT_TIME;
            }
        },
        repository: () => this.RepositoryFleetAuthenticatePeriods,
    });

    /** States for all {@link ListVehicleProductivity}. */
    public vehicleProductivity = new ListStates<RepositoryVehicles>({
        getSortKeyForColumn: (column) => {
            switch (column.key) {
                case "serialNumber":
                    return VehicleSortKey.SERIAL_NUMBER;
                case "createdAt":
                    return VehicleSortKey.CREATED_AT;
                case "manufactureYear":
                    return VehicleSortKey.MANUFACTURE_YEAR;
                case "manufacturer":
                    return VehicleSortKey.MANUFACTURER;
                case "model":
                    return VehicleSortKey.MODEL;
                case "department":
                    return VehicleSortKey.DEPARTMENT;
            }
        },
        repository: () => this.repositoryVehicles,
    });
}
