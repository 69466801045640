import React from "react";
import { declareRoute, routePath } from "../routes";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { PageContent } from "../ui/atoms/page-content";
import { Section } from "../ui/atoms/section";
import { PageHeader } from "../ui/atoms/page-header";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { ListPhones } from "../ui/molecules/list-phones";
import { createUuid } from "../utils/uuid";
import sizes from "../ui/sizes.scss";

export function PagePhones(): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    return (
        <LayoutDefault header={<PageHeader title={t("page.phones.title")} />}>
            <PageContent>
                <Section>
                    <ListPhones listStateId={createUuid()} maxHeight={`${sizes.listMaxHeight}`} />
                </Section>
            </PageContent>
        </LayoutDefault>
    );
}

export const routePhones = declareRoute({
    component: PagePhones,
    icon: "CellPhone",
    title: "page.phones.navbarEntry",
    path: routePath.phones,
    pattern: "/phones",
});
