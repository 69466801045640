import React, { useState } from "react";
import { declareRoute, routePath } from "../routes";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { BulkImportError, ErrorResponse } from "../api";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { PageContent } from "../ui/atoms/page-content";
import { Section } from "../ui/atoms/section";
import { PageHeader } from "../ui/atoms/page-header";
import { BulkImportErrorMessage } from "../ui/molecules/bulk-import-error-message";
import { Icon, MessageBar, MessageBarButton, MessageBarType } from "@fluentui/react";
import css from "./page-bulk-import.scss";
import { tsdi } from "../tsdi";
import { RepositoryVehicles } from "../domain/repositories/repository-vehicles";
import { RepositoryUsers } from "../domain/repositories/repository-users";
import { RepositoryUserGroups } from "../domain/repositories/repository-user-groups";
import { RepositoryVehicleGroups } from "../domain/repositories/repository-vehicle-groups";
import { RepositorySites } from "../domain/repositories/repository-sites";
import { RepositoryDepartments } from "../domain/repositories/repository-departments";
import { RepositoryPreOpsChecklists } from "../domain/repositories/repository-pre-ops-checklists";
import { RepositoryPublicSettings } from "../domain/repositories/repository-public-settings";
import { RepositoryVehicleTypes } from "../domain/repositories/repository-vehicle-types";
import { RepositorySafetyMessages } from "../domain/repositories/repository-safety-messages";
import { defaultPageSize } from "../utils/constants";

export function PageBulkImport(): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    const [error, setError] = useState<BulkImportError | undefined>(undefined);
    /** Set on a successful file upload */
    const [success, setSuccess] = useState(false);
    /** Set on a successful file validation */
    const [validatedFile, setValidatedFile] = useState<ArrayBuffer | undefined>(undefined);

    const sitesRepository = tsdi.get(RepositorySites);
    const departmentsRepository = tsdi.get(RepositoryDepartments);
    const preOpsChecklistsRepository = tsdi.get(RepositoryPreOpsChecklists);
    const vehiclesRepository = tsdi.get(RepositoryVehicles);
    const vehicleGroupsRepository = tsdi.get(RepositoryVehicleGroups);
    const usersRepository = tsdi.get(RepositoryUsers);
    const userGroupsRepository = tsdi.get(RepositoryUserGroups);
    const publicSettingsRepository = tsdi.get(RepositoryPublicSettings);
    const vehicleTypesRepository = tsdi.get(RepositoryVehicleTypes);
    const safetyMessagesRepository = tsdi.get(RepositorySafetyMessages);

    /** Constructs the bulk import request. */
    function getBulkImportRequest(file: ArrayBuffer, path?: string): Request {
        // The generated OpenAPI spec odes not work for uploading the file. Therefore
        // we build the request manually.
        let url = "/api/v1/bulk_import";
        if (path) {
            url += path;
        }
        const request = new Request(url, {
            method: "POST",
            credentials: "same-origin",
            body: new Uint8Array(file),
            headers: [
                [
                    "Content-Type",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                ],
            ],
        });
        return request;
    }

    /** Reads the input file. */
    function readFile(event: React.ChangeEvent<HTMLInputElement>): void {
        setSuccess(false);
        // Return when no files are selected
        if (!event.target.files) {
            return;
        }

        // Read file
        const reader = new FileReader();
        reader.onload = (e) => {
            const res = e.target?.result as ArrayBuffer;
            // Validate file on a finished read
            validateFile(res);
        };
        const file = event.target.files[0];
        reader.readAsArrayBuffer(file);
    }

    /** Validates the input file via the backend validation endpoint. */
    async function validateFile(file: ArrayBuffer): Promise<void> {
        if (!file) {
            return;
        }
        const request = getBulkImportRequest(file, "/validate");
        const response = await fetch(request);

        // On OK, return and set valid file
        if (response.ok) {
            setError(undefined);
            setValidatedFile(file);
            return;
        }

        // Otherwise we received an error
        const error = (await response.json()) as ErrorResponse;
        if (error.error.bulkImportError) {
            setError(error.error.bulkImportError as BulkImportError);
            setValidatedFile(undefined);
        }
    }

    /** Uploads a valid file to the backend. */
    async function uploadFile(): Promise<void> {
        if (!validatedFile) {
            return;
        }
        const request = getBulkImportRequest(validatedFile);
        const response = await fetch(request);
        if (response.ok) {
            setSuccess(true);
            setValidatedFile(undefined);
            await resetRepositories();
            return;
        }
    }

    /** Resets all repositories affected by the bulk import. Otherwise
        we could end up displaying outdated data due to the mobx
        caching problem. */
    async function resetRepositories(): Promise<void> {
        await sitesRepository.reloadQuery({ pageSize: defaultPageSize });
        await departmentsRepository.reloadQuery({ pageSize: defaultPageSize });
        await preOpsChecklistsRepository.reloadQuery({ pageSize: defaultPageSize });
        await vehiclesRepository.reloadQuery({ pageSize: defaultPageSize });
        await vehicleGroupsRepository.reloadQuery({ pageSize: defaultPageSize });
        await usersRepository.reloadQuery({ pageSize: defaultPageSize });
        await userGroupsRepository.reloadQuery({ pageSize: defaultPageSize });
        await publicSettingsRepository.reloadQuery();
        await vehicleTypesRepository.reloadQuery({ pageSize: defaultPageSize });
        await safetyMessagesRepository.reloadQuery({ pageSize: defaultPageSize });
    }

    return (
        <LayoutDefault header={<PageHeader title={t("page.systemWide.title")} />}>
            <PageContent>
                <Section withPadding title={t("page.bulkImport.title")}>
                    <BulkImportErrorMessage error={error} />
                    {success ? (
                        <>
                            <MessageBar messageBarType={MessageBarType.success}>
                                {"That worked! All data from your sheet was successfully imported."}
                            </MessageBar>
                        </>
                    ) : (
                        <></>
                    )}
                    {validatedFile ? (
                        <MessageBar
                            isMultiline={false}
                            messageBarType={MessageBarType.info}
                            actions={
                                <MessageBarButton onClick={() => uploadFile()}>
                                    {"Import data"}
                                </MessageBarButton>
                            }
                        >
                            {
                                "Your input file contains valid data! Execute your import by clicking the 'Import data' button."
                            }
                        </MessageBar>
                    ) : (
                        <></>
                    )}
                    <br />
                    <label htmlFor="file-upload" className={css.pageBulkImport__label}>
                        <Icon iconName="OpenFile" className={css.pageBulkImport__icon} />
                        <p>{"Drag and drop a file here"}</p>
                    </label>
                    <br />
                    <input id="file-upload" type="file" accept=".xlsx" onChange={readFile} />
                </Section>
            </PageContent>
        </LayoutDefault>
    );
}

export const routeBulkImport = declareRoute({
    component: PageBulkImport,
    icon: "ExcelDocument",
    title: "page.bulkImport.navbarEntry",
    path: routePath.bulkImport,
    pattern: "/bulk-import",
});
