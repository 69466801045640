import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    PageInfo,
    PreOpsAnswerCreate,
    PreOpsAnswersApi,
    PreOpsAnswersListPreOpsAnswersRequest,
    PreOpsAnswer,
    PreOpsAnswerSortKey,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { defaultPageSize } from "../../utils/constants";

export type PreOpsAnswersQuery = Omit<PreOpsAnswersListPreOpsAnswersRequest, "page">;

export interface PreOpsAnswersApiResource extends ApiResource {
    entity: PreOpsAnswer;
    query: PreOpsAnswersQuery;
    update: unknown;
    create: PreOpsAnswerCreate;
    sortKey: PreOpsAnswerSortKey;
}

@component
export class RepositoryPreOpsAnswers extends ElofleetRepository<PreOpsAnswersApiResource> {
    @inject private readonly preOpsAnswersApi!: PreOpsAnswersApi;

    private metadataRequest: Promise<PageInfo> | undefined;

    protected async fetchById(answerId: UUID): Promise<PreOpsAnswer | undefined> {
        return await this.preOpsAnswersApi.preOpsAnswersReadPreOpsAnswer({
            answerId,
        });
    }

    protected extractId = prop("id");

    protected async fetchByQuery(
        query: PreOpsAnswersQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<PreOpsAnswer>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.preOpsAnswersApi.preOpsAnswersListPreOpsAnswers({
            ...query,
            page,
        });
        return { entities };
    }

    public async waitForIdle(): Promise<void> {
        await Promise.all([super.waitForIdle(), this.metadataRequest]);
    }

    public fetchMetadata(_: PreOpsAnswersQuery): Promise<PageInfo> {
        this.metadataRequest = this.preOpsAnswersApi.preOpsAnswersListPreOpsAnswersMetadata({
            pageSize: defaultPageSize,
        });
        return this.metadataRequest;
    }

    public update(_id: string, _update: never): Promise<PreOpsAnswer> {
        throw new Error("Method not implemented.");
    }
    public create(_update: PreOpsAnswerCreate): Promise<PreOpsAnswer> {
        throw new Error("Method not implemented.");
    }
    public delete(..._ids: string[]): Promise<void> {
        throw new Error("Method not implemented.");
    }
}
