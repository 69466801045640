import React from "react";
import css from "./inline-stacked-bar-chart.scss";

export interface Props {
    bars: BarProps[];
}

interface BarProps {
    count: number;
    color: string;
}

export function InlineStackedBarChart(props: Props): JSX.Element {
    // Render a `StackedBar` component for each bar in `props.bars`.
    const barElements = props.bars.map(StackedBar);

    return <div className={css.inlineStackedBarChart}>{barElements}</div>;
}

function StackedBar({ color, count }: BarProps): JSX.Element {
    return (
        <div
            key={color + count.toString()}
            style={{ backgroundColor: color, flexGrow: Math.floor(count) }}
        ></div>
    );
}
