/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum VehicleDeviceStatus {
    ASSIGNED = 'assigned',
    NOT_ASSIGNED = 'notAssigned'
}


export function VehicleDeviceStatusFromJSON(json: any): VehicleDeviceStatus {
    return VehicleDeviceStatusFromJSONTyped(json, false);
}

export function VehicleDeviceStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleDeviceStatus {
    return json as VehicleDeviceStatus;
}

export function VehicleDeviceStatusToJSON(value?: VehicleDeviceStatus | null): any {
    return value as any;
}

