import { action, makeObservable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { RepositoryVehicles } from "../../domain/repositories/repository-vehicles";
import { UUID } from "../../utils/uuid";
import { ListCommandButtons, ListCommandButtonsProps } from "./list-command-buttons";
import { FormUpdateVehicle } from "../molecules/form-update-vehicle";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { ErrorReport, ServiceErrors } from "../../domain/services/service-errors";
import { defaultPageSize } from "../../utils/constants";
import { ResponseError, VehiclesApi } from "../../api";

export type ListCommandButtonsVehiclesProps = Omit<
    ListCommandButtonsProps<RepositoryVehicles>,
    "list" | "createForm" | "listState"
>;

@external
@observer
export class ListCommandButtonsVehicles extends React.Component<ListCommandButtonsVehiclesProps> {
    @inject private readonly repositoryVehicles!: RepositoryVehicles;
    @inject private readonly vehiclesApi!: VehiclesApi;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject protected readonly i18n!: I18nProvider;
    @inject readonly serviceErrors!: ServiceErrors;

    private undeletableVehicles: string[] = [];

    constructor(props: ListCommandButtonsVehiclesProps) {
        super(props);
        makeObservable(this);
    }

    /** Tries to delete all vehicles and reports an error on
        encounter with a vehicle linked to events.
    */
    @action.bound private async deleteVehicles(ids: UUID[]): Promise<void> {
        if (this.props.onDelete) {
            this.props.onDelete(ids);
        }
        ids.forEach(async (id) => await this.deleteSingleVehicle(id));

        // This reload is necessary to reload all queries that want to display "all" entities.
        await this.repositoryVehicles.waitForIdle();
        await this.repositoryVehicles.reloadQuery({ pageSize: defaultPageSize });

        // If we successfully deleted all vehicles return early
        if (this.undeletableVehicles.length == 0) {
            return;
        }

        // Report error that lists all undeletable vehicles
        const vehicleList = this.undeletableVehicles.map((vehicle) => <li>{vehicle}</li>);
        const errorReport = {
            title: "component.errorBar.vehicle.unableToDeleteVehicle.title",
            description: "component.errorBar.vehicle.unableToDeleteVehicle.description",
            html: <ul>{vehicleList}</ul>,
        } as ErrorReport;
        void this.serviceErrors.reportError(errorReport);
        this.undeletableVehicles = [];
    }

    /** Tries to delete a single vehicle. If we receive a validation error (422)
        we mark this vehicle as undeletable. */
    private async deleteSingleVehicle(id: UUID): Promise<void> {
        try {
            await this.vehiclesApi.vehiclesDeleteVehicle({ vehicleId: id });
            return;
        } catch (error) {
            const responseError = error as ResponseError;
            const response = responseError.response;

            // Keep track of vehicles that produce a validation error
            if (response.status === 422) {
                const vehicle = this.repositoryVehicles.byId(id);
                this.undeletableVehicles.push(this.i18n.formatVehicle(vehicle!));
                return;
            } else {
                // We still have to report other errors (e.g., internal server error)
                this.serviceErrors.reportResponse(response);
            }
        }
    }

    public render(): JSX.Element {
        return (
            <ListCommandButtons
                updateForm={FormUpdateVehicle}
                listState={this.serviceListStates.vehicles}
                createDialogTitle={this.i18n.t("listVehicles.createDialog.title")}
                updateDialogTitle={this.i18n.t("listVehicles.updateDialog.title")}
                {...this.props}
                onDelete={this.deleteVehicles}
                dialogMaxWidth="1200px"
            />
        );
    }
}
