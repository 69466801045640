import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { declareRoute, routePath, RouteProps } from "../routes";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { ListShockProfiles } from "../ui/molecules/list-shock-profiles";
import { ListCommandButtonsShockProfiles } from "../ui/organisms/list-command-buttons-shock-profiles";
import { createUuid } from "../utils/uuid";
import { PageHeader } from "../ui/atoms/page-header";
import { PageContent } from "../ui/atoms/page-content";
import { Section } from "../ui/atoms/section";
import sizes from "../ui/sizes.scss";

@external
@observer
export class PageShockProfiles extends React.Component<RouteProps> {
    @inject protected readonly i18n!: I18nProvider;

    // Create the UUID on this level, so the button bar is able to access the same state as the list.
    private listStateId = createUuid();

    constructor(props: RouteProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <LayoutDefault header={<PageHeader title={this.i18n.t("page.shockProfiles.title")} />}>
                <PageContent>
                    <Section>
                        <ListShockProfiles
                            commandBar={
                                <ListCommandButtonsShockProfiles
                                    listStateId={this.listStateId}
                                    canDelete
                                />
                            }
                            listStateId={this.listStateId}
                            maxHeight={`${sizes.listMaxHeight}`}
                        />
                    </Section>
                </PageContent>
            </LayoutDefault>
        );
    }
}

export const routeShockProfiles = declareRoute({
    component: PageShockProfiles,
    icon: "ActivateOrders",
    title: "page.shockProfiles.navbarEntry",
    path: routePath.shockProfiles,
    pattern: "/shock-profiles",
});
