import { Segment, FetchByQueryResult } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    PageInfo,
    ShockEvent,
    ShockEventCreate,
    ShockEventsApi,
    ShockEventsListShockEventsRequest,
    ShockEventSortKey,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";

export type ShockEventsQuery = Omit<ShockEventsListShockEventsRequest, "page">;

export interface ShockEventsApiResource extends ApiResource {
    entity: ShockEvent;
    query: ShockEventsQuery;
    update: unknown;
    create: ShockEventCreate;
    sortKey: ShockEventSortKey;
}

@component
export class RepositoryShockEvents extends ElofleetRepository<ShockEventsApiResource> {
    @inject private readonly shockEventsApi!: ShockEventsApi;

    public fetchMetadata(query: ShockEventsQuery): Promise<PageInfo> {
        return this.shockEventsApi.shockEventsListShockEventsMetadata(query);
    }

    public update(): Promise<ShockEvent> {
        throw new Error("Cannot update shock events: Not implemented.");
    }

    public create(): Promise<ShockEvent> {
        throw new Error("Cannot update shock events: Not implemented.");
    }

    public delete(): Promise<void> {
        throw new Error("Cannot delete shock events: Not implemented.");
    }

    protected async fetchByQuery(
        query: ShockEventsQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<ShockEvent>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.shockEventsApi.shockEventsListShockEvents({ ...query, page });
        return { entities };
    }

    protected fetchById(shockEventId: string): Promise<ShockEvent | undefined> {
        return this.shockEventsApi.shockEventsReadShockEvent({ shockEventId });
    }
    protected extractId = prop("id");
}
