import * as React from "react";
import { external } from "tsdi";
import { IColumn } from "@fluentui/react";
import { makeObservable, computed } from "mobx";
import { observer } from "mobx-react";
import { inject } from "tsdi";
import { UUID } from "../../utils/uuid";
import {
    Department,
    PreOpsAnswer,
    PreOpsAnswerChoice,
    PreOpsChecklist,
    PreOpsQuestion,
    User,
    Vehicle,
} from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryPreOpsAnswers } from "../../domain/repositories/repository-pre-ops-answers";
import { RepositoryPreOpsChecklists } from "../../domain/repositories/repository-pre-ops-checklists";
import { RepositoryPreOpsQuestionVersions } from "../../domain/repositories/repository-pre-ops-question-versions";
import { RepositoryVehicles } from "../../domain/repositories/repository-vehicles";
import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { RepositoryUsers } from "../../domain/repositories/repository-users";
import { Severity, SeverityPill } from "./severity-pill";

export interface ListPreOpsAnswerItemCellProps {
    readonly answerId: UUID;
    readonly column: IColumn;
}

@external
@observer
export class ListPreOpsAnswerItemCell extends React.Component<ListPreOpsAnswerItemCellProps> {
    @inject private readonly repositoryDepartments!: RepositoryDepartments;
    @inject private readonly repositoryPreOpsQuestionVersions!: RepositoryPreOpsQuestionVersions;
    @inject private readonly repositoryPreOpsChecklists!: RepositoryPreOpsChecklists;
    @inject private readonly repositoryPreOpsAnswers!: RepositoryPreOpsAnswers;
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;
    @inject protected readonly i18n!: I18nProvider;

    constructor(props: ListPreOpsAnswerItemCellProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get preOpsAnswer(): PreOpsAnswer | undefined {
        return this.repositoryPreOpsAnswers.byId(this.props.answerId);
    }

    @computed private get preOpsQuestion(): PreOpsQuestion | undefined {
        if (this.preOpsAnswer) {
            return this.repositoryPreOpsQuestionVersions.byId(this.preOpsAnswer.questionVersionId);
        }
    }

    @computed private get preOpsChecklist(): PreOpsChecklist | undefined {
        if (this.preOpsQuestion) {
            return this.repositoryPreOpsChecklists.byId(this.preOpsQuestion.checklistId);
        }
    }

    @computed private get vehicle(): Vehicle | undefined {
        if (this.preOpsAnswer) {
            return this.repositoryVehicles.byId(this.preOpsAnswer.vehicleId);
        }
    }
    @computed private get department(): Department | undefined {
        if (this.vehicle && this.vehicle.departmentId) {
            return this.repositoryDepartments.byId(this.vehicle.departmentId);
        }
    }

    @computed private get user(): User | undefined {
        if (this.preOpsAnswer) {
            return this.repositoryUsers.byId(this.preOpsAnswer.userId);
        }
    }

    @computed private get severity(): Severity | undefined {
        if (!this.preOpsAnswer) {
            return;
        }

        switch (this.preOpsAnswer.choice) {
            case PreOpsAnswerChoice.GOOD:
                return Severity.LIGHT;
            case PreOpsAnswerChoice.OK:
                return Severity.MEDIUM;
            case PreOpsAnswerChoice.CRITICAL:
                return Severity.SEVERE;
        }
    }

    public render(): JSX.Element {
        const { column } = this.props;
        switch (column.key) {
            case "time":
                return (
                    <span>
                        {this.preOpsAnswer && this.i18n.formatDateTime(this.preOpsAnswer.time)}
                    </span>
                );
            case "vehicle":
                return <span>{this.vehicle?.serialNumber}</span>;
            case "department":
                return <span>{this.department?.label}</span>;
            case "user":
                return <span>{this.user && this.i18n.formatUserFullName(this.user)}</span>;
            case "checklistLabel":
                return <span>{this.preOpsChecklist?.label}</span>;
            case "question":
                return <span>{this.preOpsQuestion?.body}</span>;
            case "choice":
                return this.severity && this.preOpsAnswer ? (
                    <SeverityPill kind={"preOpsAnswer"} severity={this.severity} />
                ) : (
                    <span />
                );
            case "key":
                return <span>{this.props.answerId}</span>;
            default:
                return <></>;
        }
    }
}
