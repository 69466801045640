import { runInAction } from "mobx";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    PageInfo,
    UserGroupAssignmentCreate,
    UserGroupAssignmentsApi,
    UserGroupAssignmentsListUserGroupAssignmentsRequest,
    UserGroupAssignmentSortKey,
    UserGroupAssignmentUpdate,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { UserGroupAssignment } from "../../api";
import { Validation } from "../../utils/validation";
import { defaultPageSize } from "../../utils/constants";

export type UserGroupAssignmentsQuery = Omit<
    UserGroupAssignmentsListUserGroupAssignmentsRequest,
    "page"
>;

export interface UserGroupAssignmentsApiResource extends ApiResource {
    entity: UserGroupAssignment;
    query: UserGroupAssignmentsQuery;
    update: UserGroupAssignmentUpdate;
    create: UserGroupAssignmentCreate;
    sortKey: UserGroupAssignmentSortKey;
}

@component
export class RepositoryUserGroupAssignments extends ElofleetRepository<UserGroupAssignmentsApiResource> {
    @inject private readonly userGroupAssignmentsApi!: UserGroupAssignmentsApi;

    public validation = {
        create: new Validation((userGroupAssignmentCreate: UserGroupAssignmentCreate) =>
            this.userGroupAssignmentsApi.userGroupAssignmentsValidateCreateUserGroupAssignment({
                userGroupAssignmentCreate: userGroupAssignmentCreate,
            }),
        ),
        update: new Validation(
            (userGroupAssignmentUpdate: UserGroupAssignmentUpdate, userGroupAssignmentId: UUID) =>
                this.userGroupAssignmentsApi.userGroupAssignmentsValidateUpdateUserGroupAssignment({
                    userGroupAssignmentUpdate,
                    userGroupAssignmentId,
                }),
        ),
    };

    protected async fetchByQuery(
        query: UserGroupAssignmentsQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<UserGroupAssignment>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.wrapApiCall(
            this.userGroupAssignmentsApi.userGroupAssignmentsListUserGroupAssignments({
                ...query,
                page,
            }),
        );
        return { entities };
    }

    public fetchMetadata(query: UserGroupAssignmentsQuery): Promise<PageInfo> {
        return this.userGroupAssignmentsApi.userGroupAssignmentsListUserGroupAssignmentsMetadata(
            query,
        );
    }

    protected async fetchById(
        userGroupAssignmentId: UUID,
    ): Promise<UserGroupAssignment | undefined> {
        return await this.userGroupAssignmentsApi.userGroupAssignmentsReadUserGroupAssignment({
            userGroupAssignmentId,
        });
    }

    protected extractId = prop("id");

    public async update(
        userGroupAssignmentId: UUID,
        userGroupAssignmentUpdate: UserGroupAssignmentUpdate,
    ): Promise<UserGroupAssignment> {
        const userGroupAssignment = await this.wrapApiCall(
            this.userGroupAssignmentsApi.userGroupAssignmentsUpdateUserGroupAssignment({
                userGroupAssignmentId,
                userGroupAssignmentUpdate,
            }),
        );
        await this.waitForIdle();
        runInAction(() => {
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(userGroupAssignment);
        });
        return userGroupAssignment;
    }

    public async create(
        userGroupAssignmentCreate: UserGroupAssignmentCreate,
    ): Promise<UserGroupAssignment> {
        const userGroupAssignment = await this.wrapApiCall(
            this.userGroupAssignmentsApi.userGroupAssignmentsCreateUserGroupAssignment({
                userGroupAssignmentCreate,
            }),
        );
        await this.waitForIdle();
        runInAction(() => {
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(userGroupAssignment);
        });
        return userGroupAssignment;
    }

    public async delete(...userGroupAssignmentIds: UUID[]): Promise<void> {
        await this.wrapApiCall(
            Promise.all(
                userGroupAssignmentIds.map((userGroupAssignmentId) =>
                    this.userGroupAssignmentsApi.userGroupAssignmentsDeleteUserGroupAssignment({
                        userGroupAssignmentId,
                    }),
                ),
            ),
        );
        await this.waitForIdle();
        this.reloadQuery({ pageSize: defaultPageSize });
    }
}
