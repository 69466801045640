import * as React from "react";
import { IColumn, Stack } from "@fluentui/react";
import { omit } from "ramda";
import { action, autorun, computed, makeObservable, observable, runInAction } from "mobx";
import { external, initialize, inject } from "tsdi";
import { createUuid, UUID } from "../../utils/uuid";
import {
    ColumnVisibility,
    FleetAuthenticateEventsApi,
    FleetAuthenticatePeriod,
    FleetAuthenticatePeriodSortKey,
} from "../../api";
import { observer } from "mobx-react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { SortState } from "../../utils/sort-state";
import { PaginationState } from "../../utils/pagination-state";
import { LayoutList } from "../layouts/layout-list";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import { SearchState } from "../../utils/search-state";
import { ListSearchField } from "../atoms/list-search-field";
import { DatePicker } from "../organisms/date-picker";
import { endOfISOWeek, startOfISOWeek } from "date-fns";
import { ListLoginReportItemCell } from "../atoms/list-login-report-item-cell";
import {
    FleetAuthenticatePeriodApiResource,
    FleetAuthenticatePeriodQuery,
} from "../../domain/repositories/repository-fleet-authenticate-periods";
import { ExportButton, ExportQuery } from "./export-button";
import { RepositoryColumnVisibility } from "../../domain/repositories/repository-column-visibility";
import { defaultPageSize } from "../../utils/constants";

export interface ListLoginReportProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly commandBar?: JSX.Element;
    readonly listStateId?: UUID;
}

@external
@observer
export class ListLoginReport extends React.Component<ListLoginReportProps> {
    @inject private readonly fleetAuthenticateEventsApi!: FleetAuthenticateEventsApi;
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject private readonly repositoryColumnVisibility!: RepositoryColumnVisibility;

    private _listStateId = createUuid();

    @observable private periods?: FleetAuthenticatePeriod[];
    @observable private dateStart?: Date = startOfISOWeek(new Date());
    @observable private dateEnd?: Date = endOfISOWeek(new Date());

    constructor(props: ListLoginReportProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.fleetAuthenticatePeriod.initializeList(this.listStateId, {
            query: () => this.query,
        });
    }

    @computed private get listStateId(): UUID {
        return this.props.listStateId ?? this._listStateId;
    }

    @computed private get sortState(): SortState<FleetAuthenticatePeriodSortKey> {
        return this.serviceListStates.fleetAuthenticatePeriod.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<FleetAuthenticatePeriodApiResource> {
        return this.serviceListStates.fleetAuthenticatePeriod.getPaginationState(this.listStateId);
    }

    @computed private get searchState(): SearchState {
        return this.serviceListStates.fleetAuthenticatePeriod.getSearchState(this.listStateId);
    }

    @computed private get columnVisibility(): ColumnVisibility | undefined {
        return this.repositoryColumnVisibility.byQuery()[0];
    }

    @computed private get query(): FleetAuthenticatePeriodQuery {
        // We need to set the page number here which was set by the mobx repository originally.
        const page = this.paginationState.pagination.offset / defaultPageSize;
        const query = {
            pageSize: defaultPageSize,
            ...this.sortState.query,
            ...this.searchState.query,
            page,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
        } as FleetAuthenticatePeriodQuery;

        return query;
    }

    @computed private get exportQuery(): ExportQuery {
        const query = Object.entries(this.query);
        return { query, basePath: "/api/v1/fleet_authenticate_events/export?" };
    }

    @computed private get columns(): IColumn[] {
        const columns: IColumn[] = [];

        if (this.columnVisibility?.fleetAuthenticatePeriodList.eventId) {
            columns.push({
                name: this.i18n.t("listLoginReport.column.eventId.name"),
                key: "eventId",
                minWidth: 90,
                maxWidth: 150,
            });
        }
        if (this.columnVisibility?.fleetAuthenticatePeriodList.userEmployeeId) {
            columns.push({
                name: this.i18n.t("listLoginReport.column.user.employeeId.name"),
                key: "userEmployeeId",
                minWidth: 100,
                maxWidth: 150,
            });
        }
        if (this.columnVisibility?.fleetAuthenticatePeriodList.vehicleSerialNumber) {
            columns.push({
                name: this.i18n.t("listLoginReport.column.vehicle.serialNumber.name"),
                key: "vehicleSerialNumber",
                minWidth: 90,
                maxWidth: 150,
            });
        }
        columns.push({
            name: this.i18n.t("listLoginReport.column.loggedInTime.name"),
            key: "loggedInTime",
            minWidth: 100,
            maxWidth: 150,
        });
        columns.push({
            name: this.i18n.t("listLoginReport.column.loggedOutTime.name"),
            key: "loggedOutTime",
            minWidth: 100,
            maxWidth: 150,
        });
        columns.push({
            name: this.i18n.t("listLoginReport.column.loginDuration.name"),
            key: "loginDuration",
            minWidth: 100,
            maxWidth: 150,
        });
        columns.push({
            name: this.i18n.t("listLoginReport.column.logoutType.name"),
            key: "logoutType",
            minWidth: 100,
            maxWidth: 150,
        });

        return columns;
    }

    private renderItem(item: FleetAuthenticatePeriod, column: IColumn): JSX.Element {
        return <ListLoginReportItemCell period={item} column={column} />;
    }

    @action.bound public onDateRangeChanged(dateStart?: Date, dateEnd?: Date): void {
        this.dateStart = dateStart;
        this.dateEnd = dateEnd;
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.fleetAuthenticatePeriod.isInitialized(this.listStateId)) {
            return <></>;
        }
        if (this.periods === undefined) {
            this.periods = [];
            autorun(() => {
                this.fleetAuthenticateEventsApi
                    .fleetAuthenticateEventsListFleetAuthenticatePeriods({ ...this.query })
                    .then((result) => {
                        runInAction(() => {
                            this.periods = result;
                        });
                    });
            });
        }

        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(["ids"], this.props);
        return (
            <LayoutList
                paginationState={this.paginationState}
                commandBar={
                    <Stack horizontal horizontalAlign="space-between">
                        {this.props.commandBar}
                        <ExportButton query={this.exportQuery} />
                    </Stack>
                }
                searchField={
                    <ListSearchField
                        placeholder={this.i18n.t("listLoginReport.search.placeholder")}
                        listStateId={this.listStateId}
                        listState={this.serviceListStates.fleetAuthenticatePeriod}
                    />
                }
                filter={
                    <Stack
                        horizontal
                        style={{ width: "100%" }}
                        horizontalAlign="end"
                        verticalAlign="center"
                        tokens={{ childrenGap: 20 }}
                    >
                        <DatePicker onDateRangeChanged={this.onDateRangeChanged} />
                    </Stack>
                }
            >
                <ElofleetList
                    {...props}
                    onRenderItemColumn={(item: FleetAuthenticatePeriod, _index, column) =>
                        this.renderItem(item, column!)
                    }
                    onColumnHeaderClick={this.sortState.toggleColumn}
                    columns={this.sortState.patchColumns(this.columns)}
                    items={this.periods}
                />
            </LayoutList>
        );
    }
}
