/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AuthError {
    TOKEN_EXPIRED = 'tokenExpired',
    TOKEN_NOT_FOUND = 'tokenNotFound',
    BEARER_TOKEN_MISSING = 'bearerTokenMissing',
    AUTHORIZATION_HEADER_MISSING = 'authorizationHeaderMissing',
    CREDENTIALS_MISSING = 'credentialsMissing',
    USER_NOT_FOUND_BY_NFC = 'userNotFoundByNfc',
    USER_NOT_FOUND_BY_EMAIL = 'userNotFoundByEmail',
    USER_NOT_FOUND_IN_TOKEN = 'userNotFoundInToken',
    FLEET_ROLE_MISSING = 'fleetRoleMissing',
    PASSWORD_MISSING = 'passwordMissing',
    INVALID_PASSWORD = 'invalidPassword',
    SIGN_IN_BY_EMAIL_NOT_ALLOWED = 'signInByEmailNotAllowed',
    EMAIL_TOKEN_NOT_FOUND = 'emailTokenNotFound',
    EMAIL_TOKEN_EXPIRED = 'emailTokenExpired',
    VEHICLE_DEVICE_NOT_FOUND = 'vehicleDeviceNotFound',
    UNKNOWN = 'unknown'
}


export function AuthErrorFromJSON(json: any): AuthError {
    return AuthErrorFromJSONTyped(json, false);
}

export function AuthErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthError {
    return json as AuthError;
}

export function AuthErrorToJSON(value?: AuthError | null): any {
    return value as any;
}

