/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NewsCategory {
    IMPROVEMENT = 'improvement',
    MOBILE = 'mobile',
    NEW = 'new',
    WEB = 'web'
}


export function NewsCategoryFromJSON(json: any): NewsCategory {
    return NewsCategoryFromJSONTyped(json, false);
}

export function NewsCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsCategory {
    return json as NewsCategory;
}

export function NewsCategoryToJSON(value?: NewsCategory | null): any {
    return value as any;
}

