/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FleetAuthenticatePeriodList } from './FleetAuthenticatePeriodList';
import {
    FleetAuthenticatePeriodListFromJSON,
    FleetAuthenticatePeriodListFromJSONTyped,
    FleetAuthenticatePeriodListToJSON,
} from './FleetAuthenticatePeriodList';
import type { MaintenanceList } from './MaintenanceList';
import {
    MaintenanceListFromJSON,
    MaintenanceListFromJSONTyped,
    MaintenanceListToJSON,
} from './MaintenanceList';
import type { PreOpsAnswerList } from './PreOpsAnswerList';
import {
    PreOpsAnswerListFromJSON,
    PreOpsAnswerListFromJSONTyped,
    PreOpsAnswerListToJSON,
} from './PreOpsAnswerList';
import type { ShockEventList } from './ShockEventList';
import {
    ShockEventListFromJSON,
    ShockEventListFromJSONTyped,
    ShockEventListToJSON,
} from './ShockEventList';
import type { UserGroupDetails } from './UserGroupDetails';
import {
    UserGroupDetailsFromJSON,
    UserGroupDetailsFromJSONTyped,
    UserGroupDetailsToJSON,
} from './UserGroupDetails';
import type { UserList } from './UserList';
import {
    UserListFromJSON,
    UserListFromJSONTyped,
    UserListToJSON,
} from './UserList';
import type { UserProductivityList } from './UserProductivityList';
import {
    UserProductivityListFromJSON,
    UserProductivityListFromJSONTyped,
    UserProductivityListToJSON,
} from './UserProductivityList';
import type { VehicleList } from './VehicleList';
import {
    VehicleListFromJSON,
    VehicleListFromJSONTyped,
    VehicleListToJSON,
} from './VehicleList';
import type { VehicleMovementPeriodList } from './VehicleMovementPeriodList';
import {
    VehicleMovementPeriodListFromJSON,
    VehicleMovementPeriodListFromJSONTyped,
    VehicleMovementPeriodListToJSON,
} from './VehicleMovementPeriodList';
import type { VehicleProductivityList } from './VehicleProductivityList';
import {
    VehicleProductivityListFromJSON,
    VehicleProductivityListFromJSONTyped,
    VehicleProductivityListToJSON,
} from './VehicleProductivityList';

/**
 * Settings for which columns should be visible in the frontend. This is a "singleton": there's only a single row on each instance. The backend does not create or delete rows, it only updates this one row.
 * @export
 * @interface ColumnVisibility
 */
export interface ColumnVisibility {
    /**
     * 
     * @type {string}
     * @memberof ColumnVisibility
     */
    id: string;
    /**
     * 
     * @type {UserList}
     * @memberof ColumnVisibility
     */
    userList: UserList;
    /**
     * 
     * @type {UserGroupDetails}
     * @memberof ColumnVisibility
     */
    userGroupDetails: UserGroupDetails;
    /**
     * 
     * @type {UserProductivityList}
     * @memberof ColumnVisibility
     */
    userProductivityList: UserProductivityList;
    /**
     * 
     * @type {VehicleList}
     * @memberof ColumnVisibility
     */
    vehicleList: VehicleList;
    /**
     * 
     * @type {VehicleProductivityList}
     * @memberof ColumnVisibility
     */
    vehicleProductivityList: VehicleProductivityList;
    /**
     * 
     * @type {VehicleMovementPeriodList}
     * @memberof ColumnVisibility
     */
    vehicleMovementPeriodList: VehicleMovementPeriodList;
    /**
     * 
     * @type {MaintenanceList}
     * @memberof ColumnVisibility
     */
    maintenanceList: MaintenanceList;
    /**
     * 
     * @type {FleetAuthenticatePeriodList}
     * @memberof ColumnVisibility
     */
    fleetAuthenticatePeriodList: FleetAuthenticatePeriodList;
    /**
     * 
     * @type {ShockEventList}
     * @memberof ColumnVisibility
     */
    shockEventList: ShockEventList;
    /**
     * 
     * @type {PreOpsAnswerList}
     * @memberof ColumnVisibility
     */
    preOpsAnswerList: PreOpsAnswerList;
}

/**
 * Check if a given object implements the ColumnVisibility interface.
 */
export function instanceOfColumnVisibility(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userList" in value;
    isInstance = isInstance && "userGroupDetails" in value;
    isInstance = isInstance && "userProductivityList" in value;
    isInstance = isInstance && "vehicleList" in value;
    isInstance = isInstance && "vehicleProductivityList" in value;
    isInstance = isInstance && "vehicleMovementPeriodList" in value;
    isInstance = isInstance && "maintenanceList" in value;
    isInstance = isInstance && "fleetAuthenticatePeriodList" in value;
    isInstance = isInstance && "shockEventList" in value;
    isInstance = isInstance && "preOpsAnswerList" in value;

    return isInstance;
}

export function ColumnVisibilityFromJSON(json: any): ColumnVisibility {
    return ColumnVisibilityFromJSONTyped(json, false);
}

export function ColumnVisibilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnVisibility {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userList': UserListFromJSON(json['userList']),
        'userGroupDetails': UserGroupDetailsFromJSON(json['userGroupDetails']),
        'userProductivityList': UserProductivityListFromJSON(json['userProductivityList']),
        'vehicleList': VehicleListFromJSON(json['vehicleList']),
        'vehicleProductivityList': VehicleProductivityListFromJSON(json['vehicleProductivityList']),
        'vehicleMovementPeriodList': VehicleMovementPeriodListFromJSON(json['vehicleMovementPeriodList']),
        'maintenanceList': MaintenanceListFromJSON(json['maintenanceList']),
        'fleetAuthenticatePeriodList': FleetAuthenticatePeriodListFromJSON(json['fleetAuthenticatePeriodList']),
        'shockEventList': ShockEventListFromJSON(json['shockEventList']),
        'preOpsAnswerList': PreOpsAnswerListFromJSON(json['preOpsAnswerList']),
    };
}

export function ColumnVisibilityToJSON(value?: ColumnVisibility | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userList': UserListToJSON(value.userList),
        'userGroupDetails': UserGroupDetailsToJSON(value.userGroupDetails),
        'userProductivityList': UserProductivityListToJSON(value.userProductivityList),
        'vehicleList': VehicleListToJSON(value.vehicleList),
        'vehicleProductivityList': VehicleProductivityListToJSON(value.vehicleProductivityList),
        'vehicleMovementPeriodList': VehicleMovementPeriodListToJSON(value.vehicleMovementPeriodList),
        'maintenanceList': MaintenanceListToJSON(value.maintenanceList),
        'fleetAuthenticatePeriodList': FleetAuthenticatePeriodListToJSON(value.fleetAuthenticatePeriodList),
        'shockEventList': ShockEventListToJSON(value.shockEventList),
        'preOpsAnswerList': PreOpsAnswerListToJSON(value.preOpsAnswerList),
    };
}

