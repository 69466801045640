/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FleetRole } from './FleetRole';
import {
    FleetRoleFromJSON,
    FleetRoleFromJSONTyped,
    FleetRoleToJSON,
} from './FleetRole';
import type { ManagementRole } from './ManagementRole';
import {
    ManagementRoleFromJSON,
    ManagementRoleFromJSONTyped,
    ManagementRoleToJSON,
} from './ManagementRole';

/**
 * 
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
    /**
     * Personal Number. Needs to be unique. Between 1 and 100 characters.
     * @type {string}
     * @memberof UserCreate
     */
    employeeId: string;
    /**
     * Password. Between 10 and 100 characters.
     * @type {string}
     * @memberof UserCreate
     */
    password?: string | null;
    /**
     * First name. Between 1 and 100 characters.
     * @type {string}
     * @memberof UserCreate
     */
    firstName: string;
    /**
     * Last name. Between 1 and 100 characters.
     * @type {string}
     * @memberof UserCreate
     */
    lastName: string;
    /**
     * Email address. Needs to be unique. Between 5 and 32 characters.
     * @type {string}
     * @memberof UserCreate
     */
    email?: string | null;
    /**
     * 
     * @type {ManagementRole}
     * @memberof UserCreate
     */
    managementRole?: ManagementRole | null;
    /**
     * 
     * @type {FleetRole}
     * @memberof UserCreate
     */
    fleetRole?: FleetRole | null;
    /**
     * Free form field for describing the user's job.
     * @type {string}
     * @memberof UserCreate
     */
    jobTitle?: string | null;
    /**
     * Department Id. A unique identifier from departments table
     * @type {string}
     * @memberof UserCreate
     */
    departmentId?: string | null;
    /**
     * Shift Id. A unique identifier from shifts table
     * @type {string}
     * @memberof UserCreate
     */
    shiftId?: string | null;
    /**
     * Site Id. A unique identifier from sites table
     * @type {string}
     * @memberof UserCreate
     */
    siteId?: string | null;
    /**
     * License Expiry Date.
     * @type {Date}
     * @memberof UserCreate
     */
    licenseExpiryDate?: Date | null;
    /**
     * Expiry Logout.
     * @type {boolean}
     * @memberof UserCreate
     */
    expiryLogout: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    nfcToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    nfcTokenDescription?: string | null;
}

/**
 * Check if a given object implements the UserCreate interface.
 */
export function instanceOfUserCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "employeeId" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "expiryLogout" in value;

    return isInstance;
}

export function UserCreateFromJSON(json: any): UserCreate {
    return UserCreateFromJSONTyped(json, false);
}

export function UserCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'managementRole': !exists(json, 'managementRole') ? undefined : ManagementRoleFromJSON(json['managementRole']),
        'fleetRole': !exists(json, 'fleetRole') ? undefined : FleetRoleFromJSON(json['fleetRole']),
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'shiftId': !exists(json, 'shiftId') ? undefined : json['shiftId'],
        'siteId': !exists(json, 'siteId') ? undefined : json['siteId'],
        'licenseExpiryDate': !exists(json, 'licenseExpiryDate') ? undefined : (json['licenseExpiryDate'] === null ? null : new Date(json['licenseExpiryDate'])),
        'expiryLogout': json['expiryLogout'],
        'nfcToken': !exists(json, 'nfcToken') ? undefined : json['nfcToken'],
        'nfcTokenDescription': !exists(json, 'nfcTokenDescription') ? undefined : json['nfcTokenDescription'],
    };
}

export function UserCreateToJSON(value?: UserCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeId': value.employeeId,
        'password': value.password,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'managementRole': ManagementRoleToJSON(value.managementRole),
        'fleetRole': FleetRoleToJSON(value.fleetRole),
        'jobTitle': value.jobTitle,
        'departmentId': value.departmentId,
        'shiftId': value.shiftId,
        'siteId': value.siteId,
        'licenseExpiryDate': value.licenseExpiryDate === undefined ? undefined : (value.licenseExpiryDate === null ? null : value.licenseExpiryDate.toISOString()),
        'expiryLogout': value.expiryLogout,
        'nfcToken': value.nfcToken,
        'nfcTokenDescription': value.nfcTokenDescription,
    };
}

