/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PageInfo,
  Site,
  SiteCreate,
  SiteSortKey,
  SiteUpdate,
  SortDirection,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    SiteFromJSON,
    SiteToJSON,
    SiteCreateFromJSON,
    SiteCreateToJSON,
    SiteSortKeyFromJSON,
    SiteSortKeyToJSON,
    SiteUpdateFromJSON,
    SiteUpdateToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface SitesCreateSiteRequest {
    siteCreate: SiteCreate;
}

export interface SitesDeleteSiteRequest {
    siteId: string;
}

export interface SitesListSitesRequest {
    pageSize: number;
    search?: string | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: SiteSortKey;
}

export interface SitesListSitesMetadataRequest {
    pageSize: number;
    search?: string | null;
}

export interface SitesReadSiteRequest {
    siteId: string;
}

export interface SitesUpdateSiteRequest {
    siteId: string;
    siteUpdate: SiteUpdate;
}

export interface SitesValidateCreateSiteRequest {
    siteCreate: SiteCreate;
}

export interface SitesValidateUpdateSiteRequest {
    siteId: string;
    siteUpdate: SiteUpdate;
}

/**
 * SitesApi - interface
 * 
 * @export
 * @interface SitesApiInterface
 */
export interface SitesApiInterface {
    /**
     * Create a new site.
     * @summary Create a site
     * @param {SiteCreate} siteCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    sitesCreateSiteRaw(requestParameters: SitesCreateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Site>>;

    /**
     * Create a new site.
     * Create a site
     */
    sitesCreateSite(requestParameters: SitesCreateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Site>;

    /**
     * Delete the site with the given ID.
     * @summary Delete a site
     * @param {string} siteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    sitesDeleteSiteRaw(requestParameters: SitesDeleteSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the site with the given ID.
     * Delete a site
     */
    sitesDeleteSite(requestParameters: SitesDeleteSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Lists all the sites present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * @summary List sites
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {SiteSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    sitesListSitesRaw(requestParameters: SitesListSitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Site>>>;

    /**
     * Lists all the sites present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List sites
     */
    sitesListSites(requestParameters: SitesListSitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Site>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary Site listing metadata
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    sitesListSitesMetadataRaw(requestParameters: SitesListSitesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * Site listing metadata
     */
    sitesListSitesMetadata(requestParameters: SitesListSitesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the site specified by the given ID.
     * @summary Get a single site
     * @param {string} siteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    sitesReadSiteRaw(requestParameters: SitesReadSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Site>>;

    /**
     * Returns the site specified by the given ID.
     * Get a single site
     */
    sitesReadSite(requestParameters: SitesReadSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Site>;

    /**
     * Update the site specified by the given ID.
     * @summary Update site
     * @param {string} siteId 
     * @param {SiteUpdate} siteUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    sitesUpdateSiteRaw(requestParameters: SitesUpdateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Site>>;

    /**
     * Update the site specified by the given ID.
     * Update site
     */
    sitesUpdateSite(requestParameters: SitesUpdateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Site>;

    /**
     * Validate data for a new site without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a site
     * @param {SiteCreate} siteCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    sitesValidateCreateSiteRaw(requestParameters: SitesValidateCreateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new site without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a site
     */
    sitesValidateCreateSite(requestParameters: SitesValidateCreateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate data for updating the given site, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate site update
     * @param {string} siteId 
     * @param {SiteUpdate} siteUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    sitesValidateUpdateSiteRaw(requestParameters: SitesValidateUpdateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for updating the given site, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate site update
     */
    sitesValidateUpdateSite(requestParameters: SitesValidateUpdateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class SitesApi extends runtime.BaseAPI implements SitesApiInterface {

    /**
     * Create a new site.
     * Create a site
     */
    async sitesCreateSiteRaw(requestParameters: SitesCreateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Site>> {
        if (requestParameters.siteCreate === null || requestParameters.siteCreate === undefined) {
            throw new runtime.RequiredError('siteCreate','Required parameter requestParameters.siteCreate was null or undefined when calling sitesCreateSite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SiteCreateToJSON(requestParameters.siteCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiteFromJSON(jsonValue));
    }

    /**
     * Create a new site.
     * Create a site
     */
    async sitesCreateSite(requestParameters: SitesCreateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Site> {
        const response = await this.sitesCreateSiteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the site with the given ID.
     * Delete a site
     */
    async sitesDeleteSiteRaw(requestParameters: SitesDeleteSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling sitesDeleteSite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites/{site_id}`.replace(`{${"site_id"}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the site with the given ID.
     * Delete a site
     */
    async sitesDeleteSite(requestParameters: SitesDeleteSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sitesDeleteSiteRaw(requestParameters, initOverrides);
    }

    /**
     * Lists all the sites present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List sites
     */
    async sitesListSitesRaw(requestParameters: SitesListSitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Site>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling sitesListSites.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SiteFromJSON));
    }

    /**
     * Lists all the sites present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List sites
     */
    async sitesListSites(requestParameters: SitesListSitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Site>> {
        const response = await this.sitesListSitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * Site listing metadata
     */
    async sitesListSitesMetadataRaw(requestParameters: SitesListSitesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling sitesListSitesMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * Site listing metadata
     */
    async sitesListSitesMetadata(requestParameters: SitesListSitesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.sitesListSitesMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the site specified by the given ID.
     * Get a single site
     */
    async sitesReadSiteRaw(requestParameters: SitesReadSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Site>> {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling sitesReadSite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites/{site_id}`.replace(`{${"site_id"}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiteFromJSON(jsonValue));
    }

    /**
     * Returns the site specified by the given ID.
     * Get a single site
     */
    async sitesReadSite(requestParameters: SitesReadSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Site> {
        const response = await this.sitesReadSiteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the site specified by the given ID.
     * Update site
     */
    async sitesUpdateSiteRaw(requestParameters: SitesUpdateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Site>> {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling sitesUpdateSite.');
        }

        if (requestParameters.siteUpdate === null || requestParameters.siteUpdate === undefined) {
            throw new runtime.RequiredError('siteUpdate','Required parameter requestParameters.siteUpdate was null or undefined when calling sitesUpdateSite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites/{site_id}`.replace(`{${"site_id"}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SiteUpdateToJSON(requestParameters.siteUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiteFromJSON(jsonValue));
    }

    /**
     * Update the site specified by the given ID.
     * Update site
     */
    async sitesUpdateSite(requestParameters: SitesUpdateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Site> {
        const response = await this.sitesUpdateSiteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new site without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a site
     */
    async sitesValidateCreateSiteRaw(requestParameters: SitesValidateCreateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.siteCreate === null || requestParameters.siteCreate === undefined) {
            throw new runtime.RequiredError('siteCreate','Required parameter requestParameters.siteCreate was null or undefined when calling sitesValidateCreateSite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SiteCreateToJSON(requestParameters.siteCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new site without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a site
     */
    async sitesValidateCreateSite(requestParameters: SitesValidateCreateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sitesValidateCreateSiteRaw(requestParameters, initOverrides);
    }

    /**
     * Validate data for updating the given site, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate site update
     */
    async sitesValidateUpdateSiteRaw(requestParameters: SitesValidateUpdateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling sitesValidateUpdateSite.');
        }

        if (requestParameters.siteUpdate === null || requestParameters.siteUpdate === undefined) {
            throw new runtime.RequiredError('siteUpdate','Required parameter requestParameters.siteUpdate was null or undefined when calling sitesValidateUpdateSite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sites/{site_id}/validate`.replace(`{${"site_id"}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SiteUpdateToJSON(requestParameters.siteUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for updating the given site, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate site update
     */
    async sitesValidateUpdateSite(requestParameters: SitesValidateUpdateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sitesValidateUpdateSiteRaw(requestParameters, initOverrides);
    }

}
