import * as React from "react";
import { observer } from "mobx-react";
import { Stack, TextField, DefaultButton, Separator, IColumn } from "@fluentui/react";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { VehicleGroup, UserGroup, VehicleGroupUpdate } from "../../api";

import { ServiceListStates } from "../../domain/services/service-list-states";

import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import { FormUpdateProps } from "../../utils/form-update-props";
import { ModalConfirmation } from "../atoms/modal-confirmation";
import { MultiSelectWithList } from "../organisms/multi-select-with-list";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";

import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { RepositoryVehicleGroups } from "../../domain/repositories/repository-vehicle-groups";
import { RepositoryUserGroups } from "../../domain/repositories/repository-user-groups";
import { RepositoryVehicles } from "../../domain/repositories/repository-vehicles";

import { createUuid, UUID } from "../../utils/uuid";
import { doubleBindString } from "../../utils/double-bind";
import { compareSets } from "../../utils/set-comparison";
import sizes from "../sizes.scss";
import { defaultPageSize } from "../../utils/constants";

export interface FormUpdateVehicleGroupProps extends FormUpdateProps<VehicleGroup> {}

@observer
@external
export class FormUpdateVehicleGroup extends React.Component<FormUpdateVehicleGroupProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;
    @inject private readonly repositoryVehicleGroups!: RepositoryVehicleGroups;
    @inject private readonly repositoryUserGroups!: RepositoryUserGroups;
    @inject private readonly repositoryDepartments!: RepositoryDepartments;

    @observable private cancelConfirmationModalVisible = false;
    // This list contains the ids of the assignments that already exist for this group.
    // I.e. the Vehicles and UserGroups that have been selected for assignments.
    @observable private originalVehicles: UUID[] = [];
    @observable private originalUserGroups: UUID[] = [];

    // These lists contain the ids of the assignments that're currently selected in the
    // multi-select dropdown.
    //
    // By comparing these with the original lists above, we can determine the changeset
    // of removed and added entities.
    @observable private selectedVehicles: UUID[] = [];
    @observable private selectedUserGroups: UUID[] = [];

    // The list state ids for the respective lists of added groups.
    private vehicleListStateId = createUuid();
    private userGroupListStateId = createUuid();

    private validationId = createUuid();

    constructor(props: FormUpdateVehicleGroupProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositoryVehicleGroups.validation.update.updateModel(
            this.validationId,
            this.vehicleGroupUpdate,
        );
    }

    @initialize protected initialize(): void {
        this.serviceListStates.vehicles.initializeList(this.vehicleListStateId, {
            query: () => {
                return {
                    pageSize: defaultPageSize,
                };
            },
            ignoreUrl: true,
        });
        this.serviceListStates.userGroups.initializeList(this.userGroupListStateId, {
            query: () => {
                return {
                    pageSize: defaultPageSize,
                };
            },
            ignoreUrl: true,
        });

        this.repositoryVehicleGroups.validation.update.initializeModel(
            this.validationId,
            this.vehicleGroupUpdate,
            this.props.id,
        );

        this.getInitialAssignments();
    }

    public componentWillUnmount(): void {
        this.repositoryVehicleGroups.discardMutableCopy(FormUpdateVehicleGroup.name, this.props.id);
    }

    /**
     * Get the Vehicles and UserGroups that're already assigned to this group.
     * This is done asynchronous once when initializing this component.
     *
     * Marking this function with @action.bound doesn't work as it's `async`.
     */
    async getInitialAssignments(): Promise<void> {
        // Get the list of all assigned user groups
        const userGroups = await this.repositoryUserGroups.byQueryAsync({
            pageSize: defaultPageSize,
            vehicleGroupIds: [this.props.id],
        });
        this.originalUserGroups = userGroups.map((userGroup) => userGroup.id);

        const vehicles = await this.repositoryVehicles.byQueryAsync({
            pageSize: defaultPageSize,
            vehicleGroupIds: [this.props.id],
        });
        this.originalVehicles = vehicles.map((userGroup) => userGroup.id);

        runInAction(() => {
            this.selectedVehicles = this.originalVehicles;
            this.selectedUserGroups = this.originalUserGroups;
        });
    }

    @computed private get vehicleGroup(): VehicleGroup | undefined {
        return this.repositoryVehicleGroups.mutableCopyById(
            FormUpdateVehicleGroup.name,
            this.props.id,
        );
    }

    @computed private get vehicleGroupUpdate(): VehicleGroupUpdate {
        const userGroupChanges = compareSets(this.originalUserGroups, this.selectedUserGroups);
        const vehicleChanges = compareSets(this.originalVehicles, this.selectedVehicles);

        const update = {
            label: this.vehicleGroup?.label,
            addUserGroupIds: userGroupChanges.added,
            removeUserGroupIds: userGroupChanges.removed,
            addVehicleIds: vehicleChanges.added,
            removeVehicleIds: vehicleChanges.removed,
        } as VehicleGroupUpdate;

        return update;
    }

    @action.bound
    private async updateVehicleGroup(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const update = this.vehicleGroupUpdate;
        const vehicleGroup = await this.repositoryVehicleGroups.update(this.props.id, update);

        // Reload Queries in here.
        // This cannot be done in the repositories itself, as they would have circular
        // dependencies on each other otherwise.
        if (update.addUserGroupIds || update.removeUserGroupIds) {
            this.repositoryUserGroups.reloadQuery({ pageSize: defaultPageSize });
            this.repositoryUserGroups.reloadQuery({
                pageSize: defaultPageSize,
                vehicleGroupIds: [this.props.id],
            });
        }
        if (update.addVehicleIds || update.removeVehicleIds) {
            this.repositoryVehicles.reloadQuery({
                pageSize: defaultPageSize,
                vehicleGroupIds: [this.props.id],
            });
        }

        this.reset();
        this.props.onUpdate(vehicleGroup);
    }

    /**
     * This is run if the user clicks the form's cancel button.
     * A confirmation modal might pop up if there are any changes.
     */
    @action.bound private showConfirmAndCallDialogCancelCallback(): void {
        // Compare the assignments
        const userGroupChanges = compareSets(this.originalUserGroups, this.selectedUserGroups);
        const vehicleChanges = compareSets(this.originalVehicles, this.selectedVehicles);

        // If there were any changes in assignments, ask the user if they really want to cancel.
        if (userGroupChanges.differs() || vehicleChanges.differs()) {
            this.cancelConfirmationModalVisible = true;
            return;
        }

        this.props.onDialogCancel();
    }

    /**
     * Actually close the component and reset all state.
     */
    @action.bound private closeForm(): void {
        this.reset();
        this.cancelConfirmationModalVisible = false;
        this.props.onDialogCancel();
    }

    @action.bound private reset(): void {
        this.originalVehicles = [];
        this.originalUserGroups = [];
        this.selectedVehicles = [];
        this.selectedUserGroups = [];
    }

    @action.bound private closeCancelConfirmationModalVisible(): void {
        this.cancelConfirmationModalVisible = false;
    }

    /**
     * Handle the actual addition of all currently selected Vehicles in the multi-select field.
     */
    @action.bound private addVehicles(vehicles: UUID[]): void {
        this.selectedVehicles = [...this.selectedVehicles, ...vehicles];
    }

    /**
     * Handle the removal of Vehicles.
     * In this case, removal means that they'll be removed from the list of added Vehicles.
     */
    @action.bound private removeVehicles(idsToRemove: UUID[]): void {
        const remainingVehicles = this.selectedVehicles.filter((id) => !idsToRemove.includes(id));
        this.selectedVehicles = remainingVehicles;
    }

    /**
     * Handle the actual addition of all currently selected UserGroups in the multi-select field.
     */
    @action.bound private addUserGroups(userGroups: UUID[]): void {
        this.selectedUserGroups = [...this.selectedUserGroups, ...userGroups];
    }

    /**
     * Handle the removal of UserGroups.
     * In this case, removal means that they'll be removed from the list of added UserGroups.
     */
    @action.bound private removeUserGroups(idsToRemove: UUID[]): void {
        const remainingGroups = this.selectedUserGroups.filter((id) => !idsToRemove.includes(id));
        this.selectedUserGroups = remainingGroups;
    }

    /**
     * The items that should be shown in the list of added UserGroups.
     */
    @computed private get userGroupItems(): Object[] {
        const groups = [];

        for (const id of this.selectedUserGroups) {
            const group = this.repositoryUserGroups.byId(id);
            if (group !== undefined) {
                groups.push({
                    key: group.id,
                    label: group.label,
                    assignedUserCount: group.assignedUserCount,
                    assignedVehicleGroupCount: group.assignedVehicleGroupCount,
                });
            }
        }

        return groups;
    }

    /**
     * The columns that should be shown in the list of selected UserGroups.
     */
    @computed private get userGroupColumns(): IColumn[] {
        return [
            {
                fieldName: "label",
                name: this.i18n.t("listUserGroups.column.label.name"),
                key: "label",
                minWidth: 100,
            },
            {
                fieldName: "assignedUserCount",
                name: this.i18n.t("listUserGroups.column.assignedUserCount.name"),
                key: "assignedUserCount",
                minWidth: 80,
            },
            {
                fieldName: "assignedVehicleGroupCount",
                name: this.i18n.t("listUserGroups.column.assignedVehicleGroupCount.name"),
                key: "assignedVehicleGroupCount",
                minWidth: 150,
            },
        ];
    }

    /**
     * The items that should be shown in the list of added Vehicles.
     */
    @computed private get vehicleItems(): Object[] {
        const vehicles = [];

        for (const id of this.selectedVehicles) {
            const vehicle = this.repositoryVehicles.byId(id);
            if (vehicle === undefined) {
                continue;
            }

            const department =
                typeof vehicle.departmentId === "string"
                    ? this.repositoryDepartments.byId(vehicle.departmentId)
                    : undefined;
            vehicles.push({
                key: vehicle.id,
                serialNumber: vehicle.serialNumber,
                manufacturer: vehicle.manufacturer,
                model: vehicle.model,
                department: department?.label ?? "",
                vehicleStatus: this.i18n.formatVehicleStatus(vehicle.status),
            });
        }

        return vehicles;
    }

    /**
     * The columns that should be shown in the list of selected Vehicles.
     */
    @computed private get vehicleColumns(): IColumn[] {
        return [
            {
                fieldName: "serialNumber",
                name: this.i18n.t("listVehicles.column.serialNumber.name"),
                key: "serialNumber",
                minWidth: 200,
                maxWidth: 300,
            },
            {
                fieldName: "manufacturer",
                name: this.i18n.t("listVehicles.column.manufacturer.name"),
                key: "manufacturer",
                minWidth: 80,
                maxWidth: 100,
            },
            {
                fieldName: "model",
                name: this.i18n.t("listVehicles.column.model.name"),
                key: "model",
                minWidth: 100,
                maxWidth: 150,
            },
            {
                fieldName: "department",
                name: this.i18n.t("listVehicles.column.department.name"),
                key: "department",
                minWidth: 100,
                maxWidth: 150,
            },
            {
                fieldName: "vehicleStatus",
                name: this.i18n.t("listVehicles.column.vehicleStatus.name"),
                key: "vehicleStatus",
                minWidth: 100,
                maxWidth: 150,
            },
        ];
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formUpdateVehicleGroup.submit.text")}
                validation={this.repositoryVehicleGroups.validation.update}
                validationId={this.validationId}
            />
        );
        return (
            <form onSubmit={this.updateVehicleGroup}>
                <Stack tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                    <TextField
                        label={this.i18n.t("formUpdateVehicleGroup.label.label")}
                        disabled={!this.vehicleGroup}
                        {...doubleBindString(this.vehicleGroup!, "label", () =>
                            this.triggerValidation(),
                        )}
                        required
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicleGroups.validation.update.getFieldValidationState(
                                this.validationId,
                                "label",
                            ),
                        )}
                    />
                </Stack>
                <Stack
                    horizontal
                    styles={{
                        root: {
                            minWidth: "1200px",
                            minHeight: "400px",
                            padding: sizes.xl,
                        },
                    }}
                >
                    <Stack.Item tokens={{ padding: `0px 0px 0px ${sizes.m}` }}>
                        <MultiSelectWithList
                            repository={this.repositoryVehicles}
                            onAdd={this.addVehicles}
                            onDelete={this.removeVehicles}
                            listStateId={this.vehicleListStateId}
                            listState={this.serviceListStates.vehicles}
                            addedEntities={this.selectedVehicles}
                            columns={this.vehicleColumns}
                            items={this.vehicleItems}
                            formatEntity={this.i18n.formatVehicle}
                            addButtonText={this.i18n.t("component.multiSelect.addButton")}
                            removeButtonText={this.i18n.t("component.multiSelect.removeButton")}
                            dropdownLabel={this.i18n.t("formCreateVehicleGroup.vehicle.label")}
                            multiSelectPlaceholder={this.i18n.t(
                                "formCreateVehicleGroup.vehicle.placeholder",
                            )}
                        />
                    </Stack.Item>
                    <Stack.Item tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                        <Separator vertical />
                    </Stack.Item>
                    <Stack.Item tokens={{ padding: `0px ${sizes.m} 0px 0px` }}>
                        <MultiSelectWithList
                            repository={this.repositoryUserGroups}
                            onAdd={this.addUserGroups}
                            onDelete={this.removeUserGroups}
                            listStateId={this.userGroupListStateId}
                            listState={this.serviceListStates.userGroups}
                            addedEntities={this.selectedUserGroups}
                            columns={this.userGroupColumns}
                            items={this.userGroupItems}
                            formatEntity={(group: UserGroup) => group.label}
                            addButtonText={this.i18n.t("component.multiSelect.addButton")}
                            removeButtonText={this.i18n.t("component.multiSelect.removeButton")}
                            dropdownLabel={this.i18n.t("formCreateVehicleGroup.userGroup.label")}
                            multiSelectPlaceholder={this.i18n.t(
                                "formCreateVehicleGroup.userGroup.placeholder",
                            )}
                        />
                    </Stack.Item>
                </Stack>
                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            label={this.i18n.t("formUpdateVehicleGroup.cancel.label")}
                            text={this.i18n.t("formUpdateVehicleGroup.cancel.text")}
                            onClick={this.showConfirmAndCallDialogCancelCallback}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
                <ModalConfirmation
                    isOpen={this.cancelConfirmationModalVisible}
                    title={this.i18n.t("modalAbortUpdate.title")}
                    text={this.i18n.t("modalAbortUpdate.description")}
                    onConfirm={this.closeForm}
                    onCancel={this.closeCancelConfirmationModalVisible}
                />
            </form>
        );
    }
}
