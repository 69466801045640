/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleListUpdate
 */
export interface VehicleListUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleStatus?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleSerialNumber?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehiclePhoneImei?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleDeviceSerialNumber?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleManufacturer?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleModel?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleEngineType?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleManufactureYear?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleShockProfile?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehiclePreOpsChecklist?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleChecklistOverride?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleIdleAutoLogoutAfter?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleLastSynchronizedAt?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehiclePurchaseDateOrLeaseTermBegin?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleLeaseTermEnd?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleLeaseTermDaysRemaining?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleInitialOperatingHours?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleCurrentOperatingHours?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleLastMaintenanceOperatingHours?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleLastMaintenanceDate?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleNextMaintenanceHours?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleMaintenanceInterval?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleMaintenanceNotificationAt?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleMaintenanceLockdown?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleHoursUntilNextMaintenance?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleTypeLabel?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    departmentLabel?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    vehicleGroupLabel?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleListUpdate
     */
    userFullName?: boolean | null;
}

/**
 * Check if a given object implements the VehicleListUpdate interface.
 */
export function instanceOfVehicleListUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleListUpdateFromJSON(json: any): VehicleListUpdate {
    return VehicleListUpdateFromJSONTyped(json, false);
}

export function VehicleListUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleListUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicleStatus': !exists(json, 'vehicleStatus') ? undefined : json['vehicleStatus'],
        'vehicleSerialNumber': !exists(json, 'vehicleSerialNumber') ? undefined : json['vehicleSerialNumber'],
        'vehiclePhoneImei': !exists(json, 'vehiclePhoneImei') ? undefined : json['vehiclePhoneImei'],
        'vehicleDeviceSerialNumber': !exists(json, 'vehicleDeviceSerialNumber') ? undefined : json['vehicleDeviceSerialNumber'],
        'vehicleManufacturer': !exists(json, 'vehicleManufacturer') ? undefined : json['vehicleManufacturer'],
        'vehicleModel': !exists(json, 'vehicleModel') ? undefined : json['vehicleModel'],
        'vehicleEngineType': !exists(json, 'vehicleEngineType') ? undefined : json['vehicleEngineType'],
        'vehicleManufactureYear': !exists(json, 'vehicleManufactureYear') ? undefined : json['vehicleManufactureYear'],
        'vehicleShockProfile': !exists(json, 'vehicleShockProfile') ? undefined : json['vehicleShockProfile'],
        'vehiclePreOpsChecklist': !exists(json, 'vehiclePreOpsChecklist') ? undefined : json['vehiclePreOpsChecklist'],
        'vehicleChecklistOverride': !exists(json, 'vehicleChecklistOverride') ? undefined : json['vehicleChecklistOverride'],
        'vehicleIdleAutoLogoutAfter': !exists(json, 'vehicleIdleAutoLogoutAfter') ? undefined : json['vehicleIdleAutoLogoutAfter'],
        'vehicleLastSynchronizedAt': !exists(json, 'vehicleLastSynchronizedAt') ? undefined : json['vehicleLastSynchronizedAt'],
        'vehiclePurchaseDateOrLeaseTermBegin': !exists(json, 'vehiclePurchaseDateOrLeaseTermBegin') ? undefined : json['vehiclePurchaseDateOrLeaseTermBegin'],
        'vehicleLeaseTermEnd': !exists(json, 'vehicleLeaseTermEnd') ? undefined : json['vehicleLeaseTermEnd'],
        'vehicleLeaseTermDaysRemaining': !exists(json, 'vehicleLeaseTermDaysRemaining') ? undefined : json['vehicleLeaseTermDaysRemaining'],
        'vehicleInitialOperatingHours': !exists(json, 'vehicleInitialOperatingHours') ? undefined : json['vehicleInitialOperatingHours'],
        'vehicleCurrentOperatingHours': !exists(json, 'vehicleCurrentOperatingHours') ? undefined : json['vehicleCurrentOperatingHours'],
        'vehicleLastMaintenanceOperatingHours': !exists(json, 'vehicleLastMaintenanceOperatingHours') ? undefined : json['vehicleLastMaintenanceOperatingHours'],
        'vehicleLastMaintenanceDate': !exists(json, 'vehicleLastMaintenanceDate') ? undefined : json['vehicleLastMaintenanceDate'],
        'vehicleNextMaintenanceHours': !exists(json, 'vehicleNextMaintenanceHours') ? undefined : json['vehicleNextMaintenanceHours'],
        'vehicleMaintenanceInterval': !exists(json, 'vehicleMaintenanceInterval') ? undefined : json['vehicleMaintenanceInterval'],
        'vehicleMaintenanceNotificationAt': !exists(json, 'vehicleMaintenanceNotificationAt') ? undefined : json['vehicleMaintenanceNotificationAt'],
        'vehicleMaintenanceLockdown': !exists(json, 'vehicleMaintenanceLockdown') ? undefined : json['vehicleMaintenanceLockdown'],
        'vehicleHoursUntilNextMaintenance': !exists(json, 'vehicleHoursUntilNextMaintenance') ? undefined : json['vehicleHoursUntilNextMaintenance'],
        'vehicleTypeLabel': !exists(json, 'vehicleTypeLabel') ? undefined : json['vehicleTypeLabel'],
        'departmentLabel': !exists(json, 'departmentLabel') ? undefined : json['departmentLabel'],
        'vehicleGroupLabel': !exists(json, 'vehicleGroupLabel') ? undefined : json['vehicleGroupLabel'],
        'userFullName': !exists(json, 'userFullName') ? undefined : json['userFullName'],
    };
}

export function VehicleListUpdateToJSON(value?: VehicleListUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicleStatus': value.vehicleStatus,
        'vehicleSerialNumber': value.vehicleSerialNumber,
        'vehiclePhoneImei': value.vehiclePhoneImei,
        'vehicleDeviceSerialNumber': value.vehicleDeviceSerialNumber,
        'vehicleManufacturer': value.vehicleManufacturer,
        'vehicleModel': value.vehicleModel,
        'vehicleEngineType': value.vehicleEngineType,
        'vehicleManufactureYear': value.vehicleManufactureYear,
        'vehicleShockProfile': value.vehicleShockProfile,
        'vehiclePreOpsChecklist': value.vehiclePreOpsChecklist,
        'vehicleChecklistOverride': value.vehicleChecklistOverride,
        'vehicleIdleAutoLogoutAfter': value.vehicleIdleAutoLogoutAfter,
        'vehicleLastSynchronizedAt': value.vehicleLastSynchronizedAt,
        'vehiclePurchaseDateOrLeaseTermBegin': value.vehiclePurchaseDateOrLeaseTermBegin,
        'vehicleLeaseTermEnd': value.vehicleLeaseTermEnd,
        'vehicleLeaseTermDaysRemaining': value.vehicleLeaseTermDaysRemaining,
        'vehicleInitialOperatingHours': value.vehicleInitialOperatingHours,
        'vehicleCurrentOperatingHours': value.vehicleCurrentOperatingHours,
        'vehicleLastMaintenanceOperatingHours': value.vehicleLastMaintenanceOperatingHours,
        'vehicleLastMaintenanceDate': value.vehicleLastMaintenanceDate,
        'vehicleNextMaintenanceHours': value.vehicleNextMaintenanceHours,
        'vehicleMaintenanceInterval': value.vehicleMaintenanceInterval,
        'vehicleMaintenanceNotificationAt': value.vehicleMaintenanceNotificationAt,
        'vehicleMaintenanceLockdown': value.vehicleMaintenanceLockdown,
        'vehicleHoursUntilNextMaintenance': value.vehicleHoursUntilNextMaintenance,
        'vehicleTypeLabel': value.vehicleTypeLabel,
        'departmentLabel': value.departmentLabel,
        'vehicleGroupLabel': value.vehicleGroupLabel,
        'userFullName': value.userFullName,
    };
}

