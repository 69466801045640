/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PageInfo,
  PersistentNotification,
  PersistentNotificationUpdate,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    PersistentNotificationFromJSON,
    PersistentNotificationToJSON,
    PersistentNotificationUpdateFromJSON,
    PersistentNotificationUpdateToJSON,
} from '../models';

export interface PersistentNotificationsListPersistentNotificationsRequest {
    pageSize: number;
    page?: number | null;
}

export interface PersistentNotificationsListPersistentNotificationsMetadataRequest {
    pageSize: number;
}

export interface PersistentNotificationsReadPersistentNotificationRequest {
    notificationId: string;
}

export interface PersistentNotificationsUpdatePersistentNotificationRequest {
    notificationId: string;
    persistentNotificationUpdate: PersistentNotificationUpdate;
}

export interface PersistentNotificationsValidateUpdatePersistentNotificationRequest {
    notificationId: string;
    persistentNotificationUpdate: PersistentNotificationUpdate;
}

/**
 * PersistentNotificationsApi - interface
 * 
 * @export
 * @interface PersistentNotificationsApiInterface
 */
export interface PersistentNotificationsApiInterface {
    /**
     * Lists all of the current notifications.
     * @summary List notifications
     * @param {number} pageSize 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersistentNotificationsApiInterface
     */
    persistentNotificationsListPersistentNotificationsRaw(requestParameters: PersistentNotificationsListPersistentNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PersistentNotification>>>;

    /**
     * Lists all of the current notifications.
     * List notifications
     */
    persistentNotificationsListPersistentNotifications(requestParameters: PersistentNotificationsListPersistentNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PersistentNotification>>;

    /**
     * Fetch information about the total number of pages available.
     * @summary Notification listing metadata
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersistentNotificationsApiInterface
     */
    persistentNotificationsListPersistentNotificationsMetadataRaw(requestParameters: PersistentNotificationsListPersistentNotificationsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total number of pages available.
     * Notification listing metadata
     */
    persistentNotificationsListPersistentNotificationsMetadata(requestParameters: PersistentNotificationsListPersistentNotificationsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the persistent notification specified by the given ID.
     * @summary Get a single notification
     * @param {string} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersistentNotificationsApiInterface
     */
    persistentNotificationsReadPersistentNotificationRaw(requestParameters: PersistentNotificationsReadPersistentNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersistentNotification>>;

    /**
     * Returns the persistent notification specified by the given ID.
     * Get a single notification
     */
    persistentNotificationsReadPersistentNotification(requestParameters: PersistentNotificationsReadPersistentNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersistentNotification>;

    /**
     * Update the notification specified by the given ID.
     * @summary Update notification
     * @param {string} notificationId 
     * @param {PersistentNotificationUpdate} persistentNotificationUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersistentNotificationsApiInterface
     */
    persistentNotificationsUpdatePersistentNotificationRaw(requestParameters: PersistentNotificationsUpdatePersistentNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersistentNotification>>;

    /**
     * Update the notification specified by the given ID.
     * Update notification
     */
    persistentNotificationsUpdatePersistentNotification(requestParameters: PersistentNotificationsUpdatePersistentNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersistentNotification>;

    /**
     * Validate data for updating the given notification, without persisting it. Returns an empty body if the data is valid. Returns validation errors if it is invalid.
     * @summary Validate notification update
     * @param {string} notificationId 
     * @param {PersistentNotificationUpdate} persistentNotificationUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersistentNotificationsApiInterface
     */
    persistentNotificationsValidateUpdatePersistentNotificationRaw(requestParameters: PersistentNotificationsValidateUpdatePersistentNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for updating the given notification, without persisting it. Returns an empty body if the data is valid. Returns validation errors if it is invalid.
     * Validate notification update
     */
    persistentNotificationsValidateUpdatePersistentNotification(requestParameters: PersistentNotificationsValidateUpdatePersistentNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class PersistentNotificationsApi extends runtime.BaseAPI implements PersistentNotificationsApiInterface {

    /**
     * Lists all of the current notifications.
     * List notifications
     */
    async persistentNotificationsListPersistentNotificationsRaw(requestParameters: PersistentNotificationsListPersistentNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PersistentNotification>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling persistentNotificationsListPersistentNotifications.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persistent_notifications/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersistentNotificationFromJSON));
    }

    /**
     * Lists all of the current notifications.
     * List notifications
     */
    async persistentNotificationsListPersistentNotifications(requestParameters: PersistentNotificationsListPersistentNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PersistentNotification>> {
        const response = await this.persistentNotificationsListPersistentNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total number of pages available.
     * Notification listing metadata
     */
    async persistentNotificationsListPersistentNotificationsMetadataRaw(requestParameters: PersistentNotificationsListPersistentNotificationsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling persistentNotificationsListPersistentNotificationsMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persistent_notifications/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total number of pages available.
     * Notification listing metadata
     */
    async persistentNotificationsListPersistentNotificationsMetadata(requestParameters: PersistentNotificationsListPersistentNotificationsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.persistentNotificationsListPersistentNotificationsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the persistent notification specified by the given ID.
     * Get a single notification
     */
    async persistentNotificationsReadPersistentNotificationRaw(requestParameters: PersistentNotificationsReadPersistentNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersistentNotification>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling persistentNotificationsReadPersistentNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persistent_notifications/{notification_id}`.replace(`{${"notification_id"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersistentNotificationFromJSON(jsonValue));
    }

    /**
     * Returns the persistent notification specified by the given ID.
     * Get a single notification
     */
    async persistentNotificationsReadPersistentNotification(requestParameters: PersistentNotificationsReadPersistentNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersistentNotification> {
        const response = await this.persistentNotificationsReadPersistentNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the notification specified by the given ID.
     * Update notification
     */
    async persistentNotificationsUpdatePersistentNotificationRaw(requestParameters: PersistentNotificationsUpdatePersistentNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersistentNotification>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling persistentNotificationsUpdatePersistentNotification.');
        }

        if (requestParameters.persistentNotificationUpdate === null || requestParameters.persistentNotificationUpdate === undefined) {
            throw new runtime.RequiredError('persistentNotificationUpdate','Required parameter requestParameters.persistentNotificationUpdate was null or undefined when calling persistentNotificationsUpdatePersistentNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persistent_notifications/{notification_id}`.replace(`{${"notification_id"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PersistentNotificationUpdateToJSON(requestParameters.persistentNotificationUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersistentNotificationFromJSON(jsonValue));
    }

    /**
     * Update the notification specified by the given ID.
     * Update notification
     */
    async persistentNotificationsUpdatePersistentNotification(requestParameters: PersistentNotificationsUpdatePersistentNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersistentNotification> {
        const response = await this.persistentNotificationsUpdatePersistentNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for updating the given notification, without persisting it. Returns an empty body if the data is valid. Returns validation errors if it is invalid.
     * Validate notification update
     */
    async persistentNotificationsValidateUpdatePersistentNotificationRaw(requestParameters: PersistentNotificationsValidateUpdatePersistentNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling persistentNotificationsValidateUpdatePersistentNotification.');
        }

        if (requestParameters.persistentNotificationUpdate === null || requestParameters.persistentNotificationUpdate === undefined) {
            throw new runtime.RequiredError('persistentNotificationUpdate','Required parameter requestParameters.persistentNotificationUpdate was null or undefined when calling persistentNotificationsValidateUpdatePersistentNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persistent_notifications/{notification_id}/validate`.replace(`{${"notification_id"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PersistentNotificationUpdateToJSON(requestParameters.persistentNotificationUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for updating the given notification, without persisting it. Returns an empty body if the data is valid. Returns validation errors if it is invalid.
     * Validate notification update
     */
    async persistentNotificationsValidateUpdatePersistentNotification(requestParameters: PersistentNotificationsValidateUpdatePersistentNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.persistentNotificationsValidateUpdatePersistentNotificationRaw(requestParameters, initOverrides);
    }

}
