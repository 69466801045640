import { createTheme } from "@fluentui/react";
import colors from "../ui/colors.scss";

// Themes can be created for example by using this tool:
// https://fluentuipr.z22.web.core.windows.net/heads/master/theming-designer/index.html
// For more documentation, please refer to the @fluent-ui/themes documentation:
// https://docs.microsoft.com/en-us/javascript/api/theme?view=office-ui-fabric-react-latest
// Please try to avoid using magic values here and instead reference them from `colors.scss`.
export const theme = createTheme({
    palette: {
        neutralPrimary: colors.primary03,
    },
});
