import * as React from "react";
import { observer } from "mobx-react";
import { Selection, DefaultButton, IColumn, Stack } from "@fluentui/react";
import { action, computed, makeObservable } from "mobx";
import { UUID } from "../../utils/uuid";
import { MultiSelectComboBox } from "../atoms/multi-select-connected-combo-box";
import { ListStates } from "../../utils/list-states";
import { ElofleetList } from "../atoms/elofleet-list";
import { ElofleetRepository } from "../../utils/elofleet-repository";
import { SelectionState } from "../../utils/selection-state";

import sizes from "../sizes.scss";

/**
 * This component is used to select a list of entities via multi-select dropdown.
 *
 * The flow is like this:
 * - Select the entities you want to add via the multi-select dropdown
 * - The selected entities are permanently selected.
 *    -> They're displayed in a list below the multi-select dropdown.
 * - Deselecting them will remove them from the list.
 * - Selected entities can also be removed from the list of permanently selected entities.
 *   This is done via a `Remove` button which will will remove all entities that're currently
 *   selected in the List!
 *
 * This component is a bit confusing as we're handling two different states:
 *
 * - Entities that're currently selected in the multi-select dropdown and are thereby
 *   shown in the list.
 * - Entities that're selected in that list.
 */
export interface MultiSelectWithListProps<TRepository extends ElofleetRepository> {
    readonly repository: TRepository;

    // Propagate the add-button click to the parent component.
    // The parent is expected to patch the addedEntities with these IDs.
    readonly onAdd: (entityIds: UUID[]) => void;
    // Propagate the delete-button click to the parent component.
    readonly onDelete: (entityIds: UUID[]) => void;

    // The list state + matching ids for the list of added entities.
    readonly listStateId: UUID;
    readonly listState: ListStates<TRepository>;

    // This is the list of entities that have already been permanently added.
    readonly addedEntities: UUID[];
    // The columns that should be shown in the list of added entities.
    readonly columns: IColumn[];
    // The items that should be shown in the list of added entities.
    readonly items: Object[];
    // Determines the way each entity will be displayed in the dropdown menu.
    readonly formatEntity: (entity: TRepository["apiResource"]["entity"]) => string;

    // Translations.
    // We cannot use i18n in here directly, as it doesn't work with generic components.
    readonly addButtonText: string;
    readonly removeButtonText: string;
    readonly dropdownLabel: string;
    readonly multiSelectPlaceholder?: string;
}

@observer
export class MultiSelectWithList<TRepository extends ElofleetRepository> extends React.Component<
    MultiSelectWithListProps<TRepository>
> {
    private selection = new Selection({
        onSelectionChanged: () => this.updateSelection(),
    });

    constructor(props: MultiSelectWithListProps<TRepository>) {
        super(props);
        makeObservable(this);
    }

    /**
     * Handles the un/selection of elements in the MultiSelectComboBox.
     */
    @action.bound private updateEntities(id?: UUID, selected?: boolean): void {
        if (id === undefined) {
            return;
        }

        if (selected) {
            this.props.onAdd([id]);
        } else {
            this.props.onDelete([id]);
        }
        return;
    }

    /**
     * Take the provided columns from the props and patch them with the current list state.
     */
    @computed private get columns(): IColumn[] {
        if (!this.props.listState.isInitialized(this.props.listStateId)) {
            return [];
        }
        return this.props.listState
            .getSortState(this.props.listStateId)
            .patchColumns(this.props.columns);
    }

    /**
     * Take the provided columns from the props and patch them with the current list state.
     */
    @computed private get selectionState(): SelectionState | undefined {
        if (!this.props.listState.isInitialized(this.props.listStateId)) {
            return;
        }

        return this.props.listState.getSelectionState(this.props.listStateId);
    }

    @action.bound private updateSelection(): void {
        // Update the listState's selectionState to the new selected keys.
        this.selectionState?.updateSelection(
            this.selection.getSelection().map((item) => item.key as UUID),
        );
    }

    /**
     * Wrap the onColumnHeaderClick function to handle non-initialized list states.
     */
    @action.bound private onColumnHeaderClick(event: unknown, column?: IColumn): void {
        if (!this.props.listState.isInitialized(this.props.listStateId)) {
            return;
        }

        this.props.listState.getSortState(this.props.listStateId).toggleColumn(event, column);
    }

    /**
     * Handle the removal of entities from the list of added entities.
     * This also resets the current selection state.
     */
    @action.bound private removeEntities(): void {
        if (!this.selectionState) {
            return;
        }

        // Get the current selection state and propagate the event to the parent.
        this.props.onDelete(this.selectionState.keys);

        this.selectionState.updateSelection([]);
    }

    public render(): JSX.Element {
        return (
            <>
                <Stack
                    tokens={{
                        childrenGap: sizes.xl,
                    }}
                >
                    <Stack
                        horizontal
                        verticalAlign="end"
                        horizontalAlign="space-between"
                        tokens={{
                            childrenGap: sizes.xl,
                        }}
                    >
                        <MultiSelectComboBox
                            label={this.props.dropdownLabel}
                            repository={this.props.repository}
                            formatEntity={this.props.formatEntity}
                            onChange={this.updateEntities}
                            selectedKeys={this.props.addedEntities}
                            placeholder={this.props.multiSelectPlaceholder}
                        />
                        <DefaultButton
                            disabled={this.selectionState?.keys.length === 0}
                            text={this.props.removeButtonText}
                            onClick={this.removeEntities}
                            key="removeButton"
                        />
                    </Stack>
                    <ElofleetList
                        {...this.props}
                        selection={this.selection}
                        onColumnHeaderClick={this.onColumnHeaderClick}
                        columns={this.columns}
                        disableColumnSorting
                    />
                </Stack>
            </>
        );
    }
}
