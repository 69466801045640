import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
    IComboBoxProps,
    SelectableOptionMenuItemType,
} from "@fluentui/react";
import { clone, omit } from "ramda";
import { external, inject } from "tsdi";
import { action, computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { PaginatedSearchableRepository } from "mobx-repository";
import * as React from "react";
import { UUID } from "../../utils/uuid";
import { defaultPageSize } from "../../utils/constants";
import { I18nProvider } from "../../domain/providers/i18n-provider";

/**
 * A Connected Combobox, which allows multi-select of entities while filtering
 * entities via search.
 *
 * This component has been initially built for the VehicleGroup and UserGroup
 * multi-assignments.
 */
export type MultiSelectComboBoxProps<TQuery, TEntity extends { id: string }> = {
    readonly repository?: PaginatedSearchableRepository<TQuery, TEntity>;
    // A static filter query that can be passed through.
    readonly query?: TQuery;
    // A static list of entities to show in the combobox.
    readonly entities?: TEntity[];
    // Determines the way each entity will be displayed in the dropdown menu.
    readonly formatEntity: (entity: TEntity) => string;
    // The parent component is expected to provide an onChange function.
    // The parent component then has to keep track of the selected items.
    readonly onChange: (value: UUID | undefined, selected?: boolean) => void;
    // These selected keys a then passed back into the component via this prop.
    readonly selectedKeys: UUID[];
    // All keys that're contained in this list won't show up in the dropdown.
    //
    // This component can work in combination with a list that displays some entities that were
    // previously added via this component. Those entities should then no longer show up in the
    // multi-select list.
    readonly hiddenKeys?: UUID[];

    readonly label?: string;
    readonly placeholder?: string;
    readonly allowFreeForm?: boolean;

    readonly sortFn?: (a: IComboBoxOption, b: IComboBoxOption) => number;
    readonly allowSelectAll?: boolean;
} & Omit<IComboBoxProps, "options" | "onChange" | "ref" | "selectedKey">;

@observer
@external
export class MultiSelectComboBox<TQuery, TEntity extends { id: string }> extends React.Component<
    MultiSelectComboBoxProps<TQuery, TEntity>
> {
    @observable private currentSearch: string | undefined;
    @inject protected readonly i18n!: I18nProvider;

    // This reference is needed to open the combobox dropdown on focus.
    // Sadly, this behavior is disabled when in freeform mode.
    private comboBoxRef: React.RefObject<IComboBox> = React.createRef();

    constructor(props: MultiSelectComboBoxProps<TQuery, TEntity>) {
        super(props);
        makeObservable(this);
    }

    @computed private get entities(): TEntity[] {
        if (this.props.entities) {
            return this.props.entities;
        }

        // Use either an empty query or the one provided via props.
        const query: TQuery & { search?: string; pageSize?: number } = this.props.query
            ? clone(this.props.query)
            : ({} as TQuery & { search?: string; pageSize?: number });
        // Patch the base query with the current search.
        if (this.currentSearch !== undefined) {
            query.search = this.currentSearch;
        }
        if (query["pageSize"] === undefined || query["pageSize"] === null) {
            query["pageSize"] = defaultPageSize;
        }

        const entities = this.props.repository!.byQuery(query as TQuery);

        return entities;
    }

    /**
     * Convert the current entities to Dropdown options.
     * Optionally add a select-all filter. This filters all
     * props.hiddenKeys.
     */
    @computed private get options(): IComboBoxOption[] {
        const entities: IComboBoxOption[] = [];
        // Optionally add select-all filter
        if (this.props.allowSelectAll) {
            entities.push(
                {
                    key: "divider",
                    text: "-",
                    itemType: SelectableOptionMenuItemType.Divider,
                },
                {
                    key: "select-all",
                    text: this.i18n.t("component.multiSelect.all"),
                    itemType: SelectableOptionMenuItemType.SelectAll,
                    styles: { optionText: { fontWeight: "bolder" } },
                },
            );
        }
        // Add available options
        const options = this.entities
            .filter((entity) => {
                if (this.props.hiddenKeys) {
                    return !this.props.hiddenKeys.includes(entity.id);
                }

                return true;
            })
            .map(
                (entity) =>
                    ({
                        key: entity.id,
                        text: this.props.formatEntity(entity),
                        itemType: SelectableOptionMenuItemType.Normal,
                    } as IComboBoxOption),
            );
        entities.push(...options);
        if (this.props.sortFn) {
            entities.sort(this.props.sortFn);
        }
        return entities;
    }

    /**
     * Select the "All" check box if every available option has
     * been selected. Deselect it otherwise.
     */
    @computed private get selectedKeys(): (UUID | string)[] {
        // Return early if option isn't set
        if (!this.props.allowSelectAll) {
            return this.props.selectedKeys;
        }
        let selectedKeys = clone(this.props.selectedKeys);
        if (this.entities.length === this.props.selectedKeys.length) {
            selectedKeys.push("select-all");
        } else {
            selectedKeys = selectedKeys.filter((key) => key !== "select-all");
        }
        return selectedKeys;
    }

    /**
     * This function is called whenever:
     * - An element in the multi-select is un-/selected.
     * - The user presses `Enter` after entering some input.
     * - The user presses `Enter` after navigating to an item with their arrow keys.
     * - The user presses `Escape` after navigating to an item with their arrow keys.
     *    Weirdly enough, pressing `Escape` triggers an onChange!
     *    This is why we simply ignore any focus releated events.
     */
    @action.bound private updateSelection(
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
        _index?: number,
        value?: string,
    ): void {
        // Don't do anything when focus is lost (E.g. escape is pressed).
        if (event.nativeEvent instanceof FocusEvent) {
            return;
        }

        if (option?.key === "select-all") {
            this.entities.forEach((entity) => {
                // "All" selected -> Select all unselected entities
                if (option.selected && !this.selectedKeys.includes(entity.id)) {
                    this.props.onChange(entity.id);
                    return;
                }
                // "All" deselected -> Deselected all selected entities
                if (!option.selected && this.selectedKeys.includes(entity.id)) {
                    this.props.onChange(entity.id);
                    return;
                }
            });
            return;
        }

        // Propagate the selection state to the parent component.
        if (option !== undefined) {
            this.props.onChange(option.key as UUID, option.selected);
            return;
        }

        // For some reason, when entering a matching text and pressing enter,
        // Fluent-ui **sometimes** does only send `value` **without** the accompanying option!!
        // That's why we sadly have to do our own value->option matching.
        if (value === undefined) {
            return;
        }

        for (const option of this.options) {
            if (option.text === value.trim()) {
                const key = option.key as UUID;
                this.props.onChange(key, !this.props.selectedKeys.includes(key));
            }
        }
    }

    /**
     * Handle input changes in the actual text field.
     * It's triggered via many different events, which we try to filter.
     *
     * The search should only be updated if the user changes the text.
     */
    @action.bound private updateSearch(
        option?: IComboBoxOption,
        _index?: number,
        value?: string,
    ): void {
        // Don't do anything, if we don't allow freeform.
        const allowFreeform =
            this.props.allowFreeForm !== undefined ? this.props.allowFreeForm : true;
        if (!allowFreeform) {
            return;
        }

        // The user either
        // - pressed enter in an empty field.
        // - moved through the options via their keyboard arrow buttons.
        // - Entered a search that perfectly matches one of the options.
        //
        // Since we cannot differentiate between those events and since we don't want to
        // adjust our search in most cases, we simply ignore all events with an existing option.
        if (option !== undefined) {
            return;
        }

        // Don't update the search if the event contains no new data or the term didn't change.
        if (value === undefined || this.currentSearch === value.trim()) {
            return;
        }

        // Update the search field state.
        if (value.trim() === "") {
            this.currentSearch = undefined;
        } else {
            this.currentSearch = value.trim();

            // Open the dropdown when writing in the field.
            this.comboBoxRef.current?.focus(true);
        }
    }

    public render(): JSX.Element {
        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(
            ["repository", "query", "formatEntity", "onChange", "clearable"],
            this.props,
        );

        // Make it so the combobox opens on focus
        return (
            <ComboBox
                multiSelect
                allowFreeform={
                    this.props.allowFreeForm !== undefined ? this.props.allowFreeForm : true
                }
                componentRef={this.comboBoxRef}
                autoComplete="off"
                options={this.options}
                onChange={this.updateSelection}
                onPendingValueChanged={this.updateSearch}
                selectedKey={this.selectedKeys}
                {...props}
            />
        );
    }
}
