import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { declareRoute, routePath, RouteProps } from "../routes";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { ListUserGroups } from "../ui/molecules/list-user-groups";
import { ListCommandButtonsUserGroups } from "../ui/organisms/list-command-buttons-user-groups";
import { createUuid } from "../utils/uuid";
import { PageHeader } from "../ui/atoms/page-header";
import { PageContent } from "../ui/atoms/page-content";
import { Section } from "../ui/atoms/section";

@external
@observer
export class PageUserGroups extends React.Component<RouteProps> {
    @inject protected readonly i18n!: I18nProvider;

    // Create the UUID on this level, so the button bar is able to access the same state as the list.
    private listStateId = createUuid();

    public render(): JSX.Element {
        return (
            <LayoutDefault
                header={
                    <PageHeader
                        title={this.i18n.t("page.userGroups.title")}
                        actionButtons={
                            <ListCommandButtonsUserGroups
                                listStateId={this.listStateId}
                                canDelete
                            />
                        }
                    />
                }
            >
                <PageContent>
                    <Section>
                        <ListUserGroups listStateId={this.listStateId} showSearchField />
                    </Section>
                </PageContent>
            </LayoutDefault>
        );
    }
}

export const routeUserGroups = declareRoute({
    component: PageUserGroups,
    icon: "Group",
    title: "page.userGroups.navbarEntry",
    path: routePath.userGroups,
    pattern: "/user-groups",
});
