/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserGroupDetails
 */
export interface UserGroupDetails {
    /**
     * 
     * @type {boolean}
     * @memberof UserGroupDetails
     */
    userEmployeeId: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserGroupDetails
     */
    userLastName: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserGroupDetails
     */
    userFirstName: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserGroupDetails
     */
    userJobTitle: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserGroupDetails
     */
    userFleetRole: boolean;
}

/**
 * Check if a given object implements the UserGroupDetails interface.
 */
export function instanceOfUserGroupDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userEmployeeId" in value;
    isInstance = isInstance && "userLastName" in value;
    isInstance = isInstance && "userFirstName" in value;
    isInstance = isInstance && "userJobTitle" in value;
    isInstance = isInstance && "userFleetRole" in value;

    return isInstance;
}

export function UserGroupDetailsFromJSON(json: any): UserGroupDetails {
    return UserGroupDetailsFromJSONTyped(json, false);
}

export function UserGroupDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGroupDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userEmployeeId': json['userEmployeeId'],
        'userLastName': json['userLastName'],
        'userFirstName': json['userFirstName'],
        'userJobTitle': json['userJobTitle'],
        'userFleetRole': json['userFleetRole'],
    };
}

export function UserGroupDetailsToJSON(value?: UserGroupDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userEmployeeId': value.userEmployeeId,
        'userLastName': value.userLastName,
        'userFirstName': value.userFirstName,
        'userJobTitle': value.userJobTitle,
        'userFleetRole': value.userFleetRole,
    };
}

