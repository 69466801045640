import { PaginatedSearchableRepository } from "mobx-repository";
import { initialize, inject } from "tsdi";
import { PageInfo, ResponseError } from "../api";
import { ServiceAuth } from "../domain/services/service-auth";
import { ServiceErrors } from "../domain/services/service-errors";
import { defaultPageSize } from "./constants";
import { IdProvider } from "./uuid";

export interface ApiResource {
    query: unknown;
    entity: IdProvider;
    create: unknown;
    update: unknown;
    sortKey: string;
}

export abstract class ElofleetRepository<
    Resource extends ApiResource = ApiResource,
    TId = string,
    TBatchId = string,
> extends PaginatedSearchableRepository<Resource["query"], Resource["entity"], TId, TBatchId> {
    @inject readonly serviceErrors!: ServiceErrors;
    @inject private readonly serviceAuth!: ServiceAuth;

    // Used to obtain the type of ApiResource used with this repository.
    // Do not use for runtime code.
    readonly apiResource!: Resource;

    protected defaultCount = defaultPageSize;

    @initialize protected listenForErrors(): void {
        this.addErrorListener((error) => this.handleError(error));
    }

    protected handleError(error: unknown): void {
        if (error instanceof ResponseError) {
            if (error.response.status === 401) {
                this.serviceAuth.invalidateSession(error.response);
            } else {
                void this.serviceErrors.reportResponse(error.response);
            }
        }
    }

    protected async wrapApiCall<T>(promise: Promise<T>): Promise<T> {
        try {
            return await promise;
        } catch (err) {
            this.handleError(err);
            throw err;
        }
    }

    public abstract fetchMetadata(query: Resource["query"]): Promise<PageInfo>;

    public abstract update(id: TId, update: Resource["update"]): Promise<Resource["entity"]>;

    public abstract create(update: Resource["create"]): Promise<Resource["entity"]>;

    public abstract delete(...ids: TId[]): Promise<void>;
}
