import { Icon, List, Spinner, SpinnerSize } from "@fluentui/react";
import css from "./list-login-news.scss";
import { News, NewsCategory, NewsItem } from "../../api";
import { formatDistance } from "date-fns";
import React from "react";
import { LoginNewsLabel } from "./login-news-label";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { tsdi } from "../../tsdi";
import { observer } from "mobx-react";

interface ListLoginNewsProps {
    news?: News;
}

const now = new Date();
const newThreshold = new Date(now.setDate(now.getDate() - 30));

/// This component renders a scrollable list of news on the login page.
export const ListLoginNews = observer(function ListLoginNews(
    props: ListLoginNewsProps,
): JSX.Element {
    const i18n = tsdi.get(I18nProvider);

    return (
        <>
            <div className={css.listLoginNews__heading}>
                <>{i18n.t("listLoginNews.title")}</>
            </div>
            <div className={css.listLoginNews} data-is-scrollable>
                {props.news === undefined ? (
                    <Spinner size={SpinnerSize.large} />
                ) : (
                    <List onRenderCell={renderCell} items={props.news.items} />
                )}
            </div>
        </>
    );
});

function renderCell(item?: NewsItem, _index?: number): JSX.Element {
    if (!item) {
        return <></>;
    }
    return (
        <div className={css.listLoginNews__cell}>
            <div className={css.listLoginNews__cellHeader}>
                {item.pubDate && (
                    <>
                        <LoginNewsLabel
                            pubDate={item.pubDate}
                            category={
                                item.pubDate > newThreshold ? NewsCategory.NEW : item.category
                            }
                        />
                        <div className={css.listLoginNews__date}>
                            <span className={css.listLoginNews__date__distance}>
                                {formatDistance(item.pubDate, new Date(), { addSuffix: true })}
                            </span>
                            <span className={css.listLoginNews__date__raw}>
                                {item.pubDate.toLocaleDateString()}
                            </span>
                            <Icon iconName="Calendar" />
                        </div>
                    </>
                )}
            </div>
            <a className={css.listLoginNews__content} href={item.link!} target="_blank">
                <div className={css.listLoginNews__title}>{item.title}</div>
                <div>{item.description}</div>
            </a>
        </div>
    );
}
