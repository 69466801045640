import React, { ReactNode } from "react";
import style from "./big-stat-number.scss";
import { Shimmer } from "@fluentui/react";

export interface Props {
    children?: ReactNode;
}

export function BigStatNumber({ children }: Props): JSX.Element {
    // Return the number, if defined.
    if (children !== undefined) {
        return <div className={style.bigStatNumber}>{children}</div>;
    }
    // Otherwise return some big stat number with the placeholder '...' so we
    // can place our <Shimmer /> perfectly above via absolute positioning.
    return (
        <div className={style.bigStatNumber}>
            <div>{"..."}</div>
            <Shimmer
                className={style.bigStatNumber__shimmer}
                styles={{
                    shimmerWrapper: {
                        // Set the height equal to the font size
                        height: style.fontSize,
                    },
                }}
            />
        </div>
    );
}
