import { runInAction } from "mobx";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    PageInfo,
    SiteCreate,
    SitesApi,
    SitesListSitesRequest,
    SiteSortKey,
    SiteUpdate,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { Validation } from "../../utils/validation";
import { Site } from "../../api";
import { defaultPageSize } from "../../utils/constants";

export type SitesQuery = Omit<SitesListSitesRequest, "page">;

export interface SitesApiResource extends ApiResource {
    entity: Site;
    query: SitesQuery;
    update: SiteUpdate;
    create: SiteCreate;
    sortKey: SiteSortKey;
}

@component
export class RepositorySites extends ElofleetRepository<SitesApiResource> {
    @inject private readonly sitesApi!: SitesApi;

    public validation = {
        create: new Validation((siteCreate: SiteCreate) =>
            this.sitesApi.sitesValidateCreateSite({ siteCreate: siteCreate }),
        ),
        update: new Validation((siteUpdate: SiteUpdate, siteId: UUID) =>
            this.sitesApi.sitesValidateUpdateSite({ siteUpdate, siteId }),
        ),
    };

    protected async fetchById(siteId: UUID): Promise<Site | undefined> {
        return await this.sitesApi.sitesReadSite({ siteId });
    }

    protected extractId = prop("id");

    protected async fetchByQuery(
        query: SitesQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<Site>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.sitesApi.sitesListSites({ ...query, page });
        return { entities };
    }

    public fetchMetadata(_: unknown): Promise<PageInfo> {
        return this.sitesApi.sitesListSitesMetadata({ pageSize: defaultPageSize });
    }

    public async update(siteId: UUID, siteUpdate: SiteUpdate): Promise<Site> {
        const site = await this.wrapApiCall(this.sitesApi.sitesUpdateSite({ siteUpdate, siteId }));

        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(site);
        });

        return site;
    }

    public async create(siteCreate: SiteCreate): Promise<Site> {
        const site = await this.wrapApiCall(this.sitesApi.sitesCreateSite({ siteCreate }));

        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(site);
        });

        return site;
    }

    public async delete(...siteIds: UUID[]): Promise<void> {
        await this.wrapApiCall(
            Promise.all(siteIds.map((siteId) => this.sitesApi.sitesDeleteSite({ siteId }))),
        );

        await this.waitForIdle();
        // This reload is necessary to reload all queries that want to display "all" entities.
        this.reloadQuery({ pageSize: defaultPageSize });
    }
}
