import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { createDocumentsSlice, DocumentsSlice } from "./document";
import { createEquipmentSlice, EquipmentSlice } from "./equipment";
import { createServiceReportsSlice, ServiceReportsSlice } from "./service-report";

export const useStore = create<DocumentsSlice & EquipmentSlice & ServiceReportsSlice>()(
    immer((...a) => ({
        ...createDocumentsSlice(...a),
        ...createEquipmentSlice(...a),
        ...createServiceReportsSlice(...a),
    })),
);
