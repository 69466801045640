import { observer } from "mobx-react";
import * as React from "react";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { declareRoute, PageUserSettingsTab, routePath, RouteProps } from "../routes";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { ListUsers } from "../ui/molecules/list-users";
import { ListCommandButtonsUsers } from "../ui/organisms/list-command-buttons-user";
import { createUuid } from "../utils/uuid";

import { PageHeader } from "../ui/atoms/page-header";
import { PageContent } from "../ui/atoms/page-content";
import { Section } from "../ui/atoms/section";
import { Pivot, PivotItem, Stack } from "@fluentui/react";
import { ListUserGroups } from "../ui/molecules/list-user-groups";
import { ListCommandButtonsUserGroups } from "../ui/organisms/list-command-buttons-user-groups";
import sizes from "../ui/sizes.scss";
import { ListUserGroupAddButton } from "../ui/organisms/list-user-group-add-button";
import { HistoryProvider } from "../domain/providers/history-provider";
import { Location } from "history";
import { action, makeObservable, observable } from "mobx";
import { articles } from "../help";

@external
@observer
export class PageUserSettings extends React.Component<RouteProps> {
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly historyProvider!: HistoryProvider;

    // Create the UUID on this level, so the button bar is able to access the same state as the list.
    private userListStateId = createUuid();
    private userGroupListStateId = createUuid();

    // This is updated by manually listening to changes in the browser history.
    // Since the history is not reactive itself, we cannot move this into a @computed getter.
    @observable private selectedTab: string = PageUserSettingsTab.Users;

    constructor(props: RouteProps) {
        super(props);
        makeObservable(this);
    }

    @initialize
    private initialize(): void {
        this.historyProvider.history.listen(({ location }) => this.updateSelectedTab(location));
        this.updateSelectedTab(this.historyProvider.history.location);
    }

    // This gets called when the user clicks a different tab.
    // It pushes a new entry into the browser history.
    // The change in the browser history will result in a call to `updateSelectedTab`
    // afterwards.
    @action.bound
    private updateURL(item?: PivotItem): void {
        if (item?.props.itemKey) {
            this.historyProvider.history.push(
                routePath.userSettings(item.props.itemKey as PageUserSettingsTab),
            );
        }
    }

    // This gets called when the history is updated in any way.
    // It recalculates the `selectedTab` value.
    @action.bound
    private updateSelectedTab(location: Location): void {
        // NOTE: when rewriting as a functional component use useLocation hook instead
        const tab = location.pathname.split("/").slice(-1)[0];

        if (typeof tab !== "string") {
            return;
        }

        this.selectedTab = tab;
    }

    public render(): JSX.Element {
        return (
            <LayoutDefault
                suggestedHelpArticles={[
                    articles.adminManageUsers,
                    articles.howToAddUser,
                    articles.userDefinition,
                    articles.fleetUserRoles,
                    articles.managementUserRoles,
                    articles.howToAccessInternalNfcId,
                    articles.howToCreateAccessControlGroups,
                    articles.administratorFunctionalities,
                ]}
                header={<PageHeader title={this.i18n.t("page.userSettings.title")} />}
            >
                <PageContent>
                    <Pivot
                        linkSize="large"
                        styles={{
                            root: {
                                display: "flex",
                                justifyContent: "center",
                                height: sizes.pivotHeight,
                            },
                            link: { marginBottom: `${sizes.gutter}` },
                        }}
                        onLinkClick={this.updateURL}
                        selectedKey={this.selectedTab}
                    >
                        <PivotItem
                            headerText={this.i18n.t("page.userSettings.tab.user")}
                            itemIcon="People"
                            itemKey={PageUserSettingsTab.Users}
                        >
                            <Section>
                                <ListUsers
                                    commandBar={
                                        <Stack horizontal style={{ alignItems: "center" }}>
                                            <ListCommandButtonsUsers
                                                listStateId={this.userListStateId}
                                                canDelete
                                            />
                                        </Stack>
                                    }
                                    listStateId={this.userListStateId}
                                    ignoreColumnVisibility={true}
                                    maxHeight={`${sizes.listMaxHeightWithPivots}`}
                                />
                            </Section>
                        </PivotItem>
                        <PivotItem
                            headerText={this.i18n.t("page.userSettings.tab.userGroup")}
                            itemIcon="People"
                            itemKey={PageUserSettingsTab.UserGroups}
                        >
                            <Section>
                                <ListUserGroups
                                    commandBar={
                                        <Stack horizontal>
                                            <ListUserGroupAddButton
                                                listStateId={this.userGroupListStateId}
                                            />
                                            <ListCommandButtonsUserGroups
                                                listStateId={this.userGroupListStateId}
                                                canDelete
                                            />
                                        </Stack>
                                    }
                                    listStateId={this.userGroupListStateId}
                                    showSearchField
                                    maxHeight={`${sizes.listMaxHeightWithPivots}`}
                                />
                            </Section>
                        </PivotItem>
                    </Pivot>
                </PageContent>
            </LayoutDefault>
        );
    }
}

export const routeUserSettings = declareRoute({
    navigation: true,
    component: PageUserSettings,
    icon: "FabricUserFolder",
    title: "page.userSettings.navbarEntry",
    path: routePath.userSettings,
    pattern: "/user-settings/:tab",
});
