/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MaintenanceList
 */
export interface MaintenanceList {
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleStatus: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleSerialNumber: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleManufacturer: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleModel: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleEngineType: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleManufactureYear: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehiclePurchaseDateOrLeaseTermBegin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleLeaseTermEnd: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleLeaseTermDaysRemaining: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleInitialOperatingHours: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleCurrentOperatingHours: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleLastMaintenanceOperatingHours: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleLastMaintenanceDate: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleNextMaintenanceHours: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleMaintenanceInterval: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleMaintenanceNotificationAt: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleMaintenanceLockdown: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleHoursUntilNextMaintenance: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    vehicleTypeLabel: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceList
     */
    departmentLabel: boolean;
}

/**
 * Check if a given object implements the MaintenanceList interface.
 */
export function instanceOfMaintenanceList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vehicleStatus" in value;
    isInstance = isInstance && "vehicleSerialNumber" in value;
    isInstance = isInstance && "vehicleManufacturer" in value;
    isInstance = isInstance && "vehicleModel" in value;
    isInstance = isInstance && "vehicleEngineType" in value;
    isInstance = isInstance && "vehicleManufactureYear" in value;
    isInstance = isInstance && "vehiclePurchaseDateOrLeaseTermBegin" in value;
    isInstance = isInstance && "vehicleLeaseTermEnd" in value;
    isInstance = isInstance && "vehicleLeaseTermDaysRemaining" in value;
    isInstance = isInstance && "vehicleInitialOperatingHours" in value;
    isInstance = isInstance && "vehicleCurrentOperatingHours" in value;
    isInstance = isInstance && "vehicleLastMaintenanceOperatingHours" in value;
    isInstance = isInstance && "vehicleLastMaintenanceDate" in value;
    isInstance = isInstance && "vehicleNextMaintenanceHours" in value;
    isInstance = isInstance && "vehicleMaintenanceInterval" in value;
    isInstance = isInstance && "vehicleMaintenanceNotificationAt" in value;
    isInstance = isInstance && "vehicleMaintenanceLockdown" in value;
    isInstance = isInstance && "vehicleHoursUntilNextMaintenance" in value;
    isInstance = isInstance && "vehicleTypeLabel" in value;
    isInstance = isInstance && "departmentLabel" in value;

    return isInstance;
}

export function MaintenanceListFromJSON(json: any): MaintenanceList {
    return MaintenanceListFromJSONTyped(json, false);
}

export function MaintenanceListFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaintenanceList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicleStatus': json['vehicleStatus'],
        'vehicleSerialNumber': json['vehicleSerialNumber'],
        'vehicleManufacturer': json['vehicleManufacturer'],
        'vehicleModel': json['vehicleModel'],
        'vehicleEngineType': json['vehicleEngineType'],
        'vehicleManufactureYear': json['vehicleManufactureYear'],
        'vehiclePurchaseDateOrLeaseTermBegin': json['vehiclePurchaseDateOrLeaseTermBegin'],
        'vehicleLeaseTermEnd': json['vehicleLeaseTermEnd'],
        'vehicleLeaseTermDaysRemaining': json['vehicleLeaseTermDaysRemaining'],
        'vehicleInitialOperatingHours': json['vehicleInitialOperatingHours'],
        'vehicleCurrentOperatingHours': json['vehicleCurrentOperatingHours'],
        'vehicleLastMaintenanceOperatingHours': json['vehicleLastMaintenanceOperatingHours'],
        'vehicleLastMaintenanceDate': json['vehicleLastMaintenanceDate'],
        'vehicleNextMaintenanceHours': json['vehicleNextMaintenanceHours'],
        'vehicleMaintenanceInterval': json['vehicleMaintenanceInterval'],
        'vehicleMaintenanceNotificationAt': json['vehicleMaintenanceNotificationAt'],
        'vehicleMaintenanceLockdown': json['vehicleMaintenanceLockdown'],
        'vehicleHoursUntilNextMaintenance': json['vehicleHoursUntilNextMaintenance'],
        'vehicleTypeLabel': json['vehicleTypeLabel'],
        'departmentLabel': json['departmentLabel'],
    };
}

export function MaintenanceListToJSON(value?: MaintenanceList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicleStatus': value.vehicleStatus,
        'vehicleSerialNumber': value.vehicleSerialNumber,
        'vehicleManufacturer': value.vehicleManufacturer,
        'vehicleModel': value.vehicleModel,
        'vehicleEngineType': value.vehicleEngineType,
        'vehicleManufactureYear': value.vehicleManufactureYear,
        'vehiclePurchaseDateOrLeaseTermBegin': value.vehiclePurchaseDateOrLeaseTermBegin,
        'vehicleLeaseTermEnd': value.vehicleLeaseTermEnd,
        'vehicleLeaseTermDaysRemaining': value.vehicleLeaseTermDaysRemaining,
        'vehicleInitialOperatingHours': value.vehicleInitialOperatingHours,
        'vehicleCurrentOperatingHours': value.vehicleCurrentOperatingHours,
        'vehicleLastMaintenanceOperatingHours': value.vehicleLastMaintenanceOperatingHours,
        'vehicleLastMaintenanceDate': value.vehicleLastMaintenanceDate,
        'vehicleNextMaintenanceHours': value.vehicleNextMaintenanceHours,
        'vehicleMaintenanceInterval': value.vehicleMaintenanceInterval,
        'vehicleMaintenanceNotificationAt': value.vehicleMaintenanceNotificationAt,
        'vehicleMaintenanceLockdown': value.vehicleMaintenanceLockdown,
        'vehicleHoursUntilNextMaintenance': value.vehicleHoursUntilNextMaintenance,
        'vehicleTypeLabel': value.vehicleTypeLabel,
        'departmentLabel': value.departmentLabel,
    };
}

