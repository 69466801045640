import { runInAction } from "mobx";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    PageInfo,
    DepartmentCreate,
    DepartmentsApi,
    DepartmentsListDepartmentsRequest,
    DepartmentUpdate,
    DepartmentSortKey,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { Validation } from "../../utils/validation";
import { Department } from "../../api";
import { defaultPageSize } from "../../utils/constants";

export type DepartmentsQuery = Omit<DepartmentsListDepartmentsRequest, "page">;

export interface DepartmentsApiResource extends ApiResource {
    entity: Department;
    query: DepartmentsQuery;
    update: DepartmentUpdate;
    create: DepartmentCreate;
    sortKey: DepartmentSortKey;
}

@component
export class RepositoryDepartments extends ElofleetRepository<DepartmentsApiResource> {
    @inject private readonly departmentsApi!: DepartmentsApi;

    public validation = {
        create: new Validation((departmentCreate: DepartmentCreate) =>
            this.departmentsApi.departmentsValidateCreateDepartment({
                departmentCreate: departmentCreate,
            }),
        ),
        update: new Validation((departmentUpdate: DepartmentUpdate, departmentId: UUID) =>
            this.departmentsApi.departmentsValidateUpdateDepartment({
                departmentUpdate,
                departmentId,
            }),
        ),
    };

    protected async fetchById(departmentId: UUID): Promise<Department | undefined> {
        return await this.departmentsApi.departmentsReadDepartment({
            departmentId,
        });
    }

    protected extractId = prop("id");

    protected async fetchByQuery(
        query: DepartmentsQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<Department>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.departmentsApi.departmentsListDepartments({
            ...query,
            page,
        });
        return { entities };
    }

    public fetchMetadata(_: unknown): Promise<PageInfo> {
        return this.departmentsApi.departmentsListDepartmentsMetadata({
            pageSize: defaultPageSize,
        });
    }

    public async update(
        departmentId: UUID,
        departmentUpdate: DepartmentUpdate,
    ): Promise<Department> {
        const department = await this.wrapApiCall(
            this.departmentsApi.departmentsUpdateDepartment({ departmentUpdate, departmentId }),
        );

        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(department);
        });

        return department;
    }

    public async create(departmentCreate: DepartmentCreate): Promise<Department> {
        const department = await this.wrapApiCall(
            this.departmentsApi.departmentsCreateDepartment({ departmentCreate }),
        );

        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(department);
        });

        return department;
    }

    public async delete(...departmentIds: UUID[]): Promise<void> {
        await this.wrapApiCall(
            Promise.all(
                departmentIds.map((departmentId) =>
                    this.departmentsApi.departmentsDeleteDepartment({ departmentId }),
                ),
            ),
        );

        await this.waitForIdle();
        // This reload is necessary to reload all queries that want to display "all" entities.
        this.reloadQuery({ pageSize: defaultPageSize });
    }
}
