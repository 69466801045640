import { Stack } from "@fluentui/react";
import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { declareRoute, routePath, RouteProps } from "../routes";
import { SelectLanguage } from "../ui/atoms/select-language";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { PageHeader } from "../ui/atoms/page-header";
import { PageContent } from "../ui/atoms/page-content";
import { Section } from "../ui/atoms/section";
import { FormUpdateUserProfileSettings } from "../ui/molecules/form-update-user-profile-settings";

@external
@observer
export class PageSettings extends React.Component<RouteProps> {
    @inject protected readonly i18n!: I18nProvider;

    public render(): JSX.Element {
        return (
            <LayoutDefault header={<PageHeader title={this.i18n.t("page.settings.title")} />}>
                <PageContent>
                    <Section withPadding>
                        <form>
                            <Stack
                                tokens={{
                                    childrenGap: "1em",
                                }}
                            >
                                <SelectLanguage
                                    label={this.i18n.t("page.settings.language.label")}
                                    style={{ maxWidth: 250 }}
                                />
                            </Stack>
                        </form>
                        <FormUpdateUserProfileSettings />
                    </Section>
                </PageContent>
            </LayoutDefault>
        );
    }
}

export const routeSettings = declareRoute({
    component: PageSettings,
    path: routePath.settings,
    pattern: "/settings",
    icon: "Settings",
    title: "page.settings.navbarEntry",
});
