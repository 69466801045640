import * as React from "react";
import { Icon } from "@fluentui/react";
import { external, inject } from "tsdi";
import css from "./help-button.scss";
import { ServiceSidebar } from "../../domain/services/service-sidebar";

export interface HelpButtonProps {}

@external
export class HelpButton extends React.Component<HelpButtonProps> {
    @inject private serviceSidebar!: ServiceSidebar;

    public render(): JSX.Element {
        return (
            <button
                className={css.button}
                onClick={() => this.serviceSidebar.toggleSecondaryCollapsed()}
                id="dashboardTutorialTargetRevisit"
            >
                <Icon iconName="Help" />
            </button>
        );
    }
}
