import { Segment, FetchByQueryResult } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    PreOpsQuestionsListPreOpsQuestionsRequest,
    PreOpsQuestion,
    PreOpsQuestionVersionsApi,
    PageInfo,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";

export type PreOpsQuestionsQuery = Omit<PreOpsQuestionsListPreOpsQuestionsRequest, "page">;

export interface PreOpsQuestionVersionsApiResource extends ApiResource {
    entity: PreOpsQuestion;
    query: never;
    update: never;
    create: never;
    sortKey: never;
}

@component
export class RepositoryPreOpsQuestionVersions extends ElofleetRepository<PreOpsQuestionVersionsApiResource> {
    @inject private readonly preOpsQuestionVersionsApi!: PreOpsQuestionVersionsApi;

    protected async fetchById(versionId: UUID): Promise<PreOpsQuestion | undefined> {
        return await this.preOpsQuestionVersionsApi.preOpsQuestionVersionsReadPreOpsQuestionVersion(
            {
                versionId,
            },
        );
    }

    protected extractId = prop("id");

    public fetchMetadata(_query: never): Promise<PageInfo> {
        throw new Error("Method not implemented.");
    }

    public update(_id: string, _update: never): Promise<PreOpsQuestion> {
        throw new Error("Method not implemented.");
    }

    public create(_update: never): Promise<PreOpsQuestion> {
        throw new Error("Method not implemented.");
    }

    public delete(..._ids: string[]): Promise<void> {
        throw new Error("Method not implemented.");
    }

    protected fetchByQuery(
        _query: never,
        _pagination: Segment,
    ): Promise<FetchByQueryResult<PreOpsQuestion>> {
        throw new Error("Method not implemented.");
    }
}
