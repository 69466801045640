import { FontIcon } from "@fluentui/react";
import classNames from "classnames";
import { action, computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { inject, external } from "tsdi";
import { HistoryProvider } from "../../domain/providers/history-provider";
import { NavigationRoute } from "../../routes";
import { CollapseIcon } from "./collapse-icon";
import css from "./section.scss";

export interface SectionProps {
    readonly children?: React.ReactElement | React.ReactElement[];
    readonly title?: string;
    readonly icon?: string;
    readonly actionButtons?: React.ReactElement;
    readonly withPadding?: boolean;
    readonly collapsible?: boolean;
    readonly defaultCollapsed?: boolean;
    readonly className?: string;
    readonly route?: NavigationRoute;
}

@external
@observer
export class Section extends React.Component<SectionProps> {
    @observable private collapsed = this.props.defaultCollapsed;
    @inject private readonly historyProvider!: HistoryProvider;

    constructor(props: SectionProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get showHeader(): boolean {
        return Boolean(
            this.props.title ||
                this.props.icon ||
                this.props.actionButtons ||
                this.props.collapsible,
        );
    }

    @computed private get showContent(): boolean {
        return !this.props.collapsible || !this.collapsed;
    }

    @action.bound private toggleCollapsed(evt: React.SyntheticEvent<HTMLElement>): void {
        evt.stopPropagation();
        evt.preventDefault();
        if (!this.props.collapsible) {
            return;
        }
        this.collapsed = !this.collapsed;
    }

    @action.bound private updateURL(evt: React.MouseEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        if (this.props.route) {
            this.historyProvider.history.push(this.props.route.path());
        }
    }

    public render(): JSX.Element {
        return (
            <section className={classNames(css.section, this.props.className)}>
                {this.showHeader && (
                    <div
                        className={classNames(css.section__header, {
                            [css["section__header--clickable"]]: this.props.collapsible,
                            [css["section__header--collapsed"]]: this.collapsed,
                        })}
                        onClick={this.toggleCollapsed}
                    >
                        {this.props.collapsible && (
                            <CollapseIcon
                                collapsed={this.collapsed}
                                onClick={this.toggleCollapsed}
                            />
                        )}
                        {this.props.title && (
                            <h2 className={css.section__title}>
                                {this.props.route ? (
                                    <a
                                        className={css.section__route}
                                        href={this.props.route.path()}
                                        onClick={this.updateURL}
                                    >
                                        {this.props.title}
                                    </a>
                                ) : (
                                    this.props.title
                                )}
                            </h2>
                        )}
                        {(this.props.icon || this.props.actionButtons) && (
                            <div className={css.section__right}>
                                {this.props.actionButtons && (
                                    <div className={css.section__actionButtons}>
                                        {this.props.actionButtons}
                                    </div>
                                )}
                                {this.props.icon && (
                                    <FontIcon
                                        className={css.section__icon}
                                        aria-label={this.props.icon}
                                        iconName={this.props.icon}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                )}
                {this.showContent && (
                    <div
                        className={
                            this.props.withPadding
                                ? css.section__content
                                : css.section__contentNoPaddings
                        }
                    >
                        {this.props.children}
                    </div>
                )}
            </section>
        );
    }
}
