import * as React from "react";
import { Section } from "../atoms/section";
import { IPanelProps, IStackTokens, Stack, StackItem, IRenderFunction } from "@fluentui/react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { Panel, PanelType, SelectionMode } from "@fluentui/react";
import { UUID } from "../../utils/uuid";
import { external, inject } from "tsdi";
import { observer } from "mobx-react";
import { action } from "mobx";
import css from "./panel-vehicle-group-details.scss";
import { RepositoryUserGroups } from "../../domain/repositories/repository-user-groups";
import { ListCommandButtonsUserGroups } from "../organisms/list-command-buttons-user-groups";
import { ListUsers, ListUsersLayout as ListUsersColumnLayout } from "./list-users";
import { ListVehicleGroups } from "./list-vehicle-groups";
import { mapSome } from "../../utils/functions";

export interface PanelUserGroupDetailsProps {
    readonly isOpen: boolean;
    readonly userGroupId?: UUID;
    readonly onDismiss: (ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => void;
}

const numericalSpacingStackTokens: IStackTokens = {
    childrenGap: 20,
    padding: 10,
};

@external
@observer
export class PanelUserGroupDetails extends React.Component<PanelUserGroupDetailsProps> {
    @inject private readonly repositoryUserGroups!: RepositoryUserGroups;
    @inject protected readonly i18n!: I18nProvider;

    @action.bound private renderHeader(): IRenderFunction<IPanelProps> {
        return (props, defaultRender) => (
            <>
                {defaultRender!(props)}
                <Stack horizontal verticalAlign="center">
                    <div className={css.commandButtons}>
                        <ListCommandButtonsUserGroups
                            canDelete
                            singleItemMode
                            itemId={this.props.userGroupId}
                            onDelete={(_evt) => this.props.onDismiss()}
                        />
                    </div>
                </Stack>
            </>
        );
    }

    public render(): JSX.Element {
        if (!this.props.userGroupId) {
            return <></>;
        }

        const userGroup = this.repositoryUserGroups.byId(this.props.userGroupId);
        const userCount = mapSome((count) => `${count}`, userGroup?.assignedUserCount) ?? "";
        const vehicleGroupCount =
            mapSome((count) => `${count}`, userGroup?.assignedVehicleGroupCount) ?? "";

        return (
            <Panel
                isOpen={this.props.isOpen}
                isBlocking={false}
                onDismiss={this.props.onDismiss}
                onRenderHeader={this.renderHeader()}
                type={PanelType.large}
                headerText={userGroup?.label}
            >
                <Stack horizontal horizontalAlign="start" tokens={numericalSpacingStackTokens}>
                    <StackItem>
                        <Section
                            withPadding
                            title={`${this.i18n.t(
                                "component.panelUserGroupDetails.users.title",
                            )} (${userCount})`}
                        >
                            <ListUsers
                                selectionMode={SelectionMode.none}
                                userGroupIds={[this.props.userGroupId]}
                                columnLayout={ListUsersColumnLayout.Group}
                                ignoreUrl
                                hideFilter
                            />
                        </Section>
                    </StackItem>
                    <StackItem>
                        <Section
                            withPadding
                            title={`${this.i18n.t(
                                "component.panelUserGroupDetails.vehicleGroups.title",
                            )} (${vehicleGroupCount})`}
                        >
                            <ListVehicleGroups
                                selectionMode={SelectionMode.none}
                                userGroupIds={[this.props.userGroupId]}
                                ignoreUrl
                                hideFilter
                            />
                        </Section>
                    </StackItem>
                </Stack>
            </Panel>
        );
    }
}
