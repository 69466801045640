import * as React from "react";
import { observer } from "mobx-react";
import {
    createTheme,
    DefaultButton,
    IColumn,
    Icon,
    ITheme,
    Separator,
    Stack,
} from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { createUuid, UUID } from "../../utils/uuid";
import {
    UserGroup,
    UserGroupAssignmentsApi,
    User,
    RoleGroups,
    MultiUserGroupAssignmentCreate,
} from "../../api";
import { RepositoryUserGroups } from "../../domain/repositories/repository-user-groups";
import { RepositoryUsers } from "../../domain/repositories/repository-users";
import { ModalConfirmation } from "../atoms/modal-confirmation";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { MultiSelectWithList } from "../organisms/multi-select-with-list";
import sizes from "../sizes.scss";
import { ElofleetDialog } from "../atoms/elofleet-dialog";
import { defaultPageSize } from "../../utils/constants";

export interface FormCreateUserGroupAssignmentsProps {
    readonly onDialogCancel: () => void;
    readonly isOpen: boolean;
}

@observer
@external
export class FormCreateUserGroupAssignments extends React.Component<FormCreateUserGroupAssignmentsProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly repositoryUserGroups!: RepositoryUserGroups;
    @inject
    private readonly userGroupAssignmentsApi!: UserGroupAssignmentsApi;

    @observable private cancelConfirmationModalVisible = false;

    // The list state ids for the respective lists of added groups.
    private userGroupListStateId = createUuid();
    private userListStateId = createUuid();

    // These lists contain the entities that've already been added.
    // I.e. the UserGroups and Userss that have been selected for assignments.
    //
    // Once the user sends this form, these entities will be used to create the
    // actual new assignments.
    @observable private addedUserGroups: UUID[] = [];
    @observable private addedUsers: UUID[] = [];

    constructor(props: FormCreateUserGroupAssignmentsProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.userGroups.initializeList(this.userGroupListStateId, {
            query: () => {
                return {
                    pageSize: defaultPageSize,
                };
            },
            ignoreUrl: true,
        });
        this.serviceListStates.users.initializeList(this.userListStateId, {
            query: () => {
                return { pageSize: defaultPageSize, roleGroup: RoleGroups.FLEET_USERS };
            },
            ignoreUrl: true,
        });
    }

    /**
     * This is run, if the user clicks the form's cancel button.
     * A confirmation modal might pop up, if the user did any changes.
     */
    @action.bound private async createUserGroupAssignments(): Promise<void> {
        const assignments_create = {
            userGroupIds: this.addedUserGroups,
            userIds: this.addedUsers,
        } as MultiUserGroupAssignmentCreate;

        const api = this.userGroupAssignmentsApi;
        await api.userGroupAssignmentsCreateMultipleUserGroupAssignments({
            multiUserGroupAssignmentCreate: assignments_create,
        });

        this.closeForm();
    }

    /**
     * This is run, if the user clicks the form's cancel button.
     * A confirmation modal might pop up, if the user did any changes.
     */
    @action.bound private showConfirmAndCloseForm(): void {
        // If there were any changes, i.e. if the original and mutable copy aren't the same,
        // open the confirmation modal for aborting the update process.
        if (this.addedUsers.length > 0 || this.addedUserGroups.length > 0) {
            this.cancelConfirmationModalVisible = true;
            return;
        }

        this.closeForm();
    }

    /**
     * Actually close the component and reset all state.
     */
    @action.bound private closeForm(): void {
        this.cancelConfirmationModalVisible = false;
        this.addedUserGroups = [];
        this.addedUsers = [];

        this.props.onDialogCancel();
    }

    @action.bound private closeCancelConfirmationModalVisible(): void {
        this.cancelConfirmationModalVisible = false;
    }

    /**
     * Handle the actual addition of a all currently selected Users in the group.
     */
    @action.bound private addUserGroups(userGroups: UUID[]): void {
        this.addedUserGroups = [...this.addedUserGroups, ...userGroups];
    }

    /**
     * Handle the removal of UserGroups.
     * In this case, removal means that they'll be removed from the list of added UserGroups.
     */
    @action.bound private removeUserGroups(idsToRemove: UUID[]): void {
        const remainingGroups = this.addedUserGroups.filter((id) => !idsToRemove.includes(id));
        this.addedUserGroups = remainingGroups;
    }

    /**
     * Handle the actual addition of a all currently selected Vehicles in the group.
     */
    @action.bound private addUsers(users: UUID[]): void {
        this.addedUsers = [...this.addedUsers, ...users];
    }

    /**
     * Handle the removal of Users.
     * In this case, removal means that they'll be removed from the list of added Users.
     */
    @action.bound private removeUsers(idsToRemove: UUID[]): void {
        const remainingGroups = this.addedUsers.filter((id) => !idsToRemove.includes(id));
        this.addedUsers = remainingGroups;
    }

    /**
     * The items that should be shown in the list of added Users.
     */
    @computed private get userItems(): Object[] {
        const groups = [];

        for (const id of this.addedUsers) {
            const user = this.repositoryUsers.byId(id);
            if (user !== undefined) {
                groups.push({
                    key: user.id,
                    employeeId: user.employeeId,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    jobTitle: user.jobTitle,
                    fleetRole: this.i18n.formatFleetRole(user.fleetRole),
                });
            }
        }

        return groups;
    }

    /**
     * The columns that should be shown in the list of selected Users.
     */
    @computed private get userColumns(): IColumn[] {
        return [
            {
                fieldName: "employeeId",
                name: this.i18n.t("listUsers.column.employeeId.name"),
                key: "employeeId",
                minWidth: 200,
                maxWidth: 300,
            },
            {
                fieldName: "firstName",
                name: this.i18n.t("listUsers.column.firstName.name"),
                key: "firstName",
                minWidth: 80,
                maxWidth: 100,
            },
            {
                fieldName: "lastName",
                name: this.i18n.t("listUsers.column.lastName.name"),
                key: "lastName",
                minWidth: 100,
                maxWidth: 150,
            },
            {
                fieldName: "jobtitle",
                name: this.i18n.t("listUsers.column.jobTitle.name"),
                key: "jobTitle",
                minWidth: 100,
                maxWidth: 150,
            },
            {
                fieldName: "fleetRole",
                name: this.i18n.t("listUsers.column.fleetRole.name"),
                key: "fleetRole",
                minWidth: 120,
                maxWidth: 200,
            },
        ];
    }

    /**
     * The items that should be shown in the list of added UserGroups.
     */
    @computed private get userGroupItems(): Object[] {
        const groups = [];

        for (const id of this.addedUserGroups) {
            const group = this.repositoryUserGroups.byId(id);
            if (group !== undefined) {
                groups.push({
                    key: group.id,
                    label: group.label,
                    assignedUserCount: group.assignedUserCount,
                    assignedVehicleGroupCount: group.assignedVehicleGroupCount,
                });
            }
        }

        return groups;
    }

    /**
     * The columns that should be shown in the list of selected UserGroups.
     */
    @computed private get userGroupColumns(): IColumn[] {
        return [
            {
                fieldName: "label",
                name: this.i18n.t("listUserGroups.column.label.name"),
                key: "label",
                minWidth: 150,
            },
            {
                fieldName: "assignedUserCount",
                name: this.i18n.t("listUserGroups.column.assignedUserCount.name"),
                key: "assignedUserCount",
                minWidth: 80,
            },
            {
                fieldName: "assignedVehicleGroupCount",
                name: this.i18n.t("listUserGroups.column.assignedVehicleGroupCount.name"),
                key: "assignedVehicleGroupCount",
                minWidth: 150,
            },
        ];
    }

    public render(): JSX.Element {
        const separatorTheme: ITheme = createTheme({
            fonts: {
                medium: {
                    fontSize: sizes.l,
                },
            },
        });

        return (
            <ElofleetDialog
                isOpen={this.props.isOpen}
                title={this.i18n.t("formCreateUserGroupAssignments.title")}
                onDismiss={this.showConfirmAndCloseForm}
            >
                <Stack
                    horizontal
                    tokens={{
                        childrenGap: sizes.xxl,
                    }}
                    styles={{
                        root: {
                            minHeight: "400px",
                            padding: sizes.xl,
                            overflow: "auto",
                        },
                    }}
                >
                    <MultiSelectWithList
                        repository={this.repositoryUsers}
                        onAdd={this.addUsers}
                        onDelete={this.removeUsers}
                        listStateId={this.userListStateId}
                        listState={this.serviceListStates.users}
                        addedEntities={this.addedUsers}
                        columns={this.userColumns}
                        items={this.userItems}
                        formatEntity={(user: User) => this.i18n.formatUserFullName(user)}
                        addButtonText={this.i18n.t("component.multiSelect.addButton")}
                        removeButtonText={this.i18n.t("component.multiSelect.removeButton")}
                        dropdownLabel={this.i18n.t("formCreateUserGroupAssignments.user.label")}
                        multiSelectPlaceholder={this.i18n.t(
                            "formCreateUserGroupAssignments.user.placeholder",
                        )}
                    />
                    <Stack.Item styles={{ root: { height: "400px" } }}>
                        <Separator theme={separatorTheme} vertical>
                            <Stack>
                                <Stack.Item tokens={{ margin: `${sizes.s} 0px` }}>
                                    {this.i18n.t("formCreateUserGroupAssignments.separator.assign")}
                                </Stack.Item>
                                <Icon iconName="ChromeBackMirrored" />
                            </Stack>
                        </Separator>
                    </Stack.Item>
                    <MultiSelectWithList
                        repository={this.repositoryUserGroups}
                        onAdd={this.addUserGroups}
                        onDelete={this.removeUserGroups}
                        listStateId={this.userGroupListStateId}
                        listState={this.serviceListStates.userGroups}
                        addedEntities={this.addedUserGroups}
                        columns={this.userGroupColumns}
                        items={this.userGroupItems}
                        formatEntity={(group: UserGroup) => group.label}
                        addButtonText={this.i18n.t("component.multiSelect.addButton")}
                        removeButtonText={this.i18n.t("component.multiSelect.removeButton")}
                        dropdownLabel={this.i18n.t(
                            "formCreateUserGroupAssignments.userGroup.label",
                        )}
                        multiSelectPlaceholder={this.i18n.t(
                            "formCreateUserGroupAssignments.userGroup.placeholder",
                        )}
                    />
                </Stack>
                <ElofleetDialogFooter>
                    <DefaultButton
                        label={this.i18n.t("formCreateUserGroupAssignments.cancel.label")}
                        text={this.i18n.t("formCreateUserGroupAssignments.cancel.text")}
                        onClick={this.showConfirmAndCloseForm}
                    />
                    <DefaultButton
                        primary
                        text={this.i18n.t("formCreateUserGroupAssignments.submit.text")}
                        onClick={this.createUserGroupAssignments}
                        disabled={this.addedUsers.length === 0 || this.addedUserGroups.length === 0}
                    />
                </ElofleetDialogFooter>
                <ModalConfirmation
                    isOpen={this.cancelConfirmationModalVisible}
                    title={this.i18n.t("modalAbortUpdate.title")}
                    text={this.i18n.t("modalAbortUpdate.description")}
                    onConfirm={this.closeForm}
                    onCancel={this.closeCancelConfirmationModalVisible}
                />
            </ElofleetDialog>
        );
    }
}
