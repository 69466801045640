import { createArticle, createTag } from "./help-types";
import { ManagementRole } from "./api";

// Create all tags.
export const tags = {
    administrator: createTag("administrator", "help.tag.administrator"),
    fleetUserRole: createTag("fleetUserRole", "help.tag.fleetUserRole"),
    howTo: createTag("howTo", "help.tag.howTo"),
    maintenanceManager: createTag("maintenanceManager", "help.tag.maintenanceManager"),
    managementUserRole: createTag("managementUserRole", "help.tag.managementUserRole"),
    operator: createTag("operator", "help.tag.operator"),
    reports: createTag("reports", "help.tag.reports"),
    roles: createTag("roles", "help.tag.roles"),
    supervisor: createTag("supervisor", "help.tag.supervisor"),
    standardUser: createTag("standardUser", "help.tag.standardUser"),
    truck: createTag("truck", "help.tag.truck"),
    user: createTag("user", "help.tag.user"),
} as const;

// Create all articles.
export const articles = {
    fleetUserRoles: createArticle({
        filename: "fleet-user-roles.md",
        title: "help.article.fleetUserRoles.title",
        summary: "help.article.fleetUserRoles.summary",
        tags: [
            tags.user,
            tags.roles,
            tags.fleetUserRole,
            tags.operator,
            tags.maintenanceManager,
            tags.supervisor,
        ],
    }),
    managementUserRoles: createArticle({
        filename: "management-user-roles.md",
        title: "help.article.managementUserRoles.title",
        summary: "help.article.managementUserRoles.summary",
        tags: [
            tags.user,
            tags.roles,
            tags.managementUserRole,
            tags.standardUser,
            tags.administrator,
        ],
    }),
    roleOperator: createArticle({
        filename: "roles/operator.md",
        title: "help.article.roleOperator.title",
        summary: "help.article.roleOperator.summary",
        tags: [tags.user, tags.roles, tags.fleetUserRole, tags.operator],
    }),
    roleMaintenanceManager: createArticle({
        filename: "roles/maintenance-manager.md",
        title: "help.article.roleMaintenanceManager.title",
        summary: "help.article.roleMaintenanceManager.summary",
        tags: [tags.user, tags.roles, tags.fleetUserRole, tags.maintenanceManager],
    }),
    roleSupervisor: createArticle({
        filename: "roles/supervisor.md",
        title: "help.article.roleSupervisor.title",
        summary: "help.article.roleSupervisor.summary",
        tags: [tags.user, tags.roles, tags.fleetUserRole, tags.supervisor],
    }),
    roleStandardUser: createArticle({
        filename: "roles/standard-user.md",
        title: "help.article.roleStandardUser.title",
        summary: "help.article.roleStandardUser.summary",
        tags: [tags.user, tags.roles, tags.managementUserRole, tags.standardUser],
    }),
    roleAdministrator: createArticle({
        filename: "roles/administrator.md",
        title: "help.article.roleAdministrator.title",
        summary: "help.article.roleAdministrator.summary",
        tags: [tags.user, tags.roles, tags.managementUserRole, tags.administrator],
    }),
    administratorFunctionalities: createArticle({
        filename: "administrator-functionalities.md",
        title: "help.article.administratorFunctionalities.title",
        summary: "help.article.administratorFunctionalities.summary",
        tags: [tags.administrator],
        access: ManagementRole.INSTANCE_ADMIN,
    }),
    adminManageUsers: createArticle({
        filename: "admin/manage-users.md",
        title: "help.article.adminManageUsers.title",
        summary: "help.article.adminManageUsers.summary",
        tags: [tags.user, tags.administrator],
        access: ManagementRole.INSTANCE_ADMIN,
    }),
    userDefinition: createArticle({
        filename: "user-definition.md",
        title: "help.article.userDefinition.title",
        summary: "help.article.userDefinition.summary",
        tags: [tags.user, tags.roles, tags.fleetUserRole, tags.managementUserRole],
    }),
    howToAddUser: createArticle({
        filename: "howtos/add-user.md",
        title: "help.article.howToAddUser.title",
        summary: "help.article.howToAddUser.summary",
        tags: [tags.user, tags.roles, tags.fleetUserRole, tags.managementUserRole, tags.howTo],
        access: ManagementRole.INSTANCE_ADMIN,
    }),
    howToChangePassword: createArticle({
        filename: "howtos/change-password.md",
        title: "help.article.howToChangePassword.title",
        summary: "help.article.howToChangePassword.summary",
        tags: [tags.user, tags.howTo],
    }),
    howToAccessInternalNfcId: createArticle({
        filename: "howtos/accessing-internal-nfc-ids.md",
        title: "help.article.howToAccessInternalNfcId.title",
        summary: "help.article.howToAccessInternalNfcId.summary",
        tags: [tags.user, tags.fleetUserRole, tags.howTo],
        access: ManagementRole.INSTANCE_ADMIN,
    }),
    contact: createArticle({
        filename: "contact.md",
        title: "help.article.contact.title",
        summary: "help.article.contact.summary",
        tags: [],
    }),
    fleetUserGroups: createArticle({
        filename: "fleet-user-groups.md",
        title: "help.article.fleetUserGroups.title",
        summary: "help.article.fleetUserGroups.summary",
        tags: [tags.fleetUserRole],
        access: ManagementRole.INSTANCE_ADMIN,
    }),
    adminManageTrucks: createArticle({
        filename: "admin/manage-trucks.md",
        title: "help.article.adminManageTrucks.title",
        summary: "help.article.adminManageTrucks.summary",
        tags: [tags.administrator, tags.truck],
        access: ManagementRole.INSTANCE_ADMIN,
    }),
    howToAddTruck: createArticle({
        filename: "howtos/add-truck.md",
        title: "help.article.howToAddTruck.title",
        summary: "help.article.howToAddTruck.summary",
        tags: [tags.howTo, tags.truck],
        access: ManagementRole.INSTANCE_ADMIN,
    }),
    howToEditTruck: createArticle({
        filename: "howtos/edit-truck.md",
        title: "help.article.howToEditTruck.title",
        summary: "help.article.howToEditTruck.summary",
        tags: [tags.howTo, tags.truck],
        access: ManagementRole.INSTANCE_ADMIN,
    }),
    howToRemoveTruck: createArticle({
        filename: "howtos/remove-truck.md",
        title: "help.article.howToRemoveTruck.title",
        summary: "help.article.howToRemoveTruck.summary",
        tags: [tags.howTo, tags.truck],
        access: ManagementRole.INSTANCE_ADMIN,
    }),
    adminSystemWideSettings: createArticle({
        filename: "admin/system-wide-settings.md",
        title: "help.article.adminSystemWideSettings.title",
        summary: "help.article.adminSystemWideSettings.summary",
        tags: [tags.administrator],
        access: ManagementRole.INSTANCE_ADMIN,
    }),
    adminConfigureChecklists: createArticle({
        filename: "admin/configure-checklists.md",
        title: "help.article.adminConfigureChecklists.title",
        summary: "help.article.adminConfigureChecklists.summary",
        tags: [tags.administrator],
        access: ManagementRole.INSTANCE_ADMIN,
    }),
    adminChangeSafetyMessage: createArticle({
        filename: "admin/change-safety-message.md",
        title: "help.article.adminChangeSafetyMessage.title",
        summary: "help.article.adminChangeSafetyMessage.summary",
        tags: [tags.administrator],
        access: ManagementRole.INSTANCE_ADMIN,
    }),
    truckFleetReport: createArticle({
        filename: "truck-fleet-report.md",
        title: "help.article.truckFleetReport.title",
        summary: "help.article.truckFleetReport.summary",
        tags: [tags.truck, tags.reports],
    }),
    truckFleetProductivityReport: createArticle({
        filename: "truck-fleet-productivity-report.md",
        title: "help.article.truckFleetProductivityReport.title",
        summary: "help.article.truckFleetProductivityReport.summary",
        tags: [tags.truck, tags.reports],
    }),
    truckFleetDriveTimeReport: createArticle({
        filename: "truck-fleet-drive-time-report.md",
        title: "help.article.truckFleetDriveTimeReport.title",
        summary: "help.article.truckFleetDriveTimeReport.summary",
        tags: [tags.truck, tags.reports],
    }),
    operatorTeamOperators: createArticle({
        filename: "operator-team-operators.md",
        title: "help.article.operatorTeamOperators.title",
        summary: "help.article.operatorTeamOperators.summary",
        tags: [tags.operator, tags.reports],
    }),
    operatorTeamProductivityReport: createArticle({
        filename: "operator-team-productivity-report.md",
        title: "help.article.operatorTeamProductivityReport.title",
        summary: "help.article.operatorTeamProductivityReport.summary",
        tags: [tags.operator, tags.reports],
    }),
    operatorTeamLoginReport: createArticle({
        filename: "operator-team-login-report.md",
        title: "help.article.operatorTeamLoginReport.title",
        summary: "help.article.operatorTeamLoginReport.summary",
        tags: [tags.operator, tags.reports],
    }),
    impactStatisticsReport: createArticle({
        filename: "impact-statistics-report.md",
        title: "help.article.impactStatisticsReport.title",
        summary: "help.article.impactStatisticsReport.summary",
        tags: [tags.truck, tags.reports],
    }),
    maintenanceReport: createArticle({
        filename: "maintenance-report.md",
        title: "help.article.maintenanceReport.title",
        summary: "help.article.maintenanceReport.summary",
        tags: [tags.truck, tags.reports],
    }),
    preOpsChecklistsReport: createArticle({
        filename: "pre-ops-checklists-report.md",
        title: "help.article.preOpsChecklistsReport.title",
        summary: "help.article.preOpsChecklistsReport.summary",
        tags: [tags.reports],
    }),
    safetyMessageReport: createArticle({
        filename: "safety-message-report.md",
        title: "help.article.safetyMessageReport.title",
        summary: "help.article.safetyMessageReport.summary",
        tags: [tags.reports],
    }),
    howToCreateAccessControlGroups: createArticle({
        filename: "howtos/create-access-control-groups.md",
        title: "help.article.howToCreateAccessControlGroups.title",
        summary: "help.article.howToCreateAccessControlGroups.summary",
        tags: [tags.administrator],
        access: ManagementRole.INSTANCE_ADMIN,
    }),
} as const;
