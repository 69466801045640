import { action, computed, makeObservable, observable } from "mobx";
import { external } from "tsdi";

/** Options for creating a new {@link SearchState}. */
export interface SearchStateOptions {
    /** If specified, URL will not be modified or read. */
    readonly searchTerm?: boolean;
}

/**
 * Holds observable state for searching in lists.
 * Can handle Fluent UI's events, patch the props for the list
 * and generate the repository's query.
 */
@external
export class SearchState {
    /** Only used if not read from URL. */
    @observable private _searchTerm: undefined | string;

    constructor() {
        makeObservable(this);
    }

    /** Set the current search state. */
    @action.bound public setSearchState(term: string): void {
        this._searchTerm = term;
    }

    /**
     * Clear the current search term.
     */
    @action.bound public clearSearch(): void {
        // Reset the search if no input is given
        this._searchTerm = undefined;
    }

    /**
     * Fluent UI event handle for `onChange` on search fields.
     */
    @action.bound public setSearchField(_: unknown, newValue?: string): void {
        if (newValue === "") {
            // Reset the search if no input is given
            this._searchTerm = undefined;
        } else if (newValue) {
            this._searchTerm = newValue;
        }
    }

    /**
     * Returns the current search term.
     * `undefined` means that there's no active search.
     */
    @computed private get searchTerm(): undefined | string {
        return this._searchTerm;
    }

    /** Create a search query segment compatible with most repositories' queries. */
    @computed public get query(): { search?: string } {
        const { searchTerm } = this;
        if (!searchTerm) {
            return {};
        }
        return {
            search: searchTerm,
        };
    }
}
