import React from "react";
import css from "./notification-check-box.scss";

export type NavigationCheckBoxProps = {
    hover?: boolean;
    onClick?: () => void;
};

export class NotificationCheckBox extends React.Component<NavigationCheckBoxProps> {
    constructor(props: NavigationCheckBoxProps) {
        super(props);
    }

    render(): JSX.Element {
        return (
            <div className={css.notificationCheckBox__container} onClick={this.props.onClick}>
                <div className={css.notificationCheckBox__empty}>
                    <div className={css.notificationCheckBox__active} />
                </div>
            </div>
        );
    }
}
