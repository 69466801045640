/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PageInfo,
  SortDirection,
  VehicleDevice,
  VehicleDeviceCreate,
  VehicleDeviceSortKey,
  VehicleDeviceStatus,
  VehicleDeviceUpdate,
  VehicleDeviceVersionUpdate,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    VehicleDeviceFromJSON,
    VehicleDeviceToJSON,
    VehicleDeviceCreateFromJSON,
    VehicleDeviceCreateToJSON,
    VehicleDeviceSortKeyFromJSON,
    VehicleDeviceSortKeyToJSON,
    VehicleDeviceStatusFromJSON,
    VehicleDeviceStatusToJSON,
    VehicleDeviceUpdateFromJSON,
    VehicleDeviceUpdateToJSON,
    VehicleDeviceVersionUpdateFromJSON,
    VehicleDeviceVersionUpdateToJSON,
} from '../models';

export interface VehicleDevicesCreateVehicleDeviceRequest {
    vehicleDeviceCreate: VehicleDeviceCreate;
}

export interface VehicleDevicesDeleteVehicleDeviceRequest {
    vehicleDeviceId: string;
}

export interface VehicleDevicesListVehicleDevicesRequest {
    pageSize: number;
    search?: string | null;
    status?: VehicleDeviceStatus;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: VehicleDeviceSortKey;
}

export interface VehicleDevicesListVehicleDevicesMetadataRequest {
    pageSize: number;
    search?: string | null;
    status?: VehicleDeviceStatus;
}

export interface VehicleDevicesReadVehicleDeviceRequest {
    vehicleDeviceId: string;
}

export interface VehicleDevicesReadVehicleDeviceByVehicleRequest {
    vehicleId: string;
}

export interface VehicleDevicesUpdateVehicleDeviceRequest {
    vehicleDeviceId: string;
    vehicleDeviceUpdate: VehicleDeviceUpdate;
}

export interface VehicleDevicesUpdateVehicleDeviceVersionRequest {
    vehicleDeviceVersionUpdate: VehicleDeviceVersionUpdate;
}

export interface VehicleDevicesValidateCreateVehicleDeviceRequest {
    vehicleDeviceCreate: VehicleDeviceCreate;
}

export interface VehicleDevicesValidateUpdateVehicleDeviceRequest {
    vehicleDeviceId: string;
    vehicleDeviceUpdate: VehicleDeviceUpdate;
}

/**
 * VehicleDevicesApi - interface
 * 
 * @export
 * @interface VehicleDevicesApiInterface
 */
export interface VehicleDevicesApiInterface {
    /**
     * Create a new vehicle device.
     * @summary Create a vehicle device
     * @param {VehicleDeviceCreate} vehicleDeviceCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDevicesApiInterface
     */
    vehicleDevicesCreateVehicleDeviceRaw(requestParameters: VehicleDevicesCreateVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleDevice>>;

    /**
     * Create a new vehicle device.
     * Create a vehicle device
     */
    vehicleDevicesCreateVehicleDevice(requestParameters: VehicleDevicesCreateVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleDevice>;

    /**
     * Delete the vehicle device with the given ID.
     * @summary Delete a vehicle device
     * @param {string} vehicleDeviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDevicesApiInterface
     */
    vehicleDevicesDeleteVehicleDeviceRaw(requestParameters: VehicleDevicesDeleteVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the vehicle device with the given ID.
     * Delete a vehicle device
     */
    vehicleDevicesDeleteVehicleDevice(requestParameters: VehicleDevicesDeleteVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Lists all the vehicle devices present. If `search` is specified, return values similar to the `serial_number` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * @summary List vehicle devices
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {VehicleDeviceStatus} [status] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {VehicleDeviceSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDevicesApiInterface
     */
    vehicleDevicesListVehicleDevicesRaw(requestParameters: VehicleDevicesListVehicleDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleDevice>>>;

    /**
     * Lists all the vehicle devices present. If `search` is specified, return values similar to the `serial_number` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List vehicle devices
     */
    vehicleDevicesListVehicleDevices(requestParameters: VehicleDevicesListVehicleDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleDevice>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary Vehicle device listing metadata
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {VehicleDeviceStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDevicesApiInterface
     */
    vehicleDevicesListVehicleDevicesMetadataRaw(requestParameters: VehicleDevicesListVehicleDevicesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * Vehicle device listing metadata
     */
    vehicleDevicesListVehicleDevicesMetadata(requestParameters: VehicleDevicesListVehicleDevicesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the vehicle device specified by the given ID.
     * @summary Get a single vehicle device
     * @param {string} vehicleDeviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDevicesApiInterface
     */
    vehicleDevicesReadVehicleDeviceRaw(requestParameters: VehicleDevicesReadVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleDevice>>;

    /**
     * Returns the vehicle device specified by the given ID.
     * Get a single vehicle device
     */
    vehicleDevicesReadVehicleDevice(requestParameters: VehicleDevicesReadVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleDevice>;

    /**
     * Returns the vehicle device specified by the given Vehicle ID.
     * @summary Get a single vehicle device by its assigned vehicle
     * @param {string} vehicleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDevicesApiInterface
     */
    vehicleDevicesReadVehicleDeviceByVehicleRaw(requestParameters: VehicleDevicesReadVehicleDeviceByVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleDevice>>;

    /**
     * Returns the vehicle device specified by the given Vehicle ID.
     * Get a single vehicle device by its assigned vehicle
     */
    vehicleDevicesReadVehicleDeviceByVehicle(requestParameters: VehicleDevicesReadVehicleDeviceByVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleDevice>;

    /**
     * Update the vehicle_device specified by the given ID.
     * @summary Update vehicle_device
     * @param {string} vehicleDeviceId 
     * @param {VehicleDeviceUpdate} vehicleDeviceUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDevicesApiInterface
     */
    vehicleDevicesUpdateVehicleDeviceRaw(requestParameters: VehicleDevicesUpdateVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleDevice>>;

    /**
     * Update the vehicle_device specified by the given ID.
     * Update vehicle_device
     */
    vehicleDevicesUpdateVehicleDevice(requestParameters: VehicleDevicesUpdateVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleDevice>;

    /**
     * Update only versioning related vehicle_device data based on the elobox associated with the vehicle token used for logging in.  This solution is also pretty clear, as the vehicle device is otherwise not allowed to edit any information about itself, making this a very sturdy solution.
     * @summary Allow a vehicle to update its own vehicle_device.
     * @param {VehicleDeviceVersionUpdate} vehicleDeviceVersionUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDevicesApiInterface
     */
    vehicleDevicesUpdateVehicleDeviceVersionRaw(requestParameters: VehicleDevicesUpdateVehicleDeviceVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleDevice>>;

    /**
     * Update only versioning related vehicle_device data based on the elobox associated with the vehicle token used for logging in.  This solution is also pretty clear, as the vehicle device is otherwise not allowed to edit any information about itself, making this a very sturdy solution.
     * Allow a vehicle to update its own vehicle_device.
     */
    vehicleDevicesUpdateVehicleDeviceVersion(requestParameters: VehicleDevicesUpdateVehicleDeviceVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleDevice>;

    /**
     * Validate data for a new vehicle device without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a vehicle device
     * @param {VehicleDeviceCreate} vehicleDeviceCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDevicesApiInterface
     */
    vehicleDevicesValidateCreateVehicleDeviceRaw(requestParameters: VehicleDevicesValidateCreateVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new vehicle device without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a vehicle device
     */
    vehicleDevicesValidateCreateVehicleDevice(requestParameters: VehicleDevicesValidateCreateVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate data for updating the given vehicle device, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate vehicle device update
     * @param {string} vehicleDeviceId 
     * @param {VehicleDeviceUpdate} vehicleDeviceUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleDevicesApiInterface
     */
    vehicleDevicesValidateUpdateVehicleDeviceRaw(requestParameters: VehicleDevicesValidateUpdateVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for updating the given vehicle device, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate vehicle device update
     */
    vehicleDevicesValidateUpdateVehicleDevice(requestParameters: VehicleDevicesValidateUpdateVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class VehicleDevicesApi extends runtime.BaseAPI implements VehicleDevicesApiInterface {

    /**
     * Create a new vehicle device.
     * Create a vehicle device
     */
    async vehicleDevicesCreateVehicleDeviceRaw(requestParameters: VehicleDevicesCreateVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleDevice>> {
        if (requestParameters.vehicleDeviceCreate === null || requestParameters.vehicleDeviceCreate === undefined) {
            throw new runtime.RequiredError('vehicleDeviceCreate','Required parameter requestParameters.vehicleDeviceCreate was null or undefined when calling vehicleDevicesCreateVehicleDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_devices/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleDeviceCreateToJSON(requestParameters.vehicleDeviceCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleDeviceFromJSON(jsonValue));
    }

    /**
     * Create a new vehicle device.
     * Create a vehicle device
     */
    async vehicleDevicesCreateVehicleDevice(requestParameters: VehicleDevicesCreateVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleDevice> {
        const response = await this.vehicleDevicesCreateVehicleDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the vehicle device with the given ID.
     * Delete a vehicle device
     */
    async vehicleDevicesDeleteVehicleDeviceRaw(requestParameters: VehicleDevicesDeleteVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.vehicleDeviceId === null || requestParameters.vehicleDeviceId === undefined) {
            throw new runtime.RequiredError('vehicleDeviceId','Required parameter requestParameters.vehicleDeviceId was null or undefined when calling vehicleDevicesDeleteVehicleDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_devices/{vehicle_device_id}`.replace(`{${"vehicle_device_id"}}`, encodeURIComponent(String(requestParameters.vehicleDeviceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the vehicle device with the given ID.
     * Delete a vehicle device
     */
    async vehicleDevicesDeleteVehicleDevice(requestParameters: VehicleDevicesDeleteVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vehicleDevicesDeleteVehicleDeviceRaw(requestParameters, initOverrides);
    }

    /**
     * Lists all the vehicle devices present. If `search` is specified, return values similar to the `serial_number` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List vehicle devices
     */
    async vehicleDevicesListVehicleDevicesRaw(requestParameters: VehicleDevicesListVehicleDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleDevice>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling vehicleDevicesListVehicleDevices.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_devices/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleDeviceFromJSON));
    }

    /**
     * Lists all the vehicle devices present. If `search` is specified, return values similar to the `serial_number` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List vehicle devices
     */
    async vehicleDevicesListVehicleDevices(requestParameters: VehicleDevicesListVehicleDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleDevice>> {
        const response = await this.vehicleDevicesListVehicleDevicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * Vehicle device listing metadata
     */
    async vehicleDevicesListVehicleDevicesMetadataRaw(requestParameters: VehicleDevicesListVehicleDevicesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling vehicleDevicesListVehicleDevicesMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_devices/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * Vehicle device listing metadata
     */
    async vehicleDevicesListVehicleDevicesMetadata(requestParameters: VehicleDevicesListVehicleDevicesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.vehicleDevicesListVehicleDevicesMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the vehicle device specified by the given ID.
     * Get a single vehicle device
     */
    async vehicleDevicesReadVehicleDeviceRaw(requestParameters: VehicleDevicesReadVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleDevice>> {
        if (requestParameters.vehicleDeviceId === null || requestParameters.vehicleDeviceId === undefined) {
            throw new runtime.RequiredError('vehicleDeviceId','Required parameter requestParameters.vehicleDeviceId was null or undefined when calling vehicleDevicesReadVehicleDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_devices/{vehicle_device_id}`.replace(`{${"vehicle_device_id"}}`, encodeURIComponent(String(requestParameters.vehicleDeviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleDeviceFromJSON(jsonValue));
    }

    /**
     * Returns the vehicle device specified by the given ID.
     * Get a single vehicle device
     */
    async vehicleDevicesReadVehicleDevice(requestParameters: VehicleDevicesReadVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleDevice> {
        const response = await this.vehicleDevicesReadVehicleDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the vehicle device specified by the given Vehicle ID.
     * Get a single vehicle device by its assigned vehicle
     */
    async vehicleDevicesReadVehicleDeviceByVehicleRaw(requestParameters: VehicleDevicesReadVehicleDeviceByVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleDevice>> {
        if (requestParameters.vehicleId === null || requestParameters.vehicleId === undefined) {
            throw new runtime.RequiredError('vehicleId','Required parameter requestParameters.vehicleId was null or undefined when calling vehicleDevicesReadVehicleDeviceByVehicle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_devices/by/vehicle/{vehicle_id}`.replace(`{${"vehicle_id"}}`, encodeURIComponent(String(requestParameters.vehicleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleDeviceFromJSON(jsonValue));
    }

    /**
     * Returns the vehicle device specified by the given Vehicle ID.
     * Get a single vehicle device by its assigned vehicle
     */
    async vehicleDevicesReadVehicleDeviceByVehicle(requestParameters: VehicleDevicesReadVehicleDeviceByVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleDevice> {
        const response = await this.vehicleDevicesReadVehicleDeviceByVehicleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the vehicle_device specified by the given ID.
     * Update vehicle_device
     */
    async vehicleDevicesUpdateVehicleDeviceRaw(requestParameters: VehicleDevicesUpdateVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleDevice>> {
        if (requestParameters.vehicleDeviceId === null || requestParameters.vehicleDeviceId === undefined) {
            throw new runtime.RequiredError('vehicleDeviceId','Required parameter requestParameters.vehicleDeviceId was null or undefined when calling vehicleDevicesUpdateVehicleDevice.');
        }

        if (requestParameters.vehicleDeviceUpdate === null || requestParameters.vehicleDeviceUpdate === undefined) {
            throw new runtime.RequiredError('vehicleDeviceUpdate','Required parameter requestParameters.vehicleDeviceUpdate was null or undefined when calling vehicleDevicesUpdateVehicleDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_devices/{vehicle_device_id}`.replace(`{${"vehicle_device_id"}}`, encodeURIComponent(String(requestParameters.vehicleDeviceId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleDeviceUpdateToJSON(requestParameters.vehicleDeviceUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleDeviceFromJSON(jsonValue));
    }

    /**
     * Update the vehicle_device specified by the given ID.
     * Update vehicle_device
     */
    async vehicleDevicesUpdateVehicleDevice(requestParameters: VehicleDevicesUpdateVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleDevice> {
        const response = await this.vehicleDevicesUpdateVehicleDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update only versioning related vehicle_device data based on the elobox associated with the vehicle token used for logging in.  This solution is also pretty clear, as the vehicle device is otherwise not allowed to edit any information about itself, making this a very sturdy solution.
     * Allow a vehicle to update its own vehicle_device.
     */
    async vehicleDevicesUpdateVehicleDeviceVersionRaw(requestParameters: VehicleDevicesUpdateVehicleDeviceVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleDevice>> {
        if (requestParameters.vehicleDeviceVersionUpdate === null || requestParameters.vehicleDeviceVersionUpdate === undefined) {
            throw new runtime.RequiredError('vehicleDeviceVersionUpdate','Required parameter requestParameters.vehicleDeviceVersionUpdate was null or undefined when calling vehicleDevicesUpdateVehicleDeviceVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_devices/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleDeviceVersionUpdateToJSON(requestParameters.vehicleDeviceVersionUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleDeviceFromJSON(jsonValue));
    }

    /**
     * Update only versioning related vehicle_device data based on the elobox associated with the vehicle token used for logging in.  This solution is also pretty clear, as the vehicle device is otherwise not allowed to edit any information about itself, making this a very sturdy solution.
     * Allow a vehicle to update its own vehicle_device.
     */
    async vehicleDevicesUpdateVehicleDeviceVersion(requestParameters: VehicleDevicesUpdateVehicleDeviceVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleDevice> {
        const response = await this.vehicleDevicesUpdateVehicleDeviceVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new vehicle device without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a vehicle device
     */
    async vehicleDevicesValidateCreateVehicleDeviceRaw(requestParameters: VehicleDevicesValidateCreateVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.vehicleDeviceCreate === null || requestParameters.vehicleDeviceCreate === undefined) {
            throw new runtime.RequiredError('vehicleDeviceCreate','Required parameter requestParameters.vehicleDeviceCreate was null or undefined when calling vehicleDevicesValidateCreateVehicleDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_devices/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleDeviceCreateToJSON(requestParameters.vehicleDeviceCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new vehicle device without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a vehicle device
     */
    async vehicleDevicesValidateCreateVehicleDevice(requestParameters: VehicleDevicesValidateCreateVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vehicleDevicesValidateCreateVehicleDeviceRaw(requestParameters, initOverrides);
    }

    /**
     * Validate data for updating the given vehicle device, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate vehicle device update
     */
    async vehicleDevicesValidateUpdateVehicleDeviceRaw(requestParameters: VehicleDevicesValidateUpdateVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.vehicleDeviceId === null || requestParameters.vehicleDeviceId === undefined) {
            throw new runtime.RequiredError('vehicleDeviceId','Required parameter requestParameters.vehicleDeviceId was null or undefined when calling vehicleDevicesValidateUpdateVehicleDevice.');
        }

        if (requestParameters.vehicleDeviceUpdate === null || requestParameters.vehicleDeviceUpdate === undefined) {
            throw new runtime.RequiredError('vehicleDeviceUpdate','Required parameter requestParameters.vehicleDeviceUpdate was null or undefined when calling vehicleDevicesValidateUpdateVehicleDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_devices/{vehicle_device_id}/validate`.replace(`{${"vehicle_device_id"}}`, encodeURIComponent(String(requestParameters.vehicleDeviceId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleDeviceUpdateToJSON(requestParameters.vehicleDeviceUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for updating the given vehicle device, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate vehicle device update
     */
    async vehicleDevicesValidateUpdateVehicleDevice(requestParameters: VehicleDevicesValidateUpdateVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vehicleDevicesValidateUpdateVehicleDeviceRaw(requestParameters, initOverrides);
    }

}
