import { range } from "ramda";
import React from "react";
import style from "./line-chart.scss";
import { Shimmer } from "@fluentui/react";

/**
 * A simple line chart based on rendering an SVG image as a react component.
 * It's laid out with the following components (all defined in this file):
 *
 * LineChart (grid layout)
 *   - Vertical Y Axis Scale (flexbox layout)
 *   - SVG image (pixel-pased fixed layout)
 *     - Y Axis guide counts - horizontal grey lines in the background
 *     - ChartLines
 *   - Horizontal X Axis Scale (flexbox layout)
 *      - X Axis guide counts - small grey marker lines shown above the x axis scale labels
 */

// [x, y] coordinates
export type Point = [number, number];

export interface Line {
    // in hex format
    color: string;
    points: Point[];
    label?: string;
}

// "Global" Settings for layouting the whole chart
interface Metadata {
    // bounds of the coordinate system inside the SVG element.
    // After rendering, this viewbox will be mapped to the actual width and height of the SVG element.
    viewbox: Point;
    // Number of grey background lines to display
    guideCounts: {
        xAxis: number;
        yAxis: number;
    };
    // Padding inside the SVG, around the whole chart
    padding: {
        left: number;
        right: number;
        top: number;
        bottom: number;
    };
    // Padding around the actual colored lines
    linePadding: number;
}

interface Props {
    lines: Line[];
    formatXValue?: (value: number) => string;
    showScales?: boolean;
    showBackgroundGuides?: boolean;
    lineThickness?: number;
    // X / Y ratio of the SVG image
    aspectRatio?: number;
    loading?: boolean;
}

export function LineChart({
    showScales = true,
    showBackgroundGuides = true,
    aspectRatio = 10 / 3,
    ...props
}: Props): JSX.Element {
    const meta: Metadata = {
        viewbox: [150 * aspectRatio, 150] as Point,
        guideCounts: {
            xAxis: 4,
            yAxis: 6,
        },
        padding: { left: 10, right: 0, top: 10, bottom: 10 },
        linePadding: 30,
    };

    const { lines, valueBounds, newYAxisGuideCount } = normalize(
        props.lines,
        meta,
        showBackgroundGuides,
    );
    meta.guideCounts.yAxis = newYAxisGuideCount;

    return (
        <div className={style.lineChart}>
            {showScales && <YAxisScale valueBounds={valueBounds} meta={meta} />}
            {/* If data still loads, display an empty SVG and place a <Shimmer />
                perfectly above. */}
            {props.loading ? (
                <>
                    <svg
                        viewBox={`-${meta.padding.left} -${meta.padding.top} ${
                            meta.padding.left + meta.padding.right + meta.viewbox[0]
                        } ${meta.padding.top + meta.padding.bottom + meta.viewbox[1]}`}
                    />
                    <Shimmer
                        style={{
                            gridArea: "chart",
                        }}
                        styles={{
                            shimmerWrapper: {
                                height: "100%",
                            },
                        }}
                    />
                </>
            ) : (
                <svg
                    viewBox={`-${meta.padding.left} -${meta.padding.top} ${
                        meta.padding.left + meta.padding.right + meta.viewbox[0]
                    } ${meta.padding.top + meta.padding.bottom + meta.viewbox[1]}`}
                >
                    {showBackgroundGuides &&
                        range(0, meta.guideCounts.yAxis).map((i) => {
                            const isLastLine = i === meta.guideCounts.yAxis - 1;
                            const color = isLastLine
                                ? style.xAxisScaleColor
                                : style.backgroundLineColor;
                            return (
                                <BackgroundGuide
                                    y={(i * meta.viewbox[1]) / (meta.guideCounts.yAxis - 1)}
                                    key={i}
                                    color={color}
                                    viewbox={meta.viewbox}
                                    thickness={(props.lineThickness ?? 3) / 6}
                                />
                            );
                        })}
                    {lines.map((line) => (
                        // fixme: colors might be equal for different lines, so we need to find a better value as key
                        <ChartLine
                            points={line.points}
                            color={line.color}
                            thickness={props.lineThickness ?? 3}
                            key={line.label ?? line.color}
                        />
                    ))}
                </svg>
            )}
            {showScales && !props.loading && (
                <XAxisScale valueBounds={valueBounds} formatter={props.formatXValue} meta={meta} />
            )}
            <Legend lines={lines} loading={props.loading} />
        </div>
    );
}

/**
 * Re-scale all points to fill the rectangle defined by
 * `viewbox`.
 * @returns Both the lines with normalized values, as well as the minimum and maximum bounds for all values shown on the chart.
 */
function normalize(
    lines: Line[],
    { viewbox, linePadding, guideCounts }: Metadata,
    showBackgroundGuides: boolean,
): { lines: Line[]; valueBounds: { min: Point; max: Point }; newYAxisGuideCount: number } {
    let minX = Infinity;
    let maxX = -Infinity;
    let minY = Infinity;
    let maxY = -Infinity;
    lines.forEach((line) => {
        line.points.forEach(([x, y]) => {
            minX = Math.min(x, minX);
            maxX = Math.max(x, maxX);
            minY = Math.min(y, minY);
            maxY = Math.max(y, maxY);
        });
    });

    // If all values are the same, add some artificial room to the chart to get
    // reasonable values for the y axis scale.
    if (minY === maxY) {
        maxY += 1;
    }

    // By default, don't change the y axis guide count.
    let newYAxisGuideCount = guideCounts.yAxis;

    // If guides are visible, show a whole number on each y axis guide.
    if (showBackgroundGuides) {
        const yValueRange = maxY - minY;
        if (yValueRange <= 6) {
            // The whole chart displays values in a range that's 6 or smaller,
            // So we can just reduce the number of guides to be equal to the
            // range of values.
            newYAxisGuideCount = Math.ceil(yValueRange) + 1;
        } else {
            // Extend the range of values displayed to be a multiple of the
            // default guide count(minus one, to divide by the space between the guide counts).
            const spacesBetweenGuides = guideCounts.yAxis - 1;
            const newYRange = Math.ceil(yValueRange / spacesBetweenGuides) * spacesBetweenGuides;
            maxY = minY + newYRange;
        }
    }

    const lineBounds = {
        x: viewbox[0] - linePadding * 2,
        y: viewbox[1],
    };

    return {
        lines: lines
            .map((line) => {
                // Discard lines that don't have any points.
                if (line.points.length === 0) {
                    return null;
                }

                const points = line.points.map(([x, y]): Point => {
                    let newX = (lineBounds.x * (x - minX)) / (maxX - minX);
                    let newY = (lineBounds.y * (y - minY)) / (maxY - minY);
                    // Invert y value because the SVG coordinate system
                    // Starts at the top-left corner
                    newY = lineBounds.y - newY;
                    // apply padding
                    newX = newX + linePadding;
                    return [newX, newY];
                });

                return {
                    ...line,
                    points,
                };
            })
            .filter((line): line is Line => line !== null),
        valueBounds: {
            min: [minX, minY],
            max: [maxX, maxY],
        },
        newYAxisGuideCount,
    };
}

function XAxisScale({
    meta: { guideCounts, padding, linePadding, viewbox },
    ...props
}: {
    valueBounds: { min: Point; max: Point };
    formatter?: (value: number) => string;
    meta: Metadata;
}): JSX.Element {
    const formatter = props.formatter ?? String;
    const valueRange = props.valueBounds.max[0] - props.valueBounds.min[0];
    // Calculate numbers to be displayed on the scale
    const numbers = range(0, guideCounts.xAxis).map(
        (i) => (i * valueRange) / (guideCounts.xAxis - 1) + props.valueBounds.min[0],
    );

    // Calculate margin to match the start- and endpoints of the lines inside the SVG
    const marginLeft =
        (100 * (padding.left + linePadding)) / (viewbox[0] + padding.left + padding.right);
    const marginRight =
        (100 * (padding.right + linePadding)) / (viewbox[0] + padding.left + padding.right);

    return (
        <div
            className={style.lineChart__xAxisScale}
            style={{ marginLeft: `${marginLeft}%`, marginRight: `${marginRight}%` }}
        >
            {numbers.map((number) => (
                <div key={number} className={style.lineChart__xAxisScale__guide}>
                    <span>{formatter(number)}</span>
                </div>
            ))}
        </div>
    );
}

function YAxisScale({
    meta: { guideCounts },
    valueBounds,
}: {
    valueBounds: { min: Point; max: Point };
    meta: Metadata;
}): JSX.Element {
    const valueRange = valueBounds.max[1] - valueBounds.min[1];
    // Calculate numbers to be displayed on the scale
    const numbers = range(0, guideCounts.yAxis).map(
        (i) => (i * valueRange) / (guideCounts.yAxis - 1) + valueBounds.min[1],
    );

    return (
        <div className={style.lineChart__yAxisScale}>
            {numbers.map((number) => (
                <span key={number}>{String(number)}</span>
            ))}
        </div>
    );
}

/**
 * Draw a line representing data in the chart.
 */
function ChartLine(props: { color: string; points: Point[]; thickness: number }): JSX.Element {
    const points = props.points.map(([x, y]) => `${x},${y}`).join(" ");
    return (
        <polyline
            fill="none"
            stroke={props.color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={props.thickness}
            points={points}
        />
    );
}

/**
 * Draw the grey guidelines behind the actual chart.
 * These need to be aligned with the numbers in the y axis.
 */
function BackgroundGuide(props: {
    y: number;
    color: string;
    viewbox: Point;
    thickness: number;
}): JSX.Element {
    return (
        <line
            stroke={props.color}
            x1="0"
            y1={props.y}
            x2={props.viewbox[0]}
            y2={props.y}
            strokeWidth={props.thickness}
        />
    );
}

function Legend({ lines, loading }: { lines: Line[]; loading?: boolean }): JSX.Element {
    // If data still loads, render an invisible legend and place a <Shimmer />
    // perfectly above.
    if (loading) {
        return (
            <>
                <div className={style.lineChart__legend}>{"..."}</div>
                <Shimmer
                    className={style.lineChart__legend}
                    style={{
                        gridArea: "legend",
                    }}
                    styles={{
                        shimmerWrapper: {
                            height: "100%",
                        },
                    }}
                />
            </>
        );
    }
    const labels = lines
        .filter((line) => typeof line.label === "string")
        .map((line) => {
            return (
                <div key={line.label}>
                    <span
                        className={style.lineChart__legend__legendMarker}
                        style={{ borderColor: line.color }}
                    />
                    <span>{line.label}</span>
                </div>
            );
        });
    if (labels.length > 0) {
        return <div className={style.lineChart__legend}>{labels}</div>;
    } else {
        return <></>;
    }
}
