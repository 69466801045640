import style from "./dashboard-cockpit.scss";
import React from "react";
import { DashboardStatusCircle } from "../atoms/dashboard-status-circle";
import { DashboardCard } from "../atoms/dashboard-card";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { Dashboard } from "../../api";
import { tsdi } from "../../tsdi";
import { ServiceOwnUser } from "../../domain/services/service-own-user";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { routePath } from "../../routes";
import { DashboardCardHeader } from "../atoms/dashboard-card";
import { BigStatNumber } from "../atoms/big-stat-number";
import { Shimmer, Text } from "@fluentui/react";

type User = { name: string };
interface Props {
    data?: Dashboard;
    bar?: User;
    tutorialId: string;
}

export function DashboardCockpit({ data, ...props }: Props): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    const serviceOwnUser = tsdi.get(ServiceOwnUser);
    const i18nProvider = tsdi.get(I18nProvider);
    const loading = data === undefined;
    const userName = i18nProvider.formatUserFullName(serviceOwnUser.user);
    const severeImpactsColor = (data?.severeImpactsToday ?? 0) > 0 ? "red" : "green";
    const licensesExpiringColor = (data?.licensesExpiringNextMonth ?? 0) > 0 ? "red" : "green";
    const overdueColor = (data?.vehiclesOverdueForMaintenanceNow ?? 0) > 0 ? "red" : "green";
    const utilizationColor = (data?.meanVehicleUtilization ?? 0) > 0.5 ? "green" : "red";
    const totalOperatingHours =
        typeof data?.totalOperatingSecondsToday === "number"
            ? Math.floor(data?.totalOperatingSecondsToday / 3600).toString()
            : undefined;
    const meanVehicleUtilization =
        typeof data?.meanVehicleUtilization === "number"
            ? `${Math.round(data.meanVehicleUtilization * 100)}%`
            : "";

    return (
        <div className={style.dashboardCockpit}>
            <div className={style.dashboardCockpit__grid}>
                <DashboardStatusCircle
                    statistic={data?.severeImpactsToday.toString() ?? ""}
                    description={t("page.dashboard.severeImpactsToday")}
                    color={severeImpactsColor}
                    link={routePath.impactManagement()}
                    loading={loading}
                />
                <DashboardStatusCircle
                    statistic={meanVehicleUtilization === "0%" ? "-" : meanVehicleUtilization}
                    description={t("page.dashboard.currentMeanTruckUtilization")}
                    color={utilizationColor}
                    link={routePath.vehicleProductivity()}
                    loading={loading}
                    tooltipTranslationKey={"page.dashboard.currentMeanTruckUtilization.explanation"}
                />
                <DashboardCard
                    id={props.tutorialId}
                    className={style.dashboardCockpit__card}
                    elevated
                    maximizable={false}
                >
                    <DashboardCardHeader greyBackground>
                        <Text variant="medium">
                            <>{t("page.dashboard.welcome")},</>
                        </Text>
                        <br />
                        {loading ? <UsernameShimmer /> : userName}
                    </DashboardCardHeader>
                    <div className={style["dashboardCockpit__card__statsGrid"]}>
                        <div>
                            <>
                                <BigStatNumber>{data?.activeVehiclesToday}</BigStatNumber>
                                {t("page.dashboard.vehiclesActiveToday")}
                            </>
                        </div>
                        <div>
                            <>
                                <BigStatNumber>{data?.totalFleetUsersLoggedInToday}</BigStatNumber>
                                {t("page.dashboard.driversLoggedInToday")}
                            </>
                        </div>
                        <div>
                            <>
                                <BigStatNumber>{totalOperatingHours}</BigStatNumber>
                                {t("page.dashboard.drivingHoursToday")}
                            </>
                        </div>
                        <div>
                            <>
                                <BigStatNumber>{data?.vehiclesInMaintenanceNow}</BigStatNumber>
                                {t("page.dashboard.vehiclesInMaintenanceNow")}
                            </>
                        </div>
                    </div>
                </DashboardCard>
                <DashboardStatusCircle
                    statistic={data?.licensesExpiringNextMonth?.toString() ?? ""}
                    description={t("page.dashboard.invalidDriverLicenses")}
                    color={licensesExpiringColor}
                    link={routePath.users()}
                    loading={loading}
                />
                <DashboardStatusCircle
                    statistic={data?.vehiclesOverdueForMaintenanceNow?.toString() ?? ""}
                    description={t("page.dashboard.overdueForMaintenance")}
                    color={overdueColor}
                    link={routePath.maintenance()}
                    loading={loading}
                />
            </div>
            <div className={style.dashboardCockpit__backgroundRibbons}></div>
        </div>
    );
}

/** Returns a FluentUI <Shimmer /> placed perfectly above a loading username.*/
function UsernameShimmer(): JSX.Element {
    return (
        <div style={{ position: "relative" }}>
            <div style={{ visibility: "hidden" }}>{"..."}</div>
            <Shimmer
                className={style.dashboardCockpit__usernameShimmer}
                styles={{
                    shimmerWrapper: {
                        width: "50%",
                    },
                }}
            />
        </div>
    );
}
