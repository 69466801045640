import React, { ReactNode } from "react";
import css from "./dashboard-table.scss";

export interface Props {
    children: ReactNode;
}

export function Table({ children }: Props): JSX.Element {
    return (
        <div className={css.dashboardTableContainer}>
            <table className={css.dashboardTable}>{children}</table>
        </div>
    );
}

export interface CellProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLTableCellElement>,
        HTMLTableCellElement
    > {
    compact?: boolean;
    border?: boolean;
}

export function Cell({ children, border, compact, ...otherProps }: CellProps): JSX.Element {
    const borderClass = border ? css["dashboardTable__cell--withBorder"] : "";
    const compactClass = compact ? css["dashboardTable__cell--compact"] : "";
    return (
        <td className={[borderClass, compactClass].join(" ")} {...otherProps}>
            {children}
        </td>
    );
}

export function HeaderCell({
    children,
    border = false,
    compact = false,
    ...otherProps
}: CellProps): JSX.Element {
    const borderClass = border ? css["dashboardTable__cell--withBorder"] : "";
    const compactClass = compact ? css["dashboardTable__cell--compact"] : "";
    return (
        <th className={[borderClass, compactClass].join(" ")} {...otherProps}>
            {children}
        </th>
    );
}

export function Head({
    children,
    ...otherProps
}: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
>): JSX.Element {
    return <thead {...otherProps}>{children}</thead>;
}

export function Body({
    children,
    ...otherProps
}: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
>): JSX.Element {
    return <tbody {...otherProps}>{children}</tbody>;
}

export function Row({
    children,
    ...otherProps
}: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
>): JSX.Element {
    return <tr {...otherProps}>{children}</tr>;
}
