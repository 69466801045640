/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FleetAuthenticatePeriodSortKey {
    EVENT_ID = 'eventId',
    USER_EMPLOYEE_ID = 'userEmployeeId',
    VEHICLE_SERIAL_NUMBER = 'vehicleSerialNumber',
    LOGGED_IN_TIME = 'loggedInTime',
    LOGGED_OUT_TIME = 'loggedOutTime'
}


export function FleetAuthenticatePeriodSortKeyFromJSON(json: any): FleetAuthenticatePeriodSortKey {
    return FleetAuthenticatePeriodSortKeyFromJSONTyped(json, false);
}

export function FleetAuthenticatePeriodSortKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FleetAuthenticatePeriodSortKey {
    return json as FleetAuthenticatePeriodSortKey;
}

export function FleetAuthenticatePeriodSortKeyToJSON(value?: FleetAuthenticatePeriodSortKey | null): any {
    return value as any;
}

