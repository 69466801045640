import { debounce } from "debounce";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { clone, equals, pipe } from "ramda";
import { ErrorResponseFromJSON, ResponseError, ValidationErrorsKind } from "../api";
import { UUID } from "./uuid";

/**
 * Represents the current status of a validation.
 * This can address either full models or individual fields of a model.
 */
export enum ValidationStatus {
    /** The validation is performed remotely and currently loading. */
    LOADING = "loading",
    /** The validation succeeded. */
    VALID = "valid",
    /** The validation failed, the value is not valid. */
    INVALID = "invalid",
    /** Special case of a failed validation when working with lists. */
    INVALID_LIST = "invalid_list",
    /**
     * The field (or model) was not yet modified by the user and validity
     * cannot be determined yet.
     */
    UNTOUCHED = "untouched",
}

/** Internal state used to store validation for a whole model. */
export type ValidationState<TModel, TIdType = undefined> = {
    /** The validation is currently loading (remote request is in progress). */
    loading: boolean;

    /** The transformed answer from the backend for the last performed validation request. */
    remoteValidationResult?: RemoteValidationResult<TModel>;

    /** A list of all fields that the user has changed from the initial value at least once. */
    touchedFields: Set<keyof TModel>;

    /** The initial model with which the validation was first initialized. */
    readonly initialModel: TModel;

    /** Some validation requests will require an additional model id. */
    readonly modelId: TIdType;
};

/**
 * Represents an individual problem (think: "error", but this term is reserved for real application errors)
 * with a value a user provided.
 */
export interface ValidationProblem {
    /** The code of the error. Can be used for i18n. */
    readonly code: string;

    /** An additional human-readable message from the backend specifying the error in more detail. */
    readonly message?: string;
    /**
     * Additional params provided by the backend that explain the problem in more details.
     * Can be used to add more information to the i18n message.
     */
    readonly params: {
        [key: string]: string | number | boolean;
        value: string;
    };
}

export interface ValidationFieldStateValid {
    readonly status: ValidationStatus.VALID;
}

export interface ValidationFieldStateLoading {
    readonly status: ValidationStatus.LOADING;
}

export interface ValidationFieldStateUntouched {
    readonly status: ValidationStatus.UNTOUCHED;
}

export interface ValidationFieldStateInvalid {
    readonly status: ValidationStatus.INVALID;
    readonly problems: ValidationProblem[];
}

// This type is used to represent a list of fields of which one more multiple may be invalid.
export interface ValidationListFieldStateInvalid {
    readonly status: ValidationStatus.INVALID_LIST;
    readonly list: ValidationProblem[][];
}

export type ValidationFieldState =
    | ValidationFieldStateValid
    | ValidationFieldStateUntouched
    | ValidationFieldStateLoading
    | ValidationFieldStateInvalid
    | ValidationListFieldStateInvalid;

export type RemoteValidationResult<TModel> = {
    /**
     * The Backend can specify that a generic error occurred with the model, which cannot be associated with any specific field.
     * Such an error will be specified in this field, if there was any.
     */
    readonly genericProblem?: boolean;
    /** An object following the same structure as the model itself, but each field is an optional {@link ValidationFieldState}. */
    readonly fields: {
        [TKey in keyof TModel]?: ValidationFieldState;
    };
};

/**
 * Takes a set of {@link ValidationStatus}es and will combine them into one.
 * This will follow a prioritization, where valid is the least likely status to be returned.
 */
export function combineValidationStatuses(...statuses: ValidationStatus[]): ValidationStatus {
    if (statuses.includes(ValidationStatus.INVALID)) {
        return ValidationStatus.INVALID;
    }
    if (statuses.includes(ValidationStatus.INVALID_LIST)) {
        return ValidationStatus.INVALID;
    }
    if (statuses.includes(ValidationStatus.LOADING)) {
        return ValidationStatus.LOADING;
    }
    if (statuses.includes(ValidationStatus.UNTOUCHED)) {
        return ValidationStatus.UNTOUCHED;
    }
    return ValidationStatus.VALID;
}

/**
 * A utility for wrapping backend API requests that will extract the validation result.
 * OpenAPI will throw the Response if the validation failed. This will convert this behavior
 * into a promise resolving with the validation result.
 *
 * @param callback The API request must be performed within this callback.
 */
export async function wrapApiValidationRoute<TModel>(
    callback: () => Promise<void>,
): Promise<RemoteValidationResult<TModel>> {
    try {
        await callback();
        return { fields: {} };
    } catch (err) {
        if (err instanceof ResponseError && err.response.status === 422) {
            const response = await err.response.json();
            return convertBackendValidationResult(response);
        }
        throw err;
    }
}

/**
 * Takes a response for a backend validation route and will convert it into the internal
 * {@link RemoteValidationResult} format.
 */
export function convertBackendValidationResult<TModel>(
    response: unknown,
): RemoteValidationResult<TModel> {
    const {
        error: { details },
    } = ErrorResponseFromJSON(response);
    if (details === undefined || details === null) {
        return {
            genericProblem: true,
            fields: {},
        };
    }

    const fields: { [TKey in keyof TModel]?: ValidationFieldState } = {};

    // Iterate over all fields in the validation error response.
    // We currently handle two validation error cases.
    // - A single field, which is the 98% usecase.
    // - A list of fields
    for (const key in details) {
        const field = details[key];
        if (field.list) {
            // Handle the case of a list of fields.
            // Each field can once again have multiple validation errors.
            const validationErrorList: ValidationProblem[][] = [];

            for (const index in field.list) {
                const inner_field_key = Object.keys(field.list[index])[0];
                const inner_field = field.list[index][inner_field_key];
                validationErrorList[parseInt(index)] = mapDetailsToValidationProblems(inner_field);
            }

            fields[key as keyof TModel] = {
                status: ValidationStatus.INVALID_LIST,
                list: validationErrorList,
            };
        } else {
            // Handle the validation on a normal single field.
            fields[key as keyof TModel] = {
                status: ValidationStatus.INVALID,
                problems: mapDetailsToValidationProblems(field),
            };
        }
    }

    return { fields };
}

/**
 * Takes a raw ValidatoinErrorsKind as returned from the backend and converts it into a
 * [ValidationProblem] list for this specific field.
 *
 * This doesn't handle the case, that the field is actually a list of fields!
 */
export function mapDetailsToValidationProblems(details: ValidationErrorsKind): ValidationProblem[] {
    return (details.field || []).map(({ code, message, params }) => ({
        code,
        message: message ?? undefined,
        // The OpenAPI generator infers the wrong type here.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params: params as any,
    }));
}

export type PerformValidationFunction<TModel, TIdType> = (
    model: TModel,
    id: TIdType,
) => Promise<void>;

/**
 * Type of {@link Validation.initializeModel}. This method has a third paramter that can be omitted if the
 * corresponding {@link Validation} class doesn't need a model id.
 */
export type ValidationInitializeModelMethod<TModel, TIdType> = TIdType extends undefined
    ? (id: UUID, initialModel: TModel) => void
    : (id: UUID, initialModel: TModel, modelId: TIdType) => void;

/** Represents the validation for one model. Can handle multiple instances of temporary models. */
export class Validation<TModel extends {} | [], TIdType = undefined> {
    @observable private state = new Map<UUID, ValidationState<TModel, TIdType>>();

    /**
     * @param performValidation A method that will be called to perform the actual validation.
     *     It is expected to either resolve or reject with a Response providing a validation result.
     */
    constructor(protected performValidation: PerformValidationFunction<TModel, TIdType>) {
        makeObservable(this);
    }

    /**
     * Checks if a specific validation state was initialized.
     */
    public isInitialized(id: UUID): boolean {
        return this.state.has(id);
    }

    /**
     * Before validation for a specific id can be performed, it must be initialize
     * with the initial, untouched values for each field before the user has touched anything.
     */
    @action.bound
    public initializeModel = ((id, initialModel, modelId) => {
        if (this.state.has(id)) {
            throw new Error(`Validation for id ${id} was already initialized.`);
        }
        this.state.set(id, {
            loading: false,
            touchedFields: new Set(),
            initialModel,
            modelId,
        } as ValidationState<TModel, TIdType>);

        // This will perform an initial remote validation.
        this.updateModel(id, clone(initialModel));
    }) as ValidationInitializeModelMethod<TModel, TIdType>;

    /** Once the user changed something, this method should be called. */
    @action.bound public updateModel(id: UUID, model: TModel): void {
        const state = this.state.get(id);
        if (!state) {
            throw new Error(`Validation for id ${id} not initialized.`);
        }

        // Iterate all fields on the model and check if they were touched yet.
        (Object.entries(model) as [keyof TModel, TModel[keyof TModel]][]).forEach(
            ([fieldName, newValue]) => {
                // We might have a list of values.
                // If so, check if any of the values changed.
                if (Array.isArray(newValue)) {
                    const initialValues = state.initialModel[fieldName];
                    if (!Array.isArray(initialValues)) {
                        state.touchedFields.add(fieldName);
                        return;
                    }
                    for (const [key, entry] of newValue.entries()) {
                        if (initialValues[key] !== entry) {
                            state.touchedFields.add(fieldName);
                        }
                    }
                    return;
                }

                // We seem to have a single item.
                if (state.initialModel[fieldName] !== newValue) {
                    state.touchedFields.add(fieldName);
                }
            },
        );

        state.loading = true;
        void this.performRemoteValidation(id, model);
    }

    private performRemoteValidation = debounce(async (id: UUID, model: TModel): Promise<void> => {
        const state = this.state.get(id);
        if (!state) {
            throw new Error(`Validation for id ${id} not initialized.`);
        }

        // Start remote validation.
        const remoteValidationResult = await wrapApiValidationRoute(() =>
            this.performValidation(model, state.modelId),
        );
        runInAction(() => {
            state.remoteValidationResult = remoteValidationResult;
            state.loading = false;
        });
    }, 200);

    /** Determine the {@link ValidationStatus} for a whole model. */
    public getModelValidationStatus(id: UUID): ValidationStatus {
        const state = this.state.get(id);
        if (!state) {
            return ValidationStatus.UNTOUCHED;
        }
        if (state.loading) {
            return ValidationStatus.LOADING;
        }
        if (state.remoteValidationResult?.genericProblem) {
            return ValidationStatus.INVALID;
        }

        // Check if there're any invalid fields
        return combineValidationStatuses(
            ...(
                Object.values(state.remoteValidationResult?.fields ?? {}) as ValidationFieldState[]
            ).map(({ status }) => status),
        );
    }

    /** Will return `true` if a model is safe to submit to the backend. */
    public isValid = pipe(this.getModelValidationStatus, equals(ValidationStatus.VALID));

    /** Get the {@link ValidationState} for an individual field of a model. */
    public getFieldValidationState(id: UUID, field: keyof TModel): ValidationFieldState {
        const modelState = this.state.get(id);
        if (!modelState || !modelState.touchedFields.has(field)) {
            return { status: ValidationStatus.UNTOUCHED };
        }
        if (modelState.loading) {
            return { status: ValidationStatus.LOADING };
        }
        const fieldState = modelState.remoteValidationResult?.fields[field];

        if (!fieldState) {
            return { status: ValidationStatus.VALID };
        }
        // I have no clue why TS cannot infer here that this is never `undefined`.
        return fieldState;
    }

    /** A list of all validation ids. */
    @computed public get validationIds(): UUID[] {
        return [...this.state.keys()];
    }

    /** Check if nothing at all is loading (spans all validation ids). */
    @computed public get isIdle(): boolean {
        return !this.isLoading;
    }

    /** Check if anything at all is loading (spans all validation ids). */
    @computed public get isLoading(): boolean {
        return [...this.state.values()].some((value) => value.loading);
    }
}
