/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PageInfo,
  SortDirection,
  UserGroup,
  UserGroupCreate,
  UserGroupSortKey,
  UserGroupUpdate,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    UserGroupFromJSON,
    UserGroupToJSON,
    UserGroupCreateFromJSON,
    UserGroupCreateToJSON,
    UserGroupSortKeyFromJSON,
    UserGroupSortKeyToJSON,
    UserGroupUpdateFromJSON,
    UserGroupUpdateToJSON,
} from '../models';

export interface UserGroupsCreateUserGroupRequest {
    userGroupCreate: UserGroupCreate;
}

export interface UserGroupsDeleteUserGroupRequest {
    userGroupId: string;
}

export interface UserGroupsListUserGroupsRequest {
    pageSize: number;
    search?: string | null;
    vehicleGroupIds?: Array<string> | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: UserGroupSortKey;
}

export interface UserGroupsListUserGroupsMetadataRequest {
    pageSize: number;
    search?: string | null;
    vehicleGroupIds?: Array<string> | null;
}

export interface UserGroupsReadUserGroupRequest {
    userGroupId: string;
}

export interface UserGroupsUpdateUserGroupRequest {
    userGroupId: string;
    userGroupUpdate: UserGroupUpdate;
}

export interface UserGroupsValidateCreateUserGroupRequest {
    userGroupCreate: UserGroupCreate;
}

export interface UserGroupsValidateUpdateUserGroupRequest {
    userGroupId: string;
    userGroupUpdate: UserGroupUpdate;
}

/**
 * UserGroupsApi - interface
 * 
 * @export
 * @interface UserGroupsApiInterface
 */
export interface UserGroupsApiInterface {
    /**
     * Create a new user group.
     * @summary Create a user group
     * @param {UserGroupCreate} userGroupCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApiInterface
     */
    userGroupsCreateUserGroupRaw(requestParameters: UserGroupsCreateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroup>>;

    /**
     * Create a new user group.
     * Create a user group
     */
    userGroupsCreateUserGroup(requestParameters: UserGroupsCreateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroup>;

    /**
     * Delete the user group with the given ID.
     * @summary Delete a user group
     * @param {string} userGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApiInterface
     */
    userGroupsDeleteUserGroupRaw(requestParameters: UserGroupsDeleteUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the user group with the given ID.
     * Delete a user group
     */
    userGroupsDeleteUserGroup(requestParameters: UserGroupsDeleteUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Lists all the user groups present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * @summary List user groups
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Array<string>} [vehicleGroupIds] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {UserGroupSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApiInterface
     */
    userGroupsListUserGroupsRaw(requestParameters: UserGroupsListUserGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserGroup>>>;

    /**
     * Lists all the user groups present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List user groups
     */
    userGroupsListUserGroups(requestParameters: UserGroupsListUserGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserGroup>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary User group listing metadata
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Array<string>} [vehicleGroupIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApiInterface
     */
    userGroupsListUserGroupsMetadataRaw(requestParameters: UserGroupsListUserGroupsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * User group listing metadata
     */
    userGroupsListUserGroupsMetadata(requestParameters: UserGroupsListUserGroupsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the user group specified by the given ID.
     * @summary Get a single user group
     * @param {string} userGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApiInterface
     */
    userGroupsReadUserGroupRaw(requestParameters: UserGroupsReadUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroup>>;

    /**
     * Returns the user group specified by the given ID.
     * Get a single user group
     */
    userGroupsReadUserGroup(requestParameters: UserGroupsReadUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroup>;

    /**
     * Update the user group specified by the given ID.
     * @summary Update user group
     * @param {string} userGroupId 
     * @param {UserGroupUpdate} userGroupUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApiInterface
     */
    userGroupsUpdateUserGroupRaw(requestParameters: UserGroupsUpdateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroup>>;

    /**
     * Update the user group specified by the given ID.
     * Update user group
     */
    userGroupsUpdateUserGroup(requestParameters: UserGroupsUpdateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroup>;

    /**
     * Validate data for a new user group without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a user group
     * @param {UserGroupCreate} userGroupCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApiInterface
     */
    userGroupsValidateCreateUserGroupRaw(requestParameters: UserGroupsValidateCreateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new user group without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a user group
     */
    userGroupsValidateCreateUserGroup(requestParameters: UserGroupsValidateCreateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate data for updating the given user group, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate user group update
     * @param {string} userGroupId 
     * @param {UserGroupUpdate} userGroupUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApiInterface
     */
    userGroupsValidateUpdateUserGroupRaw(requestParameters: UserGroupsValidateUpdateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for updating the given user group, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate user group update
     */
    userGroupsValidateUpdateUserGroup(requestParameters: UserGroupsValidateUpdateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class UserGroupsApi extends runtime.BaseAPI implements UserGroupsApiInterface {

    /**
     * Create a new user group.
     * Create a user group
     */
    async userGroupsCreateUserGroupRaw(requestParameters: UserGroupsCreateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroup>> {
        if (requestParameters.userGroupCreate === null || requestParameters.userGroupCreate === undefined) {
            throw new runtime.RequiredError('userGroupCreate','Required parameter requestParameters.userGroupCreate was null or undefined when calling userGroupsCreateUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_groups/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupCreateToJSON(requestParameters.userGroupCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupFromJSON(jsonValue));
    }

    /**
     * Create a new user group.
     * Create a user group
     */
    async userGroupsCreateUserGroup(requestParameters: UserGroupsCreateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroup> {
        const response = await this.userGroupsCreateUserGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the user group with the given ID.
     * Delete a user group
     */
    async userGroupsDeleteUserGroupRaw(requestParameters: UserGroupsDeleteUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userGroupId === null || requestParameters.userGroupId === undefined) {
            throw new runtime.RequiredError('userGroupId','Required parameter requestParameters.userGroupId was null or undefined when calling userGroupsDeleteUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_groups/{user_group_id}`.replace(`{${"user_group_id"}}`, encodeURIComponent(String(requestParameters.userGroupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the user group with the given ID.
     * Delete a user group
     */
    async userGroupsDeleteUserGroup(requestParameters: UserGroupsDeleteUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userGroupsDeleteUserGroupRaw(requestParameters, initOverrides);
    }

    /**
     * Lists all the user groups present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List user groups
     */
    async userGroupsListUserGroupsRaw(requestParameters: UserGroupsListUserGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserGroup>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling userGroupsListUserGroups.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.vehicleGroupIds) {
            queryParameters['vehicle_group_ids'] = requestParameters.vehicleGroupIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_groups/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserGroupFromJSON));
    }

    /**
     * Lists all the user groups present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List user groups
     */
    async userGroupsListUserGroups(requestParameters: UserGroupsListUserGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserGroup>> {
        const response = await this.userGroupsListUserGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * User group listing metadata
     */
    async userGroupsListUserGroupsMetadataRaw(requestParameters: UserGroupsListUserGroupsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling userGroupsListUserGroupsMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.vehicleGroupIds) {
            queryParameters['vehicle_group_ids'] = requestParameters.vehicleGroupIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_groups/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * User group listing metadata
     */
    async userGroupsListUserGroupsMetadata(requestParameters: UserGroupsListUserGroupsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.userGroupsListUserGroupsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the user group specified by the given ID.
     * Get a single user group
     */
    async userGroupsReadUserGroupRaw(requestParameters: UserGroupsReadUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroup>> {
        if (requestParameters.userGroupId === null || requestParameters.userGroupId === undefined) {
            throw new runtime.RequiredError('userGroupId','Required parameter requestParameters.userGroupId was null or undefined when calling userGroupsReadUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_groups/{user_group_id}`.replace(`{${"user_group_id"}}`, encodeURIComponent(String(requestParameters.userGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupFromJSON(jsonValue));
    }

    /**
     * Returns the user group specified by the given ID.
     * Get a single user group
     */
    async userGroupsReadUserGroup(requestParameters: UserGroupsReadUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroup> {
        const response = await this.userGroupsReadUserGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the user group specified by the given ID.
     * Update user group
     */
    async userGroupsUpdateUserGroupRaw(requestParameters: UserGroupsUpdateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroup>> {
        if (requestParameters.userGroupId === null || requestParameters.userGroupId === undefined) {
            throw new runtime.RequiredError('userGroupId','Required parameter requestParameters.userGroupId was null or undefined when calling userGroupsUpdateUserGroup.');
        }

        if (requestParameters.userGroupUpdate === null || requestParameters.userGroupUpdate === undefined) {
            throw new runtime.RequiredError('userGroupUpdate','Required parameter requestParameters.userGroupUpdate was null or undefined when calling userGroupsUpdateUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_groups/{user_group_id}`.replace(`{${"user_group_id"}}`, encodeURIComponent(String(requestParameters.userGroupId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupUpdateToJSON(requestParameters.userGroupUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupFromJSON(jsonValue));
    }

    /**
     * Update the user group specified by the given ID.
     * Update user group
     */
    async userGroupsUpdateUserGroup(requestParameters: UserGroupsUpdateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroup> {
        const response = await this.userGroupsUpdateUserGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new user group without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a user group
     */
    async userGroupsValidateCreateUserGroupRaw(requestParameters: UserGroupsValidateCreateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userGroupCreate === null || requestParameters.userGroupCreate === undefined) {
            throw new runtime.RequiredError('userGroupCreate','Required parameter requestParameters.userGroupCreate was null or undefined when calling userGroupsValidateCreateUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_groups/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupCreateToJSON(requestParameters.userGroupCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new user group without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a user group
     */
    async userGroupsValidateCreateUserGroup(requestParameters: UserGroupsValidateCreateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userGroupsValidateCreateUserGroupRaw(requestParameters, initOverrides);
    }

    /**
     * Validate data for updating the given user group, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate user group update
     */
    async userGroupsValidateUpdateUserGroupRaw(requestParameters: UserGroupsValidateUpdateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userGroupId === null || requestParameters.userGroupId === undefined) {
            throw new runtime.RequiredError('userGroupId','Required parameter requestParameters.userGroupId was null or undefined when calling userGroupsValidateUpdateUserGroup.');
        }

        if (requestParameters.userGroupUpdate === null || requestParameters.userGroupUpdate === undefined) {
            throw new runtime.RequiredError('userGroupUpdate','Required parameter requestParameters.userGroupUpdate was null or undefined when calling userGroupsValidateUpdateUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_groups/{user_group_id}/validate`.replace(`{${"user_group_id"}}`, encodeURIComponent(String(requestParameters.userGroupId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupUpdateToJSON(requestParameters.userGroupUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for updating the given user group, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate user group update
     */
    async userGroupsValidateUpdateUserGroup(requestParameters: UserGroupsValidateUpdateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userGroupsValidateUpdateUserGroupRaw(requestParameters, initOverrides);
    }

}
