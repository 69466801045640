import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { Pagination } from "mobx-repository";
import { equals, clone } from "ramda";
import { external, inject } from "tsdi";
import { PageInfo } from "../api";
import { HistoryProvider } from "../domain/providers/history-provider";
import { defaultPageSize } from "./constants";
import { ApiResource, ElofleetRepository } from "./elofleet-repository";

/** Options for the {@link PaginationState}. */
export interface PaginationStateOptions<Resource extends ApiResource> {
    /** A function that will return the {@link PaginatedSearchableRepository} that will perform the query. */
    readonly repository: () => ElofleetRepository<Resource>;
    /** The query that is used to filter the items in the corresponding list. */
    readonly query: () => Resource["query"];
    /** If specified, URL will not be modified or read. */
    readonly ignoreUrl?: boolean;
}

interface MetaInfo<TQuery> {
    query: TQuery | undefined;
    pageInfo: PageInfo;
}

/**
 * A class that manages pagination for list.
 */
@external
export class PaginationState<Resource extends ApiResource> {
    @inject private readonly historyProvider!: HistoryProvider;

    @observable private metaInfo: MetaInfo<Resource["query"]> = {
        query: undefined,
        pageInfo: {
            pageSize: defaultPageSize,
            totalPages: 1,
            totalCount: defaultPageSize,
        },
    };

    /** Only used if not read from URL. */
    @observable private _page: number = 0;

    constructor(private options: PaginationStateOptions<Resource>) {
        makeObservable(this);
    }

    @action.bound private async updatePageInfo(): Promise<void> {
        const pageInfo = await this.options.repository().fetchMetadata(this.options.query());
        runInAction(() => {
            this.metaInfo = {
                query: clone(this.options.query()),
                pageInfo,
            };
        });
    }

    @computed private get pageInfo(): PageInfo {
        if (!this.options.ignoreUrl && !equals(this.options.query(), this.metaInfo.query)) {
            this.updatePageInfo();
        }
        return this.metaInfo.pageInfo;
    }

    /**
     * Zero-based index of the current page.
     * Take care to increment this by one before showing it to the user.
     */
    @computed private get page(): number {
        if (this.options.ignoreUrl) {
            return this._page;
        }
        const page = this.historyProvider.getQueryParam("page");
        if (typeof page !== "string") {
            return 0;
        }
        return Number(page) - 1;
    }

    private set page(page: number) {
        if (this.options.ignoreUrl) {
            this._page = page;
            return;
        }
        // Increment index by one as URLs are user-visible and should start at number 1.
        this.historyProvider.setQueryParam("page", `${page + 1}`);
    }

    /**
     * An action that will navigate to the next page if possible.
     * Otherwise no operation will be performed.
     */
    @action.bound public next(): void {
        if (this.nextDisabled) {
            return;
        }
        this.page++;
    }

    /**
     * An action that will navigate to the previous page if possible.
     * Otherwise no operation will be performed.
     */
    @action.bound public previous(): void {
        if (this.previousDisabled) {
            return;
        }
        this.page--;
    }

    /** Check whether {@link PaginationState.next} can be called. */
    @computed public get nextDisabled(): boolean {
        return this.page >= this.totalPages - 1;
    }

    /** Check whether {@link PaginationState.previous} can be called. */
    @computed public get previousDisabled(): boolean {
        return this.page === 0;
    }

    /** Returns a {@link Pagination} object compatible with {@link PaginatedSearchableRepository}. */
    @computed public get pagination(): Pagination {
        return { count: defaultPageSize, offset: this.page * defaultPageSize };
    }

    /** Reset pagination to first page. */
    @action.bound public reset(): void {
        this.page = 0;
    }

    /**
     * Will return `true` if no pagination is needed.
     * This will happen if the list is already exhausted on the first page.
     */
    @computed public get paginationNotNeeded(): boolean {
        return this.page === 0 && this.totalPages <= 1;
    }

    @computed public get totalPages(): number {
        return this.pageInfo?.totalPages;
    }

    /**
     * Returns an action that can be called to navigate to the specified page.
     * (Index starts at `0`.)
     */
    public goto(index: number): () => void {
        return action(() => {
            this.page = index;
        });
    }

    /** The current page. (Index starts at `0`.) */
    @computed public get currentPage(): number {
        return this.page;
    }
}
