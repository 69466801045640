/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FleetAuthenticatePeriodList
 */
export interface FleetAuthenticatePeriodList {
    /**
     * 
     * @type {boolean}
     * @memberof FleetAuthenticatePeriodList
     */
    eventId: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FleetAuthenticatePeriodList
     */
    userEmployeeId: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FleetAuthenticatePeriodList
     */
    vehicleSerialNumber: boolean;
}

/**
 * Check if a given object implements the FleetAuthenticatePeriodList interface.
 */
export function instanceOfFleetAuthenticatePeriodList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "eventId" in value;
    isInstance = isInstance && "userEmployeeId" in value;
    isInstance = isInstance && "vehicleSerialNumber" in value;

    return isInstance;
}

export function FleetAuthenticatePeriodListFromJSON(json: any): FleetAuthenticatePeriodList {
    return FleetAuthenticatePeriodListFromJSONTyped(json, false);
}

export function FleetAuthenticatePeriodListFromJSONTyped(json: any, ignoreDiscriminator: boolean): FleetAuthenticatePeriodList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventId': json['eventId'],
        'userEmployeeId': json['userEmployeeId'],
        'vehicleSerialNumber': json['vehicleSerialNumber'],
    };
}

export function FleetAuthenticatePeriodListToJSON(value?: FleetAuthenticatePeriodList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventId': value.eventId,
        'userEmployeeId': value.userEmployeeId,
        'vehicleSerialNumber': value.vehicleSerialNumber,
    };
}

