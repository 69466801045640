/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A single validation error for a single constraint that was not satisfied.
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * The code specifying the constraint that was not satisfied. Possible values: - `permission` - `must_exist` - `unique` - `length` - `year` - `regex`
     * @type {string}
     * @memberof ValidationError
     */
    code: string;
    /**
     * A human readable message describing the error.
     * @type {string}
     * @memberof ValidationError
     */
    message?: string | null;
    /**
     * The parameters of the constraint, e.g. maximum length of a string.
     * @type {{ [key: string]: any; }}
     * @memberof ValidationError
     */
    params: { [key: string]: any; };
}

/**
 * Check if a given object implements the ValidationError interface.
 */
export function instanceOfValidationError(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "params" in value;

    return isInstance;
}

export function ValidationErrorFromJSON(json: any): ValidationError {
    return ValidationErrorFromJSONTyped(json, false);
}

export function ValidationErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'params': json['params'],
    };
}

export function ValidationErrorToJSON(value?: ValidationError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'message': value.message,
        'params': value.params,
    };
}

