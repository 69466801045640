/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ColumnVisibility,
  ColumnVisibilityUpdate,
  ErrorResponse,
} from '../models';
import {
    ColumnVisibilityFromJSON,
    ColumnVisibilityToJSON,
    ColumnVisibilityUpdateFromJSON,
    ColumnVisibilityUpdateToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface ColumnVisibilityUpdateColumnVisibilityRequest {
    id: string;
    columnVisibilityUpdate: ColumnVisibilityUpdate;
}

export interface ColumnVisibilityValidateUpdateColumnVisibilityRequest {
    id: string;
    columnVisibilityUpdate: ColumnVisibilityUpdate;
}

/**
 * ColumnVisibilitysApi - interface
 * 
 * @export
 * @interface ColumnVisibilitysApiInterface
 */
export interface ColumnVisibilitysApiInterface {
    /**
     * Since there\'s only a single column visibility row, we don\'t take an ID parameter here.
     * @summary Fetch the column visibility entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColumnVisibilitysApiInterface
     */
    columnVisibilityReadColumnVisibilityRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ColumnVisibility>>;

    /**
     * Since there\'s only a single column visibility row, we don\'t take an ID parameter here.
     * Fetch the column visibility entity
     */
    columnVisibilityReadColumnVisibility(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ColumnVisibility>;

    /**
     * Update the column visibility entity. Since we know the ID of the singleton entity after reading, we take an ID argument here to keep the API consistent with other update endpoints.
     * @summary Update column visibility
     * @param {string} id 
     * @param {ColumnVisibilityUpdate} columnVisibilityUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColumnVisibilitysApiInterface
     */
    columnVisibilityUpdateColumnVisibilityRaw(requestParameters: ColumnVisibilityUpdateColumnVisibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ColumnVisibility>>;

    /**
     * Update the column visibility entity. Since we know the ID of the singleton entity after reading, we take an ID argument here to keep the API consistent with other update endpoints.
     * Update column visibility
     */
    columnVisibilityUpdateColumnVisibility(requestParameters: ColumnVisibilityUpdateColumnVisibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ColumnVisibility>;

    /**
     * Validate an update struct for the column visibility entity.
     * @summary Validate column visibility update
     * @param {string} id 
     * @param {ColumnVisibilityUpdate} columnVisibilityUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColumnVisibilitysApiInterface
     */
    columnVisibilityValidateUpdateColumnVisibilityRaw(requestParameters: ColumnVisibilityValidateUpdateColumnVisibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate an update struct for the column visibility entity.
     * Validate column visibility update
     */
    columnVisibilityValidateUpdateColumnVisibility(requestParameters: ColumnVisibilityValidateUpdateColumnVisibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class ColumnVisibilitysApi extends runtime.BaseAPI implements ColumnVisibilitysApiInterface {

    /**
     * Since there\'s only a single column visibility row, we don\'t take an ID parameter here.
     * Fetch the column visibility entity
     */
    async columnVisibilityReadColumnVisibilityRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ColumnVisibility>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/column_visibilitys/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ColumnVisibilityFromJSON(jsonValue));
    }

    /**
     * Since there\'s only a single column visibility row, we don\'t take an ID parameter here.
     * Fetch the column visibility entity
     */
    async columnVisibilityReadColumnVisibility(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ColumnVisibility> {
        const response = await this.columnVisibilityReadColumnVisibilityRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the column visibility entity. Since we know the ID of the singleton entity after reading, we take an ID argument here to keep the API consistent with other update endpoints.
     * Update column visibility
     */
    async columnVisibilityUpdateColumnVisibilityRaw(requestParameters: ColumnVisibilityUpdateColumnVisibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ColumnVisibility>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling columnVisibilityUpdateColumnVisibility.');
        }

        if (requestParameters.columnVisibilityUpdate === null || requestParameters.columnVisibilityUpdate === undefined) {
            throw new runtime.RequiredError('columnVisibilityUpdate','Required parameter requestParameters.columnVisibilityUpdate was null or undefined when calling columnVisibilityUpdateColumnVisibility.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/column_visibilitys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ColumnVisibilityUpdateToJSON(requestParameters.columnVisibilityUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ColumnVisibilityFromJSON(jsonValue));
    }

    /**
     * Update the column visibility entity. Since we know the ID of the singleton entity after reading, we take an ID argument here to keep the API consistent with other update endpoints.
     * Update column visibility
     */
    async columnVisibilityUpdateColumnVisibility(requestParameters: ColumnVisibilityUpdateColumnVisibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ColumnVisibility> {
        const response = await this.columnVisibilityUpdateColumnVisibilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate an update struct for the column visibility entity.
     * Validate column visibility update
     */
    async columnVisibilityValidateUpdateColumnVisibilityRaw(requestParameters: ColumnVisibilityValidateUpdateColumnVisibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling columnVisibilityValidateUpdateColumnVisibility.');
        }

        if (requestParameters.columnVisibilityUpdate === null || requestParameters.columnVisibilityUpdate === undefined) {
            throw new runtime.RequiredError('columnVisibilityUpdate','Required parameter requestParameters.columnVisibilityUpdate was null or undefined when calling columnVisibilityValidateUpdateColumnVisibility.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/column_visibilitys/{id}/validate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ColumnVisibilityUpdateToJSON(requestParameters.columnVisibilityUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate an update struct for the column visibility entity.
     * Validate column visibility update
     */
    async columnVisibilityValidateUpdateColumnVisibility(requestParameters: ColumnVisibilityValidateUpdateColumnVisibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.columnVisibilityValidateUpdateColumnVisibilityRaw(requestParameters, initOverrides);
    }

}
