import { makeObservable, observable, runInAction } from "mobx";

export interface WrappedValue<T> {
    /** A unique identifier used to identify version of the application when the value was stored. */
    readonly version: number;
    /** The actual data. */
    readonly data: T;
}

export interface LocalStorageServiceOptions<T> {
    /** A unique identifier used to identify the object in the local storage. */
    readonly identifier: string;
    /** An integer number that identifiers the version of the local storage that this application expects. */
    readonly version: number;
    /** A default value that will be returned if the local storage is not yet initialized. */
    readonly defaultValue: T;
}

// Global prefix for all local storage items to avoid accidential collision with other scripts that
// might be injected into the page.
const globalLocalStoragePrefix = "elokon-elofleet";

/** Save a value in the browser's local storage and provide it as a service. */
export abstract class LocalStorageService<T> implements LocalStorageServiceOptions<T> {
    /** @inheritdoc */
    public readonly identifier: string;
    /** @inheritdoc */
    public readonly version: number;
    /** @inheritdoc */
    public readonly defaultValue: T;

    // This observable makes this service reactive.
    @observable private _storedValue!: T;

    constructor({ identifier, version, defaultValue }: LocalStorageServiceOptions<T>) {
        makeObservable(this);

        this.identifier = identifier;
        this.version = version;
        this.defaultValue = defaultValue;

        // Load item from local storage.
        /* istanbul ignore next */
        const localStorageString = window.localStorage?.getItem(this.prefixedIdentifier);

        // If key was not defined or the browser doesn't support local storage, return default value.
        if (!localStorageString) {
            runInAction(() => (this._storedValue = this.defaultValue));
        } else {
            // Otherwise, try to use the value from the local storage.
            try {
                // Try to parse the value and return default value on failure.
                const value: { version: number; data: T } = JSON.parse(localStorageString);

                if (
                    // Value was set, but it was set to undefined.
                    value === undefined ||
                    // Metadata is present, but version is not a number.
                    typeof value.version !== "number" ||
                    // Metadata is present, but version in storage differs from version expected by application.
                    value.version !== this.version
                ) {
                    this.initializeWithDefaultValue();
                } else {
                    // Parsing was successful and meta data checked out; Initialize reactive cache and return value.
                    this.initializeWithValue(value.data);
                }
            } catch (err) {
                this.initializeWithDefaultValue();
            }
        }
    }

    /** Use the specified value as initial value for this service. */
    private initializeWithValue(data: T): void {
        runInAction(() => (this._storedValue = data));
    }

    /** Use the default value provided in the constructor as initial value for this service. */
    private initializeWithDefaultValue(): void {
        // Clear the local storage in case it contained garbage data.
        window.localStorage.removeItem(this.prefixedIdentifier);
        runInAction(() => (this._storedValue = this.defaultValue));
    }

    /** The stored value, persisted in the browser's local storage.  */
    protected get storedValue(): T {
        return this._storedValue;
    }

    /** The configured identifier, prefixed with the global local storage identifier prefix. */
    private get prefixedIdentifier(): string {
        return `${globalLocalStoragePrefix}-${this.identifier}`;
    }

    /** The stored value, persisted in the browser's local storage.  */
    protected set storedValue(data: T) {
        // Update the local storage.
        /* istanbul ignore next */
        window.localStorage?.setItem(
            this.prefixedIdentifier,
            JSON.stringify({ version: this.version, data }),
        );
        // Update the cache so reactivity is triggered.
        runInAction(() => (this._storedValue = data));
    }
}
