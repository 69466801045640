/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  MultiUserGroupAssignmentCreate,
  PageInfo,
  SortDirection,
  UserGroupAssignment,
  UserGroupAssignmentCreate,
  UserGroupAssignmentSortKey,
  UserGroupAssignmentUpdate,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    MultiUserGroupAssignmentCreateFromJSON,
    MultiUserGroupAssignmentCreateToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    UserGroupAssignmentFromJSON,
    UserGroupAssignmentToJSON,
    UserGroupAssignmentCreateFromJSON,
    UserGroupAssignmentCreateToJSON,
    UserGroupAssignmentSortKeyFromJSON,
    UserGroupAssignmentSortKeyToJSON,
    UserGroupAssignmentUpdateFromJSON,
    UserGroupAssignmentUpdateToJSON,
} from '../models';

export interface UserGroupAssignmentsCreateMultipleUserGroupAssignmentsRequest {
    multiUserGroupAssignmentCreate: MultiUserGroupAssignmentCreate;
}

export interface UserGroupAssignmentsCreateUserGroupAssignmentRequest {
    userGroupAssignmentCreate: UserGroupAssignmentCreate;
}

export interface UserGroupAssignmentsDeleteUserGroupAssignmentRequest {
    userGroupAssignmentId: string;
}

export interface UserGroupAssignmentsListUserGroupAssignmentsRequest {
    pageSize: number;
    userId?: string | null;
    userGroupIds?: Array<string> | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: UserGroupAssignmentSortKey;
}

export interface UserGroupAssignmentsListUserGroupAssignmentsMetadataRequest {
    pageSize: number;
    userId?: string | null;
    userGroupIds?: Array<string> | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: UserGroupAssignmentSortKey;
}

export interface UserGroupAssignmentsReadUserGroupAssignmentRequest {
    userGroupAssignmentId: string;
}

export interface UserGroupAssignmentsUpdateUserGroupAssignmentRequest {
    userGroupAssignmentId: string;
    userGroupAssignmentUpdate: UserGroupAssignmentUpdate;
}

export interface UserGroupAssignmentsValidateCreateUserGroupAssignmentRequest {
    userGroupAssignmentCreate: UserGroupAssignmentCreate;
}

export interface UserGroupAssignmentsValidateUpdateUserGroupAssignmentRequest {
    userGroupAssignmentId: string;
    userGroupAssignmentUpdate: UserGroupAssignmentUpdate;
}

/**
 * UserGroupAssignmentsApi - interface
 * 
 * @export
 * @interface UserGroupAssignmentsApiInterface
 */
export interface UserGroupAssignmentsApiInterface {
    /**
     * This endpoint accepts a list of User ids and UserGroup ids respectively.  Assignments are then created for each User to each UserGroup. Trying to create duplicate assignments won\'t lead to errors and will simply be ignored.
     * @summary Create multiple UserGroupAssignments
     * @param {MultiUserGroupAssignmentCreate} multiUserGroupAssignmentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupAssignmentsApiInterface
     */
    userGroupAssignmentsCreateMultipleUserGroupAssignmentsRaw(requestParameters: UserGroupAssignmentsCreateMultipleUserGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserGroupAssignment>>>;

    /**
     * This endpoint accepts a list of User ids and UserGroup ids respectively.  Assignments are then created for each User to each UserGroup. Trying to create duplicate assignments won\'t lead to errors and will simply be ignored.
     * Create multiple UserGroupAssignments
     */
    userGroupAssignmentsCreateMultipleUserGroupAssignments(requestParameters: UserGroupAssignmentsCreateMultipleUserGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserGroupAssignment>>;

    /**
     * Create a new user_group_assignment.
     * @summary Create a user_group_assignment
     * @param {UserGroupAssignmentCreate} userGroupAssignmentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupAssignmentsApiInterface
     */
    userGroupAssignmentsCreateUserGroupAssignmentRaw(requestParameters: UserGroupAssignmentsCreateUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupAssignment>>;

    /**
     * Create a new user_group_assignment.
     * Create a user_group_assignment
     */
    userGroupAssignmentsCreateUserGroupAssignment(requestParameters: UserGroupAssignmentsCreateUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupAssignment>;

    /**
     * Delete the user_group_assignment with the given ID.
     * @summary Delete a user_group_assignment
     * @param {string} userGroupAssignmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupAssignmentsApiInterface
     */
    userGroupAssignmentsDeleteUserGroupAssignmentRaw(requestParameters: UserGroupAssignmentsDeleteUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the user_group_assignment with the given ID.
     * Delete a user_group_assignment
     */
    userGroupAssignmentsDeleteUserGroupAssignment(requestParameters: UserGroupAssignmentsDeleteUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Lists all the user_group_assignment present.
     * @summary List user_group_assignments
     * @param {number} pageSize 
     * @param {string} [userId] 
     * @param {Array<string>} [userGroupIds] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {UserGroupAssignmentSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupAssignmentsApiInterface
     */
    userGroupAssignmentsListUserGroupAssignmentsRaw(requestParameters: UserGroupAssignmentsListUserGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserGroupAssignment>>>;

    /**
     * Lists all the user_group_assignment present.
     * List user_group_assignments
     */
    userGroupAssignmentsListUserGroupAssignments(requestParameters: UserGroupAssignmentsListUserGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserGroupAssignment>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary User Group Assignment listing metadata
     * @param {number} pageSize 
     * @param {string} [userId] 
     * @param {Array<string>} [userGroupIds] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {UserGroupAssignmentSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupAssignmentsApiInterface
     */
    userGroupAssignmentsListUserGroupAssignmentsMetadataRaw(requestParameters: UserGroupAssignmentsListUserGroupAssignmentsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * User Group Assignment listing metadata
     */
    userGroupAssignmentsListUserGroupAssignmentsMetadata(requestParameters: UserGroupAssignmentsListUserGroupAssignmentsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the user_group_assignment specified by the given ID.
     * @summary Get a single user_group_assignment
     * @param {string} userGroupAssignmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupAssignmentsApiInterface
     */
    userGroupAssignmentsReadUserGroupAssignmentRaw(requestParameters: UserGroupAssignmentsReadUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupAssignment>>;

    /**
     * Returns the user_group_assignment specified by the given ID.
     * Get a single user_group_assignment
     */
    userGroupAssignmentsReadUserGroupAssignment(requestParameters: UserGroupAssignmentsReadUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupAssignment>;

    /**
     * Update the user_group_assignment specified by the given ID.
     * @summary Update user_group_assignment
     * @param {string} userGroupAssignmentId 
     * @param {UserGroupAssignmentUpdate} userGroupAssignmentUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupAssignmentsApiInterface
     */
    userGroupAssignmentsUpdateUserGroupAssignmentRaw(requestParameters: UserGroupAssignmentsUpdateUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupAssignment>>;

    /**
     * Update the user_group_assignment specified by the given ID.
     * Update user_group_assignment
     */
    userGroupAssignmentsUpdateUserGroupAssignment(requestParameters: UserGroupAssignmentsUpdateUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupAssignment>;

    /**
     * Validate data for a new user_group_assignment without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a user_group_assignment
     * @param {UserGroupAssignmentCreate} userGroupAssignmentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupAssignmentsApiInterface
     */
    userGroupAssignmentsValidateCreateUserGroupAssignmentRaw(requestParameters: UserGroupAssignmentsValidateCreateUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new user_group_assignment without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a user_group_assignment
     */
    userGroupAssignmentsValidateCreateUserGroupAssignment(requestParameters: UserGroupAssignmentsValidateCreateUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate data for updating the given user_group_assignment, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate user_group_assignment update
     * @param {string} userGroupAssignmentId 
     * @param {UserGroupAssignmentUpdate} userGroupAssignmentUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupAssignmentsApiInterface
     */
    userGroupAssignmentsValidateUpdateUserGroupAssignmentRaw(requestParameters: UserGroupAssignmentsValidateUpdateUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for updating the given user_group_assignment, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate user_group_assignment update
     */
    userGroupAssignmentsValidateUpdateUserGroupAssignment(requestParameters: UserGroupAssignmentsValidateUpdateUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class UserGroupAssignmentsApi extends runtime.BaseAPI implements UserGroupAssignmentsApiInterface {

    /**
     * This endpoint accepts a list of User ids and UserGroup ids respectively.  Assignments are then created for each User to each UserGroup. Trying to create duplicate assignments won\'t lead to errors and will simply be ignored.
     * Create multiple UserGroupAssignments
     */
    async userGroupAssignmentsCreateMultipleUserGroupAssignmentsRaw(requestParameters: UserGroupAssignmentsCreateMultipleUserGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserGroupAssignment>>> {
        if (requestParameters.multiUserGroupAssignmentCreate === null || requestParameters.multiUserGroupAssignmentCreate === undefined) {
            throw new runtime.RequiredError('multiUserGroupAssignmentCreate','Required parameter requestParameters.multiUserGroupAssignmentCreate was null or undefined when calling userGroupAssignmentsCreateMultipleUserGroupAssignments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_group_assignments/multi`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MultiUserGroupAssignmentCreateToJSON(requestParameters.multiUserGroupAssignmentCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserGroupAssignmentFromJSON));
    }

    /**
     * This endpoint accepts a list of User ids and UserGroup ids respectively.  Assignments are then created for each User to each UserGroup. Trying to create duplicate assignments won\'t lead to errors and will simply be ignored.
     * Create multiple UserGroupAssignments
     */
    async userGroupAssignmentsCreateMultipleUserGroupAssignments(requestParameters: UserGroupAssignmentsCreateMultipleUserGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserGroupAssignment>> {
        const response = await this.userGroupAssignmentsCreateMultipleUserGroupAssignmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new user_group_assignment.
     * Create a user_group_assignment
     */
    async userGroupAssignmentsCreateUserGroupAssignmentRaw(requestParameters: UserGroupAssignmentsCreateUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupAssignment>> {
        if (requestParameters.userGroupAssignmentCreate === null || requestParameters.userGroupAssignmentCreate === undefined) {
            throw new runtime.RequiredError('userGroupAssignmentCreate','Required parameter requestParameters.userGroupAssignmentCreate was null or undefined when calling userGroupAssignmentsCreateUserGroupAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_group_assignments/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupAssignmentCreateToJSON(requestParameters.userGroupAssignmentCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupAssignmentFromJSON(jsonValue));
    }

    /**
     * Create a new user_group_assignment.
     * Create a user_group_assignment
     */
    async userGroupAssignmentsCreateUserGroupAssignment(requestParameters: UserGroupAssignmentsCreateUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupAssignment> {
        const response = await this.userGroupAssignmentsCreateUserGroupAssignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the user_group_assignment with the given ID.
     * Delete a user_group_assignment
     */
    async userGroupAssignmentsDeleteUserGroupAssignmentRaw(requestParameters: UserGroupAssignmentsDeleteUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userGroupAssignmentId === null || requestParameters.userGroupAssignmentId === undefined) {
            throw new runtime.RequiredError('userGroupAssignmentId','Required parameter requestParameters.userGroupAssignmentId was null or undefined when calling userGroupAssignmentsDeleteUserGroupAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_group_assignments/{user_group_assignment_id}`.replace(`{${"user_group_assignment_id"}}`, encodeURIComponent(String(requestParameters.userGroupAssignmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the user_group_assignment with the given ID.
     * Delete a user_group_assignment
     */
    async userGroupAssignmentsDeleteUserGroupAssignment(requestParameters: UserGroupAssignmentsDeleteUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userGroupAssignmentsDeleteUserGroupAssignmentRaw(requestParameters, initOverrides);
    }

    /**
     * Lists all the user_group_assignment present.
     * List user_group_assignments
     */
    async userGroupAssignmentsListUserGroupAssignmentsRaw(requestParameters: UserGroupAssignmentsListUserGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserGroupAssignment>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling userGroupAssignmentsListUserGroupAssignments.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        if (requestParameters.userGroupIds) {
            queryParameters['user_group_ids'] = requestParameters.userGroupIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_group_assignments/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserGroupAssignmentFromJSON));
    }

    /**
     * Lists all the user_group_assignment present.
     * List user_group_assignments
     */
    async userGroupAssignmentsListUserGroupAssignments(requestParameters: UserGroupAssignmentsListUserGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserGroupAssignment>> {
        const response = await this.userGroupAssignmentsListUserGroupAssignmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * User Group Assignment listing metadata
     */
    async userGroupAssignmentsListUserGroupAssignmentsMetadataRaw(requestParameters: UserGroupAssignmentsListUserGroupAssignmentsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling userGroupAssignmentsListUserGroupAssignmentsMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        if (requestParameters.userGroupIds) {
            queryParameters['user_group_ids'] = requestParameters.userGroupIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_group_assignments/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * User Group Assignment listing metadata
     */
    async userGroupAssignmentsListUserGroupAssignmentsMetadata(requestParameters: UserGroupAssignmentsListUserGroupAssignmentsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.userGroupAssignmentsListUserGroupAssignmentsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the user_group_assignment specified by the given ID.
     * Get a single user_group_assignment
     */
    async userGroupAssignmentsReadUserGroupAssignmentRaw(requestParameters: UserGroupAssignmentsReadUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupAssignment>> {
        if (requestParameters.userGroupAssignmentId === null || requestParameters.userGroupAssignmentId === undefined) {
            throw new runtime.RequiredError('userGroupAssignmentId','Required parameter requestParameters.userGroupAssignmentId was null or undefined when calling userGroupAssignmentsReadUserGroupAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_group_assignments/{user_group_assignment_id}`.replace(`{${"user_group_assignment_id"}}`, encodeURIComponent(String(requestParameters.userGroupAssignmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupAssignmentFromJSON(jsonValue));
    }

    /**
     * Returns the user_group_assignment specified by the given ID.
     * Get a single user_group_assignment
     */
    async userGroupAssignmentsReadUserGroupAssignment(requestParameters: UserGroupAssignmentsReadUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupAssignment> {
        const response = await this.userGroupAssignmentsReadUserGroupAssignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the user_group_assignment specified by the given ID.
     * Update user_group_assignment
     */
    async userGroupAssignmentsUpdateUserGroupAssignmentRaw(requestParameters: UserGroupAssignmentsUpdateUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupAssignment>> {
        if (requestParameters.userGroupAssignmentId === null || requestParameters.userGroupAssignmentId === undefined) {
            throw new runtime.RequiredError('userGroupAssignmentId','Required parameter requestParameters.userGroupAssignmentId was null or undefined when calling userGroupAssignmentsUpdateUserGroupAssignment.');
        }

        if (requestParameters.userGroupAssignmentUpdate === null || requestParameters.userGroupAssignmentUpdate === undefined) {
            throw new runtime.RequiredError('userGroupAssignmentUpdate','Required parameter requestParameters.userGroupAssignmentUpdate was null or undefined when calling userGroupAssignmentsUpdateUserGroupAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_group_assignments/{user_group_assignment_id}`.replace(`{${"user_group_assignment_id"}}`, encodeURIComponent(String(requestParameters.userGroupAssignmentId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupAssignmentUpdateToJSON(requestParameters.userGroupAssignmentUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupAssignmentFromJSON(jsonValue));
    }

    /**
     * Update the user_group_assignment specified by the given ID.
     * Update user_group_assignment
     */
    async userGroupAssignmentsUpdateUserGroupAssignment(requestParameters: UserGroupAssignmentsUpdateUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupAssignment> {
        const response = await this.userGroupAssignmentsUpdateUserGroupAssignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new user_group_assignment without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a user_group_assignment
     */
    async userGroupAssignmentsValidateCreateUserGroupAssignmentRaw(requestParameters: UserGroupAssignmentsValidateCreateUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userGroupAssignmentCreate === null || requestParameters.userGroupAssignmentCreate === undefined) {
            throw new runtime.RequiredError('userGroupAssignmentCreate','Required parameter requestParameters.userGroupAssignmentCreate was null or undefined when calling userGroupAssignmentsValidateCreateUserGroupAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_group_assignments/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupAssignmentCreateToJSON(requestParameters.userGroupAssignmentCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new user_group_assignment without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a user_group_assignment
     */
    async userGroupAssignmentsValidateCreateUserGroupAssignment(requestParameters: UserGroupAssignmentsValidateCreateUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userGroupAssignmentsValidateCreateUserGroupAssignmentRaw(requestParameters, initOverrides);
    }

    /**
     * Validate data for updating the given user_group_assignment, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate user_group_assignment update
     */
    async userGroupAssignmentsValidateUpdateUserGroupAssignmentRaw(requestParameters: UserGroupAssignmentsValidateUpdateUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userGroupAssignmentId === null || requestParameters.userGroupAssignmentId === undefined) {
            throw new runtime.RequiredError('userGroupAssignmentId','Required parameter requestParameters.userGroupAssignmentId was null or undefined when calling userGroupAssignmentsValidateUpdateUserGroupAssignment.');
        }

        if (requestParameters.userGroupAssignmentUpdate === null || requestParameters.userGroupAssignmentUpdate === undefined) {
            throw new runtime.RequiredError('userGroupAssignmentUpdate','Required parameter requestParameters.userGroupAssignmentUpdate was null or undefined when calling userGroupAssignmentsValidateUpdateUserGroupAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_group_assignments/{user_group_assignment_id}/validate`.replace(`{${"user_group_assignment_id"}}`, encodeURIComponent(String(requestParameters.userGroupAssignmentId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupAssignmentUpdateToJSON(requestParameters.userGroupAssignmentUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for updating the given user_group_assignment, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate user_group_assignment update
     */
    async userGroupAssignmentsValidateUpdateUserGroupAssignment(requestParameters: UserGroupAssignmentsValidateUpdateUserGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userGroupAssignmentsValidateUpdateUserGroupAssignmentRaw(requestParameters, initOverrides);
    }

}
