/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PreOpsAnswerList
 */
export interface PreOpsAnswerList {
    /**
     * 
     * @type {boolean}
     * @memberof PreOpsAnswerList
     */
    userFullName: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PreOpsAnswerList
     */
    vehicleSerialNumber: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PreOpsAnswerList
     */
    departmentLabel: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PreOpsAnswerList
     */
    preOpsChecklistLabel: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PreOpsAnswerList
     */
    preOpsQuestionBody: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PreOpsAnswerList
     */
    preOpsAnswerChoice: boolean;
}

/**
 * Check if a given object implements the PreOpsAnswerList interface.
 */
export function instanceOfPreOpsAnswerList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userFullName" in value;
    isInstance = isInstance && "vehicleSerialNumber" in value;
    isInstance = isInstance && "departmentLabel" in value;
    isInstance = isInstance && "preOpsChecklistLabel" in value;
    isInstance = isInstance && "preOpsQuestionBody" in value;
    isInstance = isInstance && "preOpsAnswerChoice" in value;

    return isInstance;
}

export function PreOpsAnswerListFromJSON(json: any): PreOpsAnswerList {
    return PreOpsAnswerListFromJSONTyped(json, false);
}

export function PreOpsAnswerListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreOpsAnswerList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userFullName': json['userFullName'],
        'vehicleSerialNumber': json['vehicleSerialNumber'],
        'departmentLabel': json['departmentLabel'],
        'preOpsChecklistLabel': json['preOpsChecklistLabel'],
        'preOpsQuestionBody': json['preOpsQuestionBody'],
        'preOpsAnswerChoice': json['preOpsAnswerChoice'],
    };
}

export function PreOpsAnswerListToJSON(value?: PreOpsAnswerList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userFullName': value.userFullName,
        'vehicleSerialNumber': value.vehicleSerialNumber,
        'departmentLabel': value.departmentLabel,
        'preOpsChecklistLabel': value.preOpsChecklistLabel,
        'preOpsQuestionBody': value.preOpsQuestionBody,
        'preOpsAnswerChoice': value.preOpsAnswerChoice,
    };
}

