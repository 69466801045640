import { ComboBox, IComboBoxOption, IComboBoxProps, ISelectableOption } from "@fluentui/react";
import { action, computed, makeObservable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider, LanguageInfo, LanguageMode } from "../../domain/providers/i18n-provider";
import { LoadingState } from "../../utils/loading-state";

enum SelectLanguageFeatures {
    CHANGE_LANGUAGE = "change language",
}

export type SelectLanguageProps = Omit<IComboBoxProps, "options" | "selectedKey" | "onChange">;

@observer
@external
export class SelectLanguage extends React.Component<SelectLanguageProps> {
    @inject private readonly i18n!: I18nProvider;

    private readonly loadingFeatures = new LoadingState<SelectLanguageFeatures>();

    constructor(props: SelectLanguageProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get options(): ISelectableOption<LanguageInfo>[] {
        return [
            {
                key: "none",
                text: this.i18n.t("component.selectLanguage.auto"),
            },
            ...this.i18n.availableLanguages.map((data) => ({
                key: data.code,
                text: data.name,
                data,
            })),
        ];
    }

    @action.bound private async changeLanguage(
        _: unknown,
        options: IComboBoxOption | undefined,
    ): Promise<void> {
        const code = options?.key === "none" ? undefined : (options?.key as string | undefined);
        if (code === undefined) {
            await this.loadingFeatures.wrap(
                SelectLanguageFeatures.CHANGE_LANGUAGE,
                this.i18n.changeLanguage({ mode: LanguageMode.AUTO }),
            );
        } else {
            await this.loadingFeatures.wrap(
                SelectLanguageFeatures.CHANGE_LANGUAGE,
                this.i18n.changeLanguage({ mode: LanguageMode.MANUAL, code }),
            );
        }
    }

    private renderOption(option: ISelectableOption<LanguageInfo> | undefined): JSX.Element {
        if (!option) {
            return <></>;
        }
        return (
            <>
                <span
                    className={`flag-icon flag-icon-${option.data?.flag!}`}
                    style={{ marginRight: "1em" }}
                />
                {option.text}
            </>
        );
    }

    public render(): JSX.Element {
        return (
            <ComboBox
                {...this.props}
                disabled={
                    this.loadingFeatures.isLoading(SelectLanguageFeatures.CHANGE_LANGUAGE) ||
                    this.props.disabled
                }
                options={this.options}
                selectedKey={
                    this.loadingFeatures.isLoading(SelectLanguageFeatures.CHANGE_LANGUAGE)
                        ? undefined
                        : this.i18n.language ?? "none"
                }
                onRenderOption={(option) => this.renderOption(option)}
                onChange={this.changeLanguage}
            />
        );
    }
}
