/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PreOpsAnswerChoice } from './PreOpsAnswerChoice';
import {
    PreOpsAnswerChoiceFromJSON,
    PreOpsAnswerChoiceFromJSONTyped,
    PreOpsAnswerChoiceToJSON,
} from './PreOpsAnswerChoice';

/**
 * 
 * @export
 * @interface PreOpsAnswer
 */
export interface PreOpsAnswer {
    /**
     * 
     * @type {string}
     * @memberof PreOpsAnswer
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof PreOpsAnswer
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof PreOpsAnswer
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof PreOpsAnswer
     */
    vehicleId: string;
    /**
     * 
     * @type {Date}
     * @memberof PreOpsAnswer
     */
    time: Date;
    /**
     * 
     * @type {string}
     * @memberof PreOpsAnswer
     */
    answerGroupId: string;
    /**
     * 
     * @type {string}
     * @memberof PreOpsAnswer
     */
    questionVersionId: string;
    /**
     * 
     * @type {PreOpsAnswerChoice}
     * @memberof PreOpsAnswer
     */
    choice: PreOpsAnswerChoice;
}

/**
 * Check if a given object implements the PreOpsAnswer interface.
 */
export function instanceOfPreOpsAnswer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "vehicleId" in value;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "answerGroupId" in value;
    isInstance = isInstance && "questionVersionId" in value;
    isInstance = isInstance && "choice" in value;

    return isInstance;
}

export function PreOpsAnswerFromJSON(json: any): PreOpsAnswer {
    return PreOpsAnswerFromJSONTyped(json, false);
}

export function PreOpsAnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreOpsAnswer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'userId': json['userId'],
        'vehicleId': json['vehicleId'],
        'time': (new Date(json['time'])),
        'answerGroupId': json['answerGroupId'],
        'questionVersionId': json['questionVersionId'],
        'choice': PreOpsAnswerChoiceFromJSON(json['choice']),
    };
}

export function PreOpsAnswerToJSON(value?: PreOpsAnswer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'userId': value.userId,
        'vehicleId': value.vehicleId,
        'time': (value.time.toISOString()),
        'answerGroupId': value.answerGroupId,
        'questionVersionId': value.questionVersionId,
        'choice': PreOpsAnswerChoiceToJSON(value.choice),
    };
}

