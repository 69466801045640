import * as React from "react";
import { pick } from "ramda";
import { observer } from "mobx-react";
import { Stack, DefaultButton, TextField } from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { ShockProfile } from "../../api";
import { createUuid } from "../../utils/uuid";
import { ShockProfileUpdate } from "../../api";
import { FormUpdateProps } from "../../utils/form-update-props";
import { doubleBindString } from "../../utils/double-bind";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { RepositoryShockProfiles } from "../../domain/repositories/repository-shock-profiles";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";
import { GtomG, mGtoG } from "../../utils/unit-conversion";
import { FloatInput } from "../atoms/float-input";

export interface FormUpdateShockProfileProps extends FormUpdateProps<ShockProfile> {}

@observer
@external
export class FormUpdateShockProfile extends React.Component<FormUpdateShockProfileProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryShockProfiles!: RepositoryShockProfiles;

    // These fields are supposed to be displayed as `g` in the frontend, even
    // though we work with `mg` under the hood.
    @observable public light?: number;
    @observable public medium?: number;
    @observable public severe?: number;

    // Duration unit is ms
    @observable public duration?: number;

    private validationId = createUuid();

    constructor(props: FormUpdateShockProfileProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositoryShockProfiles.validation.update.updateModel(
            this.validationId,
            this.shockProfileUpdate,
        );
    }

    @initialize protected initialize(): void {
        this.repositoryShockProfiles.validation.update.initializeModel(
            this.validationId,
            this.shockProfileUpdate,
            this.props.id,
        );
    }

    public componentWillUnmount(): void {
        this.repositoryShockProfiles.discardMutableCopy(FormUpdateShockProfile.name, this.props.id);
    }

    @computed private get shockProfile(): ShockProfile | undefined {
        return this.repositoryShockProfiles.mutableCopyById(
            FormUpdateShockProfile.name,
            this.props.id,
        );
    }

    @computed private get shockProfileUpdate(): ShockProfileUpdate {
        const update = pick(["label"], this.shockProfile) as ShockProfileUpdate;

        update["light"] = GtomG(this.light);
        update["medium"] = GtomG(this.medium);
        update["severe"] = GtomG(this.severe);
        update["duration"] = this.duration;

        return update;
    }

    @action.bound private setLight(value?: number): void {
        this.light = value;
        this.triggerValidation();
    }

    @action.bound private setMedium(value?: number): void {
        this.medium = value;
        this.triggerValidation();
    }

    @action.bound private setSevere(value?: number): void {
        this.severe = value;
        this.triggerValidation();
    }

    @action.bound private setDuration(value?: number): void {
        this.duration = value;
        this.triggerValidation();
    }

    @action.bound
    private async updateShockProfile(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const shockProfile = await this.repositoryShockProfiles.update(
            this.props.id,
            this.shockProfileUpdate,
        );
        this.props.onUpdate(shockProfile);
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formUpdateShockProfile.submit.text")}
                validation={this.repositoryShockProfiles.validation.update}
                validationId={this.validationId}
            />
        );
        return (
            <form onSubmit={this.updateShockProfile}>
                <Stack tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                    <TextField
                        label={this.i18n.t("formCreateShockProfile.label.label")}
                        required
                        {...doubleBindString(this.shockProfile!, "label", () =>
                            this.triggerValidation(),
                        )}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryShockProfiles.validation.update.getFieldValidationState(
                                this.validationId,
                                "label",
                            ),
                        )}
                    />
                    <FloatInput
                        required
                        label={this.i18n.t("formCreateShockProfile.light.label")}
                        precision={3}
                        defaultValue={mGtoG(this.shockProfile!.light)}
                        onChange={this.setLight}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryShockProfiles.validation.update.getFieldValidationState(
                                this.validationId,
                                "light",
                            ),
                            (p) => this.i18n.formatFloat(mGtoG(String(p)), 3),
                        )}
                    />
                    <FloatInput
                        required
                        label={this.i18n.t("formCreateShockProfile.medium.label")}
                        precision={3}
                        defaultValue={mGtoG(this.shockProfile!.medium)}
                        onChange={this.setMedium}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryShockProfiles.validation.update.getFieldValidationState(
                                this.validationId,
                                "medium",
                            ),
                            (p) => this.i18n.formatFloat(mGtoG(String(p)), 3),
                        )}
                    />
                    <FloatInput
                        required
                        label={this.i18n.t("formCreateShockProfile.severe.label")}
                        precision={3}
                        defaultValue={mGtoG(this.shockProfile!.severe)}
                        onChange={this.setSevere}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryShockProfiles.validation.update.getFieldValidationState(
                                this.validationId,
                                "severe",
                            ),
                            (p) => this.i18n.formatFloat(mGtoG(String(p)), 3),
                        )}
                    />
                    <FloatInput
                        required
                        label={this.i18n.t("formCreateShockProfile.duration.label")}
                        precision={0}
                        defaultValue={this.shockProfile!.duration}
                        onChange={this.setDuration}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryShockProfiles.validation.update.getFieldValidationState(
                                this.validationId,
                                "duration",
                            ),
                            (p) => p,
                        )}
                    />
                </Stack>
                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            label={this.i18n.t("formUpdateShockProfile.cancel.label")}
                            text={this.i18n.t("formUpdateShockProfile.cancel.text")}
                            onClick={this.props.onDialogCancel}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
            </form>
        );
    }
}
