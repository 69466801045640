/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserProductivityListUpdate
 */
export interface UserProductivityListUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof UserProductivityListUpdate
     */
    userEmployeeId?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProductivityListUpdate
     */
    userLastName?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProductivityListUpdate
     */
    userFirstName?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProductivityListUpdate
     */
    departmentLabel?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProductivityListUpdate
     */
    shiftLabel?: boolean | null;
}

/**
 * Check if a given object implements the UserProductivityListUpdate interface.
 */
export function instanceOfUserProductivityListUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserProductivityListUpdateFromJSON(json: any): UserProductivityListUpdate {
    return UserProductivityListUpdateFromJSONTyped(json, false);
}

export function UserProductivityListUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProductivityListUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userEmployeeId': !exists(json, 'userEmployeeId') ? undefined : json['userEmployeeId'],
        'userLastName': !exists(json, 'userLastName') ? undefined : json['userLastName'],
        'userFirstName': !exists(json, 'userFirstName') ? undefined : json['userFirstName'],
        'departmentLabel': !exists(json, 'departmentLabel') ? undefined : json['departmentLabel'],
        'shiftLabel': !exists(json, 'shiftLabel') ? undefined : json['shiftLabel'],
    };
}

export function UserProductivityListUpdateToJSON(value?: UserProductivityListUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userEmployeeId': value.userEmployeeId,
        'userLastName': value.userLastName,
        'userFirstName': value.userFirstName,
        'departmentLabel': value.departmentLabel,
        'shiftLabel': value.shiftLabel,
    };
}

