import { FontIcon, StackItem, Image, ImageFit, Stack, Label, IconButton } from "@fluentui/react";
import { action, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { external, initialize, inject } from "tsdi";
import { ServiceFile } from "../../domain/services/service-file";
import { UUID } from "../../utils/uuid";

export interface ImageUploaderProps {
    onImageChanged: (bytes?: Uint8Array) => void;
    initialImage?: UUID | null;
    label?: string;
    // Display this FontIcon when no image is selected
    placeholderIcon: string;
}

// A component for uploading images.
// Manages the uploaded data internally.
@observer
@external
export class ImageUploader extends React.Component<ImageUploaderProps> {
    @inject private readonly serviceFile!: ServiceFile;

    @observable private imageBytes?: Uint8Array;

    constructor(props: ImageUploaderProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.loadInitialImage();
    }

    private async loadInitialImage(): Promise<void> {
        if (this.props.initialImage) {
            const imageByte = await (
                await this.serviceFile.getFile(this.props.initialImage)
            ).arrayBuffer();
            runInAction(() => {
                this.setImageBytes(new Uint8Array(imageByte));
            });
        }
    }

    @action.bound private readImage(event: React.ChangeEvent<HTMLInputElement>): void {
        // We need this check to please the type checker, as we cannot know that
        // this input element is of `input=file`.
        const files = event.target.files;
        if (files === null) {
            throw new Error("Unexpected event without files received");
        }
        const file = files[0];

        // Read the file to a byte array.
        const reader = new FileReader();
        reader.onload = (e) => {
            const res = e.target?.result as ArrayBuffer;
            this.setImageBytes(new Uint8Array(res));
        };

        reader.onerror = (e) => {
            throw e;
        };

        const data = new FormData();
        data.append("file", file);
        reader.readAsArrayBuffer(file);
    }

    @action.bound private setImageBytes(bytes: Uint8Array | undefined): void {
        this.imageBytes = bytes;
        this.props.onImageChanged(bytes);
    }

    @action.bound private removeImage(): void {
        this.setImageBytes(undefined);
    }

    private get imageUri(): string | undefined {
        if (this.imageBytes) {
            return URL.createObjectURL(new Blob([this.imageBytes]));
        }
    }

    public render(): JSX.Element {
        return (
            <Stack
                verticalAlign="space-between"
                tokens={{
                    childrenGap: "2em",
                }}
            >
                {this.props.label && (
                    <StackItem>
                        <Label>{this.props.label}</Label>
                    </StackItem>
                )}
                <StackItem>
                    {this.imageUri == null ? (
                        <FontIcon
                            aria-label={this.props.label}
                            iconName={this.props.placeholderIcon}
                            style={{
                                fontSize: 140,
                                // get rid of extra padding at the top and bottom
                                // Which is a natural result of using font icons
                                lineHeight: 1,
                                verticalAlign: "middle",
                                margin: 0,
                                color: "#1B559B",
                            }}
                        />
                    ) : (
                        <Image src={this.imageUri ?? ""} height={150} imageFit={ImageFit.contain} />
                    )}
                </StackItem>
                <Stack horizontal verticalAlign="center">
                    <StackItem>
                        <input type="file" onChange={this.readImage.bind(this)} />
                    </StackItem>
                    <StackItem>
                        <IconButton iconProps={{ iconName: "Cancel" }} onClick={this.removeImage} />
                    </StackItem>
                </Stack>
            </Stack>
        );
    }
}
