/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A custom update schema, which can be used by logged in users to update some parts of their own profile. Normal users are much more restricted regarding permissions.
 * @export
 * @interface LoggedInUserUpdate
 */
export interface LoggedInUserUpdate {
    /**
     * 
     * @type {string}
     * @memberof LoggedInUserUpdate
     */
    password?: string | null;
}

/**
 * Check if a given object implements the LoggedInUserUpdate interface.
 */
export function instanceOfLoggedInUserUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LoggedInUserUpdateFromJSON(json: any): LoggedInUserUpdate {
    return LoggedInUserUpdateFromJSONTyped(json, false);
}

export function LoggedInUserUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoggedInUserUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'password': !exists(json, 'password') ? undefined : json['password'],
    };
}

export function LoggedInUserUpdateToJSON(value?: LoggedInUserUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'password': value.password,
    };
}

