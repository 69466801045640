import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { observer } from "mobx-react";
import { NavigationItem } from "../atoms/navigation-item";
import { NavigationGroup } from "../atoms/navigation-group";
import { routeVehicles } from "../../pages/page-vehicles";
import { routeUsers } from "../../pages/page-users";
import { routeVehicleProductivity } from "../../pages/page-vehicle-productivity";
import { routeLoginReport } from "../../pages/page-login-report";
import { routeImpactManagement } from "../../pages/page-impact-management";
import { routeMaintenance } from "../../pages/page-maintenance";
import { routePreOpsChecklists } from "../../pages/page-pre-ops-checklists";
import { routeUserProductivity } from "../../pages/page-user-productivity";
import { routeVehicleDevices } from "../../pages/page-vehicle-devices";
import { computed } from "mobx";
import { Navigation } from "../atoms/navigation";
import { SidebarPrimary } from "../molecules/sidebar-primary";
import { routeShockProfiles } from "../../pages/page-shock-profiles";
import { routeUserSettings } from "../../pages/page-user-settings";
import { routePreOpsChecklistSettings } from "../../pages/page-checklist-settings";
import { routeSystemWide } from "../../pages/page-system-wide";
import { ServiceOwnUser } from "../../domain/services/service-own-user";
import { ManagementRole } from "../../api";

import { routeSafetyMessagesSettings } from "../../pages/page-safety-messages-settings";
import { routeTruckActivity } from "../../pages/page-truck-activity";
import { routePedestrianActivity } from "../../pages/page-pedestrian-activity";
import { routeZoningActivity } from "../../pages/page-zoning-activity";
import { routeSafetyMessages } from "../../pages/page-safety-messages";
import { routeGeneralSettings } from "../../pages/page-general-settings";
import { routeVehicleSettings } from "../../pages/page-vehicle-settings";
import { routeVehicleMovementPeriods } from "../../pages/page-vehicle-movement-periods";
import { routeColumnVisibility } from "../../pages/page-column-visibility";
import { routeDashboard } from "../../pages/page-dashboard";
import { routePhones } from "../../pages/page-phones";
import { routeMotionSensors } from "../../pages/page-motion-sensors";
import { routeBulkImport } from "../../pages/page-bulk-import";
import { routeActionConfiguration } from "../../pages/page-action-configuration";

@external
@observer
export class SidebarNavigation extends React.Component {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly serviceOwnUser!: ServiceOwnUser;

    constructor(props: {}) {
        super(props);
    }

    @computed private get showAdminMenu(): boolean {
        const { user } = this.serviceOwnUser;
        if (!user) {
            return false;
        }
        return user.managementRole === ManagementRole.SUPER_ADMIN;
    }

    @computed private get showSetupMenu(): boolean {
        const { user } = this.serviceOwnUser;
        if (!user) {
            return false;
        }
        return (
            user.managementRole === ManagementRole.INSTANCE_ADMIN ||
            user.managementRole === ManagementRole.SUPER_ADMIN
        );
    }

    public render(): JSX.Element {
        // TODO:
        // These are temporary hacks for the eloshield.elofleet.com instance.
        // Remove them once we have a propper solution.
        let showElofleetItems = false;
        const hostname = window.location.hostname;
        if (hostname === "eloshield.elofleet.com") {
            showElofleetItems = true;
        }

        return (
            <SidebarPrimary
                primaryContent={
                    <nav>
                        <Navigation>
                            {!showElofleetItems && (
                                <>
                                    <NavigationItem route={routeDashboard} />
                                    <NavigationGroup
                                        title={this.i18n.t("component.sidebarNavigation.fleet")}
                                        icon="Forklift"
                                        routes={[
                                            routeVehicles,
                                            routeVehicleProductivity,
                                            routeVehicleMovementPeriods,
                                        ]}
                                    />
                                    <NavigationGroup
                                        title={this.i18n.t(
                                            "component.sidebarNavigation.driverTeam",
                                        )}
                                        icon="Group"
                                        routes={[
                                            routeUsers,
                                            routeUserProductivity,
                                            routeLoginReport,
                                        ]}
                                    />
                                    <NavigationItem route={routeImpactManagement} />
                                    <NavigationItem route={routeMaintenance} />
                                    <NavigationItem route={routePreOpsChecklists} />
                                    <NavigationItem route={routeSafetyMessages} />
                                </>
                            )}
                            {showElofleetItems && (
                                <>
                                    <NavigationItem route={routeTruckActivity} />
                                    <NavigationItem route={routePedestrianActivity} />
                                    <NavigationItem route={routeZoningActivity} />
                                </>
                            )}
                        </Navigation>
                    </nav>
                }
                secondaryContent={
                    <Navigation>
                        {this.showSetupMenu && (
                            <NavigationGroup
                                title={this.i18n.t("component.sidebarNavigation.setup")}
                                icon="Admin"
                                routes={[
                                    routeUserSettings,
                                    routeVehicleSettings,
                                    routePreOpsChecklistSettings,
                                    routeSafetyMessagesSettings,
                                    routeSystemWide,
                                ]}
                                bottom
                            />
                        )}
                        {this.showAdminMenu && (
                            <NavigationGroup
                                title={this.i18n.t("component.sidebarNavigation.settings")}
                                icon="Settings"
                                routes={[
                                    routeGeneralSettings,
                                    routeVehicleDevices,
                                    routeShockProfiles,
                                    routeColumnVisibility,
                                    routePhones,
                                    routeMotionSensors,
                                    routeBulkImport,
                                    routeActionConfiguration,
                                ]}
                                bottom
                            />
                        )}
                    </Navigation>
                }
            />
        );
    }
}
