import React from "react";
import { ElodmsLayout } from "../../components/layout";
import { UUID } from "../../../utils/uuid";
import { useStore } from "../../stores/store";
import {
    CostType,
    DocumentGroup,
    DocumentType,
    FilePickerDocument,
    filePickerDocuments,
} from "../../stores/document";
import ButtonRadioGroup from "../../components/button-radio-group";
import TableRadioGroup from "../../components/table-radio-group";
import DocumentTypeRadioGroup from "../../components/document-type-radio-group";
import { Card } from "../../components/card";
import { Label } from "../../components/label";
import SingleSelect from "../../components/single-select";
import { TextInput } from "../../components/text-input";
import { Transition } from "@headlessui/react";
import { FilePickerModal } from "../../components/file-picker-modal";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router";
import routes from "../../routes";
import Fuse from "fuse.js";
import { Equipment } from "../../stores/equipment";
import { mdiChevronRight, mdiMagnify, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { take } from "ramda";
import { useTranslation } from "react-i18next";

interface DraftCostItem {
    name: string;
    amount: string;
    type: CostType | null;
}

export function NewDocument(): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [searchString, setSearchString] = React.useState("");

    const [filePickerModalOpen, setFilePickerModalOpen] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState<FilePickerDocument | null>(null);

    const [costItems, setCostItems] = useImmer<Array<DraftCostItem>>([]);

    const [documentGroup, setDocumentGroup] = React.useState<DocumentGroup | null>(null);
    const [documentType, setDocumentType] = React.useState<DocumentType | null>(null);

    const [associatedEquipment, setAssociatedEquipment] = React.useState<UUID | null>(null);

    const resetForm = (): void => {
        setSelectedFile(null);

        setCostItems([
            {
                name: "",
                amount: "",
                type: null,
            },
        ]);

        // No reason to reset documentGroup.
        setDocumentType(null);

        setAssociatedEquipment(null);
    };

    const costItemNull = (costItem: DraftCostItem): boolean =>
        (costItem.name === null || costItem.name === "") &&
        (costItem.amount === null || costItem.amount === "") &&
        costItem.type === null;

    const costItemValid = (costItem: DraftCostItem): boolean =>
        (costItem.name !== "" &&
            costItem.amount !== "" &&
            !isNaN(Number(costItem.amount)) &&
            costItem.type !== null) ||
        costItemNull(costItem);

    const formValid =
        selectedFile !== null &&
        costItems.every(costItemValid) &&
        documentType !== null &&
        associatedEquipment !== null;

    const insertDocument = useStore((state) => state.insertDocument);

    const equipment: Array<[UUID, Equipment]> = useStore((state) => {
        let equipment = Object.entries(state.equipment).map(([equipmentId, equipment]) => ({
            id: equipmentId,
            equipment: {
                ...equipment,
                oem: t(equipment.oem),
                department: t(equipment.department),
                modelName: t(equipment.modelName),
            },
        }));

        if (searchString) {
            const fuse = new Fuse(equipment, {
                keys: [
                    "equipment.oem",
                    "equipment.modelName",
                    "equipment.serialNumber",
                    "equipment.department",
                    "equipment.equipmentType",
                    "equipment.manufactureYear",
                ],
                minMatchCharLength: 2,
                threshold: 0.5,
            });
            equipment = fuse.search(searchString).map(({ item }) => item);
        }

        return equipment.map(({ id, equipment }) => [id, equipment]);
    });

    const onSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        if (!formValid) {
            return;
        }

        insertDocument({
            name: selectedFile.name,
            documentType: documentType,
            costItems: costItems
                .filter((costItem) => !costItemNull(costItem))
                .map((costItem) => ({
                    name: costItem.name,
                    amount: Math.trunc(Number(costItem.amount) * 100),
                    type: costItem.type!,
                })),
            equipmentId: associatedEquipment,
            link: selectedFile.link,
        });
        resetForm();
        navigate(routes.viewEquipment.generateUrl(associatedEquipment));
    };

    return (
        <ElodmsLayout title={t("dms.page.newDocument.title")}>
            <form onSubmit={onSubmit} className="flex h-full flex-col">
                <Card>
                    <Label label={t("dms.page.newDocument.filePicker.label")} />
                    {selectedFile && (
                        <div className="flex gap-x-8 rounded border border-slate-300 bg-white px-8 leading-8">
                            <span>{t(selectedFile.name)}</span>
                            <span className="grow text-neutral-500">{selectedFile.fileName}</span>
                            <a href={selectedFile.link} target="_blank">
                                {t("dms.page.newDocument.viewFile")}
                                <Icon
                                    className="ml-1 inline-block h-4"
                                    path={mdiChevronRight}
                                ></Icon>
                            </a>
                        </div>
                    )}
                    {!selectedFile && (
                        <button
                            onClick={(e: React.FormEvent) => {
                                e.preventDefault();
                                setFilePickerModalOpen(true);
                            }}
                            className="h-48 rounded border-2 border-dashed border-slate-300 bg-white"
                            type="button"
                        >
                            {t("dms.page.newDocument.filePicker.button")}
                        </button>
                    )}

                    <Label label={t("dms.page.newDocument.costItems.label")} className="mt-8" />
                    {costItems.map((costItem, index) => (
                        <div key={index} className="mb-2 flex gap-4">
                            <TextInput
                                value={costItem.name}
                                onChange={(e) =>
                                    setCostItems((costItems) => {
                                        costItems[index].name = e.target.value;
                                    })
                                }
                                placeholder={t("dms.page.newDocument.costItems.name")}
                                className="grow-2 basis-4"
                            />
                            <TextInput
                                value={costItem.amount}
                                onChange={(e) =>
                                    setCostItems((costItems) => {
                                        costItems[index].amount = e.target.value.replace(
                                            /[^\d.]/g,
                                            "",
                                        );
                                    })
                                }
                                placeholder={t("dms.page.newDocument.costItems.amount")}
                                className="grow basis-4"
                            />
                            <SingleSelect
                                value={costItem.type}
                                placeholder={t("dms.page.newDocument.costItems.type")}
                                onChange={(value: string | null) =>
                                    setCostItems((costItems) => {
                                        costItems[index].type = value as CostType;
                                    })
                                }
                                className="grow basis-4"
                                displayValue={(costType: string) =>
                                    t(`dms.schema.costType.${costType}`)
                                }
                            >
                                {Object.values(CostType).map((costType) => (
                                    <SingleSelect.Option
                                        key={costType}
                                        value={costType}
                                        text={t(`dms.schema.costType.${costType}`)}
                                    />
                                ))}
                            </SingleSelect>
                        </div>
                    ))}
                    <button
                        onClick={(e: React.FormEvent) => {
                            e.preventDefault();
                            setCostItems((state) => {
                                state.push({
                                    name: "",
                                    amount: "",
                                    type: null,
                                });
                            });
                        }}
                        className="w-fit shrink"
                    >
                        <Icon path={mdiPlus} className="mr-1 inline-block h-4" />
                        {t("dms.page.newDocument.costItems.add")}
                    </button>
                </Card>
                <Card>
                    <Label label={t("dms.page.newDocument.documentType.label")} />
                    <ButtonRadioGroup
                        value={documentGroup}
                        onChange={setDocumentGroup}
                        className="mb-2"
                    >
                        <ButtonRadioGroup.Option
                            value={DocumentGroup.Invoice}
                            label={t("dms.page.newDocument.documentType.invoice")}
                        />
                        <ButtonRadioGroup.Option
                            value={DocumentGroup.Contract}
                            label={t("dms.page.newDocument.documentType.contract")}
                        />
                        <ButtonRadioGroup.Option
                            value={DocumentGroup.Other}
                            label={t("dms.page.newDocument.documentType.other")}
                        />
                    </ButtonRadioGroup>
                    <DocumentTypeRadioGroup
                        documentGroup={documentGroup}
                        value={documentType}
                        onChange={setDocumentType}
                    />
                </Card>

                <Card className="row-1 relative grow pb-8">
                    <Label label={t("dms.page.newDocument.equipment.label")} />
                    <div className="mb-2 flex items-center">
                        <Icon path={mdiMagnify} className="mr-1 h-6 text-neutral-500" />
                        <TextInput
                            value={searchString}
                            onChange={(e) => setSearchString(e.target.value)}
                            placeholder={t("dms.page.newDocument.equipment.search")}
                            className="form-input w-full border-none bg-transparent px-2"
                        />
                    </div>
                    <TableRadioGroup value={associatedEquipment} onChange={setAssociatedEquipment}>
                        {take(
                            5,
                            equipment.map(([id, equipment]) => (
                                <TableRadioGroup.Option key={id} id={id} {...equipment} />
                            )),
                        )}
                    </TableRadioGroup>

                    <div className="flex grow items-end">
                        <div className="z-10 flex w-full justify-end">
                            <button
                                className="mt-8 h-12 rounded border-2 border-transparent bg-yellow-400 px-8 transition-colors duration-150 focus:border-blue-900 disabled:bg-gray-200 disabled:text-gray-500"
                                type="submit"
                                disabled={!formValid}
                            >
                                {t("dms.page.newDocument.finishDocument")}
                            </button>
                        </div>

                        <Transition
                            show={formValid}
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            enter="transition-opacity duration-200"
                            leave="transition-opacity duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="absolute bottom-0 right-0">
                                <svg
                                    width="330"
                                    height="128"
                                    viewBox="0 0 330 128"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_75_1556)">
                                        <path
                                            d="M207.971 20.3573L885.796 -29.8977L341.679 267.961L-336.146 318.216L207.971 20.3573Z"
                                            fill="#FFDC00"
                                            fillOpacity="0.3"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_75_1556">
                                            <rect width="330" height="128" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </Transition>
                    </div>
                </Card>
            </form>

            <FilePickerModal
                isOpen={filePickerModalOpen}
                onDismiss={() => setFilePickerModalOpen(false)}
                onSelect={(file: FilePickerDocument) => {
                    setSelectedFile(file);
                    setFilePickerModalOpen(false);
                }}
                files={filePickerDocuments}
            />
        </ElodmsLayout>
    );
}
