import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { prop } from "ramda";
import { runInAction } from "mobx";
import { UUID } from "../../utils/uuid";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { Validation } from "../../utils/validation";
import { component, inject } from "tsdi";
import {
    UserGroupVehicleGroupAssignmentsApi,
    MultiUserGroupVehicleGroupAssignmentCreate,
    PageInfo,
    UserGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsRequest,
    UserGroupVehicleGroupAssignment,
    UserGroupVehicleGroupAssignmentCreate,
    UserGroupVehicleGroupAssignmentSortKey,
} from "../../api";
import { RepositoryVehicleGroups } from "./repository-vehicle-groups";
import { RepositoryUserGroups } from "./repository-user-groups";
import { defaultPageSize } from "../../utils/constants";

export type UserGroupVehicleGroupAssignmentsQuery = Omit<
    UserGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsRequest,
    "page"
>;

export interface UserGroupVehicleGroupAssignmentsApiResource extends ApiResource {
    entity: UserGroupVehicleGroupAssignment;
    query: UserGroupVehicleGroupAssignmentsQuery;
    update: unknown;
    create: UserGroupVehicleGroupAssignmentCreate;
    sortKey: UserGroupVehicleGroupAssignmentSortKey;
}

@component
export class RepositoryUserGroupVehicleGroupAssignments extends ElofleetRepository<UserGroupVehicleGroupAssignmentsApiResource> {
    @inject
    private readonly userGroupVehicleGroupAssignmentsApi!: UserGroupVehicleGroupAssignmentsApi;
    @inject private readonly repositoryVehicleGroups!: RepositoryVehicleGroups;
    @inject private readonly repositoryUserGroups!: RepositoryUserGroups;

    public validation = {
        create: new Validation(
            (userGroupVehicleGroupAssignmentCreate: UserGroupVehicleGroupAssignmentCreate) =>
                this.userGroupVehicleGroupAssignmentsApi.userGroupVehicleGroupAssignmentsValidateCreateUserGroupVehicleGroupAssignment(
                    {
                        userGroupVehicleGroupAssignmentCreate:
                            userGroupVehicleGroupAssignmentCreate,
                    },
                ),
        ),
    };

    protected async fetchByQuery(
        query: UserGroupVehicleGroupAssignmentsQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<UserGroupVehicleGroupAssignment>> {
        const page = pagination.offset / this.defaultCount;
        const entities =
            await this.userGroupVehicleGroupAssignmentsApi.userGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignments(
                {
                    ...query,
                    page,
                },
            );
        return { entities };
    }

    public fetchMetadata(query: UserGroupVehicleGroupAssignmentsQuery): Promise<PageInfo> {
        return this.userGroupVehicleGroupAssignmentsApi.userGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsMetadata(
            query,
        );
    }

    protected async fetchById(
        assignmentId: UUID,
    ): Promise<UserGroupVehicleGroupAssignment | undefined> {
        return await this.userGroupVehicleGroupAssignmentsApi.userGroupVehicleGroupAssignmentsReadUserGroupVehicleGroupAssignment(
            {
                assignmentId,
            },
        );
    }

    protected extractId = prop("id");

    public async create(
        userGroupVehicleGroupAssignmentCreate: UserGroupVehicleGroupAssignmentCreate,
    ): Promise<UserGroupVehicleGroupAssignment> {
        const userGroupVehicleGroupAssignment = await this.wrapApiCall(
            this.userGroupVehicleGroupAssignmentsApi.userGroupVehicleGroupAssignmentsCreateUserGroupVehicleGroupAssignment(
                {
                    userGroupVehicleGroupAssignmentCreate,
                },
            ),
        );
        runInAction(() => {
            // As the user and vehicle group lists contain information about
            // group assignments we need to reload those as well.
            this.repositoryUserGroups.reloadId(userGroupVehicleGroupAssignmentCreate.userGroupId);
            this.repositoryVehicleGroups.reloadId(
                userGroupVehicleGroupAssignmentCreate.vehicleGroupId,
            );
            this.reloadQuery({
                pageSize: defaultPageSize,
            });
            this.add(userGroupVehicleGroupAssignment);
        });
        return userGroupVehicleGroupAssignment;
    }

    public update(_id: string, _update: never): Promise<UserGroupVehicleGroupAssignment> {
        throw new Error("Method not implemented.");
    }

    public async delete(...assignmentIds: UUID[]): Promise<void> {
        await this.wrapApiCall(
            Promise.all(
                assignmentIds.map((assignmentId) =>
                    this.userGroupVehicleGroupAssignmentsApi.userGroupVehicleGroupAssignmentsDeleteUserGroupVehicleGroupAssignment(
                        {
                            assignmentId,
                        },
                    ),
                ),
            ),
        );
        // As the user and vehicle group lists contain information about group
        // assignments, we need to reload those as well.
        this.repositoryUserGroups.reloadQuery({
            pageSize: defaultPageSize,
        });
        this.repositoryVehicleGroups.reloadQuery({
            pageSize: defaultPageSize,
        });
        await this.reloadQuery({
            pageSize: defaultPageSize,
        });
    }

    public async createMultiple(
        multiUserGroupVehicleGroupAssignmentCreate: MultiUserGroupVehicleGroupAssignmentCreate,
    ): Promise<UserGroupVehicleGroupAssignment[]> {
        const assignments = await this.wrapApiCall(
            this.userGroupVehicleGroupAssignmentsApi.userGroupVehicleGroupAssignmentsCreateMultipleUserGroupVehicleGroupAssignment(
                { multiUserGroupVehicleGroupAssignmentCreate },
            ),
        );

        runInAction(() => {
            // As the user and vehicle group lists contain information about
            // group assignments, we need to reload those as well.
            for (const userGroupId of multiUserGroupVehicleGroupAssignmentCreate.userGroupIds) {
                this.repositoryUserGroups.reloadId(userGroupId);
            }

            for (const vehicleGroupId of multiUserGroupVehicleGroupAssignmentCreate.vehicleGroupIds) {
                this.repositoryVehicleGroups.reloadId(vehicleGroupId);
            }
            this.reloadQuery({
                pageSize: defaultPageSize,
            });
        });

        return assignments;
    }
}
