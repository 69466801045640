/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PageInfo,
  ShockProfile,
  ShockProfileCreate,
  ShockProfileSortKey,
  ShockProfileUpdate,
  SortDirection,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    ShockProfileFromJSON,
    ShockProfileToJSON,
    ShockProfileCreateFromJSON,
    ShockProfileCreateToJSON,
    ShockProfileSortKeyFromJSON,
    ShockProfileSortKeyToJSON,
    ShockProfileUpdateFromJSON,
    ShockProfileUpdateToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface ShockProfilesCreateShockProfileRequest {
    shockProfileCreate: ShockProfileCreate;
}

export interface ShockProfilesDeleteShockProfileRequest {
    shockProfileId: string;
}

export interface ShockProfilesListShockProfilesRequest {
    pageSize: number;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: ShockProfileSortKey;
}

export interface ShockProfilesListShockProfilesMetadataRequest {
    pageSize: number;
}

export interface ShockProfilesReadShockProfileRequest {
    shockProfileId: string;
}

export interface ShockProfilesUpdateShockProfileRequest {
    shockProfileId: string;
    shockProfileUpdate: ShockProfileUpdate;
}

export interface ShockProfilesValidateCreateShockProfileRequest {
    shockProfileCreate: ShockProfileCreate;
}

export interface ShockProfilesValidateUpdateShockProfileRequest {
    shockProfileId: string;
    shockProfileUpdate: ShockProfileUpdate;
}

/**
 * ShockProfilesApi - interface
 * 
 * @export
 * @interface ShockProfilesApiInterface
 */
export interface ShockProfilesApiInterface {
    /**
     * Create a new shock profile.
     * @summary Create a shock profile
     * @param {ShockProfileCreate} shockProfileCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShockProfilesApiInterface
     */
    shockProfilesCreateShockProfileRaw(requestParameters: ShockProfilesCreateShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShockProfile>>;

    /**
     * Create a new shock profile.
     * Create a shock profile
     */
    shockProfilesCreateShockProfile(requestParameters: ShockProfilesCreateShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShockProfile>;

    /**
     * Delete the shock profile with the given ID.
     * @summary Delete a shock profile
     * @param {string} shockProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShockProfilesApiInterface
     */
    shockProfilesDeleteShockProfileRaw(requestParameters: ShockProfilesDeleteShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the shock profile with the given ID.
     * Delete a shock profile
     */
    shockProfilesDeleteShockProfile(requestParameters: ShockProfilesDeleteShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Lists all the shock profiles, optionally filtering by their associated vehicle type.
     * @summary List shock profiles for a given vehicle type.
     * @param {number} pageSize 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {ShockProfileSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShockProfilesApiInterface
     */
    shockProfilesListShockProfilesRaw(requestParameters: ShockProfilesListShockProfilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShockProfile>>>;

    /**
     * Lists all the shock profiles, optionally filtering by their associated vehicle type.
     * List shock profiles for a given vehicle type.
     */
    shockProfilesListShockProfiles(requestParameters: ShockProfilesListShockProfilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShockProfile>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary ShockProfile listing metadata for a specific vehicle type.
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShockProfilesApiInterface
     */
    shockProfilesListShockProfilesMetadataRaw(requestParameters: ShockProfilesListShockProfilesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * ShockProfile listing metadata for a specific vehicle type.
     */
    shockProfilesListShockProfilesMetadata(requestParameters: ShockProfilesListShockProfilesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the shock profile specified by the given ID.
     * @summary Get a single shock profile
     * @param {string} shockProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShockProfilesApiInterface
     */
    shockProfilesReadShockProfileRaw(requestParameters: ShockProfilesReadShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShockProfile>>;

    /**
     * Returns the shock profile specified by the given ID.
     * Get a single shock profile
     */
    shockProfilesReadShockProfile(requestParameters: ShockProfilesReadShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShockProfile>;

    /**
     * Update a shock profile by a given ID.
     * @summary Update a shock profile.
     * @param {string} shockProfileId 
     * @param {ShockProfileUpdate} shockProfileUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShockProfilesApiInterface
     */
    shockProfilesUpdateShockProfileRaw(requestParameters: ShockProfilesUpdateShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShockProfile>>;

    /**
     * Update a shock profile by a given ID.
     * Update a shock profile.
     */
    shockProfilesUpdateShockProfile(requestParameters: ShockProfilesUpdateShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShockProfile>;

    /**
     * Validate data for a new shock profile without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a new shock profile
     * @param {ShockProfileCreate} shockProfileCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShockProfilesApiInterface
     */
    shockProfilesValidateCreateShockProfileRaw(requestParameters: ShockProfilesValidateCreateShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new shock profile without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a new shock profile
     */
    shockProfilesValidateCreateShockProfile(requestParameters: ShockProfilesValidateCreateShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate data for updating the given shock profile, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate shock profile update.
     * @param {string} shockProfileId 
     * @param {ShockProfileUpdate} shockProfileUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShockProfilesApiInterface
     */
    shockProfilesValidateUpdateShockProfileRaw(requestParameters: ShockProfilesValidateUpdateShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for updating the given shock profile, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate shock profile update.
     */
    shockProfilesValidateUpdateShockProfile(requestParameters: ShockProfilesValidateUpdateShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class ShockProfilesApi extends runtime.BaseAPI implements ShockProfilesApiInterface {

    /**
     * Create a new shock profile.
     * Create a shock profile
     */
    async shockProfilesCreateShockProfileRaw(requestParameters: ShockProfilesCreateShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShockProfile>> {
        if (requestParameters.shockProfileCreate === null || requestParameters.shockProfileCreate === undefined) {
            throw new runtime.RequiredError('shockProfileCreate','Required parameter requestParameters.shockProfileCreate was null or undefined when calling shockProfilesCreateShockProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shock_profiles/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShockProfileCreateToJSON(requestParameters.shockProfileCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShockProfileFromJSON(jsonValue));
    }

    /**
     * Create a new shock profile.
     * Create a shock profile
     */
    async shockProfilesCreateShockProfile(requestParameters: ShockProfilesCreateShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShockProfile> {
        const response = await this.shockProfilesCreateShockProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the shock profile with the given ID.
     * Delete a shock profile
     */
    async shockProfilesDeleteShockProfileRaw(requestParameters: ShockProfilesDeleteShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shockProfileId === null || requestParameters.shockProfileId === undefined) {
            throw new runtime.RequiredError('shockProfileId','Required parameter requestParameters.shockProfileId was null or undefined when calling shockProfilesDeleteShockProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shock_profiles/{shock_profile_id}`.replace(`{${"shock_profile_id"}}`, encodeURIComponent(String(requestParameters.shockProfileId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the shock profile with the given ID.
     * Delete a shock profile
     */
    async shockProfilesDeleteShockProfile(requestParameters: ShockProfilesDeleteShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.shockProfilesDeleteShockProfileRaw(requestParameters, initOverrides);
    }

    /**
     * Lists all the shock profiles, optionally filtering by their associated vehicle type.
     * List shock profiles for a given vehicle type.
     */
    async shockProfilesListShockProfilesRaw(requestParameters: ShockProfilesListShockProfilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShockProfile>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling shockProfilesListShockProfiles.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shock_profiles/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShockProfileFromJSON));
    }

    /**
     * Lists all the shock profiles, optionally filtering by their associated vehicle type.
     * List shock profiles for a given vehicle type.
     */
    async shockProfilesListShockProfiles(requestParameters: ShockProfilesListShockProfilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShockProfile>> {
        const response = await this.shockProfilesListShockProfilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * ShockProfile listing metadata for a specific vehicle type.
     */
    async shockProfilesListShockProfilesMetadataRaw(requestParameters: ShockProfilesListShockProfilesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling shockProfilesListShockProfilesMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shock_profiles/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * ShockProfile listing metadata for a specific vehicle type.
     */
    async shockProfilesListShockProfilesMetadata(requestParameters: ShockProfilesListShockProfilesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.shockProfilesListShockProfilesMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the shock profile specified by the given ID.
     * Get a single shock profile
     */
    async shockProfilesReadShockProfileRaw(requestParameters: ShockProfilesReadShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShockProfile>> {
        if (requestParameters.shockProfileId === null || requestParameters.shockProfileId === undefined) {
            throw new runtime.RequiredError('shockProfileId','Required parameter requestParameters.shockProfileId was null or undefined when calling shockProfilesReadShockProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shock_profiles/{shock_profile_id}`.replace(`{${"shock_profile_id"}}`, encodeURIComponent(String(requestParameters.shockProfileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShockProfileFromJSON(jsonValue));
    }

    /**
     * Returns the shock profile specified by the given ID.
     * Get a single shock profile
     */
    async shockProfilesReadShockProfile(requestParameters: ShockProfilesReadShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShockProfile> {
        const response = await this.shockProfilesReadShockProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a shock profile by a given ID.
     * Update a shock profile.
     */
    async shockProfilesUpdateShockProfileRaw(requestParameters: ShockProfilesUpdateShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShockProfile>> {
        if (requestParameters.shockProfileId === null || requestParameters.shockProfileId === undefined) {
            throw new runtime.RequiredError('shockProfileId','Required parameter requestParameters.shockProfileId was null or undefined when calling shockProfilesUpdateShockProfile.');
        }

        if (requestParameters.shockProfileUpdate === null || requestParameters.shockProfileUpdate === undefined) {
            throw new runtime.RequiredError('shockProfileUpdate','Required parameter requestParameters.shockProfileUpdate was null or undefined when calling shockProfilesUpdateShockProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shock_profiles/{shock_profile_id}`.replace(`{${"shock_profile_id"}}`, encodeURIComponent(String(requestParameters.shockProfileId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ShockProfileUpdateToJSON(requestParameters.shockProfileUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShockProfileFromJSON(jsonValue));
    }

    /**
     * Update a shock profile by a given ID.
     * Update a shock profile.
     */
    async shockProfilesUpdateShockProfile(requestParameters: ShockProfilesUpdateShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShockProfile> {
        const response = await this.shockProfilesUpdateShockProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new shock profile without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a new shock profile
     */
    async shockProfilesValidateCreateShockProfileRaw(requestParameters: ShockProfilesValidateCreateShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shockProfileCreate === null || requestParameters.shockProfileCreate === undefined) {
            throw new runtime.RequiredError('shockProfileCreate','Required parameter requestParameters.shockProfileCreate was null or undefined when calling shockProfilesValidateCreateShockProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shock_profiles/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShockProfileCreateToJSON(requestParameters.shockProfileCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new shock profile without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a new shock profile
     */
    async shockProfilesValidateCreateShockProfile(requestParameters: ShockProfilesValidateCreateShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.shockProfilesValidateCreateShockProfileRaw(requestParameters, initOverrides);
    }

    /**
     * Validate data for updating the given shock profile, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate shock profile update.
     */
    async shockProfilesValidateUpdateShockProfileRaw(requestParameters: ShockProfilesValidateUpdateShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shockProfileId === null || requestParameters.shockProfileId === undefined) {
            throw new runtime.RequiredError('shockProfileId','Required parameter requestParameters.shockProfileId was null or undefined when calling shockProfilesValidateUpdateShockProfile.');
        }

        if (requestParameters.shockProfileUpdate === null || requestParameters.shockProfileUpdate === undefined) {
            throw new runtime.RequiredError('shockProfileUpdate','Required parameter requestParameters.shockProfileUpdate was null or undefined when calling shockProfilesValidateUpdateShockProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shock_profiles/{shock_profile_id}/validate`.replace(`{${"shock_profile_id"}}`, encodeURIComponent(String(requestParameters.shockProfileId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ShockProfileUpdateToJSON(requestParameters.shockProfileUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for updating the given shock profile, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate shock profile update.
     */
    async shockProfilesValidateUpdateShockProfile(requestParameters: ShockProfilesValidateUpdateShockProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.shockProfilesValidateUpdateShockProfileRaw(requestParameters, initOverrides);
    }

}
