/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CurrentArtifactVersions,
  DownloadToken,
  ErrorResponse,
} from '../models';
import {
    CurrentArtifactVersionsFromJSON,
    CurrentArtifactVersionsToJSON,
    DownloadTokenFromJSON,
    DownloadTokenToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface ArtifactsAndroidAppApkDownloadRequest {
    expectedVersion: string;
    token?: DownloadToken;
}

export interface ArtifactsFirmwareEloboxDownloadRequest {
    expectedVersion: string;
}

export interface ArtifactsFirmwareMotionSensorDownloadRequest {
    expectedVersion: string;
}

export interface ArtifactsLegacyVersionRoutesLegacyAndroidAppApkDownloadRequest {
    expectedVersion: string;
    legacyExpectedVersionHash: string;
}

export interface ArtifactsLegacyVersionRoutesLegacyFirmwareEloboxDownloadRequest {
    expectedVersion: string;
    legacyExpectedVersionHash: string;
}

export interface ArtifactsLegacyVersionRoutesLegacyFirmwareMotionSensorDownloadRequest {
    expectedVersion: string;
    legacyExpectedVersionHash: string;
}

/**
 * ArtifactsApi - interface
 * 
 * @export
 * @interface ArtifactsApiInterface
 */
export interface ArtifactsApiInterface {
    /**
     * Authentication is done via login or download token.  This route is special and has different behavior in dev mode. It and serves the current artifact directly from the disk, with the version number that\'s found in the `.env` file.
     * @summary Return embedded Android app apk for download.
     * @param {string} expectedVersion 
     * @param {DownloadToken} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsApiInterface
     */
    artifactsAndroidAppApkDownloadRaw(requestParameters: ArtifactsAndroidAppApkDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     * Authentication is done via login or download token.  This route is special and has different behavior in dev mode. It and serves the current artifact directly from the disk, with the version number that\'s found in the `.env` file.
     * Return embedded Android app apk for download.
     */
    artifactsAndroidAppApkDownload(requestParameters: ArtifactsAndroidAppApkDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * This route is special and has different behavior in dev mode. It and serves the current artifact directly from the disk, with the version number that\'s found in the `.env` file.
     * @summary Return embedded ELObox firmware.
     * @param {string} expectedVersion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsApiInterface
     */
    artifactsFirmwareEloboxDownloadRaw(requestParameters: ArtifactsFirmwareEloboxDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     * This route is special and has different behavior in dev mode. It and serves the current artifact directly from the disk, with the version number that\'s found in the `.env` file.
     * Return embedded ELObox firmware.
     */
    artifactsFirmwareEloboxDownload(requestParameters: ArtifactsFirmwareEloboxDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * This route is special and has different behavior in dev mode. It and serves the current artifact directly from the disk, with the version number that\'s found in the `.env` file.
     * @summary Return embedded motion sensor firmware.
     * @param {string} expectedVersion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsApiInterface
     */
    artifactsFirmwareMotionSensorDownloadRaw(requestParameters: ArtifactsFirmwareMotionSensorDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     * This route is special and has different behavior in dev mode. It and serves the current artifact directly from the disk, with the version number that\'s found in the `.env` file.
     * Return embedded motion sensor firmware.
     */
    artifactsFirmwareMotionSensorDownload(requestParameters: ArtifactsFirmwareMotionSensorDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * This function is exclusively used during the testing phase and is designed to return the latest versions every time it is invoked. This ensures that even if the version changes during runtime, the most up-to-date version is always returned.
     * @summary Return current versions of all artifacts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsApiInterface
     */
    artifactsGetCurrentVersionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CurrentArtifactVersions>>;

    /**
     * This function is exclusively used during the testing phase and is designed to return the latest versions every time it is invoked. This ensures that even if the version changes during runtime, the most up-to-date version is always returned.
     * Return current versions of all artifacts.
     */
    artifactsGetCurrentVersions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CurrentArtifactVersions>;

    /**
     * This token is used for authentication when downloading the mobile application.
     * @summary Get a download token via the user login token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsApiInterface
     */
    artifactsGetDownloadTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DownloadToken>>;

    /**
     * This token is used for authentication when downloading the mobile application.
     * Get a download token via the user login token
     */
    artifactsGetDownloadToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DownloadToken>;

    /**
     * Authentication is done via login or download token. Depending on the \"build-with-artifacts\" feature, this will return either the real artifact or a dummy response.
     * @summary Return embedded Android app apk for download.
     * @param {string} expectedVersion 
     * @param {string} legacyExpectedVersionHash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsApiInterface
     */
    artifactsLegacyVersionRoutesLegacyAndroidAppApkDownloadRaw(requestParameters: ArtifactsLegacyVersionRoutesLegacyAndroidAppApkDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     * Authentication is done via login or download token. Depending on the \"build-with-artifacts\" feature, this will return either the real artifact or a dummy response.
     * Return embedded Android app apk for download.
     */
    artifactsLegacyVersionRoutesLegacyAndroidAppApkDownload(requestParameters: ArtifactsLegacyVersionRoutesLegacyAndroidAppApkDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * Depending on the \"build-with-artifacts\" feature, this will return either the real artifact or a dummy response.
     * @summary Return embedded ELObox firmware.
     * @param {string} expectedVersion 
     * @param {string} legacyExpectedVersionHash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsApiInterface
     */
    artifactsLegacyVersionRoutesLegacyFirmwareEloboxDownloadRaw(requestParameters: ArtifactsLegacyVersionRoutesLegacyFirmwareEloboxDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     * Depending on the \"build-with-artifacts\" feature, this will return either the real artifact or a dummy response.
     * Return embedded ELObox firmware.
     */
    artifactsLegacyVersionRoutesLegacyFirmwareEloboxDownload(requestParameters: ArtifactsLegacyVersionRoutesLegacyFirmwareEloboxDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * Depending on the \"build-with-artifacts\" feature, this will return either the real artifact or a dummy response.
     * @summary Return embedded motion sensor firmware.
     * @param {string} expectedVersion 
     * @param {string} legacyExpectedVersionHash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsApiInterface
     */
    artifactsLegacyVersionRoutesLegacyFirmwareMotionSensorDownloadRaw(requestParameters: ArtifactsLegacyVersionRoutesLegacyFirmwareMotionSensorDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     * Depending on the \"build-with-artifacts\" feature, this will return either the real artifact or a dummy response.
     * Return embedded motion sensor firmware.
     */
    artifactsLegacyVersionRoutesLegacyFirmwareMotionSensorDownload(requestParameters: ArtifactsLegacyVersionRoutesLegacyFirmwareMotionSensorDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

}

/**
 * 
 */
export class ArtifactsApi extends runtime.BaseAPI implements ArtifactsApiInterface {

    /**
     * Authentication is done via login or download token.  This route is special and has different behavior in dev mode. It and serves the current artifact directly from the disk, with the version number that\'s found in the `.env` file.
     * Return embedded Android app apk for download.
     */
    async artifactsAndroidAppApkDownloadRaw(requestParameters: ArtifactsAndroidAppApkDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.expectedVersion === null || requestParameters.expectedVersion === undefined) {
            throw new runtime.RequiredError('expectedVersion','Required parameter requestParameters.expectedVersion was null or undefined when calling artifactsAndroidAppApkDownload.');
        }

        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artifacts/android_app/{expected_version}`.replace(`{${"expected_version"}}`, encodeURIComponent(String(requestParameters.expectedVersion))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Authentication is done via login or download token.  This route is special and has different behavior in dev mode. It and serves the current artifact directly from the disk, with the version number that\'s found in the `.env` file.
     * Return embedded Android app apk for download.
     */
    async artifactsAndroidAppApkDownload(requestParameters: ArtifactsAndroidAppApkDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.artifactsAndroidAppApkDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This route is special and has different behavior in dev mode. It and serves the current artifact directly from the disk, with the version number that\'s found in the `.env` file.
     * Return embedded ELObox firmware.
     */
    async artifactsFirmwareEloboxDownloadRaw(requestParameters: ArtifactsFirmwareEloboxDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.expectedVersion === null || requestParameters.expectedVersion === undefined) {
            throw new runtime.RequiredError('expectedVersion','Required parameter requestParameters.expectedVersion was null or undefined when calling artifactsFirmwareEloboxDownload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artifacts/firmware/vehicle_device/{expected_version}`.replace(`{${"expected_version"}}`, encodeURIComponent(String(requestParameters.expectedVersion))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * This route is special and has different behavior in dev mode. It and serves the current artifact directly from the disk, with the version number that\'s found in the `.env` file.
     * Return embedded ELObox firmware.
     */
    async artifactsFirmwareEloboxDownload(requestParameters: ArtifactsFirmwareEloboxDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.artifactsFirmwareEloboxDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This route is special and has different behavior in dev mode. It and serves the current artifact directly from the disk, with the version number that\'s found in the `.env` file.
     * Return embedded motion sensor firmware.
     */
    async artifactsFirmwareMotionSensorDownloadRaw(requestParameters: ArtifactsFirmwareMotionSensorDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.expectedVersion === null || requestParameters.expectedVersion === undefined) {
            throw new runtime.RequiredError('expectedVersion','Required parameter requestParameters.expectedVersion was null or undefined when calling artifactsFirmwareMotionSensorDownload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artifacts/firmware/motion_sensor/{expected_version}`.replace(`{${"expected_version"}}`, encodeURIComponent(String(requestParameters.expectedVersion))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * This route is special and has different behavior in dev mode. It and serves the current artifact directly from the disk, with the version number that\'s found in the `.env` file.
     * Return embedded motion sensor firmware.
     */
    async artifactsFirmwareMotionSensorDownload(requestParameters: ArtifactsFirmwareMotionSensorDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.artifactsFirmwareMotionSensorDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This function is exclusively used during the testing phase and is designed to return the latest versions every time it is invoked. This ensures that even if the version changes during runtime, the most up-to-date version is always returned.
     * Return current versions of all artifacts.
     */
    async artifactsGetCurrentVersionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CurrentArtifactVersions>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artifacts/current_versions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CurrentArtifactVersionsFromJSON(jsonValue));
    }

    /**
     * This function is exclusively used during the testing phase and is designed to return the latest versions every time it is invoked. This ensures that even if the version changes during runtime, the most up-to-date version is always returned.
     * Return current versions of all artifacts.
     */
    async artifactsGetCurrentVersions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CurrentArtifactVersions> {
        const response = await this.artifactsGetCurrentVersionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * This token is used for authentication when downloading the mobile application.
     * Get a download token via the user login token
     */
    async artifactsGetDownloadTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DownloadToken>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artifacts/download_token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DownloadTokenFromJSON(jsonValue));
    }

    /**
     * This token is used for authentication when downloading the mobile application.
     * Get a download token via the user login token
     */
    async artifactsGetDownloadToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DownloadToken> {
        const response = await this.artifactsGetDownloadTokenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Authentication is done via login or download token. Depending on the \"build-with-artifacts\" feature, this will return either the real artifact or a dummy response.
     * Return embedded Android app apk for download.
     */
    async artifactsLegacyVersionRoutesLegacyAndroidAppApkDownloadRaw(requestParameters: ArtifactsLegacyVersionRoutesLegacyAndroidAppApkDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.expectedVersion === null || requestParameters.expectedVersion === undefined) {
            throw new runtime.RequiredError('expectedVersion','Required parameter requestParameters.expectedVersion was null or undefined when calling artifactsLegacyVersionRoutesLegacyAndroidAppApkDownload.');
        }

        if (requestParameters.legacyExpectedVersionHash === null || requestParameters.legacyExpectedVersionHash === undefined) {
            throw new runtime.RequiredError('legacyExpectedVersionHash','Required parameter requestParameters.legacyExpectedVersionHash was null or undefined when calling artifactsLegacyVersionRoutesLegacyAndroidAppApkDownload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artifacts/android_app/{expected_version}/{legacy_expected_version_hash}`.replace(`{${"expected_version"}}`, encodeURIComponent(String(requestParameters.expectedVersion))).replace(`{${"legacy_expected_version_hash"}}`, encodeURIComponent(String(requestParameters.legacyExpectedVersionHash))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Authentication is done via login or download token. Depending on the \"build-with-artifacts\" feature, this will return either the real artifact or a dummy response.
     * Return embedded Android app apk for download.
     */
    async artifactsLegacyVersionRoutesLegacyAndroidAppApkDownload(requestParameters: ArtifactsLegacyVersionRoutesLegacyAndroidAppApkDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.artifactsLegacyVersionRoutesLegacyAndroidAppApkDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Depending on the \"build-with-artifacts\" feature, this will return either the real artifact or a dummy response.
     * Return embedded ELObox firmware.
     */
    async artifactsLegacyVersionRoutesLegacyFirmwareEloboxDownloadRaw(requestParameters: ArtifactsLegacyVersionRoutesLegacyFirmwareEloboxDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.expectedVersion === null || requestParameters.expectedVersion === undefined) {
            throw new runtime.RequiredError('expectedVersion','Required parameter requestParameters.expectedVersion was null or undefined when calling artifactsLegacyVersionRoutesLegacyFirmwareEloboxDownload.');
        }

        if (requestParameters.legacyExpectedVersionHash === null || requestParameters.legacyExpectedVersionHash === undefined) {
            throw new runtime.RequiredError('legacyExpectedVersionHash','Required parameter requestParameters.legacyExpectedVersionHash was null or undefined when calling artifactsLegacyVersionRoutesLegacyFirmwareEloboxDownload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artifacts/firmware/vehicle_device/{expected_version}/{legacy_expected_version_hash}`.replace(`{${"expected_version"}}`, encodeURIComponent(String(requestParameters.expectedVersion))).replace(`{${"legacy_expected_version_hash"}}`, encodeURIComponent(String(requestParameters.legacyExpectedVersionHash))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Depending on the \"build-with-artifacts\" feature, this will return either the real artifact or a dummy response.
     * Return embedded ELObox firmware.
     */
    async artifactsLegacyVersionRoutesLegacyFirmwareEloboxDownload(requestParameters: ArtifactsLegacyVersionRoutesLegacyFirmwareEloboxDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.artifactsLegacyVersionRoutesLegacyFirmwareEloboxDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Depending on the \"build-with-artifacts\" feature, this will return either the real artifact or a dummy response.
     * Return embedded motion sensor firmware.
     */
    async artifactsLegacyVersionRoutesLegacyFirmwareMotionSensorDownloadRaw(requestParameters: ArtifactsLegacyVersionRoutesLegacyFirmwareMotionSensorDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.expectedVersion === null || requestParameters.expectedVersion === undefined) {
            throw new runtime.RequiredError('expectedVersion','Required parameter requestParameters.expectedVersion was null or undefined when calling artifactsLegacyVersionRoutesLegacyFirmwareMotionSensorDownload.');
        }

        if (requestParameters.legacyExpectedVersionHash === null || requestParameters.legacyExpectedVersionHash === undefined) {
            throw new runtime.RequiredError('legacyExpectedVersionHash','Required parameter requestParameters.legacyExpectedVersionHash was null or undefined when calling artifactsLegacyVersionRoutesLegacyFirmwareMotionSensorDownload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artifacts/firmware/motion_sensor/{expected_version}/{legacy_expected_version_hash}`.replace(`{${"expected_version"}}`, encodeURIComponent(String(requestParameters.expectedVersion))).replace(`{${"legacy_expected_version_hash"}}`, encodeURIComponent(String(requestParameters.legacyExpectedVersionHash))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Depending on the \"build-with-artifacts\" feature, this will return either the real artifact or a dummy response.
     * Return embedded motion sensor firmware.
     */
    async artifactsLegacyVersionRoutesLegacyFirmwareMotionSensorDownload(requestParameters: ArtifactsLegacyVersionRoutesLegacyFirmwareMotionSensorDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.artifactsLegacyVersionRoutesLegacyFirmwareMotionSensorDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
