/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The download token is created by hashing the login token of the user.
 * @export
 * @interface DownloadToken
 */
export interface DownloadToken {
    /**
     * 
     * @type {string}
     * @memberof DownloadToken
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadToken
     */
    loginTokenId: string;
}

/**
 * Check if a given object implements the DownloadToken interface.
 */
export function instanceOfDownloadToken(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "loginTokenId" in value;

    return isInstance;
}

export function DownloadTokenFromJSON(json: any): DownloadToken {
    return DownloadTokenFromJSONTyped(json, false);
}

export function DownloadTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): DownloadToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'loginTokenId': json['loginTokenId'],
    };
}

export function DownloadTokenToJSON(value?: DownloadToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'loginTokenId': value.loginTokenId,
    };
}

