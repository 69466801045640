import css from "./dashboard-card.scss";
import React, { ReactNode, useState } from "react";
import {
    DirectionalHint,
    HoverCard,
    HoverCardType,
    Icon,
    IconButton,
    Modal,
    Stack,
} from "@fluentui/react";
import { Trans } from "react-i18next";

interface Props
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    className?: string;
    elevated?: boolean;
    maximizable?: boolean;
    children: ReactNode;
}

export function DashboardCard({
    elevated,
    children,
    className = "",
    maximizable = true,
    ...props
}: Props): JSX.Element {
    const elevatedClass: string = elevated ? css["dashboardCard--elevated"] : "";
    const [isModal, setIsModal] = useState(false);

    let maximizeButton;
    let modalElement;
    if (maximizable) {
        maximizeButton = (
            <IconButton
                className={css.dashboardCard__maximizeButton}
                onClick={() => setIsModal(true)}
            >
                <Icon iconName="FullScreen"></Icon>
            </IconButton>
        );

        modalElement = (
            <CardModal isOpen={isModal} onDismiss={() => setIsModal(false)}>
                {children}
            </CardModal>
        );
    }

    return (
        <div className={`${elevatedClass} ${css.dashboardCard} ${className}`} {...props}>
            {maximizeButton}
            <div className={css.dashboardCard__contents}>{children}</div>

            {modalElement}
        </div>
    );
}

function CardModal({
    isOpen,
    onDismiss,
    children,
}: {
    isOpen: boolean;
    onDismiss: () => void;
    children: ReactNode;
}): JSX.Element {
    return (
        <Modal
            isOpen={isOpen}
            onDismiss={onDismiss}
            styles={{ main: { width: "calc(min(70rem, 100%))" } }}
        >
            <IconButton className={css.dashboardCard__maximizeButton} onClick={onDismiss}>
                <Icon iconName="Cancel"></Icon>
            </IconButton>
            <div className={css.dashboardCard__contents}>{children}</div>
        </Modal>
    );
}

export interface HeaderProps {
    children: ReactNode;
    dropdown?: ReactNode;
    tooltipTranslationKey?: string;
    greyBackground?: boolean;
}

export function DashboardCardHeader({
    children,
    dropdown,
    greyBackground = false,
    tooltipTranslationKey,
}: HeaderProps): JSX.Element {
    const backgroundClass = greyBackground ? css["dashboardCardHeader--greyBackground"] : "";

    const TooltipWrapper = ({ children }: { children: ReactNode }): JSX.Element => {
        if (typeof tooltipTranslationKey === "string") {
            return (
                <HoverCard
                    plainCardProps={{
                        onRenderPlainCard: TooltipContents,
                        directionalHint: DirectionalHint.topCenter,
                        calloutProps: { isBeakVisible: true },
                        renderData: (
                            <Trans i18nKey={tooltipTranslationKey} components={[<strong />]} />
                        ),
                    }}
                    cardOpenDelay={200}
                    type={HoverCardType.plain}
                    className={css.dashboardCardHeader__tooltipWrapper}
                    instantOpenOnClick={true}
                >
                    <div>{children}</div>
                </HoverCard>
            );
        } else {
            return <>{children}</>;
        }
    };

    return (
        <Stack
            horizontal
            verticalAlign="center"
            className={[css.dashboardCardHeader, backgroundClass].join(" ")}
        >
            <Stack.Item grow>
                <h3>
                    <TooltipWrapper>{children}</TooltipWrapper>
                </h3>
            </Stack.Item>
            <Stack.Item verticalFill>{dropdown}</Stack.Item>
        </Stack>
    );
}

export function TooltipContents(text: string): JSX.Element {
    return <div className={css.dashboardCardHeader__tooltipContents}>{text}</div>;
}

export function DashboardCardCornerFooter({
    children,
    ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
    return (
        <>
            <div className={css.dashboardCard__cornerFooterMargin}></div>
            <div {...props} className={css.dashboardCard__cornerFooter}>
                {children}
            </div>
        </>
    );
}
