import { autorun, computed, makeObservable, observable, runInAction } from "mobx";
import { component, initialize, inject } from "tsdi";
import { PublicSetting, PublicSettingsApi } from "../../api";
import { UUID } from "../../utils/uuid";
import { RepositoryPublicSettings } from "../repositories/repository-public-settings";
import { ServiceOwnUser } from "./service-own-user";

@component
export class ServicePublicSettings {
    @inject private readonly serviceOwnUser!: ServiceOwnUser;
    @inject private readonly repositoryPublicSettings!: RepositoryPublicSettings;
    @inject private readonly publicSettingsApi!: PublicSettingsApi;

    @observable private publicSettingId?: UUID;

    constructor() {
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        autorun(() => {
            if (this.serviceOwnUser.user === undefined) {
                return;
            }

            // Pull the settings if they haven't been pulled yet.
            if (!this.publicSetting) {
                this.publicSettingsApi.publicSettingsReadPublicSetting().then((result) => {
                    runInAction(() => {
                        this.publicSettingId = result.id;
                    });
                });
            }
        });

        autorun(() => {
            if (this.publicSetting && this.publicSetting.instanceName !== "ELOfleet") {
                document.title = `ELOfleet - ${this.publicSetting.instanceName}`;
            } else if (!this.serviceOwnUser.user) {
                document.title = "Login";
            } else {
                document.title = "ELOfleet";
            }
        });
    }

    @computed public get publicSetting(): PublicSetting | undefined {
        if (this.publicSettingId === undefined) {
            return;
        }
        return this.repositoryPublicSettings.byId(this.publicSettingId);
    }
}
