import { IModalProps, Modal, PrimaryButton, Separator, Stack } from "@fluentui/react";
import * as React from "react";
import { I18nProvider, TFunction } from "../../domain/providers/i18n-provider";
import QRCode from "qrcode.react";
import css from "./modal-android-info.scss";
import { ArtifactsApi, CurrentArtifactVersions, DownloadToken } from "../../api";
import { tsdi } from "../../tsdi";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export function ModalAndroidInfo(props: IModalProps): JSX.Element {
    const i18n = tsdi.get(I18nProvider);
    const { t }: { t: TFunction } = useTranslation();
    const artifactsApi = tsdi.get(ArtifactsApi);

    // Authenticates an anonymous user for downloading the android application
    const [downloadToken, setDownloadToken] = React.useState<DownloadToken | undefined>(undefined);
    const [versions, setVersions] = React.useState<CurrentArtifactVersions | undefined>(undefined);
    const [downloadLink, setDownloadLink] = React.useState<string | undefined>(undefined);

    // Fetch a download token to store inside the QR code. This way
    // users can download the mobile application onto their smartphones
    // without the need of a login token.
    useEffect(() => {
        artifactsApi.artifactsGetDownloadToken().then(setDownloadToken);
        artifactsApi.artifactsGetCurrentVersions().then(setVersions);
    }, [artifactsApi]);

    React.useMemo(() => {
        // Don't do anything until we have all necessary components.
        if (versions == undefined || downloadToken == undefined) {
            return;
        }

        // Assemble the android app download link with a custom login token.
        const base = window.location.origin;
        const versionString = i18n.formatVersion(versions.version);
        const token = encodeURIComponent(downloadToken.token);
        const loginTokenId = encodeURIComponent(downloadToken.loginTokenId);
        const downloadUrl = `${base}/api/v1/artifacts/android_app/${versionString}?token=${token}&login_token_id=${loginTokenId}`;

        setDownloadLink(downloadUrl);
    }, [i18n, versions, downloadToken]);

    if (!downloadLink) {
        return <></>;
    }

    return (
        <Modal {...props} styles={{ main: [{ overflow: "hidden" }] }}>
            <Stack tokens={{ padding: 50 }}>
                <h1 style={{ textAlign: "center" }}>{t("component.modalAndroidInfo.title")}</h1>
                <Stack.Item>
                    <h2>{t("component.modalAndroidInfo.step1.title")}</h2>
                    <p>{t("component.modalAndroidInfo.step1.description")}</p>

                    <Stack horizontal horizontalAlign="space-evenly" verticalAlign="center">
                        <div className={css.mobile}>
                            <PrimaryButton
                                text={t("component.modalAndroidInfo.download.text")}
                                href={downloadLink}
                                // Make this the exact same size as the URL in the stack below to align the separators horizontally
                                style={{ width: "15em" }}
                                download
                            />
                            <Stack horizontalAlign="center">
                                <Stack.Item style={{ height: "150px" }}>
                                    <Separator vertical>
                                        {t("component.modalAndroidInfo.or")}
                                    </Separator>
                                </Stack.Item>
                            </Stack>
                            <Stack horizontalAlign="center" tokens={{ childrenGap: "2rem" }}>
                                <QRCode size={240} value={downloadLink} />
                            </Stack>
                        </div>
                    </Stack>
                </Stack.Item>

                <Stack.Item>
                    <h2>{t("component.modalAndroidInfo.step2.title")}</h2>
                    <p>{t("component.modalAndroidInfo.step2.description")}</p>

                    <Stack horizontal horizontalAlign="space-evenly" verticalAlign="center">
                        <div className={css.mobile}>
                            <div
                                style={{
                                    width: "15em",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                }}
                            >
                                {window.location.origin}
                            </div>
                            <Stack horizontalAlign="center">
                                <Stack.Item style={{ height: "150px" }}>
                                    <Separator vertical>
                                        {t("component.modalAndroidInfo.or")}
                                    </Separator>
                                </Stack.Item>
                            </Stack>
                            <Stack horizontalAlign="center">
                                <QRCode size={240} value={window.location.origin} />
                            </Stack>
                        </div>
                    </Stack>
                </Stack.Item>
                {versions && (
                    <div style={{ fontSize: "small", textAlign: "center", marginTop: "2rem" }}>
                        <p style={{ lineHeight: "0.5", margin: "0.5rem 0" }}>
                            <strong>{t("component.modalAndroidInfo.elofleetVersion")}</strong>
                            {": "}
                            {i18n.formatVersion(versions.version)}
                        </p>
                    </div>
                )}
            </Stack>
        </Modal>
    );
}
