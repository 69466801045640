/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleGroup
 */
export interface VehicleGroup {
    /**
     * 
     * @type {string}
     * @memberof VehicleGroup
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleGroup
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof VehicleGroup
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof VehicleGroup
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleGroup
     */
    assignedVehicleCount: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleGroup
     */
    assignedUserGroupCount: number;
}

/**
 * Check if a given object implements the VehicleGroup interface.
 */
export function instanceOfVehicleGroup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "assignedVehicleCount" in value;
    isInstance = isInstance && "assignedUserGroupCount" in value;

    return isInstance;
}

export function VehicleGroupFromJSON(json: any): VehicleGroup {
    return VehicleGroupFromJSONTyped(json, false);
}

export function VehicleGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'label': json['label'],
        'assignedVehicleCount': json['assignedVehicleCount'],
        'assignedUserGroupCount': json['assignedUserGroupCount'],
    };
}

export function VehicleGroupToJSON(value?: VehicleGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'label': value.label,
        'assignedVehicleCount': value.assignedVehicleCount,
        'assignedUserGroupCount': value.assignedUserGroupCount,
    };
}

