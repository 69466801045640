import React from "react";
import { useTranslation } from "react-i18next";
import { DirectionalHint, Icon, TooltipHost } from "@fluentui/react";
import css from "./dashboard-link.scss";
import sizes from "../../ui/sizes.scss";
import { Link } from "react-router-dom";
import { TFunction } from "i18next";

export interface Props {
    link: string;
    children: JSX.Element | string;
    centered?: boolean;
}

export default function DashboardLink({ children, link: href, centered }: Props): JSX.Element {
    const centeredClass = centered ? css["dashboardLink--centered"] : "";
    const { t }: { t: TFunction } = useTranslation();

    // Disable links on mobile devices and show a tooltip.
    if (window.innerWidth <= parseInt(sizes.maxWidthMobileScreens)) {
        return (
            <TooltipHost
                hostClassName={[css.dashboardLink, centeredClass].join(" ")}
                content={t("page.dashboard.disabledExpandCardTooltip")}
                directionalHint={DirectionalHint.bottomCenter}
            >
                <div className={css.dashboardLink__children}>{children}</div>
                <Icon className={css.disabledDashboardLink} iconName="Forward"></Icon>
            </TooltipHost>
        );
    }

    return (
        <Link to={href} className={[css.dashboardLink, centeredClass].join(" ")}>
            <div className={css.dashboardLink__children}>{children}</div>
            <Icon iconName="Forward"></Icon>
        </Link>
    );
}
