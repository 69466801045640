import * as React from "react";
import { pick } from "ramda";
import { observer } from "mobx-react";
import { Stack, TextField } from "@fluentui/react";
import { action, computed, makeObservable, observable, when } from "mobx";

import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { LoggedInUserUpdate, UserUpdate } from "../../api";
import { RepositoryUsers } from "../../domain/repositories/repository-users";
import { User } from "../../api";
import { createUuid } from "../../utils/uuid";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { doubleBindNullableString } from "../../utils/double-bind";
import { ServiceOwnUser } from "../../domain/services/service-own-user";

export interface FormUpdateUserProfileSettingsProps {
    readonly onUpdate?: (entity: User) => void | Promise<void>;
}

@observer
@external
export class FormUpdateUserProfileSettings extends React.Component<FormUpdateUserProfileSettingsProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly serviceOwnUser!: ServiceOwnUser;
    @inject private readonly repositoryUsers!: RepositoryUsers;

    @observable public password: string = "";
    @observable public passwordRepeat: string = "";

    // Properties that aren't used during render and don't need to be observable
    private validationId = createUuid();

    constructor(props: FormUpdateUserProfileSettingsProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected async initialize(): Promise<void> {
        // Don't initialize if we don't have props yet.
        // This will otherwise fail.
        if (!this.props) {
            return;
        }

        await when(() => this.user !== undefined);
        this.repositoryUsers.validation.updateLoggedInUser.initializeModel(
            this.validationId,
            this.userUpdate,
        );
    }

    @computed private get user(): User | undefined {
        if (this.userId === undefined) {
            return;
        }
        return this.repositoryUsers.mutableCopyById(
            FormUpdateUserProfileSettings.name,
            this.userId,
        );
    }

    @computed private get userId(): string | undefined {
        return this.serviceOwnUser.user?.id;
    }

    @action.bound private triggerValidation(): void {
        if (this.userId === undefined) {
            return;
        }
        const validation = this.repositoryUsers.validation.updateLoggedInUser;
        if (!validation.isInitialized(this.validationId)) {
            validation.initializeModel(this.validationId, this.userUpdate);
        }
        validation.updateModel(this.validationId, this.userUpdate);
    }

    public componentWillUnmount(): void {
        if (this.userId === undefined) {
            return;
        }
        this.repositoryUsers.discardMutableCopy(FormUpdateUserProfileSettings.name, this.userId);
    }

    @computed private get userUpdate(): LoggedInUserUpdate {
        const userUpdate: UserUpdate = pick(["password"], this.user);

        // The password cannot exist on the original [User] object.
        // Hence we save it on the form and manually set it the [LoggedInUserUpdate].
        if (this.password) {
            userUpdate.password = this.password;
        }

        return userUpdate;
    }

    @action.bound private reset(): void {
        this.password = "";
        this.passwordRepeat = "";
    }

    @action.bound
    private async updateLoggedInUser(evt: React.FormEvent): Promise<void> {
        if (this.userId === undefined) {
            return;
        }
        evt.preventDefault();
        const user = await this.repositoryUsers.updateLoggedInUser(this.userUpdate);
        this.props.onUpdate && this.props.onUpdate(user);
        this.reset();
    }

    @computed private get passwordRepeatValid(): boolean {
        return this.password === this.passwordRepeat;
    }

    @computed private get passwordRepeatErrorMessage(): string | undefined {
        if (this.passwordRepeatValid) {
            return;
        }
        return this.i18n.t("formCreateUser.passwordRepeatInvalid");
    }

    public onFormatDate = (date?: Date): string => {
        return this.i18n.formatDateOnly(date!);
    };

    public render(): JSX.Element {
        if (this.user === undefined) {
            return <></>;
        }
        return (
            <form onSubmit={this.updateLoggedInUser}>
                <Stack
                    horizontal
                    horizontalAlign="space-between"
                    tokens={{
                        childrenGap: "2em",
                    }}
                    // When the Current Tab is changed, the Height of the Current Form also changes,
                    // so there is a shift of the image. Fixed height was given to prevent this.
                    style={{ minHeight: "260px" }}
                >
                    <Stack>
                        <TextField
                            label={this.i18n.t("formUpdateUser.password.label")}
                            type="password"
                            canRevealPassword
                            {...doubleBindNullableString<FormUpdateUserProfileSettings>(
                                this,
                                "password",
                                this.triggerValidation,
                            )}
                            errorMessage={this.i18n.formatFieldValidationState(
                                this.repositoryUsers.validation.updateLoggedInUser.getFieldValidationState(
                                    this.validationId,
                                    "password",
                                ),
                            )}
                        />
                        <TextField
                            label={this.i18n.t("formUpdateUser.passwordRepeat.label")}
                            type="password"
                            canRevealPassword
                            {...doubleBindNullableString<FormUpdateUserProfileSettings>(
                                this,
                                "passwordRepeat",
                            )}
                            errorMessage={this.passwordRepeatErrorMessage}
                        />
                        <Stack style={{ marginTop: "2em" }}>
                            <PrimaryButtonValidation
                                text={this.i18n.t("formUpdateUser.submit.text")}
                                validationId={this.validationId}
                                validation={this.repositoryUsers.validation.updateLoggedInUser}
                                additionalCondition={this.passwordRepeatValid}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </form>
        );
    }
}
