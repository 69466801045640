import * as React from "react";
import sizes from "../sizes.scss";
import { external, initialize, inject } from "tsdi";
import { Stack, TextField, PrimaryButton, DefaultButton } from "@fluentui/react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { EmailLoginTokenCreate, EmailLoginTokensApi } from "../../api";
import { action, makeObservable, observable } from "mobx";
import { doubleBindString } from "../../utils/double-bind";
import { observer } from "mobx-react";
import { LoadingState } from "../../utils/loading-state";
import { createUuid } from "../../utils/uuid";
import { Validation } from "../../utils/validation";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";

enum FormResetLoadingFeature {
    TOKEN_SENT = "token-sent",
}

interface FormResetPasswordProps {
    onComplete: () => void;
    onDismiss: () => void;
}

@observer
@external
export class FormResetPassword extends React.Component<FormResetPasswordProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly emailLoginTokensApi!: EmailLoginTokensApi;

    @observable public email: string = "";

    public loadingState = new LoadingState<FormResetLoadingFeature>();

    private validationId = createUuid();

    private validation = new Validation((create: EmailLoginTokenCreate) =>
        this.emailLoginTokensApi.emailLoginTokensValidateCreateEmailLoginToken({
            emailLoginTokenCreate: create,
        }),
    );

    constructor(props: FormResetPasswordProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.validation.initializeModel(this.validationId, { email: this.email });
    }

    private triggerValidation(): void {
        this.validation.updateModel(this.validationId, { email: this.email });
    }

    @action.bound
    private async createEmailLoginToken(e: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        const request = {
            emailLoginTokenCreate: {
                email: this.email,
            },
        };

        await this.loadingState.wrap(
            FormResetLoadingFeature.TOKEN_SENT,
            this.emailLoginTokensApi.emailLoginTokensCreateEmailLoginToken(request),
        );

        this.props.onComplete();
    }

    public render(): JSX.Element {
        if (this.validation === undefined) {
            return <></>;
        }

        return (
            <form onSubmit={this.createEmailLoginToken}>
                <Stack
                    tokens={{
                        childrenGap: "1em",
                        padding: `0 ${sizes.formPaddingHorizontal}`,
                    }}
                >
                    <TextField
                        label={this.i18n.t("formResetPassword.email")}
                        {...doubleBindString<FormResetPassword>(this, "email", () =>
                            this.triggerValidation(),
                        )}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.validation.getFieldValidationState(this.validationId, "email"),
                        )}
                        required
                    />
                </Stack>
                <ElofleetDialogFooter>
                    <DefaultButton
                        onClick={this.props.onDismiss}
                        label={this.i18n.t("formResetPassword.cancel.text")}
                        text={this.i18n.t("formResetPassword.cancel.text")}
                    />
                    <PrimaryButton
                        type="submit"
                        label={this.i18n.t("formResetPassword.submit.text")}
                        primary
                        disabled={
                            this.loadingState.isAnythingLoading ||
                            !this.validation.isValid(this.validationId) ||
                            this.validation.isLoading
                        }
                        text={this.i18n.t("formResetPassword.submit.text")}
                    />
                </ElofleetDialogFooter>
            </form>
        );
    }
}
