import * as React from "react";
import { routePath, RouteProps } from "../routes";
import { LayoutCenter } from "../ui/layouts/layout-centered";
import {
    Stack,
    getTheme,
    IStackStyles,
    TextField,
    PrimaryButton,
    Separator,
    IStackTokens,
} from "@fluentui/react";
import elokonLogoUrl from "../images/elofleet-logo.svg";
import colors from "../ui/colors.scss";
import css from "./page-dispatcher.scss";
import { useEffect, useState } from "react";
import { ListLoginNews } from "../ui/atoms/list-login-news";
import { News, NewsApi } from "../api";
import { tsdi } from "../tsdi";
import { LanguageButtons } from "../ui/atoms/language-buttons";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { observer } from "mobx-react";

const theme = getTheme();

export type PageLoginProps = RouteProps<{}>;

const childrenToken: IStackTokens = { childrenGap: "1.5em" };

const stackStyle: IStackStyles = {
    root: {
        background: colors.primarywhite,
        borderRadius: theme.effects.roundedCorner4,
        boxShadow: theme.effects.elevation4,
    },
};

export const PageDispatcher = observer(function PageDispatcher(_props: {}): JSX.Element {
    const [instanceName, setInstanceName] = useState<string | undefined>(undefined);
    const [badInstance, setBadInstance] = useState(false);
    const [news, setNews] = useState<undefined | News>();
    const i18n = tsdi.get(I18nProvider);
    const newsApi = tsdi.get(NewsApi);

    useEffect(() => {
        newsApi.newsReadNews().then(setNews);
    }, [newsApi]);

    async function check(ev: React.FormEvent): Promise<void> {
        ev.preventDefault();

        if (!instanceName) {
            setBadInstance(true);
            return;
        }
        // The backend /health route sets `Access-Control-Allow-Origin: *`. This way we
        // are able to check the status of other existent instances by fetching that route.
        // A bad URL will typically return 404 but the fetch client is not allowed to query
        // that resource due to missing CORS headers.
        const instance = `https://${instanceName}.elofleet.com`;
        await fetch(`${instance}/health`)
            .then((res) => {
                const bad = res.status === 404;
                setBadInstance(bad);

                if (!bad) {
                    window.location.assign(`${instance}/login`);
                }
            })
            .catch((_error) => {
                // Catch the CORS error, which indicates a non existent instance
                setBadInstance(true);
            });
    }

    return (
        <div className={css.pageDispatcher}>
            <LayoutCenter
                background={colors["secondarypage-background"]}
                footer={() => (
                    <div
                        style={{
                            textAlign: "center",
                            padding: theme.spacing.m,
                            color: theme.semanticColors.bodyText,
                        }}
                    >
                        © {new Date().getFullYear()}{" "}
                        <a
                            style={{
                                color: theme.semanticColors.bodyText,
                                textDecoration: "none",
                            }}
                            href="https://www.elokon.com"
                        >
                            elokon.com
                        </a>{" "}
                        <>{i18n.t("page.login.allRightsReserved")}</>
                    </div>
                )}
            >
                <Stack>
                    <Stack.Item align="center">
                        <a href="https://www.elofleet.com">
                            <img
                                src={elokonLogoUrl}
                                style={{ width: "20rem", padding: theme.spacing.l1 }}
                            />
                        </a>
                    </Stack.Item>
                    <Stack wrap horizontal horizontalAlign="center" tokens={childrenToken}>
                        <Stack.Item styles={stackStyle} className={css.pageDispatcher__form}>
                            <Stack style={{ height: "100%" }}>
                                <div className={css.pageDispatcher__heading}>
                                    <>{i18n.t("page.dispatcher.title")}</>
                                </div>
                                <Stack
                                    className={css.pageDispatcher__form__body}
                                    tokens={childrenToken}
                                >
                                    <form onSubmit={check}>
                                        <LanguageButtons />
                                        <TextField
                                            className={css.pageDispatcher__form__body__input}
                                            errorMessage={
                                                badInstance
                                                    ? i18n.t("page.dispatcher.badInstanceName")
                                                    : undefined
                                            }
                                            placeholder={i18n.t(
                                                "page.dispatcher.input.placeholder",
                                            )}
                                            suffix=".elofleet.com"
                                            styles={{
                                                fieldGroup: {
                                                    borderRadius: theme.effects.roundedCorner4,
                                                    outline: "1px solid rgb(96, 94, 92)",
                                                    border: "unset",
                                                    "::after": {
                                                        borderRadius: theme.effects.roundedCorner4,
                                                    },
                                                },
                                            }}
                                            onChange={(_ev: React.FormEvent, instanceName) =>
                                                setInstanceName(instanceName)
                                            }
                                        />
                                        <PrimaryButton
                                            type="submit"
                                            className={css.pageDispatcher__form__body__button}
                                            text={i18n.t("page.dispatcher.button.continue")}
                                        />
                                    </form>
                                    <div>
                                        <Separator />
                                        <>{i18n.t("page.dispatcher.notACustomerYet")}</>{" "}
                                        <a
                                            className={css.pageDispatcher__form__body__bookADemo}
                                            href={"https://www.elofleet.com/#book-a-demo"}
                                        >
                                            {i18n.t("page.dispatcher.bookADemo")}
                                        </a>
                                    </div>
                                </Stack>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item className={css.pageDispatcher__newsList} styles={stackStyle}>
                            <ListLoginNews news={news} />
                        </Stack.Item>
                    </Stack>
                </Stack>
            </LayoutCenter>
        </div>
    );
});

export const routeDispatcher = {
    public: true,
    component: PageDispatcher,
    path: routePath.dispatcher,
    pattern: "/start",
};
