/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MaintenanceListUpdate
 */
export interface MaintenanceListUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleStatus?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleSerialNumber?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleManufacturer?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleModel?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleEngineType?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleManufactureYear?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehiclePurchaseDateOrLeaseTermBegin?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleLeaseTermEnd?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleLeaseTermDaysRemaining?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleInitialOperatingHours?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleCurrentOperatingHours?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleLastMaintenanceOperatingHours?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleLastMaintenanceDate?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleNextMaintenanceHours?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleMaintenanceInterval?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleMaintenanceNotificationAt?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleMaintenanceLockdown?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleHoursUntilNextMaintenance?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    vehicleTypeLabel?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceListUpdate
     */
    departmentLabel?: boolean | null;
}

/**
 * Check if a given object implements the MaintenanceListUpdate interface.
 */
export function instanceOfMaintenanceListUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MaintenanceListUpdateFromJSON(json: any): MaintenanceListUpdate {
    return MaintenanceListUpdateFromJSONTyped(json, false);
}

export function MaintenanceListUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaintenanceListUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicleStatus': !exists(json, 'vehicleStatus') ? undefined : json['vehicleStatus'],
        'vehicleSerialNumber': !exists(json, 'vehicleSerialNumber') ? undefined : json['vehicleSerialNumber'],
        'vehicleManufacturer': !exists(json, 'vehicleManufacturer') ? undefined : json['vehicleManufacturer'],
        'vehicleModel': !exists(json, 'vehicleModel') ? undefined : json['vehicleModel'],
        'vehicleEngineType': !exists(json, 'vehicleEngineType') ? undefined : json['vehicleEngineType'],
        'vehicleManufactureYear': !exists(json, 'vehicleManufactureYear') ? undefined : json['vehicleManufactureYear'],
        'vehiclePurchaseDateOrLeaseTermBegin': !exists(json, 'vehiclePurchaseDateOrLeaseTermBegin') ? undefined : json['vehiclePurchaseDateOrLeaseTermBegin'],
        'vehicleLeaseTermEnd': !exists(json, 'vehicleLeaseTermEnd') ? undefined : json['vehicleLeaseTermEnd'],
        'vehicleLeaseTermDaysRemaining': !exists(json, 'vehicleLeaseTermDaysRemaining') ? undefined : json['vehicleLeaseTermDaysRemaining'],
        'vehicleInitialOperatingHours': !exists(json, 'vehicleInitialOperatingHours') ? undefined : json['vehicleInitialOperatingHours'],
        'vehicleCurrentOperatingHours': !exists(json, 'vehicleCurrentOperatingHours') ? undefined : json['vehicleCurrentOperatingHours'],
        'vehicleLastMaintenanceOperatingHours': !exists(json, 'vehicleLastMaintenanceOperatingHours') ? undefined : json['vehicleLastMaintenanceOperatingHours'],
        'vehicleLastMaintenanceDate': !exists(json, 'vehicleLastMaintenanceDate') ? undefined : json['vehicleLastMaintenanceDate'],
        'vehicleNextMaintenanceHours': !exists(json, 'vehicleNextMaintenanceHours') ? undefined : json['vehicleNextMaintenanceHours'],
        'vehicleMaintenanceInterval': !exists(json, 'vehicleMaintenanceInterval') ? undefined : json['vehicleMaintenanceInterval'],
        'vehicleMaintenanceNotificationAt': !exists(json, 'vehicleMaintenanceNotificationAt') ? undefined : json['vehicleMaintenanceNotificationAt'],
        'vehicleMaintenanceLockdown': !exists(json, 'vehicleMaintenanceLockdown') ? undefined : json['vehicleMaintenanceLockdown'],
        'vehicleHoursUntilNextMaintenance': !exists(json, 'vehicleHoursUntilNextMaintenance') ? undefined : json['vehicleHoursUntilNextMaintenance'],
        'vehicleTypeLabel': !exists(json, 'vehicleTypeLabel') ? undefined : json['vehicleTypeLabel'],
        'departmentLabel': !exists(json, 'departmentLabel') ? undefined : json['departmentLabel'],
    };
}

export function MaintenanceListUpdateToJSON(value?: MaintenanceListUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicleStatus': value.vehicleStatus,
        'vehicleSerialNumber': value.vehicleSerialNumber,
        'vehicleManufacturer': value.vehicleManufacturer,
        'vehicleModel': value.vehicleModel,
        'vehicleEngineType': value.vehicleEngineType,
        'vehicleManufactureYear': value.vehicleManufactureYear,
        'vehiclePurchaseDateOrLeaseTermBegin': value.vehiclePurchaseDateOrLeaseTermBegin,
        'vehicleLeaseTermEnd': value.vehicleLeaseTermEnd,
        'vehicleLeaseTermDaysRemaining': value.vehicleLeaseTermDaysRemaining,
        'vehicleInitialOperatingHours': value.vehicleInitialOperatingHours,
        'vehicleCurrentOperatingHours': value.vehicleCurrentOperatingHours,
        'vehicleLastMaintenanceOperatingHours': value.vehicleLastMaintenanceOperatingHours,
        'vehicleLastMaintenanceDate': value.vehicleLastMaintenanceDate,
        'vehicleNextMaintenanceHours': value.vehicleNextMaintenanceHours,
        'vehicleMaintenanceInterval': value.vehicleMaintenanceInterval,
        'vehicleMaintenanceNotificationAt': value.vehicleMaintenanceNotificationAt,
        'vehicleMaintenanceLockdown': value.vehicleMaintenanceLockdown,
        'vehicleHoursUntilNextMaintenance': value.vehicleHoursUntilNextMaintenance,
        'vehicleTypeLabel': value.vehicleTypeLabel,
        'departmentLabel': value.departmentLabel,
    };
}

