import { action, makeObservable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { RepositorySafetyMessages } from "../../domain/repositories/repository-safety-messages";
import { UUID } from "../../utils/uuid";
import { ListCommandButtons, ListCommandButtonsProps } from "./list-command-buttons";
import { FormCreateSafetyMessage } from "../molecules/form-create-safety-message";
import { FormUpdateSafetyMessage } from "../molecules/form-update-safety-message";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { I18nProvider } from "../../domain/providers/i18n-provider";

export type ListCommandButtonsSafetyMessagesProps = Omit<
    ListCommandButtonsProps<RepositorySafetyMessages>,
    "list" | "createForm" | "listState"
>;

@external
@observer
export class ListCommandButtonsSafetyMessages extends React.Component<ListCommandButtonsSafetyMessagesProps> {
    @inject private readonly repositorySafetyMessages!: RepositorySafetyMessages;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject private readonly i18n!: I18nProvider;

    constructor(props: ListCommandButtonsSafetyMessagesProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private async deleteSafetyMessages(ids: UUID[]): Promise<void> {
        await this.repositorySafetyMessages.delete(...ids);
        if (this.props.onDelete) {
            this.props.onDelete(ids);
        }
    }
    public render(): JSX.Element {
        return (
            <ListCommandButtons
                createButtonText={this.i18n.t("listSafetyMessages.button.add")}
                createDialogTitle={this.i18n.t("formCreateSafetyMessage.title")}
                updateDialogTitle={this.i18n.t("formUpdateSafetyMessage.title")}
                updateForm={FormUpdateSafetyMessage}
                createForm={FormCreateSafetyMessage}
                {...this.props}
                listState={this.serviceListStates.safetyMessages}
                onDelete={this.deleteSafetyMessages}
            />
        );
    }
}
