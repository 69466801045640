import { IStackTokens, Stack, ThemeProvider } from "@fluentui/react";
import * as React from "react";
import { ApiResource } from "../../utils/elofleet-repository";
import { PaginationState } from "../../utils/pagination-state";
import { PaginationButtons } from "../atoms/pagination-buttons";
import css from "./layout-list.scss";
import sizes from "../sizes.scss";
import { theme } from "../../utils/fluent-ui-theme";

export interface LayoutListProps {
    readonly children: JSX.Element;
    readonly paginationState?: PaginationState<ApiResource>;
    readonly commandBar?: JSX.Element;
    readonly searchField?: JSX.Element;
    readonly title?: String;
    readonly filter?: JSX.Element;
    readonly footer?: JSX.Element;
}

const horizontalGapStackTokens: IStackTokens = {
    childrenGap: sizes.xl,
};

export class LayoutList extends React.Component<LayoutListProps> {
    public render(): JSX.Element {
        return (
            <ThemeProvider theme={theme}>
                <Stack>
                    <Stack
                        horizontal
                        verticalAlign="center"
                        className={css.layoutList__header}
                        style={{ backgroundColor: theme.palette.white }}
                    >
                        {this.props.title && (
                            <h3 className={css.layoutList__title}>{this.props.title}</h3>
                        )}
                        {this.props.searchField && (
                            <div className={css.layoutList__searchField}>
                                <Stack.Item align="start">{this.props.searchField}</Stack.Item>
                            </div>
                        )}
                        {this.props.filter && (
                            <div className={css.layoutList__filter}>
                                <Stack
                                    horizontal
                                    verticalAlign="center"
                                    tokens={horizontalGapStackTokens}
                                >
                                    {this.props.filter}
                                </Stack>
                            </div>
                        )}
                        {this.props.commandBar && (
                            <div className={css.layoutList__commandBar}>
                                <Stack.Item align="end">{this.props.commandBar}</Stack.Item>
                            </div>
                        )}
                    </Stack>
                    <Stack.Item>{this.props.children}</Stack.Item>
                    <Stack.Item
                        className={css.layoutList__footer}
                        style={{ background: theme.palette.white }}
                    >
                        {this.props.paginationState && (
                            <PaginationButtons paginationState={this.props.paginationState} />
                        )}
                        {this.props.footer}
                    </Stack.Item>
                </Stack>
            </ThemeProvider>
        );
    }
}
