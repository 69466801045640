import * as React from "react";
import { observer } from "mobx-react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { declareRoute, routePath, RouteProps } from "../routes";
import { PageContent } from "../ui/atoms/page-content";
import { PageHeader } from "../ui/atoms/page-header";
import { Section } from "../ui/atoms/section";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { ListVehicles } from "../ui/molecules/list-vehicles";
import { SelectionMode } from "@fluentui/react";
import sizes from "../ui/sizes.scss";
import { articles } from "../help";

@external
@observer
export class PageVehicles extends React.Component<RouteProps> {
    @inject protected readonly i18n!: I18nProvider;

    public render(): JSX.Element {
        return (
            <LayoutDefault
                suggestedHelpArticles={[articles.truckFleetReport]}
                header={<PageHeader title={this.i18n.t("page.vehicles.title")} />}
            >
                <PageContent>
                    <Section>
                        <ListVehicles
                            selectionMode={SelectionMode.none}
                            maxHeight={`${sizes.listMaxHeight}`}
                        />
                    </Section>
                </PageContent>
            </LayoutDefault>
        );
    }
}

export const routeVehicles = declareRoute({
    navigation: true,
    component: PageVehicles,
    icon: "Forklift",
    title: "page.vehicles.navbarEntry",
    path: routePath.vehicles,
    pattern: "/vehicles",
});
