import * as React from "react";
import { observer } from "mobx-react";
import {
    Stack,
    DefaultButton,
    TextField,
    Toggle,
    DatePicker,
    Separator,
    Dropdown,
    IDropdownOption,
} from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { ConnectedComboBox } from "../atoms/connected-combo-box";
import { RepositoryVehicles } from "../../domain/repositories/repository-vehicles";
import {
    VehicleDeviceStatus,
    Department,
    ProcurementType,
    ShockProfile,
    Vehicle,
    VehicleDevice,
    VehicleStatus,
    ManagementRole,
    EngineType,
    VehicleType,
} from "../../api";
import { RepositoryVehicleDevices } from "../../domain/repositories/repository-vehicle-devices";
import { createUuid, UUID } from "../../utils/uuid";
import { VehicleCreate } from "../../api";
import { FormCreateProps } from "../../utils/form-create-props";
import {
    doubleBindIntegerToString,
    doubleBindNullableString,
    doubleBindNullableStringEmptyAsNull,
    doubleBindString,
} from "../../utils/double-bind";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { RepositoryShockProfiles } from "../../domain/repositories/repository-shock-profiles";
import { ModalConfirmation } from "../atoms/modal-confirmation";
import { clone, equals } from "ramda";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";
import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { userInputToNumber } from "../../utils/user-input-to-number";
import { RepositoryPreOpsChecklists } from "../../domain/repositories/repository-pre-ops-checklists";
import { ServiceOwnUser } from "../../domain/services/service-own-user";
import { defaultPageSize } from "../../utils/constants";
import { RepositoryVehicleTypes } from "../../domain/repositories/repository-vehicle-types";
import { ClearableDropdown } from "../atoms/clearable-dropdown";

export interface FormCreateVehicleProps extends FormCreateProps<Vehicle> {
    asDialogContent?: boolean;
}

@observer
@external
export class FormCreateVehicle extends React.Component<FormCreateVehicleProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryDepartments!: RepositoryDepartments;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;
    @inject private readonly repositoryVehicleDevices!: RepositoryVehicleDevices;
    @inject private readonly repositoryVehicleTypes!: RepositoryVehicleTypes;
    @inject private readonly repositoryShockProfiles!: RepositoryShockProfiles;
    @inject private readonly repositoryPreOpsChecklists!: RepositoryPreOpsChecklists;
    @inject private readonly serviceOwnUser!: ServiceOwnUser;

    @observable public manufacturer: string | undefined;
    @observable public model: string | undefined;
    @observable public serialNumber = "";
    @observable public vehicleTypeId?: UUID | null;
    @observable public departmentId?: UUID | null;
    @observable public manufactureYear?: number | null;
    @observable public shockProfileId?: UUID | null;
    @observable public magneticFieldThreshold: number = 100;
    @observable public preOpsChecklistId?: UUID | null;

    @observable public maintenanceLockdown = false;
    @observable public checklistOverride = false;

    @observable public initialOperatingHours = "";
    @observable public idleAutoLogoutAfter = "10";
    @observable public maintenanceInterval = "100";
    @observable public maintenanceNotificationAt = "";

    @observable public procurementType?: ProcurementType;
    @observable public purchaseDate?: Date;
    @observable public leaseTermBegin?: Date;
    @observable public leaseTermEnd?: Date;
    @observable public lastMaintenance?: Date;

    @observable public status = VehicleStatus.ACTIVE;
    @observable public phoneImei: string | undefined | null;

    @observable public vehicleDeviceId?: UUID;

    @observable public engineType = EngineType.ELECTRIC;

    @observable private cancelConfirmationModalVisible = false;

    private validationId = createUuid();
    private cleanState: VehicleCreate;

    constructor(props: FormCreateVehicleProps) {
        super(props);
        makeObservable(this);
        this.cleanState = clone(this.vehicleCreate);
    }

    private triggerValidation(): void {
        this.repositoryVehicles.validation.create.updateModel(
            this.validationId,
            this.vehicleCreate,
        );
    }

    @initialize protected initialize(): void {
        this.repositoryVehicles.validation.create.initializeModel(
            this.validationId,
            this.vehicleCreate,
        );
    }

    @computed private get vehicleCreate(): VehicleCreate {
        const {
            manufacturer,
            model,
            vehicleTypeId,
            serialNumber,
            departmentId,
            manufactureYear,
            shockProfileId,
            magneticFieldThreshold,
            preOpsChecklistId,

            maintenanceLockdown,
            checklistOverride,

            initialOperatingHours,
            idleAutoLogoutAfter,
            maintenanceInterval,
            maintenanceNotificationAt,

            procurementType,
            purchaseDate,
            leaseTermBegin,
            leaseTermEnd,
            phoneImei,
            engineType,
        } = this;

        return {
            manufacturer,
            model,
            vehicleTypeId,
            serialNumber,
            manufactureYear,
            shockProfileId,
            departmentId,
            magneticFieldThreshold,
            preOpsChecklistId,

            maintenanceLockdown,
            checklistOverride,

            initialOperatingHours: userInputToNumber(initialOperatingHours, 0),
            idleAutoLogoutAfter: userInputToNumber(idleAutoLogoutAfter, undefined),
            maintenanceInterval: userInputToNumber(maintenanceInterval, 100),
            maintenanceNotificationAt: userInputToNumber(maintenanceNotificationAt, undefined),

            procurementType,
            purchaseDate,
            leaseTermBegin,
            leaseTermEnd,
            phoneImei,
            engineType,
        };
    }

    @action.bound
    private async createVehicle(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const { vehicleCreate } = this;
        if (!vehicleCreate.serialNumber) {
            throw new Error("Can't submit form: Not fully filled.");
        }
        const vehicle = await this.repositoryVehicles.create(this.vehicleCreate);

        if (this.vehicleDeviceId != null) {
            await this.repositoryVehicleDevices.update(this.vehicleDeviceId, {
                vehicleId: vehicle.id,
            });
            await this.repositoryVehicleDevices.reloadQuery({
                pageSize: defaultPageSize,
                vehicleId: vehicle.id,
            });
            await this.repositoryVehicleDevices.reloadQuery({
                pageSize: defaultPageSize,
                status: VehicleDeviceStatus.NOT_ASSIGNED,
            });
        }

        this.reset();
        if (this.props.onCreate) {
            this.props.onCreate(vehicle);
        }
    }

    @action.bound private reset(): void {
        this.triggerValidation();
    }

    @action.bound private setVehicleType(vehicleTypeId?: UUID): void {
        this.vehicleTypeId = vehicleTypeId ?? null;
        this.triggerValidation();
    }

    @action.bound private setShockProfile(shockProfileId?: UUID): void {
        this.shockProfileId = shockProfileId ?? null;
        this.triggerValidation();
    }

    @action.bound private setDepartment(departmentId?: UUID): void {
        this.departmentId = departmentId ?? null;
        this.triggerValidation();
    }

    @action.bound private setChecklistOverride(_event: unknown, flag: boolean | undefined): void {
        this.checklistOverride = flag ?? false;
        this.triggerValidation();
    }

    @action.bound private setMaintenanceLockdown(_event: unknown, flag: boolean | undefined): void {
        this.maintenanceLockdown = flag ?? false;
        this.triggerValidation();
    }

    @action.bound private setManufactureYear(
        _event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        _index?: number,
        _value?: string,
    ): void {
        option == undefined
            ? (this.manufactureYear = null)
            : (this.manufactureYear = option?.key as number);
        this.triggerValidation();
    }

    @action.bound private setPreOpsChecklist(preOpsChecklistId?: UUID): void {
        this.preOpsChecklistId = preOpsChecklistId ?? null;
        this.triggerValidation();
    }

    /**
     * This is run, if the user clicks the form's cancel button.
     * A confirmation modal might pop up, if the user did any changes.
     */
    @action.bound private showConfirmAndCallDialogCancelCallback(): void {
        // If there were any changes, i.e. if the original and mutable copy aren't the same,
        // open the confirmation modal for aborting the update process.
        if (!equals(this.cleanState, this.vehicleCreate)) {
            this.cancelConfirmationModalVisible = true;
            return;
        }

        this.props.onDialogCancel();
    }

    @action.bound private setPhoneImei(
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string | undefined,
    ): void {
        this.phoneImei = newValue == "" ? null : newValue;
        this.triggerValidation();
    }

    @action.bound private setProcurementType(key?: string): void {
        this.procurementType =
            ProcurementType[String(key).toUpperCase() as keyof typeof ProcurementType] || null;
        this.triggerValidation();
    }

    @action.bound private setPurchaseDate(date: Date | null | undefined): void {
        this.purchaseDate = date ?? undefined;
        this.triggerValidation();
    }

    @action.bound private setLeaseTermBegin(date: Date | null | undefined): void {
        this.leaseTermBegin = date ?? undefined;
        this.triggerValidation();
    }

    @action.bound private setLeaseTermEnd(date: Date | null | undefined): void {
        this.leaseTermEnd = date ?? undefined;
        this.triggerValidation();
    }

    @action.bound private setVehicleDevice(vehicleDeviceId?: UUID): void {
        this.vehicleDeviceId = vehicleDeviceId;
        this.triggerValidation();
    }

    @action.bound private closeCancelConfirmationModalVisible(): void {
        this.cancelConfirmationModalVisible = false;
    }

    @action.bound private setEngineType(
        _event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        _index?: number,
    ): void {
        this.engineType =
            EngineType[String(option!.key).toUpperCase() as keyof typeof EngineType] ||
            EngineType.ELECTRIC;
        this.triggerValidation();
    }

    public get yearOptions(): IDropdownOption[] {
        const options: IDropdownOption[] = [];
        const currentYear = new Date().getFullYear();
        for (let i = currentYear; i > 1924; i--) {
            options.push({ key: i, text: i.toString() });
        }
        return options;
    }

    public onFormatDate = (date?: Date): string => {
        return this.i18n.formatDateOnly(date!);
    };

    public renderGeneralSection(): JSX.Element {
        const engineTypeOptions: IDropdownOption[] = [
            {
                key: "electric",
                text: this.i18n.t("formCreateVehicle.engineTypeOptions.electric"),
            },
            {
                key: "combustion",
                text: this.i18n.t("formCreateVehicle.engineTypeOptions.combustion"),
            },
        ];
        return (
            <Stack
                horizontal
                horizontalAlign="space-between"
                tokens={{
                    childrenGap: "2em",
                }}
            >
                <Stack>
                    <TextField
                        label={this.i18n.t("formCreateVehicle.id.label")}
                        {...doubleBindString<FormCreateVehicle>(this, "serialNumber", () =>
                            this.triggerValidation(),
                        )}
                        required
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "serialNumber",
                            ),
                        )}
                    />
                    <TextField
                        label={this.i18n.t("formCreateVehicle.manufacturer.label")}
                        {...doubleBindNullableStringEmptyAsNull<FormCreateVehicle>(
                            this,
                            "manufacturer",
                            () => this.triggerValidation(),
                        )}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "manufacturer",
                            ),
                        )}
                    />
                    <TextField
                        label={this.i18n.t("formCreateVehicle.model.label")}
                        {...doubleBindNullableStringEmptyAsNull<FormCreateVehicle>(
                            this,
                            "model",
                            () => this.triggerValidation(),
                        )}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "model",
                            ),
                        )}
                    />
                    <ConnectedComboBox
                        formatEntity={(vehicleType: VehicleType) =>
                            this.i18n.formatVehicleType(vehicleType)
                        }
                        repository={this.repositoryVehicleTypes}
                        query={{ pageSize: defaultPageSize }}
                        label={this.i18n.t("formCreateVehicle.vehicleType.label")}
                        onChange={this.setVehicleType}
                        clearable
                        selectedKey={this.vehicleTypeId}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "vehicleTypeId",
                            ),
                        )}
                    />
                    <Dropdown
                        label={this.i18n.t("formCreateVehicle.yearOfBuild.label")}
                        selectedKey={this.manufactureYear}
                        onChange={this.setManufactureYear}
                        options={this.yearOptions}
                        calloutProps={{ directionalHintFixed: true }}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "manufactureYear",
                            ),
                        )}
                    />
                    <ConnectedComboBox
                        formatEntity={(department: Department) =>
                            this.i18n.formatDepartment(department)
                        }
                        repository={this.repositoryDepartments}
                        query={{ pageSize: defaultPageSize }}
                        label={this.i18n.t("formCreateVehicle.department.label")}
                        onChange={this.setDepartment}
                        clearable
                        selectedKey={this.departmentId}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "departmentId",
                            ),
                        )}
                    />
                    <ConnectedComboBox
                        formatEntity={this.i18n.formatPreOpsChecklist}
                        repository={this.repositoryPreOpsChecklists}
                        query={{ pageSize: defaultPageSize }}
                        label={this.i18n.t("formCreateVehicle.preOpsChecklist.label")}
                        onChange={this.setPreOpsChecklist}
                        clearable
                        selectedKey={this.preOpsChecklistId}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "preOpsChecklistId",
                            ),
                        )}
                    />
                </Stack>
                <Stack>
                    <TextField disabled label={this.i18n.t("formCreateVehicle.group.label")} />
                    <Toggle
                        label={this.i18n.t("formCreateVehicle.checklistOverride.label")}
                        checked={this.checklistOverride}
                        onChange={this.setChecklistOverride}
                    />
                    <ConnectedComboBox
                        formatEntity={(shockProfile: ShockProfile) => shockProfile.label}
                        repository={this.repositoryShockProfiles}
                        query={{ pageSize: defaultPageSize }}
                        label={this.i18n.t("formCreateVehicle.shockProfile.label")}
                        onChange={this.setShockProfile}
                        clearable
                        selectedKey={this.shockProfileId}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "shockProfileId",
                            ),
                        )}
                    />
                    <TextField
                        disabled={
                            this.serviceOwnUser.user?.managementRole != ManagementRole.SUPER_ADMIN
                        }
                        label={this.i18n.t("formCreateVehicle.magneticFieldThreshold.label")}
                        type="number"
                        {...doubleBindIntegerToString<FormCreateVehicle>(
                            this,
                            "magneticFieldThreshold",
                            () => this.triggerValidation(),
                        )}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "magneticFieldThreshold",
                            ),
                        )}
                    />
                    <TextField
                        type="number"
                        label={this.i18n.t("formCreateVehicle.idleAutoLogoutAfter.label")}
                        {...doubleBindString<FormCreateVehicle>(this, "idleAutoLogoutAfter", () =>
                            this.triggerValidation(),
                        )}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "idleAutoLogoutAfter",
                            ),
                        )}
                    />
                    <Dropdown
                        label={this.i18n.t("formCreateVehicle.engineTypeOptions.label")}
                        options={engineTypeOptions}
                        selectedKey={this.engineType}
                        onChange={this.setEngineType}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "engineType",
                            ),
                        )}
                    />
                </Stack>
            </Stack>
        );
    }

    public renderMaintenanceSection(): JSX.Element {
        const procurementOptions: IDropdownOption[] = [
            {
                key: "purchase",
                text: this.i18n.t("formCreateVehicle.procurementOptions.purchase"),
            },
            {
                key: "leasing",
                text: this.i18n.t("formCreateVehicle.procurementOptions.leasing"),
            },
        ];
        return (
            <Stack
                horizontal
                horizontalAlign="space-between"
                tokens={{
                    childrenGap: "2em",
                }}
            >
                <Stack>
                    <TextField
                        type="number"
                        label={this.i18n.t("formCreateVehicle.initialOperatingHours.label")}
                        {...doubleBindString<FormCreateVehicle>(this, "initialOperatingHours", () =>
                            this.triggerValidation(),
                        )}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "initialOperatingHours",
                            ),
                        )}
                    />
                    <DatePicker
                        disabled={this.procurementType !== ProcurementType.LEASING}
                        isRequired={this.procurementType === ProcurementType.LEASING}
                        maxDate={this.leaseTermEnd}
                        formatDate={this.onFormatDate}
                        placeholder={this.i18n.t("formCreateVehicle.datePicker.placeholder")}
                        label={this.i18n.t("formCreateVehicle.leaseTermBegin.label")}
                        onSelectDate={this.setLeaseTermBegin}
                        strings={this.i18n.datePickerStrings(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "leaseTermBegin",
                            ),
                        )}
                    />
                    <DatePicker
                        formatDate={this.onFormatDate}
                        disabled
                        placeholder={this.i18n.t("formCreateVehicle.datePicker.placeholder")}
                        label={this.i18n.t("formCreateVehicle.lastMaintenance.label")}
                        strings={this.i18n.datePickerStrings(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "leaseTermEnd",
                            ),
                        )}
                    />
                    <TextField
                        type="number"
                        label={this.i18n.t("formCreateVehicle.maintenanceNotificationAt.label")}
                        {...doubleBindString<FormCreateVehicle>(
                            this,
                            "maintenanceNotificationAt",
                            () => this.triggerValidation(),
                        )}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "maintenanceNotificationAt",
                            ),
                        )}
                    />
                </Stack>
                <Stack>
                    <ClearableDropdown
                        label={this.i18n.t("formCreateVehicle.procurementOptions.label")}
                        onChange={this.setProcurementType}
                        options={procurementOptions}
                        selectedKey={this.procurementType}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "procurementType",
                            ),
                        )}
                    />
                    <DatePicker
                        disabled={this.procurementType !== ProcurementType.LEASING}
                        isRequired={this.procurementType === ProcurementType.LEASING}
                        minDate={this.leaseTermBegin}
                        formatDate={this.onFormatDate}
                        placeholder={this.i18n.t("formCreateVehicle.datePicker.placeholder")}
                        label={this.i18n.t("formCreateVehicle.leaseTermEnd.label")}
                        onSelectDate={this.setLeaseTermEnd}
                    />
                    <TextField
                        disabled
                        label={this.i18n.t("formCreateVehicle.operatingHours.label")}
                    />
                    <TextField
                        disabled
                        label={this.i18n.t("formCreateVehicle.hoursSinceLastMaintenance.label")}
                    />
                    <Toggle
                        label={this.i18n.t("formCreateVehicle.maintenanceLockdown.label")}
                        checked={this.maintenanceLockdown}
                        onChange={this.setMaintenanceLockdown}
                    />
                </Stack>
                <Stack>
                    <DatePicker
                        disabled={this.procurementType !== ProcurementType.PURCHASE}
                        isRequired={this.procurementType === ProcurementType.PURCHASE}
                        formatDate={this.onFormatDate}
                        placeholder={this.i18n.t("formCreateVehicle.datePicker.placeholder")}
                        label={this.i18n.t("formCreateVehicle.purchaseDate.label")}
                        onSelectDate={this.setPurchaseDate}
                    />
                    <TextField
                        disabled
                        label={this.i18n.t("formCreateVehicle.daysUntilLeaseTermEnd.label")}
                    />
                    <TextField
                        type="number"
                        label={this.i18n.t("formCreateVehicle.maintenanceInterval.label")}
                        {...doubleBindString<FormCreateVehicle>(this, "maintenanceInterval", () =>
                            this.triggerValidation(),
                        )}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "maintenanceInterval",
                            ),
                        )}
                    />
                    <TextField
                        disabled
                        label={this.i18n.t("formCreateVehicle.hoursUntilMaintenance.label")}
                    />
                </Stack>
            </Stack>
        );
    }

    public renderMiscellaneousSection(): JSX.Element {
        return (
            <Stack
                horizontal
                horizontalAlign="space-between"
                tokens={{
                    childrenGap: "2em",
                }}
            >
                <Stack>
                    <TextField
                        disabled
                        label={this.i18n.t("formCreateVehicle.currentStatus.label")}
                    />
                    <TextField
                        disabled
                        label={this.i18n.t("formCreateVehicle.lastLocation.label")}
                    />
                </Stack>
                <Stack>
                    <TextField disabled label={this.i18n.t("formCreateVehicle.lastDriver.label")} />
                    <TextField
                        disabled={
                            this.serviceOwnUser.user?.managementRole != ManagementRole.SUPER_ADMIN
                        }
                        label={this.i18n.t("formCreateVehicle.phoneImei.label")}
                        {...doubleBindNullableString<FormCreateVehicle>(this, "phoneImei", () =>
                            this.triggerValidation(),
                        )}
                        onChange={this.setPhoneImei}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicles.validation.create.getFieldValidationState(
                                this.validationId,
                                "phoneImei",
                            ),
                        )}
                    />
                </Stack>
                <Stack>
                    <TextField
                        disabled
                        label={this.i18n.t("formCreateVehicle.lastSynchronization.label")}
                    />
                    <ConnectedComboBox
                        disabled={
                            this.serviceOwnUser.user?.managementRole != ManagementRole.SUPER_ADMIN
                        }
                        label={this.i18n.t("formCreateVehicle.vehicleDevice.label")}
                        formatEntity={(vehicleDevice: VehicleDevice) => vehicleDevice.serialNumber}
                        repository={this.repositoryVehicleDevices}
                        query={{
                            pageSize: defaultPageSize,
                            status: VehicleDeviceStatus.NOT_ASSIGNED,
                        }}
                        onChange={this.setVehicleDevice}
                        clearable
                        selectedKey={this.vehicleDeviceId}
                    />
                </Stack>
            </Stack>
        );
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formCreateVehicle.submit.text")}
                validation={this.repositoryVehicles.validation.create}
                validationId={this.validationId}
            />
        );
        return (
            <form onSubmit={this.createVehicle}>
                <Stack
                    horizontal
                    horizontalAlign="space-between"
                    tokens={{
                        childrenGap: "1em",
                        padding: `0px ${sizes.formPaddingHorizontal}`,
                    }}
                >
                    <Stack>
                        <h2>{this.i18n.t("formCreateVehicle.title.general")}</h2>
                        {this.renderGeneralSection()}
                    </Stack>
                    <Separator vertical />
                    <Stack>
                        <h2>{this.i18n.t("formCreateVehicle.title.maintenance")}</h2>
                        {this.renderMaintenanceSection()}
                        <Separator />
                        <h2>{this.i18n.t("formCreateVehicle.title.miscellaneous")}</h2>
                        {this.renderMiscellaneousSection()}
                    </Stack>
                </Stack>
                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            label={this.i18n.t("formCreateVehicle.cancel.label")}
                            text={this.i18n.t("formCreateVehicle.cancel.text")}
                            onClick={this.showConfirmAndCallDialogCancelCallback}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
                {
                    <ModalConfirmation
                        isOpen={this.cancelConfirmationModalVisible}
                        title={this.i18n.t("modalAbortUpdate.title")}
                        text={this.i18n.t("modalAbortUpdate.description")}
                        onConfirm={this.props.onDialogCancel}
                        onCancel={this.closeCancelConfirmationModalVisible}
                    />
                }
            </form>
        );
    }
}
