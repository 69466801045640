import { action } from "mobx";
import React from "react";
import { PropertiesOfType } from "./types";

/** A subset of properties that can be defined on Fluent UI inputs. */
export interface FluentUiDoubleBinding {
    readonly value: string;
    readonly onChange: (evt: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

/**
 * Returns a subset of properties that can be used on Fluent UI inputs to double bind that input
 * to a variable.
 *
 * #### Example
 *
 * ```ts
 * @observer
 * export class ExampleForm extends React.Component {
 *      @observable public value: string = "";
 *
 *      ...
 *
 *      public render(): JSX.Element {
 *          return (<TextField {...doubleBindString<ExampleForm>(this, "value")} />);
 *      }
 * }
 * ```
 */
export function doubleBindString<T>(
    instance: T,
    key: PropertiesOfType<T, string>,
    callback?: (evt: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
): FluentUiDoubleBinding {
    return {
        // Typescript is unable to infer that this is always `string`.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: instance[key] as any,
        onChange: action((evt: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            // Typescript is unable to infer that this is always `string`.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            instance[key] = evt.currentTarget.value as any;
            if (callback) {
                callback(evt);
            }
        }),
    };
}

/**
 * Returns a subset of properties that can be used on Fluent UI inputs to double bind that input
 * to a variable.
 *
 * #### Example
 *
 * ```ts
 * @observer
 * export class ExampleForm extends React.Component {
 *      @observable public value: string = "";
 *
 *      ...
 *
 *      public render(): JSX.Element {
 *          return (<TextField {...doubleBindNullableString<ExampleForm>(this, "value")} />);
 *      }
 * }
 * ```
 */
export function doubleBindNullableString<T>(
    instance: T,
    key: PropertiesOfType<T, string | null | undefined>,
    callback?: (evt: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
): FluentUiDoubleBinding {
    return {
        // Typescript is unable to infer that this is always `string`.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: instance[key] as any,
        onChange: action((evt: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            // Typescript is unable to infer that this is always `string`.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            instance[key] = evt.currentTarget.value as any;
            if (callback) {
                callback(evt);
            }
        }),
    };
}

/**
 * Returns a subset of properties that can be used on Fluent UI inputs to double bind that input
 * to a variable.  Transforms empty string inputs to null.
 *
 * #### Example
 *
 * ```ts
 * @observer
 * export class ExampleForm extends React.Component {
 *      @observable public value: string = "";
 *
 *      ...
 *
 *      public render(): JSX.Element {
 *          return (<TextField {...doubleBindNullableStringEmptyAsNull<ExampleForm>(this, "value")} />);
 *      }
 * }
 * ```
 */
export function doubleBindNullableStringEmptyAsNull<T>(
    instance: T,
    key: PropertiesOfType<T, string | null | undefined>,
    callback?: (evt: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
): FluentUiDoubleBinding {
    return {
        // Typescript is unable to infer that this is always `string`.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: instance[key] as any,
        onChange: action((evt: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            // Typescript is unable to infer that this is always `string`.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            instance[key] = (evt.currentTarget.value == "" ? null : evt.currentTarget.value) as any;
            if (callback) {
                callback(evt);
            }
        }),
    };
}

/**
 * Returns a subset of properties that can be used on Fluent UI inputs to double bind that string
 * input field to a number variable.
 *
 * #### Example
 *
 * ```ts
 * @observer
 * export class ExampleForm extends React.Component {
 *      @observable public value: number = 0;
 *
 *      ...
 *
 *      public render(): JSX.Element {
 *          return (<TextField {...doubleBindIntegerToString<ExampleForm>(this, "value")} />);
 *      }
 * }
 * ```
 */
export function doubleBindIntegerToString<T>(
    instance: T,
    key: PropertiesOfType<T, number | null | undefined>,
    callback?: (evt: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
): FluentUiDoubleBinding {
    return {
        // Typescript is unable to infer that this is always `string`.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: instance[key] as any,
        onChange: action((evt: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            // Typescript is unable to infer that this is always `number`.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            instance[key] = parseInt(evt.currentTarget.value, 10) as any;
            if (callback) {
                callback(evt);
            }
        }),
    };
}
