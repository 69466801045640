import * as React from "react";
import { pick } from "ramda";
import { observer } from "mobx-react";
import { Stack, TextField, DefaultButton } from "@fluentui/react";
import { action, computed, makeObservable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { VehicleTypeUpdate } from "../../api";
import { doubleBindString } from "../../utils/double-bind";
import { VehicleType } from "../../api";
import { createUuid } from "../../utils/uuid";
import { FormUpdateProps } from "../../utils/form-update-props";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";
import { RepositoryVehicleTypes } from "../../domain/repositories/repository-vehicle-types";

export interface FormUpdateVehicleTypeProps extends FormUpdateProps<VehicleType> {}

@observer
@external
export class FormUpdateVehicleType extends React.Component<FormUpdateVehicleTypeProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryVehicleTypes!: RepositoryVehicleTypes;

    private validationId = createUuid();

    constructor(props: FormUpdateVehicleTypeProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositoryVehicleTypes.validation.update.updateModel(
            this.validationId,
            this.vehicleTypeUpdate,
        );
    }

    @initialize protected initialize(): void {
        this.repositoryVehicleTypes.validation.update.initializeModel(
            this.validationId,
            this.vehicleTypeUpdate,
            this.props.id,
        );
    }

    public componentWillUnmount(): void {
        this.repositoryVehicleTypes.discardMutableCopy(FormUpdateVehicleType.name, this.props.id);
    }

    @computed private get vehicleType(): VehicleType | undefined {
        return this.repositoryVehicleTypes.mutableCopyById(
            FormUpdateVehicleType.name,
            this.props.id,
        );
    }

    @computed private get vehicleTypeUpdate(): VehicleTypeUpdate {
        return pick(["label"], this.vehicleType);
    }

    @action.bound
    private async updateVehicleType(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const vehicleType = await this.repositoryVehicleTypes.update(
            this.props.id,
            this.vehicleTypeUpdate,
        );
        if (this.props.onUpdate) {
            this.props.onUpdate(vehicleType);
        }
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formUpdateVehicleType.submit.text")}
                validation={this.repositoryVehicleTypes.validation.update}
                validationId={this.validationId}
            />
        );
        return (
            <form onSubmit={this.updateVehicleType}>
                <Stack tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                    <TextField
                        label={this.i18n.t("formUpdateVehicleType.label.label")}
                        disabled={!this.vehicleType}
                        {...doubleBindString(this.vehicleType!, "label", () =>
                            this.triggerValidation(),
                        )}
                        required
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicleTypes.validation.update.getFieldValidationState(
                                this.validationId,
                                "label",
                            ),
                        )}
                    />
                </Stack>
                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            label={this.i18n.t("formUpdateVehicleType.cancel.label")}
                            text={this.i18n.t("formUpdateVehicleType.cancel.text")}
                            onClick={this.props.onDialogCancel}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
            </form>
        );
    }
}
