import * as React from "react";
import { observer } from "mobx-react";
import { action, computed, makeObservable, observable } from "mobx";
import { ComboBox, IComboBoxOption, IComboBoxProps, ISelectableOption } from "@fluentui/react";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryPublicSettings } from "../../domain/repositories/repository-public-settings";
import { PublicSettingsApi, TimezoneListEntry, WindowsTimezone } from "../../api";
import { LoadingState } from "../../utils/loading-state";
import { ServicePublicSettings } from "../../domain/services/service-public-settings";
import { UUID } from "../../utils/uuid";

export type FormUpdateDefaultTimezoneProps = Omit<
    IComboBoxProps,
    "options" | "selectedKey" | "onChange"
>;

enum UpdateDefaultTimezoneFeatures {
    CHANGE_DEFAULT_TIMEZONE = "change default timezone",
}

@observer
@external
export class FormUpdateDefaultTimezone extends React.Component<FormUpdateDefaultTimezoneProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryPublicSettings!: RepositoryPublicSettings;
    @inject private readonly publicSettingsApi!: PublicSettingsApi;
    @inject private readonly servicePublicSettings!: ServicePublicSettings;

    @observable private timezoneList: TimezoneListEntry[] = [];
    private readonly loadingFeatures = new LoadingState<UpdateDefaultTimezoneFeatures>();

    constructor(props: FormUpdateDefaultTimezoneProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.publicSettingsApi.publicSettingsListTimezones({}).then((timezoneList) => {
            this.timezoneList = timezoneList;
        });
    }

    @computed private get options(): ISelectableOption<unknown>[] {
        return this.timezoneList.map((timezone) => ({
            key: timezone.timezone,
            text: timezone.description,
        }));
    }

    @action.bound private async updateDefaultTimeZone(
        publicSettingId: UUID,
        option: IComboBoxOption | undefined,
    ): Promise<void> {
        const timezone = option?.key as WindowsTimezone;
        await this.loadingFeatures.wrap(
            UpdateDefaultTimezoneFeatures.CHANGE_DEFAULT_TIMEZONE,
            this.repositoryPublicSettings.update(publicSettingId, {
                defaultTimezone: timezone,
            }),
        );
    }

    public render(): JSX.Element {
        return (
            <ComboBox
                {...this.props}
                disabled={
                    this.loadingFeatures.isLoading(
                        UpdateDefaultTimezoneFeatures.CHANGE_DEFAULT_TIMEZONE,
                    ) || this.props.disabled
                }
                label={this.i18n.t("formUpdateDefaultTimezone.label")}
                options={this.options}
                selectedKey={
                    this.loadingFeatures.isLoading(
                        UpdateDefaultTimezoneFeatures.CHANGE_DEFAULT_TIMEZONE,
                    )
                        ? undefined
                        : this.servicePublicSettings.publicSetting?.defaultTimezone
                }
                onChange={(_, option) => {
                    if (this.servicePublicSettings.publicSetting) {
                        this.updateDefaultTimeZone(
                            this.servicePublicSettings.publicSetting.id,
                            option,
                        );
                    }
                }}
            />
        );
    }
}
