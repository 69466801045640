import { ManagementRole } from "./api";

/**
 * Represents a tag in the manual.
 * Articles are tagged with tags so that the user can browse the manual by tags.
 */
export interface HelpTag {
    /** Translation key that is displayed to the user. */
    readonly title: string;
    /** Unique name for this tag used internally for referencing. */
    readonly name: string;
}

/** State of loading an article. */
export enum HelpArticleStatus {
    /** The article is currently not being loaded. */
    PENDING = "PENDING",
    /** The article is currently being loaded. */
    LOADING = "LOADING",
    /** The article was loaded successfully. */
    SUCCESS = "SUCCESS",
    /** The article was not found in the current locale, but in a fallback locale. */
    FALLBACK = "FALLBACK",
    /** The article could not be loaded. */
    FAILURE = "FAILURE",
}

/** Will be returned when trying to load an article. */
export type HelpArticleContent =
    | {
          status: HelpArticleStatus.SUCCESS | HelpArticleStatus.FALLBACK;
          readonly content: string;
      }
    | {
          status: HelpArticleStatus.FAILURE | HelpArticleStatus.PENDING | HelpArticleStatus.LOADING;
      };

/** Meta information for an article in the manual. */
export interface HelpArticle {
    /**
     * Filename in the `help/` directory.
     * This file must exist in the language subdirectories.
     */
    readonly filename: string;
    /** Translation key for the article's title. */
    readonly title: string;
    /** Translation key for the article's summary. */
    readonly summary: string;
    /** List of tags that this article is associated with. */
    readonly tags: HelpTag[];
    /** Role that the user must have in order to be able to see this article. */
    readonly access: ManagementRole;
    /** Optional content of a help article. */
    content: HelpArticleContent;
}

export function createArticle(
    article: Omit<HelpArticle, "access" | "content"> & { access?: ManagementRole },
): HelpArticle {
    // The management role can be omitted when registering the article.
    // Make sure the stored and returned article is fully specified.
    const fullArticle: HelpArticle = {
        ...article,
        access: article.access ?? ManagementRole.RESTRICTED,
        content: { status: HelpArticleStatus.PENDING },
    };
    return fullArticle;
}

export function createTag(name: string, title: string): HelpTag {
    return { name, title };
}
