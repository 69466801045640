import { History } from "history";
import React from "react";
import { unstable_HistoryRouter as Router, Route, Routes } from "react-router-dom";
import { RequireAuth } from "./components/RequireAuth";
import { ServiceAuth } from "./domain/services/service-auth";
import { getRoutes } from "./routes";
import { PageDashboard } from "./pages/page-dashboard";
import elodmsRoutes from "./elodms/routes";

import "./elodms/preflight.css";
import "./elodms/style.css";
import RedirectMobileToDashboard from "./ui/layouts/redirect-mobile-to-dashboard";
import { PageDispatcher } from "./pages/page-dispatcher";

// Root react component.
export function Main(props: { serviceAuth: ServiceAuth; history: History }): JSX.Element {
    if ([...getRoutes()].length === 0) return <></>;
    // We serve one ELOfleet instance specifically for the instance dispatcher.
    // This instance shouldn't serve any other routes.
    if (window.location.hostname === "login.elofleet.com") {
        return <PageDispatcher />;
    }
    return (
        <Router history={props.history}>
            <Routes>
                {/* protected routes */}
                <Route element={<RequireAuth serviceAuth={props.serviceAuth} />}>
                    {/* default route */}
                    <Route path="/" element={<PageDashboard />} />
                    {/* Redirect ELOfleet pages to dashboard on small screens */}
                    <Route element={<RedirectMobileToDashboard />}>
                        {/* ELOfleet routes */}
                        {[...getRoutes()]
                            .filter((route) => route.public === undefined || !route.public)
                            .map((route) => {
                                const Component = route.component;
                                return (
                                    <Route
                                        path={route.pattern}
                                        element={<Component />}
                                        key={route.component.name}
                                    />
                                );
                            })}
                    </Route>
                    {/* ELOdms routes */}
                    {Object.entries(elodmsRoutes).map(([key, route]) => {
                        return (
                            <Route
                                path={route.pathDefinition}
                                element={route.component}
                                key={key}
                            />
                        );
                    })}
                </Route>
                {/* public routes */}
                {[...getRoutes()]
                    .filter((route) => route.public !== undefined && route.public)
                    .map((route) => {
                        const Component = route.component;
                        return (
                            <Route
                                path={route.pattern}
                                element={<Component />}
                                key={route.component.name}
                            />
                        );
                    })}
            </Routes>
        </Router>
    );
}
