import React from "react";
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, useReactFlow } from "reactflow";
import css from "./action-config-edge.scss";
import { TooltipHost } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

/** A custom edge type for our action configuration. This example is directly
    taken from https://reactflow.dev/examples/edges/custom-edges. */
export default function ActionConfigEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
}: EdgeProps): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    const { setEdges } = useReactFlow();
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const onEdgeClick = (): void => {
        setEdges((edges) => edges.filter((edge) => edge.id !== id));
    };

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: "absolute",
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        // Everything inside EdgeLabelRenderer has no pointer events by default.
                        // If you have an interactive element, set pointer-events: all.
                        pointerEvents: "all",
                    }}
                    className="nodrag nopan"
                >
                    <TooltipHost content={t("component.actionConfigEdge.delete")}>
                        <button className={css.actionConfigEdge__button} onClick={onEdgeClick}>
                            ×
                        </button>
                    </TooltipHost>
                </div>
            </EdgeLabelRenderer>
        </>
    );
}
