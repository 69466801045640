/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PreOpsAnswerChoice } from './PreOpsAnswerChoice';
import {
    PreOpsAnswerChoiceFromJSON,
    PreOpsAnswerChoiceFromJSONTyped,
    PreOpsAnswerChoiceToJSON,
} from './PreOpsAnswerChoice';

/**
 * 
 * @export
 * @interface PreOpsAnswerCreate
 */
export interface PreOpsAnswerCreate {
    /**
     * 
     * @type {PreOpsAnswerChoice}
     * @memberof PreOpsAnswerCreate
     */
    choice: PreOpsAnswerChoice;
    /**
     * The precise version of the question that's being answered.
     * @type {string}
     * @memberof PreOpsAnswerCreate
     */
    questionVersionId: string;
}

/**
 * Check if a given object implements the PreOpsAnswerCreate interface.
 */
export function instanceOfPreOpsAnswerCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "choice" in value;
    isInstance = isInstance && "questionVersionId" in value;

    return isInstance;
}

export function PreOpsAnswerCreateFromJSON(json: any): PreOpsAnswerCreate {
    return PreOpsAnswerCreateFromJSONTyped(json, false);
}

export function PreOpsAnswerCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreOpsAnswerCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'choice': PreOpsAnswerChoiceFromJSON(json['choice']),
        'questionVersionId': json['questionVersionId'],
    };
}

export function PreOpsAnswerCreateToJSON(value?: PreOpsAnswerCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'choice': PreOpsAnswerChoiceToJSON(value.choice),
        'questionVersionId': value.questionVersionId,
    };
}

