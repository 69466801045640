import { setHours, setMinutes, subDays, weeksToDays } from "date-fns";
import { Draft } from "immer";
import { StateCreator } from "zustand";
import { UUID } from "../../utils/uuid";
import { demoEquipment, equipmentIsVehicle } from "./equipment";
import { createUuid } from "../../utils/uuid";

export enum ServiceReportStatus {
    Ok,
    Warning,
    Critical,
}

export function serviceReportStatusToString(status: ServiceReportStatus): string {
    switch (status) {
        case ServiceReportStatus.Ok:
            return "Ok";
        case ServiceReportStatus.Warning:
            return "Warning";
        case ServiceReportStatus.Critical:
            return "Critical";
    }
}

export interface ServiceReportStatuses {
    motorFluid: ServiceReportStatus;
    steeringAxle: ServiceReportStatus;
    safetyContacts: ServiceReportStatus;
    lubricate: ServiceReportStatus;
}

export interface ServiceReport {
    timestamp: Date;
    equipment_id: UUID;
    statuses: ServiceReportStatuses;
}

export function serviceReportStatus(serviceReport: ServiceReport): ServiceReportStatus {
    const statuses = Object.values(serviceReport.statuses);
    if (statuses.includes(ServiceReportStatus.Critical)) {
        return ServiceReportStatus.Critical;
    } else if (statuses.includes(ServiceReportStatus.Warning)) {
        return ServiceReportStatus.Warning;
    } else {
        return ServiceReportStatus.Ok;
    }
}

export interface ServiceReportsSlice {
    serviceReports: Record<UUID, ServiceReport>;

    insertServiceReport: (serviceReport: ServiceReport) => UUID;
    updateServiceReport: (
        id: UUID,
        callback: (serviceReport: Draft<ServiceReport>) => void,
    ) => void;
}

export const createServiceReportsSlice: StateCreator<
    ServiceReportsSlice,
    [["zustand/immer", never]],
    []
> = (set) => ({
    serviceReports: demoServiceReports,

    insertServiceReport: (serviceReport: ServiceReport) => {
        const id = createUuid();
        set((state) => {
            state.serviceReports[id] = serviceReport;
        });
        return id;
    },
    updateServiceReport: (id, callback) =>
        set((state) => {
            callback(state.serviceReports[id]);
        }),
});

const demoServiceReports: Record<UUID, ServiceReport> = {};

const now = new Date();
const oneWeekAgo = subDays(now, weeksToDays(1));
const twoWeeksAgo = subDays(now, weeksToDays(2));
const threeWeeksAgo = subDays(now, weeksToDays(3));

// Can't import from faker.ts because webpack doesn't have a polyfill for the crypto module.
const randomInteger = (min: number, max: number): number =>
    Math.floor(Math.random() * (max - min)) + min;
const randomServiceReportStatus = (): ServiceReportStatus => {
    switch (randomInteger(0, 6)) {
        case 0:
        case 1:
        case 2:
            return ServiceReportStatus.Ok;
        case 3:
        case 4:
            return ServiceReportStatus.Warning;
        case 5:
            return ServiceReportStatus.Critical;
        default:
            // Unreachable
            return ServiceReportStatus.Ok;
    }
};

const vehicles = Object.entries(demoEquipment)
    .filter(([_equipmentId, equipment]) => equipmentIsVehicle(equipment.equipmentType))
    .map(([equipmentId, _equipment]) => equipmentId);
for (const equipmentId of vehicles) {
    for (const [start, end] of [
        [twoWeeksAgo.getTime(), threeWeeksAgo.getTime()],
        [oneWeekAgo.getTime(), twoWeeksAgo.getTime()],
        [now.getTime(), oneWeekAgo.getTime()],
    ]) {
        const timestamp = setMinutes(
            setHours(new Date(randomInteger(start, end)), randomInteger(0, 9) + 8),
            randomInteger(0, 60),
        );
        if (Math.random() < 0.5) {
            demoServiceReports[createUuid()] = {
                timestamp,
                equipment_id: equipmentId,
                statuses: {
                    safetyContacts: randomServiceReportStatus(),
                    motorFluid: ServiceReportStatus.Ok,
                    steeringAxle: ServiceReportStatus.Ok,
                    lubricate: ServiceReportStatus.Ok,
                },
            };
        }
    }
}
