/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ShockEventSortKey {
    TIME = 'time',
    USERNAME = 'username',
    G_FORCE = 'gForce',
    VEHICLE = 'vehicle',
    VEHICLE_DEPARTMENT = 'vehicleDepartment'
}


export function ShockEventSortKeyFromJSON(json: any): ShockEventSortKey {
    return ShockEventSortKeyFromJSONTyped(json, false);
}

export function ShockEventSortKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShockEventSortKey {
    return json as ShockEventSortKey;
}

export function ShockEventSortKeyToJSON(value?: ShockEventSortKey | null): any {
    return value as any;
}

