import { runInAction } from "mobx";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    FileType,
    PageInfo,
    PublicSetting,
    PublicSettingsApi,
    PublicSettingUpdate,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { Validation } from "../../utils/validation";
import { ServiceFile } from "../services/service-file";

export interface PublicSettingsApiResource extends ApiResource {
    entity: PublicSetting;
    query: void;
    update: PublicSettingUpdate;
    create: void;
    sortKey: string;
}

@component
export class RepositoryPublicSettings extends ElofleetRepository<PublicSettingsApiResource> {
    @inject private readonly publicSettingsApi!: PublicSettingsApi;
    @inject private readonly serviceFile!: ServiceFile;

    public validation = {
        update: new Validation((publicSettingUpdate: PublicSettingUpdate, id: UUID) =>
            this.publicSettingsApi.publicSettingsValidateUpdatePublicSetting({
                publicSettingUpdate,
                id,
            }),
        ),
    };

    protected async fetchById(publicSettingId: UUID): Promise<PublicSetting | undefined> {
        return this.publicSettingsApi.publicSettingsReadPublicSettingById({ id: publicSettingId });
    }

    protected extractId = prop("id");

    protected async fetchByQuery(
        _query: void,
        _pagination: Segment,
    ): Promise<FetchByQueryResult<PublicSetting>> {
        return { entities: [await this.publicSettingsApi.publicSettingsReadPublicSetting()] };
    }

    public fetchMetadata(_: unknown): Promise<PageInfo> {
        throw new Error("Cannot list setting metadata: Method not implemented.");
    }

    public async update(
        id: UUID,
        publicSettingUpdate: PublicSettingUpdate,
        file?: Uint8Array,
    ): Promise<PublicSetting> {
        const publicSetting = await this.wrapApiCall(
            this.publicSettingsApi.publicSettingsUpdatePublicSetting({ publicSettingUpdate, id }),
        );

        if (file !== undefined) {
            try {
                const logoId = await this.serviceFile.createImageFile(
                    id,
                    FileType.PUBLIC_SETTING_LOGO,
                    file,
                );
                if (logoId) {
                    publicSetting.logoId = logoId;
                }
            } catch (error) {
                this.handleError(error);
            }
        }

        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery();
            this.add(publicSetting);
        });

        return publicSetting;
    }

    public create(_settingCreate: never): Promise<PublicSetting> {
        throw new Error("Cannot create new public setting: Method not implemented.");
    }

    public delete(..._ids: string[]): Promise<void> {
        throw new Error("Cannot delete public setting: Method not implemented.");
    }
}
