import { routePath } from "../routes";
import * as React from "react";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { PageContent } from "../ui/atoms/page-content";
import { PageHeader } from "../ui/atoms/page-header";
import { useTranslation } from "react-i18next";
import { ColumnVisibility, ColumnVisibilitysApi, ColumnVisibilityUpdate } from "../api";
import { tsdi } from "../tsdi";
import { TFunction } from "i18next";
import { Section } from "../ui/atoms/section";
import { useImmer } from "use-immer";
import { Draft } from "immer";
import { UUID } from "../utils/uuid";
import { useState, useCallback, useEffect } from "react";
import { ListColumnVisibility } from "../ui/molecules/list-column-visibility";
import { RepositoryColumnVisibility } from "../domain/repositories/repository-column-visibility";
import sizes from "../ui/sizes.scss";

export type ColumnVisibilityMap = {
    [key: string]: {
        [key: string]: boolean;
    };
};

export function PageColumnVisibility(_props: {}): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    const columnVisibilitysApi = tsdi.get(ColumnVisibilitysApi);
    const repositoryColumnVisibility = tsdi.get(RepositoryColumnVisibility);

    // We need to keep track of the ID of the column visibility entity so that
    // we know which ID to use when submitting the update request.
    const [columnVisibilityId, setColumnVisibilityId] = useState<UUID | undefined>(undefined);
    const [columnVisibilityMap, setColumnVisibilityMap] = useState<ColumnVisibilityMap | undefined>(
        undefined,
    );
    const [columnVisibilityUpdate, updateColumnVisibilityUpdate] = useImmer<ColumnVisibilityMap>(
        {},
    );

    const setColumnVisibilityState = useCallback(
        (columnVisibility: ColumnVisibility): void => {
            // We filter the ColumnVisibility object such that it only contains
            // the keys that are also objects.
            const map: ColumnVisibilityMap = Object.fromEntries(
                Object.entries(columnVisibility).filter(
                    ([page, _]) =>
                        typeof (columnVisibility as unknown as ColumnVisibilityMap)[page] ===
                            "object" && page,
                ),
            );
            setColumnVisibilityId(columnVisibility.id);
            setColumnVisibilityMap(map);
            // When we retrieve a new state we cleAr the old update map.
            updateColumnVisibilityUpdate({});
        },
        [updateColumnVisibilityUpdate],
    );

    useEffect(() => {
        let ignoreResult = false;
        columnVisibilitysApi.columnVisibilityReadColumnVisibility({}).then((columnVisibility) => {
            // If the cleanup function was called we want to ignore the
            // returned data as it might no longer be valid.
            if (ignoreResult) {
                return;
            }
            setColumnVisibilityState(columnVisibility);
        });
        return () => {
            ignoreResult = true;
        };
    }, [columnVisibilitysApi, setColumnVisibilityState]);

    // Callback used to set the column visibility update state for a specific
    // column on a specific page.
    const setColumnVisibility = (page: string, column: string, visibility: boolean): void => {
        if (!columnVisibilityMap) {
            return;
        }

        // Update the visibility for a specific column on a specific page.
        const updateVisibility = (
            draft: Draft<ColumnVisibilityMap>,
            page: string,
            column: string,
            visibility: boolean,
        ): void => {
            if (columnVisibilityMap[page][column] === visibility) {
                // If the new visibility state is the same as the old one we
                // just remove it from the update map since it's a no-op.
                if (page in draft) {
                    if (column in draft[page]) {
                        delete draft[page][column];
                    }
                    if (Object.keys(draft[page]).length === 0) {
                        delete draft[page];
                    }
                }
            } else {
                // Otherwise we just add it to the update map.
                if (!(page in draft)) {
                    draft[page] = {};
                }
                draft[page][column] = visibility;
            }
        };

        updateColumnVisibilityUpdate((draft) => {
            // If the user clicked on an "All" column checkbox we need to
            // update the visibility for every page that has that column.
            if (page === "all") {
                for (const page of Object.keys(columnVisibilityMap)) {
                    // If the column doesn't exist on this page, ignore it.
                    if (!(column in columnVisibilityMap[page])) {
                        continue;
                    }
                    updateVisibility(draft, page, column, visibility);
                }
            } else {
                updateVisibility(draft, page, column, visibility);
            }
        });
    };

    const updateColumnVisibility = (): Promise<void> => {
        if (!columnVisibilityId) {
            return new Promise(() => {});
        }
        return columnVisibilitysApi
            .columnVisibilityUpdateColumnVisibility({
                id: columnVisibilityId,
                columnVisibilityUpdate: columnVisibilityUpdate as ColumnVisibilityUpdate,
            })
            .then(setColumnVisibilityState)
            .then(() => repositoryColumnVisibility.reset());
    };

    return (
        <LayoutDefault header={<PageHeader title={t("page.columnVisibility.title")} />}>
            <PageContent>
                <Section>
                    <ListColumnVisibility
                        columnVisibilityMap={columnVisibilityMap}
                        columnVisibilityUpdate={columnVisibilityUpdate}
                        onChange={setColumnVisibility}
                        onSave={updateColumnVisibility}
                        maxHeight={`${sizes.listMaxHeight}`}
                    />
                </Section>
            </PageContent>
        </LayoutDefault>
    );
}

export const routeColumnVisibility = {
    component: PageColumnVisibility,
    icon: "TripleColumn",
    title: "page.columnVisibility.navbarEntry",
    path: routePath.columnVisibility,
    pattern: "/column-visibility",
};
