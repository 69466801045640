import { observable, action, makeObservable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { inject, external } from "tsdi";
import { Department, UserGroup, Shift, FleetRole } from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { RepositoryShifts } from "../../domain/repositories/repository-shifts";
import { RepositoryUserGroups } from "../../domain/repositories/repository-user-groups";
import { MultiSelectComboBox } from "../atoms/multi-select-connected-combo-box";
import { ActionButton } from "@fluentui/react";

export interface FilterUserListProps {
    /** This callback function allows the filter to rerender
     * the parent component. */
    setUserListFilter(filter: FilterUserListQuery): void;
}

export type FilterUserListQuery = {
    filteredDepartments: string[];
    filteredFleetRoles: FleetRole[];
    filteredUserGroups: string[];
    filteredShifts: string[];
    isFiltered: boolean;
};

/**
 * A class which can filter the user list by departments, fleet roles,
 * group assignments and shifts.
 */
@external
@observer
export class FilterUserList extends React.Component<FilterUserListProps> {
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly repositoryDepartments!: RepositoryDepartments;
    @inject private readonly repositoryUserGroups!: RepositoryUserGroups;
    @inject private readonly repositoryShifts!: RepositoryShifts;

    @observable private filteredDepartments: string[] = [];
    @observable private filteredFleetRoles: FleetRole[] = [];
    @observable private filteredUserGroups: string[] = [];
    @observable private filteredShifts: string[] = [];
    @observable private isFiltered: boolean = false;

    constructor(props: FilterUserListProps) {
        super(props);
        makeObservable(this);
    }

    private setDepartmentFilter(filter: string): void {
        // Remove element if it is already clicked.
        if (this.filteredDepartments.includes(filter)) {
            this.filteredDepartments = this.filteredDepartments.filter((id) => id !== filter);
            this.setUserListFilter();
            return;
        }
        this.isFiltered = true;
        this.filteredDepartments = [filter, ...this.filteredDepartments];
        this.setUserListFilter();
    }

    private setFleetRolesFilter(filter: FleetRole): void {
        // Remove element if it is already clicked.
        if (this.filteredFleetRoles.includes(filter)) {
            this.filteredFleetRoles = this.filteredFleetRoles.filter((id) => id !== filter);
            this.setUserListFilter();
            return;
        }
        this.isFiltered = true;
        this.filteredFleetRoles = [filter, ...this.filteredFleetRoles];
        this.setUserListFilter();
    }

    private setShiftFilter(filter: string): void {
        // Remove element if it is already clicked.
        if (this.filteredShifts.includes(filter)) {
            this.filteredShifts = this.filteredShifts.filter((id) => id !== filter);
            this.setUserListFilter();
            return;
        }
        this.isFiltered = true;
        this.filteredShifts = [filter, ...this.filteredShifts];
        this.setUserListFilter();
    }

    private setUserGroupFilter(filter: string): void {
        // Remove element if it is already clicked.
        if (this.filteredUserGroups.includes(filter)) {
            this.filteredUserGroups = this.filteredUserGroups.filter((id) => id !== filter);
            this.setUserListFilter();
            return;
        }
        this.isFiltered = true;
        this.filteredUserGroups = [filter, ...this.filteredUserGroups];
        this.setUserListFilter();
    }

    @action.bound private clearFilter(): void {
        this.filteredDepartments = [];
        this.filteredShifts = [];
        this.filteredUserGroups = [];
        this.isFiltered = false;
        this.setUserListFilter();
    }

    private setUserListFilter(): void {
        const filterQuery: FilterUserListQuery = {
            filteredDepartments: this.filteredDepartments,
            filteredFleetRoles: this.filteredFleetRoles,
            filteredUserGroups: this.filteredUserGroups,
            filteredShifts: this.filteredShifts,
            isFiltered: this.isFiltered,
        };
        this.props.setUserListFilter(filterQuery);
    }

    public render(): JSX.Element {
        return (
            <>
                <MultiSelectComboBox
                    allowFreeForm={false}
                    placeholder={this.i18n.t("schema.user.department")}
                    repository={this.repositoryDepartments}
                    formatEntity={(department: Department) => department.label}
                    selectedKeys={this.filteredDepartments}
                    onChange={(departmentId: string | undefined) => {
                        if (departmentId === undefined) {
                            return;
                        }
                        this.setDepartmentFilter(departmentId);
                    }}
                    allowSelectAll
                />
                <MultiSelectComboBox
                    allowFreeForm={false}
                    entities={Object.values(FleetRole).map((fleetRole) => {
                        return {
                            id: fleetRole.toString(),
                        };
                    })}
                    placeholder={this.i18n.t("schema.user.fleetRole")}
                    formatEntity={({ id: fleetRole }) =>
                        this.i18n.formatFleetRole(fleetRole as FleetRole)
                    }
                    selectedKeys={this.filteredFleetRoles}
                    onChange={(fleetRole?: string | undefined) => {
                        if (fleetRole === undefined) {
                            return;
                        }
                        this.setFleetRolesFilter(fleetRole as FleetRole);
                    }}
                    allowSelectAll
                />
                <MultiSelectComboBox
                    allowFreeForm={false}
                    placeholder={this.i18n.t("schema.user.shift")}
                    repository={this.repositoryShifts}
                    formatEntity={(shift: Shift) => shift.label}
                    selectedKeys={this.filteredShifts}
                    onChange={(shiftId: string | undefined) => {
                        if (shiftId === undefined) {
                            return;
                        }
                        this.setShiftFilter(shiftId);
                    }}
                    allowSelectAll
                />
                <MultiSelectComboBox
                    allowFreeForm={false}
                    placeholder={this.i18n.t("schema.userGroup.userGroup")}
                    repository={this.repositoryUserGroups}
                    formatEntity={(userGroup: UserGroup) => userGroup.label}
                    selectedKeys={this.filteredUserGroups}
                    onChange={(userGroupId: string | undefined) => {
                        if (userGroupId === undefined) {
                            return;
                        }
                        this.setUserGroupFilter(userGroupId);
                    }}
                    allowSelectAll
                />
                <ActionButton
                    iconProps={{ iconName: "clearFilter" }}
                    allowDisabledFocus
                    disabled={!this.isFiltered}
                    checked={true}
                    onClick={this.clearFilter}
                ></ActionButton>
            </>
        );
    }
}
