/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Department,
  DepartmentCreate,
  DepartmentSortKey,
  DepartmentUpdate,
  ErrorResponse,
  PageInfo,
  SortDirection,
} from '../models';
import {
    DepartmentFromJSON,
    DepartmentToJSON,
    DepartmentCreateFromJSON,
    DepartmentCreateToJSON,
    DepartmentSortKeyFromJSON,
    DepartmentSortKeyToJSON,
    DepartmentUpdateFromJSON,
    DepartmentUpdateToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface DepartmentsCreateDepartmentRequest {
    departmentCreate: DepartmentCreate;
}

export interface DepartmentsDeleteDepartmentRequest {
    departmentId: string;
}

export interface DepartmentsListDepartmentsRequest {
    pageSize: number;
    search?: string | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: DepartmentSortKey;
}

export interface DepartmentsListDepartmentsMetadataRequest {
    pageSize: number;
}

export interface DepartmentsReadDepartmentRequest {
    departmentId: string;
}

export interface DepartmentsUpdateDepartmentRequest {
    departmentId: string;
    departmentUpdate: DepartmentUpdate;
}

export interface DepartmentsValidateCreateDepartmentRequest {
    departmentCreate: DepartmentCreate;
}

export interface DepartmentsValidateUpdateDepartmentRequest {
    departmentId: string;
    departmentUpdate: DepartmentUpdate;
}

/**
 * DepartmentsApi - interface
 * 
 * @export
 * @interface DepartmentsApiInterface
 */
export interface DepartmentsApiInterface {
    /**
     * Create a new department.
     * @summary Create a department
     * @param {DepartmentCreate} departmentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApiInterface
     */
    departmentsCreateDepartmentRaw(requestParameters: DepartmentsCreateDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Department>>;

    /**
     * Create a new department.
     * Create a department
     */
    departmentsCreateDepartment(requestParameters: DepartmentsCreateDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Department>;

    /**
     * Delete the department with the given ID.
     * @summary Delete a department
     * @param {string} departmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApiInterface
     */
    departmentsDeleteDepartmentRaw(requestParameters: DepartmentsDeleteDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the department with the given ID.
     * Delete a department
     */
    departmentsDeleteDepartment(requestParameters: DepartmentsDeleteDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Lists all the departments present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * @summary List departments
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {DepartmentSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApiInterface
     */
    departmentsListDepartmentsRaw(requestParameters: DepartmentsListDepartmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Department>>>;

    /**
     * Lists all the departments present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List departments
     */
    departmentsListDepartments(requestParameters: DepartmentsListDepartmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Department>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary Department listing metadata
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApiInterface
     */
    departmentsListDepartmentsMetadataRaw(requestParameters: DepartmentsListDepartmentsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * Department listing metadata
     */
    departmentsListDepartmentsMetadata(requestParameters: DepartmentsListDepartmentsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the department specified by the given ID.
     * @summary Get a single department
     * @param {string} departmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApiInterface
     */
    departmentsReadDepartmentRaw(requestParameters: DepartmentsReadDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Department>>;

    /**
     * Returns the department specified by the given ID.
     * Get a single department
     */
    departmentsReadDepartment(requestParameters: DepartmentsReadDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Department>;

    /**
     * Update the department specified by the given ID.
     * @summary Update department
     * @param {string} departmentId 
     * @param {DepartmentUpdate} departmentUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApiInterface
     */
    departmentsUpdateDepartmentRaw(requestParameters: DepartmentsUpdateDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Department>>;

    /**
     * Update the department specified by the given ID.
     * Update department
     */
    departmentsUpdateDepartment(requestParameters: DepartmentsUpdateDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Department>;

    /**
     * Validate data for a new department without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a department,
     * @param {DepartmentCreate} departmentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApiInterface
     */
    departmentsValidateCreateDepartmentRaw(requestParameters: DepartmentsValidateCreateDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new department without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a department,
     */
    departmentsValidateCreateDepartment(requestParameters: DepartmentsValidateCreateDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate data for updating the given department, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate department update
     * @param {string} departmentId 
     * @param {DepartmentUpdate} departmentUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApiInterface
     */
    departmentsValidateUpdateDepartmentRaw(requestParameters: DepartmentsValidateUpdateDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for updating the given department, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate department update
     */
    departmentsValidateUpdateDepartment(requestParameters: DepartmentsValidateUpdateDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class DepartmentsApi extends runtime.BaseAPI implements DepartmentsApiInterface {

    /**
     * Create a new department.
     * Create a department
     */
    async departmentsCreateDepartmentRaw(requestParameters: DepartmentsCreateDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Department>> {
        if (requestParameters.departmentCreate === null || requestParameters.departmentCreate === undefined) {
            throw new runtime.RequiredError('departmentCreate','Required parameter requestParameters.departmentCreate was null or undefined when calling departmentsCreateDepartment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/departments/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DepartmentCreateToJSON(requestParameters.departmentCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DepartmentFromJSON(jsonValue));
    }

    /**
     * Create a new department.
     * Create a department
     */
    async departmentsCreateDepartment(requestParameters: DepartmentsCreateDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Department> {
        const response = await this.departmentsCreateDepartmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the department with the given ID.
     * Delete a department
     */
    async departmentsDeleteDepartmentRaw(requestParameters: DepartmentsDeleteDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
            throw new runtime.RequiredError('departmentId','Required parameter requestParameters.departmentId was null or undefined when calling departmentsDeleteDepartment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/departments/{department_id}`.replace(`{${"department_id"}}`, encodeURIComponent(String(requestParameters.departmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the department with the given ID.
     * Delete a department
     */
    async departmentsDeleteDepartment(requestParameters: DepartmentsDeleteDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.departmentsDeleteDepartmentRaw(requestParameters, initOverrides);
    }

    /**
     * Lists all the departments present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List departments
     */
    async departmentsListDepartmentsRaw(requestParameters: DepartmentsListDepartmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Department>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling departmentsListDepartments.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/departments/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DepartmentFromJSON));
    }

    /**
     * Lists all the departments present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List departments
     */
    async departmentsListDepartments(requestParameters: DepartmentsListDepartmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Department>> {
        const response = await this.departmentsListDepartmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * Department listing metadata
     */
    async departmentsListDepartmentsMetadataRaw(requestParameters: DepartmentsListDepartmentsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling departmentsListDepartmentsMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/departments/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * Department listing metadata
     */
    async departmentsListDepartmentsMetadata(requestParameters: DepartmentsListDepartmentsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.departmentsListDepartmentsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the department specified by the given ID.
     * Get a single department
     */
    async departmentsReadDepartmentRaw(requestParameters: DepartmentsReadDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Department>> {
        if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
            throw new runtime.RequiredError('departmentId','Required parameter requestParameters.departmentId was null or undefined when calling departmentsReadDepartment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/departments/{department_id}`.replace(`{${"department_id"}}`, encodeURIComponent(String(requestParameters.departmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DepartmentFromJSON(jsonValue));
    }

    /**
     * Returns the department specified by the given ID.
     * Get a single department
     */
    async departmentsReadDepartment(requestParameters: DepartmentsReadDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Department> {
        const response = await this.departmentsReadDepartmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the department specified by the given ID.
     * Update department
     */
    async departmentsUpdateDepartmentRaw(requestParameters: DepartmentsUpdateDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Department>> {
        if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
            throw new runtime.RequiredError('departmentId','Required parameter requestParameters.departmentId was null or undefined when calling departmentsUpdateDepartment.');
        }

        if (requestParameters.departmentUpdate === null || requestParameters.departmentUpdate === undefined) {
            throw new runtime.RequiredError('departmentUpdate','Required parameter requestParameters.departmentUpdate was null or undefined when calling departmentsUpdateDepartment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/departments/{department_id}`.replace(`{${"department_id"}}`, encodeURIComponent(String(requestParameters.departmentId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DepartmentUpdateToJSON(requestParameters.departmentUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DepartmentFromJSON(jsonValue));
    }

    /**
     * Update the department specified by the given ID.
     * Update department
     */
    async departmentsUpdateDepartment(requestParameters: DepartmentsUpdateDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Department> {
        const response = await this.departmentsUpdateDepartmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new department without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a department,
     */
    async departmentsValidateCreateDepartmentRaw(requestParameters: DepartmentsValidateCreateDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.departmentCreate === null || requestParameters.departmentCreate === undefined) {
            throw new runtime.RequiredError('departmentCreate','Required parameter requestParameters.departmentCreate was null or undefined when calling departmentsValidateCreateDepartment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/departments/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DepartmentCreateToJSON(requestParameters.departmentCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new department without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a department,
     */
    async departmentsValidateCreateDepartment(requestParameters: DepartmentsValidateCreateDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.departmentsValidateCreateDepartmentRaw(requestParameters, initOverrides);
    }

    /**
     * Validate data for updating the given department, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate department update
     */
    async departmentsValidateUpdateDepartmentRaw(requestParameters: DepartmentsValidateUpdateDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
            throw new runtime.RequiredError('departmentId','Required parameter requestParameters.departmentId was null or undefined when calling departmentsValidateUpdateDepartment.');
        }

        if (requestParameters.departmentUpdate === null || requestParameters.departmentUpdate === undefined) {
            throw new runtime.RequiredError('departmentUpdate','Required parameter requestParameters.departmentUpdate was null or undefined when calling departmentsValidateUpdateDepartment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/departments/{department_id}/validate`.replace(`{${"department_id"}}`, encodeURIComponent(String(requestParameters.departmentId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DepartmentUpdateToJSON(requestParameters.departmentUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for updating the given department, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate department update
     */
    async departmentsValidateUpdateDepartment(requestParameters: DepartmentsValidateUpdateDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.departmentsValidateUpdateDepartmentRaw(requestParameters, initOverrides);
    }

}
