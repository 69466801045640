/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CurrentArtifactVersionsAppVersion } from './CurrentArtifactVersionsAppVersion';
import {
    CurrentArtifactVersionsAppVersionFromJSON,
    CurrentArtifactVersionsAppVersionFromJSONTyped,
    CurrentArtifactVersionsAppVersionToJSON,
} from './CurrentArtifactVersionsAppVersion';
import type { LegacyVersion } from './LegacyVersion';
import {
    LegacyVersionFromJSON,
    LegacyVersionFromJSONTyped,
    LegacyVersionToJSON,
} from './LegacyVersion';
import type { Version } from './Version';
import {
    VersionFromJSON,
    VersionFromJSONTyped,
    VersionToJSON,
} from './Version';

/**
 * 
 * @export
 * @interface CurrentArtifactVersions
 */
export interface CurrentArtifactVersions {
    /**
     * 
     * @type {Version}
     * @memberof CurrentArtifactVersions
     */
    version: Version;
    /**
     * Hex-encoded SHA-512 hash of the APK.
     * @type {string}
     * @memberof CurrentArtifactVersions
     */
    appApkHash: string;
    /**
     * Hex-encoded SHA-512 hash of the vehicle device firmware image.
     * @type {string}
     * @memberof CurrentArtifactVersions
     */
    vehicleDeviceFirmwareHash: string;
    /**
     * 
     * @type {LegacyVersion}
     * @memberof CurrentArtifactVersions
     */
    motionSensorVersion?: LegacyVersion | null;
    /**
     * 
     * @type {CurrentArtifactVersionsAppVersion}
     * @memberof CurrentArtifactVersions
     */
    appVersion?: CurrentArtifactVersionsAppVersion | null;
    /**
     * 
     * @type {LegacyVersion}
     * @memberof CurrentArtifactVersions
     */
    vehicleDeviceVersion?: LegacyVersion | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentArtifactVersions
     */
    motionSensorFirmwareHash: string;
}

/**
 * Check if a given object implements the CurrentArtifactVersions interface.
 */
export function instanceOfCurrentArtifactVersions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "appApkHash" in value;
    isInstance = isInstance && "vehicleDeviceFirmwareHash" in value;
    isInstance = isInstance && "motionSensorFirmwareHash" in value;

    return isInstance;
}

export function CurrentArtifactVersionsFromJSON(json: any): CurrentArtifactVersions {
    return CurrentArtifactVersionsFromJSONTyped(json, false);
}

export function CurrentArtifactVersionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentArtifactVersions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': VersionFromJSON(json['version']),
        'appApkHash': json['appApkHash'],
        'vehicleDeviceFirmwareHash': json['vehicleDeviceFirmwareHash'],
        'motionSensorVersion': !exists(json, 'motionSensorVersion') ? undefined : LegacyVersionFromJSON(json['motionSensorVersion']),
        'appVersion': !exists(json, 'appVersion') ? undefined : CurrentArtifactVersionsAppVersionFromJSON(json['appVersion']),
        'vehicleDeviceVersion': !exists(json, 'vehicleDeviceVersion') ? undefined : LegacyVersionFromJSON(json['vehicleDeviceVersion']),
        'motionSensorFirmwareHash': json['motionSensorFirmwareHash'],
    };
}

export function CurrentArtifactVersionsToJSON(value?: CurrentArtifactVersions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': VersionToJSON(value.version),
        'appApkHash': value.appApkHash,
        'vehicleDeviceFirmwareHash': value.vehicleDeviceFirmwareHash,
        'motionSensorVersion': LegacyVersionToJSON(value.motionSensorVersion),
        'appVersion': CurrentArtifactVersionsAppVersionToJSON(value.appVersion),
        'vehicleDeviceVersion': LegacyVersionToJSON(value.vehicleDeviceVersion),
        'motionSensorFirmwareHash': value.motionSensorFirmwareHash,
    };
}

