import { component, initialize, inject } from "tsdi";
import * as Sentry from "@sentry/browser";
import * as SentryReact from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { HistoryProvider } from "../providers/history-provider";

// These variables are injected by webpack.
declare const SENTRY_DSN: string;
declare const SOFTWARE_VERSION: string;

@component
export class ServiceSentry {
    @inject private historyProvider!: HistoryProvider;

    public get enabled(): boolean {
        return Boolean(SENTRY_DSN);
    }

    @initialize protected initialize(): void {
        if (!this.enabled) {
            return;
        }
        const { location } = this.historyProvider.history;

        Sentry.init({
            dsn: SENTRY_DSN,
            release: SOFTWARE_VERSION,
            integrations: [
                new Integrations.BrowserTracing({
                    routingInstrumentation: SentryReact.reactRouterV5Instrumentation({
                        location,
                    }),
                }),
            ],
            tracesSampleRate: 1.0,
        });
    }
}
