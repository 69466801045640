/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TimeFormat {
    TWENTY_FOUR_HOUR = 'twentyFourHour',
    TWELVE_HOUR = 'twelveHour'
}


export function TimeFormatFromJSON(json: any): TimeFormat {
    return TimeFormatFromJSONTyped(json, false);
}

export function TimeFormatFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeFormat {
    return json as TimeFormat;
}

export function TimeFormatToJSON(value?: TimeFormat | null): any {
    return value as any;
}

