import { isNil } from "ramda";

export function mapSome<Input, Output>(
    fn: (arg: Input) => Output,
    arg: Input | null | undefined,
): Output | undefined {
    if (isNil(arg)) {
        return undefined;
    } else {
        return fn(arg);
    }
}
