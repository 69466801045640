import { component, inject } from "tsdi";
import {
    PageInfo,
    VehicleType,
    VehicleTypeCreate,
    VehicleTypeSortKey,
    VehicleTypeUpdate,
    VehicleTypesApi,
    VehicleTypesListVehicleTypesRequest,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { Validation } from "../../utils/validation";
import { UUID } from "../../utils/uuid";
import { runInAction } from "mobx";
import { defaultPageSize } from "../../utils/constants";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";

export type VehicleTypesQuery = Omit<VehicleTypesListVehicleTypesRequest, "page">;

export interface VehicleTypesApiResource extends ApiResource {
    entity: VehicleType;
    query: VehicleTypesQuery;
    update: VehicleTypeUpdate;
    create: VehicleTypeCreate;
    sortKey: VehicleTypeSortKey;
}

@component
export class RepositoryVehicleTypes extends ElofleetRepository<VehicleTypesApiResource> {
    @inject private readonly vehicleTypesApi!: VehicleTypesApi;

    public validation = {
        create: new Validation((vehicleTypeCreate: VehicleTypeCreate) =>
            this.vehicleTypesApi.vehicleTypesValidateCreateVehicleType({
                vehicleTypeCreate: vehicleTypeCreate,
            }),
        ),
        update: new Validation((vehicleTypeUpdate: VehicleTypeUpdate, id: UUID) =>
            this.vehicleTypesApi.vehicleTypesValidateUpdateVehicleType({
                vehicleTypeUpdate,
                id,
            }),
        ),
    };

    protected async fetchById(id: string): Promise<VehicleType | undefined> {
        return await this.vehicleTypesApi.vehicleTypesReadVehicleType({
            id,
        });
    }

    protected extractId = prop("id");

    protected async fetchByQuery(
        query: VehicleTypesQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<VehicleType>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.vehicleTypesApi.vehicleTypesListVehicleTypes({
            ...query,
            page,
        });
        return { entities };
    }

    public fetchMetadata(_: unknown): Promise<PageInfo> {
        return this.vehicleTypesApi.vehicleTypesListVehicleTypesMetadata({
            pageSize: defaultPageSize,
        });
    }

    public async create(vehicleTypeCreate: VehicleTypeCreate): Promise<VehicleType> {
        const vehicleType = await this.wrapApiCall(
            this.vehicleTypesApi.vehicleTypesCreateVehicleType({ vehicleTypeCreate }),
        );

        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(vehicleType);
        });

        return vehicleType;
    }

    public async update(id: UUID, vehicleTypeUpdate: VehicleTypeUpdate): Promise<VehicleType> {
        const vehicleType = await this.wrapApiCall(
            this.vehicleTypesApi.vehicleTypesUpdateVehicleType({ vehicleTypeUpdate, id }),
        );

        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(vehicleType);
        });

        return vehicleType;
    }

    public async delete(...vehicleTypeIds: UUID[]): Promise<void> {
        await this.wrapApiCall(
            Promise.all(
                vehicleTypeIds.map((id) =>
                    this.vehicleTypesApi.vehicleTypesDeleteVehicleType({ id }),
                ),
            ),
        );

        await this.waitForIdle();
        // This reload is necessary to reload all queries that want to display "all" entities.
        this.reloadQuery({ pageSize: defaultPageSize });
    }
}
