import * as React from "react";
import { external } from "tsdi";
import { IColumn } from "@fluentui/react";
import { makeObservable, computed } from "mobx";
import { observer } from "mobx-react";
import { inject } from "tsdi";
import { UUID } from "../../utils/uuid";
import { RepositoryShockProfiles } from "../../domain/repositories/repository-shock-profiles";
import { ShockProfile } from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { mGtoG } from "../../utils/unit-conversion";

export interface ListShockProfileItemCellProps {
    readonly shockProfileId: UUID;
    readonly column: IColumn;
}

@external
@observer
export class ListShockProfileItemCell extends React.Component<ListShockProfileItemCellProps> {
    @inject private readonly repositoryShockProfiles!: RepositoryShockProfiles;
    @inject private readonly i18n!: I18nProvider;

    constructor(props: ListShockProfileItemCellProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get shockProfile(): ShockProfile | undefined {
        return this.repositoryShockProfiles.byId(this.props.shockProfileId);
    }

    public render(): JSX.Element {
        const { column } = this.props;
        switch (column.key) {
            case "label":
                return <span>{this.shockProfile?.label ?? ""}</span>;
            case "light":
                return (
                    <span>{this.i18n.formatFloat(mGtoG(this.shockProfile?.light), 3) ?? ""}</span>
                );
            case "medium":
                return (
                    <span>{this.i18n.formatFloat(mGtoG(this.shockProfile?.medium), 3) ?? ""}</span>
                );
            case "severe":
                return (
                    <span>{this.i18n.formatFloat(mGtoG(this.shockProfile?.severe), 3) ?? ""}</span>
                );
            case "duration":
                return <span>{this.i18n.formatFloat(this.shockProfile?.duration, 0) ?? ""}</span>;
            default:
                return <></>;
        }
    }
}
