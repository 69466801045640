/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserSortKey {
    CREATED_AT = 'createdAt',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    EMAIL = 'email',
    EMPLOYEE_ID = 'employeeId',
    DEPARTMENT = 'department',
    SHIFT = 'shift',
    SITE = 'site',
    MANAGEMENT_ROLE = 'managementRole',
    FLEET_ROLE = 'fleetRole',
    JOB_TITLE = 'jobTitle',
    LICENSE_EXPIRY_DATE = 'licenseExpiryDate',
    EXPIRY_LOGOUT = 'expiryLogout'
}


export function UserSortKeyFromJSON(json: any): UserSortKey {
    return UserSortKeyFromJSONTyped(json, false);
}

export function UserSortKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSortKey {
    return json as UserSortKey;
}

export function UserSortKeyToJSON(value?: UserSortKey | null): any {
    return value as any;
}

