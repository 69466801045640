import React, { useState } from "react";
import css from "./action-config-nodes-sidebar.scss";
import { IStackTokens, IconButton, Stack } from "@fluentui/react";
import ActionConfigNode from "../molecules/action-configs/action-config-node";
import { EloshieldModule, NodeKind } from "../../api";
import { ReactFlowProvider } from "reactflow";

const stackToken: IStackTokens = { childrenGap: "3em" };

interface ActionConfigNodesSidebarProps {
    eloshieldModule?: EloshieldModule;
}

export default function ActionConfigNodesSidebar(
    props: ActionConfigNodesSidebarProps,
): JSX.Element {
    const [collapsed, setCollapsed] = useState(false);

    const onDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: string): void => {
        event.dataTransfer.setData("application/reactflow", nodeType);
        event.dataTransfer.effectAllowed = "move";
    };

    const startNodes = [
        NodeKind.ANY_IN_ZONE,
        NodeKind.DIGITAL_INPUT_STATE,
        NodeKind.IN_ERROR_MODE,
        NodeKind.MUTING,
        NodeKind.PEDESTRIANS_IN_ZONE,
        NodeKind.STATIONS_IN_ZONE,
        NodeKind.VEHICLES_IN_ZONE,
    ].map((nodeKind) => {
        return { kind: nodeKind };
    });

    const middleNodes = [
        NodeKind.AND,
        NodeKind.EQUAL,
        NodeKind.GREATER_THAN,
        NodeKind.LESS_THAN,
        NodeKind.NOT,
        NodeKind.OR,
    ].map((nodeKind) => {
        return { kind: nodeKind };
    });

    const endNodes = [
        NodeKind.DEACTIVATE_ANTENNAS,
        ...(props.eloshieldModule === EloshieldModule.STATION
            ? [NodeKind.SET_STATIONARY_RELAY]
            : []),
        ...(props.eloshieldModule === EloshieldModule.VEHICLE ? [NodeKind.SET_VEHICLE_RELAY] : []),
    ].map((nodeKind) => {
        return { kind: nodeKind };
    });

    function renderNode(nodeKind: NodeKind): JSX.Element {
        return (
            <div
                onDragStart={(event) => onDragStart(event, nodeKind)}
                draggable
                className={css.actionConfigNodesSidebar__entry}
                // We show each kind only one time in the sidebar menu. This is
                // therefore a valid key.
                key={nodeKind}
            >
                <ActionConfigNode nodeKind={nodeKind} sidebar />
            </div>
        );
    }

    return (
        <ReactFlowProvider>
            <IconButton
                iconProps={{ iconName: collapsed ? "ChevronUp" : "ChevronDown" }}
                styles={{
                    root: {
                        position: "absolute",
                        bottom: collapsed ? "20px" : "170px",
                        left: "50%",
                    },
                    icon: {
                        fontSize: "2rem",
                    },
                }}
                onClick={() => setCollapsed(!collapsed)}
            />
            {!collapsed && (
                <Stack
                    tokens={stackToken}
                    horizontal
                    className={css.actionConfigNodesSidebar__nodeList}
                >
                    {startNodes.map((node) => renderNode(node.kind))}
                    {middleNodes.map((node) => renderNode(node.kind))}
                    {endNodes.map((node) => renderNode(node.kind))}
                </Stack>
            )}
        </ReactFlowProvider>
    );
}
