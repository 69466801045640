/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EngineType } from './EngineType';
import {
    EngineTypeFromJSON,
    EngineTypeFromJSONTyped,
    EngineTypeToJSON,
} from './EngineType';
import type { ProcurementType } from './ProcurementType';
import {
    ProcurementTypeFromJSON,
    ProcurementTypeFromJSONTyped,
    ProcurementTypeToJSON,
} from './ProcurementType';

/**
 * 
 * @export
 * @interface VehicleUpdate
 */
export interface VehicleUpdate {
    /**
     * 
     * @type {string}
     * @memberof VehicleUpdate
     */
    manufacturer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleUpdate
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleUpdate
     */
    vehicleTypeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleUpdate
     */
    serialNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleUpdate
     */
    manufactureYear?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleUpdate
     */
    departmentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleUpdate
     */
    shockProfileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleUpdate
     */
    phoneImei?: string | null;
    /**
     * Only super admin users are allowed to change this value.
     * @type {number}
     * @memberof VehicleUpdate
     */
    magneticFieldThreshold?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleUpdate
     */
    preOpsChecklistId?: string | null;
    /**
     * 
     * @type {ProcurementType}
     * @memberof VehicleUpdate
     */
    procurementType?: ProcurementType | null;
    /**
     * 
     * @type {Date}
     * @memberof VehicleUpdate
     */
    purchaseDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof VehicleUpdate
     */
    leaseTermBegin?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof VehicleUpdate
     */
    leaseTermEnd?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleUpdate
     */
    initialOperatingHours?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleUpdate
     */
    idleAutoLogoutAfter?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleUpdate
     */
    maintenanceInterval?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleUpdate
     */
    maintenanceNotificationAt?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleUpdate
     */
    maintenanceLockdown?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleUpdate
     */
    checklistOverride?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleUpdate
     */
    weeklyDriveGoalHours?: number | null;
    /**
     * 
     * @type {EngineType}
     * @memberof VehicleUpdate
     */
    engineType?: EngineType | null;
}

/**
 * Check if a given object implements the VehicleUpdate interface.
 */
export function instanceOfVehicleUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleUpdateFromJSON(json: any): VehicleUpdate {
    return VehicleUpdateFromJSONTyped(json, false);
}

export function VehicleUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'vehicleTypeId': !exists(json, 'vehicleTypeId') ? undefined : json['vehicleTypeId'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'manufactureYear': !exists(json, 'manufactureYear') ? undefined : json['manufactureYear'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'shockProfileId': !exists(json, 'shockProfileId') ? undefined : json['shockProfileId'],
        'phoneImei': !exists(json, 'phoneImei') ? undefined : json['phoneImei'],
        'magneticFieldThreshold': !exists(json, 'magneticFieldThreshold') ? undefined : json['magneticFieldThreshold'],
        'preOpsChecklistId': !exists(json, 'preOpsChecklistId') ? undefined : json['preOpsChecklistId'],
        'procurementType': !exists(json, 'procurementType') ? undefined : ProcurementTypeFromJSON(json['procurementType']),
        'purchaseDate': !exists(json, 'purchaseDate') ? undefined : (json['purchaseDate'] === null ? null : new Date(json['purchaseDate'])),
        'leaseTermBegin': !exists(json, 'leaseTermBegin') ? undefined : (json['leaseTermBegin'] === null ? null : new Date(json['leaseTermBegin'])),
        'leaseTermEnd': !exists(json, 'leaseTermEnd') ? undefined : (json['leaseTermEnd'] === null ? null : new Date(json['leaseTermEnd'])),
        'initialOperatingHours': !exists(json, 'initialOperatingHours') ? undefined : json['initialOperatingHours'],
        'idleAutoLogoutAfter': !exists(json, 'idleAutoLogoutAfter') ? undefined : json['idleAutoLogoutAfter'],
        'maintenanceInterval': !exists(json, 'maintenanceInterval') ? undefined : json['maintenanceInterval'],
        'maintenanceNotificationAt': !exists(json, 'maintenanceNotificationAt') ? undefined : json['maintenanceNotificationAt'],
        'maintenanceLockdown': !exists(json, 'maintenanceLockdown') ? undefined : json['maintenanceLockdown'],
        'checklistOverride': !exists(json, 'checklistOverride') ? undefined : json['checklistOverride'],
        'weeklyDriveGoalHours': !exists(json, 'weeklyDriveGoalHours') ? undefined : json['weeklyDriveGoalHours'],
        'engineType': !exists(json, 'engineType') ? undefined : EngineTypeFromJSON(json['engineType']),
    };
}

export function VehicleUpdateToJSON(value?: VehicleUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'manufacturer': value.manufacturer,
        'model': value.model,
        'vehicleTypeId': value.vehicleTypeId,
        'serialNumber': value.serialNumber,
        'manufactureYear': value.manufactureYear,
        'departmentId': value.departmentId,
        'shockProfileId': value.shockProfileId,
        'phoneImei': value.phoneImei,
        'magneticFieldThreshold': value.magneticFieldThreshold,
        'preOpsChecklistId': value.preOpsChecklistId,
        'procurementType': ProcurementTypeToJSON(value.procurementType),
        'purchaseDate': value.purchaseDate === undefined ? undefined : (value.purchaseDate === null ? null : value.purchaseDate.toISOString()),
        'leaseTermBegin': value.leaseTermBegin === undefined ? undefined : (value.leaseTermBegin === null ? null : value.leaseTermBegin.toISOString()),
        'leaseTermEnd': value.leaseTermEnd === undefined ? undefined : (value.leaseTermEnd === null ? null : value.leaseTermEnd.toISOString()),
        'initialOperatingHours': value.initialOperatingHours,
        'idleAutoLogoutAfter': value.idleAutoLogoutAfter,
        'maintenanceInterval': value.maintenanceInterval,
        'maintenanceNotificationAt': value.maintenanceNotificationAt,
        'maintenanceLockdown': value.maintenanceLockdown,
        'checklistOverride': value.checklistOverride,
        'weeklyDriveGoalHours': value.weeklyDriveGoalHours,
        'engineType': EngineTypeToJSON(value.engineType),
    };
}

