export function userInputToNumber<T>(input: string, failureValue: T): number | T {
    if (input === "") {
        return failureValue;
    }
    const num = Number(input);
    if (isNaN(num)) {
        return failureValue;
    }
    return num;
}
