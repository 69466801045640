import * as React from "react";
import { SidebarNavigation } from "../organisms/sidebar-navigation";
import ErrorBars from "../molecules/error-bars";
import { observer } from "mobx-react";
import { external, inject } from "tsdi";
import { ServiceSidebar } from "../../domain/services/service-sidebar";
import classNames from "classnames";
import css from "./layout-default.scss";
import { ThemeProvider } from "@fluentui/react";
import { theme } from "../../utils/fluent-ui-theme";
import sizes from "../sizes.scss";
import { ElofleetMessage } from "../molecules/elofleet-message";
import { MessageBarType } from "@fluentui/react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { HelpSidebar } from "../molecules/help-sidebar";
import { HelpArticle } from "../../help-types";

export interface LayoutDefaultProps {
    readonly header?: JSX.Element;
    readonly primarySidebarContent?: JSX.Element;
    readonly secondarySidebarContent?: JSX.Element;
    readonly contentClass?: string;
    readonly suggestedHelpArticles?: HelpArticle[];
    children: React.ReactNode;
}

@observer
@external
export class LayoutDefault extends React.Component<LayoutDefaultProps> {
    @inject private readonly serviceSidebar!: ServiceSidebar;
    @inject private readonly i18n!: I18nProvider;

    public render(): JSX.Element {
        const classes = classNames({
            [css.layoutDefault]: true,
            [css["layoutDefault--primaryCollapsed"]]: this.serviceSidebar.primaryCollapsed,
            [css["layoutDefault--secondaryCollapsed"]]: this.serviceSidebar.secondaryCollapsed,
            [css["layoutDefault--secondaryCollapsed"]]: this.serviceSidebar.secondaryCollapsed,
            [css["layoutDefault--noSecondary"]]: !this.props.secondarySidebarContent,
        });

        return (
            <ThemeProvider theme={theme}>
                <div className={classes}>
                    {this.props.header}
                    <ElofleetMessage
                        localStorageKey={"layoutShowMobileMessage"}
                        type={MessageBarType.warning}
                        body={this.i18n.t("component.elofleetMessage.mobileLayout")}
                        isMobile
                    />
                    <div style={{ display: "flex", flexDirection: "row", overflow: "auto" }}>
                        <div className={css.layoutDefault__primarySidebar}>
                            {this.props.primarySidebarContent ?? <SidebarNavigation />}
                        </div>
                        <div
                            className={classNames(
                                css.layoutDefault__pageContent,
                                this.props.contentClass,
                            )}
                        >
                            <ErrorBars />
                            <div style={{ flexGrow: 1, minWidth: sizes.layoutMinWidth }}>
                                {this.props.children}
                            </div>
                        </div>
                        <div
                            style={{
                                display: this.serviceSidebar.secondaryCollapsed ? "block" : "none",
                            }}
                            className={css.layoutDefault__secondarySidebar}
                        >
                            <HelpSidebar suggestedHelpArticles={this.props.suggestedHelpArticles} />
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        );
    }
}
