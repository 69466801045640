import { runInAction } from "mobx";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    PageInfo,
    PreOpsChecklistCreate,
    PreOpsChecklistsApi,
    PreOpsChecklistsListPreOpsChecklistsRequest,
    PreOpsChecklistSortKey,
    PreOpsChecklistUpdate,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { Validation } from "../../utils/validation";
import { PreOpsChecklist } from "../../api";
import { defaultPageSize } from "../../utils/constants";

export type PreOpsChecklistQuery = Omit<PreOpsChecklistsListPreOpsChecklistsRequest, "page">;

export interface PreOpsChecklistsApiResource extends ApiResource {
    entity: PreOpsChecklist;
    query: PreOpsChecklistQuery;
    update: PreOpsChecklistUpdate;
    create: PreOpsChecklistCreate;
    sortKey: PreOpsChecklistSortKey;
}

@component
export class RepositoryPreOpsChecklists extends ElofleetRepository<PreOpsChecklistsApiResource> {
    @inject private readonly preOpsChecklistsApi!: PreOpsChecklistsApi;

    protected async fetchById(checklistId: UUID): Promise<PreOpsChecklist | undefined> {
        return await this.preOpsChecklistsApi.preOpsChecklistsReadPreOpsChecklist({
            checklistId,
        });
    }

    public validation = {
        create: new Validation((preOpsChecklistCreate: PreOpsChecklistCreate) =>
            this.preOpsChecklistsApi.preOpsChecklistsValidateCreatePreOpsChecklist({
                preOpsChecklistCreate: preOpsChecklistCreate,
            }),
        ),
        update: new Validation((preOpsChecklistUpdate: PreOpsChecklistUpdate, checklistId: UUID) =>
            this.preOpsChecklistsApi.preOpsChecklistsValidateUpdatePreOpsChecklist({
                checklistId,
                preOpsChecklistUpdate,
            }),
        ),
    };

    protected extractId = prop("id");

    protected async fetchByQuery(
        query: PreOpsChecklistQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<PreOpsChecklist>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.preOpsChecklistsApi.preOpsChecklistsListPreOpsChecklists({
            ...query,
            page,
        });
        return { entities };
    }

    public fetchMetadata(_: unknown): Promise<PageInfo> {
        return this.preOpsChecklistsApi.preOpsChecklistsListPreOpsChecklistsMetadata({
            pageSize: defaultPageSize,
        });
    }

    public async update(
        checklistId: UUID,
        preOpsChecklistUpdate: PreOpsChecklistUpdate,
    ): Promise<PreOpsChecklist> {
        const preOpsChecklist = await this.wrapApiCall(
            this.preOpsChecklistsApi.preOpsChecklistsUpdatePreOpsChecklist({
                preOpsChecklistUpdate,
                checklistId,
            }),
        );
        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(preOpsChecklist);
        });

        return preOpsChecklist;
    }

    public async create(preOpsChecklistCreate: PreOpsChecklistCreate): Promise<PreOpsChecklist> {
        const preOpsChecklist = await this.wrapApiCall(
            this.preOpsChecklistsApi.preOpsChecklistsCreatePreOpsChecklist({
                preOpsChecklistCreate,
            }),
        );
        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(preOpsChecklist);
        });

        return preOpsChecklist;
    }

    public async delete(...preOpsChecklistIds: UUID[]): Promise<void> {
        await this.wrapApiCall(
            Promise.all(
                preOpsChecklistIds.map((checklistId) =>
                    this.preOpsChecklistsApi.preOpsChecklistsDeletePreOpsChecklist({
                        checklistId,
                    }),
                ),
            ),
        );

        // This reload is necessary to reload all queries that want to display "all" entities.
        await this.waitForIdle();
        this.reloadQuery({ pageSize: defaultPageSize });
    }
}
