/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A special payload, that allows vehiles to update their own version info.
 * @export
 * @interface VehicleDeviceVersionUpdate
 */
export interface VehicleDeviceVersionUpdate {
    /**
     * 
     * @type {Date}
     * @memberof VehicleDeviceVersionUpdate
     */
    firmwareLastUpdatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof VehicleDeviceVersionUpdate
     */
    currentVersion: string;
}

/**
 * Check if a given object implements the VehicleDeviceVersionUpdate interface.
 */
export function instanceOfVehicleDeviceVersionUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firmwareLastUpdatedAt" in value;
    isInstance = isInstance && "currentVersion" in value;

    return isInstance;
}

export function VehicleDeviceVersionUpdateFromJSON(json: any): VehicleDeviceVersionUpdate {
    return VehicleDeviceVersionUpdateFromJSONTyped(json, false);
}

export function VehicleDeviceVersionUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleDeviceVersionUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firmwareLastUpdatedAt': (new Date(json['firmwareLastUpdatedAt'])),
        'currentVersion': json['currentVersion'],
    };
}

export function VehicleDeviceVersionUpdateToJSON(value?: VehicleDeviceVersionUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firmwareLastUpdatedAt': (value.firmwareLastUpdatedAt.toISOString()),
        'currentVersion': value.currentVersion,
    };
}

