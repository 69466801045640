import * as React from "react";
import { useTranslation } from "react-i18next";
import { IColumn, SelectionMode } from "@fluentui/react";
import { ColumnVisibilityMap } from "../../pages/page-column-visibility";
import { TFunction } from "i18next";
import { useState } from "react";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { ColumnVisibilityCheckbox } from "../column-visibility-checkbox";
import { LayoutList } from "../layouts/layout-list";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";

// The column visibility matrix is a single-view configuration tool used to configure
// the visibilities of all configurable columns across all views.
//
// Each row represents a field, which is displayed in at least one table, but **can** be displayed in multiple tables.
// A good example is the Vehicle list and the Maintenance list.
// Since they're both about vehicles, they share many fields.
export type ColumnVisibilityRow = {
    column: string;
    name: string;
};

export interface ListColumnVisibilityProps extends Omit<ElofleetListProps, "items" | "columns"> {
    columnVisibilityMap?: ColumnVisibilityMap;
    columnVisibilityUpdate: ColumnVisibilityMap;
    onChange: (page: string, column: string, visibility: boolean) => void;
    onSave: () => Promise<void>;
}

export function ListColumnVisibility(props: ListColumnVisibilityProps): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();

    const [backendRequestLoading, setBackendRequestLoading] = useState<boolean | undefined>(false);

    if (!props.columnVisibilityMap) {
        return <></>;
    }

    // Build up the array of table columns.
    const staticTableColumns: IColumn[] = [
        { key: "column", fieldName: "name", name: "Column", minWidth: 200 },
        {
            key: "all",
            fieldName: "all",
            name: "All",
            minWidth: 50,
            onRender: (item: ColumnVisibilityRow, _?: number, column?: IColumn): JSX.Element => (
                <ColumnVisibilityCheckbox
                    columnVisibilityMap={props.columnVisibilityMap!}
                    columnVisibilityUpdate={props.columnVisibilityUpdate}
                    onVisibilityChanged={props.onChange}
                    item={item}
                    column={column}
                    allColumn
                />
            ),
        },
    ];

    const tableColumns: IColumn[] = [];
    for (const pageKey of Object.keys(props.columnVisibilityMap)) {
        tableColumns.push({
            key: pageKey,
            name: t(`page.columnVisibility.page.${pageKey}.name`),
            minWidth: 150,
            onRender: (item: ColumnVisibilityRow, _?: number, column?: IColumn): JSX.Element => (
                <ColumnVisibilityCheckbox
                    columnVisibilityMap={props.columnVisibilityMap!}
                    columnVisibilityUpdate={props.columnVisibilityUpdate}
                    onVisibilityChanged={props.onChange}
                    item={item}
                    column={column}
                />
            ),
        });
    }
    tableColumns.sort(sortByName);

    // Build up the array of table rows.
    const tableRows: ColumnVisibilityRow[] = [];
    const columnKeys = [
        ...new Set(Object.values(props.columnVisibilityMap).flatMap((page) => Object.keys(page))),
    ];
    for (const columnKey of columnKeys) {
        tableRows.push({
            column: columnKey,
            name: t(`page.columnVisibility.column.${columnKey}.name`),
        });
    }
    tableRows.sort(sortByName);

    return (
        <LayoutList
            footer={
                <PrimaryButtonValidation
                    buttonDisabled={
                        backendRequestLoading ||
                        Object.keys(props.columnVisibilityUpdate).length === 0
                    }
                    validationLoading={backendRequestLoading}
                    text={t("page.columnVisibility.submit.text")}
                    onClickCapture={() => {
                        setBackendRequestLoading(true);
                        props.onSave().then(() => setBackendRequestLoading(false));
                    }}
                />
            }
        >
            <ElofleetList
                {...props}
                columns={[...staticTableColumns, ...tableColumns]}
                items={tableRows}
                selectionMode={SelectionMode.none}
            />
        </LayoutList>
    );
}

function sortByName(a: { name: string }, b: { name: string }): number {
    return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
}
