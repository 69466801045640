import { makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { ElofleetDialog, ElofleetDialogProps } from "../atoms/elofleet-dialog";
import { FormResetPassword } from "../molecules/form-reset-password";
import sizes from "../sizes.scss";

@external
@observer
export class ModalResetPassword extends React.Component<Omit<ElofleetDialogProps, "children">> {
    @inject private readonly i18n!: I18nProvider;

    @observable public completed = false;

    constructor(props: ElofleetDialogProps) {
        super(props);
        makeObservable(this);
    }

    public render(): JSX.Element {
        let modalContents = (
            <>
                <p style={{ padding: `0 ${sizes.formPaddingHorizontal}` }}>
                    {this.i18n.t("component.modalResetPassword.description")}
                </p>

                <FormResetPassword
                    onComplete={() => runInAction(() => (this.completed = true))}
                    onDismiss={() => this.props.onDismiss && this.props.onDismiss()}
                />
            </>
        );

        if (this.completed) {
            modalContents = (
                <p style={{ padding: `0 ${sizes.formPaddingHorizontal}` }}>
                    {this.i18n.t("component.modalResetPassword.completed")}
                </p>
            );
        }

        return (
            <ElofleetDialog
                {...this.props}
                title={this.i18n.t("component.modalResetPassword.title")}
                maxWidth="400px"
            >
                {modalContents}
            </ElofleetDialog>
        );
    }
}
