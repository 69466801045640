import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    FleetAuthenticateEventsApi,
    FleetAuthenticateEventsListFleetAuthenticatePeriodsRequest,
    FleetAuthenticatePeriod,
    FleetAuthenticatePeriodSortKey,
    PageInfo,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";

export type FleetAuthenticatePeriodQuery = Omit<
    FleetAuthenticateEventsListFleetAuthenticatePeriodsRequest,
    "page"
>;

export interface FleetAuthenticatePeriodApiResource extends ApiResource {
    entity: FleetAuthenticatePeriod;
    query: FleetAuthenticatePeriodQuery;
    update: unknown;
    create: unknown;
    sortKey: FleetAuthenticatePeriodSortKey;
}

@component
export class RepositoryFleetAuthenticatePeriods extends ElofleetRepository<FleetAuthenticatePeriodApiResource> {
    @inject private readonly fleetAuthenticateEventsApi!: FleetAuthenticateEventsApi;

    protected extractId = prop("id");

    protected async fetchByQuery(
        query: FleetAuthenticatePeriodQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<FleetAuthenticatePeriod>> {
        const page = pagination.offset / this.defaultCount;
        const entities =
            await this.fleetAuthenticateEventsApi.fleetAuthenticateEventsListFleetAuthenticatePeriods(
                {
                    ...query,
                    page,
                },
            );
        return { entities };
    }

    public fetchMetadata(query: FleetAuthenticatePeriodQuery): Promise<PageInfo> {
        return this.fleetAuthenticateEventsApi.fleetAuthenticateEventsListFleetAuthenticatePeriodsMetadata(
            query,
        );
    }

    public create(_create: never): Promise<FleetAuthenticatePeriod> {
        throw new Error("Cannot create new fleet authenticate period: Method not implemented.");
    }
    public update(_update: never): Promise<FleetAuthenticatePeriod> {
        throw new Error("Cannot update new fleet authenticate period: Method not implemented.");
    }
    public delete(..._ids: string[]): Promise<void> {
        throw new Error("Cannot delete fleet authenticate period: Method not implemented.");
    }
    protected fetchById(_id: string): Promise<FleetAuthenticatePeriod | undefined> {
        throw new Error("Cannot fetch fleet authenticate period by ID: Method not implemented.");
    }
}
