/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationEmail } from './NotificationEmail';
import {
    NotificationEmailFromJSON,
    NotificationEmailFromJSONTyped,
    NotificationEmailToJSON,
} from './NotificationEmail';

/**
 * Settings for all settings. This is a "singleton": there's only a single row on each instance. The backend does not create or delete rows, it only updates this one row.
 * @export
 * @interface Setting
 */
export interface Setting {
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Setting
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Setting
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    preOpsMessage: string;
    /**
     * 
     * @type {number}
     * @memberof Setting
     */
    preOpsHoursUntilRecheck?: number | null;
    /**
     * 
     * @type {Array<NotificationEmail>}
     * @memberof Setting
     */
    preOpsNotificationEmails: Array<NotificationEmail>;
    /**
     * 
     * @type {boolean}
     * @memberof Setting
     */
    preOpsRecheckOnDriverChange: boolean;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    impactMessage: string;
    /**
     * 
     * @type {Array<NotificationEmail>}
     * @memberof Setting
     */
    impactNotificationEmails: Array<NotificationEmail>;
    /**
     * 
     * @type {Array<NotificationEmail>}
     * @memberof Setting
     */
    maintenanceNotificationEmails: Array<NotificationEmail>;
    /**
     * 
     * @type {boolean}
     * @memberof Setting
     */
    generateDemoData: boolean;
}

/**
 * Check if a given object implements the Setting interface.
 */
export function instanceOfSetting(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "preOpsMessage" in value;
    isInstance = isInstance && "preOpsNotificationEmails" in value;
    isInstance = isInstance && "preOpsRecheckOnDriverChange" in value;
    isInstance = isInstance && "impactMessage" in value;
    isInstance = isInstance && "impactNotificationEmails" in value;
    isInstance = isInstance && "maintenanceNotificationEmails" in value;
    isInstance = isInstance && "generateDemoData" in value;

    return isInstance;
}

export function SettingFromJSON(json: any): Setting {
    return SettingFromJSONTyped(json, false);
}

export function SettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Setting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'preOpsMessage': json['preOpsMessage'],
        'preOpsHoursUntilRecheck': !exists(json, 'preOpsHoursUntilRecheck') ? undefined : json['preOpsHoursUntilRecheck'],
        'preOpsNotificationEmails': ((json['preOpsNotificationEmails'] as Array<any>).map(NotificationEmailFromJSON)),
        'preOpsRecheckOnDriverChange': json['preOpsRecheckOnDriverChange'],
        'impactMessage': json['impactMessage'],
        'impactNotificationEmails': ((json['impactNotificationEmails'] as Array<any>).map(NotificationEmailFromJSON)),
        'maintenanceNotificationEmails': ((json['maintenanceNotificationEmails'] as Array<any>).map(NotificationEmailFromJSON)),
        'generateDemoData': json['generateDemoData'],
    };
}

export function SettingToJSON(value?: Setting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'preOpsMessage': value.preOpsMessage,
        'preOpsHoursUntilRecheck': value.preOpsHoursUntilRecheck,
        'preOpsNotificationEmails': ((value.preOpsNotificationEmails as Array<any>).map(NotificationEmailToJSON)),
        'preOpsRecheckOnDriverChange': value.preOpsRecheckOnDriverChange,
        'impactMessage': value.impactMessage,
        'impactNotificationEmails': ((value.impactNotificationEmails as Array<any>).map(NotificationEmailToJSON)),
        'maintenanceNotificationEmails': ((value.maintenanceNotificationEmails as Array<any>).map(NotificationEmailToJSON)),
        'generateDemoData': value.generateDemoData,
    };
}

