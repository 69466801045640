import * as React from "react";
import { omit } from "ramda";
import { IColumn, IObjectWithKey, SelectionMode, Stack } from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { createUuid, UUID } from "../../utils/uuid";
import { ColumnVisibility, ShockEvent, ShockEventSortKey } from "../../api";
import { observer } from "mobx-react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { SortState } from "../../utils/sort-state";
import { PaginationState } from "../../utils/pagination-state";
import { LayoutList } from "../layouts/layout-list";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import {
    ShockEventsQuery,
    RepositoryShockEvents,
    ShockEventsApiResource,
} from "../../domain/repositories/repository-shock-events";
import { ListShockEventItemCell } from "../atoms/list-shock-event-item-cell";
import {
    FilterShockEventList,
    FilterShockEventListQuery,
} from "../organisms/filter-shock-event-list";
import { DatePicker } from "../organisms/date-picker";
import { endOfISOWeek, startOfDay, startOfISOWeek } from "date-fns";
import { ExportButton, ExportQuery } from "./export-button";
import { RepositoryColumnVisibility } from "../../domain/repositories/repository-column-visibility";
import { defaultPageSize } from "../../utils/constants";

export interface ListShockEventsProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly commandBar?: JSX.Element;
    readonly listStateId?: UUID;
}

interface ListShockEventItem extends IObjectWithKey {
    readonly key: UUID;
}

@external
@observer
export class ListShockEvents extends React.Component<ListShockEventsProps> {
    @inject private readonly repositoryShockEvents!: RepositoryShockEvents;
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject private readonly repositoryColumnVisibility!: RepositoryColumnVisibility;

    private _listStateId = createUuid();

    // Use the same defaults that're displayed in the DatePicker
    @observable public dateStart?: Date = startOfISOWeek(new Date());
    @observable public dateEnd?: Date = endOfISOWeek(new Date());
    @observable private filterShockEventListQuery: FilterShockEventListQuery = {
        filteredVehicles: [],
        filteredFleetUsers: [],
        filteredShockLevels: [],
        isFiltered: false,
    };

    constructor(props: ListShockEventsProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.shockEvents.initializeList(this.listStateId, {
            query: () => this.query,
        });
    }
    @computed private get listStateId(): UUID {
        return this.props.listStateId ?? this._listStateId;
    }

    @computed private get sortState(): SortState<ShockEventSortKey> {
        return this.serviceListStates.shockEvents.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<ShockEventsApiResource> {
        return this.serviceListStates.shockEvents.getPaginationState(this.listStateId);
    }

    @computed private get columnVisibility(): ColumnVisibility | undefined {
        return this.repositoryColumnVisibility.byQuery()[0];
    }

    private getDateEndForQuery(date: Date | undefined): Date | undefined {
        if (date === undefined) return date;

        // The Product Owner wants to include also date_end in the filter results.
        // Therefore we send the start of the following day to the backend.
        const endDateForQuery = startOfDay(date.valueOf());
        endDateForQuery.setDate(date.getDate() + 1);
        return endDateForQuery;
    }

    @computed private get query(): ShockEventsQuery {
        // For example if DatePicker for date_start is 01.01.2022 and DatePicker for date_end is 10.01.2022
        // It sends to backend date_start = 01.01.2022 00:00 and date_end = 11.01.2022 00:00
        // Backend returns all shock events from 01.01.2022 00:00 to 11.01.2022 00:00
        const query = {
            pageSize: defaultPageSize,
            dateStart: this.dateStart,
            dateEnd: this.getDateEndForQuery(this.dateEnd),
            ...this.sortState.query,
        } as ShockEventsQuery;
        if (this.filterShockEventListQuery === undefined) {
            return query;
        }
        if (this.filterShockEventListQuery.filteredVehicles.length > 0) {
            query.vehicleIds = this.filterShockEventListQuery.filteredVehicles;
        }
        if (this.filterShockEventListQuery.filteredFleetUsers.length > 0) {
            query.userIds = this.filterShockEventListQuery.filteredFleetUsers;
        }
        if (this.filterShockEventListQuery.filteredShockLevels.length > 0) {
            query.shockLevels = this.filterShockEventListQuery.filteredShockLevels;
        }
        return query;
    }

    @computed private get exportQuery(): ExportQuery {
        const query = Object.entries(this.query);
        return { query, basePath: "/api/v1/shock_events/export?" };
    }

    @computed private get columns(): IColumn[] {
        const columns: IColumn[] = [];

        columns.push({
            fieldName: "time",
            name: this.i18n.t("listShockEvents.column.time.name"),
            key: "time",
            minWidth: 100,
            maxWidth: 200,
        });
        columns.push({
            fieldName: "severity",
            name: this.i18n.t("listShockEvents.column.severity.name"),
            key: "severity",
            minWidth: 100,
            maxWidth: 200,
        });
        if (this.columnVisibility?.shockEventList.shockEventMotionStatus) {
            columns.push({
                fieldName: "motionStatus",
                name: this.i18n.t("listShockEvents.column.motionStatus.name"),
                key: "motionStatus",
                minWidth: 100,
                maxWidth: 200,
            });
        }
        if (this.columnVisibility?.shockEventList.userFullName) {
            columns.push({
                fieldName: "username",
                name: this.i18n.t("listShockEvents.column.username.name"),
                key: "username",
                minWidth: 100,
                maxWidth: 200,
            });
        }
        if (this.columnVisibility?.shockEventList.vehicleSerialNumber) {
            columns.push({
                fieldName: "vehicle",
                name: this.i18n.t("listShockEvents.column.vehicle.name"),
                key: "vehicle",
                minWidth: 100,
                maxWidth: 200,
            });
        }
        if (this.columnVisibility?.shockEventList.departmentLabel) {
            columns.push({
                fieldName: "department",
                name: this.i18n.t("listShockEvents.column.department.name"),
                key: "department",
                minWidth: 100,
                maxWidth: 200,
            });
        }
        if (this.columnVisibility?.shockEventList.vehicleGroupLabel) {
            columns.push({
                fieldName: "group",
                name: this.i18n.t("listShockEvents.column.group.name"),
                key: "group",
                minWidth: 150,
                maxWidth: 200,
            });
        }
        if (this.columnVisibility?.shockEventList.shockProfileLabel) {
            columns.push({
                fieldName: "shockProfile",
                name: this.i18n.t("listShockEvents.column.shockProfile.name"),
                key: "shockProfile",
                minWidth: 150,
                maxWidth: 200,
            });
        }

        return columns;
    }

    @computed private get shockEvents(): (ShockEvent | undefined)[] {
        let shockEvents: (ShockEvent | undefined)[];
        if (this.props.ids) {
            shockEvents = this.props.ids.map((id) => this.repositoryShockEvents.byId(id));
        } else
            shockEvents = this.repositoryShockEvents.byQuery(
                this.query,
                this.paginationState.pagination,
            );
        return shockEvents;
    }

    @computed private get items(): (ListShockEventItem | undefined)[] {
        return this.shockEvents.map((shockEvent) => {
            if (!shockEvent) {
                return;
            }
            return {
                key: shockEvent.id,
            };
        });
    }

    @action.bound public onDateRangeChanged(dateStart?: Date, dateEnd?: Date): void {
        this.dateStart = dateStart;
        this.dateEnd = dateEnd;
    }

    @action.bound private setShockEventListFilter(filterQuery: FilterShockEventListQuery): void {
        this.filterShockEventListQuery = {
            filteredVehicles: filterQuery.filteredVehicles,
            filteredFleetUsers: filterQuery.filteredFleetUsers,
            filteredShockLevels: filterQuery.filteredShockLevels,
            isFiltered: filterQuery.isFiltered,
        };
    }

    private renderItem(shockEventId: UUID, column: IColumn): JSX.Element {
        return <ListShockEventItemCell shockEventId={shockEventId} column={column} />;
    }

    public onFormatDate = (date?: Date): string => {
        return this.i18n.formatDateOnly(date!);
    };

    public render(): JSX.Element {
        if (!this.serviceListStates.shockEvents.isInitialized(this.listStateId)) {
            return <></>;
        }
        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(["ids"], this.props);

        return (
            <Stack>
                <Stack>
                    <LayoutList
                        paginationState={this.paginationState}
                        commandBar={
                            <Stack horizontal horizontalAlign="space-between">
                                {this.props.commandBar}
                                <ExportButton query={this.exportQuery} />
                            </Stack>
                        }
                        filter={
                            <Stack
                                horizontal
                                style={{ width: "100%" }}
                                horizontalAlign="end"
                                verticalAlign="center"
                                tokens={{ childrenGap: 20 }}
                            >
                                <FilterShockEventList
                                    setShockEventListFilter={this.setShockEventListFilter}
                                />
                                <DatePicker onDateRangeChanged={this.onDateRangeChanged} />
                            </Stack>
                        }
                    >
                        <ElofleetList
                            {...props}
                            onColumnHeaderClick={this.sortState.toggleColumn}
                            // Disable right-click to stay consistent with other lists
                            onItemContextMenu={() => {}}
                            selectionMode={SelectionMode.none}
                            onRenderItemColumn={(item: ListShockEventItem, _index, column) =>
                                this.renderItem(item.key, column!)
                            }
                            columns={this.sortState.patchColumns(this.columns)}
                            items={this.items}
                        />
                    </LayoutList>
                </Stack>
            </Stack>
        );
    }
}
