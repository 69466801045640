import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { declareRoute, routePath, RouteProps } from "../routes";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { PageHeader } from "../ui/atoms/page-header";
import { ListPreOpsChecklists } from "../ui/molecules/list-pre-ops-checklists";
import { PageContent } from "../ui/atoms/page-content";
import { Section } from "../ui/atoms/section";
import { ListPreOpsChecklistButtons } from "../ui/organisms/list-pre-ops-checklist-buttons";
import { articles } from "../help";

@external
@observer
export class PageChecklistSettings extends React.Component<RouteProps> {
    @inject protected readonly i18n!: I18nProvider;

    public render(): JSX.Element {
        return (
            <LayoutDefault
                suggestedHelpArticles={[
                    articles.adminConfigureChecklists,
                    articles.administratorFunctionalities,
                ]}
                header={<PageHeader title={this.i18n.t("page.checklistSettings.title")} />}
            >
                <PageContent>
                    <Section>
                        <ListPreOpsChecklists
                            commandBar={<ListPreOpsChecklistButtons />}
                            canDelete
                            canUpdate
                        />
                    </Section>
                </PageContent>
            </LayoutDefault>
        );
    }
}

export const routePreOpsChecklistSettings = declareRoute({
    navigation: true,
    component: PageChecklistSettings,
    icon: "ClipboardList",
    title: "page.checklistSettings.navbarEntry",
    path: routePath.checklistSettings,
    pattern: "/checklist-settings",
});
