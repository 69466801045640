/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailLoginTokenCreate
 */
export interface EmailLoginTokenCreate {
    /**
     * 
     * @type {string}
     * @memberof EmailLoginTokenCreate
     */
    email: string;
}

/**
 * Check if a given object implements the EmailLoginTokenCreate interface.
 */
export function instanceOfEmailLoginTokenCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function EmailLoginTokenCreateFromJSON(json: any): EmailLoginTokenCreate {
    return EmailLoginTokenCreateFromJSONTyped(json, false);
}

export function EmailLoginTokenCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailLoginTokenCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function EmailLoginTokenCreateToJSON(value?: EmailLoginTokenCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
    };
}

