/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  NfcToken,
  NfcTokenCreate,
  NfcTokenSortKey,
  NfcTokenUpdate,
  PageInfo,
  SortDirection,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    NfcTokenFromJSON,
    NfcTokenToJSON,
    NfcTokenCreateFromJSON,
    NfcTokenCreateToJSON,
    NfcTokenSortKeyFromJSON,
    NfcTokenSortKeyToJSON,
    NfcTokenUpdateFromJSON,
    NfcTokenUpdateToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface NfcTokensCreateNfcTokenRequest {
    nfcTokenCreate: NfcTokenCreate;
}

export interface NfcTokensDeleteNfcTokenRequest {
    nfcTokenId: string;
}

export interface NfcTokensListNfcTokensRequest {
    pageSize: number;
    search?: string | null;
    userId?: string | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: NfcTokenSortKey;
}

export interface NfcTokensListNfcTokensMetadataRequest {
    pageSize: number;
    search?: string | null;
    userId?: string | null;
}

export interface NfcTokensReadNfcTokenRequest {
    nfcTokenId: string;
}

export interface NfcTokensUpdateNfcTokenRequest {
    nfcTokenId: string;
    nfcTokenUpdate: NfcTokenUpdate;
}

export interface NfcTokensValidateCreateNfcTokenRequest {
    nfcTokenCreate: NfcTokenCreate;
}

export interface NfcTokensValidateUpdateNfcTokenRequest {
    nfcTokenId: string;
    nfcTokenUpdate: NfcTokenUpdate;
}

/**
 * NfcTokensApi - interface
 * 
 * @export
 * @interface NfcTokensApiInterface
 */
export interface NfcTokensApiInterface {
    /**
     * Create a new nfc_token.
     * @summary Create a nfc_token
     * @param {NfcTokenCreate} nfcTokenCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NfcTokensApiInterface
     */
    nfcTokensCreateNfcTokenRaw(requestParameters: NfcTokensCreateNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NfcToken>>;

    /**
     * Create a new nfc_token.
     * Create a nfc_token
     */
    nfcTokensCreateNfcToken(requestParameters: NfcTokensCreateNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NfcToken>;

    /**
     * Delete the nfc_token with the given ID.
     * @summary Delete a nfc_token
     * @param {string} nfcTokenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NfcTokensApiInterface
     */
    nfcTokensDeleteNfcTokenRaw(requestParameters: NfcTokensDeleteNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the nfc_token with the given ID.
     * Delete a nfc_token
     */
    nfcTokensDeleteNfcToken(requestParameters: NfcTokensDeleteNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Lists all the nfc_tokens present. If `search` is specified, return values similar to the `description` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * @summary List nfc_tokens
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {string} [userId] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {NfcTokenSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NfcTokensApiInterface
     */
    nfcTokensListNfcTokensRaw(requestParameters: NfcTokensListNfcTokensRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NfcToken>>>;

    /**
     * Lists all the nfc_tokens present. If `search` is specified, return values similar to the `description` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List nfc_tokens
     */
    nfcTokensListNfcTokens(requestParameters: NfcTokensListNfcTokensRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NfcToken>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary Nfc token listing metadata
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NfcTokensApiInterface
     */
    nfcTokensListNfcTokensMetadataRaw(requestParameters: NfcTokensListNfcTokensMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * Nfc token listing metadata
     */
    nfcTokensListNfcTokensMetadata(requestParameters: NfcTokensListNfcTokensMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the nfc_token specified by the given ID.
     * @summary Get a single nfc_token
     * @param {string} nfcTokenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NfcTokensApiInterface
     */
    nfcTokensReadNfcTokenRaw(requestParameters: NfcTokensReadNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NfcToken>>;

    /**
     * Returns the nfc_token specified by the given ID.
     * Get a single nfc_token
     */
    nfcTokensReadNfcToken(requestParameters: NfcTokensReadNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NfcToken>;

    /**
     * Update the nfc_token specified by the given ID.
     * @summary Update nfc_token
     * @param {string} nfcTokenId 
     * @param {NfcTokenUpdate} nfcTokenUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NfcTokensApiInterface
     */
    nfcTokensUpdateNfcTokenRaw(requestParameters: NfcTokensUpdateNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NfcToken>>;

    /**
     * Update the nfc_token specified by the given ID.
     * Update nfc_token
     */
    nfcTokensUpdateNfcToken(requestParameters: NfcTokensUpdateNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NfcToken>;

    /**
     * Validate data for a new nfc_token without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a nfc_token
     * @param {NfcTokenCreate} nfcTokenCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NfcTokensApiInterface
     */
    nfcTokensValidateCreateNfcTokenRaw(requestParameters: NfcTokensValidateCreateNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new nfc_token without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a nfc_token
     */
    nfcTokensValidateCreateNfcToken(requestParameters: NfcTokensValidateCreateNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate data for updating the given nfc_token, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate nfc_token update
     * @param {string} nfcTokenId 
     * @param {NfcTokenUpdate} nfcTokenUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NfcTokensApiInterface
     */
    nfcTokensValidateUpdateNfcTokenRaw(requestParameters: NfcTokensValidateUpdateNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for updating the given nfc_token, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate nfc_token update
     */
    nfcTokensValidateUpdateNfcToken(requestParameters: NfcTokensValidateUpdateNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class NfcTokensApi extends runtime.BaseAPI implements NfcTokensApiInterface {

    /**
     * Create a new nfc_token.
     * Create a nfc_token
     */
    async nfcTokensCreateNfcTokenRaw(requestParameters: NfcTokensCreateNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NfcToken>> {
        if (requestParameters.nfcTokenCreate === null || requestParameters.nfcTokenCreate === undefined) {
            throw new runtime.RequiredError('nfcTokenCreate','Required parameter requestParameters.nfcTokenCreate was null or undefined when calling nfcTokensCreateNfcToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nfc_tokens/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NfcTokenCreateToJSON(requestParameters.nfcTokenCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NfcTokenFromJSON(jsonValue));
    }

    /**
     * Create a new nfc_token.
     * Create a nfc_token
     */
    async nfcTokensCreateNfcToken(requestParameters: NfcTokensCreateNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NfcToken> {
        const response = await this.nfcTokensCreateNfcTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the nfc_token with the given ID.
     * Delete a nfc_token
     */
    async nfcTokensDeleteNfcTokenRaw(requestParameters: NfcTokensDeleteNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.nfcTokenId === null || requestParameters.nfcTokenId === undefined) {
            throw new runtime.RequiredError('nfcTokenId','Required parameter requestParameters.nfcTokenId was null or undefined when calling nfcTokensDeleteNfcToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nfc_tokens/{nfc_token_id}`.replace(`{${"nfc_token_id"}}`, encodeURIComponent(String(requestParameters.nfcTokenId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the nfc_token with the given ID.
     * Delete a nfc_token
     */
    async nfcTokensDeleteNfcToken(requestParameters: NfcTokensDeleteNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.nfcTokensDeleteNfcTokenRaw(requestParameters, initOverrides);
    }

    /**
     * Lists all the nfc_tokens present. If `search` is specified, return values similar to the `description` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List nfc_tokens
     */
    async nfcTokensListNfcTokensRaw(requestParameters: NfcTokensListNfcTokensRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NfcToken>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling nfcTokensListNfcTokens.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nfc_tokens/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NfcTokenFromJSON));
    }

    /**
     * Lists all the nfc_tokens present. If `search` is specified, return values similar to the `description` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List nfc_tokens
     */
    async nfcTokensListNfcTokens(requestParameters: NfcTokensListNfcTokensRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NfcToken>> {
        const response = await this.nfcTokensListNfcTokensRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * Nfc token listing metadata
     */
    async nfcTokensListNfcTokensMetadataRaw(requestParameters: NfcTokensListNfcTokensMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling nfcTokensListNfcTokensMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nfc_tokens/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * Nfc token listing metadata
     */
    async nfcTokensListNfcTokensMetadata(requestParameters: NfcTokensListNfcTokensMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.nfcTokensListNfcTokensMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the nfc_token specified by the given ID.
     * Get a single nfc_token
     */
    async nfcTokensReadNfcTokenRaw(requestParameters: NfcTokensReadNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NfcToken>> {
        if (requestParameters.nfcTokenId === null || requestParameters.nfcTokenId === undefined) {
            throw new runtime.RequiredError('nfcTokenId','Required parameter requestParameters.nfcTokenId was null or undefined when calling nfcTokensReadNfcToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nfc_tokens/{nfc_token_id}`.replace(`{${"nfc_token_id"}}`, encodeURIComponent(String(requestParameters.nfcTokenId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NfcTokenFromJSON(jsonValue));
    }

    /**
     * Returns the nfc_token specified by the given ID.
     * Get a single nfc_token
     */
    async nfcTokensReadNfcToken(requestParameters: NfcTokensReadNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NfcToken> {
        const response = await this.nfcTokensReadNfcTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the nfc_token specified by the given ID.
     * Update nfc_token
     */
    async nfcTokensUpdateNfcTokenRaw(requestParameters: NfcTokensUpdateNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NfcToken>> {
        if (requestParameters.nfcTokenId === null || requestParameters.nfcTokenId === undefined) {
            throw new runtime.RequiredError('nfcTokenId','Required parameter requestParameters.nfcTokenId was null or undefined when calling nfcTokensUpdateNfcToken.');
        }

        if (requestParameters.nfcTokenUpdate === null || requestParameters.nfcTokenUpdate === undefined) {
            throw new runtime.RequiredError('nfcTokenUpdate','Required parameter requestParameters.nfcTokenUpdate was null or undefined when calling nfcTokensUpdateNfcToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nfc_tokens/{nfc_token_id}`.replace(`{${"nfc_token_id"}}`, encodeURIComponent(String(requestParameters.nfcTokenId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: NfcTokenUpdateToJSON(requestParameters.nfcTokenUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NfcTokenFromJSON(jsonValue));
    }

    /**
     * Update the nfc_token specified by the given ID.
     * Update nfc_token
     */
    async nfcTokensUpdateNfcToken(requestParameters: NfcTokensUpdateNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NfcToken> {
        const response = await this.nfcTokensUpdateNfcTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new nfc_token without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a nfc_token
     */
    async nfcTokensValidateCreateNfcTokenRaw(requestParameters: NfcTokensValidateCreateNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.nfcTokenCreate === null || requestParameters.nfcTokenCreate === undefined) {
            throw new runtime.RequiredError('nfcTokenCreate','Required parameter requestParameters.nfcTokenCreate was null or undefined when calling nfcTokensValidateCreateNfcToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nfc_tokens/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NfcTokenCreateToJSON(requestParameters.nfcTokenCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new nfc_token without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a nfc_token
     */
    async nfcTokensValidateCreateNfcToken(requestParameters: NfcTokensValidateCreateNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.nfcTokensValidateCreateNfcTokenRaw(requestParameters, initOverrides);
    }

    /**
     * Validate data for updating the given nfc_token, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate nfc_token update
     */
    async nfcTokensValidateUpdateNfcTokenRaw(requestParameters: NfcTokensValidateUpdateNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.nfcTokenId === null || requestParameters.nfcTokenId === undefined) {
            throw new runtime.RequiredError('nfcTokenId','Required parameter requestParameters.nfcTokenId was null or undefined when calling nfcTokensValidateUpdateNfcToken.');
        }

        if (requestParameters.nfcTokenUpdate === null || requestParameters.nfcTokenUpdate === undefined) {
            throw new runtime.RequiredError('nfcTokenUpdate','Required parameter requestParameters.nfcTokenUpdate was null or undefined when calling nfcTokensValidateUpdateNfcToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nfc_tokens/{nfc_token_id}/validate`.replace(`{${"nfc_token_id"}}`, encodeURIComponent(String(requestParameters.nfcTokenId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: NfcTokenUpdateToJSON(requestParameters.nfcTokenUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for updating the given nfc_token, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate nfc_token update
     */
    async nfcTokensValidateUpdateNfcToken(requestParameters: NfcTokensValidateUpdateNfcTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.nfcTokensValidateUpdateNfcTokenRaw(requestParameters, initOverrides);
    }

}
