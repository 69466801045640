import { IColumn, IObjectWithKey, Selection } from "@fluentui/react";
import * as React from "react";
import { UUID, createUuid } from "../../utils/uuid";
import { external, initialize, inject } from "tsdi";
import { observer } from "mobx-react";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import {
    MotionSensorsApiResource,
    MotionSensorsQuery,
    RepositoryMotionSensors,
} from "../../domain/repositories/repository-motion-sensors";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { LayoutList } from "../layouts/layout-list";
import { omit } from "ramda";
import { action, computed, makeObservable } from "mobx";
import { MotionSensor, MotionSensorSortKey } from "../../api";
import { PaginationState } from "../../utils/pagination-state";
import { SelectionState } from "../../utils/selection-state";
import { defaultPageSize } from "../../utils/constants";
import { SortState } from "../../utils/sort-state";
import { ListMotionSensorItemCell } from "../atoms/list-motion-sensor-item-cell";

export interface ListMotionSensorsProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly commandBar?: JSX.Element;
    readonly listStateId?: UUID;
}

interface ListMotionSensorItem extends IObjectWithKey {
    readonly key: UUID;
}

@external
@observer
export class ListMotionSensors extends React.Component<ListMotionSensorsProps> {
    @inject private readonly repositoryMotionSensors!: RepositoryMotionSensors;
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;

    private _listStateId = createUuid();

    private selection = new Selection({
        onSelectionChanged: () => this.updateSelection(),
    });

    constructor(props: ListMotionSensorsProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.motionSensors.initializeList(this.listStateId, {
            query: () => this.query,
        });
    }

    @computed private get listStateId(): UUID {
        return this.props.listStateId ?? this._listStateId;
    }

    @computed private get sortState(): SortState<MotionSensorSortKey> {
        return this.serviceListStates.motionSensors.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<MotionSensorsApiResource> {
        return this.serviceListStates.motionSensors.getPaginationState(this.listStateId);
    }

    @computed private get selectionState(): SelectionState {
        return this.serviceListStates.motionSensors.getSelectionState(this.listStateId);
    }

    @computed private get query(): MotionSensorsQuery {
        return { pageSize: defaultPageSize, ...this.sortState.query };
    }

    @computed private get motionSensors(): (MotionSensor | undefined)[] {
        return this.repositoryMotionSensors.byQuery(this.query, this.paginationState.pagination);
    }

    @computed private get items(): (ListMotionSensorItem | undefined)[] {
        return this.motionSensors.map((motionSensor) => {
            if (!motionSensor) {
                return;
            }

            return {
                key: motionSensor.id,
            };
        });
    }

    private renderItem(motionSensorId: UUID, column: IColumn): JSX.Element {
        return <ListMotionSensorItemCell motionSensorId={motionSensorId} column={column} />;
    }

    @computed private get columns(): IColumn[] {
        const columns: IColumn[] = [
            {
                name: this.i18n.t("listMotionSensors.column.vehicleDeviceSerialNumber.name"),
                key: "vehicleDeviceSerialNumber",
                minWidth: 100,
                maxWidth: 200,
            },
            {
                name: this.i18n.t("listMotionSensors.column.firmwareLastUpdatedAt.name"),
                key: "firmwareLastUpdatedAt",
                minWidth: 100,
                maxWidth: 200,
            },
            {
                name: this.i18n.t("listMotionSensors.column.currentFirmwareVersion.name"),
                key: "currentFirmwareVersion",
                minWidth: 100,
                maxWidth: 200,
            },
        ];

        return columns;
    }

    @action.bound private updateSelection(): void {
        // Update the listState's selectionState to the new selected keys.
        this.selectionState.updateSelection(
            this.selection.getSelection().map((item) => item.key as UUID),
        );
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.motionSensors.isInitialized(this.listStateId)) {
            return <></>;
        }
        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(["ids"], this.props);
        return (
            <LayoutList paginationState={this.paginationState} commandBar={this.props.commandBar}>
                <ElofleetList
                    {...props}
                    // Disable right-click to stay consistent with other lists
                    onItemContextMenu={() => {}}
                    selection={this.selection}
                    onRenderItemColumn={(item: ListMotionSensorItem, _index, column) =>
                        this.renderItem(item.key, column!)
                    }
                    onColumnHeaderClick={this.sortState.toggleColumn}
                    columns={this.sortState.patchColumns(this.columns)}
                    items={this.items}
                />
            </LayoutList>
        );
    }
}
