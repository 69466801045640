import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    VehicleMovementPeriod,
    VehicleMovementPeriodSortKey,
    PageInfo,
    VehicleMovementsListVehicleMovementPeriodsRequest,
    VehicleMovementsApi,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";

export type VehicleMovementPeriodQuery = Omit<
    VehicleMovementsListVehicleMovementPeriodsRequest,
    "page"
>;

export interface VehicleMovementPeriodApiResource extends ApiResource {
    entity: VehicleMovementPeriod;
    query: VehicleMovementPeriodQuery;
    update: unknown;
    create: unknown;
    sortKey: VehicleMovementPeriodSortKey;
}

@component
export class RepositoryVehicleMovementPeriods extends ElofleetRepository<VehicleMovementPeriodApiResource> {
    @inject private readonly vehicleMovementsApi!: VehicleMovementsApi;

    protected extractId = prop("id");

    protected async fetchByQuery(
        query: VehicleMovementPeriodQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<VehicleMovementPeriod>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.vehicleMovementsApi.vehicleMovementsListVehicleMovementPeriods({
            ...query,
            page,
        });
        return { entities };
    }

    public fetchMetadata(query: VehicleMovementPeriodQuery): Promise<PageInfo> {
        return this.vehicleMovementsApi.vehicleMovementsListVehicleMovementPeriodsMetadata(query);
    }

    public create(_create: never): Promise<VehicleMovementPeriod> {
        throw new Error("Cannot create new vehicle movement period: Method not implemented.");
    }
    public update(_update: never): Promise<VehicleMovementPeriod> {
        throw new Error("Cannot update new vehicle movement period: Method not implemented.");
    }
    public delete(..._ids: string[]): Promise<void> {
        throw new Error("Cannot delete vehicle movement period: Method not implemented.");
    }
    protected fetchById(_id: string): Promise<VehicleMovementPeriod | undefined> {
        throw new Error("Cannot fetch vehicle movement period by ID: Method not implemented.");
    }
}
