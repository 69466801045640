/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MultiUserGroupAssignmentCreate
 */
export interface MultiUserGroupAssignmentCreate {
    /**
     * 
     * @type {Array<string>}
     * @memberof MultiUserGroupAssignmentCreate
     */
    userGroupIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MultiUserGroupAssignmentCreate
     */
    userIds: Array<string>;
}

/**
 * Check if a given object implements the MultiUserGroupAssignmentCreate interface.
 */
export function instanceOfMultiUserGroupAssignmentCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userGroupIds" in value;
    isInstance = isInstance && "userIds" in value;

    return isInstance;
}

export function MultiUserGroupAssignmentCreateFromJSON(json: any): MultiUserGroupAssignmentCreate {
    return MultiUserGroupAssignmentCreateFromJSONTyped(json, false);
}

export function MultiUserGroupAssignmentCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultiUserGroupAssignmentCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userGroupIds': json['userGroupIds'],
        'userIds': json['userIds'],
    };
}

export function MultiUserGroupAssignmentCreateToJSON(value?: MultiUserGroupAssignmentCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userGroupIds': value.userGroupIds,
        'userIds': value.userIds,
    };
}

