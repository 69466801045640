import * as React from "react";
import { observer } from "mobx-react";
import {
    Stack,
    DefaultButton,
    TextField,
    Separator,
    IDropdownOption,
    Dropdown,
    IconButton,
    ActionButton,
} from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { createUuid, UUID } from "../../utils/uuid";
import {
    PreOpsChecklist,
    PreOpsChecklistUpdate,
    PreOpsQuestion,
    PreOpsQuestionCreate,
    PreOpsQuestionSortKey,
    PreOpsQuestionUpdate,
} from "../../api";
import { doubleBindString } from "../../utils/double-bind";
import { ValidationFieldState } from "../../utils/validation";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { RepositoryPreOpsChecklists } from "../../domain/repositories/repository-pre-ops-checklists";
import {
    PreOpsQuestionsQuery,
    RepositoryPreOpsQuestions,
} from "../../domain/repositories/repository-pre-ops-questions";
import { FormUpdateProps } from "../../utils/form-update-props";
import { pick } from "ramda";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";
import { defaultPageSize } from "../../utils/constants";
import { SortState } from "../../utils/sort-state";

export interface FormUpdatePreOpsChecklistProps extends FormUpdateProps<PreOpsChecklist> {
    sortState: SortState<PreOpsQuestionSortKey>;
}

@observer
@external
export class FormUpdatePreOpsChecklist extends React.Component<FormUpdatePreOpsChecklistProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryPreOpsChecklists!: RepositoryPreOpsChecklists;
    @inject private readonly repositoryPreOpsQuestions!: RepositoryPreOpsQuestions;

    // Validation ID for the actual checklist entity.
    private validationId = createUuid();
    // Contains the validation IDs for all existing questions.
    // Each question has their own validation state.
    private questionValidationIds: {
        [questionId: string]: UUID;
    } = {};
    @observable private questionsToDelete: PreOpsQuestion[] = [];
    // Contains a list of validationIds and create structs for new questions.
    // Each question has their own validation state.
    @observable private questionsToAdd: [UUID, PreOpsQuestionCreate][] = [];

    constructor(props: FormUpdatePreOpsChecklistProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositoryPreOpsChecklists.validation.update.updateModel(
            this.validationId,
            this.preOpsChecklistUpdate,
        );
    }

    private triggerQuestionCreateValidation(
        validationId: UUID,
        create: PreOpsQuestionCreate,
    ): void {
        this.repositoryPreOpsQuestions.validation.create.updateModel(validationId, create);
    }

    private triggerQuestionUpdateValidation(
        validationId: UUID,
        update: PreOpsQuestionUpdate,
    ): void {
        this.repositoryPreOpsQuestions.validation.update.updateModel(validationId, update);
    }

    @initialize protected initialize(): void {
        if (!this.props || !this.props.id) {
            return;
        }

        this.repositoryPreOpsChecklists.validation.update.initializeModel(
            this.validationId,
            this.preOpsChecklistUpdate,
            this.props.id,
        );
    }

    @computed private get preOpsChecklistUpdate(): PreOpsChecklistUpdate {
        const preOpsChecklistUpdate: PreOpsChecklistUpdate = pick(
            ["label"],
            this.preOpsChecklist || {},
        );

        return preOpsChecklistUpdate;
    }

    @computed private get preOpsChecklist(): PreOpsChecklist | undefined {
        if (!this.props || !this.props.id) {
            return undefined;
        }

        return this.repositoryPreOpsChecklists.mutableCopyById(
            FormUpdatePreOpsChecklist.name,
            this.props.id,
        );
    }

    /**
     * Query all questions for the current checklist and create mutable copies for them.
     */
    @computed private get preOpsQuestions(): PreOpsQuestion[] {
        if (!this.props.id) {
            return [];
        }

        const questions = this.repositoryPreOpsQuestions.byQuery({
            pageSize: defaultPageSize,
            checklistId: this.props.id,
            ...this.props.sortState.query,
        } as PreOpsQuestionsQuery);
        if (!questions) {
            return [];
        }

        // Create new validation IDs for questions.
        for (const question of questions) {
            // Skip this if there already is a validation id.
            if (!(question.id in this.questionValidationIds)) {
                const validationId = createUuid();
                this.repositoryPreOpsQuestions.validation.update.initializeModel(
                    validationId,
                    question,
                    question.id,
                );
                this.questionValidationIds[question.id] = validationId;
            }
        }

        return questions.map(
            (question) =>
                this.repositoryPreOpsQuestions.mutableCopyById(
                    FormUpdatePreOpsChecklist.name,
                    question.id,
                )!,
        );
    }

    @action.bound private changeQuestion(index: number, text?: string | undefined): void {
        text = text ?? "";
        const question = this.preOpsQuestions[index];
        question.body = text;

        // Trigger update validation for this question
        this.triggerQuestionUpdateValidation(this.questionValidationIds[question.id], question);
    }

    @action.bound private changeNewQuestion(index: number, text?: string | undefined): void {
        text = text ?? "";
        this.questionsToAdd[index][1].body = text;

        // Trigger creation validation for this question
        this.triggerQuestionCreateValidation(
            this.questionsToAdd[index][0],
            this.questionsToAdd[index][1],
        );
    }

    /**
     * Schedule a question for later deletion.
     * It'll be deleted once the `save` button is clicked.
     */
    @action.bound private deleteQuestion(index: number): void {
        this.questionsToDelete.push(this.preOpsQuestions[index]);
    }

    /**
     * New questions aren't yet created, simply remove them from the new list.
     */
    @action.bound private deleteNewQuestion(index: number): void {
        this.questionsToAdd.splice(index, 1);
    }

    @action.bound private addQuestion(): void {
        const validationId = createUuid();
        const create: PreOpsQuestionCreate = {
            body: "",
            checklistId: this.props.id,
        };

        this.repositoryPreOpsQuestions.validation.create.initializeModel(validationId, create);
        this.questionsToAdd.push([validationId, create]);
    }

    @action.bound
    private async updatePreOpsQuestions(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const preOpsChecklist = await this.repositoryPreOpsChecklists.update(
            this.props.id,
            this.preOpsChecklistUpdate,
        );
        // At first, delete all questions that were deleted by the user.
        for (const question of this.questionsToDelete) {
            await this.repositoryPreOpsQuestions.delete(question.id);
        }

        // Create all new questions.
        for (const questionCreate of this.questionsToAdd) {
            await this.repositoryPreOpsQuestions.create(questionCreate[1]);
        }

        // Update the texts of all old questions.
        for (const question of this.preOpsQuestions) {
            if (this.questionsToDelete.includes(question)) {
                continue;
            }
            await this.repositoryPreOpsQuestions.update(
                question.id,
                pick(["body"], question) as PreOpsQuestionUpdate,
            );
        }

        this.questionsToAdd = [];
        this.questionsToDelete = [];

        await this.repositoryPreOpsQuestions.reloadQuery({
            pageSize: defaultPageSize,
            checklistId: this.props.id,
            ...this.props.sortState.query,
        });

        if (this.props.onUpdate) {
            this.props.onUpdate(preOpsChecklist);
        }
    }

    /**
     * Compile the JSX for the question list of the form.
     */
    @computed private get renderQuestions(): JSX.Element[] {
        if (!this.preOpsQuestions) {
            return [];
        }

        let index = 1;
        const questions: JSX.Element[] = [];
        this.preOpsQuestions.map((question, internal_index) => {
            // Don't display questions that're scheduled for deletion
            if (this.questionsToDelete.includes(question)) {
                return;
            }

            questions.push(this.renderQuestion(index, question.body, false, internal_index));

            index = index + 1;
        });

        // Show the questions that're supposed to be added.
        this.questionsToAdd.forEach((question, new_index) => {
            questions.push(this.renderQuestion(index, question[1].body, true, new_index));

            index = index + 1;
        });

        return questions;
    }

    /**
     * Compile the JSX for a single question.
     */
    private renderQuestion(
        index: number,
        text: string,
        newQuestion: boolean,
        internal_index: number,
    ): JSX.Element {
        // The options for responses are currently hard coded and not editable.
        const responseOptions: IDropdownOption[] = [
            {
                key: "good",
                text: this.i18n.t("formUpdatePreOpsChecklist.responseOptions.good"),
                disabled: true,
            },
            {
                key: "ok",
                text: this.i18n.t("formUpdatePreOpsChecklist.responseOptions.ok"),
                disabled: true,
            },
            {
                key: "critical",
                text: this.i18n.t("formUpdatePreOpsChecklist.responseOptions.critical"),
                disabled: true,
            },
        ];

        // Only add labels to the very first question.
        let questionLabel = undefined;
        let responseOptionsLabel = undefined;
        let required = false;
        let canDeleteFirst = true;
        if (index === 1) {
            questionLabel = this.i18n.t("formUpdatePreOpsChecklist.questions.label");
            responseOptionsLabel = this.i18n.t("formUpdatePreOpsChecklist.responseOptions.label");
            // Only show the "required" marker for the first question
            required = true;

            // Don't make the first question deletable, if it's the only one.
            const totalQuestions =
                this.questionsToAdd.length +
                this.preOpsQuestions.length -
                this.questionsToDelete.length;
            if (totalQuestions === 1) {
                canDeleteFirst = false;
            }
        }

        // We use different update functions for new and existing questions.
        let questionUpdate = (
            _: React.FormEvent<HTMLElement>,
            newValue?: string | undefined,
        ): void => {
            this.changeQuestion(internal_index, newValue);
        };
        if (newQuestion) {
            questionUpdate = (_: React.FormEvent<HTMLElement>, newValue?: string | undefined) => {
                this.changeNewQuestion(internal_index, newValue);
            };
        }

        // We use different delete functions for new and existing questions.
        let questionDelete = (_: React.MouseEvent<HTMLButtonElement>): void =>
            this.deleteQuestion(internal_index);
        if (newQuestion) {
            questionDelete = (_: React.MouseEvent<HTMLButtonElement>) =>
                this.deleteNewQuestion(internal_index);
        }

        // We use different validation functions for new and existing questions.
        let validateQuestion = (field: string): ValidationFieldState => {
            return this.repositoryPreOpsQuestions.validation.update.getFieldValidationState(
                this.questionValidationIds[this.preOpsQuestions[internal_index].id],
                field as keyof PreOpsQuestionUpdate,
            );
        };
        if (newQuestion) {
            validateQuestion = (field: string): ValidationFieldState => {
                return this.repositoryPreOpsQuestions.validation.create.getFieldValidationState(
                    this.questionsToAdd[internal_index][0],
                    field as keyof PreOpsQuestionCreate,
                );
            };
        }

        return (
            <Stack.Item key={index}>
                <Stack
                    horizontal
                    tokens={{
                        childrenGap: "2rem",
                    }}
                >
                    <Stack.Item
                        styles={{
                            root: {
                                minWidth: "500px",
                            },
                        }}
                    >
                        <TextField
                            autoComplete="off"
                            prefix={`${index}.`}
                            required={required}
                            value={text}
                            label={questionLabel}
                            onChange={questionUpdate}
                            errorMessage={this.i18n.formatFieldValidationState(
                                validateQuestion("body"),
                            )}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Dropdown
                            placeholder={this.i18n.t(
                                "formUpdatePreOpsChecklist.responseOptions.placeholder",
                            )}
                            label={responseOptionsLabel}
                            options={responseOptions}
                            multiSelect
                        />
                    </Stack.Item>
                    <Stack.Item align="end" style={{ marginLeft: "1rem" }}>
                        {canDeleteFirst && (
                            <IconButton
                                iconProps={{ iconName: "Trash" }}
                                onClick={questionDelete}
                            />
                        )}
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        );
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formUpdatePreOpsChecklist.submit.text")}
                validation={this.repositoryPreOpsChecklists.validation.update}
                validationId={this.validationId}
            />
        );
        const questions = this.renderQuestions;
        return (
            <form onSubmit={this.updatePreOpsQuestions}>
                <Stack
                    horizontal
                    horizontalAlign="space-between"
                    tokens={{
                        childrenGap: "2rem",
                        padding: `0px ${sizes.formPaddingHorizontal}`,
                    }}
                >
                    <Stack
                        tokens={{
                            childrenGap: "1em",
                        }}
                        styles={{
                            root: {
                                minWidth: "500px",
                            },
                        }}
                    >
                        <TextField
                            label={this.i18n.t("formUpdatePreOpsChecklist.label.label")}
                            {...doubleBindString(this.preOpsChecklist!, "label", () =>
                                this.triggerValidation(),
                            )}
                            required
                            errorMessage={this.i18n.formatFieldValidationState(
                                this.repositoryPreOpsChecklists.validation.update.getFieldValidationState(
                                    this.validationId,
                                    "label",
                                ),
                            )}
                        />
                    </Stack>
                    <Stack verticalAlign="end">
                        <Stack.Item
                            styles={{
                                root: {
                                    minWidth: "300px",
                                },
                            }}
                        ></Stack.Item>
                    </Stack>
                </Stack>
                <Separator />
                <Stack
                    tokens={{
                        childrenGap: "1em",
                        padding: `0px ${sizes.formPaddingHorizontal}`,
                    }}
                    styles={{
                        root: {
                            maxHeight: "50vh",
                            overflow: "auto",
                        },
                    }}
                >
                    {questions}
                    <Stack.Item>
                        <ActionButton
                            iconProps={{ iconName: "Add" }}
                            text={this.i18n.t("formUpdatePreOpsChecklist.addQuestion.text")}
                            onClick={this.addQuestion}
                        />
                    </Stack.Item>
                </Stack>
                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            label={this.i18n.t("formUpdatePreOpsChecklist.cancel.label")}
                            text={this.i18n.t("formUpdatePreOpsChecklist.cancel.text")}
                            onClick={this.props.onDialogCancel}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
            </form>
        );
    }
}
