import { SpinButton } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { NodeProps } from "reactflow";
import ActionConfigNode from "./action-config-node";
import { LessThanData } from "../../../api";

export default function LessThan(
    props: NodeProps<LessThanData & { invalid?: boolean; validationErrorMessage?: string }>,
): JSX.Element {
    const [number, setNumber] = useState(props.data.number ?? 1);

    // We have to set the data to prevent undefined values
    useEffect(() => {
        props.data.number = number;
    }, [props.data, number]);

    function onSpinButtonChange(
        _e: React.SyntheticEvent<HTMLElement, Event>,
        newValue?: string | undefined,
    ): void {
        if (!newValue) {
            return;
        }
        const newNumber = parseInt(newValue);
        setNumber(newNumber);
        props.data.number = newNumber;
    }

    return (
        <ActionConfigNode nodeKind={props.type} {...props.data}>
            <SpinButton
                className={"nodrag"}
                value={number.toString()}
                onChange={onSpinButtonChange}
                min={1}
                max={100}
            />
        </ActionConfigNode>
    );
}
