import * as React from "react";
import { computed, makeObservable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { createUuid, UUID } from "../../utils/uuid";
import { PreOpsChecklist } from "../../api";
import { observer } from "mobx-react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { PaginationState } from "../../utils/pagination-state";
import { ServiceListStates } from "../../domain/services/service-list-states";
import {
    RepositoryPreOpsChecklists,
    PreOpsChecklistQuery,
    PreOpsChecklistsApiResource,
} from "../../domain/repositories/repository-pre-ops-checklists";
import { ElofleetListProps } from "../atoms/elofleet-list";
import { SectionPreOpsChecklistSettings } from "./section-pre-ops-checklist-settings";
import css from "./list-checklists.scss";
import { LayoutList } from "../layouts/layout-list";
import { defaultPageSize } from "../../utils/constants";

export interface ListPreOpsChecklistsProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly commandBar?: JSX.Element;
    readonly canDelete?: boolean;
    readonly canUpdate?: boolean;
}

@external
@observer
export class ListPreOpsChecklists extends React.Component<ListPreOpsChecklistsProps> {
    @inject private readonly repositoryPreOpsChecklists!: RepositoryPreOpsChecklists;
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;

    private _listStateId = createUuid();

    constructor(props: ListPreOpsChecklistsProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.preOpsChecklists.initializeList(this._listStateId, {
            query: () => this.query,
        });
    }

    @computed private get paginationState(): PaginationState<PreOpsChecklistsApiResource> {
        return this.serviceListStates.preOpsChecklists.getPaginationState(this._listStateId);
    }

    @computed private get query(): PreOpsChecklistQuery {
        return { pageSize: defaultPageSize };
    }

    @computed private get preOpsChecklists(): PreOpsChecklist[] {
        if (this.props.ids) {
            return this.props.ids
                .map((id) => this.repositoryPreOpsChecklists.byId(id))
                .filter((checklist) => Boolean(checklist)) as PreOpsChecklist[];
        }
        return this.repositoryPreOpsChecklists.byQuery(this.query, this.paginationState.pagination);
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.preOpsChecklists.isInitialized(this._listStateId)) {
            return <></>;
        }
        return (
            <LayoutList
                paginationState={this.paginationState}
                commandBar={this.props.commandBar ? this.props.commandBar : undefined}
                title={this.i18n.t("listPreOpsChecklist.title")}
            >
                <div>
                    {this.preOpsChecklists.map(({ id }) => (
                        <SectionPreOpsChecklistSettings
                            className={css.listChecklists__checklist}
                            id={id}
                            key={id}
                            canDelete={this.props.canDelete}
                            canUpdate={this.props.canUpdate}
                        />
                    ))}
                </div>
            </LayoutList>
        );
    }
}
