import * as React from "react";
import { pick } from "ramda";
import { observer } from "mobx-react";
import { Stack, TextField, DefaultButton } from "@fluentui/react";
import { action, computed, makeObservable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryShifts } from "../../domain/repositories/repository-shifts";
import { ShiftUpdate } from "../../api";
import { doubleBindString } from "../../utils/double-bind";
import { Shift } from "../../api";
import { createUuid } from "../../utils/uuid";
import { FormUpdateProps } from "../../utils/form-update-props";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";

export interface FormUpdateShiftProps extends FormUpdateProps<Shift> {}

@observer
@external
export class FormUpdateShift extends React.Component<FormUpdateShiftProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryShifts!: RepositoryShifts;

    private validationId = createUuid();

    constructor(props: FormUpdateShiftProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositoryShifts.validation.update.updateModel(this.validationId, this.shiftUpdate);
    }

    @initialize protected initialize(): void {
        this.repositoryShifts.validation.update.initializeModel(
            this.validationId,
            this.shiftUpdate,
            this.props.id,
        );
    }

    public componentWillUnmount(): void {
        this.repositoryShifts.discardMutableCopy(FormUpdateShift.name, this.props.id);
    }

    @computed private get shift(): Shift | undefined {
        return this.repositoryShifts.mutableCopyById(FormUpdateShift.name, this.props.id);
    }

    @computed private get shiftUpdate(): ShiftUpdate {
        return pick(["label"], this.shift);
    }

    @action.bound
    private async updateShift(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const shift = await this.repositoryShifts.update(this.props.id, this.shiftUpdate);
        if (this.props.onUpdate) {
            this.props.onUpdate(shift);
        }
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formUpdateShift.submit.text")}
                validation={this.repositoryShifts.validation.update}
                validationId={this.validationId}
            />
        );
        return (
            <form onSubmit={this.updateShift}>
                <Stack tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                    <TextField
                        label={this.i18n.t("formUpdateShift.label.label")}
                        disabled={!this.shift}
                        {...doubleBindString(this.shift!, "label", () => this.triggerValidation())}
                        required
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryShifts.validation.update.getFieldValidationState(
                                this.validationId,
                                "label",
                            ),
                        )}
                    />
                </Stack>
                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            label={this.i18n.t("formUpdateShift.cancel.label")}
                            text={this.i18n.t("formUpdateShift.cancel.text")}
                            onClick={this.props.onDialogCancel}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
            </form>
        );
    }
}
