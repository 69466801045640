import { runInAction } from "mobx";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    FileType,
    PageInfo,
    UserCreate,
    UsersApi,
    UsersListUsersRequest,
    UserUpdate,
    User,
    UserSortKey,
    FilesApi,
    LoggedInUserUpdate,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { Validation } from "../../utils/validation";
import { ServiceFile } from "../services/service-file";
import { defaultPageSize } from "../../utils/constants";

export type UsersQuery = Omit<UsersListUsersRequest, "page">;

export interface UsersApiResource extends ApiResource {
    entity: User;
    query: UsersQuery;
    update: UserUpdate;
    create: UserCreate;
    sortKey: UserSortKey;
}

@component
export class RepositoryUsers extends ElofleetRepository<UsersApiResource> {
    @inject private readonly usersApi!: UsersApi;
    @inject private readonly filesApi!: FilesApi;
    @inject private readonly serviceFile!: ServiceFile;

    private metadataRequest: Promise<PageInfo> | undefined;

    public validation = {
        create: new Validation((userCreate: UserCreate) =>
            this.usersApi.usersValidateCreateUser({ userCreate }),
        ),
        update: new Validation((userUpdate: UserUpdate, userId: UUID) =>
            this.usersApi.usersValidateUpdateUser({ userUpdate, userId }),
        ),
        updateLoggedInUser: new Validation((loggedInUserUpdate: LoggedInUserUpdate) =>
            this.usersApi.usersValidateUpdateLoggedInUser({ loggedInUserUpdate }),
        ),
    };

    protected async fetchById(userId: UUID): Promise<User | undefined> {
        return await this.usersApi.usersReadUser({ userId });
    }

    protected extractId = prop("id");

    protected async fetchByQuery(
        query: UsersQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<User>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.usersApi.usersListUsers({
            ...query,
            page,
        });
        return { entities };
    }

    public async waitForIdle(): Promise<void> {
        await Promise.all([super.waitForIdle(), this.metadataRequest]);
    }

    public fetchMetadata(query: UsersQuery): Promise<PageInfo> {
        this.metadataRequest = this.usersApi.usersListUsersMetadata(query);
        return this.metadataRequest;
    }

    public async update(userId: UUID, userUpdate: UserUpdate, file?: Uint8Array): Promise<User> {
        const user = await this.wrapApiCall(this.usersApi.usersUpdateUser({ userUpdate, userId }));

        if (file) {
            try {
                const imageId = await this.serviceFile.createImageFile(
                    user.id,
                    FileType.USER_IMAGE,
                    file,
                );
                if (imageId) {
                    user.imageId = imageId;
                }
            } catch (error) {
                this.handleError(error);
            }
        } else if (user.imageId) {
            // If the user has an image set, delete it
            await this.filesApi.filesDeleteFile({ fileId: user.imageId });
            user.imageId = undefined;
        }

        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(user);
        });

        return user;
    }

    public async updateLoggedInUser(loggedInUserUpdate: LoggedInUserUpdate): Promise<User> {
        const user = await this.wrapApiCall(
            this.usersApi.usersUpdateLoggedInUser({ loggedInUserUpdate }),
        );

        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(user);
        });

        return user;
    }

    public async create(userCreate: UserCreate, file?: Uint8Array): Promise<User> {
        const user = await this.wrapApiCall(this.usersApi.usersCreateUser({ userCreate }));
        if (file) {
            try {
                const imageId = await this.serviceFile.createImageFile(
                    user.id,
                    FileType.USER_IMAGE,
                    file,
                );
                if (imageId) {
                    user.imageId = imageId;
                }
            } catch (error) {
                this.handleError(error);
            }
        }
        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that might want
            // to display the user.
            this.stateByQuery.reset();
            // If we don't trigger a query to be loaded after resetting the
            // state mobx seems to get into a confused state and the tests
            // hang.
            this.byQuery({ pageSize: defaultPageSize });
        });

        return user;
    }

    public async delete(...userIds: UUID[]): Promise<void> {
        await this.wrapApiCall(
            Promise.all(userIds.map((userId) => this.usersApi.usersDeleteUser({ userId }))),
        );

        runInAction(() => {
            this.stateById.reset();
            this.stateByQuery.reset();
        });
    }

    public async getLastDriverByVehicle(vehicleId: UUID): Promise<User> {
        const lastDriver = await this.usersApi.usersReadLastDriverByVehicle({
            vehicleId: vehicleId,
        });
        return lastDriver;
    }
}
