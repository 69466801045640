import { action, makeObservable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { UUID } from "../../utils/uuid";
import { ListCommandButtons, ListCommandButtonsProps } from "./list-command-buttons";
import { FormCreateDepartment } from "../molecules/form-create-department";
import { FormUpdateDepartment } from "../molecules/form-update-department";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { I18nProvider } from "../../domain/providers/i18n-provider";

export type ListCommandButtonsDepartmentsProps = Omit<
    ListCommandButtonsProps<RepositoryDepartments>,
    "list" | "createForm" | "listState"
>;

@external
@observer
export class ListCommandButtonsDepartments extends React.Component<ListCommandButtonsDepartmentsProps> {
    @inject private readonly repositoryDepartments!: RepositoryDepartments;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject protected readonly i18n!: I18nProvider;

    constructor(props: ListCommandButtonsDepartmentsProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private async deleteDepartments(ids: UUID[]): Promise<void> {
        await this.repositoryDepartments.delete(...ids);
        if (this.props.onDelete) {
            this.props.onDelete(ids);
        }
    }
    public render(): JSX.Element {
        return (
            <ListCommandButtons
                updateForm={FormUpdateDepartment}
                createForm={FormCreateDepartment}
                listState={this.serviceListStates.departments}
                createButtonText={this.i18n.t("page.systemWide.department.createButton.text")}
                createDialogTitle={this.i18n.t("page.systemWide.department.createDialog.title")}
                updateDialogTitle={this.i18n.t("page.systemWide.department.updateDialog.title")}
                {...this.props}
                onDelete={this.deleteDepartments}
            />
        );
    }
}
