import { TooltipHost } from "@fluentui/react";
import * as React from "react";
import css from "./navigation.scss";
import classNames from "classnames";
import { NavigationRoute } from "../../routes";
import { NavigationItem } from "./navigation-item";
import { observer } from "mobx-react";
import { external, inject } from "tsdi";
import { action, computed, makeObservable } from "mobx";
import { HistoryProvider } from "../../domain/providers/history-provider";
import { ServiceSidebar } from "../../domain/services/service-sidebar";
import ElofleetIcon from "./elofleet-icon";

export interface NavigationGroupProps {
    /** Title for the group displayed to the user. */
    readonly title: string;
    /** Fluent UI icon name. */
    readonly icon: string;
    /** Can be set to `true` if the group is expanded. */
    readonly expanded?: boolean;
    /** Called when the group's title is clicked. */
    readonly onClick?: () => void;
    /**
     * If specified, each route will be rendered as a {@link NavItem} and
     * the group will be expanded if at least one of the routes is currently
     * active.
     */
    readonly routes?: NavigationRoute[];
    /** React children. */
    readonly children?: JSX.Element | JSX.Element[];
    readonly bottom?: boolean;
}

/** A group of multiple {@link NavItem}s. */
@observer
@external
export class NavigationGroup extends React.Component<NavigationGroupProps> {
    @inject private readonly historyProvider!: HistoryProvider;
    @inject private readonly serviceSidebar!: ServiceSidebar;

    constructor(props: NavigationGroupProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private updateURL(evt: React.MouseEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        if (this.props.onClick) {
            this.props.onClick();
        } else if (this.defaultRoute) {
            this.historyProvider.history.push(this.defaultRoute.path());
        }
    }

    @computed private get defaultRoute(): NavigationRoute | undefined {
        if (!this.props.routes || this.props.routes.length === 0) {
            return;
        }
        return this.props.routes[0];
    }

    @computed private get expanded(): boolean {
        if (this.props.expanded) {
            return true;
        }
        return this.props.routes?.some((route) => this.historyProvider.isOnRoute(route)) ?? false;
    }

    private renderExpandedChildren(): JSX.Element {
        if (!this.expanded || this.collapsed) {
            return <></>;
        }
        return <ul className={css.navigationGroup__expandedChildren}>{this.renderChildren()}</ul>;
    }

    private renderHoveredChildren(): JSX.Element {
        if (this.expanded && !this.collapsed) {
            return <></>;
        }
        return <ul>{this.renderChildren()}</ul>;
    }

    private renderChildren(): JSX.Element {
        return (
            <>
                {this.props.routes?.map((route) => (
                    <NavigationItem key={route.path()} route={route} />
                )) ?? <></>}
                {this.props.children}
            </>
        );
    }

    @computed private get collapsed(): boolean {
        return this.serviceSidebar.primaryCollapsed;
    }

    @computed private get href(): string | undefined {
        if (!this.defaultRoute) {
            return;
        }
        return this.defaultRoute.path();
    }

    public render(): JSX.Element {
        const navigationGroup = (
            <>
                <div className={css.navigationGroup__titleAndIcon}>
                    <ElofleetIcon icon={this.props.icon} className={css.navigationGroup__icon} />
                    <a className={css.navigationGroup__title} href={this.href}>
                        {this.props.title}
                    </a>
                </div>
                {this.renderExpandedChildren()}
                <div className={css.navigationGroup__hoveredChildren}>
                    {this.renderHoveredChildren()}
                </div>
            </>
        );
        return (
            <li
                className={classNames(css.navigationGroup, {
                    [css["navigationGroup--expanded"]]: this.expanded,
                    [css["navigationGroup--collapsed"]]: this.collapsed,
                    [css["navigationGroup--bottom"]]: this.props.bottom,
                })}
                onClick={this.updateURL}
            >
                {this.collapsed ? (
                    <TooltipHost content={this.props.title}>{navigationGroup}</TooltipHost>
                ) : (
                    <>{navigationGroup}</>
                )}
            </li>
        );
    }
}
