import * as React from "react";

export interface LabelProps {
    readonly label?: React.ReactNode;
    readonly className?: string;
}

/**
 * An generic pre-styled label for convenient reuse.
 */
export function Label(props: LabelProps): JSX.Element {
    return (
        <label className={`bold mb-2 font-medium leading-7 ${props.className ?? ""}`}>
            {props.label}
        </label>
    );
}
