import { declareRoute, routePath } from "../routes";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { PageHeader } from "../ui/atoms/page-header";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import css from "./page-action-configuration.scss";
import React from "react";
import { ReactFlowProvider } from "reactflow";
import { ActionConfiguration } from "../ui/atoms/action-configuration";

export function PageActionConfiguration(): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();

    return (
        <LayoutDefault header={<PageHeader title={t("page.actionConfiguration.title")} />}>
            <div className={css.pageActionConfiguration}>
                <ReactFlowProvider>
                    <ActionConfiguration />
                </ReactFlowProvider>
            </div>
        </LayoutDefault>
    );
}

export const routeActionConfiguration = declareRoute({
    component: PageActionConfiguration,
    icon: "SetAction",
    title: "page.actionConfiguration.navbarEntry",
    path: routePath.actionConfiguration,
    pattern: "/action-configuration",
});
