import { observer } from "mobx-react";
import * as React from "react";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { declareRoute, routePath, RouteProps } from "../routes";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { PageHeader } from "../ui/atoms/page-header";
import { PageContent } from "../ui/atoms/page-content";
import { action, computed, makeObservable, observable, runInAction, when } from "mobx";
import { ImageUploader } from "../ui/atoms/image-uploader";
import { DefaultButton, IStackTokens, Stack, TextField, Toggle } from "@fluentui/react";
import { RepositoryPublicSettings } from "../domain/repositories/repository-public-settings";
import { PublicSetting, PublicSettingUpdate, Setting } from "../api";
import { ServicePublicSettings } from "../domain/services/service-public-settings";
import { ServiceSidebar } from "../domain/services/service-sidebar";
import { ServiceFile } from "../domain/services/service-file";
import { PrimaryButtonValidation } from "../ui/atoms/primary-button-validation";
import { Section } from "../ui/atoms/section";
import { ListCommandButtonsSites } from "../ui/organisms/list-command-buttons-sites";
import { ListSites } from "../ui/molecules/list-sites";
import { LoadingState } from "../utils/loading-state";
import { createUuid, UUID } from "../utils/uuid";
import { RepositorySettings } from "../domain/repositories/repository-settings";
import { doubleBindNullableString } from "../utils/double-bind";
import { ListVehicleTypes } from "../ui/list-vehicle-types";
import { ListCommandButtonsVehicleTypes } from "../ui/organisms/list-command-buttons-vehicle-types";

const numericalSpacingStackTokens: IStackTokens = {
    childrenGap: 30,
    padding: 30,
};

enum PageGeneralSettingsFeatures {
    UPDATE_LOGO = "update logo",
    SUBMITTING_SETTINGS = "submitting settings",
}

@external
@observer
export class PageGeneralSettings extends React.Component<RouteProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryPublicSettings!: RepositoryPublicSettings;
    @inject private readonly repositorySettings!: RepositorySettings;
    @inject private readonly sidebarService!: ServiceSidebar;
    @inject private readonly servicePublicSettings!: ServicePublicSettings;
    @inject private readonly serviceFile!: ServiceFile;

    @observable private settingId?: UUID;

    private validationId = createUuid();

    private imageBytes: Uint8Array | undefined;
    private siteListStateId = createUuid();
    private vehicleTypeListStateId = createUuid();
    private readonly loadingFeatures = new LoadingState<PageGeneralSettingsFeatures>();

    constructor(props: RouteProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected async initialize(): Promise<void> {
        const [setting] = await this.repositorySettings.byQueryAsync();
        runInAction(() => {
            this.settingId = setting.id;
        });

        when(
            () => this.publicSetting !== undefined,
            () => {
                this.repositoryPublicSettings.validation.update.initializeModel(
                    this.validationId,
                    this.publicSettingUpdate,
                    this.publicSetting!.id,
                );
            },
        );
    }

    @computed private get publicSetting(): PublicSetting | undefined {
        if (this.servicePublicSettings.publicSetting === undefined) {
            return;
        }
        // TODO: Somehow this causes a mobx warning saying that state is modified outside of an action.
        // We couldn't figure out where the mutation comes from.
        return this.repositoryPublicSettings.mutableCopyById(
            PageGeneralSettings.name,
            this.servicePublicSettings.publicSetting.id,
        );
    }

    @computed private get publicSettingUpdate(): PublicSettingUpdate {
        return this.publicSetting!;
    }

    @computed private get setting(): Setting | undefined {
        if (this.settingId === undefined) {
            return;
        }
        return this.repositorySettings.byId(this.settingId);
    }

    private triggerValidation(): void {
        this.repositoryPublicSettings.validation.update.updateModel(
            this.validationId,
            this.publicSettingUpdate,
        );
    }

    @action.bound private setImageBytes(bytes?: Uint8Array): void {
        this.imageBytes = bytes;
    }

    @action.bound
    private async updateLogo(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const publicSetting = await this.repositoryPublicSettings.update(
            this.publicSetting!.id,
            this.publicSettingUpdate,
            this.imageBytes,
        );
        this.reloadLogo(publicSetting.logoId);
    }

    @action.bound
    private async updateInstanceName(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        await this.repositoryPublicSettings.update(
            this.publicSetting!.id,
            this.publicSettingUpdate,
        );
    }

    @action.bound
    private reloadLogo(logoId: string | null | undefined): void {
        const logo = this.serviceFile.getFileUri(logoId);
        this.sidebarService.setLogo(logo);
    }

    @action.bound
    private async deleteLogo(): Promise<void> {
        const logoId = this.publicSetting?.logoId;
        if (logoId === undefined || logoId === null) {
            return;
        }
        await this.serviceFile.deleteFile(logoId);
        this.sidebarService.setLogo(undefined);
    }

    @action.bound
    private setDemoDataGeneration(generateDemoData?: boolean): void {
        if (generateDemoData === undefined || !this.settingId) {
            return;
        }
        this.repositorySettings.update(this.settingId, {
            generateDemoData,
        });
    }

    public render(): JSX.Element {
        // wait until setting is loaded from backend
        // otherwise the initial image won't be set
        if (this.publicSetting === undefined) {
            return (
                <LayoutDefault
                    header={<PageHeader title={this.i18n.t("page.generalSettings.title")} />}
                >
                    <></>
                </LayoutDefault>
            );
        }
        return (
            <LayoutDefault
                header={<PageHeader title={this.i18n.t("page.generalSettings.title")} />}
            >
                <PageContent>
                    <Stack horizontal tokens={numericalSpacingStackTokens} wrap>
                        <Section withPadding title={this.i18n.t("page.generalSettings.logoUpload")}>
                            <form
                                onSubmit={(evt) =>
                                    this.loadingFeatures.wrap(
                                        PageGeneralSettingsFeatures.UPDATE_LOGO,
                                        this.updateLogo(evt),
                                    )
                                }
                            >
                                <Stack
                                    horizontal
                                    horizontalAlign="space-between"
                                    tokens={{
                                        childrenGap: "2em",
                                    }}
                                >
                                    <ImageUploader
                                        onImageChanged={this.setImageBytes}
                                        initialImage={this.publicSetting?.logoId}
                                        placeholderIcon="FileImage"
                                    />
                                </Stack>
                                <Stack
                                    horizontal
                                    horizontalAlign="start"
                                    tokens={{
                                        childrenGap: "2em",
                                    }}
                                >
                                    <PrimaryButtonValidation
                                        buttonDisabled={this.loadingFeatures.isLoading(
                                            PageGeneralSettingsFeatures.UPDATE_LOGO,
                                        )}
                                        validationLoading={this.loadingFeatures.isLoading(
                                            PageGeneralSettingsFeatures.UPDATE_LOGO,
                                        )}
                                        text={this.i18n.t("page.generalSettings.logoSubmit.text")}
                                    />
                                    <DefaultButton
                                        text="Delete logo"
                                        disabled={this.loadingFeatures.isLoading(
                                            PageGeneralSettingsFeatures.UPDATE_LOGO,
                                        )}
                                        onClick={() =>
                                            this.loadingFeatures.wrap(
                                                PageGeneralSettingsFeatures.UPDATE_LOGO,
                                                this.deleteLogo(),
                                            )
                                        }
                                    />
                                </Stack>
                            </form>
                        </Section>
                        <Section
                            withPadding
                            title={this.i18n.t("page.systemWide.site.title")}
                            icon="CityNext2"
                        >
                            <ListCommandButtonsSites listStateId={this.siteListStateId} canDelete />
                            <ListSites listStateId={this.siteListStateId} />
                        </Section>

                        <Section
                            withPadding
                            title={this.i18n.t("page.systemWide.vehicleTypes.title")}
                        >
                            <ListCommandButtonsVehicleTypes
                                listStateId={this.vehicleTypeListStateId}
                                canDelete
                            />
                            <ListVehicleTypes listStateId={this.vehicleTypeListStateId} />
                        </Section>

                        <Section
                            withPadding
                            title={this.i18n.t("page.generalSettings.settings.title")}
                        >
                            {this.publicSetting && (
                                <form
                                    onSubmit={(evt) =>
                                        this.loadingFeatures.wrap(
                                            PageGeneralSettingsFeatures.SUBMITTING_SETTINGS,
                                            this.updateInstanceName(evt),
                                        )
                                    }
                                >
                                    <Stack
                                        tokens={{
                                            childrenGap: "1em",
                                        }}
                                    >
                                        <TextField
                                            label={this.i18n.t(
                                                "page.generalSettings.instanceName.label",
                                            )}
                                            {...doubleBindNullableString(
                                                this.publicSettingUpdate,
                                                "instanceName",
                                                () => this.triggerValidation(),
                                            )}
                                            required
                                            errorMessage={this.i18n.formatFieldValidationState(
                                                this.repositoryPublicSettings.validation.update.getFieldValidationState(
                                                    this.validationId,
                                                    "instanceName",
                                                ),
                                            )}
                                        />
                                        <PrimaryButtonValidation
                                            buttonDisabled={this.loadingFeatures.isLoading(
                                                PageGeneralSettingsFeatures.SUBMITTING_SETTINGS,
                                            )}
                                            validationLoading={this.loadingFeatures.isLoading(
                                                PageGeneralSettingsFeatures.SUBMITTING_SETTINGS,
                                            )}
                                            text={this.i18n.t(
                                                "page.generalSettings.settingsSubmit.text",
                                            )}
                                        />
                                    </Stack>
                                </form>
                            )}
                        </Section>

                        <Section
                            withPadding
                            title={this.i18n.t("page.generalSettings.generateDemoData.title")}
                        >
                            {this.setting && (
                                <Toggle
                                    label={this.i18n.t(
                                        "page.generalSettings.generateDemoData.label",
                                    )}
                                    defaultChecked={this.setting.generateDemoData}
                                    onChange={(_event, checked) =>
                                        this.setDemoDataGeneration(checked)
                                    }
                                />
                            )}
                        </Section>
                    </Stack>
                </PageContent>
            </LayoutDefault>
        );
    }
}

export const routeGeneralSettings = declareRoute({
    component: PageGeneralSettings,
    icon: "PlayerSettings",
    title: "page.generalSettings.navbarEntry",
    path: routePath.generalSettings,
    pattern: "/general-settings",
});
