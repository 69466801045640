import React from "react";
import { declareRoute, routePath } from "../routes";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { PageContent } from "../ui/atoms/page-content";
import { Section } from "../ui/atoms/section";
import { PageHeader } from "../ui/atoms/page-header";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { createUuid } from "../utils/uuid";
import sizes from "../ui/sizes.scss";
import { ListMotionSensors } from "../ui/molecules/list-motion-sensors";

export function PageMotionSensors(): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    return (
        <LayoutDefault header={<PageHeader title={t("page.motionSensors.title")} />}>
            <PageContent>
                <Section>
                    <ListMotionSensors
                        listStateId={createUuid()}
                        maxHeight={`${sizes.listMaxHeight}`}
                    />
                </Section>
            </PageContent>
        </LayoutDefault>
    );
}

export const routeMotionSensors = declareRoute({
    component: PageMotionSensors,
    icon: "CellPhone",
    title: "page.motionSensors.navbarEntry",
    path: routePath.motionSensors,
    pattern: "/motion-sensors",
});
