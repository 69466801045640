/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum VehicleTypeSortKey {
    LABEL = 'label'
}


export function VehicleTypeSortKeyFromJSON(json: any): VehicleTypeSortKey {
    return VehicleTypeSortKeyFromJSONTyped(json, false);
}

export function VehicleTypeSortKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleTypeSortKey {
    return json as VehicleTypeSortKey;
}

export function VehicleTypeSortKeyToJSON(value?: VehicleTypeSortKey | null): any {
    return value as any;
}

