/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PublicSetting,
  PublicSettingUpdate,
  TimezoneListEntry,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PublicSettingFromJSON,
    PublicSettingToJSON,
    PublicSettingUpdateFromJSON,
    PublicSettingUpdateToJSON,
    TimezoneListEntryFromJSON,
    TimezoneListEntryToJSON,
} from '../models';

export interface PublicSettingsReadPublicSettingByIdRequest {
    id: string;
}

export interface PublicSettingsUpdatePublicSettingRequest {
    id: string;
    publicSettingUpdate: PublicSettingUpdate;
}

export interface PublicSettingsValidateUpdatePublicSettingRequest {
    id: string;
    publicSettingUpdate: PublicSettingUpdate;
}

/**
 * PublicSettingsApi - interface
 * 
 * @export
 * @interface PublicSettingsApiInterface
 */
export interface PublicSettingsApiInterface {
    /**
     * Returns a list of all supported timezones including their associated descriptions.
     * @summary List all supported timezones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicSettingsApiInterface
     */
    publicSettingsListTimezonesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TimezoneListEntry>>>;

    /**
     * Returns a list of all supported timezones including their associated descriptions.
     * List all supported timezones
     */
    publicSettingsListTimezones(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TimezoneListEntry>>;

    /**
     * Since there\'s only a single public settings row, we don\'t take an ID parameter here.
     * @summary Fetch the public settings entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicSettingsApiInterface
     */
    publicSettingsReadPublicSettingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicSetting>>;

    /**
     * Since there\'s only a single public settings row, we don\'t take an ID parameter here.
     * Fetch the public settings entity
     */
    publicSettingsReadPublicSetting(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicSetting>;

    /**
     * This route aids to keep the frontend public settings repository consistent to the other respositories
     * @summary Fetch the public settings entity by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicSettingsApiInterface
     */
    publicSettingsReadPublicSettingByIdRaw(requestParameters: PublicSettingsReadPublicSettingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicSetting>>;

    /**
     * This route aids to keep the frontend public settings repository consistent to the other respositories
     * Fetch the public settings entity by id
     */
    publicSettingsReadPublicSettingById(requestParameters: PublicSettingsReadPublicSettingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicSetting>;

    /**
     * Update the public setting entity. Since we know the ID of the singleton entity after reading, we take an ID argument here to keep the API consistent with other update endpoints.
     * @summary Update public setting
     * @param {string} id 
     * @param {PublicSettingUpdate} publicSettingUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicSettingsApiInterface
     */
    publicSettingsUpdatePublicSettingRaw(requestParameters: PublicSettingsUpdatePublicSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicSetting>>;

    /**
     * Update the public setting entity. Since we know the ID of the singleton entity after reading, we take an ID argument here to keep the API consistent with other update endpoints.
     * Update public setting
     */
    publicSettingsUpdatePublicSetting(requestParameters: PublicSettingsUpdatePublicSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicSetting>;

    /**
     * Validate an update struct for the public setting entity.
     * @summary Validate public setting update
     * @param {string} id 
     * @param {PublicSettingUpdate} publicSettingUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicSettingsApiInterface
     */
    publicSettingsValidateUpdatePublicSettingRaw(requestParameters: PublicSettingsValidateUpdatePublicSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate an update struct for the public setting entity.
     * Validate public setting update
     */
    publicSettingsValidateUpdatePublicSetting(requestParameters: PublicSettingsValidateUpdatePublicSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class PublicSettingsApi extends runtime.BaseAPI implements PublicSettingsApiInterface {

    /**
     * Returns a list of all supported timezones including their associated descriptions.
     * List all supported timezones
     */
    async publicSettingsListTimezonesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TimezoneListEntry>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/public_settings/timezones`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TimezoneListEntryFromJSON));
    }

    /**
     * Returns a list of all supported timezones including their associated descriptions.
     * List all supported timezones
     */
    async publicSettingsListTimezones(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TimezoneListEntry>> {
        const response = await this.publicSettingsListTimezonesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Since there\'s only a single public settings row, we don\'t take an ID parameter here.
     * Fetch the public settings entity
     */
    async publicSettingsReadPublicSettingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicSetting>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/public_settings/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicSettingFromJSON(jsonValue));
    }

    /**
     * Since there\'s only a single public settings row, we don\'t take an ID parameter here.
     * Fetch the public settings entity
     */
    async publicSettingsReadPublicSetting(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicSetting> {
        const response = await this.publicSettingsReadPublicSettingRaw(initOverrides);
        return await response.value();
    }

    /**
     * This route aids to keep the frontend public settings repository consistent to the other respositories
     * Fetch the public settings entity by id
     */
    async publicSettingsReadPublicSettingByIdRaw(requestParameters: PublicSettingsReadPublicSettingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicSetting>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling publicSettingsReadPublicSettingById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/public_settings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicSettingFromJSON(jsonValue));
    }

    /**
     * This route aids to keep the frontend public settings repository consistent to the other respositories
     * Fetch the public settings entity by id
     */
    async publicSettingsReadPublicSettingById(requestParameters: PublicSettingsReadPublicSettingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicSetting> {
        const response = await this.publicSettingsReadPublicSettingByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the public setting entity. Since we know the ID of the singleton entity after reading, we take an ID argument here to keep the API consistent with other update endpoints.
     * Update public setting
     */
    async publicSettingsUpdatePublicSettingRaw(requestParameters: PublicSettingsUpdatePublicSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicSetting>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling publicSettingsUpdatePublicSetting.');
        }

        if (requestParameters.publicSettingUpdate === null || requestParameters.publicSettingUpdate === undefined) {
            throw new runtime.RequiredError('publicSettingUpdate','Required parameter requestParameters.publicSettingUpdate was null or undefined when calling publicSettingsUpdatePublicSetting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/public_settings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PublicSettingUpdateToJSON(requestParameters.publicSettingUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicSettingFromJSON(jsonValue));
    }

    /**
     * Update the public setting entity. Since we know the ID of the singleton entity after reading, we take an ID argument here to keep the API consistent with other update endpoints.
     * Update public setting
     */
    async publicSettingsUpdatePublicSetting(requestParameters: PublicSettingsUpdatePublicSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicSetting> {
        const response = await this.publicSettingsUpdatePublicSettingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate an update struct for the public setting entity.
     * Validate public setting update
     */
    async publicSettingsValidateUpdatePublicSettingRaw(requestParameters: PublicSettingsValidateUpdatePublicSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling publicSettingsValidateUpdatePublicSetting.');
        }

        if (requestParameters.publicSettingUpdate === null || requestParameters.publicSettingUpdate === undefined) {
            throw new runtime.RequiredError('publicSettingUpdate','Required parameter requestParameters.publicSettingUpdate was null or undefined when calling publicSettingsValidateUpdatePublicSetting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/public_settings/{id}/validate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PublicSettingUpdateToJSON(requestParameters.publicSettingUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate an update struct for the public setting entity.
     * Validate public setting update
     */
    async publicSettingsValidateUpdatePublicSetting(requestParameters: PublicSettingsValidateUpdatePublicSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.publicSettingsValidateUpdatePublicSettingRaw(requestParameters, initOverrides);
    }

}
