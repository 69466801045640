/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AuthError } from './AuthError';
import {
    AuthErrorFromJSON,
    AuthErrorFromJSONTyped,
    AuthErrorToJSON,
} from './AuthError';
import type { ErrorPropertiesBulkImportError } from './ErrorPropertiesBulkImportError';
import {
    ErrorPropertiesBulkImportErrorFromJSON,
    ErrorPropertiesBulkImportErrorFromJSONTyped,
    ErrorPropertiesBulkImportErrorToJSON,
} from './ErrorPropertiesBulkImportError';
import type { ValidationErrorsKind } from './ValidationErrorsKind';
import {
    ValidationErrorsKindFromJSON,
    ValidationErrorsKindFromJSONTyped,
    ValidationErrorsKindToJSON,
} from './ValidationErrorsKind';

/**
 * 
 * @export
 * @interface ErrorProperties
 */
export interface ErrorProperties {
    /**
     * A human-readable description of the error
     * @type {string}
     * @memberof ErrorProperties
     */
    description: string;
    /**
     * A Tree of validation errors.
     * @type {{ [key: string]: ValidationErrorsKind; }}
     * @memberof ErrorProperties
     */
    details?: { [key: string]: ValidationErrorsKind; } | null;
    /**
     * 
     * @type {ErrorPropertiesBulkImportError}
     * @memberof ErrorProperties
     */
    bulkImportError?: ErrorPropertiesBulkImportError | null;
    /**
     * 
     * @type {AuthError}
     * @memberof ErrorProperties
     */
    authError?: AuthError | null;
}

/**
 * Check if a given object implements the ErrorProperties interface.
 */
export function instanceOfErrorProperties(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function ErrorPropertiesFromJSON(json: any): ErrorProperties {
    return ErrorPropertiesFromJSONTyped(json, false);
}

export function ErrorPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'details': !exists(json, 'details') ? undefined : (json['details'] === null ? null : mapValues(json['details'], ValidationErrorsKindFromJSON)),
        'bulkImportError': !exists(json, 'bulkImportError') ? undefined : ErrorPropertiesBulkImportErrorFromJSON(json['bulkImportError']),
        'authError': !exists(json, 'authError') ? undefined : AuthErrorFromJSON(json['authError']),
    };
}

export function ErrorPropertiesToJSON(value?: ErrorProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'details': value.details === undefined ? undefined : (value.details === null ? null : mapValues(value.details, ValidationErrorsKindToJSON)),
        'bulkImportError': ErrorPropertiesBulkImportErrorToJSON(value.bulkImportError),
        'authError': AuthErrorToJSON(value.authError),
    };
}

