import * as React from "react";
import { pick } from "ramda";
import { observer } from "mobx-react";
import { Stack, TextField, DefaultButton } from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryShifts } from "../../domain/repositories/repository-shifts";
import { ShiftCreate } from "../../api";
import { doubleBindString } from "../../utils/double-bind";
import { FormCreateProps } from "../../utils/form-create-props";
import { Shift } from "../../api";
import { createUuid } from "../../utils/uuid";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";

export interface FormCreateShiftProps extends FormCreateProps<Shift> {}

@observer
@external
export class FormCreateShift extends React.Component<FormCreateShiftProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryShifts!: RepositoryShifts;

    @observable public label = "";

    private validationId = createUuid();

    constructor(props: FormCreateShiftProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositoryShifts.validation.create.updateModel(this.validationId, this.shiftCreate);
    }

    @initialize protected initialize(): void {
        this.repositoryShifts.validation.create.initializeModel(
            this.validationId,
            this.shiftCreate,
        );
    }

    @computed private get shiftCreate(): ShiftCreate {
        return pick(["label"], this as FormCreateShift);
    }

    @action.bound
    private async createShift(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const shift = await this.repositoryShifts.create(this.shiftCreate);
        this.reset();
        if (this.props.onCreate) {
            this.props.onCreate(shift);
        }
    }

    @action.bound private reset(): void {
        this.label = "";
        this.triggerValidation();
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formCreateShift.submit.text")}
                validation={this.repositoryShifts.validation.create}
                validationId={this.validationId}
            />
        );
        return (
            <form onSubmit={this.createShift}>
                <Stack tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                    <TextField
                        label={this.i18n.t("formCreateShift.label.label")}
                        {...doubleBindString<FormCreateShift>(this, "label", () =>
                            this.triggerValidation(),
                        )}
                        required
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryShifts.validation.create.getFieldValidationState(
                                this.validationId,
                                "label",
                            ),
                        )}
                    />
                </Stack>
                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            label={this.i18n.t("formCreateShift.cancel.label")}
                            text={this.i18n.t("formCreateShift.cancel.text")}
                            onClick={this.props.onDialogCancel}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
            </form>
        );
    }
}
