import { ActionButton, DatePicker, IIconProps, Stack } from "@fluentui/react";
import { endOfDay, endOfISOWeek, startOfDay, startOfISOWeek } from "date-fns";
import { observable, action, makeObservable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";

export interface FilterByDateRangeProps {
    currentWeek?: boolean;
    onDateRangeChanged: (dateStart?: Date, dateEnd?: Date) => void;
}

/**
 * A class where you can set the date range using two DateTime pickers.
 * Only date selection is available from the DateTime picker.
 * Dates are given with a timezone added by DateTime picker.

 * For example, when 01.01.2022 is selected in picker for the Berlin timezone and then open API converts to ISO 8601 (UTC), sends to the backend.
 * The toISOString() method returns a string in simplified extended ISO format (ISO 8601).
 * Backend reads this date as 31.12.2021 23:00 as UTC.
 */
@external
@observer
export class FilterByDateRange extends React.Component<FilterByDateRangeProps> {
    @inject protected readonly i18n!: I18nProvider;

    @observable public dateStart?: Date;
    @observable public dateEnd?: Date;
    @observable public isFilteredByDateRange: boolean = false;

    constructor(props: FilterByDateRangeProps) {
        super(props);
        makeObservable(this);
        if (this.props.currentWeek) {
            this.dateStart = startOfISOWeek(new Date());
            this.dateEnd = endOfISOWeek(new Date());
        }
    }

    @action.bound private setDateStart(date: Date | null | undefined): void {
        if (date == null || date == undefined) return;
        this.dateStart = startOfDay(date);
        this.isFilteredByDateRange = true;
        this.props.onDateRangeChanged(this.dateStart, this.dateEnd);
    }

    @action.bound private setDateEnd(date: Date | null | undefined): void {
        if (date == null || date == undefined) return;
        this.dateEnd = endOfDay(date);
        this.isFilteredByDateRange = true;
        this.props.onDateRangeChanged(this.dateStart, this.dateEnd);
    }

    @action.bound private clearFilter(): void {
        if (this.props.currentWeek) {
            this.dateStart = startOfISOWeek(new Date());
            this.dateEnd = endOfISOWeek(new Date());
        } else {
            this.dateEnd = undefined;
            this.dateStart = undefined;
        }
        this.isFilteredByDateRange = false;
        this.props.onDateRangeChanged(this.dateStart, this.dateEnd);
    }

    clearFilterIcon: IIconProps = { iconName: "ClearFilter" };

    public onFormatDate = (date?: Date): string => {
        return this.i18n.formatDateOnly(date!);
    };

    public render(): JSX.Element {
        return (
            <Stack>
                <Stack
                    horizontal
                    style={{ width: "100%" }}
                    horizontalAlign="end"
                    verticalAlign="center"
                >
                    <Stack.Item>
                        <DatePicker
                            value={this.dateStart}
                            onSelectDate={this.setDateStart}
                            formatDate={this.onFormatDate}
                            placeholder={this.i18n.t("filterByDateRange.startDate.placeholder")}
                            style={{ width: "165px" }}
                        />
                    </Stack.Item>
                    <Stack.Item style={{ marginLeft: "20px" }}>
                        <div />
                    </Stack.Item>
                    <Stack.Item>
                        <DatePicker
                            value={this.dateEnd}
                            onSelectDate={this.setDateEnd}
                            formatDate={this.onFormatDate}
                            placeholder={this.i18n.t("filterByDateRange.endDate.placeholder")}
                            style={{ width: "165px" }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <ActionButton
                            iconProps={this.clearFilterIcon}
                            allowDisabledFocus
                            disabled={!this.isFilteredByDateRange}
                            checked={true}
                            onClick={this.clearFilter}
                        ></ActionButton>
                    </Stack.Item>
                </Stack>
            </Stack>
        );
    }
}
