/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Dashboard,
  ErrorResponse,
} from '../models';
import {
    DashboardFromJSON,
    DashboardToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface DashboardReadDashboardDataRequest {
    todayStart: Date;
    todayEnd: Date;
    shockEventChartPeriodStart: Date;
    shockEventChartPeriodEnd: Date;
    maintenanceChartPeriodStart: Date;
    maintenanceChartPeriodEnd: Date;
    topVehiclesByShockEventsPeriodStart: Date;
    topVehiclesByShockEventsPeriodEnd: Date;
    topUsersByShockEventsPeriodStart: Date;
    topUsersByShockEventsPeriodEnd: Date;
    vehicleUtilizationPeriodStart: Date;
    vehicleUtilizationPeriodEnd: Date;
}

/**
 * DashboardApi - interface
 * 
 * @export
 * @interface DashboardApiInterface
 */
export interface DashboardApiInterface {
    /**
     * Returns the whole data needed for the new dashboard.
     * @summary Fetch the data for the new dashboard
     * @param {Date} todayStart 
     * @param {Date} todayEnd 
     * @param {Date} shockEventChartPeriodStart 
     * @param {Date} shockEventChartPeriodEnd 
     * @param {Date} maintenanceChartPeriodStart 
     * @param {Date} maintenanceChartPeriodEnd 
     * @param {Date} topVehiclesByShockEventsPeriodStart 
     * @param {Date} topVehiclesByShockEventsPeriodEnd 
     * @param {Date} topUsersByShockEventsPeriodStart 
     * @param {Date} topUsersByShockEventsPeriodEnd 
     * @param {Date} vehicleUtilizationPeriodStart 
     * @param {Date} vehicleUtilizationPeriodEnd 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    dashboardReadDashboardDataRaw(requestParameters: DashboardReadDashboardDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Dashboard>>;

    /**
     * Returns the whole data needed for the new dashboard.
     * Fetch the data for the new dashboard
     */
    dashboardReadDashboardData(requestParameters: DashboardReadDashboardDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Dashboard>;

}

/**
 * 
 */
export class DashboardApi extends runtime.BaseAPI implements DashboardApiInterface {

    /**
     * Returns the whole data needed for the new dashboard.
     * Fetch the data for the new dashboard
     */
    async dashboardReadDashboardDataRaw(requestParameters: DashboardReadDashboardDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Dashboard>> {
        if (requestParameters.todayStart === null || requestParameters.todayStart === undefined) {
            throw new runtime.RequiredError('todayStart','Required parameter requestParameters.todayStart was null or undefined when calling dashboardReadDashboardData.');
        }

        if (requestParameters.todayEnd === null || requestParameters.todayEnd === undefined) {
            throw new runtime.RequiredError('todayEnd','Required parameter requestParameters.todayEnd was null or undefined when calling dashboardReadDashboardData.');
        }

        if (requestParameters.shockEventChartPeriodStart === null || requestParameters.shockEventChartPeriodStart === undefined) {
            throw new runtime.RequiredError('shockEventChartPeriodStart','Required parameter requestParameters.shockEventChartPeriodStart was null or undefined when calling dashboardReadDashboardData.');
        }

        if (requestParameters.shockEventChartPeriodEnd === null || requestParameters.shockEventChartPeriodEnd === undefined) {
            throw new runtime.RequiredError('shockEventChartPeriodEnd','Required parameter requestParameters.shockEventChartPeriodEnd was null or undefined when calling dashboardReadDashboardData.');
        }

        if (requestParameters.maintenanceChartPeriodStart === null || requestParameters.maintenanceChartPeriodStart === undefined) {
            throw new runtime.RequiredError('maintenanceChartPeriodStart','Required parameter requestParameters.maintenanceChartPeriodStart was null or undefined when calling dashboardReadDashboardData.');
        }

        if (requestParameters.maintenanceChartPeriodEnd === null || requestParameters.maintenanceChartPeriodEnd === undefined) {
            throw new runtime.RequiredError('maintenanceChartPeriodEnd','Required parameter requestParameters.maintenanceChartPeriodEnd was null or undefined when calling dashboardReadDashboardData.');
        }

        if (requestParameters.topVehiclesByShockEventsPeriodStart === null || requestParameters.topVehiclesByShockEventsPeriodStart === undefined) {
            throw new runtime.RequiredError('topVehiclesByShockEventsPeriodStart','Required parameter requestParameters.topVehiclesByShockEventsPeriodStart was null or undefined when calling dashboardReadDashboardData.');
        }

        if (requestParameters.topVehiclesByShockEventsPeriodEnd === null || requestParameters.topVehiclesByShockEventsPeriodEnd === undefined) {
            throw new runtime.RequiredError('topVehiclesByShockEventsPeriodEnd','Required parameter requestParameters.topVehiclesByShockEventsPeriodEnd was null or undefined when calling dashboardReadDashboardData.');
        }

        if (requestParameters.topUsersByShockEventsPeriodStart === null || requestParameters.topUsersByShockEventsPeriodStart === undefined) {
            throw new runtime.RequiredError('topUsersByShockEventsPeriodStart','Required parameter requestParameters.topUsersByShockEventsPeriodStart was null or undefined when calling dashboardReadDashboardData.');
        }

        if (requestParameters.topUsersByShockEventsPeriodEnd === null || requestParameters.topUsersByShockEventsPeriodEnd === undefined) {
            throw new runtime.RequiredError('topUsersByShockEventsPeriodEnd','Required parameter requestParameters.topUsersByShockEventsPeriodEnd was null or undefined when calling dashboardReadDashboardData.');
        }

        if (requestParameters.vehicleUtilizationPeriodStart === null || requestParameters.vehicleUtilizationPeriodStart === undefined) {
            throw new runtime.RequiredError('vehicleUtilizationPeriodStart','Required parameter requestParameters.vehicleUtilizationPeriodStart was null or undefined when calling dashboardReadDashboardData.');
        }

        if (requestParameters.vehicleUtilizationPeriodEnd === null || requestParameters.vehicleUtilizationPeriodEnd === undefined) {
            throw new runtime.RequiredError('vehicleUtilizationPeriodEnd','Required parameter requestParameters.vehicleUtilizationPeriodEnd was null or undefined when calling dashboardReadDashboardData.');
        }

        const queryParameters: any = {};

        if (requestParameters.todayStart !== undefined) {
            queryParameters['today_start'] = (requestParameters.todayStart as any).toISOString();
        }

        if (requestParameters.todayEnd !== undefined) {
            queryParameters['today_end'] = (requestParameters.todayEnd as any).toISOString();
        }

        if (requestParameters.shockEventChartPeriodStart !== undefined) {
            queryParameters['shock_event_chart_period_start'] = (requestParameters.shockEventChartPeriodStart as any).toISOString();
        }

        if (requestParameters.shockEventChartPeriodEnd !== undefined) {
            queryParameters['shock_event_chart_period_end'] = (requestParameters.shockEventChartPeriodEnd as any).toISOString();
        }

        if (requestParameters.maintenanceChartPeriodStart !== undefined) {
            queryParameters['maintenance_chart_period_start'] = (requestParameters.maintenanceChartPeriodStart as any).toISOString();
        }

        if (requestParameters.maintenanceChartPeriodEnd !== undefined) {
            queryParameters['maintenance_chart_period_end'] = (requestParameters.maintenanceChartPeriodEnd as any).toISOString();
        }

        if (requestParameters.topVehiclesByShockEventsPeriodStart !== undefined) {
            queryParameters['top_vehicles_by_shock_events_period_start'] = (requestParameters.topVehiclesByShockEventsPeriodStart as any).toISOString();
        }

        if (requestParameters.topVehiclesByShockEventsPeriodEnd !== undefined) {
            queryParameters['top_vehicles_by_shock_events_period_end'] = (requestParameters.topVehiclesByShockEventsPeriodEnd as any).toISOString();
        }

        if (requestParameters.topUsersByShockEventsPeriodStart !== undefined) {
            queryParameters['top_users_by_shock_events_period_start'] = (requestParameters.topUsersByShockEventsPeriodStart as any).toISOString();
        }

        if (requestParameters.topUsersByShockEventsPeriodEnd !== undefined) {
            queryParameters['top_users_by_shock_events_period_end'] = (requestParameters.topUsersByShockEventsPeriodEnd as any).toISOString();
        }

        if (requestParameters.vehicleUtilizationPeriodStart !== undefined) {
            queryParameters['vehicle_utilization_period_start'] = (requestParameters.vehicleUtilizationPeriodStart as any).toISOString();
        }

        if (requestParameters.vehicleUtilizationPeriodEnd !== undefined) {
            queryParameters['vehicle_utilization_period_end'] = (requestParameters.vehicleUtilizationPeriodEnd as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dashboard/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardFromJSON(jsonValue));
    }

    /**
     * Returns the whole data needed for the new dashboard.
     * Fetch the data for the new dashboard
     */
    async dashboardReadDashboardData(requestParameters: DashboardReadDashboardDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Dashboard> {
        const response = await this.dashboardReadDashboardDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
