import * as React from "react";
import { observer } from "mobx-react";
import {
    createTheme,
    DefaultButton,
    IColumn,
    Icon,
    ITheme,
    Separator,
    Stack,
} from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { createUuid, UUID } from "../../utils/uuid";
import { UserGroup, VehicleGroup } from "../../api";
import { RepositoryUserGroups } from "../../domain/repositories/repository-user-groups";
import { RepositoryUserGroupVehicleGroupAssignments } from "../../domain/repositories/repository-user-group-vehicle-group-assignments";
import { RepositoryVehicleGroups } from "../../domain/repositories/repository-vehicle-groups";
import { ModalConfirmation } from "../atoms/modal-confirmation";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { MultiSelectWithList } from "../organisms/multi-select-with-list";
import { ElofleetDialog } from "../atoms/elofleet-dialog";

import sizes from "../sizes.scss";
import { defaultPageSize } from "../../utils/constants";

export interface FormCreateUserGroupVehicleGroupAssignmentsProps {
    readonly onDialogCancel: () => void;
    readonly isOpen: boolean;
}

@observer
@external
export class FormCreateUserGroupVehicleGroupAssignments extends React.Component<FormCreateUserGroupVehicleGroupAssignmentsProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject private readonly repositoryVehicleGroups!: RepositoryVehicleGroups;
    @inject private readonly repositoryUserGroups!: RepositoryUserGroups;
    @inject
    private readonly repositoryUserGroupVehicleGroupAssignments!: RepositoryUserGroupVehicleGroupAssignments;

    @observable private cancelConfirmationModalVisible = false;

    // The list state ids for the respective lists of added groups.
    private userGroupListStateId = createUuid();
    private vehicleGroupListStateId = createUuid();

    // These lists contain the entities that've already been added.
    // I.e. the UserGroups and VehicleGroupss that have been selected for assignments.
    //
    // Once the user sends this form, these entities will be used to create the
    // actual new assignments.
    @observable private addedUserGroups: UUID[] = [];
    @observable private addedVehicleGroups: UUID[] = [];

    constructor(props: FormCreateUserGroupVehicleGroupAssignmentsProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.userGroups.initializeList(this.userGroupListStateId, {
            query: () => {
                return {
                    pageSize: defaultPageSize,
                };
            },
            ignoreUrl: true,
        });
        this.serviceListStates.vehicleGroups.initializeList(this.vehicleGroupListStateId, {
            query: () => {
                return {
                    pageSize: defaultPageSize,
                };
            },
            ignoreUrl: true,
        });
    }

    /**
     * This is run if the user clicks the form's save button.
     */
    @action.bound private async createUserGroupVehicleGroupAssignments(): Promise<void> {
        await this.repositoryUserGroupVehicleGroupAssignments.createMultiple({
            userGroupIds: this.addedUserGroups,
            vehicleGroupIds: this.addedVehicleGroups,
        });
        this.closeForm();
    }

    /**
     * This is run if the user clicks the form's cancel button.
     * A confirmation modal might pop up if there are any changes.
     */
    @action.bound private showConfirmAndCloseForm(): void {
        // If there were any changes, i.e. if the original and mutable copy aren't the same,
        // open the confirmation modal for aborting the update process.
        if (this.addedVehicleGroups.length > 0 || this.addedUserGroups.length > 0) {
            this.cancelConfirmationModalVisible = true;
            return;
        }

        this.closeForm();
    }

    /**
     * Actually close the component and reset all state.
     */
    @action.bound private closeForm(): void {
        this.cancelConfirmationModalVisible = false;
        this.addedUserGroups = [];
        this.addedVehicleGroups = [];

        this.props.onDialogCancel();
    }

    @action.bound private closeCancelConfirmationModalVisible(): void {
        this.cancelConfirmationModalVisible = false;
    }

    /**
     * Handle the actual addition of a all currently selected Users in the group.
     */
    @action.bound private addUserGroups(userGroups: UUID[]): void {
        this.addedUserGroups = [...this.addedUserGroups, ...userGroups];
    }

    /**
     * Handle the removal of UserGroups.
     * In this case, removal means that they'll be removed from the list of added UserGroups.
     */
    @action.bound private removeUserGroups(idsToRemove: UUID[]): void {
        const remainingGroups = this.addedUserGroups.filter((id) => !idsToRemove.includes(id));
        this.addedUserGroups = remainingGroups;
    }

    /**
     * Handle the actual addition of a all currently selected Vehicles in the group.
     */
    @action.bound private addVehicleGroups(vehicleGroups: UUID[]): void {
        this.addedVehicleGroups = [...this.addedVehicleGroups, ...vehicleGroups];
    }

    /**
     * Handle the removal of VehicleGroups.
     * In this case, removal means that they'll be removed from the list of added VehicleGroups.
     */
    @action.bound private removeVehicleGroups(idsToRemove: UUID[]): void {
        const remainingGroups = this.addedVehicleGroups.filter((id) => !idsToRemove.includes(id));
        this.addedVehicleGroups = remainingGroups;
    }

    /**
     * The items that should be shown in the list of added VehicleGroups.
     */
    @computed private get vehicleGroupItems(): Object[] {
        const groups = [];

        for (const id of this.addedVehicleGroups) {
            const group = this.repositoryVehicleGroups.byId(id);
            if (group !== undefined) {
                groups.push({
                    key: group.id,
                    label: group.label,
                    assignedVehicleCount: group.assignedVehicleCount,
                    assignedUserGroupCount: group.assignedUserGroupCount,
                });
            }
        }

        return groups;
    }

    /**
     * The columns that should be shown in the list of selected VehicleGroups.
     */
    @computed private get vehicleGroupColumns(): IColumn[] {
        return [
            {
                fieldName: "label",
                name: this.i18n.t("listVehicleGroups.column.label.name"),
                key: "label",
                minWidth: 100,
            },
            {
                fieldName: "assignedVehicleCount",
                name: this.i18n.t("listVehicleGroups.column.assignedVehicleCount.name"),
                key: "assignedVehicleCount",
                minWidth: 80,
            },
            {
                fieldName: "assignedUserGroupCount",
                name: this.i18n.t("listVehicleGroups.column.assignedUserGroupCount.name"),
                key: "assignedUserGroupCount",
                minWidth: 150,
            },
        ];
    }

    /**
     * The items that should be shown in the list of added UserGroups.
     */
    @computed private get userGroupItems(): Object[] {
        const groups = [];

        for (const id of this.addedUserGroups) {
            const group = this.repositoryUserGroups.byId(id);
            if (group !== undefined) {
                groups.push({
                    key: group.id,
                    label: group.label,
                    assignedUserCount: group.assignedUserCount,
                    assignedVehicleGroupCount: group.assignedVehicleGroupCount,
                });
            }
        }

        return groups;
    }

    /**
     * The columns that should be shown in the list of selected UserGroups.
     */
    @computed private get userGroupColumns(): IColumn[] {
        return [
            {
                fieldName: "label",
                name: this.i18n.t("listUserGroups.column.label.name"),
                key: "label",
                minWidth: 100,
            },
            {
                fieldName: "assignedUserCount",
                name: this.i18n.t("listUserGroups.column.assignedUserCount.name"),
                key: "assignedUserCount",
                minWidth: 80,
            },
            {
                fieldName: "assignedVehicleGroupCount",
                name: this.i18n.t("listUserGroups.column.assignedVehicleGroupCount.name"),
                key: "assignedVehicleGroupCount",
                minWidth: 150,
            },
        ];
    }

    public render(): JSX.Element {
        const separatorTheme: ITheme = createTheme({
            fonts: {
                medium: {
                    fontSize: sizes.l,
                },
            },
        });

        return (
            <ElofleetDialog
                isOpen={this.props.isOpen}
                title={this.i18n.t("formCreateUserGroupVehicleGroupAssignments.title")}
                onDismiss={this.showConfirmAndCloseForm}
            >
                <Stack
                    horizontal
                    styles={{
                        root: {
                            minWidth: "1200px",
                            minHeight: "400px",
                            padding: sizes.xl,
                            justifyContent: "space-evenly",
                        },
                    }}
                >
                    <MultiSelectWithList
                        repository={this.repositoryUserGroups}
                        onAdd={this.addUserGroups}
                        onDelete={this.removeUserGroups}
                        listStateId={this.userGroupListStateId}
                        listState={this.serviceListStates.userGroups}
                        addedEntities={this.addedUserGroups}
                        columns={this.userGroupColumns}
                        items={this.userGroupItems}
                        formatEntity={(group: UserGroup) => group.label}
                        addButtonText={this.i18n.t("component.multiSelect.addButton")}
                        removeButtonText={this.i18n.t("component.multiSelect.removeButton")}
                        dropdownLabel={this.i18n.t(
                            "formCreateUserGroupVehicleGroupAssignments.userGroup.label",
                        )}
                        multiSelectPlaceholder={this.i18n.t(
                            "formCreateUserGroupVehicleGroupAssignments.userGroup.placeholder",
                        )}
                    />
                    <Separator theme={separatorTheme} vertical>
                        <Stack>
                            <Stack.Item tokens={{ margin: `${sizes.s} 0px` }}>
                                {this.i18n.t(
                                    "formCreateUserGroupVehicleGroupAssignments.separator.assign",
                                )}
                            </Stack.Item>
                            <Icon iconName="ChromeBackMirrored" />
                        </Stack>
                    </Separator>
                    <MultiSelectWithList
                        repository={this.repositoryVehicleGroups}
                        onAdd={this.addVehicleGroups}
                        onDelete={this.removeVehicleGroups}
                        listStateId={this.vehicleGroupListStateId}
                        listState={this.serviceListStates.vehicleGroups}
                        addedEntities={this.addedVehicleGroups}
                        columns={this.vehicleGroupColumns}
                        items={this.vehicleGroupItems}
                        formatEntity={(group: VehicleGroup) => group.label}
                        addButtonText={this.i18n.t("component.multiSelect.addButton")}
                        removeButtonText={this.i18n.t("component.multiSelect.removeButton")}
                        dropdownLabel={this.i18n.t(
                            "formCreateUserGroupVehicleGroupAssignments.vehicleGroup.label",
                        )}
                        multiSelectPlaceholder={this.i18n.t(
                            "formCreateUserGroupVehicleGroupAssignments.vehicleGroup.placeholder",
                        )}
                    />
                </Stack>
                <ElofleetDialogFooter>
                    <DefaultButton
                        label={this.i18n.t(
                            "formCreateUserGroupVehicleGroupAssignments.cancel.label",
                        )}
                        text={this.i18n.t("formCreateUserGroupVehicleGroupAssignments.cancel.text")}
                        onClick={this.showConfirmAndCloseForm}
                    />
                    <DefaultButton
                        primary
                        text={this.i18n.t("formCreateUserGroupVehicleGroupAssignments.submit.text")}
                        onClick={this.createUserGroupVehicleGroupAssignments}
                        disabled={
                            this.addedVehicleGroups.length === 0 ||
                            this.addedUserGroups.length === 0
                        }
                    />
                </ElofleetDialogFooter>
                <ModalConfirmation
                    isOpen={this.cancelConfirmationModalVisible}
                    title={this.i18n.t("modalAbortUpdate.title")}
                    text={this.i18n.t("modalAbortUpdate.description")}
                    onConfirm={this.closeForm}
                    onCancel={this.closeCancelConfirmationModalVisible}
                />
            </ElofleetDialog>
        );
    }
}
