/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShockProfile
 */
export interface ShockProfile {
    /**
     * 
     * @type {string}
     * @memberof ShockProfile
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof ShockProfile
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ShockProfile
     */
    updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ShockProfile
     */
    deleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShockProfile
     */
    versionId: string;
    /**
     * 
     * @type {string}
     * @memberof ShockProfile
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof ShockProfile
     */
    light: number;
    /**
     * 
     * @type {number}
     * @memberof ShockProfile
     */
    medium: number;
    /**
     * 
     * @type {number}
     * @memberof ShockProfile
     */
    severe: number;
    /**
     * 
     * @type {number}
     * @memberof ShockProfile
     */
    duration: number;
}

/**
 * Check if a given object implements the ShockProfile interface.
 */
export function instanceOfShockProfile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "deleted" in value;
    isInstance = isInstance && "versionId" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "light" in value;
    isInstance = isInstance && "medium" in value;
    isInstance = isInstance && "severe" in value;
    isInstance = isInstance && "duration" in value;

    return isInstance;
}

export function ShockProfileFromJSON(json: any): ShockProfile {
    return ShockProfileFromJSONTyped(json, false);
}

export function ShockProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShockProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'deleted': json['deleted'],
        'versionId': json['versionId'],
        'label': json['label'],
        'light': json['light'],
        'medium': json['medium'],
        'severe': json['severe'],
        'duration': json['duration'],
    };
}

export function ShockProfileToJSON(value?: ShockProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'deleted': value.deleted,
        'versionId': value.versionId,
        'label': value.label,
        'light': value.light,
        'medium': value.medium,
        'severe': value.severe,
        'duration': value.duration,
    };
}

