/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserListUpdate
 */
export interface UserListUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof UserListUpdate
     */
    userImage?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListUpdate
     */
    userEmployeeId?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListUpdate
     */
    userLastName?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListUpdate
     */
    userFirstName?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListUpdate
     */
    userJobTitle?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListUpdate
     */
    userNfcTokenDescription?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListUpdate
     */
    userNfcToken?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListUpdate
     */
    userFleetRole?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListUpdate
     */
    userLicenseExpiryDate?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListUpdate
     */
    userExpiryLogout?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListUpdate
     */
    departmentLabel?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListUpdate
     */
    shiftLabel?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListUpdate
     */
    siteLabel?: boolean | null;
}

/**
 * Check if a given object implements the UserListUpdate interface.
 */
export function instanceOfUserListUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserListUpdateFromJSON(json: any): UserListUpdate {
    return UserListUpdateFromJSONTyped(json, false);
}

export function UserListUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserListUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userImage': !exists(json, 'userImage') ? undefined : json['userImage'],
        'userEmployeeId': !exists(json, 'userEmployeeId') ? undefined : json['userEmployeeId'],
        'userLastName': !exists(json, 'userLastName') ? undefined : json['userLastName'],
        'userFirstName': !exists(json, 'userFirstName') ? undefined : json['userFirstName'],
        'userJobTitle': !exists(json, 'userJobTitle') ? undefined : json['userJobTitle'],
        'userNfcTokenDescription': !exists(json, 'userNfcTokenDescription') ? undefined : json['userNfcTokenDescription'],
        'userNfcToken': !exists(json, 'userNfcToken') ? undefined : json['userNfcToken'],
        'userFleetRole': !exists(json, 'userFleetRole') ? undefined : json['userFleetRole'],
        'userLicenseExpiryDate': !exists(json, 'userLicenseExpiryDate') ? undefined : json['userLicenseExpiryDate'],
        'userExpiryLogout': !exists(json, 'userExpiryLogout') ? undefined : json['userExpiryLogout'],
        'departmentLabel': !exists(json, 'departmentLabel') ? undefined : json['departmentLabel'],
        'shiftLabel': !exists(json, 'shiftLabel') ? undefined : json['shiftLabel'],
        'siteLabel': !exists(json, 'siteLabel') ? undefined : json['siteLabel'],
    };
}

export function UserListUpdateToJSON(value?: UserListUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userImage': value.userImage,
        'userEmployeeId': value.userEmployeeId,
        'userLastName': value.userLastName,
        'userFirstName': value.userFirstName,
        'userJobTitle': value.userJobTitle,
        'userNfcTokenDescription': value.userNfcTokenDescription,
        'userNfcToken': value.userNfcToken,
        'userFleetRole': value.userFleetRole,
        'userLicenseExpiryDate': value.userLicenseExpiryDate,
        'userExpiryLogout': value.userExpiryLogout,
        'departmentLabel': value.departmentLabel,
        'shiftLabel': value.shiftLabel,
        'siteLabel': value.siteLabel,
    };
}

