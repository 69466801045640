import { TooltipHost } from "@fluentui/react";
import { external, inject } from "tsdi";
import * as React from "react";
import css from "./navigation.scss";
import classNames from "classnames";
import { NavigationRoute } from "../../routes";
import { HistoryProvider } from "../../domain/providers/history-provider";
import { action, computed, makeObservable } from "mobx";
import { observer } from "mobx-react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { ServiceSidebar } from "../../domain/services/service-sidebar";
import ElofleetIcon from "./elofleet-icon";

export type NavigationItemProps = {
    /** When this is set to true, the item is rendered bigger. */
    readonly huge?: boolean;
    /** When this is set to true, a separator is rendered. */
    readonly separated?: boolean;
    /** Override whether the item is collapsed. */
    readonly collapsed?: boolean;
    /** Rendered as secondary item (in right navigation). */
    readonly secondary?: boolean;
} & (
    | {
          /** Title for the group displayed to the user. */
          readonly title: string;
          /** Fluent UI icon name. */
          readonly icon: string;
          /** Called when the item title is clicked. */
          readonly onClick?: () => void;
          /** Render the item as active. */
          readonly active?: boolean;
          readonly route?: undefined;
      }
    | {
          readonly route: NavigationRoute;
      }
);

/** A navigation item, contained in a {@link NavGroup}. */
@external
@observer
export class NavigationItem extends React.Component<NavigationItemProps> {
    @inject private readonly historyProvider!: HistoryProvider;
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly serviceSidebar!: ServiceSidebar;

    constructor(props: NavigationItemProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private updateURL(evt: React.MouseEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        if (this.props.route) {
            this.historyProvider.history.push(this.props.route.path());
        } else if (this.props.onClick) {
            this.props.onClick();
        }
    }

    @computed private get title(): string {
        if (this.props.route) {
            return this.i18n.t(this.props.route.title);
        }
        return this.props.title;
    }

    @computed private get icon(): string {
        if (this.props.route) {
            return this.props.route.icon;
        }
        return this.props.icon;
    }

    @computed private get active(): boolean {
        if (this.props.route) {
            return this.historyProvider.isOnRoute(this.props.route);
        } else {
            return this.props.active ?? false;
        }
    }

    @computed private get collapsed(): boolean {
        if (this.props.collapsed !== undefined) {
            return this.props.collapsed;
        }
        return this.serviceSidebar.primaryCollapsed;
    }

    @computed private get href(): string | undefined {
        if (!this.props.route) {
            return;
        }
        return this.props.route.path();
    }

    public render(): JSX.Element {
        const navigationItem = (
            <div className={css.navigationItem__titleAndIcon}>
                <ElofleetIcon icon={this.icon} className={css.navigationItem__icon} />
                <a className={css.navigationItem__title} href={this.href}>
                    {this.title}
                </a>
            </div>
        );

        return (
            <li
                className={classNames(css.navigationItem, {
                    [css["navigationItem--active"]]: this.active,
                    [css["navigationItem--huge"]]: this.props.huge,
                    [css["navigationItem--separated"]]: this.props.separated,
                    [css["navigationItem--collapsed"]]: this.collapsed,
                    [css["navigationItem--secondary"]]: this.props.secondary,
                })}
                onClick={this.updateURL}
            >
                {this.collapsed ? (
                    <TooltipHost content={this.title}>{navigationItem}</TooltipHost>
                ) : (
                    <>{navigationItem}</>
                )}
            </li>
        );
    }
}
