import { Icon } from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { ManagementRole } from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { HelpStateStatus, ServiceHelp } from "../../domain/services/service-help";
import { ServiceOwnUser } from "../../domain/services/service-own-user";
import { ServiceSidebar } from "../../domain/services/service-sidebar";
import { HelpArticle, HelpTag } from "../../help-types";
import { ModalAndroidInfo } from "../organisms/modal-android-info";
import { HelpArticleFull } from "./help-article-full";
import { HelpArticlePreview } from "./help-article-preview";
import { HelpSearch } from "./help-search";
import css from "./help-sidebar.scss";
import { HelpTagCloud } from "./help-tag-cloud";

export interface HelpSidebarProps {
    readonly suggestedHelpArticles?: HelpArticle[];
}

@external
@observer
export class HelpSidebar extends React.Component<HelpSidebarProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private serviceSidebar!: ServiceSidebar;
    @inject private serviceHelp!: ServiceHelp;
    @inject private serviceOwnUser!: ServiceOwnUser;

    @observable private androidInfoIsOpen = false;

    constructor(props: HelpSidebarProps) {
        super(props);
        makeObservable(this);
    }

    private renderSuggestedArticles(): JSX.Element {
        const { suggestedHelpArticles } = this.props;
        if (suggestedHelpArticles && suggestedHelpArticles.length > 0) {
            return (
                <>
                    <h3>{this.i18n.t("helpSidebar.suggestedArticles.title")}</h3>
                    <p>{this.i18n.t("helpSidebar.suggestedArticles.description")}</p>
                    {suggestedHelpArticles.map((article) => (
                        <HelpArticlePreview key={article.filename} article={article} />
                    ))}
                </>
            );
        }
        return (
            <>
                <h3>{this.i18n.t("helpSidebar.suggestedArticles.title")}</h3>
                <p>{this.i18n.t("helpSidebar.suggestedArticles.noArticles")}</p>
            </>
        );
    }

    @computed private get relevantTags(): HelpTag[] {
        const { state } = this.serviceHelp;
        switch (state.status) {
            case HelpStateStatus.ARTICLE_ACTIVE:
                return state.article.tags;
            case HelpStateStatus.SEARCHING:
                return state.searchResults.tags;
            case HelpStateStatus.NONE:
                return this.serviceHelp.allTags;
            case HelpStateStatus.NOT_FOUND:
                return this.serviceHelp.allTags;
        }
    }

    @computed private get showAdminMenu(): boolean {
        const { user } = this.serviceOwnUser;
        if (!user) {
            return false;
        }
        return user.managementRole === ManagementRole.SUPER_ADMIN;
    }

    private renderContent(): JSX.Element {
        const { state } = this.serviceHelp;
        switch (state.status) {
            case HelpStateStatus.ARTICLE_ACTIVE:
                return (
                    <>
                        {this.renderActions()}
                        <HelpArticleFull article={state.article} />
                    </>
                );
            case HelpStateStatus.SEARCHING:
                return (
                    <>
                        <div className={css.helpSidebar__search}>
                            <HelpSearch />
                        </div>
                        <h3>{this.i18n.t("helpSidebar.searchResults.articles")}</h3>
                        {state.searchResults.articles.map((article) => (
                            <HelpArticlePreview key={article.filename} article={article} />
                        ))}
                    </>
                );
            case HelpStateStatus.NONE:
                return (
                    <>
                        <div className={css.helpSidebar__search}>
                            <HelpSearch />
                        </div>
                        {this.renderSuggestedArticles()}
                    </>
                );
            case HelpStateStatus.NOT_FOUND:
                return (
                    <>
                        {this.renderActions()}
                        <h3>{this.i18n.t("helpSidebar.notFound.title")}</h3>
                        <p>{this.i18n.t("helpSidebar.notFound.description")}</p>
                        <Icon className={css.helpSidebar__notFoundIcon} iconName="Sad" />
                    </>
                );
        }
    }

    private renderActions(): JSX.Element {
        return (
            <div className={css.helpSidebar__actions}>
                <a onClick={() => this.serviceHelp.back()} className={css.helpSidebar__action}>
                    <Icon iconName="Back" /> <span>{this.i18n.t("helpSidebar.back")}</span>
                </a>
                <a
                    onClick={() => this.serviceHelp.clearHistory()}
                    className={css.helpSidebar__action}
                >
                    <span className={css.helpSidebar__actionText}>
                        {this.i18n.t("helpSidebar.leave")}
                    </span>{" "}
                    <Icon iconName="Clear" />
                </a>
            </div>
        );
    }

    @action.bound private toggleAndroidInfo(): void {
        this.androidInfoIsOpen = !this.androidInfoIsOpen;
    }

    public render(): JSX.Element {
        return (
            <div className={css.helpSidebar}>
                <div className={css.helpSidebar__header}>
                    <h2 className={css.helpSidebar__title}>{this.i18n.t("helpSidebar.title")}</h2>
                    <button
                        className={css.helpSidebar__closeButton}
                        onClick={() => this.serviceSidebar.toggleSecondaryCollapsed()}
                    >
                        <Icon iconName="Clear" />
                    </button>
                </div>
                <div className={css.helpSidebar__scroll}>
                    <div className={css.helpSidebar__content}>{this.renderContent()}</div>
                    <div className={css.helpSidebar__tagCloud}>
                        <HelpTagCloud tags={this.relevantTags} />
                        {this.showAdminMenu && (
                            <>
                                <div className={css.helpSidebar__android}>
                                    <a
                                        className={css.helpSidebar__androidLink}
                                        onClick={() => this.toggleAndroidInfo()}
                                    >
                                        Android App Download
                                    </a>
                                    <ModalAndroidInfo
                                        isOpen={this.androidInfoIsOpen}
                                        onDismiss={() => this.toggleAndroidInfo()}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
