import * as React from "react";
import { external } from "tsdi";
import { DefaultButton, IContextualMenuProps } from "@fluentui/react";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { inject } from "tsdi";
import { UUID } from "../../utils/uuid";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { FormCreateUserGroupVehicleGroupAssignments } from "../molecules/form-create-user-group-vehicle-group-assignments";
import sizes from "../sizes.scss";
import { FormCreateUserGroupAssignments } from "../molecules/form-create-user-group-assignments";
import { FormCreateUserGroup } from "../molecules/form-create-user-group";

export interface ListUserGroupAddButtonProps {
    readonly listStateId?: UUID;
}

/**
 * The UserGroup list has some special behavior regarding forms.
 * One can open several forms to add various relationship instead of just having only a single
 * button for creating a new UserGroup.
 *
 * This is implemented via a button with a drop-down.
 * At the point of writing this, this includes:
 * - Adding a new UserGroup.
 * - Adding new Users to Groups.
 * - Assign new VehicleGroups to UserGroups.
 */
@external
@observer
export class ListUserGroupAddButton extends React.Component<ListUserGroupAddButtonProps> {
    @inject protected readonly i18n!: I18nProvider;

    @observable private assignUsersDialogVisible = false;
    @observable private createUserGroupDialogVisible = false;
    @observable private assignVehicleGroupsDialogVisible = false;

    constructor(props: ListUserGroupAddButtonProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private openAssignUsersDialog(): void {
        this.assignUsersDialogVisible = true;
    }

    @action.bound private closeAssignUsersDialog(): void {
        this.assignUsersDialogVisible = false;
    }

    @action.bound private openCreateUserGroupDialog(): boolean | void {
        this.createUserGroupDialogVisible = true;
    }

    @action.bound private closeCreateUserGroupDialog(): void {
        this.createUserGroupDialogVisible = false;
    }

    @action.bound private openAssignVehicleGroupsDialog(): void {
        this.assignVehicleGroupsDialogVisible = true;
    }

    @action.bound private closeAssignVehicleGroupsDialog(): void {
        this.assignVehicleGroupsDialogVisible = false;
    }

    public render(): JSX.Element {
        const menuProps: IContextualMenuProps = {
            items: [
                {
                    key: "addGroup",
                    text: this.i18n.t("listUserGroups.button.addGroup"),
                    onClick: this.openCreateUserGroupDialog,
                },
                {
                    key: "assignVehicleGroups",
                    text: this.i18n.t("listUserGroups.button.assignGroups"),
                    onClick: this.openAssignVehicleGroupsDialog,
                },
            ],
        };

        return (
            <>
                <div
                    style={{
                        marginRight: sizes.xl,
                    }}
                >
                    <DefaultButton
                        text={this.i18n.t("listUserGroups.button.assignUsers")}
                        primary
                        split
                        aria-roledescription="split button"
                        menuProps={menuProps}
                        onClick={this.openAssignUsersDialog}
                    />
                </div>
                <FormCreateUserGroupAssignments
                    onDialogCancel={this.closeAssignUsersDialog}
                    isOpen={this.assignUsersDialogVisible}
                />
                <FormCreateUserGroup
                    onDialogCancel={this.closeCreateUserGroupDialog}
                    asDialogContent={true}
                    isOpen={this.createUserGroupDialogVisible}
                />
                <FormCreateUserGroupVehicleGroupAssignments
                    onDialogCancel={this.closeAssignVehicleGroupsDialog}
                    isOpen={this.assignVehicleGroupsDialogVisible}
                />
            </>
        );
    }
}
