import { Draft } from "immer";
import { StateCreator } from "zustand";
import { createUuid, UUID } from "../../utils/uuid";
import { TFunction } from "i18next";

export enum EquipmentType {
    Truck = "truck",
    TowTruck = "towTruck",
    Battery = "battery",
    AGV = "agv",
    AMR = "amr",
    CleaningMachine = "cleaningMachine",
    HandPalletJack = "handPalletJack",
}

export function displayEquipmentType(t: TFunction, equipmentType: EquipmentType): string {
    return t(`dms.schema.equipment.type.${equipmentType}`);
}

export function equipmentIsVehicle(equipmentType: EquipmentType): boolean {
    return equipmentType !== EquipmentType.Battery;
}

export interface Equipment {
    oem?: string;
    modelName: string;
    serialNumber?: string;
    department?: string;
    equipmentType: EquipmentType;
    manufactureYear: number;
}

export interface EquipmentSlice {
    equipment: Record<UUID, Equipment>;

    insertEquipment: (equipment: Equipment) => UUID;
    updateEquipment: (id: UUID, callback: (equipment: Draft<Equipment>) => void) => void;
}

export const createEquipmentSlice: StateCreator<EquipmentSlice, [["zustand/immer", never]], []> = (
    set,
) => ({
    equipment: demoEquipment,

    insertEquipment: (equipment: Equipment) => {
        const id = createUuid();
        set((state) => {
            state.equipment[id] = equipment;
        });
        return id;
    },
    updateEquipment: (id, callback) =>
        set((state) => {
            callback(state.equipment[id]);
        }),
});

export const demoEquipment: { [key: string]: Equipment } = {
    "d5e35f97-8120-4e44-aaea-bca66f36386e": {
        modelName: "dms.demo.model.1a",
        oem: "dms.demo.oem.1",
        serialNumber: "FN383756",
        department: "dms.demo.department.packing",
        equipmentType: EquipmentType.Truck,
        manufactureYear: 2018,
    },
    "d77bbdf3-8f4e-419f-8fd6-d3ab8344d7bf": {
        modelName: "dms.demo.model.2a",
        oem: "dms.demo.oem.2",
        serialNumber: "FN383913",
        department: "dms.demo.department.receiving",
        equipmentType: EquipmentType.Truck,
        manufactureYear: 2020,
    },
    "fbda7cfd-ec49-45bb-8cb9-af9373120b82": {
        modelName: "dms.demo.model.2b",
        oem: "dms.demo.oem.2",
        serialNumber: "FN504291",
        department: "dms.demo.department.shipping",
        equipmentType: EquipmentType.Truck,
        manufactureYear: 2021,
    },
    "f013f0fb-f4dd-4023-b76c-e8a64936e8af": {
        modelName: "dms.demo.model.3a",
        oem: "dms.demo.oem.3",
        serialNumber: "933304291",
        department: "dms.demo.department.shipping",
        equipmentType: EquipmentType.AGV,
        manufactureYear: 2021,
    },
    "864fa6ec-1f06-462c-95f7-9d79024ae37c": {
        modelName: "dms.demo.model.4a",
        oem: "dms.demo.oem.4",
        serialNumber: "29391054845",
        department: "dms.demo.department.shipping",
        equipmentType: EquipmentType.Battery,
        manufactureYear: 2019,
    },
    "b48f9aba-4680-4fce-9d39-f7626327d5d5": {
        modelName: "dms.demo.model.5a",
        oem: "dms.demo.oem.5",
        serialNumber: "111045232",
        department: "dms.demo.department.picking",
        equipmentType: EquipmentType.HandPalletJack,
        manufactureYear: 2020,
    },
    "e159e890-0993-4335-8e6d-48e36ca2baa6": {
        modelName: "dms.demo.model.6a",
        oem: "dms.demo.oem.6",
        serialNumber: "FN938311112",
        department: "dms.demo.department.picking",
        equipmentType: EquipmentType.HandPalletJack,
        manufactureYear: 2015,
    },
    "586db8ef-1558-4b1a-97a8-a57c0d2ce114": {
        modelName: "dms.demo.model.5b",
        oem: "dms.demo.oem.5",
        serialNumber: "LI9811168",
        department: "dms.demo.department.putaway",
        equipmentType: EquipmentType.Battery,
        manufactureYear: 2021,
    },
    "bb457249-a039-4efa-8ed1-22dfcc27eb8e": {
        modelName: "dms.demo.model.7a",
        oem: "dms.demo.oem.7",
        serialNumber: "1992700812",
        department: "dms.demo.department.shipping",
        equipmentType: EquipmentType.CleaningMachine,
        manufactureYear: 2017,
    },
    "3be65bd9-e768-496d-9734-f257412f4e1f": {
        modelName: "dms.demo.model.7b",
        oem: "dms.demo.oem.7",
        serialNumber: "1992700819",
        department: "dms.demo.department.packing",
        equipmentType: EquipmentType.CleaningMachine,
        manufactureYear: 2017,
    },
    "11dddb92-8bf9-4a78-af0d-9479af525550": {
        modelName: "dms.demo.model.5c",
        oem: "dms.demo.oem.5",
        serialNumber: "87322135",
        department: "dms.demo.department.putaway",
        equipmentType: EquipmentType.AGV,
        manufactureYear: 2022,
    },
    "2ca94d0f-fb2b-4bd3-b5f0-c02dd3be8752": {
        modelName: "dms.demo.model.8a",
        oem: "dms.demo.oem.8",
        serialNumber: "6752311309",
        department: "dms.demo.department.picking",
        equipmentType: EquipmentType.Truck,
        manufactureYear: 2022,
    },
    "6fc4a213-e555-4438-a5e0-28676c39e206": {
        modelName: "dms.demo.model.2c",
        oem: "dms.demo.oem.2",
        serialNumber: "16712451",
        department: "dms.demo.department.storage",
        equipmentType: EquipmentType.TowTruck,
        manufactureYear: 2021,
    },
    "28ab932a-6693-40d3-b9fd-506d9cfac4e4": {
        modelName: "dms.demo.model.9a",
        oem: "dms.demo.oem.9",
        serialNumber: "ZZ9365111",
        department: "dms.demo.department.picking",
        equipmentType: EquipmentType.AMR,
        manufactureYear: 2022,
    },
};
