/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VehicleMovementType } from './VehicleMovementType';
import {
    VehicleMovementTypeFromJSON,
    VehicleMovementTypeFromJSONTyped,
    VehicleMovementTypeToJSON,
} from './VehicleMovementType';

/**
 * 
 * @export
 * @interface VehicleMovement
 */
export interface VehicleMovement {
    /**
     * Primary key, provided by clients to provide idempotent inserts. See `docs/backend/Offline-Synchronization.md` for details.
     * @type {string}
     * @memberof VehicleMovement
     */
    id: string;
    /**
     * This is the time at which the movement has been reported to our system. Since movements might happen in offline mode, `created_at` and `time` might differ considerably.
     * @type {Date}
     * @memberof VehicleMovement
     */
    createdAt: Date;
    /**
     * The vehicle this movement belongs to. Although this is reported by the vehicle device, we record the vehicle that was assigned to the device at the time of recording, because vehicle-to-vehicle-device assigments can change.
     * @type {string}
     * @memberof VehicleMovement
     */
    vehicleId: string;
    /**
     * The user that was logged in, if any.
     * @type {string}
     * @memberof VehicleMovement
     */
    userId?: string | null;
    /**
     * An id tying together a pair of [VehicleMovementType::Start] and [VehicleMovementType::Stop] entries.
     * @type {string}
     * @memberof VehicleMovement
     */
    periodId: string;
    /**
     * This is the on-device time the movement was recorded at.
     * @type {Date}
     * @memberof VehicleMovement
     */
    time: Date;
    /**
     * 
     * @type {VehicleMovementType}
     * @memberof VehicleMovement
     */
    movementType: VehicleMovementType;
    /**
     * Sequential ID used to ensure that the background workers only process a row once.
     * @type {number}
     * @memberof VehicleMovement
     */
    sequentialId: number;
}

/**
 * Check if a given object implements the VehicleMovement interface.
 */
export function instanceOfVehicleMovement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "vehicleId" in value;
    isInstance = isInstance && "periodId" in value;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "movementType" in value;
    isInstance = isInstance && "sequentialId" in value;

    return isInstance;
}

export function VehicleMovementFromJSON(json: any): VehicleMovement {
    return VehicleMovementFromJSONTyped(json, false);
}

export function VehicleMovementFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleMovement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'vehicleId': json['vehicleId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'periodId': json['periodId'],
        'time': (new Date(json['time'])),
        'movementType': VehicleMovementTypeFromJSON(json['movementType']),
        'sequentialId': json['sequentialId'],
    };
}

export function VehicleMovementToJSON(value?: VehicleMovement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'vehicleId': value.vehicleId,
        'userId': value.userId,
        'periodId': value.periodId,
        'time': (value.time.toISOString()),
        'movementType': VehicleMovementTypeToJSON(value.movementType),
        'sequentialId': value.sequentialId,
    };
}

