import * as React from "react";
import { observer } from "mobx-react";
import { Pivot, PivotItem } from "@fluentui/react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { PersistentNotification, PersistentNotificationType } from "../../api";
import { external, inject } from "tsdi";
import { NotificationLabel, LabelDate } from "../atoms/notification-label";
import { ChecklistNotificationItem } from "./notification-item-checklist";
import { ImpactNotificationItem } from "./notification-item-impact";
import { MaintenanceNotificationItem } from "./notification-item-maintenance";

export interface NotificationPivotMenuProps {
    notifications: PersistentNotification[];
    markNotificationRead: (id: string) => void;
    onClick: () => void;
}

type RenderState = {
    renderMarkAllRead: boolean;
    lastLabel: LabelDate | null;
    today: Date;
    yesterday: Date;
};

/// This is the "main" component for displaying notifications.
/// It uses a Pivot menu to allow for selecting between different types of
/// notifications.
@external
@observer
export class NotificationPivotMenu extends React.Component<NotificationPivotMenuProps> {
    @inject private i18n!: I18nProvider;

    /**
     * Return a {@link NotificationLabel} if one hasn't already been rendered
     * for the date range the current notification falls under.
     *
     * If this is the first {@link NotificationLabel} being rendered it will
     * also render the "Mark all as read" link.
     */
    private maybeRenderNotificationLabel(
        notification: PersistentNotification,
        renderState: RenderState,
    ): JSX.Element | null {
        let notificationLabel = null;

        if (notification.triggeredAt.getTime() > renderState.today.getTime()) {
            if (renderState.lastLabel !== LabelDate.TODAY) {
                notificationLabel = (
                    <NotificationLabel
                        key="notification-label-today"
                        date={LabelDate.TODAY}
                        renderMarkAllRead={renderState.renderMarkAllRead}
                        onClick={this.props.onClick}
                    />
                );
                renderState.renderMarkAllRead = false;
                renderState.lastLabel = LabelDate.TODAY;
            }
        } else if (notification.triggeredAt.getTime() > renderState.yesterday.getTime()) {
            if (renderState.lastLabel !== LabelDate.YESTERDAY) {
                notificationLabel = (
                    <NotificationLabel
                        key="notification-label-yesterday"
                        date={LabelDate.YESTERDAY}
                        renderMarkAllRead={renderState.renderMarkAllRead}
                        onClick={this.props.onClick}
                    />
                );
                renderState.renderMarkAllRead = false;
                renderState.lastLabel = LabelDate.YESTERDAY;
            }
        } else if (notification.triggeredAt.getTime() <= renderState.yesterday.getTime()) {
            if (renderState.lastLabel !== LabelDate.OLDER) {
                notificationLabel = (
                    <NotificationLabel
                        key="notification-label-older"
                        date={LabelDate.OLDER}
                        renderMarkAllRead={renderState.renderMarkAllRead}
                        onClick={this.props.onClick}
                    />
                );
                renderState.renderMarkAllRead = false;
                renderState.lastLabel = LabelDate.OLDER;
            }
        }

        return notificationLabel;
    }

    private notificationItem(notification: PersistentNotification): JSX.Element {
        switch (notification.notificationType) {
            case PersistentNotificationType.CHECKLIST:
                return (
                    <ChecklistNotificationItem
                        key={notification.id}
                        notification={notification}
                        markNotificationRead={this.props.markNotificationRead}
                    />
                );
            case PersistentNotificationType.IMPACT:
                return (
                    <ImpactNotificationItem
                        key={notification.id}
                        notification={notification}
                        markNotificationRead={this.props.markNotificationRead}
                    />
                );
            case PersistentNotificationType.MAINTENANCE:
                return (
                    <MaintenanceNotificationItem
                        key={notification.id}
                        notification={notification}
                        markNotificationRead={this.props.markNotificationRead}
                    />
                );
        }
    }

    private renderNotificationItemsOfType(
        notifications: PersistentNotification[],
        notificationType: PersistentNotificationType,
        renderState: RenderState,
    ): JSX.Element[] {
        renderState = { ...renderState, renderMarkAllRead: true, lastLabel: null };
        return notifications
            .filter((notification) => notification.notificationType === notificationType)
            .flatMap((notification) => [
                this.maybeRenderNotificationLabel(notification, renderState),
                this.notificationItem(notification),
            ])
            .filter((item) => item !== null) as JSX.Element[];
    }

    public render(): JSX.Element {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const renderState = {
            renderMarkAllRead: true,
            lastLabel: null,
            today: today,
            yesterday: yesterday,
        };
        const allNotifications = this.props.notifications
            .flatMap((notification) => [
                this.maybeRenderNotificationLabel(notification, renderState),
                this.notificationItem(notification),
            ])
            .filter((item) => item !== null);

        const impactNotifications = this.renderNotificationItemsOfType(
            this.props.notifications,
            PersistentNotificationType.IMPACT,
            renderState,
        );
        const checklistNotifications = this.renderNotificationItemsOfType(
            this.props.notifications,
            PersistentNotificationType.CHECKLIST,
            renderState,
        );
        const maintenanceNotifications = this.renderNotificationItemsOfType(
            this.props.notifications,
            PersistentNotificationType.MAINTENANCE,
            renderState,
        );

        return (
            <Pivot aria-label="Notification pivot menu">
                <PivotItem headerText={this.i18n.t("component.notificationPivotMenu.all")}>
                    {allNotifications}
                </PivotItem>
                <PivotItem headerText={this.i18n.t("component.notificationPivotMenu.impacts")}>
                    {impactNotifications}
                </PivotItem>
                <PivotItem headerText={this.i18n.t("component.notificationPivotMenu.checklists")}>
                    {checklistNotifications}
                </PivotItem>
                <PivotItem headerText={this.i18n.t("component.notificationPivotMenu.maintenance")}>
                    {maintenanceNotifications}
                </PivotItem>
            </Pivot>
        );
    }
}
