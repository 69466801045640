import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { ServiceHelp } from "../../domain/services/service-help";
import { HelpArticle } from "../../help-types";
import css from "./help-article-preview.scss";

export interface HelpArticlePreviewProps {
    readonly article: HelpArticle;
}

@observer
@external
export class HelpArticlePreview extends React.Component<HelpArticlePreviewProps> {
    @inject private i18n!: I18nProvider;
    @inject private serviceHelp!: ServiceHelp;

    public render(): JSX.Element {
        const { title, summary } = this.props.article;
        return (
            <div
                className={css.helpArticlePreview}
                onClick={() => this.serviceHelp.enterArticle(this.props.article.filename)}
            >
                <h3 className={css.helpArticlePreview__title}>{this.i18n.t(title)}</h3>
                <p className={css.helpArticlePreview__summary}>
                    <span>{this.i18n.t(summary)}</span>{" "}
                    <a className={css.helpArticlePreview__readMore}>
                        {this.i18n.t("helpArticlePreview.readMore")}
                    </a>
                </p>
            </div>
        );
    }
}
