/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PersistentNotificationType {
    CHECKLIST = 'checklist',
    IMPACT = 'impact',
    MAINTENANCE = 'maintenance'
}


export function PersistentNotificationTypeFromJSON(json: any): PersistentNotificationType {
    return PersistentNotificationTypeFromJSONTyped(json, false);
}

export function PersistentNotificationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersistentNotificationType {
    return json as PersistentNotificationType;
}

export function PersistentNotificationTypeToJSON(value?: PersistentNotificationType | null): any {
    return value as any;
}

