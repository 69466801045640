import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { external, initialize, inject } from "tsdi";
import { NotificationEmail, NotificationEmailCreate, Setting, SettingUpdate } from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositorySettings } from "../../domain/repositories/repository-settings";
import { createUuid, UUID } from "../../utils/uuid";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { Stack } from "@fluentui/react";
import { NotificationEmails } from "../atoms/notification-emails";

export enum NotificationEmailType {
    PreOps,
    Impact,
    Maintenance,
}

export interface FormUpdateNotificationMailsProps {}

@observer
@external
export class FormUpdateNotificationMails extends React.Component<FormUpdateNotificationMailsProps> {
    @inject private readonly repositorySetting!: RepositorySettings;
    @inject private readonly i18n!: I18nProvider;

    private validationId = createUuid();

    @observable private settingId?: UUID;
    @observable private emailsCreatePreOps: NotificationEmailCreate[] = [];
    @observable private emailsCreateImpact: NotificationEmailCreate[] = [];
    @observable private emailsCreateMaintenance: NotificationEmailCreate[] = [];
    @observable private emailsUpdatePreOps: NotificationEmail[] = [];
    @observable private emailsUpdateImpact: NotificationEmail[] = [];
    @observable private emailsUpdateMaintenance: NotificationEmail[] = [];
    @observable private isUpdated: boolean = false;

    constructor(props: FormUpdateNotificationMailsProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected async initialize(): Promise<void> {
        // We can always assume that the API will return exactly one setting object
        const [setting] = await this.repositorySetting.byQueryAsync();
        runInAction(() => {
            this.settingId = setting.id;
        });
        this.setInitialEmails(setting);
        this.repositorySetting.validation.update.initializeModel(
            this.validationId,
            this.settingUpdate,
            setting.id,
        );
    }

    @action.bound private setInitialEmails(setting: Setting | undefined): void {
        if (setting === undefined) {
            return undefined;
        }
        this.emailsCreatePreOps = [];
        this.emailsCreateImpact = [];
        this.emailsCreateMaintenance = [];
        this.emailsUpdatePreOps = setting.preOpsNotificationEmails;
        this.emailsUpdateImpact = setting.impactNotificationEmails;
        this.emailsUpdateMaintenance = setting.maintenanceNotificationEmails;
        this.isUpdated = false;
    }

    @action.bound private onChangeEmails(): void {
        this.triggerValidation();
    }

    private triggerValidation(): void {
        this.repositorySetting.validation.update.updateModel(this.validationId, this.settingUpdate);
        this.isUpdated = true;
    }

    public componentWillUnmount(): void {
        if (this.settingId === undefined) {
            return;
        }
        this.repositorySetting.discardMutableCopy(FormUpdateNotificationMails.name, this.settingId);
    }

    @computed private get settingUpdate(): SettingUpdate {
        return {
            preOpsNotificationEmails: this.emailsUpdatePreOps,
            preOpsNotificationEmailsCreate:
                this.emailsCreatePreOps.length == 0 ? null : this.emailsCreatePreOps,
            impactNotificationEmails: this.emailsUpdateImpact,
            impactNotificationEmailsCreate:
                this.emailsCreateImpact.length == 0 ? null : this.emailsCreateImpact,
            maintenanceNotificationEmails: this.emailsUpdateMaintenance,
            maintenanceNotificationEmailsCreate:
                this.emailsCreateMaintenance.length == 0 ? null : this.emailsCreateMaintenance,
        };
    }

    @action.bound
    private async updateSetting(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        if (this.settingId === undefined) {
            throw new Error("submit button should be disabled until setting is loaded");
        }
        evt.preventDefault();
        const setting = await this.repositorySetting.update(this.settingId, this.settingUpdate);
        this.setInitialEmails(setting);
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formUpdateSettingImpact.submit.text")}
                validation={this.repositorySetting.validation.update}
                validationId={this.validationId}
                primaryDisabled={!this.isUpdated}
            />
        );
        if (this.settingId != undefined) {
            return (
                <form onSubmit={this.updateSetting}>
                    <NotificationEmails
                        emailsCreate={this.emailsCreateMaintenance}
                        emailsUpdate={this.emailsUpdateMaintenance}
                        onChange={this.onChangeEmails}
                        translationPrefixKey={"formUpdateSettingVehicle"}
                        validationKey={"maintenanceNotificationEmails"}
                        validationKeyCreate={"maintenanceNotificationEmailsCreate"}
                        validationId={this.validationId}
                    ></NotificationEmails>
                    <NotificationEmails
                        emailsCreate={this.emailsCreatePreOps}
                        emailsUpdate={this.emailsUpdatePreOps}
                        onChange={this.onChangeEmails}
                        translationPrefixKey={"formUpdateSettingPreOps"}
                        validationKey={"preOpsNotificationEmails"}
                        validationKeyCreate={"preOpsNotificationEmailsCreate"}
                        validationId={this.validationId}
                    ></NotificationEmails>
                    <NotificationEmails
                        emailsCreate={this.emailsCreateImpact}
                        emailsUpdate={this.emailsUpdateImpact}
                        onChange={this.onChangeEmails}
                        translationPrefixKey={"formUpdateSettingImpact"}
                        validationKey={"impactNotificationEmails"}
                        validationKeyCreate={"impactNotificationEmailsCreate"}
                        validationId={this.validationId}
                    ></NotificationEmails>
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                </form>
            );
        } else return <></>;
    }
}
