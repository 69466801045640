import { action, autorun, computed, makeObservable } from "mobx";
import { component, initialize, inject } from "tsdi";
import { LocalStorageService } from "../../utils/local-storage";
import elokonO from "../../images/elokon-o.svg";
import { ServicePublicSettings } from "./service-public-settings";
import { ServiceFile } from "./service-file";

export interface SidebarState {
    primaryCollapsed: boolean;
    secondaryCollapsed: boolean;
    logo: string;
}

@component
export class ServiceSidebar extends LocalStorageService<SidebarState> {
    @inject private readonly servicePublicSettings!: ServicePublicSettings;
    @inject private readonly serviceFile!: ServiceFile;

    constructor() {
        super({
            version: 2,
            defaultValue: { primaryCollapsed: false, secondaryCollapsed: false, logo: elokonO },
            identifier: "sidebar",
        });
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        autorun(() => {
            if (this.servicePublicSettings.publicSetting === undefined) {
                return;
            }
            const logo = this.serviceFile.getFileUri(
                this.servicePublicSettings.publicSetting.logoId,
            );
            this.setLogo(logo);
        });
    }

    @computed public get primaryCollapsed(): boolean {
        return this.storedValue.primaryCollapsed;
    }

    @action.bound public setPrimaryCollapsed(primaryCollapsed: boolean): void {
        this.storedValue = {
            ...this.storedValue,
            primaryCollapsed,
        };
    }

    @action.bound public togglePrimaryCollapsed(): void {
        this.setPrimaryCollapsed(!this.primaryCollapsed);
    }

    @computed public get secondaryCollapsed(): boolean {
        return this.storedValue.secondaryCollapsed;
    }

    @action.bound public setSecondaryCollapsed(secondaryCollapsed: boolean): void {
        this.storedValue = {
            ...this.storedValue,
            secondaryCollapsed,
        };
    }

    @action.bound public toggleSecondaryCollapsed(): void {
        this.setSecondaryCollapsed(!this.secondaryCollapsed);
    }

    @computed public get logo(): string {
        return this.storedValue.logo;
    }

    @action.bound public setLogo(maybeLogo?: string | null): void {
        const logo = maybeLogo ?? elokonO;
        this.storedValue = {
            ...this.storedValue,
            logo,
        };
    }
}
