import * as React from "react";
import { Link } from "react-router-dom";

export interface AttributeProps {
    /** Title of the attribute to render. */
    readonly title: string;
    /** The actual value, if any. */
    readonly value?: string | number | null;
    /** If no value is specified, fallback to this value. */
    readonly fallback?: string;
    /** Can be specified to make the value a link. */
    readonly url?: string;
}

export class Attribute extends React.Component<AttributeProps> {
    public render(): JSX.Element {
        const { title, value, fallback, url } = this.props;
        const rawContent = value ?? fallback ?? "";
        const content = url ? <Link to={url}>{rawContent}</Link> : rawContent;
        return (
            <p>
                <b>{title}</b>: {content}
            </p>
        );
    }
}
