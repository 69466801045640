/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DateFormat {
    ISO8601 = 'iso8601',
    AMERICAN = 'american',
    GERMAN_LEGACY = 'germanLegacy'
}


export function DateFormatFromJSON(json: any): DateFormat {
    return DateFormatFromJSONTyped(json, false);
}

export function DateFormatFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateFormat {
    return json as DateFormat;
}

export function DateFormatToJSON(value?: DateFormat | null): any {
    return value as any;
}

