import * as React from "react";
import { Stack, TextField, PrimaryButton, getTheme } from "@fluentui/react";
import { LoginStatus, ServiceAuth } from "../../domain/services/service-auth";
import { LoadingState } from "../../utils/loading-state";
import { LanguageButtons } from "../atoms/language-buttons";
import { ModalResetPassword } from "../organisms/modal-reset-password";
import { routeDashboard } from "../../pages/page-dashboard";
import { tsdi } from "../../tsdi";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router";
import { ServiceErrors } from "../../domain/services/service-errors";
import { I18nProvider } from "../../domain/providers/i18n-provider";

const theme = getTheme();

enum FormLoginLoadingFeature {
    AUTHORIZE = "authorize",
}

export const FormLogin = observer(function FormLogin(): JSX.Element {
    const serviceAuth = tsdi.get(ServiceAuth);
    const serviceErrors = tsdi.get(ServiceErrors);
    const i18n = tsdi.get(I18nProvider);

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [loginResult, setLoginResult] = React.useState<LoginStatus | undefined>(undefined);
    const [passwordForgottenIsOpen, setPasswordForgottenIsOpen] = React.useState<boolean>(false);
    const [loadingState, _] = React.useState(new LoadingState<FormLoginLoadingFeature>());

    const navigate = useNavigate();
    const { state } = useLocation();

    async function login(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        if (loadingState.isLoading(FormLoginLoadingFeature.AUTHORIZE)) {
            return;
        }

        const result = await loadingState.wrap(
            FormLoginLoadingFeature.AUTHORIZE,
            serviceAuth.login({ email, password }),
        );
        setLoginResult(result.status);
        if (result.status === LoginStatus.SUCCESS) {
            serviceErrors.dismissAllErrors();
            const targetPath: string | Location =
                typeof state === "object" &&
                state !== null &&
                Object.hasOwnProperty.apply(state, ["from"])
                    ? ((state as Record<string, unknown>)["from"] as Location)
                    : routeDashboard.path();
            navigate(targetPath);
        }
    }

    function resetLoginResult(): void {
        setLoginResult(undefined);
    }

    function submitButtonEnabled(): boolean {
        return !loadingState.isAnythingLoading && email !== "" && password !== "";
    }

    function emailErrorMessage(): string | undefined {
        if (loginResult === LoginStatus.INVALID) {
            return i18n.t("formLogin.email.error.invalid");
        }
    }

    function passwordErrorMessage(): string | undefined {
        if (loginResult === LoginStatus.INVALID) {
            return i18n.t("formLogin.password.error.invalid");
        }
    }

    return (
        <form onSubmit={login}>
            <Stack
                tokens={{
                    childrenGap: "1em",
                }}
            >
                <LanguageButtons />
                <TextField
                    label={i18n.t("formLogin.email.label")}
                    value={email}
                    onChange={(event) => {
                        resetLoginResult();
                        setEmail(event.currentTarget.value);
                    }}
                    required
                    autoComplete="email"
                    errorMessage={emailErrorMessage()}
                />
                <TextField
                    label={i18n.t("formLogin.password.label")}
                    required
                    value={password}
                    onChange={(event) => {
                        resetLoginResult();
                        setPassword(event.currentTarget.value);
                    }}
                    type="password"
                    canRevealPassword
                    revealPasswordAriaLabel={i18n.t("formLogin.password.revealPasswordAriaLabel")}
                    autoComplete="current-password"
                    errorMessage={passwordErrorMessage()}
                />
                <div style={{ textAlign: "right" }}>
                    <a
                        style={{ color: theme.semanticColors.link, cursor: "pointer" }}
                        onClick={() => setPasswordForgottenIsOpen(true)}
                    >
                        <>{i18n.t("formLogin.forgotPassword")}</>
                    </a>
                </div>
                <ModalResetPassword
                    onDismiss={() => setPasswordForgottenIsOpen(false)}
                    isOpen={passwordForgottenIsOpen}
                />
                <Stack horizontal horizontalAlign="end">
                    <PrimaryButton
                        type="submit"
                        label={i18n.t("formLogin.submit.text")}
                        primary
                        disabled={!submitButtonEnabled()}
                        text={i18n.t("formLogin.submit.text")}
                    />
                </Stack>
            </Stack>
        </form>
    );
});
