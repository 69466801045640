/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ShockEventMotionStatus {
    IN_MOTION = 'inMotion',
    STATIONARY = 'stationary'
}


export function ShockEventMotionStatusFromJSON(json: any): ShockEventMotionStatus {
    return ShockEventMotionStatusFromJSONTyped(json, false);
}

export function ShockEventMotionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShockEventMotionStatus {
    return json as ShockEventMotionStatus;
}

export function ShockEventMotionStatusToJSON(value?: ShockEventMotionStatus | null): any {
    return value as any;
}

