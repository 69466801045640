/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PersistentNotificationImpactSeverity } from './PersistentNotificationImpactSeverity';
import {
    PersistentNotificationImpactSeverityFromJSON,
    PersistentNotificationImpactSeverityFromJSONTyped,
    PersistentNotificationImpactSeverityToJSON,
} from './PersistentNotificationImpactSeverity';
import type { PersistentNotificationType } from './PersistentNotificationType';
import {
    PersistentNotificationTypeFromJSON,
    PersistentNotificationTypeFromJSONTyped,
    PersistentNotificationTypeToJSON,
} from './PersistentNotificationType';

/**
 * 
 * @export
 * @interface PersistentNotification
 */
export interface PersistentNotification {
    /**
     * 
     * @type {string}
     * @memberof PersistentNotification
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof PersistentNotification
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof PersistentNotification
     */
    updatedAt: Date;
    /**
     * 
     * @type {PersistentNotificationType}
     * @memberof PersistentNotification
     */
    notificationType: PersistentNotificationType;
    /**
     * 
     * @type {Date}
     * @memberof PersistentNotification
     */
    triggeredAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PersistentNotification
     */
    read: boolean;
    /**
     * 
     * @type {string}
     * @memberof PersistentNotification
     */
    vehicleId: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentNotification
     */
    fleetUserId?: string | null;
    /**
     * 
     * @type {PersistentNotificationImpactSeverity}
     * @memberof PersistentNotification
     */
    impactSeverity?: PersistentNotificationImpactSeverity | null;
    /**
     * 
     * @type {number}
     * @memberof PersistentNotification
     */
    hoursUntilMaintenance?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PersistentNotification
     */
    truckDepartmentId?: string | null;
}

/**
 * Check if a given object implements the PersistentNotification interface.
 */
export function instanceOfPersistentNotification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "notificationType" in value;
    isInstance = isInstance && "triggeredAt" in value;
    isInstance = isInstance && "read" in value;
    isInstance = isInstance && "vehicleId" in value;

    return isInstance;
}

export function PersistentNotificationFromJSON(json: any): PersistentNotification {
    return PersistentNotificationFromJSONTyped(json, false);
}

export function PersistentNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersistentNotification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'notificationType': PersistentNotificationTypeFromJSON(json['notificationType']),
        'triggeredAt': (new Date(json['triggeredAt'])),
        'read': json['read'],
        'vehicleId': json['vehicleId'],
        'fleetUserId': !exists(json, 'fleetUserId') ? undefined : json['fleetUserId'],
        'impactSeverity': !exists(json, 'impactSeverity') ? undefined : PersistentNotificationImpactSeverityFromJSON(json['impactSeverity']),
        'hoursUntilMaintenance': !exists(json, 'hoursUntilMaintenance') ? undefined : json['hoursUntilMaintenance'],
        'truckDepartmentId': !exists(json, 'truckDepartmentId') ? undefined : json['truckDepartmentId'],
    };
}

export function PersistentNotificationToJSON(value?: PersistentNotification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'notificationType': PersistentNotificationTypeToJSON(value.notificationType),
        'triggeredAt': (value.triggeredAt.toISOString()),
        'read': value.read,
        'vehicleId': value.vehicleId,
        'fleetUserId': value.fleetUserId,
        'impactSeverity': PersistentNotificationImpactSeverityToJSON(value.impactSeverity),
        'hoursUntilMaintenance': value.hoursUntilMaintenance,
        'truckDepartmentId': value.truckDepartmentId,
    };
}

