/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShockEventList
 */
export interface ShockEventList {
    /**
     * 
     * @type {boolean}
     * @memberof ShockEventList
     */
    userFullName: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShockEventList
     */
    shockEventMotionStatus: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShockEventList
     */
    vehicleSerialNumber: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShockEventList
     */
    departmentLabel: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShockEventList
     */
    vehicleGroupLabel: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShockEventList
     */
    shockProfileLabel: boolean;
}

/**
 * Check if a given object implements the ShockEventList interface.
 */
export function instanceOfShockEventList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userFullName" in value;
    isInstance = isInstance && "shockEventMotionStatus" in value;
    isInstance = isInstance && "vehicleSerialNumber" in value;
    isInstance = isInstance && "departmentLabel" in value;
    isInstance = isInstance && "vehicleGroupLabel" in value;
    isInstance = isInstance && "shockProfileLabel" in value;

    return isInstance;
}

export function ShockEventListFromJSON(json: any): ShockEventList {
    return ShockEventListFromJSONTyped(json, false);
}

export function ShockEventListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShockEventList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userFullName': json['userFullName'],
        'shockEventMotionStatus': json['shockEventMotionStatus'],
        'vehicleSerialNumber': json['vehicleSerialNumber'],
        'departmentLabel': json['departmentLabel'],
        'vehicleGroupLabel': json['vehicleGroupLabel'],
        'shockProfileLabel': json['shockProfileLabel'],
    };
}

export function ShockEventListToJSON(value?: ShockEventList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userFullName': value.userFullName,
        'shockEventMotionStatus': value.shockEventMotionStatus,
        'vehicleSerialNumber': value.vehicleSerialNumber,
        'departmentLabel': value.departmentLabel,
        'vehicleGroupLabel': value.vehicleGroupLabel,
        'shockProfileLabel': value.shockProfileLabel,
    };
}

