/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PageInfo,
  PreOpsAnswer,
  PreOpsAnswerChoice,
  PreOpsAnswerGroupCreate,
  PreOpsAnswerSortKey,
  SortDirection,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    PreOpsAnswerFromJSON,
    PreOpsAnswerToJSON,
    PreOpsAnswerChoiceFromJSON,
    PreOpsAnswerChoiceToJSON,
    PreOpsAnswerGroupCreateFromJSON,
    PreOpsAnswerGroupCreateToJSON,
    PreOpsAnswerSortKeyFromJSON,
    PreOpsAnswerSortKeyToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface PreOpsAnswersCreatePreOpsAnswerGroupRequest {
    preOpsAnswerGroupCreate: PreOpsAnswerGroupCreate;
}

export interface PreOpsAnswersExportPreOpsAnswersListRequest {
    timezone: string;
    exportType: PreOpsAnswersExportPreOpsAnswersListExportType;
    sortKey?: PreOpsAnswerSortKey;
    sortDirection?: SortDirection;
    choices?: Array<PreOpsAnswerChoice> | null;
    questionId?: string | null;
    answerGroupId?: string | null;
    checklistId?: string | null;
    dateStart?: Date;
    dateEnd?: Date;
}

export interface PreOpsAnswersListPreOpsAnswersRequest {
    pageSize: number;
    choices?: Array<PreOpsAnswerChoice> | null;
    questionId?: string | null;
    answerGroupId?: string | null;
    checklistId?: string | null;
    dateStart?: Date;
    dateEnd?: Date;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: PreOpsAnswerSortKey;
}

export interface PreOpsAnswersListPreOpsAnswersMetadataRequest {
    pageSize: number;
    choices?: Array<PreOpsAnswerChoice> | null;
    questionId?: string | null;
    answerGroupId?: string | null;
    checklistId?: string | null;
    dateStart?: Date;
    dateEnd?: Date;
}

export interface PreOpsAnswersReadPreOpsAnswerRequest {
    answerId: string;
}

export interface PreOpsAnswersValidatePreOpsAnswerGroupRequest {
    preOpsAnswerGroupCreate: PreOpsAnswerGroupCreate;
}

/**
 * PreOpsAnswersApi - interface
 * 
 * @export
 * @interface PreOpsAnswersApiInterface
 */
export interface PreOpsAnswersApiInterface {
    /**
     * Create a new pre-ops answer group.
     * @summary Create a pre-ops answer group
     * @param {PreOpsAnswerGroupCreate} preOpsAnswerGroupCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsAnswersApiInterface
     */
    preOpsAnswersCreatePreOpsAnswerGroupRaw(requestParameters: PreOpsAnswersCreatePreOpsAnswerGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PreOpsAnswer>>>;

    /**
     * Create a new pre-ops answer group.
     * Create a pre-ops answer group
     */
    preOpsAnswersCreatePreOpsAnswerGroup(requestParameters: PreOpsAnswersCreatePreOpsAnswerGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PreOpsAnswer>>;

    /**
     * Exports all the answers present.  If `search` is specified, return values similar to the `label` field. In that case, `sortKey` will not have any effect. I.e. results will be sorted by similarity to the search term instead.  Optionally filter by answer choice, question ID, answer group ID, create_date and checklist ID. The date_start parameter is inclusive, the date_end parameter is exclusive.
     * @summary Export pre-ops answers as csv or xlsx
     * @param {string} timezone 
     * @param {'csv' | 'xlsx'} exportType 
     * @param {PreOpsAnswerSortKey} [sortKey] 
     * @param {SortDirection} [sortDirection] 
     * @param {Array<PreOpsAnswerChoice>} [choices] 
     * @param {string} [questionId] 
     * @param {string} [answerGroupId] 
     * @param {string} [checklistId] 
     * @param {Date} [dateStart] 
     * @param {Date} [dateEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsAnswersApiInterface
     */
    preOpsAnswersExportPreOpsAnswersListRaw(requestParameters: PreOpsAnswersExportPreOpsAnswersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     * Exports all the answers present.  If `search` is specified, return values similar to the `label` field. In that case, `sortKey` will not have any effect. I.e. results will be sorted by similarity to the search term instead.  Optionally filter by answer choice, question ID, answer group ID, create_date and checklist ID. The date_start parameter is inclusive, the date_end parameter is exclusive.
     * Export pre-ops answers as csv or xlsx
     */
    preOpsAnswersExportPreOpsAnswersList(requestParameters: PreOpsAnswersExportPreOpsAnswersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

    /**
     * Lists all the answers present.  If `search` is specified, return values similar to the `label` field. In that case, `sortKey` will not have any effect. I.e. results will be sorted by similarity to the search term instead.  Optionally filter by answer choice, question ID, answer group ID, create_date and checklist ID. The date_start parameter is inclusive, the date_end parameter is exclusive.
     * @summary List pre-ops answers
     * @param {number} pageSize 
     * @param {Array<PreOpsAnswerChoice>} [choices] 
     * @param {string} [questionId] 
     * @param {string} [answerGroupId] 
     * @param {string} [checklistId] 
     * @param {Date} [dateStart] 
     * @param {Date} [dateEnd] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {PreOpsAnswerSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsAnswersApiInterface
     */
    preOpsAnswersListPreOpsAnswersRaw(requestParameters: PreOpsAnswersListPreOpsAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PreOpsAnswer>>>;

    /**
     * Lists all the answers present.  If `search` is specified, return values similar to the `label` field. In that case, `sortKey` will not have any effect. I.e. results will be sorted by similarity to the search term instead.  Optionally filter by answer choice, question ID, answer group ID, create_date and checklist ID. The date_start parameter is inclusive, the date_end parameter is exclusive.
     * List pre-ops answers
     */
    preOpsAnswersListPreOpsAnswers(requestParameters: PreOpsAnswersListPreOpsAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PreOpsAnswer>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary Pre-ops answer listing metadata
     * @param {number} pageSize 
     * @param {Array<PreOpsAnswerChoice>} [choices] 
     * @param {string} [questionId] 
     * @param {string} [answerGroupId] 
     * @param {string} [checklistId] 
     * @param {Date} [dateStart] 
     * @param {Date} [dateEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsAnswersApiInterface
     */
    preOpsAnswersListPreOpsAnswersMetadataRaw(requestParameters: PreOpsAnswersListPreOpsAnswersMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * Pre-ops answer listing metadata
     */
    preOpsAnswersListPreOpsAnswersMetadata(requestParameters: PreOpsAnswersListPreOpsAnswersMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the answer specified by the given ID.
     * @summary Get a single answer
     * @param {string} answerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsAnswersApiInterface
     */
    preOpsAnswersReadPreOpsAnswerRaw(requestParameters: PreOpsAnswersReadPreOpsAnswerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreOpsAnswer>>;

    /**
     * Returns the answer specified by the given ID.
     * Get a single answer
     */
    preOpsAnswersReadPreOpsAnswer(requestParameters: PreOpsAnswersReadPreOpsAnswerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreOpsAnswer>;

    /**
     * Validate data for a new answer group without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate an answer group
     * @param {PreOpsAnswerGroupCreate} preOpsAnswerGroupCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsAnswersApiInterface
     */
    preOpsAnswersValidatePreOpsAnswerGroupRaw(requestParameters: PreOpsAnswersValidatePreOpsAnswerGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new answer group without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate an answer group
     */
    preOpsAnswersValidatePreOpsAnswerGroup(requestParameters: PreOpsAnswersValidatePreOpsAnswerGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class PreOpsAnswersApi extends runtime.BaseAPI implements PreOpsAnswersApiInterface {

    /**
     * Create a new pre-ops answer group.
     * Create a pre-ops answer group
     */
    async preOpsAnswersCreatePreOpsAnswerGroupRaw(requestParameters: PreOpsAnswersCreatePreOpsAnswerGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PreOpsAnswer>>> {
        if (requestParameters.preOpsAnswerGroupCreate === null || requestParameters.preOpsAnswerGroupCreate === undefined) {
            throw new runtime.RequiredError('preOpsAnswerGroupCreate','Required parameter requestParameters.preOpsAnswerGroupCreate was null or undefined when calling preOpsAnswersCreatePreOpsAnswerGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_answers/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PreOpsAnswerGroupCreateToJSON(requestParameters.preOpsAnswerGroupCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PreOpsAnswerFromJSON));
    }

    /**
     * Create a new pre-ops answer group.
     * Create a pre-ops answer group
     */
    async preOpsAnswersCreatePreOpsAnswerGroup(requestParameters: PreOpsAnswersCreatePreOpsAnswerGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PreOpsAnswer>> {
        const response = await this.preOpsAnswersCreatePreOpsAnswerGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Exports all the answers present.  If `search` is specified, return values similar to the `label` field. In that case, `sortKey` will not have any effect. I.e. results will be sorted by similarity to the search term instead.  Optionally filter by answer choice, question ID, answer group ID, create_date and checklist ID. The date_start parameter is inclusive, the date_end parameter is exclusive.
     * Export pre-ops answers as csv or xlsx
     */
    async preOpsAnswersExportPreOpsAnswersListRaw(requestParameters: PreOpsAnswersExportPreOpsAnswersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.timezone === null || requestParameters.timezone === undefined) {
            throw new runtime.RequiredError('timezone','Required parameter requestParameters.timezone was null or undefined when calling preOpsAnswersExportPreOpsAnswersList.');
        }

        if (requestParameters.exportType === null || requestParameters.exportType === undefined) {
            throw new runtime.RequiredError('exportType','Required parameter requestParameters.exportType was null or undefined when calling preOpsAnswersExportPreOpsAnswersList.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.choices) {
            queryParameters['choices'] = requestParameters.choices;
        }

        if (requestParameters.questionId !== undefined) {
            queryParameters['question_id'] = requestParameters.questionId;
        }

        if (requestParameters.answerGroupId !== undefined) {
            queryParameters['answer_group_id'] = requestParameters.answerGroupId;
        }

        if (requestParameters.checklistId !== undefined) {
            queryParameters['checklist_id'] = requestParameters.checklistId;
        }

        if (requestParameters.dateStart !== undefined) {
            queryParameters['date_start'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['date_end'] = (requestParameters.dateEnd as any).toISOString();
        }

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        if (requestParameters.exportType !== undefined) {
            queryParameters['export_type'] = requestParameters.exportType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_answers/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Exports all the answers present.  If `search` is specified, return values similar to the `label` field. In that case, `sortKey` will not have any effect. I.e. results will be sorted by similarity to the search term instead.  Optionally filter by answer choice, question ID, answer group ID, create_date and checklist ID. The date_start parameter is inclusive, the date_end parameter is exclusive.
     * Export pre-ops answers as csv or xlsx
     */
    async preOpsAnswersExportPreOpsAnswersList(requestParameters: PreOpsAnswersExportPreOpsAnswersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.preOpsAnswersExportPreOpsAnswersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists all the answers present.  If `search` is specified, return values similar to the `label` field. In that case, `sortKey` will not have any effect. I.e. results will be sorted by similarity to the search term instead.  Optionally filter by answer choice, question ID, answer group ID, create_date and checklist ID. The date_start parameter is inclusive, the date_end parameter is exclusive.
     * List pre-ops answers
     */
    async preOpsAnswersListPreOpsAnswersRaw(requestParameters: PreOpsAnswersListPreOpsAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PreOpsAnswer>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling preOpsAnswersListPreOpsAnswers.');
        }

        const queryParameters: any = {};

        if (requestParameters.choices) {
            queryParameters['choices'] = requestParameters.choices;
        }

        if (requestParameters.questionId !== undefined) {
            queryParameters['question_id'] = requestParameters.questionId;
        }

        if (requestParameters.answerGroupId !== undefined) {
            queryParameters['answer_group_id'] = requestParameters.answerGroupId;
        }

        if (requestParameters.checklistId !== undefined) {
            queryParameters['checklist_id'] = requestParameters.checklistId;
        }

        if (requestParameters.dateStart !== undefined) {
            queryParameters['date_start'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['date_end'] = (requestParameters.dateEnd as any).toISOString();
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_answers/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PreOpsAnswerFromJSON));
    }

    /**
     * Lists all the answers present.  If `search` is specified, return values similar to the `label` field. In that case, `sortKey` will not have any effect. I.e. results will be sorted by similarity to the search term instead.  Optionally filter by answer choice, question ID, answer group ID, create_date and checklist ID. The date_start parameter is inclusive, the date_end parameter is exclusive.
     * List pre-ops answers
     */
    async preOpsAnswersListPreOpsAnswers(requestParameters: PreOpsAnswersListPreOpsAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PreOpsAnswer>> {
        const response = await this.preOpsAnswersListPreOpsAnswersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * Pre-ops answer listing metadata
     */
    async preOpsAnswersListPreOpsAnswersMetadataRaw(requestParameters: PreOpsAnswersListPreOpsAnswersMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling preOpsAnswersListPreOpsAnswersMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.choices) {
            queryParameters['choices'] = requestParameters.choices;
        }

        if (requestParameters.questionId !== undefined) {
            queryParameters['question_id'] = requestParameters.questionId;
        }

        if (requestParameters.answerGroupId !== undefined) {
            queryParameters['answer_group_id'] = requestParameters.answerGroupId;
        }

        if (requestParameters.checklistId !== undefined) {
            queryParameters['checklist_id'] = requestParameters.checklistId;
        }

        if (requestParameters.dateStart !== undefined) {
            queryParameters['date_start'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['date_end'] = (requestParameters.dateEnd as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_answers/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * Pre-ops answer listing metadata
     */
    async preOpsAnswersListPreOpsAnswersMetadata(requestParameters: PreOpsAnswersListPreOpsAnswersMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.preOpsAnswersListPreOpsAnswersMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the answer specified by the given ID.
     * Get a single answer
     */
    async preOpsAnswersReadPreOpsAnswerRaw(requestParameters: PreOpsAnswersReadPreOpsAnswerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreOpsAnswer>> {
        if (requestParameters.answerId === null || requestParameters.answerId === undefined) {
            throw new runtime.RequiredError('answerId','Required parameter requestParameters.answerId was null or undefined when calling preOpsAnswersReadPreOpsAnswer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_answers/{answer_id}`.replace(`{${"answer_id"}}`, encodeURIComponent(String(requestParameters.answerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreOpsAnswerFromJSON(jsonValue));
    }

    /**
     * Returns the answer specified by the given ID.
     * Get a single answer
     */
    async preOpsAnswersReadPreOpsAnswer(requestParameters: PreOpsAnswersReadPreOpsAnswerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreOpsAnswer> {
        const response = await this.preOpsAnswersReadPreOpsAnswerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new answer group without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate an answer group
     */
    async preOpsAnswersValidatePreOpsAnswerGroupRaw(requestParameters: PreOpsAnswersValidatePreOpsAnswerGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.preOpsAnswerGroupCreate === null || requestParameters.preOpsAnswerGroupCreate === undefined) {
            throw new runtime.RequiredError('preOpsAnswerGroupCreate','Required parameter requestParameters.preOpsAnswerGroupCreate was null or undefined when calling preOpsAnswersValidatePreOpsAnswerGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_answers/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PreOpsAnswerGroupCreateToJSON(requestParameters.preOpsAnswerGroupCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new answer group without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate an answer group
     */
    async preOpsAnswersValidatePreOpsAnswerGroup(requestParameters: PreOpsAnswersValidatePreOpsAnswerGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.preOpsAnswersValidatePreOpsAnswerGroupRaw(requestParameters, initOverrides);
    }

}

/**
  * @export
  * @enum {string}
  */
export enum PreOpsAnswersExportPreOpsAnswersListExportType {
    CSV = 'csv',
    XLSX = 'xlsx'
}
