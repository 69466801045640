import { v4 } from "uuid";

/** Anything that provides a UUID. */
export interface IdProvider {
    /** Unique Id identifying this specific model across the whole stack. */
    readonly id: UUID;
}

/** A unique identifier used for weakly referencing models across the application. */
export type UUID = string;

export function createUuid(): UUID {
    return v4();
}
