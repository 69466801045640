import { action, makeObservable } from "mobx";
import { component, inject } from "tsdi";
import { User, Vehicle } from "../../api";
import { I18nProvider } from "../providers/i18n-provider";
import { RepositoryUsers } from "../repositories/repository-users";
import { defaultPageSize } from "../../utils/constants";

/**
 * This service is to be removed at some point!!!
 *
 * The whole reason while this exists is, so we can show semi-random realistic data
 * in our application for features that don't yet exist.
 *
 * This class has been introduced during our preparation for the Modex2022 conference.
 * Elokon wanted to show some features they're still planning to do, so we had to hack
 * around to show some data that doesn't actually exist in our database.
 *
 * The idea behind this class is that you can request data of some specific form,
 * such as an integer in a range, a date in a range or a random value from an array,
 * and the returned value won't change between reloads and stay the same for a given entity.
 *
 * The best way to achieve this is to use an entity's UUID as a string input value.
 *
 * Examples:
 *
 * Returns a fix value somewhere in the range between 50 and 4000 based on the vehicle's id.
 * ```
 * this.serviceStubData.valueInRange(this.vehicle.id, 50, 4000)};
 * ```
 *
 * This returns a fix date somewhere in the range between start and end based on the vehicle's id.
 * ```
 * this.serviceStubData.dateInRange(vehicle.id, startDate, endDate)
 * ```
 */
@component
export class ServiceStubData {
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly i18n!: I18nProvider;

    constructor() {
        makeObservable(this);
    }

    /**
     * This function takes any string and maps it to a float in the space between 0.0 to 1.0.
     *
     * This is done by creating a 53 bit hash from the string and deviding that hash by 2^53.
     * The hashing function is copied from Stackoverflow:
     * https://stackoverflow.com/a/52171480
     */
    private hashAndNormalize(str: string, seed: number = 0): number {
        let h1 = 0xdeadbeef ^ seed;
        let h2 = 0x41c6ce57 ^ seed;
        for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
        }
        h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
        h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
        const final_hash = 4294967296 * (2097151 & h2) + (h1 >>> 0);

        return final_hash / Math.pow(2, 53);
    }

    /**
     * Maps a string deterministically to an integer value in the specified range.
     */
    public valueInRange(str: string, min: number, max: number): number {
        // Swap the numbers, if min isn't the smaller value.
        if (min > max) {
            const temp = max;
            max = min;
            min = temp;
        }

        // Generate the deterministic value for this string.
        const value = this.hashAndNormalize(str);

        // Calculate the range in which we can move around.
        const range = max - min;
        // Map the generated value to this this range.
        const mapped = Math.round(range * value);

        // Add the min value to the mapped value, so we're somewhere between min and max.
        return min + mapped;
    }

    /**
     * Maps a string deterministically to a boolean.
     * The ratio between true and false values can be tweaked with the second parameter.
     * A value of 0.8 will result in ~80% of the hashes being true.
     *
     * The default is a 50/50 ratio.
     */
    public bool(str: string, true_with_chance: number = 0.5): boolean {
        // Generate the deterministic value for this string.
        const value = this.hashAndNormalize(str);

        // Convert the value to a boolean.
        if (value < true_with_chance) {
            return true;
        }
        return false;
    }

    /**
     * Return a random element from a given arrya
     */
    public elementFromArray<Type>(str: string, array: Type[]): Type {
        const index = this.valueInRange(str, 0, array.length - 1);

        return array[index];
    }

    /**
     * Maps a string deterministically to a date in a specified range.
     */
    public dateInRange(str: string, start: Date, end: Date): Date {
        const startMillis = start.getTime();
        const endMillis = end.getTime();

        const newDateInMillis = this.valueInRange(str, startMillis, endMillis);

        return new Date(newDateInMillis);
    }

    /**
     * Maps a string deterministically to an existing driver in our Database.
     */
    @action.bound public driver(str: string): User | undefined {
        // Get the first max 50 drivers.
        const drivers = this.repositoryUsers.byQuery({ pageSize: defaultPageSize });

        if (drivers.length === 0) {
            return;
        }

        return this.elementFromArray(str, drivers);
    }

    /**
     * A special stub method to compute the hours since last maintenance for a vehicle.
     * TODO: Remove this, once this functionality has been properly implemented.
     */
    public hoursSinceLastMaintenance(vehicle?: Vehicle): number | undefined {
        if (!vehicle) {
            return;
        }
        const lastMaintenanceDate = vehicle.lastMaintenanceDate;
        if (!lastMaintenanceDate) {
            return;
        }
        const difference = Math.max(0, new Date().getTime() - lastMaintenanceDate.getTime());

        const millisecondsPerDay = 1000 * 60 * 60 * 24;
        const days = Math.floor(difference / millisecondsPerDay);

        // Assume that a vehicle is used about 8 hours per day, 5 days a week.
        return Math.floor(days * (5 / 7) * 8);
    }

    /**
     * A special stub method to display a checklist name on entities for the Modex2022.
     * TODO: Remove this, once the vehicle -> checklist relationship has been added.
     */
    public defaultChecklist(): string {
        return "Standard Checklist";
    }
}
