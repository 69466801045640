import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { component, inject } from "tsdi";
import { LoginTokensApi, EmailPasswordLoginInfo, ResponseError } from "../../api";
import { LocalStorageService } from "../../utils/local-storage";
import { LoginToken } from "../../api";
import { ServiceErrors } from "./service-errors";
import { routePath } from "../../routes";
import { HistoryProvider } from "../providers/history-provider";

export enum LoginStatus {
    SUCCESS = "success",
    INVALID = "invalid",
}

export interface LoginResultSuccess {
    readonly status: LoginStatus.SUCCESS;
    readonly loginToken: LoginToken;
}

export interface LoginResultInvalid {
    readonly status: LoginStatus.INVALID;
}

export type LoginResult = LoginResultSuccess | LoginResultInvalid;

export enum LogoutReason {
    USER_LOGOUT = "user",
    INVALID_TOKEN = "invalid",
    NOT_AUTHENTICATED = "not_authenticated",
    UNKNOWN = "unknown",
}

@component
export class ServiceAuth extends LocalStorageService<LoginToken | undefined> {
    @inject private readonly loginTokensApi!: LoginTokensApi;
    @inject private readonly serviceErrors!: ServiceErrors;
    @inject private readonly historyProvider!: HistoryProvider;

    @observable public lastLoginResult: LoginStatus | undefined = undefined;

    constructor() {
        super({
            version: 1,
            defaultValue: undefined,
            identifier: "auth",
        });
        makeObservable(this);
    }

    /** Check whether the user is logged in. */
    @computed public get isLoggedIn(): boolean {
        return this.storedValue !== undefined;
    }

    /** Retrieve stored login token. */
    @computed public get loginToken(): LoginToken | undefined {
        return this.storedValue;
    }

    /** Attempt to login using the provided credentials. The token will be stored. */
    @action.bound public async login(info: EmailPasswordLoginInfo): Promise<LoginResult> {
        if (this.isLoggedIn) {
            throw new Error("Already signed in. Cannot sign in again.");
        }
        try {
            const loginToken = await this.loginTokensApi.loginTokensCreateLoginToken({
                authenticationInfo: {
                    emailPassword: info,
                },
            });
            runInAction(() => {
                this.storedValue = loginToken;
                this.lastLoginResult = LoginStatus.SUCCESS;
            });
            return { status: LoginStatus.SUCCESS, loginToken };
        } catch (error) {
            if (!(error instanceof ResponseError)) {
                this.serviceErrors.reportError({
                    title: "component.errorBar.auth.login.unknownReason.title",
                    description: "component.errorBar.auth.login.unknownReason.description",
                    error: error as Error,
                    fatal: true,
                });
            } else if (error.response.status !== 401) {
                this.serviceErrors.reportResponse(error.response);
            }
            runInAction(() => (this.lastLoginResult = LoginStatus.INVALID));
            return { status: LoginStatus.INVALID };
        }
    }

    /** Attempt to login using the provided email login token. The token will be stored. */
    @action.bound public async loginWithEmailLoginToken(
        emailLoginToken: string,
    ): Promise<LoginResult> {
        if (this.isLoggedIn) {
            throw new Error("Already signed in. Cannot sign in again.");
        }
        try {
            const loginToken = await this.loginTokensApi.loginTokensCreateLoginToken({
                authenticationInfo: {
                    emailLoginToken,
                },
            });
            runInAction(() => {
                this.storedValue = loginToken;
                this.lastLoginResult = LoginStatus.SUCCESS;
            });
            return { status: LoginStatus.SUCCESS, loginToken };
        } catch (error) {
            if (!(error instanceof ResponseError)) {
                this.serviceErrors.reportError({
                    title: "component.errorBar.auth.login.unknownReason.title",
                    description: "component.errorBar.auth.login.unknownReason.description",
                    error: error as Error,
                    fatal: true,
                });
            } else if (error.response.status !== 401) {
                this.serviceErrors.reportResponse(error.response);
            }
            runInAction(() => (this.lastLoginResult = LoginStatus.INVALID));
            return { status: LoginStatus.INVALID };
        }
    }

    /** Sign out by invalidating the token on the backend and clearing it from the local storage. */
    @action.bound public async logout(reason: LogoutReason): Promise<void> {
        if (!this.loginToken) {
            throw new Error("Not signed in. Cannot sign out.");
        }
        try {
            await this.loginTokensApi.loginTokensDeleteLoginToken({
                loginTokenId: this.loginToken.id,
            });
            this.storedValue = undefined;
            // Reload the page to completely clear all cached data
            window.location.href = routePath.login(reason);
        } catch (error) {
            if (!(error instanceof ResponseError)) {
                this.serviceErrors.reportError({
                    title: "component.errorBar.auth.logout.unknownReason.title",
                    description: "component.errorBar.auth.logout.unknownReason.description",
                    error: error as Error,
                });
            } else {
                this.serviceErrors.reportResponse(error.response);
            }
        }
    }

    /**
     * Sign out the user because their token expired.
     * Report the failed request as well to allow diagnosing issues in tests.
     */
    @action.bound public invalidateSession(error?: Response): void {
        this.serviceErrors.reportError({
            title: "component.errorBar.auth.invalid.title",
            description: "component.errorBar.auth.invalid.description",
            error,
        });
        this.storedValue = undefined;

        // For privacy reasons and because we treat the internal state as completely broken at
        // this point.
        this.historyProvider.history.push(routePath.login(LogoutReason.INVALID_TOKEN), {
            from: this.historyProvider.history.location,
        });
    }
}
