import { History, Location } from "history";
import { action, computed, makeObservable, observable } from "mobx";
import { component } from "tsdi";
import * as qs from "query-string";
import { Route } from "../../routes";
import { pathToRegexp } from "path-to-regexp";

@component
export class HistoryProvider {
    @observable private queryParams = new Map<string, string | undefined | (string | null)[]>();

    constructor(private _history: History) {
        makeObservable(this);
        this.setQueryParamsFromUrl(this.history.location);
        this.history.listen(({ location }) => this.setQueryParamsFromUrl(location));
    }

    /** Refresh the query from the provided URL. */
    @action.bound private setQueryParamsFromUrl(location: Location): void {
        this.queryParams.clear();
        Object.entries(qs.parse(location.search)).map(([key, value]) =>
            this.queryParams.set(key, value ?? undefined),
        );
    }

    /** Provides the currently used History. */
    public get history(): History {
        return this._history;
    }

    /**
     * Set a query parameter.
     * This is a reactive MobX action.
     * Calling this will perform a change of the URL.
     */
    @action.bound public setQueryParam(
        name: string,
        value: string | undefined | (string | null)[],
    ): void {
        this.history.push({
            search: this.getQueryStringWithParam(name, value),
        });
    }

    /** Returns a query string with the current query and an additional parameter. */
    private getQueryStringWithParam(
        name: string,
        value: string | undefined | (string | null)[],
    ): string {
        const existingParams = Object.fromEntries(this.queryParams.entries());
        return qs.stringify({
            ...existingParams,
            [name]: value,
        });
    }

    /** Get the search part of the current location as string. */
    @computed public get queryString(): string {
        return qs.stringify(Object.fromEntries(this.queryParams.entries()));
    }

    /**
     * Retrieve the value for a query parameter from the URL.
     * This is a reactive method that will react to invocations of {@link HistoryProvider.setQueryParam}.
     */
    public getQueryParam(name: string): string | undefined | (string | null)[] {
        return this.queryParams.get(name);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public isOnRoute(route: Route<any>): boolean {
        return Boolean(pathToRegexp(route.pattern).exec(this.history.location.pathname));
    }
}
