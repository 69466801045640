/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleProductivityListUpdate
 */
export interface VehicleProductivityListUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityListUpdate
     */
    vehicleSerialNumber?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityListUpdate
     */
    vehicleManufacturer?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityListUpdate
     */
    vehicleModel?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityListUpdate
     */
    vehicleEngineType?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityListUpdate
     */
    vehicleManufactureYear?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityListUpdate
     */
    vehicleLastSynchronizedAt?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityListUpdate
     */
    vehicleTypeLabel?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityListUpdate
     */
    departmentLabel?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityListUpdate
     */
    vehicleGroupLabel?: boolean | null;
}

/**
 * Check if a given object implements the VehicleProductivityListUpdate interface.
 */
export function instanceOfVehicleProductivityListUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleProductivityListUpdateFromJSON(json: any): VehicleProductivityListUpdate {
    return VehicleProductivityListUpdateFromJSONTyped(json, false);
}

export function VehicleProductivityListUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleProductivityListUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicleSerialNumber': !exists(json, 'vehicleSerialNumber') ? undefined : json['vehicleSerialNumber'],
        'vehicleManufacturer': !exists(json, 'vehicleManufacturer') ? undefined : json['vehicleManufacturer'],
        'vehicleModel': !exists(json, 'vehicleModel') ? undefined : json['vehicleModel'],
        'vehicleEngineType': !exists(json, 'vehicleEngineType') ? undefined : json['vehicleEngineType'],
        'vehicleManufactureYear': !exists(json, 'vehicleManufactureYear') ? undefined : json['vehicleManufactureYear'],
        'vehicleLastSynchronizedAt': !exists(json, 'vehicleLastSynchronizedAt') ? undefined : json['vehicleLastSynchronizedAt'],
        'vehicleTypeLabel': !exists(json, 'vehicleTypeLabel') ? undefined : json['vehicleTypeLabel'],
        'departmentLabel': !exists(json, 'departmentLabel') ? undefined : json['departmentLabel'],
        'vehicleGroupLabel': !exists(json, 'vehicleGroupLabel') ? undefined : json['vehicleGroupLabel'],
    };
}

export function VehicleProductivityListUpdateToJSON(value?: VehicleProductivityListUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicleSerialNumber': value.vehicleSerialNumber,
        'vehicleManufacturer': value.vehicleManufacturer,
        'vehicleModel': value.vehicleModel,
        'vehicleEngineType': value.vehicleEngineType,
        'vehicleManufactureYear': value.vehicleManufactureYear,
        'vehicleLastSynchronizedAt': value.vehicleLastSynchronizedAt,
        'vehicleTypeLabel': value.vehicleTypeLabel,
        'departmentLabel': value.departmentLabel,
        'vehicleGroupLabel': value.vehicleGroupLabel,
    };
}

