import { autorun, computed, makeObservable } from "mobx";
import { component, initialize, inject } from "tsdi";
import { Department, User } from "../../api";
import { RepositoryUsers } from "../repositories/repository-users";
import { ServiceAuth } from "./service-auth";
import * as Sentry from "@sentry/browser";
import { RepositoryDepartments } from "../repositories/repository-departments";

// This service manages the currently logged in user.
@component
export class ServiceOwnUser {
    @inject private readonly serviceAuth!: ServiceAuth;
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly repositoryDepartments!: RepositoryDepartments;

    constructor() {
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        autorun(() => {
            if (this.user === undefined) {
                return;
            }
            Sentry.setUser({ username: this.user.employeeId, id: this.user.id });
        });
    }

    @computed public get user(): User | undefined {
        // The frontend only works with UserLogin tokens, which is why
        // we can safely cast this to the expected type.
        const userId = this.serviceAuth.loginToken?.userId;
        if (!userId) {
            return undefined;
        }

        return this.repositoryUsers.byId(userId);
    }

    @computed public get userDepartment(): Department | undefined {
        if (!this.user || !this.user.departmentId) {
            return;
        }
        return this.repositoryDepartments.byId(this.user.departmentId);
    }
}
