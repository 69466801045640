/* eslint-disable filenames/no-index */
import * as React from "react";
import * as SentryReact from "@sentry/react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import "flag-icon-css/css/flag-icon.min.css";
/* eslint-disable no-restricted-imports */
import "reactflow/dist/style.css";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { I18nProvider } from "./domain/providers/i18n-provider";
import { ServiceSentry } from "./domain/services/service-sentry";
import { ServicePublicSettings } from "./domain/services/service-public-settings";
import { ServiceSse } from "./domain/services/service-sse";
import { Main } from "./main";
import { HistoryProvider } from "./domain/providers/history-provider";
import { ServiceAuth } from "./domain/services/service-auth";
import { setTsdi, tsdi } from "./tsdi";
import { createApiConfiguration } from "./utils/api-configuration";
import { Application } from "./application";
import { TSDI } from "tsdi";

// Fluent UI expects this method to have been called, in order for the icons to be usable.
// Explicitly set the CDN endpoint here to match our Content Security Policy as defined in the backend.
// If changing this, take care to adjust the CSP as well.
initializeIcons("https://spoprod-a.akamaihd.net/files/fabric/assets/icons/");

(async () => {
    setTsdi(new TSDI(new Application(createApiConfiguration({ tsdi: () => tsdi }))));
    // Getting some services early to always start them when the application boots.
    // Without this block, they wouldn't be initialized at all.
    tsdi.get(ServiceSentry);
    tsdi.get(ServicePublicSettings);
    tsdi.get(ServiceSse);

    // Create root element for page.
    const parentElement = document.createElement("div");
    parentElement.id = "main";
    document.body.appendChild(parentElement);

    // Wait for the i18n provider to load become ready.
    await tsdi.asyncGet(I18nProvider);

    // Decorate `<Main />` component with Sentry HOCs.
    const MainComponent = SentryReact.withProfiler(SentryReact.withErrorBoundary(Main, {}));

    const history = tsdi.get(HistoryProvider).history;

    // Start React.
    const root = createRoot(parentElement);
    root.render(<MainComponent history={history} serviceAuth={tsdi.get(ServiceAuth)} />);
})();
