/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValidationError } from './ValidationError';
import {
    ValidationErrorFromJSON,
    ValidationErrorFromJSONTyped,
    ValidationErrorToJSON,
} from './ValidationError';

/**
 * 
 * @export
 * @interface ValidationErrorsKind
 */
export interface ValidationErrorsKind {
    /**
     * 
     * @type {{ [key: string]: ValidationErrorsKind; }}
     * @memberof ValidationErrorsKind
     */
    nested?: { [key: string]: ValidationErrorsKind; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: ValidationErrorsKind; }; }}
     * @memberof ValidationErrorsKind
     */
    list?: { [key: string]: { [key: string]: ValidationErrorsKind; }; } | null;
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof ValidationErrorsKind
     */
    field?: Array<ValidationError> | null;
}

/**
 * Check if a given object implements the ValidationErrorsKind interface.
 */
export function instanceOfValidationErrorsKind(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ValidationErrorsKindFromJSON(json: any): ValidationErrorsKind {
    return ValidationErrorsKindFromJSONTyped(json, false);
}

export function ValidationErrorsKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationErrorsKind {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nested': !exists(json, 'nested') ? undefined : (json['nested'] === null ? null : mapValues(json['nested'], ValidationErrorsKindFromJSON)),
        'list': !exists(json, 'list') ? undefined : json['list'],
        'field': !exists(json, 'field') ? undefined : (json['field'] === null ? null : (json['field'] as Array<any>).map(ValidationErrorFromJSON)),
    };
}

export function ValidationErrorsKindToJSON(value?: ValidationErrorsKind | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nested': value.nested === undefined ? undefined : (value.nested === null ? null : mapValues(value.nested, ValidationErrorsKindToJSON)),
        'list': value.list,
        'field': value.field === undefined ? undefined : (value.field === null ? null : (value.field as Array<any>).map(ValidationErrorToJSON)),
    };
}

