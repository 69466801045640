import {
    ActionButton,
    DefaultButton,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Stack,
    TextField,
} from "@fluentui/react";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ElofleetDialog } from "../atoms/elofleet-dialog";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";
import { UserDataExportRequest, UserDataExportResponse, UsersApi } from "../../api";
import { tsdi } from "../../tsdi";
import UserDataExportCredentials from "../atoms/user-data-export-credentials";

/** Stores an email together with its validity. */
interface CheckedEmail {
    email: string;
    valid: boolean;
}

interface UserDataExportButtonProps {
    /** The user one wants to export. */
    userId: string;
}

/** Button that opens a FluentUI dialog for initiating the user data export. */
export default function UserDataExportButton(props: UserDataExportButtonProps): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    const [userAcknowledgedNotice, setUserAcknowledgedNotice] = useState(false);
    const [checkedEmail, setCheckedEmail] = useState<CheckedEmail>({
        email: "",
        valid: true,
    });
    const [credentials, setCredentials] = useState<UserDataExportResponse | undefined>(undefined);
    const [showDialog, setShowDialog] = useState(false);
    const usersApi = tsdi.get(UsersApi);

    function initiateUserDataExport(): void {
        const userDataExportRequest: UserDataExportRequest = {
            recipientEmail: checkedEmail.email,
        };
        usersApi
            .usersInitiateUserDataExport({
                userId: props.userId,
                userDataExportRequest,
            })
            .then((res) => {
                setCredentials(res);
                setCheckedEmail({ ...checkedEmail, valid: true });
            })
            .catch((_err) => {
                // This is a hack for now: The API route returns an error code on an
                // invalid email. If this happens, we catch it and invalidate the
                // current email input.
                setCheckedEmail({ ...checkedEmail, valid: false });
            });
    }

    function setEmail(
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newEmail?: string,
    ): void {
        if (newEmail === undefined) {
            return;
        }
        setCheckedEmail({ ...checkedEmail, email: newEmail });
    }

    return (
        <>
            <ActionButton
                iconProps={{ iconName: "FabricUserFolder", style: { color: "black" } }}
                text={t("component.exportUserButton.text")}
                disabled={showDialog}
                onClick={() => setShowDialog(true)}
            />
            <ElofleetDialog
                isOpen={showDialog}
                title={t("component.exportUserButton.dialog.title")}
                onDismissed={() => {
                    setUserAcknowledgedNotice(false);
                    setCredentials(undefined);
                    setCheckedEmail({ email: "", valid: true });
                }}
            >
                <Stack tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                    <Stack tokens={{ maxWidth: "30rem" }}>
                        <MessageBar messageBarType={MessageBarType.info} isMultiline>
                            <>{t("component.exportUserButton.dialog.info")}</>
                        </MessageBar>
                        <TextField
                            label={t("formUpdateUser.email.label")}
                            placeholder={t("component.exportUserButton.dialog.email.placeholder")}
                            onChange={setEmail}
                            errorMessage={
                                !checkedEmail.valid ? t("validation.invalid.email") : undefined
                            }
                            disabled={credentials !== undefined}
                            required
                        />
                        {credentials && (
                            <UserDataExportCredentials
                                credentials={credentials}
                                email={checkedEmail.email}
                                userAcknowledgedNotice={userAcknowledgedNotice}
                                onChange={setUserAcknowledgedNotice}
                            />
                        )}
                    </Stack>
                </Stack>
                <ElofleetDialogFooter>
                    {credentials === undefined ? (
                        <>
                            <DefaultButton
                                text={t("component.exportUserButton.cancel")}
                                onClick={() => setShowDialog(false)}
                            />
                            <PrimaryButton
                                text={t("component.exportUserButton.text")}
                                onClick={initiateUserDataExport}
                            />
                        </>
                    ) : (
                        <PrimaryButton
                            text={t("component.exportUserButton.dialog.close")}
                            onClick={() => setShowDialog(false)}
                            disabled={!userAcknowledgedNotice}
                        />
                    )}
                </ElofleetDialogFooter>
            </ElofleetDialog>
        </>
    );
}
