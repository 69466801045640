import { MessageBar, MessageBarType } from "@fluentui/react";
import { observer } from "mobx-react";
import React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { ServiceErrors } from "../../domain/services/service-errors";

@external
@observer
export default class ErrorBars extends React.Component {
    @inject private readonly serviceErrors!: ServiceErrors;
    @inject private readonly i18n!: I18nProvider;

    public render(): JSX.Element {
        if (!this.serviceErrors.nextError) {
            return <></>;
        }
        const { title, description, id, additionalInformation, html } =
            this.serviceErrors.nextError;
        return (
            <MessageBar
                messageBarType={MessageBarType.error}
                onDismiss={() => this.serviceErrors.dismissError(id)}
            >
                <h3>
                    <>{this.i18n.t(title, additionalInformation)}</>{" "}
                    <>
                        {this.serviceErrors.count > 1 ? (
                            this.i18n.t("component.errorBar.count", {
                                count: this.serviceErrors.count - 1,
                            })
                        ) : (
                            <></>
                        )}
                    </>
                </h3>
                <p>{this.i18n.t(description, additionalInformation)}</p>
                {html}
            </MessageBar>
        );
    }
}
