/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  MultiUserGroupVehicleGroupAssignmentCreate,
  PageInfo,
  SortDirection,
  UserGroupVehicleGroupAssignment,
  UserGroupVehicleGroupAssignmentCreate,
  UserGroupVehicleGroupAssignmentSortKey,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    MultiUserGroupVehicleGroupAssignmentCreateFromJSON,
    MultiUserGroupVehicleGroupAssignmentCreateToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    UserGroupVehicleGroupAssignmentFromJSON,
    UserGroupVehicleGroupAssignmentToJSON,
    UserGroupVehicleGroupAssignmentCreateFromJSON,
    UserGroupVehicleGroupAssignmentCreateToJSON,
    UserGroupVehicleGroupAssignmentSortKeyFromJSON,
    UserGroupVehicleGroupAssignmentSortKeyToJSON,
} from '../models';

export interface UserGroupVehicleGroupAssignmentsCreateMultipleUserGroupVehicleGroupAssignmentRequest {
    multiUserGroupVehicleGroupAssignmentCreate: MultiUserGroupVehicleGroupAssignmentCreate;
}

export interface UserGroupVehicleGroupAssignmentsCreateUserGroupVehicleGroupAssignmentRequest {
    userGroupVehicleGroupAssignmentCreate: UserGroupVehicleGroupAssignmentCreate;
}

export interface UserGroupVehicleGroupAssignmentsDeleteUserGroupVehicleGroupAssignmentRequest {
    assignmentId: string;
}

export interface UserGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsRequest {
    pageSize: number;
    userGroupId?: string | null;
    vehicleGroupId?: string | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: UserGroupVehicleGroupAssignmentSortKey;
}

export interface UserGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsMetadataRequest {
    pageSize: number;
    userGroupId?: string | null;
    vehicleGroupId?: string | null;
}

export interface UserGroupVehicleGroupAssignmentsReadUserGroupVehicleGroupAssignmentRequest {
    assignmentId: string;
}

export interface UserGroupVehicleGroupAssignmentsValidateCreateUserGroupVehicleGroupAssignmentRequest {
    userGroupVehicleGroupAssignmentCreate: UserGroupVehicleGroupAssignmentCreate;
}

/**
 * UserGroupVehicleGroupAssignmentsApi - interface
 * 
 * @export
 * @interface UserGroupVehicleGroupAssignmentsApiInterface
 */
export interface UserGroupVehicleGroupAssignmentsApiInterface {
    /**
     * This endpoint accepts a list of UserGroup ids and VehicleGroup ids respectively.  Assignments are then created for each UserGroup to each VehicleGroup. Trying to create duplicate assignments won\'t lead to errors and will simply be ignored.
     * @summary Create multiple UserGroupVehicleGroupAssignments
     * @param {MultiUserGroupVehicleGroupAssignmentCreate} multiUserGroupVehicleGroupAssignmentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupVehicleGroupAssignmentsApiInterface
     */
    userGroupVehicleGroupAssignmentsCreateMultipleUserGroupVehicleGroupAssignmentRaw(requestParameters: UserGroupVehicleGroupAssignmentsCreateMultipleUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserGroupVehicleGroupAssignment>>>;

    /**
     * This endpoint accepts a list of UserGroup ids and VehicleGroup ids respectively.  Assignments are then created for each UserGroup to each VehicleGroup. Trying to create duplicate assignments won\'t lead to errors and will simply be ignored.
     * Create multiple UserGroupVehicleGroupAssignments
     */
    userGroupVehicleGroupAssignmentsCreateMultipleUserGroupVehicleGroupAssignment(requestParameters: UserGroupVehicleGroupAssignmentsCreateMultipleUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserGroupVehicleGroupAssignment>>;

    /**
     * Create a new UserGroupVehicleGroupAssignment.
     * @summary Create a UserGroupVehicleGroupAssignment
     * @param {UserGroupVehicleGroupAssignmentCreate} userGroupVehicleGroupAssignmentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupVehicleGroupAssignmentsApiInterface
     */
    userGroupVehicleGroupAssignmentsCreateUserGroupVehicleGroupAssignmentRaw(requestParameters: UserGroupVehicleGroupAssignmentsCreateUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupVehicleGroupAssignment>>;

    /**
     * Create a new UserGroupVehicleGroupAssignment.
     * Create a UserGroupVehicleGroupAssignment
     */
    userGroupVehicleGroupAssignmentsCreateUserGroupVehicleGroupAssignment(requestParameters: UserGroupVehicleGroupAssignmentsCreateUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupVehicleGroupAssignment>;

    /**
     * Delete the user_group_vehicle_group_assignment with the given ID.
     * @summary Delete a user_group_vehicle_group_assignment
     * @param {string} assignmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupVehicleGroupAssignmentsApiInterface
     */
    userGroupVehicleGroupAssignmentsDeleteUserGroupVehicleGroupAssignmentRaw(requestParameters: UserGroupVehicleGroupAssignmentsDeleteUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the user_group_vehicle_group_assignment with the given ID.
     * Delete a user_group_vehicle_group_assignment
     */
    userGroupVehicleGroupAssignmentsDeleteUserGroupVehicleGroupAssignment(requestParameters: UserGroupVehicleGroupAssignmentsDeleteUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Lists all the UserGroupVehicleGroupAssignment present.
     * @summary List UserGroupVehicleGroupAssignment
     * @param {number} pageSize 
     * @param {string} [userGroupId] 
     * @param {string} [vehicleGroupId] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {UserGroupVehicleGroupAssignmentSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupVehicleGroupAssignmentsApiInterface
     */
    userGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsRaw(requestParameters: UserGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserGroupVehicleGroupAssignment>>>;

    /**
     * Lists all the UserGroupVehicleGroupAssignment present.
     * List UserGroupVehicleGroupAssignment
     */
    userGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignments(requestParameters: UserGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserGroupVehicleGroupAssignment>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary UserGroupVehicleGroupAssignment  listing metadata
     * @param {number} pageSize 
     * @param {string} [userGroupId] 
     * @param {string} [vehicleGroupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupVehicleGroupAssignmentsApiInterface
     */
    userGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsMetadataRaw(requestParameters: UserGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * UserGroupVehicleGroupAssignment  listing metadata
     */
    userGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsMetadata(requestParameters: UserGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the UserGroupVehicleGroupAssignment specified by the given ID.
     * @summary Get a single UserGroupVehicleGroupAssignment
     * @param {string} assignmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupVehicleGroupAssignmentsApiInterface
     */
    userGroupVehicleGroupAssignmentsReadUserGroupVehicleGroupAssignmentRaw(requestParameters: UserGroupVehicleGroupAssignmentsReadUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupVehicleGroupAssignment>>;

    /**
     * Returns the UserGroupVehicleGroupAssignment specified by the given ID.
     * Get a single UserGroupVehicleGroupAssignment
     */
    userGroupVehicleGroupAssignmentsReadUserGroupVehicleGroupAssignment(requestParameters: UserGroupVehicleGroupAssignmentsReadUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupVehicleGroupAssignment>;

    /**
     * Validate data for a new UserGroupVehicleGroupAssignment without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a UserGroupVehicleGroupAssignment
     * @param {UserGroupVehicleGroupAssignmentCreate} userGroupVehicleGroupAssignmentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupVehicleGroupAssignmentsApiInterface
     */
    userGroupVehicleGroupAssignmentsValidateCreateUserGroupVehicleGroupAssignmentRaw(requestParameters: UserGroupVehicleGroupAssignmentsValidateCreateUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new UserGroupVehicleGroupAssignment without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a UserGroupVehicleGroupAssignment
     */
    userGroupVehicleGroupAssignmentsValidateCreateUserGroupVehicleGroupAssignment(requestParameters: UserGroupVehicleGroupAssignmentsValidateCreateUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class UserGroupVehicleGroupAssignmentsApi extends runtime.BaseAPI implements UserGroupVehicleGroupAssignmentsApiInterface {

    /**
     * This endpoint accepts a list of UserGroup ids and VehicleGroup ids respectively.  Assignments are then created for each UserGroup to each VehicleGroup. Trying to create duplicate assignments won\'t lead to errors and will simply be ignored.
     * Create multiple UserGroupVehicleGroupAssignments
     */
    async userGroupVehicleGroupAssignmentsCreateMultipleUserGroupVehicleGroupAssignmentRaw(requestParameters: UserGroupVehicleGroupAssignmentsCreateMultipleUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserGroupVehicleGroupAssignment>>> {
        if (requestParameters.multiUserGroupVehicleGroupAssignmentCreate === null || requestParameters.multiUserGroupVehicleGroupAssignmentCreate === undefined) {
            throw new runtime.RequiredError('multiUserGroupVehicleGroupAssignmentCreate','Required parameter requestParameters.multiUserGroupVehicleGroupAssignmentCreate was null or undefined when calling userGroupVehicleGroupAssignmentsCreateMultipleUserGroupVehicleGroupAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_group_vehicle_group_assignments/multi`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MultiUserGroupVehicleGroupAssignmentCreateToJSON(requestParameters.multiUserGroupVehicleGroupAssignmentCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserGroupVehicleGroupAssignmentFromJSON));
    }

    /**
     * This endpoint accepts a list of UserGroup ids and VehicleGroup ids respectively.  Assignments are then created for each UserGroup to each VehicleGroup. Trying to create duplicate assignments won\'t lead to errors and will simply be ignored.
     * Create multiple UserGroupVehicleGroupAssignments
     */
    async userGroupVehicleGroupAssignmentsCreateMultipleUserGroupVehicleGroupAssignment(requestParameters: UserGroupVehicleGroupAssignmentsCreateMultipleUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserGroupVehicleGroupAssignment>> {
        const response = await this.userGroupVehicleGroupAssignmentsCreateMultipleUserGroupVehicleGroupAssignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new UserGroupVehicleGroupAssignment.
     * Create a UserGroupVehicleGroupAssignment
     */
    async userGroupVehicleGroupAssignmentsCreateUserGroupVehicleGroupAssignmentRaw(requestParameters: UserGroupVehicleGroupAssignmentsCreateUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupVehicleGroupAssignment>> {
        if (requestParameters.userGroupVehicleGroupAssignmentCreate === null || requestParameters.userGroupVehicleGroupAssignmentCreate === undefined) {
            throw new runtime.RequiredError('userGroupVehicleGroupAssignmentCreate','Required parameter requestParameters.userGroupVehicleGroupAssignmentCreate was null or undefined when calling userGroupVehicleGroupAssignmentsCreateUserGroupVehicleGroupAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_group_vehicle_group_assignments/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupVehicleGroupAssignmentCreateToJSON(requestParameters.userGroupVehicleGroupAssignmentCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupVehicleGroupAssignmentFromJSON(jsonValue));
    }

    /**
     * Create a new UserGroupVehicleGroupAssignment.
     * Create a UserGroupVehicleGroupAssignment
     */
    async userGroupVehicleGroupAssignmentsCreateUserGroupVehicleGroupAssignment(requestParameters: UserGroupVehicleGroupAssignmentsCreateUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupVehicleGroupAssignment> {
        const response = await this.userGroupVehicleGroupAssignmentsCreateUserGroupVehicleGroupAssignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the user_group_vehicle_group_assignment with the given ID.
     * Delete a user_group_vehicle_group_assignment
     */
    async userGroupVehicleGroupAssignmentsDeleteUserGroupVehicleGroupAssignmentRaw(requestParameters: UserGroupVehicleGroupAssignmentsDeleteUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.assignmentId === null || requestParameters.assignmentId === undefined) {
            throw new runtime.RequiredError('assignmentId','Required parameter requestParameters.assignmentId was null or undefined when calling userGroupVehicleGroupAssignmentsDeleteUserGroupVehicleGroupAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_group_vehicle_group_assignments/{assignment_id}`.replace(`{${"assignment_id"}}`, encodeURIComponent(String(requestParameters.assignmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the user_group_vehicle_group_assignment with the given ID.
     * Delete a user_group_vehicle_group_assignment
     */
    async userGroupVehicleGroupAssignmentsDeleteUserGroupVehicleGroupAssignment(requestParameters: UserGroupVehicleGroupAssignmentsDeleteUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userGroupVehicleGroupAssignmentsDeleteUserGroupVehicleGroupAssignmentRaw(requestParameters, initOverrides);
    }

    /**
     * Lists all the UserGroupVehicleGroupAssignment present.
     * List UserGroupVehicleGroupAssignment
     */
    async userGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsRaw(requestParameters: UserGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserGroupVehicleGroupAssignment>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling userGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignments.');
        }

        const queryParameters: any = {};

        if (requestParameters.userGroupId !== undefined) {
            queryParameters['user_group_id'] = requestParameters.userGroupId;
        }

        if (requestParameters.vehicleGroupId !== undefined) {
            queryParameters['vehicle_group_id'] = requestParameters.vehicleGroupId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_group_vehicle_group_assignments/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserGroupVehicleGroupAssignmentFromJSON));
    }

    /**
     * Lists all the UserGroupVehicleGroupAssignment present.
     * List UserGroupVehicleGroupAssignment
     */
    async userGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignments(requestParameters: UserGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserGroupVehicleGroupAssignment>> {
        const response = await this.userGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * UserGroupVehicleGroupAssignment  listing metadata
     */
    async userGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsMetadataRaw(requestParameters: UserGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling userGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.userGroupId !== undefined) {
            queryParameters['user_group_id'] = requestParameters.userGroupId;
        }

        if (requestParameters.vehicleGroupId !== undefined) {
            queryParameters['vehicle_group_id'] = requestParameters.vehicleGroupId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_group_vehicle_group_assignments/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * UserGroupVehicleGroupAssignment  listing metadata
     */
    async userGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsMetadata(requestParameters: UserGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.userGroupVehicleGroupAssignmentsListUserGroupVehicleGroupAssignmentsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the UserGroupVehicleGroupAssignment specified by the given ID.
     * Get a single UserGroupVehicleGroupAssignment
     */
    async userGroupVehicleGroupAssignmentsReadUserGroupVehicleGroupAssignmentRaw(requestParameters: UserGroupVehicleGroupAssignmentsReadUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupVehicleGroupAssignment>> {
        if (requestParameters.assignmentId === null || requestParameters.assignmentId === undefined) {
            throw new runtime.RequiredError('assignmentId','Required parameter requestParameters.assignmentId was null or undefined when calling userGroupVehicleGroupAssignmentsReadUserGroupVehicleGroupAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_group_vehicle_group_assignments/{assignment_id}`.replace(`{${"assignment_id"}}`, encodeURIComponent(String(requestParameters.assignmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupVehicleGroupAssignmentFromJSON(jsonValue));
    }

    /**
     * Returns the UserGroupVehicleGroupAssignment specified by the given ID.
     * Get a single UserGroupVehicleGroupAssignment
     */
    async userGroupVehicleGroupAssignmentsReadUserGroupVehicleGroupAssignment(requestParameters: UserGroupVehicleGroupAssignmentsReadUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupVehicleGroupAssignment> {
        const response = await this.userGroupVehicleGroupAssignmentsReadUserGroupVehicleGroupAssignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new UserGroupVehicleGroupAssignment without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a UserGroupVehicleGroupAssignment
     */
    async userGroupVehicleGroupAssignmentsValidateCreateUserGroupVehicleGroupAssignmentRaw(requestParameters: UserGroupVehicleGroupAssignmentsValidateCreateUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userGroupVehicleGroupAssignmentCreate === null || requestParameters.userGroupVehicleGroupAssignmentCreate === undefined) {
            throw new runtime.RequiredError('userGroupVehicleGroupAssignmentCreate','Required parameter requestParameters.userGroupVehicleGroupAssignmentCreate was null or undefined when calling userGroupVehicleGroupAssignmentsValidateCreateUserGroupVehicleGroupAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user_group_vehicle_group_assignments/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupVehicleGroupAssignmentCreateToJSON(requestParameters.userGroupVehicleGroupAssignmentCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new UserGroupVehicleGroupAssignment without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a UserGroupVehicleGroupAssignment
     */
    async userGroupVehicleGroupAssignmentsValidateCreateUserGroupVehicleGroupAssignment(requestParameters: UserGroupVehicleGroupAssignmentsValidateCreateUserGroupVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userGroupVehicleGroupAssignmentsValidateCreateUserGroupVehicleGroupAssignmentRaw(requestParameters, initOverrides);
    }

}
