import * as React from "react";
import { observer } from "mobx-react";
import { Stack, TextField, DefaultButton, IColumn, Separator } from "@fluentui/react";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { VehicleGroup, UserGroup, User, UserGroupUpdate } from "../../api";

import { ServiceListStates } from "../../domain/services/service-list-states";

import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import { FormUpdateProps } from "../../utils/form-update-props";
import { ModalConfirmation } from "../atoms/modal-confirmation";
import { MultiSelectWithList } from "../organisms/multi-select-with-list";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";

import { RepositoryVehicleGroups } from "../../domain/repositories/repository-vehicle-groups";
import { RepositoryUserGroups } from "../../domain/repositories/repository-user-groups";
import { RepositoryUsers } from "../../domain/repositories/repository-users";

import { createUuid, UUID } from "../../utils/uuid";
import { doubleBindString } from "../../utils/double-bind";
import { compareSets } from "../../utils/set-comparison";
import sizes from "../sizes.scss";
import { defaultPageSize } from "../../utils/constants";

export interface FormUpdateUserGroupProps extends FormUpdateProps<UserGroup> {}

@observer
@external
export class FormUpdateUserGroup extends React.Component<FormUpdateUserGroupProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject private readonly repositoryVehicleGroups!: RepositoryVehicleGroups;
    @inject private readonly repositoryUserGroups!: RepositoryUserGroups;
    @inject private readonly repositoryUsers!: RepositoryUsers;

    @observable private cancelConfirmationModalVisible = false;

    // This list contains the ids of the assignments that already exist for this group.
    // I.e. the Users and VehicleGroups that have been selected for assignments.
    @observable private originalUsers: UUID[] = [];
    @observable private originalVehicleGroups: UUID[] = [];

    // These lists contain the ids of the assignments that're currently selected in the
    // multi-select dropdown.
    //
    // By comparing these with the original lists above, we can determine the changeset
    // of removed and added entities.
    @observable private selectedUsers: UUID[] = [];
    @observable private selectedVehicleGroups: UUID[] = [];

    // The list state ids for the respective lists of added groups.
    private userListStateId = createUuid();
    private vehicleGroupListStateId = createUuid();

    private validationId = createUuid();

    constructor(props: FormUpdateUserGroupProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositoryUserGroups.validation.update.updateModel(
            this.validationId,
            this.userGroupUpdate,
        );
    }

    @initialize protected initialize(): void {
        this.repositoryUserGroups.validation.update.initializeModel(
            this.validationId,
            this.userGroupUpdate,
            this.props.id,
        );

        this.serviceListStates.users.initializeList(this.userListStateId, {
            query: () => {
                return { pageSize: defaultPageSize };
            },
            ignoreUrl: true,
        });
        this.serviceListStates.vehicleGroups.initializeList(this.vehicleGroupListStateId, {
            query: () => {
                return { pageSize: defaultPageSize };
            },
            ignoreUrl: true,
        });

        this.getInitialAssignments();
    }

    public componentWillUnmount(): void {
        this.repositoryUserGroups.discardMutableCopy(FormUpdateUserGroup.name, this.props.id);
    }

    /**
     * Get the Users and VehileGroups that're already assigned to this UserGroup.
     * This is done asynchronous once when initializing this component.
     *
     * Marking this function with @action.bound doesn't work as it's `async`.
     */
    async getInitialAssignments(): Promise<void> {
        // Get the list of all assigned VehicleGroups
        const vehicleGroups = await this.repositoryVehicleGroups.byQueryAsync({
            pageSize: defaultPageSize,
            userGroupIds: [this.props.id],
        });
        this.originalVehicleGroups = vehicleGroups.map((vehicleGroup) => vehicleGroup.id);

        const users = await this.repositoryUsers.byQueryAsync({
            pageSize: defaultPageSize,
            userGroupIds: [this.props.id],
        });
        this.originalUsers = users.map((userGroup) => userGroup.id);

        runInAction(() => {
            this.selectedUsers = this.originalUsers;
            this.selectedVehicleGroups = this.originalVehicleGroups;
        });
    }

    @computed private get userGroup(): UserGroup | undefined {
        return this.repositoryUserGroups.mutableCopyById(FormUpdateUserGroup.name, this.props.id);
    }

    @computed private get userGroupUpdate(): UserGroupUpdate {
        const vehicleGroupChanges = compareSets(
            this.originalVehicleGroups,
            this.selectedVehicleGroups,
        );
        const userChanges = compareSets(this.originalUsers, this.selectedUsers);

        const update = {
            label: this.userGroup?.label,
            addVehicleGroupIds: vehicleGroupChanges.added,
            removeVehicleGroupIds: vehicleGroupChanges.removed,
            addUserIds: userChanges.added,
            removeUserIds: userChanges.removed,
        } as UserGroupUpdate;

        return update;
    }

    @action.bound
    private async updateUserGroup(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const update = this.userGroupUpdate;
        const userGroup = await this.repositoryUserGroups.update(this.props.id, update);

        // Reload Queries in here.
        // This cannot be done in the repositories itself, as they would have circular
        // dependencies on each other otherwise.
        if (update.addVehicleGroupIds || update.removeVehicleGroupIds) {
            this.repositoryVehicleGroups.reloadQuery({
                pageSize: defaultPageSize,
            });
            this.repositoryVehicleGroups.reloadQuery({
                pageSize: defaultPageSize,
                userGroupIds: [this.props.id],
            });
        }
        if (update.addUserIds || update.removeUserIds) {
            this.repositoryUsers.reloadQuery({
                pageSize: defaultPageSize,
            });
            this.repositoryUsers.reloadQuery({
                pageSize: defaultPageSize,
                userGroupIds: [this.props.id],
            });
        }

        this.reset();
        this.props.onUpdate(userGroup);
    }

    /**
     * This is run if the user clicks the form's cancel button.
     * A confirmation modal might pop up if there are any changes.
     */
    @action.bound private showConfirmAndCallDialogCancelCallback(): void {
        // Compare the assignments
        const userGroupChanges = compareSets(
            this.originalVehicleGroups,
            this.selectedVehicleGroups,
        );
        const vehicleChanges = compareSets(this.originalUsers, this.selectedUsers);

        // If there were any changes in assignments, ask the user if they really want to cancel.
        if (userGroupChanges.differs() || vehicleChanges.differs()) {
            this.cancelConfirmationModalVisible = true;
            return;
        }

        this.props.onDialogCancel();
    }

    /**
     * Actually close the component and reset all state.
     */
    @action.bound private closeForm(): void {
        this.reset();
        this.cancelConfirmationModalVisible = false;
        this.props.onDialogCancel();
    }

    @action.bound private reset(): void {
        this.originalUsers = [];
        this.originalVehicleGroups = [];
        this.selectedUsers = [];
        this.selectedVehicleGroups = [];
    }

    @action.bound private closeCancelConfirmationModalVisible(): void {
        this.cancelConfirmationModalVisible = false;
    }

    /**
     * Handle the actual addition of all currently selected Vehicles in the multi-select field.
     */
    @action.bound private addUsers(users: UUID[]): void {
        this.selectedUsers = [...this.selectedUsers, ...users];
    }

    /**
     * Handle the removal of Users.
     * In this case, removal means that they'll be removed from the list of added Users.
     */
    @action.bound private removeUsers(idsToRemove: UUID[]): void {
        const remainingUsers = this.selectedUsers.filter((id) => !idsToRemove.includes(id));
        this.selectedUsers = remainingUsers;
    }

    /**
     * Handle the actual addition of all currently selected VehicleGroups in the multi-select field.
     */
    @action.bound private addVehicleGroups(vehicleGroups: UUID[]): void {
        this.selectedVehicleGroups = [...this.selectedVehicleGroups, ...vehicleGroups];
    }

    /**
     * Handle the removal of VehicleGroups.
     * In this case, removal means that they'll be removed from the list of added VehicleGroups.
     */
    @action.bound private removeVehicleGroups(idsToRemove: UUID[]): void {
        const remainingGroups = this.selectedVehicleGroups.filter(
            (id) => !idsToRemove.includes(id),
        );
        this.selectedVehicleGroups = remainingGroups;
    }

    /**
     * The items that should be shown in the list of added VehicleGroups.
     */
    @computed private get vehicleGroupItems(): Object[] {
        const groups = [];

        for (const id of this.selectedVehicleGroups) {
            const group = this.repositoryVehicleGroups.byId(id);
            if (group !== undefined) {
                groups.push({
                    key: group.id,
                    label: group.label,
                    assignedVehicleCount: group.assignedVehicleCount,
                    assignedUserGroupCount: group.assignedUserGroupCount,
                });
            }
        }

        return groups;
    }

    /**
     * The columns that should be shown in the list of selected VehicleGroups.
     */
    @computed private get vehicleGroupColumns(): IColumn[] {
        return [
            {
                fieldName: "label",
                name: this.i18n.t("listVehicleGroups.column.label.name"),
                key: "label",
                minWidth: 100,
            },
            {
                fieldName: "assignedVehicleCount",
                name: this.i18n.t("listVehicleGroups.column.assignedVehicleCount.name"),
                key: "assignedVehicleCount",
                minWidth: 80,
            },
            {
                fieldName: "assignedUserGroupCount",
                name: this.i18n.t("listVehicleGroups.column.assignedUserGroupCount.name"),
                key: "assignedUserGroupCount",
                minWidth: 150,
            },
        ];
    }

    /**
     * The items that should be shown in the list of added Users.
     */
    @computed private get userItems(): Object[] {
        const users = [];

        for (const id of this.selectedUsers) {
            const user = this.repositoryUsers.byId(id);
            if (user !== undefined) {
                users.push({
                    key: user.id,
                    employeeId: user.employeeId,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    jobTitle: user.jobTitle,
                    fleetRole: this.i18n.formatFleetRole(user.fleetRole),
                });
            }
        }

        return users;
    }

    /**
     * The columns that should be shown in the list of selected Users.
     */
    @computed private get userColumns(): IColumn[] {
        return [
            {
                fieldName: "employeeId",
                name: this.i18n.t("listUsers.column.employeeId.name"),
                key: "employeeId",
                minWidth: 200,
                maxWidth: 300,
            },
            {
                fieldName: "firstName",
                name: this.i18n.t("listUsers.column.firstName.name"),
                key: "firstName",
                minWidth: 80,
                maxWidth: 100,
            },
            {
                fieldName: "lastName",
                name: this.i18n.t("listUsers.column.lastName.name"),
                key: "lastName",
                minWidth: 100,
                maxWidth: 150,
            },
            {
                fieldName: "jobtitle",
                name: this.i18n.t("listUsers.column.jobTitle.name"),
                key: "jobTitle",
                minWidth: 100,
                maxWidth: 150,
            },
            {
                fieldName: "fleetRole",
                name: this.i18n.t("listUsers.column.fleetRole.name"),
                key: "fleetRole",
                minWidth: 120,
                maxWidth: 200,
            },
        ];
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formUpdateUserGroup.submit.text")}
                validation={this.repositoryUserGroups.validation.update}
                validationId={this.validationId}
            />
        );
        return (
            <form onSubmit={this.updateUserGroup}>
                <Stack tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                    <TextField
                        label={this.i18n.t("formUpdateUserGroup.label.label")}
                        disabled={!this.userGroup}
                        {...doubleBindString(this.userGroup!, "label", () =>
                            this.triggerValidation(),
                        )}
                        required
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryUserGroups.validation.update.getFieldValidationState(
                                this.validationId,
                                "label",
                            ),
                        )}
                    />
                </Stack>
                <Stack
                    horizontal
                    styles={{
                        root: {
                            minWidth: "1200px",
                            minHeight: "400px",
                            padding: sizes.xl,
                        },
                    }}
                >
                    <Stack.Item tokens={{ padding: `0px 0px 0px ${sizes.m}` }}>
                        <MultiSelectWithList
                            repository={this.repositoryUsers}
                            onAdd={this.addUsers}
                            onDelete={this.removeUsers}
                            listStateId={this.userListStateId}
                            listState={this.serviceListStates.users}
                            addedEntities={this.selectedUsers}
                            columns={this.userColumns}
                            items={this.userItems}
                            formatEntity={(user: User) => this.i18n.formatUserFullName(user)}
                            addButtonText={this.i18n.t("component.multiSelect.addButton")}
                            removeButtonText={this.i18n.t("component.multiSelect.removeButton")}
                            dropdownLabel={this.i18n.t("formCreateUserGroup.user.label")}
                            multiSelectPlaceholder={this.i18n.t(
                                "formCreateUserGroup.user.placeholder",
                            )}
                        />
                    </Stack.Item>
                    <Stack.Item tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                        <Separator vertical />
                    </Stack.Item>
                    <Stack.Item tokens={{ padding: `0px ${sizes.m} 0px 0px` }}>
                        <MultiSelectWithList
                            repository={this.repositoryVehicleGroups}
                            onAdd={this.addVehicleGroups}
                            onDelete={this.removeVehicleGroups}
                            listStateId={this.vehicleGroupListStateId}
                            listState={this.serviceListStates.vehicleGroups}
                            addedEntities={this.selectedVehicleGroups}
                            columns={this.vehicleGroupColumns}
                            items={this.vehicleGroupItems}
                            formatEntity={(group: VehicleGroup) => group.label}
                            addButtonText={this.i18n.t("component.multiSelect.addButton")}
                            removeButtonText={this.i18n.t("component.multiSelect.removeButton")}
                            dropdownLabel={this.i18n.t("formCreateUserGroup.vehicleGroup.label")}
                            multiSelectPlaceholder={this.i18n.t(
                                "formCreateUserGroup.vehicleGroup.placeholder",
                            )}
                        />
                    </Stack.Item>
                </Stack>
                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            label={this.i18n.t("formUpdateUserGroup.cancel.label")}
                            text={this.i18n.t("formUpdateUserGroup.cancel.text")}
                            onClick={this.showConfirmAndCallDialogCancelCallback}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
                <ModalConfirmation
                    isOpen={this.cancelConfirmationModalVisible}
                    title={this.i18n.t("modalAbortUpdate.title")}
                    text={this.i18n.t("modalAbortUpdate.description")}
                    onConfirm={this.closeForm}
                    onCancel={this.closeCancelConfirmationModalVisible}
                />
            </form>
        );
    }
}
