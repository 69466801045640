/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EloshieldModule {
    PEDESTRIAN = 'pedestrian',
    VEHICLE = 'vehicle',
    STATION = 'station'
}


export function EloshieldModuleFromJSON(json: any): EloshieldModule {
    return EloshieldModuleFromJSONTyped(json, false);
}

export function EloshieldModuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): EloshieldModule {
    return json as EloshieldModule;
}

export function EloshieldModuleToJSON(value?: EloshieldModule | null): any {
    return value as any;
}

