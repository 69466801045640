import { ActionButton } from "@fluentui/react";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { external, inject } from "tsdi";
import { PreOpsAnswerChoice } from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { MultiSelectComboBox } from "../atoms/multi-select-connected-combo-box";

export interface FilterPreOpsAnswerListProps {
    /** This callback function allows the filter to rerender
     * the parent component. */
    setPreOpsAnswerListFilter(filter: FilterPreOpsAnswerListQuery): void;
}

export type FilterPreOpsAnswerListQuery = {
    filteredChoices: PreOpsAnswerChoice[];
    isFiltered: boolean;
};

@external
@observer
export class FilterPreOpsAnswerList extends React.Component<FilterPreOpsAnswerListProps> {
    @inject private readonly i18n!: I18nProvider;

    @observable private filteredChoices: PreOpsAnswerChoice[] = [];
    @observable private isFiltered: boolean = false;

    constructor(props: FilterPreOpsAnswerListProps) {
        super(props);
        makeObservable(this);
    }

    private setPreOpsAnswerChoiceFilter(filter: PreOpsAnswerChoice): void {
        // Remove element if it is already clicked.
        if (this.filteredChoices.includes(filter)) {
            this.filteredChoices = this.filteredChoices.filter((id) => id !== filter);
            this.setPreOpsAnswerListFilter();
            return;
        }
        this.isFiltered = true;
        this.filteredChoices = [filter, ...this.filteredChoices];
        this.setPreOpsAnswerListFilter();
    }

    @action.bound private clearFilter(): void {
        this.isFiltered = false;
        this.filteredChoices = [];
        this.setPreOpsAnswerListFilter();
    }

    private setPreOpsAnswerListFilter(): void {
        const filterQuery: FilterPreOpsAnswerListQuery = {
            filteredChoices: this.filteredChoices,
            isFiltered: this.isFiltered,
        };
        this.props.setPreOpsAnswerListFilter(filterQuery);
    }

    public render(): JSX.Element {
        return (
            <>
                <MultiSelectComboBox
                    entities={[
                        { id: PreOpsAnswerChoice.CRITICAL },
                        { id: PreOpsAnswerChoice.OK },
                        { id: PreOpsAnswerChoice.GOOD },
                    ]}
                    allowFreeForm={false}
                    placeholder={this.i18n.t("listPreOpsAnswers.column.choice.name")}
                    formatEntity={(level: { id: string }) =>
                        this.i18n.t(`schema.preOpsAnswerChoice.${level.id}`)
                    }
                    selectedKeys={this.filteredChoices}
                    onChange={(choice: string | undefined) => {
                        if (
                            choice === undefined ||
                            !Object.values(PreOpsAnswerChoice).includes(
                                choice as PreOpsAnswerChoice,
                            )
                        ) {
                            return;
                        }
                        this.setPreOpsAnswerChoiceFilter(choice as PreOpsAnswerChoice);
                    }}
                    allowSelectAll
                />

                <ActionButton
                    iconProps={{ iconName: "clearFilter" }}
                    allowDisabledFocus
                    disabled={!this.isFiltered}
                    checked={true}
                    onClick={this.clearFilter}
                ></ActionButton>
            </>
        );
    }
}
