import { observer } from "mobx-react";
import * as React from "react";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { declareRoute, PageVehicleSettingsTab, routePath, RouteProps } from "../routes";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { ListVehicles } from "../ui/molecules/list-vehicles";
import { ListVehicleGroups } from "../ui/molecules/list-vehicle-groups";
import { ListCommandButtonsVehicles } from "../ui/organisms/list-command-buttons-vehicles";
import { createUuid } from "../utils/uuid";
import { PageHeader } from "../ui/atoms/page-header";
import { PageContent } from "../ui/atoms/page-content";
import { IPivotItemProps, Pivot, PivotItem, Stack } from "@fluentui/react";
import { Section } from "../ui/atoms/section";
import { ListCommandButtonsVehicleGroups } from "../ui/organisms/list-command-buttons-vehicle-groups";
import { ListVehicleAddButton } from "../ui/organisms/list-vehicle-add-button";
import { ListVehicleGroupAddButton } from "../ui/organisms/list-vehicle-group-add-button";
import { action, makeObservable, observable } from "mobx";
import { HistoryProvider } from "../domain/providers/history-provider";
import { Location } from "history";
import sizes from "../ui/sizes.scss";
import { articles } from "../help";
import ElofleetIcon from "../ui/atoms/elofleet-icon";

const customPivotItemRenderer = (
    link?: IPivotItemProps,
    defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null,
): JSX.Element | null => {
    if (!link || !defaultRenderer) {
        return null;
    }

    return (
        <span style={{ display: "flex", alignItems: "center" }}>
            <ElofleetIcon
                icon="Forklift"
                style={{ width: 20, height: 20, paddingRight: 5, display: "inline-flex" }}
            />
            <div>{defaultRenderer({ ...link })}</div>
        </span>
    );
};

@external
@observer
export class PageVehicleSettings extends React.Component {
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly historyProvider!: HistoryProvider;

    // Create the UUID on this level, so the button bar is able to access the same state as the list.
    private vehicleListStateId = createUuid();
    private vehicleGroupListStateId = createUuid();

    @observable private selectedTab: string = PageVehicleSettingsTab.Vehicles;

    constructor(props: RouteProps<{ tab?: string }>) {
        super(props);
        makeObservable(this);
    }

    @initialize
    private initialize(): void {
        this.historyProvider.history.listen(({ location }) => this.updateSelectedTab(location));
        this.updateSelectedTab(this.historyProvider.history.location);
    }

    @action.bound
    private updateSelectedTab(location: Location): void {
        // NOTE: when rewriting as a functional component use useLocation hook instead
        const tab = location.pathname.split("/").slice(-1)[0];

        if (typeof tab !== "string") {
            return;
        }

        this.selectedTab = tab;
    }

    // When the tab is changed, update the URL
    @action.bound
    private updateURL(item?: PivotItem): void {
        if (item?.props.itemKey) {
            this.historyProvider.history.push(
                routePath.vehicleSettings(item.props.itemKey as PageVehicleSettingsTab),
            );
        }
    }

    public render(): JSX.Element {
        return (
            <LayoutDefault
                suggestedHelpArticles={[
                    articles.adminManageTrucks,
                    articles.howToAddTruck,
                    articles.howToEditTruck,
                    articles.howToRemoveTruck,
                    articles.howToCreateAccessControlGroups,
                    articles.administratorFunctionalities,
                ]}
                header={<PageHeader title={this.i18n.t("page.vehicleSettings.title")} />}
            >
                <PageContent>
                    <Pivot
                        linkSize="large"
                        styles={{
                            root: {
                                display: "flex",
                                justifyContent: "center",
                                height: sizes.pivotHeight,
                            },
                            link: { marginBottom: `${sizes.gutter}` },
                        }}
                        onLinkClick={this.updateURL}
                        selectedKey={this.selectedTab}
                    >
                        <PivotItem
                            headerText={this.i18n.t("page.vehicleSettings.tab.vehicle")}
                            itemKey={PageVehicleSettingsTab.Vehicles}
                            onRenderItemLink={customPivotItemRenderer}
                        >
                            <Section>
                                <ListVehicles
                                    commandBar={
                                        <Stack horizontal style={{ alignItems: "center" }}>
                                            <ListVehicleAddButton
                                                listStateId={this.vehicleListStateId}
                                            />
                                            <ListCommandButtonsVehicles
                                                listStateId={this.vehicleListStateId}
                                                canDelete
                                            />
                                        </Stack>
                                    }
                                    listStateId={this.vehicleListStateId}
                                    ignoreColumnVisibility={true}
                                    maxHeight={`${sizes.listMaxHeightWithPivots}`}
                                />
                            </Section>
                        </PivotItem>
                        <PivotItem
                            headerText={this.i18n.t("page.vehicleSettings.tab.vehicleGroup")}
                            itemKey={PageVehicleSettingsTab.VehicleGroups}
                            onRenderItemLink={customPivotItemRenderer}
                        >
                            <Section>
                                <ListVehicleGroups
                                    commandBar={
                                        <Stack horizontal>
                                            <ListVehicleGroupAddButton
                                                listStateId={this.vehicleGroupListStateId}
                                            />
                                            <ListCommandButtonsVehicleGroups
                                                listStateId={this.vehicleGroupListStateId}
                                                canDelete
                                            />
                                        </Stack>
                                    }
                                    listStateId={this.vehicleGroupListStateId}
                                    maxHeight={`${sizes.listMaxHeightWithPivots}`}
                                />
                            </Section>
                        </PivotItem>
                    </Pivot>
                </PageContent>
            </LayoutDefault>
        );
    }
}

export const routeVehicleSettings = declareRoute({
    navigation: true,
    component: PageVehicleSettings,
    icon: "Forklift",
    title: "page.vehicleSettings.navbarEntry",
    path: routePath.vehicleSettings,
    pattern: "/vehicle-settings/:tab",
});
