import { ActionButton, IconButton, Separator, Stack, TextField } from "@fluentui/react";
import { action, computed, makeObservable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { NotificationEmail, NotificationEmailCreate, SettingUpdate } from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositorySettings } from "../../domain/repositories/repository-settings";
import { UUID } from "../../utils/uuid";
import sizes from "../sizes.scss";

export interface NotificationEmailProps {
    readonly emailsCreate: NotificationEmailCreate[];
    readonly emailsUpdate: NotificationEmail[];
    readonly validationKey: keyof SettingUpdate;
    readonly validationKeyCreate: keyof SettingUpdate;
    readonly onChange: () => void;
    readonly translationPrefixKey: string;
    readonly validationId: UUID;
}

@observer
@external
export class NotificationEmails extends React.Component<NotificationEmailProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositorySettings!: RepositorySettings;

    constructor(props: NotificationEmailProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private addEmail(): void {
        if (this.props.emailsCreate === undefined) return;
        this.props.emailsCreate.push({ email: "" });

        this.props.onChange();
    }

    @action.bound private updateEmail(
        index: number,
        isNew: boolean,
        email?: string | undefined,
    ): void {
        email = email ?? "";

        if (isNew) {
            this.props.emailsCreate[index].email = email;
        } else {
            const updateEmail = this.props.emailsUpdate[index];

            if (updateEmail) updateEmail.email = email;
        }

        // Trigger update validation for this email
        this.props.onChange();
    }

    /**
     * Schedule an email for later deletion.
     * It'll be deleted once the `save` button is clicked.
     */
    @action.bound private deleteEmail(index: number, isNew: boolean): void {
        if (isNew) {
            this.props.emailsCreate.splice(index, 1);
            return;
        }

        this.props.emailsUpdate.splice(index, 1);
        return;
    }

    /**
     * Compile the JSX for the email list of the form.
     */
    @computed get renderEmails(): JSX.Element[] {
        let rowIndex = 1;
        const itemsEmail: JSX.Element[] = [];
        const emails: NotificationEmail[] = this.props.emailsUpdate;
        emails.map((email, index) => {
            itemsEmail.push(this.renderEmail(rowIndex, email.email, false, index));

            rowIndex = rowIndex + 1;
        });

        // Show the emails that're supposed to be added.
        this.props.emailsCreate.forEach((email, index) => {
            itemsEmail.push(this.renderEmail(rowIndex, email.email, true, index));

            rowIndex = rowIndex + 1;
        });

        return itemsEmail;
    }

    /**
     * Compile the JSX for a single email.
     */
    private renderEmail(
        rowIndex: number,
        email: string,
        isNew: boolean,
        index: number,
    ): JSX.Element {
        // Only add labels to the very first email.
        let emailLabel = undefined;

        if (rowIndex === 1) {
            emailLabel = this.i18n.t(
                `${this.props.translationPrefixKey}.emailNotification.list.title`,
            );
        }

        const emailUpdate = (
            _: React.FormEvent<HTMLElement>,
            newValue?: string | undefined,
        ): void => {
            this.updateEmail(index, isNew, newValue);
        };

        const emailDelete = (_: React.MouseEvent<HTMLButtonElement>): void => {
            this.deleteEmail(index, isNew);
            this.props.onChange();
        };

        return (
            <Stack.Item key={rowIndex}>
                <Stack
                    horizontal
                    tokens={{
                        childrenGap: `${sizes.xl}`,
                    }}
                >
                    <Stack.Item
                        styles={{
                            root: {
                                minWidth: "500px",
                            },
                        }}
                    >
                        <TextField
                            prefix={`${rowIndex}.`}
                            placeholder={this.i18n.t(
                                `${this.props.translationPrefixKey}.emailNotification.placeholder`,
                            )}
                            value={email}
                            label={emailLabel}
                            onChange={emailUpdate}
                            errorMessage={this.i18n.formatListFieldValidationState(
                                isNew
                                    ? rowIndex - 1 - this.props.emailsUpdate.length
                                    : rowIndex - 1,
                                this.repositorySettings.validation.update.getFieldValidationState(
                                    this.props.validationId,
                                    isNew
                                        ? this.props.validationKeyCreate
                                        : this.props.validationKey,
                                ),
                            )}
                            autoComplete="off"
                        />
                    </Stack.Item>

                    <Stack.Item align="end" style={{ marginLeft: `${sizes.gutter}` }}>
                        <IconButton iconProps={{ iconName: "Trash" }} onClick={emailDelete} />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        );
    }

    public render(): JSX.Element {
        return (
            <Stack
                tokens={{
                    childrenGap: `${sizes.gutter}`,
                }}
                styles={{
                    root: {
                        maxHeight: sizes.halfHeight,
                        overflow: "auto",
                    },
                }}
            >
                <Stack>
                    <h3
                        style={{
                            marginBottom: sizes.marginBottomForHeaderWithSeparator,
                        }}
                    >
                        {this.i18n.t(`${this.props.translationPrefixKey}.emailNotification.title`)}
                    </h3>
                    <Separator />
                    <span style={{ marginBottom: sizes.marginBottomForHeaderWithSeparator }}>
                        {this.i18n.t(`${this.props.translationPrefixKey}.emailNotification.text`)}
                    </span>
                </Stack>
                {this.renderEmails}
                <Stack.Item>
                    <ActionButton
                        iconProps={{ iconName: "Add" }}
                        text={this.i18n.t(`${this.props.translationPrefixKey}.addEmail.text`)}
                        onClick={() => this.addEmail()}
                    />
                </Stack.Item>
            </Stack>
        );
    }
}
