import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { NodeProps } from "reactflow";
import { PedestriansInZoneData, Zone } from "../../../api";
import ActionConfigNode, { zoneOptions } from "./action-config-node";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export default function PedestriansInZone(
    props: NodeProps<
        PedestriansInZoneData & { invalid?: boolean; validationErrorMessage?: string }
    >,
): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    const [selectedZone, setSelectedZone] = useState(props.data.zone ?? Zone.ONE);

    // We have to set the data to prevent undefined values
    useEffect(() => {
        props.data.zone = selectedZone;
    }, [props.data, selectedZone]);

    function onChange(
        _e?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption,
    ): void {
        if (!option) {
            return;
        }
        setSelectedZone(option.key as Zone);
        props.data.zone = option.key as Zone;
    }

    return (
        <ActionConfigNode nodeKind={props.type} {...props.data}>
            <ChoiceGroup options={zoneOptions(t)} selectedKey={selectedZone} onChange={onChange} />
        </ActionConfigNode>
    );
}
