/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum VehicleRelay {
    ONE = 'one',
    TWO = 'two'
}


export function VehicleRelayFromJSON(json: any): VehicleRelay {
    return VehicleRelayFromJSONTyped(json, false);
}

export function VehicleRelayFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleRelay {
    return json as VehicleRelay;
}

export function VehicleRelayToJSON(value?: VehicleRelay | null): any {
    return value as any;
}

