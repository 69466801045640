import React from "react";
import { Link } from "react-router-dom";
import { Stack } from "@fluentui/react";
import { mergeStyleSets } from "@fluentui/react";
import { NotificationCheckBox } from "../atoms/notification-check-box";
import { routeImpactManagement } from "../../pages/page-impact-management";
import { routeVehicles } from "../../pages/page-vehicles";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { RepositoryVehicles } from "../../domain/repositories/repository-vehicles";
import { PersistentNotification } from "../../api";
import { external, inject } from "tsdi";
import { observer } from "mobx-react";
import { action, makeObservable } from "mobx";
import colors from "../colors.scss";
import css from "./notification-item.scss";
import sizes from "../sizes.scss";

export type NotificationItemProps = {
    notification: PersistentNotification;
    markNotificationRead: (id: string) => void;
};

const classNames = mergeStyleSets({
    item: {
        padding: 10,
        selectors: {
            "&:hover": { background: "#eee" },
        },
    },
});

@external
@observer
export class MaintenanceNotificationItem extends React.Component<NotificationItemProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryDepartments!: RepositoryDepartments;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;

    constructor(props: NotificationItemProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private markRead(): void {
        this.props.markNotificationRead(this.props.notification.id);
    }

    render(): JSX.Element {
        const notification = this.props.notification;
        const hoursUntilMaintenance = notification.hoursUntilMaintenance ?? 0;

        const vehicle = this.repositoryVehicles.byId(notification.vehicleId);
        if (!vehicle) {
            return <></>;
        }

        let title = this.i18n.t("component.notificationItemMaintenance.maintenanceDueTitle");
        let hoursUntilMaintenanceLabel = this.i18n.t(
            "component.notificationItemMaintenance.hoursUntilMaintenance",
        );
        let hoursUntilMaintenanceStyle = {};
        if (hoursUntilMaintenance <= 0) {
            title = this.i18n.t("component.notificationItemMaintenance.maintenanceOverdueTitle");
            hoursUntilMaintenanceLabel = this.i18n.t(
                "component.notificationItemMaintenance.maintenanceOverdueSince",
            );
            hoursUntilMaintenanceStyle = { color: colors["secondary11"] };
        }

        let departmentLabel = this.i18n.t(
            "component.notificationItemMaintenance.noTruckDepartment",
        );
        if (notification.truckDepartmentId) {
            const department = this.repositoryDepartments.byId(notification.truckDepartmentId);
            departmentLabel = department?.label ?? departmentLabel;
        }

        return (
            <Stack horizontal className={classNames.item}>
                <Stack style={{ overflow: "hidden", whiteSpace: "nowrap" }} grow={5}>
                    <Link to={routeImpactManagement.path()} className={css.notificationItem__title}>
                        {title}
                    </Link>
                    <span className={css.notificationItem__info}>
                        {this.i18n.formatDateTime(notification.triggeredAt)}
                    </span>
                    <Stack horizontal>
                        <Stack.Item style={{ overflow: "hidden" }} order={2}>
                            <span className={css.notificationItem__info}>
                                <>
                                    {this.i18n.t("component.notificationItemMaintenance.truck")}
                                    :&#32;
                                </>
                            </span>
                            <Link
                                to={routeVehicles.path()}
                                style={{ fontSize: 14, color: colors["secondary08"] }}
                            >
                                {vehicle.serialNumber}
                            </Link>
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal>
                        <Stack.Item style={{ overflow: "hidden" }} order={2}>
                            <span className={css.notificationItem__info}>
                                <>
                                    {this.i18n.t(
                                        "component.notificationItemMaintenance.truckDepartment",
                                    )}
                                    :&#32;
                                </>
                            </span>
                            <span className={css.notificationItem__info}>{departmentLabel}</span>
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal>
                        <Stack.Item style={{ overflow: "hidden" }} order={2}>
                            <span className={css.notificationItem__info}>
                                {hoursUntilMaintenanceLabel}:&#32;
                            </span>
                            <span
                                className={css.notificationItem__info}
                                style={hoursUntilMaintenanceStyle}
                            >
                                {Math.abs(notification.hoursUntilMaintenance ?? 0)}h
                            </span>
                        </Stack.Item>
                    </Stack>
                </Stack>
                <Stack
                    horizontalAlign="end"
                    verticalAlign="center"
                    grow={1}
                    style={{ paddingLeft: `${sizes.gutter}`, paddingRight: `${sizes.gutter}` }}
                >
                    {!this.props.notification.read && (
                        <NotificationCheckBox hover onClick={this.markRead} />
                    )}
                </Stack>
            </Stack>
        );
    }
}
