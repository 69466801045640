/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PageInfo,
  ShockEvent,
  ShockEventCreate,
  ShockEventLevel,
  ShockEventSortKey,
  SortDirection,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    ShockEventFromJSON,
    ShockEventToJSON,
    ShockEventCreateFromJSON,
    ShockEventCreateToJSON,
    ShockEventLevelFromJSON,
    ShockEventLevelToJSON,
    ShockEventSortKeyFromJSON,
    ShockEventSortKeyToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface ShockEventsCreateShockEventRequest {
    shockEventCreate: ShockEventCreate;
}

export interface ShockEventsExportShockEventsRequest {
    timezone: string;
    exportType: ShockEventsExportShockEventsExportType;
    pageSize: number;
    dateStart?: Date;
    dateEnd?: Date;
    vehicleIds?: Array<string> | null;
    userIds?: Array<string> | null;
    shockLevels?: Array<ShockEventLevel> | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: ShockEventSortKey;
}

export interface ShockEventsListShockEventsRequest {
    pageSize: number;
    dateStart?: Date;
    dateEnd?: Date;
    vehicleIds?: Array<string> | null;
    userIds?: Array<string> | null;
    shockLevels?: Array<ShockEventLevel> | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: ShockEventSortKey;
}

export interface ShockEventsListShockEventsMetadataRequest {
    pageSize: number;
    dateStart?: Date;
    dateEnd?: Date;
    vehicleIds?: Array<string> | null;
    userIds?: Array<string> | null;
    shockLevels?: Array<ShockEventLevel> | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: ShockEventSortKey;
}

export interface ShockEventsReadShockEventRequest {
    shockEventId: string;
}

/**
 * ShockEventsApi - interface
 * 
 * @export
 * @interface ShockEventsApiInterface
 */
export interface ShockEventsApiInterface {
    /**
     * Create a new shock event
     * @summary Create a shock event.
     * @param {ShockEventCreate} shockEventCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShockEventsApiInterface
     */
    shockEventsCreateShockEventRaw(requestParameters: ShockEventsCreateShockEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShockEvent>>;

    /**
     * Create a new shock event
     * Create a shock event.
     */
    shockEventsCreateShockEvent(requestParameters: ShockEventsCreateShockEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShockEvent>;

    /**
     * The endpoint will return shock events that have the between date_start and date_end and also exact same value as date_start. date_start <= shock_event.time < date_end
     * @summary Export shock events as csv or xlsx
     * @param {string} timezone 
     * @param {'csv' | 'xlsx'} exportType 
     * @param {number} pageSize 
     * @param {Date} [dateStart] 
     * @param {Date} [dateEnd] 
     * @param {Array<string>} [vehicleIds] 
     * @param {Array<string>} [userIds] 
     * @param {Array<ShockEventLevel>} [shockLevels] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {ShockEventSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShockEventsApiInterface
     */
    shockEventsExportShockEventsRaw(requestParameters: ShockEventsExportShockEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     * The endpoint will return shock events that have the between date_start and date_end and also exact same value as date_start. date_start <= shock_event.time < date_end
     * Export shock events as csv or xlsx
     */
    shockEventsExportShockEvents(requestParameters: ShockEventsExportShockEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

    /**
     * Get a paginated view on all the shock events. The endpoint will return shock events that have the between date_start and date_end and also exact same value as date_start. date_start <= shock_event.time < date_end
     * @summary List shock events
     * @param {number} pageSize 
     * @param {Date} [dateStart] 
     * @param {Date} [dateEnd] 
     * @param {Array<string>} [vehicleIds] 
     * @param {Array<string>} [userIds] 
     * @param {Array<ShockEventLevel>} [shockLevels] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {ShockEventSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShockEventsApiInterface
     */
    shockEventsListShockEventsRaw(requestParameters: ShockEventsListShockEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShockEvent>>>;

    /**
     * Get a paginated view on all the shock events. The endpoint will return shock events that have the between date_start and date_end and also exact same value as date_start. date_start <= shock_event.time < date_end
     * List shock events
     */
    shockEventsListShockEvents(requestParameters: ShockEventsListShockEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShockEvent>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary Shock event listing metadata
     * @param {number} pageSize 
     * @param {Date} [dateStart] 
     * @param {Date} [dateEnd] 
     * @param {Array<string>} [vehicleIds] 
     * @param {Array<string>} [userIds] 
     * @param {Array<ShockEventLevel>} [shockLevels] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {ShockEventSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShockEventsApiInterface
     */
    shockEventsListShockEventsMetadataRaw(requestParameters: ShockEventsListShockEventsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * Shock event listing metadata
     */
    shockEventsListShockEventsMetadata(requestParameters: ShockEventsListShockEventsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the shock event specified by the given ID.
     * @summary Get a single shock event
     * @param {string} shockEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShockEventsApiInterface
     */
    shockEventsReadShockEventRaw(requestParameters: ShockEventsReadShockEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShockEvent>>;

    /**
     * Returns the shock event specified by the given ID.
     * Get a single shock event
     */
    shockEventsReadShockEvent(requestParameters: ShockEventsReadShockEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShockEvent>;

}

/**
 * 
 */
export class ShockEventsApi extends runtime.BaseAPI implements ShockEventsApiInterface {

    /**
     * Create a new shock event
     * Create a shock event.
     */
    async shockEventsCreateShockEventRaw(requestParameters: ShockEventsCreateShockEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShockEvent>> {
        if (requestParameters.shockEventCreate === null || requestParameters.shockEventCreate === undefined) {
            throw new runtime.RequiredError('shockEventCreate','Required parameter requestParameters.shockEventCreate was null or undefined when calling shockEventsCreateShockEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shock_events/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShockEventCreateToJSON(requestParameters.shockEventCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShockEventFromJSON(jsonValue));
    }

    /**
     * Create a new shock event
     * Create a shock event.
     */
    async shockEventsCreateShockEvent(requestParameters: ShockEventsCreateShockEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShockEvent> {
        const response = await this.shockEventsCreateShockEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The endpoint will return shock events that have the between date_start and date_end and also exact same value as date_start. date_start <= shock_event.time < date_end
     * Export shock events as csv or xlsx
     */
    async shockEventsExportShockEventsRaw(requestParameters: ShockEventsExportShockEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.timezone === null || requestParameters.timezone === undefined) {
            throw new runtime.RequiredError('timezone','Required parameter requestParameters.timezone was null or undefined when calling shockEventsExportShockEvents.');
        }

        if (requestParameters.exportType === null || requestParameters.exportType === undefined) {
            throw new runtime.RequiredError('exportType','Required parameter requestParameters.exportType was null or undefined when calling shockEventsExportShockEvents.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling shockEventsExportShockEvents.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateStart !== undefined) {
            queryParameters['date_start'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['date_end'] = (requestParameters.dateEnd as any).toISOString();
        }

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        if (requestParameters.exportType !== undefined) {
            queryParameters['export_type'] = requestParameters.exportType;
        }

        if (requestParameters.vehicleIds) {
            queryParameters['vehicle_ids'] = requestParameters.vehicleIds;
        }

        if (requestParameters.userIds) {
            queryParameters['user_ids'] = requestParameters.userIds;
        }

        if (requestParameters.shockLevels) {
            queryParameters['shock_levels'] = requestParameters.shockLevels;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shock_events/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * The endpoint will return shock events that have the between date_start and date_end and also exact same value as date_start. date_start <= shock_event.time < date_end
     * Export shock events as csv or xlsx
     */
    async shockEventsExportShockEvents(requestParameters: ShockEventsExportShockEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.shockEventsExportShockEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a paginated view on all the shock events. The endpoint will return shock events that have the between date_start and date_end and also exact same value as date_start. date_start <= shock_event.time < date_end
     * List shock events
     */
    async shockEventsListShockEventsRaw(requestParameters: ShockEventsListShockEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShockEvent>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling shockEventsListShockEvents.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateStart !== undefined) {
            queryParameters['date_start'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['date_end'] = (requestParameters.dateEnd as any).toISOString();
        }

        if (requestParameters.vehicleIds) {
            queryParameters['vehicle_ids'] = requestParameters.vehicleIds;
        }

        if (requestParameters.userIds) {
            queryParameters['user_ids'] = requestParameters.userIds;
        }

        if (requestParameters.shockLevels) {
            queryParameters['shock_levels'] = requestParameters.shockLevels;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shock_events/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShockEventFromJSON));
    }

    /**
     * Get a paginated view on all the shock events. The endpoint will return shock events that have the between date_start and date_end and also exact same value as date_start. date_start <= shock_event.time < date_end
     * List shock events
     */
    async shockEventsListShockEvents(requestParameters: ShockEventsListShockEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShockEvent>> {
        const response = await this.shockEventsListShockEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * Shock event listing metadata
     */
    async shockEventsListShockEventsMetadataRaw(requestParameters: ShockEventsListShockEventsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling shockEventsListShockEventsMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateStart !== undefined) {
            queryParameters['date_start'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['date_end'] = (requestParameters.dateEnd as any).toISOString();
        }

        if (requestParameters.vehicleIds) {
            queryParameters['vehicle_ids'] = requestParameters.vehicleIds;
        }

        if (requestParameters.userIds) {
            queryParameters['user_ids'] = requestParameters.userIds;
        }

        if (requestParameters.shockLevels) {
            queryParameters['shock_levels'] = requestParameters.shockLevels;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shock_events/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * Shock event listing metadata
     */
    async shockEventsListShockEventsMetadata(requestParameters: ShockEventsListShockEventsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.shockEventsListShockEventsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the shock event specified by the given ID.
     * Get a single shock event
     */
    async shockEventsReadShockEventRaw(requestParameters: ShockEventsReadShockEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShockEvent>> {
        if (requestParameters.shockEventId === null || requestParameters.shockEventId === undefined) {
            throw new runtime.RequiredError('shockEventId','Required parameter requestParameters.shockEventId was null or undefined when calling shockEventsReadShockEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shock_events/{shock_event_id}`.replace(`{${"shock_event_id"}}`, encodeURIComponent(String(requestParameters.shockEventId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShockEventFromJSON(jsonValue));
    }

    /**
     * Returns the shock event specified by the given ID.
     * Get a single shock event
     */
    async shockEventsReadShockEvent(requestParameters: ShockEventsReadShockEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShockEvent> {
        const response = await this.shockEventsReadShockEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
  * @export
  * @enum {string}
  */
export enum ShockEventsExportShockEventsExportType {
    CSV = 'csv',
    XLSX = 'xlsx'
}
