/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  ShockProfile,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ShockProfileFromJSON,
    ShockProfileToJSON,
} from '../models';

export interface ShockProfileVersionsReadShockProfileVersionRequest {
    versionId: string;
}

/**
 * ShockProfileVersionsApi - interface
 * 
 * @export
 * @interface ShockProfileVersionsApiInterface
 */
export interface ShockProfileVersionsApiInterface {
    /**
     * Returns the shock profile version specified by the given version ID.
     * @summary Get a single shock profile by version ID
     * @param {string} versionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShockProfileVersionsApiInterface
     */
    shockProfileVersionsReadShockProfileVersionRaw(requestParameters: ShockProfileVersionsReadShockProfileVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShockProfile>>;

    /**
     * Returns the shock profile version specified by the given version ID.
     * Get a single shock profile by version ID
     */
    shockProfileVersionsReadShockProfileVersion(requestParameters: ShockProfileVersionsReadShockProfileVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShockProfile>;

}

/**
 * 
 */
export class ShockProfileVersionsApi extends runtime.BaseAPI implements ShockProfileVersionsApiInterface {

    /**
     * Returns the shock profile version specified by the given version ID.
     * Get a single shock profile by version ID
     */
    async shockProfileVersionsReadShockProfileVersionRaw(requestParameters: ShockProfileVersionsReadShockProfileVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShockProfile>> {
        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling shockProfileVersionsReadShockProfileVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shock_profile_versions/{version_id}`.replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters.versionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShockProfileFromJSON(jsonValue));
    }

    /**
     * Returns the shock profile version specified by the given version ID.
     * Get a single shock profile by version ID
     */
    async shockProfileVersionsReadShockProfileVersion(requestParameters: ShockProfileVersionsReadShockProfileVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShockProfile> {
        const response = await this.shockProfileVersionsReadShockProfileVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
