/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FleetAuthenticateLogoutType } from './FleetAuthenticateLogoutType';
import {
    FleetAuthenticateLogoutTypeFromJSON,
    FleetAuthenticateLogoutTypeFromJSONTyped,
    FleetAuthenticateLogoutTypeToJSON,
} from './FleetAuthenticateLogoutType';

/**
 * 
 * @export
 * @interface FleetAuthenticatePeriod
 */
export interface FleetAuthenticatePeriod {
    /**
     * 
     * @type {string}
     * @memberof FleetAuthenticatePeriod
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof FleetAuthenticatePeriod
     */
    eventId: number;
    /**
     * 
     * @type {string}
     * @memberof FleetAuthenticatePeriod
     */
    vehicleId: string;
    /**
     * 
     * @type {string}
     * @memberof FleetAuthenticatePeriod
     */
    userId: string;
    /**
     * 
     * @type {Date}
     * @memberof FleetAuthenticatePeriod
     */
    startTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof FleetAuthenticatePeriod
     */
    stopTime: Date;
    /**
     * 
     * @type {boolean}
     * @memberof FleetAuthenticatePeriod
     */
    receivedStartEvent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FleetAuthenticatePeriod
     */
    receivedStopEvent: boolean;
    /**
     * 
     * @type {FleetAuthenticateLogoutType}
     * @memberof FleetAuthenticatePeriod
     */
    logoutType?: FleetAuthenticateLogoutType | null;
}

/**
 * Check if a given object implements the FleetAuthenticatePeriod interface.
 */
export function instanceOfFleetAuthenticatePeriod(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "eventId" in value;
    isInstance = isInstance && "vehicleId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "startTime" in value;
    isInstance = isInstance && "stopTime" in value;
    isInstance = isInstance && "receivedStartEvent" in value;
    isInstance = isInstance && "receivedStopEvent" in value;

    return isInstance;
}

export function FleetAuthenticatePeriodFromJSON(json: any): FleetAuthenticatePeriod {
    return FleetAuthenticatePeriodFromJSONTyped(json, false);
}

export function FleetAuthenticatePeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): FleetAuthenticatePeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'eventId': json['eventId'],
        'vehicleId': json['vehicleId'],
        'userId': json['userId'],
        'startTime': (new Date(json['startTime'])),
        'stopTime': (new Date(json['stopTime'])),
        'receivedStartEvent': json['receivedStartEvent'],
        'receivedStopEvent': json['receivedStopEvent'],
        'logoutType': !exists(json, 'logoutType') ? undefined : FleetAuthenticateLogoutTypeFromJSON(json['logoutType']),
    };
}

export function FleetAuthenticatePeriodToJSON(value?: FleetAuthenticatePeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'eventId': value.eventId,
        'vehicleId': value.vehicleId,
        'userId': value.userId,
        'startTime': (value.startTime.toISOString()),
        'stopTime': (value.stopTime.toISOString()),
        'receivedStartEvent': value.receivedStartEvent,
        'receivedStopEvent': value.receivedStopEvent,
        'logoutType': FleetAuthenticateLogoutTypeToJSON(value.logoutType),
    };
}

