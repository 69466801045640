import * as qs from "query-string";
import React from "react";
import { UUID } from "./utils/uuid";

/** Mimics the props as they are handed into a component from react-router. */
export interface RouteProps<_T = {}> {
    // This interface became de facto obsolete when react router was upgraded to v6.
    // It was still left here in order not to touch all class based components which
    // have it in their props type signature (basically all of them use it).
}

export interface BaseRoute<TProps> {
    /** A pattern compatible with react-router. */
    readonly pattern: string;
    /** React component that shall be rendered for this route. */
    readonly component: React.ComponentClass<RouteProps<TProps>> | React.FC<RouteProps<TProps>>;
    /** Whether the route is publicly visible, or only for logged in users. */
    readonly public?: boolean;
}

export interface NavigationRoute extends BaseRoute<{}> {
    /** The page's as it is supposed to show up in the navigation. */
    readonly title: string;
    /** The page's title. */
    readonly icon: string;
    /** A factory for creating a path to a specific entity. */
    readonly path: () => string;
}

export interface HiddenRoute<TProps> extends BaseRoute<TProps> {
    /** A factory for creating a path to a specific entity. */
    readonly path: (...args: string[]) => string;
}

export type Route<TProps> = NavigationRoute | HiddenRoute<TProps>;

/** List of all routes. */
const routes = new Set<Route<unknown>>();

/** Add a new route to the set of routes. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function declareRoute<T extends Route<any>>(route: T): T {
    routes.add(route as Route<unknown>);
    return route;
}

/**
 * Return a list of all registered routes.
 * **Make sure to use this function only when the react context is already started.**
 * Routes are added to the underlying set of routes statically, when the invocation is discovered.
 */
export function getRoutes(): Set<Route<unknown>> {
    return routes;
}

/** Delete all registered routes. */
export function resetRoutes(): void {
    routes.clear();
}

// Part of the URL for this page to show which tab is active.
export enum PageUserSettingsTab {
    Users = "users",
    UserGroups = "user-groups",
}

export enum PageVehicleSettingsTab {
    Vehicles = "vehicles",
    VehicleGroups = "vehicle-groups",
}

/**
 * Path generators must be defined here so that they can be imported from `domain/`.
 * Otherwise, `/domain` would have to import `/pages` which would lead to circular
 * dependencies.
 */
export const routePath = {
    actionConfiguration: () => "/action-configuration",
    adminSettings: () => "/admin-settings",
    bulkImport: () => "/bulk-import",
    generalSettings: () => "/general-settings",
    columnVisibility: () => "/column-visibility",
    dashboard: (showTutorial?: boolean) =>
        qs.stringifyUrl({ url: "/dashboard", query: { showTutorial } }),
    dispatcher: () => "/start",
    helpAndTraining: () => "/help-and-training",
    impactManagement: () => "/impact-management",
    maintenance: () => "/maintenance",
    loginReport: () => "/login-report",
    motionSensors: () => "/motion-sensors",
    phones: () => "/phones",
    preOpsChecklists: () => "/pre-ops-checklists",
    safetyMessages: () => "/safety-messages",
    safetyMessagesSettings: () => "/safety-messages-settings",
    publicSettings: () => "/public_settings",
    settings: () => "/settings",
    shockProfiles: () => "/shock-profiles",
    sites: () => "/sites",
    userProductivity: () => "/user-productivity",
    userGroups: () => "/user-groups",
    user: (userId: string) => `/users/${userId}`,
    users: () => "/users",
    vehicleDevices: () => "/vehicle-devices",
    vehicleProductivity: () => "/vehicle-productivity",
    vehicleGroups: () => "/vehicle-groups",
    vehicleMovementPeriods: () => "/vehicle-movement-periods",
    vehicleDevice: (vehicleDeviceId: UUID) => `/vehicle-devices/${vehicleDeviceId}`,
    vehicle: (vehicleId: string) => `/vehicles/${vehicleId}`,
    vehicles: () => "/vehicles",
    systemWide: () => "/system-wide",
    userSettings: (tab?: PageUserSettingsTab) =>
        `/user-settings/${tab ?? PageUserSettingsTab.Users}`,
    vehicleSettings: (tab?: PageVehicleSettingsTab) =>
        `/vehicle-settings/${tab ?? PageVehicleSettingsTab.Vehicles}`,
    checklistSettings: () => "/checklist-settings",
    login: (logoutReason?: string): string => {
        if (!logoutReason) {
            return "/login";
        }
        return qs.stringifyUrl({ url: "/login", query: { logoutReason } });
    },
    emailLoginTokenLogin: () => "/email-token-login",
    customizeUserList: () => "/customize-user-list",
    customizeVehicleList: () => "/customize-vehicle-list",
    customizeMaintenanceList: () => "/customize-maintenance-list",
    truckActivity: () => "/truck-activity",
    pedestrianActivity: () => "/pedestrian-activity",
    zoningActivity: () => "/zoning-activity",
};
