/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleProductivityList
 */
export interface VehicleProductivityList {
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityList
     */
    vehicleSerialNumber: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityList
     */
    vehicleManufacturer: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityList
     */
    vehicleModel: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityList
     */
    vehicleEngineType: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityList
     */
    vehicleManufactureYear: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityList
     */
    vehicleLastSynchronizedAt: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityList
     */
    vehicleTypeLabel: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityList
     */
    departmentLabel: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleProductivityList
     */
    vehicleGroupLabel: boolean;
}

/**
 * Check if a given object implements the VehicleProductivityList interface.
 */
export function instanceOfVehicleProductivityList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vehicleSerialNumber" in value;
    isInstance = isInstance && "vehicleManufacturer" in value;
    isInstance = isInstance && "vehicleModel" in value;
    isInstance = isInstance && "vehicleEngineType" in value;
    isInstance = isInstance && "vehicleManufactureYear" in value;
    isInstance = isInstance && "vehicleLastSynchronizedAt" in value;
    isInstance = isInstance && "vehicleTypeLabel" in value;
    isInstance = isInstance && "departmentLabel" in value;
    isInstance = isInstance && "vehicleGroupLabel" in value;

    return isInstance;
}

export function VehicleProductivityListFromJSON(json: any): VehicleProductivityList {
    return VehicleProductivityListFromJSONTyped(json, false);
}

export function VehicleProductivityListFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleProductivityList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicleSerialNumber': json['vehicleSerialNumber'],
        'vehicleManufacturer': json['vehicleManufacturer'],
        'vehicleModel': json['vehicleModel'],
        'vehicleEngineType': json['vehicleEngineType'],
        'vehicleManufactureYear': json['vehicleManufactureYear'],
        'vehicleLastSynchronizedAt': json['vehicleLastSynchronizedAt'],
        'vehicleTypeLabel': json['vehicleTypeLabel'],
        'departmentLabel': json['departmentLabel'],
        'vehicleGroupLabel': json['vehicleGroupLabel'],
    };
}

export function VehicleProductivityListToJSON(value?: VehicleProductivityList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicleSerialNumber': value.vehicleSerialNumber,
        'vehicleManufacturer': value.vehicleManufacturer,
        'vehicleModel': value.vehicleModel,
        'vehicleEngineType': value.vehicleEngineType,
        'vehicleManufactureYear': value.vehicleManufactureYear,
        'vehicleLastSynchronizedAt': value.vehicleLastSynchronizedAt,
        'vehicleTypeLabel': value.vehicleTypeLabel,
        'departmentLabel': value.departmentLabel,
        'vehicleGroupLabel': value.vehicleGroupLabel,
    };
}

