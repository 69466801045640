/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum VehicleMovementPeriodSortKey {
    EVENT_ID = 'eventId',
    VEHICLE_SERIAL_NUMBER = 'vehicleSerialNumber',
    USER_EMPLOYEE_ID = 'userEmployeeId',
    START_TIME = 'startTime',
    STOP_TIME = 'stopTime',
    TOTAL_TIME = 'totalTime'
}


export function VehicleMovementPeriodSortKeyFromJSON(json: any): VehicleMovementPeriodSortKey {
    return VehicleMovementPeriodSortKeyFromJSONTyped(json, false);
}

export function VehicleMovementPeriodSortKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleMovementPeriodSortKey {
    return json as VehicleMovementPeriodSortKey;
}

export function VehicleMovementPeriodSortKeyToJSON(value?: VehicleMovementPeriodSortKey | null): any {
    return value as any;
}

