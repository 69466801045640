import * as React from "react";
import { omit } from "ramda";
import { Selection, IObjectWithKey, IconButton, Icon } from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { createUuid, UUID } from "../../utils/uuid";
import { VehicleGroup, VehicleGroupSortKey } from "../../api";
import {
    RepositoryVehicleGroups,
    VehicleGroupsApiResource,
    VehicleGroupsQuery,
} from "../../domain/repositories/repository-vehicle-groups";
import { observer } from "mobx-react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { SortState } from "../../utils/sort-state";
import { PaginationState } from "../../utils/pagination-state";
import { LayoutList } from "../layouts/layout-list";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import { SelectionState } from "../../utils/selection-state";
import { SearchState } from "../../utils/search-state";
import { ListSearchField } from "../atoms/list-search-field";
import { PanelVehicleGroupDetails } from "./panel-vehicle-group-details";
import css from "./list-vehicle-groups.scss";
import { defaultPageSize } from "../../utils/constants";

export interface ListVehicleGroupsProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly commandBar?: JSX.Element;
    readonly listStateId?: UUID;
    readonly userGroupIds?: UUID[];
    readonly ignoreUrl?: boolean;
    readonly hideFilter?: boolean;
}

interface ListVehicleGroupItem extends IObjectWithKey {
    readonly label: string;
    readonly key: UUID;
    readonly createdAt: string;
    readonly assignedVehicleCount: number;
    readonly assignedUserGroupCount: number;
}

@external
@observer
export class ListVehicleGroups extends React.Component<ListVehicleGroupsProps> {
    @inject private readonly repositoryVehicleGroups!: RepositoryVehicleGroups;
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;

    private _listStateId = createUuid();
    private selection = new Selection({
        onSelectionChanged: () => this.updateSelection(),
    });

    @observable private detailsPanelVisible: boolean = false;
    @observable private detailsPanelVehicleGroupId?: UUID;

    constructor(props: ListVehicleGroupsProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.vehicleGroups.initializeList(this.listStateId, {
            query: () => this.query,
            ignoreUrl: this.props.ignoreUrl ?? false,
        });
    }

    @computed private get listStateId(): UUID {
        return this.props.listStateId ?? this._listStateId;
    }

    @computed private get sortState(): SortState<VehicleGroupSortKey> {
        return this.serviceListStates.vehicleGroups.getSortState(this.listStateId);
    }

    @computed private get searchState(): SearchState {
        return this.serviceListStates.vehicleGroups.getSearchState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<VehicleGroupsApiResource> {
        return this.serviceListStates.vehicleGroups.getPaginationState(this.listStateId);
    }

    @computed private get selectionState(): SelectionState {
        return this.serviceListStates.vehicleGroups.getSelectionState(this.listStateId);
    }

    @computed private get query(): VehicleGroupsQuery {
        return {
            pageSize: defaultPageSize,
            userGroupIds: this.props.userGroupIds ?? [],
            ...this.sortState.query,
            ...this.searchState.query,
        };
    }

    @computed private get vehicleGroups(): (VehicleGroup | undefined)[] {
        if (this.props.ids) {
            return this.props.ids.map((id) => this.repositoryVehicleGroups.byId(id));
        }
        const group = this.repositoryVehicleGroups.byQuery(
            this.query,
            this.paginationState.pagination,
        );
        return group;
    }

    @computed private get items(): (ListVehicleGroupItem | undefined)[] {
        return this.vehicleGroups.map((vehicleGroup) => {
            if (!vehicleGroup) {
                return;
            }
            return {
                label: vehicleGroup.label,
                key: vehicleGroup.id,
                createdAt: this.i18n.formatDateOnly(vehicleGroup.createdAt),
                assignedVehicleCount: vehicleGroup.assignedVehicleCount,
                assignedUserGroupCount: vehicleGroup.assignedUserGroupCount,
            };
        });
    }

    @action.bound private updateSelection(): void {
        // Update the listState's selectionState to the new selected keys.
        this.selectionState.updateSelection(
            this.selection.getSelection().map((item) => item.key as UUID),
        );
    }

    @action.bound private openDetailsPanel(item: ListVehicleGroupItem): void {
        this.detailsPanelVehicleGroupId = item.key;
        this.detailsPanelVisible = true;
    }

    @action.bound private closeDetailsPanel(): void {
        this.detailsPanelVisible = false;
        this.detailsPanelVehicleGroupId = undefined;
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.vehicleGroups.isInitialized(this.listStateId)) {
            return <></>;
        }
        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(["ids"], this.props);

        let search = (
            <ListSearchField
                placeholder={this.i18n.t("listVehicleGroups.search.placeholder")}
                listStateId={this.listStateId}
                listState={this.serviceListStates.vehicleGroups}
            />
        );
        if (this.props.hideFilter) {
            search = <></>;
        }

        return (
            <>
                <LayoutList
                    paginationState={this.paginationState}
                    commandBar={this.props.commandBar ? this.props.commandBar : undefined}
                    searchField={search}
                >
                    <ElofleetList
                        {...props}
                        selection={this.selection}
                        onColumnHeaderClick={this.sortState.toggleColumn}
                        columns={this.sortState.patchColumns([
                            {
                                fieldName: "detailsButton",
                                name: "",
                                key: "detailsButton",
                                minWidth: 20,
                                maxWidth: 20,
                                onRender: (item, _index) => {
                                    return (
                                        <IconButton
                                            className={css.detailsButton__button}
                                            onClick={() => this.openDetailsPanel(item)}
                                        >
                                            <Icon
                                                iconName="ChevronRight"
                                                className={css.detailsButton__icon}
                                            />
                                        </IconButton>
                                    );
                                },
                            },
                            {
                                fieldName: "label",
                                name: this.i18n.t("listVehicleGroups.column.label.name"),
                                key: "label",
                                minWidth: 200,
                                maxWidth: 300,
                            },
                            {
                                fieldName: "assignedVehicleCount",
                                name: this.i18n.t(
                                    "listVehicleGroups.column.assignedVehicleCount.name",
                                ),
                                key: "assignedVehicleCount",
                                minWidth: 80,
                                maxWidth: 100,
                            },
                            {
                                fieldName: "assignedUserGroupCount",
                                name: this.i18n.t(
                                    "listVehicleGroups.column.assignedUserGroupCount.name",
                                ),
                                key: "assignedUserGroupCount",
                                minWidth: 150,
                                maxWidth: 200,
                            },
                        ])}
                        items={this.items}
                    />
                </LayoutList>
                <PanelVehicleGroupDetails
                    isOpen={this.detailsPanelVisible}
                    vehicleGroupId={this.detailsPanelVehicleGroupId}
                    onDismiss={this.closeDetailsPanel}
                />
            </>
        );
    }
}
