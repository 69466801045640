/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FleetAuthenticateLogoutType {
    MANUAL = 'manual',
    IDLE_TIMEOUT = 'idleTimeout',
    POWER_OFF = 'powerOff',
    CRITICAL_CHECKLIST = 'criticalChecklist',
    NEW_OPERATOR = 'newOperator'
}


export function FleetAuthenticateLogoutTypeFromJSON(json: any): FleetAuthenticateLogoutType {
    return FleetAuthenticateLogoutTypeFromJSONTyped(json, false);
}

export function FleetAuthenticateLogoutTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FleetAuthenticateLogoutType {
    return json as FleetAuthenticateLogoutType;
}

export function FleetAuthenticateLogoutTypeToJSON(value?: FleetAuthenticateLogoutType | null): any {
    return value as any;
}

