import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import elokonO from "../../images/elokon-o.svg";
import css from "./company-logo.scss";
import classNames from "classnames";
import { observer } from "mobx-react";

export interface CompanyLogoProps {
    /** If set to true, only the logo will be rendered. */
    readonly logo?: string;
}

@external
@observer
export class CompanyLogo extends React.Component<CompanyLogoProps> {
    @inject private readonly i18nProvider!: I18nProvider;

    constructor(props: CompanyLogoProps) {
        super(props);
    }

    public render(): JSX.Element {
        let whitelabel = true;

        if (this.props.logo === elokonO) {
            whitelabel = false;
        }
        return (
            <div
                className={classNames({
                    [css.companyLogo]: true,
                })}
            >
                {!whitelabel && (
                    <a href="/" className={css.companyLogo__logoButton}>
                        <img src={this.props.logo} className={css.companyLogo__logoImage} />
                    </a>
                )}
                {whitelabel && (
                    <>
                        <a href="/" className={css.companyLogo__logoButton}>
                            <img src={this.props.logo} className={css.companyLogo__logoImage} />
                        </a>
                    </>
                )}
            </div>
        );
    }
}
