import * as React from "react";
import { external } from "tsdi";
import { IColumn } from "@fluentui/react";
import { makeObservable, computed } from "mobx";
import { observer } from "mobx-react";
import { inject } from "tsdi";
import { Vehicle, VehicleGroup } from "../../api";
import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryVehicleGroups } from "../../domain/repositories/repository-vehicle-groups";
import { defaultPageSize } from "../../utils/constants";
import { RepositoryVehicleTypes } from "../../domain/repositories/repository-vehicle-types";

export interface ListVehicleProductivityItemCellProps {
    readonly vehicle: Vehicle;
    readonly column: IColumn;
    readonly filteredVehicleGroups: string[];
}

@external
@observer
export class ListVehicleProductivityItemCell extends React.Component<ListVehicleProductivityItemCellProps> {
    @inject private readonly repositoryDepartments!: RepositoryDepartments;
    @inject private readonly repositoryVehicleGroups!: RepositoryVehicleGroups;
    @inject private readonly repositoryVehicleTypes!: RepositoryVehicleTypes;
    @inject private readonly i18n!: I18nProvider;

    constructor(props: ListVehicleProductivityItemCellProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get vehicleDepartment(): string | undefined {
        if (!this.props.vehicle || !this.props.vehicle.departmentId) {
            return;
        }
        return this.repositoryDepartments.byId(this.props.vehicle.departmentId)?.label;
    }

    @computed private get vehicleType(): string | undefined {
        if (!this.props.vehicle || !this.props.vehicle.vehicleTypeId) {
            return;
        }
        return this.repositoryVehicleTypes.byId(this.props.vehicle.vehicleTypeId)?.label;
    }

    @computed private get groups(): VehicleGroup[] {
        if (!this.props.vehicle) {
            return [];
        }
        return this.repositoryVehicleGroups.byQuery({
            pageSize: defaultPageSize,
            vehicleId: this.props.vehicle.id,
        });
    }

    public render(): JSX.Element {
        const { column } = this.props;
        switch (column.key) {
            case "serialNumber":
                return <span>{this.props.vehicle?.serialNumber}</span>;
            case "manufacturer":
                return <span>{this.props.vehicle?.manufacturer ?? ""}</span>;
            case "model":
                return <span>{this.props.vehicle.model}</span>;
            case "vehicleType":
                return <span>{this.vehicleType}</span>;
            case "engineType": {
                if (!this.props.vehicle?.engineType) {
                    return <></>;
                }
                const label = this.i18n.t(
                    `listVehicles.engineType.${this.props.vehicle.engineType}`,
                );
                return <span>{label}</span>;
            }
            case "department":
                return <span>{this.vehicleDepartment}</span>;
            case "manufactureYear":
                return <span>{this.props.vehicle?.manufactureYear ?? ""}</span>;
            case "group":
                if (this.groups.length === 0) {
                    return <span />;
                }
                return (
                    <div>
                        {this.groups.map((group) => {
                            if (this.props.filteredVehicleGroups.includes(group.id)) {
                                return (
                                    <div key={group.id}>
                                        <strong>{group.label}</strong>
                                    </div>
                                );
                            }
                            return <div key={group.id}>{group.label}</div>;
                        })}
                    </div>
                );
            case "loginTime":
                return (
                    <span>
                        {this.props.vehicle &&
                            this.i18n.formatDuration(this.props.vehicle.totalLoginSeconds)}
                    </span>
                );
            case "driveTime":
                return (
                    <span>
                        {this.props.vehicle &&
                            this.i18n.formatDuration(this.props.vehicle.totalDriveSeconds)}
                    </span>
                );
            case "idleTime":
                return (
                    <span>
                        {this.props.vehicle &&
                            this.i18n.formatDuration(
                                this.props.vehicle.totalLoginSeconds -
                                    this.props.vehicle.totalDriveSeconds,
                            )}
                    </span>
                );
            case "lastSynchronization":
                return (
                    <span>
                        {this.props.vehicle?.lastSynchronizedAt &&
                            this.i18n.formatDateTime(this.props.vehicle?.lastSynchronizedAt)}
                    </span>
                );
            default:
                return <></>;
        }
    }
}
