/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleGroupUpdate
 */
export interface VehicleGroupUpdate {
    /**
     * 
     * @type {string}
     * @memberof VehicleGroupUpdate
     */
    label?: string | null;
    /**
     * UserGroups for which new assignments should be created.
     * @type {Array<string>}
     * @memberof VehicleGroupUpdate
     */
    addUserGroupIds?: Array<string> | null;
    /**
     * UserGroup for which existing assignments should be removed.
     * @type {Array<string>}
     * @memberof VehicleGroupUpdate
     */
    removeUserGroupIds?: Array<string> | null;
    /**
     * Vehicles for which new assignments should be created.
     * @type {Array<string>}
     * @memberof VehicleGroupUpdate
     */
    addVehicleIds?: Array<string> | null;
    /**
     * Vehicles for which existing assignments should be removed.
     * @type {Array<string>}
     * @memberof VehicleGroupUpdate
     */
    removeVehicleIds?: Array<string> | null;
}

/**
 * Check if a given object implements the VehicleGroupUpdate interface.
 */
export function instanceOfVehicleGroupUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleGroupUpdateFromJSON(json: any): VehicleGroupUpdate {
    return VehicleGroupUpdateFromJSONTyped(json, false);
}

export function VehicleGroupUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleGroupUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': !exists(json, 'label') ? undefined : json['label'],
        'addUserGroupIds': !exists(json, 'addUserGroupIds') ? undefined : json['addUserGroupIds'],
        'removeUserGroupIds': !exists(json, 'removeUserGroupIds') ? undefined : json['removeUserGroupIds'],
        'addVehicleIds': !exists(json, 'addVehicleIds') ? undefined : json['addVehicleIds'],
        'removeVehicleIds': !exists(json, 'removeVehicleIds') ? undefined : json['removeVehicleIds'],
    };
}

export function VehicleGroupUpdateToJSON(value?: VehicleGroupUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'addUserGroupIds': value.addUserGroupIds,
        'removeUserGroupIds': value.removeUserGroupIds,
        'addVehicleIds': value.addVehicleIds,
        'removeVehicleIds': value.removeVehicleIds,
    };
}

