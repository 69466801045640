import React from "react";
import { external, inject } from "tsdi";
import { IColumn } from "@fluentui/react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { UUID } from "../../utils/uuid";
import { observer } from "mobx-react";
import { RepositoryPhones } from "../../domain/repositories/repository-phones";
import { computed, makeObservable } from "mobx";
import { Phone, VehicleDevice } from "../../api";
import { RepositoryVehicleDevices } from "../../domain/repositories/repository-vehicle-devices";

interface ListPhoneItemCellProps {
    readonly phoneId: UUID;
    readonly column: IColumn;
}
@external
@observer
export class ListPhoneItemCell extends React.Component<ListPhoneItemCellProps> {
    @inject private readonly repositoryPhones!: RepositoryPhones;
    @inject private readonly repositoryVehicleDevices!: RepositoryVehicleDevices;
    @inject private readonly i18n!: I18nProvider;

    constructor(props: ListPhoneItemCellProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get phone(): Phone | undefined {
        return this.repositoryPhones.byId(this.props.phoneId);
    }

    @computed private get vehicleDevice(): VehicleDevice | undefined {
        if (!this.phone?.vehicleDeviceId) {
            return;
        }

        return this.repositoryVehicleDevices.byId(this.phone.vehicleDeviceId);
    }

    public render(): JSX.Element {
        const { column } = this.props;
        if (!this.phone) {
            return <></>;
        }
        switch (column.key) {
            case "vehicleDeviceId":
                return this.vehicleDevice ? (
                    <span>{this.vehicleDevice.serialNumber}</span>
                ) : (
                    <span />
                );
            case "androidId":
                return <span>{this.phone.androidId}</span>;
            case "appLastUpdatedAt":
                return <span>{this.i18n.formatDateTime(this.phone.appLastUpdatedAt)}</span>;
            case "currentAppVersion":
                return <span>{this.phone.currentAppVersion}</span>;
            default:
                return <></>;
        }
    }
}
