import * as React from "react";
import { omit } from "ramda";
import { IColumn, IObjectWithKey, SelectionMode, Stack } from "@fluentui/react";
import { external, inject } from "tsdi";
import { createUuid, UUID } from "../../utils/uuid";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { LayoutList } from "../layouts/layout-list";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import data from "../../utils/mock/eloshield-data.json";
import { Zone, ListZoneEventItemCell } from "../atoms/list-zone-event-item-cell";

export interface ListZoneEventsProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly commandBar?: JSX.Element;
    readonly listStateId?: string;
}

interface ListZoneEventItem extends IObjectWithKey {
    readonly key: number;
    readonly data: Object;
}

@external
export class ListZoneEvents extends React.Component<ListZoneEventsProps> {
    @inject protected readonly i18n!: I18nProvider;

    private listStateId = createUuid();

    private get items(): (ListZoneEventItem | undefined)[] {
        return data["zoning"].map((zoneItem) => {
            if (!zoneItem) {
                return;
            }

            return {
                key: zoneItem.id,
                data: zoneItem,
            };
        });
    }

    private get columns(): IColumn[] {
        const columns = [
            {
                fieldName: "event-time",
                name: this.i18n.t("listEvents.column.time.name"),
                key: "event-time",
                minWidth: 150,
                maxWidth: 200,
            },
            {
                fieldName: "stationary-id",
                name: this.i18n.t("listEvents.column.stationary.unit.id"),
                key: "stationary-id",
                minWidth: 100,
                maxWidth: 200,
            },
            {
                fieldName: "zone-name",
                name: this.i18n.t("listEvents.column.zone.name"),
                key: "zone-name",
                minWidth: 150,
                maxWidth: 200,
            },
            {
                fieldName: "zone-trigger",
                name: this.i18n.t("listEvents.column.zone.trigger"),
                key: "zone-trigger",
                minWidth: 100,
                maxWidth: 200,
            },
            {
                fieldName: "detected-eloshield-id",
                name: this.i18n.t("listEvents.column.detected.eloshield.id"),
                key: "detected-eloshield-id",
                minWidth: 150,
                maxWidth: 200,
            },
            {
                fieldName: "detected-truck-id",
                name: this.i18n.t("listEvents.column.detected.truck.id"),
                key: "detected-truck-id",
                minWidth: 150,
                maxWidth: 200,
            },

            {
                fieldName: "oem",
                name: this.i18n.t("listEvents.column.oem"),
                key: "oem",
                minWidth: 100,
                maxWidth: 200,
            },
            {
                fieldName: "oem-type",
                name: this.i18n.t("listEvents.column.oem.type"),
                key: "oem-type",
                minWidth: 100,
                maxWidth: 200,
            },
            {
                fieldName: "duration",
                name: this.i18n.t("listEvents.column.duration"),
                key: "duration",
                minWidth: 150,
                maxWidth: 200,
            },
        ];

        return columns;
    }

    private renderItem(truckEventId: number, column: IColumn, item: Zone): JSX.Element {
        return <ListZoneEventItemCell truckEventId={truckEventId} column={column} item={item} />;
    }

    public render(): JSX.Element {
        if (!this.listStateId) {
            return <></>;
        }
        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(["ids"], this.props);

        return (
            <Stack>
                <Stack>
                    <LayoutList commandBar={this.props.commandBar}>
                        <ElofleetList
                            {...props}
                            onColumnHeaderClick={() => {}}
                            // Disable right-click to stay consistent with other lists
                            onItemContextMenu={() => {}}
                            selectionMode={SelectionMode.none}
                            onRenderItemColumn={(item: Zone, _index, column) =>
                                this.renderItem(item.key, column!, item)
                            }
                            columns={this.columns}
                            items={this.items}
                        />
                    </LayoutList>
                </Stack>
            </Stack>
        );
    }
}
