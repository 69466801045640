import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { ServiceSidebar } from "../../domain/services/service-sidebar";
import { HelpButton } from "../molecules/help-button";
import { NotificationMenu } from "../molecules/notification-menu";
import { UserMenu } from "../molecules/user-menu";
import { CompanyLogo } from "./company-logo";
import css from "./page-header.scss";

export interface PageHeaderProps {
    readonly title: string;
    readonly actionButtons?: React.ReactNode;
}

@observer
@external
export class PageHeader extends React.Component<PageHeaderProps> {
    @inject private readonly serviceSidebar!: ServiceSidebar;

    public render(): JSX.Element {
        return (
            <header className={css.pageHeader}>
                <CompanyLogo logo={this.serviceSidebar.logo} />
                <div className={css.pageHeader__main}>
                    <h1 className={css.pageHeader__title}>{this.props.title}</h1>
                    <div className={css.pageHeader__secondaryContent}>
                        {this.props.actionButtons && (
                            <div className={css.pageHeader__actionButtons}>
                                {this.props.actionButtons}
                            </div>
                        )}
                        <NotificationMenu />
                        <HelpButton />
                        <UserMenu />
                    </div>
                </div>
            </header>
        );
    }
}
