/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PageInfo
 */
export interface PageInfo {
    /**
     * 
     * @type {number}
     * @memberof PageInfo
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof PageInfo
     */
    pageSize: number;
    /**
     * 
     * @type {number}
     * @memberof PageInfo
     */
    totalCount: number;
}

/**
 * Check if a given object implements the PageInfo interface.
 */
export function instanceOfPageInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalPages" in value;
    isInstance = isInstance && "pageSize" in value;
    isInstance = isInstance && "totalCount" in value;

    return isInstance;
}

export function PageInfoFromJSON(json: any): PageInfo {
    return PageInfoFromJSONTyped(json, false);
}

export function PageInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPages': json['totalPages'],
        'pageSize': json['pageSize'],
        'totalCount': json['totalCount'],
    };
}

export function PageInfoToJSON(value?: PageInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalPages': value.totalPages,
        'pageSize': value.pageSize,
        'totalCount': value.totalCount,
    };
}

