/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NewsCategory } from './NewsCategory';
import {
    NewsCategoryFromJSON,
    NewsCategoryFromJSONTyped,
    NewsCategoryToJSON,
} from './NewsCategory';

/**
 * Tailored item version of the elokon.com RSS feed item.
 * @export
 * @interface NewsItem
 */
export interface NewsItem {
    /**
     * 
     * @type {string}
     * @memberof NewsItem
     */
    title?: string | null;
    /**
     * 
     * @type {NewsCategory}
     * @memberof NewsItem
     */
    category: NewsCategory;
    /**
     * 
     * @type {string}
     * @memberof NewsItem
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsItem
     */
    link?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof NewsItem
     */
    pubDate?: Date | null;
}

/**
 * Check if a given object implements the NewsItem interface.
 */
export function instanceOfNewsItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "category" in value;

    return isInstance;
}

export function NewsItemFromJSON(json: any): NewsItem {
    return NewsItemFromJSONTyped(json, false);
}

export function NewsItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'category': NewsCategoryFromJSON(json['category']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'pubDate': !exists(json, 'pubDate') ? undefined : (json['pubDate'] === null ? null : new Date(json['pubDate'])),
    };
}

export function NewsItemToJSON(value?: NewsItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'category': NewsCategoryToJSON(value.category),
        'description': value.description,
        'link': value.link,
        'pubDate': value.pubDate === undefined ? undefined : (value.pubDate === null ? null : value.pubDate.toISOString()),
    };
}

