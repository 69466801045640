import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    PageInfo,
    MotionSensorsApi,
    MotionSensorsListMotionSensorsRequest,
    MotionSensorSortKey,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { MotionSensor } from "../../api";

export type MotionSensorsQuery = Omit<MotionSensorsListMotionSensorsRequest, "page">;

export interface MotionSensorsApiResource extends ApiResource {
    entity: MotionSensor;
    query: MotionSensorsQuery;
    update: void;
    create: void;
    sortKey: MotionSensorSortKey;
}

@component
export class RepositoryMotionSensors extends ElofleetRepository<MotionSensorsApiResource> {
    @inject private readonly motionSensorsApi!: MotionSensorsApi;

    protected async fetchByQuery(
        query: MotionSensorsQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<MotionSensor>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.motionSensorsApi.motionSensorsListMotionSensors({
            ...query,
            page,
        });
        return { entities };
    }

    public fetchMetadata(query: MotionSensorsQuery): Promise<PageInfo> {
        return this.motionSensorsApi.motionSensorsListMotionSensorsMetadata(query);
    }

    protected fetchById(): Promise<MotionSensor | undefined> {
        throw new Error("Method not implemented.");
    }

    public update(): Promise<MotionSensor> {
        throw new Error("Method not implemented.");
    }
    public create(): Promise<MotionSensor> {
        throw new Error("Method not implemented.");
    }
    public delete(): Promise<void> {
        throw new Error("Method not implemented.");
    }

    protected extractId = prop("id");
}
