import { action, makeObservable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryShockProfiles } from "../../domain/repositories/repository-shock-profiles";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { UUID } from "../../utils/uuid";
import { FormCreateShockProfile } from "../molecules/form-create-shock-profile";
import { FormUpdateShockProfile } from "../molecules/form-update-shock-profile";
import { ListCommandButtons, ListCommandButtonsProps } from "./list-command-buttons";
import { defaultPageSize } from "../../utils/constants";

export type ListCommandButtonsShockProfilesProps = Omit<
    ListCommandButtonsProps<RepositoryShockProfiles>,
    "list" | "createForm" | "listState"
> & {};

@external
@observer
export class ListCommandButtonsShockProfiles extends React.Component<ListCommandButtonsShockProfilesProps> {
    @inject private readonly repositoryShockProfiles!: RepositoryShockProfiles;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject protected readonly i18n!: I18nProvider;

    constructor(props: ListCommandButtonsShockProfilesProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private async deleteShockProfiles(ids: UUID[]): Promise<void> {
        await this.repositoryShockProfiles.delete(...ids);
        if (this.props.onDelete) {
            this.props.onDelete(ids);
        }
        this.repositoryShockProfiles.reloadQuery({
            pageSize: defaultPageSize,
        });
    }

    @action.bound private reloadShockProfiles(): void {
        this.repositoryShockProfiles.reloadQuery({
            pageSize: defaultPageSize,
        });
    }

    public render(): JSX.Element {
        return (
            <ListCommandButtons
                updateForm={FormUpdateShockProfile}
                createForm={(props) => <FormCreateShockProfile {...props} />}
                listState={this.serviceListStates.shockProfiles}
                {...this.props}
                onDelete={this.deleteShockProfiles}
                onCreate={this.reloadShockProfiles}
                createDialogTitle={this.i18n.t("listShockProfiles.createDialog.title")}
                createButtonText={this.i18n.t("listShockProfiles.createButton.name")}
                updateDialogTitle={this.i18n.t("listShockProfiles.updateDialog.title")}
            />
        );
    }
}
