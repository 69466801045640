import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { declareRoute, routePath, RouteProps } from "../routes";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { ListSafetyMessages } from "../ui/molecules/list-safety-messages";
import { createUuid } from "../utils/uuid";
import { PageHeader } from "../ui/atoms/page-header";
import { PageContent } from "../ui/atoms/page-content";
import { Section } from "../ui/atoms/section";
import { SelectionMode } from "@fluentui/react";
import sizes from "../ui/sizes.scss";
import { articles } from "../help";

@external
@observer
export class PageSafetyMessages extends React.Component<RouteProps> {
    @inject protected readonly i18n!: I18nProvider;

    // Create the UUID on this level, so the button bar is able to access the same state as the list.
    private listStateId = createUuid();

    public render(): JSX.Element {
        return (
            <LayoutDefault
                suggestedHelpArticles={[articles.safetyMessageReport]}
                header={<PageHeader title={this.i18n.t("page.safetyMessages.title")} />}
            >
                <PageContent>
                    <Section>
                        <ListSafetyMessages
                            listStateId={this.listStateId}
                            selectionMode={SelectionMode.none}
                            maxHeight={`${sizes.listMaxHeight}`}
                        />
                    </Section>
                </PageContent>
            </LayoutDefault>
        );
    }
}

export const routeSafetyMessages = declareRoute({
    navigation: true,
    component: PageSafetyMessages,
    icon: "Message",
    title: "page.safetyMessages.navbarEntry",
    path: routePath.safetyMessages,
    pattern: "/safety-messages",
});
