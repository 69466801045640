import { action, makeObservable, observable } from "mobx";
import { pick } from "ramda";
import { ElofleetRepository } from "./elofleet-repository";
import { PaginationState, PaginationStateOptions } from "./pagination-state";
import { SearchState } from "./search-state";
import { SelectionState } from "./selection-state";
import { SortState, SortStateOptions } from "./sort-state";
import { UUID } from "./uuid";

/**
 * Represents the state for a single list (react component).
 * The state includes things such as pagination or sorting.
 */
export interface ListState<TRepository extends ElofleetRepository> {
    /** Current sorting state. */
    readonly sortState: SortState<TRepository["apiResource"]["sortKey"]>;
    /** Current pagination state. */
    readonly paginationState: PaginationState<TRepository["apiResource"]>;
    /** Current selected element. */
    readonly selectionState: SelectionState;
    /** Current active search. */
    readonly searchState: SearchState;
}

export interface InitializeListOptions<TQuery> {
    /** If specified, URL will not be modified or read. */
    readonly ignoreUrl?: boolean;
    /** The query that is used to filter the items in the corresponding list. */
    readonly query: () => TQuery;
}

/**
 * A class that wraps the states for a react component type list.
 * It can be used to register new lists and then retrieve them via a uuid.
 */
export class ListStates<TRepository extends ElofleetRepository> {
    @observable private state = new Map<UUID, ListState<TRepository>>();

    /**
     * @param options A set of options for {@link PaginationState} and {@link SortState} that
     *     stays the same for all lists of this type.
     */
    constructor(
        private options: Omit<PaginationStateOptions<TRepository["apiResource"]>, "query"> &
            SortStateOptions<TRepository["apiResource"]["sortKey"]>,
    ) {
        makeObservable(this);
    }

    /**
     * Create a new list with an UUID and specific options for {@link PaginationState} and
     * {@link SortState}.
     *
     * @param id A UUID by which the state can later be referenced.
     * @param options a set of options for {@link PaginationState} and {@link SortState} for
     *     this particular list.
     */
    @action.bound public initializeList(
        id: UUID,
        options: InitializeListOptions<TRepository["apiResource"]["query"]>,
    ): void {
        // Just return if the state has already been initialized.
        // This can happen if, for instance, a component is removed in a tab view.
        // In that case, the listStateId is held by the page and is thereby persistent when
        // switching between views.
        if (this.state.has(id)) {
            return;
        }

        // Create a new pagination state with the options that are generic for all lists
        // and the options that are specific for this list.
        const paginationState = new PaginationState<TRepository["apiResource"]>({
            ...pick(["repository"], this.options),
            ...pick(["query", "ignoreUrl"], options),
        });
        // Create a new sort state with the specified generic options.
        const sortState = new SortState({
            ...pick(["getSortKeyForColumn", "isColumnSorted", "paginationState"], this.options),
            paginationState,
            ignoreUrl: options.ignoreUrl,
        });
        const selectionState = new SelectionState([]);
        const searchState = new SearchState();
        this.state.set(id, { paginationState, sortState, selectionState, searchState });
    }

    /** Get the whole state for one list. */
    public getState(id: UUID): ListState<TRepository> {
        const state = this.state.get(id);
        if (!state) {
            throw new Error(`List state for id ${id} not initialized.`);
        }
        return state;
    }

    /** Get only the {@link PaginationState} for a list. */
    public getPaginationState(id: UUID): PaginationState<TRepository["apiResource"]> {
        return this.getState(id).paginationState;
    }

    /** Get only the {@link SortState} for a list. */
    public getSortState(id: UUID): SortState<TRepository["apiResource"]["sortKey"]> {
        return this.getState(id).sortState;
    }

    /** Get the current selection for a list. */
    public getSelectionState(id: UUID): SelectionState {
        return this.getState(id).selectionState;
    }

    /** Get the current selection for a list. */
    public getSearchState(id: UUID): SearchState {
        return this.getState(id).searchState;
    }

    /** Check whether the specified id is known. */
    public isInitialized(id: UUID): boolean {
        return this.state.has(id);
    }
}
