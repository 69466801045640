import classNames from "classnames";
import * as React from "react";
import css from "./sidebar.scss";

export interface SidebarProps {
    readonly collapsed?: boolean;
    readonly primaryContent?: JSX.Element | JSX.Element[];
    readonly secondaryContent?: JSX.Element | JSX.Element[];
    readonly secondary?: boolean;
}

export class Sidebar extends React.Component<SidebarProps> {
    public render(): JSX.Element {
        return (
            <div
                className={classNames({
                    [css.sidebar]: true,
                    [css["sidebar--collapsed"]]: this.props.collapsed,
                    [css["sidebar--secondary"]]: this.props.secondary,
                })}
            >
                <div className={css.sidebar__primary}>{this.props.primaryContent}</div>
                <div className={css.sidebar__secondary}>{this.props.secondaryContent}</div>
            </div>
        );
    }
}
