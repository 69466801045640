/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SafetyMessageUpdate
 */
export interface SafetyMessageUpdate {
    /**
     * 
     * @type {string}
     * @memberof SafetyMessageUpdate
     */
    body?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SafetyMessageUpdate
     */
    active?: boolean | null;
}

/**
 * Check if a given object implements the SafetyMessageUpdate interface.
 */
export function instanceOfSafetyMessageUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SafetyMessageUpdateFromJSON(json: any): SafetyMessageUpdate {
    return SafetyMessageUpdateFromJSONTyped(json, false);
}

export function SafetyMessageUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SafetyMessageUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'body': !exists(json, 'body') ? undefined : json['body'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function SafetyMessageUpdateToJSON(value?: SafetyMessageUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'body': value.body,
        'active': value.active,
    };
}

