import * as React from "react";
import { HelpTag } from "../../help-types";
import { HelpTagBubble } from "../atoms/help-tag-bubble";
import css from "./help-tag-cloud.scss";

export interface HelpTagCloudProps {
    readonly tags: HelpTag[];
}

export class HelpTagCloud extends React.Component<HelpTagCloudProps> {
    public render(): JSX.Element {
        return (
            <div className={css.tagCloud}>
                {this.props.tags.map((tag) => (
                    <HelpTagBubble key={tag.name} tag={tag} />
                ))}
            </div>
        );
    }
}
