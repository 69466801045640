import { Dialog, Transition } from "@headlessui/react";
import {
    mdiBookshelf,
    mdiCellphoneArrowDown,
    mdiClose,
    mdiCommentQuestion,
    mdiCommentQuote,
    mdiHelp,
    mdiNewspaper,
    mdiServerNetwork,
} from "@mdi/js";
import Icon from "@mdi/react";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export interface Props {
    className?: string;
}

export default function HelpMenu({ className }: Props): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);
    const { t }: { t: TFunction } = useTranslation();

    const menuItems = [
        [mdiBookshelf, t("dms.component.help_menu.articles")],
        [mdiCommentQuestion, t("dms.component.help_menu.community")],
        [mdiNewspaper, t("dms.component.help_menu.news")],
        [mdiCommentQuote, t("dms.component.help_menu.feedback")],
        [mdiCellphoneArrowDown, t("dms.component.help_menu.app")],
        [mdiServerNetwork, t("dms.component.help_menu.status")],
    ];

    const menuContents = (
        <Dialog.Panel className="flex h-full w-96 flex-col bg-gray-50">
            <button
                onClick={() => setIsOpen(false)}
                className="absolute top-4 right-4 text-neutral-500"
            >
                <Icon path={mdiClose} className="h-5" />
            </button>
            <Dialog.Title className="border-b-2 border-blue-900 bg-gray-100 p-8 font-header text-xl">
                <>{t("dms.component.help_menu.header")}</>
            </Dialog.Title>
            <ul>
                {menuItems.map(([iconPath, text]) => {
                    return (
                        <li
                            className="group flex cursor-default items-center gap-3 border-b border-slate-300 p-4 px-6 hover:bg-gray-100"
                            key={text}
                        >
                            <Icon path={iconPath} className="inline h-8 text-slate-500" />
                            <p className="tracking-wide text-gray-800">
                                <span className="font-medium">{text}</span>
                                <br />
                                <span className="invisible absolute text-sm text-slate-500 group-hover:visible">
                                    <>{t("dms.generic.coming_soon")}</>
                                </span>
                            </p>
                        </li>
                    );
                })}
            </ul>
        </Dialog.Panel>
    );

    return (
        <>
            <button className={className} onClick={() => setIsOpen(true)}>
                <Icon path={mdiHelp} className="h-8 rounded-full bg-slate-500 p-2 text-white" />
            </button>

            <Transition appear show={isOpen} as={React.Fragment}>
                <Dialog onClose={() => setIsOpen(false)}>
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-x-4"
                        enterTo="opacity-100 translate-x-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-x-0"
                        leaveTo="opacity-0 translate-x-4"
                    >
                        <div className="preflight fixed inset-y-0 right-0 overflow-y-auto shadow-xl">
                            {menuContents}
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition>
        </>
    );
}
