/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  FleetRole,
  LoggedInUserUpdate,
  PageInfo,
  RoleGroups,
  SortDirection,
  User,
  UserCreate,
  UserDataExportRequest,
  UserDataExportResponse,
  UserSortKey,
  UserUpdate,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FleetRoleFromJSON,
    FleetRoleToJSON,
    LoggedInUserUpdateFromJSON,
    LoggedInUserUpdateToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    RoleGroupsFromJSON,
    RoleGroupsToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    UserFromJSON,
    UserToJSON,
    UserCreateFromJSON,
    UserCreateToJSON,
    UserDataExportRequestFromJSON,
    UserDataExportRequestToJSON,
    UserDataExportResponseFromJSON,
    UserDataExportResponseToJSON,
    UserSortKeyFromJSON,
    UserSortKeyToJSON,
    UserUpdateFromJSON,
    UserUpdateToJSON,
} from '../models';

export interface UsersCreateUserRequest {
    userCreate: UserCreate;
}

export interface UsersDeleteUserRequest {
    userId: string;
}

export interface UsersExportUserListRequest {
    timezone: string;
    exportType: UsersExportUserListExportType;
    listType: UsersExportUserListListType;
    pageSize: number;
    search?: string | null;
    roleGroup?: RoleGroups;
    departmentIds?: Array<string> | null;
    fleetRoles?: Array<FleetRole> | null;
    userGroupIds?: Array<string> | null;
    shiftIds?: Array<string> | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: UserSortKey;
}

export interface UsersInitiateUserDataExportRequest {
    userId: string;
    userDataExportRequest: UserDataExportRequest;
}

export interface UsersListUsersRequest {
    pageSize: number;
    search?: string | null;
    roleGroup?: RoleGroups;
    departmentIds?: Array<string> | null;
    fleetRoles?: Array<FleetRole> | null;
    userGroupIds?: Array<string> | null;
    shiftIds?: Array<string> | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: UserSortKey;
}

export interface UsersListUsersMetadataRequest {
    pageSize: number;
    roleGroup?: RoleGroups;
    search?: string | null;
    departmentIds?: Array<string> | null;
    fleetRoles?: Array<FleetRole> | null;
    userGroupIds?: Array<string> | null;
    shiftIds?: Array<string> | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: UserSortKey;
}

export interface UsersReadLastDriverByVehicleRequest {
    vehicleId: string;
}

export interface UsersReadUserRequest {
    userId: string;
}

export interface UsersUpdateLoggedInUserRequest {
    loggedInUserUpdate: LoggedInUserUpdate;
}

export interface UsersUpdateUserRequest {
    userId: string;
    userUpdate: UserUpdate;
}

export interface UsersValidateCreateUserRequest {
    userCreate: UserCreate;
}

export interface UsersValidateInitiateUserDataExportRequest {
    userId: string;
    userDataExportRequest: UserDataExportRequest;
}

export interface UsersValidateUpdateLoggedInUserRequest {
    loggedInUserUpdate: LoggedInUserUpdate;
}

export interface UsersValidateUpdateUserRequest {
    userId: string;
    userUpdate: UserUpdate;
}

/**
 * UsersApi - interface
 * 
 * @export
 * @interface UsersApiInterface
 */
export interface UsersApiInterface {
    /**
     * Create a new user.
     * @summary Create a user
     * @param {UserCreate} userCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersCreateUserRaw(requestParameters: UsersCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>>;

    /**
     * Create a new user.
     * Create a user
     */
    usersCreateUser(requestParameters: UsersCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User>;

    /**
     * Delete the user with the given ID.
     * @summary Delete a user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersDeleteUserRaw(requestParameters: UsersDeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the user with the given ID.
     * Delete a user
     */
    usersDeleteUser(requestParameters: UsersDeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Exports all users with consideration of optional filters while distinguishing between different frontend pages.
     * @summary Export user list as csv or xlsx for a specific frontend page
     * @param {string} timezone 
     * @param {'csv' | 'xlsx'} exportType 
     * @param {'operators' | 'productivity'} listType 
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {RoleGroups} [roleGroup] 
     * @param {Array<string>} [departmentIds] 
     * @param {Array<FleetRole>} [fleetRoles] 
     * @param {Array<string>} [userGroupIds] 
     * @param {Array<string>} [shiftIds] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {UserSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersExportUserListRaw(requestParameters: UsersExportUserListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     * Exports all users with consideration of optional filters while distinguishing between different frontend pages.
     * Export user list as csv or xlsx for a specific frontend page
     */
    usersExportUserList(requestParameters: UsersExportUserListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

    /**
     * Initiate a GDPR user data export to be generated and sent encrypted to the provided email.
     * @summary Initiate GDPR data export
     * @param {string} userId 
     * @param {UserDataExportRequest} userDataExportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersInitiateUserDataExportRaw(requestParameters: UsersInitiateUserDataExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDataExportResponse>>;

    /**
     * Initiate a GDPR user data export to be generated and sent encrypted to the provided email.
     * Initiate GDPR data export
     */
    usersInitiateUserDataExport(requestParameters: UsersInitiateUserDataExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDataExportResponse>;

    /**
     * Lists all the users present. If `search` is specified, return values similar to the `email`, `firstName` and `lastName` fields, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead. If `role_group` is specified, return Management Users or Fleet Users
     * @summary List users
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {RoleGroups} [roleGroup] 
     * @param {Array<string>} [departmentIds] 
     * @param {Array<FleetRole>} [fleetRoles] 
     * @param {Array<string>} [userGroupIds] 
     * @param {Array<string>} [shiftIds] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {UserSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersListUsersRaw(requestParameters: UsersListUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<User>>>;

    /**
     * Lists all the users present. If `search` is specified, return values similar to the `email`, `firstName` and `lastName` fields, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead. If `role_group` is specified, return Management Users or Fleet Users
     * List users
     */
    usersListUsers(requestParameters: UsersListUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<User>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary User listing metadata
     * @param {number} pageSize 
     * @param {RoleGroups} [roleGroup] 
     * @param {string} [search] 
     * @param {Array<string>} [departmentIds] 
     * @param {Array<FleetRole>} [fleetRoles] 
     * @param {Array<string>} [userGroupIds] 
     * @param {Array<string>} [shiftIds] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {UserSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersListUsersMetadataRaw(requestParameters: UsersListUsersMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * User listing metadata
     */
    usersListUsersMetadata(requestParameters: UsersListUsersMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the last driver specified by the given vehicle.
     * @summary Get a single user
     * @param {string} vehicleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersReadLastDriverByVehicleRaw(requestParameters: UsersReadLastDriverByVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>>;

    /**
     * Returns the last driver specified by the given vehicle.
     * Get a single user
     */
    usersReadLastDriverByVehicle(requestParameters: UsersReadLastDriverByVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User>;

    /**
     * Returns the user specified by the given ID.
     * @summary Get a single user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersReadUserRaw(requestParameters: UsersReadUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>>;

    /**
     * Returns the user specified by the given ID.
     * Get a single user
     */
    usersReadUser(requestParameters: UsersReadUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User>;

    /**
     * Update the user that\'s currently logged.
     * @summary Update the logged in user
     * @param {LoggedInUserUpdate} loggedInUserUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersUpdateLoggedInUserRaw(requestParameters: UsersUpdateLoggedInUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>>;

    /**
     * Update the user that\'s currently logged.
     * Update the logged in user
     */
    usersUpdateLoggedInUser(requestParameters: UsersUpdateLoggedInUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User>;

    /**
     * Update the user specified by the given ID.
     * @summary Update user
     * @param {string} userId 
     * @param {UserUpdate} userUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersUpdateUserRaw(requestParameters: UsersUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>>;

    /**
     * Update the user specified by the given ID.
     * Update user
     */
    usersUpdateUser(requestParameters: UsersUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User>;

    /**
     * Validate data for a new user without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a new user
     * @param {UserCreate} userCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersValidateCreateUserRaw(requestParameters: UsersValidateCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new user without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a new user
     */
    usersValidateCreateUser(requestParameters: UsersValidateCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate data for initiating a GDPR user data export. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate GDPR data export
     * @param {string} userId 
     * @param {UserDataExportRequest} userDataExportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersValidateInitiateUserDataExportRaw(requestParameters: UsersValidateInitiateUserDataExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for initiating a GDPR user data export. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate GDPR data export
     */
    usersValidateInitiateUserDataExport(requestParameters: UsersValidateInitiateUserDataExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate the update of the user that\'s currently logged.
     * @summary Validate the update of an logged in user
     * @param {LoggedInUserUpdate} loggedInUserUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersValidateUpdateLoggedInUserRaw(requestParameters: UsersValidateUpdateLoggedInUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate the update of the user that\'s currently logged.
     * Validate the update of an logged in user
     */
    usersValidateUpdateLoggedInUser(requestParameters: UsersValidateUpdateLoggedInUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate data for updating the given user, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate user update
     * @param {string} userId 
     * @param {UserUpdate} userUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersValidateUpdateUserRaw(requestParameters: UsersValidateUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for updating the given user, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate user update
     */
    usersValidateUpdateUser(requestParameters: UsersValidateUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI implements UsersApiInterface {

    /**
     * Create a new user.
     * Create a user
     */
    async usersCreateUserRaw(requestParameters: UsersCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userCreate === null || requestParameters.userCreate === undefined) {
            throw new runtime.RequiredError('userCreate','Required parameter requestParameters.userCreate was null or undefined when calling usersCreateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCreateToJSON(requestParameters.userCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Create a new user.
     * Create a user
     */
    async usersCreateUser(requestParameters: UsersCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.usersCreateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the user with the given ID.
     * Delete a user
     */
    async usersDeleteUserRaw(requestParameters: UsersDeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersDeleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the user with the given ID.
     * Delete a user
     */
    async usersDeleteUser(requestParameters: UsersDeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersDeleteUserRaw(requestParameters, initOverrides);
    }

    /**
     * Exports all users with consideration of optional filters while distinguishing between different frontend pages.
     * Export user list as csv or xlsx for a specific frontend page
     */
    async usersExportUserListRaw(requestParameters: UsersExportUserListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.timezone === null || requestParameters.timezone === undefined) {
            throw new runtime.RequiredError('timezone','Required parameter requestParameters.timezone was null or undefined when calling usersExportUserList.');
        }

        if (requestParameters.exportType === null || requestParameters.exportType === undefined) {
            throw new runtime.RequiredError('exportType','Required parameter requestParameters.exportType was null or undefined when calling usersExportUserList.');
        }

        if (requestParameters.listType === null || requestParameters.listType === undefined) {
            throw new runtime.RequiredError('listType','Required parameter requestParameters.listType was null or undefined when calling usersExportUserList.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling usersExportUserList.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.roleGroup !== undefined) {
            queryParameters['role_group'] = requestParameters.roleGroup;
        }

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        if (requestParameters.exportType !== undefined) {
            queryParameters['export_type'] = requestParameters.exportType;
        }

        if (requestParameters.listType !== undefined) {
            queryParameters['list_type'] = requestParameters.listType;
        }

        if (requestParameters.departmentIds) {
            queryParameters['department_ids'] = requestParameters.departmentIds;
        }

        if (requestParameters.fleetRoles) {
            queryParameters['fleet_roles'] = requestParameters.fleetRoles;
        }

        if (requestParameters.userGroupIds) {
            queryParameters['user_group_ids'] = requestParameters.userGroupIds;
        }

        if (requestParameters.shiftIds) {
            queryParameters['shift_ids'] = requestParameters.shiftIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Exports all users with consideration of optional filters while distinguishing between different frontend pages.
     * Export user list as csv or xlsx for a specific frontend page
     */
    async usersExportUserList(requestParameters: UsersExportUserListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.usersExportUserListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Initiate a GDPR user data export to be generated and sent encrypted to the provided email.
     * Initiate GDPR data export
     */
    async usersInitiateUserDataExportRaw(requestParameters: UsersInitiateUserDataExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDataExportResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersInitiateUserDataExport.');
        }

        if (requestParameters.userDataExportRequest === null || requestParameters.userDataExportRequest === undefined) {
            throw new runtime.RequiredError('userDataExportRequest','Required parameter requestParameters.userDataExportRequest was null or undefined when calling usersInitiateUserDataExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}/export`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserDataExportRequestToJSON(requestParameters.userDataExportRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDataExportResponseFromJSON(jsonValue));
    }

    /**
     * Initiate a GDPR user data export to be generated and sent encrypted to the provided email.
     * Initiate GDPR data export
     */
    async usersInitiateUserDataExport(requestParameters: UsersInitiateUserDataExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDataExportResponse> {
        const response = await this.usersInitiateUserDataExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists all the users present. If `search` is specified, return values similar to the `email`, `firstName` and `lastName` fields, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead. If `role_group` is specified, return Management Users or Fleet Users
     * List users
     */
    async usersListUsersRaw(requestParameters: UsersListUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<User>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling usersListUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.roleGroup !== undefined) {
            queryParameters['role_group'] = requestParameters.roleGroup;
        }

        if (requestParameters.departmentIds) {
            queryParameters['department_ids'] = requestParameters.departmentIds;
        }

        if (requestParameters.fleetRoles) {
            queryParameters['fleet_roles'] = requestParameters.fleetRoles;
        }

        if (requestParameters.userGroupIds) {
            queryParameters['user_group_ids'] = requestParameters.userGroupIds;
        }

        if (requestParameters.shiftIds) {
            queryParameters['shift_ids'] = requestParameters.shiftIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * Lists all the users present. If `search` is specified, return values similar to the `email`, `firstName` and `lastName` fields, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead. If `role_group` is specified, return Management Users or Fleet Users
     * List users
     */
    async usersListUsers(requestParameters: UsersListUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<User>> {
        const response = await this.usersListUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * User listing metadata
     */
    async usersListUsersMetadataRaw(requestParameters: UsersListUsersMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling usersListUsersMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.roleGroup !== undefined) {
            queryParameters['role_group'] = requestParameters.roleGroup;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.departmentIds) {
            queryParameters['department_ids'] = requestParameters.departmentIds;
        }

        if (requestParameters.fleetRoles) {
            queryParameters['fleet_roles'] = requestParameters.fleetRoles;
        }

        if (requestParameters.userGroupIds) {
            queryParameters['user_group_ids'] = requestParameters.userGroupIds;
        }

        if (requestParameters.shiftIds) {
            queryParameters['shift_ids'] = requestParameters.shiftIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * User listing metadata
     */
    async usersListUsersMetadata(requestParameters: UsersListUsersMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.usersListUsersMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the last driver specified by the given vehicle.
     * Get a single user
     */
    async usersReadLastDriverByVehicleRaw(requestParameters: UsersReadLastDriverByVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.vehicleId === null || requestParameters.vehicleId === undefined) {
            throw new runtime.RequiredError('vehicleId','Required parameter requestParameters.vehicleId was null or undefined when calling usersReadLastDriverByVehicle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/last_driver/{vehicle_id}`.replace(`{${"vehicle_id"}}`, encodeURIComponent(String(requestParameters.vehicleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Returns the last driver specified by the given vehicle.
     * Get a single user
     */
    async usersReadLastDriverByVehicle(requestParameters: UsersReadLastDriverByVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.usersReadLastDriverByVehicleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the user specified by the given ID.
     * Get a single user
     */
    async usersReadUserRaw(requestParameters: UsersReadUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersReadUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Returns the user specified by the given ID.
     * Get a single user
     */
    async usersReadUser(requestParameters: UsersReadUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.usersReadUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the user that\'s currently logged.
     * Update the logged in user
     */
    async usersUpdateLoggedInUserRaw(requestParameters: UsersUpdateLoggedInUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.loggedInUserUpdate === null || requestParameters.loggedInUserUpdate === undefined) {
            throw new runtime.RequiredError('loggedInUserUpdate','Required parameter requestParameters.loggedInUserUpdate was null or undefined when calling usersUpdateLoggedInUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: LoggedInUserUpdateToJSON(requestParameters.loggedInUserUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Update the user that\'s currently logged.
     * Update the logged in user
     */
    async usersUpdateLoggedInUser(requestParameters: UsersUpdateLoggedInUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.usersUpdateLoggedInUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the user specified by the given ID.
     * Update user
     */
    async usersUpdateUserRaw(requestParameters: UsersUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersUpdateUser.');
        }

        if (requestParameters.userUpdate === null || requestParameters.userUpdate === undefined) {
            throw new runtime.RequiredError('userUpdate','Required parameter requestParameters.userUpdate was null or undefined when calling usersUpdateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateToJSON(requestParameters.userUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Update the user specified by the given ID.
     * Update user
     */
    async usersUpdateUser(requestParameters: UsersUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.usersUpdateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new user without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a new user
     */
    async usersValidateCreateUserRaw(requestParameters: UsersValidateCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userCreate === null || requestParameters.userCreate === undefined) {
            throw new runtime.RequiredError('userCreate','Required parameter requestParameters.userCreate was null or undefined when calling usersValidateCreateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCreateToJSON(requestParameters.userCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new user without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a new user
     */
    async usersValidateCreateUser(requestParameters: UsersValidateCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersValidateCreateUserRaw(requestParameters, initOverrides);
    }

    /**
     * Validate data for initiating a GDPR user data export. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate GDPR data export
     */
    async usersValidateInitiateUserDataExportRaw(requestParameters: UsersValidateInitiateUserDataExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersValidateInitiateUserDataExport.');
        }

        if (requestParameters.userDataExportRequest === null || requestParameters.userDataExportRequest === undefined) {
            throw new runtime.RequiredError('userDataExportRequest','Required parameter requestParameters.userDataExportRequest was null or undefined when calling usersValidateInitiateUserDataExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}/export/validate`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserDataExportRequestToJSON(requestParameters.userDataExportRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for initiating a GDPR user data export. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate GDPR data export
     */
    async usersValidateInitiateUserDataExport(requestParameters: UsersValidateInitiateUserDataExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersValidateInitiateUserDataExportRaw(requestParameters, initOverrides);
    }

    /**
     * Validate the update of the user that\'s currently logged.
     * Validate the update of an logged in user
     */
    async usersValidateUpdateLoggedInUserRaw(requestParameters: UsersValidateUpdateLoggedInUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.loggedInUserUpdate === null || requestParameters.loggedInUserUpdate === undefined) {
            throw new runtime.RequiredError('loggedInUserUpdate','Required parameter requestParameters.loggedInUserUpdate was null or undefined when calling usersValidateUpdateLoggedInUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/validate`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: LoggedInUserUpdateToJSON(requestParameters.loggedInUserUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate the update of the user that\'s currently logged.
     * Validate the update of an logged in user
     */
    async usersValidateUpdateLoggedInUser(requestParameters: UsersValidateUpdateLoggedInUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersValidateUpdateLoggedInUserRaw(requestParameters, initOverrides);
    }

    /**
     * Validate data for updating the given user, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate user update
     */
    async usersValidateUpdateUserRaw(requestParameters: UsersValidateUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersValidateUpdateUser.');
        }

        if (requestParameters.userUpdate === null || requestParameters.userUpdate === undefined) {
            throw new runtime.RequiredError('userUpdate','Required parameter requestParameters.userUpdate was null or undefined when calling usersValidateUpdateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}/validate`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateToJSON(requestParameters.userUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for updating the given user, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate user update
     */
    async usersValidateUpdateUser(requestParameters: UsersValidateUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersValidateUpdateUserRaw(requestParameters, initOverrides);
    }

}

/**
  * @export
  * @enum {string}
  */
export enum UsersExportUserListExportType {
    CSV = 'csv',
    XLSX = 'xlsx'
}
/**
  * @export
  * @enum {string}
  */
export enum UsersExportUserListListType {
    OPERATORS = 'operators',
    PRODUCTIVITY = 'productivity'
}
