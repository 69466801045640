import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    Dropdown,
    IDropdownOption,
    IconButton,
    PrimaryButton,
    Stack,
    TextField,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactFlow, {
    Panel,
    Node,
    ReactFlowInstance,
    Connection,
    addEdge,
    Background,
    Controls,
    useNodesState,
    useEdgesState,
    NodeTypes,
    EdgeTypes,
} from "reactflow";
import {
    ActionConfig,
    ActionConfigUpdate,
    ActionConfigsApi,
    EloshieldModule,
    NodeKind,
} from "../../api";
import VehiclesInZone from "../molecules/action-configs/vehicles-in-zone";
import PedestriansInZone from "../molecules/action-configs/pedestrians-in-zone";
import GreaterThan from "../molecules/action-configs/greater-than";
import StationsInZone from "../molecules/action-configs/stations-in-zone";
import Equal from "../molecules/action-configs/equal";
import And from "../molecules/action-configs/and";
import Or from "../molecules/action-configs/or";
import LessThan from "../molecules/action-configs/less-than";
import InErrorMode from "../molecules/action-configs/in-error-mode";
import ActionConfigEdge from "../molecules/action-configs/action-config-edge";
import {
    ValidationFieldState,
    ValidationFieldStateInvalid,
    wrapApiValidationRoute,
} from "../../utils/validation";
import Muting from "../molecules/action-configs/muting";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import DigitalInputState from "../molecules/action-configs/digital-input-state";
import DeactivateAntennas from "../molecules/action-configs/deactivate-antennas";
import Not from "../molecules/action-configs/not";
import colors from "../../ui/colors.scss";
import { createUuid } from "../../utils/uuid";
import ElofleetIcon from "./elofleet-icon";
import ActionConfigNodesSidebar from "./action-config-nodes-sidebar";
import AnyInZone from "../molecules/action-configs/any-in-zone";
import SetStationaryRelay from "../molecules/action-configs/set-stationary-relay";
import SetVehicleRelay from "../molecules/action-configs/set-vehicle-relay";

/** All available node types. We use the OpenAPI generated enum variants as keys
    to ensure a direct mapping to the backend. */
const nodeTypes: NodeTypes = {
    // Input nodes
    [NodeKind.ANY_IN_ZONE]: AnyInZone,
    [NodeKind.DIGITAL_INPUT_STATE]: DigitalInputState,
    [NodeKind.IN_ERROR_MODE]: InErrorMode,
    [NodeKind.MUTING]: Muting,
    [NodeKind.PEDESTRIANS_IN_ZONE]: PedestriansInZone,
    [NodeKind.STATIONS_IN_ZONE]: StationsInZone,
    [NodeKind.VEHICLES_IN_ZONE]: VehiclesInZone,

    // Intermediate nodes
    [NodeKind.AND]: And,
    [NodeKind.EQUAL]: Equal,
    [NodeKind.GREATER_THAN]: GreaterThan,
    [NodeKind.LESS_THAN]: LessThan,
    [NodeKind.NOT]: Not,
    [NodeKind.OR]: Or,

    // Output nodes
    [NodeKind.DEACTIVATE_ANTENNAS]: DeactivateAntennas,
    [NodeKind.SET_STATIONARY_RELAY]: SetStationaryRelay,
    [NodeKind.SET_VEHICLE_RELAY]: SetVehicleRelay,
};

const edgeTypes: EdgeTypes = {
    actionConfigEdge: ActionConfigEdge,
};

function getEloshieldModuleIcon(module: EloshieldModule): string {
    switch (module) {
        case EloshieldModule.PEDESTRIAN:
            return "Contact";
        case EloshieldModule.STATION:
            return "NetworkTower";
        case EloshieldModule.VEHICLE:
            return "Forklift";
        default:
            return "StatusCircleQuestionMark";
    }
}

interface EloshieldModuleData {
    icon: string;
}

const onRenderOption = (option?: IDropdownOption<EloshieldModuleData>): JSX.Element => {
    if (!option) {
        return <></>;
    }
    return (
        <div>
            {option.data && option.data.icon && (
                <ElofleetIcon
                    icon={option.data.icon}
                    aria-hidden="true"
                    style={{
                        width: 14,
                        height: 14,
                        marginRight: 8,
                        display: "inline-flex",
                    }}
                />
            )}
            <span>{option.text}</span>
        </div>
    );
};

const onRenderTitle = (options?: IDropdownOption<EloshieldModuleData>[]): JSX.Element => {
    if (!options) {
        return <></>;
    }

    const option = options[0];
    return (
        <div>
            {option.data && option.data.icon && (
                <ElofleetIcon
                    icon={option.data.icon}
                    aria-hidden="true"
                    style={{
                        width: 14,
                        height: 14,
                        marginRight: 8,
                        display: "inline-flex",
                    }}
                />
            )}
            <span>{option.text}</span>
        </div>
    );
};

export function ActionConfiguration(): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();

    // We manage the sidebar state in here, as we adjust the react flow view
    // depending on the sidebar collapse state.

    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>();
    const [actionConfigs, setActionConfigs] = useState<ActionConfig[]>([]);
    const [selectedActionConfig, setSelectedActionConfig] = useState<ActionConfig>();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const [actionConfigCreateLabel, setActionConfigCreateLabel] = useState<string>();
    const [actionConfigCreateModule, setActionConfigCreateModule] = useState<EloshieldModule>(
        EloshieldModule.PEDESTRIAN,
    );
    const [hideCreateDialog, { toggle: toggleCreateHideDialog }] = useBoolean(true);
    const [hideEditDialog, { toggle: toggleEditHideDialog }] = useBoolean(true);
    const [hideDeleteDialog, { toggle: toggleDeleteHideDialog }] = useBoolean(true);

    const actionConfigsApi = useMemo(() => new ActionConfigsApi(), []);

    const createDialogContentProps = {
        type: DialogType.normal,
        title: t("page.actionConfiguration.createDialog.title"),
        subText: t("page.actionConfiguration.createDialog.subText"),
    };

    const editDialogContentProps = {
        type: DialogType.normal,
        title: t("page.actionConfiguration.editDialog.title"),
        subText: t("page.actionConfiguration.editDialog.subText"),
    };

    const deleteDialogContentProps = {
        type: DialogType.normal,
        title: t("page.actionConfiguration.deleteDialog.title"),
        subText: t("page.actionConfiguration.deleteDialog.subText"),
    };

    const getId = useCallback(() => {
        // If there are no nodes just return ID 1.
        if (nodes.length === 0) {
            return 1;
        }
        // Otherwise return the next highest ID.
        const ids = nodes.map((node) => Number(node.id)).sort((n1, n2) => n1 - n2);
        return ids[ids.length - 1] + 1;
    }, [nodes]);

    useEffect(() => {
        setNodes(selectedActionConfig?.nodes ?? []);
        setEdges(selectedActionConfig?.edges ?? []);
        actionConfigsApi.actionConfigsListActionConfigs({ pageSize: 500 }).then(setActionConfigs);
    }, [actionConfigsApi, setEdges, setNodes, selectedActionConfig]);

    const onConnect = useCallback(
        (conn: Connection) => {
            // Return early if source node output is incompatible with target
            // node input
            if (conn.sourceHandle !== conn.targetHandle) {
                return;
            }
            setEdges((eds) => addEdge({ ...conn, animated: true, type: "actionConfigEdge" }, eds));
        },
        [setEdges],
    );

    const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    }, []);

    const onDrop = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            if (!reactFlowInstance) {
                return;
            }

            // Check if the dropped element is valid
            const type = event.dataTransfer.getData("application/reactflow");
            if (typeof type === "undefined" || !type) {
                return;
            }

            const position = reactFlowInstance.screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });
            const newNode: Node = {
                id: getId().toString(),
                type,
                position,
                deletable: true,
                data: {},
            };
            setNodes((nodes) => nodes.concat(newNode));
        },
        [getId, reactFlowInstance, setNodes],
    );

    async function validateConfiguration(actionConfigUpdate: ActionConfigUpdate): Promise<void> {
        if (!selectedActionConfig) {
            return;
        }
        // Wrap API call to extract validation errors.
        const remoteValidationResult = await wrapApiValidationRoute<ValidationFieldState>(() =>
            actionConfigsApi.actionConfigsValidateUpdateActionConfig({
                actionConfigId: selectedActionConfig.id,
                actionConfigUpdate,
            }),
        );
        const fieldStates = Object.values(
            remoteValidationResult.fields,
        ) as ValidationFieldStateInvalid[];
        const invalidNodes = new Map<string, string>();
        fieldStates.forEach((state) =>
            state.problems.forEach((problem) => {
                invalidNodes.set(problem.code, problem.message ?? "unknown");
            }),
        );
        setNodes((nodes) =>
            nodes.map((node) => {
                const validationErrorMessage = invalidNodes.get(node.id);
                if (validationErrorMessage) {
                    node.data = {
                        ...node.data,
                        invalid: true,
                        validationErrorMessage,
                    };
                } else {
                    node.data = {
                        ...node.data,
                        invalid: false,
                        validationErrorMessage: undefined,
                    };
                }
                return node;
            }),
        );
    }

    async function updateAndValidateConfiguration(): Promise<void> {
        if (!selectedActionConfig) {
            return;
        }
        const actionConfigUpdate: ActionConfigUpdate = {
            ...selectedActionConfig,
            nodes,
            edges,
        };
        await validateConfiguration(actionConfigUpdate);
        await actionConfigsApi.actionConfigsUpdateActionConfig({
            actionConfigId: selectedActionConfig.id,
            actionConfigUpdate,
        });
    }

    async function handleActionConfigSelect(
        _event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ): Promise<void> {
        if (!option?.key) {
            return;
        }
        const actionConfig = await actionConfigsApi.actionConfigsReadActionConfig({
            actionConfigId: option.key as string,
        });
        setNodes([]);
        setEdges([]);
        setSelectedActionConfig(actionConfig);
    }

    return (
        <>
            <ReactFlow
                proOptions={{ hideAttribution: true }}
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onConnect={onConnect}
                onInit={setReactFlowInstance}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                fitView
                fitViewOptions={{ padding: 0.2 }}
            >
                <Panel position="top-center">
                    <Stack horizontal tokens={{ childrenGap: "1em" }}>
                        <Dropdown
                            label={t("page.actionConfiguration.dropdown.label")}
                            onRenderOption={onRenderOption}
                            onRenderTitle={onRenderTitle}
                            selectedKey={selectedActionConfig?.id ?? createUuid()}
                            options={actionConfigs.map((config) => {
                                const icon = getEloshieldModuleIcon(config.eloshieldModule);
                                return {
                                    key: config.id,
                                    text: config.label,
                                    data: { icon: icon },
                                };
                            })}
                            onChange={handleActionConfigSelect}
                            styles={{ dropdown: { width: 300 } }}
                        />
                        <Stack.Item style={{ alignSelf: "end" }}>
                            <IconButton
                                iconProps={{ iconName: "Add" }}
                                onClick={toggleCreateHideDialog}
                            />
                            <IconButton
                                disabled={!selectedActionConfig}
                                iconProps={{ iconName: "Edit" }}
                                onClick={toggleEditHideDialog}
                            />
                            <IconButton
                                disabled={!selectedActionConfig}
                                iconProps={{
                                    iconName: "Trash",
                                    styles: {
                                        root: {
                                            color: selectedActionConfig
                                                ? colors["secondarydashboard-severe"]
                                                : "",
                                        },
                                    },
                                }}
                                onClick={toggleDeleteHideDialog}
                            />
                            <PrimaryButton
                                disabled={!selectedActionConfig}
                                onClick={updateAndValidateConfiguration}
                                text={t("page.actionConfiguration.updateConfiguration")}
                            />
                        </Stack.Item>
                    </Stack>
                </Panel>
                <Background />
                <Controls fitViewOptions={{ padding: 0.2 }} position={"top-left"} />
            </ReactFlow>
            <ActionConfigNodesSidebar eloshieldModule={selectedActionConfig?.eloshieldModule} />
            <Dialog
                hidden={hideDeleteDialog}
                onDismiss={toggleDeleteHideDialog}
                dialogContentProps={deleteDialogContentProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={async () => {
                            if (!selectedActionConfig) {
                                return;
                            }
                            await actionConfigsApi.actionConfigsDeleteActionConfig({
                                actionConfigId: selectedActionConfig.id,
                            });
                            toggleDeleteHideDialog();
                            setSelectedActionConfig(undefined);
                        }}
                        text={t("page.actionConfiguration.deleteDialog.delete")}
                    />
                    <DefaultButton
                        onClick={toggleDeleteHideDialog}
                        text={t("page.actionConfiguration.deleteDialog.cancel")}
                    />
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={hideEditDialog}
                onDismiss={toggleEditHideDialog}
                dialogContentProps={editDialogContentProps}
            >
                <TextField
                    label={t("page.actionConfiguration.editDialog.nameInput.label")}
                    onChange={(_e, newValue) => {
                        if (!selectedActionConfig || !newValue) {
                            return;
                        }
                        setSelectedActionConfig({ ...selectedActionConfig, label: newValue });
                    }}
                    placeholder={selectedActionConfig?.label}
                />
                <DialogFooter>
                    <PrimaryButton
                        onClick={async () => {
                            if (!selectedActionConfig) {
                                return;
                            }
                            const updatedConfig =
                                await actionConfigsApi.actionConfigsUpdateActionConfig({
                                    actionConfigId: selectedActionConfig.id,
                                    actionConfigUpdate: selectedActionConfig,
                                });
                            toggleEditHideDialog();
                            setSelectedActionConfig(updatedConfig);
                        }}
                        text={t("page.actionConfiguration.editDialog.update")}
                    />
                    <DefaultButton
                        onClick={toggleEditHideDialog}
                        text={t("page.actionConfiguration.editDialog.cancel")}
                    />
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={hideCreateDialog}
                onDismiss={toggleCreateHideDialog}
                dialogContentProps={createDialogContentProps}
            >
                <Dropdown
                    label={t("page.actionConfiguration.createDialog.moduleInput.label")}
                    onRenderOption={onRenderOption}
                    onRenderTitle={onRenderTitle}
                    options={Object.values(EloshieldModule)
                        .filter((key) => key !== EloshieldModule.PEDESTRIAN)
                        .map((key, _index) => {
                            return {
                                key,
                                text: t(`component.actionConfigNodes.eloshieldModule.${key}`),
                                data: { icon: getEloshieldModuleIcon(key) },
                            };
                        })}
                    onChange={(_e, option) => {
                        if (!option) {
                            return;
                        }
                        setActionConfigCreateModule(option.key as EloshieldModule);
                    }}
                    styles={{ dropdown: { width: 200 } }}
                />
                <TextField
                    label={t("page.actionConfiguration.createDialog.nameInput.label")}
                    onChange={(_e, newValue) => {
                        setActionConfigCreateLabel(newValue);
                    }}
                    value={actionConfigCreateLabel}
                />
                <DialogFooter>
                    <PrimaryButton
                        onClick={async () => {
                            if (!actionConfigCreateLabel) {
                                return;
                            }
                            const newActionConfig =
                                await actionConfigsApi.actionConfigsCreateActionConfig({
                                    actionConfigCreate: {
                                        nodes: [],
                                        edges: [],
                                        label: actionConfigCreateLabel,
                                        eloshieldModule: actionConfigCreateModule,
                                    },
                                });
                            toggleCreateHideDialog();
                            setSelectedActionConfig(newActionConfig);
                        }}
                        text={t("page.actionConfiguration.createDialog.create")}
                    />
                    <DefaultButton
                        onClick={toggleCreateHideDialog}
                        text={t("page.actionConfiguration.createDialog.cancel")}
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
}
