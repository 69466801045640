import { IStackTokens, Stack, StackItem } from "@fluentui/react";
import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { declareRoute, routePath, RouteProps } from "../routes";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { FormUpdateDefaultLanguage } from "../ui/molecules/form-update-default-language";
import { ListDepartments } from "../ui/molecules/list-departments";
import { ListShifts } from "../ui/molecules/list-shifts";
import { ListCommandButtonsDepartments } from "../ui/organisms/list-command-buttons-departments";
import { ListCommandButtonsShifts } from "../ui/organisms/list-command-buttons-shifts";
import { createUuid } from "../utils/uuid";
import { PageHeader } from "../ui/atoms/page-header";
import { Section } from "../ui/atoms/section";
import { FormUpdateNotificationMails } from "../ui/molecules/form-update-notification-mails";
import { FormUpdateDefaultTimezone } from "../ui/molecules/form-update-default-timezone";
import { FormUpdateDefaultDateFormat } from "../ui/molecules/form-update-default-date-format";
import { FormUpdateDefaultTimeFormat } from "../ui/molecules/form-update-default-time-format";
import { articles } from "../help";

const numericalSpacingStackTokens: IStackTokens = {
    childrenGap: 30,
    padding: 30,
};

@external
@observer
export class PageSystemWide extends React.Component<RouteProps> {
    @inject protected readonly i18n!: I18nProvider;

    // Create the UUID on this level, so the button bar is able to access the same state as the list.
    private departmentsListStateId = createUuid();
    private shiftsListStateId = createUuid();

    public render(): JSX.Element {
        return (
            <LayoutDefault
                suggestedHelpArticles={[
                    articles.adminSystemWideSettings,
                    articles.administratorFunctionalities,
                ]}
                header={<PageHeader title={this.i18n.t("page.systemWide.title")} />}
            >
                <Stack horizontal horizontalAlign="start" tokens={numericalSpacingStackTokens} wrap>
                    <StackItem>
                        <Section
                            withPadding
                            title={this.i18n.t("page.systemWide.department.title")}
                            icon="Group"
                        >
                            <ListCommandButtonsDepartments
                                listStateId={this.departmentsListStateId}
                                canDelete
                            />
                            <ListDepartments listStateId={this.departmentsListStateId} />
                        </Section>
                    </StackItem>
                    <StackItem>
                        <Section
                            withPadding
                            title={this.i18n.t("page.systemWide.shift.title")}
                            icon="WorkforceManagement"
                        >
                            <ListCommandButtonsShifts
                                listStateId={this.shiftsListStateId}
                                canDelete
                            />
                            <ListShifts listStateId={this.shiftsListStateId} />
                        </Section>
                    </StackItem>
                    <StackItem>
                        <Section
                            withPadding
                            title={this.i18n.t("page.systemWide.general.title")}
                            icon="Settings"
                        >
                            <FormUpdateDefaultLanguage />
                            <FormUpdateDefaultTimezone />
                            <FormUpdateDefaultDateFormat />
                            <FormUpdateDefaultTimeFormat />
                        </Section>
                    </StackItem>

                    <StackItem>
                        <Section
                            withPadding
                            title={this.i18n.t("page.systemWide.emails.title")}
                            icon="CreateMailRule"
                        >
                            <Stack.Item>
                                <FormUpdateNotificationMails />
                            </Stack.Item>
                        </Section>
                    </StackItem>
                </Stack>
            </LayoutDefault>
        );
    }
}

export const routeSystemWide = declareRoute({
    component: PageSystemWide,
    icon: "Settings",
    title: "page.systemWide.navbarEntry",
    path: routePath.systemWide,
    pattern: "/system-wide",
});
