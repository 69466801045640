import * as React from "react";
import { pick } from "ramda";
import { observer } from "mobx-react";
import { Stack, TextField, DefaultButton } from "@fluentui/react";
import { action, computed, makeObservable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { DepartmentUpdate } from "../../api";
import { doubleBindString } from "../../utils/double-bind";
import { Department } from "../../api";
import { createUuid } from "../../utils/uuid";
import { FormUpdateProps } from "../../utils/form-update-props";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";

export interface FormUpdateDepartmentProps extends FormUpdateProps<Department> {}

@observer
@external
export class FormUpdateDepartment extends React.Component<FormUpdateDepartmentProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryDepartments!: RepositoryDepartments;

    private validationId = createUuid();

    constructor(props: FormUpdateDepartmentProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositoryDepartments.validation.update.updateModel(
            this.validationId,
            this.departmentUpdate,
        );
    }

    @initialize protected initialize(): void {
        this.repositoryDepartments.validation.update.initializeModel(
            this.validationId,
            this.departmentUpdate,
            this.props.id,
        );
    }

    public componentWillUnmount(): void {
        this.repositoryDepartments.discardMutableCopy(FormUpdateDepartment.name, this.props.id);
    }

    @computed private get department(): Department | undefined {
        return this.repositoryDepartments.mutableCopyById(FormUpdateDepartment.name, this.props.id);
    }

    @computed private get departmentUpdate(): DepartmentUpdate {
        return pick(["label"], this.department);
    }

    @action.bound
    private async updateDepartment(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const department = await this.repositoryDepartments.update(
            this.props.id,
            this.departmentUpdate,
        );
        if (this.props.onUpdate) {
            this.props.onUpdate(department);
        }
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formUpdateDepartment.submit.text")}
                validation={this.repositoryDepartments.validation.update}
                validationId={this.validationId}
            />
        );
        return (
            <form onSubmit={this.updateDepartment}>
                <Stack tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                    <TextField
                        label={this.i18n.t("formUpdateDepartment.label.label")}
                        disabled={!this.department}
                        {...doubleBindString(this.department!, "label", () =>
                            this.triggerValidation(),
                        )}
                        required
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryDepartments.validation.update.getFieldValidationState(
                                this.validationId,
                                "label",
                            ),
                        )}
                    />
                </Stack>
                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            label={this.i18n.t("formUpdateDepartment.cancel.label")}
                            text={this.i18n.t("formUpdateDepartment.cancel.text")}
                            onClick={this.props.onDialogCancel}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
            </form>
        );
    }
}
