/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PreOpsChecklistSortKey {
    CREATED_AT = 'createdAt',
    LABEL = 'label'
}


export function PreOpsChecklistSortKeyFromJSON(json: any): PreOpsChecklistSortKey {
    return PreOpsChecklistSortKeyFromJSONTyped(json, false);
}

export function PreOpsChecklistSortKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreOpsChecklistSortKey {
    return json as PreOpsChecklistSortKey;
}

export function PreOpsChecklistSortKeyToJSON(value?: PreOpsChecklistSortKey | null): any {
    return value as any;
}

