/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum VehicleStatus {
    ACTIVE = 'active',
    IN_MAINTENANCE = 'inMaintenance',
    MAINTENANCE_DUE = 'maintenanceDue',
    MAINTENANCE_OVERDUE = 'maintenanceOverdue'
}


export function VehicleStatusFromJSON(json: any): VehicleStatus {
    return VehicleStatusFromJSONTyped(json, false);
}

export function VehicleStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleStatus {
    return json as VehicleStatus;
}

export function VehicleStatusToJSON(value?: VehicleStatus | null): any {
    return value as any;
}

