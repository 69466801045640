/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShockProfileCreate
 */
export interface ShockProfileCreate {
    /**
     * The name of the shock profile.
     * @type {string}
     * @memberof ShockProfileCreate
     */
    label: string;
    /**
     * The threshold for light shock events. The value is measured in g-force.
     * @type {number}
     * @memberof ShockProfileCreate
     */
    light: number;
    /**
     * The threshold for medium shock events. The value is measured in g-force.
     * @type {number}
     * @memberof ShockProfileCreate
     */
    medium: number;
    /**
     * The threshold for severe shock events. The value is measured in g-force.
     * @type {number}
     * @memberof ShockProfileCreate
     */
    severe: number;
    /**
     * There must be elevated gforce values for this amount of time, before we consider the current event an actual crash. The unit of this value are milliseconds. System time resolution is one second and shock won't last more.
     * @type {number}
     * @memberof ShockProfileCreate
     */
    duration: number;
}

/**
 * Check if a given object implements the ShockProfileCreate interface.
 */
export function instanceOfShockProfileCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "light" in value;
    isInstance = isInstance && "medium" in value;
    isInstance = isInstance && "severe" in value;
    isInstance = isInstance && "duration" in value;

    return isInstance;
}

export function ShockProfileCreateFromJSON(json: any): ShockProfileCreate {
    return ShockProfileCreateFromJSONTyped(json, false);
}

export function ShockProfileCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShockProfileCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'light': json['light'],
        'medium': json['medium'],
        'severe': json['severe'],
        'duration': json['duration'],
    };
}

export function ShockProfileCreateToJSON(value?: ShockProfileCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'light': value.light,
        'medium': value.medium,
        'severe': value.severe,
        'duration': value.duration,
    };
}

