/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PreOpsAnswerListUpdate
 */
export interface PreOpsAnswerListUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof PreOpsAnswerListUpdate
     */
    userFullName?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PreOpsAnswerListUpdate
     */
    vehicleSerialNumber?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PreOpsAnswerListUpdate
     */
    departmentLabel?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PreOpsAnswerListUpdate
     */
    preOpsChecklistLabel?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PreOpsAnswerListUpdate
     */
    preOpsQuestionBody?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PreOpsAnswerListUpdate
     */
    preOpsAnswerChoice?: boolean | null;
}

/**
 * Check if a given object implements the PreOpsAnswerListUpdate interface.
 */
export function instanceOfPreOpsAnswerListUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PreOpsAnswerListUpdateFromJSON(json: any): PreOpsAnswerListUpdate {
    return PreOpsAnswerListUpdateFromJSONTyped(json, false);
}

export function PreOpsAnswerListUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreOpsAnswerListUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userFullName': !exists(json, 'userFullName') ? undefined : json['userFullName'],
        'vehicleSerialNumber': !exists(json, 'vehicleSerialNumber') ? undefined : json['vehicleSerialNumber'],
        'departmentLabel': !exists(json, 'departmentLabel') ? undefined : json['departmentLabel'],
        'preOpsChecklistLabel': !exists(json, 'preOpsChecklistLabel') ? undefined : json['preOpsChecklistLabel'],
        'preOpsQuestionBody': !exists(json, 'preOpsQuestionBody') ? undefined : json['preOpsQuestionBody'],
        'preOpsAnswerChoice': !exists(json, 'preOpsAnswerChoice') ? undefined : json['preOpsAnswerChoice'],
    };
}

export function PreOpsAnswerListUpdateToJSON(value?: PreOpsAnswerListUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userFullName': value.userFullName,
        'vehicleSerialNumber': value.vehicleSerialNumber,
        'departmentLabel': value.departmentLabel,
        'preOpsChecklistLabel': value.preOpsChecklistLabel,
        'preOpsQuestionBody': value.preOpsQuestionBody,
        'preOpsAnswerChoice': value.preOpsAnswerChoice,
    };
}

