/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailPasswordLoginInfo } from './EmailPasswordLoginInfo';
import {
    EmailPasswordLoginInfoFromJSON,
    EmailPasswordLoginInfoFromJSONTyped,
    EmailPasswordLoginInfoToJSON,
} from './EmailPasswordLoginInfo';

/**
 * 
 * @export
 * @interface AuthenticationInfo
 */
export interface AuthenticationInfo {
    /**
     * 
     * @type {EmailPasswordLoginInfo}
     * @memberof AuthenticationInfo
     */
    emailPassword?: EmailPasswordLoginInfo | null;
    /**
     * Normalized to lowercase by the backend.
     * @type {string}
     * @memberof AuthenticationInfo
     */
    nfcToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationInfo
     */
    hardwareKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationInfo
     */
    emailLoginToken?: string | null;
}

/**
 * Check if a given object implements the AuthenticationInfo interface.
 */
export function instanceOfAuthenticationInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuthenticationInfoFromJSON(json: any): AuthenticationInfo {
    return AuthenticationInfoFromJSONTyped(json, false);
}

export function AuthenticationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailPassword': !exists(json, 'emailPassword') ? undefined : EmailPasswordLoginInfoFromJSON(json['emailPassword']),
        'nfcToken': !exists(json, 'nfcToken') ? undefined : json['nfcToken'],
        'hardwareKey': !exists(json, 'hardwareKey') ? undefined : json['hardwareKey'],
        'emailLoginToken': !exists(json, 'emailLoginToken') ? undefined : json['emailLoginToken'],
    };
}

export function AuthenticationInfoToJSON(value?: AuthenticationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailPassword': EmailPasswordLoginInfoToJSON(value.emailPassword),
        'nfcToken': value.nfcToken,
        'hardwareKey': value.hardwareKey,
        'emailLoginToken': value.emailLoginToken,
    };
}

