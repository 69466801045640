/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DateFormat } from './DateFormat';
import {
    DateFormatFromJSON,
    DateFormatFromJSONTyped,
    DateFormatToJSON,
} from './DateFormat';
import type { Language } from './Language';
import {
    LanguageFromJSON,
    LanguageFromJSONTyped,
    LanguageToJSON,
} from './Language';
import type { TimeFormat } from './TimeFormat';
import {
    TimeFormatFromJSON,
    TimeFormatFromJSONTyped,
    TimeFormatToJSON,
} from './TimeFormat';
import type { WindowsTimezone } from './WindowsTimezone';
import {
    WindowsTimezoneFromJSON,
    WindowsTimezoneFromJSONTyped,
    WindowsTimezoneToJSON,
} from './WindowsTimezone';

/**
 * 
 * @export
 * @interface PublicSettingUpdate
 */
export interface PublicSettingUpdate {
    /**
     * 
     * @type {string}
     * @memberof PublicSettingUpdate
     */
    instanceName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicSettingUpdate
     */
    logoId?: string | null;
    /**
     * 
     * @type {Language}
     * @memberof PublicSettingUpdate
     */
    defaultLanguage?: Language | null;
    /**
     * 
     * @type {WindowsTimezone}
     * @memberof PublicSettingUpdate
     */
    defaultTimezone?: WindowsTimezone | null;
    /**
     * 
     * @type {DateFormat}
     * @memberof PublicSettingUpdate
     */
    defaultDateFormat?: DateFormat | null;
    /**
     * 
     * @type {TimeFormat}
     * @memberof PublicSettingUpdate
     */
    defaultTimeFormat?: TimeFormat | null;
}

/**
 * Check if a given object implements the PublicSettingUpdate interface.
 */
export function instanceOfPublicSettingUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PublicSettingUpdateFromJSON(json: any): PublicSettingUpdate {
    return PublicSettingUpdateFromJSONTyped(json, false);
}

export function PublicSettingUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicSettingUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'instanceName': !exists(json, 'instanceName') ? undefined : json['instanceName'],
        'logoId': !exists(json, 'logoId') ? undefined : json['logoId'],
        'defaultLanguage': !exists(json, 'defaultLanguage') ? undefined : LanguageFromJSON(json['defaultLanguage']),
        'defaultTimezone': !exists(json, 'defaultTimezone') ? undefined : WindowsTimezoneFromJSON(json['defaultTimezone']),
        'defaultDateFormat': !exists(json, 'defaultDateFormat') ? undefined : DateFormatFromJSON(json['defaultDateFormat']),
        'defaultTimeFormat': !exists(json, 'defaultTimeFormat') ? undefined : TimeFormatFromJSON(json['defaultTimeFormat']),
    };
}

export function PublicSettingUpdateToJSON(value?: PublicSettingUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'instanceName': value.instanceName,
        'logoId': value.logoId,
        'defaultLanguage': LanguageToJSON(value.defaultLanguage),
        'defaultTimezone': WindowsTimezoneToJSON(value.defaultTimezone),
        'defaultDateFormat': DateFormatToJSON(value.defaultDateFormat),
        'defaultTimeFormat': TimeFormatToJSON(value.defaultTimeFormat),
    };
}

