// Convert a `mg` int into a float number with 3 decimal places.
export function mGtoG(value?: number | string): number | undefined {
    if (value === undefined) {
        return;
    }

    if (typeof value === "string") {
        value = parseInt(value, 10);
    }

    return value / Math.pow(10, 3);
}

// Convert a float `g`-force value into a rounded `mg` integer value.
export function GtomG(value?: number): number | undefined {
    if (value === undefined) {
        return undefined;
    }

    return Math.round(value * 1000);
}
