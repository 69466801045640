import { runInAction } from "mobx";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import { FilesApi, PageInfo, Setting, SettingsApi, SettingUpdate } from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { Validation } from "../../utils/validation";
import { ServiceFile } from "../services/service-file";

export interface SettingsApiResource extends ApiResource {
    entity: Setting;
    query: void;
    update: SettingUpdate;
    create: void;
    sortKey: string;
}

@component
export class RepositorySettings extends ElofleetRepository<SettingsApiResource> {
    @inject private readonly settingsApi!: SettingsApi;
    @inject private readonly filesApi!: FilesApi;
    @inject private readonly serviceFile!: ServiceFile;

    public validation = {
        update: new Validation((settingUpdate: SettingUpdate, id: UUID) =>
            this.settingsApi.settingsValidateUpdateSetting({
                settingUpdate,
                id,
            }),
        ),
    };

    protected async fetchById(settingId: UUID): Promise<Setting | undefined> {
        return this.settingsApi.settingsReadSettingById({ id: settingId });
    }

    protected extractId = prop("id");

    protected async fetchByQuery(
        _query: void,
        _pagination: Segment,
    ): Promise<FetchByQueryResult<Setting>> {
        return { entities: [await this.settingsApi.settingsReadSetting()] };
    }

    public fetchMetadata(_: unknown): Promise<PageInfo> {
        throw new Error("Cannot list setting metadata: Method not implemented.");
    }

    public async update(id: UUID, settingUpdate: SettingUpdate): Promise<Setting> {
        const setting = await this.wrapApiCall(
            this.settingsApi.settingsUpdateSetting({ settingUpdate, id }),
        );

        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery();
            this.add(setting);
        });

        return setting;
    }

    public create(_settingCreate: never): Promise<Setting> {
        throw new Error("Cannot create new setting: Method not implemented.");
    }

    public delete(..._ids: string[]): Promise<void> {
        throw new Error("Cannot delete setting: Method not implemented.");
    }
}
