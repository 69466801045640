import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { declareRoute, routePath, RouteProps } from "../routes";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { PageHeader } from "../ui/atoms/page-header";
import { PageContent } from "../ui/atoms/page-content";
import { Section } from "../ui/atoms/section";
import { FilterByDateRange } from "../ui/organisms/filter-by-date-range";
import { SelectionMode } from "@fluentui/react";
import { ListTruckEvents } from "../ui/molecules/list-truck-events";
import sizes from "../ui/sizes.scss";

@external
export class PageTruckActivity extends React.Component<RouteProps> {
    @inject protected readonly i18n!: I18nProvider;

    public render(): JSX.Element {
        return (
            <LayoutDefault header={<PageHeader title={this.i18n.t("page.truckActivity.title")} />}>
                <PageContent>
                    <Section>
                        <ListTruckEvents
                            commandBar={<FilterByDateRange onDateRangeChanged={() => {}} />}
                            selectionMode={SelectionMode.none}
                            maxHeight={`${sizes.listMaxHeight}`}
                        />
                    </Section>
                </PageContent>
            </LayoutDefault>
        );
    }
}

export const routeTruckActivity = declareRoute({
    navigation: true,
    component: PageTruckActivity,
    icon: "Car",
    title: "page.truckActivity.navbarEntry",
    path: routePath.truckActivity,
    pattern: "/truck-activity",
});
