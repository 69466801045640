/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Phone
 */
export interface Phone {
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Phone
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Phone
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    vehicleDeviceId?: string | null;
    /**
     * The unique id that's given to any android device. This id is read by the mobile app and transmitted to the back end.
     * @type {string}
     * @memberof Phone
     */
    androidId: string;
    /**
     * Set by the app to the last time of a successful self-update.
     * @type {Date}
     * @memberof Phone
     */
    appLastUpdatedAt: Date;
    /**
     * This current semantic version of the installed mobile app.
     * @type {string}
     * @memberof Phone
     */
    currentAppVersion: string;
    /**
     * 
     * @type {number}
     * @memberof Phone
     */
    currentAppVersionNumber: number;
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    currentAppVersionHash: string;
}

/**
 * Check if a given object implements the Phone interface.
 */
export function instanceOfPhone(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "androidId" in value;
    isInstance = isInstance && "appLastUpdatedAt" in value;
    isInstance = isInstance && "currentAppVersion" in value;
    isInstance = isInstance && "currentAppVersionNumber" in value;
    isInstance = isInstance && "currentAppVersionHash" in value;

    return isInstance;
}

export function PhoneFromJSON(json: any): Phone {
    return PhoneFromJSONTyped(json, false);
}

export function PhoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): Phone {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'vehicleDeviceId': !exists(json, 'vehicleDeviceId') ? undefined : json['vehicleDeviceId'],
        'androidId': json['androidId'],
        'appLastUpdatedAt': (new Date(json['appLastUpdatedAt'])),
        'currentAppVersion': json['currentAppVersion'],
        'currentAppVersionNumber': json['currentAppVersionNumber'],
        'currentAppVersionHash': json['currentAppVersionHash'],
    };
}

export function PhoneToJSON(value?: Phone | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'vehicleDeviceId': value.vehicleDeviceId,
        'androidId': value.androidId,
        'appLastUpdatedAt': (value.appLastUpdatedAt.toISOString()),
        'currentAppVersion': value.currentAppVersion,
        'currentAppVersionNumber': value.currentAppVersionNumber,
        'currentAppVersionHash': value.currentAppVersionHash,
    };
}

