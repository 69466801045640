/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PageInfo,
  SortDirection,
  VehicleGroup,
  VehicleGroupCreate,
  VehicleGroupSortKey,
  VehicleGroupUpdate,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    VehicleGroupFromJSON,
    VehicleGroupToJSON,
    VehicleGroupCreateFromJSON,
    VehicleGroupCreateToJSON,
    VehicleGroupSortKeyFromJSON,
    VehicleGroupSortKeyToJSON,
    VehicleGroupUpdateFromJSON,
    VehicleGroupUpdateToJSON,
} from '../models';

export interface VehicleGroupsCreateVehicleGroupRequest {
    vehicleGroupCreate: VehicleGroupCreate;
}

export interface VehicleGroupsDeleteVehicleGroupRequest {
    vehicleGroupId: string;
}

export interface VehicleGroupsListVehicleGroupsRequest {
    pageSize: number;
    search?: string | null;
    vehicleId?: string | null;
    userGroupIds?: Array<string> | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: VehicleGroupSortKey;
}

export interface VehicleGroupsListVehicleGroupsMetadataRequest {
    pageSize: number;
    search?: string | null;
    vehicleId?: string | null;
    userGroupIds?: Array<string> | null;
}

export interface VehicleGroupsReadVehicleGroupRequest {
    vehicleGroupId: string;
}

export interface VehicleGroupsUpdateVehicleGroupRequest {
    vehicleGroupId: string;
    vehicleGroupUpdate: VehicleGroupUpdate;
}

export interface VehicleGroupsValidateCreateVehicleGroupRequest {
    vehicleGroupCreate: VehicleGroupCreate;
}

export interface VehicleGroupsValidateUpdateVehicleGroupRequest {
    vehicleGroupId: string;
    vehicleGroupUpdate: VehicleGroupUpdate;
}

/**
 * VehicleGroupsApi - interface
 * 
 * @export
 * @interface VehicleGroupsApiInterface
 */
export interface VehicleGroupsApiInterface {
    /**
     * Create a new vehicle group.
     * @summary Create a vehicle group
     * @param {VehicleGroupCreate} vehicleGroupCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGroupsApiInterface
     */
    vehicleGroupsCreateVehicleGroupRaw(requestParameters: VehicleGroupsCreateVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleGroup>>;

    /**
     * Create a new vehicle group.
     * Create a vehicle group
     */
    vehicleGroupsCreateVehicleGroup(requestParameters: VehicleGroupsCreateVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleGroup>;

    /**
     * Delete the vehicle group with the given ID.
     * @summary Delete a vehicle group
     * @param {string} vehicleGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGroupsApiInterface
     */
    vehicleGroupsDeleteVehicleGroupRaw(requestParameters: VehicleGroupsDeleteVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the vehicle group with the given ID.
     * Delete a vehicle group
     */
    vehicleGroupsDeleteVehicleGroup(requestParameters: VehicleGroupsDeleteVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Lists all the vehicle group present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead. Optionally filter by assignment to the given vehicle.
     * @summary List vehicle group
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {string} [vehicleId] 
     * @param {Array<string>} [userGroupIds] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {VehicleGroupSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGroupsApiInterface
     */
    vehicleGroupsListVehicleGroupsRaw(requestParameters: VehicleGroupsListVehicleGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleGroup>>>;

    /**
     * Lists all the vehicle group present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead. Optionally filter by assignment to the given vehicle.
     * List vehicle group
     */
    vehicleGroupsListVehicleGroups(requestParameters: VehicleGroupsListVehicleGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleGroup>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary Vehicle group listing metadata
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {string} [vehicleId] 
     * @param {Array<string>} [userGroupIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGroupsApiInterface
     */
    vehicleGroupsListVehicleGroupsMetadataRaw(requestParameters: VehicleGroupsListVehicleGroupsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * Vehicle group listing metadata
     */
    vehicleGroupsListVehicleGroupsMetadata(requestParameters: VehicleGroupsListVehicleGroupsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the vehicle group specified by the given ID.
     * @summary Get a single vehicle group
     * @param {string} vehicleGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGroupsApiInterface
     */
    vehicleGroupsReadVehicleGroupRaw(requestParameters: VehicleGroupsReadVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleGroup>>;

    /**
     * Returns the vehicle group specified by the given ID.
     * Get a single vehicle group
     */
    vehicleGroupsReadVehicleGroup(requestParameters: VehicleGroupsReadVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleGroup>;

    /**
     * Update the vehicle group specified by the given ID.
     * @summary Update vehicle group
     * @param {string} vehicleGroupId 
     * @param {VehicleGroupUpdate} vehicleGroupUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGroupsApiInterface
     */
    vehicleGroupsUpdateVehicleGroupRaw(requestParameters: VehicleGroupsUpdateVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleGroup>>;

    /**
     * Update the vehicle group specified by the given ID.
     * Update vehicle group
     */
    vehicleGroupsUpdateVehicleGroup(requestParameters: VehicleGroupsUpdateVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleGroup>;

    /**
     * Validate data for a new vehicle group without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a vehicle group
     * @param {VehicleGroupCreate} vehicleGroupCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGroupsApiInterface
     */
    vehicleGroupsValidateCreateVehicleGroupRaw(requestParameters: VehicleGroupsValidateCreateVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new vehicle group without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a vehicle group
     */
    vehicleGroupsValidateCreateVehicleGroup(requestParameters: VehicleGroupsValidateCreateVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate data for updating the given vehicle group, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate vehicle group update
     * @param {string} vehicleGroupId 
     * @param {VehicleGroupUpdate} vehicleGroupUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGroupsApiInterface
     */
    vehicleGroupsValidateUpdateVehicleGroupRaw(requestParameters: VehicleGroupsValidateUpdateVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for updating the given vehicle group, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate vehicle group update
     */
    vehicleGroupsValidateUpdateVehicleGroup(requestParameters: VehicleGroupsValidateUpdateVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class VehicleGroupsApi extends runtime.BaseAPI implements VehicleGroupsApiInterface {

    /**
     * Create a new vehicle group.
     * Create a vehicle group
     */
    async vehicleGroupsCreateVehicleGroupRaw(requestParameters: VehicleGroupsCreateVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleGroup>> {
        if (requestParameters.vehicleGroupCreate === null || requestParameters.vehicleGroupCreate === undefined) {
            throw new runtime.RequiredError('vehicleGroupCreate','Required parameter requestParameters.vehicleGroupCreate was null or undefined when calling vehicleGroupsCreateVehicleGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_groups/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleGroupCreateToJSON(requestParameters.vehicleGroupCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleGroupFromJSON(jsonValue));
    }

    /**
     * Create a new vehicle group.
     * Create a vehicle group
     */
    async vehicleGroupsCreateVehicleGroup(requestParameters: VehicleGroupsCreateVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleGroup> {
        const response = await this.vehicleGroupsCreateVehicleGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the vehicle group with the given ID.
     * Delete a vehicle group
     */
    async vehicleGroupsDeleteVehicleGroupRaw(requestParameters: VehicleGroupsDeleteVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.vehicleGroupId === null || requestParameters.vehicleGroupId === undefined) {
            throw new runtime.RequiredError('vehicleGroupId','Required parameter requestParameters.vehicleGroupId was null or undefined when calling vehicleGroupsDeleteVehicleGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_groups/{vehicle_group_id}`.replace(`{${"vehicle_group_id"}}`, encodeURIComponent(String(requestParameters.vehicleGroupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the vehicle group with the given ID.
     * Delete a vehicle group
     */
    async vehicleGroupsDeleteVehicleGroup(requestParameters: VehicleGroupsDeleteVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vehicleGroupsDeleteVehicleGroupRaw(requestParameters, initOverrides);
    }

    /**
     * Lists all the vehicle group present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead. Optionally filter by assignment to the given vehicle.
     * List vehicle group
     */
    async vehicleGroupsListVehicleGroupsRaw(requestParameters: VehicleGroupsListVehicleGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleGroup>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling vehicleGroupsListVehicleGroups.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.vehicleId !== undefined) {
            queryParameters['vehicle_id'] = requestParameters.vehicleId;
        }

        if (requestParameters.userGroupIds) {
            queryParameters['user_group_ids'] = requestParameters.userGroupIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_groups/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleGroupFromJSON));
    }

    /**
     * Lists all the vehicle group present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead. Optionally filter by assignment to the given vehicle.
     * List vehicle group
     */
    async vehicleGroupsListVehicleGroups(requestParameters: VehicleGroupsListVehicleGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleGroup>> {
        const response = await this.vehicleGroupsListVehicleGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * Vehicle group listing metadata
     */
    async vehicleGroupsListVehicleGroupsMetadataRaw(requestParameters: VehicleGroupsListVehicleGroupsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling vehicleGroupsListVehicleGroupsMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.vehicleId !== undefined) {
            queryParameters['vehicle_id'] = requestParameters.vehicleId;
        }

        if (requestParameters.userGroupIds) {
            queryParameters['user_group_ids'] = requestParameters.userGroupIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_groups/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * Vehicle group listing metadata
     */
    async vehicleGroupsListVehicleGroupsMetadata(requestParameters: VehicleGroupsListVehicleGroupsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.vehicleGroupsListVehicleGroupsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the vehicle group specified by the given ID.
     * Get a single vehicle group
     */
    async vehicleGroupsReadVehicleGroupRaw(requestParameters: VehicleGroupsReadVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleGroup>> {
        if (requestParameters.vehicleGroupId === null || requestParameters.vehicleGroupId === undefined) {
            throw new runtime.RequiredError('vehicleGroupId','Required parameter requestParameters.vehicleGroupId was null or undefined when calling vehicleGroupsReadVehicleGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_groups/{vehicle_group_id}`.replace(`{${"vehicle_group_id"}}`, encodeURIComponent(String(requestParameters.vehicleGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleGroupFromJSON(jsonValue));
    }

    /**
     * Returns the vehicle group specified by the given ID.
     * Get a single vehicle group
     */
    async vehicleGroupsReadVehicleGroup(requestParameters: VehicleGroupsReadVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleGroup> {
        const response = await this.vehicleGroupsReadVehicleGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the vehicle group specified by the given ID.
     * Update vehicle group
     */
    async vehicleGroupsUpdateVehicleGroupRaw(requestParameters: VehicleGroupsUpdateVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleGroup>> {
        if (requestParameters.vehicleGroupId === null || requestParameters.vehicleGroupId === undefined) {
            throw new runtime.RequiredError('vehicleGroupId','Required parameter requestParameters.vehicleGroupId was null or undefined when calling vehicleGroupsUpdateVehicleGroup.');
        }

        if (requestParameters.vehicleGroupUpdate === null || requestParameters.vehicleGroupUpdate === undefined) {
            throw new runtime.RequiredError('vehicleGroupUpdate','Required parameter requestParameters.vehicleGroupUpdate was null or undefined when calling vehicleGroupsUpdateVehicleGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_groups/{vehicle_group_id}`.replace(`{${"vehicle_group_id"}}`, encodeURIComponent(String(requestParameters.vehicleGroupId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleGroupUpdateToJSON(requestParameters.vehicleGroupUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleGroupFromJSON(jsonValue));
    }

    /**
     * Update the vehicle group specified by the given ID.
     * Update vehicle group
     */
    async vehicleGroupsUpdateVehicleGroup(requestParameters: VehicleGroupsUpdateVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleGroup> {
        const response = await this.vehicleGroupsUpdateVehicleGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new vehicle group without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a vehicle group
     */
    async vehicleGroupsValidateCreateVehicleGroupRaw(requestParameters: VehicleGroupsValidateCreateVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.vehicleGroupCreate === null || requestParameters.vehicleGroupCreate === undefined) {
            throw new runtime.RequiredError('vehicleGroupCreate','Required parameter requestParameters.vehicleGroupCreate was null or undefined when calling vehicleGroupsValidateCreateVehicleGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_groups/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleGroupCreateToJSON(requestParameters.vehicleGroupCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new vehicle group without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a vehicle group
     */
    async vehicleGroupsValidateCreateVehicleGroup(requestParameters: VehicleGroupsValidateCreateVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vehicleGroupsValidateCreateVehicleGroupRaw(requestParameters, initOverrides);
    }

    /**
     * Validate data for updating the given vehicle group, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate vehicle group update
     */
    async vehicleGroupsValidateUpdateVehicleGroupRaw(requestParameters: VehicleGroupsValidateUpdateVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.vehicleGroupId === null || requestParameters.vehicleGroupId === undefined) {
            throw new runtime.RequiredError('vehicleGroupId','Required parameter requestParameters.vehicleGroupId was null or undefined when calling vehicleGroupsValidateUpdateVehicleGroup.');
        }

        if (requestParameters.vehicleGroupUpdate === null || requestParameters.vehicleGroupUpdate === undefined) {
            throw new runtime.RequiredError('vehicleGroupUpdate','Required parameter requestParameters.vehicleGroupUpdate was null or undefined when calling vehicleGroupsValidateUpdateVehicleGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_groups/{vehicle_group_id}/validate`.replace(`{${"vehicle_group_id"}}`, encodeURIComponent(String(requestParameters.vehicleGroupId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleGroupUpdateToJSON(requestParameters.vehicleGroupUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for updating the given vehicle group, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate vehicle group update
     */
    async vehicleGroupsValidateUpdateVehicleGroup(requestParameters: VehicleGroupsValidateUpdateVehicleGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vehicleGroupsValidateUpdateVehicleGroupRaw(requestParameters, initOverrides);
    }

}
