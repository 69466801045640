import * as React from "react";
import { external } from "tsdi";
import { IColumn, Icon } from "@fluentui/react";
import { makeObservable, computed, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import { inject } from "tsdi";
import { RepositoryVehicles } from "../../domain/repositories/repository-vehicles";
import { UUID } from "../../utils/uuid";
import {
    PreOpsChecklist,
    ShockProfile,
    User,
    Vehicle,
    VehicleDevice,
    VehicleGroup,
} from "../../api";
import { RepositoryVehicleGroups } from "../../domain/repositories/repository-vehicle-groups";
import { RepositoryVehicleDevices } from "../../domain/repositories/repository-vehicle-devices";
import { BooleanIcon } from "./boolean-icon";
import { RepositoryShockProfiles } from "../../domain/repositories/repository-shock-profiles";
import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryPreOpsChecklists } from "../../domain/repositories/repository-pre-ops-checklists";
import { RepositoryUsers } from "../../domain/repositories/repository-users";
import { mapSome } from "../../utils/functions";
import { defaultPageSize } from "../../utils/constants";
import { RepositoryVehicleTypes } from "../../domain/repositories/repository-vehicle-types";

export interface ListVehicleItemCellProps {
    readonly vehicleId: UUID;
    readonly column: IColumn;
    readonly filteredVehicleGroups: string[];
}

@external
@observer
export class ListVehicleItemCell extends React.Component<ListVehicleItemCellProps> {
    @inject private readonly repositoryDepartments!: RepositoryDepartments;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;
    @inject private readonly repositoryVehicleDevices!: RepositoryVehicleDevices;
    @inject private readonly repositoryVehicleGroups!: RepositoryVehicleGroups;
    @inject private readonly repositoryVehicleTypes!: RepositoryVehicleTypes;
    @inject private readonly repositoryShockProfiles!: RepositoryShockProfiles;
    @inject private readonly repositoryPreOpsChecklists!: RepositoryPreOpsChecklists;
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly i18n!: I18nProvider;

    @observable private lastDriver: User | undefined;
    @observable private lastDriverChecked: boolean = false;

    constructor(props: ListVehicleItemCellProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get vehicle(): Vehicle | undefined {
        return this.repositoryVehicles.byId(this.props.vehicleId);
    }

    @computed private get preOpsChecklist(): PreOpsChecklist | undefined {
        if (!this.vehicle || !this.vehicle.preOpsChecklistId) {
            return;
        }
        return this.repositoryPreOpsChecklists.byId(this.vehicle.preOpsChecklistId);
    }

    @computed private get shockProfile(): ShockProfile | undefined {
        if (!this.vehicle || !this.vehicle.shockProfileId) {
            return;
        }
        return this.repositoryShockProfiles.byId(this.vehicle.shockProfileId);
    }

    @computed private get vehicleDepartment(): string | undefined {
        if (!this.vehicle || !this.vehicle.departmentId) {
            return;
        }
        return this.repositoryDepartments.byId(this.vehicle.departmentId)?.label;
    }

    @computed private get vehicleType(): string | undefined {
        if (!this.vehicle || !this.vehicle.vehicleTypeId) {
            return;
        }
        return this.repositoryVehicleTypes.byId(this.vehicle.vehicleTypeId)?.label;
    }

    @computed private get groups(): VehicleGroup[] {
        if (!this.vehicle) {
            return [];
        }
        return this.repositoryVehicleGroups.byQuery({
            pageSize: defaultPageSize,
            vehicleId: this.vehicle.id,
        });
    }

    @computed private get vehicleDevice(): VehicleDevice | undefined {
        return this.repositoryVehicleDevices.byVehicleId(this.props.vehicleId);
    }

    private fetchLastDriver(): void {
        if (this.props.column.key === "lastDriver" && !this.lastDriverChecked) {
            this.repositoryUsers
                .getLastDriverByVehicle(this.vehicle!.id)
                .then((user) => runInAction(() => (this.lastDriver = user)))
                .finally(() => runInAction(() => (this.lastDriverChecked = true)));
        }
    }

    public formatHoursUntilNextMaintenance(hour: number | undefined): string {
        if (hour === undefined) {
            return "";
        }

        return `${this.i18n.formatHours(Math.abs(hour))} ${
            hour <= 0 ? this.i18n.t("listVehicles.overdue") : ""
        }`;
    }

    public render(): JSX.Element {
        const { column } = this.props;
        this.fetchLastDriver();

        switch (column.key) {
            case "eloboxId":
                if (!this.vehicleDevice) {
                    return <></>;
                }
                return <span>{this.vehicleDevice.serialNumber}</span>;
            case "phoneImei":
                return <span>{this.vehicle?.phoneImei}</span>;
            case "manufacturer":
                return <span>{this.vehicle?.manufacturer ?? ""}</span>;
            case "model":
                return <span>{this.vehicle?.model ?? ""}</span>;
            case "vehicleType":
                return <span>{this.vehicleType}</span>;
            case "engineType": {
                if (!this.vehicle?.engineType) {
                    return <></>;
                }
                const label = this.i18n.t(`listVehicles.engineType.${this.vehicle.engineType}`);
                return <span>{label}</span>;
            }
            case "serialNumber":
                return <span>{this.vehicle?.serialNumber}</span>;
            case "manufactureYear":
                return <span>{this.vehicle?.manufactureYear ?? ""}</span>;
            case "department":
                return <span>{this.vehicleDepartment}</span>;
            case "group":
                if (this.groups.length === 0) {
                    return <span />;
                }
                return (
                    <div>
                        {this.groups.map((group) => {
                            if (this.props.filteredVehicleGroups.includes(group.id)) {
                                return (
                                    <div key={group.id}>
                                        <strong>{group.label}</strong>
                                    </div>
                                );
                            }
                            return <div key={group.id}>{group.label}</div>;
                        })}
                    </div>
                );
            case "shockProfile":
                return <span>{this.shockProfile?.label ?? ""}</span>;
            case "checklistProfile":
                return <span>{this.preOpsChecklist?.label}</span>;
            case "checklistOverride":
                return this.vehicle?.checklistOverride !== undefined ? (
                    <BooleanIcon status={this.vehicle?.checklistOverride} />
                ) : (
                    <></>
                );
            case "initialOperatingHours":
                return this.vehicle?.initialOperatingHours ? (
                    <span>{this.i18n.formatHours(this.vehicle.initialOperatingHours)}</span>
                ) : (
                    <></>
                );
            case "idleAutoLogoutAfter":
                return <span></span>;
            case "maintenanceNotificationAt":
                return <span>{this.vehicle?.maintenanceNotificationAt ?? ""}</span>;
            case "maintenanceLockdown":
                return this.vehicle?.maintenanceLockdown !== undefined ? (
                    <BooleanIcon status={this.vehicle?.maintenanceLockdown} />
                ) : (
                    <></>
                );
            case "lastDriver":
                return this.lastDriver ? (
                    <span>{this.i18n.formatUserFullName(this.lastDriver)}</span>
                ) : (
                    <span />
                );
            case "lastSynchronization":
                return (
                    <span>
                        {this.vehicle?.lastSynchronizedAt
                            ? this.i18n.formatDateTime(this.vehicle.lastSynchronizedAt)
                            : ""}
                    </span>
                );
            case "lastMaintenanceDate":
                return (
                    <span>
                        {this.vehicle?.lastMaintenanceDate
                            ? this.i18n.formatDateOnly(this.vehicle.lastMaintenanceDate)
                            : ""}
                    </span>
                );
            case "lastMaintenanceHours": {
                return (
                    <span>
                        {this.vehicle?.lastMaintenanceOperatingHours
                            ? this.i18n.formatHours(this.vehicle.lastMaintenanceOperatingHours)
                            : ""}
                    </span>
                );
            }
            case "nextMaintenanceHours": {
                return (
                    <span>
                        {this.vehicle?.nextMaintenanceHours
                            ? this.i18n.formatHours(this.vehicle.nextMaintenanceHours)
                            : ""}
                    </span>
                );
            }
            case "hoursUntilMaintenance": {
                return (
                    <span>
                        {this.formatHoursUntilNextMaintenance(
                            this.vehicle?.hoursUntilNextMaintenance,
                        )}
                    </span>
                );
            }
            case "purchaseTermBegin": {
                const startDate = this.vehicle?.leaseTermBegin ?? this.vehicle?.purchaseDate;
                return <span>{startDate ? this.i18n.formatDateOnly(startDate) : ""}</span>;
            }
            case "leaseTermEnd":
                return (
                    <span>
                        {this.vehicle?.leaseTermEnd
                            ? this.i18n.formatDateOnly(this.vehicle.leaseTermEnd)
                            : ""}
                    </span>
                );
            case "status":
                return (
                    <span>
                        {mapSome(
                            (status) => this.i18n.t(`listVehicleItemCell.status.${status}`),
                            this.vehicle?.status,
                        )}
                    </span>
                );
            case "currentOperatingHours": {
                const seconds = this.vehicle?.totalOperatingSeconds;
                return <>{seconds ? this.i18n.formatDuration(seconds) : ""}</>;
            }
            case "vehicleStatus": {
                if (!this.vehicle?.status) {
                    return <></>;
                }
                const label = this.i18n.t(`listVehicles.status.${this.vehicle.status}`);
                return <span>{label}</span>;
            }
            case "maintenanceInterval":
                return <span>{this.vehicle?.maintenanceInterval ?? ""}</span>;
            case "daysUntilLeaseTermEnd": {
                const leaseTermEnd = this.vehicle?.leaseTermEnd;
                if (!leaseTermEnd) {
                    return <></>;
                }
                const difference = Math.max(0, leaseTermEnd.getTime() - new Date().getTime());
                const millisecondsPerDay = 1000 * 60 * 60 * 24;
                const days = Math.floor(difference / millisecondsPerDay);
                return <span>{this.i18n.addThousandsSeperator(days)}</span>;
            }
            case "vehicleIdleAutoLogoutAfter":
                return (
                    <span>
                        {this.vehicle?.idleAutoLogoutAfter != null
                            ? this.i18n.formatMinutes(this.vehicle?.idleAutoLogoutAfter)
                            : ""}
                    </span>
                );
            default:
                if (this.groups.find((group) => group.id === column.key)) {
                    return (
                        <span style={{ display: "block", textAlign: "center" }}>
                            <Icon iconName="Accept" />
                        </span>
                    );
                }
                console.warn(`Unknown column ${column.key} in ListVehicleItemCell`);
                return <></>;
        }
    }
}
