/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShockEventLevel } from './ShockEventLevel';
import {
    ShockEventLevelFromJSON,
    ShockEventLevelFromJSONTyped,
    ShockEventLevelToJSON,
} from './ShockEventLevel';

/**
 * 
 * @export
 * @interface ShockEventCreate
 */
export interface ShockEventCreate {
    /**
     * The client also provides the event id. This way we don't have to rely on timing based constraints. See `docs/backend/Offline-Synchronization.md` for details.
     * @type {string}
     * @memberof ShockEventCreate
     */
    id: string;
    /**
     * The shock profile that was used to detect the shock event.
     * @type {string}
     * @memberof ShockEventCreate
     */
    shockProfileVersionId: string;
    /**
     * The exact time the shock event occurred at. This has to be the time registered by the Elobox/App.
     * @type {Date}
     * @memberof ShockEventCreate
     */
    time: Date;
    /**
     * The peak gravitational force during the impact. The unit of this value are milliseconds.
     * @type {number}
     * @memberof ShockEventCreate
     */
    gForce: number;
    /**
     * The vehicle that was driven when the event was detected.
     * @type {string}
     * @memberof ShockEventCreate
     */
    vehicleId: string;
    /**
     * The user that drove the vehicle when the event was detected.
     * @type {string}
     * @memberof ShockEventCreate
     */
    userId?: string | null;
    /**
     * The period ID of the vehicle movement that was in progress when the event was detected.
     * @type {string}
     * @memberof ShockEventCreate
     */
    vehicleMovementPeriodId?: string | null;
    /**
     * 
     * @type {ShockEventLevel}
     * @memberof ShockEventCreate
     */
    shockLevel: ShockEventLevel;
}

/**
 * Check if a given object implements the ShockEventCreate interface.
 */
export function instanceOfShockEventCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "shockProfileVersionId" in value;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "gForce" in value;
    isInstance = isInstance && "vehicleId" in value;
    isInstance = isInstance && "shockLevel" in value;

    return isInstance;
}

export function ShockEventCreateFromJSON(json: any): ShockEventCreate {
    return ShockEventCreateFromJSONTyped(json, false);
}

export function ShockEventCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShockEventCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'shockProfileVersionId': json['shockProfileVersionId'],
        'time': (new Date(json['time'])),
        'gForce': json['gForce'],
        'vehicleId': json['vehicleId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'vehicleMovementPeriodId': !exists(json, 'vehicleMovementPeriodId') ? undefined : json['vehicleMovementPeriodId'],
        'shockLevel': ShockEventLevelFromJSON(json['shockLevel']),
    };
}

export function ShockEventCreateToJSON(value?: ShockEventCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'shockProfileVersionId': value.shockProfileVersionId,
        'time': (value.time.toISOString()),
        'gForce': value.gForce,
        'vehicleId': value.vehicleId,
        'userId': value.userId,
        'vehicleMovementPeriodId': value.vehicleMovementPeriodId,
        'shockLevel': ShockEventLevelToJSON(value.shockLevel),
    };
}

