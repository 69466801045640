import { runInAction } from "mobx";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    PageInfo,
    PreOpsQuestionCreate,
    PreOpsQuestionsApi,
    PreOpsQuestionsListPreOpsQuestionsRequest,
    PreOpsQuestionUpdate,
    PreOpsQuestion,
    PreOpsQuestionSortKey,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { Validation } from "../../utils/validation";
import { defaultPageSize } from "../../utils/constants";

export type PreOpsQuestionsQuery = Omit<PreOpsQuestionsListPreOpsQuestionsRequest, "page">;

export interface PreOpsQuestionsApiResource extends ApiResource {
    entity: PreOpsQuestion;
    query: PreOpsQuestionsQuery;
    update: PreOpsQuestionUpdate;
    create: PreOpsQuestionCreate;
    sortKey: PreOpsQuestionSortKey;
}

@component
export class RepositoryPreOpsQuestions extends ElofleetRepository<PreOpsQuestionsApiResource> {
    @inject private readonly preOpsQuestionsApi!: PreOpsQuestionsApi;

    private metadataRequest: Promise<PageInfo> | undefined;

    public validation = {
        create: new Validation((preOpsQuestionCreate: PreOpsQuestionCreate) =>
            this.preOpsQuestionsApi.preOpsQuestionsValidateCreatePreOpsQuestion({
                preOpsQuestionCreate,
            }),
        ),
        update: new Validation((preOpsQuestionUpdate: PreOpsQuestionUpdate, questionId: UUID) =>
            this.preOpsQuestionsApi.preOpsQuestionsValidateUpdatePreOpsQuestion({
                preOpsQuestionUpdate,
                questionId,
            }),
        ),
    };

    protected async fetchById(questionId: UUID): Promise<PreOpsQuestion | undefined> {
        return await this.preOpsQuestionsApi.preOpsQuestionsReadPreOpsQuestion({
            questionId,
        });
    }

    protected extractId = prop("id");

    protected async fetchByQuery(
        query: PreOpsQuestionsQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<PreOpsQuestion>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.preOpsQuestionsApi.preOpsQuestionsListPreOpsQuestions({
            ...query,
            page,
        });
        return { entities };
    }

    public async waitForIdle(): Promise<void> {
        await Promise.all([super.waitForIdle(), this.metadataRequest]);
    }

    public fetchMetadata(_: PreOpsQuestionsQuery): Promise<PageInfo> {
        this.metadataRequest = this.preOpsQuestionsApi.preOpsQuestionsListPreOpsQuestionsMetadata({
            pageSize: defaultPageSize,
        });
        return this.metadataRequest;
    }

    public async update(
        questionId: UUID,
        preOpsQuestionUpdate: PreOpsQuestionUpdate,
    ): Promise<PreOpsQuestion> {
        const preOpsQuestion = await this.wrapApiCall(
            this.preOpsQuestionsApi.preOpsQuestionsUpdatePreOpsQuestion({
                questionId,
                preOpsQuestionUpdate,
            }),
        );

        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(preOpsQuestion);
        });

        return preOpsQuestion;
    }

    public async create(preOpsQuestionCreate: PreOpsQuestionCreate): Promise<PreOpsQuestion> {
        const preOpsQuestion = await this.wrapApiCall(
            this.preOpsQuestionsApi.preOpsQuestionsCreatePreOpsQuestion({
                preOpsQuestionCreate,
            }),
        );

        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(preOpsQuestion);
        });

        return preOpsQuestion;
    }

    public async delete(...questionIds: UUID[]): Promise<void> {
        await this.wrapApiCall(
            Promise.all(
                questionIds.map((questionId) =>
                    this.preOpsQuestionsApi.preOpsQuestionsDeletePreOpsQuestion({ questionId }),
                ),
            ),
        );

        // This reload is necessary to reload all queries that want to display "all" entities.
        await this.waitForIdle();
        this.reloadQuery({ pageSize: defaultPageSize });
    }
}
