/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PageInfo,
  SortDirection,
  Vehicle,
  VehicleCreate,
  VehicleLastSynchronizedAtUpdate,
  VehicleListStats,
  VehicleSortKey,
  VehicleUpdate,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    VehicleFromJSON,
    VehicleToJSON,
    VehicleCreateFromJSON,
    VehicleCreateToJSON,
    VehicleLastSynchronizedAtUpdateFromJSON,
    VehicleLastSynchronizedAtUpdateToJSON,
    VehicleListStatsFromJSON,
    VehicleListStatsToJSON,
    VehicleSortKeyFromJSON,
    VehicleSortKeyToJSON,
    VehicleUpdateFromJSON,
    VehicleUpdateToJSON,
} from '../models';

export interface VehiclesCreateVehicleRequest {
    vehicleCreate: VehicleCreate;
}

export interface VehiclesDeleteVehicleRequest {
    vehicleId: string;
}

export interface VehiclesExportVehicleListRequest {
    timezone: string;
    exportType: VehiclesExportVehicleListExportType;
    listType: VehiclesExportVehicleListListType;
    pageSize: number;
    dateStart?: Date;
    dateEnd?: Date;
    search?: string | null;
    departmentIds?: Array<string> | null;
    vehicleGroupIds?: Array<string> | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: VehicleSortKey;
}

export interface VehiclesListVehiclesRequest {
    pageSize: number;
    search?: string | null;
    dateStart?: Date;
    dateEnd?: Date;
    departmentIds?: Array<string> | null;
    vehicleGroupIds?: Array<string> | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: VehicleSortKey;
}

export interface VehiclesListVehiclesMetadataRequest {
    pageSize: number;
    search?: string | null;
    departmentIds?: Array<string> | null;
    vehicleGroupIds?: Array<string> | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: VehicleSortKey;
}

export interface VehiclesReadVehicleRequest {
    vehicleId: string;
}

export interface VehiclesReadVehicleListStatsRequest {
    pageSize: number;
    dateStart?: Date;
    dateEnd?: Date;
    search?: string | null;
    departmentIds?: Array<string> | null;
    vehicleGroupIds?: Array<string> | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: VehicleSortKey;
}

export interface VehiclesUpdateVehicleRequest {
    vehicleId: string;
    vehicleUpdate: VehicleUpdate;
}

export interface VehiclesUpdateVehicleLastSynchronizedAtRequest {
    vehicleLastSynchronizedAtUpdate: VehicleLastSynchronizedAtUpdate;
}

export interface VehiclesValidateCreateVehicleRequest {
    vehicleCreate: VehicleCreate;
}

export interface VehiclesValidateUpdateVehicleRequest {
    vehicleId: string;
    vehicleUpdate: VehicleUpdate;
}

/**
 * VehiclesApi - interface
 * 
 * @export
 * @interface VehiclesApiInterface
 */
export interface VehiclesApiInterface {
    /**
     * Create a new vehicle.
     * @summary Create a vehicle
     * @param {VehicleCreate} vehicleCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApiInterface
     */
    vehiclesCreateVehicleRaw(requestParameters: VehiclesCreateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>>;

    /**
     * Create a new vehicle.
     * Create a vehicle
     */
    vehiclesCreateVehicle(requestParameters: VehiclesCreateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle>;

    /**
     * Delete the vehicle with the given ID.
     * @summary Delete a vehicle
     * @param {string} vehicleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApiInterface
     */
    vehiclesDeleteVehicleRaw(requestParameters: VehiclesDeleteVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the vehicle with the given ID.
     * Delete a vehicle
     */
    vehiclesDeleteVehicle(requestParameters: VehiclesDeleteVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Exports all vehicles with consideration of optional filters.
     * @summary Export vehicle list as csv or xlsx
     * @param {string} timezone 
     * @param {'csv' | 'xlsx'} exportType 
     * @param {'trucks' | 'productivity'} listType 
     * @param {number} pageSize 
     * @param {Date} [dateStart] 
     * @param {Date} [dateEnd] 
     * @param {string} [search] 
     * @param {Array<string>} [departmentIds] 
     * @param {Array<string>} [vehicleGroupIds] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {VehicleSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApiInterface
     */
    vehiclesExportVehicleListRaw(requestParameters: VehiclesExportVehicleListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     * Exports all vehicles with consideration of optional filters.
     * Export vehicle list as csv or xlsx
     */
    vehiclesExportVehicleList(requestParameters: VehiclesExportVehicleListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

    /**
     * Lists all the vehicle present.  Optionally filter by `user_id` to return all `Vehicle`s which are assigned to the `User`. If `search` is specified, return values similar to the `serialNumber` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * @summary List vehicles
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Date} [dateStart] 
     * @param {Date} [dateEnd] 
     * @param {Array<string>} [departmentIds] 
     * @param {Array<string>} [vehicleGroupIds] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {VehicleSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApiInterface
     */
    vehiclesListVehiclesRaw(requestParameters: VehiclesListVehiclesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Vehicle>>>;

    /**
     * Lists all the vehicle present.  Optionally filter by `user_id` to return all `Vehicle`s which are assigned to the `User`. If `search` is specified, return values similar to the `serialNumber` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List vehicles
     */
    vehiclesListVehicles(requestParameters: VehiclesListVehiclesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Vehicle>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary Vehicle listing metadata
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {Array<string>} [departmentIds] 
     * @param {Array<string>} [vehicleGroupIds] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {VehicleSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApiInterface
     */
    vehiclesListVehiclesMetadataRaw(requestParameters: VehiclesListVehiclesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * Vehicle listing metadata
     */
    vehiclesListVehiclesMetadata(requestParameters: VehiclesListVehiclesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the vehicle specified by the given ID.
     * @summary Get a single vehicle
     * @param {string} vehicleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApiInterface
     */
    vehiclesReadVehicleRaw(requestParameters: VehiclesReadVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>>;

    /**
     * Returns the vehicle specified by the given ID.
     * Get a single vehicle
     */
    vehiclesReadVehicle(requestParameters: VehiclesReadVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle>;

    /**
     * Returns only the vehicle of vehicle device token
     * @summary Get a single vehicle by vehicle device.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApiInterface
     */
    vehiclesReadVehicleByVehicleDeviceSerialNumberRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>>;

    /**
     * Returns only the vehicle of vehicle device token
     * Get a single vehicle by vehicle device.
     */
    vehiclesReadVehicleByVehicleDeviceSerialNumber(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle>;

    /**
     * Fetch summed up stats of filtered vehicles.
     * @summary Read summed up stats of all filtered vehicles.
     * @param {number} pageSize 
     * @param {Date} [dateStart] 
     * @param {Date} [dateEnd] 
     * @param {string} [search] 
     * @param {Array<string>} [departmentIds] 
     * @param {Array<string>} [vehicleGroupIds] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {VehicleSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApiInterface
     */
    vehiclesReadVehicleListStatsRaw(requestParameters: VehiclesReadVehicleListStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleListStats>>;

    /**
     * Fetch summed up stats of filtered vehicles.
     * Read summed up stats of all filtered vehicles.
     */
    vehiclesReadVehicleListStats(requestParameters: VehiclesReadVehicleListStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleListStats>;

    /**
     * Update the vehicle specified by the given ID.
     * @summary Update vehicle
     * @param {string} vehicleId 
     * @param {VehicleUpdate} vehicleUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApiInterface
     */
    vehiclesUpdateVehicleRaw(requestParameters: VehiclesUpdateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>>;

    /**
     * Update the vehicle specified by the given ID.
     * Update vehicle
     */
    vehiclesUpdateVehicle(requestParameters: VehiclesUpdateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle>;

    /**
     * Update the last synchronization date of the vehicle specified by the given ID.
     * @summary Update vehicle last synchronization date
     * @param {VehicleLastSynchronizedAtUpdate} vehicleLastSynchronizedAtUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApiInterface
     */
    vehiclesUpdateVehicleLastSynchronizedAtRaw(requestParameters: VehiclesUpdateVehicleLastSynchronizedAtRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>>;

    /**
     * Update the last synchronization date of the vehicle specified by the given ID.
     * Update vehicle last synchronization date
     */
    vehiclesUpdateVehicleLastSynchronizedAt(requestParameters: VehiclesUpdateVehicleLastSynchronizedAtRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle>;

    /**
     * Validate data for a new vehicle without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a vehicle
     * @param {VehicleCreate} vehicleCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApiInterface
     */
    vehiclesValidateCreateVehicleRaw(requestParameters: VehiclesValidateCreateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new vehicle without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a vehicle
     */
    vehiclesValidateCreateVehicle(requestParameters: VehiclesValidateCreateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate data for updating the given vehicle, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate vehicle update
     * @param {string} vehicleId 
     * @param {VehicleUpdate} vehicleUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApiInterface
     */
    vehiclesValidateUpdateVehicleRaw(requestParameters: VehiclesValidateUpdateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for updating the given vehicle, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate vehicle update
     */
    vehiclesValidateUpdateVehicle(requestParameters: VehiclesValidateUpdateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class VehiclesApi extends runtime.BaseAPI implements VehiclesApiInterface {

    /**
     * Create a new vehicle.
     * Create a vehicle
     */
    async vehiclesCreateVehicleRaw(requestParameters: VehiclesCreateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>> {
        if (requestParameters.vehicleCreate === null || requestParameters.vehicleCreate === undefined) {
            throw new runtime.RequiredError('vehicleCreate','Required parameter requestParameters.vehicleCreate was null or undefined when calling vehiclesCreateVehicle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleCreateToJSON(requestParameters.vehicleCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleFromJSON(jsonValue));
    }

    /**
     * Create a new vehicle.
     * Create a vehicle
     */
    async vehiclesCreateVehicle(requestParameters: VehiclesCreateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle> {
        const response = await this.vehiclesCreateVehicleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the vehicle with the given ID.
     * Delete a vehicle
     */
    async vehiclesDeleteVehicleRaw(requestParameters: VehiclesDeleteVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.vehicleId === null || requestParameters.vehicleId === undefined) {
            throw new runtime.RequiredError('vehicleId','Required parameter requestParameters.vehicleId was null or undefined when calling vehiclesDeleteVehicle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/{vehicle_id}`.replace(`{${"vehicle_id"}}`, encodeURIComponent(String(requestParameters.vehicleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the vehicle with the given ID.
     * Delete a vehicle
     */
    async vehiclesDeleteVehicle(requestParameters: VehiclesDeleteVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vehiclesDeleteVehicleRaw(requestParameters, initOverrides);
    }

    /**
     * Exports all vehicles with consideration of optional filters.
     * Export vehicle list as csv or xlsx
     */
    async vehiclesExportVehicleListRaw(requestParameters: VehiclesExportVehicleListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.timezone === null || requestParameters.timezone === undefined) {
            throw new runtime.RequiredError('timezone','Required parameter requestParameters.timezone was null or undefined when calling vehiclesExportVehicleList.');
        }

        if (requestParameters.exportType === null || requestParameters.exportType === undefined) {
            throw new runtime.RequiredError('exportType','Required parameter requestParameters.exportType was null or undefined when calling vehiclesExportVehicleList.');
        }

        if (requestParameters.listType === null || requestParameters.listType === undefined) {
            throw new runtime.RequiredError('listType','Required parameter requestParameters.listType was null or undefined when calling vehiclesExportVehicleList.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling vehiclesExportVehicleList.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateStart !== undefined) {
            queryParameters['date_start'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['date_end'] = (requestParameters.dateEnd as any).toISOString();
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        if (requestParameters.exportType !== undefined) {
            queryParameters['export_type'] = requestParameters.exportType;
        }

        if (requestParameters.listType !== undefined) {
            queryParameters['list_type'] = requestParameters.listType;
        }

        if (requestParameters.departmentIds) {
            queryParameters['department_ids'] = requestParameters.departmentIds;
        }

        if (requestParameters.vehicleGroupIds) {
            queryParameters['vehicle_group_ids'] = requestParameters.vehicleGroupIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Exports all vehicles with consideration of optional filters.
     * Export vehicle list as csv or xlsx
     */
    async vehiclesExportVehicleList(requestParameters: VehiclesExportVehicleListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.vehiclesExportVehicleListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists all the vehicle present.  Optionally filter by `user_id` to return all `Vehicle`s which are assigned to the `User`. If `search` is specified, return values similar to the `serialNumber` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List vehicles
     */
    async vehiclesListVehiclesRaw(requestParameters: VehiclesListVehiclesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Vehicle>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling vehiclesListVehicles.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.dateStart !== undefined) {
            queryParameters['date_start'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['date_end'] = (requestParameters.dateEnd as any).toISOString();
        }

        if (requestParameters.departmentIds) {
            queryParameters['department_ids'] = requestParameters.departmentIds;
        }

        if (requestParameters.vehicleGroupIds) {
            queryParameters['vehicle_group_ids'] = requestParameters.vehicleGroupIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleFromJSON));
    }

    /**
     * Lists all the vehicle present.  Optionally filter by `user_id` to return all `Vehicle`s which are assigned to the `User`. If `search` is specified, return values similar to the `serialNumber` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List vehicles
     */
    async vehiclesListVehicles(requestParameters: VehiclesListVehiclesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Vehicle>> {
        const response = await this.vehiclesListVehiclesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * Vehicle listing metadata
     */
    async vehiclesListVehiclesMetadataRaw(requestParameters: VehiclesListVehiclesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling vehiclesListVehiclesMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.departmentIds) {
            queryParameters['department_ids'] = requestParameters.departmentIds;
        }

        if (requestParameters.vehicleGroupIds) {
            queryParameters['vehicle_group_ids'] = requestParameters.vehicleGroupIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * Vehicle listing metadata
     */
    async vehiclesListVehiclesMetadata(requestParameters: VehiclesListVehiclesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.vehiclesListVehiclesMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the vehicle specified by the given ID.
     * Get a single vehicle
     */
    async vehiclesReadVehicleRaw(requestParameters: VehiclesReadVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>> {
        if (requestParameters.vehicleId === null || requestParameters.vehicleId === undefined) {
            throw new runtime.RequiredError('vehicleId','Required parameter requestParameters.vehicleId was null or undefined when calling vehiclesReadVehicle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/{vehicle_id}`.replace(`{${"vehicle_id"}}`, encodeURIComponent(String(requestParameters.vehicleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleFromJSON(jsonValue));
    }

    /**
     * Returns the vehicle specified by the given ID.
     * Get a single vehicle
     */
    async vehiclesReadVehicle(requestParameters: VehiclesReadVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle> {
        const response = await this.vehiclesReadVehicleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns only the vehicle of vehicle device token
     * Get a single vehicle by vehicle device.
     */
    async vehiclesReadVehicleByVehicleDeviceSerialNumberRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/by/vehicle_device`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleFromJSON(jsonValue));
    }

    /**
     * Returns only the vehicle of vehicle device token
     * Get a single vehicle by vehicle device.
     */
    async vehiclesReadVehicleByVehicleDeviceSerialNumber(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle> {
        const response = await this.vehiclesReadVehicleByVehicleDeviceSerialNumberRaw(initOverrides);
        return await response.value();
    }

    /**
     * Fetch summed up stats of filtered vehicles.
     * Read summed up stats of all filtered vehicles.
     */
    async vehiclesReadVehicleListStatsRaw(requestParameters: VehiclesReadVehicleListStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleListStats>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling vehiclesReadVehicleListStats.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateStart !== undefined) {
            queryParameters['date_start'] = (requestParameters.dateStart as any).toISOString();
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['date_end'] = (requestParameters.dateEnd as any).toISOString();
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.departmentIds) {
            queryParameters['department_ids'] = requestParameters.departmentIds;
        }

        if (requestParameters.vehicleGroupIds) {
            queryParameters['vehicle_group_ids'] = requestParameters.vehicleGroupIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/vehicle_list_stats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleListStatsFromJSON(jsonValue));
    }

    /**
     * Fetch summed up stats of filtered vehicles.
     * Read summed up stats of all filtered vehicles.
     */
    async vehiclesReadVehicleListStats(requestParameters: VehiclesReadVehicleListStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleListStats> {
        const response = await this.vehiclesReadVehicleListStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the vehicle specified by the given ID.
     * Update vehicle
     */
    async vehiclesUpdateVehicleRaw(requestParameters: VehiclesUpdateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>> {
        if (requestParameters.vehicleId === null || requestParameters.vehicleId === undefined) {
            throw new runtime.RequiredError('vehicleId','Required parameter requestParameters.vehicleId was null or undefined when calling vehiclesUpdateVehicle.');
        }

        if (requestParameters.vehicleUpdate === null || requestParameters.vehicleUpdate === undefined) {
            throw new runtime.RequiredError('vehicleUpdate','Required parameter requestParameters.vehicleUpdate was null or undefined when calling vehiclesUpdateVehicle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/{vehicle_id}`.replace(`{${"vehicle_id"}}`, encodeURIComponent(String(requestParameters.vehicleId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleUpdateToJSON(requestParameters.vehicleUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleFromJSON(jsonValue));
    }

    /**
     * Update the vehicle specified by the given ID.
     * Update vehicle
     */
    async vehiclesUpdateVehicle(requestParameters: VehiclesUpdateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle> {
        const response = await this.vehiclesUpdateVehicleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the last synchronization date of the vehicle specified by the given ID.
     * Update vehicle last synchronization date
     */
    async vehiclesUpdateVehicleLastSynchronizedAtRaw(requestParameters: VehiclesUpdateVehicleLastSynchronizedAtRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>> {
        if (requestParameters.vehicleLastSynchronizedAtUpdate === null || requestParameters.vehicleLastSynchronizedAtUpdate === undefined) {
            throw new runtime.RequiredError('vehicleLastSynchronizedAtUpdate','Required parameter requestParameters.vehicleLastSynchronizedAtUpdate was null or undefined when calling vehiclesUpdateVehicleLastSynchronizedAt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/last_synchronized_at`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleLastSynchronizedAtUpdateToJSON(requestParameters.vehicleLastSynchronizedAtUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleFromJSON(jsonValue));
    }

    /**
     * Update the last synchronization date of the vehicle specified by the given ID.
     * Update vehicle last synchronization date
     */
    async vehiclesUpdateVehicleLastSynchronizedAt(requestParameters: VehiclesUpdateVehicleLastSynchronizedAtRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle> {
        const response = await this.vehiclesUpdateVehicleLastSynchronizedAtRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new vehicle without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a vehicle
     */
    async vehiclesValidateCreateVehicleRaw(requestParameters: VehiclesValidateCreateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.vehicleCreate === null || requestParameters.vehicleCreate === undefined) {
            throw new runtime.RequiredError('vehicleCreate','Required parameter requestParameters.vehicleCreate was null or undefined when calling vehiclesValidateCreateVehicle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleCreateToJSON(requestParameters.vehicleCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new vehicle without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a vehicle
     */
    async vehiclesValidateCreateVehicle(requestParameters: VehiclesValidateCreateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vehiclesValidateCreateVehicleRaw(requestParameters, initOverrides);
    }

    /**
     * Validate data for updating the given vehicle, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate vehicle update
     */
    async vehiclesValidateUpdateVehicleRaw(requestParameters: VehiclesValidateUpdateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.vehicleId === null || requestParameters.vehicleId === undefined) {
            throw new runtime.RequiredError('vehicleId','Required parameter requestParameters.vehicleId was null or undefined when calling vehiclesValidateUpdateVehicle.');
        }

        if (requestParameters.vehicleUpdate === null || requestParameters.vehicleUpdate === undefined) {
            throw new runtime.RequiredError('vehicleUpdate','Required parameter requestParameters.vehicleUpdate was null or undefined when calling vehiclesValidateUpdateVehicle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/{vehicle_id}/validate`.replace(`{${"vehicle_id"}}`, encodeURIComponent(String(requestParameters.vehicleId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleUpdateToJSON(requestParameters.vehicleUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for updating the given vehicle, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate vehicle update
     */
    async vehiclesValidateUpdateVehicle(requestParameters: VehiclesValidateUpdateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vehiclesValidateUpdateVehicleRaw(requestParameters, initOverrides);
    }

}

/**
  * @export
  * @enum {string}
  */
export enum VehiclesExportVehicleListExportType {
    CSV = 'csv',
    XLSX = 'xlsx'
}
/**
  * @export
  * @enum {string}
  */
export enum VehiclesExportVehicleListListType {
    TRUCKS = 'trucks',
    PRODUCTIVITY = 'productivity'
}
