/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleDevice
 */
export interface VehicleDevice {
    /**
     * 
     * @type {string}
     * @memberof VehicleDevice
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleDevice
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof VehicleDevice
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof VehicleDevice
     */
    vehicleId?: string | null;
    /**
     * This is the mac address of the ELObox, by convention.
     * @type {string}
     * @memberof VehicleDevice
     */
    serialNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleDevice
     */
    firmwareLastUpdatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof VehicleDevice
     */
    currentVersion: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleDevice
     */
    currentFirmwareVersionNumber: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleDevice
     */
    currentFirmwareVersionHash: string;
}

/**
 * Check if a given object implements the VehicleDevice interface.
 */
export function instanceOfVehicleDevice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "serialNumber" in value;
    isInstance = isInstance && "firmwareLastUpdatedAt" in value;
    isInstance = isInstance && "currentVersion" in value;
    isInstance = isInstance && "currentFirmwareVersionNumber" in value;
    isInstance = isInstance && "currentFirmwareVersionHash" in value;

    return isInstance;
}

export function VehicleDeviceFromJSON(json: any): VehicleDevice {
    return VehicleDeviceFromJSONTyped(json, false);
}

export function VehicleDeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleDevice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'vehicleId': !exists(json, 'vehicleId') ? undefined : json['vehicleId'],
        'serialNumber': json['serialNumber'],
        'firmwareLastUpdatedAt': (new Date(json['firmwareLastUpdatedAt'])),
        'currentVersion': json['currentVersion'],
        'currentFirmwareVersionNumber': json['currentFirmwareVersionNumber'],
        'currentFirmwareVersionHash': json['currentFirmwareVersionHash'],
    };
}

export function VehicleDeviceToJSON(value?: VehicleDevice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'vehicleId': value.vehicleId,
        'serialNumber': value.serialNumber,
        'firmwareLastUpdatedAt': (value.firmwareLastUpdatedAt.toISOString()),
        'currentVersion': value.currentVersion,
        'currentFirmwareVersionNumber': value.currentFirmwareVersionNumber,
        'currentFirmwareVersionHash': value.currentFirmwareVersionHash,
    };
}

