/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Enum that defines all possible node types. We keep input, intermediate, and output nodes together.
 * @export
 * @enum {string}
 */
export enum NodeKind {
    ANY_IN_ZONE = 'anyInZone',
    DIGITAL_INPUT_STATE = 'digitalInputState',
    IN_ERROR_MODE = 'inErrorMode',
    MUTING = 'muting',
    PEDESTRIANS_IN_ZONE = 'pedestriansInZone',
    STATIONS_IN_ZONE = 'stationsInZone',
    VEHICLES_IN_ZONE = 'vehiclesInZone',
    AND = 'and',
    EQUAL = 'equal',
    GREATER_THAN = 'greaterThan',
    LESS_THAN = 'lessThan',
    NOT = 'not',
    OR = 'or',
    DEACTIVATE_ANTENNAS = 'deactivateAntennas',
    SET_STATIONARY_RELAY = 'setStationaryRelay',
    SET_VEHICLE_RELAY = 'setVehicleRelay',
    UNKNOWN = 'unknown'
}


export function NodeKindFromJSON(json: any): NodeKind {
    return NodeKindFromJSONTyped(json, false);
}

export function NodeKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeKind {
    return json as NodeKind;
}

export function NodeKindToJSON(value?: NodeKind | null): any {
    return value as any;
}

