import * as React from "react";
import { external } from "tsdi";
import { IColumn } from "@fluentui/react";
import { makeObservable, computed } from "mobx";
import { observer } from "mobx-react";
import { inject } from "tsdi";
import { FleetAuthenticatePeriod, User, Vehicle } from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryUsers } from "../../domain/repositories/repository-users";
import { RepositoryVehicles } from "../../domain/repositories/repository-vehicles";

export interface ListLoginReportItemCellProps {
    readonly period: FleetAuthenticatePeriod;
    readonly column: IColumn;
}

@external
@observer
export class ListLoginReportItemCell extends React.Component<ListLoginReportItemCellProps> {
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;
    @inject private readonly i18n!: I18nProvider;

    constructor(props: ListLoginReportItemCellProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get user(): User | undefined {
        if (!this.props.period) {
            return;
        }
        return this.repositoryUsers.byId(this.props.period.userId);
    }

    @computed private get vehicle(): Vehicle | undefined {
        if (!this.props.period) {
            return;
        }
        return this.repositoryVehicles.byId(this.props.period.vehicleId);
    }

    public render(): JSX.Element {
        const { column } = this.props;
        switch (column.key) {
            case "eventId":
                return <span>LOG-{this.props.period?.eventId}</span>;
            case "userEmployeeId":
                return <span>{this.user?.employeeId}</span>;
            case "vehicleSerialNumber":
                return <span>{this.vehicle?.serialNumber}</span>;
            case "loggedInTime":
                return <span>{this.i18n.formatDateTime(this.props.period.startTime)}</span>;
            case "loggedOutTime":
                return (
                    <span>
                        {this.props.period.stopTime
                            ? this.i18n.formatDateTime(this.props.period.stopTime)
                            : ""}
                    </span>
                );
            case "loginDuration":
                return (
                    <span>
                        {this.props.period.stopTime
                            ? this.i18n.formatDuration(
                                  (this.props.period.stopTime.getTime() -
                                      this.props.period.startTime.getTime()) /
                                      1000,
                              )
                            : ""}
                    </span>
                );
            case "logoutType":
                return (
                    <span>
                        {this.props.period.logoutType
                            ? this.i18n.formatLogoutType(this.props.period.logoutType)
                            : ""}
                    </span>
                );
            default:
                return <></>;
        }
    }
}
