import { MessageBar, MessageBarType } from "@fluentui/react";
import { BulkImportError } from "../../api";
import React from "react";

interface BulkImportErrorMessageProps {
    error?: BulkImportError;
}

export function BulkImportErrorMessage(props: BulkImportErrorMessageProps): JSX.Element {
    const error = props.error;
    // If no error is present render nothing
    if (!error) {
        return <></>;
    }

    /** Groups maps into an unordered list. Keys are used as headings and values
        as list items. */
    function mapIntoErrorList(map: { [key: string]: string[] }): JSX.Element[] {
        const list = Object.keys(map).map((sheet) => {
            const errors = map[sheet];
            const listItems = errors.map((error) => {
                return <li>{error}</li>;
            });
            return (
                <section>
                    <h3>{sheet}</h3>
                    <ul>{listItems}</ul>
                </section>
            );
        });
        return list;
    }

    return (
        <MessageBar messageBarType={MessageBarType.warning}>
            {
                "There are problems in the input file you provided. Here is a categorized summary of these issues, organized by sheet:"
            }
            {Object.keys(error.parse).length > 0 && mapIntoErrorList(error.parse)}
            {Object.keys(error.validation).length > 0 && mapIntoErrorList(error.validation)}
            {error.database && "An unknown database error has occurred."}
        </MessageBar>
    );
}
