/* eslint-disable filenames/match-regex */
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LogoutReason, ServiceAuth } from "../domain/services/service-auth";
import { routePath } from "../routes";

enum TargetView {
    Pass,
    Login,
}

export interface RequireAuthProps {
    serviceAuth: ServiceAuth;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function assertUnreachable(x: TargetView): never {
    throw new Error(`TargetView has an unsupported value: ${x}`);
}

export function RequireAuth(props: RequireAuthProps): JSX.Element {
    const location = useLocation();

    const targetView =
        !props.serviceAuth.isLoggedIn && location.pathname !== routePath.login()
            ? TargetView.Login
            : TargetView.Pass;

    switch (targetView) {
        case TargetView.Pass:
            return <Outlet />;
        case TargetView.Login:
            return (
                <Navigate
                    to={routePath.login(LogoutReason.NOT_AUTHENTICATED)}
                    state={{ from: location }}
                    replace={true}
                />
            );
        default:
            return assertUnreachable(targetView);
    }
}
