/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum VehicleMaintenanceSortKey {
    CREATED_AT = 'createdAt',
    VEHICLE = 'vehicle',
    USER = 'user',
    STARTED_DATE = 'startedDate',
    COMPLETED_DATE = 'completedDate',
    MAINTENANCE_STATUS = 'maintenanceStatus'
}


export function VehicleMaintenanceSortKeyFromJSON(json: any): VehicleMaintenanceSortKey {
    return VehicleMaintenanceSortKeyFromJSONTyped(json, false);
}

export function VehicleMaintenanceSortKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleMaintenanceSortKey {
    return json as VehicleMaintenanceSortKey;
}

export function VehicleMaintenanceSortKeyToJSON(value?: VehicleMaintenanceSortKey | null): any {
    return value as any;
}

