import * as React from "react";
import { IColumn } from "@fluentui/react";
import { Severity, SeverityPill } from "./severity-pill";

interface TruckActivityObj {
    readonly eventTime: string;
    readonly truckId: number;
    readonly eloshieldId: string;
    readonly oem: string;
    readonly oemType: string;
    readonly transgression: string;
    readonly detectedELOshieldId: string;
    readonly detectedTruckId: number;
    readonly detectedOem: string;
    readonly detectedOemType: string;
    readonly duration: string;
    readonly id: number;
}
export interface TruckObject {
    key: number;
    data: TruckActivityObj;
}
export interface ListTruckEventItemCellProps {
    readonly truckEventId: number;
    readonly column: IColumn;
    readonly item: TruckObject;
}

export class ListTruckEventItemCell extends React.Component<ListTruckEventItemCellProps> {
    private get severity(): Severity | undefined {
        const { data } = this.props.item;

        if (data?.transgression === "Warning Zone") {
            return Severity.WARNING;
        } else if (data?.transgression === "Danger Zone") {
            return Severity.DANGER;
        } else {
            return Severity.LIGHT;
        }
    }

    public render(): JSX.Element {
        const { column } = this.props;
        const { data } = this.props.item;

        switch (column.key) {
            case "event-time":
                return <span>{data?.eventTime}</span>;
            case "truck-id":
                return <span>{data?.truckId}</span>;
            case "eloshield-id":
                return <span>{data?.eloshieldId}</span>;
            case "oem":
                return <span>{data?.oem}</span>;
            case "oem-type":
                return <span>{data?.oemType}</span>;
            case "transgression":
                return this.severity ? <SeverityPill severity={this.severity} /> : <span />;
            case "detected-eloshield-id":
                return <span>{data?.detectedELOshieldId}</span>;
            case "detected-truck-id":
                return <span>{data?.detectedTruckId}</span>;
            case "detected-oem":
                return <span>{data?.detectedOem}</span>;
            case "detected-oem-type":
                return <span>{data?.detectedOemType}</span>;
            case "duration":
                return <span>{data?.duration}</span>;
            default:
                return <></>;
        }
    }
}
