/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  FileType,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FileTypeFromJSON,
    FileTypeToJSON,
} from '../models';

export interface FilesCreateFileRequest {
    fileType: FileType;
    entityId: string;
    requestBody: Array<number>;
}

export interface FilesDeleteFileRequest {
    fileId: string;
}

export interface FilesReadFileByTokenRequest {
    fileId: string;
}

/**
 * FilesApi - interface
 * 
 * @export
 * @interface FilesApiInterface
 */
export interface FilesApiInterface {
    /**
     * Create a new file for a given entity. Returns the Uuid of the created file.  If there already is a file of this type, the old file is removed and replaced.
     * @summary Create a new file for a specific entity.
     * @param {FileType} fileType 
     * @param {string} entityId 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApiInterface
     */
    filesCreateFileRaw(requestParameters: FilesCreateFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     * Create a new file for a given entity. Returns the Uuid of the created file.  If there already is a file of this type, the old file is removed and replaced.
     * Create a new file for a specific entity.
     */
    filesCreateFile(requestParameters: FilesCreateFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

    /**
     * Delete the file with the given ID.
     * @summary Delete a file
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApiInterface
     */
    filesDeleteFileRaw(requestParameters: FilesDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the file with the given ID.
     * Delete a file
     */
    filesDeleteFile(requestParameters: FilesDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Returns a specific file by the given ID.  The login token has to be specified as a cookie, as there\'s no way to send tokens via header if the browser asks for these files directly.
     * @summary Get a file
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApiInterface
     */
    filesReadFileByTokenRaw(requestParameters: FilesReadFileByTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>>;

    /**
     * Returns a specific file by the given ID.  The login token has to be specified as a cookie, as there\'s no way to send tokens via header if the browser asks for these files directly.
     * Get a file
     */
    filesReadFileByToken(requestParameters: FilesReadFileByTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>>;

}

/**
 * 
 */
export class FilesApi extends runtime.BaseAPI implements FilesApiInterface {

    /**
     * Create a new file for a given entity. Returns the Uuid of the created file.  If there already is a file of this type, the old file is removed and replaced.
     * Create a new file for a specific entity.
     */
    async filesCreateFileRaw(requestParameters: FilesCreateFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.fileType === null || requestParameters.fileType === undefined) {
            throw new runtime.RequiredError('fileType','Required parameter requestParameters.fileType was null or undefined when calling filesCreateFile.');
        }

        if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
            throw new runtime.RequiredError('entityId','Required parameter requestParameters.entityId was null or undefined when calling filesCreateFile.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling filesCreateFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/octet-stream';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/{file_type}/{entity_id}`.replace(`{${"file_type"}}`, encodeURIComponent(String(requestParameters.fileType))).replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters.entityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new file for a given entity. Returns the Uuid of the created file.  If there already is a file of this type, the old file is removed and replaced.
     * Create a new file for a specific entity.
     */
    async filesCreateFile(requestParameters: FilesCreateFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.filesCreateFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the file with the given ID.
     * Delete a file
     */
    async filesDeleteFileRaw(requestParameters: FilesDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling filesDeleteFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/{file_id}`.replace(`{${"file_id"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the file with the given ID.
     * Delete a file
     */
    async filesDeleteFile(requestParameters: FilesDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.filesDeleteFileRaw(requestParameters, initOverrides);
    }

    /**
     * Returns a specific file by the given ID.  The login token has to be specified as a cookie, as there\'s no way to send tokens via header if the browser asks for these files directly.
     * Get a file
     */
    async filesReadFileByTokenRaw(requestParameters: FilesReadFileByTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling filesReadFileByToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/{file_id}`.replace(`{${"file_id"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Returns a specific file by the given ID.  The login token has to be specified as a cookie, as there\'s no way to send tokens via header if the browser asks for these files directly.
     * Get a file
     */
    async filesReadFileByToken(requestParameters: FilesReadFileByTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.filesReadFileByTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
