import { when, runInAction } from "mobx";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, initialize, inject } from "tsdi";
import {
    PageInfo,
    UserGroupCreate,
    UserGroupsApi,
    UserGroupsListUserGroupsRequest,
    UserGroupSortKey,
    UserGroupUpdate,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { Validation } from "../../utils/validation";
import { UserGroup } from "../../api";
import { ServiceOwnUser } from "../services/service-own-user";
import { defaultPageSize } from "../../utils/constants";

export type UserGroupsQuery = Omit<UserGroupsListUserGroupsRequest, "page">;

export interface UserGroupsApiResource extends ApiResource {
    entity: UserGroup;
    query: UserGroupsQuery;
    update: UserGroupUpdate;
    create: UserGroupCreate;
    sortKey: UserGroupSortKey;
}

@component
export class RepositoryUserGroups extends ElofleetRepository<UserGroupsApiResource> {
    @inject private readonly serviceOwnUser!: ServiceOwnUser;
    @inject private readonly userGroupsApi!: UserGroupsApi;

    public validation = {
        create: new Validation((userGroupCreate: UserGroupCreate) =>
            this.userGroupsApi.userGroupsValidateCreateUserGroup({
                userGroupCreate: userGroupCreate,
            }),
        ),
        update: new Validation((userGroupUpdate: UserGroupUpdate, userGroupId: UUID) =>
            this.userGroupsApi.userGroupsValidateUpdateUserGroup({ userGroupUpdate, userGroupId }),
        ),
    };

    @initialize protected initialize(): void {
        // Preload the first page once the user is logged in to avoid loading them individually later.
        when(
            () => this.serviceOwnUser.user !== undefined,
            () => this.byQuery({ pageSize: defaultPageSize }, { count: this.defaultCount }),
        );
    }

    protected async fetchByQuery(
        query: UserGroupsQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<UserGroup>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.userGroupsApi.userGroupsListUserGroups({
            ...query,
            page,
        });
        return { entities };
    }

    public fetchMetadata(_query: unknown): Promise<PageInfo> {
        return this.userGroupsApi.userGroupsListUserGroupsMetadata({ pageSize: defaultPageSize });
    }

    protected async fetchById(userGroupId: UUID): Promise<UserGroup | undefined> {
        return await this.userGroupsApi.userGroupsReadUserGroup({
            userGroupId,
        });
    }

    protected extractId = prop("id");

    public async update(userGroupId: UUID, userGroupUpdate: UserGroupUpdate): Promise<UserGroup> {
        const userGroup = await this.wrapApiCall(
            this.userGroupsApi.userGroupsUpdateUserGroup({
                userGroupId,
                userGroupUpdate,
            }),
        );
        await this.waitForIdle();
        runInAction(() => {
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(userGroup);
        });
        return userGroup;
    }

    public async create(userGroupCreate: UserGroupCreate): Promise<UserGroup> {
        const userGroup = await this.wrapApiCall(
            this.userGroupsApi.userGroupsCreateUserGroup({
                userGroupCreate,
            }),
        );
        await this.waitForIdle();
        runInAction(() => {
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(userGroup);
        });
        return userGroup;
    }

    public async delete(...userGroupIds: UUID[]): Promise<void> {
        await this.wrapApiCall(
            Promise.all(
                userGroupIds.map((userGroupId) =>
                    this.userGroupsApi.userGroupsDeleteUserGroup({ userGroupId }),
                ),
            ),
        );
        await this.waitForIdle();
        this.reloadQuery({ pageSize: defaultPageSize });
    }
}
