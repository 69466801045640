import { runInAction } from "mobx";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    PageInfo,
    VehicleCreate,
    VehiclesApi,
    VehiclesListVehiclesRequest,
    VehicleSortKey,
    VehicleUpdate,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { Validation } from "../../utils/validation";
import { Vehicle } from "../../api";
import { defaultPageSize } from "../../utils/constants";

export type VehiclesQuery = Omit<VehiclesListVehiclesRequest, "page">;

export interface VehiclesApiResource extends ApiResource {
    entity: Vehicle;
    query: VehiclesQuery;
    update: VehicleUpdate;
    create: VehicleCreate;
    sortKey: VehicleSortKey;
}

@component
export class RepositoryVehicles extends ElofleetRepository<VehiclesApiResource> {
    @inject private readonly vehiclesApi!: VehiclesApi;

    protected async fetchById(vehicleId: UUID): Promise<Vehicle | undefined> {
        return await this.vehiclesApi.vehiclesReadVehicle({ vehicleId });
    }

    public validation = {
        create: new Validation((vehicleCreate: VehicleCreate) =>
            this.vehiclesApi.vehiclesValidateCreateVehicle({
                vehicleCreate: vehicleCreate,
            }),
        ),
        update: new Validation((vehicleUpdate: VehicleUpdate, vehicleId: UUID) =>
            this.vehiclesApi.vehiclesValidateUpdateVehicle({ vehicleId, vehicleUpdate }),
        ),
    };

    protected extractId = prop("id");

    protected async fetchByQuery(
        query: VehiclesQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<Vehicle>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.vehiclesApi.vehiclesListVehicles({
            ...query,
            page,
        });
        return { entities };
    }

    public fetchMetadata(query: VehiclesQuery): Promise<PageInfo> {
        return this.vehiclesApi.vehiclesListVehiclesMetadata(query);
    }

    public async update(vehicleId: UUID, vehicleUpdate: VehicleUpdate): Promise<Vehicle> {
        const vehicle = await this.wrapApiCall(
            this.vehiclesApi.vehiclesUpdateVehicle({
                vehicleUpdate,
                vehicleId,
            }),
        );
        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(vehicle);
        });

        return vehicle;
    }

    public async create(vehicleCreate: VehicleCreate): Promise<Vehicle> {
        const vehicle = await this.wrapApiCall(
            this.vehiclesApi.vehiclesCreateVehicle({
                vehicleCreate,
            }),
        );
        await this.waitForIdle();
        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(vehicle);
        });

        return vehicle;
    }

    public async delete(...vehicleIds: UUID[]): Promise<void> {
        await this.wrapApiCall(
            Promise.all(
                vehicleIds.map((vehicleId) =>
                    this.vehiclesApi.vehiclesDeleteVehicle({ vehicleId }),
                ),
            ),
        );

        // This reload is necessary to reload all queries that want to display "all" entities.
        await this.waitForIdle();
        this.reloadQuery({ pageSize: defaultPageSize });
    }
}
