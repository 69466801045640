/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NfcTokenUpdate
 */
export interface NfcTokenUpdate {
    /**
     * 
     * @type {string}
     * @memberof NfcTokenUpdate
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NfcTokenUpdate
     */
    userId?: string | null;
    /**
     * Normalized to lowercase by the backend.
     * @type {string}
     * @memberof NfcTokenUpdate
     */
    token?: string | null;
}

/**
 * Check if a given object implements the NfcTokenUpdate interface.
 */
export function instanceOfNfcTokenUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NfcTokenUpdateFromJSON(json: any): NfcTokenUpdate {
    return NfcTokenUpdateFromJSONTyped(json, false);
}

export function NfcTokenUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): NfcTokenUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}

export function NfcTokenUpdateToJSON(value?: NfcTokenUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'userId': value.userId,
        'token': value.token,
    };
}

