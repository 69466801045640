import * as React from "react";
import { observer } from "mobx-react";
import {
    createTheme,
    DefaultButton,
    IColumn,
    Icon,
    ITheme,
    Separator,
    Stack,
} from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { createUuid, UUID } from "../../utils/uuid";
import { VehicleGroup } from "../../api";
import { RepositoryVehicleGroups } from "../../domain/repositories/repository-vehicle-groups";
import { RepositoryVehicles } from "../../domain/repositories/repository-vehicles";
import { ModalConfirmation } from "../atoms/modal-confirmation";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { MultiSelectWithList } from "../organisms/multi-select-with-list";
import sizes from "../sizes.scss";
import { ElofleetDialog } from "../atoms/elofleet-dialog";
import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { RepositoryVehicleGroupAssignments } from "../../domain/repositories/repository-vehicle-group-assignments";
import { defaultPageSize } from "../../utils/constants";

export interface FormCreateVehicleGroupAssignmentsProps {
    readonly onDialogCancel: () => void;
    readonly isOpen: boolean;
}

@observer
@external
export class FormCreateVehicleGroupAssignments extends React.Component<FormCreateVehicleGroupAssignmentsProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;
    @inject private readonly repositoryVehicleGroups!: RepositoryVehicleGroups;
    @inject private readonly repositoryVehicleGroupAssignments!: RepositoryVehicleGroupAssignments;
    @inject private readonly repositoryDepartments!: RepositoryDepartments;

    @observable private cancelConfirmationModalVisible = false;

    // The list state ids for the respective lists of added groups.
    private vehicleGroupListStateId = createUuid();
    private vehicleListStateId = createUuid();

    // These lists contain the entities that've already been added.
    // I.e. the VehicleGroups and Vehicles that have been selected for assignments.
    //
    // Once the user sends this form, these entities will be used to create the
    // actual new assignments.
    @observable private addedVehicleGroups: UUID[] = [];
    @observable private addedVehicles: UUID[] = [];

    constructor(props: FormCreateVehicleGroupAssignmentsProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.vehicleGroups.initializeList(this.vehicleGroupListStateId, {
            query: () => {
                return {
                    pageSize: defaultPageSize,
                };
            },
            ignoreUrl: true,
        });
        this.serviceListStates.vehicles.initializeList(this.vehicleListStateId, {
            query: () => {
                return {
                    pageSize: defaultPageSize,
                };
            },
            ignoreUrl: true,
        });
    }

    /**
     * This is run, if the user clicks the form's cancel button.
     * A confirmation modal might pop up, if the user did any changes.
     */
    @action.bound private async createVehicleGroupAssignments(): Promise<void> {
        const assignmentsCreate = {
            vehicleGroupIds: this.addedVehicleGroups,
            vehicleIds: this.addedVehicles,
        };

        await this.repositoryVehicleGroupAssignments.createMultiple(assignmentsCreate);

        this.closeForm();
    }

    /**
     * This is run, if the user clicks the form's cancel button.
     * A confirmation modal might pop up, if the user did any changes.
     */
    @action.bound private showConfirmAndCloseForm(): void {
        // If there were any changes, i.e. if the original and mutable copy aren't the same,
        // open the confirmation modal for aborting the update process.
        if (this.addedVehicles.length > 0 || this.addedVehicleGroups.length > 0) {
            this.cancelConfirmationModalVisible = true;
            return;
        }

        this.closeForm();
    }

    /**
     * Actually close the component and reset all state.
     */
    @action.bound private closeForm(): void {
        this.cancelConfirmationModalVisible = false;
        this.addedVehicleGroups = [];
        this.addedVehicles = [];

        this.props.onDialogCancel();
    }

    @action.bound private closeCancelConfirmationModalVisible(): void {
        this.cancelConfirmationModalVisible = false;
    }

    /**
     * Handle the actual addition of a all currently selected Vehicles in the group.
     */
    @action.bound private addVehicleGroups(vehicleGroups: UUID[]): void {
        this.addedVehicleGroups = [...this.addedVehicleGroups, ...vehicleGroups];
    }

    /**
     * Handle the removal of VehicleGroups.
     * In this case, removal means that they'll be removed from the list of added VehicleGroups.
     */
    @action.bound private removeVehicleGroups(idsToRemove: UUID[]): void {
        const remainingGroups = this.addedVehicleGroups.filter((id) => !idsToRemove.includes(id));
        this.addedVehicleGroups = remainingGroups;
    }

    /**
     * Handle the actual addition of a all currently selected Vehicles in the group.
     */
    @action.bound private addVehicles(vehicles: UUID[]): void {
        this.addedVehicles = [...this.addedVehicles, ...vehicles];
    }

    /**
     * Handle the removal of Vehicles.
     * In this case, removal means that they'll be removed from the list of added Vehicles.
     */
    @action.bound private removeVehicles(idsToRemove: UUID[]): void {
        const remainingGroups = this.addedVehicles.filter((id) => !idsToRemove.includes(id));
        this.addedVehicles = remainingGroups;
    }

    /**
     * The items that should be shown in the list of added Vehicles.
     */
    @computed private get vehicleItems(): Object[] {
        const vehicles = [];

        for (const id of this.addedVehicles) {
            const vehicle = this.repositoryVehicles.byId(id);
            if (vehicle === undefined) {
                continue;
            }

            const department =
                typeof vehicle.departmentId === "string"
                    ? this.repositoryDepartments.byId(vehicle.departmentId)
                    : undefined;
            vehicles.push({
                key: vehicle.id,
                serialNumber: vehicle.serialNumber,
                manufacturer: vehicle.manufacturer,
                model: vehicle.model,
                department: department?.label ?? "",
                vehicleStatus: this.i18n.formatVehicleStatus(vehicle.status),
            });
        }

        return vehicles;
    }

    /**
     * The columns that should be shown in the list of selected Vehicles.
     */
    @computed private get vehicleColumns(): IColumn[] {
        return [
            {
                fieldName: "serialNumber",
                name: this.i18n.t("listVehicles.column.serialNumber.name"),
                key: "serialNumber",
                minWidth: 200,
                maxWidth: 300,
            },
            {
                fieldName: "manufacturer",
                name: this.i18n.t("listVehicles.column.manufacturer.name"),
                key: "manufacturer",
                minWidth: 80,
                maxWidth: 100,
            },
            {
                fieldName: "model",
                name: this.i18n.t("listVehicles.column.model.name"),
                key: "model",
                minWidth: 100,
                maxWidth: 150,
            },
            {
                fieldName: "department",
                name: this.i18n.t("listVehicles.column.department.name"),
                key: "department",
                minWidth: 100,
                maxWidth: 150,
            },
            {
                fieldName: "vehicleStatus",
                name: this.i18n.t("listVehicles.column.vehicleStatus.name"),
                key: "vehicleStatus",
                minWidth: 100,
                maxWidth: 150,
            },
        ];
    }

    /**
     * The items that should be shown in the list of added VehicleGroups.
     */
    @computed private get vehicleGroupItems(): Object[] {
        const groups = [];

        for (const id of this.addedVehicleGroups) {
            const group = this.repositoryVehicleGroups.byId(id);
            if (group !== undefined) {
                groups.push({
                    key: group.id,
                    label: group.label,
                    assignedVehicleCount: group.assignedVehicleCount,
                    assignedUserGroupCount: group.assignedUserGroupCount,
                });
            }
        }

        return groups;
    }

    /**
     * The columns that should be shown in the list of selected VehicleGroups.
     */
    @computed private get userGroupColumns(): IColumn[] {
        return [
            {
                fieldName: "label",
                name: this.i18n.t("listUserGroups.column.label.name"),
                key: "label",
                minWidth: 150,
            },
            {
                fieldName: "assignedUserCount",
                name: this.i18n.t("listUserGroups.column.assignedUserCount.name"),
                key: "assignedUserCount",
                minWidth: 80,
            },
            {
                fieldName: "assignedVehicleGroupCount",
                name: this.i18n.t("listUserGroups.column.assignedVehicleGroupCount.name"),
                key: "assignedVehicleGroupCount",
                minWidth: 150,
            },
        ];
    }

    public render(): JSX.Element {
        const separatorTheme: ITheme = createTheme({
            fonts: {
                medium: {
                    fontSize: sizes.l,
                },
            },
        });

        return (
            <ElofleetDialog
                isOpen={this.props.isOpen}
                title={this.i18n.t("formCreateVehicleGroupAssignments.title")}
                onDismiss={this.showConfirmAndCloseForm}
            >
                <Stack
                    horizontal
                    tokens={{
                        childrenGap: sizes.xxl,
                    }}
                    styles={{
                        root: {
                            minHeight: "400px",
                            padding: sizes.xl,
                            overflow: "auto",
                        },
                    }}
                >
                    <MultiSelectWithList
                        repository={this.repositoryVehicles}
                        onAdd={this.addVehicles}
                        onDelete={this.removeVehicles}
                        listStateId={this.vehicleListStateId}
                        listState={this.serviceListStates.vehicles}
                        addedEntities={this.addedVehicles}
                        columns={this.vehicleColumns}
                        items={this.vehicleItems}
                        formatEntity={this.i18n.formatVehicle}
                        addButtonText={this.i18n.t("component.multiSelect.addButton")}
                        removeButtonText={this.i18n.t("component.multiSelect.removeButton")}
                        dropdownLabel={this.i18n.t(
                            "formCreateVehicleGroupAssignments.vehicle.label",
                        )}
                        multiSelectPlaceholder={this.i18n.t(
                            "formCreateVehicleGroupAssignments.vehicle.placeholder",
                        )}
                    />
                    <Stack.Item styles={{ root: { height: "400px" } }}>
                        <Separator theme={separatorTheme} vertical>
                            <Stack>
                                <Stack.Item tokens={{ margin: `${sizes.s} 0px` }}>
                                    {this.i18n.t(
                                        "formCreateVehicleGroupAssignments.separator.assign",
                                    )}
                                </Stack.Item>
                                <Icon iconName="ChromeBackMirrored" />
                            </Stack>
                        </Separator>
                    </Stack.Item>
                    <MultiSelectWithList
                        repository={this.repositoryVehicleGroups}
                        onAdd={this.addVehicleGroups}
                        onDelete={this.removeVehicleGroups}
                        listStateId={this.vehicleGroupListStateId}
                        listState={this.serviceListStates.vehicleGroups}
                        addedEntities={this.addedVehicleGroups}
                        columns={this.userGroupColumns}
                        items={this.vehicleGroupItems}
                        formatEntity={(group: VehicleGroup) => group.label}
                        addButtonText={this.i18n.t("component.multiSelect.addButton")}
                        removeButtonText={this.i18n.t("component.multiSelect.removeButton")}
                        dropdownLabel={this.i18n.t(
                            "formCreateVehicleGroupAssignments.vehicleGroup.label",
                        )}
                        multiSelectPlaceholder={this.i18n.t(
                            "formCreateVehicleGroupAssignments.vehicleGroup.placeholder",
                        )}
                    />
                </Stack>
                <ElofleetDialogFooter>
                    <DefaultButton
                        label={this.i18n.t("formCreateVehicleGroupAssignments.cancel.label")}
                        text={this.i18n.t("formCreateVehicleGroupAssignments.cancel.text")}
                        onClick={this.showConfirmAndCloseForm}
                    />
                    <DefaultButton
                        primary
                        text={this.i18n.t("formCreateVehicleGroupAssignments.submit.text")}
                        onClick={this.createVehicleGroupAssignments}
                        disabled={
                            this.addedVehicles.length === 0 || this.addedVehicleGroups.length === 0
                        }
                    />
                </ElofleetDialogFooter>
                <ModalConfirmation
                    isOpen={this.cancelConfirmationModalVisible}
                    title={this.i18n.t("modalAbortUpdate.title")}
                    text={this.i18n.t("modalAbortUpdate.description")}
                    onConfirm={this.closeForm}
                    onCancel={this.closeCancelConfirmationModalVisible}
                />
            </ElofleetDialog>
        );
    }
}
