import * as React from "react";
import { CommandBar, IContextualMenuItem, Stack } from "@fluentui/react";
import { action, computed, makeObservable } from "mobx";
import { external } from "tsdi";
import css from "./export-button.scss";
import colors from "../colors.scss";
import { ShockEventsExportShockEventsExportType } from "../../api";
import { clone } from "ramda";
import { snakeCase } from "change-case";

export interface ExportQuery {
    query: [string, string | number | string[] | Date | null][];
    basePath: string;
}

export interface ExportButtonProps {
    query: ExportQuery;
}

const iconStyle = {
    color: colors.primary03,
};

@external
export class ExportButton extends React.Component<ExportButtonProps> {
    constructor(props: ExportButtonProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get items(): IContextualMenuItem[] {
        return [
            {
                key: "share",
                iconProps: { iconName: "Share", style: iconStyle },
                subMenuProps: {
                    items: [
                        {
                            key: ShockEventsExportShockEventsExportType.XLSX,
                            text: "Excel",
                            iconProps: { iconName: "ExcelDocument", style: iconStyle },

                            onClick: this.downloadFile,
                        },
                        {
                            key: ShockEventsExportShockEventsExportType.CSV,
                            text: "CSV",
                            iconProps: { iconName: "TextDocument", style: iconStyle },
                            onClick: this.downloadFile,
                        },
                    ],
                },
            },
        ];
    }

    @action.bound private downloadFile(
        _event?:
            | React.MouseEvent<HTMLElement | MouseEvent>
            | React.KeyboardEvent<HTMLElement>
            | undefined,
        item?: IContextualMenuItem,
    ): void {
        if (!item) {
            return;
        }
        const params = new URLSearchParams();
        const { query, basePath } = clone(this.props.query);

        // Manually build the url for the query.
        query.forEach(([key, value]) => {
            const snakeKey = snakeCase(key);
            if (!value) {
                return;
            }
            if (value instanceof Date) {
                params.append(snakeKey, value.toISOString());
            } else if (Array.isArray(value)) {
                value.forEach((inner) => {
                    params.append(snakeKey, inner);
                });
            } else {
                params.append(snakeKey, value.toString());
            }
        });
        params.append("export_type", item.key);
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        params.append("timezone", timezone);

        // Create a click on a <a /> tag element which allows us to download
        // a file directly with respect to headers set by the backend
        // (e.g. Content-type, Content-disposition).
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = basePath + params.toString();
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(a.href);
    }

    public render(): JSX.Element {
        return (
            <Stack className={css.exportButton}>
                <CommandBar items={this.items} />
            </Stack>
        );
    }
}
