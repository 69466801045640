import * as React from "react";
import { UUID, createUuid } from "../utils/uuid";
import { ElofleetList, ElofleetListProps } from "./atoms/elofleet-list";
import { external, initialize, inject } from "tsdi";
import { observer } from "mobx-react";
import { action, computed, makeObservable } from "mobx";
import {
    RepositoryVehicleTypes,
    VehicleTypesApiResource,
    VehicleTypesQuery,
} from "../domain/repositories/repository-vehicle-types";
import { ServiceListStates } from "../domain/services/service-list-states";
import { defaultPageSize } from "../utils/constants";
import { VehicleType, VehicleTypeSortKey } from "../api";
import { SortState } from "../utils/sort-state";
import { LayoutList } from "./layouts/layout-list";
import { PaginationState } from "../utils/pagination-state";
import { SelectionState } from "../utils/selection-state";
import { IObjectWithKey, Selection } from "@fluentui/react";
import { omit } from "ramda";
import { I18nProvider } from "../domain/providers/i18n-provider";

export interface ListVehicleTypesProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly commandBar?: JSX.Element;
    readonly listStateId?: UUID;
}

interface ListVehicleTypeItem extends IObjectWithKey {
    readonly label: string;
    readonly key: UUID;
}

@external
@observer
export class ListVehicleTypes extends React.Component<ListVehicleTypesProps> {
    @inject private readonly repositoryVehicleTypes!: RepositoryVehicleTypes;
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;

    private _listStateId = createUuid();
    private selection = new Selection({
        onSelectionChanged: () => this.updateSelection(),
    });

    constructor(props: ListVehicleTypesProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.vehicleTypes.initializeList(this.listStateId, {
            query: () => this.query,
        });
    }

    @computed private get listStateId(): UUID {
        return this.props.listStateId ?? this._listStateId;
    }

    @computed private get sortState(): SortState<VehicleTypeSortKey> {
        return this.serviceListStates.vehicleTypes.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<VehicleTypesApiResource> {
        return this.serviceListStates.vehicleTypes.getPaginationState(this.listStateId);
    }

    @computed private get selectionState(): SelectionState {
        return this.serviceListStates.vehicleTypes.getSelectionState(this.listStateId);
    }

    @computed private get query(): VehicleTypesQuery {
        return { pageSize: defaultPageSize, ...this.sortState.query };
    }

    @computed private get vehicleTypes(): (VehicleType | undefined)[] {
        return this.repositoryVehicleTypes.byQuery(this.query, this.paginationState.pagination);
    }

    @computed private get items(): (ListVehicleTypeItem | undefined)[] {
        return this.vehicleTypes.map((site) => {
            if (!site) {
                return;
            }
            return {
                label: site.label,
                key: site.id,
            };
        });
    }

    @action.bound private updateSelection(): void {
        // Update the listState's selectionState to the new selected keys.
        this.selectionState.updateSelection(
            this.selection.getSelection().map((item) => item.key as UUID),
        );
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.vehicleTypes.isInitialized(this.listStateId)) {
            return <></>;
        }

        const props = omit(["ids"], this.props);
        return (
            <LayoutList paginationState={this.paginationState} commandBar={this.props.commandBar}>
                <ElofleetList
                    {...props}
                    selection={this.selection}
                    onColumnHeaderClick={this.sortState.toggleColumn}
                    columns={this.sortState.patchColumns([
                        {
                            fieldName: "label",
                            name: this.i18n.t("listVehicleTypes.column.label.name"),
                            key: "label",
                            minWidth: 100,
                        },
                    ])}
                    items={this.items}
                />
            </LayoutList>
        );
    }
}
