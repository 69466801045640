import * as React from "react";

export interface CardProps {
    readonly children: React.ReactNode;
    readonly className?: string;
}

/**
 * A card that's displayed in the <main> element of a page.
 * It's basically a content segment.
 */
export function Card(props: CardProps): JSX.Element {
    const className = props.className ? props.className : "";
    return (
        <div
            className={`flex w-full flex-col border-b border-b-slate-300 px-8 pt-8 pb-11 last:border-0 ${className}`}
        >
            {props.children}
        </div>
    );
}
