import { runInAction } from "mobx";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    MultiVehicleGroupAssignmentCreate,
    PageInfo,
    VehicleGroupAssignmentCreate,
    VehicleGroupAssignmentsApi,
    VehicleGroupAssignmentsListVehicleGroupAssignmentsRequest,
    VehicleGroupAssignmentSortKey,
    VehicleGroupAssignmentUpdate,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { VehicleGroupAssignment } from "../../api";
import { Validation } from "../../utils/validation";
import { RepositoryVehicleGroups } from "./repository-vehicle-groups";
import { defaultPageSize } from "../../utils/constants";

export type VehicleGroupAssignmentsQuery = Omit<
    VehicleGroupAssignmentsListVehicleGroupAssignmentsRequest,
    "page"
>;

export interface VehicleGroupAssignmentsApiResource extends ApiResource {
    entity: VehicleGroupAssignment;
    query: VehicleGroupAssignmentsQuery;
    update: VehicleGroupAssignmentUpdate;
    create: VehicleGroupAssignmentCreate;
    sortKey: VehicleGroupAssignmentSortKey;
}

@component
export class RepositoryVehicleGroupAssignments extends ElofleetRepository<VehicleGroupAssignmentsApiResource> {
    @inject private readonly vehicleGroupAssignmentsApi!: VehicleGroupAssignmentsApi;
    @inject private readonly repositoryVehicleGroups!: RepositoryVehicleGroups;

    public validation = {
        create: new Validation((vehicleGroupAssignmentCreate: VehicleGroupAssignmentCreate) =>
            this.vehicleGroupAssignmentsApi.vehicleGroupAssignmentsValidateCreateVehicleGroupAssignment(
                {
                    vehicleGroupAssignmentCreate: vehicleGroupAssignmentCreate,
                },
            ),
        ),
        update: new Validation(
            (
                vehicleGroupAssignmentUpdate: VehicleGroupAssignmentUpdate,
                vehicleGroupAssignmentId: UUID,
            ) =>
                this.vehicleGroupAssignmentsApi.vehicleGroupAssignmentsValidateUpdateVehicleGroupAssignment(
                    {
                        vehicleGroupAssignmentUpdate,
                        vehicleGroupAssignmentId,
                    },
                ),
        ),
    };

    protected async fetchByQuery(
        query: VehicleGroupAssignmentsQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<VehicleGroupAssignment>> {
        const page = pagination.offset / this.defaultCount;
        const entities =
            await this.vehicleGroupAssignmentsApi.vehicleGroupAssignmentsListVehicleGroupAssignments(
                {
                    ...query,
                    page,
                },
            );
        return { entities };
    }

    public fetchMetadata(query: VehicleGroupAssignmentsQuery): Promise<PageInfo> {
        return this.vehicleGroupAssignmentsApi.vehicleGroupAssignmentsListVehicleGroupAssignmentsMetadata(
            query,
        );
    }

    protected async fetchById(
        vehicleGroupAssignmentId: UUID,
    ): Promise<VehicleGroupAssignment | undefined> {
        return await this.vehicleGroupAssignmentsApi.vehicleGroupAssignmentsReadVehicleGroupAssignment(
            {
                vehicleGroupAssignmentId,
            },
        );
    }

    protected extractId = prop("id");

    public async update(
        vehicleGroupAssignmentId: UUID,
        vehicleGroupAssignmentUpdate: VehicleGroupAssignmentUpdate,
    ): Promise<VehicleGroupAssignment> {
        const vehicleGroupAssignment = await this.wrapApiCall(
            this.vehicleGroupAssignmentsApi.vehicleGroupAssignmentsUpdateVehicleGroupAssignment({
                vehicleGroupAssignmentId,
                vehicleGroupAssignmentUpdate,
            }),
        );
        runInAction(() => {
            // As the vehicle group lists contain information about group assignments, we need to reload those as well.
            this.repositoryVehicleGroups.reloadQuery({ pageSize: defaultPageSize });
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(vehicleGroupAssignment);
        });
        return vehicleGroupAssignment;
    }

    public async create(
        vehicleGroupAssignmentCreate: VehicleGroupAssignmentCreate,
    ): Promise<VehicleGroupAssignment> {
        const vehicleGroupAssignment = await this.wrapApiCall(
            this.vehicleGroupAssignmentsApi.vehicleGroupAssignmentsCreateVehicleGroupAssignment({
                vehicleGroupAssignmentCreate,
            }),
        );
        runInAction(() => {
            // As the vehicle group lists contain information about group assignments, we need to reload those as well.
            this.repositoryVehicleGroups.reloadQuery({ pageSize: defaultPageSize });
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(vehicleGroupAssignment);
        });
        return vehicleGroupAssignment;
    }

    public async delete(...vehicleGroupAssignmentIds: UUID[]): Promise<void> {
        await this.wrapApiCall(
            Promise.all(
                vehicleGroupAssignmentIds.map((vehicleGroupAssignmentId) =>
                    this.vehicleGroupAssignmentsApi.vehicleGroupAssignmentsDeleteVehicleGroupAssignment(
                        {
                            vehicleGroupAssignmentId,
                        },
                    ),
                ),
            ),
        );
        // As the vehicle group lists contain information about group assignments, we need to reload those as well.
        this.repositoryVehicleGroups.reloadQuery({ pageSize: defaultPageSize });
        await this.reloadQuery({ pageSize: defaultPageSize });
    }

    public async createMultiple(
        multiVehicleGroupAssignmentCreate: MultiVehicleGroupAssignmentCreate,
    ): Promise<VehicleGroupAssignment[]> {
        const assignments = await this.wrapApiCall(
            this.vehicleGroupAssignmentsApi.vehicleGroupAssignmentsCreateMultipleVehicleGroupAssignments(
                { multiVehicleGroupAssignmentCreate },
            ),
        );

        runInAction(() => {
            // As the vehicle group lists contain information about group assignments, we need to reload those as well.
            this.repositoryVehicleGroups.reloadQuery({ pageSize: defaultPageSize });
            this.reloadQuery({ pageSize: defaultPageSize });
        });

        return assignments;
    }
}
