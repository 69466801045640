/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum VehicleSortKey {
    CREATED_AT = 'createdAt',
    MANUFACTURER = 'manufacturer',
    MODEL = 'model',
    VEHICLE_TYPE = 'vehicleType',
    SERIAL_NUMBER = 'serialNumber',
    MANUFACTURE_YEAR = 'manufactureYear',
    DEPARTMENT = 'department',
    SHOCK_PROFILE = 'shockProfile',
    PHONE_IMEI = 'phoneImei'
}


export function VehicleSortKeyFromJSON(json: any): VehicleSortKey {
    return VehicleSortKeyFromJSONTyped(json, false);
}

export function VehicleSortKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleSortKey {
    return json as VehicleSortKey;
}

export function VehicleSortKeyToJSON(value?: VehicleSortKey | null): any {
    return value as any;
}

