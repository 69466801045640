/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ActionConfigSortKey {
    LABEL = 'label',
    ELOSHIELD_MODULE = 'eloshieldModule'
}


export function ActionConfigSortKeyFromJSON(json: any): ActionConfigSortKey {
    return ActionConfigSortKeyFromJSONTyped(json, false);
}

export function ActionConfigSortKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionConfigSortKey {
    return json as ActionConfigSortKey;
}

export function ActionConfigSortKeyToJSON(value?: ActionConfigSortKey | null): any {
    return value as any;
}

