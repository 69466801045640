import React, { useEffect, useState } from "react";
import { NodeProps } from "reactflow";
import ActionConfigNode from "./action-config-node";
import { VehicleRelay, SetVehicleRelayData } from "../../../api";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export default function SetVehicleRelay(
    props: NodeProps<SetVehicleRelayData & { invalid?: boolean; validationErrorMessage?: string }>,
): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    const [selectedRelay, setSelectedRelay] = useState(props.data.relay ?? VehicleRelay.ONE);
    const relayOptions: IChoiceGroupOption[] = Object.values(VehicleRelay).map((key, index) => {
        return { key, text: t(`component.actionConfigNodes.relay.${index}`) };
    });

    // We have to set the data to prevent undefined values
    useEffect(() => {
        props.data.relay = selectedRelay;
    }, [props.data, selectedRelay]);

    function onChange(
        _e?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption,
    ): void {
        if (!option) {
            return;
        }
        setSelectedRelay(option.key as VehicleRelay);
        props.data.relay = option.key as VehicleRelay;
    }

    return (
        <ActionConfigNode nodeKind={props.type} {...props.data}>
            <ChoiceGroup options={relayOptions} selectedKey={selectedRelay} onChange={onChange} />
        </ActionConfigNode>
    );
}
