import { TSDI } from "tsdi";
import { Configuration, HTTPHeaders } from "../api";
import { createHeaders } from "./headers";

export interface CreateApiConfigurationOptions {
    /** A function returning the main dependency injection instance. */
    readonly tsdi: () => TSDI;
    /** URL to react the backend under. Defaults to `""` (same URL as frontend).*/
    readonly basePath?: string;
    /**
     * Optional additional headers to pass to the request.
     * These may be amended later.
     */
    readonly headers?: HTTPHeaders;
}

/** Create configuration for the backend API. */
export function createApiConfiguration({
    tsdi,
    basePath = "/api/v1/",
    headers,
}: CreateApiConfigurationOptions): Configuration {
    return new Configuration({
        basePath,
        get headers(): HTTPHeaders | undefined {
            return createHeaders(tsdi(), headers);
        },
    });
}
