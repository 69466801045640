import classNames from "classnames";
import React from "react";
import css from "./login-news-label.scss";
import { NewsCategory } from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { tsdi } from "../../tsdi";
import { observer } from "mobx-react";

interface LoginNewsLabelProps {
    pubDate: Date;
    category: NewsCategory;
}

export const LoginNewsLabel = observer(function LoginNewsLabel(
    props: LoginNewsLabelProps,
): JSX.Element {
    const i18n = tsdi.get(I18nProvider);
    const classes = classNames({
        [css.loginNewsLabel]: true,
        [css.loginNewsLabel__improvement]: props.category == NewsCategory.IMPROVEMENT,
        [css.loginNewsLabel__mobile]: props.category == NewsCategory.MOBILE,
        [css.loginNewsLabel__new]: props.category == NewsCategory.NEW,
        [css.loginNewsLabel__web]: props.category == NewsCategory.WEB,
    });
    const labelText = i18n.t(getNewsCategoryKey(props.category));

    return <label className={classes}>{labelText}</label>;
});

function getNewsCategoryKey(category: NewsCategory): string {
    if (category === undefined) {
        return "";
    }
    switch (category) {
        case NewsCategory.IMPROVEMENT:
            return "listLoginNews.label.improvement";
        case NewsCategory.MOBILE:
            return "listLoginNews.label.mobile";
        case NewsCategory.NEW:
            return "listLoginNews.label.new";
        case NewsCategory.WEB:
            return "listLoginNews.label.web";
    }
}
