import { autorun, makeObservable, runInAction, action, observable } from "mobx";
import { component, initialize, inject } from "tsdi";

import { ServiceAuth } from "./service-auth";
import { RepositoryPersistentNotifications } from "../repositories/repository-persistent-notifications";

@component
export class ServiceSse {
    @inject private readonly serviceAuth!: ServiceAuth;
    @inject private readonly repositoryPersistentNotifications!: RepositoryPersistentNotifications;

    @observable
    private eventSource: EventSource | undefined;

    @observable
    private status: "disconnected" | "connecting" | "connected" = "connecting";

    private reconnectInterval: number | undefined;

    constructor() {
        makeObservable(this);
    }

    @initialize
    protected initialize(): void {
        autorun(() => {
            if (!this.serviceAuth.isLoggedIn) {
                this.destroyEventSource();
                return;
            }

            if (this.status == "connecting") {
                this.createEventSource();
            }
        });
    }

    @action.bound
    private createEventSource(): void {
        if (this.eventSource) {
            this.destroyEventSource();
        }

        this.eventSource = new EventSource("/api/v1/sse/subscribe", { withCredentials: true });

        this.eventSource.onopen = this.handleEventSourceOpen;
        this.eventSource.onerror = this.handleEventSourceError;

        this.eventSource.addEventListener(
            "persistent-notification-created",
            this.handlePersistentNotificationCreatedOrUpdated,
        );
        this.eventSource.addEventListener(
            "persistent-notification-updated",
            this.handlePersistentNotificationCreatedOrUpdated,
        );
        this.eventSource.addEventListener(
            "persistent-notification-deleted",
            this.handlePersistentNotificationDeleted,
        );
    }

    @action.bound
    private destroyEventSource(): void {
        if (this.eventSource) {
            this.eventSource.close();
            this.eventSource = undefined;
            this.status = "disconnected";
        }
    }

    @action.bound
    private handleEventSourceOpen(_event: Event): void {
        runInAction(() => {
            this.status = "connected";

            if (this.reconnectInterval) {
                window.clearTimeout(this.reconnectInterval);
                this.reconnectInterval = undefined;
            }

            this.repositoryPersistentNotifications.reset();
            this.repositoryPersistentNotifications.fetchAll();
        });
    }

    @action.bound
    private handleEventSourceError(_event: Event): void {
        if (this.eventSource?.readyState != 2) {
            return;
        }

        runInAction(() => {
            this.destroyEventSource();

            if (!this.reconnectInterval) {
                this.reconnectInterval = window.setInterval(() => {
                    runInAction(() => {
                        this.status = "connecting";
                    });
                }, 60000);
            }
        });
    }

    @action.bound
    private handlePersistentNotificationCreatedOrUpdated(event: MessageEvent): void {
        runInAction(() => {
            this.repositoryPersistentNotifications.reloadId(event.data);
        });
    }

    @action.bound
    private handlePersistentNotificationDeleted(event: MessageEvent): void {
        runInAction(() => {
            this.repositoryPersistentNotifications.evict(event.data);
        });
    }
}
