import * as React from "react";
import { Section } from "../atoms/section";
import { IPanelProps, IStackTokens, Stack, StackItem, IRenderFunction } from "@fluentui/react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryVehicleGroups } from "../../domain/repositories/repository-vehicle-groups";
import { ListVehicles, ListVehiclesLayout } from "./list-vehicles";
import { ListUserGroups } from "./list-user-groups";
import { Panel, PanelType, SelectionMode } from "@fluentui/react";
import { UUID } from "../../utils/uuid";
import { external, inject } from "tsdi";
import { observer } from "mobx-react";
import { action } from "mobx";
import css from "./panel-vehicle-group-details.scss";
import { ListCommandButtonsVehicleGroups } from "../organisms/list-command-buttons-vehicle-groups";
import { mapSome } from "../../utils/functions";

export interface PanelVehicleGroupDetailsProps {
    readonly isOpen: boolean;
    readonly vehicleGroupId?: UUID;
    readonly onDismiss: (ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => void;
}

const numericalSpacingStackTokens: IStackTokens = {
    childrenGap: 20,
    padding: 10,
};

@external
@observer
export class PanelVehicleGroupDetails extends React.Component<PanelVehicleGroupDetailsProps> {
    @inject private readonly repositoryVehicleGroups!: RepositoryVehicleGroups;
    @inject protected readonly i18n!: I18nProvider;

    @action.bound private renderHeader(): IRenderFunction<IPanelProps> {
        return (props, defaultRender) => (
            <>
                {defaultRender!(props)}
                <Stack horizontal verticalAlign="center">
                    <div className={css.commandButtons}>
                        <ListCommandButtonsVehicleGroups
                            canDelete
                            singleItemMode
                            itemId={this.props.vehicleGroupId}
                            onDelete={(_evt) => this.props.onDismiss()}
                        />
                    </div>
                </Stack>
            </>
        );
    }

    public render(): JSX.Element {
        if (!this.props.vehicleGroupId) {
            return <></>;
        }

        const vehicleGroup = this.repositoryVehicleGroups.byId(this.props.vehicleGroupId);
        const vehicleCount =
            mapSome((count) => `(${count})`, vehicleGroup?.assignedVehicleCount) ?? "";
        const userGroupCount =
            mapSome((count) => `(${count})`, vehicleGroup?.assignedUserGroupCount) ?? "";

        return (
            <Panel
                isOpen={this.props.isOpen}
                isBlocking={false}
                onDismiss={this.props.onDismiss}
                onRenderHeader={this.renderHeader()}
                type={PanelType.large}
                headerText={vehicleGroup?.label}
            >
                <Stack horizontal horizontalAlign="start" tokens={numericalSpacingStackTokens}>
                    <StackItem>
                        <Section
                            withPadding
                            title={`${this.i18n.t(
                                "component.panelVehicleGroupDetails.vehicles.title",
                            )} ${vehicleCount}`}
                        >
                            <ListVehicles
                                selectionMode={SelectionMode.none}
                                columnLayout={ListVehiclesLayout.Group}
                                vehicleGroupIds={[this.props.vehicleGroupId]}
                                ignoreUrl
                                hideFilter
                            />
                        </Section>
                    </StackItem>
                    <StackItem>
                        <Section
                            withPadding
                            title={`${this.i18n.t(
                                "component.panelVehicleGroupDetails.userGroups.title",
                            )} ${userGroupCount}`}
                        >
                            <ListUserGroups
                                selectionMode={SelectionMode.none}
                                vehicleGroupIds={[this.props.vehicleGroupId]}
                                ignoreUrl
                            />
                        </Section>
                    </StackItem>
                </Stack>
            </Panel>
        );
    }
}
