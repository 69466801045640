import * as React from "react";
import { pick } from "ramda";
import { observer } from "mobx-react";
import { Stack, TextField, DefaultButton } from "@fluentui/react";
import { action, computed, makeObservable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositorySites } from "../../domain/repositories/repository-sites";
import { SiteUpdate } from "../../api";
import { doubleBindString } from "../../utils/double-bind";
import { Site } from "../../api";
import { createUuid } from "../../utils/uuid";
import { FormUpdateProps } from "../../utils/form-update-props";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";

export interface FormUpdateSiteProps extends FormUpdateProps<Site> {}

@observer
@external
export class FormUpdateSite extends React.Component<FormUpdateSiteProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositorySites!: RepositorySites;

    private validationId = createUuid();

    constructor(props: FormUpdateSiteProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositorySites.validation.update.updateModel(this.validationId, this.siteUpdate);
    }

    @initialize protected initialize(): void {
        this.repositorySites.validation.update.initializeModel(
            this.validationId,
            this.siteUpdate,
            this.props.id,
        );
    }

    public componentWillUnmount(): void {
        this.repositorySites.discardMutableCopy(FormUpdateSite.name, this.props.id);
    }

    @computed private get site(): Site | undefined {
        return this.repositorySites.mutableCopyById(FormUpdateSite.name, this.props.id);
    }

    @computed private get siteUpdate(): SiteUpdate {
        return pick(["label"], this.site);
    }

    @action.bound
    private async updateSite(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const site = await this.repositorySites.update(this.props.id, this.siteUpdate);
        this.props.onUpdate(site);
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formUpdateSite.submit.text")}
                validation={this.repositorySites.validation.update}
                validationId={this.validationId}
            />
        );
        return (
            <form onSubmit={this.updateSite}>
                <Stack tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                    <TextField
                        label={this.i18n.t("formUpdateSite.label.label")}
                        disabled={!this.site}
                        {...doubleBindString(this.site!, "label", () => this.triggerValidation())}
                        required
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositorySites.validation.update.getFieldValidationState(
                                this.validationId,
                                "label",
                            ),
                        )}
                    />
                </Stack>
                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            label={this.i18n.t("formUpdateSite.cancel.label")}
                            text={this.i18n.t("formUpdateSite.cancel.text")}
                            onClick={this.props.onDialogCancel}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
            </form>
        );
    }
}
