/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShockProfileUpdate
 */
export interface ShockProfileUpdate {
    /**
     * 
     * @type {string}
     * @memberof ShockProfileUpdate
     */
    label?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShockProfileUpdate
     */
    light?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShockProfileUpdate
     */
    medium?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShockProfileUpdate
     */
    severe?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShockProfileUpdate
     */
    duration?: number | null;
}

/**
 * Check if a given object implements the ShockProfileUpdate interface.
 */
export function instanceOfShockProfileUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ShockProfileUpdateFromJSON(json: any): ShockProfileUpdate {
    return ShockProfileUpdateFromJSONTyped(json, false);
}

export function ShockProfileUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShockProfileUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': !exists(json, 'label') ? undefined : json['label'],
        'light': !exists(json, 'light') ? undefined : json['light'],
        'medium': !exists(json, 'medium') ? undefined : json['medium'],
        'severe': !exists(json, 'severe') ? undefined : json['severe'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
    };
}

export function ShockProfileUpdateToJSON(value?: ShockProfileUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'light': value.light,
        'medium': value.medium,
        'severe': value.severe,
        'duration': value.duration,
    };
}

