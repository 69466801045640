/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MotionSensor
 */
export interface MotionSensor {
    /**
     * 
     * @type {string}
     * @memberof MotionSensor
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof MotionSensor
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof MotionSensor
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof MotionSensor
     */
    vehicleDeviceId: string;
    /**
     * 
     * @type {Date}
     * @memberof MotionSensor
     */
    firmwareLastUpdatedAt: Date;
    /**
     * This current semantic version of the firmware.
     * @type {string}
     * @memberof MotionSensor
     */
    currentVersion: string;
    /**
     * 
     * @type {number}
     * @memberof MotionSensor
     */
    currentVersionNumber: number;
    /**
     * 
     * @type {string}
     * @memberof MotionSensor
     */
    currentVersionHash: string;
}

/**
 * Check if a given object implements the MotionSensor interface.
 */
export function instanceOfMotionSensor(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "vehicleDeviceId" in value;
    isInstance = isInstance && "firmwareLastUpdatedAt" in value;
    isInstance = isInstance && "currentVersion" in value;
    isInstance = isInstance && "currentVersionNumber" in value;
    isInstance = isInstance && "currentVersionHash" in value;

    return isInstance;
}

export function MotionSensorFromJSON(json: any): MotionSensor {
    return MotionSensorFromJSONTyped(json, false);
}

export function MotionSensorFromJSONTyped(json: any, ignoreDiscriminator: boolean): MotionSensor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'vehicleDeviceId': json['vehicleDeviceId'],
        'firmwareLastUpdatedAt': (new Date(json['firmwareLastUpdatedAt'])),
        'currentVersion': json['currentVersion'],
        'currentVersionNumber': json['currentVersionNumber'],
        'currentVersionHash': json['currentVersionHash'],
    };
}

export function MotionSensorToJSON(value?: MotionSensor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'vehicleDeviceId': value.vehicleDeviceId,
        'firmwareLastUpdatedAt': (value.firmwareLastUpdatedAt.toISOString()),
        'currentVersion': value.currentVersion,
        'currentVersionNumber': value.currentVersionNumber,
        'currentVersionHash': value.currentVersionHash,
    };
}

