/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShockEventsPerEntity
 */
export interface ShockEventsPerEntity {
    /**
     * 
     * @type {string}
     * @memberof ShockEventsPerEntity
     */
    entityId: string;
    /**
     * 
     * @type {string}
     * @memberof ShockEventsPerEntity
     */
    entityName: string;
    /**
     * 
     * @type {number}
     * @memberof ShockEventsPerEntity
     */
    severe: number;
    /**
     * 
     * @type {number}
     * @memberof ShockEventsPerEntity
     */
    medium: number;
    /**
     * 
     * @type {number}
     * @memberof ShockEventsPerEntity
     */
    light: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShockEventsPerEntity
     */
    chartData: Array<number>;
}

/**
 * Check if a given object implements the ShockEventsPerEntity interface.
 */
export function instanceOfShockEventsPerEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entityId" in value;
    isInstance = isInstance && "entityName" in value;
    isInstance = isInstance && "severe" in value;
    isInstance = isInstance && "medium" in value;
    isInstance = isInstance && "light" in value;
    isInstance = isInstance && "chartData" in value;

    return isInstance;
}

export function ShockEventsPerEntityFromJSON(json: any): ShockEventsPerEntity {
    return ShockEventsPerEntityFromJSONTyped(json, false);
}

export function ShockEventsPerEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShockEventsPerEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityId': json['entityId'],
        'entityName': json['entityName'],
        'severe': json['severe'],
        'medium': json['medium'],
        'light': json['light'],
        'chartData': json['chartData'],
    };
}

export function ShockEventsPerEntityToJSON(value?: ShockEventsPerEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityId': value.entityId,
        'entityName': value.entityName,
        'severe': value.severe,
        'medium': value.medium,
        'light': value.light,
        'chartData': value.chartData,
    };
}

