import * as React from "react";
import { external } from "tsdi";
import { DefaultButton, IContextualMenuProps } from "@fluentui/react";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { inject } from "tsdi";
import { UUID } from "../../utils/uuid";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import sizes from "../sizes.scss";
import { FormCreateVehicleGroupAssignments } from "../molecules/form-create-vehicle-group-assignments";
import { FormCreateUserGroupVehicleGroupAssignments } from "../molecules/form-create-user-group-vehicle-group-assignments";
import { FormCreateVehicleGroup } from "../molecules/form-create-vehicle-group";

export interface ListVehicleGroupAddButtonProps {
    readonly listStateId?: UUID;
}

/**
 * The VehicleGroup list has some special behavior regarding forms.
 * One can open several forms to add various relationship instead of just having only a single
 * button for creating a new VehicleGroup.
 *
 * This is implemented via a button with a drop-down.
 * At the point of writing, this includes:
 * - Assigning users to vehicle groups.
 * - Creating a new vehicle group
 * - Assigning user groups to vehicle groups.
 */
@external
@observer
export class ListVehicleGroupAddButton extends React.Component<ListVehicleGroupAddButtonProps> {
    @inject protected readonly i18n!: I18nProvider;

    @observable private assignVehiclesDialogVisible = false;
    @observable private createVehicleGroupDialogVisible = false;
    @observable private assignUserGroupsDialogVisible = false;

    constructor(props: ListVehicleGroupAddButtonProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private openAssignVehiclesDialog(): void {
        this.assignVehiclesDialogVisible = true;
    }

    @action.bound private closeAssignVehicleGroupsDialog(): void {
        this.assignUserGroupsDialogVisible = false;
    }

    @action.bound private openAssignUserGroupsDialog(): void {
        this.assignUserGroupsDialogVisible = true;
    }

    @action.bound private closeAssignVehiclesDialog(): void {
        this.assignVehiclesDialogVisible = false;
    }

    @action.bound private openAddVehicleGroupDialog(): boolean | void {
        this.createVehicleGroupDialogVisible = true;
    }

    @action.bound private closeAddVehicleGroupDialog(): boolean | void {
        this.createVehicleGroupDialogVisible = false;
    }

    public render(): JSX.Element {
        const menuProps: IContextualMenuProps = {
            items: [
                {
                    key: "addGroup",
                    text: this.i18n.t("listVehicleGroups.button.addGroup"),
                    onClick: this.openAddVehicleGroupDialog,
                },
                {
                    key: "assignVehicleGroups",
                    text: this.i18n.t("listVehicleGroups.button.assignGroups"),
                    onClick: this.openAssignUserGroupsDialog,
                },
            ],
        };

        return (
            <div
                style={{
                    marginRight: sizes.xl,
                }}
            >
                <DefaultButton
                    text={this.i18n.t("listVehicleGroups.button.assignVehicles")}
                    primary
                    split
                    aria-roledescription="split button"
                    menuProps={menuProps}
                    onClick={this.openAssignVehiclesDialog}
                />
                <FormCreateVehicleGroup
                    onDialogCancel={this.closeAddVehicleGroupDialog}
                    asDialogContent={true}
                    isOpen={this.createVehicleGroupDialogVisible}
                />
                <FormCreateVehicleGroupAssignments
                    onDialogCancel={this.closeAssignVehiclesDialog}
                    isOpen={this.assignVehiclesDialogVisible}
                />
                <FormCreateUserGroupVehicleGroupAssignments
                    onDialogCancel={this.closeAssignVehicleGroupsDialog}
                    isOpen={this.assignUserGroupsDialogVisible}
                />
            </div>
        );
    }
}
