/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  MultiVehicleGroupAssignmentCreate,
  PageInfo,
  SortDirection,
  VehicleGroupAssignment,
  VehicleGroupAssignmentCreate,
  VehicleGroupAssignmentSortKey,
  VehicleGroupAssignmentUpdate,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    MultiVehicleGroupAssignmentCreateFromJSON,
    MultiVehicleGroupAssignmentCreateToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    VehicleGroupAssignmentFromJSON,
    VehicleGroupAssignmentToJSON,
    VehicleGroupAssignmentCreateFromJSON,
    VehicleGroupAssignmentCreateToJSON,
    VehicleGroupAssignmentSortKeyFromJSON,
    VehicleGroupAssignmentSortKeyToJSON,
    VehicleGroupAssignmentUpdateFromJSON,
    VehicleGroupAssignmentUpdateToJSON,
} from '../models';

export interface VehicleGroupAssignmentsCreateMultipleVehicleGroupAssignmentsRequest {
    multiVehicleGroupAssignmentCreate: MultiVehicleGroupAssignmentCreate;
}

export interface VehicleGroupAssignmentsCreateVehicleGroupAssignmentRequest {
    vehicleGroupAssignmentCreate: VehicleGroupAssignmentCreate;
}

export interface VehicleGroupAssignmentsDeleteVehicleGroupAssignmentRequest {
    vehicleGroupAssignmentId: string;
}

export interface VehicleGroupAssignmentsListVehicleGroupAssignmentsRequest {
    pageSize: number;
    vehicleGroupIds?: Array<string> | null;
    vehicleId?: string | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: VehicleGroupAssignmentSortKey;
}

export interface VehicleGroupAssignmentsListVehicleGroupAssignmentsMetadataRequest {
    pageSize: number;
    vehicleGroupIds?: Array<string> | null;
    vehicleId?: string | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: VehicleGroupAssignmentSortKey;
}

export interface VehicleGroupAssignmentsReadVehicleGroupAssignmentRequest {
    vehicleGroupAssignmentId: string;
}

export interface VehicleGroupAssignmentsUpdateVehicleGroupAssignmentRequest {
    vehicleGroupAssignmentId: string;
    vehicleGroupAssignmentUpdate: VehicleGroupAssignmentUpdate;
}

export interface VehicleGroupAssignmentsValidateCreateVehicleGroupAssignmentRequest {
    vehicleGroupAssignmentCreate: VehicleGroupAssignmentCreate;
}

export interface VehicleGroupAssignmentsValidateUpdateVehicleGroupAssignmentRequest {
    vehicleGroupAssignmentId: string;
    vehicleGroupAssignmentUpdate: VehicleGroupAssignmentUpdate;
}

/**
 * VehicleGroupAssignmentsApi - interface
 * 
 * @export
 * @interface VehicleGroupAssignmentsApiInterface
 */
export interface VehicleGroupAssignmentsApiInterface {
    /**
     * This endpoint accepts a list of Vehicle ids and VehicleGroup ids respectively.  Assignments are then created for each Vehicle to each VehicleGroup. Trying to create duplicate assignments won\'t lead to errors and will simply be ignored.
     * @summary Create multiple VehicleGroupAssignments
     * @param {MultiVehicleGroupAssignmentCreate} multiVehicleGroupAssignmentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGroupAssignmentsApiInterface
     */
    vehicleGroupAssignmentsCreateMultipleVehicleGroupAssignmentsRaw(requestParameters: VehicleGroupAssignmentsCreateMultipleVehicleGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleGroupAssignment>>>;

    /**
     * This endpoint accepts a list of Vehicle ids and VehicleGroup ids respectively.  Assignments are then created for each Vehicle to each VehicleGroup. Trying to create duplicate assignments won\'t lead to errors and will simply be ignored.
     * Create multiple VehicleGroupAssignments
     */
    vehicleGroupAssignmentsCreateMultipleVehicleGroupAssignments(requestParameters: VehicleGroupAssignmentsCreateMultipleVehicleGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleGroupAssignment>>;

    /**
     * Create a new vehicle_group_assignment.
     * @summary Create a vehicle_group_assignment
     * @param {VehicleGroupAssignmentCreate} vehicleGroupAssignmentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGroupAssignmentsApiInterface
     */
    vehicleGroupAssignmentsCreateVehicleGroupAssignmentRaw(requestParameters: VehicleGroupAssignmentsCreateVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleGroupAssignment>>;

    /**
     * Create a new vehicle_group_assignment.
     * Create a vehicle_group_assignment
     */
    vehicleGroupAssignmentsCreateVehicleGroupAssignment(requestParameters: VehicleGroupAssignmentsCreateVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleGroupAssignment>;

    /**
     * Delete the vehicle_group_assignment with the given ID.
     * @summary Delete a vehicle_group_assignment
     * @param {string} vehicleGroupAssignmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGroupAssignmentsApiInterface
     */
    vehicleGroupAssignmentsDeleteVehicleGroupAssignmentRaw(requestParameters: VehicleGroupAssignmentsDeleteVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the vehicle_group_assignment with the given ID.
     * Delete a vehicle_group_assignment
     */
    vehicleGroupAssignmentsDeleteVehicleGroupAssignment(requestParameters: VehicleGroupAssignmentsDeleteVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Lists all the vehicle_group_assignment present.
     * @summary List vehicle_group_assignments
     * @param {number} pageSize 
     * @param {Array<string>} [vehicleGroupIds] 
     * @param {string} [vehicleId] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {VehicleGroupAssignmentSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGroupAssignmentsApiInterface
     */
    vehicleGroupAssignmentsListVehicleGroupAssignmentsRaw(requestParameters: VehicleGroupAssignmentsListVehicleGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleGroupAssignment>>>;

    /**
     * Lists all the vehicle_group_assignment present.
     * List vehicle_group_assignments
     */
    vehicleGroupAssignmentsListVehicleGroupAssignments(requestParameters: VehicleGroupAssignmentsListVehicleGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleGroupAssignment>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary Vehicle group assignment listing metadata
     * @param {number} pageSize 
     * @param {Array<string>} [vehicleGroupIds] 
     * @param {string} [vehicleId] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {VehicleGroupAssignmentSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGroupAssignmentsApiInterface
     */
    vehicleGroupAssignmentsListVehicleGroupAssignmentsMetadataRaw(requestParameters: VehicleGroupAssignmentsListVehicleGroupAssignmentsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * Vehicle group assignment listing metadata
     */
    vehicleGroupAssignmentsListVehicleGroupAssignmentsMetadata(requestParameters: VehicleGroupAssignmentsListVehicleGroupAssignmentsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the vehicle_group_assignment specified by the given ID.
     * @summary Get a single vehicle_group_assignment
     * @param {string} vehicleGroupAssignmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGroupAssignmentsApiInterface
     */
    vehicleGroupAssignmentsReadVehicleGroupAssignmentRaw(requestParameters: VehicleGroupAssignmentsReadVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleGroupAssignment>>;

    /**
     * Returns the vehicle_group_assignment specified by the given ID.
     * Get a single vehicle_group_assignment
     */
    vehicleGroupAssignmentsReadVehicleGroupAssignment(requestParameters: VehicleGroupAssignmentsReadVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleGroupAssignment>;

    /**
     * Update the vehicle_group_assignment specified by the given ID.
     * @summary Update vehicle_group_assignment
     * @param {string} vehicleGroupAssignmentId 
     * @param {VehicleGroupAssignmentUpdate} vehicleGroupAssignmentUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGroupAssignmentsApiInterface
     */
    vehicleGroupAssignmentsUpdateVehicleGroupAssignmentRaw(requestParameters: VehicleGroupAssignmentsUpdateVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleGroupAssignment>>;

    /**
     * Update the vehicle_group_assignment specified by the given ID.
     * Update vehicle_group_assignment
     */
    vehicleGroupAssignmentsUpdateVehicleGroupAssignment(requestParameters: VehicleGroupAssignmentsUpdateVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleGroupAssignment>;

    /**
     * Validate data for a new vehicle_group_assignment without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a vehicle_group_assignment
     * @param {VehicleGroupAssignmentCreate} vehicleGroupAssignmentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGroupAssignmentsApiInterface
     */
    vehicleGroupAssignmentsValidateCreateVehicleGroupAssignmentRaw(requestParameters: VehicleGroupAssignmentsValidateCreateVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new vehicle_group_assignment without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a vehicle_group_assignment
     */
    vehicleGroupAssignmentsValidateCreateVehicleGroupAssignment(requestParameters: VehicleGroupAssignmentsValidateCreateVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate data for updating the given vehicle_group_assignment, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate vehicle_group_assignment update
     * @param {string} vehicleGroupAssignmentId 
     * @param {VehicleGroupAssignmentUpdate} vehicleGroupAssignmentUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleGroupAssignmentsApiInterface
     */
    vehicleGroupAssignmentsValidateUpdateVehicleGroupAssignmentRaw(requestParameters: VehicleGroupAssignmentsValidateUpdateVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for updating the given vehicle_group_assignment, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate vehicle_group_assignment update
     */
    vehicleGroupAssignmentsValidateUpdateVehicleGroupAssignment(requestParameters: VehicleGroupAssignmentsValidateUpdateVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class VehicleGroupAssignmentsApi extends runtime.BaseAPI implements VehicleGroupAssignmentsApiInterface {

    /**
     * This endpoint accepts a list of Vehicle ids and VehicleGroup ids respectively.  Assignments are then created for each Vehicle to each VehicleGroup. Trying to create duplicate assignments won\'t lead to errors and will simply be ignored.
     * Create multiple VehicleGroupAssignments
     */
    async vehicleGroupAssignmentsCreateMultipleVehicleGroupAssignmentsRaw(requestParameters: VehicleGroupAssignmentsCreateMultipleVehicleGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleGroupAssignment>>> {
        if (requestParameters.multiVehicleGroupAssignmentCreate === null || requestParameters.multiVehicleGroupAssignmentCreate === undefined) {
            throw new runtime.RequiredError('multiVehicleGroupAssignmentCreate','Required parameter requestParameters.multiVehicleGroupAssignmentCreate was null or undefined when calling vehicleGroupAssignmentsCreateMultipleVehicleGroupAssignments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_group_assignments/multi`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MultiVehicleGroupAssignmentCreateToJSON(requestParameters.multiVehicleGroupAssignmentCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleGroupAssignmentFromJSON));
    }

    /**
     * This endpoint accepts a list of Vehicle ids and VehicleGroup ids respectively.  Assignments are then created for each Vehicle to each VehicleGroup. Trying to create duplicate assignments won\'t lead to errors and will simply be ignored.
     * Create multiple VehicleGroupAssignments
     */
    async vehicleGroupAssignmentsCreateMultipleVehicleGroupAssignments(requestParameters: VehicleGroupAssignmentsCreateMultipleVehicleGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleGroupAssignment>> {
        const response = await this.vehicleGroupAssignmentsCreateMultipleVehicleGroupAssignmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new vehicle_group_assignment.
     * Create a vehicle_group_assignment
     */
    async vehicleGroupAssignmentsCreateVehicleGroupAssignmentRaw(requestParameters: VehicleGroupAssignmentsCreateVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleGroupAssignment>> {
        if (requestParameters.vehicleGroupAssignmentCreate === null || requestParameters.vehicleGroupAssignmentCreate === undefined) {
            throw new runtime.RequiredError('vehicleGroupAssignmentCreate','Required parameter requestParameters.vehicleGroupAssignmentCreate was null or undefined when calling vehicleGroupAssignmentsCreateVehicleGroupAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_group_assignments/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleGroupAssignmentCreateToJSON(requestParameters.vehicleGroupAssignmentCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleGroupAssignmentFromJSON(jsonValue));
    }

    /**
     * Create a new vehicle_group_assignment.
     * Create a vehicle_group_assignment
     */
    async vehicleGroupAssignmentsCreateVehicleGroupAssignment(requestParameters: VehicleGroupAssignmentsCreateVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleGroupAssignment> {
        const response = await this.vehicleGroupAssignmentsCreateVehicleGroupAssignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the vehicle_group_assignment with the given ID.
     * Delete a vehicle_group_assignment
     */
    async vehicleGroupAssignmentsDeleteVehicleGroupAssignmentRaw(requestParameters: VehicleGroupAssignmentsDeleteVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.vehicleGroupAssignmentId === null || requestParameters.vehicleGroupAssignmentId === undefined) {
            throw new runtime.RequiredError('vehicleGroupAssignmentId','Required parameter requestParameters.vehicleGroupAssignmentId was null or undefined when calling vehicleGroupAssignmentsDeleteVehicleGroupAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_group_assignments/{vehicle_group_assignment_id}`.replace(`{${"vehicle_group_assignment_id"}}`, encodeURIComponent(String(requestParameters.vehicleGroupAssignmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the vehicle_group_assignment with the given ID.
     * Delete a vehicle_group_assignment
     */
    async vehicleGroupAssignmentsDeleteVehicleGroupAssignment(requestParameters: VehicleGroupAssignmentsDeleteVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vehicleGroupAssignmentsDeleteVehicleGroupAssignmentRaw(requestParameters, initOverrides);
    }

    /**
     * Lists all the vehicle_group_assignment present.
     * List vehicle_group_assignments
     */
    async vehicleGroupAssignmentsListVehicleGroupAssignmentsRaw(requestParameters: VehicleGroupAssignmentsListVehicleGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleGroupAssignment>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling vehicleGroupAssignmentsListVehicleGroupAssignments.');
        }

        const queryParameters: any = {};

        if (requestParameters.vehicleGroupIds) {
            queryParameters['vehicle_group_ids'] = requestParameters.vehicleGroupIds;
        }

        if (requestParameters.vehicleId !== undefined) {
            queryParameters['vehicle_id'] = requestParameters.vehicleId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_group_assignments/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleGroupAssignmentFromJSON));
    }

    /**
     * Lists all the vehicle_group_assignment present.
     * List vehicle_group_assignments
     */
    async vehicleGroupAssignmentsListVehicleGroupAssignments(requestParameters: VehicleGroupAssignmentsListVehicleGroupAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleGroupAssignment>> {
        const response = await this.vehicleGroupAssignmentsListVehicleGroupAssignmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * Vehicle group assignment listing metadata
     */
    async vehicleGroupAssignmentsListVehicleGroupAssignmentsMetadataRaw(requestParameters: VehicleGroupAssignmentsListVehicleGroupAssignmentsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling vehicleGroupAssignmentsListVehicleGroupAssignmentsMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.vehicleGroupIds) {
            queryParameters['vehicle_group_ids'] = requestParameters.vehicleGroupIds;
        }

        if (requestParameters.vehicleId !== undefined) {
            queryParameters['vehicle_id'] = requestParameters.vehicleId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_group_assignments/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * Vehicle group assignment listing metadata
     */
    async vehicleGroupAssignmentsListVehicleGroupAssignmentsMetadata(requestParameters: VehicleGroupAssignmentsListVehicleGroupAssignmentsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.vehicleGroupAssignmentsListVehicleGroupAssignmentsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the vehicle_group_assignment specified by the given ID.
     * Get a single vehicle_group_assignment
     */
    async vehicleGroupAssignmentsReadVehicleGroupAssignmentRaw(requestParameters: VehicleGroupAssignmentsReadVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleGroupAssignment>> {
        if (requestParameters.vehicleGroupAssignmentId === null || requestParameters.vehicleGroupAssignmentId === undefined) {
            throw new runtime.RequiredError('vehicleGroupAssignmentId','Required parameter requestParameters.vehicleGroupAssignmentId was null or undefined when calling vehicleGroupAssignmentsReadVehicleGroupAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_group_assignments/{vehicle_group_assignment_id}`.replace(`{${"vehicle_group_assignment_id"}}`, encodeURIComponent(String(requestParameters.vehicleGroupAssignmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleGroupAssignmentFromJSON(jsonValue));
    }

    /**
     * Returns the vehicle_group_assignment specified by the given ID.
     * Get a single vehicle_group_assignment
     */
    async vehicleGroupAssignmentsReadVehicleGroupAssignment(requestParameters: VehicleGroupAssignmentsReadVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleGroupAssignment> {
        const response = await this.vehicleGroupAssignmentsReadVehicleGroupAssignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the vehicle_group_assignment specified by the given ID.
     * Update vehicle_group_assignment
     */
    async vehicleGroupAssignmentsUpdateVehicleGroupAssignmentRaw(requestParameters: VehicleGroupAssignmentsUpdateVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleGroupAssignment>> {
        if (requestParameters.vehicleGroupAssignmentId === null || requestParameters.vehicleGroupAssignmentId === undefined) {
            throw new runtime.RequiredError('vehicleGroupAssignmentId','Required parameter requestParameters.vehicleGroupAssignmentId was null or undefined when calling vehicleGroupAssignmentsUpdateVehicleGroupAssignment.');
        }

        if (requestParameters.vehicleGroupAssignmentUpdate === null || requestParameters.vehicleGroupAssignmentUpdate === undefined) {
            throw new runtime.RequiredError('vehicleGroupAssignmentUpdate','Required parameter requestParameters.vehicleGroupAssignmentUpdate was null or undefined when calling vehicleGroupAssignmentsUpdateVehicleGroupAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_group_assignments/{vehicle_group_assignment_id}`.replace(`{${"vehicle_group_assignment_id"}}`, encodeURIComponent(String(requestParameters.vehicleGroupAssignmentId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleGroupAssignmentUpdateToJSON(requestParameters.vehicleGroupAssignmentUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleGroupAssignmentFromJSON(jsonValue));
    }

    /**
     * Update the vehicle_group_assignment specified by the given ID.
     * Update vehicle_group_assignment
     */
    async vehicleGroupAssignmentsUpdateVehicleGroupAssignment(requestParameters: VehicleGroupAssignmentsUpdateVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleGroupAssignment> {
        const response = await this.vehicleGroupAssignmentsUpdateVehicleGroupAssignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new vehicle_group_assignment without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a vehicle_group_assignment
     */
    async vehicleGroupAssignmentsValidateCreateVehicleGroupAssignmentRaw(requestParameters: VehicleGroupAssignmentsValidateCreateVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.vehicleGroupAssignmentCreate === null || requestParameters.vehicleGroupAssignmentCreate === undefined) {
            throw new runtime.RequiredError('vehicleGroupAssignmentCreate','Required parameter requestParameters.vehicleGroupAssignmentCreate was null or undefined when calling vehicleGroupAssignmentsValidateCreateVehicleGroupAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_group_assignments/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleGroupAssignmentCreateToJSON(requestParameters.vehicleGroupAssignmentCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new vehicle_group_assignment without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a vehicle_group_assignment
     */
    async vehicleGroupAssignmentsValidateCreateVehicleGroupAssignment(requestParameters: VehicleGroupAssignmentsValidateCreateVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vehicleGroupAssignmentsValidateCreateVehicleGroupAssignmentRaw(requestParameters, initOverrides);
    }

    /**
     * Validate data for updating the given vehicle_group_assignment, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate vehicle_group_assignment update
     */
    async vehicleGroupAssignmentsValidateUpdateVehicleGroupAssignmentRaw(requestParameters: VehicleGroupAssignmentsValidateUpdateVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.vehicleGroupAssignmentId === null || requestParameters.vehicleGroupAssignmentId === undefined) {
            throw new runtime.RequiredError('vehicleGroupAssignmentId','Required parameter requestParameters.vehicleGroupAssignmentId was null or undefined when calling vehicleGroupAssignmentsValidateUpdateVehicleGroupAssignment.');
        }

        if (requestParameters.vehicleGroupAssignmentUpdate === null || requestParameters.vehicleGroupAssignmentUpdate === undefined) {
            throw new runtime.RequiredError('vehicleGroupAssignmentUpdate','Required parameter requestParameters.vehicleGroupAssignmentUpdate was null or undefined when calling vehicleGroupAssignmentsValidateUpdateVehicleGroupAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle_group_assignments/{vehicle_group_assignment_id}/validate`.replace(`{${"vehicle_group_assignment_id"}}`, encodeURIComponent(String(requestParameters.vehicleGroupAssignmentId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleGroupAssignmentUpdateToJSON(requestParameters.vehicleGroupAssignmentUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for updating the given vehicle_group_assignment, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate vehicle_group_assignment update
     */
    async vehicleGroupAssignmentsValidateUpdateVehicleGroupAssignment(requestParameters: VehicleGroupAssignmentsValidateUpdateVehicleGroupAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vehicleGroupAssignmentsValidateUpdateVehicleGroupAssignmentRaw(requestParameters, initOverrides);
    }

}
