import * as React from "react";
import { external, inject } from "tsdi";
import { ListCommandButtons, ListCommandButtonsProps } from "./list-command-buttons";
import { FormCreatePreOpsChecklist } from "../molecules/form-create-pre-ops-checklist";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryPreOpsChecklists } from "../../domain/repositories/repository-pre-ops-checklists";

export type ListCommandButtonsPreOpsChecklistsProps = Omit<
    ListCommandButtonsProps<RepositoryPreOpsChecklists>,
    "list" | "createForm" | "listState"
>;

@external
export class ListCommandButtonsPreOpsChecklists extends React.Component<ListCommandButtonsPreOpsChecklistsProps> {
    @inject protected readonly i18n!: I18nProvider;

    public render(): JSX.Element {
        return (
            <ListCommandButtons<RepositoryPreOpsChecklists>
                {...this.props}
                createButtonText={this.i18n.t("listPreOpsChecklist.button.add")}
                createDialogTitle={this.i18n.t("formCreatePreOpsChecklist.title")}
                createForm={FormCreatePreOpsChecklist}
                dialogMaxWidth="1000px"
            />
        );
    }
}
