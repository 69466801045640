/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FleetAuthenticateConnectionStatus } from './FleetAuthenticateConnectionStatus';
import {
    FleetAuthenticateConnectionStatusFromJSON,
    FleetAuthenticateConnectionStatusFromJSONTyped,
    FleetAuthenticateConnectionStatusToJSON,
} from './FleetAuthenticateConnectionStatus';
import type { FleetAuthenticateLogoutType } from './FleetAuthenticateLogoutType';
import {
    FleetAuthenticateLogoutTypeFromJSON,
    FleetAuthenticateLogoutTypeFromJSONTyped,
    FleetAuthenticateLogoutTypeToJSON,
} from './FleetAuthenticateLogoutType';
import type { FleetAuthenticateType } from './FleetAuthenticateType';
import {
    FleetAuthenticateTypeFromJSON,
    FleetAuthenticateTypeFromJSONTyped,
    FleetAuthenticateTypeToJSON,
} from './FleetAuthenticateType';

/**
 * 
 * @export
 * @interface FleetAuthenticateEventCreate
 */
export interface FleetAuthenticateEventCreate {
    /**
     * 
     * @type {string}
     * @memberof FleetAuthenticateEventCreate
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof FleetAuthenticateEventCreate
     */
    time: Date;
    /**
     * 
     * @type {string}
     * @memberof FleetAuthenticateEventCreate
     */
    periodId: string;
    /**
     * 
     * @type {string}
     * @memberof FleetAuthenticateEventCreate
     */
    vehicleId: string;
    /**
     * 
     * @type {string}
     * @memberof FleetAuthenticateEventCreate
     */
    userId: string;
    /**
     * 
     * @type {FleetAuthenticateType}
     * @memberof FleetAuthenticateEventCreate
     */
    authenticateType: FleetAuthenticateType;
    /**
     * 
     * @type {FleetAuthenticateConnectionStatus}
     * @memberof FleetAuthenticateEventCreate
     */
    connectionStatus?: FleetAuthenticateConnectionStatus | null;
    /**
     * 
     * @type {FleetAuthenticateLogoutType}
     * @memberof FleetAuthenticateEventCreate
     */
    logoutType?: FleetAuthenticateLogoutType | null;
}

/**
 * Check if a given object implements the FleetAuthenticateEventCreate interface.
 */
export function instanceOfFleetAuthenticateEventCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "periodId" in value;
    isInstance = isInstance && "vehicleId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "authenticateType" in value;

    return isInstance;
}

export function FleetAuthenticateEventCreateFromJSON(json: any): FleetAuthenticateEventCreate {
    return FleetAuthenticateEventCreateFromJSONTyped(json, false);
}

export function FleetAuthenticateEventCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FleetAuthenticateEventCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'time': (new Date(json['time'])),
        'periodId': json['periodId'],
        'vehicleId': json['vehicleId'],
        'userId': json['userId'],
        'authenticateType': FleetAuthenticateTypeFromJSON(json['authenticateType']),
        'connectionStatus': !exists(json, 'connectionStatus') ? undefined : FleetAuthenticateConnectionStatusFromJSON(json['connectionStatus']),
        'logoutType': !exists(json, 'logoutType') ? undefined : FleetAuthenticateLogoutTypeFromJSON(json['logoutType']),
    };
}

export function FleetAuthenticateEventCreateToJSON(value?: FleetAuthenticateEventCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'time': (value.time.toISOString()),
        'periodId': value.periodId,
        'vehicleId': value.vehicleId,
        'userId': value.userId,
        'authenticateType': FleetAuthenticateTypeToJSON(value.authenticateType),
        'connectionStatus': FleetAuthenticateConnectionStatusToJSON(value.connectionStatus),
        'logoutType': FleetAuthenticateLogoutTypeToJSON(value.logoutType),
    };
}

