/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PageInfo,
  SafetyMessage,
  SafetyMessageCreate,
  SafetyMessageSortKey,
  SafetyMessageUpdate,
  SortDirection,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    SafetyMessageFromJSON,
    SafetyMessageToJSON,
    SafetyMessageCreateFromJSON,
    SafetyMessageCreateToJSON,
    SafetyMessageSortKeyFromJSON,
    SafetyMessageSortKeyToJSON,
    SafetyMessageUpdateFromJSON,
    SafetyMessageUpdateToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface SafetyMessagesCreateSafetyMessageRequest {
    safetyMessageCreate: SafetyMessageCreate;
}

export interface SafetyMessagesDeleteSafetyMessageRequest {
    safetyMessageId: string;
}

export interface SafetyMessagesListSafetyMessagesRequest {
    pageSize: number;
    search?: string | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: SafetyMessageSortKey;
}

export interface SafetyMessagesListSafetyMessagesMetadataRequest {
    pageSize: number;
    search?: string | null;
}

export interface SafetyMessagesReadSafetyMessageRequest {
    safetyMessageId: string;
}

export interface SafetyMessagesUpdateSafetyMessageRequest {
    safetyMessageId: string;
    safetyMessageUpdate: SafetyMessageUpdate;
}

export interface SafetyMessagesValidateCreateSafetyMessageRequest {
    safetyMessageCreate: SafetyMessageCreate;
}

export interface SafetyMessagesValidateUpdateSafetyMessageRequest {
    safetyMessageId: string;
    safetyMessageUpdate: SafetyMessageUpdate;
}

/**
 * SafetyMessagesApi - interface
 * 
 * @export
 * @interface SafetyMessagesApiInterface
 */
export interface SafetyMessagesApiInterface {
    /**
     * Create a new safety message.
     * @summary Create a safety message
     * @param {SafetyMessageCreate} safetyMessageCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafetyMessagesApiInterface
     */
    safetyMessagesCreateSafetyMessageRaw(requestParameters: SafetyMessagesCreateSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SafetyMessage>>;

    /**
     * Create a new safety message.
     * Create a safety message
     */
    safetyMessagesCreateSafetyMessage(requestParameters: SafetyMessagesCreateSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SafetyMessage>;

    /**
     * Delete the safety_message with the given ID.
     * @summary Delete a safety_message
     * @param {string} safetyMessageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafetyMessagesApiInterface
     */
    safetyMessagesDeleteSafetyMessageRaw(requestParameters: SafetyMessagesDeleteSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the safety_message with the given ID.
     * Delete a safety_message
     */
    safetyMessagesDeleteSafetyMessage(requestParameters: SafetyMessagesDeleteSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Lists all the safety messages present. If `search` is specified, return values similar to the `body` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * @summary List safety messages
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {SafetyMessageSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafetyMessagesApiInterface
     */
    safetyMessagesListSafetyMessagesRaw(requestParameters: SafetyMessagesListSafetyMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SafetyMessage>>>;

    /**
     * Lists all the safety messages present. If `search` is specified, return values similar to the `body` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List safety messages
     */
    safetyMessagesListSafetyMessages(requestParameters: SafetyMessagesListSafetyMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SafetyMessage>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary Safety message listing metadata
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafetyMessagesApiInterface
     */
    safetyMessagesListSafetyMessagesMetadataRaw(requestParameters: SafetyMessagesListSafetyMessagesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * Safety message listing metadata
     */
    safetyMessagesListSafetyMessagesMetadata(requestParameters: SafetyMessagesListSafetyMessagesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * There\'s guaranteed to always only be a single active message at a time.  Returns a 404 if no message is active at the moment.
     * @summary Get a the current active safety message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafetyMessagesApiInterface
     */
    safetyMessagesReadActiveSafetyMessageRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SafetyMessage>>;

    /**
     * There\'s guaranteed to always only be a single active message at a time.  Returns a 404 if no message is active at the moment.
     * Get a the current active safety message
     */
    safetyMessagesReadActiveSafetyMessage(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SafetyMessage>;

    /**
     * Returns the safety message specified by the given ID.
     * @summary Get a single safety message
     * @param {string} safetyMessageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafetyMessagesApiInterface
     */
    safetyMessagesReadSafetyMessageRaw(requestParameters: SafetyMessagesReadSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SafetyMessage>>;

    /**
     * Returns the safety message specified by the given ID.
     * Get a single safety message
     */
    safetyMessagesReadSafetyMessage(requestParameters: SafetyMessagesReadSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SafetyMessage>;

    /**
     * Update the safety message specified by the given ID.
     * @summary Update a safety message
     * @param {string} safetyMessageId 
     * @param {SafetyMessageUpdate} safetyMessageUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafetyMessagesApiInterface
     */
    safetyMessagesUpdateSafetyMessageRaw(requestParameters: SafetyMessagesUpdateSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SafetyMessage>>;

    /**
     * Update the safety message specified by the given ID.
     * Update a safety message
     */
    safetyMessagesUpdateSafetyMessage(requestParameters: SafetyMessagesUpdateSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SafetyMessage>;

    /**
     * Validate data for a new safety message without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a safety message
     * @param {SafetyMessageCreate} safetyMessageCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafetyMessagesApiInterface
     */
    safetyMessagesValidateCreateSafetyMessageRaw(requestParameters: SafetyMessagesValidateCreateSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new safety message without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a safety message
     */
    safetyMessagesValidateCreateSafetyMessage(requestParameters: SafetyMessagesValidateCreateSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate data for updating the given safety message, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate safety message update
     * @param {string} safetyMessageId 
     * @param {SafetyMessageUpdate} safetyMessageUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafetyMessagesApiInterface
     */
    safetyMessagesValidateUpdateSafetyMessageRaw(requestParameters: SafetyMessagesValidateUpdateSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for updating the given safety message, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate safety message update
     */
    safetyMessagesValidateUpdateSafetyMessage(requestParameters: SafetyMessagesValidateUpdateSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class SafetyMessagesApi extends runtime.BaseAPI implements SafetyMessagesApiInterface {

    /**
     * Create a new safety message.
     * Create a safety message
     */
    async safetyMessagesCreateSafetyMessageRaw(requestParameters: SafetyMessagesCreateSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SafetyMessage>> {
        if (requestParameters.safetyMessageCreate === null || requestParameters.safetyMessageCreate === undefined) {
            throw new runtime.RequiredError('safetyMessageCreate','Required parameter requestParameters.safetyMessageCreate was null or undefined when calling safetyMessagesCreateSafetyMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/safety_messages/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SafetyMessageCreateToJSON(requestParameters.safetyMessageCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SafetyMessageFromJSON(jsonValue));
    }

    /**
     * Create a new safety message.
     * Create a safety message
     */
    async safetyMessagesCreateSafetyMessage(requestParameters: SafetyMessagesCreateSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SafetyMessage> {
        const response = await this.safetyMessagesCreateSafetyMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the safety_message with the given ID.
     * Delete a safety_message
     */
    async safetyMessagesDeleteSafetyMessageRaw(requestParameters: SafetyMessagesDeleteSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.safetyMessageId === null || requestParameters.safetyMessageId === undefined) {
            throw new runtime.RequiredError('safetyMessageId','Required parameter requestParameters.safetyMessageId was null or undefined when calling safetyMessagesDeleteSafetyMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/safety_messages/{safety_message_id}`.replace(`{${"safety_message_id"}}`, encodeURIComponent(String(requestParameters.safetyMessageId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the safety_message with the given ID.
     * Delete a safety_message
     */
    async safetyMessagesDeleteSafetyMessage(requestParameters: SafetyMessagesDeleteSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.safetyMessagesDeleteSafetyMessageRaw(requestParameters, initOverrides);
    }

    /**
     * Lists all the safety messages present. If `search` is specified, return values similar to the `body` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List safety messages
     */
    async safetyMessagesListSafetyMessagesRaw(requestParameters: SafetyMessagesListSafetyMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SafetyMessage>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling safetyMessagesListSafetyMessages.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/safety_messages/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SafetyMessageFromJSON));
    }

    /**
     * Lists all the safety messages present. If `search` is specified, return values similar to the `body` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List safety messages
     */
    async safetyMessagesListSafetyMessages(requestParameters: SafetyMessagesListSafetyMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SafetyMessage>> {
        const response = await this.safetyMessagesListSafetyMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * Safety message listing metadata
     */
    async safetyMessagesListSafetyMessagesMetadataRaw(requestParameters: SafetyMessagesListSafetyMessagesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling safetyMessagesListSafetyMessagesMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/safety_messages/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * Safety message listing metadata
     */
    async safetyMessagesListSafetyMessagesMetadata(requestParameters: SafetyMessagesListSafetyMessagesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.safetyMessagesListSafetyMessagesMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * There\'s guaranteed to always only be a single active message at a time.  Returns a 404 if no message is active at the moment.
     * Get a the current active safety message
     */
    async safetyMessagesReadActiveSafetyMessageRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SafetyMessage>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/safety_messages/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SafetyMessageFromJSON(jsonValue));
    }

    /**
     * There\'s guaranteed to always only be a single active message at a time.  Returns a 404 if no message is active at the moment.
     * Get a the current active safety message
     */
    async safetyMessagesReadActiveSafetyMessage(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SafetyMessage> {
        const response = await this.safetyMessagesReadActiveSafetyMessageRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns the safety message specified by the given ID.
     * Get a single safety message
     */
    async safetyMessagesReadSafetyMessageRaw(requestParameters: SafetyMessagesReadSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SafetyMessage>> {
        if (requestParameters.safetyMessageId === null || requestParameters.safetyMessageId === undefined) {
            throw new runtime.RequiredError('safetyMessageId','Required parameter requestParameters.safetyMessageId was null or undefined when calling safetyMessagesReadSafetyMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/safety_messages/{safety_message_id}`.replace(`{${"safety_message_id"}}`, encodeURIComponent(String(requestParameters.safetyMessageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SafetyMessageFromJSON(jsonValue));
    }

    /**
     * Returns the safety message specified by the given ID.
     * Get a single safety message
     */
    async safetyMessagesReadSafetyMessage(requestParameters: SafetyMessagesReadSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SafetyMessage> {
        const response = await this.safetyMessagesReadSafetyMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the safety message specified by the given ID.
     * Update a safety message
     */
    async safetyMessagesUpdateSafetyMessageRaw(requestParameters: SafetyMessagesUpdateSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SafetyMessage>> {
        if (requestParameters.safetyMessageId === null || requestParameters.safetyMessageId === undefined) {
            throw new runtime.RequiredError('safetyMessageId','Required parameter requestParameters.safetyMessageId was null or undefined when calling safetyMessagesUpdateSafetyMessage.');
        }

        if (requestParameters.safetyMessageUpdate === null || requestParameters.safetyMessageUpdate === undefined) {
            throw new runtime.RequiredError('safetyMessageUpdate','Required parameter requestParameters.safetyMessageUpdate was null or undefined when calling safetyMessagesUpdateSafetyMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/safety_messages/{safety_message_id}`.replace(`{${"safety_message_id"}}`, encodeURIComponent(String(requestParameters.safetyMessageId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SafetyMessageUpdateToJSON(requestParameters.safetyMessageUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SafetyMessageFromJSON(jsonValue));
    }

    /**
     * Update the safety message specified by the given ID.
     * Update a safety message
     */
    async safetyMessagesUpdateSafetyMessage(requestParameters: SafetyMessagesUpdateSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SafetyMessage> {
        const response = await this.safetyMessagesUpdateSafetyMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new safety message without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a safety message
     */
    async safetyMessagesValidateCreateSafetyMessageRaw(requestParameters: SafetyMessagesValidateCreateSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.safetyMessageCreate === null || requestParameters.safetyMessageCreate === undefined) {
            throw new runtime.RequiredError('safetyMessageCreate','Required parameter requestParameters.safetyMessageCreate was null or undefined when calling safetyMessagesValidateCreateSafetyMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/safety_messages/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SafetyMessageCreateToJSON(requestParameters.safetyMessageCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new safety message without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a safety message
     */
    async safetyMessagesValidateCreateSafetyMessage(requestParameters: SafetyMessagesValidateCreateSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.safetyMessagesValidateCreateSafetyMessageRaw(requestParameters, initOverrides);
    }

    /**
     * Validate data for updating the given safety message, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate safety message update
     */
    async safetyMessagesValidateUpdateSafetyMessageRaw(requestParameters: SafetyMessagesValidateUpdateSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.safetyMessageId === null || requestParameters.safetyMessageId === undefined) {
            throw new runtime.RequiredError('safetyMessageId','Required parameter requestParameters.safetyMessageId was null or undefined when calling safetyMessagesValidateUpdateSafetyMessage.');
        }

        if (requestParameters.safetyMessageUpdate === null || requestParameters.safetyMessageUpdate === undefined) {
            throw new runtime.RequiredError('safetyMessageUpdate','Required parameter requestParameters.safetyMessageUpdate was null or undefined when calling safetyMessagesValidateUpdateSafetyMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/safety_messages/{safety_message_id}/validate`.replace(`{${"safety_message_id"}}`, encodeURIComponent(String(requestParameters.safetyMessageId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SafetyMessageUpdateToJSON(requestParameters.safetyMessageUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for updating the given safety message, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate safety message update
     */
    async safetyMessagesValidateUpdateSafetyMessage(requestParameters: SafetyMessagesValidateUpdateSafetyMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.safetyMessagesValidateUpdateSafetyMessageRaw(requestParameters, initOverrides);
    }

}
