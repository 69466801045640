import * as React from "react";
import { Selection, Stack } from "@fluentui/react";
import { omit } from "ramda";
import { IColumn } from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { createUuid, UUID } from "../../utils/uuid";
import {
    ColumnVisibility,
    RoleGroups,
    User,
    UsersExportUserListListType,
    UserSortKey,
} from "../../api";
import { observer } from "mobx-react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import {
    RepositoryUsers,
    UsersApiResource,
    UsersQuery,
} from "../../domain/repositories/repository-users";
import { ListUserItemCell } from "../atoms/list-user-item-cell";
import { SortState } from "../../utils/sort-state";
import { PaginationState } from "../../utils/pagination-state";
import { LayoutList } from "../layouts/layout-list";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import { SelectionState } from "../../utils/selection-state";
import { RepositoryColumnVisibility } from "../../domain/repositories/repository-column-visibility";
import { ListSearchField } from "../atoms/list-search-field";
import { SearchState } from "../../utils/search-state";
import { FilterUserList, FilterUserListQuery } from "../organisms/filter-user-list";
import { ExportButton, ExportQuery } from "./export-button";
import { defaultPageSize } from "../../utils/constants";

export enum ListUsersLayout {
    Default,
    Group,
}

export interface ListUsersProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly commandBar?: JSX.Element;
    readonly roleGroup?: RoleGroups;
    readonly ignoreColumnVisibility?: boolean;
    readonly listStateId?: UUID;
    readonly userGroupIds?: UUID[];
    readonly columnLayout?: ListUsersLayout;
    readonly ignoreUrl?: boolean;
    readonly hideFilter?: boolean;
}

interface ListUserItem {
    readonly key: UUID;
}

@external
@observer
export class ListUsers extends React.Component<ListUsersProps> {
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly repositoryColumnVisibility!: RepositoryColumnVisibility;
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;

    private _listStateId = createUuid();
    private selection = new Selection({
        onSelectionChanged: () => this.updateSelection(),
    });

    @observable private filterUserListQuery: FilterUserListQuery = {
        filteredDepartments: [],
        filteredFleetRoles: [],
        filteredUserGroups: [],
        filteredShifts: [],
        isFiltered: false,
    };

    constructor(props: ListUsersProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.users.initializeList(this.listStateId, {
            query: () => this.query,
            ignoreUrl: this.props.ignoreUrl,
        });
    }

    @computed private get columnVisibility(): ColumnVisibility {
        return this.repositoryColumnVisibility.byQuery()[0];
    }

    @computed private get listStateId(): UUID {
        return this.props.listStateId ?? this._listStateId;
    }

    @computed private get sortState(): SortState<UserSortKey> {
        return this.serviceListStates.users.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<UsersApiResource> {
        return this.serviceListStates.users.getPaginationState(this.listStateId);
    }

    @computed private get selectionState(): SelectionState {
        return this.serviceListStates.users.getSelectionState(this.listStateId);
    }

    @computed private get searchState(): SearchState {
        return this.serviceListStates.users.getSearchState(this.listStateId);
    }

    @computed private get query(): UsersQuery {
        const query = {
            pageSize: defaultPageSize,
            roleGroup: this.props.roleGroup,
            userGroupIds: this.props.userGroupIds ?? [],
            ...this.sortState.query,
            ...this.searchState.query,
        } as UsersQuery;
        if (this.filterUserListQuery === undefined) {
            return query;
        }
        if (this.filterUserListQuery.filteredDepartments.length > 0) {
            query.departmentIds = this.filterUserListQuery.filteredDepartments;
        }
        if (this.filterUserListQuery.filteredFleetRoles.length > 0) {
            query.fleetRoles = this.filterUserListQuery.filteredFleetRoles;
        }
        if (this.filterUserListQuery.filteredUserGroups.length > 0) {
            query.userGroupIds = this.filterUserListQuery.filteredUserGroups;
        }
        if (this.filterUserListQuery.filteredShifts.length > 0) {
            query.shiftIds = this.filterUserListQuery.filteredShifts;
        }
        return query;
    }

    @computed private get exportQuery(): ExportQuery {
        const query = Object.entries({
            list_type: UsersExportUserListListType.OPERATORS,
            ...this.query,
        });
        return { query, basePath: "/api/v1/users/export?" };
    }

    @computed private get users(): (User | undefined)[] {
        if (this.props.ids) {
            return this.props.ids.map((id) => this.repositoryUsers.byId(id));
        }
        return (
            this.repositoryUsers
                .byQuery(this.query, this.paginationState.pagination)
                // Remove the super admin as it shouldn't be visible to end users
                // We can't do this in the repository itself as it would break
                // pagination logic
                .filter((user) => user.email !== "admin@elofleet.com")
        );
    }

    @computed private get defaultLayoutColumns(): IColumn[] {
        const columns: IColumn[] = [];

        // Base columns that're shown in any mode.
        if (this.props.ignoreColumnVisibility || this.columnVisibility?.userList.userImage)
            columns.push({
                fieldName: "imageUri",
                name: this.i18n.t("listUsers.column.image.name"),
                key: "imageUri",
                minWidth: 100,
                maxWidth: 100,
            });

        if (this.props.ignoreColumnVisibility || this.columnVisibility?.userList.userEmployeeId)
            columns.push({
                name: this.i18n.t("listUsers.column.employeeId.name"),
                key: "employeeId",
                minWidth: 100,
                maxWidth: 200,
            });

        if (this.props.ignoreColumnVisibility || this.columnVisibility?.userList.userLastName)
            columns.push({
                name: this.i18n.t("listUsers.column.lastName.name"),
                key: "lastName",
                minWidth: 100,
                maxWidth: 200,
            });

        if (this.props.ignoreColumnVisibility || this.columnVisibility?.userList.userFirstName)
            columns.push({
                name: this.i18n.t("listUsers.column.firstName.name"),
                key: "firstName",
                minWidth: 100,
                maxWidth: 200,
            });

        if (this.props.ignoreColumnVisibility || this.columnVisibility?.userList.userJobTitle)
            columns.push({
                name: this.i18n.t("listUsers.column.jobTitle.name"),
                key: "jobTitle",
                minWidth: 100,
                maxWidth: 200,
            });

        if (this.props.roleGroup === RoleGroups.FLEET_USERS) {
            if (this.columnVisibility?.userList.userNfcTokenDescription)
                columns.push({
                    name: this.i18n.t("listUsers.column.nfcTokenDescription.name"),
                    key: "nfcTokenDescription",
                    minWidth: 100,
                    maxWidth: 200,
                });

            if (this.columnVisibility?.userList.userNfcToken)
                columns.push({
                    name: this.i18n.t("listUsers.column.nfcToken.name"),
                    key: "nfcToken",
                    minWidth: 120,
                    maxWidth: 200,
                });

            if (this.props.ignoreColumnVisibility || this.columnVisibility?.userList.userFleetRole)
                columns.push({
                    name: this.i18n.t("listUsers.column.fleetRole.name"),
                    key: "fleetRole",
                    minWidth: 120,
                    maxWidth: 200,
                });

            if (
                this.props.ignoreColumnVisibility ||
                this.columnVisibility?.userList.departmentLabel
            )
                columns.push({
                    name: this.i18n.t("listUsers.column.department.name"),
                    key: "department",
                    minWidth: 100,
                    maxWidth: 200,
                });

            if (this.props.ignoreColumnVisibility || this.columnVisibility?.userList.shiftLabel)
                columns.push({
                    name: this.i18n.t("listUsers.column.shift.name"),
                    key: "shift",
                    minWidth: 100,
                    maxWidth: 200,
                });

            if (this.props.ignoreColumnVisibility || this.columnVisibility?.userList.siteLabel)
                columns.push({
                    name: this.i18n.t("listUsers.column.site.name"),
                    key: "site",
                    minWidth: 100,
                    maxWidth: 200,
                });

            if (
                this.props.ignoreColumnVisibility ||
                this.columnVisibility?.userList.userLicenseExpiryDate
            )
                columns.push({
                    name: this.i18n.t("listUsers.column.licenseExpiry.date.name"),
                    key: "licenseExpiryDate",
                    minWidth: 100,
                    maxWidth: 200,
                });

            if (
                this.props.ignoreColumnVisibility ||
                this.columnVisibility?.userList.userExpiryLogout
            )
                columns.push({
                    name: this.i18n.t("listUsers.column.expiryLogout.name"),
                    key: "expiryLogout",
                    minWidth: 100,
                    maxWidth: 200,
                });
        } else {
            if (
                this.props.ignoreColumnVisibility ||
                this.columnVisibility?.userList.departmentLabel
            )
                columns.push({
                    name: this.i18n.t("listUsers.column.department.name"),
                    key: "department",
                    minWidth: 100,
                    maxWidth: 200,
                });

            if (this.props.ignoreColumnVisibility || this.columnVisibility?.userList.userFleetRole)
                columns.push({
                    name: this.i18n.t("listUsers.column.fleetRole.name"),
                    key: "fleetRole",
                    minWidth: 120,
                    maxWidth: 200,
                });

            if (this.props.ignoreColumnVisibility || this.columnVisibility?.userList.shiftLabel)
                columns.push({
                    name: this.i18n.t("listUsers.column.managementRole.name"),
                    key: "managementRole",
                    minWidth: 100,
                    maxWidth: 200,
                });
        }

        return columns;
    }

    @computed private get groupLayoutColumns(): IColumn[] {
        const columns: IColumn[] = [];

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.userGroupDetails.userEmployeeId
        )
            columns.push({
                name: this.i18n.t("listUsers.column.employeeId.name"),
                key: "employeeId",
                minWidth: 100,
                maxWidth: 200,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.userGroupDetails.userLastName
        )
            columns.push({
                name: this.i18n.t("listUsers.column.lastName.name"),
                key: "lastName",
                minWidth: 100,
                maxWidth: 200,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.userGroupDetails.userFirstName
        )
            columns.push({
                name: this.i18n.t("listUsers.column.firstName.name"),
                key: "firstName",
                minWidth: 100,
                maxWidth: 200,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.userGroupDetails.userJobTitle
        )
            columns.push({
                name: this.i18n.t("listUsers.column.jobTitle.name"),
                key: "jobTitle",
                minWidth: 100,
                maxWidth: 200,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.userGroupDetails.userFleetRole
        )
            columns.push({
                name: this.i18n.t("listUsers.column.fleetRole.name"),
                key: "fleetRole",
                minWidth: 120,
                maxWidth: 200,
            });

        return columns;
    }

    @computed private get columns(): IColumn[] {
        switch (this.props.columnLayout) {
            case ListUsersLayout.Group:
                return this.groupLayoutColumns;
            default:
                return this.defaultLayoutColumns;
        }
    }

    @computed private get items(): (ListUserItem | undefined)[] {
        return this.users.map((user) => {
            if (!user) {
                return;
            }
            return {
                key: user.id,
            };
        });
    }

    @action.bound private updateSelection(): void {
        // Update the listState's selectionState to the new selected keys.
        this.selectionState.updateSelection(
            this.selection.getSelection().map((item) => item.key as UUID),
        );
    }

    private renderItem(item: ListUserItem, userId: UUID, column: IColumn): JSX.Element {
        return <ListUserItemCell userId={userId} column={column} />;
    }

    @action.bound private setUserListFilter(filterQuery: FilterUserListQuery): void {
        this.filterUserListQuery = {
            filteredDepartments: filterQuery.filteredDepartments,
            filteredFleetRoles: filterQuery.filteredFleetRoles,
            filteredUserGroups: filterQuery.filteredUserGroups,
            filteredShifts: filterQuery.filteredShifts,
            isFiltered: filterQuery.isFiltered,
        };
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.users.isInitialized(this.listStateId)) {
            return <></>;
        }

        let filter = <FilterUserList setUserListFilter={this.setUserListFilter} />;
        if (this.props?.hideFilter) {
            filter = <></>;
        }

        let searchField = (
            <ListSearchField
                placeholder={this.i18n.t("listUsers.search.placeholder")}
                listStateId={this.listStateId}
                listState={this.serviceListStates.users}
            />
        );
        if (this.props?.hideFilter) {
            searchField = <></>;
        }

        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(["ids"], this.props);
        return (
            <LayoutList
                paginationState={this.paginationState}
                commandBar={
                    <Stack horizontal horizontalAlign="space-between">
                        {this.props.commandBar}
                        <ExportButton query={this.exportQuery} />
                    </Stack>
                }
                searchField={searchField}
                filter={filter}
            >
                <ElofleetList
                    {...props}
                    selection={this.selection}
                    onRenderItemColumn={(item: ListUserItem, _index, column) =>
                        this.renderItem(item, item.key, column!)
                    }
                    onColumnHeaderClick={this.sortState.toggleColumn}
                    columns={this.sortState.patchColumns(this.columns)}
                    items={this.items}
                />
            </LayoutList>
        );
    }
}
