/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DigitalInput {
    INPUT1 = 'input1',
    INPUT2 = 'input2'
}


export function DigitalInputFromJSON(json: any): DigitalInput {
    return DigitalInputFromJSONTyped(json, false);
}

export function DigitalInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigitalInput {
    return json as DigitalInput;
}

export function DigitalInputToJSON(value?: DigitalInput | null): any {
    return value as any;
}

