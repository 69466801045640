import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { declareRoute, routePath, RouteProps } from "../routes";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { PageHeader } from "../ui/atoms/page-header";
import { PageContent } from "../ui/atoms/page-content";
import { Section } from "../ui/atoms/section";
import { FilterByDateRange } from "../ui/organisms/filter-by-date-range";
import { SelectionMode } from "@fluentui/react";
import { ListZoneEvents } from "../ui/molecules/list-zone-events";

@external
export class PageZoningActivity extends React.Component<RouteProps> {
    @inject protected readonly i18n!: I18nProvider;

    public render(): JSX.Element {
        return (
            <LayoutDefault header={<PageHeader title={this.i18n.t("page.zoningActivity.title")} />}>
                <PageContent>
                    <Section>
                        <ListZoneEvents
                            commandBar={<FilterByDateRange onDateRangeChanged={() => {}} />}
                            selectionMode={SelectionMode.none}
                        />
                    </Section>
                </PageContent>
            </LayoutDefault>
        );
    }
}

export const routeZoningActivity = declareRoute({
    navigation: true,
    component: PageZoningActivity,
    icon: "Location",
    title: "page.zoningActivity.navbarEntry",
    path: routePath.zoningActivity,
    pattern: "/zoning-activity",
});
