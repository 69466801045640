import { FontIcon, IconButton, Modal, Stack, StackItem } from "@fluentui/react";
import * as React from "react";
import { external } from "tsdi";
import colors from "../colors.scss";
import sizes from "../sizes.scss";

export interface ElofleetDialogProps {
    readonly isOpen: boolean;
    readonly title?: string;
    readonly children: JSX.Element | JSX.Element[];
    readonly notBlocking?: boolean;
    readonly maxWidth?: string;
    readonly maxHeight?: string;
    readonly icon?: string;
    readonly onDismiss?: () => void;
    readonly onDismissed?: () => void;
}

@external
export class ElofleetDialog extends React.Component<ElofleetDialogProps> {
    public render(): JSX.Element {
        return (
            <Modal {...this.props} isBlocking={!this.props.notBlocking}>
                <Stack
                    tokens={{
                        childrenGap: "1em",
                        maxWidth: this.props.maxWidth,
                    }}
                    // Set the max height manually here to avoid double scrollbar issues
                    style={{ maxHeight: "90vh" }}
                >
                    <Stack
                        horizontal
                        tokens={{ childrenGap: "1em", padding: `0px ${sizes.xl}` }}
                        style={{
                            backgroundColor: colors["secondarydialog-header-background"],
                            borderBottom: `1px solid ${colors["secondarydialog-header-border"]}`,
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        {this.props.icon && (
                            <StackItem>
                                <FontIcon
                                    aria-label={this.props.icon}
                                    iconName={this.props.icon}
                                    style={{ fontSize: "xx-large" }}
                                />
                            </StackItem>
                        )}
                        <StackItem>
                            <h2 style={{ margin: `${sizes.m} 0px` }}>{this.props.title}</h2>
                        </StackItem>
                        {this.props.onDismiss && (
                            <StackItem>
                                <IconButton
                                    iconProps={{ iconName: "Cancel" }}
                                    onClick={this.props.onDismiss}
                                />
                            </StackItem>
                        )}
                    </Stack>

                    <Stack verticalAlign="space-between">{this.props.children}</Stack>
                </Stack>
            </Modal>
        );
    }
}
