import * as React from "react";
import { observer } from "mobx-react";
import { Stack, DefaultButton, TextField } from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { createUuid } from "../../utils/uuid";
import { ShockProfile, ShockProfileCreate } from "../../api";
import { FormCreateProps } from "../../utils/form-create-props";
import { doubleBindString } from "../../utils/double-bind";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { RepositoryShockProfiles } from "../../domain/repositories/repository-shock-profiles";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";
import { FloatInput } from "../atoms/float-input";
import { GtomG, mGtoG } from "../../utils/unit-conversion";

export interface FormCreateShockProfileProps extends FormCreateProps<ShockProfile> {}

@observer
@external
export class FormCreateShockProfile extends React.Component<FormCreateShockProfileProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryShockProfiles!: RepositoryShockProfiles;

    @observable public light?: number;
    @observable public medium?: number;
    @observable public severe?: number;
    @observable public duration?: number;
    @observable public label = "";

    private validationId = createUuid();

    constructor(props: FormCreateShockProfileProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositoryShockProfiles.validation.create.updateModel(
            this.validationId,
            this.shockProfileCreate,
        );
    }

    @initialize protected initialize(): void {
        this.repositoryShockProfiles.validation.create.initializeModel(
            this.validationId,
            this.shockProfileCreate,
        );
    }

    @computed private get shockProfileCreate(): ShockProfileCreate {
        const { light, medium, severe, duration, label } = this;

        return {
            light: GtomG(light) ?? 0,
            medium: GtomG(medium) ?? 0,
            severe: GtomG(severe) ?? 0,
            duration: duration ?? 0,
            label,
        };
    }

    @action.bound
    private async createShockProfile(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const { shockProfileCreate } = this;
        const requiredFields = ["light", "medium", "severe", "duration"] as const;
        if (requiredFields.some((key) => shockProfileCreate[key] === undefined)) {
            return;
        }
        const shockProfile = await this.repositoryShockProfiles.create(shockProfileCreate);
        this.reset();
        if (this.props.onCreate) {
            this.props.onCreate(shockProfile);
        }
    }

    @action.bound private reset(): void {
        this.light = undefined;
        this.medium = undefined;
        this.severe = undefined;
        this.duration = undefined;
        this.label = "";
        this.triggerValidation();
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formCreateShockProfile.submit.text")}
                validation={this.repositoryShockProfiles.validation.create}
                validationId={this.validationId}
            />
        );

        return (
            <form onSubmit={this.createShockProfile}>
                <Stack tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                    <TextField
                        label={this.i18n.t("formCreateShockProfile.label.label")}
                        {...doubleBindString<FormCreateShockProfile>(this, "label", () =>
                            this.triggerValidation(),
                        )}
                        required
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryShockProfiles.validation.create.getFieldValidationState(
                                this.validationId,
                                "label",
                            ),
                        )}
                    />
                    <FloatInput
                        required
                        label={this.i18n.t("formCreateShockProfile.light.label")}
                        precision={3}
                        onChange={(val) => {
                            this.light = val;
                            this.triggerValidation();
                        }}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryShockProfiles.validation.create.getFieldValidationState(
                                this.validationId,
                                "light",
                            ),
                            (p) => this.i18n.formatFloat(mGtoG(String(p)), 3),
                        )}
                    />
                    <FloatInput
                        required
                        label={this.i18n.t("formCreateShockProfile.medium.label")}
                        precision={3}
                        onChange={(val) => {
                            this.medium = val;
                            this.triggerValidation();
                        }}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryShockProfiles.validation.create.getFieldValidationState(
                                this.validationId,
                                "medium",
                            ),
                            (p) => this.i18n.formatFloat(mGtoG(String(p)), 3),
                        )}
                    />
                    <FloatInput
                        required
                        label={this.i18n.t("formCreateShockProfile.severe.label")}
                        precision={3}
                        onChange={(val) => {
                            this.severe = val;
                            this.triggerValidation();
                        }}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryShockProfiles.validation.create.getFieldValidationState(
                                this.validationId,
                                "severe",
                            ),
                            (p) => this.i18n.formatFloat(mGtoG(String(p)), 3),
                        )}
                    />
                    <FloatInput
                        required
                        label={this.i18n.t("formCreateShockProfile.duration.label")}
                        precision={0}
                        onChange={(val) => {
                            this.duration = val;
                            this.triggerValidation();
                        }}
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryShockProfiles.validation.create.getFieldValidationState(
                                this.validationId,
                                "duration",
                            ),
                            (p) => p,
                        )}
                    />
                </Stack>
                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            label={this.i18n.t("formCreateShockProfile.cancel.label")}
                            text={this.i18n.t("formCreateShockProfile.cancel.text")}
                            onClick={this.props.onDialogCancel}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
            </form>
        );
    }
}
