import * as React from "react";
import { observer } from "mobx-react";
import { action } from "mobx";
import { ComboBox, IComboBoxOption, IComboBoxProps, ISelectableOption } from "@fluentui/react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryPublicSettings } from "../../domain/repositories/repository-public-settings";
import { DateFormat } from "../../api";
import { LoadingState } from "../../utils/loading-state";
import { ServicePublicSettings } from "../../domain/services/service-public-settings";
import { UUID } from "../../utils/uuid";

export type FormUpdateDefaultDateFormatProps = Omit<
    IComboBoxProps,
    "options" | "selectedKey" | "onChange"
>;

enum UpdateDefaultDateFormatFeatures {
    CHANGE_DEFAULT_DATE_FORMAT = "change default date format",
}

@observer
@external
export class FormUpdateDefaultDateFormat extends React.Component<FormUpdateDefaultDateFormatProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryPublicSettings!: RepositoryPublicSettings;
    @inject private readonly servicePublicSettings!: ServicePublicSettings;

    private readonly loadingFeatures = new LoadingState<UpdateDefaultDateFormatFeatures>();

    private get options(): ISelectableOption<unknown>[] {
        return Object.values(DateFormat).map((format) => ({
            key: format,
            text: this.i18n.t(`page.systemWide.defaultDateFormat.format.${format}`),
        }));
    }

    @action.bound private async updateDefaultDateFormat(
        publicSettingId: UUID,
        options: IComboBoxOption | undefined,
    ): Promise<void> {
        const defaultDateFormat = options?.key as DateFormat;
        await this.loadingFeatures.wrap(
            UpdateDefaultDateFormatFeatures.CHANGE_DEFAULT_DATE_FORMAT,
            this.repositoryPublicSettings.update(publicSettingId, { defaultDateFormat }),
        );
    }

    public render(): JSX.Element {
        return (
            <ComboBox
                {...this.props}
                disabled={
                    this.loadingFeatures.isLoading(
                        UpdateDefaultDateFormatFeatures.CHANGE_DEFAULT_DATE_FORMAT,
                    ) || this.props.disabled
                }
                label={this.i18n.t("page.systemWide.defaultDateFormat.label")}
                options={this.options}
                selectedKey={
                    this.loadingFeatures.isLoading(
                        UpdateDefaultDateFormatFeatures.CHANGE_DEFAULT_DATE_FORMAT,
                    )
                        ? undefined
                        : this.servicePublicSettings.publicSetting?.defaultDateFormat
                }
                onChange={(_, option) => {
                    if (this.servicePublicSettings.publicSetting) {
                        this.updateDefaultDateFormat(
                            this.servicePublicSettings.publicSetting.id,
                            option,
                        );
                    }
                }}
            />
        );
    }
}
