import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { external, initialize, inject } from "tsdi";
import { NotificationEmailCreate, Setting, SettingUpdate } from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositorySettings } from "../../domain/repositories/repository-settings";
import { FormUpdateProps } from "../../utils/form-update-props";
import { createUuid, UUID } from "../../utils/uuid";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { DefaultButton, Separator, Slider, Stack, TextField, Toggle } from "@fluentui/react";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import { doubleBindString } from "../../utils/double-bind";
import sizes from "../sizes.scss";
import { NotificationEmails } from "../atoms/notification-emails";

export interface FormUpdateSettingPreOpsProps extends Omit<FormUpdateProps<Setting>, "id"> {}

@observer
@external
export class FormUpdateSettingPreOps extends React.Component<FormUpdateSettingPreOpsProps> {
    @inject private readonly repositorySetting!: RepositorySettings;
    @inject private readonly i18n!: I18nProvider;

    private validationId = createUuid();

    @observable private settingId?: UUID;
    @observable private emailsCreate: NotificationEmailCreate[] = [];

    constructor(props: FormUpdateSettingPreOpsProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected async initialize(): Promise<void> {
        // We can always assume that the API will return exactly one setting object
        const [setting] = await this.repositorySetting.byQueryAsync();
        runInAction(() => {
            this.settingId = setting.id;
        });
        this.repositorySetting.validation.update.initializeModel(
            this.validationId,
            this.settingUpdate,
            setting.id,
        );
    }

    private triggerValidation(): void {
        this.repositorySetting.validation.update.updateModel(this.validationId, this.settingUpdate);
    }

    public componentWillUnmount(): void {
        if (this.setting === undefined) {
            return;
        }
        this.repositorySetting.discardMutableCopy(FormUpdateSettingPreOps.name, this.setting.id);
    }

    @computed private get setting(): Setting | undefined {
        if (this.settingId === undefined) {
            return undefined;
        }
        return this.repositorySetting.mutableCopyById(FormUpdateSettingPreOps.name, this.settingId);
    }

    @computed private get settingUpdate(): SettingUpdate {
        return {
            preOpsMessage: this.setting?.preOpsMessage,
            preOpsHoursUntilRecheck: this.setting?.preOpsHoursUntilRecheck,
            preOpsRecheckOnDriverChange: this.setting?.preOpsRecheckOnDriverChange,
            preOpsNotificationEmails: this.setting?.preOpsNotificationEmails,
            preOpsNotificationEmailsCreate:
                this.emailsCreate.length == 0 ? null : this.emailsCreate,
        };
    }

    @action.bound
    private async updateSetting(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        if (this.setting === undefined) {
            throw new Error("submit button should be disabled until setting is loaded");
        }
        evt.preventDefault();
        const setting = await this.repositorySetting.update(this.setting.id, this.settingUpdate);
        this.props.onUpdate(setting);
    }

    @action.bound private setPreOpsMessage(
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newMessage?: string | undefined,
    ): void {
        if (!this.setting) {
            throw new Error("Can't update Message: setting not loaded.");
        }
        this.setting.preOpsMessage = newMessage == undefined ? "" : newMessage;
        this.triggerValidation();
    }

    @action.bound private setPreOpsHoursUntilRecheck(hour: number): void {
        if (this.setting === undefined) {
            throw new Error("Can't update Hour until recheck: setting not loaded.");
        }
        this.setting.preOpsHoursUntilRecheck = hour ?? null;
        this.triggerValidation();
    }

    @action.bound private setPreOpsRecheckOnDriverChange(
        _event: unknown,
        preOpsRecheckOnDriverChange: boolean | undefined,
    ): void {
        if (this.setting === undefined) {
            throw new Error("Can't update preOpsRecheckOnDriverChange: setting not loaded.");
        }
        this.setting.preOpsRecheckOnDriverChange = preOpsRecheckOnDriverChange ?? false;
        this.triggerValidation();
    }

    @action.bound private onChangeEmails(): void {
        this.triggerValidation();
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formUpdateSettingPreOps.submit.text")}
                validation={this.repositorySetting.validation.update}
                validationId={this.validationId}
            />
        );

        if (this.setting != undefined) {
            return (
                <form onSubmit={this.updateSetting}>
                    <Stack
                        horizontal
                        horizontalAlign="space-between"
                        tokens={{
                            childrenGap: `${sizes.l}`,
                            padding: `0px ${sizes.xl}`,
                        }}
                    >
                        <Stack
                            tokens={{
                                childrenGap: `${sizes.gutter}`,
                            }}
                            styles={{
                                root: {
                                    minWidth: "500px",
                                },
                            }}
                        >
                            <Stack>
                                <h3
                                    style={{
                                        marginBottom: sizes.marginBottomForHeaderWithSeparator,
                                    }}
                                >
                                    {this.i18n.t("formUpdateSettingPreOps.subtitle")}
                                </h3>
                                <Separator />
                            </Stack>

                            <TextField
                                multiline
                                rows={3}
                                label={this.i18n.t("formUpdateSettingPreOps.message.label")}
                                {...doubleBindString(this.setting, "preOpsMessage", () =>
                                    this.triggerValidation(),
                                )}
                                onChange={this.setPreOpsMessage}
                                required
                                errorMessage={this.i18n.formatFieldValidationState(
                                    this.repositorySetting.validation.update.getFieldValidationState(
                                        this.validationId,
                                        "preOpsMessage",
                                    ),
                                )}
                            />
                            <Slider
                                label={this.i18n.t(
                                    "formUpdateSettingPreOps.hoursUntilRecheck.label",
                                )}
                                max={24}
                                value={this.setting.preOpsHoursUntilRecheck ?? 0}
                                showValue
                                onChange={this.setPreOpsHoursUntilRecheck}
                                styles={{
                                    root: {
                                        maxWidth: "500px",
                                    },
                                }}
                            />
                            <Toggle
                                label={this.i18n.t(
                                    "formUpdateSettingPreOps.preOpsRecheckOnDriverChange.label",
                                )}
                                checked={this.setting?.preOpsRecheckOnDriverChange}
                                onChange={this.setPreOpsRecheckOnDriverChange}
                            />
                            <NotificationEmails
                                emailsCreate={this.emailsCreate}
                                emailsUpdate={this.setting.preOpsNotificationEmails}
                                onChange={this.onChangeEmails}
                                translationPrefixKey={"formUpdateSettingPreOps"}
                                validationId={this.validationId}
                                validationKey={"preOpsNotificationEmails"}
                                validationKeyCreate={"preOpsNotificationEmailsCreate"}
                            ></NotificationEmails>
                        </Stack>
                    </Stack>

                    {this.props.asDialogContent ? (
                        <ElofleetDialogFooter>
                            <DefaultButton
                                label={this.i18n.t("formUpdateSettingPreOps.cancel.label")}
                                text={this.i18n.t("formUpdateSettingPreOps.cancel.text")}
                                onClick={this.props.onDialogCancel}
                            />
                            {primaryButton}
                        </ElofleetDialogFooter>
                    ) : (
                        <Stack horizontal horizontalAlign="end">
                            {primaryButton}
                        </Stack>
                    )}
                </form>
            );
        } else return <></>;
    }
}
