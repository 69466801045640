import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { declareRoute, routePath, RouteProps } from "../routes";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { ListShockEvents } from "../ui/molecules/list-shock-events";
import { PageHeader } from "../ui/atoms/page-header";
import { PageContent } from "../ui/atoms/page-content";
import { Section } from "../ui/atoms/section";
import { SelectionMode } from "@fluentui/react";
import sizes from "../ui/sizes.scss";
import { articles } from "../help";

@external
@observer
export class PageImpactManagement extends React.Component<RouteProps> {
    @inject protected readonly i18n!: I18nProvider;

    public render(): JSX.Element {
        return (
            <LayoutDefault
                suggestedHelpArticles={[articles.impactStatisticsReport]}
                header={<PageHeader title={this.i18n.t("page.impactManagement.title")} />}
            >
                <PageContent>
                    <Section>
                        <ListShockEvents
                            selectionMode={SelectionMode.none}
                            maxHeight={`${sizes.listMaxHeight}`}
                        />
                    </Section>
                </PageContent>
            </LayoutDefault>
        );
    }
}

export const routeImpactManagement = declareRoute({
    navigation: true,
    component: PageImpactManagement,
    icon: "AlertSolid",
    title: "page.impactManagement.navbarEntry",
    path: routePath.impactManagement,
    pattern: "/impact-management",
});
