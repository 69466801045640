import { Dispatch, SetStateAction, useState } from "react";

type ValueSetter<T> = Dispatch<SetStateAction<T>>;

export function useLocalStorage<T>(unprefixedKey: string, initialValue: T): [T, ValueSetter<T>] {
    const key = `elokon-elofleet-${unprefixedKey}`;
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // On error, also return initialValue
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that
    // persists the new value to localStorage.
    const setValue: ValueSetter<T> = (value) => {
        // Save state
        setStoredValue(value);
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(value));
    };

    return [storedValue, setValue];
}
