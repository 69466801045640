/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PreOpsQuestion,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PreOpsQuestionFromJSON,
    PreOpsQuestionToJSON,
} from '../models';

export interface PreOpsQuestionVersionsReadPreOpsQuestionVersionRequest {
    versionId: string;
}

/**
 * PreOpsQuestionVersionsApi - interface
 * 
 * @export
 * @interface PreOpsQuestionVersionsApiInterface
 */
export interface PreOpsQuestionVersionsApiInterface {
    /**
     * Returns the question version specified by the given version ID.
     * @summary Get a single question version
     * @param {string} versionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsQuestionVersionsApiInterface
     */
    preOpsQuestionVersionsReadPreOpsQuestionVersionRaw(requestParameters: PreOpsQuestionVersionsReadPreOpsQuestionVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreOpsQuestion>>;

    /**
     * Returns the question version specified by the given version ID.
     * Get a single question version
     */
    preOpsQuestionVersionsReadPreOpsQuestionVersion(requestParameters: PreOpsQuestionVersionsReadPreOpsQuestionVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreOpsQuestion>;

}

/**
 * 
 */
export class PreOpsQuestionVersionsApi extends runtime.BaseAPI implements PreOpsQuestionVersionsApiInterface {

    /**
     * Returns the question version specified by the given version ID.
     * Get a single question version
     */
    async preOpsQuestionVersionsReadPreOpsQuestionVersionRaw(requestParameters: PreOpsQuestionVersionsReadPreOpsQuestionVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreOpsQuestion>> {
        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling preOpsQuestionVersionsReadPreOpsQuestionVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_question_versions/{version_id}`.replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters.versionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreOpsQuestionFromJSON(jsonValue));
    }

    /**
     * Returns the question version specified by the given version ID.
     * Get a single question version
     */
    async preOpsQuestionVersionsReadPreOpsQuestionVersion(requestParameters: PreOpsQuestionVersionsReadPreOpsQuestionVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreOpsQuestion> {
        const response = await this.preOpsQuestionVersionsReadPreOpsQuestionVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
