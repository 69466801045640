import { TSDI } from "tsdi";
import { HTTPHeaders } from "../api";
import { ServiceAuth } from "../domain/services/service-auth";

/** Inject the headers for each request with mandatory information such as authentication. */
export function createHeaders(tsdi: TSDI, headers?: HTTPHeaders): HTTPHeaders | undefined {
    const serviceAuth = tsdi.get(ServiceAuth);

    if (serviceAuth.loginToken) {
        return {
            authorization: `Bearer ${serviceAuth.loginToken.token}`,
            ...headers,
        };
    } else {
        return headers;
    }
}
