/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserProductivityList
 */
export interface UserProductivityList {
    /**
     * 
     * @type {boolean}
     * @memberof UserProductivityList
     */
    userEmployeeId: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserProductivityList
     */
    userLastName: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserProductivityList
     */
    userFirstName: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserProductivityList
     */
    departmentLabel: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserProductivityList
     */
    shiftLabel: boolean;
}

/**
 * Check if a given object implements the UserProductivityList interface.
 */
export function instanceOfUserProductivityList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userEmployeeId" in value;
    isInstance = isInstance && "userLastName" in value;
    isInstance = isInstance && "userFirstName" in value;
    isInstance = isInstance && "departmentLabel" in value;
    isInstance = isInstance && "shiftLabel" in value;

    return isInstance;
}

export function UserProductivityListFromJSON(json: any): UserProductivityList {
    return UserProductivityListFromJSONTyped(json, false);
}

export function UserProductivityListFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProductivityList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userEmployeeId': json['userEmployeeId'],
        'userLastName': json['userLastName'],
        'userFirstName': json['userFirstName'],
        'departmentLabel': json['departmentLabel'],
        'shiftLabel': json['shiftLabel'],
    };
}

export function UserProductivityListToJSON(value?: UserProductivityList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userEmployeeId': value.userEmployeeId,
        'userLastName': value.userLastName,
        'userFirstName': value.userFirstName,
        'departmentLabel': value.departmentLabel,
        'shiftLabel': value.shiftLabel,
    };
}

