import React from "react";
import { ElodmsLayout } from "../components/layout";
import routes from "../routes";
import Icon from "@mdi/react";
import { mdiArrowRight, mdiFileDocument, mdiFinance, mdiTools } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export function Dashboard(): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    return (
        <ElodmsLayout title="Dashboard">
            <div className="prose prose-lg prose-slate mx-auto max-w-3xl p-16 text-center">
                <h2 className="font-header">
                    <>{t("dms.page.dashboard.header")}</>
                </h2>
                <p className="tracking-tight">
                    <>{t("dms.page.dashboard.subheader")}</>
                </p>
            </div>
            <div className="flex justify-center gap-x-5 border-t border-slate-300 p-16">
                <Icon path={mdiTools} className="h-8 w-8 rounded-full bg-slate-100 p-1.5" />
                <div className="prose prose-slate">
                    <h3>
                        <>{t("dms.page.dashboard.serviceJobs.header")}</>
                    </h3>
                    <p>
                        <>{t("dms.page.dashboard.serviceJobs.body")}</>
                    </p>

                    <a
                        href={routes.viewEquipment.generateUrl(
                            "d77bbdf3-8f4e-419f-8fd6-d3ab8344d7bf",
                        )}
                    >
                        <>{t("dms.page.dashboard.serviceJobs.button")}</>
                        <Icon path={mdiArrowRight} className="ml-1 inline h-4" />
                    </a>
                </div>
            </div>
            <div className="flex justify-center gap-x-5 border-t border-slate-300 p-16">
                <Icon path={mdiFinance} className="h-8 w-8 rounded-full bg-slate-100 p-1.5" />
                <div className="prose prose-slate">
                    <h3>
                        <>{t("dms.page.dashboard.tco.header")}</>
                    </h3>
                    <p>
                        <>{t("dms.page.dashboard.tco.body")}</>
                    </p>
                    <a
                        href={routes.viewEquipment.generateUrl(
                            "d77bbdf3-8f4e-419f-8fd6-d3ab8344d7bf",
                        )}
                    >
                        <>{t("dms.page.dashboard.tco.button")}</>
                        <Icon path={mdiArrowRight} className="ml-1 inline h-4" />
                    </a>
                </div>
            </div>
            <div className="flex justify-center gap-x-5 border-t border-slate-300 p-16">
                <Icon path={mdiFileDocument} className="h-8 w-8 rounded-full bg-slate-100 p-1.5" />
                <div className="prose prose-slate">
                    <h3>
                        <>{t("dms.page.dashboard.documents.header")}</>
                    </h3>
                    <p>
                        <>{t("dms.page.dashboard.documents.body")}</>
                    </p>

                    <a href={routes.newDocument.generateUrl()}>
                        <>{t("dms.page.dashboard.documents.button")}</>
                        <Icon path={mdiArrowRight} className="ml-1 inline h-4" />
                    </a>
                </div>
            </div>
            <div className="border-t border-slate-300 p-16">
                <div className="prose prose-lg prose-slate mx-auto text-center">
                    <p className="tracking-tight">
                        <>{t("dms.page.dashboard.bottomText")}</>
                    </p>
                </div>
            </div>
        </ElodmsLayout>
    );
}
