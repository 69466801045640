import { action, computed, makeObservable, observable } from "mobx";
import { external } from "tsdi";
import { UUID } from "./uuid";

/** Options for the {@link SelectionState }. */
export interface SelectionStateOptions<> {}

/**
 * A class that manages the current selection state for list.
 */
@external
export class SelectionState {
    @observable private _selectedKeys: UUID[] = [];

    constructor(private options: SelectionStateOptions) {
        makeObservable(this);
    }

    @action.bound updateSelection(keys: UUID[]): void {
        this._selectedKeys = keys;
    }

    @computed get keys(): UUID[] {
        return this._selectedKeys;
    }
}
