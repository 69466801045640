import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { declareRoute, routePath, RouteProps } from "../routes";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { ListVehicleGroups } from "../ui/molecules/list-vehicle-groups";
import { ListCommandButtonsVehicleGroups } from "../ui/organisms/list-command-buttons-vehicle-groups";
import { createUuid } from "../utils/uuid";
import { PageHeader } from "../ui/atoms/page-header";
import { PageContent } from "../ui/atoms/page-content";
import { Section } from "../ui/atoms/section";

@external
@observer
export class PageVehicleGroups extends React.Component<RouteProps> {
    @inject protected readonly i18n!: I18nProvider;

    // Create the UUID on this level, so the button bar is able to access the same state as the list.
    private listStateId = createUuid();

    public render(): JSX.Element {
        return (
            <LayoutDefault header={<PageHeader title={this.i18n.t("page.vehicleGroups.title")} />}>
                <PageContent>
                    <Section>
                        <ListVehicleGroups
                            commandBar={
                                <ListCommandButtonsVehicleGroups
                                    listStateId={this.listStateId}
                                    canDelete
                                />
                            }
                            listStateId={this.listStateId}
                        />
                    </Section>
                </PageContent>
            </LayoutDefault>
        );
    }
}

export const routeVehicleGroups = declareRoute({
    navigation: true,
    component: PageVehicleGroups,
    icon: "Group",
    title: "page.vehicleGroups.navbarEntry",
    path: routePath.vehicleGroups,
    pattern: "/vehicle-groups",
});
