import { IColumn } from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, inject } from "tsdi";
import { SortDirection } from "../api";
import { HistoryProvider } from "../domain/providers/history-provider";
import { ApiResource } from "./elofleet-repository";
import { PaginationState } from "./pagination-state";

/** Options for creating a new {@link SortState}. */
export interface SortStateOptions<TSortKey> {
    /**
     * Mandatory function for mapping a column to a sort key.
     * This can in most cases be implemented by switching over the column key and
     * returning an enum literal.
     *
     * ```ts
     *  (column) => {
     *      switch (column.key) {
     *          case "label":
     *              return MySortKey.LABEL;
     *          case "createdAt":
     *              return MySortKey.CREATED_AT;
     *      }
     *  },
     * ```
     *
     * It is possible to return `undefined` in which case the default sorting will be applied.
     */
    readonly getSortKeyForColumn: (column: IColumn) => TSortKey | undefined;
    /**
     * This can be specified optionally to override the default behavior of when a column is marked as
     * sorted. (Little arrow displayed next to the column's name).
     */
    readonly isColumnSorted?: (column: IColumn, sortKey: TSortKey) => boolean;
    /** Pagination state can be specified optionally. Will be reset when sorting. */
    readonly paginationState?: PaginationState<ApiResource>;
    /** If specified, URL will not be modified or read. */
    readonly ignoreUrl?: boolean;
}

/**
 * Holds observable state for sorting.
 * Can handle Fluent UI's events, patch the props for the list
 * and generate the repository's query.
 */
@external
export class SortState<TSortKey extends string> {
    @inject private readonly historyProvider!: HistoryProvider;

    /** Only used if not read from URL. */
    @observable private _sortDirection: SortDirection = SortDirection.ASCENDING;
    /** Only used if not read from URL. */
    @observable private _sortKey: TSortKey | undefined;

    constructor(private options: SortStateOptions<TSortKey>) {
        makeObservable(this);
    }

    /** The sort direction (ascending or descending). */
    @computed private get direction(): SortDirection {
        if (this.options.ignoreUrl) {
            return this._sortDirection;
        }
        const direction = this.historyProvider.getQueryParam("sortDirection");
        if (typeof direction !== "string") {
            return SortDirection.ASCENDING;
        }
        return direction as SortDirection;
    }

    /** The sort direction (ascending or descending). */
    private set direction(direction: SortDirection) {
        if (this.options.ignoreUrl) {
            this._sortDirection = direction;
            return;
        }
        this.historyProvider.setQueryParam("sortDirection", String(direction));
    }

    /** Currently active sort key. `undefined` means the default sorting is in place. */
    @computed private get sortKey(): undefined | TSortKey {
        if (this.options.ignoreUrl) {
            return this._sortKey;
        }
        const sortKey = this.historyProvider.getQueryParam("sortKey");
        if (typeof sortKey !== "string") {
            return;
        }
        return sortKey as TSortKey;
    }

    /** Currently active sort key. `undefined` means the default sorting is in place. */
    private set sortKey(sortKey: undefined | TSortKey) {
        if (this.options.ignoreUrl) {
            this._sortKey = sortKey;
            return;
        }
        this.historyProvider.setQueryParam("sortKey", sortKey ? String(sortKey) : undefined);
    }

    /** Manually set the sort state. */
    @action.bound public setState(sortKey: undefined | TSortKey, direction: SortDirection): void {
        this.sortKey = sortKey;
        this.direction = direction;
    }

    /**
     * Fluent UI event handle for `onColumnHeaderClick`.
     * Call this to toggle whether a column is sorted.
     * If the column was already the active column, the order is reversed.
     * If this is invoked with another column, the active column is changed.
     */
    @action.bound public toggleColumn(_: unknown, column?: IColumn): void {
        if (!column) {
            return;
        }
        const sortKey = this.options.getSortKeyForColumn(column);

        // If the column is unknown, fall back to default sorting.
        if (!sortKey) {
            return;
        }

        if (sortKey === this.sortKey) {
            // The already active column was clicked once again: Change direction.
            if (this.direction === SortDirection.ASCENDING) {
                this.direction = SortDirection.DESCENDING;
            } else {
                this.direction = SortDirection.ASCENDING;
            }
        } else {
            // Another column is selected, change the active column and reset direction.
            this.sortKey = sortKey;
            this.direction = SortDirection.ASCENDING;
        }

        this.options.paginationState?.reset();
    }

    /**
     * Can be used to add sorting-specific properties to a Fluent UI column.
     * Will make the column display an arrow next to the title if it is the active column.
     */
    public patchColumn(column: IColumn): IColumn {
        if (!this.sortKey || !this.isColumnSorted(column, this.sortKey)) {
            return {
                ...column,
                isSorted: false,
            };
        }
        return {
            ...column,
            isSorted: true,
            isSortedDescending: this.direction === SortDirection.DESCENDING,
        };
    }

    /**
     * Patch an array of columns for Fluent UI's lists.
     * This adds props to sorted columns, making them show a little arrow next
     * to the title.
     * @see SortState.patchColumn
     */
    public patchColumns(columns: IColumn[]): IColumn[] {
        return columns.map((column) => this.patchColumn(column));
    }

    /** A query segment compatible with most repository's queries. */
    @computed public get query(): { sortKey?: TSortKey; sortDirection?: SortDirection } {
        const { sortKey, direction: sortDirection } = this;
        if (!sortKey) {
            return {};
        }
        return {
            sortKey,
            sortDirection,
        };
    }

    private isColumnSorted(column: IColumn, sortKey: TSortKey): boolean {
        if (this.options.isColumnSorted) {
            return this.options.isColumnSorted(column, sortKey);
        }
        if (!sortKey) {
            return false;
        }
        return this.options.getSortKeyForColumn(column) === sortKey;
    }
}
