/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PreOpsAnswerChoice {
    GOOD = 'good',
    OK = 'ok',
    CRITICAL = 'critical'
}


export function PreOpsAnswerChoiceFromJSON(json: any): PreOpsAnswerChoice {
    return PreOpsAnswerChoiceFromJSONTyped(json, false);
}

export function PreOpsAnswerChoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreOpsAnswerChoice {
    return json as PreOpsAnswerChoice;
}

export function PreOpsAnswerChoiceToJSON(value?: PreOpsAnswerChoice | null): any {
    return value as any;
}

