import { format } from "date-fns";
import * as React from "react";
import { ElodmsLayout } from "../../components/layout";
import {
    serviceReportStatus,
    ServiceReport,
    ServiceReportStatus,
} from "../../stores/service-report";
import { useStore } from "../../stores/store";
import Fuse from "fuse.js";
import { UUID } from "../../../utils/uuid";
import { Equipment } from "../../stores/equipment";
import { TextInput } from "../../components/text-input";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

const statusColors = {
    [ServiceReportStatus.Ok]: "bg-green-700",
    [ServiceReportStatus.Warning]: "bg-yellow-500",
    [ServiceReportStatus.Critical]: "bg-red-700",
};

export function ListServiceReports(): JSX.Element {
    const [searchString, setSearchString] = React.useState("");
    const { t }: { t: TFunction } = useTranslation();

    const serviceReports: Array<[UUID, ServiceReport, ServiceReportStatus, Equipment]> = useStore(
        (state) => {
            let enrichedReports = Object.entries(state.serviceReports).map(
                ([serviceReportId, serviceReport]) => {
                    const equipment = state.equipment[serviceReport.equipment_id];
                    return {
                        id: serviceReportId,
                        report: serviceReport,
                        reportStatus: serviceReportStatus(serviceReport),
                        submittedYear: serviceReport.timestamp.getFullYear(),
                        equipment: {
                            ...equipment,
                            oem: t(equipment.oem ?? ""),
                            modelName: t(equipment.modelName),
                        },
                    };
                },
            );
            enrichedReports.sort(
                (a, b) => b.report.timestamp.getTime() - a.report.timestamp.getTime(),
            );

            if (searchString) {
                const fuse = new Fuse(enrichedReports, {
                    keys: [
                        "reportStatus",
                        "submittedYear",
                        "equipment.oem",
                        "equipment.modelName",
                        "equipment.serialNumber",
                        "equipment.department",
                        "equipment.equipmentType",
                        "equipment.manufactureYear",
                    ],
                });

                enrichedReports = fuse.search(searchString).map(({ item }) => item);
            } else {
                enrichedReports.sort(
                    (reportA, reportB) =>
                        reportB.report.timestamp.getTime() - reportA.report.timestamp.getTime(),
                );
            }

            return enrichedReports.map(({ id, report, reportStatus, equipment }) => [
                id,
                report,
                reportStatus,
                equipment,
            ]);
        },
    );

    return (
        <ElodmsLayout title={t("dms.page.listServiceReports.title")} className="pb-16">
            <div className="flex items-center border-b border-slate-300 px-8 py-4">
                <Icon path={mdiMagnify} className="mr-1 h-6 text-neutral-500" />
                <TextInput
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                    className="form-input w-full border-none bg-transparent"
                    placeholder={t("dms.page.listServiceReports.search")}
                />
            </div>
            <table className="w-full">
                <thead className="h-16 border-b-2 border-b-blue-900 text-left font-header">
                    <tr>
                        <th className="pl-8 pr-4 text-center">
                            <>{t("dms.page.listServiceReports.column.ok")}</>
                        </th>
                        <th className="pr-4">
                            <>{t("dms.page.listServiceReports.column.date")}</>
                        </th>
                        <th className="pr-8">
                            <>{t("dms.schema.equipment.equipment")}</>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {serviceReports.map(
                        ([serviceReportId, serviceReport, serviceReportStatus, equipment]) => {
                            return (
                                <tr
                                    key={serviceReportId}
                                    className="h-12 border-b border-b-slate-300 hover:bg-gray-100"
                                >
                                    <td className="w-24 pl-8 pr-4 text-center">
                                        <div
                                            className={`inline-block h-4 w-4 rounded-full border-2 border-white/10 ${statusColors[serviceReportStatus]}`}
                                        />
                                    </td>
                                    <td className="pr-4">
                                        {format(serviceReport.timestamp, "yyyy-MM-dd HH:mm")}
                                    </td>
                                    <td className="pr-8">
                                        {equipment.oem} {equipment.modelName}{" "}
                                        {equipment.serialNumber ? `#${equipment.serialNumber}` : ""}
                                    </td>
                                </tr>
                            );
                        },
                    )}
                </tbody>
            </table>
        </ElodmsLayout>
    );
}
