import * as React from "react";
import { external, initialize, inject } from "tsdi";
import { declareRoute, routePath, RouteProps } from "../routes";
import { LayoutCenter } from "../ui/layouts/layout-centered";
import { FormLogin } from "../ui/molecules/form-login";
import {
    Stack,
    getTheme,
    MessageBar,
    MessageBarType,
    IStackTokens,
    IStackStyles,
} from "@fluentui/react";
import elokonLogoUrl from "../images/elofleet-logo.svg";
import { LogoutReason, ServiceAuth } from "../domain/services/service-auth";
import { HistoryProvider } from "../domain/providers/history-provider";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import colors from "../ui/colors.scss";
import css from "./page-login.scss";
import { ListLoginNews } from "../ui/atoms/list-login-news";
import { News, NewsApi } from "../api";

const theme = getTheme();

export type PageLoginProps = RouteProps<{}>;

const childrenToken: IStackTokens = { childrenGap: "1.5em" };

const stackStyle: IStackStyles = {
    root: {
        background: colors.primarywhite,
        borderRadius: theme.effects.roundedCorner4,
        boxShadow: theme.effects.elevation4,
    },
};

@external
@observer
export class PageLogin extends React.Component<PageLoginProps> {
    @inject private serviceAuth!: ServiceAuth;
    @inject private historyProvider!: HistoryProvider;
    @inject private readonly i18n!: I18nProvider;
    @inject private newsApi!: NewsApi;

    @observable public androidInfoIsOpen = false;
    @observable public news?: News;

    constructor(props: PageLoginProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get logoutReason(): LogoutReason | undefined {
        const reason = this.historyProvider.getQueryParam("logoutReason");
        if (!Object.values(LogoutReason).includes(reason as LogoutReason)) {
            return;
        }
        return reason as LogoutReason;
    }

    @initialize protected initialize(): void {
        // Don't allow users to visit login page if already logged in.
        if (this.serviceAuth.isLoggedIn) {
            this.historyProvider.history.push(routePath.dashboard());
        }
        this.loadNews();
    }

    @computed private get messageBarType(): MessageBarType {
        switch (this.logoutReason) {
            case LogoutReason.UNKNOWN:
                return MessageBarType.error;
            case LogoutReason.INVALID_TOKEN:
                return MessageBarType.warning;
            case LogoutReason.USER_LOGOUT:
            default:
                return MessageBarType.success;
        }
    }

    private renderLogoutReason(): JSX.Element {
        if (!this.logoutReason) {
            return <></>;
        }
        return (
            <MessageBar messageBarType={this.messageBarType} isMultiline style={{ maxWidth: 250 }}>
                {this.i18n.t(`page.login.logoutReason.${this.logoutReason}`)}
            </MessageBar>
        );
    }

    private loadNews(): void {
        this.newsApi
            .newsReadNews()
            .then((news) => {
                runInAction(() => {
                    this.news = news;
                });
            })
            .catch((_err) => {});
    }

    public render(): JSX.Element {
        return (
            <div className={css.pageLogin}>
                <LayoutCenter
                    background={colors["secondarypage-background"]}
                    footer={() => (
                        <div
                            style={{
                                textAlign: "center",
                                padding: theme.spacing.m,
                                color: theme.semanticColors.bodyText,
                            }}
                        >
                            © {new Date().getFullYear()}{" "}
                            <a
                                style={{
                                    color: theme.semanticColors.bodyText,
                                    textDecoration: "none",
                                }}
                                href="https://www.elokon.com"
                            >
                                elokon.com
                            </a>{" "}
                            <>{this.i18n.t("page.login.allRightsReserved")}</>
                        </div>
                    )}
                >
                    <Stack>
                        <Stack.Item align="center">
                            <a href="https://www.elofleet.com">
                                <img
                                    src={elokonLogoUrl}
                                    style={{ width: "20rem", padding: theme.spacing.l1 }}
                                />
                            </a>
                        </Stack.Item>
                        <Stack wrap horizontal horizontalAlign="center" tokens={childrenToken}>
                            <Stack.Item className={css.pageLogin__loginForm} styles={stackStyle}>
                                <Stack>
                                    <div className={css.pageLogin__heading}>
                                        {this.i18n.t("page.login.title")}
                                    </div>
                                    <Stack
                                        className={css.pageLogin__loginForm__body}
                                        tokens={childrenToken}
                                    >
                                        {this.renderLogoutReason()}
                                        <FormLogin />
                                    </Stack>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item className={css.pageLogin__newsList} styles={stackStyle}>
                                <ListLoginNews news={this.news} />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                </LayoutCenter>
            </div>
        );
    }
}

export const routeLogin = declareRoute({
    component: PageLogin,
    path: routePath.login,
    pattern: "/login",
    public: true,
});
