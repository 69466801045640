import { TextField } from "@fluentui/react";
import { observer } from "mobx-react";
import React from "react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { external, initialize, inject } from "tsdi";
import { action, makeObservable, observable } from "mobx";

export interface FloatInputProps {
    label: string;
    // Number of fractional digits, e.g. a precision
    // of three would result in a number like 4.395
    precision: number;
    onChange: (value?: number) => void;
    errorMessage?: string;
    // Current value of the input. If undefined,
    // the input will simply be empty
    defaultValue?: number;
    // Default: false
    required?: boolean;
}

@external
@observer
export class FloatInput extends React.Component<FloatInputProps> {
    @inject private readonly i18n!: I18nProvider;

    @observable public stringInput = "";

    constructor(props: FloatInputProps) {
        super(props);
        makeObservable(this);
    }

    @initialize
    @action.bound
    private initialize(): void {
        if (this.props.defaultValue !== undefined) {
            this.updateTextInput(
                null,
                this.i18n.formatFloat(this.props.defaultValue, this.props.precision),
            );
        }
    }

    @action.bound
    updateTextInput(_: unknown, newValue?: string): void {
        this.stringInput = newValue ?? "";
        const number = this.i18n.parseFloat(this.stringInput, undefined);

        this.stringInput = this.truncateInputToPrecision(this.stringInput);

        this.props.onChange(number);
    }

    // Make sure that users can't enter more fractional
    // digits than specified through the precision prop.
    private truncateInputToPrecision(input: string): string {
        const split = input.split(this.i18n.getFloatSeparator());
        // Assume that there's only one dot in the input,
        // All other inputs will be invalid floats,
        // failing validation anyway
        if (split.length !== 2) {
            return input;
        }
        const [whole, fraction] = split;
        const truncatedFraction = fraction.substring(0, this.props.precision);
        return `${whole}.${truncatedFraction}`;
    }

    // Calculate the correct HTML step attribute
    // depending on the given precision of our input.
    get step(): string {
        const step = 1 / Math.pow(10, this.props.precision);
        return String(step);
    }

    public render(): JSX.Element {
        return (
            <TextField
                type="number"
                onChange={this.updateTextInput}
                required={this.props.required}
                label={this.props.label}
                value={this.stringInput}
                errorMessage={this.props.errorMessage}
                step={this.step}
            />
        );
    }
}
