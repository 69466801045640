import { Icon, Stack, StackItem, TooltipHost } from "@fluentui/react";
import React from "react";
import sizes from "../sizes.scss";
import colors from "../colors.scss";
import { inject, external } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import css from "./dashboard-item-cell.scss";

type DashboardItemCellThreshold = {
    l: number;
    m: number;
    s: number;
};

export interface DashboardItemCellProps {
    title: string;
    itemValue: number | boolean;
    /** Set this to display the itemValue with a percent sign. */
    percent?: boolean;
    threshold?: DashboardItemCellThreshold;
    /** Lets you overwrite the default background color of the value field for this cell. */
    color?: string;
    tooltip?: string;
}

@external
export class DashboardItemCell extends React.Component<DashboardItemCellProps> {
    @inject protected readonly i18n!: I18nProvider;
    /** Sets an icon ("ChevronUp" or "ChevronDown") instead of a number in the item value field.
    Those icons indicate positive or negative development regarding the value compared to the past. */
    private iconName?: string;

    constructor(props: DashboardItemCellProps) {
        super(props);
    }

    /** Helper function to determine the background color for an input field with a given threshold and
    item value. */
    private evaluateThreshold(threshold: DashboardItemCellThreshold, itemValue: number): string {
        /** Threshold order: severe < medium < light */
        if (threshold.s < threshold.l) {
            if (itemValue <= threshold.s) {
                return colors["secondarydashboard-severe"];
            } else if (itemValue <= threshold.m) {
                return colors["secondarydashboard-medium"];
            } else {
                return colors["secondarydashboard-light"];
            }
        }
        /** Threshold order: light < medium < severe */
        if (itemValue >= threshold.s) {
            return colors["secondarydashboard-severe"];
        } else if (itemValue >= threshold.m) {
            return colors["secondarydashboard-medium"];
        } else {
            return colors["secondarydashboard-light"];
        }
    }

    /** Determines the color for the background of the value field of the cell depending on the input threshold. */
    private color(threshold: DashboardItemCellThreshold | undefined): string {
        const itemValue = this.props.itemValue;
        // If the color is set manually, use that.
        if (this.props.color !== undefined) {
            return this.props.color;
        }
        // A boolean itemValue indicates the use of an icon rather than a numerical value.
        if (typeof itemValue === "boolean") {
            this.iconName = itemValue ? "ChevronUp" : "ChevronDown";
            return itemValue
                ? colors["secondarydashboard-light"]
                : colors["secondarydashboard-severe"];
        }
        // Some dashboard items do not require a color and therefore no treshold. In that case
        // color the background white.
        if (itemValue === undefined || threshold === undefined) {
            return colors.primarywhite;
        }
        return this.evaluateThreshold(threshold, itemValue);
    }

    public render(): JSX.Element {
        const itemValueIsBoolean = typeof this.props.itemValue === "boolean";

        return (
            <Stack horizontal horizontalAlign="start">
                <StackItem
                    style={{
                        fontSize: sizes.l,
                        display: "flex",
                        alignItems: "center",
                        flexGrow: 4,
                    }}
                >
                    {this.props.title}
                    {this.props.tooltip && (
                        <div className={css.tooltipWrapper}>
                            <TooltipHost content={this.props.tooltip}>
                                <div className={css.tooltip}>
                                    <Icon iconName="Help" className={css.tooltipIcon} />
                                </div>
                            </TooltipHost>
                        </div>
                    )}
                </StackItem>
                {itemValueIsBoolean ? (
                    <StackItem
                        style={{
                            padding: sizes.s,
                            fontWeight: "bold",
                            fontSize: sizes.l,
                            border: "1px solid grey",
                            flexBasis: "12%",
                            textAlign: "center",
                        }}
                    >
                        <Icon iconName={this.iconName} />
                    </StackItem>
                ) : (
                    <StackItem
                        style={{
                            padding: sizes.s,
                            fontWeight: "bold",
                            fontSize: sizes.l,
                            border: "1px solid grey",
                            flexBasis: sizes.dashboardCellWidth,
                            textAlign: "center",
                        }}
                    >
                        <span>
                            {this.i18n.addThousandsSeperator(this.props.itemValue as number)}
                            {this.props.percent ? "%" : ""}
                        </span>
                    </StackItem>
                )}
            </Stack>
        );
    }
}
