/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FleetAuthenticatePeriodListUpdate
 */
export interface FleetAuthenticatePeriodListUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof FleetAuthenticatePeriodListUpdate
     */
    eventId?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FleetAuthenticatePeriodListUpdate
     */
    userEmployeeId?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FleetAuthenticatePeriodListUpdate
     */
    vehicleSerialNumber?: boolean | null;
}

/**
 * Check if a given object implements the FleetAuthenticatePeriodListUpdate interface.
 */
export function instanceOfFleetAuthenticatePeriodListUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FleetAuthenticatePeriodListUpdateFromJSON(json: any): FleetAuthenticatePeriodListUpdate {
    return FleetAuthenticatePeriodListUpdateFromJSONTyped(json, false);
}

export function FleetAuthenticatePeriodListUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FleetAuthenticatePeriodListUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'userEmployeeId': !exists(json, 'userEmployeeId') ? undefined : json['userEmployeeId'],
        'vehicleSerialNumber': !exists(json, 'vehicleSerialNumber') ? undefined : json['vehicleSerialNumber'],
    };
}

export function FleetAuthenticatePeriodListUpdateToJSON(value?: FleetAuthenticatePeriodListUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventId': value.eventId,
        'userEmployeeId': value.userEmployeeId,
        'vehicleSerialNumber': value.vehicleSerialNumber,
    };
}

