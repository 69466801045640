/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PageInfo,
  PreOpsChecklist,
  PreOpsChecklistCreate,
  PreOpsChecklistSortKey,
  PreOpsChecklistUpdate,
  SortDirection,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    PreOpsChecklistFromJSON,
    PreOpsChecklistToJSON,
    PreOpsChecklistCreateFromJSON,
    PreOpsChecklistCreateToJSON,
    PreOpsChecklistSortKeyFromJSON,
    PreOpsChecklistSortKeyToJSON,
    PreOpsChecklistUpdateFromJSON,
    PreOpsChecklistUpdateToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface PreOpsChecklistsCreatePreOpsChecklistRequest {
    preOpsChecklistCreate: PreOpsChecklistCreate;
}

export interface PreOpsChecklistsDeletePreOpsChecklistRequest {
    checklistId: string;
}

export interface PreOpsChecklistsListPreOpsChecklistsRequest {
    pageSize: number;
    search?: string | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: PreOpsChecklistSortKey;
}

export interface PreOpsChecklistsListPreOpsChecklistsMetadataRequest {
    pageSize: number;
    search?: string | null;
}

export interface PreOpsChecklistsReadPreOpsChecklistRequest {
    checklistId: string;
}

export interface PreOpsChecklistsUpdatePreOpsChecklistRequest {
    checklistId: string;
    preOpsChecklistUpdate: PreOpsChecklistUpdate;
}

export interface PreOpsChecklistsValidateCreatePreOpsChecklistRequest {
    preOpsChecklistCreate: PreOpsChecklistCreate;
}

export interface PreOpsChecklistsValidateUpdatePreOpsChecklistRequest {
    checklistId: string;
    preOpsChecklistUpdate: PreOpsChecklistUpdate;
}

/**
 * PreOpsChecklistsApi - interface
 * 
 * @export
 * @interface PreOpsChecklistsApiInterface
 */
export interface PreOpsChecklistsApiInterface {
    /**
     * Create a new pre-ops checklist.
     * @summary Create a pre-ops checklist
     * @param {PreOpsChecklistCreate} preOpsChecklistCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsChecklistsApiInterface
     */
    preOpsChecklistsCreatePreOpsChecklistRaw(requestParameters: PreOpsChecklistsCreatePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreOpsChecklist>>;

    /**
     * Create a new pre-ops checklist.
     * Create a pre-ops checklist
     */
    preOpsChecklistsCreatePreOpsChecklist(requestParameters: PreOpsChecklistsCreatePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreOpsChecklist>;

    /**
     * Delete the pre-ops checklists with the given ID.
     * @summary Delete a pre-ops checklists
     * @param {string} checklistId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsChecklistsApiInterface
     */
    preOpsChecklistsDeletePreOpsChecklistRaw(requestParameters: PreOpsChecklistsDeletePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the pre-ops checklists with the given ID.
     * Delete a pre-ops checklists
     */
    preOpsChecklistsDeletePreOpsChecklist(requestParameters: PreOpsChecklistsDeletePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Lists all the checklists present.  If `search` is specified, return values similar to the `label` field. In that case, `sortKey` will not have any effect. I.e. results will be sorted by similarity to the search term instead.
     * @summary List pre-ops checklists
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {PreOpsChecklistSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsChecklistsApiInterface
     */
    preOpsChecklistsListPreOpsChecklistsRaw(requestParameters: PreOpsChecklistsListPreOpsChecklistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PreOpsChecklist>>>;

    /**
     * Lists all the checklists present.  If `search` is specified, return values similar to the `label` field. In that case, `sortKey` will not have any effect. I.e. results will be sorted by similarity to the search term instead.
     * List pre-ops checklists
     */
    preOpsChecklistsListPreOpsChecklists(requestParameters: PreOpsChecklistsListPreOpsChecklistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PreOpsChecklist>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary Pre-ops checklist listing metadata
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsChecklistsApiInterface
     */
    preOpsChecklistsListPreOpsChecklistsMetadataRaw(requestParameters: PreOpsChecklistsListPreOpsChecklistsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * Pre-ops checklist listing metadata
     */
    preOpsChecklistsListPreOpsChecklistsMetadata(requestParameters: PreOpsChecklistsListPreOpsChecklistsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the checklist specified by the given ID.
     * @summary Get a single checklist
     * @param {string} checklistId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsChecklistsApiInterface
     */
    preOpsChecklistsReadPreOpsChecklistRaw(requestParameters: PreOpsChecklistsReadPreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreOpsChecklist>>;

    /**
     * Returns the checklist specified by the given ID.
     * Get a single checklist
     */
    preOpsChecklistsReadPreOpsChecklist(requestParameters: PreOpsChecklistsReadPreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreOpsChecklist>;

    /**
     * Update the checklist specified by the given ID.
     * @summary Update a pre-ops checklist
     * @param {string} checklistId 
     * @param {PreOpsChecklistUpdate} preOpsChecklistUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsChecklistsApiInterface
     */
    preOpsChecklistsUpdatePreOpsChecklistRaw(requestParameters: PreOpsChecklistsUpdatePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreOpsChecklist>>;

    /**
     * Update the checklist specified by the given ID.
     * Update a pre-ops checklist
     */
    preOpsChecklistsUpdatePreOpsChecklist(requestParameters: PreOpsChecklistsUpdatePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreOpsChecklist>;

    /**
     * Validate data for a new checklist without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a checklist
     * @param {PreOpsChecklistCreate} preOpsChecklistCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsChecklistsApiInterface
     */
    preOpsChecklistsValidateCreatePreOpsChecklistRaw(requestParameters: PreOpsChecklistsValidateCreatePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new checklist without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a checklist
     */
    preOpsChecklistsValidateCreatePreOpsChecklist(requestParameters: PreOpsChecklistsValidateCreatePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate data for updating the given checklist, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a pre-ops checklist update
     * @param {string} checklistId 
     * @param {PreOpsChecklistUpdate} preOpsChecklistUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOpsChecklistsApiInterface
     */
    preOpsChecklistsValidateUpdatePreOpsChecklistRaw(requestParameters: PreOpsChecklistsValidateUpdatePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for updating the given checklist, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a pre-ops checklist update
     */
    preOpsChecklistsValidateUpdatePreOpsChecklist(requestParameters: PreOpsChecklistsValidateUpdatePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class PreOpsChecklistsApi extends runtime.BaseAPI implements PreOpsChecklistsApiInterface {

    /**
     * Create a new pre-ops checklist.
     * Create a pre-ops checklist
     */
    async preOpsChecklistsCreatePreOpsChecklistRaw(requestParameters: PreOpsChecklistsCreatePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreOpsChecklist>> {
        if (requestParameters.preOpsChecklistCreate === null || requestParameters.preOpsChecklistCreate === undefined) {
            throw new runtime.RequiredError('preOpsChecklistCreate','Required parameter requestParameters.preOpsChecklistCreate was null or undefined when calling preOpsChecklistsCreatePreOpsChecklist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_checklists/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PreOpsChecklistCreateToJSON(requestParameters.preOpsChecklistCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreOpsChecklistFromJSON(jsonValue));
    }

    /**
     * Create a new pre-ops checklist.
     * Create a pre-ops checklist
     */
    async preOpsChecklistsCreatePreOpsChecklist(requestParameters: PreOpsChecklistsCreatePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreOpsChecklist> {
        const response = await this.preOpsChecklistsCreatePreOpsChecklistRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the pre-ops checklists with the given ID.
     * Delete a pre-ops checklists
     */
    async preOpsChecklistsDeletePreOpsChecklistRaw(requestParameters: PreOpsChecklistsDeletePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.checklistId === null || requestParameters.checklistId === undefined) {
            throw new runtime.RequiredError('checklistId','Required parameter requestParameters.checklistId was null or undefined when calling preOpsChecklistsDeletePreOpsChecklist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_checklists/{checklist_id}`.replace(`{${"checklist_id"}}`, encodeURIComponent(String(requestParameters.checklistId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the pre-ops checklists with the given ID.
     * Delete a pre-ops checklists
     */
    async preOpsChecklistsDeletePreOpsChecklist(requestParameters: PreOpsChecklistsDeletePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.preOpsChecklistsDeletePreOpsChecklistRaw(requestParameters, initOverrides);
    }

    /**
     * Lists all the checklists present.  If `search` is specified, return values similar to the `label` field. In that case, `sortKey` will not have any effect. I.e. results will be sorted by similarity to the search term instead.
     * List pre-ops checklists
     */
    async preOpsChecklistsListPreOpsChecklistsRaw(requestParameters: PreOpsChecklistsListPreOpsChecklistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PreOpsChecklist>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling preOpsChecklistsListPreOpsChecklists.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_checklists/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PreOpsChecklistFromJSON));
    }

    /**
     * Lists all the checklists present.  If `search` is specified, return values similar to the `label` field. In that case, `sortKey` will not have any effect. I.e. results will be sorted by similarity to the search term instead.
     * List pre-ops checklists
     */
    async preOpsChecklistsListPreOpsChecklists(requestParameters: PreOpsChecklistsListPreOpsChecklistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PreOpsChecklist>> {
        const response = await this.preOpsChecklistsListPreOpsChecklistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * Pre-ops checklist listing metadata
     */
    async preOpsChecklistsListPreOpsChecklistsMetadataRaw(requestParameters: PreOpsChecklistsListPreOpsChecklistsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling preOpsChecklistsListPreOpsChecklistsMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_checklists/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * Pre-ops checklist listing metadata
     */
    async preOpsChecklistsListPreOpsChecklistsMetadata(requestParameters: PreOpsChecklistsListPreOpsChecklistsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.preOpsChecklistsListPreOpsChecklistsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the checklist specified by the given ID.
     * Get a single checklist
     */
    async preOpsChecklistsReadPreOpsChecklistRaw(requestParameters: PreOpsChecklistsReadPreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreOpsChecklist>> {
        if (requestParameters.checklistId === null || requestParameters.checklistId === undefined) {
            throw new runtime.RequiredError('checklistId','Required parameter requestParameters.checklistId was null or undefined when calling preOpsChecklistsReadPreOpsChecklist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_checklists/{checklist_id}`.replace(`{${"checklist_id"}}`, encodeURIComponent(String(requestParameters.checklistId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreOpsChecklistFromJSON(jsonValue));
    }

    /**
     * Returns the checklist specified by the given ID.
     * Get a single checklist
     */
    async preOpsChecklistsReadPreOpsChecklist(requestParameters: PreOpsChecklistsReadPreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreOpsChecklist> {
        const response = await this.preOpsChecklistsReadPreOpsChecklistRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the checklist specified by the given ID.
     * Update a pre-ops checklist
     */
    async preOpsChecklistsUpdatePreOpsChecklistRaw(requestParameters: PreOpsChecklistsUpdatePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreOpsChecklist>> {
        if (requestParameters.checklistId === null || requestParameters.checklistId === undefined) {
            throw new runtime.RequiredError('checklistId','Required parameter requestParameters.checklistId was null or undefined when calling preOpsChecklistsUpdatePreOpsChecklist.');
        }

        if (requestParameters.preOpsChecklistUpdate === null || requestParameters.preOpsChecklistUpdate === undefined) {
            throw new runtime.RequiredError('preOpsChecklistUpdate','Required parameter requestParameters.preOpsChecklistUpdate was null or undefined when calling preOpsChecklistsUpdatePreOpsChecklist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_checklists/{checklist_id}`.replace(`{${"checklist_id"}}`, encodeURIComponent(String(requestParameters.checklistId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PreOpsChecklistUpdateToJSON(requestParameters.preOpsChecklistUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreOpsChecklistFromJSON(jsonValue));
    }

    /**
     * Update the checklist specified by the given ID.
     * Update a pre-ops checklist
     */
    async preOpsChecklistsUpdatePreOpsChecklist(requestParameters: PreOpsChecklistsUpdatePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreOpsChecklist> {
        const response = await this.preOpsChecklistsUpdatePreOpsChecklistRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new checklist without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a checklist
     */
    async preOpsChecklistsValidateCreatePreOpsChecklistRaw(requestParameters: PreOpsChecklistsValidateCreatePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.preOpsChecklistCreate === null || requestParameters.preOpsChecklistCreate === undefined) {
            throw new runtime.RequiredError('preOpsChecklistCreate','Required parameter requestParameters.preOpsChecklistCreate was null or undefined when calling preOpsChecklistsValidateCreatePreOpsChecklist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_checklists/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PreOpsChecklistCreateToJSON(requestParameters.preOpsChecklistCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new checklist without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a checklist
     */
    async preOpsChecklistsValidateCreatePreOpsChecklist(requestParameters: PreOpsChecklistsValidateCreatePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.preOpsChecklistsValidateCreatePreOpsChecklistRaw(requestParameters, initOverrides);
    }

    /**
     * Validate data for updating the given checklist, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a pre-ops checklist update
     */
    async preOpsChecklistsValidateUpdatePreOpsChecklistRaw(requestParameters: PreOpsChecklistsValidateUpdatePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.checklistId === null || requestParameters.checklistId === undefined) {
            throw new runtime.RequiredError('checklistId','Required parameter requestParameters.checklistId was null or undefined when calling preOpsChecklistsValidateUpdatePreOpsChecklist.');
        }

        if (requestParameters.preOpsChecklistUpdate === null || requestParameters.preOpsChecklistUpdate === undefined) {
            throw new runtime.RequiredError('preOpsChecklistUpdate','Required parameter requestParameters.preOpsChecklistUpdate was null or undefined when calling preOpsChecklistsValidateUpdatePreOpsChecklist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pre_ops_checklists/{checklist_id}/validate`.replace(`{${"checklist_id"}}`, encodeURIComponent(String(requestParameters.checklistId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PreOpsChecklistUpdateToJSON(requestParameters.preOpsChecklistUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for updating the given checklist, without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a pre-ops checklist update
     */
    async preOpsChecklistsValidateUpdatePreOpsChecklist(requestParameters: PreOpsChecklistsValidateUpdatePreOpsChecklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.preOpsChecklistsValidateUpdatePreOpsChecklistRaw(requestParameters, initOverrides);
    }

}
