/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ManagementRole {
    RESTRICTED = 'restricted',
    INSTANCE_ADMIN = 'instanceAdmin',
    SUPER_ADMIN = 'superAdmin'
}


export function ManagementRoleFromJSON(json: any): ManagementRole {
    return ManagementRoleFromJSONTyped(json, false);
}

export function ManagementRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementRole {
    return json as ManagementRole;
}

export function ManagementRoleToJSON(value?: ManagementRole | null): any {
    return value as any;
}

