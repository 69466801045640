import * as React from "react";
import css from "./navigation.scss";

export interface NavigationProps {
    readonly children?: React.ReactNode;
}

export class Navigation extends React.Component<NavigationProps> {
    public render(): JSX.Element {
        return <ul className={css.navigation}>{this.props.children}</ul>;
    }
}
