import * as React from "react";
import { omit } from "ramda";
import { IColumn, Selection } from "@fluentui/react";
import { action, computed, makeObservable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { createUuid, UUID } from "../../utils/uuid";
import { SafetyMessage, SafetyMessageSortKey } from "../../api";
import { observer } from "mobx-react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { SortState } from "../../utils/sort-state";
import { PaginationState } from "../../utils/pagination-state";
import { LayoutList } from "../layouts/layout-list";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import { SelectionState } from "../../utils/selection-state";
import {
    RepositorySafetyMessages,
    SafetyMessagesQuery,
} from "../../domain/repositories/repository-safety-messages";
import { BooleanIcon } from "../atoms/boolean-icon";
import { defaultPageSize } from "../../utils/constants";

export interface ListSafetyMessagesProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly commandBar?: JSX.Element;
    readonly listStateId?: UUID;
}

interface ListSafetyMessagesItem {
    readonly key: UUID;
    readonly body: string;
    readonly active: boolean;
}

@external
@observer
export class ListSafetyMessages extends React.Component<ListSafetyMessagesProps> {
    @inject private readonly repositorySafetyMessages!: RepositorySafetyMessages;
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;

    private _listStateId = createUuid();
    private selection = new Selection({
        onSelectionChanged: () => this.updateSelection(),
    });

    constructor(props: ListSafetyMessagesProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.safetyMessages.initializeList(this.listStateId, {
            query: () => this.query,
        });
    }

    @computed private get listStateId(): UUID {
        return this.props.listStateId ?? this._listStateId;
    }

    @computed private get sortState(): SortState<SafetyMessageSortKey> {
        return this.serviceListStates.safetyMessages.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<
        RepositorySafetyMessages["apiResource"]
    > {
        return this.serviceListStates.safetyMessages.getPaginationState(this.listStateId);
    }
    @computed private get selectionState(): SelectionState {
        return this.serviceListStates.safetyMessages.getSelectionState(this.listStateId);
    }

    @computed private get query(): SafetyMessagesQuery {
        return { pageSize: defaultPageSize, ...this.sortState.query };
    }

    @computed private get safetyMessages(): (SafetyMessage | undefined)[] {
        if (this.props.ids) {
            return this.props.ids.map((id) => this.repositorySafetyMessages.byId(id));
        }
        return this.repositorySafetyMessages.byQuery(this.query, this.paginationState.pagination);
    }

    @computed private get items(): (ListSafetyMessagesItem | undefined)[] {
        return this.safetyMessages.map((safetyMessage) => {
            if (!safetyMessage) {
                return;
            }

            return {
                key: safetyMessage.id,
                body: safetyMessage.body,
                active: safetyMessage.active,
            };
        });
    }

    @action.bound private updateSelection(): void {
        // Update the listState's selectionState to the new selected keys.
        this.selectionState.updateSelection(
            this.selection.getSelection().map((item) => item.key as UUID),
        );
    }

    private renderItem(item: ListSafetyMessagesItem, column: IColumn): JSX.Element {
        switch (column.key) {
            case "body":
                return <span>{item.body}</span>;
            case "active":
                if (item.active) {
                    return <BooleanIcon status={true} />;
                } else {
                    return <BooleanIcon status={false} />;
                }
            default:
                return <></>;
        }
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.safetyMessages.isInitialized(this.listStateId)) {
            return <></>;
        }
        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(["ids"], this.props);
        return (
            <LayoutList
                paginationState={this.paginationState}
                commandBar={this.props.commandBar ? this.props.commandBar : undefined}
            >
                <ElofleetList
                    {...props}
                    selection={this.selection}
                    onColumnHeaderClick={this.sortState.toggleColumn}
                    columns={this.sortState.patchColumns([
                        {
                            fieldName: "body",
                            name: this.i18n.t("listSafetyMessages.column.body.name"),
                            key: "body",
                            minWidth: 100,
                            maxWidth: 800,
                        },
                        {
                            fieldName: "active",
                            name: this.i18n.t("listSafetyMessages.column.active.name"),
                            key: "active",
                            minWidth: 150,
                        },
                    ])}
                    items={this.items}
                    onRenderItemColumn={(item, _index, column) => this.renderItem(item, column!)}
                />
            </LayoutList>
        );
    }
}
