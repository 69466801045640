import * as React from "react";
import { equals, pick } from "ramda";
import { observer } from "mobx-react";
import {
    Stack,
    TextField,
    DefaultButton,
    Separator,
    getTheme,
    DatePicker,
    Toggle,
    PivotItem,
    Pivot,
} from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";

import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import {
    doubleBindNullableString,
    doubleBindNullableStringEmptyAsNull,
    doubleBindString,
} from "../../utils/double-bind";
import { ManagementRole, FleetRole, UserUpdate, Department, Shift, Site } from "../../api";
import { RepositoryUsers } from "../../domain/repositories/repository-users";
import { User } from "../../api";
import { FormUpdateProps } from "../../utils/form-update-props";
import { createUuid, UUID } from "../../utils/uuid";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { ClearableComboBox } from "../atoms/clearable-combo-box";
import { ModalConfirmation } from "../atoms/modal-confirmation";
import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { ConnectedComboBox } from "../atoms/connected-combo-box";
import { RepositoryShifts } from "../../domain/repositories/repository-shifts";
import { RepositorySites } from "../../domain/repositories/repository-sites";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";
import { ImageUploader } from "../atoms/image-uploader";
import { ServiceOwnUser } from "../../domain/services/service-own-user";
import UserDataExportButton from "./user-data-export-button";
import { defaultPageSize } from "../../utils/constants";

const theme = getTheme();

export interface FormUpdateUserProps extends FormUpdateProps<User> {}

@observer
@external
export class FormUpdateUser extends React.Component<FormUpdateUserProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly repositoryDepartments!: RepositoryDepartments;
    @inject private readonly repositoryShifts!: RepositoryShifts;
    @inject private readonly repositorySites!: RepositorySites;
    @inject private serviceOwnUser!: ServiceOwnUser;

    @observable public password: string | undefined;
    @observable public passwordRepeat: string | undefined;
    @observable public email: string | undefined;
    @observable private cancelConfirmationModalVisible = false;
    @observable private fleetRoleDeletionConfirmationModalVisible = false;
    @observable private fleetRoleDeletionConfirmed = false;

    // Properties that aren't used during render and don't need to be observable
    private imageBytes: Uint8Array | undefined;
    private validationId = createUuid();
    private deletedFleetRole = false;

    constructor(props: FormUpdateUserProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private triggerValidation(): void {
        this.repositoryUsers.validation.update.updateModel(this.validationId, this.userUpdate);
    }

    @initialize protected initialize(): void {
        // Don't initialize if we don't have props yet.
        // This will otherwise fail.
        if (!this.props) {
            return;
        }
        this.repositoryUsers.validation.update.initializeModel(
            this.validationId,
            this.userUpdate,
            this.props.id,
        );
    }

    public componentWillUnmount(): void {
        this.repositoryUsers.discardMutableCopy(FormUpdateUser.name, this.props.id);
    }

    @computed private get user(): User | undefined {
        return this.repositoryUsers.mutableCopyById(FormUpdateUser.name, this.props.id);
    }

    @computed private get userUpdate(): UserUpdate {
        const userUpdate: UserUpdate = pick(
            [
                "email",
                "employeeId",
                "password",
                "firstName",
                "lastName",
                "managementRole",
                "fleetRole",
                "jobTitle",
                "departmentId",
                "shiftId",
                "siteId",
                "licenseExpiryDate",
                "expiryLogout",
                "nfcToken",
                "nfcTokenDescription",
            ],
            this.user,
        );

        // The password cannot exist on the original [User] object.
        // Hence we save it on the form and manually set it the [UserUpdate].
        if (this.password !== undefined) {
            userUpdate.password = this.password;
        }

        if (this.email !== undefined) {
            userUpdate.email = this.email;
        }

        return userUpdate;
    }

    @action.bound
    private async updateUser(evt?: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt?.preventDefault();
        if (this.deletedFleetRole && !this.fleetRoleDeletionConfirmed) {
            this.fleetRoleDeletionConfirmationModalVisible = true;
            return;
        }
        const user = await this.repositoryUsers.update(
            this.props.id,
            this.userUpdate,
            this.imageBytes,
        );
        this.props.onUpdate(user);
    }

    /**
     * This is run, if the user clicks the form's cancel button.
     * A confirmation modal might pop up, if the user did any changes.
     */
    @action.bound private showConfirmAndCallDialogCancelCallback(): void {
        // If there were any changes, i.e. if the original and mutable copy aren't the same,
        // open the confirmation modal for aborting the update process.
        const original = this.repositoryUsers.byId(this.props.id);
        if (!equals(original, this.user)) {
            this.cancelConfirmationModalVisible = true;
            return;
        }

        this.props.onDialogCancel();
    }

    @action.bound private closeCancelConfirmationModal(): void {
        this.cancelConfirmationModalVisible = false;
    }

    @action.bound private closeFleetRoleDeletionConfirmationModal(): void {
        this.fleetRoleDeletionConfirmationModalVisible = false;
    }

    @action.bound private setManagementRole(managementRole?: ManagementRole): void {
        if (this.user === undefined) {
            throw new Error("Can't update role: User not loaded.");
        }

        this.user.managementRole = managementRole ?? null;
        this.triggerValidation();
    }

    @action.bound private setDepartment(departmentId?: UUID): void {
        if (this.user === undefined) {
            throw new Error("Can't update department: User not loaded.");
        }
        this.user.departmentId = departmentId ?? null;
        this.triggerValidation();
    }

    @action.bound private setShift(shiftId?: UUID): void {
        if (this.user === undefined) {
            throw new Error("Can't update shift: User not loaded.");
        }
        this.user.shiftId = shiftId ?? null;
        this.triggerValidation();
    }

    @action.bound private setSite(siteId?: UUID): void {
        if (this.user === undefined) {
            throw new Error("Can't update site: User not loaded.");
        }
        this.user.siteId = siteId ?? null;
        this.triggerValidation();
    }

    @action.bound private setFleetRole(fleetRole?: FleetRole): void {
        if (this.user === undefined) {
            throw new Error("Can't update role: User not loaded.");
        }

        // If the original user has a fleet role assigned and it is removed, set this flag.
        this.deletedFleetRole = this.user.fleetRole !== undefined && fleetRole === undefined;

        this.user.fleetRole = fleetRole ?? null;
        this.triggerValidation();
    }

    @action.bound private setLicenseExpiryDate(date: Date | null | undefined): void {
        if (this.user === undefined) {
            throw new Error("Can't update license expiry date: User not loaded.");
        }
        this.user.licenseExpiryDate = date ?? undefined;
        this.triggerValidation();
    }

    @action.bound private setExpiryLogout(
        _event: unknown,
        expiryLogout: boolean | undefined,
    ): void {
        if (this.user === undefined) {
            throw new Error("Can't update expiry logout: User not loaded.");
        }
        this.user.expiryLogout = expiryLogout ?? false;
        this.triggerValidation();
    }

    @action.bound private setPassword(_: unknown, password: string | undefined): void {
        // Empty strings fail validation, hence we set the value to null.
        this.password = password === "" ? undefined : password;
        this.triggerValidation();
    }

    @action.bound private setPasswordRepeat(_: unknown, password: string | undefined): void {
        // Empty strings fail validation, hence we set the value to null.
        this.passwordRepeat = password === "" ? undefined : password;
        this.triggerValidation();
    }

    @action.bound private setEmail(
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newEmail?: string | undefined,
    ): void {
        if (!this.user) {
            throw new Error("Can't update Email: user not loaded.");
        }
        this.user.email = newEmail == "" ? null : newEmail;
        this.triggerValidation();
    }

    @action.bound private setImage(bytes?: Uint8Array): void {
        this.imageBytes = bytes;
    }

    // Only require a password if the user management Role wasn't previously set.
    // This is because existing passwords can not be displayed and the
    // password input will be empty in those cases.
    @action.bound private passwordRequired(): boolean {
        const original = this.repositoryUsers.byId(this.props.id);
        if (original === undefined) {
            return false;
        }

        const hadManagementRole = original.managementRole !== undefined;
        if (!hadManagementRole) {
            // Check whether the form now has a management role.
            return !!this.userUpdate.managementRole;
        }

        return false;
    }

    @computed private get passwordRepeatValid(): boolean {
        return this.password === this.passwordRepeat;
    }

    @computed private get passwordRepeatErrorMessage(): string | undefined {
        if (this.passwordRepeatValid) {
            return;
        }
        return this.i18n.t("formUpdateUser.passwordRepeatInvalid");
    }

    public onFormatDate = (date?: Date): string => {
        return this.i18n.formatDateOnly(date!);
    };

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formUpdateUser.submit.text")}
                validationId={this.validationId}
                validation={this.repositoryUsers.validation.update}
                additionalCondition={this.passwordRepeatValid}
            />
        );
        return (
            <form onSubmit={this.updateUser}>
                <Stack tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                    <Stack
                        horizontal
                        horizontalAlign="space-between"
                        tokens={{
                            childrenGap: "2em",
                        }}
                    >
                        <ImageUploader
                            onImageChanged={this.setImage}
                            label={this.i18n.t("formUpdateUser.image.label")}
                            initialImage={this.user?.imageId}
                            placeholderIcon="ContactCard"
                        />
                        <Stack>
                            <TextField
                                label={this.i18n.t("formUpdateUser.employeeId.label")}
                                disabled={!this.user}
                                {...doubleBindString(this.user!, "employeeId", () =>
                                    this.triggerValidation(),
                                )}
                                required
                                errorMessage={this.i18n.formatFieldValidationState(
                                    this.repositoryUsers.validation.update.getFieldValidationState(
                                        this.validationId,
                                        "employeeId",
                                    ),
                                )}
                            />
                            <TextField
                                label={this.i18n.t("formUpdateUser.lastName.label")}
                                disabled={!this.user}
                                {...doubleBindString(this.user!, "lastName", () =>
                                    this.triggerValidation(),
                                )}
                                required
                                errorMessage={this.i18n.formatFieldValidationState(
                                    this.repositoryUsers.validation.update.getFieldValidationState(
                                        this.validationId,
                                        "lastName",
                                    ),
                                )}
                            />
                            <TextField
                                label={this.i18n.t("formUpdateUser.firstName.label")}
                                disabled={!this.user}
                                {...doubleBindString(this.user!, "firstName", () =>
                                    this.triggerValidation(),
                                )}
                                required
                                errorMessage={this.i18n.formatFieldValidationState(
                                    this.repositoryUsers.validation.update.getFieldValidationState(
                                        this.validationId,
                                        "firstName",
                                    ),
                                )}
                            />
                        </Stack>
                        <Stack>
                            <TextField
                                label={this.i18n.t("formUpdateUser.jobTitle.label")}
                                disabled={!this.user}
                                {...doubleBindNullableString(this.user!, "jobTitle", () =>
                                    this.triggerValidation(),
                                )}
                                errorMessage={this.i18n.formatFieldValidationState(
                                    this.repositoryUsers.validation.update.getFieldValidationState(
                                        this.validationId,
                                        "jobTitle",
                                    ),
                                )}
                            />
                            <ConnectedComboBox
                                formatEntity={(department: Department) =>
                                    this.i18n.formatDepartment(department)
                                }
                                repository={this.repositoryDepartments}
                                query={{ pageSize: defaultPageSize }}
                                label={this.i18n.t("formUpdateUser.department.label")}
                                onChange={this.setDepartment}
                                clearable
                                selectedKey={this.user?.departmentId}
                                errorMessage={this.i18n.formatFieldValidationState(
                                    this.repositoryUsers.validation.create.getFieldValidationState(
                                        this.validationId,
                                        "departmentId",
                                    ),
                                )}
                            />
                        </Stack>
                    </Stack>
                    <Separator></Separator>
                    <Stack
                        horizontal
                        horizontalAlign="space-between"
                        tokens={{
                            childrenGap: "2em",
                        }}
                        // When the Current Tab is changed, the Height of the Current Form also changes,
                        // so there is a shift of the image. Fixed height was given to prevent this.
                        style={{ minHeight: "260px" }}
                    >
                        <Pivot linkSize="large">
                            <PivotItem
                                headerText={this.i18n.t("formUpdateUser.fleetUser.tab.label")}
                                itemIcon="People"
                            >
                                <Stack
                                    horizontal
                                    horizontalAlign="space-between"
                                    tokens={{
                                        childrenGap: "2em",
                                    }}
                                    style={{
                                        paddingTop: theme.spacing.l1,
                                    }}
                                >
                                    <Stack>
                                        <ClearableComboBox
                                            label={this.i18n.t("formUpdateUser.fleetRole.label")}
                                            options={Object.values(FleetRole).map((role) => ({
                                                key: role,
                                                text: this.i18n.formatFleetRole(role),
                                            }))}
                                            selectedKey={this.user?.fleetRole}
                                            onChange={this.setFleetRole}
                                            errorMessage={this.i18n.formatFieldValidationState(
                                                this.repositoryUsers.validation.update.getFieldValidationState(
                                                    this.validationId,
                                                    "fleetRole",
                                                ),
                                            )}
                                        />

                                        <TextField
                                            label={this.i18n.t(
                                                "formUpdateUser.nfcTokenDescription.label",
                                            )}
                                            disabled={!this.user}
                                            {...doubleBindNullableStringEmptyAsNull(
                                                this.user!,
                                                "nfcTokenDescription",
                                                () => this.triggerValidation(),
                                            )}
                                            errorMessage={this.i18n.formatFieldValidationState(
                                                this.repositoryUsers.validation.update.getFieldValidationState(
                                                    this.validationId,
                                                    "nfcTokenDescription",
                                                ),
                                            )}
                                        />
                                        <TextField
                                            label={this.i18n.t("formUpdateUser.nfcToken.label")}
                                            disabled={!this.user}
                                            {...doubleBindNullableStringEmptyAsNull(
                                                this.user!,
                                                "nfcToken",
                                                () => {
                                                    if (this.user?.nfcToken) {
                                                        this.user.nfcToken = this.user.nfcToken
                                                            .replace(/:/g, "")
                                                            .match(/.{1,2}/g)!
                                                            .join(":");
                                                    }
                                                    this.triggerValidation();
                                                },
                                            )}
                                            errorMessage={this.i18n.formatFieldValidationState(
                                                this.repositoryUsers.validation.update.getFieldValidationState(
                                                    this.validationId,
                                                    "nfcToken",
                                                ),
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                        <ConnectedComboBox
                                            formatEntity={(shift: Shift) =>
                                                this.i18n.formatShift(shift)
                                            }
                                            repository={this.repositoryShifts}
                                            query={{ pageSize: defaultPageSize }}
                                            label={this.i18n.t("formUpdateUser.shift.label")}
                                            onChange={this.setShift}
                                            clearable
                                            selectedKey={this.user?.shiftId}
                                            errorMessage={this.i18n.formatFieldValidationState(
                                                this.repositoryUsers.validation.create.getFieldValidationState(
                                                    this.validationId,
                                                    "shiftId",
                                                ),
                                            )}
                                        />
                                        <ConnectedComboBox
                                            formatEntity={(site: Site) =>
                                                this.i18n.formatShift(site)
                                            }
                                            repository={this.repositorySites}
                                            query={{ pageSize: defaultPageSize }}
                                            label={this.i18n.t("formUpdateUser.site.label")}
                                            onChange={this.setSite}
                                            clearable
                                            selectedKey={this.user?.siteId}
                                            errorMessage={this.i18n.formatFieldValidationState(
                                                this.repositoryUsers.validation.create.getFieldValidationState(
                                                    this.validationId,
                                                    "siteId",
                                                ),
                                            )}
                                        />
                                        <DatePicker
                                            formatDate={this.onFormatDate}
                                            placeholder={this.i18n.t(
                                                "formUpdateUser.licenseExpiryDate.placeholder",
                                            )}
                                            label={this.i18n.t(
                                                "formUpdateUser.licenseExpiryDate.label",
                                            )}
                                            value={this.user?.licenseExpiryDate ?? undefined}
                                            onSelectDate={this.setLicenseExpiryDate}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Toggle
                                            label={this.i18n.t("formUpdateUser.expiryLogout.label")}
                                            checked={this.user?.expiryLogout}
                                            onChange={this.setExpiryLogout}
                                        />
                                    </Stack>
                                </Stack>
                            </PivotItem>
                            <PivotItem
                                headerText={this.i18n.t("formUpdateUser.managementUser.tab.label")}
                                itemIcon="ContactCard"
                            >
                                <Stack
                                    horizontal
                                    horizontalAlign="space-between"
                                    tokens={{
                                        childrenGap: "2em",
                                    }}
                                    style={{
                                        paddingTop: theme.spacing.l2,
                                        paddingBottom: theme.spacing.l2,
                                    }}
                                >
                                    <Stack>
                                        <ClearableComboBox
                                            label={this.i18n.t(
                                                "formUpdateUser.managementRole.label",
                                            )}
                                            options={Object.values(ManagementRole)
                                                .filter((role) =>
                                                    this.serviceOwnUser.user?.managementRole ==
                                                    ManagementRole.SUPER_ADMIN
                                                        ? true
                                                        : role != ManagementRole.SUPER_ADMIN,
                                                )
                                                .map((role) => ({
                                                    key: role,
                                                    text: this.i18n.formatManagementRole(role),
                                                }))}
                                            selectedKey={this.user?.managementRole}
                                            onChange={this.setManagementRole}
                                            errorMessage={this.i18n.formatFieldValidationState(
                                                this.repositoryUsers.validation.update.getFieldValidationState(
                                                    this.validationId,
                                                    "managementRole",
                                                ),
                                            )}
                                        />
                                        <TextField
                                            label={this.i18n.t("formUpdateUser.email.label")}
                                            {...doubleBindNullableString(this.user!, "email", () =>
                                                this.triggerValidation(),
                                            )}
                                            required={!!this.user?.managementRole}
                                            onChange={this.setEmail}
                                            errorMessage={this.i18n.formatFieldValidationState(
                                                this.repositoryUsers.validation.update.getFieldValidationState(
                                                    this.validationId,
                                                    "email",
                                                ),
                                            )}
                                        />
                                    </Stack>
                                    <Stack>
                                        <TextField
                                            label={this.i18n.t("formUpdateUser.password.label")}
                                            type="password"
                                            required={this.passwordRequired()}
                                            canRevealPassword
                                            onChange={this.setPassword}
                                            errorMessage={this.i18n.formatFieldValidationState(
                                                this.repositoryUsers.validation.update.getFieldValidationState(
                                                    this.validationId,
                                                    "password",
                                                ),
                                            )}
                                        />
                                        <TextField
                                            label={this.i18n.t(
                                                "formUpdateUser.passwordRepeat.label",
                                            )}
                                            type="password"
                                            required={this.passwordRequired()}
                                            canRevealPassword
                                            onChange={this.setPasswordRepeat}
                                            errorMessage={this.passwordRepeatErrorMessage}
                                        />
                                    </Stack>
                                </Stack>
                            </PivotItem>
                        </Pivot>
                    </Stack>
                </Stack>
                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter
                        leftItems={this.user && <UserDataExportButton userId={this.user.id} />}
                    >
                        <DefaultButton
                            label={this.i18n.t("formUpdateUser.cancel.label")}
                            text={this.i18n.t("formUpdateUser.cancel.text")}
                            onClick={this.showConfirmAndCallDialogCancelCallback}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
                {
                    <>
                        <ModalConfirmation
                            isOpen={this.cancelConfirmationModalVisible}
                            title={this.i18n.t("modalAbortUpdate.title")}
                            text={this.i18n.t("modalAbortUpdate.description")}
                            onConfirm={this.props.onDialogCancel}
                            onCancel={this.closeCancelConfirmationModal}
                        />
                        <ModalConfirmation
                            isOpen={this.fleetRoleDeletionConfirmationModalVisible}
                            title={this.i18n.t("modalConfirmDeletion.title")}
                            text={this.i18n.t("formUpdateUser.modalConfirmFleetRoleDeletion")}
                            onConfirm={() => {
                                // Submit user update form.
                                this.closeFleetRoleDeletionConfirmationModal();
                                this.fleetRoleDeletionConfirmed = true;
                                this.updateUser();
                            }}
                            onCancel={this.closeFleetRoleDeletionConfirmationModal}
                        />
                    </>
                }
            </form>
        );
    }
}
