/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum StationaryRelay {
    ONE = 'one',
    TWO = 'two',
    THREE = 'three',
    FOUR = 'four'
}


export function StationaryRelayFromJSON(json: any): StationaryRelay {
    return StationaryRelayFromJSONTyped(json, false);
}

export function StationaryRelayFromJSONTyped(json: any, ignoreDiscriminator: boolean): StationaryRelay {
    return json as StationaryRelay;
}

export function StationaryRelayToJSON(value?: StationaryRelay | null): any {
    return value as any;
}

