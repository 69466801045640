import { SearchBox } from "@fluentui/react";
import { computed } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { ServiceHelp } from "../../domain/services/service-help";
import css from "./help-search.scss";

export interface HelpSidebarProps {}

@external
@observer
export class HelpSearch extends React.Component<HelpSidebarProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private serviceHelp!: ServiceHelp;

    @computed private get searchHint(): JSX.Element {
        const { isSearching, remainingSearchCharacters } = this.serviceHelp;
        if (!isSearching) {
            return <></>;
        }
        if (remainingSearchCharacters > 0) {
            return (
                <p className={css.helpSearch__hint}>
                    {this.i18n.t("helpSearch.queryTooShort", {
                        count: remainingSearchCharacters,
                    })}
                </p>
            );
        }

        const count = this.serviceHelp.searchResults?.articles.length ?? 0;
        return (
            <p className={css.helpSearch__hint}>
                {this.i18n.t("helpSearch.resultCount", { count })}
            </p>
        );
    }

    public render(): JSX.Element {
        return (
            <div className={css.helpSearch}>
                <SearchBox
                    placeholder={this.i18n.t("helpSearch.placeholder")}
                    onChange={(_, value?: string) => (this.serviceHelp.query = value ?? "")}
                    value={this.serviceHelp.query}
                    onClear={() => (this.serviceHelp.query = "")}
                />
                {this.searchHint}
            </div>
        );
    }
}
