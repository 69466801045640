import * as React from "react";
import { omit } from "ramda";
import { Selection, IColumn, IObjectWithKey } from "@fluentui/react";
import { action, computed, makeObservable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { createUuid, UUID } from "../../utils/uuid";
import { VehicleDevice, VehicleDeviceSortKey } from "../../api";
import {
    RepositoryVehicleDevices,
    VehicleDevicesApiResource,
    VehicleDevicesQuery,
} from "../../domain/repositories/repository-vehicle-devices";
import { observer } from "mobx-react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { SortState } from "../../utils/sort-state";
import { PaginationState } from "../../utils/pagination-state";
import { LayoutList } from "../layouts/layout-list";
import { ListVehicleDeviceItemCell } from "../atoms/list-vehicle-device-item-cell";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import { SelectionState } from "../../utils/selection-state";
import { defaultPageSize } from "../../utils/constants";

export interface ListVehicleDevicesProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly commandBar?: JSX.Element;
    readonly listStateId?: UUID;
}

interface ListVehicleDeviceItem extends IObjectWithKey {
    readonly key: UUID;
}

@external
@observer
export class ListVehicleDevices extends React.Component<ListVehicleDevicesProps> {
    @inject private readonly repositoryVehicleDevices!: RepositoryVehicleDevices;
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;

    private _listStateId = createUuid();

    constructor(props: ListVehicleDevicesProps) {
        super(props);
        makeObservable(this);
    }
    private selection = new Selection({
        onSelectionChanged: () => this.updateSelection(),
    });

    @initialize protected initialize(): void {
        this.serviceListStates.vehicleDevices.initializeList(this.listStateId, {
            query: () => this.query,
        });
    }

    @computed private get listStateId(): UUID {
        return this.props.listStateId ?? this._listStateId;
    }

    @computed private get sortState(): SortState<VehicleDeviceSortKey> {
        return this.serviceListStates.vehicleDevices.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<VehicleDevicesApiResource> {
        return this.serviceListStates.vehicleDevices.getPaginationState(this.listStateId);
    }

    @computed private get selectionState(): SelectionState {
        return this.serviceListStates.vehicleDevices.getSelectionState(this.listStateId);
    }
    @computed private get query(): VehicleDevicesQuery {
        return { pageSize: defaultPageSize, ...this.sortState.query };
    }

    @computed private get vehicleDevices(): (VehicleDevice | undefined)[] {
        if (this.props.ids) {
            return this.props.ids.map((id) => this.repositoryVehicleDevices.byId(id));
        }
        return this.repositoryVehicleDevices.byQuery(this.query, this.paginationState.pagination);
    }

    @computed private get items(): (ListVehicleDeviceItem | undefined)[] {
        return this.vehicleDevices.map((vehicleDevice) => {
            if (!vehicleDevice) {
                return;
            }

            return {
                key: vehicleDevice.id,
            };
        });
    }

    private renderItem(deviceId: UUID, column: IColumn): JSX.Element {
        return <ListVehicleDeviceItemCell vehicleDeviceId={deviceId} column={column} />;
    }

    @computed private get columns(): IColumn[] {
        const columns: IColumn[] = [
            {
                name: this.i18n.t("listVehicleDevices.column.serialNumber.name"),
                key: "serialNumber",
                minWidth: 100,
            },
            {
                name: this.i18n.t("listVehicleDevices.column.vehicle.id"),
                key: "vehicleId",
                minWidth: 300,
            },
            {
                name: this.i18n.t("listVehicleDevices.column.createdAt.name"),
                key: "createdAt",
                minWidth: 150,
            },
            {
                name: this.i18n.t("listVehicleDevices.column.lastUpdated.name"),
                key: "firmwareLastUpdatedAt",
                minWidth: 150,
            },
            {
                name: this.i18n.t("listVehicleDevices.column.currentFirmwareVersion.name"),
                key: "currentFirmwareVersion",
                minWidth: 200,
            },
        ];

        return columns;
    }

    @action.bound private updateSelection(): void {
        // Update the listState's selectionState to the new selected keys.
        this.selectionState.updateSelection(
            this.selection.getSelection().map((item) => item.key as UUID),
        );
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.vehicleDevices.isInitialized(this.listStateId)) {
            return <></>;
        }
        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(["ids"], this.props);
        return (
            <LayoutList paginationState={this.paginationState} commandBar={this.props.commandBar}>
                <ElofleetList
                    {...props}
                    // Disable right-click to stay consistent with other lists
                    onItemContextMenu={() => {}}
                    selection={this.selection}
                    onRenderItemColumn={(item: ListVehicleDeviceItem, _index, column) =>
                        this.renderItem(item.key, column!)
                    }
                    onColumnHeaderClick={this.sortState.toggleColumn}
                    columns={this.sortState.patchColumns(this.columns)}
                    items={this.items}
                />
            </LayoutList>
        );
    }
}
