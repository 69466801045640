import * as React from "react";
import { FormCreateProps } from "../../utils/form-create-props";
import { VehicleType, VehicleTypeCreate } from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryVehicleTypes } from "../../domain/repositories/repository-vehicle-types";
import { external, initialize, inject } from "tsdi";
import { action, computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { createUuid } from "../../utils/uuid";
import { pick } from "ramda";
import { DefaultButton, Stack, TextField } from "@fluentui/react";
import { doubleBindString } from "../../utils/double-bind";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import sizes from "../sizes.scss";

export interface FormCreateVehicleTypeProps extends FormCreateProps<VehicleType> {}

@external
@observer
export class FormCreateVehicleType extends React.Component<FormCreateVehicleTypeProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryVehicleTypes!: RepositoryVehicleTypes;

    @observable public label = "";

    private validationId = createUuid();

    constructor(props: FormCreateVehicleTypeProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositoryVehicleTypes.validation.create.updateModel(
            this.validationId,
            this.siteCreate,
        );
    }

    @initialize protected initialize(): void {
        this.repositoryVehicleTypes.validation.create.initializeModel(
            this.validationId,
            this.siteCreate,
        );
    }

    @computed private get siteCreate(): VehicleTypeCreate {
        return pick(["label"], this as FormCreateVehicleType);
    }

    @action.bound
    private async createVehicleType(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const site = await this.repositoryVehicleTypes.create(this.siteCreate);
        this.reset();
        if (this.props.onCreate) {
            this.props.onCreate(site);
        }
    }

    @action.bound private reset(): void {
        this.label = "";
        this.triggerValidation();
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formCreateVehicleType.submit.text")}
                validation={this.repositoryVehicleTypes.validation.create}
                validationId={this.validationId}
            />
        );

        return (
            <form onSubmit={this.createVehicleType}>
                <Stack tokens={{ padding: `0px ${sizes.formPaddingHorizontal}` }}>
                    <TextField
                        label={this.i18n.t("formCreateVehicleType.label.label")}
                        {...doubleBindString<FormCreateVehicleType>(this, "label", () =>
                            this.triggerValidation(),
                        )}
                        required
                        errorMessage={this.i18n.formatFieldValidationState(
                            this.repositoryVehicleTypes.validation.create.getFieldValidationState(
                                this.validationId,
                                "label",
                            ),
                        )}
                    />
                </Stack>
                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            label={this.i18n.t("formCreateVehicleType.cancel.label")}
                            text={this.i18n.t("formCreateVehicleType.cancel.text")}
                            onClick={this.props.onDialogCancel}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
            </form>
        );
    }
}
