import * as React from "react";
import { Icon, IPanelProps, Panel, TooltipHost, PanelType } from "@fluentui/react";
import classNames from "classnames";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryPersistentNotifications } from "../../domain/repositories/repository-persistent-notifications";
import css from "./notification-menu.scss";
import { NotificationPivotMenu } from "./notification-pivot-menu";

export interface NotificationMenuProps {}

@external
@observer
export class NotificationMenu extends React.Component<NotificationMenuProps> {
    @inject private i18n!: I18nProvider;
    @inject private repositoryPersistentNotifications!: RepositoryPersistentNotifications;

    @observable private menuOpened = false;

    private personaRef = React.createRef<HTMLDivElement>();
    constructor(props: NotificationMenuProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected async initialize(): Promise<void> {
        await this.repositoryPersistentNotifications.fetchAll();
    }

    @action.bound private async markNotificationRead(id: string): Promise<void> {
        await this.repositoryPersistentNotifications.update(id, { read: true });
    }

    @action.bound private async markAllNotificationsRead(): Promise<void> {
        for (const [key, value] of this.repositoryPersistentNotifications.entities) {
            if (!value.read) {
                await this.markNotificationRead(key);
            }
        }
    }

    @action.bound private openMenu(): void {
        this.menuOpened = true;
    }

    @action.bound private closeMenu(): void {
        this.menuOpened = false;
    }

    private renderNavigationContent(
        props?: IPanelProps,
        defaultRender?: (props?: IPanelProps) => JSX.Element | null,
    ): JSX.Element | null {
        // If defaultRender is not defined, we want to log an error.

        return (
            <div className={css.header}>
                <div role="heading" className={css.title}>
                    {this.i18n.t("component.notificationMenu.header")}
                </div>
                {defaultRender!(props)}
            </div>
        );
    }

    public render(): JSX.Element {
        const notifications = Array.from(this.repositoryPersistentNotifications.entities.values());
        notifications.sort((a, b) => b.triggeredAt.getTime() - a.triggeredAt.getTime());

        const unreadNotifications = notifications.reduce((unreadNotifications, notification) => {
            return unreadNotifications ? true : !notification.read;
        }, false);

        return (
            <div className={css.button}>
                <TooltipHost content={this.i18n.t("component.notificationMenu.tooltip")}>
                    <div
                        ref={this.personaRef}
                        onClick={this.openMenu}
                        className={classNames(css.icon, {
                            [css["icon--unread"]]: unreadNotifications,
                        })}
                    >
                        <Icon iconName="Ringer" />
                    </div>
                    <Panel
                        type={PanelType.custom}
                        customWidth={"380px"}
                        isOpen={this.menuOpened}
                        onDismiss={this.closeMenu}
                        isLightDismiss
                        onRenderNavigationContent={this.renderNavigationContent.bind(this)}
                    >
                        <NotificationPivotMenu
                            notifications={notifications}
                            markNotificationRead={this.markNotificationRead}
                            onClick={this.markAllNotificationsRead}
                        />
                    </Panel>
                </TooltipHost>
            </div>
        );
    }
}
