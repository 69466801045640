/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * We use a single version for all components/artifacts. Both number or hash field should be unique for any given version, but we include them both for descriptive reasons.
 * @export
 * @interface Version
 */
export interface Version {
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    major: number;
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    minor: number;
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    patch: number;
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    revisionNumber: number;
    /**
     * The first few letters of this version's git commit hash.
     * @type {string}
     * @memberof Version
     */
    shortHash: string;
}

/**
 * Check if a given object implements the Version interface.
 */
export function instanceOfVersion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "major" in value;
    isInstance = isInstance && "minor" in value;
    isInstance = isInstance && "patch" in value;
    isInstance = isInstance && "revisionNumber" in value;
    isInstance = isInstance && "shortHash" in value;

    return isInstance;
}

export function VersionFromJSON(json: any): Version {
    return VersionFromJSONTyped(json, false);
}

export function VersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Version {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'major': json['major'],
        'minor': json['minor'],
        'patch': json['patch'],
        'revisionNumber': json['revisionNumber'],
        'shortHash': json['shortHash'],
    };
}

export function VersionToJSON(value?: Version | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'major': value.major,
        'minor': value.minor,
        'patch': value.patch,
        'revisionNumber': value.revisionNumber,
        'shortHash': value.shortHash,
    };
}

