import * as React from "react";
import { observer } from "mobx-react";
import { PaginationState } from "../../utils/pagination-state";
import { CommandButton, Stack } from "@fluentui/react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { external, inject } from "tsdi";
import { ApiResource } from "../../utils/elofleet-repository";

export interface PaginationButtonsProps {
    readonly paginationState: PaginationState<ApiResource>;
}

@external
@observer
export class PaginationButtons extends React.Component<PaginationButtonsProps> {
    @inject private readonly i18n!: I18nProvider;

    public render(): JSX.Element {
        if (this.props.paginationState.paginationNotNeeded) {
            return <></>;
        }

        return (
            <Stack horizontal horizontalAlign="end">
                <CommandButton
                    key="left"
                    onClick={this.props.paginationState.previous}
                    disabled={this.props.paginationState.previousDisabled}
                    iconProps={{ iconName: "ChevronLeft" }}
                />

                <span
                    style={{
                        display: "inline-flex",
                        alignItems: "center",
                        padding: "0 1ch 0 1ch",
                    }}
                >
                    {this.i18n.t("component.paginationButtons.currentPage", {
                        page: this.props.paginationState.currentPage + 1,
                    })}
                </span>

                <CommandButton
                    key="right"
                    onClick={this.props.paginationState.next}
                    disabled={this.props.paginationState.nextDisabled}
                    iconProps={{ iconName: "ChevronRight" }}
                />
            </Stack>
        );
    }
}
