import { action, makeObservable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { RepositoryUserGroupAssignments } from "../../domain/repositories/repository-user-group-assignments";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { UUID } from "../../utils/uuid";
import { ListCommandButtons, ListCommandButtonsProps } from "./list-command-buttons";

export type ListCommandButtonsUserGroupAssignmentsProps = Omit<
    ListCommandButtonsProps<RepositoryUserGroupAssignments>,
    "list" | "createForm" | "listState"
> & {
    readonly userId: UUID;
};

@external
@observer
export class ListCommandButtonsUserGroupAssignments extends React.Component<ListCommandButtonsUserGroupAssignmentsProps> {
    @inject private readonly repositoryUserGroupAssignments!: RepositoryUserGroupAssignments;
    @inject private readonly serviceListStates!: ServiceListStates;

    constructor(props: ListCommandButtonsUserGroupAssignmentsProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private async deleteUserGroupAssignments(ids: UUID[]): Promise<void> {
        await this.repositoryUserGroupAssignments.delete(...ids);
        if (this.props.onDelete) {
            this.props.onDelete(ids);
        }
    }
    public render(): JSX.Element {
        return (
            <ListCommandButtons
                listState={this.serviceListStates.userGroupAssignments}
                {...this.props}
                onDelete={this.deleteUserGroupAssignments}
            />
        );
    }
}
