import { Icon } from "@fluentui/react";
import classNames from "classnames";
import * as React from "react";
import css from "./collapse-icon.scss";

export interface CollapseIconProps {
    readonly collapsed?: boolean;
    readonly onClick?: (evt: React.SyntheticEvent<HTMLElement>) => void;
}

export class CollapseIcon extends React.Component<CollapseIconProps> {
    public render(): JSX.Element {
        return (
            <div className={css.collapseIcon} onClick={this.props.onClick}>
                <Icon
                    className={classNames(css.collapseIcon__icon, {
                        [css["collapseIcon__icon--collapsed"]]: this.props.collapsed,
                        [css["collapseIcon__icon--expanded"]]: !this.props.collapsed,
                    })}
                    iconName="ChevronRightMed"
                />
            </div>
        );
    }
}
