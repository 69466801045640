import * as React from "react";
import { omit } from "ramda";
import { IColumn, IObjectWithKey, SelectionMode, Stack } from "@fluentui/react";
import { external, inject } from "tsdi";
import { createUuid, UUID } from "../../utils/uuid";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { LayoutList } from "../layouts/layout-list";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";

import data from "../../utils/mock/eloshield-data.json";
import { Pedestrian, ListPedestrianEventItemCell } from "../atoms/list-pedestrian-event-item-cell";

export interface ListPedestrianEventsProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly commandBar?: JSX.Element;
    readonly listStateId?: string;
}

interface ListPedestrianEventItem extends IObjectWithKey {
    readonly key: number;
    readonly data: Object;
}

@external
export class ListPedestrianEvents extends React.Component<ListPedestrianEventsProps> {
    @inject protected readonly i18n!: I18nProvider;

    private listStateId = createUuid();

    private get items(): (ListPedestrianEventItem | undefined)[] {
        return data["pedestrian"].map((pedestrianItem) => {
            if (!pedestrianItem) {
                return;
            }

            return {
                key: pedestrianItem.id,
                data: pedestrianItem,
            };
        });
    }

    private get columns(): IColumn[] {
        const columns = [
            {
                fieldName: "event-time",
                name: this.i18n.t("listEvents.column.time.name"),
                key: "event-time",
                minWidth: 150,
                maxWidth: 200,
            },
            {
                fieldName: "truck-id",
                name: this.i18n.t("listEvents.column.truck.id"),
                key: "truck-id",
                minWidth: 100,
                maxWidth: 200,
            },
            {
                fieldName: "eloshield-id",
                name: this.i18n.t("listEvents.column.eloshield.id"),
                key: "eloshield-id",
                minWidth: 150,
                maxWidth: 200,
            },
            {
                fieldName: "oem",
                name: this.i18n.t("listEvents.column.oem"),
                key: "oem",
                minWidth: 100,
                maxWidth: 200,
            },
            {
                fieldName: "oem-type",
                name: this.i18n.t("listEvents.column.oem.type"),
                key: "oem-type",
                minWidth: 100,
                maxWidth: 200,
            },
            {
                fieldName: "transgression",
                name: this.i18n.t("listEvents.column.transgression"),
                key: "transgression",
                minWidth: 150,
                maxWidth: 200,
            },
            {
                fieldName: "pedestrian-badge-id",
                name: this.i18n.t("listEvents.column.pedestrian.badge.id"),
                key: "pedestrian-badge-id",
                minWidth: 150,
                maxWidth: 200,
            },
            {
                fieldName: "duration",
                name: this.i18n.t("listEvents.column.duration"),
                key: "duration",
                minWidth: 150,
                maxWidth: 200,
            },
        ];

        return columns;
    }

    private renderItem(truckEventId: number, column: IColumn, item: Pedestrian): JSX.Element {
        return (
            <ListPedestrianEventItemCell truckEventId={truckEventId} column={column} item={item} />
        );
    }

    public onFormatDate = (date?: Date): string => {
        return this.i18n.formatDateOnly(date!);
    };

    public render(): JSX.Element {
        if (!this.listStateId) {
            return <></>;
        }
        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(["ids"], this.props);

        return (
            <Stack>
                <Stack>
                    <LayoutList commandBar={this.props.commandBar}>
                        <ElofleetList
                            {...props}
                            onColumnHeaderClick={() => {}}
                            // Disable right-click to stay consistent with other lists
                            onItemContextMenu={() => {}}
                            selectionMode={SelectionMode.none}
                            onRenderItemColumn={(item: Pedestrian, _index, column) =>
                                this.renderItem(item.key, column!, item)
                            }
                            columns={this.columns}
                            items={this.items}
                        />
                    </LayoutList>
                </Stack>
            </Stack>
        );
    }
}
