import * as React from "react";
import { Stack, ThemeProvider } from "@fluentui/react";
import ErrorBars from "../molecules/error-bars";
import { theme } from "../../utils/fluent-ui-theme";

export interface LayoutCenterProps {
    readonly children: JSX.Element;
    readonly footer?: () => JSX.Element;
    readonly background?: string;
}

export class LayoutCenter extends React.Component<LayoutCenterProps> {
    public render(): JSX.Element {
        return (
            <ThemeProvider theme={theme}>
                <Stack
                    verticalAlign="center"
                    verticalFill
                    grow
                    style={{ backgroundColor: this.props.background }}
                >
                    <Stack verticalAlign="center" verticalFill>
                        <ErrorBars />
                        <Stack horizontal horizontalAlign="center" grow>
                            {this.props.children}
                        </Stack>
                    </Stack>
                    <Stack verticalFill reversed>
                        {this.props.footer ? (
                            <Stack.Item>{this.props.footer()}</Stack.Item>
                        ) : undefined}
                    </Stack>
                </Stack>
            </ThemeProvider>
        );
    }
}
