import * as React from "react";
import { observer } from "mobx-react";
import {
    Stack,
    DefaultButton,
    TextField,
    Separator,
    IDropdownOption,
    Dropdown,
    IconButton,
    ActionButton,
} from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { createUuid, UUID } from "../../utils/uuid";
import {
    PreOpsChecklist,
    PreOpsChecklistCreate,
    PreOpsQuestionCreate,
    PreOpsQuestionSortKey,
    SortDirection,
} from "../../api";
import { FormCreateProps } from "../../utils/form-create-props";
import { doubleBindString } from "../../utils/double-bind";
import { PrimaryButtonValidation } from "../atoms/primary-button-validation";
import { RepositoryPreOpsChecklists } from "../../domain/repositories/repository-pre-ops-checklists";
import { RepositoryPreOpsQuestions } from "../../domain/repositories/repository-pre-ops-questions";
import { ElofleetDialogFooter } from "../atoms/elofleet-dialog-footer";
import sizes from "../sizes.scss";
import { defaultPageSize } from "../../utils/constants";

export interface FormCreatePreOpsChecklistProps extends FormCreateProps<PreOpsChecklist> {}

@observer
@external
export class FormCreatePreOpsChecklist extends React.Component<FormCreatePreOpsChecklistProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly repositoryPreOpsChecklists!: RepositoryPreOpsChecklists;
    @inject private readonly repositoryPreOpsQuestions!: RepositoryPreOpsQuestions;

    @observable public label = "";
    @observable public message = "";
    @observable public hoursUntilRecheck = 5;
    // Contains a list of validationIds and create structs for new questions.
    // Each question has their own validation state.
    @observable public questions: [UUID, PreOpsQuestionCreate][] = [];

    private validationId = createUuid();

    constructor(props: FormCreatePreOpsChecklistProps) {
        super(props);
        makeObservable(this);
    }

    private triggerValidation(): void {
        this.repositoryPreOpsChecklists.validation.create.updateModel(
            this.validationId,
            this.preOpsChecklistCreate,
        );
    }

    private triggerQuestionCreateValidation(
        validationId: UUID,
        create: PreOpsQuestionCreate,
    ): void {
        this.repositoryPreOpsQuestions.validation.create.updateModel(validationId, create);
    }

    @initialize protected initialize(): void {
        this.repositoryPreOpsChecklists.validation.create.initializeModel(
            this.validationId,
            this.preOpsChecklistCreate,
        );

        this.addQuestion();
    }

    @computed private get preOpsChecklistCreate(): PreOpsChecklistCreate {
        const { label } = this;

        return {
            label,
        };
    }

    // Compile the JSX for the question list of the form.
    private renderQuestions(): JSX.Element[] {
        // The options for responses are currently hard coded and not editable.
        const responseOptions: IDropdownOption[] = [
            {
                key: "good",
                text: this.i18n.t("formCreatePreOpsChecklist.responseOptions.good"),
                disabled: true,
            },
            {
                key: "ok",
                text: this.i18n.t("formCreatePreOpsChecklist.responseOptions.ok"),
                disabled: true,
            },
            {
                key: "critical",
                text: this.i18n.t("formCreatePreOpsChecklist.responseOptions.critical"),
                disabled: true,
            },
        ];

        return this.questions.map((question, index) => {
            // Only add labels to the very first question.
            let questionLabel = undefined;
            let responseOptionsLabel = undefined;
            // Only show the "required" marker for the first question
            let required = false;
            let canDeleteFirst = true;
            if (index === 0) {
                questionLabel = this.i18n.t("formCreatePreOpsChecklist.questions.label");
                responseOptionsLabel = this.i18n.t(
                    "formCreatePreOpsChecklist.responseOptions.label",
                );
                required = true;

                // Don't make the first question deletable, if it's the only one.
                if (this.questions.length === 1) {
                    canDeleteFirst = false;
                }
            }
            return (
                <Stack.Item key={index}>
                    <Stack
                        horizontal
                        tokens={{
                            childrenGap: "2rem",
                        }}
                    >
                        <Stack.Item
                            styles={{
                                root: {
                                    minWidth: "500px",
                                },
                            }}
                        >
                            <TextField
                                prefix={`${index + 1}.`}
                                required={required}
                                value={question[1].body}
                                label={questionLabel}
                                onChange={(event) =>
                                    this.changeQuestion(index, event.currentTarget.value)
                                }
                                errorMessage={this.i18n.formatFieldValidationState(
                                    this.repositoryPreOpsQuestions.validation.create.getFieldValidationState(
                                        question[0],
                                        "body",
                                    ),
                                )}
                            />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Dropdown
                                placeholder={this.i18n.t(
                                    "formCreatePreOpsChecklist.responseOptions.placeholder",
                                )}
                                label={responseOptionsLabel}
                                options={responseOptions}
                                multiSelect
                            />
                        </Stack.Item>
                        <Stack.Item align="end" style={{ marginLeft: "1rem" }}>
                            {canDeleteFirst && (
                                <IconButton
                                    iconProps={{ iconName: "Trash" }}
                                    onClick={() => this.deleteQuestion(index)}
                                />
                            )}
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            );
        });
    }

    @action.bound private changeQuestion(index: number, text: string): void {
        this.questions[index][1].body = text;

        // Trigger creation validation for this question
        this.triggerQuestionCreateValidation(this.questions[index][0], this.questions[index][1]);
    }

    @action.bound private deleteQuestion(index: number): void {
        this.questions.splice(index, 1);
    }

    @action.bound private addQuestion(): void {
        const validationId = createUuid();
        const create: PreOpsQuestionCreate = {
            body: "",
        };

        this.repositoryPreOpsQuestions.validation.create.initializeModel(validationId, create);
        this.questions.push([validationId, create]);
    }

    @action.bound
    private async createPreOpsChecklist(evt: React.SyntheticEvent<HTMLFormElement>): Promise<void> {
        evt.preventDefault();
        const preOpsChecklist = await this.repositoryPreOpsChecklists.create(
            this as PreOpsChecklistCreate,
        );
        for (const question of this.questions) {
            await this.repositoryPreOpsQuestions.create({
                body: question[1].body,
                checklistId: preOpsChecklist.id,
            } as PreOpsQuestionCreate);
        }

        // Reload the query for all questions once they're created.
        // The checklist is already displayed and reloaded in the background while
        // we're creating the individual questions, but not all questions exist at that point yet.
        await this.repositoryPreOpsQuestions.reloadQuery({
            pageSize: defaultPageSize,
            sortKey: PreOpsQuestionSortKey.POSITION,
            sortDirection: SortDirection.ASCENDING,
            checklistId: preOpsChecklist.id,
        });
        await this.repositoryPreOpsChecklists.reloadQuery({ pageSize: defaultPageSize });
        this.reset();
        if (this.props.onCreate) {
            this.props.onCreate(preOpsChecklist);
        }
    }

    @action.bound private reset(): void {
        this.label = "";
        this.message = "";
        this.hoursUntilRecheck = 5;
        this.questions = [];
    }

    public render(): JSX.Element {
        const primaryButton = (
            <PrimaryButtonValidation
                text={this.i18n.t("formCreatePreOpsChecklist.submit.text")}
                validation={this.repositoryPreOpsChecklists.validation.create}
                validationId={this.validationId}
            />
        );
        const questions = this.renderQuestions();
        return (
            <form onSubmit={this.createPreOpsChecklist}>
                <Stack
                    horizontal
                    horizontalAlign="space-between"
                    tokens={{
                        childrenGap: "2rem",
                        padding: `0px ${sizes.formPaddingHorizontal}`,
                    }}
                >
                    <Stack
                        tokens={{
                            childrenGap: "1em",
                        }}
                        styles={{
                            root: {
                                minWidth: "500px",
                            },
                        }}
                    >
                        <TextField
                            label={this.i18n.t("formCreatePreOpsChecklist.label.label")}
                            {...doubleBindString<FormCreatePreOpsChecklist>(this, "label", () =>
                                this.triggerValidation(),
                            )}
                            required
                            errorMessage={this.i18n.formatFieldValidationState(
                                this.repositoryPreOpsChecklists.validation.create.getFieldValidationState(
                                    this.validationId,
                                    "label",
                                ),
                            )}
                        />
                    </Stack>
                    <Stack verticalAlign="end" grow style={{ minWidth: "300px" }}>
                        <Stack.Item></Stack.Item>
                    </Stack>
                </Stack>
                <Separator />
                <Stack
                    tokens={{
                        childrenGap: "1em",
                        padding: `0px ${sizes.formPaddingHorizontal}`,
                    }}
                    styles={{
                        root: {
                            maxHeight: "50vh",
                            overflow: "auto",
                        },
                    }}
                >
                    {questions}
                    <Stack.Item key="add">
                        <ActionButton
                            iconProps={{ iconName: "Add" }}
                            text={this.i18n.t("formCreatePreOpsChecklist.addQuestion.text")}
                            onClick={this.addQuestion}
                        />
                    </Stack.Item>
                </Stack>

                {this.props.asDialogContent ? (
                    <ElofleetDialogFooter>
                        <DefaultButton
                            label={this.i18n.t("formCreatePreOpsChecklist.cancel.label")}
                            text={this.i18n.t("formCreatePreOpsChecklist.cancel.text")}
                            onClick={this.props.onDialogCancel}
                        />
                        {primaryButton}
                    </ElofleetDialogFooter>
                ) : (
                    <Stack horizontal horizontalAlign="end">
                        {primaryButton}
                    </Stack>
                )}
            </form>
        );
    }
}
