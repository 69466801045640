import * as React from "react";
import { observer } from "mobx-react";
import { IButtonProps, PrimaryButton, Shimmer, ShimmerElementType } from "@fluentui/react";
import { Validation, ValidationStatus } from "../../utils/validation";
import { UUID } from "../../utils/uuid";

export type PrimaryButtonValidationProps = Omit<
    IButtonProps,
    "type" | "primary" | "disabled" | "onRenderIcon" | "label"
> & {
    // All types of {@link Validation} must be accepted.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly validation?: Validation<any, any>;
    // All types of {@link ValidationState} must be accepted.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly validationId?: UUID;
    readonly additionalCondition?: boolean;

    readonly buttonDisabled?: boolean;
    readonly validationLoading?: boolean;
};

@observer
export class PrimaryButtonValidation extends React.Component<PrimaryButtonValidationProps> {
    public render(): JSX.Element {
        const { validationId, validation, text, ...rest } = this.props;

        let buttonDisabled = false;
        if (this.props.buttonDisabled) {
            buttonDisabled = this.props.buttonDisabled;
        } else if (validation && validationId) {
            buttonDisabled =
                !validation.isValid(validationId) || this.props.additionalCondition === false;
        }

        let validationLoading = false;
        if (this.props.validationLoading) {
            validationLoading = this.props.validationLoading;
        } else if (validation && validationId) {
            validationLoading =
                validation.getModelValidationStatus(validationId) === ValidationStatus.LOADING;
        }

        // The default height of a Fluent UI button.
        const buttonHeight = 32;

        return (
            <span
                style={{
                    position: "relative",
                    height: buttonHeight,
                    display: "inline-block",
                    // When buttons are included in a <Stack> but not wrapped in a
                    // <Stack.Item>, the font size for this span will be 0px,
                    // effectively disabling em-based margins. To fix this, we re-set the
                    // font size here.
                    fontSize: "1rem",
                }}
            >
                {/* Show the shimmer element behind the button as long as validation is loading */}
                <Shimmer
                    shimmerColors={{
                        shimmerWave: "rgb(205, 222, 255)",
                    }}
                    shimmerElements={[
                        {
                            type: ShimmerElementType.line,
                            height: buttonHeight,
                        },
                    ]}
                    isDataLoaded={!validationLoading}
                    style={{ position: "absolute", left: 0, top: 0, width: "100%" }}
                />
                <PrimaryButton
                    {...rest}
                    text={text}
                    label={text}
                    type="submit"
                    primary
                    disabled={buttonDisabled}
                    style={{
                        // If the validation is loading, make the button transparent to show
                        // the shimmer behind it. Otherwise, use the default background color
                        backgroundColor: validationLoading ? "rgba(1,1,1,0)" : undefined,
                    }}
                />
            </span>
        );
    }
}
