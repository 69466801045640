/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PageInfo,
  Phone,
  PhoneCreate,
  PhoneSortKey,
  SortDirection,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    PhoneFromJSON,
    PhoneToJSON,
    PhoneCreateFromJSON,
    PhoneCreateToJSON,
    PhoneSortKeyFromJSON,
    PhoneSortKeyToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface PhonesDeletePhoneRequest {
    phoneId: string;
}

export interface PhonesListPhonesRequest {
    pageSize: number;
    search?: string | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: PhoneSortKey;
}

export interface PhonesListPhonesMetadataRequest {
    pageSize: number;
    search?: string | null;
}

export interface PhonesReadPhoneRequest {
    phoneId: string;
}

export interface PhonesReadPhoneByVehicleDeviceRequest {
    vehicleDeviceId: string;
}

export interface PhonesUpsertPhoneRequest {
    phoneCreate: PhoneCreate;
}

export interface PhonesValidateUpsertPhoneRequest {
    phoneCreate: PhoneCreate;
}

/**
 * PhonesApi - interface
 * 
 * @export
 * @interface PhonesApiInterface
 */
export interface PhonesApiInterface {
    /**
     * Delete the phone with the given ID.
     * @summary Delete a phone
     * @param {string} phoneId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhonesApiInterface
     */
    phonesDeletePhoneRaw(requestParameters: PhonesDeletePhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the phone with the given ID.
     * Delete a phone
     */
    phonesDeletePhone(requestParameters: PhonesDeletePhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Lists all the phones present. If `search` is specified, return values similar to the `current_app_version` and `android_id` fields, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * @summary List phones
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {PhoneSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhonesApiInterface
     */
    phonesListPhonesRaw(requestParameters: PhonesListPhonesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Phone>>>;

    /**
     * Lists all the phones present. If `search` is specified, return values similar to the `current_app_version` and `android_id` fields, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List phones
     */
    phonesListPhones(requestParameters: PhonesListPhonesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Phone>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary Vehicle device listing metadata
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhonesApiInterface
     */
    phonesListPhonesMetadataRaw(requestParameters: PhonesListPhonesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * Vehicle device listing metadata
     */
    phonesListPhonesMetadata(requestParameters: PhonesListPhonesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the phone specified by the given ID.
     * @summary Get a single phone
     * @param {string} phoneId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhonesApiInterface
     */
    phonesReadPhoneRaw(requestParameters: PhonesReadPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Phone>>;

    /**
     * Returns the phone specified by the given ID.
     * Get a single phone
     */
    phonesReadPhone(requestParameters: PhonesReadPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Phone>;

    /**
     * Returns the phone specified by the given Vehicle Device ID.
     * @summary Get a single phone by its assigned vehicle device
     * @param {string} vehicleDeviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhonesApiInterface
     */
    phonesReadPhoneByVehicleDeviceRaw(requestParameters: PhonesReadPhoneByVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Phone>>;

    /**
     * Returns the phone specified by the given Vehicle Device ID.
     * Get a single phone by its assigned vehicle device
     */
    phonesReadPhoneByVehicleDevice(requestParameters: PhonesReadPhoneByVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Phone>;

    /**
     * Create a new phone.
     * @summary Create a phone
     * @param {PhoneCreate} phoneCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhonesApiInterface
     */
    phonesUpsertPhoneRaw(requestParameters: PhonesUpsertPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Phone>>;

    /**
     * Create a new phone.
     * Create a phone
     */
    phonesUpsertPhone(requestParameters: PhonesUpsertPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Phone>;

    /**
     * Validate data for a new phone without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a phone
     * @param {PhoneCreate} phoneCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhonesApiInterface
     */
    phonesValidateUpsertPhoneRaw(requestParameters: PhonesValidateUpsertPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new phone without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a phone
     */
    phonesValidateUpsertPhone(requestParameters: PhonesValidateUpsertPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class PhonesApi extends runtime.BaseAPI implements PhonesApiInterface {

    /**
     * Delete the phone with the given ID.
     * Delete a phone
     */
    async phonesDeletePhoneRaw(requestParameters: PhonesDeletePhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.phoneId === null || requestParameters.phoneId === undefined) {
            throw new runtime.RequiredError('phoneId','Required parameter requestParameters.phoneId was null or undefined when calling phonesDeletePhone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/phones/{phone_id}`.replace(`{${"phone_id"}}`, encodeURIComponent(String(requestParameters.phoneId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the phone with the given ID.
     * Delete a phone
     */
    async phonesDeletePhone(requestParameters: PhonesDeletePhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.phonesDeletePhoneRaw(requestParameters, initOverrides);
    }

    /**
     * Lists all the phones present. If `search` is specified, return values similar to the `current_app_version` and `android_id` fields, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List phones
     */
    async phonesListPhonesRaw(requestParameters: PhonesListPhonesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Phone>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling phonesListPhones.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/phones/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhoneFromJSON));
    }

    /**
     * Lists all the phones present. If `search` is specified, return values similar to the `current_app_version` and `android_id` fields, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List phones
     */
    async phonesListPhones(requestParameters: PhonesListPhonesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Phone>> {
        const response = await this.phonesListPhonesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * Vehicle device listing metadata
     */
    async phonesListPhonesMetadataRaw(requestParameters: PhonesListPhonesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling phonesListPhonesMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/phones/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * Vehicle device listing metadata
     */
    async phonesListPhonesMetadata(requestParameters: PhonesListPhonesMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.phonesListPhonesMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the phone specified by the given ID.
     * Get a single phone
     */
    async phonesReadPhoneRaw(requestParameters: PhonesReadPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Phone>> {
        if (requestParameters.phoneId === null || requestParameters.phoneId === undefined) {
            throw new runtime.RequiredError('phoneId','Required parameter requestParameters.phoneId was null or undefined when calling phonesReadPhone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/phones/{phone_id}`.replace(`{${"phone_id"}}`, encodeURIComponent(String(requestParameters.phoneId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneFromJSON(jsonValue));
    }

    /**
     * Returns the phone specified by the given ID.
     * Get a single phone
     */
    async phonesReadPhone(requestParameters: PhonesReadPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Phone> {
        const response = await this.phonesReadPhoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the phone specified by the given Vehicle Device ID.
     * Get a single phone by its assigned vehicle device
     */
    async phonesReadPhoneByVehicleDeviceRaw(requestParameters: PhonesReadPhoneByVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Phone>> {
        if (requestParameters.vehicleDeviceId === null || requestParameters.vehicleDeviceId === undefined) {
            throw new runtime.RequiredError('vehicleDeviceId','Required parameter requestParameters.vehicleDeviceId was null or undefined when calling phonesReadPhoneByVehicleDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/phones/by/vehicle_device/{vehicle_device_id}`.replace(`{${"vehicle_device_id"}}`, encodeURIComponent(String(requestParameters.vehicleDeviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneFromJSON(jsonValue));
    }

    /**
     * Returns the phone specified by the given Vehicle Device ID.
     * Get a single phone by its assigned vehicle device
     */
    async phonesReadPhoneByVehicleDevice(requestParameters: PhonesReadPhoneByVehicleDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Phone> {
        const response = await this.phonesReadPhoneByVehicleDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new phone.
     * Create a phone
     */
    async phonesUpsertPhoneRaw(requestParameters: PhonesUpsertPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Phone>> {
        if (requestParameters.phoneCreate === null || requestParameters.phoneCreate === undefined) {
            throw new runtime.RequiredError('phoneCreate','Required parameter requestParameters.phoneCreate was null or undefined when calling phonesUpsertPhone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/phones/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PhoneCreateToJSON(requestParameters.phoneCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneFromJSON(jsonValue));
    }

    /**
     * Create a new phone.
     * Create a phone
     */
    async phonesUpsertPhone(requestParameters: PhonesUpsertPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Phone> {
        const response = await this.phonesUpsertPhoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new phone without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a phone
     */
    async phonesValidateUpsertPhoneRaw(requestParameters: PhonesValidateUpsertPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.phoneCreate === null || requestParameters.phoneCreate === undefined) {
            throw new runtime.RequiredError('phoneCreate','Required parameter requestParameters.phoneCreate was null or undefined when calling phonesValidateUpsertPhone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/phones/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PhoneCreateToJSON(requestParameters.phoneCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new phone without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a phone
     */
    async phonesValidateUpsertPhone(requestParameters: PhonesValidateUpsertPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.phonesValidateUpsertPhoneRaw(requestParameters, initOverrides);
    }

}
