/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EmailLoginTokenCreate,
  ErrorResponse,
} from '../models';
import {
    EmailLoginTokenCreateFromJSON,
    EmailLoginTokenCreateToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface EmailLoginTokensCreateEmailLoginTokenRequest {
    emailLoginTokenCreate: EmailLoginTokenCreate;
}

export interface EmailLoginTokensValidateCreateEmailLoginTokenRequest {
    emailLoginTokenCreate: EmailLoginTokenCreate;
}

/**
 * EmailLoginTokensApi - interface
 * 
 * @export
 * @interface EmailLoginTokensApiInterface
 */
export interface EmailLoginTokensApiInterface {
    /**
     * Send a new email login token by email. This does not return the token as the purpose is to authenticate the user by their email address. Works only for users that have an email assigned. Will remove any previously existing email login tokens for the given user.
     * @summary Create an email login token
     * @param {EmailLoginTokenCreate} emailLoginTokenCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailLoginTokensApiInterface
     */
    emailLoginTokensCreateEmailLoginTokenRaw(requestParameters: EmailLoginTokensCreateEmailLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Send a new email login token by email. This does not return the token as the purpose is to authenticate the user by their email address. Works only for users that have an email assigned. Will remove any previously existing email login tokens for the given user.
     * Create an email login token
     */
    emailLoginTokensCreateEmailLoginToken(requestParameters: EmailLoginTokensCreateEmailLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Validate the create struct. Does not validate whether the user specified by the given email exists to avoid account enumeration attacks.
     * @summary Validate a new email login token
     * @param {EmailLoginTokenCreate} emailLoginTokenCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailLoginTokensApiInterface
     */
    emailLoginTokensValidateCreateEmailLoginTokenRaw(requestParameters: EmailLoginTokensValidateCreateEmailLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate the create struct. Does not validate whether the user specified by the given email exists to avoid account enumeration attacks.
     * Validate a new email login token
     */
    emailLoginTokensValidateCreateEmailLoginToken(requestParameters: EmailLoginTokensValidateCreateEmailLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class EmailLoginTokensApi extends runtime.BaseAPI implements EmailLoginTokensApiInterface {

    /**
     * Send a new email login token by email. This does not return the token as the purpose is to authenticate the user by their email address. Works only for users that have an email assigned. Will remove any previously existing email login tokens for the given user.
     * Create an email login token
     */
    async emailLoginTokensCreateEmailLoginTokenRaw(requestParameters: EmailLoginTokensCreateEmailLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.emailLoginTokenCreate === null || requestParameters.emailLoginTokenCreate === undefined) {
            throw new runtime.RequiredError('emailLoginTokenCreate','Required parameter requestParameters.emailLoginTokenCreate was null or undefined when calling emailLoginTokensCreateEmailLoginToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/email_login_tokens/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailLoginTokenCreateToJSON(requestParameters.emailLoginTokenCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send a new email login token by email. This does not return the token as the purpose is to authenticate the user by their email address. Works only for users that have an email assigned. Will remove any previously existing email login tokens for the given user.
     * Create an email login token
     */
    async emailLoginTokensCreateEmailLoginToken(requestParameters: EmailLoginTokensCreateEmailLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.emailLoginTokensCreateEmailLoginTokenRaw(requestParameters, initOverrides);
    }

    /**
     * Validate the create struct. Does not validate whether the user specified by the given email exists to avoid account enumeration attacks.
     * Validate a new email login token
     */
    async emailLoginTokensValidateCreateEmailLoginTokenRaw(requestParameters: EmailLoginTokensValidateCreateEmailLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.emailLoginTokenCreate === null || requestParameters.emailLoginTokenCreate === undefined) {
            throw new runtime.RequiredError('emailLoginTokenCreate','Required parameter requestParameters.emailLoginTokenCreate was null or undefined when calling emailLoginTokensValidateCreateEmailLoginToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/email_login_tokens/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailLoginTokenCreateToJSON(requestParameters.emailLoginTokenCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate the create struct. Does not validate whether the user specified by the given email exists to avoid account enumeration attacks.
     * Validate a new email login token
     */
    async emailLoginTokensValidateCreateEmailLoginToken(requestParameters: EmailLoginTokensValidateCreateEmailLoginTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.emailLoginTokensValidateCreateEmailLoginTokenRaw(requestParameters, initOverrides);
    }

}
