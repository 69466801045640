import { Icon } from "@fluentui/react";
import React from "react";
import forkliftIcon from "../../images/forklift_icon.svg?raw";

const customIcons = new Map([["Forklift", forkliftIcon]]);

export default function ElofleetIcon(props: {
    icon: string;
    className?: string;
    style?: React.CSSProperties;
}): JSX.Element {
    const customIcon = customIcons.get(props.icon);
    if (customIcon !== undefined) {
        return (
            // We can safely set unsanitized html here as long as `customIcons` only contains our own svg files
            // We're using inlined svg to be able to style
            // it using css
            <div
                dangerouslySetInnerHTML={{ __html: customIcon }}
                className={props.className}
                style={props.style}
            ></div>
        );
    }
    return <Icon iconName={props.icon} className={props.className} style={props.style} />;
}
