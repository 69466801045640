import { mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import * as React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Cell, Pie, PieChart, Sector } from "recharts";
import { formatCurrency } from "../../utils/currency";
import { UUID } from "../../utils/uuid";
import { ElodmsLayout } from "../components/layout";
import { CostType, Document, displayCostType } from "../stores/document";
import { useStore } from "../stores/store";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { displayEquipmentType } from "../stores/equipment";

export function ViewEquipment(): JSX.Element {
    const { equipmentId } = useParams();
    const { t }: { t: TFunction } = useTranslation();

    const equipment = useStore((state) => state.equipment[equipmentId!]);
    const documents: Array<[UUID, Document]> = useStore((state) =>
        Object.entries(state.documents).filter(
            ([_documentId, document]) => document.equipmentId === equipmentId,
        ),
    );

    const title = `
        ${t(equipment.oem ?? "")}
        ${t(equipment.modelName)}
        #${equipment.serialNumber ? equipment.serialNumber : ""}
    `;

    const totalCostOfOwnership = documents
        .filter(([_id, document]) => document.equipmentId === equipmentId)
        .flatMap(([_id, document]) => document.costItems)
        .map((costItem) => costItem.amount)
        .reduce((a, b) => a + b, 0);

    return (
        <ElodmsLayout title={title} className="pb-16">
            <div className="flex divide-x divide-slate-300 p-8">
                <div className="w-1/2 pr-8">
                    <h3 className="mb-2 font-header text-xl">
                        {displayEquipmentType(t, equipment.equipmentType)}
                    </h3>
                    <table>
                        <tbody>
                            <DetailsTableRow
                                key="oem"
                                label={t("dms.schema.equipment.oem")}
                                value={t(equipment.oem ?? "")}
                            />
                            <DetailsTableRow
                                key="Year of Manufacture"
                                label={t("dms.schema.equipment.yearOfManufacture")}
                                value={equipment.manufactureYear.toString()}
                            />
                            <DetailsTableRow
                                key="Department"
                                label={t("dms.schema.equipment.department")}
                                value={
                                    equipment.department
                                        ? t(equipment.department)
                                        : t("dms.schema.equipment.noDepartment")
                                }
                            />
                        </tbody>
                    </table>
                </div>
                <div className="w-1/2 pl-8">
                    <div className="my-2 align-text-bottom font-header">
                        {<>{t("dms.schema.equipment.totalCostOfOwnership")}</>}:{" "}
                        {formatCurrency(t, totalCostOfOwnership)}
                    </div>
                    <div className="flex items-center justify-center">
                        <CostChart documents={documents} />
                    </div>
                </div>
            </div>

            <table className="w-full">
                <thead className="h-16 border-t border-b-2 border-t-slate-300 border-b-blue-900 bg-slate-100 text-left font-header">
                    <tr>
                        <th className="pl-8 pr-4">
                            {<>{t("dms.page.viewEquipment.documents.documentName")}</>}
                        </th>
                        <th className="pr-4">
                            {<>{t("dms.page.viewEquipment.documents.costTypes")}</>}
                        </th>
                        <th className="pr-4">
                            {<>{t("dms.page.viewEquipment.documents.totalCost")}</>}
                        </th>
                        <th className="pr-8"></th>
                    </tr>
                </thead>
                <tbody>
                    {documents.map(([documentId, document]) => {
                        const costTypes = [
                            ...new Set(document.costItems.map((costItem) => costItem.type)),
                        ];
                        costTypes.sort();

                        const costAmount = document.costItems
                            .map((costItem) => costItem.amount)
                            .reduce((a, b) => a + b, 0);

                        return (
                            <tr
                                className="h-12 border-b border-b-slate-300 hover:bg-gray-100"
                                key={documentId}
                            >
                                <td className="pl-8 pr-4">
                                    <>{t(document.name)}</>
                                </td>
                                <td className="pr-4">
                                    {costTypes
                                        .map((costType) => displayCostType(t, costType))
                                        .join(", ")}
                                </td>
                                <td className="pr-4">
                                    {costTypes.length === 0 ? "" : formatCurrency(t, costAmount)}
                                </td>
                                <td className="w-20">
                                    {document.link !== undefined && (
                                        <Link
                                            to={document.link}
                                            target="_blank"
                                            // Make the link big so it's easily clickable
                                            className="flex h-12 items-center justify-end pr-8"
                                        >
                                            <Icon path={mdiChevronRight} className="h-6 w-6" />
                                        </Link>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </ElodmsLayout>
    );
}

function DetailsTableRow({ label, value }: { label: string; value: string }): JSX.Element {
    return (
        <tr className="h-10">
            <th className="pr-4 text-left font-header">{label}</th>
            <td>{value}</td>
        </tr>
    );
}

const chartColors: Record<CostType, string> = {
    [CostType.Purchase]: "fill-slate-400",
    [CostType.Rental]: "fill-slate-400",
    [CostType.Disposal]: "fill-slate-400",
    [CostType.Maintenance]: "fill-blue-900",
    [CostType.SpareParts]: "fill-blue-900",
    [CostType.Repair]: "fill-blue-900",
    [CostType.Upgrades]: "fill-neutral-500",
    [CostType.Training]: "fill-neutral-500",
};

function CostChart({ documents }: { documents: Array<[UUID, Document]> }): JSX.Element {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const { t }: { t: TFunction } = useTranslation();

    const costs = Object.entries(
        documents.reduce(
            (acc: Record<CostType, number>, [_id, document]: [UUID, Document]) => {
                for (const costItem of document.costItems) {
                    acc[costItem.type] += costItem.amount;
                }
                return acc;
            },
            {
                [CostType.Disposal]: 0,
                [CostType.Maintenance]: 0,
                [CostType.Purchase]: 0,
                [CostType.Rental]: 0,
                [CostType.Repair]: 0,
                [CostType.SpareParts]: 0,
                [CostType.Training]: 0,
                [CostType.Upgrades]: 0,
            },
        ),
    )
        .filter(([_costType, costAmount]) => costAmount > 0)
        .map(([costType, costAmount]) => ({
            name: displayCostType(t, costType as CostType),
            cost: costAmount,
            costType: costType as CostType,
            t,
        }));

    return (
        <PieChart width={500} height={380}>
            <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={costs}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                dataKey="cost"
                onMouseEnter={(_, index) => setActiveIndex(index)}
            >
                {costs.map(({ name, costType }) => (
                    <Cell key={name} className={chartColors[costType]} />
                ))}
            </Pie>
        </PieChart>
    );
}

const renderActiveShape = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    payload,
    percent,
    value,
}: {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    startAngle: number;
    endAngle: number;
    fill: string;
    payload: { name: string; costType: CostType; t: TFunction };
    percent: number;
    value: number;
}): React.ReactElement<SVGElement> => {
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    const fillClass = chartColors[payload.costType];

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill="currentColor">
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                className={fillClass}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill="currentColor"
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke="currentColor" fill="none" />
            <circle cx={ex} cy={ey} r={2} fill="currentColor" stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="currentColor"
            >
                {formatCurrency(payload.t, value)}
            </text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                className="fill-neutral-500"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        </g>
    );
};
