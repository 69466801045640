/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PreOpsAnswerCreate } from './PreOpsAnswerCreate';
import {
    PreOpsAnswerCreateFromJSON,
    PreOpsAnswerCreateFromJSONTyped,
    PreOpsAnswerCreateToJSON,
} from './PreOpsAnswerCreate';

/**
 * Used to create a full questionnaire for a given checklist, user and vehicle.
 * @export
 * @interface PreOpsAnswerGroupCreate
 */
export interface PreOpsAnswerGroupCreate {
    /**
     * The vehicle this questionnaire is filled out for.
     * @type {string}
     * @memberof PreOpsAnswerGroupCreate
     */
    vehicleId: string;
    /**
     * The user that filled out this questionnaire.
     * @type {string}
     * @memberof PreOpsAnswerGroupCreate
     */
    userId: string;
    /**
     * The time at which the answered questions have been pulled.
     * @type {Date}
     * @memberof PreOpsAnswerGroupCreate
     */
    questionsPulledAt: Date;
    /**
     * The ID of the checklist the answers are created for.
     * @type {string}
     * @memberof PreOpsAnswerGroupCreate
     */
    checklistId: string;
    /**
     * The list of all answers and their choices for this checklist.
     * @type {Array<PreOpsAnswerCreate>}
     * @memberof PreOpsAnswerGroupCreate
     */
    answers: Array<PreOpsAnswerCreate>;
    /**
     * The point in time when this checklist has been filled out.
     * @type {Date}
     * @memberof PreOpsAnswerGroupCreate
     */
    time: Date;
}

/**
 * Check if a given object implements the PreOpsAnswerGroupCreate interface.
 */
export function instanceOfPreOpsAnswerGroupCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vehicleId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "questionsPulledAt" in value;
    isInstance = isInstance && "checklistId" in value;
    isInstance = isInstance && "answers" in value;
    isInstance = isInstance && "time" in value;

    return isInstance;
}

export function PreOpsAnswerGroupCreateFromJSON(json: any): PreOpsAnswerGroupCreate {
    return PreOpsAnswerGroupCreateFromJSONTyped(json, false);
}

export function PreOpsAnswerGroupCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreOpsAnswerGroupCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicleId': json['vehicleId'],
        'userId': json['userId'],
        'questionsPulledAt': (new Date(json['questionsPulledAt'])),
        'checklistId': json['checklistId'],
        'answers': ((json['answers'] as Array<any>).map(PreOpsAnswerCreateFromJSON)),
        'time': (new Date(json['time'])),
    };
}

export function PreOpsAnswerGroupCreateToJSON(value?: PreOpsAnswerGroupCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicleId': value.vehicleId,
        'userId': value.userId,
        'questionsPulledAt': (value.questionsPulledAt.toISOString()),
        'checklistId': value.checklistId,
        'answers': ((value.answers as Array<any>).map(PreOpsAnswerCreateToJSON)),
        'time': (value.time.toISOString()),
    };
}

