/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MotionSensorSortKey {
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',
    FIRMWARE_LAST_UPDATED_AT = 'firmwareLastUpdatedAt',
    CURRENT_VERSION = 'currentVersion',
    VEHICLE_DEVICE_SERIAL_NUMBER = 'vehicleDeviceSerialNumber'
}


export function MotionSensorSortKeyFromJSON(json: any): MotionSensorSortKey {
    return MotionSensorSortKeyFromJSONTyped(json, false);
}

export function MotionSensorSortKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MotionSensorSortKey {
    return json as MotionSensorSortKey;
}

export function MotionSensorSortKeyToJSON(value?: MotionSensorSortKey | null): any {
    return value as any;
}

