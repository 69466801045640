import * as React from "react";
import { omit } from "ramda";
import { Selection, IObjectWithKey } from "@fluentui/react";
import { action, computed, makeObservable } from "mobx";
import { external, initialize, inject } from "tsdi";
import { createUuid, UUID } from "../../utils/uuid";
import { Department, DepartmentSortKey } from "../../api";
import { observer } from "mobx-react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { SortState } from "../../utils/sort-state";
import { PaginationState } from "../../utils/pagination-state";
import { LayoutList } from "../layouts/layout-list";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import { SelectionState } from "../../utils/selection-state";
import {
    DepartmentsApiResource,
    DepartmentsQuery,
    RepositoryDepartments,
} from "../../domain/repositories/repository-departments";
import { defaultPageSize } from "../../utils/constants";

export interface ListDepartmentsProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly commandBar?: JSX.Element;
    readonly listStateId?: UUID;
}

interface ListDepartmentItem extends IObjectWithKey {
    readonly label: string;
    readonly createdAt: string;
    readonly key: UUID;
}

@external
@observer
export class ListDepartments extends React.Component<ListDepartmentsProps> {
    @inject private readonly repositoryDepartments!: RepositoryDepartments;
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;

    private _listStateId = createUuid();
    private selection = new Selection({
        onSelectionChanged: () => this.updateSelection(),
    });

    constructor(props: ListDepartmentsProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.departments.initializeList(this.listStateId, {
            query: () => this.query,
            // Ignore this the url history for this list, as this is always shown in conjunction with other lists.
            ignoreUrl: true,
        });
    }
    @computed private get listStateId(): UUID {
        return this.props.listStateId ?? this._listStateId;
    }

    @computed private get sortState(): SortState<DepartmentSortKey> {
        return this.serviceListStates.departments.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<DepartmentsApiResource> {
        return this.serviceListStates.departments.getPaginationState(this.listStateId);
    }

    @computed private get selectionState(): SelectionState {
        return this.serviceListStates.departments.getSelectionState(this.listStateId);
    }

    @computed private get query(): DepartmentsQuery {
        return { pageSize: defaultPageSize, ...this.sortState.query };
    }

    @computed private get departments(): (Department | undefined)[] {
        if (this.props.ids) {
            return this.props.ids.map((id) => this.repositoryDepartments.byId(id));
        }
        return this.repositoryDepartments.byQuery(this.query, this.paginationState.pagination);
    }

    @computed private get items(): (ListDepartmentItem | undefined)[] {
        return this.departments.map((department) => {
            if (!department) {
                return;
            }
            return {
                label: department.label,
                createdAt: this.i18n.formatDateOnly(department.createdAt),
                key: department.id,
            };
        });
    }

    @action.bound private updateSelection(): void {
        // Update the listState's selectionState to the new selected keys.
        this.selectionState.updateSelection(
            this.selection.getSelection().map((item) => item.key as UUID),
        );
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.departments.isInitialized(this.listStateId)) {
            return <></>;
        }
        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(["ids"], this.props);
        return (
            <LayoutList paginationState={this.paginationState} commandBar={this.props.commandBar}>
                <ElofleetList
                    {...props}
                    selection={this.selection}
                    onColumnHeaderClick={this.sortState.toggleColumn}
                    columns={this.sortState.patchColumns([
                        {
                            fieldName: "label",
                            name: this.i18n.t("listDepartments.column.label.name"),
                            key: "label",
                            minWidth: 100,
                        },
                        {
                            fieldName: "createdAt",
                            name: this.i18n.t("listDepartments.column.createdAt.name"),
                            key: "createdAt",
                            minWidth: 100,
                        },
                    ])}
                    items={this.items}
                />
            </LayoutList>
        );
    }
}
