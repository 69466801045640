import { Checkbox, ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { NodeProps } from "reactflow";
import { AnyInZoneData, Zone } from "../../../api";
import ActionConfigNode, { zoneOptions } from "./action-config-node";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export default function AnyInZone(
    props: NodeProps<AnyInZoneData & { invalid?: boolean; validationErrorMessage?: string }>,
): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    const [selectedZone, setSelectedZone] = useState(props.data.zone ?? Zone.ONE);
    const [pedestrian, setPedestrian] = useState(props.data.pedestrian ?? true);
    const [vehicle, setVehicle] = useState(props.data.vehicle ?? true);
    const [station, setStation] = useState(props.data.station ?? true);

    // We have to set the data to prevent undefined values
    useEffect(() => {
        props.data.zone = selectedZone;
        props.data.pedestrian = pedestrian;
        props.data.vehicle = vehicle;
        props.data.station = station;
    }, [props.data, selectedZone, pedestrian, vehicle, station]);

    function onChange(
        _e?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption,
    ): void {
        if (!option) {
            return;
        }
        setSelectedZone(option.key as Zone);
        props.data.zone = option.key as Zone;
    }

    return (
        <ActionConfigNode nodeKind={props.type} {...props.data}>
            <ChoiceGroup options={zoneOptions(t)} selectedKey={selectedZone} onChange={onChange} />
            <Checkbox
                label={t("component.actionConfigNodes.anyInZone.pedestrian")}
                checked={pedestrian}
                onChange={(_e, checked) => setPedestrian(checked!)}
            />
            <Checkbox
                label={t("component.actionConfigNodes.anyInZone.vehicle")}
                checked={vehicle}
                onChange={(_e, checked) => setVehicle(checked!)}
            />
            <Checkbox
                label={t("component.actionConfigNodes.anyInZone.station")}
                checked={station}
                onChange={(_e, checked) => setStation(checked!)}
            />
        </ActionConfigNode>
    );
}
