import { component, initialize } from "tsdi";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import i18next, {
    InitOptions,
    StringMap,
    TFunctionDetailedResult,
    TFunctionResult as OriginalTFunctionResult,
    TFunctionKeys,
    TOptions,
} from "i18next";
import {
    ManagementRole as ManagementRole,
    FleetRole,
    User,
    Vehicle,
    ShockProfile,
    Department,
    Shift,
    Site,
    PreOpsAnswerChoice,
    PreOpsChecklist,
    ShockEventMotionStatus,
    FleetAuthenticateLogoutType,
    VehicleStatus,
    VehicleType,
    Version,
} from "../../api";
import { ValidationFieldState, ValidationProblem, ValidationStatus } from "../../utils/validation";
import { LocalStorageService } from "../../utils/local-storage";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { format } from "date-fns";
import { clone } from "ramda";
import { defaultDatePickerStrings, IDatePickerStrings } from "@fluentui/react";

export interface LanguageInfo {
    readonly code: string;
    readonly locale: string;
    readonly name: string;
    readonly flag: string;
}

// The following two types override the ones provided by i18next for the `t` function.
// This is necessary because i18next by default does not provide react support.
// We could get rid of this by adding react-i18next as a dependency, but it would result in 20kb
// added to our bundle size and we don't need anything else from it.
// Maybe there's a way to use only the types without bloating the bundle by taking advantage of
// tree shaking.
export type TFunctionResult =
    | OriginalTFunctionResult
    // React.ReactNode was added to the original type definition to comply with type requirements
    | React.ReactNode;

// The interface had to be copied here in order to make it use the altered TFunctionResult type above
export interface TFunction {
    // basic usage
    <
        TResult extends TFunctionResult = string,
        TKeys extends TFunctionKeys = string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        TInterpolationMap extends object = StringMap,
    >(
        key: TKeys | TKeys[],
    ): TResult;
    <
        TResult extends TFunctionResult = TFunctionDetailedResult<object>,
        TKeys extends TFunctionKeys = string,
        TInterpolationMap extends object = StringMap,
    >(
        key: TKeys | TKeys[],
        options?: TOptions<TInterpolationMap> & {
            returnDetails: true;
            returnObjects: true;
        },
    ): TResult;
    <
        TResult extends TFunctionResult = TFunctionDetailedResult,
        TKeys extends TFunctionKeys = string,
        TInterpolationMap extends object = StringMap,
    >(
        key: TKeys | TKeys[],
        options?: TOptions<TInterpolationMap> & { returnDetails: true },
    ): TResult;
    <
        TResult extends TFunctionResult = object,
        TKeys extends TFunctionKeys = string,
        TInterpolationMap extends object = StringMap,
    >(
        key: TKeys | TKeys[],
        options?: TOptions<TInterpolationMap> & { returnObjects: true },
    ): TResult;
    <
        TResult extends TFunctionResult = string,
        TKeys extends TFunctionKeys = string,
        TInterpolationMap extends object = StringMap,
    >(
        key: TKeys | TKeys[],
        options?: TOptions<TInterpolationMap> | string,
    ): TResult;
    // overloaded usage
    <
        TResult extends TFunctionResult = string,
        TKeys extends TFunctionKeys = string,
        TInterpolationMap extends object = StringMap,
    >(
        key: TKeys | TKeys[],
        defaultValue?: string,
        options?: TOptions<TInterpolationMap> | string,
    ): TResult;
}

export enum LanguageMode {
    AUTO = "auto",
    MANUAL = "manual",
}

export type I18nOptions =
    | {
          readonly mode: LanguageMode.AUTO;
      }
    | {
          readonly mode: LanguageMode.MANUAL;
          readonly code: string;
      };

@component
export class I18nProvider extends LocalStorageService<I18nOptions> {
    @observable private _translate: TFunction | undefined;

    public readonly availableLanguages: LanguageInfo[] = [
        {
            code: "en",
            locale: "en-US",
            name: "English",
            flag: "us",
        },
        {
            code: "de",
            locale: "de-DE",
            name: "Deutsch",
            flag: "de",
        },
        {
            code: "fr",
            locale: "fr-FR",
            name: "Français",
            flag: "fr",
        },
        {
            code: "pl",
            locale: "pl-PL",
            name: "Polski",
            flag: "pl",
        },
    ];

    constructor() {
        super({
            version: 1,
            defaultValue: { mode: LanguageMode.AUTO },
            identifier: "i18n",
        });
        makeObservable(this);
    }

    @initialize
    @action.bound
    protected async initialize(): Promise<void> {
        await this.initializeI18n(this.storedValue);
    }

    @action.bound public async changeLanguage(options: I18nOptions): Promise<void> {
        await this.initializeI18n(options);
    }

    @computed public get language(): string | undefined {
        switch (this.storedValue.mode) {
            case LanguageMode.AUTO:
                return;
            case LanguageMode.MANUAL:
                return this.storedValue.code;
        }
    }

    @computed public get mode(): LanguageMode {
        return this.storedValue.mode;
    }

    @computed public get languageInfo(): LanguageInfo | undefined {
        return this.availableLanguages.find(({ code }) => code === this.language);
    }

    private async initializeI18n(options: I18nOptions): Promise<void> {
        const instance = i18next.createInstance();
        const i18nextBuilder = instance.use(XHR).use(initReactI18next);
        const i18nOptions: InitOptions = {
            fallbackLng: "de",
            // Set english as default language
            lng: "en",
            ns: ["common"],
            defaultNS: "common",
            backend: {},
            keySeparator: false,
        };

        switch (options.mode) {
            case LanguageMode.AUTO:
                // I18next caches the last selected language - preventing auto detection.
                window.localStorage.removeItem("i18nextLng");
                instance.use(LanguageDetector);
                break;
            case LanguageMode.MANUAL:
                i18nOptions.lng = options.code;
        }
        this.storedValue = options;
        this._translate = await i18nextBuilder.init(i18nOptions);
        if (options.mode === LanguageMode.AUTO) {
            // Always switch to manual mode using the detected language
            // to display it in the language selector
            runInAction(() => {
                this.storedValue = {
                    mode: LanguageMode.MANUAL,
                    code: i18nextBuilder.resolvedLanguage,
                };
            });
        }
    }

    public get t(): TFunction {
        return this._translate!;
    }

    public formatManagementRole(role: ManagementRole | undefined | null): string {
        switch (role) {
            case undefined:
            case null:
                return this.t("schema.managementRole.none");
            case ManagementRole.RESTRICTED:
                return this.t("schema.managementRole.restricted");
            case ManagementRole.INSTANCE_ADMIN:
                return this.t("schema.managementRole.instanceAdmin");
            case ManagementRole.SUPER_ADMIN:
                return this.t("schema.managementRole.superAdmin");
        }
    }

    public formatFleetRole(role: FleetRole | undefined | null): string {
        switch (role) {
            case undefined:
            case null:
                return this.t("schema.fleetRole.none");
            case FleetRole.DRIVER:
                return this.t("schema.fleetRole.driver");
            case FleetRole.MAINTENANCE_MANAGER:
                return this.t("schema.fleetRole.maintenanceManager");
            case FleetRole.SUPERVISOR:
                return this.t("schema.fleetRole.supervisor");
        }
    }

    public formatProblems(
        problems: ValidationProblem[],
        transformParams?: (param: string | number | boolean) => string | number | boolean,
    ): string {
        if (problems.length === 0) {
            return this.t("validation.invalid.unknown");
        }

        return problems
            .map(({ code, params }) => {
                let updatedParams = params;
                if (transformParams !== undefined) {
                    updatedParams = clone(params);
                    for (const [key, val] of Object.entries(updatedParams)) {
                        updatedParams[key] = transformParams(val);
                    }
                }
                return this.t(`validation.invalid.${code}`, updatedParams);
            })
            .join(" ");
    }

    public formatPreOpsAnswerChoice(choice: PreOpsAnswerChoice): string {
        switch (choice) {
            case PreOpsAnswerChoice.CRITICAL:
                return this.t("schema.preOpsAnswerChoice.critical");
            case PreOpsAnswerChoice.GOOD:
                return this.t("schema.preOpsAnswerChoice.good");
            case PreOpsAnswerChoice.OK:
                return this.t("schema.preOpsAnswerChoice.ok");
        }
    }

    public formatShockEventMotionStatus(motionStatus: ShockEventMotionStatus): string {
        switch (motionStatus) {
            case ShockEventMotionStatus.IN_MOTION:
                return this.t("schema.shockEventMotionStatus.inMotion");
            case ShockEventMotionStatus.STATIONARY:
                return this.t("schema.shockEventMotionStatus.stationary");
        }
    }

    public formatLogoutType(logoutType: FleetAuthenticateLogoutType): string {
        switch (logoutType) {
            case FleetAuthenticateLogoutType.IDLE_TIMEOUT:
                return this.t("schema.fleetAuthenticateLogoutType.idleTimeout");
            case FleetAuthenticateLogoutType.MANUAL:
                return this.t("schema.fleetAuthenticateLogoutType.manual");
            case FleetAuthenticateLogoutType.POWER_OFF:
                return this.t("schema.fleetAuthenticateLogoutType.powerOff");
            case FleetAuthenticateLogoutType.CRITICAL_CHECKLIST:
                return this.t("schema.fleetAuthenticateLogoutType.criticalChecklist");
            case FleetAuthenticateLogoutType.NEW_OPERATOR:
                return this.t("schema.fleetAuthenticateLogoutType.newOperator");
        }
    }

    public formatFieldValidationState(
        state: ValidationFieldState,
        transformParams?: (param: string | number | boolean) => string | number | boolean,
    ): string | undefined {
        if (state.status !== ValidationStatus.INVALID) {
            return undefined;
        }
        const { problems } = state;
        return this.formatProblems(problems, transformParams);
    }

    /**
     * Get the formatted validation message for a single field in a list of validated input fields.
     *
     * The first parameter is the index of the field in the list whose validation error message is requested.
     */
    public formatListFieldValidationState(
        index: number,
        state: ValidationFieldState,
        transformParams?: (param: string | number | boolean) => string | number | boolean,
    ): string | undefined {
        if (state.status !== ValidationStatus.INVALID_LIST) {
            return undefined;
        }
        // There are invalid fields, but not for the index in question.
        if (state.list[index] === undefined) {
            return undefined;
        }

        return this.formatProblems(state.list[index], transformParams);
    }

    public formatVehicleStatus(status: VehicleStatus): string {
        switch (status) {
            case VehicleStatus.ACTIVE:
                return this.t("schema.vehicleStatus.active");
            case VehicleStatus.IN_MAINTENANCE:
                return this.t("schema.vehicleStatus.inMaintenance");
            case VehicleStatus.MAINTENANCE_DUE:
                return this.t("schema.vehicleStatus.maintenanceDue");
            case VehicleStatus.MAINTENANCE_OVERDUE:
                return this.t("schema.vehicleStatus.maintenanceOverdue");
        }
    }

    // Return the localized strings that're shown in the datepicker.
    // This is also used to set the validation error message, if any exists.
    // TODO: For now, everything is english and this function is only used to get the
    // form validation working for datepickers .
    public datePickerStrings(
        state: ValidationFieldState,
        transformParams?: (param: string | number | boolean) => string | number | boolean,
    ): IDatePickerStrings {
        // Use the english datepicker translations by default.
        const datePickerStrings = defaultDatePickerStrings;

        // TODO: Handle other languages
        //if (this.language() == 'de') {
        //    let datePickerStrings = defaultDatePickerStrings;
        //}

        // Set an validation error, if we encounter one.
        // We only set the out of bound error message, as the other error messages are
        // either generic and will be translated lateron or cannot occur in our datepicker.
        //
        // `isResetStatusMessage` and `invalidInputErrorMessage` won't apply, as we don't use
        // freeform datepicker fields.
        // `isRequiredErrorMessage` is generic and will be set via normal translations.
        const validationError = this.formatFieldValidationState(state, transformParams);
        if (validationError !== undefined) {
            datePickerStrings.isOutOfBoundsErrorMessage = validationError;
        }

        return datePickerStrings;
    }

    public formatUserFullName(user: User | undefined): string {
        if (!user) {
            return "";
        }
        return `${user.firstName} ${user.lastName}`;
    }

    public formatVehicleType(vehicleType: VehicleType): string {
        return vehicleType.label;
    }

    public formatDepartment(department: Department): string {
        return department.label;
    }

    public formatShift(shift: Shift): string {
        return shift.label;
    }

    public formatSite(site: Site): string {
        return site.label;
    }

    public formatPreOpsChecklist(preOpsChecklist: PreOpsChecklist): string {
        return preOpsChecklist.label;
    }

    @action.bound
    public formatVehicle(vehicle: Vehicle): string {
        return this.t("schema.vehicle.formattedVehicleName", {
            model: vehicle.model ?? "",
            serialNumber: vehicle.serialNumber,
            interpolation: { escapeValue: false },
        });
    }

    public formatShockProfile(shockProfile: ShockProfile): string {
        return shockProfile.label;
    }

    public formatVersion(version: Version): string {
        return `${version.major}.${version.minor}.${version.patch}-${version.revisionNumber}-${version.shortHash}`;
    }

    public formatDateOnly(date: Date): string {
        switch (this.language) {
            case "de":
                return format(date, "dd.MM.yyy");
            default:
            case "en":
                return format(date, "yyyy-MM-dd");
        }
    }

    public formatDateTime(date: Date, displaySeconds?: boolean): string {
        const timeFormat = displaySeconds ? "HH:mm:ss" : "HH:mm";
        switch (this.language) {
            case "de":
                return format(date, "dd.MM.yyy " + timeFormat);
            default:
            case "en":
                return format(date, "yyyy-MM-dd " + timeFormat);
        }
    }

    public getFloatSeparator(): string {
        switch (this.language) {
            case "de":
                return ",";
            default:
            case "en":
                return ".";
        }
    }

    public addThousandsSeperator(num: number): string {
        return Number(num).toLocaleString(this.language);
    }

    public parseFloat<T>(input: string, failureValue: T): number | T {
        if (input === "") {
            return failureValue;
        }

        // Replace international fraction notation with the format used by
        // `parseFloat`
        input = input.replace(this.getFloatSeparator(), ".");

        const num = parseFloat(input);
        if (isNaN(num)) {
            return failureValue;
        }

        return num;
    }

    // Format a float number to a string with X decimal places.
    // For example `intToFloat(1.23456, 3)` will become `1.234`.
    public formatFloat(value?: number, decimal?: number): string {
        if (value === undefined) {
            return "";
        }

        const formatter = new Intl.NumberFormat(this.languageInfo?.locale, {
            maximumFractionDigits: decimal ?? 1,
        });

        return formatter.format(value);
    }

    public formatDuration(seconds: number, displaySeconds?: boolean): string {
        const h = Math.trunc(seconds / 3600);
        const m = Math.trunc((seconds % 3600) / 60);

        if (displaySeconds) {
            const s = Math.floor(seconds % 60);
            return `${this.formatHours(h)} ${this.formatMinutes(m)} ${this.formatSeconds(s)}`;
        }

        return `${this.formatHours(h)} ${this.formatMinutes(m)}`;
    }

    public formatHours(hours: number): string {
        const localeHours = this.addThousandsSeperator(hours);
        return this.t("generic.i18n.format.hours", { localeHours });
    }

    public formatMinutes(min: number): string {
        return this.t("generic.i18n.format.minutes", { min });
    }

    public formatSeconds(sec: number): string {
        return this.t("generic.i18n.format.seconds", { sec });
    }
}
