import * as React from "react";
import { Selection, IColumn } from "@fluentui/react";
import { external, initialize, inject } from "tsdi";
import { UserGroupAssignment, UserGroupAssignmentSortKey } from "../../api";
import { createUuid, UUID } from "../../utils/uuid";
import { observer } from "mobx-react";
import {
    RepositoryUserGroupAssignments,
    UserGroupAssignmentsApiResource,
    UserGroupAssignmentsQuery,
} from "../../domain/repositories/repository-user-group-assignments";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { action, computed, makeObservable } from "mobx";
import { RepositoryUserGroups } from "../../domain/repositories/repository-user-groups";
import { RepositoryUsers } from "../../domain/repositories/repository-users";
import { omit } from "ramda";
import { SortState } from "../../utils/sort-state";
import { PaginationState } from "../../utils/pagination-state";
import { LayoutList } from "../layouts/layout-list";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import { SelectionState } from "../../utils/selection-state";
import { defaultPageSize } from "../../utils/constants";

export interface ListUserGroupAssignmentProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly userId?: UUID;
    readonly userGroupId?: UUID;
    readonly showUser?: boolean;
    readonly showGroup?: boolean;
    readonly commandBar?: JSX.Element;
    readonly listStateId?: UUID;
}

interface ListUserGroupAssignmentItem {
    readonly key: UUID;
    readonly username: string;
    readonly groupLabel: string;
    readonly createdAt: string;
}

@external
@observer
export class ListUserGroupAssignments extends React.Component<ListUserGroupAssignmentProps> {
    @inject private readonly repositoryUserGroupAssignments!: RepositoryUserGroupAssignments;
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly repositoryUserGroups!: RepositoryUserGroups;
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;

    private _listStateId = createUuid();
    private selection = new Selection({
        onSelectionChanged: () => this.updateSelection(),
    });

    constructor(props: ListUserGroupAssignmentProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.userGroupAssignments.initializeList(this.listStateId, {
            query: () => this.query,
        });
    }

    @computed private get listStateId(): UUID {
        return this.props.listStateId ?? this._listStateId;
    }

    @computed private get sortState(): SortState<UserGroupAssignmentSortKey> {
        return this.serviceListStates.userGroupAssignments.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<UserGroupAssignmentsApiResource> {
        return this.serviceListStates.userGroupAssignments.getPaginationState(this.listStateId);
    }

    @computed private get selectionState(): SelectionState {
        return this.serviceListStates.userGroupAssignments.getSelectionState(this.listStateId);
    }

    @computed private get query(): UserGroupAssignmentsQuery {
        const { userId } = this.props;
        return { userId, pageSize: defaultPageSize, ...this.sortState.query };
    }

    @computed private get userGroupAssignments(): (UserGroupAssignment | undefined)[] {
        if (this.props.ids) {
            return this.props.ids.map((id) => this.repositoryUserGroupAssignments.byId(id));
        }
        return this.repositoryUserGroupAssignments.byQuery(
            this.query,
            this.paginationState.pagination,
        );
    }

    @computed private get items(): (ListUserGroupAssignmentItem | undefined)[] {
        return this.userGroupAssignments.map((userGroupAssignment) => {
            if (!userGroupAssignment) {
                return;
            }
            const user = this.repositoryUsers.byId(userGroupAssignment.userId);
            const username = user ? this.i18n.formatUserFullName(user) : "";
            return {
                key: userGroupAssignment.id,
                username: username,
                groupLabel:
                    this.repositoryUserGroups.byId(userGroupAssignment.userGroupId)?.label ?? "",
                createdAt: this.i18n.formatDateOnly(userGroupAssignment.createdAt),
            };
        });
    }

    @action.bound private updateSelection(): void {
        // Update the listState's selectionState to the new selected keys.
        this.selectionState.updateSelection(
            this.selection.getSelection().map((item) => item.key as UUID),
        );
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.userGroupAssignments.isInitialized(this.listStateId)) {
            return <></>;
        }
        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(["ids", "userId", "userGroupId", "showUser", "showGroup"], this.props);
        const columns: IColumn[] = [];
        if (this.props.showUser ?? true) {
            columns.push({
                fieldName: "username",
                name: this.i18n.t("listUserGroupAssignments.column.user.name"),
                key: "username",
                minWidth: 100,
            });
        }
        if (this.props.showGroup ?? true) {
            columns.push({
                fieldName: "groupLabel",
                name: this.i18n.t("listUserGroupAssignments.column.userGroup.name"),
                key: "groupLabel",
                minWidth: 100,
            });
        }
        columns.push({
            fieldName: "createdAt",
            name: this.i18n.t("listUserGroupAssignments.column.createdAt.name"),
            key: "createdAt",
            minWidth: 100,
        });
        return (
            <LayoutList paginationState={this.paginationState} commandBar={this.props.commandBar}>
                <ElofleetList
                    {...props}
                    selection={this.selection}
                    onColumnHeaderClick={this.sortState.toggleColumn}
                    columns={this.sortState.patchColumns(columns)}
                    items={this.items}
                />
            </LayoutList>
        );
    }
}
