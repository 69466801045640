/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShockEventListUpdate
 */
export interface ShockEventListUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof ShockEventListUpdate
     */
    userFullName?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShockEventListUpdate
     */
    shockEventMotionStatus?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShockEventListUpdate
     */
    vehicleSerialNumber?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShockEventListUpdate
     */
    departmentLabel?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShockEventListUpdate
     */
    vehicleGroupLabel?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShockEventListUpdate
     */
    shockProfileLabel?: boolean | null;
}

/**
 * Check if a given object implements the ShockEventListUpdate interface.
 */
export function instanceOfShockEventListUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ShockEventListUpdateFromJSON(json: any): ShockEventListUpdate {
    return ShockEventListUpdateFromJSONTyped(json, false);
}

export function ShockEventListUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShockEventListUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userFullName': !exists(json, 'userFullName') ? undefined : json['userFullName'],
        'shockEventMotionStatus': !exists(json, 'shockEventMotionStatus') ? undefined : json['shockEventMotionStatus'],
        'vehicleSerialNumber': !exists(json, 'vehicleSerialNumber') ? undefined : json['vehicleSerialNumber'],
        'departmentLabel': !exists(json, 'departmentLabel') ? undefined : json['departmentLabel'],
        'vehicleGroupLabel': !exists(json, 'vehicleGroupLabel') ? undefined : json['vehicleGroupLabel'],
        'shockProfileLabel': !exists(json, 'shockProfileLabel') ? undefined : json['shockProfileLabel'],
    };
}

export function ShockEventListUpdateToJSON(value?: ShockEventListUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userFullName': value.userFullName,
        'shockEventMotionStatus': value.shockEventMotionStatus,
        'vehicleSerialNumber': value.vehicleSerialNumber,
        'departmentLabel': value.departmentLabel,
        'vehicleGroupLabel': value.vehicleGroupLabel,
        'shockProfileLabel': value.shockProfileLabel,
    };
}

