/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FleetRole } from './FleetRole';
import {
    FleetRoleFromJSON,
    FleetRoleFromJSONTyped,
    FleetRoleToJSON,
} from './FleetRole';
import type { ManagementRole } from './ManagementRole';
import {
    ManagementRoleFromJSON,
    ManagementRoleFromJSONTyped,
    ManagementRoleToJSON,
} from './ManagementRole';

/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    employeeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    email?: string | null;
    /**
     * 
     * @type {ManagementRole}
     * @memberof UserUpdate
     */
    managementRole?: ManagementRole | null;
    /**
     * 
     * @type {FleetRole}
     * @memberof UserUpdate
     */
    fleetRole?: FleetRole | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    departmentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    shiftId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    siteId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserUpdate
     */
    licenseExpiryDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdate
     */
    expiryLogout?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    nfcToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    nfcTokenDescription?: string | null;
}

/**
 * Check if a given object implements the UserUpdate interface.
 */
export function instanceOfUserUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserUpdateFromJSON(json: any): UserUpdate {
    return UserUpdateFromJSONTyped(json, false);
}

export function UserUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeId': !exists(json, 'employeeId') ? undefined : json['employeeId'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'managementRole': !exists(json, 'managementRole') ? undefined : ManagementRoleFromJSON(json['managementRole']),
        'fleetRole': !exists(json, 'fleetRole') ? undefined : FleetRoleFromJSON(json['fleetRole']),
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'shiftId': !exists(json, 'shiftId') ? undefined : json['shiftId'],
        'siteId': !exists(json, 'siteId') ? undefined : json['siteId'],
        'licenseExpiryDate': !exists(json, 'licenseExpiryDate') ? undefined : (json['licenseExpiryDate'] === null ? null : new Date(json['licenseExpiryDate'])),
        'expiryLogout': !exists(json, 'expiryLogout') ? undefined : json['expiryLogout'],
        'nfcToken': !exists(json, 'nfcToken') ? undefined : json['nfcToken'],
        'nfcTokenDescription': !exists(json, 'nfcTokenDescription') ? undefined : json['nfcTokenDescription'],
    };
}

export function UserUpdateToJSON(value?: UserUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeId': value.employeeId,
        'password': value.password,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'managementRole': ManagementRoleToJSON(value.managementRole),
        'fleetRole': FleetRoleToJSON(value.fleetRole),
        'jobTitle': value.jobTitle,
        'departmentId': value.departmentId,
        'shiftId': value.shiftId,
        'siteId': value.siteId,
        'licenseExpiryDate': value.licenseExpiryDate === undefined ? undefined : (value.licenseExpiryDate === null ? null : value.licenseExpiryDate.toISOString()),
        'expiryLogout': value.expiryLogout,
        'nfcToken': value.nfcToken,
        'nfcTokenDescription': value.nfcTokenDescription,
    };
}

