import { external, initialize, inject } from "tsdi";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { UUID, createUuid } from "../../utils/uuid";
import {
    PhonesApiResource,
    PhonesQuery,
    RepositoryPhones,
} from "../../domain/repositories/repository-phones";
import { observer } from "mobx-react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { computed, makeObservable } from "mobx";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import { IColumn, IObjectWithKey, SelectionMode } from "@fluentui/react";
import { SortState } from "../../utils/sort-state";
import { Phone, PhoneSortKey } from "../../api";
import { PaginationState } from "../../utils/pagination-state";
import { SearchState } from "../../utils/search-state";
import { LayoutList } from "../layouts/layout-list";
import React from "react";
import { ListPhoneItemCell } from "../atoms/list-phone-item-cell";
import { ListSearchField } from "../atoms/list-search-field";
import { defaultPageSize } from "../../utils/constants";

export interface ListPhonesProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly commandBar?: JSX.Element;
    readonly listStateId?: UUID;
}

interface ListPhoneItem extends IObjectWithKey {
    readonly key: UUID;
}

@external
@observer
export class ListPhones extends React.Component<ListPhonesProps> {
    @inject private readonly repositoryPhones!: RepositoryPhones;
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;

    private _listStateId = createUuid();

    constructor(props: ListPhonesProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        this.serviceListStates.phones.initializeList(this.listStateId, {
            query: () => this.query,
        });
    }
    @computed private get listStateId(): UUID {
        return this.props.listStateId ?? this._listStateId;
    }

    @computed private get searchState(): SearchState {
        return this.serviceListStates.phones.getSearchState(this.listStateId);
    }

    @computed private get sortState(): SortState<PhoneSortKey> {
        return this.serviceListStates.phones.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<PhonesApiResource> {
        return this.serviceListStates.phones.getPaginationState(this.listStateId);
    }

    @computed private get columns(): IColumn[] {
        const columns: IColumn[] = [];
        columns.push({
            fieldName: "vehicleDeviceId",
            name: this.i18n.t("listPhones.column.vehicleDeviceSerialNumber.name"),
            key: "vehicleDeviceId",
            minWidth: 100,
            maxWidth: 200,
        });
        columns.push({
            fieldName: "androidId",
            name: this.i18n.t("listPhones.column.androidId.name"),
            key: "androidId",
            minWidth: 100,
            maxWidth: 200,
        });
        columns.push({
            fieldName: "appLastUpdatedAt",
            name: this.i18n.t("listPhones.column.appLastUpdatedAt.name"),
            key: "appLastUpdatedAt",
            minWidth: 100,
            maxWidth: 200,
        });
        columns.push({
            fieldName: "currentAppVersion",
            name: this.i18n.t("listPhones.column.currentAppVersion.name"),
            key: "currentAppVersion",
            minWidth: 100,
            maxWidth: 200,
        });
        return columns;
    }

    @computed private get phones(): (Phone | undefined)[] {
        if (this.props.ids) {
            return this.props.ids.map((id) => this.repositoryPhones.byId(id));
        } else {
            return this.repositoryPhones.byQuery(this.query, this.paginationState.pagination);
        }
    }

    @computed private get items(): (ListPhoneItem | undefined)[] {
        return this.phones.map((phone) => {
            if (!phone) {
                return;
            }
            return {
                key: phone.id,
            };
        });
    }

    @computed private get query(): PhonesQuery {
        const query = {
            pageSize: defaultPageSize,
            ...this.searchState.query,
            ...this.sortState.query,
        } as PhonesQuery;
        return query;
    }

    private renderItem(phoneId: UUID, column: IColumn): JSX.Element {
        return <ListPhoneItemCell phoneId={phoneId} column={column} />;
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.phones.isInitialized(this.listStateId)) {
            return <></>;
        }
        const searchField = (
            <ListSearchField
                placeholder={this.i18n.t("listPhones.search.placeholder")}
                listStateId={this.listStateId}
                listState={this.serviceListStates.phones}
            />
        );

        return (
            <LayoutList
                paginationState={this.paginationState}
                commandBar={this.props.commandBar}
                searchField={searchField}
            >
                <ElofleetList
                    {...this.props}
                    onColumnHeaderClick={this.sortState.toggleColumn}
                    // Disable right-click to stay consistent with other lists
                    onItemContextMenu={() => {}}
                    selectionMode={SelectionMode.none}
                    onRenderItemColumn={(item: ListPhoneItem, _index, column) =>
                        this.renderItem(item.key, column!)
                    }
                    columns={this.sortState.patchColumns(this.columns)}
                    items={this.items}
                />
            </LayoutList>
        );
    }
}
