import { computed, makeObservable, observable } from "mobx";
import { component, inject } from "tsdi";
import { articles, tags } from "../../help";
import { HelpArticle, HelpArticleContent, HelpArticleStatus, HelpTag } from "../../help-types";
import { LocalStorageService } from "../../utils/local-storage";
import { I18nProvider } from "../providers/i18n-provider";

/** Search results for the search query. Articles and tags are both searched. */
export interface HelpSearchResults {
    readonly articles: HelpArticle[];
    readonly tags: HelpTag[];
}

/**
 * This describes the status of the help sidebar itself.
 * It can display one of these options:
 *     - The article that the user is currently reading.
 *     - An error, because an unknown article was encountered.
 *     - Nothing, because no article is open and the user is not searching.
 *     - The search results.
 */
export enum HelpStateStatus {
    ARTICLE_ACTIVE = "ARTICLE ACTIVE",
    NOT_FOUND = "NOT FOUND",
    NONE = "NONE",
    SEARCHING = "SEARCHING",
}

/** State of the help sidebar with meta information. */
export type HelpState =
    | {
          readonly status: HelpStateStatus.ARTICLE_ACTIVE;
          readonly article: HelpArticle;
      }
    | {
          readonly status: HelpStateStatus.NONE;
      }
    | {
          readonly status: HelpStateStatus.NOT_FOUND;
      }
    | {
          readonly status: HelpStateStatus.SEARCHING;
          readonly searchResults: HelpSearchResults;
      };

/** Stored in the local storage. */
interface HelpStorageState {
    /** History stack of articles the user navigated. The last article is displayed in the sidebar. */
    history: string[];
    /** User's search query. */
    query: string;
}

@component
export class ServiceHelp extends LocalStorageService<HelpStorageState> {
    @inject private readonly i18n!: I18nProvider;

    private tags: Map<string, HelpTag> = new Map();
    @observable private articles: Map<string, HelpArticle> = new Map();

    /**
     * This map is a cache for quickly resolving which articles belong to a tag.
     * It's a back reference from the tags in an article.
     */
    private tagArticleReference: Map<string, HelpArticle[]> = new Map();

    constructor() {
        super({
            version: 1,
            defaultValue: { history: [], query: "" },
            identifier: "help",
        });
        makeObservable(this);
        Object.values(tags).forEach((tag) => this.registerTag(tag));
        Object.values(articles).forEach((article) => this.registerArticle(article));
    }

    /** The search term the user entered. */
    @computed public get query(): string {
        return this.storedValue.query;
    }

    /** The search term the user entered. */
    public set query(query: string) {
        this.storedValue = { ...this.storedValue, query };
    }

    /** The filename of the article the user is currently reading or `undefined`. */
    @computed private get activeFilename(): string | undefined {
        return this.storedValue.history[this.storedValue.history.length - 1];
    }

    /** Delete the history stack (stop reading an article and return to index). */
    public clearHistory(): void {
        this.storedValue = { ...this.storedValue, history: [] };
    }

    /** Navigate to the article with the given filename. */
    public enterArticle(filename: string): void {
        this.storedValue = {
            ...this.storedValue,
            history: [...this.storedValue.history, filename],
        };
    }

    /** Navigate back on the history stack, leaves the browsing if the stack is empty. */
    public back(): void {
        const history = [...this.storedValue.history];
        history.pop();
        this.storedValue = { ...this.storedValue, history };
    }

    /**
     * Describes what should be displayed at the moment.
     * If an article is currently being read by the user, this article will be returned.
     * If a search is active, this will return the search results.
     */
    @computed public get state(): HelpState {
        if (this.activeFilename) {
            const article = this.getArticle(this.activeFilename);
            if (article) {
                return {
                    status: HelpStateStatus.ARTICLE_ACTIVE,
                    article,
                };
            }
            return {
                status: HelpStateStatus.NOT_FOUND,
            };
        }
        if (this.searchResults) {
            return {
                status: HelpStateStatus.SEARCHING,
                searchResults: this.searchResults,
            };
        }
        return { status: HelpStateStatus.NONE };
    }

    /** Whether the user is currently searching or not. */
    @computed public get isSearching(): boolean {
        return this.query.length > 0;
    }

    /** Number of characters missing in query in order to trigger a search. */
    @computed public get remainingSearchCharacters(): number {
        return Math.max(0, 3 - this.query.length);
    }

    /**
     * Returns the search results for the query.
     * If no query is entered (or if query is too short), this will return `undefined`.
     */
    @computed
    public get searchResults(): HelpSearchResults | undefined {
        const query = this.query.toLocaleLowerCase();

        if (this.remainingSearchCharacters > 0) {
            return;
        }

        const articles = [...this.articles.values()].filter((article) => {
            const title = this.i18n.t(article.title).toLocaleLowerCase();
            const summary = this.i18n.t(article.summary).toLocaleLowerCase();
            const tags = article.tags.map((tag) => this.i18n.t(tag.title).toLocaleLowerCase());

            return (
                title.includes(query) ||
                summary.includes(query) ||
                tags.some((tag) => tag.includes(query))
            );
        });
        const tags = [...this.tags.values()].filter((tag) => {
            const title = this.i18n.t(tag.title).toLocaleLowerCase();

            return title.includes(query);
        });
        return { articles, tags };
    }

    @computed
    public get articleCount(): number {
        return this.articles.size;
    }

    /** Return an article and start loading the article in the background. */
    public getArticle(filename: string): HelpArticle | undefined {
        const article = this.articles.get(filename);
        if (!article) {
            return;
        }

        // Load the article in the background.
        void this.loadArticle(article);

        return article;
    }

    /** Load an article  */
    private async loadArticle(article: HelpArticle): Promise<HelpArticle> {
        // Don't load an article twice.
        if (article.content.status !== HelpArticleStatus.PENDING) {
            return article;
        }

        article.content.status = HelpArticleStatus.LOADING;

        // Load the content for the article and assign it to the article.
        const content = await this.fetchArticleContent(article);
        article.content = content;

        return article;
    }

    private getArticleUrl(filename: string, locale?: string): URL {
        // Fall back to English if language has not yet been configured.
        locale = locale ?? this.i18n.language ?? "en";

        return new URL(`/help/${locale}/${filename}`, location.origin);
    }

    /**
     * Fetch the content for an help article.
     * If the content is not found in the current locale, this will fall back to English.
     * Will return the loaded content and status, but not update the article itself.
     */
    private async fetchArticleContent(article: HelpArticle): Promise<HelpArticleContent> {
        try {
            // Attempt to load the article in the selected locale.
            const result = await fetch(this.getArticleUrl(article.filename).href);

            // Status codes like `404` do not result in error. Escalate this.
            if (!result.ok) {
                throw new Error("Invalid status code.");
            }

            const content = await result.text();

            // Return the content.
            return { status: HelpArticleStatus.SUCCESS, content };
        } catch (err) {
            // Try to load the article in english if it was not available in the current locale.
            try {
                const result = await fetch(this.getArticleUrl(article.filename, "en").href);

                // Status codes like `404` do not result in error. Escalate this.
                if (!result.ok) {
                    throw new Error("Invalid status code.");
                }

                const content = await result.text();

                // Return the content.
                return { status: HelpArticleStatus.FALLBACK, content };
            } catch (err) {
                // Article could not be found.
                return { status: HelpArticleStatus.FAILURE };
            }
        }
    }

    /** Register a tag in the list of available tags. */
    private registerTag(tag: HelpTag): void {
        // Tag names must be unique.
        if (this.tags.has(tag.name)) {
            throw new Error(`Tag with name "${tag.name}" already exists.`);
        }

        // Store tag in cache.
        this.tags.set(tag.name, tag);

        // Initialize the reference cache.
        // This is used for quicker lookup later on.
        this.tagArticleReference.set(tag.name, []);
    }

    /** Register an article in the virtual file system. */
    private registerArticle(article: HelpArticle): void {
        // Filenames must be unique.
        if (this.articles.has(article.filename)) {
            throw new Error(`Article with filename "${article.filename}" already exists.`);
        }

        // Store the article in cache.
        this.articles.set(article.filename, article);

        // Update the reference between tag and article for faster lookup.
        for (const tag of article.tags) {
            const referencedArticles = this.tagArticleReference.get(tag.name);
            referencedArticles?.push(article);
        }
    }

    /**
     * Will resolve a path to another markdown file relative to the source file.
     *
     * **Example:** When a link is placed in the file `help/en/a/b/source.md`
     * pointing to `../target.md`, this will return `a/target.md`.
     * The reasoning behind this is as follows:
     * The directory `help` is always added, since it's the root of all help pages.
     * The directory `en` is inferred from the browser's locale and has nothing to do with
     * internal routing.
     */
    public resolveMarkdownPath(sourceFile: string, path: string): string {
        // Most markdown editors support omitting the file extension.
        // We want to stay compatible with this.
        path = path.endsWith(".md") ? path : `${path}.md`;

        // Use the browser's `URL` class to perform the path resolving for us.
        const sourceUrl = this.getArticleUrl(sourceFile);
        const targetUrl = new URL(path, sourceUrl);

        // The pathname will contain the full path (`/help/locale/dir/example.md`).
        // Only the part after the locale is needed (`dir/example.md`).
        // This regex will remove the `/help/locale/` portion.
        return targetUrl.pathname.replace(/^\/help\/.*?\//, "");
    }

    /**
     * Will resolve a URL to an asset file (image, video, ...) relative to the source file.
     *
     * **Example:** When an image is referenced in the file `help/en/a/source.md`
     * pointing to `../../assets/image.jpg`, this will return `https://example.com/help/assets/image.jpg`.
     */
    public resolveAssetUrl(sourceFile: string, path: string): string {
        // Use the browser's `URL` class to perform the path resolving for us.
        const sourceUrl = this.getArticleUrl(sourceFile);
        const targetUrl = new URL(path, sourceUrl);
        return targetUrl.href;
    }

    /** Count the number of articles that are associated with the given tag. */
    public countArticlesInTag(tag: HelpTag): number {
        return this.tagArticleReference.get(tag.name)?.length ?? 0;
    }

    @computed public get allTags(): HelpTag[] {
        return [...this.tags.values()];
    }

    /** Called when the user searches for a tag. */
    public browseTag(tag: HelpTag): void {
        this.query = this.i18n.t(tag.title);
        this.clearHistory();
    }
}
