import * as React from "react";
import { IColumn } from "@fluentui/react";
import { User, Vehicle, VehicleMovementPeriod } from "../../api";
import { external, inject } from "tsdi";
import { observer } from "mobx-react";
import { computed, makeObservable } from "mobx";
import { RepositoryUsers } from "../../domain/repositories/repository-users";
import { RepositoryVehicles } from "../../domain/repositories/repository-vehicles";
import { I18nProvider } from "../../domain/providers/i18n-provider";

export interface ListVehicleMovementPeriodItemCellProps {
    readonly period: VehicleMovementPeriod;
    readonly column: IColumn;
}

@external
@observer
export class ListVehicleMovementPeriodItemCell extends React.Component<ListVehicleMovementPeriodItemCellProps> {
    @inject private readonly repositoryUsers!: RepositoryUsers;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;
    @inject private readonly i18n!: I18nProvider;

    constructor(props: ListVehicleMovementPeriodItemCellProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get user(): User | undefined {
        if (!this.props.period || !this.props.period.userId) {
            return;
        }
        return this.repositoryUsers.byId(this.props.period.userId);
    }

    @computed private get vehicle(): Vehicle | undefined {
        if (!this.props.period) {
            return;
        }
        return this.repositoryVehicles.byId(this.props.period.vehicleId);
    }

    public render(): JSX.Element {
        const { column } = this.props;
        switch (column.key) {
            case "eventId":
                return <span>MOV-{this.props.period?.eventId}</span>;
            case "vehicleSerialNumber":
                return <span>{this.vehicle?.serialNumber}</span>;
            case "userEmployeeId":
                return (
                    <span>
                        {this.user?.employeeId ??
                            this.i18n.t("listVehicleMovementPeriods.data.user.null")}
                    </span>
                );
            case "startTime":
                return <span>{this.i18n.formatDateTime(this.props.period.startTime, true)}</span>;
            case "stopTime":
                return <span>{this.i18n.formatDateTime(this.props.period.stopTime, true)}</span>;
            case "totalTime":
                return (
                    <span>
                        {this.i18n.formatDuration(
                            (this.props.period.stopTime.getTime() -
                                this.props.period.startTime.getTime()) /
                                1000,
                            true,
                        )}
                    </span>
                );
            default:
                return <></>;
        }
    }
}
