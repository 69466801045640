import { runInAction } from "mobx";
import { Segment, FetchByQueryResult } from "mobx-repository";
import { component, inject } from "tsdi";
import {
    ColumnVisibility,
    ColumnVisibilitysApi,
    ColumnVisibilityUpdate,
    PageInfo,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { Validation } from "../../utils/validation";

export interface ColumnVisibilitysApiResource extends ApiResource {
    entity: ColumnVisibility;
    query: void;
    update: ColumnVisibilityUpdate;
    create: void;
    sortKey: string;
}

@component
export class RepositoryColumnVisibility extends ElofleetRepository<ColumnVisibilitysApiResource> {
    @inject private readonly api!: ColumnVisibilitysApi;

    public validation = {
        update: new Validation((columnVisibilityUpdate: ColumnVisibilityUpdate, id: UUID) =>
            this.api.columnVisibilityValidateUpdateColumnVisibility({
                columnVisibilityUpdate,
                id,
            }),
        ),
    };

    public async update(id: string, update: ColumnVisibilityUpdate): Promise<ColumnVisibility> {
        const updatedVisibility = await this.wrapApiCall(
            this.api.columnVisibilityUpdateColumnVisibility({ columnVisibilityUpdate: update, id }),
        );

        runInAction(() => {
            // This reload is necessary to reload all queries that want to display "all" entities.
            this.reloadQuery();
        });

        return updatedVisibility;
    }

    protected async fetchByQuery(
        _query: void,
        _pagination: Segment,
    ): Promise<FetchByQueryResult<ColumnVisibility>> {
        return { entities: [await this.api.columnVisibilityReadColumnVisibility()] };
    }

    protected extractId(entity: ColumnVisibility): string {
        return entity.id;
    }

    public fetchMetadata(_query: never): Promise<PageInfo> {
        throw new Error("Cannot list column visibility metadata: Method not implemented.");
    }
    public create(_update: never): Promise<ColumnVisibility> {
        throw new Error("Cannot create new column visibility: Method not implemented.");
    }
    public delete(..._ids: string[]): Promise<void> {
        throw new Error("Cannot delete column visibility: Method not implemented.");
    }
    protected fetchById(_id: string): Promise<ColumnVisibility | undefined> {
        throw new Error("Cannot fetch column visibility by ID: Method not implemented.");
    }
}
