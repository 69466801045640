/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleDeviceCreate
 */
export interface VehicleDeviceCreate {
    /**
     * 
     * @type {string}
     * @memberof VehicleDeviceCreate
     */
    vehicleId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleDeviceCreate
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDeviceCreate
     */
    hardwareKey: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleDeviceCreate
     */
    firmwareLastUpdatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleDeviceCreate
     */
    currentVersion?: string | null;
}

/**
 * Check if a given object implements the VehicleDeviceCreate interface.
 */
export function instanceOfVehicleDeviceCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "serialNumber" in value;
    isInstance = isInstance && "hardwareKey" in value;

    return isInstance;
}

export function VehicleDeviceCreateFromJSON(json: any): VehicleDeviceCreate {
    return VehicleDeviceCreateFromJSONTyped(json, false);
}

export function VehicleDeviceCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleDeviceCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicleId': !exists(json, 'vehicleId') ? undefined : json['vehicleId'],
        'serialNumber': json['serialNumber'],
        'hardwareKey': json['hardwareKey'],
        'firmwareLastUpdatedAt': !exists(json, 'firmwareLastUpdatedAt') ? undefined : (json['firmwareLastUpdatedAt'] === null ? null : new Date(json['firmwareLastUpdatedAt'])),
        'currentVersion': !exists(json, 'currentVersion') ? undefined : json['currentVersion'],
    };
}

export function VehicleDeviceCreateToJSON(value?: VehicleDeviceCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicleId': value.vehicleId,
        'serialNumber': value.serialNumber,
        'hardwareKey': value.hardwareKey,
        'firmwareLastUpdatedAt': value.firmwareLastUpdatedAt === undefined ? undefined : (value.firmwareLastUpdatedAt === null ? null : value.firmwareLastUpdatedAt.toISOString()),
        'currentVersion': value.currentVersion,
    };
}

