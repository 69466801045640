/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum VehicleDeviceSortKey {
    CREATED_AT = 'createdAt',
    SERIAL_NUMBER = 'serialNumber',
    FIRMWARE_LAST_UPDATED_AT = 'firmwareLastUpdatedAt',
    CURRENT_VERSION = 'currentVersion'
}


export function VehicleDeviceSortKeyFromJSON(json: any): VehicleDeviceSortKey {
    return VehicleDeviceSortKeyFromJSONTyped(json, false);
}

export function VehicleDeviceSortKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleDeviceSortKey {
    return json as VehicleDeviceSortKey;
}

export function VehicleDeviceSortKeyToJSON(value?: VehicleDeviceSortKey | null): any {
    return value as any;
}

