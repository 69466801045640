import type { TSDI } from "tsdi";

// Start dependency injection with basic application container.
export let tsdi: TSDI;

// Set the global TSDI instance used throughout the application.
// Pass in an already constructed instance to avoid an import
// of `Application` which would lead to circular imports in some places.
export function setTsdi(newTsdi: TSDI): void {
    tsdi = newTsdi;
}
