import { createBrowserHistory } from "history";
import { configure } from "tsdi";
import {
    ArtifactsApi,
    ColumnVisibilitysApi,
    Configuration,
    DashboardApi,
    DepartmentsApi,
    FilesApi,
    LoginTokensApi,
    NfcTokensApi,
    PreOpsAnswersApi,
    PersistentNotificationsApi,
    PreOpsChecklistsApi,
    PreOpsQuestionsApi,
    PreOpsQuestionVersionsApi,
    SafetyMessagesApi,
    ShiftsApi,
    ShockEventsApi,
    ShockProfilesApi,
    SitesApi,
    UsersApi,
    UserGroupAssignmentsApi,
    UserGroupsApi,
    UserGroupVehicleGroupAssignmentsApi,
    VehicleDevicesApi,
    VehiclesApi,
    VehicleGroupAssignmentsApi,
    VehicleGroupsApi,
    SettingsApi,
    FleetAuthenticateEventsApi,
    PublicSettingsApi,
    ShockProfileVersionsApi,
    EmailLoginTokensApi,
    VehicleMovementsApi,
    NewsApi,
    PhonesApi,
    VehicleTypesApi,
    MotionSensorsApi,
} from "./api";
import { HistoryProvider } from "./domain/providers/history-provider";
import { I18nProvider } from "./domain/providers/i18n-provider";
import { RepositoryNfcTokens } from "./domain/repositories/repository-nfc-tokens";
import { RepositorySafetyMessages } from "./domain/repositories/repository-safety-messages";
import { RepositorySites } from "./domain/repositories/repository-sites";
import { RepositoryDepartments } from "./domain/repositories/repository-departments";
import { RepositoryUserGroupAssignments } from "./domain/repositories/repository-user-group-assignments";
import { RepositoryUserGroupVehicleGroupAssignments } from "./domain/repositories/repository-user-group-vehicle-group-assignments";
import { RepositoryUserGroups } from "./domain/repositories/repository-user-groups";
import { RepositoryUsers } from "./domain/repositories/repository-users";
import { RepositoryVehicleDevices } from "./domain/repositories/repository-vehicle-devices";
import { RepositoryVehicleGroupAssignments } from "./domain/repositories/repository-vehicle-group-assignments";
import { RepositoryVehicleGroups } from "./domain/repositories/repository-vehicle-groups";
import { RepositoryVehicleMovementPeriods } from "./domain/repositories/repository-vehicle-movement-periods";
import { RepositoryVehicleTypes } from "./domain/repositories/repository-vehicle-types";
import { RepositoryVehicles } from "./domain/repositories/repository-vehicles";
import { ServiceAuth } from "./domain/services/service-auth";
import { ServicePublicSettings } from "./domain/services/service-public-settings";
import { ServiceSse } from "./domain/services/service-sse";
import { ServiceErrors } from "./domain/services/service-errors";
import { ServiceOwnUser } from "./domain/services/service-own-user";
import { ServiceFile } from "./domain/services/service-file";
import { ServiceSentry } from "./domain/services/service-sentry";
import { PageImpactManagement } from "./pages/page-impact-management";
import { PageLogin } from "./pages/page-login";
import { PageMaintenance } from "./pages/page-maintenance";
import { PageVehicleProductivity } from "./pages/page-vehicle-productivity";
import { PageLoginReport } from "./pages/page-login-report";

import { PageSafetyMessages } from "./pages/page-safety-messages";
import { PageSafetyMessagesSettings } from "./pages/page-safety-messages-settings";
import { PageUserGroups } from "./pages/page-user-groups";
import { PageUsers } from "./pages/page-users";
import { PageUserProductivity } from "./pages/page-user-productivity";
import { PageVehicle } from "./pages/page-vehicle";
import { PageVehicleDevices } from "./pages/page-vehicle-devices";
import { PageVehicleGroups } from "./pages/page-vehicle-groups";
import { PageVehicles } from "./pages/page-vehicles";
import { ListUserItemCell } from "./ui/atoms/list-user-item-cell";
import { ListVehicleItemCell } from "./ui/atoms/list-vehicle-item-cell";
import { ListPreOpsAnswerItemCell } from "./ui/atoms/list-pre-ops-answer-item-cell";
import { PaginationButtons } from "./ui/atoms/pagination-buttons";
import ErrorBars from "./ui/molecules/error-bars";
import { FormCreateUserGroupVehicleGroupAssignments } from "./ui/molecules/form-create-user-group-vehicle-group-assignments";
import { FormCreateUserGroupAssignments } from "./ui/molecules/form-create-user-group-assignments";
import { FormCreatePreOpsChecklist } from "./ui/molecules/form-create-pre-ops-checklist";
import { FormCreateSafetyMessage } from "./ui/molecules/form-create-safety-message";
import { FormCreateSite } from "./ui/molecules/form-create-site";
import { FormCreateDepartment } from "./ui/molecules/form-create-department";
import { FormCreateUser } from "./ui/molecules/form-create-user";
import { FormCreateUserGroup } from "./ui/molecules/form-create-user-group";
import { FormCreateVehicle } from "./ui/molecules/form-create-vehicle";
import { FormCreateVehicleGroup } from "./ui/molecules/form-create-vehicle-group";
import { FormUpdateDefaultLanguage } from "./ui/molecules/form-update-default-language";
import { FormUpdatePreOpsChecklist } from "./ui/molecules/form-update-pre-ops-checklist";
import { FormUpdateSafetyMessage } from "./ui/molecules/form-update-safety-message";
import { FormUpdateSite } from "./ui/molecules/form-update-site";
import { FormUpdateUser } from "./ui/molecules/form-update-user";
import { FormUpdateUserGroup } from "./ui/molecules/form-update-user-group";
import { FormUpdateVehicle } from "./ui/molecules/form-update-vehicle";
import { FormUpdateVehicleDevice } from "./ui/molecules/form-update-vehicle-device";
import { FormUpdateVehicleGroup } from "./ui/molecules/form-update-vehicle-group";
import { LayoutDefault } from "./ui/layouts/layout-default";
import { ListSafetyMessages } from "./ui/molecules/list-safety-messages";
import { ListMotionSensors } from "./ui/molecules/list-motion-sensors";
import { ListMotionSensorItemCell } from "./ui/atoms/list-motion-sensor-item-cell";
import { ListPreOpsChecklists } from "./ui/molecules/list-pre-ops-checklists";
import { ListPreOpsChecklistItemCell } from "./ui/atoms/list-pre-ops-checklists-item-cell";
import { ListSites } from "./ui/molecules/list-sites";
import { ListUserGroupAssignments } from "./ui/molecules/list-user-group-assignments";
import { ListUserGroups } from "./ui/molecules/list-user-groups";
import { ListUsers } from "./ui/molecules/list-users";
import { ListVehicleDevices } from "./ui/molecules/list-vehicle-devices";
import { ListVehicleDeviceItemCell } from "./ui/atoms/list-vehicle-device-item-cell";
import { ListVehicleProductivity } from "./ui/molecules/list-vehicle-productivity";
import { ListVehicleProductivityItemCell } from "./ui/atoms/list-vehicle-productivity-item-cell";
import { ListVehicleGroupAssignments } from "./ui/molecules/list-vehicle-group-assignments";
import { ListVehicleGroups } from "./ui/molecules/list-vehicle-groups";
import { ListVehicles } from "./ui/molecules/list-vehicles";
import { ListLoginReport } from "./ui/molecules/list-login-report";
import { ListLoginReportItemCell } from "./ui/atoms/list-login-report-item-cell";
import { ListCommandButtons } from "./ui/organisms/list-command-buttons";
import { ListCommandButtonsPreOpsChecklists } from "./ui/organisms/list-command-buttons-pre-ops-checklists";
import { ListCommandButtonsSafetyMessages } from "./ui/organisms/list-command-buttons-safety-messages";
import { ListCommandButtonsSites } from "./ui/organisms/list-command-buttons-sites";
import { ListCommandButtonsUsers } from "./ui/organisms/list-command-buttons-user";
import { ListCommandButtonsUserGroupAssignments } from "./ui/organisms/list-command-buttons-user-group-assignments";
import { ListUserGroupAddButton } from "./ui/organisms/list-user-group-add-button";
import { ListCommandButtonsUserGroups } from "./ui/organisms/list-command-buttons-user-groups";
import { ListCommandButtonsVehicleDevices } from "./ui/organisms/list-command-buttons-vehicle-devices";
import { ListCommandButtonsVehicleGroupAssignments } from "./ui/organisms/list-command-buttons-vehicle-group-assignments";
import { ListCommandButtonsVehicleGroups } from "./ui/organisms/list-command-buttons-vehicle-groups";
import { ListCommandButtonsVehicles } from "./ui/organisms/list-command-buttons-vehicles";
import { FilterByDateRange } from "./ui/organisms/filter-by-date-range";
import { FilterVehicleList } from "./ui/organisms/filter-vehicle-list";
import { SidebarNavigation } from "./ui/organisms/sidebar-navigation";
import { SearchState } from "./utils/search-state";
import { SortState } from "./utils/sort-state";
import { SelectionState } from "./utils/selection-state";
import { ServiceListStates } from "./domain/services/service-list-states";
import { ServiceSidebar } from "./domain/services/service-sidebar";
import { ServiceStubData } from "./domain/services/service-stub-data";
import { PageSettings } from "./pages/page-settings";
import { SelectLanguage } from "./ui/atoms/select-language";
import { LanguageButtons } from "./ui/atoms/language-buttons";
import { ModalResetPassword } from "./ui/organisms/modal-reset-password";
import { FormResetPassword } from "./ui/molecules/form-reset-password";
import { NavigationItem } from "./ui/atoms/navigation-item";
import { NavigationGroup } from "./ui/atoms/navigation-group";
import { PanelVehicleGroupDetails } from "./ui/molecules/panel-vehicle-group-details";
import { CompanyLogo } from "./ui/atoms/company-logo";
import { SidebarPrimary } from "./ui/molecules/sidebar-primary";
import { RepositoryMotionSensors } from "./domain/repositories/repository-motion-sensors";
import { RepositoryShockEvents } from "./domain/repositories/repository-shock-events";
import { RepositoryPersistentNotifications } from "./domain/repositories/repository-persistent-notifications";
import { RepositoryPreOpsChecklists } from "./domain/repositories/repository-pre-ops-checklists";
import { RepositoryPreOpsQuestions } from "./domain/repositories/repository-pre-ops-questions";
import { RepositoryFleetAuthenticatePeriods } from "./domain/repositories/repository-fleet-authenticate-periods";
import { ListShockEvents } from "./ui/molecules/list-shock-events";
import { ListShockEventItemCell } from "./ui/atoms/list-shock-event-item-cell";
import { RepositoryShockProfiles } from "./domain/repositories/repository-shock-profiles";
import { ListShockProfiles } from "./ui/molecules/list-shock-profiles";
import { ListShockProfileItemCell } from "./ui/atoms/list-shock-profile-item-cell";
import { FormUpdateShockProfile } from "./ui/molecules/form-update-shock-profile";
import { FormCreateShockProfile } from "./ui/molecules/form-create-shock-profile";
import { ListCommandButtonsShockProfiles } from "./ui/organisms/list-command-buttons-shock-profiles";
import { PageShockProfiles } from "./pages/page-shock-profiles";
import { PageUserSettings } from "./pages/page-user-settings";
import { PageVehicleSettings } from "./pages/page-vehicle-settings";
import { PageChecklistSettings } from "./pages/page-checklist-settings";
import { PagePreOpsChecklists } from "./pages/page-pre-ops-checklists";
import { ListUserProductivity } from "./ui/molecules/list-user-productivity";
import { ListUserProductivityItemCell } from "./ui/atoms/list-user-productivity-item-cell";
import { RepositoryColumnVisibility } from "./domain/repositories/repository-column-visibility";
import { ModalConfirmation } from "./ui/atoms/modal-confirmation";
import { ElofleetDialog } from "./ui/atoms/elofleet-dialog";
import { PageSystemWide } from "./pages/page-system-wide";
import { ListCommandButtonsDepartments } from "./ui/organisms/list-command-buttons-departments";
import { FormUpdateDepartment } from "./ui/molecules/form-update-department";
import { ListDepartments } from "./ui/molecules/list-departments";
import { ListCommandButtonsShifts } from "./ui/organisms/list-command-buttons-shifts";
import { FormCreateShift } from "./ui/molecules/form-create-shift";
import { FormUpdateShift } from "./ui/molecules/form-update-shift";
import { ListShifts } from "./ui/molecules/list-shifts";
import { RepositoryShifts } from "./domain/repositories/repository-shifts";
import { SeverityPill } from "./ui/atoms/severity-pill";
import { ElofleetDialogFooter } from "./ui/atoms/elofleet-dialog-footer";
import { SectionPreOpsChecklistSettings } from "./ui/molecules/section-pre-ops-checklist-settings";
import { RepositoryPreOpsQuestionVersions } from "./domain/repositories/repository-pre-ops-question-versions";
import { RepositoryPreOpsAnswers } from "./domain/repositories/repository-pre-ops-answers";
import { ListPreOpsAnswers } from "./ui/molecules/list-pre-ops-answers";
import { UserMenu } from "./ui/molecules/user-menu";
import { ApiResource } from "./utils/elofleet-repository";
import { PaginationState } from "./utils/pagination-state";
import { HelpArticleFull } from "./ui/molecules/help-article-full";
import { HelpArticlePreview } from "./ui/molecules/help-article-preview";
import { HelpButton } from "./ui/molecules/help-button";
import { HelpSearch } from "./ui/molecules/help-search";
import { HelpSidebar } from "./ui/molecules/help-sidebar";
import { HelpTagBubble } from "./ui/atoms/help-tag-bubble";
import { NotificationMenu } from "./ui/molecules/notification-menu";
import { ImageUploader } from "./ui/atoms/image-uploader";
import { PageTruckActivity } from "./pages/page-truck-activity";
import { PagePedestrianActivity } from "./pages/page-pedestrian-activity";
import { PageZoningActivity } from "./pages/page-zoning-activity";
import { ListTruckEvents } from "./ui/molecules/list-truck-events";
import { ListTruckEventItemCell } from "./ui/atoms/list-truck-event-item-cell";
import { NotificationPivotMenu } from "./ui/molecules/notification-pivot-menu";
import { NotificationLabel } from "./ui/atoms/notification-label";
import { ChecklistNotificationItem } from "./ui/molecules/notification-item-checklist";
import { ImpactNotificationItem } from "./ui/molecules/notification-item-impact";
import { MaintenanceNotificationItem } from "./ui/molecules/notification-item-maintenance";
import { FloatInput } from "./ui/atoms/float-input";
import { ListPedestrianEvents } from "./ui/molecules/list-pedestrian-events";
import { ListZoneEvents } from "./ui/molecules/list-zone-events";
import { RepositorySettings } from "./domain/repositories/repository-settings";
import { RepositoryPublicSettings } from "./domain/repositories/repository-public-settings";
import { FormUpdateSettingPreOps } from "./ui/molecules/form-update-setting-pre-ops";
import { FormUpdateSettingVehicle } from "./ui/molecules/form-update-setting-vehicle";
import { ListPreOpsChecklistButtons } from "./ui/organisms/list-pre-ops-checklist-buttons";
import { FormUpdateUserProfileSettings } from "./ui/molecules/form-update-user-profile-settings";
import { PageGeneralSettings } from "./pages/page-general-settings";
import { ListVehicleAddButton } from "./ui/organisms/list-vehicle-add-button";
import { ListVehicleGroupAddButton } from "./ui/organisms/list-vehicle-group-add-button";
import { FormCreateVehicleGroupAssignments } from "./ui/molecules/form-create-vehicle-group-assignments";
import { FormUpdateNotificationMails } from "./ui/molecules/form-update-notification-mails";
import { NotificationEmails } from "./ui/atoms/notification-emails";
import { RepositoryShockProfileVersions } from "./domain/repositories/repository-shock-profile-versions";
import { PageEmailLoginTokenLogin } from "./pages/page-email-token-login";
import { FilterUserList } from "./ui/organisms/filter-user-list";
import { PanelUserGroupDetails } from "./ui/molecules/panel-user-group-details";
import { DatePicker } from "./ui/organisms/date-picker";
import { Section } from "./ui/atoms/section";
import { PageHeader } from "./ui/atoms/page-header";
import { DashboardItemCell } from "./ui/atoms/dashboard-item-cell";
import { ListVehicleMovementPeriods } from "./ui/molecules/list-vehicle-movement-periods";
import { PageVehicleMovementPeriods } from "./pages/page-vehicle-movement-periods";
import { ListVehicleMovementPeriodItemCell } from "./ui/atoms/list-vehicle-movement-period-item-cell";
import { FilterVehicleMovementPeriodList } from "./ui/organisms/filter-vehicle-movement-period-list";
import { FilterShockEventList } from "./ui/organisms/filter-shock-event-list";
import { declareRoute } from "./routes";
import { ExportButton } from "./ui/molecules/export-button";
import { routeColumnVisibility } from "./pages/page-column-visibility";
import { FormUpdateDefaultTimezone } from "./ui/molecules/form-update-default-timezone";
import { routeDashboard } from "./pages/page-dashboard";
import { FormUpdateDefaultDateFormat } from "./ui/molecules/form-update-default-date-format";
import { FormUpdateDefaultTimeFormat } from "./ui/molecules/form-update-default-time-format";
import { FilterPreOpsAnswerList } from "./ui/organisms/filter-pre-ops-answers";
import { RepositoryPhones } from "./domain/repositories/repository-phones";
import { ListPhones } from "./ui/molecules/list-phones";
import { ListPhoneItemCell } from "./ui/atoms/list-phone-item-cell";
import { routeDispatcher } from "./pages/page-dispatcher";
import { ListVehicleTypes } from "./ui/list-vehicle-types";
import { ListCommandButtonsVehicleTypes } from "./ui/organisms/list-command-buttons-vehicle-types";
import { FormCreateVehicleType } from "./ui/molecules/form-create-vehicle-type";
import { FormUpdateVehicleType } from "./ui/molecules/form-update-vehicle-type";
import { MultiSelectComboBox } from "./ui/atoms/multi-select-connected-combo-box";
import { ServiceHelp } from "./domain/services/service-help";

/**
 * This is the root TSDI application container.
 *
 * @see Take a look at [TSDI Container hierarchies](https://tsdi.js.org/docs/en/features#container-hierarchies).
 */
export class Application {
    constructor(private apiConfiguration: Configuration) {
        // For pages that are not using TSDI, declare routes here
        // as they would otherwise not be imported anywhere, excluding them from the bundle.
        declareRoute(routeDashboard);
        declareRoute(routeColumnVisibility);
        declareRoute(routeDispatcher);
    }

    // Providers.
    @configure protected history(): HistoryProvider {
        return new HistoryProvider(createBrowserHistory());
    }
    @configure protected i18nProvider!: I18nProvider;

    // Pages.
    @configure protected pageHeader!: PageHeader;
    @configure protected pageImpactManagement!: PageImpactManagement;
    @configure protected pageLogin!: PageLogin;
    @configure protected pageMaintenance!: PageMaintenance;
    @configure protected pageProductivity!: PageVehicleProductivity;
    @configure protected pageLoginReport!: PageLoginReport;
    @configure protected pageSettings!: PageSettings;
    @configure protected pageGeneralSettings!: PageGeneralSettings;
    @configure protected pageShockProfiles!: PageShockProfiles;
    @configure protected pageSafetyMessages!: PageSafetyMessages;
    @configure protected pageSafetyMessagesSettings!: PageSafetyMessagesSettings;
    @configure protected pageUserGroups!: PageUserGroups;
    @configure protected pageUsers!: PageUsers;
    @configure protected pageUserProductivity!: PageUserProductivity;
    @configure protected pageVehicle!: PageVehicle;
    @configure protected pageVehicleDevices!: PageVehicleDevices;
    @configure protected pageVehicleGroups!: PageVehicleGroups;
    @configure protected pageVehicleMovementPeriods!: PageVehicleMovementPeriods;
    @configure protected pageVehicles!: PageVehicles;
    @configure protected pageUserSettings!: PageUserSettings;
    @configure protected pageVehicleSettings!: PageVehicleSettings;
    @configure protected pagePreOpsChecklists!: PagePreOpsChecklists;
    @configure protected pagePreOpsChecklistSettings!: PageChecklistSettings;
    @configure protected pageSystemWide!: PageSystemWide;

    // React Components.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @configure protected listCommandButton!: ListCommandButtons<any>;
    @configure protected dashboardItemCell!: DashboardItemCell;
    @configure protected exportButton!: ExportButton;
    @configure protected listCommandButtonsPreOpsChecklists!: ListCommandButtonsPreOpsChecklists;
    @configure protected listCommandButtonsSafetyMessages!: ListCommandButtonsSafetyMessages;
    @configure protected listCommandButtonSites!: ListCommandButtonsSites;
    @configure protected listCommandButtonDepartments!: ListCommandButtonsDepartments;
    @configure protected listCommandButtonShifts!: ListCommandButtonsShifts;
    @configure protected listCommandButtonShockProfiles!: ListCommandButtonsShockProfiles;
    @configure protected listCommandButtonUsers!: ListCommandButtonsUsers;
    @configure
    protected listCommandButtonUserGroupAssignments!: ListCommandButtonsUserGroupAssignments;
    @configure protected listUserGroupAddButton!: ListUserGroupAddButton;
    @configure protected listVehicleAddButton!: ListVehicleAddButton;
    @configure protected listVehicleGroupAddButton!: ListVehicleGroupAddButton;
    @configure protected listCommandButtonUserGroups!: ListCommandButtonsUserGroups;
    @configure
    @configure
    protected listCommandButtonVehicleDevices!: ListCommandButtonsVehicleDevices;
    @configure protected listCommandButtonVehicles!: ListCommandButtonsVehicles;
    @configure
    protected listCommandButtonVehicleGroupAssignments!: ListCommandButtonsVehicleGroupAssignments;
    @configure protected listCommandButtonVehicleGroups!: ListCommandButtonsVehicleGroups;
    @configure protected listCommandButtonVehicleTypes!: ListCommandButtonsVehicleTypes;
    @configure protected listPreOpsChecklistButton!: ListPreOpsChecklistButtons;
    @configure protected errorBars!: ErrorBars;
    @configure protected dashboardDatePicker!: DatePicker;
    @configure protected filterByDateRange!: FilterByDateRange;
    @configure protected filterPreOpsAnswerList!: FilterPreOpsAnswerList;
    @configure protected filterShockEventList!: FilterShockEventList;
    @configure protected filterUserList!: FilterUserList;
    @configure protected filterVehicleList!: FilterVehicleList;
    @configure protected filterVehicleMovementPeriodList!: FilterVehicleMovementPeriodList;
    @configure protected formCreatePreOpsChecklist!: FormCreatePreOpsChecklist;
    @configure protected formCreateSafetyMessage!: FormCreateSafetyMessage;
    @configure protected formCreateSite!: FormCreateSite;
    @configure protected formCreateDepartments!: FormCreateDepartment;
    @configure protected formCreateShifts!: FormCreateShift;
    @configure protected formCreateShockProfile!: FormCreateShockProfile;
    @configure protected formCreateUser!: FormCreateUser;
    @configure protected formCreateUserGroup!: FormCreateUserGroup;
    @configure
    protected formCreateUserGroupVehicleGroupAssignments!: FormCreateUserGroupVehicleGroupAssignments;
    @configure protected formCreateUserGroupAssignments!: FormCreateUserGroupAssignments;
    @configure protected formCreateVehicleGroupAssignments!: FormCreateVehicleGroupAssignments;

    @configure protected formCreateVehicle!: FormCreateVehicle;
    @configure protected formCreateVehicleGroup!: FormCreateVehicleGroup;
    @configure protected formCreateVehicleType!: FormCreateVehicleType;
    @configure protected formResetPassword!: FormResetPassword;
    @configure protected FormUpdateDefaultLanguage!: FormUpdateDefaultLanguage;
    @configure protected FormUpdateDefaultTimezone!: FormUpdateDefaultTimezone;
    @configure protected FormUpdateDefaultDateFormat!: FormUpdateDefaultDateFormat;
    @configure protected FormUpdateDefaultTimeFormat!: FormUpdateDefaultTimeFormat;
    @configure protected formUpdatePreOpsChecklist!: FormUpdatePreOpsChecklist;
    @configure protected formUpdateSite!: FormUpdateSite;
    @configure protected formUpdateSafetyMessage!: FormUpdateSafetyMessage;
    @configure protected formUpdateDepartment!: FormUpdateDepartment;
    @configure protected formUpdateShift!: FormUpdateShift;
    @configure protected formUpdateShockProfile!: FormUpdateShockProfile;
    @configure protected formUpdateUser!: FormUpdateUser;
    @configure protected formUpdateUserGroup!: FormUpdateUserGroup;
    @configure protected formUpdateUserProfileSettings!: FormUpdateUserProfileSettings;
    @configure protected formUpdateVehicleDevice!: FormUpdateVehicleDevice;
    @configure protected formUpdateVehicle!: FormUpdateVehicle;
    @configure protected formUpdateVehicleGroup!: FormUpdateVehicleGroup;
    @configure protected formUpdateVehicleType!: FormUpdateVehicleType;
    @configure protected formUpdateSettingPreOps!: FormUpdateSettingPreOps;
    @configure protected formUpdateSettingVehicle!: FormUpdateSettingVehicle;
    @configure protected formUpdateSetting!: FormUpdateNotificationMails;
    @configure protected languageButtons!: LanguageButtons;
    @configure protected layoutDefault!: LayoutDefault;
    @configure protected listMotionSensors!: ListMotionSensors;
    @configure protected listMotionSensorItemCell!: ListMotionSensorItemCell;
    @configure protected listSafetyMessages!: ListSafetyMessages;
    @configure protected listShockEvents!: ListShockEvents;
    @configure protected listShockEventItemCell!: ListShockEventItemCell;
    @configure protected listShockProfiles!: ListShockProfiles;
    @configure protected listShockProfileItemCell!: ListShockProfileItemCell;
    @configure protected listPhones!: ListPhones;
    @configure protected listPhoneItemCell!: ListPhoneItemCell;
    @configure protected listPreOpsChecklists!: ListPreOpsChecklists;
    @configure protected listPreOpsChecklistItemCell!: ListPreOpsChecklistItemCell;
    @configure protected listSites!: ListSites;
    @configure protected listDepartments!: ListDepartments;
    @configure protected listShifts!: ListShifts;
    @configure protected listUsersItemCell!: ListUserItemCell;
    @configure protected listUsers!: ListUsers;
    @configure protected listUserProductivityItemCell!: ListUserProductivityItemCell;
    @configure protected listUserProductivity!: ListUserProductivity;
    @configure protected listUserGroupAssignments!: ListUserGroupAssignments;
    @configure protected listUserGroups!: ListUserGroups;
    @configure protected listVehicleProductivity!: ListVehicleProductivity;
    @configure protected listVehicleProductivityItemCell!: ListVehicleProductivityItemCell;
    @configure protected listVehicleDevices!: ListVehicleDevices;
    @configure protected listVehicleDeviceItemCell!: ListVehicleDeviceItemCell;
    @configure protected listVehicleItemCell!: ListVehicleItemCell;
    @configure protected listVehicleMovementPeriods!: ListVehicleMovementPeriods;
    @configure protected listVehicleMovementPeriodItemCell!: ListVehicleMovementPeriodItemCell;
    @configure protected listVehicleTypes!: ListVehicleTypes;
    @configure protected listPreOpsAnswerItemCell!: ListPreOpsAnswerItemCell;
    @configure protected listVehicles!: ListVehicles;
    @configure protected listVehicleGroupAssignments!: ListVehicleGroupAssignments;
    @configure protected listVehicleGroups!: ListVehicleGroups;
    @configure protected listLoginReport!: ListLoginReport;
    @configure protected listLoginReportItemCell!: ListLoginReportItemCell;
    @configure protected listPreOpsAnswers!: ListPreOpsAnswers;
    @configure protected modalResetPassword!: ModalResetPassword;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @configure protected multiSelectComboBox!: MultiSelectComboBox<any, any>;
    @configure protected navItem!: NavigationItem;
    @configure protected navGroup!: NavigationGroup;
    @configure protected panelVehicleGroupDetails!: PanelVehicleGroupDetails;
    @configure protected panelUserGroupDetails!: PanelUserGroupDetails;
    @configure protected section!: Section;
    @configure protected sectionPreOpsCheclistSettings!: SectionPreOpsChecklistSettings;
    @configure protected selectLanguage!: SelectLanguage;
    @configure protected severityPill!: SeverityPill;
    @configure protected sidebarNavigation!: SidebarNavigation;
    @configure protected sidebarPrimary!: SidebarPrimary;
    @configure protected paginationButtons!: PaginationButtons;
    @configure protected userMenu!: UserMenu;
    @configure protected userBadge!: CompanyLogo;
    @configure protected modalConfirmation!: ModalConfirmation;
    @configure protected notificationEmails!: NotificationEmails;
    @configure protected elofleetDialog!: ElofleetDialog;
    @configure protected elofleetDialogFooter!: ElofleetDialogFooter;
    @configure protected helpArticleFull!: HelpArticleFull;
    @configure protected helpArticlePreview!: HelpArticlePreview;
    @configure protected helpButton!: HelpButton;
    @configure protected helpSearch!: HelpSearch;
    @configure protected helpSidebar!: HelpSidebar;
    @configure protected helpTagBubble!: HelpTagBubble;
    @configure protected notificationMenu!: NotificationMenu;
    @configure protected imageUploader!: ImageUploader;
    @configure protected pageTruckActivity!: PageTruckActivity;
    @configure protected pagePedestrianActivity!: PagePedestrianActivity;
    @configure protected pageZoningActivity!: PageZoningActivity;
    @configure protected listTruckEvents!: ListTruckEvents;
    @configure protected listTruckEventItemCell!: ListTruckEventItemCell;
    @configure protected notificationPivotMenu!: NotificationPivotMenu;
    @configure protected notificationLabel!: NotificationLabel;
    @configure protected checklistNotificationItem!: ChecklistNotificationItem;
    @configure protected impactNotificationItem!: ImpactNotificationItem;
    @configure protected maintenanceNotificationItem!: MaintenanceNotificationItem;
    @configure protected floatInput!: FloatInput;
    @configure protected listPedestrianEvents!: ListPedestrianEvents;
    @configure protected listZoneEvents!: ListZoneEvents;
    @configure protected pageEmailLoginTokenLogin!: PageEmailLoginTokenLogin;

    // Api.
    @configure protected artifactsApi(): ArtifactsApi {
        return new ArtifactsApi(this.apiConfiguration);
    }
    @configure protected loginTokensApi(): LoginTokensApi {
        return new LoginTokensApi(this.apiConfiguration);
    }

    @configure protected motionSensorsApi(): MotionSensorsApi {
        return new MotionSensorsApi(this.apiConfiguration);
    }

    @configure protected vehicleDevicesApi(): VehicleDevicesApi {
        return new VehicleDevicesApi(this.apiConfiguration);
    }

    @configure protected vehiclesApi(): VehiclesApi {
        return new VehiclesApi(this.apiConfiguration);
    }

    @configure protected shockEventsApi(): ShockEventsApi {
        return new ShockEventsApi(this.apiConfiguration);
    }

    @configure protected shockProfiles(): ShockProfilesApi {
        return new ShockProfilesApi(this.apiConfiguration);
    }

    @configure protected shockProfileVersions(): ShockProfileVersionsApi {
        return new ShockProfileVersionsApi(this.apiConfiguration);
    }

    @configure protected sitesApi(): SitesApi {
        return new SitesApi(this.apiConfiguration);
    }

    @configure protected vehicleMovementsApi(): VehicleMovementsApi {
        return new VehicleMovementsApi(this.apiConfiguration);
    }

    @configure protected vehicleTypesApi(): VehicleTypesApi {
        return new VehicleTypesApi(this.apiConfiguration);
    }

    @configure protected usersApi(): UsersApi {
        return new UsersApi(this.apiConfiguration);
    }

    @configure protected usersGroupsApi(): UserGroupsApi {
        return new UserGroupsApi(this.apiConfiguration);
    }

    @configure protected vehicleGroupsApi(): VehicleGroupsApi {
        return new VehicleGroupsApi(this.apiConfiguration);
    }

    @configure protected vehicleGroupAssignmentsApi(): VehicleGroupAssignmentsApi {
        return new VehicleGroupAssignmentsApi(this.apiConfiguration);
    }

    @configure protected userGroupAssignmentsApi(): UserGroupAssignmentsApi {
        return new UserGroupAssignmentsApi(this.apiConfiguration);
    }

    @configure
    protected userGroupVehicleGroupAssignmentsApi(): UserGroupVehicleGroupAssignmentsApi {
        return new UserGroupVehicleGroupAssignmentsApi(this.apiConfiguration);
    }

    @configure protected newsApi(): NewsApi {
        return new NewsApi(this.apiConfiguration);
    }

    @configure protected nfcTokensApi(): NfcTokensApi {
        return new NfcTokensApi(this.apiConfiguration);
    }

    @configure protected filesApi(): FilesApi {
        return new FilesApi(this.apiConfiguration);
    }

    @configure protected dashboardApi(): DashboardApi {
        return new DashboardApi(this.apiConfiguration);
    }

    @configure protected departmentsApi(): DepartmentsApi {
        return new DepartmentsApi(this.apiConfiguration);
    }

    @configure protected safetyMessageApi(): SafetyMessagesApi {
        return new SafetyMessagesApi(this.apiConfiguration);
    }

    @configure protected shiftsApi(): ShiftsApi {
        return new ShiftsApi(this.apiConfiguration);
    }

    @configure protected persistentNotificationsApi(): PersistentNotificationsApi {
        return new PersistentNotificationsApi(this.apiConfiguration);
    }

    @configure protected phonesApi(): PhonesApi {
        return new PhonesApi(this.apiConfiguration);
    }

    @configure protected preOpsChecklistsApi(): PreOpsChecklistsApi {
        return new PreOpsChecklistsApi(this.apiConfiguration);
    }

    @configure protected preOpsQuestionsApi(): PreOpsQuestionsApi {
        return new PreOpsQuestionsApi(this.apiConfiguration);
    }

    @configure protected preOpsQuestionVersionsApi(): PreOpsQuestionVersionsApi {
        return new PreOpsQuestionVersionsApi(this.apiConfiguration);
    }

    @configure protected preOpsAnswersApi(): PreOpsAnswersApi {
        return new PreOpsAnswersApi(this.apiConfiguration);
    }

    @configure protected settingsApi(): SettingsApi {
        return new SettingsApi(this.apiConfiguration);
    }

    @configure protected publicSettingsApi(): PublicSettingsApi {
        return new PublicSettingsApi(this.apiConfiguration);
    }

    @configure protected columnVisibilitysApi(): ColumnVisibilitysApi {
        return new ColumnVisibilitysApi(this.apiConfiguration);
    }

    @configure protected fleetAuthenticateEventsApi(): FleetAuthenticateEventsApi {
        return new FleetAuthenticateEventsApi(this.apiConfiguration);
    }

    @configure protected emailLoginTokensApi(): EmailLoginTokensApi {
        return new EmailLoginTokensApi(this.apiConfiguration);
    }

    // Services.
    @configure protected serviceAuth!: ServiceAuth;
    @configure protected ServicePublicSettings!: ServicePublicSettings;
    @configure protected ServiceSse!: ServiceSse;
    @configure protected serviceErrors!: ServiceErrors;
    @configure protected serviceOwnUser!: ServiceOwnUser;
    @configure protected serviceFile!: ServiceFile;
    @configure protected serviceHelp!: ServiceHelp;
    @configure protected serviceListStates!: ServiceListStates;
    @configure protected serviceSidebar!: ServiceSidebar;
    @configure protected serviceStubData!: ServiceStubData;
    @configure protected serviceSentry!: ServiceSentry;

    // Repositories.
    @configure protected repositoryColumnVisibility!: RepositoryColumnVisibility;
    @configure protected repositorySetting!: RepositorySettings;
    @configure protected repositoryPublicSetting!: RepositoryPublicSettings;
    @configure protected repositoryNfcTokens!: RepositoryNfcTokens;
    @configure protected repositorySafetyMessages!: RepositorySafetyMessages;
    @configure protected repositoryPersistentNotifications!: RepositoryPersistentNotifications;
    @configure protected repositoryMotionSensors!: RepositoryMotionSensors;
    @configure protected repositoryPhones!: RepositoryPhones;
    @configure protected repositoryPreOpsChecklists!: RepositoryPreOpsChecklists;
    @configure protected repositoryPreOpsQuestions!: RepositoryPreOpsQuestions;
    @configure protected repositoryPreOpsQuestionVersions!: RepositoryPreOpsQuestionVersions;
    @configure protected repositoryPreOpsAnswers!: RepositoryPreOpsAnswers;
    @configure protected repositoryShockEvents!: RepositoryShockEvents;
    @configure protected repositoryShockProfiles!: RepositoryShockProfiles;
    @configure protected repositoryShockProfileVersions!: RepositoryShockProfileVersions;
    @configure protected repositorySites!: RepositorySites;
    @configure protected repositoryDepartments!: RepositoryDepartments;
    @configure protected repositoryShifts!: RepositoryShifts;
    @configure protected repositoryUsers!: RepositoryUsers;
    @configure protected repositoryUserGroupAssignments!: RepositoryUserGroupAssignments;
    @configure
    protected repositoryUserGroupVehicleGroupAssignments!: RepositoryUserGroupVehicleGroupAssignments;
    @configure protected repositoryUserGroups!: RepositoryUserGroups;
    @configure protected repositoryVehicleDevices!: RepositoryVehicleDevices;
    @configure protected repositoryVehicles!: RepositoryVehicles;
    @configure protected repositoryVehicleGroupAssignments!: RepositoryVehicleGroupAssignments;
    @configure protected repositoryVehicleGroups!: RepositoryVehicleGroups;
    @configure protected repositoryVehicleMovementPeriods!: RepositoryVehicleMovementPeriods;
    @configure protected repositoryVehicleTypes!: RepositoryVehicleTypes;
    @configure protected repositoryFleetAuthenticatePeriods!: RepositoryFleetAuthenticatePeriods;

    // Other.
    @configure protected paginationState!: PaginationState<ApiResource>;
    @configure protected searchState!: SearchState;
    @configure protected sortState!: SortState<string>;
    @configure protected selectionState!: SelectionState;
}
