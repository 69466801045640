import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { ServiceHelp } from "../../domain/services/service-help";
import { HelpTag } from "../../help-types";
import css from "./help-tag-bubble.scss";

export interface HelpTagBubbleProps {
    readonly tag: HelpTag;
}

@external
@observer
export class HelpTagBubble extends React.Component<HelpTagBubbleProps> {
    @inject private i18n!: I18nProvider;
    @inject private serviceHelp!: ServiceHelp;

    public render(): JSX.Element {
        const { tag } = this.props;
        return (
            <div className={css.helpTagBubble} onClick={() => this.serviceHelp.browseTag(tag)}>
                <span className={css.helpTagBubble__name}>{this.i18n.t(tag.title)}</span>
                <span className={css.helpTagBubble__count}>
                    {this.serviceHelp.countArticlesInTag(tag)}
                </span>
            </div>
        );
    }
}
