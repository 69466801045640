import * as React from "react";
import { IColumn } from "@fluentui/react";

interface ZoneActivity {
    readonly eventTime: string;
    readonly stationaryUnitID: string;
    readonly zoneName: string;
    readonly zoneTrigger: string;
    readonly oem: string;
    readonly oemType: string;
    readonly detectedELOshieldId: string;
    readonly detectedTruckId: number;
    readonly duration: string;
    readonly id: number;
}
export interface Zone {
    key: number;
    data: ZoneActivity;
}
export interface ListZoneEventItemCellProps {
    readonly truckEventId: number;
    readonly column: IColumn;
    readonly item: Zone;
}

export class ListZoneEventItemCell extends React.Component<ListZoneEventItemCellProps> {
    public render(): JSX.Element {
        const { column } = this.props;
        const { data } = this.props.item;

        switch (column.key) {
            case "event-time":
                return <span>{data?.eventTime}</span>;
            case "stationary-id":
                return <span>{data?.stationaryUnitID}</span>;
            case "zone-name":
                return <span>{data?.zoneName}</span>;
            case "zone-trigger":
                return <span>{data?.zoneTrigger}</span>;
            case "detected-eloshield-id":
                return <span>{data?.detectedELOshieldId}</span>;
            case "detected-truck-id":
                return <span>{data?.detectedTruckId}</span>;
            case "oem":
                return <span>{data?.oem}</span>;
            case "oem-type":
                return <span>{data?.oemType}</span>;
            case "duration":
                return <span>{data?.duration}</span>;
            default:
                return <></>;
        }
    }
}
