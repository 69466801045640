import { runInAction } from "mobx";
import { FetchByQueryResult, Segment } from "mobx-repository";
import { prop } from "ramda";
import { component, inject } from "tsdi";
import {
    PageInfo,
    SafetyMessageCreate,
    SafetyMessagesApi,
    SafetyMessagesListSafetyMessagesRequest,
    SafetyMessage,
    SafetyMessageUpdate,
    SafetyMessageSortKey,
} from "../../api";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";
import { UUID } from "../../utils/uuid";
import { Validation } from "../../utils/validation";
import { defaultPageSize } from "../../utils/constants";

export type SafetyMessagesQuery = Omit<SafetyMessagesListSafetyMessagesRequest, "page">;

export interface SafetyMessagesApiResource extends ApiResource {
    entity: SafetyMessage;
    query: SafetyMessagesQuery;
    update: SafetyMessageUpdate;
    create: SafetyMessageCreate;
    sortKey: SafetyMessageSortKey;
}

@component
export class RepositorySafetyMessages extends ElofleetRepository<SafetyMessagesApiResource> {
    @inject private readonly safetyMessagesApi!: SafetyMessagesApi;

    private metadataRequest: Promise<PageInfo> | undefined;

    public validation = {
        create: new Validation((safetyMessageCreate: SafetyMessageCreate) =>
            this.safetyMessagesApi.safetyMessagesValidateCreateSafetyMessage({
                safetyMessageCreate,
            }),
        ),
        update: new Validation((safetyMessageUpdate: SafetyMessageUpdate, safetyMessageId: UUID) =>
            this.safetyMessagesApi.safetyMessagesValidateUpdateSafetyMessage({
                safetyMessageUpdate,
                safetyMessageId,
            }),
        ),
    };

    protected async fetchById(safetyMessageId: UUID): Promise<SafetyMessage | undefined> {
        return await this.safetyMessagesApi.safetyMessagesReadSafetyMessage({
            safetyMessageId,
        });
    }

    protected extractId = prop("id");

    protected async fetchByQuery(
        query: SafetyMessagesQuery,
        pagination: Segment,
    ): Promise<FetchByQueryResult<SafetyMessage>> {
        const page = pagination.offset / this.defaultCount;
        const entities = await this.safetyMessagesApi.safetyMessagesListSafetyMessages({
            ...query,
            page,
        });
        return { entities };
    }

    public async waitForIdle(): Promise<void> {
        await Promise.all([super.waitForIdle(), this.metadataRequest]);
    }

    public fetchMetadata(_: SafetyMessagesQuery): Promise<PageInfo> {
        this.metadataRequest = this.safetyMessagesApi.safetyMessagesListSafetyMessagesMetadata({
            pageSize: defaultPageSize,
        });
        return this.metadataRequest;
    }

    public async update(
        safetyMessageId: UUID,
        safetyMessageUpdate: SafetyMessageUpdate,
    ): Promise<SafetyMessage> {
        const safetyMessage = await this.wrapApiCall(
            this.safetyMessagesApi.safetyMessagesUpdateSafetyMessage({
                safetyMessageId,
                safetyMessageUpdate,
            }),
        );
        await this.waitForIdle();
        runInAction(() => {
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(safetyMessage);
        });
        return safetyMessage;
    }

    public async create(safetyMessageCreate: SafetyMessageCreate): Promise<SafetyMessage> {
        const safetyMessage = await this.wrapApiCall(
            this.safetyMessagesApi.safetyMessagesCreateSafetyMessage({
                safetyMessageCreate,
            }),
        );
        await this.waitForIdle();
        runInAction(() => {
            this.reloadQuery({ pageSize: defaultPageSize });
            this.add(safetyMessage);
        });
        return safetyMessage;
    }

    public async delete(...safetyMessageIds: UUID[]): Promise<void> {
        await this.wrapApiCall(
            Promise.all(
                safetyMessageIds.map((safetyMessageId) =>
                    this.safetyMessagesApi.safetyMessagesDeleteSafetyMessage({ safetyMessageId }),
                ),
            ),
        );
        await this.waitForIdle();
        runInAction(() => {
            this.reloadQuery({ pageSize: defaultPageSize });
        });
    }
}
