import { action, makeObservable } from "mobx";
import * as React from "react";
import { RepositoryVehicleTypes } from "../../domain/repositories/repository-vehicle-types";
import { ListCommandButtons, ListCommandButtonsProps } from "./list-command-buttons";
import { external, inject } from "tsdi";
import { observer } from "mobx-react";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { FormCreateVehicleType } from "../molecules/form-create-vehicle-type";
import { UUID } from "../../utils/uuid";
import { FormUpdateVehicleType } from "../molecules/form-update-vehicle-type";

export type ListCommandButtonsVehicleTypesProps = Omit<
    ListCommandButtonsProps<RepositoryVehicleTypes>,
    "list" | "createForm" | "listState"
>;

@external
@observer
export class ListCommandButtonsVehicleTypes extends React.Component<ListCommandButtonsVehicleTypesProps> {
    @inject private readonly repositoryVehicleTypes!: RepositoryVehicleTypes;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject protected readonly i18n!: I18nProvider;

    constructor(props: ListCommandButtonsVehicleTypesProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private async deleteVehicleTypes(ids: UUID[]): Promise<void> {
        await this.repositoryVehicleTypes.delete(...ids);
        if (this.props.onDelete) {
            this.props.onDelete(ids);
        }
    }

    public render(): JSX.Element {
        return (
            <ListCommandButtons
                createForm={FormCreateVehicleType}
                updateForm={FormUpdateVehicleType}
                listState={this.serviceListStates.vehicleTypes}
                {...this.props}
                onDelete={this.deleteVehicleTypes}
            />
        );
    }
}
