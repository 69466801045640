/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  Setting,
  SettingUpdate,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    SettingFromJSON,
    SettingToJSON,
    SettingUpdateFromJSON,
    SettingUpdateToJSON,
} from '../models';

export interface SettingsReadSettingByIdRequest {
    id: string;
}

export interface SettingsUpdateSettingRequest {
    id: string;
    settingUpdate: SettingUpdate;
}

export interface SettingsValidateUpdateSettingRequest {
    id: string;
    settingUpdate: SettingUpdate;
}

/**
 * SettingsApi - interface
 * 
 * @export
 * @interface SettingsApiInterface
 */
export interface SettingsApiInterface {
    /**
     * Since there\'s only a single settings row, we don\'t take an ID parameter here.
     * @summary Fetch the settings entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApiInterface
     */
    settingsReadSettingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Setting>>;

    /**
     * Since there\'s only a single settings row, we don\'t take an ID parameter here.
     * Fetch the settings entity
     */
    settingsReadSetting(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Setting>;

    /**
     * This route aids to keep the frontend settings repository consistent to the other respositories
     * @summary Fetch the settings entity by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApiInterface
     */
    settingsReadSettingByIdRaw(requestParameters: SettingsReadSettingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Setting>>;

    /**
     * This route aids to keep the frontend settings repository consistent to the other respositories
     * Fetch the settings entity by id
     */
    settingsReadSettingById(requestParameters: SettingsReadSettingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Setting>;

    /**
     * Update the setting entity. Since we know the ID of the singleton entity after reading, we take an ID argument here to keep the API consistent with other update endpoints.
     * @summary Update setting
     * @param {string} id 
     * @param {SettingUpdate} settingUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApiInterface
     */
    settingsUpdateSettingRaw(requestParameters: SettingsUpdateSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Setting>>;

    /**
     * Update the setting entity. Since we know the ID of the singleton entity after reading, we take an ID argument here to keep the API consistent with other update endpoints.
     * Update setting
     */
    settingsUpdateSetting(requestParameters: SettingsUpdateSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Setting>;

    /**
     * Validate an update struct for the setting entity.
     * @summary Validate setting update
     * @param {string} id 
     * @param {SettingUpdate} settingUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApiInterface
     */
    settingsValidateUpdateSettingRaw(requestParameters: SettingsValidateUpdateSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate an update struct for the setting entity.
     * Validate setting update
     */
    settingsValidateUpdateSetting(requestParameters: SettingsValidateUpdateSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class SettingsApi extends runtime.BaseAPI implements SettingsApiInterface {

    /**
     * Since there\'s only a single settings row, we don\'t take an ID parameter here.
     * Fetch the settings entity
     */
    async settingsReadSettingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Setting>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingFromJSON(jsonValue));
    }

    /**
     * Since there\'s only a single settings row, we don\'t take an ID parameter here.
     * Fetch the settings entity
     */
    async settingsReadSetting(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Setting> {
        const response = await this.settingsReadSettingRaw(initOverrides);
        return await response.value();
    }

    /**
     * This route aids to keep the frontend settings repository consistent to the other respositories
     * Fetch the settings entity by id
     */
    async settingsReadSettingByIdRaw(requestParameters: SettingsReadSettingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Setting>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling settingsReadSettingById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingFromJSON(jsonValue));
    }

    /**
     * This route aids to keep the frontend settings repository consistent to the other respositories
     * Fetch the settings entity by id
     */
    async settingsReadSettingById(requestParameters: SettingsReadSettingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Setting> {
        const response = await this.settingsReadSettingByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the setting entity. Since we know the ID of the singleton entity after reading, we take an ID argument here to keep the API consistent with other update endpoints.
     * Update setting
     */
    async settingsUpdateSettingRaw(requestParameters: SettingsUpdateSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Setting>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling settingsUpdateSetting.');
        }

        if (requestParameters.settingUpdate === null || requestParameters.settingUpdate === undefined) {
            throw new runtime.RequiredError('settingUpdate','Required parameter requestParameters.settingUpdate was null or undefined when calling settingsUpdateSetting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SettingUpdateToJSON(requestParameters.settingUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingFromJSON(jsonValue));
    }

    /**
     * Update the setting entity. Since we know the ID of the singleton entity after reading, we take an ID argument here to keep the API consistent with other update endpoints.
     * Update setting
     */
    async settingsUpdateSetting(requestParameters: SettingsUpdateSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Setting> {
        const response = await this.settingsUpdateSettingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate an update struct for the setting entity.
     * Validate setting update
     */
    async settingsValidateUpdateSettingRaw(requestParameters: SettingsValidateUpdateSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling settingsValidateUpdateSetting.');
        }

        if (requestParameters.settingUpdate === null || requestParameters.settingUpdate === undefined) {
            throw new runtime.RequiredError('settingUpdate','Required parameter requestParameters.settingUpdate was null or undefined when calling settingsValidateUpdateSetting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings/{id}/validate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SettingUpdateToJSON(requestParameters.settingUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate an update struct for the setting entity.
     * Validate setting update
     */
    async settingsValidateUpdateSetting(requestParameters: SettingsValidateUpdateSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.settingsValidateUpdateSettingRaw(requestParameters, initOverrides);
    }

}
