import { ActionButton } from "@fluentui/react";
import { observable, action, makeObservable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { inject, external } from "tsdi";
import { Department, VehicleGroup } from "../../api";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { RepositoryDepartments } from "../../domain/repositories/repository-departments";
import { RepositoryVehicleGroups } from "../../domain/repositories/repository-vehicle-groups";
import { MultiSelectComboBox } from "../atoms/multi-select-connected-combo-box";

export interface FilterVehicleListProps {
    /** This callback function allows the filter to rerender
     * the parent component. */
    setVehicleListFilter(filter: FilterVehicleListQuery): void;
}

export type FilterVehicleListQuery = {
    filteredDepartments: string[];
    filteredVehicleGroups: string[];
    isFiltered: boolean;
};

/**
 * A class which can filter the vehicle list by departments, vehicle groups
 * and vehicle types.
 */
@external
@observer
export class FilterVehicleList extends React.Component<FilterVehicleListProps> {
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly repositoryDepartments!: RepositoryDepartments;
    @inject private readonly repositoryVehicleGroups!: RepositoryVehicleGroups;

    @observable private filteredDepartments: string[] = [];
    @observable private filteredVehicleGroups: string[] = [];
    @observable private isFiltered: boolean = false;

    constructor(props: FilterVehicleListProps) {
        super(props);
        makeObservable(this);
    }

    private setDepartmentFilter(filter: string): void {
        // Remove element if it is already clicked.
        if (this.filteredDepartments.includes(filter)) {
            this.filteredDepartments = this.filteredDepartments.filter((id) => id !== filter);
            this.setVehicleListFilter();
            return;
        }
        this.isFiltered = true;
        this.filteredDepartments = [filter, ...this.filteredDepartments];
        this.setVehicleListFilter();
    }

    private setVehicleGroupFilter(filter: string): void {
        // Remove element if it is already clicked.
        if (this.filteredVehicleGroups.includes(filter)) {
            this.filteredVehicleGroups = this.filteredVehicleGroups.filter((id) => id !== filter);
            this.setVehicleListFilter();
            return;
        }
        this.isFiltered = true;
        this.filteredVehicleGroups = [filter, ...this.filteredVehicleGroups];
        this.setVehicleListFilter();
    }

    @action.bound private clearFilter(): void {
        this.filteredDepartments = [];
        this.filteredVehicleGroups = [];
        this.isFiltered = false;
        this.setVehicleListFilter();
    }

    private setVehicleListFilter(): void {
        const filterQuery: FilterVehicleListQuery = {
            filteredDepartments: this.filteredDepartments,
            filteredVehicleGroups: this.filteredVehicleGroups,
            isFiltered: this.isFiltered,
        };
        this.props.setVehicleListFilter(filterQuery);
    }

    public render(): JSX.Element {
        return (
            <>
                <MultiSelectComboBox
                    allowFreeForm={false}
                    placeholder={this.i18n.t("schema.vehicle.department")}
                    repository={this.repositoryDepartments}
                    formatEntity={(department: Department) => department.label}
                    selectedKeys={this.filteredDepartments}
                    onChange={(departmentId: string | undefined) => {
                        if (departmentId === undefined) {
                            return;
                        }
                        this.setDepartmentFilter(departmentId);
                    }}
                    allowSelectAll
                />
                <MultiSelectComboBox
                    allowFreeForm={false}
                    placeholder={this.i18n.t("schema.vehicle.vehicleGroup")}
                    repository={this.repositoryVehicleGroups}
                    formatEntity={(vehicleGroup: VehicleGroup) => vehicleGroup.label}
                    selectedKeys={this.filteredVehicleGroups}
                    onChange={(vehicleGroupId: string | undefined) => {
                        if (vehicleGroupId === undefined) {
                            return;
                        }
                        this.setVehicleGroupFilter(vehicleGroupId);
                    }}
                    allowSelectAll
                />
                <ActionButton
                    iconProps={{ iconName: "clearFilter" }}
                    allowDisabledFocus
                    disabled={!this.isFiltered}
                    checked={true}
                    onClick={this.clearFilter}
                ></ActionButton>
            </>
        );
    }
}
