import * as React from "react";
import { external } from "tsdi";
import { DefaultButton, IContextualMenuProps } from "@fluentui/react";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { inject } from "tsdi";
import { UUID } from "../../utils/uuid";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import sizes from "../sizes.scss";
import { FormCreateVehicle } from "../molecules/form-create-vehicle";
import { FormUpdateSettingVehicle } from "../molecules/form-update-setting-vehicle";
import { ElofleetDialog } from "../atoms/elofleet-dialog";

export interface ListVehicleAddButtonProps {
    readonly listStateId?: UUID;
}

@external
@observer
export class ListVehicleAddButton extends React.Component<ListVehicleAddButtonProps> {
    @inject protected readonly i18n!: I18nProvider;

    @observable private settingVehicleDialogVisible = false;
    @observable private createVehicleDialogVisible = false;

    constructor(props: ListVehicleAddButtonProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound private openSettingVehicleDialog(): void {
        this.settingVehicleDialogVisible = true;
    }

    @action.bound private closeSettingVehicleDialog(): void {
        this.settingVehicleDialogVisible = false;
    }

    @action.bound private openCreateVehicleDialog(): void {
        this.createVehicleDialogVisible = true;
    }

    @action.bound private closeCreateVehicleDialog(): void {
        this.createVehicleDialogVisible = false;
    }

    public render(): JSX.Element {
        const menuProps: IContextualMenuProps = {
            items: [
                {
                    key: "vehicleSettings",
                    text: this.i18n.t("listVehicles.button.settings"),
                    iconProps: { iconName: "settings" },
                    onClick: this.openSettingVehicleDialog,
                },
            ],
        };

        return (
            <div
                style={{
                    marginRight: sizes.xl,
                }}
            >
                <DefaultButton
                    text={this.i18n.t("listVehicles.button.add")}
                    primary
                    split
                    aria-roledescription="split button"
                    menuProps={menuProps}
                    onClick={this.openCreateVehicleDialog}
                />
                <ElofleetDialog
                    isOpen={this.createVehicleDialogVisible}
                    title={this.i18n.t("listVehicles.button.add")}
                >
                    <FormCreateVehicle
                        onCreate={this.closeCreateVehicleDialog}
                        asDialogContent
                        onDialogCancel={this.closeCreateVehicleDialog}
                    />
                </ElofleetDialog>
                <ElofleetDialog
                    isOpen={this.settingVehicleDialogVisible}
                    title={this.i18n.t("listVehicles.button.add")}
                >
                    <FormUpdateSettingVehicle
                        onUpdate={this.closeSettingVehicleDialog}
                        asDialogContent
                        onDialogCancel={this.closeSettingVehicleDialog}
                    />
                </ElofleetDialog>
            </div>
        );
    }
}
