import * as React from "react";
import {
    ConstrainMode,
    DetailsHeader,
    DetailsList,
    DetailsListLayoutMode,
    IDetailsColumnStyles,
    IDetailsHeaderProps,
    IDetailsListProps,
    SelectionMode,
    TooltipHost,
} from "@fluentui/react";
import css from "./elofleet-list.scss";
import classNames from "classnames";
import sizes from "../sizes.scss";
import zIndex from "../z-index.scss";

export interface ElofleetListProps extends IDetailsListProps {
    maxHeight?: string;
    disableColumnSorting?: boolean;
}

export class ElofleetList extends React.Component<ElofleetListProps> {
    headerStyle: Partial<IDetailsColumnStyles> = {
        cellName: {
            fontSize: "13px",
        },
        root: {
            paddingLeft: 0,
        },
    };

    private renderDetailsHeader(props?: IDetailsHeaderProps): JSX.Element | null {
        // By default, the header includes 16px of padding at the top.
        // This function removes that and adds a tooltip.
        return (
            <DetailsHeader
                {...props}
                layoutMode={DetailsListLayoutMode.justified}
                styles={{
                    root: {
                        paddingTop: 0,
                    },
                }}
                onRenderColumnHeaderTooltip={(props) => {
                    return (
                        <TooltipHost content={props ? props.column?.name : ""}>
                            {props?.children}
                        </TooltipHost>
                    );
                }}
            ></DetailsHeader>
        );
    }

    public render(): JSX.Element {
        // Apply some padding if the table has no selection.
        const padded = this.props.selectionMode === SelectionMode.none;

        // These styles are taken and adapted from the official documentation:
        // https://developer.microsoft.com/en-us/fluentui#/controls/web/scrollablepane
        return (
            <DetailsList
                {...this.props}
                styles={{
                    ...this.props.styles,
                    root: {
                        overflow: "auto",
                    },
                    headerWrapper: {
                        flex: "0 0 auto",
                        position: "sticky",
                        top: "0",
                        zIndex: `${zIndex.listHeader}`,
                        "> div": {
                            height: `${sizes.listHeaderColumnsHeight}`,
                        },
                    },
                    contentWrapper: {
                        maxHeight: this.props.maxHeight,
                    },
                }}
                className={classNames({ [css["elofleetList--padded"]]: padded })}
                onRenderDetailsHeader={this.renderDetailsHeader}
                constrainMode={ConstrainMode.unconstrained}
                layoutMode={DetailsListLayoutMode.justified}
                columns={this.props.columns?.map((column) => {
                    column.styles = this.headerStyle;
                    column.isResizable = true;
                    if (this.props.disableColumnSorting) {
                        column.isSorted = false;
                    }
                    return column;
                })}
                // Render the whole list at once. If this is set
                // to `true`, fluent UI will render entries in chunks when the
                // user scrolls. Since we use a page size of 50 everywhere
                // at the time of writing, it's no problem to always render
                // the whole list, so we always set this to `false`.
                // This prevents a bug where lists don't render correctly in
                // some cases.
                onShouldVirtualize={() => false}
            />
        );
    }
}
