/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PreOpsQuestion
 */
export interface PreOpsQuestion {
    /**
     * 
     * @type {string}
     * @memberof PreOpsQuestion
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof PreOpsQuestion
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof PreOpsQuestion
     */
    updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PreOpsQuestion
     */
    deleted: boolean;
    /**
     * This is the time at which this question has been pulled from the database. This timestamp is needed to reconstruct the list of questions at this point of time. Without this, it's impossible to ensure that all questions of a checklist have been answered.
     * @type {Date}
     * @memberof PreOpsQuestion
     */
    pulledAt: Date;
    /**
     * 
     * @type {string}
     * @memberof PreOpsQuestion
     */
    body: string;
    /**
     * 
     * @type {number}
     * @memberof PreOpsQuestion
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof PreOpsQuestion
     */
    versionId: string;
    /**
     * 
     * @type {string}
     * @memberof PreOpsQuestion
     */
    checklistId: string;
}

/**
 * Check if a given object implements the PreOpsQuestion interface.
 */
export function instanceOfPreOpsQuestion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "deleted" in value;
    isInstance = isInstance && "pulledAt" in value;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "versionId" in value;
    isInstance = isInstance && "checklistId" in value;

    return isInstance;
}

export function PreOpsQuestionFromJSON(json: any): PreOpsQuestion {
    return PreOpsQuestionFromJSONTyped(json, false);
}

export function PreOpsQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreOpsQuestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'deleted': json['deleted'],
        'pulledAt': (new Date(json['pulledAt'])),
        'body': json['body'],
        'position': json['position'],
        'versionId': json['versionId'],
        'checklistId': json['checklistId'],
    };
}

export function PreOpsQuestionToJSON(value?: PreOpsQuestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'deleted': value.deleted,
        'pulledAt': (value.pulledAt.toISOString()),
        'body': value.body,
        'position': value.position,
        'versionId': value.versionId,
        'checklistId': value.checklistId,
    };
}

