import * as React from "react";

export interface TextInputProps {
    value: string;
    placeholder: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    className?: string;
}

/**
 * A generic pre-styled text input for convenient reuse.
 */
export function TextInput(props: TextInputProps): JSX.Element {
    const className = props.className ? props.className : "";
    return (
        <input
            type="text"
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
            className={`form-input h-11 rounded border border-slate-300 placeholder:text-neutral-500 ${className}`}
        />
    );
}
