import { action, computed, makeObservable, observable } from "mobx";

/** Stores information about features that are loading. */
export class LoadingState<TEnum extends string> {
    @observable private readonly state = new Set<TEnum>();

    constructor() {
        makeObservable(this);
    }

    /** Manually designate that the specified feature just started loading. */
    @action.bound public started(feature: TEnum): void {
        if (this.state.has(feature)) {
            throw new Error(`State "${feature}" is already loading.`);
        }
        this.state.add(feature);
    }

    /** Manually designate that the specified feature just finished loading. */
    @action.bound public done(feature: TEnum): void {
        if (!this.state.has(feature)) {
            throw new Error(`State "${feature}" is not loading.`);
        }
        this.state.delete(feature);
    }

    /** Check whether the specified feature is currently loading. */
    public isLoading(feature: TEnum): boolean {
        return this.state.has(feature);
    }

    /** Check whether any tracked feature is loading. */
    @computed public get isAnythingLoading(): boolean {
        return this.state.size > 0;
    }

    /**
     * Wrap any promise in this invocation to automatically track when it starts and finishes
     * loading.
     *
     * #### Example
     *
     * ```ts
     * enum ExampleLoadingFeature {
     *     OPERATION_A = "operation a",
     *     OPERATION_B = "operation b",
     * }
     *
     * @observer
     * export class Example extends React.Component {
     *     private readonly loadingState = new LoadingState<ExampleLoadingFeature>();
     *
     *     ...
     *
     *     @action.bound private async readonly handleBClick(): Promise<void> {
     *         const result = this.loadingState.wrap(
     *             ExampleLoadingFeature.OPERATION_B,
     *             this.someService.longLastingNetworkCall(),
     *         );
     *
     *         ...
     *     }
     *
     *     public render(): JSX.Element {
     *         if (this.loadingState.isLoading(ExampleLoadingFeature.OPERATION_A)) {
     *             return <p>Operation A is loading.</p>;
     *         }
     *         if (this.loadingState.isLoading(ExampleLoadingFeature.OPERATION_B)) {
     *             return <p>Operation B is loading.</p>;
     *         }
     *         return (<button onClick={this.handleBClick}>Trigger operation B</button>);
     *     }
     * }
     * ```
     */
    public async wrap<TResolve, T extends Promise<TResolve>>(
        value: TEnum,
        promise: T,
    ): Promise<T extends Promise<infer TResolve> ? TResolve : never> {
        this.started(value);
        try {
            const result = await promise;
            this.done(value);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return result as any;
        } catch (err) {
            this.done(value);
            throw err;
        }
    }
}
