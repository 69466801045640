/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationEmail } from './NotificationEmail';
import {
    NotificationEmailFromJSON,
    NotificationEmailFromJSONTyped,
    NotificationEmailToJSON,
} from './NotificationEmail';
import type { NotificationEmailCreate } from './NotificationEmailCreate';
import {
    NotificationEmailCreateFromJSON,
    NotificationEmailCreateFromJSONTyped,
    NotificationEmailCreateToJSON,
} from './NotificationEmailCreate';

/**
 * 
 * @export
 * @interface SettingUpdate
 */
export interface SettingUpdate {
    /**
     * 
     * @type {string}
     * @memberof SettingUpdate
     */
    preOpsMessage?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SettingUpdate
     */
    preOpsHoursUntilRecheck?: number | null;
    /**
     * 
     * @type {Array<NotificationEmail>}
     * @memberof SettingUpdate
     */
    preOpsNotificationEmails?: Array<NotificationEmail> | null;
    /**
     * 
     * @type {Array<NotificationEmailCreate>}
     * @memberof SettingUpdate
     */
    preOpsNotificationEmailsCreate?: Array<NotificationEmailCreate> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SettingUpdate
     */
    preOpsRecheckOnDriverChange?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SettingUpdate
     */
    impactMessage?: string | null;
    /**
     * 
     * @type {Array<NotificationEmail>}
     * @memberof SettingUpdate
     */
    impactNotificationEmails?: Array<NotificationEmail> | null;
    /**
     * 
     * @type {Array<NotificationEmailCreate>}
     * @memberof SettingUpdate
     */
    impactNotificationEmailsCreate?: Array<NotificationEmailCreate> | null;
    /**
     * 
     * @type {Array<NotificationEmail>}
     * @memberof SettingUpdate
     */
    maintenanceNotificationEmails?: Array<NotificationEmail> | null;
    /**
     * 
     * @type {Array<NotificationEmailCreate>}
     * @memberof SettingUpdate
     */
    maintenanceNotificationEmailsCreate?: Array<NotificationEmailCreate> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SettingUpdate
     */
    generateDemoData?: boolean | null;
}

/**
 * Check if a given object implements the SettingUpdate interface.
 */
export function instanceOfSettingUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SettingUpdateFromJSON(json: any): SettingUpdate {
    return SettingUpdateFromJSONTyped(json, false);
}

export function SettingUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'preOpsMessage': !exists(json, 'preOpsMessage') ? undefined : json['preOpsMessage'],
        'preOpsHoursUntilRecheck': !exists(json, 'preOpsHoursUntilRecheck') ? undefined : json['preOpsHoursUntilRecheck'],
        'preOpsNotificationEmails': !exists(json, 'preOpsNotificationEmails') ? undefined : (json['preOpsNotificationEmails'] === null ? null : (json['preOpsNotificationEmails'] as Array<any>).map(NotificationEmailFromJSON)),
        'preOpsNotificationEmailsCreate': !exists(json, 'preOpsNotificationEmailsCreate') ? undefined : (json['preOpsNotificationEmailsCreate'] === null ? null : (json['preOpsNotificationEmailsCreate'] as Array<any>).map(NotificationEmailCreateFromJSON)),
        'preOpsRecheckOnDriverChange': !exists(json, 'preOpsRecheckOnDriverChange') ? undefined : json['preOpsRecheckOnDriverChange'],
        'impactMessage': !exists(json, 'impactMessage') ? undefined : json['impactMessage'],
        'impactNotificationEmails': !exists(json, 'impactNotificationEmails') ? undefined : (json['impactNotificationEmails'] === null ? null : (json['impactNotificationEmails'] as Array<any>).map(NotificationEmailFromJSON)),
        'impactNotificationEmailsCreate': !exists(json, 'impactNotificationEmailsCreate') ? undefined : (json['impactNotificationEmailsCreate'] === null ? null : (json['impactNotificationEmailsCreate'] as Array<any>).map(NotificationEmailCreateFromJSON)),
        'maintenanceNotificationEmails': !exists(json, 'maintenanceNotificationEmails') ? undefined : (json['maintenanceNotificationEmails'] === null ? null : (json['maintenanceNotificationEmails'] as Array<any>).map(NotificationEmailFromJSON)),
        'maintenanceNotificationEmailsCreate': !exists(json, 'maintenanceNotificationEmailsCreate') ? undefined : (json['maintenanceNotificationEmailsCreate'] === null ? null : (json['maintenanceNotificationEmailsCreate'] as Array<any>).map(NotificationEmailCreateFromJSON)),
        'generateDemoData': !exists(json, 'generateDemoData') ? undefined : json['generateDemoData'],
    };
}

export function SettingUpdateToJSON(value?: SettingUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'preOpsMessage': value.preOpsMessage,
        'preOpsHoursUntilRecheck': value.preOpsHoursUntilRecheck,
        'preOpsNotificationEmails': value.preOpsNotificationEmails === undefined ? undefined : (value.preOpsNotificationEmails === null ? null : (value.preOpsNotificationEmails as Array<any>).map(NotificationEmailToJSON)),
        'preOpsNotificationEmailsCreate': value.preOpsNotificationEmailsCreate === undefined ? undefined : (value.preOpsNotificationEmailsCreate === null ? null : (value.preOpsNotificationEmailsCreate as Array<any>).map(NotificationEmailCreateToJSON)),
        'preOpsRecheckOnDriverChange': value.preOpsRecheckOnDriverChange,
        'impactMessage': value.impactMessage,
        'impactNotificationEmails': value.impactNotificationEmails === undefined ? undefined : (value.impactNotificationEmails === null ? null : (value.impactNotificationEmails as Array<any>).map(NotificationEmailToJSON)),
        'impactNotificationEmailsCreate': value.impactNotificationEmailsCreate === undefined ? undefined : (value.impactNotificationEmailsCreate === null ? null : (value.impactNotificationEmailsCreate as Array<any>).map(NotificationEmailCreateToJSON)),
        'maintenanceNotificationEmails': value.maintenanceNotificationEmails === undefined ? undefined : (value.maintenanceNotificationEmails === null ? null : (value.maintenanceNotificationEmails as Array<any>).map(NotificationEmailToJSON)),
        'maintenanceNotificationEmailsCreate': value.maintenanceNotificationEmailsCreate === undefined ? undefined : (value.maintenanceNotificationEmailsCreate === null ? null : (value.maintenanceNotificationEmailsCreate as Array<any>).map(NotificationEmailCreateToJSON)),
        'generateDemoData': value.generateDemoData,
    };
}

