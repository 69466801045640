/** This is the return type of the `compareSets` function. */
export class SetComparisonResult<T> {
    public readonly added: T[];
    public readonly removed: T[];

    constructor(added: T[], removed: T[]) {
        this.added = added;
        this.removed = removed;
    }

    // Return whether there are any changes on this comparison result.
    differs(): boolean {
        return this.added.length > 0 || this.removed.length > 0;
    }
}

/**
 * This function takes two sets of comparable types, an original set and a modified set,
 * and returns in which way the original differs compared to the modified set.
 * More specifically, this function returns, which strings have been have been added and
 * which have been removed.
 *
 * This is useful to, for instance, see which entities have been removed or added in a
 * MultiSelectWithList component.
 */
export function compareSets<T>(original: T[], modified: T[]): SetComparisonResult<T> {
    const added: T[] = [];
    const removed: T[] = [];

    // Check for removed entries
    for (const entry of original) {
        if (!modified.includes(entry) && !removed.includes(entry)) {
            removed.push(entry);
        }
    }

    // Check for removed entries
    for (const entry of modified) {
        if (!original.includes(entry) && !added.includes(entry)) {
            added.push(entry);
        }
    }

    return new SetComparisonResult(added, removed);
}
