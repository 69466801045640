/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleGroupAssignment
 */
export interface VehicleGroupAssignment {
    /**
     * 
     * @type {string}
     * @memberof VehicleGroupAssignment
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleGroupAssignment
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof VehicleGroupAssignment
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof VehicleGroupAssignment
     */
    vehicleGroupId: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleGroupAssignment
     */
    vehicleId: string;
}

/**
 * Check if a given object implements the VehicleGroupAssignment interface.
 */
export function instanceOfVehicleGroupAssignment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "vehicleGroupId" in value;
    isInstance = isInstance && "vehicleId" in value;

    return isInstance;
}

export function VehicleGroupAssignmentFromJSON(json: any): VehicleGroupAssignment {
    return VehicleGroupAssignmentFromJSONTyped(json, false);
}

export function VehicleGroupAssignmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleGroupAssignment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'vehicleGroupId': json['vehicleGroupId'],
        'vehicleId': json['vehicleId'],
    };
}

export function VehicleGroupAssignmentToJSON(value?: VehicleGroupAssignment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'vehicleGroupId': value.vehicleGroupId,
        'vehicleId': value.vehicleId,
    };
}

