import {
    getTheme,
    IButtonProps,
    IComboBoxProps,
    IconButton,
    Stack,
    TooltipHost,
} from "@fluentui/react";
import { action, computed, makeObservable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider, LanguageMode } from "../../domain/providers/i18n-provider";
import { LoadingState } from "../../utils/loading-state";

const theme = getTheme();

enum SelectLanguageFeatures {
    CHANGE_LANGUAGE = "change language",
}

export type SelectLanguageProps = Omit<IComboBoxProps, "options" | "selectedKey" | "onChange">;

@observer
@external
export class LanguageButtons extends React.Component<SelectLanguageProps> {
    @inject private readonly i18n!: I18nProvider;

    private readonly loadingFeatures = new LoadingState<SelectLanguageFeatures>();

    constructor(props: SelectLanguageProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get options(): (IButtonProps & { key: string })[] {
        return [
            ...this.i18n.availableLanguages.map((data) => {
                const style =
                    data.code === this.i18n.language
                        ? {
                              border: "1px solid",
                              borderColor: theme.semanticColors.buttonBorder,
                          }
                        : undefined;
                return {
                    key: data.code,
                    onRenderIcon: () => <span className={`flag-icon flag-icon-${data?.flag}`} />,
                    onClick: () => this.changeLanguage(data.code),
                    style,
                };
            }),
        ];
    }

    @action.bound private async changeLanguage(code: string): Promise<void> {
        if (code === undefined) {
            await this.loadingFeatures.wrap(
                SelectLanguageFeatures.CHANGE_LANGUAGE,
                this.i18n.changeLanguage({ mode: LanguageMode.AUTO }),
            );
        } else {
            await this.loadingFeatures.wrap(
                SelectLanguageFeatures.CHANGE_LANGUAGE,
                this.i18n.changeLanguage({ mode: LanguageMode.MANUAL, code }),
            );
        }
    }

    public render(): JSX.Element {
        return (
            <Stack horizontal>
                {this.options.map((props) => (
                    <IconButton {...props} />
                ))}
                <TooltipHost content={"Coming soon!"}>
                    <IconButton
                        disabled={true}
                        key={"es"}
                        onRenderIcon={() => <span className={`flag-icon flag-icon-${"es"}`} />}
                    ></IconButton>
                </TooltipHost>
            </Stack>
        );
    }
}
