import * as React from "react";
import { Async, ISearchBoxProps, SearchBox } from "@fluentui/react";
import { ListStates } from "../../utils/list-states";
import { UUID } from "../../utils/uuid";
import { ApiResource, ElofleetRepository } from "../../utils/elofleet-repository";

export interface ListSearchFieldProps<TRepository extends ElofleetRepository<ApiResource>>
    extends ISearchBoxProps {
    readonly listStateId: UUID;
    readonly listState: ListStates<TRepository>;
}

/**
 * This component is specifically build to act as a search field on top of a list.
 *
 * It is connected with the list's search state, which it updates on change.
 */
export class ListSearchField<TRepository extends ElofleetRepository> extends React.Component<
    ListSearchFieldProps<TRepository>
> {
    constructor(props: ListSearchFieldProps<TRepository>) {
        super(props);
    }

    public render(): JSX.Element {
        // These styles are taken and adapted from the official documentation:
        // https://developer.microsoft.com/en-us/fluentui#/controls/web/scrollablepane
        return (
            <SearchBox
                styles={{
                    root: {
                        minWidth: "300px",
                    },
                    ...this.props.styles,
                }}
                {...this.props}
                onChange={new Async().debounce(
                    this.props.listState.getSearchState(this.props.listStateId).setSearchField,
                    250,
                )}
                onClear={this.props.listState.getSearchState(this.props.listStateId).clearSearch}
            />
        );
    }
}
