/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActionConfig,
  ActionConfigCreate,
  ActionConfigSortKey,
  ActionConfigUpdate,
  ErrorResponse,
  PageInfo,
  SortDirection,
} from '../models';
import {
    ActionConfigFromJSON,
    ActionConfigToJSON,
    ActionConfigCreateFromJSON,
    ActionConfigCreateToJSON,
    ActionConfigSortKeyFromJSON,
    ActionConfigSortKeyToJSON,
    ActionConfigUpdateFromJSON,
    ActionConfigUpdateToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageInfoFromJSON,
    PageInfoToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface ActionConfigsCreateActionConfigRequest {
    actionConfigCreate: ActionConfigCreate;
}

export interface ActionConfigsDeleteActionConfigRequest {
    actionConfigId: string;
}

export interface ActionConfigsListActionConfigsRequest {
    pageSize: number;
    search?: string | null;
    page?: number | null;
    sortDirection?: SortDirection;
    sortKey?: ActionConfigSortKey;
}

export interface ActionConfigsListActionConfigsMetadataRequest {
    pageSize: number;
    search?: string | null;
}

export interface ActionConfigsReadActionConfigRequest {
    actionConfigId: string;
}

export interface ActionConfigsUpdateActionConfigRequest {
    actionConfigId: string;
    actionConfigUpdate: ActionConfigUpdate;
}

export interface ActionConfigsValidateCreateActionConfigRequest {
    actionConfigCreate: ActionConfigCreate;
}

export interface ActionConfigsValidateUpdateActionConfigRequest {
    actionConfigId: string;
    actionConfigUpdate: ActionConfigUpdate;
}

/**
 * ActionConfigsApi - interface
 * 
 * @export
 * @interface ActionConfigsApiInterface
 */
export interface ActionConfigsApiInterface {
    /**
     * Create a new action configuration.
     * @summary Create an action configuration
     * @param {ActionConfigCreate} actionConfigCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionConfigsApiInterface
     */
    actionConfigsCreateActionConfigRaw(requestParameters: ActionConfigsCreateActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActionConfig>>;

    /**
     * Create a new action configuration.
     * Create an action configuration
     */
    actionConfigsCreateActionConfig(requestParameters: ActionConfigsCreateActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActionConfig>;

    /**
     * Delete the action configuration with the given ID.
     * @summary Delete an action configuration
     * @param {string} actionConfigId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionConfigsApiInterface
     */
    actionConfigsDeleteActionConfigRaw(requestParameters: ActionConfigsDeleteActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete the action configuration with the given ID.
     * Delete an action configuration
     */
    actionConfigsDeleteActionConfig(requestParameters: ActionConfigsDeleteActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Lists all the action configurations present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * @summary List action configurations
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {SortDirection} [sortDirection] 
     * @param {ActionConfigSortKey} [sortKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionConfigsApiInterface
     */
    actionConfigsListActionConfigsRaw(requestParameters: ActionConfigsListActionConfigsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ActionConfig>>>;

    /**
     * Lists all the action configurations present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List action configurations
     */
    actionConfigsListActionConfigs(requestParameters: ActionConfigsListActionConfigsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ActionConfig>>;

    /**
     * Fetch information about the total amount of pages available.
     * @summary Action configuration listing metadata
     * @param {number} pageSize 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionConfigsApiInterface
     */
    actionConfigsListActionConfigsMetadataRaw(requestParameters: ActionConfigsListActionConfigsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>>;

    /**
     * Fetch information about the total amount of pages available.
     * Action configuration listing metadata
     */
    actionConfigsListActionConfigsMetadata(requestParameters: ActionConfigsListActionConfigsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo>;

    /**
     * Returns the action configuration template
     * @summary Get an action configuration
     * @param {string} actionConfigId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionConfigsApiInterface
     */
    actionConfigsReadActionConfigRaw(requestParameters: ActionConfigsReadActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActionConfig>>;

    /**
     * Returns the action configuration template
     * Get an action configuration
     */
    actionConfigsReadActionConfig(requestParameters: ActionConfigsReadActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActionConfig>;

    /**
     * Returns the updated action configuration
     * @summary Update an action configuration
     * @param {string} actionConfigId 
     * @param {ActionConfigUpdate} actionConfigUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionConfigsApiInterface
     */
    actionConfigsUpdateActionConfigRaw(requestParameters: ActionConfigsUpdateActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActionConfig>>;

    /**
     * Returns the updated action configuration
     * Update an action configuration
     */
    actionConfigsUpdateActionConfig(requestParameters: ActionConfigsUpdateActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActionConfig>;

    /**
     * Validate data for a new action configuration without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate a action configuration,
     * @param {ActionConfigCreate} actionConfigCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionConfigsApiInterface
     */
    actionConfigsValidateCreateActionConfigRaw(requestParameters: ActionConfigsValidateCreateActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Validate data for a new action configuration without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a action configuration,
     */
    actionConfigsValidateCreateActionConfig(requestParameters: ActionConfigsValidateCreateActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Return an empty body if the data is valid. Return validation errors if it is invalid.
     * @summary Validate an action configuration
     * @param {string} actionConfigId 
     * @param {ActionConfigUpdate} actionConfigUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionConfigsApiInterface
     */
    actionConfigsValidateUpdateActionConfigRaw(requestParameters: ActionConfigsValidateUpdateActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate an action configuration
     */
    actionConfigsValidateUpdateActionConfig(requestParameters: ActionConfigsValidateUpdateActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class ActionConfigsApi extends runtime.BaseAPI implements ActionConfigsApiInterface {

    /**
     * Create a new action configuration.
     * Create an action configuration
     */
    async actionConfigsCreateActionConfigRaw(requestParameters: ActionConfigsCreateActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActionConfig>> {
        if (requestParameters.actionConfigCreate === null || requestParameters.actionConfigCreate === undefined) {
            throw new runtime.RequiredError('actionConfigCreate','Required parameter requestParameters.actionConfigCreate was null or undefined when calling actionConfigsCreateActionConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/action_configs/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActionConfigCreateToJSON(requestParameters.actionConfigCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionConfigFromJSON(jsonValue));
    }

    /**
     * Create a new action configuration.
     * Create an action configuration
     */
    async actionConfigsCreateActionConfig(requestParameters: ActionConfigsCreateActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActionConfig> {
        const response = await this.actionConfigsCreateActionConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the action configuration with the given ID.
     * Delete an action configuration
     */
    async actionConfigsDeleteActionConfigRaw(requestParameters: ActionConfigsDeleteActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.actionConfigId === null || requestParameters.actionConfigId === undefined) {
            throw new runtime.RequiredError('actionConfigId','Required parameter requestParameters.actionConfigId was null or undefined when calling actionConfigsDeleteActionConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/action_configs/{action_config_id}`.replace(`{${"action_config_id"}}`, encodeURIComponent(String(requestParameters.actionConfigId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the action configuration with the given ID.
     * Delete an action configuration
     */
    async actionConfigsDeleteActionConfig(requestParameters: ActionConfigsDeleteActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.actionConfigsDeleteActionConfigRaw(requestParameters, initOverrides);
    }

    /**
     * Lists all the action configurations present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List action configurations
     */
    async actionConfigsListActionConfigsRaw(requestParameters: ActionConfigsListActionConfigsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ActionConfig>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling actionConfigsListActionConfigs.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sort_direction'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortKey !== undefined) {
            queryParameters['sort_key'] = requestParameters.sortKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/action_configs/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionConfigFromJSON));
    }

    /**
     * Lists all the action configurations present. If `search` is specified, return values similar to the `label` field, and `sortKey` will not have any effect: results will be sorted by similarity to the search term instead.
     * List action configurations
     */
    async actionConfigsListActionConfigs(requestParameters: ActionConfigsListActionConfigsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ActionConfig>> {
        const response = await this.actionConfigsListActionConfigsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information about the total amount of pages available.
     * Action configuration listing metadata
     */
    async actionConfigsListActionConfigsMetadataRaw(requestParameters: ActionConfigsListActionConfigsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInfo>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling actionConfigsListActionConfigsMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/action_configs/meta`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInfoFromJSON(jsonValue));
    }

    /**
     * Fetch information about the total amount of pages available.
     * Action configuration listing metadata
     */
    async actionConfigsListActionConfigsMetadata(requestParameters: ActionConfigsListActionConfigsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInfo> {
        const response = await this.actionConfigsListActionConfigsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the action configuration template
     * Get an action configuration
     */
    async actionConfigsReadActionConfigRaw(requestParameters: ActionConfigsReadActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActionConfig>> {
        if (requestParameters.actionConfigId === null || requestParameters.actionConfigId === undefined) {
            throw new runtime.RequiredError('actionConfigId','Required parameter requestParameters.actionConfigId was null or undefined when calling actionConfigsReadActionConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/action_configs/{action_config_id}`.replace(`{${"action_config_id"}}`, encodeURIComponent(String(requestParameters.actionConfigId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionConfigFromJSON(jsonValue));
    }

    /**
     * Returns the action configuration template
     * Get an action configuration
     */
    async actionConfigsReadActionConfig(requestParameters: ActionConfigsReadActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActionConfig> {
        const response = await this.actionConfigsReadActionConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the updated action configuration
     * Update an action configuration
     */
    async actionConfigsUpdateActionConfigRaw(requestParameters: ActionConfigsUpdateActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActionConfig>> {
        if (requestParameters.actionConfigId === null || requestParameters.actionConfigId === undefined) {
            throw new runtime.RequiredError('actionConfigId','Required parameter requestParameters.actionConfigId was null or undefined when calling actionConfigsUpdateActionConfig.');
        }

        if (requestParameters.actionConfigUpdate === null || requestParameters.actionConfigUpdate === undefined) {
            throw new runtime.RequiredError('actionConfigUpdate','Required parameter requestParameters.actionConfigUpdate was null or undefined when calling actionConfigsUpdateActionConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/action_configs/{action_config_id}`.replace(`{${"action_config_id"}}`, encodeURIComponent(String(requestParameters.actionConfigId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ActionConfigUpdateToJSON(requestParameters.actionConfigUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionConfigFromJSON(jsonValue));
    }

    /**
     * Returns the updated action configuration
     * Update an action configuration
     */
    async actionConfigsUpdateActionConfig(requestParameters: ActionConfigsUpdateActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActionConfig> {
        const response = await this.actionConfigsUpdateActionConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate data for a new action configuration without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a action configuration,
     */
    async actionConfigsValidateCreateActionConfigRaw(requestParameters: ActionConfigsValidateCreateActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.actionConfigCreate === null || requestParameters.actionConfigCreate === undefined) {
            throw new runtime.RequiredError('actionConfigCreate','Required parameter requestParameters.actionConfigCreate was null or undefined when calling actionConfigsValidateCreateActionConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/action_configs/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActionConfigCreateToJSON(requestParameters.actionConfigCreate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate data for a new action configuration without persisting it. Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate a action configuration,
     */
    async actionConfigsValidateCreateActionConfig(requestParameters: ActionConfigsValidateCreateActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.actionConfigsValidateCreateActionConfigRaw(requestParameters, initOverrides);
    }

    /**
     * Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate an action configuration
     */
    async actionConfigsValidateUpdateActionConfigRaw(requestParameters: ActionConfigsValidateUpdateActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.actionConfigId === null || requestParameters.actionConfigId === undefined) {
            throw new runtime.RequiredError('actionConfigId','Required parameter requestParameters.actionConfigId was null or undefined when calling actionConfigsValidateUpdateActionConfig.');
        }

        if (requestParameters.actionConfigUpdate === null || requestParameters.actionConfigUpdate === undefined) {
            throw new runtime.RequiredError('actionConfigUpdate','Required parameter requestParameters.actionConfigUpdate was null or undefined when calling actionConfigsValidateUpdateActionConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/action_configs/{action_config_id}/validate`.replace(`{${"action_config_id"}}`, encodeURIComponent(String(requestParameters.actionConfigId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ActionConfigUpdateToJSON(requestParameters.actionConfigUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Return an empty body if the data is valid. Return validation errors if it is invalid.
     * Validate an action configuration
     */
    async actionConfigsValidateUpdateActionConfig(requestParameters: ActionConfigsValidateUpdateActionConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.actionConfigsValidateUpdateActionConfigRaw(requestParameters, initOverrides);
    }

}
