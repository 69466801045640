/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EngineType } from './EngineType';
import {
    EngineTypeFromJSON,
    EngineTypeFromJSONTyped,
    EngineTypeToJSON,
} from './EngineType';
import type { ProcurementType } from './ProcurementType';
import {
    ProcurementTypeFromJSON,
    ProcurementTypeFromJSONTyped,
    ProcurementTypeToJSON,
} from './ProcurementType';
import type { VehicleStatus } from './VehicleStatus';
import {
    VehicleStatusFromJSON,
    VehicleStatusFromJSONTyped,
    VehicleStatusToJSON,
} from './VehicleStatus';

/**
 * 
 * @export
 * @interface Vehicle
 */
export interface Vehicle {
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Vehicle
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Vehicle
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    manufacturer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    vehicleTypeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    serialNumber: string;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    manufactureYear?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    departmentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    shockProfileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    phoneImei?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    magneticFieldThreshold: number;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    preOpsChecklistId?: string | null;
    /**
     * 
     * @type {ProcurementType}
     * @memberof Vehicle
     */
    procurementType?: ProcurementType | null;
    /**
     * 
     * @type {Date}
     * @memberof Vehicle
     */
    purchaseDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Vehicle
     */
    leaseTermBegin?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Vehicle
     */
    leaseTermEnd?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    initialOperatingHours: number;
    /**
     * This already includes the initial operating hours.
     * @type {number}
     * @memberof Vehicle
     */
    totalOperatingSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    idleAutoLogoutAfter?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    maintenanceInterval: number;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    maintenanceNotificationAt?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Vehicle
     */
    maintenanceLockdown: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Vehicle
     */
    checklistOverride: boolean;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    weeklyDriveGoalHours?: number | null;
    /**
     * This is calculated from the fleet authentication events table
     * @type {number}
     * @memberof Vehicle
     */
    totalLoginSeconds: number;
    /**
     * This is calculated from the vehicle movements table
     * @type {number}
     * @memberof Vehicle
     */
    totalDriveSeconds: number;
    /**
     * 
     * @type {VehicleStatus}
     * @memberof Vehicle
     */
    status: VehicleStatus;
    /**
     * This comes from vehicle maintenance table
     * @type {Date}
     * @memberof Vehicle
     */
    lastMaintenanceDate?: Date | null;
    /**
     * This comes from vehicle maintenance table
     * @type {number}
     * @memberof Vehicle
     */
    lastMaintenanceOperatingHours?: number | null;
    /**
     * When maintenance table has no entry, this is calculated from initial operating hours
     * @type {number}
     * @memberof Vehicle
     */
    nextMaintenanceHours: number;
    /**
     * This is calculated
     * @type {number}
     * @memberof Vehicle
     */
    hoursUntilNextMaintenance: number;
    /**
     * 
     * @type {Date}
     * @memberof Vehicle
     */
    lastSynchronizedAt?: Date | null;
    /**
     * 
     * @type {EngineType}
     * @memberof Vehicle
     */
    engineType: EngineType;
}

/**
 * Check if a given object implements the Vehicle interface.
 */
export function instanceOfVehicle(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "serialNumber" in value;
    isInstance = isInstance && "magneticFieldThreshold" in value;
    isInstance = isInstance && "initialOperatingHours" in value;
    isInstance = isInstance && "totalOperatingSeconds" in value;
    isInstance = isInstance && "maintenanceInterval" in value;
    isInstance = isInstance && "maintenanceLockdown" in value;
    isInstance = isInstance && "checklistOverride" in value;
    isInstance = isInstance && "totalLoginSeconds" in value;
    isInstance = isInstance && "totalDriveSeconds" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "nextMaintenanceHours" in value;
    isInstance = isInstance && "hoursUntilNextMaintenance" in value;
    isInstance = isInstance && "engineType" in value;

    return isInstance;
}

export function VehicleFromJSON(json: any): Vehicle {
    return VehicleFromJSONTyped(json, false);
}

export function VehicleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Vehicle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'vehicleTypeId': !exists(json, 'vehicleTypeId') ? undefined : json['vehicleTypeId'],
        'serialNumber': json['serialNumber'],
        'manufactureYear': !exists(json, 'manufactureYear') ? undefined : json['manufactureYear'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'shockProfileId': !exists(json, 'shockProfileId') ? undefined : json['shockProfileId'],
        'phoneImei': !exists(json, 'phoneImei') ? undefined : json['phoneImei'],
        'magneticFieldThreshold': json['magneticFieldThreshold'],
        'preOpsChecklistId': !exists(json, 'preOpsChecklistId') ? undefined : json['preOpsChecklistId'],
        'procurementType': !exists(json, 'procurementType') ? undefined : ProcurementTypeFromJSON(json['procurementType']),
        'purchaseDate': !exists(json, 'purchaseDate') ? undefined : (json['purchaseDate'] === null ? null : new Date(json['purchaseDate'])),
        'leaseTermBegin': !exists(json, 'leaseTermBegin') ? undefined : (json['leaseTermBegin'] === null ? null : new Date(json['leaseTermBegin'])),
        'leaseTermEnd': !exists(json, 'leaseTermEnd') ? undefined : (json['leaseTermEnd'] === null ? null : new Date(json['leaseTermEnd'])),
        'initialOperatingHours': json['initialOperatingHours'],
        'totalOperatingSeconds': json['totalOperatingSeconds'],
        'idleAutoLogoutAfter': !exists(json, 'idleAutoLogoutAfter') ? undefined : json['idleAutoLogoutAfter'],
        'maintenanceInterval': json['maintenanceInterval'],
        'maintenanceNotificationAt': !exists(json, 'maintenanceNotificationAt') ? undefined : json['maintenanceNotificationAt'],
        'maintenanceLockdown': json['maintenanceLockdown'],
        'checklistOverride': json['checklistOverride'],
        'weeklyDriveGoalHours': !exists(json, 'weeklyDriveGoalHours') ? undefined : json['weeklyDriveGoalHours'],
        'totalLoginSeconds': json['totalLoginSeconds'],
        'totalDriveSeconds': json['totalDriveSeconds'],
        'status': VehicleStatusFromJSON(json['status']),
        'lastMaintenanceDate': !exists(json, 'lastMaintenanceDate') ? undefined : (json['lastMaintenanceDate'] === null ? null : new Date(json['lastMaintenanceDate'])),
        'lastMaintenanceOperatingHours': !exists(json, 'lastMaintenanceOperatingHours') ? undefined : json['lastMaintenanceOperatingHours'],
        'nextMaintenanceHours': json['nextMaintenanceHours'],
        'hoursUntilNextMaintenance': json['hoursUntilNextMaintenance'],
        'lastSynchronizedAt': !exists(json, 'lastSynchronizedAt') ? undefined : (json['lastSynchronizedAt'] === null ? null : new Date(json['lastSynchronizedAt'])),
        'engineType': EngineTypeFromJSON(json['engineType']),
    };
}

export function VehicleToJSON(value?: Vehicle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'manufacturer': value.manufacturer,
        'model': value.model,
        'vehicleTypeId': value.vehicleTypeId,
        'serialNumber': value.serialNumber,
        'manufactureYear': value.manufactureYear,
        'departmentId': value.departmentId,
        'shockProfileId': value.shockProfileId,
        'phoneImei': value.phoneImei,
        'magneticFieldThreshold': value.magneticFieldThreshold,
        'preOpsChecklistId': value.preOpsChecklistId,
        'procurementType': ProcurementTypeToJSON(value.procurementType),
        'purchaseDate': value.purchaseDate === undefined ? undefined : (value.purchaseDate === null ? null : value.purchaseDate.toISOString()),
        'leaseTermBegin': value.leaseTermBegin === undefined ? undefined : (value.leaseTermBegin === null ? null : value.leaseTermBegin.toISOString()),
        'leaseTermEnd': value.leaseTermEnd === undefined ? undefined : (value.leaseTermEnd === null ? null : value.leaseTermEnd.toISOString()),
        'initialOperatingHours': value.initialOperatingHours,
        'totalOperatingSeconds': value.totalOperatingSeconds,
        'idleAutoLogoutAfter': value.idleAutoLogoutAfter,
        'maintenanceInterval': value.maintenanceInterval,
        'maintenanceNotificationAt': value.maintenanceNotificationAt,
        'maintenanceLockdown': value.maintenanceLockdown,
        'checklistOverride': value.checklistOverride,
        'weeklyDriveGoalHours': value.weeklyDriveGoalHours,
        'totalLoginSeconds': value.totalLoginSeconds,
        'totalDriveSeconds': value.totalDriveSeconds,
        'status': VehicleStatusToJSON(value.status),
        'lastMaintenanceDate': value.lastMaintenanceDate === undefined ? undefined : (value.lastMaintenanceDate === null ? null : value.lastMaintenanceDate.toISOString()),
        'lastMaintenanceOperatingHours': value.lastMaintenanceOperatingHours,
        'nextMaintenanceHours': value.nextMaintenanceHours,
        'hoursUntilNextMaintenance': value.hoursUntilNextMaintenance,
        'lastSynchronizedAt': value.lastSynchronizedAt === undefined ? undefined : (value.lastSynchronizedAt === null ? null : value.lastSynchronizedAt.toISOString()),
        'engineType': EngineTypeToJSON(value.engineType),
    };
}

