import * as React from "react";
import { external, inject } from "tsdi";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { declareRoute, routePath, RouteProps } from "../routes";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { PageHeader } from "../ui/atoms/page-header";
import { PageContent } from "../ui/atoms/page-content";
import { Section } from "../ui/atoms/section";
import { ListPedestrianEvents } from "../ui/molecules/list-pedestrian-events";
import { FilterByDateRange } from "../ui/organisms/filter-by-date-range";
import { SelectionMode } from "@fluentui/react";

@external
export class PagePedestrianActivity extends React.Component<RouteProps> {
    @inject protected readonly i18n!: I18nProvider;

    public render(): JSX.Element {
        return (
            <LayoutDefault
                header={<PageHeader title={this.i18n.t("page.pedestrianActivity.title")} />}
            >
                <PageContent>
                    <Section>
                        <ListPedestrianEvents
                            commandBar={<FilterByDateRange onDateRangeChanged={() => {}} />}
                            selectionMode={SelectionMode.none}
                        />
                    </Section>
                </PageContent>
            </LayoutDefault>
        );
    }
}

export const routePedestrianActivity = declareRoute({
    navigation: true,
    component: PagePedestrianActivity,
    icon: "PeopleAlert",
    title: "page.pedestrianActivity.navbarEntry",
    path: routePath.pedestrianActivity,
    pattern: "/pedestrian-activity",
});
