import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { NodeProps } from "reactflow";
import { DigitalInputStateData, DigitalInput } from "../../../api";
import ActionConfigNode, { digitalInputOptions } from "./action-config-node";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export default function DigitalInputState(
    props: NodeProps<
        DigitalInputStateData & { invalid?: boolean; validationErrorMessage?: string }
    >,
): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    const [selectedDigitalInput, setSelectedDigitalInput] = useState(
        props.data.digitalInput ?? DigitalInput.INPUT1,
    );

    // We have to set the data to prevent undefined values
    useEffect(() => {
        props.data.digitalInput = selectedDigitalInput;
    }, [props.data, selectedDigitalInput]);

    function onChange(
        _e?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption,
    ): void {
        if (!option) {
            return;
        }
        setSelectedDigitalInput(option.key as DigitalInput);
        props.data.digitalInput = option.key as DigitalInput;
    }

    return (
        <ActionConfigNode nodeKind={props.type} {...props.data}>
            <ChoiceGroup
                options={digitalInputOptions(t)}
                selectedKey={selectedDigitalInput}
                onChange={onChange}
            />
        </ActionConfigNode>
    );
}
