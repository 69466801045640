import * as React from "react";
import { Selection, IObjectWithKey, IColumn, Stack } from "@fluentui/react";
import { omit } from "ramda";
import { action, computed, makeObservable, observable } from "mobx";
import { external, initialize, inject } from "tsdi";
import {
    RepositoryVehicles,
    VehiclesApiResource,
    VehiclesQuery,
} from "../../domain/repositories/repository-vehicles";
import { createUuid, UUID } from "../../utils/uuid";
import {
    ColumnVisibility,
    Vehicle,
    VehiclesExportVehicleListListType,
    VehicleSortKey,
} from "../../api";
import { observer } from "mobx-react";
import { I18nProvider } from "../../domain/providers/i18n-provider";
import { ListVehicleItemCell } from "../atoms/list-vehicle-item-cell";
import { SortState } from "../../utils/sort-state";
import { PaginationState } from "../../utils/pagination-state";
import { LayoutList } from "../layouts/layout-list";
import { ServiceListStates } from "../../domain/services/service-list-states";
import { ElofleetList, ElofleetListProps } from "../atoms/elofleet-list";
import { ListSearchField } from "../atoms/list-search-field";
import { SelectionState } from "../../utils/selection-state";
import { RepositoryColumnVisibility } from "../../domain/repositories/repository-column-visibility";
import { SearchState } from "../../utils/search-state";
import { FilterVehicleList, FilterVehicleListQuery } from "../organisms/filter-vehicle-list";
import { ExportButton, ExportQuery } from "./export-button";
import { defaultPageSize } from "../../utils/constants";

export enum ListVehiclesLayout {
    Default,
    Maintenance,
    Group,
}

export interface ListVehiclesProps extends Omit<ElofleetListProps, "items" | "columns"> {
    readonly ids?: UUID[];
    readonly vehicleGroupIds?: UUID[];
    readonly commandBar?: JSX.Element;
    readonly ignoreColumnVisibility?: boolean;
    readonly listStateId?: UUID;
    readonly columnLayout?: ListVehiclesLayout;
    readonly hideFilter?: boolean;
    readonly ignoreUrl?: boolean;
}

interface ListVehicleItem extends IObjectWithKey {
    readonly key: UUID;
}

@external
@observer
export class ListVehicles extends React.Component<ListVehiclesProps> {
    @inject protected readonly i18n!: I18nProvider;
    @inject private readonly serviceListStates!: ServiceListStates;
    @inject private readonly repositoryColumnVisibility!: RepositoryColumnVisibility;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;

    private _listStateId = createUuid();
    private selection = new Selection({
        onSelectionChanged: () => this.updateSelection(),
    });

    @observable private filterVehicleListQuery: FilterVehicleListQuery = {
        filteredDepartments: [],
        filteredVehicleGroups: [],
        isFiltered: false,
    };

    constructor(props: ListVehiclesProps) {
        super(props);
        makeObservable(this);
    }

    @initialize protected initialize(): void {
        if (!this.props) {
            return;
        }
        this.serviceListStates.vehicles.initializeList(this.listStateId, {
            query: () => this.query,
            ignoreUrl: this.props.ignoreUrl ?? false,
        });
    }

    @computed private get columnVisibility(): ColumnVisibility | undefined {
        return this.repositoryColumnVisibility.byQuery()[0];
    }

    @computed private get listStateId(): UUID {
        return this.props.listStateId ?? this._listStateId;
    }

    @computed private get sortState(): SortState<VehicleSortKey> {
        return this.serviceListStates.vehicles.getSortState(this.listStateId);
    }

    @computed private get paginationState(): PaginationState<VehiclesApiResource> {
        return this.serviceListStates.vehicles.getPaginationState(this.listStateId);
    }

    @computed private get selectionState(): SelectionState {
        return this.serviceListStates.vehicles.getSelectionState(this.listStateId);
    }

    @computed private get searchState(): SearchState {
        return this.serviceListStates.vehicles.getSearchState(this.listStateId);
    }

    @computed private get query(): VehiclesQuery {
        const query = {
            pageSize: defaultPageSize,
            ...this.sortState.query,
            ...this.searchState.query,
        } as VehiclesQuery;
        if (this.filterVehicleListQuery === undefined) {
            return query;
        }
        if (this.filterVehicleListQuery.filteredDepartments.length > 0) {
            query.departmentIds = this.filterVehicleListQuery.filteredDepartments;
        }
        if (this.filterVehicleListQuery.filteredVehicleGroups.length > 0) {
            query.vehicleGroupIds = this.filterVehicleListQuery.filteredVehicleGroups;
        }
        if (this.props.vehicleGroupIds) {
            query.vehicleGroupIds = (query.vehicleGroupIds ?? []).concat(
                this.props.vehicleGroupIds,
            );
        }
        return query;
    }

    @computed private get exportQuery(): ExportQuery {
        const query = Object.entries({
            list_type: VehiclesExportVehicleListListType.TRUCKS,
            ...this.query,
        });
        return { query, basePath: "/api/v1/vehicles/export?" };
    }

    @computed private get vehicles(): (Vehicle | undefined)[] {
        if (this.props.ids) {
            return this.props.ids.map((id) => this.repositoryVehicles.byId(id));
        }
        return this.repositoryVehicles.byQuery(this.query, this.paginationState.pagination);
    }

    @computed private get items(): (ListVehicleItem | undefined)[] {
        return this.vehicles.map((vehicle) => {
            if (!vehicle) {
                return;
            }
            return {
                key: vehicle.id,
            };
        });
    }

    // This column layout is shown in the detail panels of VehicleGroups.
    @computed private get groupColumns(): IColumn[] {
        const columns: IColumn[] = [];

        columns.push({
            name: this.i18n.t("listVehicles.column.serialNumber.name"),
            key: "serialNumber",
            minWidth: 120,
        });
        columns.push({
            name: this.i18n.t("listVehicles.column.manufacturer.name"),
            key: "manufacturer",
            minWidth: 100,
        });
        columns.push({
            name: this.i18n.t("listVehicles.column.model.name"),
            key: "model",
            minWidth: 100,
        });
        columns.push({
            name: this.i18n.t("listVehicles.column.vehicleType.name"),
            key: "vehicleType",
            minWidth: 100,
        });
        columns.push({
            name: this.i18n.t("listVehicles.column.department.name"),
            key: "department",
            minWidth: 150,
        });
        columns.push({
            name: this.i18n.t("listVehicles.column.vehicleStatus.name"),
            key: "vehicleStatus",
            minWidth: 150,
        });

        return columns;
    }

    @computed private get maintenanceColumns(): IColumn[] {
        const columns: IColumn[] = [];

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleStatus
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.vehicleStatus.name"),
                key: "vehicleStatus",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleSerialNumber
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.serialNumber.name"),
                key: "serialNumber",
                minWidth: 120,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleManufacturer
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.manufacturer.name"),
                key: "manufacturer",
                minWidth: 100,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleModel
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.model.name"),
                key: "model",
                minWidth: 100,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleTypeLabel
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.vehicleType.name"),
                key: "vehicleType",
                minWidth: 100,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleEngineType
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.engineType.name"),
                key: "engineType",
                minWidth: 100,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleManufactureYear
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.manufactureYear.name"),
                key: "manufactureYear",
                minWidth: 80,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.departmentLabel
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.department.name"),
                key: "department",
                minWidth: 150,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehiclePurchaseDateOrLeaseTermBegin
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.purchaseTermBegin.name"),
                key: "purchaseTermBegin",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleLeaseTermEnd
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.leaseTermEnd.name"),
                key: "leaseTermEnd",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleLeaseTermDaysRemaining
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.daysUntilLeaseTermEnd.name"),
                key: "daysUntilLeaseTermEnd",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleInitialOperatingHours
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.initialOperatingHours.name"),
                key: "initialOperatingHours",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleCurrentOperatingHours
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.currentOperatingHours.name"),
                key: "currentOperatingHours",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleLastMaintenanceOperatingHours
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.lastMaintenanceHours.name"),
                key: "lastMaintenanceHours",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleLastMaintenanceDate
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.lastMaintenanceDate.name"),
                key: "lastMaintenanceDate",
                minWidth: 150,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleNextMaintenanceHours
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.nextMaintenanceHours.name"),
                key: "nextMaintenanceHours",
                minWidth: 150,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleMaintenanceInterval
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.maintenanceInterval.name"),
                key: "maintenanceInterval",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleMaintenanceNotificationAt
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.maintenanceNotificationAt.name"),
                key: "maintenanceNotificationAt",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleMaintenanceLockdown
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.maintenanceLockdown.name"),
                key: "maintenanceLockdown",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.maintenanceList.vehicleHoursUntilNextMaintenance
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.hoursUntilMaintenance.name"),
                key: "hoursUntilMaintenance",
                minWidth: 150,
            });

        return columns;
    }

    @computed private get defaultColumns(): IColumn[] {
        const columns: IColumn[] = [];

        if (this.props.ignoreColumnVisibility || this.columnVisibility?.vehicleList.vehicleStatus)
            columns.push({
                name: this.i18n.t("listVehicles.column.vehicleStatus.name"),
                key: "vehicleStatus",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleSerialNumber
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.serialNumber.name"),
                key: "serialNumber",
                minWidth: 120,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehiclePhoneImei
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.phoneImei.name"),
                key: "phoneImei",
                minWidth: 100,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleDeviceSerialNumber
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.eloboxId.name"),
                key: "eloboxId",
                minWidth: 100,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleManufacturer
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.manufacturer.name"),
                key: "manufacturer",
                minWidth: 100,
            });
        if (this.props.ignoreColumnVisibility || this.columnVisibility?.vehicleList.vehicleModel)
            columns.push({
                name: this.i18n.t("listVehicles.column.model.name"),
                key: "model",
                minWidth: 100,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleTypeLabel
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.vehicleType.name"),
                key: "vehicleType",
                minWidth: 100,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleEngineType
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.vehicleEngineType.name"),
                key: "engineType",
                minWidth: 100,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleManufactureYear
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.manufactureYear.name"),
                key: "manufactureYear",
                minWidth: 80,
            });
        if (this.props.ignoreColumnVisibility || this.columnVisibility?.vehicleList.departmentLabel)
            columns.push({
                name: this.i18n.t("listVehicles.column.department.name"),
                key: "department",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleGroupLabel
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.group.name"),
                key: "group",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleShockProfile
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.shockProfile.name"),
                key: "shockProfile",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehiclePreOpsChecklist
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.checklistProfile.name"),
                key: "checklistProfile",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleChecklistOverride
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.checklistOverride.name"),
                key: "checklistOverride",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleIdleAutoLogoutAfter
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.vehicleIdleAutoLogoutAfter.name"),
                key: "vehicleIdleAutoLogoutAfter",
                minWidth: 150,
            });
        if (this.props.ignoreColumnVisibility || this.columnVisibility?.vehicleList.userFullName)
            columns.push({
                name: this.i18n.t("listVehicles.column.lastDriver.name"),
                key: "lastDriver",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleLastSynchronizedAt
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.lastSynchronization.name"),
                key: "lastSynchronization",
                minWidth: 150,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehiclePurchaseDateOrLeaseTermBegin
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.purchaseTermBegin.name"),
                key: "purchaseTermBegin",
                minWidth: 150,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleLeaseTermEnd
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.leaseTermEnd.name"),
                key: "leaseTermEnd",
                minWidth: 150,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleLeaseTermDaysRemaining
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.daysUntilLeaseTermEnd.name"),
                key: "daysUntilLeaseTermEnd",
                minWidth: 150,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleInitialOperatingHours
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.initialOperatingHours.name"),
                key: "initialOperatingHours",
                minWidth: 150,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleCurrentOperatingHours
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.currentOperatingHours.name"),
                key: "currentOperatingHours",
                minWidth: 150,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleLastMaintenanceOperatingHours
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.lastMaintenanceHours.name"),
                key: "lastMaintenanceHours",
                minWidth: 150,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleLastMaintenanceDate
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.lastMaintenanceDate.name"),
                key: "lastMaintenanceDate",
                minWidth: 150,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleNextMaintenanceHours
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.nextMaintenanceHours.name"),
                key: "nextMaintenanceHours",
                minWidth: 150,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleMaintenanceInterval
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.maintenanceInterval.name"),
                key: "maintenanceInterval",
                minWidth: 150,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleMaintenanceNotificationAt
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.maintenanceNotificationAt.name"),
                key: "maintenanceNotificationAt",
                minWidth: 150,
            });
        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleMaintenanceLockdown
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.maintenanceLockdown.name"),
                key: "maintenanceLockdown",
                minWidth: 150,
            });

        if (
            this.props.ignoreColumnVisibility ||
            this.columnVisibility?.vehicleList.vehicleHoursUntilNextMaintenance
        )
            columns.push({
                name: this.i18n.t("listVehicles.column.hoursUntilMaintenance.name"),
                key: "hoursUntilMaintenance",
                minWidth: 150,
            });

        return columns;
    }

    @computed private get columns(): IColumn[] {
        if (this.props.columnLayout === ListVehiclesLayout.Maintenance) {
            return this.maintenanceColumns;
        } else if (this.props.columnLayout === ListVehiclesLayout.Group) {
            return this.groupColumns;
        } else {
            return this.defaultColumns;
        }
    }

    private renderItem(vehicleId: UUID, column: IColumn): JSX.Element {
        return (
            <ListVehicleItemCell
                vehicleId={vehicleId}
                column={column}
                filteredVehicleGroups={this.filterVehicleListQuery.filteredVehicleGroups}
            />
        );
    }

    @action.bound private updateSelection(): void {
        // Update the listState's selectionState to the new selected keys.
        this.selectionState.updateSelection(
            this.selection.getSelection().map((item) => item.key as UUID),
        );
    }

    @action.bound private setVehicleListFilter(filterQuery: FilterVehicleListQuery): void {
        this.filterVehicleListQuery = {
            filteredDepartments: filterQuery.filteredDepartments,
            filteredVehicleGroups: filterQuery.filteredVehicleGroups,
            isFiltered: filterQuery.isFiltered,
        };
    }

    public render(): JSX.Element {
        if (!this.serviceListStates.vehicles.isInitialized(this.listStateId)) {
            return <></>;
        }

        // In the group details panel we always show the full list of vehicles
        // and so we disable the search functionality.
        let searchField;
        if (this.props.columnLayout !== ListVehiclesLayout.Group) {
            searchField = (
                <ListSearchField
                    placeholder={this.i18n.t("listVehicles.search.placeholder")}
                    listStateId={this.listStateId}
                    listState={this.serviceListStates.vehicles}
                />
            );
        }

        let filter;
        if (!this.props.hideFilter) {
            filter = <FilterVehicleList setVehicleListFilter={this.setVehicleListFilter} />;
        }

        // Forward properties that are used by Fluent UI to the `ShimmeredDetailsList`.
        const props = omit(["ids"], this.props);
        return (
            <LayoutList
                paginationState={this.paginationState}
                commandBar={
                    <Stack horizontal horizontalAlign="space-between">
                        {this.props.commandBar}
                        <ExportButton query={this.exportQuery} />
                    </Stack>
                }
                searchField={searchField}
                filter={filter}
            >
                <ElofleetList
                    {...props}
                    selection={this.selection}
                    onRenderItemColumn={(item: ListVehicleItem, _index, column) =>
                        this.renderItem(item.key, column!)
                    }
                    onColumnHeaderClick={this.sortState.toggleColumn}
                    columns={this.sortState.patchColumns(this.columns)}
                    items={this.items}
                />
            </LayoutList>
        );
    }
}
