import { TFunction } from "i18next";

export function formatCurrency(t: TFunction, dollarCents: number): string {
    const cents = Math.trunc(dollarCents % 100).toString();

    const wholeDollars = Math.trunc(dollarCents / 100);
    const commaSeparatedDollars = wholeDollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const currencySign = t("dms.generic.currencySign");

    return `${currencySign}${commaSeparatedDollars}.${cents.padEnd(2, "0")}`;
}
