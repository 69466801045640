import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { NodeProps } from "reactflow";
import ActionConfigNode, { antennaOptions, zoneOptions } from "./action-config-node";
import { Antenna, StationsInZoneData, Zone } from "../../../api";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export default function StationsInZone(
    props: NodeProps<StationsInZoneData & { invalid?: boolean; validationErrorMessage?: string }>,
): JSX.Element {
    const { t }: { t: TFunction } = useTranslation();
    const [selectedAntenna, setSelectedAntenna] = useState(props.data.antenna ?? Antenna.A);
    const [selectedZone, setSelectedZone] = useState(props.data.zone ?? Zone.ONE);

    // We have to set the data to prevent undefined values
    useEffect(() => {
        props.data.zone = selectedZone;
        props.data.antenna = selectedAntenna;
    }, [props.data, selectedZone, selectedAntenna]);

    function onZoneChange(
        _e?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption,
    ): void {
        if (!option) {
            return;
        }
        setSelectedZone(option.key as Zone);
        props.data.zone = option.key as Zone;
    }

    function onAntennaChange(
        _e?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption,
    ): void {
        if (!option) {
            return;
        }
        setSelectedAntenna(option.key as Antenna);
        props.data.antenna = option.key as Antenna;
    }

    return (
        <ActionConfigNode nodeKind={props.type} {...props.data}>
            <ChoiceGroup
                options={zoneOptions(t)}
                selectedKey={selectedZone}
                onChange={onZoneChange}
            />
            <ChoiceGroup
                options={antennaOptions(t)}
                selectedKey={selectedAntenna}
                onChange={onAntennaChange}
            />
        </ActionConfigNode>
    );
}
