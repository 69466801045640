/* tslint:disable */
/* eslint-disable */
/**
 * ELOfleet API Documentation
 * Backend of the ELOfleet fleet management system
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ShockEventLevel {
    LIGHT = 'light',
    MEDIUM = 'medium',
    SEVERE = 'severe'
}


export function ShockEventLevelFromJSON(json: any): ShockEventLevel {
    return ShockEventLevelFromJSONTyped(json, false);
}

export function ShockEventLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShockEventLevel {
    return json as ShockEventLevel;
}

export function ShockEventLevelToJSON(value?: ShockEventLevel | null): any {
    return value as any;
}

