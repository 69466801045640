import React from "react";
import {
    Edge,
    Handle,
    NodeInternals,
    Position,
    ReactFlowState,
    getConnectedEdges,
    useNodeId,
    useReactFlow,
    useStore,
} from "reactflow";

import css from "./restricted-handle.scss";
import { NodeKind } from "../../../api";

// The logic for the handle restriction is taken from
// https://reactflow.dev/examples/nodes/connection-limit.
const selector = (s: ReactFlowState): { nodeInternals: NodeInternals; edges: Edge[] } => ({
    nodeInternals: s.nodeInternals,
    edges: s.edges,
});

export const boolInputHandle = (isConnectable: boolean): JSX.Element => (
    <Handle
        id="boolean"
        className={css.inputBoolHandle}
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
    />
);
export const boolOutputHandle = (
    <Handle
        id="boolean"
        type="source"
        position={Position.Bottom}
        className={css.outputBoolHandle}
    />
);
export const numberInputHandle = (isConnectable: boolean): JSX.Element => (
    <Handle
        id="number"
        type="target"
        position={Position.Top}
        className={css.inputNumberHandle}
        isConnectable={isConnectable}
    />
);
export const numberOutputHandle = (
    <Handle
        id="number"
        type="source"
        position={Position.Bottom}
        className={css.outputNumberHandle}
    />
);

interface NodeHandlesProps {
    nodeKind: NodeKind;
}

/** Handles for specific node kind. Different nodes require different
    restrictions. */
export function NodeHandles(props: NodeHandlesProps): JSX.Element {
    const { nodeInternals } = useStore(selector);
    const { getEdges } = useReactFlow();
    const nodeId = useNodeId();
    /** Only allow a connection if the maximum number of connections is
    not reached yet. */
    function isConnectable(maxConnections: number): boolean {
        if (!nodeId) {
            return false;
        }
        const node = nodeInternals.get(nodeId);
        if (!node) {
            return false;
        }
        const connectedEdges = getConnectedEdges([node], getEdges()).filter(
            (edge) => edge.target == nodeId,
        );
        if (connectedEdges.length < maxConnections) {
            return true;
        } else {
            return false;
        }
    }

    switch (props.nodeKind) {
        // Input nodes
        case NodeKind.ANY_IN_ZONE:
            return boolOutputHandle;
        case NodeKind.DIGITAL_INPUT_STATE:
            return boolOutputHandle;
        case NodeKind.IN_ERROR_MODE:
            return boolOutputHandle;
        case NodeKind.MUTING:
            return boolOutputHandle;
        case NodeKind.PEDESTRIANS_IN_ZONE:
            return numberOutputHandle;
        case NodeKind.STATIONS_IN_ZONE:
            return numberOutputHandle;
        case NodeKind.VEHICLES_IN_ZONE:
            return numberOutputHandle;

        // Intermediate nodes
        case NodeKind.AND:
            return (
                <>
                    {boolInputHandle(isConnectable(5))}
                    {boolOutputHandle}
                </>
            );
        case NodeKind.EQUAL:
            return (
                <>
                    {numberInputHandle(isConnectable(2))}
                    {boolOutputHandle}
                </>
            );
        case NodeKind.GREATER_THAN:
            return (
                <>
                    {numberInputHandle(isConnectable(1))} {boolOutputHandle}
                </>
            );
        case NodeKind.LESS_THAN:
            return (
                <>
                    {numberInputHandle(isConnectable(1))} {boolOutputHandle}
                </>
            );
        case NodeKind.NOT:
            return (
                <>
                    {boolInputHandle(isConnectable(1))} {boolOutputHandle}
                </>
            );
        case NodeKind.OR:
            return (
                <>
                    {boolInputHandle(isConnectable(5))}
                    {boolOutputHandle}
                </>
            );

        // Output nodes
        case NodeKind.DEACTIVATE_ANTENNAS:
            return boolInputHandle(isConnectable(1));
        case NodeKind.SET_STATIONARY_RELAY:
            return boolInputHandle(isConnectable(1));
        case NodeKind.SET_VEHICLE_RELAY:
            return boolInputHandle(isConnectable(1));
        default:
            return <></>;
    }
}
