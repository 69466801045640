import { CommandButton } from "@fluentui/react";
import { action, computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { external, inject } from "tsdi";
import { ShockProfile, Vehicle, VehicleDevice } from "../api";
import { I18nProvider } from "../domain/providers/i18n-provider";
import { RepositoryShockProfiles } from "../domain/repositories/repository-shock-profiles";
import { RepositoryVehicleDevices } from "../domain/repositories/repository-vehicle-devices";
import { RepositoryVehicles } from "../domain/repositories/repository-vehicles";
import { declareRoute, routePath, RouteProps } from "../routes";
import { Attribute } from "../ui/atoms/attribute";
import { LayoutDefault } from "../ui/layouts/layout-default";
import { FormUpdateVehicle } from "../ui/molecules/form-update-vehicle";
import { ListVehicleGroupAssignments } from "../ui/molecules/list-vehicle-group-assignments";
import { ListCommandButtonsVehicleGroupAssignments } from "../ui/organisms/list-command-buttons-vehicle-group-assignments";
import { createUuid, UUID } from "../utils/uuid";

export type PageVehicleProps = RouteProps<{ vehicleId: UUID }>;
import { PageHeader } from "../ui/atoms/page-header";
import { PageContent } from "../ui/atoms/page-content";
import { Section } from "../ui/atoms/section";
import { ElofleetDialog } from "../ui/atoms/elofleet-dialog";
import { HistoryProvider } from "../domain/providers/history-provider";

@external
@observer
export class PageVehicle extends React.Component<PageVehicleProps> {
    @inject private readonly i18n!: I18nProvider;
    @inject private readonly historyProvider!: HistoryProvider;
    @inject private readonly repositoryVehicles!: RepositoryVehicles;
    @inject private readonly repositoryShockProfiles!: RepositoryShockProfiles;
    @inject private readonly repositoryVehicleDevices!: RepositoryVehicleDevices;

    @observable private updateDialogVisible = false;

    // Create the UUID on this level, so the button bar is able to access the same state as the list.
    private vehicleGroupAssignmentListStateId = createUuid();

    constructor(props: PageVehicleProps) {
        super(props);
        makeObservable(this);
    }

    @computed private get vehicleId(): UUID {
        // NOTE: when rewriting as a functional component use useLocation hook instead
        return this.historyProvider.history.location.pathname.split("/").slice(-1)[0];
    }

    @computed private get vehicle(): Vehicle | undefined {
        return this.repositoryVehicles.byId(this.vehicleId);
    }

    @computed private get shockProfile(): ShockProfile | undefined {
        if (!this.vehicle || !this.vehicle.shockProfileId) {
            return;
        }
        return this.repositoryShockProfiles.byId(this.vehicle.shockProfileId);
    }

    @computed private get vehicleDevice(): VehicleDevice | undefined {
        if (!this.vehicle) {
            return;
        }
        return this.repositoryVehicleDevices.byVehicleId(this.vehicle.id);
    }

    @action.bound private showUpdateDialog(): void {
        this.updateDialogVisible = true;
    }

    @action.bound private hideUpdateDialog(): void {
        this.updateDialogVisible = false;
    }

    public render(): JSX.Element {
        if (!this.vehicle) {
            return <></>;
        }
        return (
            <LayoutDefault header={<PageHeader title={this.i18n.formatVehicle(this.vehicle)} />}>
                <PageContent>
                    <Section withPadding>
                        <Attribute
                            title={this.i18n.t("page.vehicle.serialNumber")}
                            value={this.vehicle.serialNumber}
                        />
                        <Attribute
                            title={this.i18n.t("page.vehicle.manufactureYear")}
                            value={this.vehicle.manufactureYear}
                        />
                        <Attribute
                            title={this.i18n.t("page.vehicle.createdAt")}
                            value={this.i18n.formatDateTime(this.vehicle.createdAt)}
                        />
                        <Attribute
                            title={this.i18n.t("page.vehicle.updatedAt")}
                            value={this.i18n.formatDateTime(this.vehicle.updatedAt)}
                        />
                        <Attribute
                            title={this.i18n.t("page.vehicle.model")}
                            value={this.vehicle.model}
                        />
                        <Attribute
                            title={this.i18n.t("page.vehicle.manufacturer")}
                            value={this.vehicle.manufacturer}
                        />
                        <Attribute
                            title={this.i18n.t("page.vehicle.shockProfile")}
                            value={
                                this.shockProfile && this.i18n.formatShockProfile(this.shockProfile)
                            }
                        />
                        <Attribute
                            title={this.i18n.t("page.vehicle.vehicleDeviceLabel")}
                            value={this.vehicleDevice?.serialNumber}
                            fallback={this.i18n.t("page.vehicle.noVehicleDevice")}
                        />
                        <CommandButton
                            onClick={this.showUpdateDialog}
                            text={this.i18n.t("page.vehicle.edit")}
                        />
                    </Section>

                    <Section
                        title={this.i18n.t("page.vehicle.groups")}
                        actionButtons={
                            <ListCommandButtonsVehicleGroupAssignments
                                canDelete
                                vehicleId={this.vehicleId}
                                listStateId={this.vehicleGroupAssignmentListStateId}
                            />
                        }
                    >
                        <ListVehicleGroupAssignments
                            vehicleId={this.vehicleId}
                            showVehicle={false}
                            listStateId={this.vehicleGroupAssignmentListStateId}
                        />
                    </Section>

                    {this.updateDialogVisible && (
                        <ElofleetDialog
                            isOpen={true}
                            title={this.i18n.t("page.vehicle.editDialog.title")}
                        >
                            {
                                <FormUpdateVehicle
                                    asDialogContent
                                    id={this.vehicle.id}
                                    onUpdate={this.hideUpdateDialog}
                                    onDialogCancel={this.hideUpdateDialog}
                                />
                            }
                        </ElofleetDialog>
                    )}
                </PageContent>
            </LayoutDefault>
        );
    }
}

export const routeVehicle = declareRoute({
    component: PageVehicle,
    path: routePath.vehicle,
    pattern: "/vehicles/:vehicleId",
});
